import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import BaseScene from '../../../../components/scenes/component'
import UsersGridScene from './scenes/users-grid'
import AddUserScene from './scenes/add-user'
import ViewUserScene from './scenes/view-user'
import { isAuthorized } from '../../../../mixins/authentication'
import { CAN_VIEW_USER, CAN_CREATE_USER } from '../../../../constants/permissions.js'

export default class UserManagementScene extends BaseScene {
  sceneName = 'user-management'

  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_USER]} exact path='/home/user-management' component={UsersGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_USER]} exact path='/home/user-management/add' component={AddUserScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_USER]} exact path='/home/user-management/:id/view' component={ViewUserScene} />
    </Router>
  }
}
