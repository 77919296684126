import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import { formatCurrency, formatDate } from '../../../../../../utils/formatter';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class PayWithTransferTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '950px',
        zoom: 'plus'
      }
    
    zoomTableHeight = this.zoomTableHeight.bind(this)
    
    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }
    
    render() {
          const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        
        const columns = [
            {
                Header: "Transaction Ref",
                accessor: 'channelTransactionReference',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                width: 200,
                style: {'white-space': 'unset'},
            },
            {
                Header: "Transaction Date",
                accessor: 'dateOfPayment',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 130,
                Cell: props => <span>{props.value ? formatDate(props.value) : ''}</span>
            },
            {
                Header: "Session ID",
                accessor: 'channelTransactionReference',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                minWidth: 200,
                maxWidth: 250,
            },
            {
                Header: "Bank Code",
                accessor: 'bankCode',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 70
            },
            {
                Header: "Account Number",
                accessor: 'retrievalReferenceNumber',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
            },
            {
                Header: "Customer Name",
                accessor: 'merchantCustomerName',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
            },
            {
                Header: "Amount",
                accessor: 'amount',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: props => props.value ? <span>&#x20A6;{formatCurrency(props.value * 0.01)}</span> : ''
            },
        ]

        return <React.Fragment> 
        <ReactTable 
            PaginationComponent={Pagination} 
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ''}
            style={{
                border: "none",
                boxShadow: "none",
                whiteSpace: 'unset',
                height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
            onFetchData={(state, instance) => {
                this.setState({page: state.page})
                if(this.props.canLoadData) {
                  this.props.getPayWithTransfer(state.page + 1, state.pageSize)
                }
              }}
            headerNodeHeight={`123`}
            minRows={(this.props.data).length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
                style: {
                  borderColor: '#00425f',
                  boxSizing: 'none',
                  border: 'none',
                },
              })}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                  display:"flex",
                  overflow: "visible",
                  color: "black",
                  fontSize: "10px",
                  fontWeight: "50",
                  paddingLeft:"5px",
                  paddingRight:"5px",
                  alignItems: "center",
                  padding:  "0px, 0",
                  
                },
              })}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                  backgroundColor: "#00425f",
                  borderBottom: "0.1px solid #DBDEDE",
                  borderWidth: "0.1px",
                  padding:"10px 0",
                  color: "#FFFFFF",
                  fontSize: "11px",
                  fontWeight: "100",
                  textAlign: "left",
                  paddingLeft:"5px",
                  paddingRight:"5px",
                },
              })}
              getPaginationProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  backgroundColor: '#ffffff',
                },
              })}    
              className="-striped -highlight" 
        />
    </React.Fragment>
    }
}