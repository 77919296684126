import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BaseScene from '../../../../../components/scenes/component';
import { CAN_MESSAGING_READ_TOPIC } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import MessagingHistoryGridScene from '../scenes/messaging-history-grid/scene';

export default class MessagingHistoryScene extends BaseScene {
  sceneName = 'messaging-history'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TOPIC]} exact path='/home/notifications/messaging-history' component={MessagingHistoryGridScene} />
    </Router>
  }
}
