import React from "react";
import "react-intl-tel-input/dist/main.css";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../../components/access-denied";
import UserForm from "../../../../../../../../components/forms/user-form";
import { GridLayout } from "../../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../../components/loader";
import Notification from "../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../../constants/api";
import {
  CAN_CREATE_AGENT_USER,
  CAN_CREATE_AGGREGATOR_USER,
  CAN_CREATE_SP_USER, CAN_CREATE_USERS
} from "../../../../../../../../constants/permissions";
import { platformManagement } from "../../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";
import { checkEmailIsValid } from "../../../../../../../../utils/validator";

export default class DomainUserAddScene extends BaseScene {
  requiredPermissions = [CAN_CREATE_USERS];

  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      emailIsValid: true,
      phoneIsValid: true,
      userFormData: [],
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
    this.updateUserFormData = this.updateUserFormData.bind(this);
  }

  componentDidMount() {
    this.domainCode = this.props.match.params.domainCode;
    this.updateUserFormData("domainCode", this.domainCode);
    this.getRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getRoles() {
    this.setState({ isGetRolesLoading: true });
    const responseObj = await platformManagement.getRoles(this.domainCode);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        roles: response,
        isGetRolesLoading: false,
      });
    } else {
      this.setState({
        roles: [],
        isGetRolesLoading: false,
        errorMessage: response,
      });
    }
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.createUser(
      this.state.userFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to create a new user has been sent successfully for approval.",
        });
      } else {
        this.setState({
          isLoading: false,
          successMessage: "User created successfully.",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  updateUserFormData(type, value) {
    this.setState({
      userFormData: { ...this.state.userFormData, [type]: value },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  isFormValid() {
    return Boolean(
      !this.state.userFormData.firstName ||
        !this.state.userFormData.lastName ||
        !this.state.userFormData.roleName ||
        !this.state.userFormData.email ||
        !this.state.userFormData.mobileNo ||
        !this.state.emailIsValid
    );
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={`/home/domain-management/${this.domainCode}/users`} />
      );
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent
          requiredPermissions={[
            CAN_CREATE_AGENT_USER,
            CAN_CREATE_AGGREGATOR_USER,
            CAN_CREATE_SP_USER,
          ]}
        >
          <button
            type="button"
            disabled={this.isFormValid()}
            class="btn btn-primary btn-sm"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ProtectedComponent
          requiredPermissions={[
            CAN_CREATE_AGENT_USER,
            CAN_CREATE_AGGREGATOR_USER,
            CAN_CREATE_SP_USER,
          ]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <UserForm
              onChange={this.updateUserFormData}
              updateSetState={this.updateSetState}
              roles={this.state.roles}
              userDetails={this.state.userDetails}
              checkEmailIsValid={checkEmailIsValid}
              emailIsValid={this.state.emailIsValid}
              phoneIsValid={this.state.phoneIsValid}
              userFormData={this.state.userFormData}
              title={"User Management"}
              submitButton={ctaWidgets}
              showPhoneNumber={true}
              isGetRolesLoading={this.state.isGetRolesLoading}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
