import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form } from '../../components/forms/form';
import { FormGroup } from '../../components/forms/form-group';
import { FormLayout } from '../../components/forms/form-layout';
import LandingBanner from '../../components/landing-banner';
import { GridItem } from '../../components/layouts/grid-item';
import { HorizontalGrid } from '../../components/layouts/grids/horizontal';
import { VerticalGrid } from '../../components/layouts/grids/vertical';
import Notification from '../../components/notification';
import { ERROR_STATUS } from '../../constants/api';
import { LOGIN } from '../../constants/routes';
import { RESET_EMAIL } from '../../constants/session-storage';
import { disabledStyle } from '../../constants/styles';
import { userManagement } from '../../mixins/api';
import { deleteOtherInformation, saveOtherInformation } from '../../mixins/authentication';
import { phoneNumberFormatter } from '../../utils/formatter';
import { checkEmailIsValid, checkPhoneIsValid } from '../../utils/validator';

export default class ForgotPasswordScene extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      emailIsValid: false,
      errorMessage: null,
      forgotPasswordFormData: {}
    }
  }

  componentDidMount() {
    deleteOtherInformation(RESET_EMAIL)
    this.setState({forgotPasswordFormData: {...this.state.forgotPasswordFormData, destinationUri: process.env.REACT_APP_RESET_PASSWORD_URL}})
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async requestPasswordReset () {
    this.setState({ isLoading: true })
    const getUserResponseObj = await userManagement.forgotPassword(this.state.forgotPasswordFormData)
    const getUserResponse = getUserResponseObj.response
    const getUserStatus = getUserResponseObj.status
    if (getUserStatus === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: getUserResponse
      })
      return
    }else if(getUserResponse.uuid) {
      this.setState({
        phoneUuid: getUserResponse.uuid,
        redirectPhone: true,
        redirect: false,
      })

    }else{
      saveOtherInformation(RESET_EMAIL, this.state.forgotPasswordFormData.username)
      this.setState({
        redirect: true,
        redirectPhone: false,
      })

    }
  }

  render() {
    if(this.state.redirectPhone) {
      return <Redirect to={`/reset-password-phone/${this.state.phoneUuid}`} /> 
    }else if (this.state.redirect) {
      return <Redirect to="/forgot-password-response" />
    }

    return <React.Fragment>
      <VerticalGrid isRoot>
        <HorizontalGrid isRoot class="kt-login kt-login--v1">
          <GridItem isFluid class="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <LandingBanner />
            <GridItem isFluid class="kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
              <FormLayout title={"Forgot Password"}>
                <div style={{textAlign: 'center', paddingBottom: '20px'}}>
                  <span>
                    Enter the email you've registered with.<br/>
                    We'll send you the instructions there.  
                  </span>
                </div>
                <Notification 
                  errorMessage={this.state.errorMessage}
                />
                <Form class="kt-form" action="" novalidate="novalidate">
                  <FormGroup>
                    <input disabled={this.state.isLoading} style={this.state.isLoading ? disabledStyle : {}} ref={input => this.usernameInput = input} id="username" class="form-control" type="text" placeholder="Email Address or Phone Number" name="username" autoComplete="off" onChange={(event) => this.setState({
                      emailIsValid: checkEmailIsValid(event.target.value) || checkPhoneIsValid(event.target.value),
                      forgotPasswordFormData: {...this.state.forgotPasswordFormData, username: phoneNumberFormatter(event.target.value)},
                    })} />
                    {(this.state.forgotPasswordFormData.username && !this.state.emailIsValid) && <span class="error">Invalid Email Address</span>}
                  </FormGroup>
                  <div class="kt-login__actions">
                    <button id="kt_login_signin_submit" disabled={this.state.isLoading || !this.state.forgotPasswordFormData || !this.state.emailIsValid} class="form-control btn btn-interswitch btn-elevate kt-login__btn-primary login-button" onClick={(e) => {
                      this.requestPasswordReset()
                    }}>{!this.state.isLoading ? `Reset Password` : <span class="loading">Loading</span>}</button>
                  </div>
                  <div class="external-link"><Link to={LOGIN}>Back to Login</Link></div>
                  {/* <a href={`${process.env.REACT_APP_ROUTE_BASENAME}/login`} style={{color: '#00425f', fontWeight: 'bold', fontSize: '15px'}}>Back to Login</a> */}
                </Form>
              </FormLayout>
            </GridItem>
          </GridItem>
        </HorizontalGrid>
      </VerticalGrid>
    </React.Fragment>
  }
}