import { convertToMajor } from '../../utils/converter';
import { formatCurrency, formatDateTime, formatLongNumber } from '../../utils/formatter';

export default class CommissionHistoryExportSerializer {
    static headerExport () {
        return [
            { label: 'Transaction Reference', key: 'transactionRef', title: 'Transaction Reference', dataIndex: 'transactionRef'},
            { label: 'Transaction Type', key: 'transactionType', title: 'Transaction Type', dataIndex: 'transactionType' },
            { label: 'Amount', key: 'transactionAmount', title: 'Amount', dataIndex: 'transactionAmount' },
            { label: 'Commission Amount', key: 'fee', title: 'Commission Amount', dataIndex: 'fee' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
            { label: 'Balance After', key: 'availableBalance', title: 'Balance After', dataIndex: 'availableBalance' },
            { label: 'Event', key: 'event', title: 'Event', dataIndex: 'event' },
            { label: 'Parent Reference', key: 'parentRef', title: 'Parent Reference', dataIndex: 'parentRef' },
            { label: 'Agent Phone Number', key: 'agentPhoneNumber', title: 'Agent Phone Number', dataIndex: 'agentPhoneNumber' },
            { label: 'Date', key: 'dateCreated', title: 'Date', dataIndex: 'dateCreated' }
        ];
    }

    static reverseCommissionHistoryDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
                transactionRef, transactionType, amount, fee, status, availableBalance, 
                event, agentPhoneNumber, dateCreated, parentRef
            } = detail

            let detailFormData = {
                event,
                transactionType,
                status,
                parentRef: parentRef ? formatLongNumber(parentRef) : '',
                transactionRef: transactionRef ? formatLongNumber(transactionRef) : '',
                agentPhoneNumber: agentPhoneNumber ? formatLongNumber(agentPhoneNumber) : '',
                fee: fee ? `₦${formatCurrency(convertToMajor(fee))}` : '',
                availableBalance: availableBalance ? `₦${formatCurrency(convertToMajor(availableBalance))}` : '',
                dateCreated: dateCreated ? formatDateTime(dateCreated) : '',
                amount: amount ? `₦${formatCurrency(convertToMajor(amount))}` : '',
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}