import React from "react";
import { sentenceCapitalization } from "../../utils/helper";

export const PromptModal = (props) => {
  const {
    modalTitle,
    modalBody,
    deleteModal,
    clearModal,
    modalSize,
    hideModalHeader,
    textAlign,
    modalFooterButton1,
    modalFooterButton2,
    modalFooterButton2Disabled,
    height,
    ref_,
  } = props;
  return (
    <React.Fragment>
      <div
        class="modal"
        id="kt_modal_1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        ref={ref_}
      >
        <div class={`modal-dialog ${modalSize}`} role="document">
          <div class="modal-content" style={{ borderRadius: "15px" }}>
            <div hidden={hideModalHeader} class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {modalTitle ? sentenceCapitalization(modalTitle) : ""}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              class="modal-body fluid"
              style={{ fontSize: "15px", height: height ? height : "100px" }}
            >
              {hideModalHeader && (
                <h4
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  {modalTitle ? sentenceCapitalization(modalTitle) : ""}
                </h4>
              )}
              <div style={{ textAlign: textAlign ? textAlign : "center" }}>
                <div>{modalBody}</div>
              </div>
              <div
                class="modal-footer row"
                style={{
                  paddingTop: "35px",
                  borderTop: "0 none",
                  textAlign: "center",
                }}
              >
                {modalFooterButton1 && (
                  <button
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      borderColor: "#F1F1F2 ",
                      backgroundColor: "#F1F1F2 ",
                      color: "#000000",
                      margin: "auto",
                      display: "block",
                      fontSize: "14px",
                    }}
                    class="btn btn-secondary btn-bold btn-sm btn-icon-h"
                    id="kt_subheader_group_actions_fetch"
                    data-dismiss="modal"
                    onClick={clearModal}
                  >
                    {modalFooterButton1}
                  </button>
                )}
                {modalFooterButton2 && (
                  <button
                    disabled={modalFooterButton2Disabled}
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      borderColor: "#00425f",
                      backgroundColor: "#00425f",
                      color: "#ffffff",
                      fontSize: "14px",
                      margin: "auto",
                      display: "block",
                    }}
                    class="btn btn-primary btn-bold btn-sm btn-icon-h"
                    id="kt_subheader_group_actions_fetch"
                    data-dismiss="modal"
                    onClick={deleteModal}
                  >
                    {modalFooterButton2}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
