import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { retrieveSessionInformation } from "../../mixins/authentication";

export default class LandingScene extends Component {
  userIsLoggedIn() {
    return retrieveSessionInformation();
  }

  render() {
    // BY-PASSING LOGIN
    // return <Redirect to="/home" />;

    if (!this.userIsLoggedIn()) {
      return <Redirect to={`/login`} />
    }

    else if (window.location.pathname === (process.env.REACT_APP_ROUTE_BASENAME || '/')) {
      return <Redirect to="/home" />
    }

    return <React.Fragment>

    </React.Fragment>
  }
}
