import React from 'react';
import { checkisPasswordMatch } from '../../../../../utils/validator';

export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          changePasswordFormData: [],
          isPasswordValid: false,
          doesPasswordMatch: false,
        }
        this.changePassword = this.changePassword.bind(this)
    }

    async changePassword () {
        this.props.changePassword(this.state.changePasswordFormData)
    }

    isFormValid () {
        return Boolean(!this.state.doesPasswordMatch || !this.state.isPasswordValid || !this.state.changePasswordFormData.oldPassword || !this.state.changePasswordFormData.password || !this.state.changePasswordFormData.confirmPassword)
    }

    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">Change Password<small>change your account password</small></h3>
                    </div>
                </div>
                <form class="kt-form kt-form--label-left"  id="kt_profile_form">
                    <div class="kt-portlet__body">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <div class="row">
                                    <label class="col-xl-3"></label>
                                    <div class="col-lg-9 col-xl-6">
                                        <h3 class="kt-section__title kt-section__title-sm">Change Or Recover Your Password:</h3>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Current Password</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input type="password" class="form-control" placeholder="Current password" onChange={event => this.setState({
                                            changePasswordFormData: {...this.state.changePasswordFormData, oldPassword: event.target.value},
                                            isPasswordValid: !checkisPasswordMatch(event.target.value, this.state.changePasswordFormData.password),
                                        })} />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">New Password</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input type="password" class="form-control" placeholder="New password" onChange={event => this.setState({
                                            changePasswordFormData: {...this.state.changePasswordFormData, password: event.target.value },
                                            isPasswordValid: !checkisPasswordMatch(this.state.changePasswordFormData.oldPassword, event.target.value),
                                            doesPasswordMatch: checkisPasswordMatch(event.target.value, this.state.changePasswordFormData.confirmPassword)
                                        })}/>
                                        {(this.state.changePasswordFormData.password && !this.state.isPasswordValid) && <span style={{color: 'red'}}>New password cannot be the same as old password</span>}
                                    </div>
                                </div>
                                <div class="form-group form-group-last row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">Verify Password</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input type="password" class="form-control" placeholder="Verify password" onChange={event => this.setState({
                                            changePasswordFormData: {...this.state.changePasswordFormData, confirmPassword: event.target.value},
                                            doesPasswordMatch: checkisPasswordMatch(this.state.changePasswordFormData.password, event.target.value)
                                        })} />
                                        {(this.state.changePasswordFormData.confirmPassword && !this.state.doesPasswordMatch) && <span style={{color: 'red'}}>The new password and confirmation password do not match</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot">
                        <div class="kt-form__actions">
                            <div class="row">
                                <div class="col-lg-3 col-xl-3">
                                </div>
                                <div class="col-lg-9 col-xl-9">
                                    <button type="button" disabled={this.isFormValid()} class="btn btn-brand btn-bold" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.changePassword}>Change Password</button>&nbsp;
                                    <button type="reset" class="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}