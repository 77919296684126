import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import EndOfDayLedgerReportsGridScene from './scenes/end-of-day-ledger-reports-grid';
import BaseScene from '../../../../../../../components/scenes/component';
import { isAuthorized } from '../../../../../../../mixins/authentication'
import { CAN_EOD_REPORT_VIEW } from '../../../../../../../constants/permissions.js'


export default class WalletManagementEndOfDayLedgerReports extends BaseScene {
  sceneName = 'wallet-management-end-of-day-leger-reports';
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_EOD_REPORT_VIEW]} exact path='/home/wallet-management-end-of-day-ledger-reports' component={EndOfDayLedgerReportsGridScene} />
    </Router>
  }
}
