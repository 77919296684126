import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { CAN_VIEW_AGENT_HIERARCHY_ROLE } from '../../../../../../../constants/permissions'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { explodeString } from '../../../../../../../utils/helper'
import Table from '../../../../../../../components/table';
import { userManagement } from '../../../../../../../mixins/api'
import { Link } from 'react-router-dom'
import { RolesExportSerializer } from '../../../../../../../serializers'

export default class AgentHierarchyRoleGridScene extends BaseScene {

  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
    },
    {
      Header: "Name",
      accessor: 'roleName',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
    },
    // {
    //   Header: "Description",
    //   accessor: 'description',
    //   resizable: false,
    //   headerStyle: {'white-space': 'unset'},
    //   style: {'white-space': 'unset'},
    // },
    {
      Header: "Action",
      accessor: 'id',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: '20px',
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 60,
      Cell: props => <div class="dropdown">
        <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
          <i class="la la-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_ROLE]}><Link to={`${this.domainType}/${props.value}`} class="dropdown-item">
            <i class="la la-eye"></i>
            View 
          </Link></ProtectedComponent>
          {/* <ProtectedComponent requiredPermissions={[CAN_CREATE_AGENT_HIERARCHY_ROLE]}><a href={`/home/role-management/add?roleId=${props.value}`} class="dropdown-item">
            <i class="la la-copy"></i>
            Create a copy
          </a></ProtectedComponent> */}
        </div>
      </div>
    }
  ]
    
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			successMessage: null,
      errorMessage: null,
      roles: []
    }
    this.getDomainTypeRoles = this.getDomainTypeRoles.bind(this)
  }

  componentDidMount () {
    this.domainType = this.props.match.params.id
    this.getDomainTypeRoles()
  }

  async getDomainTypeRoles () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.getDomainTypeRoles(this.getDomainTypeId(this.domainType))
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
        this.setState({
            roles: response,
            isLoading: false,
        })
    }else{
        this.setState({
            roles: [],
            errorMessage: response,
            isLoading: false,
        })
    }
  }

  splitString (string) {
    return string.split("-");
  }

  getDomainTypeId (domainType) {
    const domainTypeName = `${this.splitString(domainType)[0]}${this.splitString(domainType)[1] ? this.splitString(domainType)[1] : ''}`
    const domainTypes = {"platformprovider": 1, "superagent": 2, "aggregator": 3, "agent": 4}
    return domainTypes[domainTypeName]
  }

  render () {
        return <React.Fragment>
            <Loader 
                isLoading={this.state.isLoading}
            />
            <Notification
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"Agent Hierarchy Management"} subtitle={`${this.domainType ? explodeString(this.domainType, '-') : ''} Roles`} withoutSearch /*actionButtonText={"Add Role"} actionButtonUrl={"/home/agent-hierarchy/manage-roles/add"} actionButtonRequiredPermissions={[CAN_CREATE_AGENT_HIERARCHY_ROLE]}*/ />
            <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_ROLE]}>
                <GridLayout>
                    {/* <Table 
                        columns={this.columns}
                        data={this.state.roles} 
                        loading={this.state.isLoading} 
                        noDataText={`No roles found`}
                        dataCount={this.state.dataCount}
                        getData={this.getDomainTypeRoles}
                        disabled={true}
                        //exportData={RolesExportSerializer.reverseRoleDetails(this.state.roles)}
                        //exportHeaders={RolesExportSerializer.headerExport()}
                        //exportFileName={'roles'}
                    /> */}
                    <Table 
                      columns={this.columns}
                      data={this.state.roles} 
                      loading={this.state.isLoading} 
                      noDataText={`No roles found`}
                      dataCount={this.state.dataCount}
                      getData={this.getDomainTypeRoles}
                      exportData={RolesExportSerializer.reverseRoleDetails(this.state.roles)}
                      exportHeaders={RolesExportSerializer.headerExport()}
                      exportFileName={'Roles'}
                    />
                </GridLayout>				
            </ProtectedComponent>
        </React.Fragment>
    }

    get permissionDeniedContent() {
        return <AccessDenied />
    }
}
