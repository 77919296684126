import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import Table from '../../../../../../../components/table';
import { CAN_CREATE_LGA, CAN_VIEW_LGA } from '../../../../../../../constants/permissions.js';
import Lgas from '../../../../../../../fixtures/lgas';
import States from '../../../../../../../fixtures/states';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { LgaExportSerializer } from '../../../../../../../serializers';
import { getLgasByStateId, getStateName } from '../../../../../../../utils/helper';
import LgaSearch from '../../components/lga-search';

const columns = [
  {
    Header: "S/N",
    accessor: 'id',
    Cell: (id) => <span>{id.index + 1}</span>,
    sortable: false,
    resizable: false,
    width: 60,
    headerStyle: {'white-space': 'unset'},
  },{
    Header: "Name",
    accessor: 'name',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
  },{
    Header: "Code",
    accessor: 'code',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
  },{
    Header: "State",
    accessor: 'stateId',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
    Cell: props => getStateName(1, props.value)
  }
  // ,{
  //   Header: "Action",
  //   accessor: 'id',
  //   overflow: 'visible',
  //   resizable: false,
  //   sortable: false,
  //   headerStyle: {'white-space': 'unset'},
  //   getProps: (state, rowInfo, column) => {
  //     return {
  //       style: {
  //         lineHeight: '20px',
  //         textAlign: 'center',
  //         whiteSpace: 'unset',
  //       },
  //     };
  //   },
  //   width: 100,
  //   Cell: ({row, original}) => <div class="dropdown">
  //     <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
  //       <i class="la la-ellipsis-v"></i>
  //     </a>
  //     <div class="dropdown-menu dropdown-menu-right">
  //       <ProtectedComponent requiredPermissions={[CAN_VIEW_LGA]}><Link to={`system-configuration/lga-management/${row.id}/view`} class="dropdown-item">
  //         <i class="la la-eye"></i>
  //         View
  //       </Link></ProtectedComponent>
  //       {/* <a href={`/home/system-configuration/lga-management/${row.id}/delete`} class="dropdown-item">
  //         <i class="la la-trash"></i>
  //         Delete
  //       </a> */}
  //     </div>
  //   </div>
  // }
]

export default class LgaGridScene extends BaseScene {
  
  sceneName = 'lga-management'

  state = {
    lgas: [],
    searchParameters: {
      state: ""
    }
  }

  getLgas = this.getLgas.bind(this)

  componentDidMount() {
    this.getLgas()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getLgas (stateId = "") {
    this.setState({
      isLoading: true,
      searchParameters: {...this.state.searchParameters, state: stateId}
    })
    setTimeout(() => {
      if(stateId == "") {
        this.setState({
          lgas: Lgas,
          isLoading: false,
          dataCount: Lgas.length,
        })
      }else{
        this.setState({
          lgas:  getLgasByStateId(stateId),
          isLoading: false,
          dataCount: getLgasByStateId(stateId).length,
        })
      }
    }, 2000)
  }

  render () {
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Local Government"} filterPermissions={[CAN_VIEW_LGA]} actionButtonText={"Add Local Government"} actionButtonUrl={"/home/system-configuration/lga-management/add"} totalCount={56} actionButtonRequiredPermissions={[CAN_CREATE_LGA]} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_LGA]}>
        <GridLayout>
          <LgaSearch 
            states={States}
            getLgas={this.getLgas}
            searchParameters={this.state.searchParameters}
          />
          <Table 
            columns={columns} 
            data={this.state.lgas} 
            loading={this.state.isLoading}
            noDataText={`No lga found`}
            dataCount={this.state.dataCount}
            getData={this.getLgas}
            disabled={true}
            exportData={LgaExportSerializer.reverseLgaDetails(this.state.lgas)}
            exportHeaders={LgaExportSerializer.headerExport()}
            exportFileName={'LGAs'}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
