import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '../../../components/forms/form';
import { FormGroup } from '../../../components/forms/form-group';
import { FormLayout } from '../../../components/forms/form-layout';
import Notification from '../../../components/notification';
import { LOGIN } from '../../../constants/routes';
import { disabledStyle } from '../../../constants/styles';
import { checkisPasswordMatch, checkPasswordLength } from '../../../utils/validator';

export const ChangePasswordForm = (props) => {
  const { 
    isLoading, successMessage, errorMessage, isPasswordValid, isPasswordNew, 
    isPasswordMatch, updateChangePasswordFormData, updateSetState, changePassword, 
    changePasswordFormData: { oldPassword, password, confirmPassword } 
  } = props;
  return <>
    <FormLayout title={"Change Password"}>
      <div style={{textAlign: 'center'}}>
        <span>
          Please choose a new password to finish signing in.
        </span>
      </div>
      <Notification 
        successMessage={successMessage}
        errorMessage={errorMessage}
      />
      <Form class="kt-form" action="" novalidate="novalidate">
        <FormGroup>
          <input disabled={isLoading} style={isLoading ? disabledStyle : {}} id="username" class="form-control" type="password" placeholder="Old Password" name="password" onChange={(event) => {
            updateChangePasswordFormData('oldPassword', event.target.value)
            updateSetState('isPasswordNew', !checkisPasswordMatch(event.target.value, password))
          }} autoComplete="off" />
        </FormGroup>  
        <FormGroup>
          <input disabled={isLoading} style={isLoading ? disabledStyle : {}} id="password" class="form-control" type="password" placeholder="New Password" name="password" onChange={(event) => {
            updateChangePasswordFormData('password', event.target.value)
            updateSetState('isPasswordValid', checkPasswordLength(event.target.value))
            updateSetState('isPasswordMatch', checkisPasswordMatch(confirmPassword, event.target.value))
            updateSetState('isPasswordNew', !checkisPasswordMatch(event.target.value, oldPassword))
          }} autoComplete="off" />
          {(password && !isPasswordValid) && <span class="error">Choose a strong password</span>}
          {(password && !isPasswordNew) && <span class="error">Choose a new password</span>}
        </FormGroup>
        <FormGroup>
          <input disabled={isLoading} style={isLoading ? disabledStyle : {}} id="password2" class="form-control" type="password" placeholder="Confirm Password" name="confirmPassword" onChange={(event) => {
            updateChangePasswordFormData('confirmPassword', event.target.value)
            updateSetState('isPasswordMatch', checkisPasswordMatch(password, event.target.value))
          }} autoComplete="off" />
          {(confirmPassword && !isPasswordMatch) && <span class="error">Password does not match</span>}
        </FormGroup>
        <div class="kt-login__actions">
          <button id="kt_login_signin_submit" disabled={isLoading || !isPasswordMatch || !isPasswordValid || !isPasswordNew} class="form-control btn btn-interswitch btn-elevate kt-login__btn-primary login-button" onClick={(e) => {
            e.preventDefault()  
            changePassword()
          }}>{!isLoading ? `Change Password` : <span class="loading">Loading</span>}</button>
        </div>
        <div class="external-link"><Link to={LOGIN}>Back to Login</Link></div>
      </Form>
    </FormLayout>
  </>
}