import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  CAN_UPDATE_COMMISSION_SPLIT_RULE,
  CAN_VIEW_COMMISSION_SPLIT_RULE,
} from "../../../../../../../constants/permissions.js";
import AccountTypes from "../../../../../../../fixtures/account_types.json";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import RevenuePartyForm from "../../components/revenue-sharing-form";

export default class ViewRevenueSharingScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "View Revenue Party",
      canSave: false,
      canUpdate: false,
      disabled: true,
      accountTypes: [],
      partyFormData: {},
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.onUpdatePartyFormData = this.onUpdatePartyFormData.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  componentDidMount() {
    this.setState({ accountTypes: AccountTypes });
    this.revenueSharingId = this.props.match.params.id;
    this.getPartyDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getPartyDetails() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getRevenueSharingParty(
      this.revenueSharingId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        partyFormData: response,
        canUpdate: true,
        isLoading: false,
      });
    } else {
      this.setState({
        partyFormData: [],
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  async onUpdateButtonClick() {
    this.setState({
      disabled: false,
      canSave: true,
      canUpdate: false,
      pageTitle: "Update Revenue Party",
    });
  }

  async onCancelButtonClick() {
    this.setState({
      disabled: true,
      canSave: false,
      canUpdate: true,
      pageTitle: "View Revenue Party",
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.updateRevenueSharingParty(
      this.revenueSharingId,
      this.state.partyFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your changes to this revenue sharing party has been sent successfully for approval.",
          pageTitle: "View Revenue Party",
          canSave: false,
          canUpdate: true,
          disabled: true,
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          pageTitle: "View Revenue Party",
          canSave: false,
          canUpdate: true,
          disabled: true,
          successMessage: "Revenue sharing party updated successfully.",
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  onUpdatePartyFormData(type, value) {
    this.setState({
      partyFormData: { ...this.state.partyFormData, [type]: value },
    });
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <ProtectedComponent
            requiredPermissions={[CAN_UPDATE_COMMISSION_SPLIT_RULE]}
          >
            <button
              type="button"
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onUpdateButtonClick}
            >
              Edit
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent
            requiredPermissions={[CAN_UPDATE_COMMISSION_SPLIT_RULE]}
          >
            <button
              type="button"
              class="btn btn-secondary btn-bold btn-sm btn-icon-h"
              id="kt_subheader_group_actions_fetch"
              onClick={this.onCancelButtonClick}
            >
              Cancel
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent
            requiredPermissions={[CAN_UPDATE_COMMISSION_SPLIT_RULE]}
          >
            <button
              type="button"
              disabled={!this.state.partyFormData.name}
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onSaveButtonClick}
            >
              Save Changes
            </button>
          </ProtectedComponent>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Revenue Sharing Parties"}
          withoutSearch
          subtitle={this.state.pageTitle}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]}
        >
          <GridLayout>
            <RevenuePartyForm
              onChange={this.onUpdatePartyFormData}
              accountTypes={this.state.accountTypes}
              partyFormData={this.state.partyFormData}
              disabled={this.state.disabled}
              disableName={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
