import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CAN_VIEW_AGENT } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import AgentBVNGridScene from './scenes/agent-bvn-grid';

export default class AgentBVNScene extends Component {
    sceneName = 'agent-management'
    
    componentDidMount () {
      this.props.updateScene(this.sceneName)
    }
  
    render () {
      const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
        return (
          <Route {...rest} render={props => (
            isAuthorized(requiredPermissions) ?
              <Component {...props} />
              : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
          )} />
        )
      }
      return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/agent-bvn-management' component={AgentBVNGridScene} />
      </Router>
    }
  }
  