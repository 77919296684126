import React from "react";
import Loader from "../../../../../components/loader";
import Notification from "../../../../../components/notification";
import { SUCCESS_STATUS } from "../../../../../constants/api";
import { transactionService } from "../../../../../mixins/api";



const QT = "QT_RAIL_SWITCH";
const NIP = "NIP_RAIL_SWITCH";
const ROUTE = "ROUTING_SWITCH";
export default class ManualRouting extends React.Component {
  state = {
    routeStatus: this.props.activeRoute,
    errorMessage: "",
    successMessage: "",
    isVisible: false,
  };
  componentDidMount() {
    this.getRoutes();
  }

async getRoutes() {
  const activeRoute = await transactionService.getActiveRoute();
  let activeRouteValue = "ROUTING_SWITCH";
  if(activeRoute.status === SUCCESS_STATUS && activeRoute.response.length > 0){
    for(let i = 0; i < activeRoute.response.length; i++){
      if(activeRoute.response[i].selected === true){
        activeRouteValue = activeRoute.response[i].serviceName
      }
    }

    this.setState({routeStatus: activeRouteValue})
  }
}
  async saveRoute() {
    const routeStatus = this.state.routeStatus;
    const payload = [
      {
          "serviceName": QT,
          "selected": routeStatus === QT,
          "switchStatus": routeStatus === QT
      },
      {
          "serviceName": NIP,
          "selected": routeStatus === NIP,
          "switchStatus": routeStatus === NIP
      },
      {
          "serviceName": ROUTE,
          "selected": routeStatus === ROUTE,
          "switchStatus": routeStatus === ROUTE
      }
  ];
    this.setState({
      isLoading: true
    });
    
    const res = await transactionService.saveManualRouting(payload);

    //Sample save route pending till api is ready
    if (res.status===SUCCESS_STATUS) {
      this.setState({
        successMessage:
        "Route successfully changed to " + this.state.routeStatus,
        isVisible: true,
        isLoading: false,
      });
      setTimeout(() => {
        this.setState({ isVisible: false });
      }, 3000);
    }
    else{
      this.setState({
        errorMessage:
        res.description || "Something went wrong",
        isVisible: true,
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
      <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={
            this.state.isVisible === true ? this.state.successMessage : null
          }
          errorMessage={
            this.state.isVisible === true ? this.state.errorMessage : null
          }
        />
        <div
          style={{
            fontSize: "16px",
            marginBottom: "20px",
          }}
        >
          Routing Switch{" "}
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            className="radio d-flex "
            style={{
              gap: "50px",
              padding: "6px",
              border: "2px solid #87CEEB",
              width: "300px",
              marginBottom: "50px",
              fontSize: "16px",
              color: "#00425f",
              display: "block",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                value={QT}
                checked={this.state.routeStatus === QT}
                onChange={(e) => this.setState({ routeStatus: e.target.value })}
              />
              <div>QT</div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  value={NIP}
                  checked={this.state.routeStatus === NIP}
                  onChange={(e) =>
                    this.setState({ routeStatus: e.target.value })
                  }
                />
                <div>NIP</div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  value={ROUTE}
                  checked={this.state.routeStatus === ROUTE}
                  onChange={(e) =>
                    this.setState({ routeStatus: e.target.value })
                  }
                />
                <div>Routing</div>
              </div>
            </div>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#00425f",
                color: "#ffffff",
                padding: "7px",
                marginLeft: "20px",
                borderRadius: "15px",
              }}
              disabled={this.state.isLoading}
              onClick={() => this.saveRoute()}
            >
              Save Route
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
