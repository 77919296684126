import React from "react";

export default function RoutingCard(props) {
  return (
    <>
      <div class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-widget14">
          <div class="kt-widget14__header">
            <h3 class="kt-widget14__title">Transfer Routing Board</h3>
            <span class="kt-widget14__desc">Route score</span>
          </div>

          {props.isLoading !== true ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h3> {props.qtRouteScore.transactionType && "QT"}</h3>

                  <div class="">
                    <p
                      class="text-center"
                      style={{
                        color: "rgb(255, 99, 132)",
                        fontWeight: "bold",
                      }}
                    >
                      {props.qtRouteScore.score}
                    </p>
                  </div>
                </div>

                <div>
                  <h3>{props.nipRouteScore.transactionType}</h3>
                  <div class="">
                    <p
                      class="text-center"
                      style={{
                        color: "rgb(53, 162, 235)",
                        fontWeight: "bold",
                      }}
                    >
                      {props.nipRouteScore.score}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class=""
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: 30,
                  }}
                >
                  <h4>
                    Current route{" "}
                    <i class="fa fa-arrow-right" style={{ fontSize: 16 }}></i>
                  </h4>
                  <p style={{ fontWeight: "bold", fontSize: 18 }}>
                    {props.theRoute.activeRoute === "TRANSFER"
                      ? "QT"
                      : props.theRoute.activeRoute}
                    <span
                      style={{ fontSize: 12, marginLeft: 5 }}
                      class="metric-blinker"
                    >
                      active
                    </span>
                  </p>
                </div>
              </div>
              {/* Current route ends here */}
            </>
          ) : (
            <small class="loading">Loading route</small>
          )}
        </div>
      </div>
    </>
  );
}
