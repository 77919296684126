import React from 'react'
import Select from '../../../../../../components/select'

export default class ExternalFeeSearch extends React.Component {
    state = {
        searchParams: {
            status: "All"
        },
        statuses: [
            { value: "All", label: "All" },
            { value: 0, label: "Active" },
            { value: 1, label: "Inactive" }
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest(this.state.searchParams)
    }

    
    render() {

        return <React.Fragment>
            <div class="search-params">
                <form onSubmit={this.handleSearchRequest}>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label class="search-label">Biller Name :</label>
                            <input type="text" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, billerName: event.target.value}})} />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Service Option Name :</label>
                            <input type="text" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, serviceOptionCodeName: event.target.value}})} />
                        </div>
                        <div class="col-md-2">
                            <label class="search-label">Paydirect Item Code :</label>
                            <input type="text" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, paydirectItemCode: event.target.value}})} />
                        </div>
                        <div class="col-md-2">
                            <label class="search-label">Status :</label>
                            <Select 
                                options={this.state.statuses}
                                onChange={(event) => this.setState({searchParams: {...this.state.searchParams, status: event.value}})}
                                defaultValue={this.state.searchParams.status}
                            />
                            {/* <select type="text" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, status: event.target.value}})} >
                                {this.state.statuses.map(value => <option value={value.id}>{value.name}</option>)}
                            </select> */}
                        </div>
                        <div class="col-md-2">
                            <label class="search-label">&nbsp;&nbsp;</label>
                            <button type="submit" class="search-button">Search</button>
                        </div>
                    </div>
                </form>
                <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="btn-group ml-12" role="group" aria-label="First group">
                        <div class="input-group">
                            <button id="basic-addon1" type="button" class="btn btn-secondary" disabled={this.props.checkedExternalFees.length > 1 ? false : true} style={{marginLeft: '10px', 'backgroundColor': '#00425f', 'borderColor':'#00425f', 'color': '#ffffff'}} onClick={() => this.props.bulkBlacklistExternalFees()}>Bulk Blacklist</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}