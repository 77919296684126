import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
	FormInput,
	FormTextArea
} from '../../../../../../components/form-controls/form-input';
import { platformManagement } from '../../../../../../mixins/api';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';

const REGEX_FOR_PHONE_NUMBER_VALIDATION = /^[0]\d{10}$/;
const PHONE_NUMBER_VALIDATION_RESULTS = {};

function phoneNumberValidation(value, setIsLoading, setUserData) {
	
	if (!REGEX_FOR_PHONE_NUMBER_VALIDATION.test(value)) {
		return 'Phone number is not valid';
	}

	const validationResultForValue = PHONE_NUMBER_VALIDATION_RESULTS[value];
	if (validationResultForValue === true) {
		return;
	} else if (typeof validationResultForValue === 'string') {
		return validationResultForValue;
	}

	setIsLoading(true);

	return platformManagement
		.getAgentByBusinessPhoneNo(`234${value.slice(1,)}`)
		.then(({response, status}) => {
			setIsLoading(false);
			if (status === ERROR_STATUS) {
				const errorMsg = 'Agent with phone number does not exist.';
				PHONE_NUMBER_VALIDATION_RESULTS[value] = errorMsg;
				return errorMsg;
			}
			if (status === SUCCESS_STATUS) {
				if (response.walletVersion !== 2) {
					const errorMsg = "Agent's wallet does not support Lien.";
					PHONE_NUMBER_VALIDATION_RESULTS[value] = errorMsg;
					return errorMsg;
				}
			}
			setUserData(response);
			PHONE_NUMBER_VALIDATION_RESULTS[value] = true;
		});
}


export default function AddLienForm(props) {
    const { 
		hasFormBeenSubmitted, innerRef, title, submitButton, onSubmit,
    } = props;

		const [isLoading, setIsLoading] = useState(false);
		const [userData, setUserData] = useState({});

		const validationSchema = Yup.object().shape({
			amount: Yup.number('Field must be a number')
				.min(10, 'Value is too small')
				.required('Field is required'),
			narration: Yup.string()
				.min(3, 'Field is too short')
				.required('Field is required'),
		});

    return <React.Fragment>
			<div class="kt-portlet">
				{title && <div class="kt-portlet__head">
					<div class="kt-portlet__head-label">
						<h3 class="kt-portlet__head-title">{title}</h3>
					</div>
					{submitButton && <div class="kt-portlet__head-label">
						{submitButton}
					</div>}
				</div>}
				<Formik
					initialValues={{}}
					innerRef={innerRef}
					isInitialValid={true}
					onSubmit={(values, actions) => {
						onSubmit({...values, userData});
					}}
					validationSchema={validationSchema}
				>
					<Form class="kt-form kt-form--label-right" id={'add-lien-form'}>
						<div class="kt-portlet__body">
							<Field
								component={(props) => <FormInput
									{...props}
									isLoading={isLoading}
									showErrorIfAvailable={true}
									subMessage={userData.businessName}
								/>}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Agent Phone Number"
								name="customerMsisdn"
								validate={(value) => phoneNumberValidation(value, setIsLoading, setUserData)}
								validateOnBlur={true}
								validateOnChange={true}
							/>
							<Field
								component={FormInput}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Amount"
								name="amount"
							/>
							<Field
								component={FormTextArea}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Reason for Lien"
								name="narration"
							/>
							
						</div>
					</Form>
				</Formik>
			</div>
    </React.Fragment>
}
