import React from 'react';
import ContentHead from '../../../../../../../../../components/content-head';
import BaseScene from '../../../../../../../../../components/scenes/component';
import WalletPoolTable from'../../components/wallet-pool-table';
import { CAN_WALLET_STMT_VIEW } from '../../../../../../../../../constants/permissions.js';
import { SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../../../components/access-denied'
import { formatBackEndDateTime } from '../../../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../../../utils/helper';
import { transactionHistory} from '../../../../../../../../../mixins/api'
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import WalletPoolSearch from'../../components/wallet-pool-search'
import Notification from '../../../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../../../mixins/protected-component';

export default class WalletPoolStatementsGridScene extends BaseScene {
  
  sceneName = 'wallet-management-wallet-pool-statements'

  transactionDetailsSectionRef = React.createRef(null)
  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      walletPools: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
        ledgerAccountCode: "",
      },
      pages: 1,
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      pageNum: 1,
      pageSize: 20,
      tableMessage: "",
      isAccountNamesLoading: false,
      accountNames: [],
    }
    this.getWalletPoolStatements = this.getWalletPoolStatements.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this)
  }
  
  componentDidMount() {
    this.getAccountNames()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getWalletPoolStatements(pageNum, pageSize) {
    const {
      endDate,
      startDate,
      walletJournalTypeId,
      ledgerAccountCode,
     } = this.state.searchParameters

     this.setState({
       canLoadData: true,
       isLoading: true,
    });
    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate);
    const { status, response, count } = (
      await transactionHistory.retrieveWalletPoolStatements(
        pageNum, pageSize, formattedEndDate, formattedStartDate,
        walletJournalTypeId,ledgerAccountCode,
      )
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        walletPools: response ? response : [],
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count,
        isLoading: false,
      })
    } else {
      this.setState({
        walletPools: [],
        dataCount: 0,
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No wallet pool statement found"
      })
    }
  }

  updateSearchParameters (type, value) {
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  async getAccountNames () {
    this.setState({ isAccountNamesLoading: true })
    const { status, response } = (
      await transactionHistory.retrieveTrialBalanceReport()
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        accountNames: response ? response.ledgerBalances : [],
        isAccountNamesLoading: false
      })
    } else {
      this.setState({
        accountNames:[],
        isAccountNamesLoading: false
      })
    }
  }

  render () {
    return <React.Fragment>
      <ContentHead title={"Wallet Management"} subtitle={"Wallet Pool Statements"} filterPermissions={[CAN_WALLET_STMT_VIEW]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_WALLET_STMT_VIEW]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <WalletPoolSearch 
            searchParameters={this.state.searchParameters}
            updateSearchParameters={this.updateSearchParameters}
            handleSearchRequest={this.getWalletPoolStatements}
            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
            accountNames={this.state.accountNames}
            isAccountNamesLoading={this.state.isAccountNamesLoading}
          />
          <WalletPoolTable 
            pages={this.state.pages}
            getWalletPoolStatements={this.getWalletPoolStatements}
            data={this.state.walletPools}
            startPage={this.state.startPage}
            viewMore={this.getTransactionDetails}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            page={this.state.pageNum}
            pageSize={this.state.pageSize}
            searchParameters={this.state.searchParameters}
            responseCode={this.state.searchParameters.responseCode}
            startDate={this.state.searchParameters.startDate}
            canView={CAN_WALLET_STMT_VIEW}
            canLoadData={this.state.canLoadData}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
