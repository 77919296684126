import React from 'react'

export default class FeeSplits extends React.Component {
    render () {
        return <React.Fragment>
            <div class="kt-portlet">
                <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne2" aria-controls="collapseOne2">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                        <h3 class="kt-portlet__head-title">
                            Fee Split Details
                        </h3>
                    </div>
                </div>
                {this.props.isLoading && <span class="loading">Loading</span>}
                {!this.props.isLoading && <form class="kt-form kt-form--label-right" id="collapseOne2" aria-labelledby="headingOne1">
                    <div class="kt-portlet__body">
                        <table class="table table-hover">
                            <thead class="thead-light">
                                <th>Sharing Party</th>
                                <th>Quota (%)</th>
                                <th>Unsettled Commission (&#x20A6;)</th>
                                <th>Account Type</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                {this.props.feeSplits.map(value =>
                                    <tr>
                                        <td>{value.agentName}</td>
                                        <td>{parseFloat(value.agentQuota).toFixed(1)}</td>
                                        <td>{(value.unsettledCommission / 100).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                        <td>{value.accountType}</td>
                                        <td>{value.settled === true ? 'Settled' : 'Unsettled'}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </form>}
            </div>
        </React.Fragment>
    }
}