import React from 'react';
import ContentHead from '../../../../components/content-head';
import { GridItem } from '../../../../components/layouts/grid-item';
import MapComponent from '../../../../components/map';
import BaseScene from '../../../../components/scenes/component';
import {
  ACTIVE,
  DORMANT,
  INACTIVE,
  NEW,
  RETIRED,
} from '../../../../constants/agent-activities';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../constants/api';
import { AGENT_TYPE_ID, SUPER_AGENT } from '../../../../constants/domain-types';
import {
  CAN_DISTRIBUTE_WALLET,
  CAN_VIEW_DASHBOARD,
} from '../../../../constants/permissions';
import { TRANSFER } from '../../../../constants/transaction-types';
import {
  platformManagement,
  settlementService,
  transactionHistory,
  transactionService,
  feeConfigurationManagement,
} from '../../../../mixins/api';
import { retrieveSessionInformation } from '../../../../mixins/authentication';
import { convertToMajor } from '../../../../utils/converter';
import {
  formatBackEndDateTime,
  formatCurrency,
  formatDailyTimeRange,
  formatDateTime2,
  formatNumber,
} from '../../../../utils/formatter';
import { mapJson, toPermissionArray } from '../../../../utils/helper';
import CardComponent from './components/cards/card';
import MapCard from './components/cards/map-card';
import BarChart from './components/charts/bar-chart';
import DoughnutChart from './components/charts/doughnut-chart';
import LineChart from './components/charts/line-chart';
import FeeConfigurationManagement from '../../../../services/api/resources/fee-configuration-management';

export default class DashboardScene extends BaseScene {
  requiredPermissions = [CAN_VIEW_DASHBOARD];

  currentUser = retrieveSessionInformation();
  userPermissions = this.currentUser.user.permissions
    ? toPermissionArray(this.currentUser.user.permissions)
    : [];

  constructor(props) {
    super(props);
    this.state = {
      agents: null,
      dailyTransactionsCount: 0,
      dailyTransactionsVolume: 0,
      agentsCount: 0,
      agent: [],
      reloadWalletBalance: false,
      walletBalance: 0,
      commissionBalance: 0,
      unsettledBalance: 0,
      loadingActivityCount: true,
      loadingChartData: true,
      loadingVolumeData: true,
      loadingDiscos: true,
      showWeeklyChart: false,
      weeklyBillsVolume: [],
      weeklyRechargeVolume: [],
      weeklyTransferVolume: [],
      chartData: [],
      agentActivityCount: [],
      transactionsVolume: [],
      buypowerDiscos: [],
    };
    this.getAllBalances = this.getAllBalances.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.getTransactionsCount = this.getTransactionsCount.bind(this);
    this.getWeeklyTransactionsCount = this.getWeeklyTransactionsCount.bind(
      this
    );
    this.getBuyPowerDiscos = this.getBuyPowerDiscos.bind(this);
    this.getAgentClasses = this.getAgentClasses.bind(this);

  }
  sceneName = 'dashboard';

  componentDidMount() {
    this.props.updateScene(this.sceneName);
    this.getAgents();
    this.getTransactionsCount();
    //this.getDailyTransactionsCount()
    if (
      this.currentUser.user.domainType &&
      this.currentUser.user.domainType.toUpperCase() === SUPER_AGENT
    ) {
      this.getWalletBalance();
      this.getCommissionBalance(this.currentUser.mobileNo);
    }
    this.getWeeklyTransactionsCount();
    this.getBuyPowerDiscos();
    this.getAgentClasses();
  }

  async getAgentClasses() {
    const responseObj = await platformManagement.getAgentClasses()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      localStorage.setItem('agentClasses', JSON.stringify(response))
    } else {
      console.log('Not available')
    }
  }

  async getAgents() {
    this.setState({
      isLoading: true,
      loadingActivityCount: true,
      reloadAgentsCount: false,
    });
    const agentResponseObj = await platformManagement.getDomains(AGENT_TYPE_ID);
    const { status, response, count } = agentResponseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreAgents(count);
    } else {
      this.getAgentActivityCount([]);
      this.setState({
        agents: [],
        agentsCount: 0,
        isLoading: false,
        loadingActivityCount: false,
        reloadAgentsCount: true,
      });
    }
  }

  async getMoreAgents(pageSize) {
    this.setState({
      isLoading: true,
      loadingActivityCount: true,
    });
    const agentResponseObj = await platformManagement.getDomains(
      AGENT_TYPE_ID,
      null,
      pageSize
    );
    const { status, response } = agentResponseObj;
    if (status === SUCCESS_STATUS) {
      this.getAgentActivityCount(response);
      this.setState({
        agents: mapJson(response),
        agentsCount: response.length,
        isLoading: false,
        loadingActivityCount: false,
        reloadAgentsCount: false,
      });
    } else {
      this.getAgentActivityCount([]);
      this.setState({
        agents: [],
        agentsCount: 0,
        isLoading: false,
        loadingActivityCount: false,
        reloadAgentsCount: true,
      });
    }
  }

  async getDailyTransactionsCount(startDate, endDate) {
    const getCount = (response) =>
      typeof response === 'string'
        ? [0]
        : response.status === ERROR_STATUS
          ? [0]
          : response.length == undefined
            ? [0]
            : response.map((data) => convertToMajor(data.count ? data.count : 0));
    const getVolume = (response) =>
      typeof response === 'string'
        ? [0]
        : response.status === ERROR_STATUS
          ? [0]
          : response.length == undefined
            ? [0]
            : response.map((data) => convertToMajor(data.volume ? data.volume : 0));
    const formattedStartDate = formatBackEndDateTime(startDate);
    const formattedEndDate = formatBackEndDateTime(endDate);
    const transactions = await this.getTransactionsCountByType(
      formattedStartDate,
      formattedEndDate,
      3
    );

    this.setState({
      dailyTransactionsCount: JSON.parse(getCount(transactions.response)),
      dailyTransactionsVolume: JSON.parse(getVolume(transactions.response)),
    });
  }

  // async getAgentActivityCount(agents) {
  //   const agentCount = agents?.length;
  //   const newAgents =
  //     agentCount > 0 ? await this.getAgentStatusCount(agents, NEW) : 0;
  //   const activeAgents =
  //     agentCount > 0 ? await this.getAgentStatusCount(agents, ACTIVE) : 0;
  //   const inactiveAgents =
  //     agentCount > 0 ? await this.getAgentStatusCount(agents, INACTIVE) : 0;
  //   const retiredAgents =
  //     agentCount > 0 ? await this.getAgentStatusCount(agents, RETIRED) : 0;
  //   const dormantAgents =
  //     agentCount > 0 ? await this.getAgentStatusCount(agents, DORMANT) : 0;

  //   const agentActivityCount = [
  //     { label: 'New', value: newAgents },
  //     { label: 'Active', value: activeAgents },
  //     { label: 'Inactive', value: inactiveAgents },
  //     { label: 'Retired', value: retiredAgents },
  //     { label: 'Dormant', value: dormantAgents },
  //   ];
  //   this.setState({
  //     agentActivityCount,
  //   });
  // }

  async getAgentActivityCount(agents) {
    const agentCount = agents && agents.length > 0 ? agents.length : 0;

    const newAgents =
      agentCount > 0 ? await this.getAgentStatusCount(agents, NEW) : 0;
    const activeAgents =
      agentCount > 0 ? await this.getAgentStatusCount(agents, ACTIVE) : 0;
    const inactiveAgents =
      agentCount > 0 ? await this.getAgentStatusCount(agents, INACTIVE) : 0;
    const retiredAgents =
      agentCount > 0 ? await this.getAgentStatusCount(agents, RETIRED) : 0;
    const dormantAgents =
      agentCount > 0 ? await this.getAgentStatusCount(agents, DORMANT) : 0;

    const agentActivityCount = [
      { label: 'New', value: newAgents },
      { label: 'Active', value: activeAgents },
      { label: 'Inactive', value: inactiveAgents },
      { label: 'Retired', value: retiredAgents },
      { label: 'Dormant', value: dormantAgents },
    ];

    this.setState({
      agentActivityCount,
    });
  }

  async getTransactionsCount() {
    this.setState({
      loadingChartData: true,
      loadingVolumeData: true,
      canReloadTransactionCount: false,
    });
    const response = await this.getTransactionsCountByType();
    //const response = DashboardData
    const transactionsCount = [
      { time: 'Fund', value: this.toDailyJson(response.fund, 'count') },
      { time: 'Bills', value: this.toDailyJson(response.bills, 'count') },
      { time: 'Recharge', value: this.toDailyJson(response.recharge, 'count') },
      { time: 'Transfer', value: this.toDailyJson(response.transfer, 'count') },
      {
        time: 'Wallet-to-Wallet',
        value: this.toDailyJson(response.walletToWallet, 'count'),
      },
      { time: 'CashIn', value: this.toDailyJson(response.cashIn, 'count') },
      { time: 'Cashout', value: this.toDailyJson(response.cashOut, 'count') },
      {
        time: 'Account-Opening',
        value: this.toDailyJson(response.accountOpening, 'count'),
      },
    ];

    const transactionsVolume = [
      { transType: 'Fund', value: this.toDailyJson(response.fund, 'volume') },
      { transType: 'Bills', value: this.toDailyJson(response.bills, 'volume') },
      {
        transType: 'Recharge',
        value: this.toDailyJson(response.recharge, 'volume'),
      },
      {
        transType: 'Wallet-to-Wallet',
        value: this.toDailyJson(response.walletToWallet, 'volume'),
      },
      {
        transType: 'Transfer',
        value: this.toDailyJson(response.transfer, 'volume'),
      },
      {
        transType: 'Cashin',
        value: this.toDailyJson(response.cashIn, 'volume'),
      },
      {
        transType: 'Cashout',
        value: this.toDailyJson(response.cashOut, 'volume'),
      },
      {
        transType: 'Account-Opening',
        value: this.toDailyJson(response.accountOpening, 'volume'),
      },
    ];

    const dailyTransactionsVolume =
      this.toDailyJson(response.fund, 'volume') +
      this.toDailyJson(response.bills, 'volume') +
      this.toDailyJson(response.recharge, 'volume') +
      this.toDailyJson(response.walletToWallet, 'volume') +
      this.toDailyJson(response.transfer, 'volume') +
      this.toDailyJson(response.cashIn, 'volume') +
      this.toDailyJson(response.cashOut, 'volume') +
      this.toDailyJson(response.accountOpening, 'volume');

    const dailyTransactionsCount =
      this.toDailyJson(response.fund, 'count') +
      this.toDailyJson(response.bills, 'count') +
      this.toDailyJson(response.recharge, 'count') +
      this.toDailyJson(response.walletToWallet, 'count') +
      this.toDailyJson(response.transfer, 'count') +
      this.toDailyJson(response.cashIn, 'count') +
      this.toDailyJson(response.cashOut, 'count') +
      this.toDailyJson(response.accountOpening, 'count');

    transactionsVolume.sort(function (a, b) {
      return b.value - a.value;
    });

    this.setState({
      chartData: transactionsCount,
      transactionsVolume,
      dailyTransactionsVolume,
      dailyTransactionsCount,
      loadingChartData: false,
      loadingVolumeData: false,
      canReloadTransactionCount: response.canReload,
    });
  }

  async getWeeklyTransactionsCount() {
    this.setState({ showWeeklyChart: false });
    const response = await this.getTransactionsCountByType();
    this.setState({
      weeklyBillsVolume: this.toWeeklyJson(response.bills),
      weeklyRechargeVolume: this.toWeeklyJson(response.recharge),
      weeklyTransferVolume: this.toWeeklyJson(response.transfer),
      showWeeklyChart: true,
      canReloadWeeklyChart: response.canReload,
    });
  }

  async getBuyPowerDiscos() {
    this.setState({ loadingDiscos: true });
    const {
      status,
      response,
    } = await feeConfigurationManagement.getDiscosStatusToday();
    if (status === SUCCESS_STATUS) {
      const dataArray = Object.keys(response).map((key) => {
        return {
          name: key,
          value: response[key],
        };
      });
      this.setState({
        loadingDiscos: false,
        buypowerDiscos: dataArray,
      });
    } else {
      this.setState({
        loadingDiscos: false,
        buypowerDiscos: [],
      });
    }
  }

  toWeeklyJson(transactionType) {
    let formattedArray = [];
    let days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    let dateLimit = new Date().getDate();
    let date = new Date();
    let today = date.getDay();
    let diff = date.getDate() - today + (today == 0 ? -6 : 1);
    let curr = new Date(date.setDate(diff));
    let day = new Date(curr.setDate(dateLimit)).toISOString().slice(0, 10);
    let d = new Date(day);
    let currentDate = this.validateDates(date, curr);
    day = new Date(currentDate.setDate(dateLimit)).toISOString().slice(0, 10);
    d = new Date(day);
    if (transactionType) {
      days.forEach((day) => {
        if (days.indexOf(day) <= d.getDay() - 1) {
          let object = {
            time: day,
            value: 0,
          };
          transactionType.map((details) => {
            if (day === details.date) {
              object.time = day;
              object.value = details.count;
            }
          });
          formattedArray.push(object);
        }
      });
    }
    return formattedArray;
  }

  validateDates(previousDate, currentDate) {
    if (currentDate < previousDate) {
      return new Date(currentDate.setMonth(currentDate.getMonth() + +1));
    }
    return currentDate;
  }

  toDailyJson(transactionType, type) {
    let days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    let dateLimit = new Date().getDate();
    let date = new Date();
    let today = date.getDay();
    let diff = date.getDate() - today + (today == 0 ? -6 : 1);
    let curr = new Date(date.setDate(diff));
    let day = new Date(curr.setDate(dateLimit)).toISOString().slice(0, 10);
    let d = new Date(day);
    let currentDate = this.validateDates(date, curr);
    day = new Date(currentDate.setDate(dateLimit)).toISOString().slice(0, 10);
    d = new Date(day);
    let dayName = days[d.getDay() - 1];
    let count = 0;
    if (transactionType) {
      transactionType.map((details) => {
        if (dayName === details.date) {
          count = details[type];
        }
      });
    }
    return count;
  }

  async getTransactionsCountByType() {
    let date = new Date();
    let today = date.getDay();
    let diff = date.getDate() - today + (today == 0 ? -6 : 1);
    let currDate = new Date(date.setDate(diff));
    let first = currDate.getDate() - currDate.getDay() + 1;
    let last = currDate.getDate() - currDate.getDay() + today;
    let startDate = new Date(currDate.setDate(first))
      .toISOString()
      .slice(0, 10);
    let endDate = new Date(currDate.setDate(last)).toISOString().slice(0, 10);
    const formattedStartDate = formatBackEndDateTime(
      formatDailyTimeRange(startDate).start
    );
    const formattedEndDate = formatBackEndDateTime(
      formatDailyTimeRange(endDate).end
    );
    try {
      const responseObj = await transactionHistory.getTransactionsByType(
        formattedStartDate,
        formattedEndDate,
        TRANSFER,
        3
      );
      const { status, response } = responseObj;
      const result = response ? response : {};
      if (status === SUCCESS_STATUS) {
        result.canReload = false;
        return result;
      } else {
        result.canReload = true;
        result.bills = 0;
        result.recharge = 0;
        result.transfer = 0;
        return result;
      }
    } catch (e) {
      const result = {};
      result.canReload = true;
      result.bills = 0;
      result.recharge = 0;
      result.transfer = 0;
      return result;
    }
  }

  // getAgentStatusCount(agents, status) {
  //   const filteredAgents = agents?.filter((value) => value.statusId === status);
  //   return filteredAgents.length;
  // }
  getAgentStatusCount(agents, status) {
    if (agents && agents.length > 0) {
      const filteredAgents = agents && agents.filter(
        (value) => value.statusId === status
      );
      return filteredAgents.length;
    }
    return 0;
  }

  async getWalletBalance() {
    this.setState({ isLoading: true });
    const balanceResponseObj = await transactionService.getWalletBalance();
    const { status, response } = balanceResponseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        walletBalance: parseFloat(response.transactionWalletBalance),
        commissionBalance: parseFloat(response.commissionWalletBalance),
        isLoading: false,
        reloadWalletBalance: false,
        lastSuccessfulWalletBalanceTime: new Date(),
      });
    } else {
      this.setState({
        walletBalance: this.state.walletBalance,
        commissionBalance: this.state.commissionBalance,
        isLoading: false,
        reloadWalletBalance: false,
      });
    }
  }

  async getCommissionBalance(agentPhoneNo) {
    this.setState({ isLoading: true, reloadWalletBalance: true });
    const balanceResponseObj = await settlementService.getCommissionBalance(
      agentPhoneNo
    );
    const { status, response } = balanceResponseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        unsettledBalance: parseFloat(response.unsettled_commission),
        isLoading: false,
        reloadWalletBalance: false,
      });
    } else {
      this.setState({
        unsettledBalance: this.state.unsettledBalance,
        isLoading: false,
        reloadWalletBalance: false,
      });
    }
  }

  getAllBalances() {
    const shouldReloadWalletBalance = !this.state.reloadWalletBalance;
    this.setState({ reloadWalletBalance: !this.state.reloadWalletBalance });
    if (shouldReloadWalletBalance) {
      this.getWalletBalance();
      this.getCommissionBalance(this.currentUser.mobileNo);
    }
  }

  get currentUserCanViewBalances() {
    return this.currentUser.user.domainType
      ? Boolean(this.currentUser.user.domainType.toUpperCase() === SUPER_AGENT)
      : false;
  }

  get currentUserCanDistribute() {
    return this.userPermissions.includes(CAN_DISTRIBUTE_WALLET);
  }

  get toShowDistributeButton() {
    return this.currentUserCanDistribute && this.state.walletBalance > 0;
  }

  render() {
    return (
      <React.Fragment>
        <GridItem isFluid isHorizontalGrid>
          <ContentHead title={'Dashboard'} />
          {this.currentUserCanViewBalances && (
            <div class='row'>
              <div class='col-md-4'>
                <CardComponent
                  title={`Wallet Balance`}
                  value={formatCurrency(
                    convertToMajor(this.state.walletBalance)
                  )}
                  currency={true}
                  showReloader={true}
                  reloadWalletBalance={this.state.reloadWalletBalance}
                  getAllBalances={this.getAllBalances}
                  showLastBalanceTime={true}
                  canDistribute={this.toShowDistributeButton}
                  lastBalanceTime={
                    this.state.lastSuccessfulWalletBalanceTime
                      ? formatDateTime2(
                        this.state.lastSuccessfulWalletBalanceTime
                      )
                      : null
                  }
                />
              </div>
              <div class='col-md-4'>
                <CardComponent
                  title={`Commission Earned`}
                  value={formatCurrency(
                    convertToMajor(this.state.commissionBalance)
                  )}
                  currency={true}
                />
              </div>
              <div class='col-md-4'>
                <CardComponent
                  title={`Unsettled Balance`}
                  value={formatCurrency(
                    convertToMajor(this.state.unsettledBalance)
                  )}
                  currency={true}
                />
              </div>
            </div>
          )}
          <div class='row'>
            <div class='col-md-4'>
              <CardComponent
                title={`Agent Activity`}
                description={`Overview of agent`}
                value={`${formatNumber(this.state.agentsCount)} agents`}
                chart={<DoughnutChart data={this.state.agentActivityCount} />}
                loading={this.state.loadingActivityCount}
                canReload={this.state.reloadAgentsCount}
                reload={this.getAgents}
              />
            </div>

            <div class='col-md-4'>
              <CardComponent
                title={`Top Transactions`}
                count={this.state.dailyTransactionsCount}
                description={`Average transactions in percentile`}
                transactionsVolume={this.state.chartData}
                //format={formatCurrency}
                // loading={this.state.loadingVolumeData}
                canReload={this.state.canReloadTransactionCount}
                reload={this.getTransactionsCount}
              />
            </div>
            {process.env.REACT_APP_SHOW_DISCOS === 'true' ? (
              <div class='col-md-4'>
                <CardComponent
                  title={`Buy power discos`}
                  buypowerDiscos={this.state.buypowerDiscos}
                  loading={this.state.loadingDiscos}
                  reload={this.getBuyPowerDiscos}
                />
              </div>
            ) : (
              <div class='col-md-4'>
                <CardComponent
                  title={`Transactions`}
                  description={`Transaction type chart`}
                  chart={<BarChart data={this.state.chartData} />}
                  loading={this.state.loadingChartData}
                  canReload={this.state.canReloadTransactionCount}
                  reload={this.getTransactionsCount}
                />
              </div>
            )}
          </div>

          <div class='row'>
            <div class='col-md-8'>
              <MapCard
                title={`Agents Location`}
                content={<MapComponent agentLocation={this.state.agents} />}
              />
            </div>

            <div class='col-md-4'>
              {process.env.REACT_APP_SHOW_DISCOS === 'true' && (
                <div class='col-md-12'>
                  <CardComponent
                    title={`Transactions`}
                    description={`Transaction type chart`}
                    chart={<BarChart data={this.state.chartData} />}
                    loading={this.state.loadingChartData}
                    canReload={this.state.canReloadTransactionCount}
                    reload={this.getTransactionsCount}
                  />
                </div>
              )}
              <div class='col-md-12'>
                <LineChart
                  data={this.state.weeklyTransferVolume}
                  title={'Transfer'}
                  backgroundColor={'#ffefce'}
                  borderColor={'#ffb822'}
                  loading={this.state.showWeeklyChart}
                  canReload={this.state.canReloadWeeklyChart}
                  reload={this.getWeeklyTransactionsCount}
                  parameter={'Transfer'}
                />
              </div>
              <div class='col-md-12'>
                <LineChart
                  data={this.state.weeklyRechargeVolume}
                  title={'Recharge'}
                  backgroundColor={'#d1f1ec'}
                  borderColor={'#34bfa3'}
                  loading={this.state.showWeeklyChart}
                  canReload={this.state.canReloadWeeklyChart}
                  reload={this.getWeeklyTransactionsCount}
                  parameter={'Recharge'}
                />
              </div>
              <div class='col-md-12'>
                <LineChart
                  data={this.state.weeklyBillsVolume}
                  title={'Bills Payment'}
                  backgroundColor={'#ffefce'}
                  borderColor={'#ffb822'}
                  loading={this.state.showWeeklyChart}
                  canReload={this.state.canReloadWeeklyChart}
                  reload={this.getWeeklyTransactionsCount}
                  parameter={'Bill Payment'}
                />
              </div>
            </div>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }
}
