import { formatLongNumber } from '../../utils/formatter';

export default class DevicesExportSerializer {
    static headerExport () {
        return [
            {label: 'Device Name', key: 'deviceName', title: 'Device Name', dataIndex: 'deviceName'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'}

        ]
    }

    static reverseDeviceDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { deviceName, status } = details
            let detailFormData = {
                deviceName,
                status: status == 'Not-Active' ? 'Inactive' : status
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}