import React from 'react'
import { formatWorkflowApprovalStatus } from '../../../../../../utils/formatter';

export default class ApprovalInformation extends React.Component {
    render() {
        return <React.Fragment>
            <h5>Information</h5>
            {/* <div class="form-group row">
                <label for="staticEmail" class="col-lg-4">Summary:</label>
                <div class="col-lg-8">
                    <span>{this.props.approvalDetails.applicationId}</span>
                </div>
            </div> */}
            {(this.props.approvalDetails.runingWorkflow && this.props.approvalDetails.runingWorkflow.name) && <div class="form-group row">
                <label for="staticEmail" class="col-lg-4">Module:</label>
                <div class="col-lg-8">
                    <span>{this.props.approvalDetails.runingWorkflow.name}</span>
                </div>
            </div>}
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-4">Action Type:</label>
                <div class="col-lg-8">
                    <span>{this.props.approvalDetails.actionType}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-4">Current Pending Step:</label>
                <div class="col-lg-8">
                    <span>{this.props.approvalDetails.step}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-4">Status:</label>
                <div class="col-lg-8">
                    <span>{formatWorkflowApprovalStatus(this.props.approvalDetails.status)}</span>
                </div>
            </div>
        </React.Fragment>
    }
}