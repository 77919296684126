import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class posrequestsAwaitingConfig {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getPosRequestsAwaitingConfig(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request-configuration`,
      args: {
        pageNum,
        pageSize,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }

  getRequestsAwaitingConfigBySearch(
    pageNumber = null,
    pageSize = null,
    mobilePhone = null,
    toDate = null,
    fromDate = null,
    status = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request-configuration/search`,
      args: {
        mobilePhone,
        toDate,
        fromDate,
        pageNumber,
        pageSize,
        status,
      },
    });
  }

  updatePosRequestAwaitingConfigById(id) {
    return this.apiRequester.put({
      endpoint: `pos-request-configuration/${id}`,
    });
  }
  updatePosRequestAwaitingConfigByBatchId(batchId) {
    return this.apiRequester.put({
      endpoint: `pos-request-configuration/batch-id/${batchId}`,
    });
  }
  // cancelPosRequestByStockId(posRequestStockId, cancelReason) {
  //   return this.apiRequester.put({
  //     endpoint: `pos-request/${posRequestStockId}`,
  //   });
  // }
  cancelPosRequestByStockId(posRequestStockId, cancelReason) {
    return this.apiRequester.put({
      endpoint: `pos-request/${posRequestStockId}`,
      body: {
        posRequestStockId: posRequestStockId,
        reasonForCancellation: cancelReason,
      },
    });
  }
}
