import React from 'react';
import { AccessDenied } from '../../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../../components/loader';
import { PromptModal } from '../../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../../components/notification';
import Table from '../../../../../../../../components/table';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../../constants/api';
import { CAN_ACTIVATE_AGENT_DEVICE, CAN_DEACTIVATE_AGENT_DEVICE, CAN_DELETE_AGENT_DEVICE, CAN_VIEW_AGENT_DEVICE } from '../../../../../../../../constants/permissions.js';
import { userManagement } from '../../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../../mixins/protected-component';
import { DevicesExportSerializer } from '../../../../../../../../serializers';
import { pastTenseFormatter } from '../../../../../../../../utils/formatter';
import DomainGridLayout from '../../../../components/domain-grid-layout';

export default class DomainDeviceGridScene extends React.Component {

    columns = [
        {
            Header: "S/N",
            accessor: 'id',
            Cell: (id) => <span>{id.index + 1}</span>,
            sortable: false,
            resizable: false,
            width: 60,
            headerStyle: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
            return {
                style: {
                textAlign: 'center',
                whiteSpace: 'unset',
                },
            };
            },
        },{
            Header: "Device Name",
            accessor: "deviceName",
            resizable: false,
            headerStyle: { 'white-space': 'unset' },
            style: { 'white-space': 'unset' },
            Cell: props => <span>{props.value}</span>
        }, {
    
            Header: "Status",
            accessor: "status",
            resizable: false,
            headerStyle: { 'white-space': 'unset' },
            style: { 'white-space': 'unset' },
            Cell: props => <span>{props.value === 'Not-Active' ? 'Inactive' : props.value}</span>,
            width: 100,
        },{
    
            Header: "Action",
            accessor: "actionType",
            resizable: false,
            headerStyle: { 'white-space': 'unset' },
            style: { 'white-space': 'unset' },
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        lineHeight: '20px',
                        textAlign: 'center',
                        whiteSpace: 'unset',
                    },
                };
            },
            width: 100,
            Cell: ({ row, original }) => <div class="dropdown">
                <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                    <i class="la la-ellipsis-v"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <ProtectedComponent requiredPermissions={[CAN_ACTIVATE_AGENT_DEVICE]}><a href={``} hidden={original.status == 'Not-Active' ? '' : 'hidden'} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(original.deviceid, original.deviceName, 'activate')}>
                        <i class="la la-toggle-on"></i>
                        Activate
                    </a></ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[CAN_DEACTIVATE_AGENT_DEVICE]}><a href={``} hidden={original.status == 'Active' ? '' : 'hidden'} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(original.deviceid, original.deviceName, 'deactivate')}>
                        <i class="la la-lock"></i>
                        Deactivate
                    </a></ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[CAN_DELETE_AGENT_DEVICE]}><a href={``} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(original.deviceid, original.deviceName, 'delete')}>
                        <i class="la la-trash"></i>
                        Remove
                    </a></ProtectedComponent>
                </div>
            </div>
        }
    ]
    
    constructor(props) {
        super(props);
        this.state = {
            domainName: null,
            allDevices: [],
            deviceId: null,
            deviceStatus: null,
            loading: false,
        }
        this.updateDomainDevice = this.updateDomainDevice.bind(this)
    }

    componentDidMount () {
        this.domainCode = this.props.match.params.domainCode
        this.getDomainDevices(this.domainCode)
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async getDomainDevices (domainCode) {
        this.setState({isLoading: true})
        const responseObj = await userManagement.getDevicesByDomainCode(domainCode)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                allDevices: response.data,
                dataCount: response.data.length,
                isLoading: false,
            })
        }else{
            this.setState({
                allDevices: [],
                dataCount: 0,
                isLoading: false,
            })
        }
    }

    getClickedAction (deviceId, deviceName, deviceStatus) {
        this.setState({
            deviceId,
            deviceStatus,
            deviceName
        })
    }

    async updateDomainDevice () {
        this.setState({isLoading: true})
        let getDeviceResponseObj
        if(this.state.deviceStatus == 'delete') {
            getDeviceResponseObj = await userManagement.deleteDevice(this.state.deviceId)
        }else{
            getDeviceResponseObj = await userManagement.updateDeviceStatus(this.state.deviceStatus, this.state.deviceId)
        }

        const approvalMessage = `Your request to ${this.state.deviceStatus} ${this.state.deviceName} as a device has been sent successfully for approval.`
        const message = `Device name ${this.state.deviceName} was successfully ${pastTenseFormatter(this.state.deviceStatus)}.`
        
        const { status, response } = getDeviceResponseObj
        
        if (status === SUCCESS_STATUS) {
            if(response.code == ACCEPTED_REQUEST){
                this.setState({
                  isLoading: false,
                  successMessage: approvalMessage
                })
                return
            }else{
                this.setState({
                    successMessage: message,
                    isLoading: false,
                })
            }
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false, 
            })
        }
        this.getDomainDevices(this.domainCode)
        setTimeout(() => {
            this.setState({
                deviceId: null,
                deviceStatus: null,
                deviceName: null
            })
        }, 3000)
    }
    

    render() {
        return <React.Fragment>
            <Loader 
                isLoading={this.state.isLoading}
            />
            <Notification 
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
           <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_DEVICE]} permissionDeniedContent={this.permissionDeniedContent}> 
            <GridLayout>
                <DomainGridLayout title={'Device Management'}>
                        <Table 
                            columns={this.columns}
                            data={this.state.allDevices} 
                            loading={this.state.isLoading} 
                            noDataText={`No devices found`}
                            dataCount={this.state.dataCount}
                            getData={this.getDevices}
                            exportData={DevicesExportSerializer.reverseDeviceDetails(this.state.allDevices)}
                            exportHeaders={DevicesExportSerializer.headerExport()}
                            exportFileName={'Devices'}
                        />
                        <PromptModal
                            modalTitle={ this.state.deviceStatus+` Device`}
                            modalBody={`Do you want to ${this.state.deviceStatus} this device ${this.state.deviceName}?`}
                            deleteModal={this.updateDomainDevice}
                            modalSize={`modal-md`}
                            height={`230px`}
                            modalFooterButton1={`Cancel`}
                            modalFooterButton2={`Ok`}
                            hideModalHeader={true}
                            hideModalFooter={true}
                        />
                    </DomainGridLayout>
                </GridLayout>
            </ProtectedComponent>
        </React.Fragment>
    }
    
    get permissionDeniedContent() {
        return <AccessDenied /> 
    }
}