import Requester from '../requester'

const API_BASE_URL = process.env.REACT_APP_HISTORICAL_DATA_SERVICE_API_BASE_URL

export default class HistoricalData {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }

    getHistoricalServices () {
        return this.apiRequester.get({
            endpoint: `archives/services`
        })
    }

    getHistoricalTransactionsOld(
        pageNum = null,
        pageSize = null,
        agentPhone = null,
        gmppRef = null,
        transType = null,
        startDate = null,
        endDate = null
    ) {
        return this.apiRequester.get({
            endpoint: `archives/transactions`,
            args: {
                agentPhone,
                gmppRef,
                endDate,
                startDate,
                page: pageNum,
                pageSize,
                ...(transType !== "" ? { transType } : {}),
            }
        })
    }

    getHistoricalTransactions(
        pageNum = null,
        pageSize = null,
        agentPhone = null,
        gmppRef = null,
        transType = null,
        startDate = null,
        endDate = null
    ) {
        return this.apiRequester.get({
            endpoint: `archives/paged-transactions`,
            args: {
                agentPhone,
                gmppRef,
                endDate,
                startDate,
                page: pageNum,
                pageSize,
                ...(transType !== "" ? { transType } : {}),
            }
        })
    }
}
