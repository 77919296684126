import React from 'react'
import { Link } from 'react-router-dom'
import ProtectedComponent from '../../../../../mixins/protected-component';

export default class DomainGridLayout extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">{this.props.title}</h3>
                        </div>
                        {this.props.url && <div class="kt-portlet__head-label">
                            <ProtectedComponent requiredPermissions={this.props.urlLinkPermission}><Link to={this.props.url} type="button" class="btn btn-primary btn-sm" style={{backgroundColor: '#00425f', borderColor: '#00425f'}}>{this.props.urlName}</Link></ProtectedComponent>
                        </div>}
                    </div>
                    <div class="kt-portlet__body">
                        {this.props.children}
                    </div>
                </div>
        </React.Fragment>
    }
}