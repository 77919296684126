import moment from 'moment';

export function formatTransactionStatus(status) {
  if (!status) {
    return status;
  }

  if (status === 'PENDING_VALUE_PROVISIONING') {
    return (status = 'Pending');
  } else if (status === 'CLOSED_AND_SUCCESSFUL' || status === 'Successful') {
    return (status = 'Successful');
  } else if (status === 'CLOSED_BUT_FAILED' || status === 'Failed') {
    return (status = 'Failed');
  } else {
    return status
      ? `${status.charAt(0).toUpperCase()}${status.substr(1).toLowerCase()}`
      : '';
  }
}

export function formatReversalTransactionStatus(status) {
  if (status === 'PENDING_REVERSAL') {
    return (status = 'Pending');
  } else if (status === 'CLOSED_AND_SUCCESSFUL') {
    return (status = 'Successful');
  } else if (status === 'CLOSED_BUT_FAILED' || status === 'CLOSE_BUT_RETRY') {
    return (status = 'Failed');
  } else {
    return `${status.charAt(0).toUpperCase()}${status.substr(1).toLowerCase()}`;
  }
}

export function formatWorkflowApprovalStatus(status) {
  if (status === 'APPROVED_CLOSED') {
    return (status = 'APPROVED & CLOSED');
  } else if (status === 'INPROGRESS') {
    return (status = 'IN PROGRESS');
  } else {
    return status;
  }
}

export function formatPhoneNumberWithCountryCode(countryCode, phoneNumber) {
  return `${countryCode}${phoneNumber.substr(1)}`;
}

export function formatTransactionStatusColorCode(status) {
  if (!status) {
    return 'badge-secondary';
  }

  if (status === 'PENDING_VALUE_PROVISIONING') {
    return (status = 'badge-warning');
  } else if (
    status === 'CLOSED_AND_SUCCESSFUL' ||
    status === 'Successful' ||
    status.toLowerCase() === 'completed'
  ) {
    return (status = 'badge-success');
  } else if (status === 'CLOSED_BUT_FAILED' || status === 'Failed') {
    return (status = 'badge-danger');
  } else {
    return 'badge-secondary';
  }
}

export function formatLienStateColorCode(state) {
  if (!state) {
    return 'badge-secondary';
  }

  if (state === 'PLACED') {
    return 'badge-warning';
  } else if (['LIFTING', 'LIFTED'].includes(state)) {
    return 'badge-success';
  } else if (state === 'CANCELLED') {
    return 'badge-danger';
  } else {
    return 'badge-secondary';
  }
}

export function formatBulkProcessingStatusColorCode(status) {
  if (status === false) {
    return (status = 'badge-warning');
  } else if (status === true) {
    return (status = 'badge-success');
  } else {
    return 'badge-secondary';
  }
}

export function formatBulkProcessingStatusName(statusName) {
  if (statusName === false) {
    return (statusName = 'Incomplete');
  } else if (statusName === true) {
    return (statusName = 'Completed');
  } else {
    return '';
  }
}

export function formatSettlementStatusColorCode(status) {
  if (status.toLowerCase() === 'unsettled') {
    return (status = 'badge-warning');
  } else if (status.toLowerCase() === 'settled') {
    return (status = 'badge-success');
  } else {
    return 'badge-secondary';
  }
}

export function formatNigeriaPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
  return phoneNumber;
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  return `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()}`;
}

export function formatBackEndDate(inputDate) {
  const date = new Date(inputDate);
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`;
}

export function formatBackEndDate2(inputDate) {
  const date = new Date(inputDate);
  return `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()}`;
}

export function formatBackEndDateTime(inputDate, time = false) {
  const date = new Date(inputDate);
  if (time) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)} ${('0' + date.getHours() * 0).slice(-2)}:${(
      '0' +
      date.getMinutes() * 0
    ).slice(-2)}:${('0' + date.getSeconds() * 0).slice(-2)}`;
  } else {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  }
}

export function formatBackEndDateTimeFrom(inputDate, time = false) {
  const date = new Date(inputDate);
  if (time) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)} ${('0' + date.getHours() * 0).slice(-2)}:${(
      '0' +
      date.getMinutes() * 0
    ).slice(-2)}:${('0' + date.getSeconds() * 0).slice(-2)}`;
  } else {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  }
}

export function getDefaultDateFrom() {
  return moment().subtract(3, 'months').format('YYYY-MM-DDT00:00:00');
}

export function getDefaultDateTo() {
  return moment().format('YYYY-MM-DDTHH:mm:ss');
}

export function formatDateTimeV2(date) {
  if (
    date.includes('pm') ||
    date.includes('am') ||
    date.includes('PM') ||
    date.includes('AM')
  ) {
    return moment(date).format('YYYY-MM-DD , HH:mm:ss A');
  } else return moment(date).format('YYYY-MM-DD , HH:mm:ss');
}

export function formatBackEndDateTimeTo(inputDate, time = false) {
  const date = new Date(inputDate);
  if (time) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  } else {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  }
}

// export function formatDateTime (inputDate) {
//     if(!inputDate) return '00-00-0000';
//     const date =  new Date(inputDate.replace(/\s/, 'T'))
//     let hours = (date.getHours()) % 12;
//     hours = hours ? hours : 12;
//     return `${("0" + (date.getDate())).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + hours).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}${(date.getHours() - 1) >= 12 ? 'pm' : 'am'}`;
//     //return `${("0" + (date.getDate())).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + (date.getHours())).slice(-2)}:${("0" + (date.getMinutes())).slice(-2)}:${("0" + (date.getSeconds())).slice(-2)}`
// }

export function formatDateTime(inputDate) {
  if (!inputDate) return '00-00-0000';
  const date = new Date(inputDate.replace(/\s/, 'T'));
  let hours = date.getHours() % 12;
  hours = hours === 0 ? 12 : hours;
  const amPm = date.getHours() >= 12 ? 'pm' : 'am';
  return `${('0' + date.getDate()).slice(-2)}/${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()} ${('0' + hours).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)} ${amPm}`;
}

export function maskDeviceUuid(deviceUuid) {
  const firstValue = deviceUuid.substring(0, 3);
  const lastValue = deviceUuid.slice(-3);

  return `${firstValue}********************${lastValue}`;
}

export function formatDateTime2(inputDate) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(inputDate);
  let hours = date.getHours() % 12;
  hours = hours ? hours : 12;
  return `${months[date.getMonth()]} ${('0' + date.getDate()).slice(
    -2
  )} ${date.getFullYear()}, ${hours}:${('0' + date.getMinutes()).slice(-2)} ${
    date.getHours() - 1 >= 12 ? 'pm' : 'am'
  }`;
}

export function formatNumber(number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatCurrency(currency) {
  return currency.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatDomainTypeUrl(domainType) {
  if (domainType == 4) {
    return (domainType = 'agent-management');
  } else if (domainType == 3) {
    return (domainType = 'aggregator-management');
  } else if ((domainType = 2)) {
    return (domainType = 'super-agent-management');
  } else {
    return '';
  }
}

export function phoneNumberFormatter(phoneNumber) {
  if (phoneNumber.startsWith('0')) {
    return formatPhoneNumberWithCountryCode('234', phoneNumber);
  } else {
    return phoneNumber;
  }
}

export function applicationIdFormatter(applicationId) {
  applicationId = applicationId.toUpperCase();
  if (applicationId.startsWith('IFIS/')) {
    applicationId = applicationId.substr(5);
  }
  return applicationId;
}

export function formatDailyTimeRange(date = new Date()) {
  let start = new Date(date);
  let end = new Date(date);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  return {
    start,
    end,
  };
}

export function formatLongNumber(value) {
  return '`' + value;
}

export function formatTransactionStatusName(statusCode) {
  if (statusCode == 0) {
    return 'failed';
  } else if (statusCode == 1) {
    return 'successful';
  }
}

export function pastTenseFormatter(word) {
  const lastWordCharacter = word.substr(word.length - 1);
  if (lastWordCharacter == 'e') {
    return word + 'd';
  } else {
    return word + 'ed';
  }
}

export function getFormattedStatus(status) {
  if (status == 'enable') {
    return 'activate';
  } else if (status == 'disable') {
    return 'deactivate';
  } else {
    return status;
  }
}

export function formatString(string) {
  const defaultLength = 4;
  const stringLength = string.toString().length;

  let lengthDiff = defaultLength - stringLength;

  let leftString = '';

  if (lengthDiff > 0) {
    while (lengthDiff > 0) {
      leftString += 0;
      lengthDiff--;
    }
    return `${leftString}${string}`;
  } else {
    return string;
  }
}

export function formatBulkProcessingType(type) {
  if (type.toLowerCase() == 'retrieval') {
    return (type = 'Debit');
  } else if (type.toLowerCase() == 'refund') {
    return (type = 'Credit');
  } else {
    return '';
  }
}

export function formatTemplateDataParameters(data) {
  const result = data
    .replace(/([A-Z])/g, ' $1')
    .replace('.', ' / ')
    .replace('_', ' ');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function formatWalletStatus(status) {
  if (status === 'WALLET_CREATED') {
    return 'Completed';
  } else {
    return 'Ongoing';
  }
}

export function formatWalletStatusClassName(status) {
  if (status === 'WALLET_CREATED') {
    return 'kt-badge--success';
  } else {
    return 'kt-badge--warning';
  }
}
