import React from "react";
import { convertToMajor } from "../../../../../../../../utils/converter";

export default class RemapPosAllModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }

  render() {
    // console.log("Remap Request Modal ", this.props.posRequestDetails);

    const resul = this.props.posRequestDetails
      ? this.props.posRequestDetails
      : "";

    return (
      <React.Fragment>
        <div
          class="modal"
          id="transaction_details_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  POS Request Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>POS Remap Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Next Terminal Owner</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.nextTerminalOwner : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Payment Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.paymentStatus : "loading..."}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Initiator Username</strong>
                          </label>
                          <br />
                          <span>
                            {resul
                              ? resul.remapInitiatorUsername
                              : "loading..."}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Request type</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.remapRequestType : "loading..."}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Request Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.requestStatus : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Model</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.terminalModel : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Unit Price</strong>
                          </label>
                          <br />
                          <span>
                            {resul
                              ? convertToMajor(resul.unitPrice)
                              : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                {resul ? (
                  resul.cancelled === true ? (
                    <button
                      type="button"
                      class="btn col-lg-4"
                      style={{
                        backgroundColor: "#004257",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#kt_modal_1"
                      onClick={() => {
                        this.props.confirmConfigure();
                      }}
                    >
                      Cancel Remap
                    </button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <button
                  type="button"
                  // class="btn btn-default"
                  class="btn col-lg-4"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
