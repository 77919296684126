import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { formatDateTime, formatString } from '../../../../../../utils/formatter'
import { explodeDate } from '../../../../../../utils/helper'
import SpinnerComponent from '../../../../../../components/spinner'
import Pagination from '../../../../../../components/pagination'
import TableExtra from '../../../../../../components/table-extra'
import { FipExportSerializer } from '../../../../../../serializers'
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class AgentTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus'
  }

  zoomTableHeight = this.zoomTableHeight.bind(this)

  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }
  render() {
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
      {
        Header: "FIP ID",
        accessor: 'id',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 70,
        Cell: props => <span>{`FIP/${formatString(props.value)}`}</span>
      },
      {
        Header: "First Name",
        accessor: 'firstName',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },
      {
        Header: "Last Name",
        accessor: 'lastName',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },
      {
        Header: "Phone Number",
        accessor: 'mobileNo',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        width: 120,
        style: {'white-space': 'unset'},
      },
      // {
      //   Header: "Zone Code",
      //   accessor: 'zoneCode',
      //   resizable: false,
      //   headerStyle: {'white-space': 'unset'},
      //   width: 100,
      //   style: {'white-space': 'unset'},
      // },
      {
        Header: "Status",
        accessor: 'status',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 100,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: 'center'
            },
          };
        },
        Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge ${props.value === 1 ? 'kt-badge--success' : props.value === 2 ? 'kt-badge--warning' : props.value === 3 ? 'kt-badge--danger' : props.value === 4 ? 'kt-badge--info' : ''} kt-badge--inline kt-badge--pill`}>{props.value === 1 ? 'Active' : props.value === 2 ? "Inactive" : props.value === 3 ? "Retired" : props.value === 4 ? "Dormant" : ''}</span>
      },
      {
        Header: "Date Created",
        accessor: 'dateCreated',
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        resizable: false,
        width: 170,
        Cell: props => {
          return props.value ? formatDateTime(explodeDate(props.value, '+')) : null
        }
      },
      {
        Header: "Last Modified",
        accessor: 'dateLastUpdated',
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        resizable: false,
        width: 170,
        Cell: props => {
          return props.value ? formatDateTime(explodeDate(props.value, '+')) : null
        }
      },
      {
        Header: "Action",
        accessor: 'agentCode',
        overflow: 'visible',
        resizable: false,
        sortable: false,
        headerStyle: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: '10px',
              textAlign: 'center',
              whiteSpace: 'unset',
            },
          };
        },
        width: 60,
        Cell: ({row, original}) => <div class="dropdown">
          <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
            <i class="la la-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`fip-management/${original.username}/view`} class="dropdown-item">
              <i class="la la-eye"></i>
              View
            </Link></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.canActivate]}><a hidden={original.status === 2 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(original.username, original.firstName, original.lastName, 'activate', original.domainCode)} /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
              <i class="la la-toggle-on"></i>
              Activate
            </a></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.canDeactivate]}><a hidden={original.status === 1 ? '' : 'hidden'} onClick={() => this.props.getClickedAction(original.username, original.firstName, original.lastName, 'suspend', original.domainCode)} data-toggle="modal" data-target="#kt_modal_1" /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
              <i class="la la-ban"></i>
              Deactivate
            </a></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.canRetire]}><a hidden={original.status != 3 ? '' : 'hidden'} onClick={() => this.props.getClickedAction(original.username, original.firstName, original.lastName, 'retire', original.domainCode)} data-toggle="modal" data-target="#kt_modal_1" /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
              <i class="la la-lock"></i>
              Retire
            </a></ProtectedComponent>
          </div>
        </div>
      }
    ]

    return <React.Fragment>
      <TableExtra 
        data={FipExportSerializer.reverseFipDetails(this.props.data)}
        headers={FipExportSerializer.headerExport()}
        fileName={'FIPs'}
        zoomTableHeight={this.zoomTableHeight}
        getData={this.props.getFips}
        zoom={this.state.zoom}
        page={this.props.page}
        pageSize={this.props.pageSize}
        disabled={this.props.loading}
      />
      <ReactTable 
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: 'unset',
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ''}
          pageSizes={[10,20,25,50,100]}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
          minRows={(this.props.data).length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({page: state.page})
            if(this.props.canLoadData) {
              this.props.getFips(state.page + 1, state.pageSize)
            } 
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display:"flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft:"5px",
              paddingRight:"5px",
              alignItems: "center",
              
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"5px",
              paddingRight:"5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
            },
          })}    
          className="-striped -highlight" 
      />
    </React.Fragment>     
  }
}