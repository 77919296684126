import React, { Component } from 'react';
import { GridItem } from '../../components/layouts/grid-item';
import { VerticalGrid } from '../../components/layouts/grids/vertical';
import { HorizontalGrid } from '../../components/layouts/grids/horizontal';
import { Form } from '../../components/forms/form';
import LandingBanner from '../../components/landing-banner';
import { retrieveOtherInformation } from '../../mixins/authentication';
import { FormLayout } from '../../components/forms/form-layout'
import { RESET_EMAIL } from  '../../constants/session-storage'

export default class ForgotPasswordResponseScene extends Component {
  render() {
    const resetEmail = retrieveOtherInformation(RESET_EMAIL)

    return <React.Fragment>
      
      <VerticalGrid isRoot>
        <HorizontalGrid isRoot class="kt-login kt-login--v1">
          <GridItem isFluid class="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <LandingBanner />
            <GridItem isFluid class="kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
              <FormLayout title={"Check Your Email To Proceed"}>
                <div style={{textAlign: 'center'}}>
                  <div>
                    <i class="fas fa-envelope-open-text fa-7x"></i>
                  </div>
                  <div style={{marginTop: '20px'}}>
                    We just sent an email to <strong>{resetEmail}</strong><br/>
                    Please check your email and click on the link provided to reset your password.  
                  </div>
                </div>
                <Form class="kt-form" action="" novalidate="novalidate">
                  <div class="kt-login__actions">
                    <button id="kt_login_signin_submit" class="form-control btn btn-interswitch btn-elevate kt-login__btn-primary login-button" onClick={(e) => {
                      this.setState({activateRedirectLink: true})
                    }}>Change Email</button>
                  </div>
                </Form>
              </FormLayout>
            </GridItem>
          </GridItem>
        </HorizontalGrid>
      </VerticalGrid>

    </React.Fragment>
  }
}