import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../../components/spinner";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { getPaginationRows } from "../../../../../../../utils/helper";

export default class AppConfigTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "860px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getSerialNumber(index) {
    return this.props.pageSize * (this.props.page - 1) + index;
  }
  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => (
          <span>{this.getSerialNumber(parseInt(id.index) + 1)}</span>
        ),
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
        //   getProps: (state, rowInfo, column) => {
        //     return {
        //         style: {
        //             textAlign: 'center',
        //             whiteSpace: 'unset',
        //         },
        //     };
        // },
      },
      {
        Header: "Route URL",
        accessor: "name",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 250,
      },
      {
        Header: "Channel Code",
        accessor: "code",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
      },
      {
        Header: "Currency",
        accessor: "currencyCode",
        resizable: false,
        width: 150,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (!props.value || props.value === 0 ? 1 : props.value),
      },
      {
        Header: "URL Name",
        accessor: "urlName",
        resizable: false,
        width: 200,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        // Cell: (props) => {
        //   return <span title={props.value == 1 ? "N settlement date" : "T+N settlement date"}>{props.value == 1 ? "N" : "T+N"}</span>
        // }
      },
      {
        Header: "Status",
        accessor: "status",
        resizable: false,
        width: 150,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => {
          return <span>{props.value === true ? "true" : "false"}</span>;
        },
      },
      {
        Header: "Provider Code",
        accessor: "providerCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
      },
      {
        Header: "Description",
        accessor: "description",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
      },
      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 70,
        // Cell: ({row, original}) => <ProtectedComponent requiredPermissions={[this.props.canView]}>
        //   <button type="button" class="btn btn-xs link-button">
        //     <Link to={`services/${original.id}/view`} class="link-button">
        //       View
        //     </Link>
        //   </button>
        // </ProtectedComponent>
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#app_config_details_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{
                    color: "#A7ABC3",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#app_config_edit_details_modal"
                  onClick={() => this.props.getClickedAction(original, "edit")}
                  style={{
                    color: "#a7abc3",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  class="dropdown-item"
                >
                  <i class="la la-toggle-on"></i>
                  Edit
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        {/* <TableExtra 
            data={ServiceExportSerializer.reverseServiceDetails(this.props.data)}
            headers={ServiceExportSerializer.headerExport()}
            fileName={'services'}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getServices}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            disabled={this.props.loading}
          /> */}
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          onFetchData={(state, instance) => {
            this.props.getBillConfigurations(state.page + 1, state.pageSize);
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "40px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
