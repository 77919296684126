import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS, ERROR_STATUS } from '../../../../../../../constants/api'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { getDeviceInformation } from '../../../../../../../mixins/authentication'
import { AGENT_TYPE_ID } from '../../../../../../../constants/domain-types'
import { TERMINAL_ID, PAYMENT_ITEM_CODE } from '../../../../../../../constants/transaction-types'
import { liquidityService, platformManagement, transactionService } from '../../../../../../../mixins/api'
import { CAN_DISTRIBUTE_WALLET } from '../../../../../../../constants/permissions'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { retrieveSessionInformation } from '../../../../../../../mixins/authentication'
import { convertToMajor, convertToMinor } from '../../../../../../../utils/converter'
import { toPermissionArray } from '../../../../../../../utils/helper'
import WalletDistributionForm from '../../components/wallet-distribution-form'
import WalletDistributionTable from '../../components/wallet-distribution-table'
import WalletBalanceSummary from '../../components/wallet-balance-summary'
import WalletDistributionLayout  from '../../components/wallet-distribution-layout'

export default class WalletDistributionScene extends BaseScene {
  requiredPermissions = [
    CAN_DISTRIBUTE_WALLET
  ]

  currentUser = retrieveSessionInformation()  
  userPermissions = toPermissionArray(this.currentUser.user.permissions)

  sceneName = 'wallet-distribution'

  canSubmit = false

  constructor (props) {
    super(props)

    this.state = {
      distributeFormData: [],
      title: 'Distribute',
      agents: [],
      agentDetails: null,
      amount: null,
      updatedAmount: null,
      availableWalletBalance: 0.0,
      walletBalance: 0.0,
      totalDistribute: 0.0,
      canDistribute: false,
      walletBalancePercentage: 0.0,
      walletDistributionFormData: [],
    }

    this.updatedistributeFormData = this.updatedistributeFormData.bind(this)
    this.updateAgentAmount = this.updateAgentAmount.bind(this)
    this.editAgentAmount = this.editAgentAmount.bind(this)
    this.removeDistribute = this.removeDistribute.bind(this)
    this.getAgents = this.getAgents.bind(this)
    this.getWalletBalance = this.getWalletBalance.bind(this)
    this.updateWalletDistributionFormData = this.updateWalletDistributionFormData.bind(this)
    this.intiateWalletDistribution = this.intiateWalletDistribution.bind(this)
    this.proceedWalletDistribution = this.proceedWalletDistribution.bind(this)
  }

  componentDidMount() {
    this.setState({device: getDeviceInformation()})
    this.getAgents()
    this.getWalletBalance()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAgents () {
    const responseObj = await platformManagement.getDomains(AGENT_TYPE_ID)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        agents: response,
      })
    }else{
      this.setState({
        agents: []
      })
    }
  }

  async getWalletBalance () {
    this.setState({isLoading: true})
    const responseObj = await transactionService.getWalletBalance()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        walletBalance: parseFloat(response.transactionWalletBalance),
        availableWalletBalance: parseFloat(response.transactionWalletBalance),
        walletBalancePercentage: 100.0,
        isLoading: false
      })
    }else{
      this.setState({
        walletBalance: this.state.walletBalance,
        walletBalancePercentage: this.state.walletBalance > 0 ? 100.0 : 0.0,
        isLoading: false,
      })
    }
  }

  async intiateWalletDistribution () {
    this.setState({isLoading: true})

    const responseObj = await liquidityService.distributeWallet({
      totalAmount: this.state.totalDistribute,
      terminalId: TERMINAL_ID,
      paymentItemCode: PAYMENT_ITEM_CODE,
      w2WRequestList: this.state.distributeFormData
    }, this.state.device.deviceUuid)

    const { status, response } = responseObj

    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: response,
      })
      return
    }
      
    this.setState({
      initiateResponseData: response,
      isLoading: false,
      canDistribute: true, 
      title: 'Distribute Summary Page'
    })
  }

  async proceedWalletDistribution () {
    this.setState({isLoading: true})

    const responseObj = await liquidityService.distributeWallet(this.state.initiateResponseData, this.state.device.deviceUuid)

    const { status, response } = responseObj

    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: response,
      })
      return
    }
      
    this.setState({
      isLoading: false,
      successMessage: 'Wallet distribution was successful.'
    })
  }

  updatedistributeFormData () {
    const distributeDetails = {
      agentCode: this.state.walletDistributionFormData.agentDetails.agentCode,
      agentName: this.state.walletDistributionFormData.agentDetails.businessName,
      beneficiaryPhone: this.state.walletDistributionFormData.agentDetails.agentMobileNo,
      amount: this.state.walletDistributionFormData.amount * 100,
      status: 'Pending'
    }
    const agentCode = this.state.walletDistributionFormData.agentDetails.agentCode
    const agents = (this.state.agents).filter(function(value) { 
      if(value.agentCode == agentCode) {
        value.selected = 0
      }  
      return value
    });

    this.setState({
      distributeFormData: [...this.state.distributeFormData, distributeDetails],
      walletDistributionFormData: {...this.state.walletDistributionFormData, agentDetails: null},
      totalDistribute: this.state.totalDistribute + parseFloat(convertToMinor(this.state.walletDistributionFormData.amount)),
      walletBalance: this.state.walletBalance - parseFloat(convertToMinor(this.state.walletDistributionFormData.amount)),
      walletBalancePercentage: (this.state.walletBalancePercentage - ((parseFloat(convertToMinor(this.state.walletDistributionFormData.amount)) / this.state.availableWalletBalance) * 100)),
      agents
    })
  }

  removeDistribute(agentCode, amount) {
    const distributeFormData = (this.state.distributeFormData).filter(function(value) { 
      return value.agentCode != agentCode;  
    });

    const agents = (this.state.agents).filter(function(value) { 
      if(value.agentCode == agentCode) {
        value.selected = 1
      }  
      return value
    });

    this.setState({
      distributeFormData,
      totalDistribute: this.state.totalDistribute - parseFloat(amount),
      walletBalance: this.state.walletBalance + parseFloat(amount),
      walletBalancePercentage: (this.state.walletBalancePercentage + ((parseFloat(amount) / this.state.availableWalletBalance) * 100)),
      agents
    })
  }

  updateAgentAmount (agentCode, oldAmount) {
    const updatedAmount = this.state.walletDistributionFormData.updatedAmount ? this.state.walletDistributionFormData.updatedAmount : convertToMajor(oldAmount)
    const distributeFormData = (this.state.distributeFormData).filter(function(value) { 
      if(value.agentCode == agentCode) {
        value.amount =  convertToMinor(updatedAmount)
      }
      return value  
    });
    this.setState({
      [agentCode]: false,
      distributeFormData,
      totalDistribute: this.state.totalDistribute + parseFloat(convertToMinor(updatedAmount)) - parseFloat(oldAmount),
      walletBalance: this.state.walletBalance - parseFloat(convertToMinor(updatedAmount)) + parseFloat(oldAmount),
      walletBalancePercentage: this.state.walletBalancePercentage + ((parseFloat(oldAmount) / this.state.availableWalletBalance) * 100) - ((parseFloat(convertToMinor(updatedAmount)) / this.state.availableWalletBalance) * 100),
      walletDistributionFormData: {...this.state.walletDistributionFormData, updatedAmount: null},
    })
  }

  
  editAgentAmount (agentCode) {
    this.setState({
      [agentCode]: true
    })
  }

  canProceed () {
    return Boolean(this.state.distributeFormData.length > 0 && this.state.walletBalancePercentage >= 0)
  }

  get currentUserCanDistribute () {
    return this.userPermissions.includes(CAN_DISTRIBUTE_WALLET)
  }

  updateWalletDistributionFormData (type, value) {
    this.setState({walletDistributionFormData: {...this.state.walletDistributionFormData, [type]: value}})
  }

  isFormValid () {
    return Boolean(this.state.walletDistributionFormData.amount > 0 && this.state.walletDistributionFormData.amount <= convertToMajor(this.state.walletBalance))
  }

  render () {
    const ctaWidgets = <React.Fragment>
      {(!this.state.canDistribute && this.currentUserCanDistribute) && <button type="button" disabled={!this.canProceed()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} class="btn btn-primary" onClick={this.intiateWalletDistribution}>Proceed</button> }
      {this.state.canDistribute && <button type="button" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} class="btn btn-primary" onClick={this.proceedWalletDistribution}>Distribute</button> }
      {this.state.canDistribute && <button type="button" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} class="btn btn-primary" onClick={() => this.setState({canDistribute: false, title: 'Distribute'}) }>Cancel</button> }
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Wallet Distribution"} withoutSearch subtitle={this.state.title} ctaWidgets={ctaWidgets}/>
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_DISTRIBUTE_WALLET]}>
        <GridLayout>
          <WalletDistributionLayout>
            <WalletDistributionForm 
              updateWalletDistributionFormData={this.updateWalletDistributionFormData}
              agents={this.state.agents}
              updatedistributeFormData={this.updatedistributeFormData}
              walletBalancePercentage={this.state.walletBalancePercentage}
              walletBalance={this.state.walletBalance}
              walletDistributionFormData={this.state.walletDistributionFormData}
              canDistribute={this.state.canDistribute}
              isFormValid={this.isFormValid()}
            />
            <WalletDistributionTable 
              distributeFormData={this.state.distributeFormData}
              editAgentAmount={this.editAgentAmount}
              updateAgentAmount={this.updateAgentAmount}
              removeDistribute={this.removeDistribute}
              canDistribute={this.state.canDistribute}
              updateWalletDistributionFormData={this.updateWalletDistributionFormData}
              state={this.state}
            />
            <WalletBalanceSummary 
              canDistribute={this.state.canDistribute}
              availableWalletBalance={this.state.availableWalletBalance}
              totalDistribute={this.state.totalDistribute}
              walletBalance={this.state.walletBalance}
            />
          </WalletDistributionLayout>
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
