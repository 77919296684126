import React from 'react'
import Select from '../../../../../../components/select'
import SettlementTypes from '../../../../../../fixtures/settlement_types.json'

export default class ServiceOptionForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Name:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.name : ''} class="form-control" placeholder="Name" onChange={event => this.props.onChange('name', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Display Name:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.displayName : ''} class="form-control" placeholder="Display Name" onChange={event => this.props.onChange('displayName', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Service Name:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.serviceName : ''} class="form-control" placeholder="Service Name" onChange={event => this.props.onChange('serviceName', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Service Type:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.serviceType : ''} class="form-control" placeholder="Service Type" onChange={event => this.props.onChange('serviceType', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Payment Code:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.paymentCode : ''} class="form-control" placeholder="Payment Code" onChange={event => this.props.onChange('paymentCode', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Paydirect Item Code:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.paydirectItemCode : ''} class="form-control" placeholder="Payment Item Code" onChange={event => this.props.onChange('paydirectItemCode', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Settlement Interval:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.settlementInterval : ''} class="form-control" placeholder="Settlement Interval" onChange={event => this.props.onChange('settlementInterval', event.target.value)} disabled={this.props.disabled ? this.props.disabled : ''}/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Settlement Type:<br></br></label>
                            <Select 
                                options={SettlementTypes.map(detail => { return ( {label: detail.name, value: detail.value} ) })}
                                onChange={event => this.props.onChange('settlementType', event.value)} 
                                disabled={this.props.disabled}
                                defaultValue={this.props.serviceOptionFormData.settlementType}
                            />
                            {/* <select autoComplete="off" type="text" value={this.props.serviceOptionFormData ? this.props.serviceOptionFormData.settlementType : ''} class="form-control" placeholder="Settlement Type" onChange={event => this.props.onChange('settlementType', event.target.value)} disabled={this.props.disabled ? this.props.disabled : ''}>
                                <option selected disabled>Select</option>
                                {SettlementTypes.map(detail => <option value={detail.value}>{detail.name}</option>)}
                            </select> */}
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}