import React from "react";
import { AccessDenied } from "../../../../../../components/access-denied";
import ContentHead from "../../../../../../components/content-head";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import {
  CAN_CANCEL_APPLICATION,
  CAN_CREATE_APPLICATION,
  CAN_VIEW_APPLICATION,
} from "../../../../../../constants/permissions";
import { onboarding } from "../../../../../../mixins/api";
import { validateApplicationPermission } from "../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { formatBackEndDateTime } from "../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../utils/helper";
import ApplicationSearch from "../../components/application-search";
import ApplicationTable from "../../components/application-table";
import NewApplicationButton from "../../components/new-application-button";

export default class ApplicationsGridScene extends BaseScene {
  metadataSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      applicationSearchParameters: {
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate"),
        // approvalStatus: "0",
        // fromDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        // toDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      useDefaultDate: true,
    };
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getApplications = this.getApplications.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.updateApplicationStatus = this.updateApplicationStatus.bind(this);
  }

  sceneName = "application-management";

  componentDidMount() {
    this.setState({
      applicationSearchParameters: {
        ...this.state.applicationSearchParameters,
        approvalStatus: validateApplicationPermission(),
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 3000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 3000);
    }
  }

  async getApplications(pageNum, pageSize) {
    const payload = this.state.applicationSearchParameters;
    payload.fromDate =
      payload.fromDate && formatBackEndDateTime(payload.fromDate);
    payload.toDate = payload.toDate && formatBackEndDateTime(payload.toDate);
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await onboarding.searchApplications(
      pageNum,
      pageSize,
      this.state.applicationSearchParameters
    );
    const { status, response } = responseObj;
    const { content, count } = response;
    if (status === SUCCESS_STATUS) {
      this.setState({
        applications: content,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        dataCount: response.count,
        tableMessage: content.length == 0 ? "No applications found" : "",
      });
    } else {
      this.setState({
        applications: [],
        applicationExportData: [],
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        errorMessage: response,
        tableMessage: "No applications found",
      });
    }
    window.scrollTo(0, this.metadataSectionRef.current);
  }

  handleSearchRequest(applicationSearchParameters) {
    this.setState({
      startPage: 1,
      applicationSearchParameters,
    });
    setTimeout(() => {
      this.getApplications(1, this.state.pageSize);
    }, 200);
  }

  getClickedAction(applicationId, applicationName, applicationStatus) {
    this.setState({
      applicationId,
      applicationName,
      applicationStatus,
    });
  }

  async updateApplicationStatus() {
    this.setState({ isLoading: true });
    const responseObj = await onboarding.updateApplicationStatus(
      this.state.applicationId,
      this.state.applicationStatus
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        applicationId: null,
        applicationName: null,
        applicationStatus: null,
        successMessage: `Application was successfully cancelled!`,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getApplications();
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />
        <ContentHead
          title={"Application Management"}
          subtitle={"All Applications"}
          totalCount={56}
          applicationButton={<NewApplicationButton />}
          filterPermissions={[CAN_VIEW_APPLICATION]}
          actionButtonRequiredPermissions={[CAN_CREATE_APPLICATION]}
          showTooltip={true}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_APPLICATION]}
        >
          <GridLayout>
            <ApplicationSearch handleSearchRequest={this.handleSearchRequest} />
            <ApplicationTable
              data={this.state.applications}
              showApplicantName={false}
              showBusinessName={true}
              getApplications={this.getApplications}
              pages={this.state.pages}
              startPage={this.state.startPage}
              noDataText={this.state.tableMessage}
              loading={this.state.isLoading}
              dataCount={this.state.dataCount}
              page={this.state.page}
              pageSize={this.state.pageSize}
              getClickedAction={this.getClickedAction}
              canView={CAN_VIEW_APPLICATION}
              canCancel={CAN_CANCEL_APPLICATION}
              canLoadData={this.state.canLoadData}
            />
            <PromptModal
              modalTitle={this.state.applicationStatus + ` Application`}
              modalBody={`Do you want to ${this.state.applicationStatus} ${this.state.applicationName} application?`}
              deleteModal={this.updateApplicationStatus}
              modalSize={`modal-md`}
              height={`230px`}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
