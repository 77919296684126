import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import BaseScene from "../../../../components/scenes/component";
import MetricGridScene from "./scenes/view-performance-metric/scene";
// import AddPermissionScene from './scenes/add-permission'
// import ViewPermissionScene from './scenes/view-permission'
import { CAN_VIEW_PERMISSION } from "../../../../constants/permissions.js";
import { isAuthorized } from "../../../../mixins/authentication";
export default class PerformanceMetricScene extends BaseScene {
  sceneName = "Performance-Metrics";
  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };

    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_PERMISSION]}
          exact
          path="/home/performance-metrics"
          component={MetricGridScene}
        />
        {/* <ProtectedRoute
          requiredPermissions={[CAN_VIEW_PERMISSION]}
          exact
          path="/home/performance-metrics/config"
          component={metricConfigScene}
        /> */}
        {/* <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION]} exact path='/home/permission-management/:id/view' component={ViewPermissionScene} /> */}
      </Router>
    );
  }
}
