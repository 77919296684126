import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { SuperAgentForm } from "../../../../../../../components/forms/super-agent-form";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../constants/api";
import { CAN_CREATE_SERVICE_PROVIDER } from "../../../../../../../constants/permissions";
import BusinessTypes from "../../../../../../../fixtures/business_types.json";
import { platformManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";

export default class AddSuperAgentScene extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      emailIsValid: true,
      phoneIsValid: true,
      websiteIsValid: true,
      createdBy: 0,
      businessTypes: BusinessTypes,
      superAgentFormData: [],
      isLoading: false,
    };
    this.requiredSuperAgentFields = [
      "businessEmail",
      "businessName",
      "businessPhoneNo",
      "companyRegNo",
      "website",
      "businessTypeId",
    ];
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.updateSuperAgentFormData = this.updateSuperAgentFormData.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.createServiceProvider(
      this.state.superAgentFormData
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new super agent has been sent successfully for approval.",
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Super agent created successfully.",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  isFormValid() {
    const emptyRequiredSuperAgentDetailsFields = this.requiredSuperAgentFields.filter(
      (value) => {
        return Boolean(this.state.superAgentFormData[value]) === false;
      }
    );

    let status;

    if (emptyRequiredSuperAgentDetailsFields.length === 0) {
      status = true;
    } else {
      status = false;
    }
    return Boolean(
      !status ||
        !this.state.emailIsValid ||
        !this.state.phoneIsValid ||
        !this.state.websiteIsValid
    );
  }

  updateSuperAgentFormData(type, value) {
    this.setState({
      superAgentFormData: { ...this.state.superAgentFormData, [type]: value },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/super-agent-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_SERVICE_PROVIDER]}>
          <button
            type="button"
            disabled={this.isFormValid()}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Super Agents"}
          withoutSearch
          subtitle="Create a Super Agent"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_CREATE_SERVICE_PROVIDER]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <SuperAgentForm
              updateSetState={this.updateSetState}
              updateSuperAgentFormData={this.updateSuperAgentFormData}
              businessTypes={this.state.businessTypes}
              superAgentFormData={this.state.superAgentFormData}
              disabled={false}
              emailIsValid={this.state.emailIsValid}
              websiteIsValid={this.state.websiteIsValid}
              phoneIsValid={this.state.phoneIsValid}
              showPhoneNumber={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
