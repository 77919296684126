import React from 'react'
import { formatDateTime } from '../../../../../../utils/formatter'

export default class ApprovalHistory extends React.Component {
    render() {
        return <React.Fragment>
            <h5>History</h5>
            <div class="kt-scroll" data-scroll="true" data-mobile-height="764" style={{height: '945px', overFlow: 'hidden'}}>
                <div class="kt-timeline">
                    {this.props.approvalDetails.history && this.props.approvalDetails.history.map(detail => {
                        return (
                            <div class="kt-timeline__item kt-timeline__item--accent">
                                <div class="kt-timeline__item-section">
                                    <div class="kt-timeline__item-section-border">
                                        <div class="kt-timeline__item-section-icon">
                                            <i class="flaticon2-download-symbol kt-font-success"></i>
                                        </div>
                                    </div>
                                    <span class="kt-timeline__item-datetime">{formatDateTime(detail.date)} {` - Step ${detail.step}`}</span>
                                </div>
                                <a href="" class="kt-timeline__item-text">
                                    {detail.message}                      
                                </a>                             
                                <div class="kt-timeline__item-info">
                                    <strong>{detail.name}</strong><br/>
                                    {detail.approver}                                                                            
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    }
}