import React from 'react';
import {
  convertToMajor,
  convertToMinor,
} from '../../../../../../utils/converter';
import { Form } from 'react-bootstrap';
import { formatCurrency } from '../../../../../../utils/formatter';
// import {
//   formatTransactionStatus
// } from "../../../../../../utils/formatter";

export default class EditStockModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      inputKey: Date.now(),
    };
  }

  render() {
    const regex = /^[0-9]*$/;
    const resul = this.props.posDetails ? this.props.posDetails : '';

    return (
      <React.Fragment>
        <div>
          <div className='m-5'>
            <form
              class='kt-form kt-form--label-right'
              aria-labelledby='headingOne4'
            >
              <div class='kt-portlet__body'>
                <div>
                  <div class='form-group row'>
                    <div class='col-lg-6'>
                      <label>
                        <strong>POS model</strong>
                      </label>
                      <br />
                      <span>
                        <input
                          type='text'
                          disabled
                          class='search-input'
                          onChange={(event) =>
                            this.props.updateEditDetails(
                              'posModel',
                              event.target.value
                            )
                          }
                          defaultValue={resul.terminalModel}
                          // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                        />
                      </span>
                    </div>
                    <div class='col-lg-6'>
                      <label>
                        <strong>Price</strong>
                      </label>
                      <br />
                      <span>
                        <input
                          type='text'
                          class='search-input'
                          onKeyPress={(event) => {
                            if (
                              (event.which !== 8 &&
                                event.which !== 0 &&
                                event.which < 48) ||
                              event.which > 57
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(event) =>
                            this.props.updateEditDetails(
                              'posPrice',
                              event.target.value
                            )
                          }
                          defaultValue={convertToMajor(resul.price)}
                          // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                          // placeholder={resul.code}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class='form-group row'>
                    <div class='col-lg-6'>
                      <label>
                        <strong>POS Image URL</strong>
                      </label>
                      <br />

                      <span>
                        <input
                          type='text'
                          class='search-input'
                          value={resul.terminalImage}

                          // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                        />
                      </span>
                    </div>
                    <div class='col-lg-6'>
                      <label>
                        <strong>Upload new POS image</strong>
                      </label>
                      <br />
                      <span>
                        {/* <input type="file" name="image" accept="image/*"> */}
                        <input
                          // for picking a new image entirely
                          key={this.state.inputKey}
                          type='file'
                          name='image'
                          accept='image/*'
                          class='search-input'
                          onChange={(event) =>
                            this.props.updateEditDetails(
                              // this.props.UploadPosImage(
                              'file',
                              event.target.files
                            )
                          }
                          defaultValue={resul.file}
                        />
                      </span>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div class='col-lg-12'>
                      <label>
                        <strong>POS Model Description(Optional)</strong>
                      </label>
                      <br />
                      <span>
                        <Form.Control
                          as='textarea'
                          onChange={(event) =>
                            this.props.updateEditDetails(
                              'description',
                              event.target.value
                            )
                          }
                          defaultValue={resul.terminalDescription}
                          style={{ height: '100px' }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class='modal-footer'>
            <button
              type='button'
              class='btn btn-primary'
              style={{
                height: '40px',
                width: '100px',
                padding: '0px',
                borderRadius: '15px',
                border: '2px solid #00425f',
                backgroundColor: '#ffffff',
                color: '#00425f',
                fontSize: '14px',
                margin: 'auto',
                display: 'block',
              }}
              onClick={() => {
                this.props.handleClose();
              }}
            >
              Close
            </button>
            <button
              type='button'
              class='btn btn-primary'
              style={{
                height: '40px',
                width: '100px',
                padding: '0px',
                borderRadius: '15px',

                backgroundColor: '#00425f',
                color: '#ffffff',
                fontSize: '14px',
                margin: 'auto',
                display: 'block',
              }}
              onClick={async () => {
                this.props.handleClose();
                await this.props.editPosDetails();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
