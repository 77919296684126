import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import BaseScene from '../../../../../../../components/scenes/component';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
// import XLSX from 'xlsx'
// import { ExportSheet } from 'react-xlsx-sheet'
import { AccessDenied } from '../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_AUDIT } from '../../../../../../../constants/permissions.js';
import { auditService } from '../../../../../../../mixins/api';
import { formatBackEndDateTimeFrom, formatBackEndDateTimeTo } from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import AuditTable from '../../components/audit-report-table';
import AuditSearch from '../../components/audit-search';

export default class AuditReportGridScene extends BaseScene {
  
  sceneName = 'audit-report'

  constructor(props) {
    super(props);
    this.state = {
      auditTrails: [],
      audits: [],
      pages: 0,
      page: 0,
      searchParams: {
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate")
      },
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      tableMessage: ""
    }
    this.getAuditReport = this.getAuditReport.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
  }


  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAuditReport (pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true
    })
    const payload = this.state.searchParams
    payload.fromDate = payload.fromDate && formatBackEndDateTimeFrom(payload.fromDate, true)
    payload.toDate = payload.toDate && formatBackEndDateTimeTo(payload.toDate, true)
    const responseObj = await auditService.getAuditReport(pageNum, pageSize, payload)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        audits: response.data.lgs,
        dataCount: response.data.count,
        pages: Math.ceil(response.data.count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
      })
    }else{
      this.setState({
        audits: [],
        dataCount: 0,
        pages: Math.ceil(0/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No audit report found"
      })
    }
  }

  handleSearchRequest (searchParams) {
    this.setState({
      searchParams,
      startPage: 1
    })
    setTimeout(() => {
      this.getAuditReport(1, 20)
    }, 500)
  }

  render () {
    return <React.Fragment>
      <ContentHead title={"Audit Report"} filterPermissions={[CAN_VIEW_AUDIT]} totalCount={56} />
      <Notification 
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_AUDIT]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <AuditSearch 
            handleSearchRequest={this.handleSearchRequest}
            searchParams={this.state.searchParams} 
          />
          <AuditTable 
            pages={this.state.pages}
            getAuditReport={this.getAuditReport}
            data={this.state.audits} 
            startPage={this.state.startPage}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            pageSize={this.state.pageSize}
            page={this.state.page}
            canLoadData={this.state.canLoadData}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }

}
