import { connect } from "react-redux";
import {
  updateCurrentPathName,
  updateScene,
} from "../../../../../../../services/redux/actions/navigation";
import CategoryUpdateScene from "./scene";

function mapStateToProps(state) {
  return {
    scene: state.navigation.scene,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCurrentPathName: (pathName) =>
      dispatch(updateCurrentPathName(pathName)),
    updateScene: (scene) => dispatch(updateScene(scene)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryUpdateScene);
