import React from 'react'
import { capitalizeFirstLetter, splitStringByUppercase } from '../../../../../../utils/helper'

export default class AuditData extends React.Component {
    render () {
        return <React.Fragment>
            <div class="kt-portlet">
                <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne2" aria-controls="collapseOne2">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                        <h3 class="kt-portlet__head-title">
                            Audit Data After
                        </h3>
                    </div>
                </div>

                <form class="kt-form kt-form--label-right" id="collapseOne2" class="collapse show" aria-labelledby="headingOne1">
                    <div class="kt-portlet__body">
                        <table class="table">
                            <tr class="thead-light">
                                <th>Key</th>
                                <th scope="col">Value</th>
                            </tr>
                            {this.props.auditData && Object.entries(this.props.auditData).map(([key, value]) => {
                                if(typeof value !== 'object') {
                                    return ( 
                                        <tr>
                                            <th scope="row">{splitStringByUppercase(capitalizeFirstLetter(key, false))}</th>
                                            <td>{value}</td>
                                        </tr>
                                    )
                                }
                            })}
                        </table>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}