import React from 'react'

export default class BatchCreditDebitForm extends React.Component {
    render() {
        return <React.Fragment>
            <form>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Business Name</label>
                        <p>{this.props.selectedAgent.businessName}</p>
                    </div>
                    <div class="col-lg-6">
                        <label>Agent Phone Number</label>
                        <p>{this.props.selectedAgent.agentMobileNo}</p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Wallet Type</label>
                        <select class="form-control" autoComplete="off" value={this.props.selectedAgent.walletType ? this.props.selectedAgent.walletType : ''} onChange={event => this.props.updateSelectedAgent('walletType', event.target.value)}>
                            <option value={''} selected disabled>Select</option>
                            <option value="COM">Commission Wallet</option>
                            <option value="TRAN">Transaction Wallet</option>
                        </select>
                    </div>
                    <div class="col-lg-6">
                        <label>Amount</label>
                        <input type="text" class="form-control" autoComplete="off" value={this.props.selectedAgent.amount ? this.props.selectedAgent.amount : ''} onChange={event => this.props.updateSelectedAgent('amount', event.target.value)}/>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Narration</label>
                        <textarea class="form-control non-resizable-textarea" rows={5} cols={20} style={{resize: 'none'}} autoComplete="off" value={this.props.selectedAgent.narration ? this.props.selectedAgent.narration : ''} onChange={event => this.props.updateSelectedAgent('narration', event.target.value)}></textarea>
                        {/* <input type="text" class="form-control" autoComplete="off" onChange={event => this.props.updateSelectedAgent('narration', event.target.value)}/> */}
                    </div>
                    <div class="col-lg-6">
                        <label>Service Provider Reference</label>
                        <input type="text" class="form-control" autoComplete="off" value={this.props.selectedAgent.serviceProviderReference ? this.props.selectedAgent.serviceProviderReference : ''} onChange={event => this.props.updateSelectedAgent('serviceProviderReference', event.target.value)}/>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}