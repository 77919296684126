import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LandingBanner from '../../components/landing-banner';
import { GridItem } from '../../components/layouts/grid-item';
import { HorizontalGrid } from '../../components/layouts/grids/horizontal';
import { VerticalGrid } from '../../components/layouts/grids/vertical';
import { LOGIN_SUCCESS, MULTIPLE_PROFILE, PASSWORD_EXPIRY, SUCCESS_STATUS } from '../../constants/api';
import { CHANGEPASSWORD, DASHBOARD } from '../../constants/routes';
import { FORGOT_PASSWORD_TOKEN } from '../../constants/session-storage';
import { userManagement } from '../../mixins/api';
import { clearLocalStorage, getDeviceInformation, saveOtherInformation, saveSessionInformation } from '../../mixins/authentication';
import { LoginForm } from './components/login-form';
import { LoginProfile } from './components/login-profile';

export default class LoginScene extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginSuccessful: null,
      isLoading: false,
      emailIsValid: false,
      errorMessage: null,
      hidePassword: false,
      loginFormData: {},
    }
    this.isFormValid = this.isFormValid.bind(this)
    this.updateLoginFormData = this.updateLoginFormData.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
    this.attemptLogin = this.attemptLogin.bind(this)
  }

  componentDidMount() {
    clearLocalStorage();
    this.setState({loginFormData: {...this.state.loginFormData, device: getDeviceInformation()}})
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async attemptLogin() {
    this.setState({ 
      errorMessage: null,
      isLoading: true
    })
    const getUserResponseObj = await userManagement.loginUser(this.state.loginFormData)
    const getUserResponse = getUserResponseObj.response
    const getUserStatus = getUserResponseObj.status
    if (getUserStatus === SUCCESS_STATUS) {
      if(getUserResponse.code === PASSWORD_EXPIRY){
        saveOtherInformation(FORGOT_PASSWORD_TOKEN, getUserResponse.data)
        this.setState({expirePasswordRedirect: true})
        return
      }else if(getUserResponse.code === MULTIPLE_PROFILE){
        this.setState({
          userProfile: getUserResponse.data,
          isLoading: false,
          showUserProfile: true
        })
      }else if(getUserResponse.code === LOGIN_SUCCESS) {
        saveSessionInformation(JSON.stringify(getUserResponse.data))
        this.setState({
          loginSuccessful: true,
          isLoading: false
        })
      }else{
        this.updateLoginFormData('domainTypeId', null)
        this.setState({
          loginSuccessful: false,
          isLoading: false,
          errorMessage: null,
          showUserProfile: false
        })  
      }
    }
    else {
      this.updateLoginFormData('domainTypeId', null)
      this.setState({
        loginSuccessful: false,
        isLoading: false,
        errorMessage: getUserResponse,
        showUserProfile: false
      })
    }
  }

  isFormValid () {
    return Boolean(!this.state.loginFormData || !this.state.loginFormData.username || !this.state.loginFormData.password)
  }

  updateLoginFormData (type, value) {
    this.setState({loginFormData: {...this.state.loginFormData, [type]: value}})
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  render() {
    if (this.state.loginSuccessful === true) {
      return <Redirect to={DASHBOARD} />
    }

    if(this.state.expirePasswordRedirect === true) {
      return <Redirect to={CHANGEPASSWORD} />
    }

    return <React.Fragment>
      <VerticalGrid isRoot>
        <HorizontalGrid isRoot class="kt-login kt-login--v1">
          <GridItem isFluid class="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <LandingBanner />
            {!this.state.showUserProfile && <LoginForm
              loginFormData={this.state.loginFormData}
              updateSetState={this.updateSetState}
              updateLoginFormData={this.updateLoginFormData}
              isLoading={this.state.isLoading}
              hidePassword={this.state.hidePassword}
              emailIsValid={this.state.emailIsValid}
              attemptLogin={this.attemptLogin}
              isFormValid={this.isFormValid}
              loginSuccessful={this.state.loginSuccessful}
              errorMessage={this.state.errorMessage}
            />}
            {this.state.showUserProfile && <LoginProfile
              isLoading={this.state.isLoading}
              userProfile={this.state.userProfile}
              updateLoginFormData={this.updateLoginFormData}
              updateSetState={this.updateSetState}
              errorMessage={this.state.errorMessage}
              attemptLogin={this.attemptLogin}
              loginSuccessful={this.state.loginSuccessful}
              loginFormData={this.state.loginFormData}
            />}
          </GridItem>
        </HorizontalGrid>
      </VerticalGrid>

    </React.Fragment>
  }
}