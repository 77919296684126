import React from 'react'
import ProtectedComponent from '../../.../../../../../../mixins/protected-component';

export default class WorkflowStepDetails extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-timeline__item-section">
                <div class="kt-timeline__item-section-border">
                    <div class="kt-timeline__item-section-icon">
                        <i class="flaticon-feed kt-font-success"></i>
                    </div>
                </div>
                <span class="kt-timeline__item-datetime">Step {this.props.stepId}</span>
                <div class="mx-auto">
                    <div class="dropdown dropdown-inline">
                        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="flaticon-more-1"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <ul class="kt-nav">
                                <ProtectedComponent requiredPermissions={[this.props.canAddStep]}>
                                    <li class="kt-nav__item">
                                        <a class="kt-nav__link" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getAddApproverDetails(this.props.stepId)}>
                                            <i class="kt-nav__link-icon la la-user"></i>
                                            <span class="kt-nav__link-text">Add Approver</span>
                                        </a>
                                    </li>
                                </ProtectedComponent>
                                <ProtectedComponent requiredPermissions={[this.props.canMoveStep]}>
                                    <li class="kt-nav__item">
                                        <a class="kt-nav__link" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getMoveStepDetails(this.props.stepId)}>
                                            <i class="kt-nav__link-icon la la-arrows"></i>
                                            <span class="kt-nav__link-text">Move Step</span>
                                        </a>
                                    </li>
                                </ProtectedComponent>
                                <ProtectedComponent requiredPermissions={[this.props.canRemoveStep]}>
                                    <li class="kt-nav__item">
                                        <a class="kt-nav__link" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getRemoveStepDetails(this.props.stepId)}>
                                            <i class="kt-nav__link-icon la la-trash"></i>
                                            <span class="kt-nav__link-text">Remove</span>
                                        </a>
                                    </li>
                                </ProtectedComponent>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
        </React.Fragment>
    }
}