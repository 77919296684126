import React from 'react'
import { BalanceCard } from './balance-card'

export default class WalletBalance extends React.Component {
    state = {
        showTransactionLedgerBalance: false,
        showTransactionAvailableBalance: false,
        showCommissionLedgerBalance: false,
        showCommissionAvailableBalance: false,
        showTransactionPAN: false,
        showCommissionPAN: false
    }

    updateSetState = this.updateSetState.bind(this)

    updateSetState (type) {
        this.setState({[type]: !this.state[type]})
    }

    showLastBalanceTime () {
        return this.state.showTransactionLedgerBalance || this.state.showTransactionAvailableBalance || this.state.showCommissionLedgerBalance || this.state.showCommissionAvailableBalance
    }

    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                {!this.props.hideTitleHeader && <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target="#collapseOne8" aria-controls="collapseOne8">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Wallet Details
                        </h3>
                    </div>
                </div>}
                {/* <form id="collapseOne8" class={`collapse ${this.props.showContent ? 'show' : ''}`}>
                    <div class="kt-portlet__body">
                        {this.props.showWalletJournalLink && <ProtectedComponent requiredPermissions={[this.props.canViewTransaction]}><Link onClick={() => this.props.redirectToWalletJournal()} >
                            View Wallet Journal
                        </Link></ProtectedComponent>}    
                        <small>
                            {this.props.lastBalanceTime ? `Balance ${this.showLastBalanceTime() ? `as @ ${this.props.lastBalanceTime}` : `hidden`}`: ''}
                            <button type="button" class="pull-right" style={{backgroundColor: '#00425f', borderColor: '#00425f', color: 'white'}}><i onClick={this.props.getAllBalances} class={`la la-refresh la-lg ${this.props.reloadWalletBalance ? `la-spin` : ''} `} style={{cursor: 'pointer'}}></i></button>
                        </small>
                        <br/>
                        <div class="row">
                            <BalanceCard 
                                title="Transaction Ledger Balance"
                                balance={this.props.ledgerBalance}
                                showBalance={this.state.showLedgerBalance}
                                updateSetState={this.updateSetState}
                                showType="showLedgerBalance"
                            />
                            <BalanceCard 
                                title="Transaction Available Balance"
                                balance={this.props.transactionBalance}
                                showBalance={this.state.showTransactionBalance}
                                updateSetState={this.updateSetState}
                                showType="showTransactionBalance"
                            />
                            <BalanceCard 
                                title="Commission Balance"
                                balance={this.props.commissionBalance}
                                showBalance={this.state.showCommissionBalance}
                                updateSetState={this.updateSetState}
                                showType="showCommissionBalance"
                            />
                        </div>
                    </div>
                </form> */}
                <form id="collapseOne8" class={`collapse ${this.props.showContent ? 'show' : ''}`}>
                    <div class="kt-portlet__body">
                        {/* {this.props.showWalletJournalLink && <ProtectedComponent requiredPermissions={[this.props.canViewTransaction]}><Link onClick={() => this.props.redirectToWalletJournal()} >
                            View Statement of account
                        </Link></ProtectedComponent>}   */}
                        <p>  
                            <small>
                                {this.props.lastSuccessfulWalletDetailsTime ? `Balance ${this.showLastBalanceTime() ? `as @ ${this.props.lastSuccessfulWalletDetailsTime}` : `hidden`}`: ''}
                                <button type="button" class="pull-right" style={{backgroundColor: '#00425f', borderColor: '#00425f', color: 'white'}}><i onClick={this.props.getAllBalances} class={`la la-refresh la-lg ${this.props.reloadWalletBalance ? `la-spin` : ''} `} style={{cursor: 'pointer'}}></i></button>
                            </small>
                        </p>
                        <br/>
                        <div class="row">
                            <BalanceCard 
                                title="Transaction Ledger Balance"
                                balance={this.props.transactionLedgerBalance}
                                showBalance={this.state.showTransactionLedgerBalance}
                                updateSetState={this.updateSetState}
                                showType="showTransactionLedgerBalance"
                                isBalance={true}
                            />
                            <BalanceCard 
                                title="Transaction Available Balance"
                                balance={this.props.transactionAvailableBalance}
                                showBalance={this.state.showTransactionAvailableBalance}
                                updateSetState={this.updateSetState}
                                showType="showTransactionAvailableBalance"
                                isBalance={true}
                            />
                            <BalanceCard 
                                title="Transaction Post card Account"
                                postCardAccount={this.props.transactionPAN}
                                showPostCardAccount={this.state.showTransactionPAN}
                                updateSetState={this.updateSetState}
                                showType="showTransactionPAN"
                                isPostCardAccount={true}
                            />
                        </div>
                    </div>
                </form>
                <form id="collapseOne8" class={`collapse ${this.props.showContent ? 'show' : ''}`}>
                    <div class="kt-portlet__body">
                        <div class="row">
                            <BalanceCard 
                                title="Commission Ledger Balance"
                                balance={this.props.commissionLedgerBalance}
                                showBalance={this.state.showCommissionLedgerBalance}
                                updateSetState={this.updateSetState}
                                showType="showCommissionLedgerBalance"
                                isBalance={true}
                            />
                            <BalanceCard 
                                title="Commission Available Balance"
                                balance={this.props.commissionAvailableBalance}
                                showBalance={this.state.showCommissionAvailableBalance}
                                updateSetState={this.updateSetState}
                                showType="showCommissionAvailableBalance"
                                isBalance={true}
                            />
                            <BalanceCard 
                                title="Commission Post Card Account"
                                postCardAccount={this.props.commissionPAN}
                                showPostCardAccount={this.state.showCommissionPAN}
                                updateSetState={this.updateSetState}
                                showType="showCommissionPAN"
                                isPostCardAccount={true}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}