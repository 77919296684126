import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class posRequestsAwaitingConfigUpload {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getRequestsAwaitingConfigUpload(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request/awaiting-configuration-upload`,
      args: {
        pageNum,
        pageSize,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }

  //   getAllPosUploadRequests(payload) {
  //     const {
  //       pageNumber, pageSize, fromDate, toDate,
  //       approvalStatus, agentUsername
  //     } = payload
  //     return this.apiRequester.get({
  //       endpoint: `pos-request/search`,
  //       args: {
  //         pageNumber: pageNumber,
  //         pageSize,
  //         ...(approvalStatus != 'All' ? { approvalStatus } : {}),
  //         ...(fromDate != null ? { fromDate: fromDate, toDate: toDate } : {})
  //       }
  //     })
  //   }

  getRequestsAwaitingConfigUploadBySearch(
    pageNumber = null,
    pageSize = null,
    // approvalStatus = null,
    // businessMobileNo = null,
    agentUsername = null,
    // dateApprovedOrDeclined = null,
    // dateInitiated = null,
    toDate = null,
    fromDate = null,
    status = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request/awaiting-configuration-upload/search`,
      args: {
        agentUsername,
        // approvalStatus,
        // dateApprovedOrDeclined,
        // dateInitiated,
        toDate,
        fromDate,
        pageNumber,
        pageSize,
        status,
      },
    });
  }

  uploadPosRequestsAwaitingConfigUpload(stockId, workflowId) {
    return this.apiRequester.put({
      endpoint: "pos-request/awaiting-configuration-upload/",
      headers: { posWorkflowId: workflowId, posRequestStockId: stockId },
    });
  }

  getAllDeliveryNotesBySearch(
    pageNumber = null,
    pageSize = null,
    agentMobile = null,
    toDate = null,
    fromDate = null
    // requestId = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-delivered/search`,
      args: {
        agentMobile,
        toDate,
        fromDate,
        pageNumber,
        pageSize,
        // requestId,
      },
    });
  }

  downloadDeliveryNotes(payload) {
    const {
      pageNum,
      pageSize,
      fromDate,
      toDate,
      posRequestsStatus,
      batchId,
      deviceModel,
      deviceType,
    } = payload;
    return this.apiRequester.get({
      endpoint: `pos-delivered/download`,
      args: {
        pageNumber: pageNum,
        pageSize: pageSize,
        // ...(batchId != "" ? { batchId } : {}),
        // ...(deviceModel != "" ? { deviceModel } : {}),
        // ...(deviceType != "All" ? { deviceType } : {}),
        // ...(posRequestsStatus != 'awaiting_configuration' ? { posRequestsStatus } : {}),
        status: posRequestsStatus,
        // status: 'awaiting_configuration',
        ...(fromDate != null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
      headers: {
        download: true,
      },
    });
  }
}
