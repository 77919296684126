import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { CommissionHistoryExportSerializer } from '../../../../../../serializers';
import { convertToMajor } from '../../../../../../utils/converter';
import { formatCurrency, formatDateTime, formatTransactionStatus, formatTransactionStatusColorCode } from '../../../../../../utils/formatter';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class CommissionHistoryTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '430px',
        zoom: 'plus'
      }
    
    zoomTableHeight = this.zoomTableHeight.bind(this)
    
    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }
    
    render() {
          const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            {
                Header: "Transaction Reference",
                accessor: 'transactionRef',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 240,
            },
            {
                Header: "Transaction Type",
                accessor: 'transactionType',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 140,
                Cell: props => <span class="word-capitalize">{props.value ? props.value.toLowerCase() : ''}</span>
            },
            {
              Header: "Amount",
              accessor: 'amount',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 130,
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
          },
            {
              Header: "Commission Amount",
              accessor: 'fee',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 130,
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
          },
          {
            Header: "Status",
            accessor: 'status',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
            width: 80,
            Cell: props => {
                return props.value ? <span class={`badge ${formatTransactionStatusColorCode(props.value)}`}>{formatTransactionStatus(props.value)}</span> : ''
            }
        },
        {
          Header: "Balance After",
          accessor: 'availableBalance',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
          width: 130,
          Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
      },
            {
              Header: "Event",
              accessor: 'event',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 80,
              Cell: props => <span>{props.value}</span>
          },
          // {
          //   Header: "Agent Phone Number",
          //   accessor: 'agentPhoneNumber',
          //   resizable: false,
          //   headerStyle: {'white-space': 'unset'},
          //   style: {'white-space': 'unset'},
          //   width: 150,
          //   Cell: props => <span>{props.value}</span>
          // },
          {
            Header: "Parent Reference",
            accessor: 'parentRef',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
            width: 170,
            Cell: props => <span>{props.value}</span>
          },
            {
                Header: "Date",
                accessor: 'dateCreated',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 170,
                Cell: props => {
                    return props.value ? formatDateTime(props.value) : ''
                }
            }
        ]

        return <React.Fragment> 
          <TableExtra 
            data={CommissionHistoryExportSerializer.reverseCommissionHistoryDetails(this.props.data)}
            headers={CommissionHistoryExportSerializer.headerExport()}
            fileName={'commissions'}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getCommissions}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            disabled={this.props.loading}
        />
        <ReactTable 
            PaginationComponent={Pagination} 
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ''}
            style={{
                border: "none",
                boxShadow: "none",
                whiteSpace: 'unset',
                height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
            onFetchData={(state, instance) => {
                const {
                    
                    transactionTypeInt,
                    start,
                    end,
                    agentPhoneNumber,
                    event,
                    parentRef,
                    transactionRef
                } = this.props.searchParameters
                this.setState({page: state.page})
                if(this.props.canLoadData) {
                  this.props.getCommissions(state.page + 1, state.pageSize, transactionTypeInt, start, end, event, agentPhoneNumber, parentRef, transactionRef)
                }
            }}
            headerNodeHeight={`123`}
            minRows={(this.props.data).length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
              style: {
                display:"flex",
                overflow: "visible",
                color: "black",
                fontSize: "11.5px",
                fontWeight: "50",
                paddingLeft:"5px",
                paddingRight:"5px",
                alignItems: "center",
                padding:  "0px, 0",
                
              },
              })}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  //backgroundColor: '#FFFFFF',
                  lineHeight: '20px',
                },
              })}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                  backgroundColor: '#00425f',
                  borderBottom: '0.1px solid #DBDEDE',
                  borderWidth: '0.1px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  color: '#FFFFFF',
                  fontSize: '12px',
                  fontWeight: '500',
                  textAlign: 'left'
                },
              })}
              getPaginationProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  backgroundColor: '#ffffff',
                },
              })}    
              className="-striped -highlight" 
        />
    </React.Fragment>
    }
}