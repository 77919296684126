import React from 'react'
import Select from '../../.../../../../../../components/select'

export default class NewWorkflowStep extends React.Component {
    render() {
        return <React.Fragment>
            <div class="form-group row">
                <div class="col-lg-12">
                    <label><strong>Step Id</strong></label>
                    <input type="text" class="form-control" onChange={event => this.props.updateSetState('stepId', event.target.value)}/>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-lg-12">
                    <label><strong>Users</strong></label>
                    <Select 
                        options={this.props.users.map(detail => { return ( {label: `${detail.firstName} ${detail.lastName}`, value: detail.email} ) })}
                        onChange={event => this.props.updateSetState('username', event.value)}
                        //defaultValue={this.props.formData.username}
                        minHeight={40}
                    />

                    {/* <select class="form-control" onChange={event => this.props.updateSetState('username', event.target.value)}>
                        <option selected disabled>Select</option>
                        {this.props.users.map(value => <option value={value.email}>{`${value.firstName} ${value.lastName}`}</option>)}
                    </select> */}
                </div>
            </div>
            {/* <div class="form-group row">
                <div class="col-lg-12">
                    <label><strong>Roles</strong></label>
                    <select class="form-control" onChange={event => this.props.updateSetState('role', event.target.value)}>
                        <option selected disabled>Select</option>
                        {this.props.roles.map(value => <option value={value.name}>{value.name}</option>)}
                    </select>
                </div>
            </div> */}
        </React.Fragment>
    }
}