import React from "react";
import PdfModalComponent from "../../../../../../components/pdf-viewer";
import Thumbnail from "../../../../../../components/thumbnail";
import { convertToMajor } from "../../../../../../utils/converter";
export default class ApprovalDetails extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    disablePrevPageButton: false,
    disableNextPageButton: false,
    documentClickUrl: "",
    documentClickFilename: "",
  };

  getActualValue(value) {
    return value === true ? "Yes" : value === false ? "No" : value;
  }

  getDocumentClickName = (filename, src) => {
    var actualPath = src.replace("/data", "");

    this.setState({
      documentClickUrl: actualPath,
      documentClickFilename: filename,
    });
  };

  getSpecificUploadedDocument(documentType, key = "documentName") {
    let document =
      this.props.details &&
      this.props.details.documents.filter(
        (details) => details.documentType == documentType
      );
    return document[0] ? document[0][key] : "";
  }
  convertUploadedDocument(documentLink) {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_FILE_URL
    );
    return convertedDocument;
  }

  getDocumentClickName(filename, src) {
    this.setState({
      documentClickUrl: src,
      documentClickFilename: filename,
    });
    //this.setState({documentClickUrl: src})
  }

  getImageComponent(key, value) {
    if (key === "SignatoryUpload") {
      var agentSignFilename = value.substr(value.lastIndexOf("/") + 1);
      return (
        <>
          <div class="form-group row">
            <div class="col-sm-10">
              <Thumbnail
                filename={agentSignFilename}
                style={{ width: 150, height: 100 }}
                src={process.env.REACT_APP_DOCUMENT_URL + value}
                getDocumentClickName={this.getDocumentClickName}
                showPreview={true}
              />
              <br />
              <br />
            </div>
          </div>

          <PdfModalComponent
            modalTitle={`PDF Viewer`}
            src={this.state.documentClickUrl}
            filename={this.state.documentClickFilename}
            modalSize={`modal-lg`}
            pageNumber={1}
            numPages={1}
          />
        </>
      );
    }
  }

  filterDetailsData(details) {
    let filteredDetails = {};
    const excludeFields = [
      "_id",
      "posRequestStockRequestId",
      "posRequestStockId",
      "isCancelled",
      "workflowStatus",
      "signatoryUploadId",
    ];

    let asArray = Object.entries(details);

    filteredDetails = Object.fromEntries(
      asArray.filter(([key, val]) => !excludeFields.includes(key))
    );

    asArray = Object.entries(filteredDetails);
    filteredDetails = Object.fromEntries(
      asArray.map(([key, val]) => {
        if (key === "signatoryUploadPath") return ["SignatoryUpload", val];
        else if (key === "posUnitPrice")
          return ["POS Unit Price", convertToMajor(val)];
        else if (key === "totalPrice")
          return ["totalPrice", convertToMajor(val)];
        else if (key === "fee") return ["fee", convertToMajor(val)];
        else if (key === "posDeploymentFee")
          return ["posDeploymentFee", convertToMajor(val)];
        else if (key === "amount") return ["amount", convertToMajor(val)];
        else if (typeof val === "string" && val.includes("_"))
          return [
            key.charAt(0).toUpperCase() + key.slice(1),
            val.replaceAll("_", " "),
          ];
        else return [key.charAt(0).toUpperCase() + key.slice(1), val];
      })
    );

    return filteredDetails;
  }

  render() {
    return (
      <React.Fragment>
        <h5>Details</h5>
        {this.props.details &&
          Object.entries(this.filterDetailsData(this.props.details)).map(
            ([key, value]) => {
              if (typeof value !== "object") {
                return (
                  <div class="form-group row">
                    <label for="staticEmail" class="col-lg-5">
                      {key.replace(/([a-z])([A-Z])/g, "$1 $2")}:
                    </label>
                    <div class="col-lg-7">
                      <span>
                        {key === "SignatoryUpload"
                          ? this.getImageComponent(key, value)
                          : this.getActualValue(value)}
                      </span>
                    </div>
                  </div>
                );
              }
            }
          )}
        {this.props.details && this.props.details.file && (
          <div class="form-group row">
            <label for="staticEmail" class="col-lg-5">
              {this.props.details.file.name}
            </label>
            <div class="col-lg-7">
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.downloadFile(this.props.details.file.name)
                }
              >
                Download
              </a>
            </div>
          </div>
        )}

     <div class="form-group  row">
          {this.props.details.documents &&
            this.props.details.documents.map((details) => {
              return (
                <div class="col-lg-3 mr-5">
                  <label>{details.type}</label>
                  {/* <div class="col-lg-8"> */}
                  <div>
                    <Thumbnail
                      showDownloadButton={true}
                      showPreview={true}
                      filename={
                        this.getSpecificUploadedDocument(details.type, "name")
                          ? this.getSpecificUploadedDocument(
                              details.type,
                              "name"
                            )
                          : details.name
                      }
                      getDocumentClickName={this.getDocumentClickName}
                      style={{ width: 150, height: 100 }}
                      src={this.convertUploadedDocument(details.url)}
                      // src={`${process.env.REACT_APP_FILE_URL}${details.documentName}`}
                    />
                    <br />
                    <br />
                  </div>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}
