import { connect } from 'react-redux'
import AppConfigScene from './scene';
import { updateScene } from '../../../../../services/redux/actions/navigation'

function mapStateToProps (state) {
  return {
    scene: state.navigation.scene
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateScene: scene => dispatch(updateScene(scene))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppConfigScene)
