import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import ContentHead from "../../../../../../../components/content-head.js";
import Loader from "../../../../../../../components/loader.js";
import Notification from "../../../../../../../components/notification.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import { CAN_REMAP_DEVICE } from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import { getDateByType } from "../../../../../../../utils/helper.js";
import FileUpload from "../../components/file-upload.js";

export default class CategoryUpdateScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {
        deviceType: "All",
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate"),
        documentStatus: "All",
        pageNum: 1,
        pageSize: 20,
      },
      selectedKycType: "",  // default empty, should be updated on change
      errorMessage: "",
      successMessage: "",
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      stockDisable: false,
      configDisable: false,
      stockFile: [],
      configFile: { filename: "", file: "", url: "" },
      stockError: "",
      configError: "",
      redirect: false,
    };

    // Bind methods (or use arrow functions to avoid binding)
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.readUploadedFile = this.readUploadedFile.bind(this);
    this.handleKycTypeChange = this.handleKycTypeChange.bind(this);  // bind the change handler
  }

  sceneName = "upload-config";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content === null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  readUploadedFile(event, type) {
    const document = {
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      config: {
        filename: document.filename,
        file: document.file,
        url: document.url,
      },
    });

    if (
      document.file.type === "application/vnd.ms-excel" ||
      document.file.type === "text/csv"
    ) {
      this.setState({
        [`${type}File`]: document,
        [`${type}Disable`]: false,
        [`${type}Error`]: "",
      });
    } else {
      this.setState({
        [`${type}Error`]: "Invalid file, upload a csv file",
        [`${type}Disable`]: false,
      });
    }
  }

  async handleFileUpload() {
    this.setState({
      isLoading: true,
      configDisable: true,
      stockDisable: true,
    });

    const { file, filename } = this.state.configFile;

    const responseObj = await platformManagement.documentExemptionUpload(file);

    const { status, response } = responseObj;

    if (status === "SUCCESS") {
      this.setState({
        successMessage: response.description,
        isLoading: false,
        stockDisable: false,
      });

      setTimeout(() => {
        this.setState({ redirect: true });
      }, 2000);
    } else {
      this.setState({
        errorMessage: response.description,
        isLoading: false,
        stockDisable: false,
      });
    }
  }

  // Handle KYC type change
  handleKycTypeChange(event) {
    const selectedKycType = event.target.value;
    this.setState({ selectedKycType });
  }

  render() {
    const { selectedKycType } = this.state;
    if (this.state.redirect) {
      return <Redirect to="/home/kyc-update" />;
    }

    const templateData = {
      NIN: {
        url: `${process.env.REACT_APP_NIN_TEMPLATE_URL}`,
        text: "Download NIN Template",
      },
      CAC: {
        url: `${process.env.REACT_APP_CAC_TEMPLATE_URL}`,
        text: "Download CAC Template",
      },
      TIN: {
        url: `${process.env.REACT_APP_TIN_TEMPLATE_URL}`,
        text: "Download TIN Template",
      },
    };

    const downloadData = templateData[selectedKycType] || {
      url: "#",
      text: "Download Template",
    };

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Upload File"}
          filterPermissions={[CAN_REMAP_DEVICE]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="kt-portlet">
                <form className="kt-form kt-form--label-right">
                  <div className="kt-portlet__body">
                    <div style={{ marginTop: "20px" }}>
                      <label htmlFor="documentType" style={{ fontWeight: "bold" }}>
                        Select KYC Type:
                      </label>
                      <select
                        id="documentType"
                        style={{
                          display: "block",
                          width: "100%",
                          padding: "10px",
                          fontSize: "16px",
                          marginTop: "10px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        onChange={this.handleKycTypeChange}  // Attach the handler
                      >
                        <option value="">Category - NIN, CAC, TIN</option>
                        <option value="NIN">NIN</option>
                        <option value="CAC">CAC</option>
                        <option value="TIN">TIN</option>
                      </select>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <label htmlFor="documentType" style={{ fontWeight: "bold" }}>
                        Download the template, complete the fields, and upload
                      </label>
                    </div>

                    <a
                      href={downloadData.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      {downloadData.text}
                    </a>

                    <div style={{ marginTop: "50px" }}>
                      <FileUpload
                        disabled={this.state.configDisable}
                        fileErrorMessage={this.state.configError}
                        file={this.state.configFile}
                        readUploadedFile={this.readUploadedFile}
                        handleFileUpload={this.handleFileUpload}
                        type="config"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
