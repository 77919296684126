export default class DomainDetailsSerializer {
  static serializeApplicantDetailsFromDomainDetails(formData) {
    const {
      dob,
      firstname,
      gender,
      lastname,
      middlename,
      motherMadienName,
      placeOfBirth,
      residentialAddress,
      emailAddress,
    } = formData.businessContact ? formData.businessContact : [];

    const {
      identificationType,
      identificationNumber,
      agentBankAccount,
      nextOfKins,
      applicationApprovedBy,
      dateApplied,
      dateLastUpdated,
      createdBy,
      dateApplicationApproved,
      dateApplicationValidated,
      applicationValidatedBy,
      agentMobileNo,
      comment,
      statusId,
      bvnVerificationStatus,
      nin,
      ninVerificationStatus,
    } = formData;

    let newFormData = {
      firstName: firstname,
      middleName: middlename,
      lastName: lastname,
      mothersMaidenName: motherMadienName,
      identificationType: identificationType,
      identificationNumber: identificationNumber,
      placeOfBirth: placeOfBirth,
      dob: dob,
      gender: gender,
      emailAddress: emailAddress,
      phoneNumber: agentMobileNo,
      nationality: residentialAddress
        ? residentialAddress.countryId
          ? residentialAddress.countryId
          : residentialAddress.country
        : "",
      state: residentialAddress
        ? residentialAddress.stateId
          ? residentialAddress.stateId
          : residentialAddress.state
        : "",
      localGovernmentArea: residentialAddress
        ? residentialAddress.lgaId
          ? residentialAddress.lgaId
          : residentialAddress.lga
        : "",
      lga: residentialAddress
        ? residentialAddress.lgaId
          ? residentialAddress.lgaId
          : residentialAddress.lga
        : "",
      closestLandmark: residentialAddress ? residentialAddress.landmark : "",
      address: residentialAddress ? residentialAddress.addressLine1 : "",
      bvn: agentBankAccount.bvn,
      bvnVerificationStatus: bvnVerificationStatus,
      nin: nin,
      ninVerificationStatus: ninVerificationStatus,

      nextOfKin: {
        firstName: nextOfKins
          ? nextOfKins[0]
            ? nextOfKins[0].firstname
            : ""
          : "",
        surname: nextOfKins
          ? nextOfKins[0]
            ? nextOfKins[0].lastname
            : ""
          : "",
        phoneNumber: nextOfKins
          ? nextOfKins[0]
            ? nextOfKins[0].phoneNo
            : ""
          : "",
        address: nextOfKins
          ? nextOfKins[0]
            ? nextOfKins[0].residentialAddress.addressLine1
            : ""
          : "",
        gender: nextOfKins ? (nextOfKins[0] ? nextOfKins[0].gender : "") : "",
        relationship: nextOfKins
          ? nextOfKins[0]
            ? nextOfKins[0].relationship
            : ""
          : "",
      },
      dateLastUpdated: dateLastUpdated,
      createdBy: createdBy,
      dateApplied: dateApplied,
      dateApplicationValidated: dateApplicationValidated,
      applicationValidatedBy: applicationValidatedBy,
      dateApplicationApproved: dateApplicationApproved,
      applicationApprovedBy: applicationApprovedBy,
      comment,
      statusId,
    };

    return newFormData;
  }

  static serializeBusinessDetailsFromDomainDetails(formData) {
    const {
      businessName,
      businessPhoneNo,
      businessTypeName,
      parentId,
      agentTypeId,
      agentClass,
      companyRegNo,
      businessLocation,
      agentBankAccount,
      agentClassId,
      businessEmail,
    } = formData;

    let newFormData = {
      businessName: businessName,
      businessType: businessTypeName,
      businessEmail: businessEmail,
      companyRegistrationNumber: companyRegNo,
      phoneNumber: businessPhoneNo,
      state: businessLocation
        ? businessLocation[0]
          ? businessLocation[0].stateId
          : ""
        : "",
      localGovernmentArea: businessLocation
        ? businessLocation[0]
          ? businessLocation[0].lgaId
          : ""
        : "",
      address: businessLocation
        ? businessLocation[0]
          ? businessLocation[0].addressLine1
          : ""
        : "",
      bankName: agentBankAccount.bankName,
      accountNumber: agentBankAccount.accountNo,
      parentId: parentId,
      agentClassId: agentClassId,
      agentClass: agentClass,
      agentTypeId: agentTypeId,
    };
    return newFormData;
  }
}
