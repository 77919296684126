export function checkAccountNumberIsValid (accountNumber) {
    var re = /[0-9]/
    return re.test(accountNumber) && String(accountNumber).length === 10
}

export function checkBvnIsValid (bvn) {
    return bvn.length === 11
}

export function checkCompanyRegistrationNumberIsValid (registrationNumber) {
    return registrationNumber.length >= 8
}

export function checkEmailIsValid (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function checkPasswordIsValid (password) {
    var re = /[a-z]/;
    var re2 = /[A-Z]/;
    var re3 = /[0-9]/;
    return re.test(String(password)) && re2.test(String(password)) && re3.test(String(password)) && String(password).length >= 8
}

export function checkPhoneIsValid (phone) {
    if (phone.startsWith('+234') && phone.length === 14) {
      return true
    }
    if (phone.startsWith('234') && phone.length === 13) {
        return true
      }
    if (phone.startsWith('0') && phone.length === 11) {
      return true
    }
    return false
}

export function checkisPasswordMatch (password, confirmPassword) {
    return password === confirmPassword
  }

export function checkPasswordLength (password) {
    return password.length > 4
}

export function checkOtpIsValid (otp) {
    return otp.length == 6
}

export function checkWebsiteIsValid (website) {
    var re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g
    return re.test(String(website).toLowerCase())
}