import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { WorkflowConfigurationsExportSerializer } from '../../../../../../serializers';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class WorkflowTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '500px',
        zoom: 'plus'
      }
    
    zoomTableHeight = this.zoomTableHeight.bind(this)
    
    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }

    render() {
        const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
         
        const columns = [
            {
                Header: "Module",
                accessor: "name",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
            }, {
                Header: "Action Type",
                accessor: "actionType",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
            },{
                Header: "Status",
                accessor: "active",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                Cell: props => props.value === false ? `Inactive` : props.value === true ? `Active` : ''
            }, {
        
                Header: "Action",
                accessor: "actionType",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            lineHeight: '20px',
                            textAlign: 'center',
                            whiteSpace: 'unset',
                        },
                    };
                },
                width: 100,
                Cell: ({ row, original }) => <div class="dropdown">
                    <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                        <i class="la la-ellipsis-v"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <Link to={`configuration/${original.id}/view`} class="dropdown-item">
                            <i class="la la-eye"></i>
                            View
                        </Link>
                        <a hidden={original.active === true} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(original.id, 'enable')} style={{color: '#A7ABC3', cursor: 'pointer'}}>
                            <i class="la la-toggle-on"></i>
                            Activate
                        </a>
                        <a hidden={original.active === false} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(original.id, 'disable')} style={{color: '#A7ABC3', cursor: 'pointer'}}>
                            <i class="la la-lock"></i>
                            Deactivate
                        </a>
                    </div>
                </div>
            }
        ]

        return <React.Fragment>
        <TableExtra 
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAllWorkflow}
          zoom={this.state.zoom}
          data={WorkflowConfigurationsExportSerializer.reverseWorkflowConfigurationDetails(this.props.data)}
          headers={WorkflowConfigurationsExportSerializer.headerExport()}
          fileName={'Workflow Configurations'}
          //disabled={true}
          disabled={this.props.loading}
          page={this.props.page}
          pageSize={this.props.pageSize}
          closePendingApprovals={this.props.closePendingApprovals}
          showApprovalRepush={true}
        />
        <ReactTable 
            PaginationComponent={Pagination} 
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ''}
            style={{
                border: "none",
                boxShadow: "none",
                whiteSpace: 'unset',
                height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
            onFetchData={(state, instance) => {
                this.setState({page: state.page})
                if(this.props.canLoadData) {
                    this.props.getAllWorkflow(state.page + 1,state.pageSize)
                }
            }}
            headerNodeHeight={`123`}
            minRows={(this.props.data).length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
                style: {
                    borderColor: '#00425f',
                    boxSizing: 'none',
                    border: 'none',
                },
            })}
            getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                    display:"flex",
                    overflow: "visible",
                    color: "black",
                    fontSize: "11.5px",
                    fontWeight: "50",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                    alignItems: "center",
                    
                  },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                    backgroundColor: "#00425f",
                    borderBottom: "0.1px solid #DBDEDE",
                    borderWidth: "0.1px",
                    padding:"10px 0",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "100",
                    textAlign: "left",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                  },
            })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
                style: {
                    overflow: 'visible',
                    backgroundColor: '#ffffff',
                },
            })}    
            className="-striped -highlight" 
        />
        </React.Fragment>
    }
}