import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function DocumentTypeForm(props) {

	const {
		documentTypeFormData,
		onChange,
		handleEditForm,
		errors = {},
	} = props;

	useEffect(() => {
		const { state } = props.history.location;
		if (state && state.isEditMode) {
			handleEditForm(state.documentTypeFormData.name, state.documentTypeFormData.description)
		}
	}, [])

	return (
		<div className="kt-portlet">
			<form className="kt-form" id="document-type-form">
				<div className="kt-portlet__body">
					<div className="form-group row">
						<div className="col-lg-12">
							<label htmlFor="name" style={{ display: 'block', fontWeight: 'bold', color: errors.name ? 'red' : 'inherit' }}>
								Name <span style={{ color: errors.name ? 'red' : 'inherit' }}>*</span>
							</label>
							<input
								name="name"
								type="text"
								value={documentTypeFormData.name}
								onChange={onChange}
								style={{
									width: '100%',
									height: '35px',
									border: '1px solid grey',
									padding: '8px',
									borderRadius: '4px',
									fontSize: '12px',
									boxSizing: 'border-box',
								}}
							/>
							{errors.name && <p style={{ color: 'red', fontSize: '12px' }}>{errors.name}</p>}
						</div>
					</div>

					<div className="form-group row">
						<div className="col-lg-12">
							<label htmlFor="description" style={{ display: 'block', fontWeight: 'bold', color: 'inherit' }}>
								Description
							</label>
							<input
								name="description"
								type="text"
								value={documentTypeFormData.description}
								onChange={onChange}
								style={{
									width: '100%',
									height: '35px',
									border: '1px solid gray',
									padding: '8px',
									borderRadius: '4px',
									fontSize: '12px',
									boxSizing: 'border-box',
								}}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
export default withRouter(DocumentTypeForm);

