export default class DocTypeExportSerializer {
    static headerExport() {
        return [
            { label: 'Agent DocType Name', key: 'docTypeName', title: 'Agent DocType Name', dataIndex: 'docTypeName' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
        ];
    }

    static reverseDocType(formData) {
        let newFormData = [];
        formData.map(details => {
            let { id, name, disabled } = details;
            let status;
            
            if (disabled === 0) {
                status = 'Active';
            } else if (disabled === 1) {
                status = 'Inactive';
            } else {
                status = '';
            }

            let detailFormData = {
                docTypeName: `${name.toLowerCase()}${id}`,
                status: status
            };

            newFormData.push(detailFormData);
        });
        return newFormData;
    }
}
