import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_ACTIVATE_SERVICE_OPTION, CAN_DEACTIVATE_SERVICE_OPTION, CAN_VIEW_SERVICE_OPTION } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import ServiceOptionSearch from '../../components/service-option-search';
import ServiceOptionTable from '../../components/service-option-table';

export default class ServiceOptionGridScene extends BaseScene {
  
  getServiceOptions = this.getServiceOptions.bind(this)
  handleSearchRequest = this.handleSearchRequest.bind(this)
  
  state = {
    serviceOptions: [],
    isLoading: true,
    searchParams: []
  }

  componentDidMount() {
    //this.getServices()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getServiceOptions (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getServiceOptions(pageNum, pageSize, this.state.searchParams)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceOptions: response ? response : [],
        dataCount: count,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      })
    }else{
      this.setState({
        serviceOptions: [],
        dataCount: 0,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response
      })
    }
  }

  handleSearchRequest (searchParams) {
    this.setState({
      searchParams,
      startPage: 1
    })
    setTimeout(() => {
      this.getServiceOptions(1, 20)
    }, 500)
  }

  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Service Options"} filterPermissions={[CAN_VIEW_SERVICE_OPTION]} totalCount={56} /> 
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_SERVICE_OPTION]}>
        <GridLayout>
          <ServiceOptionSearch 
            handleSearchRequest={this.handleSearchRequest}
            searchParams={this.state.searchParams}
          />
          <ServiceOptionTable 
            getServiceOptions={this.getServiceOptions}
            pages={this.state.pages}
            data={this.state.serviceOptions}
            updateStatus={this.getClickedAction}
            loading={this.state.isLoading} 
            noDataText={`No service options found`}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canView={CAN_VIEW_SERVICE_OPTION}
            canActivate={CAN_ACTIVATE_SERVICE_OPTION}
            canDeactivate={CAN_DEACTIVATE_SERVICE_OPTION}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
  
}
