import React from "react";
import ApplicantDetailsForm from "../../../../../../components/application-form/applicant-details";
import BusinessDetailsForm from "../../../../../../components/application-form/business-details";
import KycDocuments from "../../../../../../components/application-form/kyc-documents";
import NextOfKinDetailsForm from "../../../../../../components/application-form/next-of-kin-details";
import { OtherDetails } from "../../../../../../components/application-form/other-details";
import { SuperAgentForm } from "../../../../../../components/forms/super-agent-form";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import { SUPER_AGENT_TYPE_ID } from "../../../../../../constants/domain-types";
import {
  CAN_APPROVE_APPLICATIONS,
  CAN_CREATE_SUPER_AGENT_USER,
  CAN_REJECT_APPLICATIONS,
  CAN_VALIDATE_APPLICATIONS,
} from "../../../../../../constants/permissions";
import Banks from "../../../../../../fixtures/banks";
import BusinessTypes from "../../../../../../fixtures/business_types.json";
import Countries from "../../../../../../fixtures/countries.json";
import IDTYPES from "../../../../../../fixtures/id-types.json";
import LGAs from "../../../../../../fixtures/lgas.json";
import PersonalDocuments from "../../../../../../fixtures/personal_documents.json";
import Relationships from "../../../../../../fixtures/relationships.json";
import States from "../../../../../../fixtures/states.json";
import UtilityBills from "../../../../../../fixtures/utility_bills.json";
import { platformManagement } from "../../../../../../mixins/api";
import {
  ApplicantDetailsSerializer,
  BusinessDetailsSerializer,
  DomainDetailsSerializer,
} from "../../../../../../serializers";
import DomainGridLayout from "../../components/domain-grid-layout";
import NavTab from "../../components/nav-tab";

import { AccessDenied } from "../../../../../../components/access-denied";
import Loader from "../../../../../../components/loader";
import Notification from "../../../../../../components/notification";
import {
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_MANAGE_AGGREGATOR_DOMAIN,
  CAN_MANAGE_SP_DOMAIN,
} from "../../../../../../constants/permissions.js";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import {
  getLgasByStateId,
  getStatesByCountryId,
} from "../../../../../../utils/helper";

export default class DomainInformationGridScene extends React.Component {
  requiredPermissions = [
    CAN_APPROVE_APPLICATIONS,
    CAN_CREATE_SUPER_AGENT_USER,
    CAN_VALIDATE_APPLICATIONS,
    CAN_REJECT_APPLICATIONS,
  ];

  constructor(props) {
    super(props);
    this.state = {
      emailIsValid: true,
      phoneIsValid: true,
      websiteIsValid: true,
      identificationTypes: IDTYPES,
      businessTypes: BusinessTypes,
      domainDetails: {
        applicantDetails: {
          nextOfKin: {},
        },
        businessDetails: {},
      },
      applicantDetailsFormData: {
        nextOfKin: {},
      },
      businessDetailsFormData: {},
      countries: Countries,
      states: States,
      lgas: LGAs,
      agentClasses: [],
      application: {
        agentBankAccount: {},
        businessContact: {
          residentialAddress: {},
        },
        nextOfKins: [],
        businessLocation: [],
        applicantDetails: {
          nextOfKin: {},
        },
        businessDetails: {},
      },
      aggregators: [],
      documents: [],
      kycDocuments: [],
      uploadedDocuments: [],
      documentErrors: [],
      disabled: true,
      superAgentFormData: [],
      showPhoneNumber: false,
      documentUploadProgress: [],
    };
    this.loadApplication = this.loadApplication.bind(this);
    this.updateApplicantDetailsForm = this.updateApplicantDetailsForm.bind(
      this
    );
    this.updateBusinessDetailsForm = this.updateBusinessDetailsForm.bind(this);
    this.updateNextOfKinDetailsForm = this.updateNextOfKinDetailsForm.bind(
      this
    );
    this.updateSetState = this.updateSetState.bind(this);
    this.updateSuperAgentFormData = this.updateSuperAgentFormData.bind(this);
    this.getIdentificationTypeName = this.getIdentificationTypeName.bind(this);
    this.getCountryName = this.getCountryName.bind(this);
    this.getStateName = this.getStateName.bind(this);
    this.getLgaName = this.getLgaName.bind(this);
  }

  componentDidMount() {
    this.domainCode = this.props.match.params.domainCode;
    this.loadApplication();
    //this.getUploadedDocuments()
    this.getAggregatorList();
    //this.getCountries()
    this.getAgentClasses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getAgentClasses() {
    const responseObj = await platformManagement.getAgentClasses();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentClasses: this.getActiveAgentClasses(response),
      });
    } else {
      this.setState({
        agentClasses: [],
      });
    }
  }

  async getAggregatorList() {
    const responseObj = await platformManagement.getSuperAgentList();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        aggregators: response,
      });
    } else {
      this.setState({
        aggregators: [],
      });
    }
  }

  getActiveAgentClasses(response) {
    return response.filter((details) => details.disabled === 0);
  }

  getIdentificationTypeName(id) {
    const details = this.state.identificationTypes.filter(
      (value) => value.id == id
    );
    return details[0] ? details[0].name : id;
  }

  getCountryName(id) {
    const details = this.state.countries.filter(
      (value) => value.id == parseInt(id)
    );
    return details[0] ? details[0].name : "";
  }

  getStateName(id) {
    const details = States.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getLgaName(id) {
    const details = LGAs.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  async updateCountry(countryId) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        country: countryId,
      },
    });
    this.setState({
      states: getStatesByCountryId(countryId),
      canUploadDocument: false,
    });
  }

  async updateState(stateId, type) {
    if (type === "applicantState") {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          state: stateId,
        },
      });
    } else {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          state: stateId,
        },
      });
    }
    this.setState({
      lgas: getLgasByStateId(stateId),
    });
  }

  async loadApplication() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getAgentByAgentCode(
      this.domainCode
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.agentTypeId === 2) {
        this.setState({
          superAgentFormData: response,
          isLoading: false,
          showSuperAgentForm:
            response.agentTypeId === SUPER_AGENT_TYPE_ID ? true : false,
          showPhoneNumber: true,
        });
      } else {
        this.setState({
          isLoading: false,
          showSuperAgentForm: false,
          uploadedDocuments: response.documents ? response.documents : [],
          domainDetails: {
            applicantDetails: {
              ...this.state.applicantDetails,
              ...DomainDetailsSerializer.serializeApplicantDetailsFromDomainDetails(
                response
              ),
            },
            businessDetails: {
              ...this.state.businessDetails,
              ...DomainDetailsSerializer.serializeBusinessDetailsFromDomainDetails(
                response
              ),
            },
          },
          applicantDetailsFormData: {
            ...ApplicantDetailsSerializer.reverseSerializeFromApplication(
              response.businessContact || {}
            ),
            howYouHeardAboutUs: response.howYouHeardAboutUs
              ? response.howYouHeardAboutUs
              : "",
          },
          businessDetailsFormData: BusinessDetailsSerializer.reverseSerializeFromApplication(
            response.businessDetails || {}
          ),
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  async getUploadedDocuments() {
    const responseObj = await platformManagement.getUploadedDocuments(
      this.domainCode
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        uploadedDocuments: response.data,
      });
    }
    return;
  }

  patchApplication(application) {
    if (application.applicantDetails === undefined) {
      application.applicantDetails = {};
    }

    if (application.applicantDetails.nextOfKin === undefined) {
      application.applicantDetails.nextOfKin = {};
    }

    if (application.businessDetails === undefined) {
      application.businessDetails = {};
    }
    return application;
  }

  updateApplicantDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        [type]: value,
      },
    });
  }

  updateBusinessDetailsForm(type, value) {
    this.setState({
      businessDetailsFormData: {
        ...this.state.businessDetailsFormData,
        [type]: value,
      },
    });
  }

  updateNextOfKinDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        nextOfKin: {
          ...this.state.applicantDetailsFormData.nextOfKin,
          [type]: value,
        },
      },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  updateSuperAgentFormData(type, value) {
    this.setState({
      superAgentFormData: { ...this.state.superAgentFormData, [type]: value },
    });
  }

  render() {
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />
        <ProtectedComponent
          requiredPermissions={[
            CAN_MANAGE_SP_DOMAIN,
            CAN_MANAGE_AGGREGATOR_DOMAIN,
            CAN_MANAGE_AGENT_DOMAIN,
          ]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <DomainGridLayout title={"Domain Information"}>
              {this.state.showSuperAgentForm && !this.state.isLoading && (
                <div>
                  <SuperAgentForm
                    updateSetState={this.updateSetState}
                    updateSuperAgentFormData={this.updateSuperAgentFormData}
                    businessTypes={this.state.businessTypes}
                    superAgentFormData={this.state.superAgentFormData}
                    disabled={true}
                    emailIsValid={this.state.emailIsValid}
                    websiteIsValid={this.state.websiteIsValid}
                    phoneIsValid={this.state.phoneIsValid}
                    showPhoneNumber={this.state.showPhoneNumber}
                  />
                </div>
              )}

              {!this.state.showSuperAgentForm && !this.state.isLoading && (
                <div>
                  <NavTab />
                  <div class="tab-content">
                    <div
                      class="tab-pane active"
                      id="kt_tabs_2_1"
                      role="tabpanel"
                    >
                      <ApplicantDetailsForm
                        identificationTypes={this.state.identificationTypes}
                        applicantDetails={
                          this.state.domainDetails.applicantDetails
                        }
                        onChange2={this.updateApplicantDetailsForm}
                        onChange={this.updateBusinessDetailsForm}
                        updateSetState={this.updateSetState}
                        applicantDetailsFormData={
                          this.state.applicantDetailsFormData
                        }
                        countries={this.state.countries}
                        states={this.state.states}
                        lgas={this.state.lgas}
                        updateCountry={this.updateCountry}
                        updateState={this.updateState}
                        disabled={this.state.disabled}
                        title={"Agent"}
                        phoneIsValid={true}
                        hideTitleHeader={true}
                        showContent={true}
                        emailIsValid={true}
                        getStateName={this.getStateName}
                        getLgaName={this.getLgaName}
                        getCountryName={this.getCountryName}
                        getIdentificationTypeName={
                          this.getIdentificationTypeName
                        }
                      />
                    </div>
                    <div class="tab-pane" id="kt_tabs_2_2" role="tabpanel">
                      <BusinessDetailsForm
                        disabled={this.state.disabled}
                        businessTypes={this.state.businessTypes}
                        businessDetailsFormData={
                          this.state.businessDetailsFormData
                        }
                        applicantDetailsFormData={
                          this.state.applicantDetailsFormData
                        }
                        states={this.state.states}
                        lgas={this.state.lgas}
                        banks={Banks}
                        applicantDetails={
                          this.state.domainDetails.applicantDetails
                        }
                        onChange2={this.updateApplicantDetailsForm}
                        onChange={this.updateBusinessDetailsForm}
                        businessDetails={
                          this.state.domainDetails.businessDetails
                        }
                        updateSetState={this.updateSetState}
                        updateCountry={this.updateCountry}
                        showAgentClass={true}
                        agentClasses={this.state.agentClasses}
                        aggregators={this.state.aggregators}
                        businessPhoneIsValid={true}
                        hideTitleHeader={true}
                        showContent={true}
                        getStateName={this.getStateName}
                        getLgaName={this.getLgaName}
                        getCountryName={this.getCountryName}
                        getIdentificationTypeName={
                          this.getIdentificationTypeName
                        }
                      />
                    </div>
                    <div class="tab-pane" id="kt_tabs_2_3" role="tabpanel">
                      <NextOfKinDetailsForm
                        disabled={this.state.disabled}
                        nextOfKinDetails={
                          this.state.domainDetails.applicantDetails.nextOfKin
                        }
                        updateSetState={this.updateSetState}
                        onChange={this.updateNextOfKinDetailsForm}
                        applicantDetailsFormData={
                          this.state.applicantDetailsFormData
                        }
                        relationships={Relationships}
                        hideTitleHeader={true}
                        showContent={true}
                        getStateName={this.getStateName}
                        getLgaName={this.getLgaName}
                        getCountryName={this.getCountryName}
                        getIdentificationTypeName={
                          this.getIdentificationTypeName
                        }
                      />
                    </div>
                    <div class="tab-pane" id="kt_tabs_2_4" role="tabpanel">
                      <KycDocuments
                        showUploadForm={false}
                        showUploadedDocuments={true}
                        kycDocuments={this.state.kycDocuments}
                        documentErrors={this.state.documentErrors}
                        utilityBills={UtilityBills}
                        personalDocuments={PersonalDocuments}
                        uploadedDocuments={this.state.uploadedDocuments}
                        nextOfKinPhoneIsValid={true}
                        hideTitleHeader={true}
                        showContent={true}
                        documentUploadProgress={
                          this.state.documentUploadProgress
                        }
                        disabled={true}
                      />
                    </div>
                    <div class="tab-pane" id="kt_tabs_2_5" role="tabpanel">
                      <OtherDetails
                        disabled={this.state.disabled}
                        applicantDetails={
                          this.state.domainDetails.applicantDetails
                        }
                        hideTitleHeader={true}
                        showContent={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </DomainGridLayout>
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
