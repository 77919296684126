import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../constants/api";
import { SUPER_AGENT_TYPE_ID } from "../../../../../../../constants/domain-types";
import {
  CAN_ACTIVATE_SERVICE_PROVIDER,
  CAN_DEACTIVATE_SERVICE_PROVIDER, CAN_DELETE_SERVICE_PROVIDER, CAN_MANAGE_SP_DOMAIN,
  CAN_RETIRE_SERVICE_PROVIDER, CAN_VIEW_SERVICE_PROVIDER
} from "../../../../../../../constants/permissions";
import { platformManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTime, pastTenseFormatter
} from "../../../../../../../utils/formatter";
import SuperAgentTable from "../../components/super-agents-table";
//import SuperAgentSearch from '../../components/super-agents-search'
import { AgentSearch } from "../../../../../../../components/forms/agent-search";

export default class SuperAgentGridScene extends BaseScene {
  state = {
    superAgents: [],
    superAgentSearchParameters: {
      agentType: SUPER_AGENT_TYPE_ID,
    },
    tableMessage: "",
    canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
  };

  updateSuperAgent = this.updateSuperAgent.bind(this);
  getClickedAction = this.getClickedAction.bind(this);
  getSuperAgents = this.getSuperAgents.bind(this);
  handleSearchRequest = this.handleSearchRequest.bind(this);
  downloadSuperAgentData = this.downloadSuperAgentData.bind(this);
  updateSuperAgentSearchParameters = this.updateSuperAgentSearchParameters.bind(
    this
  );

  sceneName = "super-agent-management";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getSuperAgents(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const payload = this.state.superAgentSearchParameters;
    payload.startDate =
      payload.startDate && formatBackEndDateTime(payload.startDate);
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    const responseObj = await platformManagement.searchAgents(
      pageNum,
      pageSize,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        superAgents: response,
        dataCount: count,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: response.length === 0 ? "No super agents found" : "",
      });
    } else {
      this.setState({
        superAgents: [],
        dataCount: 0,
        pages: 1,
        isLoading: false,
        page: pageNum,
        pageSize: pageSize,
        errorMessage: response,
        tableMessage: "No super agents found",
      });
    }
  }

  getClickedAction(Id, businessName, status) {
    this.setState({
      superAgentId: Id,
      superAgentStatus: status,
      superAgentBusinessName: businessName,
      showCommentField:
        status === "suspend" || status === "retire" ? true : false,
    });
  }

  async updateSuperAgent() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.updateAgentStatus(
      this.state.superAgentStatus,
      this.state.superAgentId,
      this.state.description
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          superAgentId: null,
          superAgentStatus: null,
          superAgentBusinessName: null,
          isLoading: false,
          description: null,
          successMessage: `Your request to ${this.state.superAgentStatus} ${this.state.superAgentBusinessName} as a super agent has been sent successfully for approval.`,
        });
        return;
      } else {
        this.setState({
          superAgentId: null,
          superAgentStatus: null,
          superAgentBusinessName: null,
          successMessage: `${
            this.state.superAgentBusinessName
          } was successfully ${pastTenseFormatter(
            this.state.superAgentStatus
          )} as an aggregator.`,
          isLoading: false,
          description: null,
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getSuperAgents();
  }

  handleSearchRequest(superAgentSearchParameters) {
    this.setState({ loading: true });
    this.setState({
      startPage: 1,
    });
    setTimeout(() => {
      this.getSuperAgents(1, 20);
      this.setState({ loading: false });
    });
  }

  async downloadSuperAgentData() {
    //this.parentId = qs.parse(window.location.search)['?parentId']
    const payload = this.state.superAgentSearchParameters;
    payload.startDate =
      payload.startDate && formatBackEndDateTime(payload.startDate);
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    //payload.parentId = this.parentId ? this.parentId : null
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await platformManagement.downloadAgentData(
      this.state.page,
      this.state.dataCount,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "super agents.xls");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  isFormValid() {
    if (this.state.showCommentField) {
      return Boolean(this.state.description);
    } else {
      return true;
    }
  }

  updateSuperAgentSearchParameters(type, value) {
    this.setState({
      superAgentSearchParameters: {
        ...this.state.superAgentSearchParameters,
        [type]: value,
      },
    });
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`Do you want to ${this.state.superAgentStatus} ${this.state.superAgentBusinessName} as a super agent?`}</p>
        {this.state.showCommentField && (
          <textarea
            placeholder={`Add more comment`}
            autoComplete="off"
            class="form-control"
            style={{ height: "200px", resize: "none" }}
            maxLength="100"
            name="description"
            onChange={(event) =>
              this.setState({ description: event.target.value })
            }
          ></textarea>
        )}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Super Agents"}
          totalCount={27}
          filterPermissions={[
            CAN_VIEW_SERVICE_PROVIDER,
          ]} /*actionButtonText="Add Super Agent" actionButtonUrl={"/home/super-agent-management/add"} actionButtonRequiredParams={[CAN_CREATE_SERVICE_PROVIDER]}*/
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_SERVICE_PROVIDER]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <AgentSearch
              handleSearchRequest={this.handleSearchRequest}
              agentType={SUPER_AGENT_TYPE_ID}
              preloadDate={false}
              agentSearchParameters={this.state.superAgentSearchParameters}
              updateAgentSearchParameters={
                this.updateSuperAgentSearchParameters
              }
            />
            <SuperAgentTable
              data={this.state.superAgents}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getSuperAgents={this.getSuperAgents}
              getClickedAction={this.getClickedAction}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_SERVICE_PROVIDER}
              canDelete={CAN_DELETE_SERVICE_PROVIDER}
              canActivate={CAN_ACTIVATE_SERVICE_PROVIDER}
              canDeactivate={CAN_DEACTIVATE_SERVICE_PROVIDER}
              canManageDomain={CAN_MANAGE_SP_DOMAIN}
              canRetire={CAN_RETIRE_SERVICE_PROVIDER}
              canLoadData={this.state.canLoadData}
              downloadSuperAgentData={this.downloadSuperAgentData}
            />
            <PromptModal
              modalTitle={this.state.superAgentStatus + ` Super Agent`}
              modalBody={modalBody}
              deleteModal={this.updateSuperAgent}
              modalSize={`modal-md`}
              height={this.state.showCommentField ? "450px" : "230px"}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
              textAlign={"left"}
              modalFooterButton2Disabled={!this.isFormValid()}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
