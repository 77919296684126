import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import Table from '../../../../../../../components/table';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS, ACCEPTED_REQUEST } from '../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../components/access-denied'
import { PromptModal } from '../../../../../../../components/modals/prompt-modal'
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import Notification from '../../../../../../../components/notification'
import { platformManagement } from '../../../../../../../mixins/api'
import { AgentClassesExportSerializer } from '../../../../../../../serializers'
import { CAN_CREATE_AGENT_CLASS, CAN_VIEW_AGENT_CLASS, CAN_DEACTIVATE_AGENT_CLASS, CAN_ACTIVATE_AGENT_CLASS, CAN_DELETE_AGENT_CLASS } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class AgentClassesGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
    },
    {
      Header: "Agent Class ID",
      accessor: 'agentClassID',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      Cell: ({row, original}) => <span class="word-capitalize">{`${original.name.toLowerCase()} ${original.id}`}</span>
    },
    {
      Header: "Agent Type",
      accessor: 'agentTypeId',
      resizable: false,
      width: 150,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      Cell: props => props.value === 1 ? 'Agent' : props.value === 2 ? 'Super Agent' : '',
    },
    {
      Header: "Status",
      accessor: 'disabled',
      resizable: false,
      width: 80,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center'
          },
        };
      },
      Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge kt-badge--inline ${props.value === 0 ? 'kt-badge--success' : props.value === 1 ? 'kt-badge--danger' : '' } kt-badge--pill`}>{props.value === 0 ? 'Active' : props.value === 1 ? 'Inactive' : ''}</span>,
    },
    {
      Header: "Action",
      accessor: 'agentClassID',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: '20px',
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 100,
      Cell: ({row, original}) => <div class="dropdown">
        <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
          <i class="la la-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_CLASS]}><Link to={`/home/agent-classes/${row.id}/view`} class="dropdown-item">
            <i class="la la-eye"></i>
            View
          </Link></ProtectedComponent>
          <ProtectedComponent requiredPermissions={[CAN_ACTIVATE_AGENT_CLASS]}><a hidden={original.disabled === 1 ? '' : 'hidden'} onClick={() => this.getClickedAction(row.id, original.name, `0`)} data-toggle="modal" data-target="#kt_modal_1" /*href={`/home/agent-classes/${'activate'}/${row.id}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
            <i class="la la-toggle-on"></i>
            Activate
          </a></ProtectedComponent>
          <ProtectedComponent requiredPermissions={[CAN_DEACTIVATE_AGENT_CLASS]}><a hidden={original.disabled === 0 ? '' : 'hidden'} onClick={() => this.getClickedAction(row.id, original.name, `1`)} data-toggle="modal" data-target="#kt_modal_1" /*href={`/home/agent-classes/${'suspend'}/${row.id}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
            <i class="la la-ban"></i>
            Deactivate
          </a></ProtectedComponent>
        </div>
      </div>
    }
  ]
  
  state = {
    agentClasses: [],
    isLoading: true,
  }

  updateAgentClass = this.updateAgentClass.bind(this);
  getAgentClasses = this.getAgentClasses.bind(this);

  componentDidMount () {
    this.getAgentClasses()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  getClickedAction (agentClassId, agentClassName, status) {
    this.setState({
      agentClassId,
      agentClassStatus: status,
      agentClassStatusName: status == 0 ? 'activate' : 'deactivate',
      agentClassName
    })
  }

  async updateAgentClass () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.updateAgentClassStatus(this.state.agentClassStatus, this.state.agentClassId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      if(response.code == ACCEPTED_REQUEST) {
        this.setState({
          agentClassId: null,
          agentClassStatus: null,
          agentClassName: null,
          agentClassStatusName: null,
          successMessage: "Your request to update the status of this agent class has been sent successfully for approval.",
          isLoading: false,
        })
      }else{
        this.setState({
          agentClassId: null,
          agentClassStatus: null,
          agentClassName: null,
          agentClassStatusName: null,
          successMessage: "Agent class status updated succesfully!",
          isLoading: false,
        })
      }
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getAgentClasses()
  }
  
  async getAgentClasses() {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getAgentClasses()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentClasses: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        agentClasses: [],
        dataCount: 0,
        isLoading: false,
        errorMessage: response,
      })
    }
  }

  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Agent Classes"} totalCount={56} filterPermissions={[CAN_VIEW_AGENT_CLASS]} actionButtonRequiredPermissions={[CAN_CREATE_AGENT_CLASS]} actionButtonText={"Create Agent Class"} actionButtonUrl={"/home/agent-classes/add"}/>
      <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_CLASS]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <Table 
            columns={this.columns} 
            data={this.state.agentClasses} 
            loading={this.state.isLoading}
            noDataText={`No agent classes found`}
            dataCount={this.state.dataCount}
            getData={this.getAgentClasses}
            exportData={AgentClassesExportSerializer.reverseAgentClassDetails(this.state.agentClasses)}
            exportHeaders={AgentClassesExportSerializer.headerExport()}
            exportFileName={'agent_classes'}
          />
          <PromptModal
            modalTitle={ this.state.agentClassStatusName+` Agent Class`}
            modalBody={`Do you want to ${this.state.agentClassStatusName} ${this.state.agentClassName} ${this.state.agentClassId} from agent classes?`}
            deleteModal={this.updateAgentClass}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
  
}
