import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class posRequestsAwaitingStockUpload {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getRequestsAwaitingStockUpload(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request/awaiting-stock-upload`,
      args: {
        pageNum,
        pageSize,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }


  getRequestsAwaitingStockUploadBySearch(
    pageNumber = null,
    pageSize = null,
    mobilePhone = null,
    toDate = null,
    fromDate = null,
    status = null
    
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request/awaiting-stock-upload/search`,
      args: {
        mobilePhone,
        toDate,
        fromDate,
        pageNumber,
        pageSize,
        status,
       
      },
    });
  }

  uploadPosRequestsAwaitingStockUpload(quantity, workflowId) {
    return this.apiRequester.put({
      endpoint: "pos-request/awaiting-stock-upload/",
      headers: { 'quantity': quantity, 'workflowId': workflowId },
    });
  }

  
}
