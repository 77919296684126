import Requester from '../requester'

const API_BASE_URL = process.env.REACT_APP_ACCOUNT_OPENING_SERVICE_API_BASE_URL

export default class accountOpeningService {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }
    
    modifyBankDetails (bankCode, payload) {
        return this.apiRequester.put({
            endpoint: `finch-account-opening-service/api/v1/bank/${bankCode}`,
            body: payload
        })
    }

    addSanefBank (payload) {
        return this.apiRequester.post({
            endpoint: `finch-account-opening-service/api/v1/bank`,
            body: payload
        })
    }

    getSanefBankList () {
        return this.apiRequester.get({
            endpoint: `finch-account-opening-service/api/v1/banklist`
        })
    } 

    getSanefBankByBankCode (bankCode) {
        return this.apiRequester.get({
            endpoint: `finch-account-opening-service/api/v1/bankDetails/${bankCode}`
        })
    }
}
