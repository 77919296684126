import { formatDateTime, formatCurrency, formatLongNumber } from '../../utils/formatter';
import { convertToMajor } from '../../utils/converter'
import { capitalizeFirstLetter } from '../../utils/helper';

export default class SettlementExportSerializer {
    static headerExport () {
        return [
            { label: 'Agent Code', key: 'agentCode', title: 'Agent Code', dataIndex: 'agentCode' },
            { label: 'Transaction Reference', key: 'transactionRef', title: 'Transaction Reference', dataIndex: 'transactionRef' },
            { label: 'Date', key: 'dateTimeProcessed', title: 'Date', dataIndex: 'dateTimeProcessed' },
            { label: 'Transaction Type', key: 'transactionType', title: 'Transaction Type', dataIndex: 'transactionType' },
            { label: 'Amount', key: 'transactionAmount', title: 'Amount', dataIndex: 'transactionAmount' },
            { label: 'Fee', key: 'transactionSurcharge', title: 'Fee', dataIndex: 'transactionSurcharge' },
        ];
    }

    static reverseSettlmentDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
                parties, transactionRef, dateTimeProcessed, transactionType, transactionAmount, transactionSurcharge
            } = detail

            let detailFormData = {
                agentCode: this.getAgentId(parties),
                transactionRef: formatLongNumber(transactionRef),
                dateTimeProcessed: dateTimeProcessed ? formatDateTime(dateTimeProcessed) : '',
                transactionType: capitalizeFirstLetter(transactionType),
                transactionAmount: transactionAmount ? `₦${formatCurrency(convertToMajor(transactionAmount))}` : `₦${formatCurrency(0)}`,
                transactionSurcharge: transactionSurcharge ? `₦${formatCurrency(convertToMajor(transactionSurcharge))}` : `₦${formatCurrency(0)}`
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }

    static getAgentId (parties) {
        let agentId = ''
        parties.map(value => {
            if(value.agentName == 'Agent'){
            agentId = value.agentId
            }
        })
        return agentId
    }
}