import React from 'react';
import { AccessDenied } from '../../../../../../components/access-denied';
import ContentHead from '../../../../../../components/content-head';
import RoleForm from '../../../../../../components/forms/role-form';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../components/loader';
import Notification from '../../../../../../components/notification';
import BaseScene from '../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../constants/api';
import { CAN_UPDATE_ROLES } from '../../../../../../constants/permissions';
import { CAN_UPDATE_ROLE, CAN_VIEW_ROLE } from '../../../../../../constants/permissions.js';
import { platformManagement } from '../../../../../../mixins/api';
import { retrieveSessionInformation } from '../../../../../../mixins/authentication';
import ProtectedComponent from '../../../../../../mixins/protected-component';

export default class ViewRoleScene extends BaseScene {
  requiredPermissions = [
    CAN_UPDATE_ROLES
  ]

  metadataSectionRef = React.createRef(null)

  constructor (props) {
    super(props)

    this.state = {
      canSave: false,
      canUpdate: false,
      permissions: [],
      pageTitle: 'View Role Details',
      disabled: true,
      roleFormData: [],
    }
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateRoleFormData = this.updateRoleFormData.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
  }

  componentDidMount() {
    this.roleId = this.props.match.params.id
    const currentUser = retrieveSessionInformation()
    this.domainCode = currentUser.user.domainCode
    //this.domainCode = localStorage.getItem('domainCode')
    this.updateRoleFormData('domainCode', this.domainCode)
    this.getRoles()
    //this.getPermissions()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getRoles () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getRoles(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      response.map(details => {
        if(this.roleId === details.id){
          this.getRolePermissions(details.name)
        }else{
          this.setState({
            roleFormData: []
          })
        }
      })
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  async getRolePermissions (roleName) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getRolePermissions(this.domainCode, roleName)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.getPermissions(roleName, response);
      // this.setState({
      //   roleFormData: {...this.state.roleFormData, 
      //     permissions: this.getPermissionId(response),
      //     defaultSelectedPermissions: this.getPermissionId(response),
      //     name: roleName
      //   },
      //   isLoading: false,
      //   canUpdate: true,
      // })
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  async getPermissions (roleName, rolePermissions) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getPermissions(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        roleFormData: {...this.state.roleFormData, 
          permissions: this.getPermissionId(rolePermissions),
          defaultSelectedPermissions: this.getPermissionId(rolePermissions),
          name: roleName
        },
        canUpdate: true,
        permissions: response,
        isLoading: false,
      })
    }else{
      this.setState({
        permissions: [],
        isLoading: false,
        errorMessage: response
      })
    }
  }

  getPermissionId (permissions) {
    let permissionArray = [];
    permissions.map(details => {
      permissionArray.push(details.id)
    })

    return permissionArray;
  }

  toJSON(array) {
    let newArray = [];
    array.map(value => {
      let myArray = {};
      myArray["id"] = value;
      newArray.push(myArray)
    })

    return newArray;
  }

  async onUpdateButtonClick () {
    this.setState({
      canUpdate: false,
      canSave: true,
      pageTitle: 'Update a Role',
      disabled: false,
    })
  }

  async onCancelButtonClick () {
    this.setState({
      canUpdate: true,
      canSave: false,
      pageTitle: 'View Role Details',
      disabled: true,
    })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})
    const { permissions, defaultSelectedPermissions } = this.state.roleFormData
    
    const addedPermissions = permissions.filter(function(v) {
      return !defaultSelectedPermissions.includes(v)
    })
  
    const removedPermissions = defaultSelectedPermissions.filter(function(v) {
      return !permissions.includes(v)
    })
  
    if(addedPermissions && addedPermissions.length > 0) {
      const createRoleResponseObj = await platformManagement.createRole({ 
        name: this.state.roleFormData.name,
        domainCode: this.state.roleFormData.domainCode,
        permissions: this.toJSON(addedPermissions)
      })

      const createRoleResponseStatus = createRoleResponseObj.status
      const createRoleResponse = createRoleResponseObj.response

      if (createRoleResponseStatus === SUCCESS_STATUS) {
        if(createRoleResponse.code === ACCEPTED_REQUEST) {
          this.setState({
            isLoading: false,
            successMessage: 'Your changes to this role has been sent successfully for approval.',
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }else{
          this.setState({
            isLoading: false,
            successMessage: 'Role updated successfully.',
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }
      }else{
        this.setState({
          errorMessage: createRoleResponse,
          isLoading: false
        })
      }
    }
    
    if(removedPermissions && removedPermissions.length > 0) {
      const removeRoleResponseObj = await platformManagement.removeRolePermission({ 
        name: this.state.roleFormData.name,
        domainCode: this.state.roleFormData.domainCode,
        permissions: this.toJSON(removedPermissions)
      })

      const removeRoleResponseStatus = removeRoleResponseObj.status
      const removeRoleResponse = removeRoleResponseObj.response
      if (removeRoleResponseStatus === SUCCESS_STATUS) {
        if(removeRoleResponse.code === ACCEPTED_REQUEST) {
          this.setState({
            isLoading: false,
            successMessage: 'Your changes to this role has been sent successfully for approval.',
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }else{
          this.setState({
            isLoading: false,
            successMessage: 'Role updated successfully.',
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }
      }else{
        this.setState({
          errorMessage: removeRoleResponse,
          isLoading: false
        })
      }
    }

    window.scrollTo(0, this.metadataSectionRef.current)

  }
  
  updateRoleFormData (type, value) {
    this.setState({roleFormData: {...this.state.roleFormData, [type]: value}})
  }

  isFormValidForUpdate () {
    const { permissions, defaultSelectedPermissions } = this.state.roleFormData
    if( permissions && defaultSelectedPermissions) {
      const addedPermissions = permissions.filter(function(v) {
        return !defaultSelectedPermissions.includes(v)
      })
  
      const removedPermissions = defaultSelectedPermissions.filter(function(v) {
        return !permissions.includes(v)
      })
  
      return Boolean(!addedPermissions || !removedPermissions)
    }
    return true
  }

  render () {
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_ROLE]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit Permissions</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_ROLE]}><button type="button" class="btn btn-primary" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_ROLE]}><button type="button" class="btn btn-primary" disabled={this.isFormValidForUpdate()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
        ref={this.metadataSectionRef}
      />
      <ContentHead title={"User Management"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_ROLE]}>
        <GridLayout>
          <RoleForm
            onChange={this.updateRoleFormData}
            roleFormData={this.state.roleFormData}
            allPermissions={this.state.permissions}
            disabled={this.state.disabled}
            disabled2={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
