import React from 'react';
import qs from 'querystring';
import ContentHead from '../../../../../../../components/content-head';
import { GridItem } from '../../../../../../../components/layouts/grid-item';
import BaseScene from '../../../../../../../components/scenes/component';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../../constants/api'
import { ApplicantDetailsSerializer, BusinessDetailsSerializer, AgentDetailsSerializer, DomainDetailsSerializer } from '../../../../../../../serializers';
import LGAs from '../../../../../../../fixtures/lgas.json'
import States from '../../../../../../../fixtures/states.json'
import Countries from '../../../../../../../fixtures/countries.json'
import Banks from '../../../../../../../fixtures/banks'
import IDTYPES from '../../../../../../../fixtures/id-types.json';
import BusinessTypes from '../../../../../../../fixtures/business_types.json';
import { mockAgentDetails } from '../../../../../../../utils/mock'
import ApplicantDetailsForm from '../../../../../../../components/application-form/applicant-details'
import BusinessDetailsForm from '../../../../../../../components/application-form/business-details'
import NextOfKinDetailsForm from '../../../../../../../components/application-form/next-of-kin-details'
import KycDocuments from '../../../../../../../components/application-form/kyc-documents'
import OtherDetails from '../../../../../../../components/application-form/other-details'
import WalletBalance from '../../../../../../../components/application-form/wallet-balance'
import AgentClasses from '../../../../../../../fixtures/agent_classes.json'
import SuperAgents from '../../../../../../../fixtures/super_agents.json'
import Relationships from '../../../../../../../fixtures/relationships.json'
import PersonalDocuments from '../../../../../../../fixtures/personal_documents.json'
import UtilityBills from '../../../../../../../fixtures/utility_bills.json'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { platformManagement, transactionService } from '../../../../../../../mixins/api'
import { formatDateTime2 } from '../../../../../../../utils/formatter'
import { CAN_VIEW_AGENT } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../components/access-denied';
import AgentClassDetails from '../../components/agent-class-details'
import { convertToMajor } from '../../../../../../../utils/converter';

export default class ManageAgentClassScene extends BaseScene {

  notificationSectionRef = React.createRef(null)

  constructor (props) {
    super(props)
    this.state = {
      identificationTypes: IDTYPES,
      kycDocuments: [],
      uploadedDocuments: [],
      disabled: true,
      aggregators: [],
      agentClasses: [],
      domainDetails: {
        applicantDetails: {
          nextOfKin: {}
        },
        businessDetails: {},
      },
      applicantDetailsFormData: {
        nextOfKin: {},
      },
      businessDetailsFormData: {},
      bvnIsValid: false,
      emailIsValid: false,
      phoneIsValid: false,
      nextOfKinPhoneIsValid: false,
      accountNumberIsValid: false,
      isLoading: true,
      businessTypes: BusinessTypes,
      countries: [],
      states: States,
      lgas: LGAs,
      ledgerBalance: 0,
      transactionBalance: 0,
      commissionBalance: 0,
      reloadWalletBalance: false,
      canUpdate: false,
      canSave: false,
      documentUploadProgress: [],
      documentErrors: [],
      kycDocuments: [],
      agentClassUpgradeFormData: [],
      agentLimitFormData: {},
    }
    
    this.getAgentLimits = this.getAgentLimits.bind(this)
    this.loadApplication = this.loadApplication.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
    this.updateState = this.updateState.bind(this)
    this.updateCountry = this.updateCountry.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
    this.updateAgentClassUpgradeFormData = this.updateAgentClassUpgradeFormData.bind(this)
    this.updateAgentLimitFormData = this.updateAgentLimitFormData.bind(this)
  }

  componentDidMount () {
    const urlParams = qs.parse(window.location.search);

    console.log({urlParams});

    this.agentId = urlParams['?agentId'];
    this.agentPhoneNumber = urlParams['agentPhoneNumber'];

    this.loadApplication()
    //this.getUploadedDocuments()
    this.getAgentLimits()
    this.getAggregatorList()
    this.getAgentClasses()
    this.getCountries()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAgentLimits() {
    const { response, status } = await platformManagement.retrieveAgentLimits(
      this.agentPhoneNumber,
    );

    // console.log({response, status});
    this.setState({
      agentLimitFormData: {
        ...response,
        accruedDailyDeposit: convertToMajor(response.accruedDailyDeposit),
        dailyDepositLimit: convertToMajor(response.dailyDepositLimit),
        ledgerBalanceLimit: convertToMajor(response.ledgerBalanceLimit),
        dailyWithdrawalLimit: convertToMajor(response.dailyWithdrawalLimit),
        singleDepositLimit: convertToMajor(response.singleDepositLimit),
        singleWithdrawalLimit: convertToMajor(response.singleWithdrawalLimit),
      },
    });
  }

  async getAggregatorList () {
    const responseObj = await platformManagement.getSuperAgentList()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        aggregators: response
      })
    }else{
      this.setState({
        aggregators: []
      })
    }
  }

  async getAgentClasses () {
    const responseObj = await platformManagement.getAgentClasses()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentClasses: this.getActiveAgentClasses(response)
      })
    }else{
      this.setState({
        agentClasses: []
      })
    }
  }

  async getCountries () {
    const responseObj = await platformManagement.getCountries()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        countries: response
      })
    }else{
      this.setState({
        countries: Countries
      })
    }
  }

  getActiveAgentClasses (response) {
    return response.filter(details => details.disabled == 0)
  }

  async loadApplication () {
    let application = undefined
    const { status, response } = await platformManagement.getAgentByAgentCode(this.agentId)
    if(status === SUCCESS_STATUS) {
      this.setState({
        canUpdate: true,
        isLoading: false,
        // uploadedDocuments: response.documents ? response.documents : [],
        domainDetails: {
          applicantDetails: {...this.state.applicantDetails, ...DomainDetailsSerializer.serializeApplicantDetailsFromDomainDetails(response)},
          businessDetails: {...this.state.businessDetails, ...DomainDetailsSerializer.serializeBusinessDetailsFromDomainDetails(response)}
        },
        // agentFormData: response,
        // applicantDetailsFormData: {
        //   ...this.state.applicantDetails, ...DomainDetailsSerializer.serializeApplicantDetailsFromDomainDetails(response),
        //   //...ApplicantDetailsSerializer.reverseSerializeFromApplication(response.businessContact || {}),
        //   howYouHeardAboutUs: response.howYouHeardAboutUs ? response.howYouHeardAboutUs : ''
        // },
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          ...DomainDetailsSerializer.serializeBusinessDetailsFromDomainDetails(response)
        },
        agentClassUpgradeFormData: {
          ...this.state.agentClassUpgradeFormData,
          agentCode: this.agentId,
          newClassId: response.agentClassId,
          upgradeClassName: response.agentClass,
        },
      })
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  updateAgentClassUpgradeFormData (type, value) {
    this.setState({
        agentClassUpgradeFormData: {...this.state.agentClassUpgradeFormData,
            agentCode: this.agentId,
            newClassId: this.splitString(value)[0],
            upgradeClassName: this.splitString(value)[1]
        },
        businessDetailsFormData: {...this.state.businessDetailsFormData,
          agentClassId: this.splitString(value)[0],
          agentClass: this.splitString(value)[1]
        }
    })
  }

  updateAgentLimitFormData (type, value) {
    this.setState({
      agentLimitFormData: {
        ...this.state.agentLimitFormData,
        [type]: value,
      },
    });
  }

  async updateCountry (countryId) {
    this.setState({applicantDetailsFormData: {...this.state.applicantDetailsFormData, country: countryId}});
    const responseObj = await platformManagement.getStatesByCountryId(countryId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        states: response,
        canUploadDocument: false,
      })
    }else{
      this.setState({
        states: States,
        canUploadDocument: false,
      })
    }
  }

  async updateState (stateId, type) {
    if(type === 'applicantState'){
      this.setState({applicantDetailsFormData: {...this.state.applicantDetailsFormData, state: stateId}})
    }else{
      this.setState({businessDetailsFormData: {...this.state.businessDetailsFormData, state: stateId}})
    }
    const responseObj = await platformManagement.getLgasByStateId(stateId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        lgas: response
      })
    }else{
      this.setState({
        lgas: LGAs
      })
    }
  }

  onUpdateButtonClick () {
    this.setState({
      canUpdate: false,
      canSave: true,
      disabled: false,
    })
  }

  onCancelButtonClick () {
    this.setState({
      canUpdate: true,
      canSave: false,
      disabled: true,
    })
  }

  async onSubmitButtonClick () {
    this.setState({isLoading: true})
    
    const responseObj = await platformManagement.upgradeAgentClass(this.state.agentClassUpgradeFormData)
    const { status, response } = responseObj
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: 'Your change request has been sent successfully and the processing is in progress.',
        canUpdate: true,
        canSave: false,
        disabled: true
      })
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response,
      })
    }

    this.loadApplication()
  }

    splitString (string) {
        return string.split("-");
    }

  render () {
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <button class="btn" style={{backgroundColor: '#00425f', color: 'white'}} onClick={this.onUpdateButtonClick}>Edit</button>}
      {this.state.canSave && <button class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button>}
      {this.state.canSave && <button class="btn" style={{backgroundColor: '#00425f', color: 'white'}} onClick={this.onSubmitButtonClick}>Save Changes</button>}
    </React.Fragment>
    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        ref={this.notificationSectionRef}
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"View Agent"} withoutSearch subtitle="Agent Class Management" ctaWidgets={ctaWidgets} />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridItem>
          <AgentClassDetails
            disabled2={this.state.disabled}
            disabled={true}
            businessTypes={this.state.businessTypes}
            businessDetailsFormData={this.state.businessDetailsFormData}
            applicantDetailsFormData={this.state.applicantDetailsFormData}
            agentLimitFormData={this.state.agentLimitFormData}
            states={this.state.states}
            lgas={this.state.lgas}
            banks={Banks}
            applicantDetails={this.state.domainDetails.applicantDetails}
            //onChange2={this.updateApplicantDetailsForm}
            onChange={this.updateAgentClassUpgradeFormData}
            onLimitChange={this.updateAgentLimitFormData}
            businessDetails={this.state.domainDetails.businessDetails}
            updateSetState={this.updateSetState}
            updateCountry={this.updateCountry}
            updateState={this.updateState}
            showAgentClass={true}
            agentClasses={this.state.agentClasses}
            aggregators={this.state.aggregators}
            businessPhoneIsValid={true}
          />
        </GridItem>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
