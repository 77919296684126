import React from "react";
import Select from "../../../../../components/select";

export default class PerformanceConfigSearch extends React.Component {
  state = {
    metricTypes: [
      { value: "ResponseTime", label: "RESPONSE TIME" },
      { value: "FulfilmentTime", label: "FULFILMENT TIME" },
      { value: "FailToSuccessRatio", label: "FAIL TO SUCCESS RATIO" },
      { value: "all", label: "All" },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <div class="col-md-3" style={{ marginBottom: 40 }}>
          <Select
            placeholder="RESPONSE TIME"
            options={this.state.metricTypes}
            onChange={(event) => {
              this.props.updateSearchParameters(event.value);
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
