import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import Table from '../../../../../../../components/table';
import { CAN_VIEW_STATE } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { StateExportSerializer } from '../../../../../../../serializers';
import { getStatesByCountryId } from '../../../../../../../utils/helper';

const columns = [
  {
    Header: "S/N",
    accessor: 'id',
    Cell: (id) => <span>{id.index + 1}</span>,
    sortable: false,
    resizable: false,
    width: 60,
    headerStyle: {'white-space': 'unset'},
  },{
    Header: "Name",
    accessor: 'name',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
  },{
    Header: "Country",
    accessor: 'countryId',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
    Cell: props => props.value === 1 ? "Nigeria" : props.value === 2 ? "Kenya" : props.value === 3 ? "Uganda" : ""
  }
  // ,{
  //   Header: "Action",
  //   accessor: 'id',
  //   overflow: 'visible',
  //   resizable: false,
  //   sortable: false,
  //   headerStyle: {'white-space': 'unset'},
  //   getProps: (state, rowInfo, column) => {
  //     return {
  //       style: {
  //         lineHeight: '20px',
  //         textAlign: 'center',
  //         whiteSpace: 'unset',
  //       },
  //     };
  //   },
  //   width: 100,
  //   Cell: ({row, original}) => <div class="dropdown">
  //     <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
  //       <i class="la la-ellipsis-v"></i>
  //     </a>
  //     <div class="dropdown-menu dropdown-menu-right">
  //       <ProtectedComponent requiredPermissions={[CAN_VIEW_STATE]}><Link to={`system-configuration/state-management/${row.id}/view`} class="dropdown-item">
  //         <i class="la la-eye"></i>
  //         View
  //       </Link></ProtectedComponent>
  //       {/* <a href={`/home/system-configuration/state-management/${row.id}/delete`} class="dropdown-item">
  //         <i class="la la-trash"></i>
  //         Delete
  //       </a> */}
  //     </div>
  //   </div>
  // }
]

export default class StateGridScene extends BaseScene {
  
  sceneName = 'state-management'

  state = {
    states: []
  }

  getStates = this.getStates.bind(this)

  componentDidMount() {
    this.getStates()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getStates () {
    this.setState({
      isLoading: true
    })
    const countryId = 1
    setTimeout(() => {
      this.setState({
        states: getStatesByCountryId(countryId),
        isLoading: false,
        dataCount: getStatesByCountryId(countryId).length,
      })
    }, 2000)
  }

  render () {
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"State"} filterPermissions={[CAN_VIEW_STATE]} /*actionButtonText={"Add State"} actionButtonUrl={"/home/system-configuration/state-management/add"} totalCount={56} actionButtonRequiredPermissions={[CAN_CREATE_STATE]}*//>
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_STATE]}>
        <GridLayout>
          <Table 
            columns={columns} 
            data={this.state.states} 
            loading={this.state.isLoading}
            NoDataText={`No states found`}
            dataCount={this.state.dataCount}
            getData={this.getStates}
            disabled={true}
            exportData={StateExportSerializer.reverseStateDetails(this.state.states)}
            exportHeaders={StateExportSerializer.headerExport()}
            exportFileName={'States'}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
