import React, { Component } from "react";

export class ContentHeadButton extends Component {
  render() {
    const { text, onSubmit, isSaveButtonEnabled } = this.props;

    return (
      <div className="row">
        <div className="kt-portlet" style={{ padding: "20px" }}>
          <form className="kt-form kt-form--label-right">
            <div className="d-flex justify-content-between">
              <div className="flex items-center">
                <span style={{ fontWeight: "500", fontSize: "16px", color: "black" }}
                >{text}</span>
              </div>

              <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
                <button
                  onClick={onSubmit}
                  type="button"
                  className={`btn isw-button ${!isSaveButtonEnabled ? "opacity-50 cursor-not-allowed" : ""}`}
                  disabled={!isSaveButtonEnabled}
                >
                  Save Changes
                </button>

              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
