import React from 'react'
import Select from '../../../../../../components/select'
import SettlementTypes from '../../../../../../fixtures/settlement_types.json'
import { splitNameWithUnderscore, toDecimal } from '../../../../../../utils/helper'

export default class ServiceTypeForm extends React.Component {
    
    validateNegativeNumber (value) {
        return Boolean (parseFloat(value) > 0)
    }
    
    validateMaxField (minValue, maxValue) {
        return Boolean (parseInt(maxValue) > parseInt(minValue))
    }
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Name *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" placeholder="Name" defaultValue={this.props.serviceTypeFormData.name} onChange={event => this.props.updateSetState('name', splitNameWithUnderscore(event.target.value))} disabled/>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Display Name *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.serviceTypeFormData.displayName} placeholder="Display Name" onChange={event => this.props.updateSetState('displayName', splitNameWithUnderscore(event.target.value))} disabled={this.props.disabled} />
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Minimum Amount <span>(&#8358;)</span> *:<br></br></label>
                            <input type="number" defaultValue={this.props.serviceTypeFormData.minimumAmountNaira} class="form-control" onChange={event => {
                                this.props.updateSetState('minimumAmount', toDecimal(event.target.value, 2) * 100)
                                this.props.updateState('isMinimumAmountNegative', !this.validateNegativeNumber(toDecimal(event.target.value, 2)))
                                this.props.updateState('isMaximumAmountValid', !this.validateMaxField((toDecimal(event.target.value, 2)) * 100, this.props.serviceTypeFormData.maximumAmount))
                            }} disabled={this.props.disabled} />
                            {this.props.isMinimumAmountNegative && <span style={{color: 'red'}}>Minimum amount must be greater than 0</span>}
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Maximum Amount <span>(&#8358;)</span> *:<br></br></label>
                            <input autoComplete="off" type="text" defaultValue={this.props.serviceTypeFormData.maximumAmountNaira} class="form-control" onChange={event => {
                                this.props.updateSetState('maximumAmount', toDecimal(event.target.value, 2) * 100)
                                this.props.updateState('isMaximumAmountNegative', !this.validateNegativeNumber(toDecimal(event.target.value, 2)))
                                this.props.updateState('isMaximumAmountValid', !this.validateMaxField(this.props.serviceTypeFormData.minimumAmount, toDecimal(event.target.value, 2) * 100))
                            }} disabled={this.props.disabled} />
                            {this.props.isMaximumAmountNegative && <span style={{color: 'red'}}>Maximum amount must be greater than 0</span>}<br/>
                            {this.props.isMaximumAmountValid && <span style={{color: 'red'}}>Maximum amount must be greater than minimum amount</span>}
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Handler *:</label>
                            <Select 
                                options={this.props.handlers.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                onChange={(event) => this.props.updateSetState('handler', event.value)}
                                disabled={true}
                                defaultValue={this.props.serviceTypeFormData.handler}
                                minHeight={40}
                            />
                            {/* <select autoComplete="off" value={this.props.serviceTypeFormData.handler} class="form-control input-large kt-selectpicker" data-live-search="true" disabled onChange={(event) => this.props.updateSetState('handler', event.target.value)}>
                                <option selected disabled>Select</option>
                                {this.props.handlers.map((value, index) => <option value={value.id} key={value.id}>{value.name}</option>)}
                            </select> */}
                        </div>

                        {this.props.serviceTypeFormData.handler == 0 && <div class="form-group col-lg-6">
                            <label>External Service Type *:</label>
                            <Select 
                                options={this.props.externalServiceTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                onChange={(event) => this.props.updateSetState('externalServiceType', event.value)}
                                disabled={this.props.disabled}
                                defaultValue={this.props.serviceTypeFormData.externalServiceType}
                                minHeight={40}
                            />
                            {/* <select autoComplete="off" value={this.props.serviceTypeFormData.externalServiceType} class="form-control input-large kt-selectpicker" data-live-search="true" disabled={this.props.disabled} onChange={(event) => this.props.updateSetState('externalServiceType', event.target.value)}>
                                <option selected disabled>Select</option>
                                {this.props.externalServiceTypes.map((value, index) => <option value={value.id} key={value.id}>{value.name}</option>)}
                            </select> */}
                        </div>}

                        <div class="form-group col-lg-6">
                            <label>Settlement Interval *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.serviceTypeFormData.settlementInterval} placeholder="Settlement Interval" onChange={event => this.props.updateSetState('settlementInterval', event.target.value)} disabled={this.props.disabled} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Settlement Type:<br></br></label>
                            <Select 
                                options={SettlementTypes.map(detail => { return ( {label: detail.name, value: detail.value} ) })}
                                onChange={event => this.props.updateSetState('settlementType', event.value)}
                                disabled={this.props.disabled}
                                defaultValue={this.props.serviceTypeFormData.settlementType}
                                minHeight={40}
                            />
                            {/* <select autoComplete="off" type="text" value={this.props.serviceTypeFormData ? this.props.serviceTypeFormData.settlementType : ''} class="form-control" placeholder="Settlement Tyoe" onChange={event => this.props.updateSetState('settlementType', event.target.value)} disabled={this.props.disabled ? this.props.disabled : ''}>
                                <option selected disabled>Select</option>
                                {SettlementTypes.map(detail => <option value={detail.value}>{detail.name}</option>)}
                            </select> */}
                        </div>
                    </div> 
                </form>
            </div>
        </React.Fragment>
    }
}