import qs from "querystring";
import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { AgentSearch } from "../../../../../../../components/forms/agent-search";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { AGENT_TYPE_ID } from "../../../../../../../constants/domain-types";
import {
  CAN_ACTIVATE_AGENT,
  CAN_DEACTIVATE_AGENT,
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_RETIRE_AGENT,
  CAN_VIEW_AGENT,
  CAN_VIEW_TRANSACTION,
  CAN_SUSPEND_FOR_KYC,
} from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api";
import {
  retrieveOtherInformation,
  saveOtherInformation,
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTime,
  pastTenseFormatter,
} from "../../../../../../../utils/formatter";
import AgentTable from "../../components/agent-table";

export default class AgentsGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      agentSearchParameters: {
        agentType: AGENT_TYPE_ID,
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      //parentBusinessName: retrieveOtherInformation("aggregatorBusinessName")
    };

    this.getAgents = this.getAgents.bind(this);
    this.updateAgent = this.updateAgent.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.redirectToWalletJournal = this.redirectToWalletJournal.bind(this);
    this.redirectToCommissionHistory = this.redirectToCommissionHistory.bind(
      this
    );
    this.downloadAgentData = this.downloadAgentData.bind(this);
    this.updateAgentSearchParameters = this.updateAgentSearchParameters.bind(
      this
    );
  }

  sceneName = "agent-management";

  componentDidMount() {
    this.parentId = qs.parse(window.location.search)["?parentId"];
    this.setState({
      parentBusinessName: this.parentId
        ? `${retrieveOtherInformation("aggregatorBusinessName")} Agents`
        : "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  getClickedAction(agentId, businessName, status, suspendKyc = false) {
    this.setState({
      agentId: agentId,
      agentStatus: status,
      suspendKyc: suspendKyc,
      agentBusinessName: businessName,
      showCommentField:
        status === "suspend" || status === "retire" ? true : false,
    });
  }

  updateAgentSearchParameters(type, value) {
    this.setState({
      agentSearchParameters: {
        ...this.state.agentSearchParameters,
        [type]: value,
      },
    });
  }

  async updateAgent() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.updateAgentStatus(
      this.state.agentStatus,
      this.state.agentId,
      this.state.description,
      this.state.suspendKyc
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code == ACCEPTED_REQUEST) {
        this.setState({
          agentId: null,
          agentStatus: null,
          agentBusinessName: null,
          description: null,
          isLoading: false,
          successMessage: `Your request to ${this.state.agentStatus} ${this.state.agentBusinessName} as an agent has been sent successfully for approval.`,
        });
        return;
      } else {
        this.setState({
          agentId: null,
          agentStatus: null,
          agentBusinessName: null,
          description: null,
          successMessage: this.state.suspendKyc
            ? `${this.state.agentBusinessName
            }'s KYC was successfully ${pastTenseFormatter(
              this.state.agentStatus
            )}.`
            : `${this.state.agentBusinessName
            } was successfully ${pastTenseFormatter(
              this.state.agentStatus
            )} as an agent.`,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getAgents();
  }

  async getAgents(pageNum, pageSize) {
    this.parentId = qs.parse(window.location.search)["?parentId"];
    const payload = this.state.agentSearchParameters || {};

    payload.startDate = payload.startDate ? formatBackEndDateTime(payload.startDate) : "";
    payload.endDate = payload.endDate ? formatBackEndDateTime(payload.endDate) : "";
    payload.parentId = this.parentId ? this.parentId : "";

    let queryParams = `?pageNum=${pageNum}&pageSize=${pageSize}`;
    if (payload.virtualAccountNumber) {
      queryParams += `&virtualAccountNumber=${encodeURIComponent(payload.virtualAccountNumber)}`;
    }

    this.setState({ isLoading: true, canLoadData: true });

    const responseObj = await platformManagement.searchAgents(pageNum, pageSize, payload);
    const { status, response, count } = responseObj;

    if (status === SUCCESS_STATUS) {
      this.setState({
        agents: response || "",
        dataCount: count,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: response.length === 0 ? "No agents found" : "",
      });
    } else {
      this.setState({
        agents: [],
        agentExportData: [],
        pages: 1,
        pageSize,
        page: pageNum,
        dataCount: 0,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No agents found",
      });
    }
  }

  async downloadAgentData() {
    this.parentId = qs.parse(window.location.search)["?parentId"];
    const payload = this.state.agentSearchParameters;
    payload.startDate =
      payload.startDate && formatBackEndDateTime(payload.startDate);
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    payload.parentId = this.parentId ? this.parentId : null;
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await platformManagement.downloadAgentData(
      this.state.page,
      this.state.dataCount,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "agents.xls");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  isFormValid() {
    if (this.state.showCommentField) {
      return Boolean(this.state.description);
    } else {
      return true;
    }
  }

  handleSearchRequest() {
    this.setState({ loading: true });
    this.setState({
      startPage: 1,
    });
    setTimeout(() => {
      this.getAgents(1, 20);
      this.setState({ loading: false });
    });
  }

  redirectToWalletJournal(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("agentDetails", JSON.stringify(agent));
  }

  redirectToCommissionHistory(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("commissionAgentDetails", JSON.stringify(agent));
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`Do you want to ${this.state.agentStatus} ${this.state.agentBusinessName} as an agent?`}</p>
        {this.state.showCommentField && (
          <textarea
            placeholder={`Add more comment`}
            autoComplete="off"
            class="form-control"
            style={{ height: "200px", resize: "none" }}
            maxLength="100"
            name="description"
            onChange={(event) =>
              this.setState({ description: event.target.value })
            }
          ></textarea>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Agents"}
          subtitle={this.state.parentBusinessName}
          filterPermissions={[CAN_VIEW_AGENT]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AGENT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <AgentSearch
              handleSearchRequest={this.handleSearchRequest}
              agentType={AGENT_TYPE_ID}
              preloadDate={false}
              agentSearchParameters={this.state.agentSearchParameters}
              updateAgentSearchParameters={this.updateAgentSearchParameters}
            />
            <AgentTable
              data={this.state.agents}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getAgents={this.getAgents}
              getClickedAction={this.getClickedAction}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_AGENT}
              canActivate={CAN_ACTIVATE_AGENT}
              canDeactivate={CAN_DEACTIVATE_AGENT}
              canSuspendKyc={CAN_SUSPEND_FOR_KYC}
              canRetire={CAN_RETIRE_AGENT}
              canManageDomain={CAN_MANAGE_AGENT_DOMAIN}
              canViewTransaction={CAN_VIEW_TRANSACTION}
              redirectToWalletJournal={this.redirectToWalletJournal}
              redirectToCommissionHistory={this.redirectToCommissionHistory}
              canLoadData={this.state.canLoadData}
              downloadAgentData={this.downloadAgentData}
            />
            <PromptModal
              modalTitle={this.state.agentStatus + ` Agent`}
              modalBody={modalBody}
              deleteModal={this.updateAgent}
              modalSize={`modal-md`}
              height={this.state.showCommentField ? "430px" : "230px"}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
              textAlign={"left"}
              modalFooterButton2Disabled={!this.isFormValid()}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
