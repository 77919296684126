import React from 'react'
import { FormLayout } from '../../../components/forms/form-layout'
import { GridItem } from '../../../components/layouts/grid-item';
import { Form } from '../../../components/forms/form';
import { getDomainTypeName } from '../../../utils/helper'
import Notification from '../../../components/notification'

export const LoginProfile = (props) => {
    const { 
        userProfile, loginSuccessful, errorMessage, updateLoginFormData, 
        updateSetState, attemptLogin, isLoading,  loginFormData: { domainTypeId } 
    } = props;
    const userProfiles = userProfile.sort((a, b) => (a.domainTypeId > b.domainTypeId) ? 1 : -1)
    return <>
        <GridItem isFluid class="kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
            <FormLayout class="fade-in" title={"Profile"}>
                <div style={{textAlign: 'center'}}>
                    <span>
                    Please select a profile to continue.
                    </span>
                </div>
                <Form class="kt-form" action="" novalidate="novalidate">
                    {loginSuccessful === false && 
                        <Notification 
                        errorMessage={errorMessage}
                        />
                    }    
                    {userProfiles.map(value => <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id={`exampleRadios${value.domainTypeId}`} value={value.domainTypeId} onChange={(event) => {
                            updateLoginFormData('domainTypeId', event.target.value)
                            updateSetState('loginSuccessful', null)
                        }}/>
                        <label class="form-check-label" for={`exampleRadios${value.domainTypeId}`}>
                            <div>
                                <p style={{fontSize: '15px'}}><strong>{getDomainTypeName(value.domainTypeId)}</strong></p>
                                <span>{value.domainName}</span><br/>
                                <span>{value.roleName}</span>
                            </div>
                        </label>    
                        <hr/>        
                    </div>)}
                    <div class="kt-login__actions">
                        <button id="kt_login_signin_submit" disabled={!domainTypeId} class="form-control btn btn-interswitch btn-elevate kt-login__btn-primary login-button" onClick={(e) => {
                        e.preventDefault()
                        attemptLogin()
                        }}>{!isLoading ? `Continue` : <span class="loading">Loading</span>}</button>
                    </div>
                </Form>
            </FormLayout>
        </GridItem>
    </>
}