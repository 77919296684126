import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS
} from "../../../../../../../constants/api";
import { CAN_VIEW_TRANSACTION } from "../../../../../../../constants/permissions.js";
import { historicalData } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTime,
  phoneNumberFormatter
} from "../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../utils/helper";
import HistoricalTransactionsSearch from "../../components/historical-transactions-search";
import HistoricalTransactionsTable from "../../components/historical-transactions-table";

export default class HistoricalTransactionsGridScene extends BaseScene {
  sceneName = "historical-transactions";

  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      historicalTransactions: [],
      historicalTransactionDetails: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("endDate")
          : null,
        transType: "",
      },
      pages: 1,
      pageNum: 1,
      pageSize: 20,
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      tableMessage: "",
    };

    this.getHistoricalTransactions = this.getHistoricalTransactions.bind(this);
    // this.getTransactions = this.getTransactions.bind(this)
    //this.getHistoricalTransactionDetails = this.getHistoricalTransactionDetails.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getHistoricalTransactions(pageNum, pageSize) {
    const {
      agentPhone,
      gmppRef,
      transType,
      endDate,
      startDate,
    } = this.state.searchParameters;
    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate);
    const formattedBusinessMobileNo =
      agentPhone && phoneNumberFormatter(agentPhone);
    this.setState({
      pageNum,
      pageSize,
      isLoading: true,
      canLoadData: true,
    });
    const {
      status,
      response,
      count,
    } = await historicalData.getHistoricalTransactions(
      pageNum,
      pageSize,
      formattedBusinessMobileNo,
      gmppRef,
      transType,
      formattedStartDate,
      formattedEndDate
    );
    if (status === SUCCESS_STATUS) {
      //this.getAllExportedHistoricalTransactions(1, count, formattedBusinessMobileNo, esbRef, transType, formattedStartDate, formattedEndDate)
      this.setState({
        historicalTransactions: response,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count ? count : 1,
        isLoading: false,
        tableMessage: count === 0 ? "No historical transactions found" : "",
      });
    } else {
      this.setState({
        historicalTransactions: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No historical transactions found",
      });
    }
  }

  handleSearchRequest() {
    // const {
    //   agentPhone,
    //   transType,
    //   gmppRef,
    //   endDate,
    //   startDate,
    // } = this.state.searchParameters
    this.getHistoricalTransactions(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(transRef, actionType) {
    if (actionType === "view") {
      this.getTransactionDetails(transRef);
    }
    this.setState({
      transRef,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`Do you want to resolve this transaction ${this.state.transRef} as`}</p>
        <div class="form-group row">
          <div class="col-lg-5">
            <div class="col-12">
              <div class="kt-radio-inline">
                <label class="kt-radio">
                  <input
                    autoComplete="off"
                    type="radio"
                    name="gender"
                    value="1"
                    checked={this.state.desiredStatus === 1}
                    onChange={(event) =>
                      this.setState({ desiredStatus: event.target.value })
                    }
                  />{" "}
                  Success
                  <span></span>
                </label>
                <label class="kt-radio">
                  <input
                    autoComplete="off"
                    type="radio"
                    name="gender"
                    value="0"
                    checked={this.state.desiredStatus === 0}
                    onChange={(event) =>
                      this.setState({ desiredStatus: event.target.value })
                    }
                  />{" "}
                  Fail
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <ContentHead
          title={"Historical Transactions"}
          filterPermissions={[CAN_VIEW_TRANSACTION]}
          totalCount={56}
        />
        <Notification
          ref={this.notificationSectionRef}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <HistoricalTransactionsSearch
              searchParameters={this.state.searchParameters}
              updateSearchParameters={this.updateSearchParameters}
              handleSearchRequest={this.handleSearchRequest}
            />
            <HistoricalTransactionsTable
              pages={this.state.pages}
              getHistoricalTransactions={this.getHistoricalTransactions}
              data={this.state.historicalTransactions}
              startPage={this.state.startPage}
              viewMore={this.getHistoricalTransactionDetails}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              page={this.state.pageNum}
              pageSize={this.state.pageSize}
              searchParameters={this.state.searchParameters}
              canView={CAN_VIEW_TRANSACTION}
              getClickedAction={this.getClickedAction}
              canLoadData={this.state.canLoadData}
            />
            {/* <PromptModal 
            modalTitle={this.state.actionType == 'resolve' ? `Transaction Resolution` : `Transaction Details`}
            modalBody={this.state.actionType == 'resolve' ? modalBody : <HistoricalTransactionDetails transactionDetails={this.state.transactionDetails} />}
            modalFooterButton1={this.state.actionType == 'resolve' ? `Cancel` : `Close`}
            hideModalHeader={true}
            hideModalFooter={this.state.hideModalFooter}
            modalSize={`modal-md`}
            height={this.state.actionType == 'resolve' ? '300px' : '590px'}
            modalFooterButton2={this.state.actionType == 'resolve' ? `Submit` : ''}
            deleteModal={this.resolveSingleTransaction}
            modalFooterButton2Disabled={!this.isFormValid()}
            isLoading={this.state.isLoading}
            canReload={this.state.canReload}
          /> */}
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
