import Requester from "../requester";
//import {mockGetWalletLienJournal} from  '../../../utils/mock'

const API_BASE_URL = process.env.REACT_APP_TRANSACTION_HISTORY_API_BASE_URL;

export default class transactionHistory {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getTransactions(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `transactions`,
      args: {
        pageNum,
        pageSize,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }

  getTransactionsByDateRange(
    pageNum = null,
    pageSize = null,
    startDate,
    endDate,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `transactions/date-range`,
      args: {
        pageNum,
        pageSize,
        startDate,
        endDate,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }

  getTransactionsByRef(transactionRef) {
    return this.apiRequester.get({
      endpoint: `transactions/${transactionRef}`,
    });
  }

  getTransactionsByType(
    startDate,
    endDate,
    transactionTypeInt,
    transactionStatus
  ) {
    return this.apiRequester.get({
      endpoint: `transactions/chart/date-range`,
      args: {
        startDate,
        endDate,
        transactionTypeInt,
        statusCodeInt: transactionStatus,
      },
    });
  }

  getTransactionsByDomainCode(domainCode, pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `transactions/domain-code`,
      args: {
        domainCode,
        pageNum,
        pageSize,
      },
    });
  }

  getTransactionsByResponseCode(responseCode, pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `transactions/response-code`,
      args: {
        responseCode,
        pageNum,
        pageSize,
      },
    });
  }

  getTransactionsByRequestRef(
    requestReference,
    pageNum = null,
    pageSize = null
  ) {
    return this.apiRequester.get({
      endpoint: `transactions/request-reference`,
      args: {
        requestReference,
        pageNum,
        pageSize,
      },
    });
  }

  getTransactionsByChannel(channel, pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `transactions/channel`,
      args: {
        channel,
        pageNum,
        pageSize,
      },
    });
  }

  getTransactionsBySearch(
    pageNum = null,
    pageSize = null,
    businessMobileNo = null,
    businessName = null,
    endDate = null,
    startDate = null,
    statusCodeInt = "",
    transactionTypeInt = "",
    transactionRef = null,
    responseCode = null,
    parentReference = null,
    service = "",
    paymentItemCode = "",
    QT = "",
    serviceProvider = ""
  ) {
    return this.apiRequester.get({
      endpoint: `transactions/search`,
      args: {
        businessMobileNo,
        businessName,
        endDate,
        startDate,
        pageNum,
        pageSize,
        ...(statusCodeInt != "" ? { statusCodeInt } : {}),
        ...(transactionTypeInt != "" ? { transactionTypeInt } : {}),
        transactionRef,
        responseCode,
        parentReference,
        ...(service != "" ? { service } : {}),
        ...(paymentItemCode != "" ? { paymentItemCode } : {}),
        ...(QT != "" ? { QT } : {}),
        ...(serviceProvider != "" ? { serviceProvider } : {}),
      },
    });
  }

  resolveSingleTransaction(transRef, payload) {
    return this.apiRequester.put({
      endpoint: `resolutions/${transRef}/complete`,
      body: payload,
    });
  }

  requerySingleTransaction(transRef) {
    return this.apiRequester.get({
      endpoint: `resolutions/${transRef}/status`,
    });
  }

  requeryBulkTransactions(payload) {
    return this.apiRequester.put({
      endpoint: `resolutions/query`,
      body: payload,
    });
  }

  resolveBulkTransactions(payload) {
    return this.apiRequester.put({
      endpoint: `resolutions/complete`,
      body: payload,
    });
  }

  getCommissions(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    start = null,
    end = null,
    event = null,
    agentPhoneNumber = null,
    parentRef = null,
    transactionRef = null,
    agentId = null
  ) {
    return this.apiRequester.get({
      endpoint: `commissions/`,
      args: {
        ...(agentId ? { agentId } : {}),
        ...(agentPhoneNumber ? { agentPhoneNumber } : {}),
        ...(parentRef != "" ? { parentRef } : {}),
        ...(transactionRef != "" ? { transactionRef } : {}),
        end,
        start,
        pageNum,
        pageSize,
        ...(transactionTypeInt != "" ? { transactionTypeInt } : {}),
        ...(event != "All" ? { event } : {}),
      },
    });
  }

  getWalletLienJournal(
    pageNum = null,
    pageSize = null,
    walletJournalType = null,
    agentCode = null,
    startDate = null,
    endDate = null,
    sortDesc = true,
    agentMobileNo = null
  ) {
    return this.apiRequester.get({
      endpoint: `journal/mini-statement`,
      args: {
        pageNum,
        pageSize,
        endDate,
        startDate,
        agentCode: "2348166380172",
        sortDesc,
        walletJournalType,
        agentMobileNo,
      },
    });
  }

  getWalletJournal(
    pageNum = null,
    pageSize = null,
    walletJournalType = null,
    agentCode = null,
    startDate = null,
    endDate = null
  ) {
    return this.apiRequester.get({
      endpoint: `journal/mini-statement`,
      args: {
        pageNum,
        pageSize,
        endDate,
        startDate,
        agentCode,
        walletJournalType,
      },
    });
  }

  downloadWalletJournal(
    startDate,
    endDate,
    walletJournalType,
    pageSize,
    pageNum,
    agentCode
  ) {
    return this.apiRequester.get({
      endpoint: "journal/mini-statement/download",
      args: {
        startDate,
        endDate,
        pageSize,
        pageNum,
        walletJournalType,
        agentCode,
      },
      headers: {
        download: true,
      },
    });
  }

  downloadTransactionSummary(pageNum = null, pageSize = null, payload) {
    const { endDate, startDate, statusCodeInt, transactionTypeInt } = payload;
    return this.apiRequester.get({
      endpoint: "transactions/search/download/summary",
      args: {
        pageNum,
        pageSize,
        ...(statusCodeInt != "" ? { statusCodeInt } : {}),
        ...(transactionTypeInt != "" ? { transactionTypeInt } : {}),
        ...(startDate != null
          ? { startDate: startDate, endDate: endDate }
          : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  getTransactionSummary(pageNum = null, pageSize = null, payload) {
    const {
      endDate,
      startDate,
      statusCodeInt,
      transactionTypeInt,
      agent,
      bankCode,
    } = payload;

    return this.apiRequester.get({
      endpoint: "transactions/search/summary",
      args: {
        pageNum,
        pageSize,
        ...(statusCodeInt != null && statusCodeInt != "All"
          ? { statusCodeInt }
          : {}),
        ...(agent ? { agent: 1 } : { agent: 0 }),
        ...(transactionTypeInt != "All" ? { transactionTypeInt } : {}),
        ...(bankCode != "All" && bankCode != null
          ? { bankCode: bankCode + "" }
          : {}),
        ...(startDate != null
          ? { startDate: startDate, endDate: endDate }
          : {}),
      },
    });
  }

  downloadTransactionSummaryDetails(pageNum = null, pageSize = null, payload) {
    const { endDate, startDate, statusCodeInt, transactionTypeInt } = payload;
    return this.apiRequester.get({
      endpoint: "transactions/search/download/details",
      args: {
        pageNum,
        pageSize,
        ...(statusCodeInt != "" ? { statusCodeInt } : {}),
        ...(transactionTypeInt != "" ? { transactionTypeInt } : {}),
        ...(startDate != null
          ? { startDate: startDate, endDate: endDate }
          : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  retrieveAgentBalances(pageNum, pageSize, payload) {
    return this.apiRequester.get({
      endpoint: "report/agent-balances",
      args: {
        pageNum,
        pageSize,
        ...payload,
      },
    });
  }

  retrieveEodReport(startDate, endDate, agentCode, pageNum, pageSize) {
    return this.apiRequester.get({
      endpoint: "report/eod",
      args: {
        agentCode,
        startDate,
        endDate,
        pageNum,
        pageSize,
      },
    });
  }

  retrieveTrialBalanceReport() {
    return this.apiRequester.get({
      endpoint: "report/trial-balance",
    });
  }

  retrieveWalletPoolStatements(
    pageNum,
    pageSize,
    endDate,
    startDate,
    walletJournalType,
    ledgerAccountCode
  ) {
    return this.apiRequester.get({
      endpoint: "report/walletpool/statement",
      args: {
        pageNum,
        pageSize,
        endDate,
        startDate,
        walletJournalType,
        ledgerAccountCode,
      },
    });
  }

  retrieveEodLedgerReport(startDate, endDate, agentCode) {
    return this.apiRequester.get({
      endpoint: "report/eod-ledger",
      args: {
        agentCode,
        startDate,
        endDate,
      },
    });
  }
  uploadNIPStock(payload) {
    const { file, filename } = payload;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", filename);
    return this.apiRequester.post({
      endpoint: `resolutions/complete/upload-transaction-status`,
      body: formData,
    });
  }
}
