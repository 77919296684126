import { formatDate } from '../../utils/formatter';

export default class NIPBankExportSerializer {
    static headerExport () {
        return [
            {label: 'Bank Name', key: 'bankName', title: 'Bank Name', dataIndex: 'bankName'},
            // {label: 'Status', key: 'enabledForSanef', title: 'Status', dataIndex: 'enabledForSanef'}
            {label: 'CBN Bank Code', key: 'cbnBankCode', title: 'cbnBankCode', dataIndex: 'cbnBankCode'},
            {label: 'NIP Bank Code', key: 'nipBankCode', title: 'nipBankCode', dataIndex: 'nipBankCode'},
            {label: 'Bank Category', key: 'bankCategory', title: 'bankCategory', dataIndex: 'bankCategory'},
            {label: 'Alias', key: 'alias', title: 'alias', dataIndex: 'alias'}

        ]
    }

    static NIPBankDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { bankName,  } = details
            let detailFormData = {
                bankName,
                
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}