import { formatDateTime } from '../../utils/formatter';

export default class RegionExportSerializer {
    static headerExport () {
        return [
            {label: 'Region Name', key: 'regionName', title: 'Region Name', dataIndex: 'regionName'},
            {label: 'Region Code', key: 'regionCode', title: 'Region Code', dataIndex: 'regionCode'},
            {label: 'status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Date Created', key: 'dateCreated', title: 'Date Created', dataIndex: 'dateCreated'},
            {label: 'Date Modified', key: 'dateLastUpdated', title: 'Date Modified', dataIndex: 'dateLastUpdated'}
        ]
    }

    static reverseRegionDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { regionName, regionCode, status, dateCreated, dateLastUpdated } = details
            let detailFormData = {
                regionName,
                regionCode,
                status,
                dateCreated: dateCreated ? formatDateTime(dateCreated) : '',
                dateLastUpdated: dateLastUpdated ? formatDateTime(dateLastUpdated) : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}