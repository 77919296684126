import React from "react";
import { ApiErrorHandler } from "../utils/error_handler";

const apiErrorHandler = new ApiErrorHandler();

const Notification = (props) => {
  return (
    <React.Fragment>
      {props.errorMessage && (
        <div class="alert alert-danger" role="alert" ref={props.ref}>
          <div class="alert-text">
            {apiErrorHandler.handleApiErrorResponse(props.errorMessage)}
          </div>
        </div>
      )}

      {props.successMessage && (
        <div class="alert alert-success" role="alert" ref={props.ref}>
          <div class="alert-text">{props.successMessage}</div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Notification;