import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import { phoneNumberFormatter } from '../../../../../../utils/formatter';

export default class FipSearch extends React.Component {
    state = {
        fipSearchParameters: {
            startDate: null,
            endDate: null,
            firstName: "",
            lastName: "",
            mobileNo: "",
            status: ""
        },
        fipStatuses: [
            {value: "", displayName: "All", label: "All"},
            {value: 1, displayName: 'Active', label: 'Active'},
            {value: 2, displayName: 'Inactive', label: 'Inactive'}
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest(this.state.fipSearchParameters)
    }
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>First Name:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({fipSearchParameters: {...this.state.fipSearchParameters, firstName: event.target.value}})} />
                    </div>
                    <div class="col-md-3">
                        <label>Last Name:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({fipSearchParameters: {...this.state.fipSearchParameters, lastName: event.target.value}})} />
                    </div>
                    <div class="col-md-3">
                        <label>Phone Number:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({fipSearchParameters: {...this.state.fipSearchParameters, mobileNo: phoneNumberFormatter(event.target.value)}})} />
                    </div>
                    <div class="col-md-3">
                        <label>Status:</label>
                        <Select 
                            options={this.state.fipStatuses}
                            onChange={event => this.setState({fipSearchParameters: {...this.state.fipSearchParameters, status: event.value}})}
                            defaultValue={this.state.fipSearchParameters.status}
                        />
                        {/* <select class="search-input" onChange={event => this.setState({fipSearchParameters: {...this.state.fipSearchParameters, status: event.target.value}})}> 
                            <option value={``}>All</option>
                            {this.state.fipStatuses.map(value => <option value={value.id}>{value.displayName}</option>)}
                        </select> */}
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Date From</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({fipSearchParameters:{...this.state.fipSearchParameters, startDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label>Date To</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({fipSearchParameters: {...this.state.fipSearchParameters, endDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.fipSearchParameters.startDate ? false : true}
                            minDate={new Date(this.state.fipSearchParameters.startDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}