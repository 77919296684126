import { connect } from 'react-redux';
import DeliveryNoteGridScene from './scene';
import { updateCurrentPathName, updateScene } from '../../../../../../../../../services/redux/actions/navigation'



function mapStateToProps (state) {

  
  return {
    scene: state.navigation.scene
  }
}




function mapDispatchToProps (dispatch) {
  return {
    updateCurrentPathName: pathName => dispatch(updateCurrentPathName(pathName)),
    updateScene: scene => dispatch(updateScene(scene))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNoteGridScene)
