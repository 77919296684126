export function mockApplicationDetails () {
  return {
    applicationType: "DRAFT",
    howYouHeardAboutUs: "Radio",
    approvalStatus: "DRAFT",
    agentTypeId: 3,
    applicantDetails: {
      firstName: "James",
      middleName: "Doe",
      surname: "Doe",
      emailAddress: "jamesdoe@gmail.com",
      address: "40, Getter Avenue, Osborne Estate",
      phoneNumber: "2349000000001",
      state: 1,
      localGovernmentArea: 1,
      nationality: 1,
      closestLandMark: "Ikoyi",
      howYouHeardAboutUs: "Radio",
      mothersMaidenName: "Doe",
      gender: "MALE",
      placeOfBirth: "Lagos",
      identificationNumber: "1234567890123",
      identificationType: "1",
      dob: "1989-01-30",
      bvn: "12345678901",
      nextOfKin: {
        firstName: "James",
        surname: "Doe",
        emailAddress: "jamesdoe@gmail.com",
        phoneNumber: "2349000000001",
        address: "40, Getter Avenue, Osborne Estate",
        relationship: "Brother",
        gender: "MALE"
      }
    },
    businessDetails: {
      businessName: "Doe Global",
      businessType: "Incorporation",
      companyRegistrationNumber: "12345678",
      phoneNumber: "2349000000001",
      state: 1,
      localGovernmentArea: 1,
      address: "40, Getter Avenue, Osborne Estate",
      bankName: "Access Bank Nigeria Plc",
      accountNumber: "0000001101"
    }
  }
}

export function mockAgentDetails () {
  return {
    agentBankAccount: {
      accountName: "James Doe",
      accountNo: "1010101010",
      bankName: "Access Bank Nigeria Plc",
      bvn: "01010101010"
    },
    agentClass: "Classic",
    agentClassId: 1,
    agentCode: "AG1234567",
    agentMobileNo: "2349070886000",
    agentType: "Agent",
    agentTypeId: 4,
    applicationApprovedBy: "James Doe",
    applicationApprovedById: "2020-01-20T07:19:45.038Z",
    applicationValidatedBy: "James Doe",
    applicationValidatedById: "2020-01-20T07:19:45.038Z",
    businessContact: {
      agentId: 3,
      contactType: 0,
      dob: "1989-01-01",
      educationLevel: "string",
      educationLevelId: 0,
      firstname: "James",
      gender: "MALE",
      id: 0,
      identityId: 2,
      lastname: "Doe",
      middlename: "James",
      motherMadienName: "Doe",
      nationality: "Nigeria",
      phoneNo: "2349070886000",
      placeOfBirth: "Lagos",
      residentialAddress: {
        addressLine1: "House 40, Amen Estate",
        addressLine2: "string",
        city: "string",
        country: "Nigeria",
        countryId: 1,
        houseNo: "string",
        landmark: "Lekki-Epe",
        latitude: 0,
        lga: "Eti-Osa",
        lgaId: 1,
        longitude: 0,
        state: "Lagos",
        stateId: 1,
        ward: "string",
        wardId: 0
      },
      title: "string"
    },
    businessEmail: "jamesdoe@gmail.com",
    businessLocation: [
      {
        addressLine1: "House 40, Amen Estate",
        addressLine2: "string",
        agentId: 0,
        approved: true,
        approvedBy: "string",
        approvedById: "string",
        city: "string",
        comments: "string",
        country: "Nigeria",
        countryId: 1,
        dateVisited: "2020-04-10T07:19:45.037Z",
        houseNo: "string",
        id: 0,
        inUsed: true,
        landmark: "Lekki-Epe",
        latitude: 0,
        lga: "Eit-Osa",
        lgaId: 1,
        longitude: 0,
        state: "Lagos",
        stateId: 1,
        visited: true,
        visitedBy: 0,
        ward: "string",
        wardId: 0
      }
    ],
    businessName: "Yetty Global Communication",
    businessPhoneNo: "2349070886000",
    businessTypeId: 1,
    businessTypeName: "Business Center",
    companyRegNo: "12891029",
    createdBy: "James Doe",
    createdById: "string",
    dateApplicationApproved: "2020-04-10T07:19:45.038Z",
    dateApplicationValidated: "2020-04-10T07:19:45.038Z",
    dateApplied: "2020-04-10T07:19:45.038Z",
    dateCreated: "2020-04-10T07:19:45.038Z",
    dateLastUpdated: "2020-04-10T07:19:45.038Z",
    directors: [],
    id: 230,
    identificationNumber: "1234567890120",
    identificationType: "Drivers License",
    lastUpdatedBy: "string",
    lastUpdatedById: "string",
    nextOfKins: [
      {
        agentId: 0,
        contactType: 0,
        dob: "string",
        educationLevel: "string",
        educationLevelId: 0,
        firstname: "James",
        gender: "MALE",
        id: 0,
        identityId: 0,
        lastname: "Doe",
        middlename: "James",
        motherMadienName: "string",
        nationality: "Nigeria",
        phoneNo: "2349070886000",
        placeOfBirth: "string",
        residentialAddress: {
          addressLine1: "House 40, Amen Estate",
          addressLine2: "string",
          city: "string",
          country: "Nigeria",
          countryId: 1,
          houseNo: "string",
          landmark: "Lekki-Epe",
          latitude: 0,
          lga: "Eti-osa",
          lgaId: 10,
          longitude: 0,
          state: "Lagos",
          stateId: 1,
          ward: "string",
          wardId: 0
        },
        title: "string"
      }
    ],
    parentId: "string",
    serviceProviderId: "string",
    status: "string",
    statusId: 0,
    walletRef: "string",
    walletStatus: "string"
  }
}

export const queryFields = [
  {
    field: "agent_class",
    label: "Agent Class",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      { value: "3", label: "Classic" },
      { value: "14", label: "Gold" },
      { value: "17", label: "Premium" },
      { value: "10007", label: "micro" }
    ]
  },
  {
    field: "status",
    label: "Status",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Enable",
        value: "1"
      },
      {
        label: "Disabled",
        value: "2"
      }
    ]
  },
  {
    field: "business_type_id",
    label: "Business Type",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      { value: "1", label: "Partnership" },
      { value: "2", label: "Sole Proprietorship" },
      { value: "3", label: "Limited Partnership" },
      { value: "4", label: "Corporation" }
    ]
  },
  {
    field: "bank_name",
    label: "Bank",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Union Bank",
        value: "123"
      }
    ]
  },
  {
    field: "location.state",
    label: "State",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Lagos",
        value: "123"
      },
      {
        label: "Abuja",
        value: "123"
      }
    ]
  },
  {
    field: "location.lga",
    label: "Lga",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Surulere",
        value: "123"
      }
    ]
  },
  {
    field: "date_created",
    label: "Date Created",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  },
  {
    field: "date_applied",
    label: "Date Applied",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  },
  {
    field: "date_application_validated",
    label: "Date Validated",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  },
  {
    field: "date_application_approved",
    label: "Date Approved",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  }
];

export function mockAllAgentBVNDetails () {

return {
  count: 151,
  content: [
    {
      agentCode: "AG349V8JNR",
      agentName: "HubMart",
      email: "femtob2006@yahoo.com",
      phoneNumber: "2347068482349",
      state: "Oyo",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "SA997QFPT9",
      agentName: "TestMania stores",
      email: "testmania@sharklasers.com",
      phoneNumber: "2348023030997",
      state: "Oyo",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG996GNFVM",
      agentName: "TestMania Agent",
      email: "testmania1@sharklasers.com",
      phoneNumber: "2348023030996",
      state: "Oyo",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "SA173VGU3Y",
      agentName: "Basito Global",
      email: "basit@sharklasers.com",
      phoneNumber: "2348166380173",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG700ZLPJU",
      agentName: "Uzoamaka",
      email: "madu@mail.com",
      phoneNumber: "2348065657700",
      state: "Adamawa",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG432S91EH",
      agentName: "jeniston stores",
      email: "ndiri2009@yahoo.com",
      phoneNumber: "2348168242123",
      state: "Anambra",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "SA091M3A9I",
      agentName: "God's Power Business Center",
      email: "godpower@gmail.com",
      phoneNumber: "2349019239091",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG0195P6PN",
      agentName: "Otaku Conglamorates 2.0",
      email: "inc4shizzy17@gmail.com",
      phoneNumber: "2348059568019",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG8925MMJU",
      agentName: "Vladimonk12",
      email: "obinna.onwuakagba@stu.cu.edu.ng",
      phoneNumber: "2348059125892",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG120ZFZUP",
      agentName: "dede investments",
      email: "bisi2016@yahoo.com",
      phoneNumber: "2348099234561",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG807V8XXE",
      agentName: "obed ventures",
      email: "marcus_2020@yahoo.com",
      phoneNumber: "2347037296530",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG192JWJIP",
      agentName: "Sba Community",
      email: "gbengasba@gmail.com",
      phoneNumber: "2348188733192",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG5052XMGJ",
      agentName: "Oloja Ventures",
      email: "olojaguyjob1@gmail.com",
      phoneNumber: "2349076177505",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG342YIDWV",
      agentName: "Oloja",
      email: "gbengaademola@gmail.com",
      phoneNumber: "2348067905342",
      state: "Imo",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG755IZWSV",
      agentName: "Olufemi Emmanuel6",
      email: "toksdprogrammer2@gmail.com",
      phoneNumber: "2348034186885",
      state: "Akwa Ibom",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG755QL1GK",
      agentName: "Olufemi Emmanuel6",
      email: "toksdprogrammer2@gmail.com",
      phoneNumber: "2348034186885",
      state: "Akwa Ibom",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG090GOCDL",
      agentName: "dele kuku",
      email: "peterkuku71@gmail.com",
      phoneNumber: "2348109319090",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG123GQKZQ",
      agentName: "daily taste",
      email: "dele253@yahoo.com",
      phoneNumber: "2348168171582",
      state: "Abia",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG8219UD8S",
      agentName: "dele Investments",
      phoneNumber: "2348033542210",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG567SXI71",
      agentName: "dubem Invests",
      email: "delabayomi@gmail.com",
      phoneNumber: "2348033642210",
      state: "Imo",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG8219UD8S",
      agentName: "dele Investments",
      phoneNumber: "2348033542210",
      state: "Lagos",
      bvnVerificationStatus: "NOT_VERIFIED"
    },
    {
      agentCode: "AG567SXI71",
      agentName: "dubem Invests",
      email: "delabayomi@gmail.com",
      phoneNumber: "2348033642210",
      state: "Imo",
      bvnVerificationStatus: "NOT_VERIFIED"
    }
  ]
}

}


export function mockgetAgentByAgentCode(){
      return {
             status :"SUCCESS",
             response:{
              "id": 49518,
              "businessName": "FUNKEE LINE",
              "companyRegNo": "27890877",
              "agentMobileNo": "2347037299649",
              "businessPhoneNo": "2347037299643",
              "agentCode": "AG643QIGKW",
              "businessTypeId": 0,
              "businessTypeName": "Super Market",
              "businessEmail": "funkee2020@gmail.com",
              "agentClass": "STANDARD",
              "agentType": "Agent",
              "agentClassId": 2,
              "agentTypeId": 4,
              "dateApplied": "2022-03-17",
              "dateApplicationValidated": "2022-03-17",
              "dateApplicationApproved": "2022-03-17",
              "dateLastUpdated": "2022-03-18",
              "applicationValidatedBy": "Super Admin",
              "applicationValidatedById": "superAdmin@mail.com",
              "createdBy": "Funke Jacobs",
              "createdById": "2347037299649",
              "parentId": "SAIFIS",
              "serviceProviderId": "SPIFIS",
              "status": "Suspended",
              "statusId": 3,
              "agentBankAccount": {
                  "accountNo": "0091923455",
                  "accountName": "FUNKEE LINE",
                  "bankName": "Zenith Bank International",
                  "bvn": "22788998098"
              },
              "businessContact": {
                  "id": 49262,
                  "agentId": 49518,
                  "firstname": "Funke",
                  "middlename": "Dele",
                  "lastname": "Jacobs",
                  "phoneNo": "2347037299649",
                  "dob": "1999-03-09",
                  "gender": "MALE",
                  "placeOfBirth": "abuja",
                  "nationality": "NIGERIA",
                  "motherMadienName": "ruth",
                  "educationLevelId": 0,
                  "residentialAddress": {
                      "addressLine1": "22 boyd road",
                      "state": "Adamawa",
                      "country": "NIGERIA",
                      "countryId": 1,
                      "stateId": 4,
                      "lgaId": 7,
                      "landmark": "brown street",
                      "longitude": 0,
                      "latitude": 0
                  },
                  "contactType": 1,
                  "identityId": 0,
                  "emailAddress": "funkee2020@gmail.com"
              },
              "nextOfKins": [
                  {
                      "id": 49263,
                      "agentId": 49518,
                      "firstname": "Mane",
                      "lastname": "Joke",
                      "phoneNo": "2349036476889",
                      "gender": "MALE",
                      "educationLevelId": 0,
                      "residentialAddress": {
                          "addressLine1": "22 boyd road",
                          "countryId": 0,
                          "stateId": 0,
                          "lgaId": 0,
                          "longitude": 0,
                          "latitude": 0
                      },
                      "contactType": 2,
                      "identityId": 0,
                      "relationship": "Brother"
                  }
              ],
              "directors": [],
              "businessLocation": [
                  {
                      "id": 49049,
                      "agentId": 49518,
                      "houseNo": "",
                      "addressLine1": "22 boyd road",
                      "country": "NIGERIA",
                      "state": "Adamawa",
                      "countryId": 1,
                      "stateId": 4,
                      "lgaId": 7,
                      "longitude": 0,
                      "latitude": 0,
                      "visited": false,
                      "visitedBy": 0,
                      "approved": false,
                      "inUsed": false,
                      "approvedBy": "",
                      "approvedById": "",
                      "comments": "transfer from on-boarding"
                  }
              ],
              "documents": [
                  {
                      "documentId": 9387,
                      "locationId": 49049,
                      "documentType": "Proof of Identity-PD",
                      "documentName": "5204_Proof_of_Identity-PD.pdf",
                      "documentExtention": "pdf",
                      "documentLink": "/data/5204_Proof_of_Identity-PD.pdf",
                      "dateCreated": "2022-03-17T17:13:12.857+0000",
                      "dateLastModified": "2022-03-17T17:13:12.857+0000"
                  },
                  {
                      "documentId": 9388,
                      "locationId": 49049,
                      "documentType": "Passport Photo-PD",
                      "documentName": "5204_Passport_Photo-PD.pdf",
                      "documentExtention": "pdf",
                      "documentLink": "/data/5204_Passport_Photo-PD.pdf",
                      "dateCreated": "2022-03-17T17:13:13.293+0000",
                      "dateLastModified": "2022-03-17T17:13:13.293+0000"
                  },
                  {
                      "documentId": 9389,
                      "locationId": 49049,
                      "documentType": "Utility Bill-BD",
                      "documentName": "5204_Utility_Bill-BD.pdf",
                      "documentExtention": "pdf",
                      "documentLink": "/data/5204_Utility_Bill-BD.pdf",
                      "dateCreated": "2022-03-17T17:13:13.720+0000",
                      "dateLastModified": "2022-03-17T17:13:13.720+0000"
                  },
                  {
                      "documentId": 9390,
                      "locationId": 49049,
                      "documentType": "Character Attestation Form-BD",
                      "documentName": "5204_Character_Attestation_Form-BD.pdf",
                      "documentExtention": "pdf",
                      "documentLink": "/data/5204_Character_Attestation_Form-BD.pdf",
                      "dateCreated": "2022-03-17T17:13:14.183+0000",
                      "dateLastModified": "2022-03-17T17:13:14.183+0000"
                  }
              ],
              "walletStatus": "WALLET_CREATED",
              "identificationType": "3",
              "identificationNumber": "23788999",
              "walletRef": "AG643QIGKW",
              "comment": "Suspension on business request",
              "howYouHeardAboutUs": "Social Media",
              "accountNo": "0091923455",
              "accountName": "FUNKEE LINE",
              "bankName": "Zenith Bank International",
              "bvn": "22788998098",
              "walletVersion": 1,
              "bvnVerificationStatus": "PENDING_VERIFICATION", //SUSPENDED
              "bvnGracePeriod": "2022-06-09T15:04:12.753",
              "dataId": "49518"
          }
      }
}

export const mockSearchAgent=()=>{
      
  return    {
        "count": 232,
        "content": [
            {
                "id": 49522,
                "businessName": "xyz",
                "agentMobileNo": "2349020261878",
                "businessPhoneNo": "2349020261878",
                "agentCode": "AG878FNJY6",
                "businessTypeId": 0,
                "businessTypeName": "classic",
                "businessEmail": "illohchidinma@gmail.com",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2022-06-09",
                "dateApplicationValidated": "2022-06-10",
                "dateApplicationApproved": "2022-06-10",
                "dateLastUpdated": "2022-06-19",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Jack Black",
                "createdById": "2349020261878",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "1234567890",
                    "accountName": "xyz",
                    "bankName": "Access Bank Nigeria Plc",
                    "bvn": "12345678910"
                },
                "walletStatus": "WALLET_CREATION_IN_PROGRESS",
                "identificationType": "1",
                "identificationNumber": "12345678",
                "walletRef": "AG878FNJY6",
                "howYouHeardAboutUs": "TV/Radio",
                "accountNo": "1234567890",
                "accountName": "xyz",
                "bankName": "Access Bank Nigeria Plc",
                "bvn": "12345678910",
                "walletVersion": 1,
                "bvnVerificationStatus": "NOT_VERIFIED",
                "dataId": "49522"
            },
            {
                "id": 49521,
                "businessName": "ndume business",
                "companyRegNo": "27890821",
                "agentMobileNo": "2348168171452",
                "businessPhoneNo": "2348033651232",
                "agentCode": "AG232QMYW4",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "funkee2025@gmail.com",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2022-04-28",
                "dateApplicationValidated": "2022-04-28",
                "dateApplicationApproved": "2022-04-28",
                "dateLastUpdated": "2022-05-10",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Dima Tolu",
                "createdById": "2348168171452",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "3454678901",
                    "accountName": "ndume business",
                    "bankName": "Zenith Bank International",
                    "bvn": "22567812345"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "23788999",
                "walletRef": "AG232QMYW4",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "3454678901",
                "accountName": "ndume business",
                "bankName": "Zenith Bank International",
                "bvn": "22567812345",
                "walletVersion": 1,
                "bvnVerificationStatus": "VERIFICATION_FAILED",
                "dataId": "49521"
            },
            {
                "id": 49520,
                "businessName": "gomez ventures",
                "companyRegNo": "27890879",
                "agentMobileNo": "2347037299652",
                "businessPhoneNo": "2348168171572",
                "agentCode": "AG572JSTKR",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "funkee2029@gmail.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2022-03-22",
                "dateApplicationValidated": "2022-03-22",
                "dateApplicationApproved": "2022-03-22",
                "dateLastUpdated": "2022-05-10",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Chidi Gomel",
                "createdById": "2347037299652",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0091922467",
                    "accountName": "gomez ventures",
                    "bankName": "Zenith Bank International",
                    "bvn": "22556788129"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "43788999",
                "walletRef": "AG572JSTKR",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "0091922467",
                "accountName": "gomez ventures",
                "bankName": "Zenith Bank International",
                "bvn": "22556788129",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49520"
            },
            {
                "id": 49519,
                "businessName": "dennis gates",
                "companyRegNo": "27890879",
                "agentMobileNo": "2347037299650",
                "businessPhoneNo": "2348168171463",
                "agentCode": "AG463SEVPM",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "funkeij29@yahoo.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2022-03-22",
                "dateApplicationValidated": "2022-03-22",
                "dateApplicationApproved": "2022-03-22",
                "dateLastUpdated": "2022-03-24",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Dotun West",
                "createdById": "2347037299650",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0091937654",
                    "accountName": "dennis gates",
                    "bankName": "Zenith Bank International",
                    "bvn": "56789803212"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "23788999",
                "walletRef": "AG463SEVPM",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "0091937654",
                "accountName": "dennis gates",
                "bankName": "Zenith Bank International",
                "bvn": "56789803212",
                "walletVersion": 1,
                "dataId": "49519"
            },
            {
                "id": 49518,
                "businessName": "FUNKEE LINE",
                "companyRegNo": "27890877",
                "agentMobileNo": "2347037299649",
                "businessPhoneNo": "2347037299643",
                "agentCode": "AG643QIGKW",
                "businessTypeId": 0,
                "businessTypeName": "Super Market",
                "businessEmail": "funkee2020@gmail.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2022-03-17",
                "dateApplicationValidated": "2022-03-17",
                "dateApplicationApproved": "2022-03-17",
                "dateLastUpdated": "2022-03-18",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Funke Jacobs",
                "createdById": "2347037299649",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "Suspended", //"Suspended"
                "statusId": 3,  //3
                "agentBankAccount": {
                    "accountNo": "0091923455",
                    "accountName": "FUNKEE LINE",
                    "bankName": "Zenith Bank International",
                    "bvn": "22788998098"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "3",
                "identificationNumber": "23788999",
                "walletRef": "AG643QIGKW",
                "comment": "Suspension on business request",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "0091923455",
                "accountName": "FUNKEE LINE",
                "bankName": "Zenith Bank International",
                "bvn": "22788998098",
                "walletVersion": 1,
                "bvnVerificationStatus": "PENDING_VERFICATION",//_SUSPENDED
                "dataId": "49518"
            },
            {
                "id": 49517,
                "businessName": "Beth Corpddd",
                "companyRegNo": "12344433",
                "agentMobileNo": "2348051587725",
                "businessPhoneNo": "2348051587725",
                "agentCode": "AG725L6R3N",
                "businessTypeId": 0,
                "businessTypeName": "Business Center",
                "businessEmail": "testerpos4@qa.team",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2022-02-11",
                "dateApplicationValidated": "2022-02-11",
                "dateApplicationApproved": "2022-02-11",
                "dateLastUpdated": "2022-03-02",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Testman Postman",
                "createdById": "2348051587725",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "2312233123",
                    "accountName": "Beth Corpddd",
                    "bankName": "Zenith Bank International",
                    "bvn": "23432222123"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "1212121233",
                "walletRef": "AG725L6R3N",
                "howYouHeardAboutUs": "From a friend",
                "accountNo": "2312233123",
                "accountName": "Beth Corpddd",
                "bankName": "Zenith Bank International",
                "bvn": "23432222123",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49517"
            },
            {
                "id": 49516,
                "businessName": "Fashionistass",
                "agentMobileNo": "2348051587726",
                "businessPhoneNo": "2348051587726",
                "agentCode": "AG7266VPET",
                "businessTypeId": 0,
                "businessTypeName": "Super Market",
                "businessEmail": "testerpos6@qa.team",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2022-02-11",
                "dateApplicationValidated": "2022-02-11",
                "dateApplicationApproved": "2022-02-11",
                "dateLastUpdated": "2022-03-02",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Tester Persona",
                "createdById": "2348051587726",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "2121343213",
                    "accountName": "Fashionistass",
                    "bankName": "Zenith Bank International",
                    "bvn": "23431234221"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "123221222",
                "walletRef": "AG7266VPET",
                "howYouHeardAboutUs": "TV/Radio",
                "accountNo": "2121343213",
                "accountName": "Fashionistass",
                "bankName": "Zenith Bank International",
                "bvn": "23431234221",
                "walletVersion": 1,
                "bvnVerificationStatus": "VERIFIED",
                "dataId": "49516"
            },
            {
                "id": 49515,
                "businessName": "Beth Corping",
                "agentMobileNo": "2348051587727",
                "businessPhoneNo": "2348051587727",
                "agentCode": "AG727DD7QJ",
                "businessTypeId": 0,
                "businessTypeName": "Super Market",
                "businessEmail": "testerpos7@qa.team",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2022-02-11",
                "dateApplicationValidated": "2022-02-11",
                "dateApplicationApproved": "2022-02-11",
                "dateLastUpdated": "2022-03-02",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Habib Habib",
                "createdById": "2348051587727",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "2321232123",
                    "accountName": "Beth Corping",
                    "bankName": "Zenith Bank International",
                    "bvn": "23425555333"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "2",
                "identificationNumber": "A1234567",
                "walletRef": "AG727DD7QJ",
                "howYouHeardAboutUs": "Market Storm",
                "accountNo": "2321232123",
                "accountName": "Beth Corping",
                "bankName": "Zenith Bank International",
                "bvn": "23425555333",
                "walletVersion": 1,
                "bvnVerificationStatus": "VERIFIED",
                "dataId": "49515"
            },
            {
                "id": 49514,
                "businessName": "Great Businessing",
                "agentMobileNo": "2348051587728",
                "businessPhoneNo": "2348051587728",
                "agentCode": "AG7282XGHE",
                "businessTypeId": 0,
                "businessTypeName": "Super Market",
                "businessEmail": "testerpos8@qa.team",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2022-02-11",
                "dateApplicationValidated": "2022-02-11",
                "dateApplicationApproved": "2022-02-11",
                "dateLastUpdated": "2022-03-16",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Habin Habin",
                "createdById": "2348051587728",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "2321211122",
                    "accountName": "Great Businessing",
                    "bankName": "Zenith Bank International",
                    "bvn": "21232223112"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "2",
                "identificationNumber": "A1234543",
                "walletRef": "AG7282XGHE",
                "howYouHeardAboutUs": "TV/Radio",
                "accountNo": "2321211122",
                "accountName": "Great Businessing",
                "bankName": "Zenith Bank International",
                "bvn": "21232223112",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49514"
            },
            {
                "id": 49513,
                "businessName": "Test",
                "agentMobileNo": "2348051587722",
                "businessPhoneNo": "2348051587722",
                "agentCode": "AG722NMQLS",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "testerpos@qa.team",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2022-02-04",
                "dateApplicationValidated": "2022-02-11",
                "dateApplicationApproved": "2022-02-11",
                "dateLastUpdated": "2022-03-02",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Tester Pos",
                "createdById": "2348051587722",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "1012345678",
                    "accountName": "Test",
                    "bankName": "Guaranty Trust Bank Plc",
                    "bvn": "12343222222"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "1234567888",
                "walletRef": "AG722NMQLS",
                "howYouHeardAboutUs": "From a friend",
                "accountNo": "1012345678",
                "accountName": "Test",
                "bankName": "Guaranty Trust Bank Plc",
                "bvn": "12343222222",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49513"
            },
            {
                "id": 49512,
                "businessName": "Fashionista test",
                "agentMobileNo": "2348051587723",
                "businessPhoneNo": "2348051587723",
                "agentCode": "AG7234DHHZ",
                "businessTypeId": 0,
                "businessTypeName": "Business Center",
                "businessEmail": "testerpos1@qa.team",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2022-02-04",
                "dateApplicationValidated": "2022-02-11",
                "dateApplicationApproved": "2022-02-11",
                "dateLastUpdated": "2022-03-02",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Tester Posone",
                "createdById": "2348051587723",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "1298999909",
                    "accountName": "Fashionista test",
                    "bankName": "Guaranty Trust Bank Plc",
                    "bvn": "21222222222"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "2",
                "identificationNumber": "1222222",
                "walletRef": "AG7234DHHZ",
                "howYouHeardAboutUs": "Market Storm",
                "accountNo": "1298999909",
                "accountName": "Fashionista test",
                "bankName": "Guaranty Trust Bank Plc",
                "bvn": "21222222222",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49512"
            },
            {
                "id": 49511,
                "businessName": "Vladimir Corps",
                "companyRegNo": "77372663",
                "agentMobileNo": "2348164138488",
                "businessPhoneNo": "2348164138488",
                "agentCode": "AG4881JKJR",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "ruleurworld90@yahoo.com",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2022-02-09",
                "dateApplicationValidated": "2022-02-09",
                "dateApplicationApproved": "2022-02-09",
                "dateLastUpdated": "2022-02-09",
                "applicationValidatedBy": "James Doe",
                "applicationValidatedById": "james.doe222@gmail.com",
                "createdBy": "Obinna Onwuakagba",
                "createdById": "2348164138488",
                "lastUpdatedBy": "James Doe",
                "lastUpdatedById": "james.doe222@gmail.com",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "Active",
                "statusId": 2,
                "agentBankAccount": {
                    "accountNo": "3030585832",
                    "accountName": "Vladimir Corps",
                    "bankName": "First Bank of Nigeria Plc",
                    "bvn": "12345677888"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "83727626",
                "walletRef": "AG4881JKJR",
                "comment": "n",
                "howYouHeardAboutUs": "From a friend",
                "accountNo": "3030585832",
                "accountName": "Vladimir Corps",
                "bankName": "First Bank of Nigeria Plc",
                "bvn": "12345677888",
                "walletVersion": 2,
                "dateMigrated": "2022-02-11",
                "bvnVerificationStatus": "VERIFIED",
                "dataId": "49511"
            },
            {
                "id": 49509,
                "businessName": "Joshua Dami",
                "agentMobileNo": "2348051583300",
                "businessPhoneNo": "2348051583300",
                "agentCode": "AG300DUPKP",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "joshua@qa.team",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2021-11-08",
                "dateApplicationValidated": "2021-11-08",
                "dateApplicationApproved": "2021-11-08",
                "dateLastUpdated": "2022-01-26",
                "applicationValidatedBy": "James Doe",
                "applicationValidatedById": "james.doe222@gmail.com",
                "createdBy": "Joshua Damilola",
                "createdById": "2348051583300",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0089090987",
                    "accountName": "Joshua Dami",
                    "bankName": "Guaranty Trust Bank Plc",
                    "bvn": "12345678909"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "A12344444",
                "walletRef": "AG300DUPKP",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "0089090987",
                "accountName": "Joshua Dami",
                "bankName": "Guaranty Trust Bank Plc",
                "bvn": "12345678909",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49509"
            },
            {
                "id": 49504,
                "businessName": "marcus training",
                "companyRegNo": "22678900",
                "agentMobileNo": "2348168272117",
                "businessPhoneNo": "2348168272118",
                "agentCode": "AG118QSCB4",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "daminaph2003@yahoo.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2021-09-30",
                "dateApplicationValidated": "2021-09-30",
                "dateApplicationApproved": "2021-09-30",
                "dateLastUpdated": "2022-03-16",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Damina Philip",
                "createdById": "2348168272117",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0906678665",
                    "accountName": "marcus training",
                    "bankName": "ABSU MFB",
                    "bvn": "22457889876"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "33546712",
                "walletRef": "AG118QSCB4",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "0906678665",
                "accountName": "marcus training",
                "bankName": "ABSU MFB",
                "bvn": "22457889876",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49504"
            },
            {
                "id": 49503,
                "businessName": "demanus place",
                "companyRegNo": "22678902",
                "agentMobileNo": "2348168272116",
                "businessPhoneNo": "2348168272191",
                "agentCode": "AG191QCINH",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "ndubuiside2003@yahoo.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2021-09-30",
                "dateApplicationValidated": "2021-09-30",
                "dateApplicationApproved": "2021-09-30",
                "dateLastUpdated": "2022-03-16",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Ndubuisi Dele",
                "createdById": "2348168272116",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0081833456",
                    "accountName": "demanus place",
                    "bankName": "Abbey Mortgage Bank Plc.",
                    "bvn": "33890998342"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "3456677789",
                "walletRef": "AG191QCINH",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "0081833456",
                "accountName": "demanus place",
                "bankName": "Abbey Mortgage Bank Plc.",
                "bvn": "33890998342",
                "sanefAgentCode": "021AG191QCINH",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49503"
            },
            {
                "id": 49502,
                "businessName": "delejemini fashion",
                "companyRegNo": "22678900",
                "agentMobileNo": "2348036110980",
                "businessPhoneNo": "2348168171342",
                "agentCode": "AG342YO0ZM",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "chidris2009@yahoo.com",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2021-09-29",
                "dateApplicationValidated": "2021-09-29",
                "dateApplicationApproved": "2021-09-29",
                "dateLastUpdated": "2022-03-16",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Chima Idris",
                "createdById": "2348036110980",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0081856763",
                    "accountName": "delejemini fashion",
                    "bankName": "Access Bank Nigeria Plc",
                    "bvn": "33568709876"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "2",
                "identificationNumber": "3456677759",
                "walletRef": "AG342YO0ZM",
                "howYouHeardAboutUs": "TV/Radio",
                "accountNo": "0081856763",
                "accountName": "delejemini fashion",
                "bankName": "Access Bank Nigeria Plc",
                "bvn": "33568709876",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49502"
            },
            {
                "id": 49501,
                "businessName": "CharleyBusinessIFFF",
                "companyRegNo": "12345679",
                "agentMobileNo": "2348082512228",
                "businessPhoneNo": "2348082512228",
                "agentCode": "AG668XLW8U",
                "businessTypeId": 0,
                "businessTypeName": "Personal",
                "businessEmail": "hiBen@mail.com",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2021-09-21",
                "dateApplicationValidated": "2021-09-24",
                "dateApplicationApproved": "2021-09-24",
                "dateLastUpdated": "2022-03-16",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Chioma Benneth",
                "createdById": "2348082512228",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "6787878787",
                    "accountName": "CharleyBusinessIFFF",
                    "bankName": "BSIC Bank Gambia",
                    "bvn": "23456787643"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "1",
                "identificationNumber": "A09266253",
                "walletRef": "AG668XLW8U",
                "howYouHeardAboutUs": "Market Storm",
                "accountNo": "6787878787",
                "accountName": "CharleyBusinessIFFF",
                "bankName": "BSIC Bank Gambia",
                "bvn": "23456787643",
                "walletVersion": 1,
                "dataId": "49501"
            },
            {
                "id": 49500,
                "businessName": "CharleyBusinessVVV",
                "companyRegNo": "12345679",
                "agentMobileNo": "2348023456780",
                "businessPhoneNo": "2348076456653",
                "agentCode": "AG653FDUF2",
                "businessTypeId": 0,
                "businessTypeName": "Personal",
                "businessEmail": "jjo@mail.com",
                "agentClass": "CLASSIC",
                "agentType": "Agent",
                "agentClassId": 1,
                "agentTypeId": 4,
                "dateApplied": "2021-09-21",
                "dateApplicationValidated": "2021-09-22",
                "dateApplicationApproved": "2021-09-22",
                "dateLastUpdated": "2022-03-16",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Chioma Osuji",
                "createdById": "2348023456780",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "1234567898",
                    "accountName": "CharleyBusinessVVV",
                    "bankName": "ABSU MFB",
                    "bvn": "23434543332"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "2",
                "identificationNumber": "6767777777",
                "walletRef": "AG653FDUF2",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "1234567898",
                "accountName": "CharleyBusinessVVV",
                "bankName": "ABSU MFB",
                "bvn": "23434543332",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49500"
            },
            {
                "id": 49499,
                "businessName": "john rejoice ltd",
                "companyRegNo": "22678809",
                "agentMobileNo": "2348168121678",
                "businessPhoneNo": "2348168171345",
                "agentCode": "AG345VF3TX",
                "businessTypeId": 0,
                "businessTypeName": "Pharmacy",
                "businessEmail": "rejoice2006@yahoo.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2021-09-07",
                "dateApplicationValidated": "2021-09-07",
                "dateApplicationApproved": "2021-09-07",
                "dateLastUpdated": "2021-09-07",
                "applicationValidatedBy": "Super Admin",
                "applicationValidatedById": "superAdmin@mail.com",
                "createdBy": "Yinka Rejoice",
                "createdById": "2348168121678",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "0091989098",
                    "accountName": "john rejoice ltd",
                    "bankName": "ABSU MFB",
                    "bvn": "22567809654"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "3",
                "identificationNumber": "345667790",
                "walletRef": "AG345VF3TX",
                "howYouHeardAboutUs": "TV/Radio",
                "accountNo": "0091989098",
                "accountName": "john rejoice ltd",
                "bankName": "ABSU MFB",
                "bvn": "22567809654",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49499"
            },
            {
                "id": 49498,
                "businessName": "CharleyBusinessII",
                "companyRegNo": "12345679",
                "agentMobileNo": "2348100963714",
                "businessPhoneNo": "2348100963714",
                "agentCode": "AG714QJYKH",
                "businessTypeId": 0,
                "businessTypeName": "Personal",
                "businessEmail": "Makwu@mail.com",
                "agentClass": "STANDARD",
                "agentType": "Agent",
                "agentClassId": 2,
                "agentTypeId": 4,
                "dateApplied": "2021-08-26",
                "dateApplicationValidated": "2021-09-03",
                "dateApplicationApproved": "2021-09-03",
                "dateLastUpdated": "2021-09-03",
                "applicationValidatedBy": "James Doe",
                "applicationValidatedById": "james.doe222@gmail.com",
                "createdBy": "Ifeanyichukwu Madu",
                "createdById": "2348100963714",
                "parentId": "SAIFIS",
                "serviceProviderId": "SPIFIS",
                "status": "New",
                "statusId": 1,
                "agentBankAccount": {
                    "accountNo": "3081386669",
                    "accountName": "CharleyBusinessII",
                    "bankName": "First Bank of Nigeria Plc",
                    "bvn": "12345678982"
                },
                "walletStatus": "WALLET_CREATED",
                "identificationType": "4",
                "identificationNumber": "19781198933",
                "walletRef": "AG714QJYKH",
                "howYouHeardAboutUs": "Social Media",
                "accountNo": "3081386669",
                "accountName": "CharleyBusinessII",
                "bankName": "First Bank of Nigeria Plc",
                "bvn": "12345678982",
                "walletVersion": 1,
                "bvnVerificationStatus": "SUSPENDED",
                "dataId": "49498"
            }
        ]
    }
}


/*Agent code=AG522LO1FR  , mobile number=2348166380172 */
export const mockGetWalletLienJournal=()=>{
     return {     
                      response: {
                        "count": 4,
                        "content": [
                          {
                            "walletJournalId": 0,
                            "walletId": 0,
                            "creditAmount": 300000,
                            "debitAmount": 0,
                            "remainingBalance": 300000,
                            "balance": 300000,
                            "journalEntryDate": "2022-05-16 00:41:56.000",
                            "journalDescription": "testing the lien",
                            "narration": "testing the lien",
                            "uniqueReference": "1293000000000000715",
                            "paymentReference": "1293000000000000715",
                            "pageNumber": 0,
                            "rowsOfPage": 0
                          },
                          {
                            "walletJournalId": 0,
                            "walletId": 0,
                            "creditAmount": 0,
                            "debitAmount": 300000,
                            "remainingBalance": 0,
                            "balance": 0,
                            "journalEntryDate": "2022-05-16 00:44:59.000",
                            "journalDescription": "testing the lien",
                            "narration": "testing the lien",
                            "uniqueReference": "1293000000000000715-LIFT",
                            "paymentReference": "1293000000000000715-LIFT",
                            "pageNumber": 0,
                            "rowsOfPage": 0
                          },
                          {
                            "walletJournalId": 0,
                            "walletId": 0,
                            "creditAmount": 300000,
                            "debitAmount": 0,
                            "remainingBalance": 300000,
                            "balance": 300000,
                            "journalEntryDate": "2022-05-16 00:44:59.000",
                            "journalDescription": "testing the lien-1293000000000000715-LIFT-Rvs",
                            "narration": "testing the lien-1293000000000000715-LIFT-Rvs",
                            "uniqueReference": "1293000000000000715-LIFT-R",
                            "paymentReference": "1293000000000000715-LIFT-R",
                            "pageNumber": 0,
                            "rowsOfPage": 0
                          },
                          {
                            "walletJournalId": 0,
                            "walletId": 0,
                            "creditAmount": 100000,
                            "debitAmount": 0,
                            "remainingBalance": 400000,
                            "balance": 400000,
                            "journalEntryDate": "2022-05-19 10:03:37.000",
                            "journalDescription": "but a recharge card, value given but not paid",
                            "narration": "but a recharge card, value given but not paid",
                            "uniqueReference": "1293000000000000735",
                            "paymentReference": "1293000000000000735",
                            "pageNumber": 0,
                            "rowsOfPage": 0
                          }
                        ]
                      },
                      status:"SUCCESS",
                      count : 4
      }

}



export const getApprovals=()=>{
     return {
     "content":[
      {
        "id": "62a891b97a4b210006bb4fff",
        "applicationId": "finch-user-mgmt-service",
        "data": {
            "_id": 38,
            "requestId": "R225117766",
            "posRequestStockRequestId": "58a0cf5b-d351-4f61-844a-56c5eb346c3b",
            "initiatorUsername": "2348051587726",
            "agentUsername": "2348051587726",
            "terminalType": "Linux",
            "terminalModel": "model1050",
            "quantity": 1,
            "requestType": "free",
            "posUnitPrice": 0,
            "dateInitiated": "Tue Jun 14 13:48:40 UTC 2022",
            "approvalStatus": "awaiting_approval",
            "workflowStatus": "awaiting_approval",
            "requestDeviceChannel": "Web",
            "acceptTermsCondition": true,
            "signatoryUploadId": "6ce4d14d-924d-48fe-8ebb-c8ffe0901247",
            "agentBusinessAddress": "Hallway Road",
            "agentState": "Lagos",
            "posRequestStockId": 0,
            "isCancelled": false,
            "signatoryUploadPath": "/data/POS/posAgentSignatory/POS-AGENT-SIGNATORY_6ce4d14d-924d-48fe-8ebb-c8ffe0901247.png"
        },
        "dataRef": "38",
        "initiator": {
            "username": "2348051587726",
            "name": "Tester Persona",
            "email": "testerpos6@qa.team",
            "phone": "2348051587726",
            "domainId": 595
        },
        "history": [
            {
                "approver": "james.doe222@gmail.com",
                "date": "2022-06-14T13:56:00.135+00:00",
                "step": 1,
                "message": "good doc",
                "name": "James Doe"
            },
            {
                "approver": "superAdmin@mail.com",
                "date": "2022-06-14T13:56:55.617+00:00",
                "step": 2,
                "message": "nice doc",
                "name": "Super Admin"
            }
        ],
        "actionType": "EDIT",
        "status": "APPROVED_CLOSED",
        "runingWorkflow": {
            "id": "finch-user-mgmt-service-com-interswitchng-finch-identity-model-posworkflowmodel-edit",
            "name": "POS Request",
            "className": "com.interswitchng.finch.identity.model.PosWorkflowModel",
            "actionType": "EDIT",
            "active": true,
            "steps": [
                {
                    "id": 1,
                    "approvers": [
                        {
                            "username": "james.doe222@gmail.com",
                            "name": "James Doe",
                            "email": "james.doe222@gmail.com",
                            "phone": "2348032190873",
                            "domainId": 389
                        }
                    ]
                },
                {
                    "id": 2,
                    "approvers": [
                        {
                            "username": "superAdmin@mail.com",
                            "name": "Super Admin",
                            "email": "superAdmin@mail.com",
                            "phone": "2347099650032",
                            "domainId": 389
                        }
                    ]
                }
            ]
        },
        "created": "2022-06-14T13:48:41.945+00:00",
        "context": {
            "auditId": "758ac84c-49d5-443c-8997-58744c4f8833",
            "deviceUuid": "7d643c94-55ae-401b-ac3b-6c15350e997c",
            "__spring_security_scpf_applied": true,
            "businessName": "FASHIONISTASS",
            "channel": "Web",
            "ipAddress": "10.154.128.4",
            "roleName": "SUPER_ADMIN",
            "__spring_security_session_mgmt_filter_applied": true,
            "__spring_security_filterSecurityInterceptor_filterApplied": true,
            "businessType": "Agent",
            "finch_logger_traceid": "1536707195403153408",
            "username": "2348051587726"
        }
    }
     ],
      "pageable": {
          "sort": {
              "unsorted": false,
              "sorted": true,
              "empty": false
          },
          "offset": 0,
          "pageNumber": 0,
          "pageSize": 20,
          "paged": true,
          "unpaged": false
      },
      "last": false,
      "totalPages": 3,
      "totalElements": 43,
      "size": 20,
      "number": 0,
      "sort": {
          "unsorted": false,
          "sorted": true,
          "empty": false
      },
      "first": true,
      "numberOfElements": 20,
      "empty": false
  }
}

export const getApprovalById=()=>{
      return [
        {
            "id": "629f2f5c7a4b210006bb4ffc",
            "applicationId": "finch-user-mgmt-service",
            "data": {
                "_id": 35,
                "requestId": "R287071047",
                "posRequestStockRequestId": "8540696a-875d-47ea-9466-7135c0d5cf98",
                "initiatorUsername": "2348166380172",
                "agentUsername": "2348166380172",
                "terminalType": "Linux",
                "terminalModel": "model1050",
                "quantity": 1,
                "requestType": "free",
                "posUnitPrice": 0,
                "dateInitiated": "Tue Jun 07 10:58:35 UTC 2022",
                "approvalStatus": "awaiting_approval",
                "workflowStatus": "awaiting_approval",
                "requestDeviceChannel": "Web",
                "acceptTermsCondition": true,
                "signatoryUploadId": "aa80c5e1-8090-462c-8e58-20c16fbbefd5",
                "agentBusinessAddress": "Ikorodu no 5 Ikorodu",
                "agentState": "Lagos",
                "posRequestStockId": 0,
                "isCancelled": false,
                "signatoryUploadPath": "/data/POS/posAgentSignatory/POS-AGENT-SIGNATORY_aa80c5e1-8090-462c-8e58-20c16fbbefd5.pdf"
            },
            "dataRef": "35",
            "initiator": {
                "username": "2348166380172",
                "name": "Abdulkareem Basit",
                "email": "collins_mba2@yahoo.com",
                "phone": "2348166380172",
                "domainId": 396
            },
            "history": [],
            "actionType": "EDIT",
            "status": "INPROGRESS",
            "step": 1,
            "runingWorkflow": {
                "id": "finch-user-mgmt-service-com-interswitchng-finch-identity-model-posworkflowmodel-edit",
                "name": "POS Request",
                "className": "com.interswitchng.finch.identity.model.PosWorkflowModel",
                "actionType": "EDIT",
                "active": true,
                "steps": [
                    {
                        "id": 1,
                        "approvers": [
                            {
                                "username": "james.doe222@gmail.com",
                                "name": "James Doe",
                                "email": "james.doe222@gmail.com",
                                "phone": "2348032190873",
                                "domainId": 389
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "approvers": [
                            {
                                "username": "superAdmin@mail.com",
                                "name": "Super Admin",
                                "email": "superAdmin@mail.com",
                                "phone": "2347099650032",
                                "domainId": 389
                            }
                        ]
                    }
                ]
            },
            "created": "2022-06-07T10:58:36.912+00:00",
            "context": {
                "auditId": "85826a19-9e08-4cbd-87bb-4bc905ae6c80",
                "deviceUuid": "43fd1e3f-56a9-4c2b-b824-6875954dee0b",
                "__spring_security_scpf_applied": true,
                "businessName": "WALE AND SONS",
                "channel": "Web",
                "ipAddress": "10.154.130.205",
                "roleName": "SUPER_ADMIN",
                "__spring_security_session_mgmt_filter_applied": true,
                "__spring_security_filterSecurityInterceptor_filterApplied": true,
                "businessType": "Agent",
                "finch_logger_traceid": "1534127677068980224",
                "username": "2348166380172"
            }
        }
    ]
}


export const getAgentDetailsV2=()=>{
    return {
      "businessName": "Fashionistass",
      "agentMobileNo": "2348051587726",
      "agentCode": "AG7266VPET",
      "businessEmail": "testerpos6@qa.team",
      "agentClass": "STANDARD",
      "agentType": "Agent",
      "agentTypeId": 4,
      "parentId": "SAIFIS",
      "serviceProviderId": "SPIFIS",
      "status": "New",
      "statusId": 1,
      "walletStatus": "WALLET_CREATED",
      "contact": {
        "firstname": "Tester",
        "lastname": "Persona",
        "phoneNo": "2348051587726",
        "gender": "MALE"
      },
      "walletRef": "AG7266VPET",
      "walletVersion": 2
    }
}

export const mockPlaceLien=()=>{
  return {
    "status": true,
    "responsecode": "00",
    "remark": "Completed Successfully",
    "transRef": "dropurmensajegmailcom2345",
    "responseMessage": {
      "ledgerRecordID": "200",
      "accountNumber": "2348166380172",
      "currentBalance": "617000.00",
      "status": true
    }
  }
}