import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class posrequests {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getPosRequests(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request`,
      args: {
        pageNum,
        pageSize,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }

  getRequestsBySearch(
    pageNumber = null,
    pageSize = null,
    agentUsername = null,
    toDate = null,
    fromDate = null,
    approvalStatus = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-request/search`,
      args: {
        agentUsername,
        toDate,
        fromDate,
        pageNumber,
        pageSize,
        approvalStatus,
      },
    });
  }

  getPosRequestsByRequestid(requestId) {
    return this.apiRequester.get({
      endpoint: `pos-request/${requestId}`,
    });
  }
}
