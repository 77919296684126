import { convertToMajor } from '../../utils/converter';
import {
    formatCurrency
} from '../../utils/formatter';

export default class ServiceTypeExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Display Name', key: 'displayName', title: 'Display Name', dataIndex: 'displayName'},
            {label: 'Minimum Amount', key: 'minimumAmount', title: 'Minimum Amount', dataIndex: 'minimumAmount'},
            {label: 'Maximum Amount', key: 'maximumAmount', title: 'Maximum Amount', dataIndex: 'maximumAmount'},
            {label: 'Handler', key: 'handler', title: 'Handler', dataIndex: 'handler'},
            {label: 'Transaction Interval', key: 'transactionInterval', title: 'Transaction Interval', dataIndex: 'transactionInterval'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Created By', key: 'createdBy', title: 'Created By', dataIndex: 'createdBy'},
            {label: 'Last Updated By', key: 'lastUpdatedBy', title: 'Last Updated By', dataIndex: 'lastUpdatedBy'},
        ]
    }

    static reverseServiceTypeDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, displayName, minimumAmount, maximumAmount, handler, transactionInterval, status, createdBy, lastUpdatedBy } = details
            let detailFormData = {
                name,
                displayName,
                minimumAmount: minimumAmount ? `₦${formatCurrency(convertToMajor(minimumAmount))}` : `₦${formatCurrency(0)}`,
                maximumAmount: maximumAmount ? `₦${formatCurrency(convertToMajor(maximumAmount))}` : `₦${formatCurrency(0)}`,
                handler: handler === 0 ? 'QuickTeller' : handler === 1 ? 'Finch' : '',
                transactionInterval,
                status: status === 0 ? 'Active' : status === 1 ?  'Inactive' : '',
                createdBy: createdBy ? createdBy : '',
                lastUpdatedBy: lastUpdatedBy ? lastUpdatedBy : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}