import React from 'react'
import BaseScene from '../../../../../../../../components/scenes/component';
import { GridLayout } from '../../../../../../../../components/layouts/grid-layout';
import DomainTypes from '../../../../../../../../fixtures/domain-types.json'
import Permissions from '../../../../../../../../fixtures/permissions.json'
import { SUCCESS_STATUS, ERROR_STATUS, ACCEPTED_REQUEST } from '../../../../../../../../constants/api';
import qs from 'querystring';
import { platformManagement } from '../../../../../../../../mixins/api'
import Loader from '../../../../../../../../components/loader'
import Notification from '../../../../../../../../components/notification'
import RoleForm from '../../../../../../../../components/forms/role-form'
import ProtectedComponent from '../../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../../components/access-denied';
import { 
    CAN_VIEW_AGENT_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_SP_ROLE,
    CAN_UPDATE_AGENT_ROLE, CAN_UPDATE_AGGREGATOR_ROLE, CAN_UPDATE_SP_ROLE
} from '../../../../../../../../constants/permissions';

export default class DomainRoleViewScene extends BaseScene {
  
  constructor (props) {
    super(props)

    this.state = {
      canSave: false,
      canUpdate: true,
      name: null,
      description: null,
      domainId: null,
      permissions: [],
      selected: [],
      paddingBottom: '0px',

      domains: [],
      allPermissions: Permissions,
      domainTypes: DomainTypes,
      data: Permissions,
      rolePermissions: [],
      pageTitle: 'View a Role',
      disabled: true,
      changedPermission: true,
    }
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
    this.updateRoleFormData = this.updateRoleFormData.bind(this)
  }

  componentDidMount() {
    this.roleId = qs.parse(window.location.search)['?id']
    this.domainCode = this.props.match.params.domainCode
    this.updateRoleFormData('domainCode', this.domainCode)
    this.getRoles()
    //this.getPermissions()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getRoles () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getRoles(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      response.map(details => {
        if(this.roleId == details.id){
          this.getRolePermissions(details.name)
        }else{
          this.setState({
            roleFormData: [],
            isLoading: false
          })
        }
      })
    }else{
      this.setState({
        isLoading: false
      })
    }
  }

  async getRolePermissions (roleName) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getRolePermissions(this.domainCode, roleName)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.getPermissions(roleName, response)
      // this.setState({
      //   roleFormData: {...this.state.roleFormData, 
      //     permissions: this.getPermissionId(response),
      //     defaultSelectedPermissions: this.getPermissionId(response),
      //     name: roleName
      //   }
      // })
    }else{
      this.setState({
        isLoading: false
      })
    }
  }

  async getPermissions (roleName, rolePermissions) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getPermissions(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        roleFormData: {...this.state.roleFormData, 
          permissions: this.getPermissionId(rolePermissions),
          defaultSelectedPermissions: this.getPermissionId(rolePermissions),
          name: roleName
        },
        permissions: response,
        isLoading: false
      })
    }else{
      this.setState({
        permissions: [],
        isLoading: false
      })
    }
  }

  getPermissionId (permissions) {
    let permissionArray = [];
    permissions.map(details => {
      permissionArray.push(details.id)
    })

    return permissionArray;
  }

  toJSON(array) {
    let newArray = [];
    array.map(value => {
      let myArray = {};
      myArray["id"] = value;
      newArray.push(myArray)
    })

    return newArray;
  }

  async onUpdateButtonClick () {
    this.setState({
      canUpdate: false,
      canSave: true,
      pageTitle: 'Update a Role',
      disabled: false,
    })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})
    const { permissions, defaultSelectedPermissions } = this.state.roleFormData
    
    const addedPermissions = permissions.filter(function(v) {
      return !defaultSelectedPermissions.includes(v)
    })
  
    const removedPermissions = defaultSelectedPermissions.filter(function(v) {
      return !permissions.includes(v)
    })
  
    if(addedPermissions && addedPermissions.length > 0) {
      const createRoleResponseObj = await platformManagement.createRole({ 
        name: this.state.roleFormData.name,
        domainCode: this.domainCode,
        permissions: this.toJSON(addedPermissions)
      })

      const createRoleResponseStatus = createRoleResponseObj.status
      const createRoleResponse = createRoleResponseObj.response

      if (createRoleResponseStatus === SUCCESS_STATUS) {
        if(createRoleResponse.code == ACCEPTED_REQUEST) {
          this.setState({
            isLoading: false,
            successMessage: "Your changes to this role has been sent successfully for approval.",
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }else{
          this.setState({
            isLoading: false,
            successMessage: 'Role updated successfully',
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }
      }else{
        this.setState({
          errorMessage: createRoleResponse,
          isLoading: false
        })
      }
    }
    
    if(removedPermissions && removedPermissions.length > 0) {
      const removeRoleResponseObj = await platformManagement.removeRolePermission({ 
        name: this.state.roleFormData.name,
        domainCode: this.domainCode,
        permissions: this.toJSON(removedPermissions)
      })

      const removeRoleResponseStatus = removeRoleResponseObj.status
      const removeRoleResponse = removeRoleResponseObj.response
      if (removeRoleResponseStatus === SUCCESS_STATUS) {
        if(removeRoleResponse.code == ACCEPTED_REQUEST) {
          this.setState({
            isLoading: false,
            successMessage: "Your changes to this role has been sent successfully for approval.",
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }else{
          this.setState({
            isLoading: false,
            successMessage: 'Role updated successfully',
            canSave: false,
            canUpdate: true,
            pageTitle: 'View a Role',
            disabled: true,
          })
        }
      }else{
        this.setState({
          errorMessage: removeRoleResponse,
          isLoading: false
        })
      }
    }
  }

  async onCancelButtonClick () {
    this.setState({
      canUpdate: true,
      canSave: false,
      pageTitle: 'View Role Details',
      disabled: true,
    })
  }
  
  updateRoleFormData (type, value) {
    this.setState({roleFormData: {...this.state.roleFormData, [type]: value}})
  }

  isFormValidForUpdate () {
    const { permissions, defaultSelectedPermissions } = this.state.roleFormData
    if( permissions && defaultSelectedPermissions) {
      const addedPermissions = permissions.filter(function(v) {
        return !defaultSelectedPermissions.includes(v)
      })
  
      const removedPermissions = defaultSelectedPermissions.filter(function(v) {
        return !permissions.includes(v)
      })
  
      return Boolean(!addedPermissions || !removedPermissions)
    }
    return true
  }

  render () {
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_ROLE, CAN_UPDATE_AGGREGATOR_ROLE, CAN_UPDATE_SP_ROLE]}><button type="button" class="btn btn-primary btn-sm" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit Permissions</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_ROLE, CAN_UPDATE_AGGREGATOR_ROLE, CAN_UPDATE_SP_ROLE]}><button type="button" class="btn btn-secondary btn-sm" style={{marginRight: '10px'}} onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_ROLE, CAN_UPDATE_AGGREGATOR_ROLE, CAN_UPDATE_SP_ROLE]}><button type="button" class="btn btn-primary btn-sm" disabled={this.isFormValidForUpdate()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_SP_ROLE]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <RoleForm
            onChange={this.updateRoleFormData}
            roleFormData={this.state.roleFormData}
            allPermissions={this.state.permissions}
            disabled={this.state.disabled}
            disabled2={true}
            title={'Role Management'}
            submitButton={ctaWidgets}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
}
