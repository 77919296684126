import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ViewAggregatorScene from './scenes/view-aggregator';
import AggregatorsGridScene from './scenes/aggregators-grid';
import BaseScene from '../../../../../components/scenes/component';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_AGGREGATOR, CAN_MANAGE_AGGREGATOR_DOMAIN, CAN_VIEW_TRANSACTION } from '../../../../../constants/permissions.js'
import DomainManagementScene from '../../domain-management';
import WalletJournalScene from '../../reports/wallet-journal'
import AgentScene from '../agents'
import CommissionHistoryScene from '../../reports/commission-history'

export default class AggregatorsScene extends BaseScene {
  sceneName = 'aggregator-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGGREGATOR]} exact path='/home/aggregator-management' component={AggregatorsGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGGREGATOR]} exact path='/home/aggregator-management/:id/view' component={ViewAggregatorScene} />
      <ProtectedRoute requiredPermissions={[CAN_MANAGE_AGGREGATOR_DOMAIN]} path="/home/domain-management/:domainCode" component={DomainManagementScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGGREGATOR]} exact path='/home/agent-management' component={AgentScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} path="/home/account-statement/:domainCode" component={WalletJournalScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} path="/home/commission-history/:domainCode" component={CommissionHistoryScene} />
    </Router>
  }
}
