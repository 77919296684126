import ErrorTypes from "../fixtures/error_types";

export class ApiErrorHandler {
  _getErrorTypeResponse(parsedErrorResponse) {
    return (
      ErrorTypes.find(
        (value) =>
          value.message_from_api === parsedErrorResponse.message ||
          value.code_from_api === parsedErrorResponse.code
      ) || parsedErrorResponse
    );
  }

  _parsedApiErrorResponse(apiErrorResponse) {
    const apiErrorObject = {
      code: null,
      message: null,
      message_to_user: null,
    };

    if (!apiErrorResponse) {
      return apiErrorObject;
    }

    apiErrorObject.code = apiErrorResponse.code
      ? apiErrorResponse.code
      : apiErrorResponse.status;

    apiErrorObject.message =
      apiErrorResponse.description ||
      apiErrorResponse.errorMessage ||
      apiErrorResponse.message;
    if (apiErrorResponse.errors && apiErrorResponse.errors.length > 0) {
      apiErrorObject.message =
        apiErrorResponse.errors[0].errorMessage ||
        apiErrorResponse.errors[0].message ||
        apiErrorResponse.errors !== ""
          ? this.handleApiErrorObjectResponse(apiErrorResponse)
          : apiErrorObject.message;
    } else if (
      apiErrorResponse.responseData &&
      apiErrorResponse.responseData.description
    ) {
      apiErrorObject.message = apiErrorResponse.responseData.description;
    }

    apiErrorObject.message_to_user = apiErrorObject.message;

    return apiErrorObject;
  }

    handleApiErrorResponse(apiErrorResponse) {
        const parsedApiErrorResponse = this._parsedApiErrorResponse(apiErrorResponse);
        let errorMessage = this._getErrorTypeResponse(parsedApiErrorResponse).message_to_user;
        if(apiErrorResponse.length > 10 && apiErrorResponse.length < 70){
            errorMessage= apiErrorResponse;
        }
        else if(apiErrorResponse.description && apiErrorResponse.description.length > 10 && apiErrorResponse.description.length < 70){
          errorMessage= apiErrorResponse.description;
        }
        else{
            errorMessage=process.env.REACT_APP_DEFAULT_ERROR_MESSAGE;
        }

    return errorMessage;
  }

  handleApiErrorObjectResponse(apiErrorResponse) {
    let errorString = "";
    const apiErrorObject = apiErrorResponse.errors;
    if (typeof apiErrorObject === "object") {
      apiErrorObject.forEach((element, index, array) => {
        //errorString.push(element.fieldName)
        errorString += element.fieldName;
      });
      return errorString !== "undefined"
        ? errorString
        : apiErrorResponse.description;
    } else {
      return (
        apiErrorResponse.description ||
        apiErrorResponse.errorMessage ||
        apiErrorResponse.message
      );
    }
  }
}
