import { convertToMajor } from '../../utils/converter';
import {
    formatCurrency, formatLongNumber
} from '../../utils/formatter';

export default class AgentBalanceReportExportSerializer {
    static headerExport () {
        return [
            { label: 'Business Name', key: 'walletAccountName', title: 'Business Name', dataIndex: 'walletAccountName' },
            { label: 'Business Phone', key: 'walletAccountNumber', title: 'Business Phone', dataIndex: 'walletAccountNumber' },
            { label: 'Agent ID', key: 'agentID', title: 'Agent ID', dataIndex: 'agentID' },
            { label: 'Available Balance', key: 'transAvailableBalance', title: 'Available Balance', dataIndex: 'transAvailableBalance' },
            { label: 'Commission Balance', key: 'commissionBalance', title: 'Commission Balance', dataIndex: 'commissionBalance' },
            { label: 'Lien Balance', key: 'lienBalance', title: 'Lien Balance', dataIndex: 'lienBalance' }
        ];
    }

    static reverseAgentBalanceReportDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
             walletAccountName, walletAccountNumber, transAvailableBalance,
             commissionBalance, lienBalance, agentID
            } = detail

            let detailFormData = {
                agentID: agentID ? formatLongNumber(agentID) : '',
                walletAccountNumber: walletAccountNumber ? formatLongNumber(walletAccountNumber) : '',
                transAvailableBalance: transAvailableBalance ? `₦${formatCurrency(convertToMajor(transAvailableBalance))}` : `₦${formatCurrency(0)}`,
                commissionBalance: commissionBalance ? `₦${formatCurrency(convertToMajor(commissionBalance))}` : `₦${formatCurrency(0)}`,
                lienBalance: lienBalance ? `₦${formatCurrency(convertToMajor(lienBalance))}` : `₦${formatCurrency(0)}`,
                walletAccountName
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}