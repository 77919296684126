import qs from 'querystring';
import React from 'react';
import { AccessDenied } from '../../../../../../../../components/access-denied';
import UserForm from '../../../../../../../../components/forms/user-form';
import { GridLayout } from '../../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../../components/loader';
import Notification from '../../../../../../../../components/notification';
import BaseScene from '../../../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../../constants/api';
import { CAN_UPDATE_AGENT_USER, CAN_UPDATE_AGGREGATOR_USER, CAN_UPDATE_SP_USER, CAN_UPDATE_USERS, CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_USER } from '../../../../../../../../constants/permissions';
import { platformManagement } from '../../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../../mixins/protected-component';

export default class DomainUserViewScene extends BaseScene {
  requiredPermissions = [
    CAN_UPDATE_USERS
  ]

  constructor (props) {
    super(props)

    this.state = {
      canSave: false,
      canUpdate: true,
      emailIsValid: true,
      phoneIsValid: true,
      roles: [],
      userDetails: [],
      pageTitle: 'View a User',
      disabled: true,
      userFormData: [],
      showPhoneNumber: false,
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
    this.updateUserFormData = this.updateUserFormData.bind(this)

  } 

  componentDidMount() {
    this.username = qs.parse(window.location.search)['?username']
    this.domainCode = this.props.match.params.domainCode
    this.getUserDetails()
    this.getDomainRoles()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getUserDetails () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getUserByEmail(this.username, this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        userDetails: response,
        userFormData: response,
        showPhoneNumber: true,
        isLoading: false
      })
    }else{
      this.setState({
        userDetails: [],
        userFormData: [],
        errorMessage: response,
        showPhoneNumber: true,
        isLoading: false
      })
    }
  }

  async getDomainRoles () {
    const responseObj = await platformManagement.getRoles(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        roles: response
      })
    }else{
      this.setState({
        roles: []
      })
    }
  }

  async onUpdateButtonClick () {
    this.setState({
      pageTitle: 'Update a User',
      canSave: true,
      canUpdate: false,
      disabled: false,
    })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})

    const createUserResponseObj = await platformManagement.updateUserRole(this.state.userFormData)
    const createUserResponseStatus = createUserResponseObj.status
    const createUserResponse = createUserResponseObj.response
    
    if (createUserResponseStatus === SUCCESS_STATUS) {
      if(createUserResponse.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: 'Your changes to this user has been sent successfully for approval.',
          canSave: false,
          canUpdate: true,
          pageTitle: 'View a User',
          disabled: true,
        })
      }else{
        this.setState({
          isLoading: false,
          successMessage: 'User role updated successfully.',
          canSave: false,
          canUpdate: true,
          pageTitle: 'View a User',
          disabled: true,
        })
      }
    }else{
      this.setState({
        isLoading: false,
        errorMessage: createUserResponse
      })
    }
  }

  isFormValid () {
    return this.state.userDetails.roleName !== this.state.userFormData.roleName;
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  updateUserFormData (type, value) {
    this.setState({userFormData: {...this.state.userFormData, [type]: value}})
  }

  render () {
    
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_USER, CAN_UPDATE_AGGREGATOR_USER, CAN_UPDATE_SP_USER]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit Role</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_USER, CAN_UPDATE_AGGREGATOR_USER, CAN_UPDATE_SP_USER]}><button type="button" class="btn btn-primary" disabled={!this.isFormValid()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_USER]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          {this.state.userDetails.username && <UserForm
            updateSetState={this.updateSetState}
            onChange={this.updateUserFormData}
            disabled={this.state.disabled}
            disabled2={true}
            userDetails={this.state.userDetails}
            userFormData={this.state.userFormData}
            roles={this.state.roles}
            emailIsValid={this.state.emailIsValid}
            title={'User Management'}
            submitButton={ctaWidgets}
            showPhoneNumber={this.state.showPhoneNumber}
          />}
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
}
