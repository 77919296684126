import React from "react";
import { Link } from "react-router-dom";
import ProtectedComponent from "../../../../../../mixins/protected-component";

const ContentHeadLien = (props) => {
  
  const cancelButtonComponent = () => {
    return (
      <Link
        to={props.cancelActionButtonUrl}
        class="btn btn-label-brand btn-bold"
        style={{ backgroundColor: "white", color: "#5f0042" }}
        data-toggle={props.showTooltip ? "kt-tooltip" : ""}
        data-skin="default"
        title={props.tooltipTittle}
      >
        <i class="la la-close" />
        Cancel Lien
      </Link>
    );
  }
  
  const debitButtonComponent = () => {
    return (
      <Link
        to={props.debitActionButtonUrl}
        class="btn btn-label-brand btn-bold"
        style={{ backgroundColor: "#007f42", color: "white" }}
        data-skin="default"
        title={props.tooltipTittle}
      >
        Debit Lien
      </Link>
    );
  }
  
  const addButtonComponent = () => {
    return (
      <Link
        to={props.addActionButtonUrl}
        class="btn btn-label-brand btn-bold"
        style={{ backgroundColor: "#00425f", color: "white" }}
        data-toggle={props.showTooltip ? "kt-tooltip" : ""}
        data-skin="default"
        title={props.tooltipTittle}
      >
        <i class="la la-plus" />
        Add Lien
      </Link>
    );
  }



    return (
      <div class="kt-subheader kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
          <h3 class="kt-subheader__title">{props.title}</h3>

          {props.subtitle && (
            <React.Fragment>
              <span class="kt-subheader__separator kt-subheader__separator--v"></span>
              <div class="kt-subheader__subtitle">{props.subtitle}</div>
            </React.Fragment>
          )}


        </div>

        <div class="kt-subheader__toolbar kt-section__content kt-section__content--border">
            <ProtectedComponent requiredPermissions={props.actionButtonRequiredPermissions}>
              {cancelButtonComponent()}
            </ProtectedComponent>
            
            <ProtectedComponent requiredPermissions={props.actionButtonRequiredPermissions}>
              {debitButtonComponent()}
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={props.actionButtonRequiredPermissions}>
              {addButtonComponent()}
            </ProtectedComponent>
          
        </div>
      </div>
    );
}

export default ContentHeadLien;