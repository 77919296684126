import React from 'react'

export default class ApprovalInitiator extends React.Component {
    render() {
        return <React.Fragment>
            <h5>Initiator</h5>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-2">Name:</label>
                <div class="col-lg-10">
                    <span>{this.props.initiator.name}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-2">Email:</label>
                <div class="col-lg-10">
                    <span>{this.props.initiator.email}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-2">Phone:</label>
                <div class="col-lg-10">
                    <span>{this.props.initiator.phone}</span>
                </div>
            </div>
        </React.Fragment>
    }
}