import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_ACTIVATE_SERVICE, CAN_DEACTIVATE_SERVICE, CAN_VIEW_SERVICE } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import ServiceSearch from '../components/service-search';
import ServiceTable from '../components/service-table';

export default class ServiceGridScene extends BaseScene {
  
  getServices = this.getServices.bind(this)
  handleSearchRequest = this.handleSearchRequest.bind(this);
  
  state = {
    services: [],
    isLoading: true,
    searchParams: {}
  }

  componentDidMount() {
    //this.getServices()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getServices (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getServices(pageNum, pageSize, this.state.searchParams)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        services: response ? response : [],
        dataCount: count,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      })
    }else{
      this.setState({
        services: [],
        dataCount: 0,
        isLoading: false,
        page: pageNum,
        pageSize: pageSize,
        errorMessage: response
      })
    }
  }

  handleSearchRequest (searchParams) {
    this.setState({
      searchParams,
      startPage: 1
    })
    setTimeout(() => {
      this.getServices(1, 20)
    }, 500)
  }

  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Services"} filterPermissions={[CAN_VIEW_SERVICE]} totalCount={56} /> 
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_SERVICE]}>
        <GridLayout>
          <ServiceSearch 
            handleSearchRequest={this.handleSearchRequest}
            searchParams={this.state.searchParams}
          />
          <ServiceTable 
            getServices={this.getServices}
            pages={this.state.pages}
            data={this.state.services}
            updateStatus={this.getClickedAction}
            loading={this.state.isLoading} 
            noDataText={`No services found`}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canView={CAN_VIEW_SERVICE}
            canActivate={CAN_ACTIVATE_SERVICE}
            canDeactivate={CAN_DEACTIVATE_SERVICE}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
  
}
