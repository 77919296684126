import React from "react";
import { Modal } from "react-bootstrap";
import { AccessDenied } from "../../../../components/access-denied";
import ContentHead from "../../../../components/content-head";
import { GridLayout } from "../../../../components/layouts/grid-layout";
import Loader from "../../../../components/loader";
import Notification from "../../../../components/notification";
import BaseScene from "../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../constants/api";
import {
  CAN_VIEW_PERMISSION_GROUP,
  CAN_VIEW_TRANSACTION
} from "../../../../constants/permissions.js";
import { userManagement } from "../../../../mixins/api";
import ProtectedComponent from "../../../../mixins/protected-component";
import { convertToMajor, convertToMinor } from "../../../../utils/converter";
import { formatCurrency } from "../../../../utils/formatter";
import BuyPowerBalanceAddModal from "./components/buy-power-balance-add-modal";
import BuyPowerBalanceEditModal from "./components/buy-power-balance-edit-modal";
import BuyPowerBalanceTable from "./components/buy-power-balance-table";


import {
  feeConfigurationManagement, transactionService
} from "../../../../mixins/api";

export default class BuypowerWalletScene extends BaseScene {
  sceneName = "buy-power-wallet";

  constructor(props) {
    super(props);

    this.state = {
      walletbalance: 0,
      thresholdStates: [],
      thresholdsDetails: [],
      isLoading: false,
      searchParameters: {
        thresholdAmount: "",
        thresholdStatus: "",
        thresholdId: "",
      },
      pages: 1,
      pageNum: 1,
      pageSize: 20,

      tableMessage: "",
      successMessage: "",
      errorMessage: "",
      showConfirm: false,
      thresholdDeleteId: "",
    };

    this.getBuyPowerWalletBalance = this.getBuyPowerWalletBalance.bind(this);
    this.getBuyPowerBalanceThreshold = this.getBuyPowerBalanceThreshold.bind(
      this
    );
    this.handleBuyPowerEditThreshold = this.handleBuyPowerEditThreshold.bind(
      this
    );
    this.handleBuyPowerAddThreshold = this.handleBuyPowerAddThreshold.bind(
      this
    );
    this.handleBuyPowerDeleteThreshold = this.handleBuyPowerDeleteThreshold.bind(
      this
    );
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.getAllPermissionGroups = this.getAllPermissionGroups.bind(this);
    this.handleNoConfirmationModal = this.handleNoConfirmationModal.bind(this);
  }

  componentDidMount() {
    this.getBuyPowerWalletBalance();
    this.getBuyPowerBalanceThreshold();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getBuyPowerWalletBalance() {
    this.setState({ canLoadData: true });

    this.setState({
      isLoading: true,
    });
    const {
      status,
      response,
      count,
    } = await transactionService.getBuyPowerBalance();
    if (status === SUCCESS_STATUS) {
      this.setState({
        walletbalance: response ? response.balance : 0,
        isLoading: false,
      });
    } else {
      this.setState({
        walletbalance: 0,
        isLoading: false,
      });
    }
    // deleteOtherInformation("parentReference");
  }

  async getBuyPowerBalanceThreshold() {
    this.setState({ canLoadData: true });

    this.setState({
      isLoading: true,
    });

    const {
      status,
      response,
      
    } = await feeConfigurationManagement.getBuyPowerBalanceThreshold();
    if (status === SUCCESS_STATUS) {
      this.setState({
        thresholdStates: response ? response : [],
        isLoading: false,
      });
    } else {
      this.setState({
        thresholdStates: [],
        isLoading: false,
        tableMessage: "Unable to fetch threshold states",
      });
    }
    // deleteOtherInformation("parentReference");
  }

  async handleBuyPowerEditThreshold() {
    this.setState({ canLoadData: true });

    this.setState({
      isLoading: true,
    });
    const {
      thresholdAmount,
      thresholdStatus,
      thresholdId,
    } = this.state.searchParameters;
    const {
      status,
      response,
    
    } = await feeConfigurationManagement.handleBuyPowerBalanceEditThreshold(
      convertToMinor(thresholdAmount),
      thresholdStatus,
      thresholdId
    );
    if (status === SUCCESS_STATUS) {
      // alert("SUCESSFUL")
      this.setState({
        successMessage: "Threshold sucessfully updated.",
        isLoading: false,
      });
      this.getBuyPowerBalanceThreshold();
    } else {
      // alert("ERROR")
      this.setState({
        isLoading: false,
        errorMessage: response.description,
      });
    }
    // deleteOtherInformation("parentReference");
  }

  async handleBuyPowerAddThreshold() {
    this.setState({ canLoadData: true });

    this.setState({
      isLoading: true,
    });
    const { thresholdAmount, thresholdStatus } = this.state.searchParameters;
    const {
      status,
      response,
      
    } = await feeConfigurationManagement.handleBuyPowerBalanceAddThreshold(
      convertToMinor(thresholdAmount),
      thresholdStatus
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: "Threshold sucessfully created.",
        isLoading: false,
      });
      this.getBuyPowerBalanceThreshold();
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response.description,
      });
    }
    // deleteOtherInformation("parentReference");
  }
  async handleBuyPowerDeleteThreshold() {
    this.setState({ canLoadData: true });

    this.setState({
      isLoading: true,
    });

    const {
      status,
      response,
      
    } = await feeConfigurationManagement.handleBuyPowerBalanceDeleteThreshold(
      this.state.thresholdsDetails.id
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        showConfirm: false,
        successMessage: "Threshold sucessfully deleted.",
        isLoading: false,
      });
      this.getBuyPowerBalanceThreshold();
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response.description,
      });
    }
    // deleteOtherInformation("parentReference");
  }

  updateSearchParameters(type, value) {
    if (value == 0 || value == "All" || !value) {
      this.setState({
        searchParameters: { ...this.state.searchParameters, [type]: "" },
      });
      // console.log("Here")
      return;
    }

    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType == "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  handleDuplicateTransactions(transactions, transRef) {
    if (transactions.indexOf(transRef) == -1) {
      return false;
    } else {
      return true;
    }
  }

  async getAllPermissionGroups() {
    this.setState({ isLoading: true });
    const responseObj = await userManagement.getAllPermissionGroups();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissionGroups: response,
        isLoading: false,
        dataCount: response.length,
      });
    } else {
      this.setState({
        isLoading: false,
        dataCount: 0,
        permissionGroups: [],
        errorMessage: response,
      });
    }
  }

  getClickedAction(thresholds, actionType) {
    if (actionType == "edit") {
      this.setState({
        thresholdsDetails: thresholds,
      });
    }
    if (actionType == "delete") {
      this.setState({
        showConfirm: true,
        thresholdsDetails: thresholds,
        // thresholdDeleteId: thresholds.id,
      });
    }
    this.setState({
      actionType,
    });
  }

  handleNoConfirmationModal() {
    this.setState({
      showConfirm: false,
    });
    // this.getAllPosRequests();
  }

  render() {
    const confirmationModal = (
      <Modal
        animation="true"
        style={{
          borderRadius: "10px",
          boxShadow: "0 0 10px #33333340",
          transition: "all .2s ease-in-out",
        }}
        size="sm"
        show={this.state.showConfirm}
        centered
      >
        <Modal.Body
          style={{
            margin: "10px",
            height: "150px",
            padding: "15px",
            overflow: "hidden",
          }}
        >
          <h4
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0px",
              padding: "0px",
            }}
          >
            Delete Threshold?
          </h4>

          <center>
            <p
              style={{
                fontSize: "15px",
                lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                margin: "10px",
              }}
            >
              Are you sure you want to delete this threshold?
            </p>
            <br />
            <br />
          </center>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-primary col-lg-6"
            style={{ backgroundColor: "#004257" }}
            onClick={() => {
              this.handleBuyPowerDeleteThreshold();
            }}
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn-primary col-lg-6"
            style={{
              border: "1px solid #00425f",
              backgroundColor: "#ffffff",
              color: "#004257",
            }}
            onClick={this.handleNoConfirmationModal}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    );
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Buy Power Wallet"}
          subtitle={"Wallet Balance"}
          filterPermissions={[CAN_VIEW_PERMISSION_GROUP]}
          totalCount={
            56
          } /*actionButtonText={"Add Permission"} actionButtonUrl={"/home/permission-management/add"} actionButtonRequiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}*/
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <div
              style={{
                margin: "30px auto",
                display: "grid",
                placeItems: "center",
                fontSize: "3.5rem",
                fontWeight: "700",
                color: "white",
                borderRadius: "10px",
                padding: "25px 30px",
                background: "rgb(0 66 95)",
                // width: "30%",
                maxWidth: "35%",
                boxShadow: "0 0 10px #333333d9",
              }}
            >
              <span
                style={{
                  fontSize: "1.7rem",
                  fontWeight: "300",
                  paddingBottom: "7px",
                  borderBottom: "1px solid white",
                }}
              >
                Balance
              </span>
              &#x20A6;{formatCurrency(convertToMajor(this.state.walletbalance))}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <div style={{ fontSize: "1.7rem", display: "flex", alignItems: "center", marginRight: "20px"}}>Add <span style={{ fontSize: "1.7rem", padding: "5px", fontWeight: "600" }}>+</span></div> */}
              <button
                style={{
                  fontSize: "1.3rem",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                  padding: "0 20px",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
                type="submit"
                class="search-button"
                data-toggle="modal"
                data-target="#buy_power_balance_modal"
                disabled={this.state.thresholdStates.length === 3}
              >
                Add{" "}
                <span
                  style={{
                    fontSize: "1.3rem",
                    padding: "5px",
                    fontWeight: "600",
                  }}
                >
                  +
                </span>
              </button>
            </div>
            {this.state.showConfirm && confirmationModal}
            <BuyPowerBalanceTable
              pages={this.state.pages}
              getBuyPowerBalanceThreshold={this.getBuyPowerBalanceThreshold}
              thresholdStates={this.state.thresholdStates}
              data={this.state.thresholdStates}
              startPage={this.state.startPage}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              page={this.state.pageNum}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_TRANSACTION}
              getClickedAction={this.getClickedAction}
              desiredStatus={
                this.state.desiredStatus ? this.state.desiredStatus : "default"
              }
              canLoadData={this.state.canLoadData}
            />
            <BuyPowerBalanceAddModal
              searchParameters={this.state.searchParameters}
              updateSearchParameters={this.updateSearchParameters}
              handleBuyPowerAddThreshold={this.handleBuyPowerAddThreshold}
            />
            <BuyPowerBalanceEditModal
              searchParameters={this.state.searchParameters}
              updateSearchParameters={this.updateSearchParameters}
              handleBuyPowerEditThreshold={this.handleBuyPowerEditThreshold}
              thresholdsDetails={this.state.thresholdsDetails}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
