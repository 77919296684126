import qs from "querystring";
import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import ContentHead from "../../../../../../../components/content-head.js";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout.js";
import Notification from "../../../../../../../components/notification.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS
} from "../../../../../../../constants/api.js";
import { AGENT_TYPE_ID } from "../../../../../../../constants/domain-types.js";
import {
  CAN_ACTIVATE_AGENT,
  CAN_DEACTIVATE_AGENT,
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_RETIRE_AGENT,
  CAN_VIEW_AGENT,
  CAN_VIEW_TRANSACTION,
  CAN_VIEW_STATIC_ACCOUNT
} from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import {
  retrieveOtherInformation,
  saveOtherInformation
} from "../../../../../../../mixins/authentication.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import StaticAccountTable from "../../components/static-account-table.js";

export default class StaticAccountGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      staticAccountProvider: [],
      agentSearchParameters: {
        agentType: AGENT_TYPE_ID,
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      agentId: null,
      agentBusinessName: null,
      agentStatus: null,
      isLoading: false,
      errorMessage: null,
      successMessage: null,
    };
    this.updateAgentSearchParameters = this.updateAgentSearchParameters.bind(this);
    this.searchStaticAccountProvider = this.searchStaticAccountProvider.bind(this);
    this.handleDataUpdate = this.handleDataUpdate.bind(this);
  }

  sceneName = "kyc-update";

  getClickedAction(agentId, businessName, status) {
    this.setState({
      agentId: agentId,
      agentStatus: status,
      agentBusinessName: businessName,
    });
  }

  updateAgentSearchParameters(type, value) {
    this.setState((prevState) => ({
      agentSearchParameters: {
        ...prevState.agentSearchParameters,
        [type]: value,
      },
    }));
  }

  async searchStaticAccountProvider() {
    this.setState({ isLoading: true, tableMessage: "" });

    try {
      const responseObj = await platformManagement.getStaticAccountProvider();
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS) {
        const data = Array.isArray(response.data) ? response.data : [];
        const sortedData = data.sort((a, b) => {
          return new Date(b.dateCreated) - new Date(a.dateCreated);
        });

        this.setState({
          staticAccountProvider: sortedData,
          isLoading: false,
          tableMessage: sortedData.length === 0 ? "No matching data found" : "",
        });
      } else {
        this.setState({
          staticAccountProvider: [],
          isLoading: false,
          tableMessage: response.description,
        });
      }
    } catch (error) {
      this.setState({
        tableMessage: "An error occurred. Please try again.",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleDataUpdate(updatedData) {
    this.setState({ staticAccountProvider: updatedData });
    this.searchStaticAccountProvider();
  }

  handleSearchRequest = () => {
    this.searchStaticAccountProvider(this.state.pageNum, this.state.pageSize);
  };

  getActiveBulkData(agents) {
    return agents.filter(function (value) {
      return value.statusId == 2;
    });
  }

  redirectToWalletJournal(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("agentDetails", JSON.stringify(agent));
  }

  redirectToCommissionHistory(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("commissionAgentDetails", JSON.stringify(agent));
  }

  render() {

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Manage Static Account"}
          subtitle={this.state.parentBusinessName}
          filterPermissions={[CAN_VIEW_STATIC_ACCOUNT]}
          totalCount={56}
          actionButtonText={"Add Provider"}
          actionButtonUrl={"/home/manage-static-account/upload"}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_STATIC_ACCOUNT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <StaticAccountTable
              data={this.state.staticAccountProvider}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getAgents={this.searchStaticAccountProvider}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_AGENT}
              canActivate={CAN_ACTIVATE_AGENT}
              canDeactivate={CAN_DEACTIVATE_AGENT}
              canRetire={CAN_RETIRE_AGENT}
              canManageDomain={CAN_MANAGE_AGENT_DOMAIN}
              canViewTransaction={CAN_VIEW_TRANSACTION}
              canLoadData={this.state.canLoadData}
              downloadAgentData={true}
              hasMultipleDownloadLinks={true}
              onUpdateData={this.handleDataUpdate}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
