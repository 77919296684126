import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_ACTIVATE_SERVICE, CAN_DEACTIVATE_SERVICE, CAN_VIEW_SERVICE } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import AppConfigDetailsModalAdd from '../components/app-config-add-modal';
import AppConfigDetailsModal from '../components/app-config-detail-modal';
import AppConfigDetailsModalEdit from '../components/app-config-edit-detail-modal';
import AppConfigTable from '../components/app-config-table';

export default class AppConfigGridScene extends BaseScene {
  
  getBillConfigurations = this.getBillConfigurations.bind(this)
  getClickedAction = this.getClickedAction.bind(this)
  updateSearchParameters = this.updateSearchParameters.bind(this)
  handleEditConfigurations = this.handleEditConfigurations.bind(this)
  handleSearchRequest = this.handleSearchRequest.bind(this);
  
  state = {
    configurations: [],
    configurationDetails: [],
    isLoading: true,
    searchParameters: {
      categoryId: "",
      categoryName: "",
      channelCode: "",
      currencyCode: "",
      customerIdName: "",
      description: "",
      imageName: "",
      imageUrl: "",
      name: "",
      providerCode: "",
      routeUrl: "",
      appStatus: "",
      surcharge: "",
      urlName: "",
    },
  }

  componentDidMount() {
    //this.getServices()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getBillConfigurations (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getBillConfigTable()
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        configurations: response ? response : [],
        dataCount: response.length,
        pages: Math.ceil(response.length/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      })
    }else{
      this.setState({
        configurations: [],
        dataCount: 0,
        isLoading: false,
        page: pageNum,
        pageSize: pageSize,
        errorMessage: response
      })
    }
  }


  async handleEditConfigurations (pageNum, pageSize) {
    const {
      categoryId,
      categoryName,
      channelCode,
      currencyCode,
      customerIdName,
      description,
      imageName,
      imageUrl,
      name,
      providerCode,
      routeUrl,
      appStatus,
      surcharge,
      urlName,
    } = this.state.searchParameters;
    const payload = {
      // additionalMessage: string,
      categoryId: categoryId,
      categoryName: categoryName,
      code: channelCode,
      currencyCode: currencyCode,
      customerIdName: customerIdName,
      description: description,
      id: 0,
      imageName: imageName,
      imageUrl: imageUrl,
      name: name,
      providerCode: providerCode,
      routeUrl: routeUrl,
      status: appStatus ? JSON.parse(appStatus.toLowerCase()) : true,
      // supportEmail: string,
      // supportPhoneNumber: string,
      surcharge: surcharge,
      urlName: urlName
    }

    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.handleAddBillConfig(payload)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "Configuration Added Successfully!",
        searchParameters: {}
      })
      window.location.reload()
      // this.getBillConfigurations()
    }else{
      console.log(response.description, "error")
      this.setState({
        isLoading: false,
        // errorMessage: `${response.description}`,
        errorMessage: "Error Adding your Configuration!",
        searchParameters: {}
        
      })
      // this.getBillConfigurations()
    }
  }


  updateSearchParameters(type, value) {
    if (value == 0 || value == "All" || !value) {
      this.setState({
        searchParameters: { ...this.state.searchParameters, [type]: "" },
      });
      console.log("Here");
      return;
    }

    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }


  getClickedAction(config, actionType) {
    if (actionType == "view") {

      this.setState({
        configurationDetails: config,
      });
    }
    if (actionType == "edit") {

      this.setState({
        configurationDetails: config,
        searchParameters: {
          categoryId: config.categoryId,
          categoryName: config.categoryName,
          channelCode: config.channelCode,
          currencyCode: config.currencyCode,
          customerIdName: config.customerIdName,
          description: config.description,
          imageName: config.imageName,
          imageUrl: config.imageUrl,
          name: config.name,
          providerCode: config.providerCode,
          routeUrl: config.routeUrl,
          appStatus: config.appStatus,
          surcharge: config.surcharge,
          urlName: config.urlName,
        },
      });
    }
    this.setState({
      actionType,
    });
  }

  handleSearchRequest() {
    const {
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      endDate,
      startDate,
    } = this.state.searchParameters;
    this.handleEditConfigurations(
      this.state.pageNum,
      this.state.pageSize,
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      endDate,
      startDate
    );
  }

  render () {

    const addButton = <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_VIEW_SERVICE]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} data-toggle="modal" data-target="#app_config_add_modal" >Add Configuration</button></ProtectedComponent>
    </React.Fragment>
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Bills Configuration"} ctaWidgets={addButton} filterPermissions={[CAN_VIEW_SERVICE]} totalCount={56} /> 
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_SERVICE]}>
        <GridLayout>
          {/* <AppConfigSearch 
            handleSearchRequest={this.handleSearchRequest}
            searchParams={this.state.searchParams}
          /> */}
          <AppConfigTable 
            getBillConfigurations={this.getBillConfigurations}
            pages={this.state.pages}
            data={this.state.configurations}
            updateStatus={this.getClickedAction}
            loading={this.state.isLoading} 
            noDataText={`No Configuration found`}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canView={CAN_VIEW_SERVICE}
            canActivate={CAN_ACTIVATE_SERVICE}
            canDeactivate={CAN_DEACTIVATE_SERVICE}
            getClickedAction={this.getClickedAction}
          />
          <AppConfigDetailsModal
              configurationDetails={this.state.configurationDetails}
            />
          <AppConfigDetailsModalEdit
              configurationDetails={this.state.configurationDetails}
              updateSearchParameters={this.updateSearchParameters}
              handleEditConfigurations={this.handleEditConfigurations}
            />
          <AppConfigDetailsModalAdd
              configurationDetails={this.state.configurationDetails}
              updateSearchParameters={this.updateSearchParameters}
              searchParameters={this.state.searchParameters}
              handleEditConfigurations={this.handleEditConfigurations}
            />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
  
}
