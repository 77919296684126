import React from "react";
import FeeSplits from "../../../../../../components/fee-splits";
import {
  formatDateTime, formatTransactionStatus
} from "../../../../../../utils/formatter";

const FeeSplit = (props) => {
  if (
    formatTransactionStatus(
      props.transactionDetails.statusCode
        ? props.transactionDetails.statusCode
        : ""
    ) == "Successful" &&
    props.transactionDetails.transactionTypeInt < 10
  ) {
    return (
      <div class="form-group row">
        <FeeSplits feeSplits={props.parties} isLoading={props.isLoading} />
      </div>
    );
  } else return null;
};

export default class ReconDownloadableListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {

    const resul = this.props.downloadableFiles
      ? this.props.downloadableFiles
      : "Loading...";

    return (
      <React.Fragment>
        <div
          class="modal"
          id="downloadable_list_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Reconciliation Report Downloadable Files List
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label>
                        <strong>Downloadable Files</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-12">
                          <ol>
                            {resul
                              ? resul.map((value, idx) => (
                                <a href= {`${process.env.REACT_APP_RECONCILIATION_REPORT_API_BASE_URL}/finch-reconciliation-service/v1/report/download/report/file/${value.fileName}`}>
                                  <li
                                    key={idx}
                                    onClick={() =>
                                      this.props.downloadReportFile(
                                        value.fileName
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid rgba(0,0,0,0.2)",
                                      padding: "10px 20px",
                                      margin: "10px 0",
                                      borderRadius: "10px",
                                      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      File Name: {value.fileName}
                                    </p>
                                    <p>Size: {value.fileSize}</p>
                                    <p>Date created: {formatDateTime(value.dateCreated)}</p>
                                    <p>Status: {value.status}</p>
                                  </li>
                                  </a>
                                ))
                              : "Loading..."}
                          </ol>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
