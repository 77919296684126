import React from 'react'
import { Redirect } from 'react-router-dom'
import BaseScene from '../../../../../../components/scenes/component';
import ContentHead from '../../../../../../components/content-head';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import { SUCCESS_STATUS, ERROR_STATUS } from '../../../../../../constants/api';
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { userManagement } from '../../../../../../mixins/api'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_VIEW_PERMISSION } from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import PermissionForm from '../../components/permission-form'

export default class AddPermissionScene extends BaseScene {
  requiredPermissions = [
    CAN_VIEW_PERMISSION
  ]
  
  notificationSectionRef = React.createRef(null)
  
  constructor (props) {
    super(props)

    this.state = {
      permissionFormData: [],
      permissionGroups: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updatePermissionFormData = this.updatePermissionFormData.bind(this)
  }

  componentDidMount () {
    this.getAllPermissionGroups()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAllPermissionGroups () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.getAllPermissionGroups()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissionGroups: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        isLoading: false,
        dataCount: 0,
        permissionGroups: [],
        errorMessage: response
      })
    }
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.createPermission(this.state.permissionFormData.permissionGroupId, {permissions: [this.state.permissionFormData]})
    const { status, response } = responseObj
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: response
      })
      return
    }
    this.setState({
      isLoading: false,
      successMessage: 'Permission created successfully.',
      redirect: true,
    })
  }

  updatePermissionFormData (type, value) {
    this.setState({permissionFormData: {...this.state.permissionFormData, [type]: value}})
  }

  isFormValid () {
    return Boolean(!this.state.permissionFormData.name || !this.state.permissionFormData.description || !this.state.permissionFormData.permissionGroupId)
  }

  render () {

    if(this.state.redirect) {
      return <Redirect to="/home/permission-management" />
    }

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION]}><button type="button" disabled={this.isFormValid()} class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
        ref={this.notificationSectionRef}
      />
      <ContentHead title={"User Management"} subtitle="Create a Permission" ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_PERMISSION]}>
        <GridLayout>
          <PermissionForm 
            onChange={this.updatePermissionFormData}
            //permissionDetails={this.state.permissionFormData}
            permissionGroups={this.state.permissionGroups}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
