import { convertToMajor } from "../../utils/converter";
import {
  formatCurrency, formatDateTime, formatLongNumber
} from "../../utils/formatter";

export default class LienDataExportSerializer {
  static headerExport = () => {
    return [
      {
        label: "Agent",
        key: "accountCode",
        title: "Agent",
        dataIndex: "accountCode",
      },
      {
        label: "Business Name",
        key: "accountName",
        title: "Business Name",
        dataIndex: "accountName",
      },
      {
        label: "Transaction Type",
        key: "transType",
        title: "Transaction Type",
        dataIndex: "transType",
      },
      {
        label: "Ref No",
        key: "requestID",
        title: "Ref No",
        dataIndex: "requestID",
      },
      {
        label: "Amount",
        key: "transAmount",
        title: "Amount",
        dataIndex: "transAmount",
      },
      {
        label: "Approval Status",
        key: "approvalStatus",
        title: "Approval Status",
        dataIndex: "approvalStatus",
      },
      {
        label: "Remark",
        key: "remark",
        title: "Remark",
        dataIndex: "remark",
      },
      {
        label: "Date of Creation",
        key: "dateCreated",
        title: "Date of Creation",
        dataIndex: "dateCreated",
      }
    ];
  }

  static lienHistoryDetails = (formData) => {
    let newFormData = [];
    formData && formData.map((detail) => {
      let {
        accountCode, accountName, currentBalance, transType, requestID, transAmount, approvalStatus, remark, dateCreated
      } = detail;

      let detailFormData = {
        accountName,
        accountCode: accountCode
          ? formatLongNumber(accountCode)
          : "",
        requestID: requestID
          ? formatLongNumber(requestID)
          : "",
        currentBalance: currentBalance
          ? `₦${formatCurrency(convertToMajor(currentBalance))}`
          : "",
        remark,
        approvalStatus,
        dateCreated: dateCreated ? formatDateTime(dateCreated) : "",
        transType,
        transAmount: transAmount
          ? `₦${formatCurrency(convertToMajor(transAmount))}`
          : "",
      };

      newFormData.push(detailFormData);
    });
    return newFormData;
  }
}
