import { formatDateTime, formatLongNumber, formatString } from '../../utils/formatter';
import { getUserStatusName } from '../../utils/helper'

export default class FipExportSerializer {
    static headerExport () {
        return [
            { label: 'FIP ID', key: 'fipId', title: 'FIP ID', dataIndex: 'fipId' },
            { label: 'First Name', key: 'firstName', title: 'First Name', dataIndex: 'firstName' },
            { label: 'Last Name', key: 'lastName', title: 'Last Name', dataIndex: 'lastName' },
            { label: 'Phone Number', key: 'mobileNo', title: 'Phone Number', dataIndex: 'mobileNo' },
            { label: 'Email Address', key: 'email', title: 'Email Address', dataIndex: 'email' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
            { label: 'Date Created', key: 'dateCreated', title: 'Date Created', dataIndex: 'dateCreated' },
            { label: 'Last Modified', key: 'dateLastModified', title: 'Last Modified', dataIndex: 'dateLastModified' },
        ];
    }

    static reverseFipDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
                id, firstName, lastName, mobileNo, email, status, dateCreated, dateLastModified
            } = detail

            let detailFormData = {
                fipId: id ? `FIP/${formatString(id)}` : '',
                firstName,
                lastName,
                mobileNo: mobileNo ? formatLongNumber(mobileNo) : '',
                email,
                status: status ? getUserStatusName(status) : '',
                dateCreated: dateCreated ? formatDateTime(dateCreated) : '',
                dateLastModified: dateLastModified ? formatDateTime(dateLastModified) : '',
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}