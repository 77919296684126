import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import WalletDistributionScene from './scenes/distribute';

export default class DistributeScene extends Component {
  sceneName = 'wallet-distribution'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <Route exact path='/home/wallet/distribute' component={WalletDistributionScene} />
    </Router>
  }
}
