import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import FeeRuleGridScene from './scenes/fee-rule-grid';
import AddFeeRuleScene from './scenes/add-fee-rule';
import ViewFeeRuleScene from './scenes/view-fee-rule';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_FEE_RULE, CAN_CREATE_FEE_RULE } from '../../../../../constants/permissions.js'

export default class FeeRuleScene extends Component {
  sceneName = 'fee-rules'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FEE_RULE]} exact path='/home/fee-rules' component={FeeRuleGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_FEE_RULE]} exact path='/home/fee-rules/add' component={AddFeeRuleScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FEE_RULE]} exact path='/home/fee-rules/:id/view' component={ViewFeeRuleScene} />
    </Router>
  }
}
