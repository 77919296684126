import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { formatDateTime } from '../../../../../../utils/formatter';
import { explodeDate } from '../../../../../../utils/helper';

export default class UploadStockTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus',
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === '430px'
          ? '860px'
          : this.state.tableHeight === '860px'
          ? 'auto'
          : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus',
    });
  }
  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className='-loading -active'>
            <div className='-loading-inner'>
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: 'Batch ID',
        accessor: 'batchId',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 370,
      },
      {
        Header: 'Uploaded By',
        accessor: 'uploadBy',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 280,
      },
      {
        Header: 'Upload Status',
        accessor: 'documentStatus',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 200,
      },
      {
        Header: 'Successfull Upload',
        accessor: 'uploadSuccessCount',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 200,
        Cell: ({ row, original }) => (
          <div class='center'>
            {original.uploadSuccessCount === null
              ? 'PENDING'
              : original.uploadSuccessCount}
          </div>
        ),
      },
      {
        Header: 'Failed Upload',
        accessor: 'uploadFailCount',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 200,
        Cell: ({ row, original }) => (
          <div class='center'>
            {original.uploadFailCount === null
              ? 'PENDING'
              : original.uploadFailCount}
          </div>
        ),
      },
      // {
      //   Header: "Download Failed Upload",
      //   accessor: "documentFailPath",
      //   resizable: false,
      //   headerStyle: { "white-space": "unset" },
      //   style: { "white-space": "unset" },
      //   width: 100,
      // },
      // {
      //   Header: "Status",
      //   accessor: 'stockStatus',
      //   resizable: false,
      //   headerStyle: {'white-space': 'unset'},
      //   style: {'white-space': 'unset'},
      //   width: 100,
      //   getProps: (state, rowInfo, column) => {
      //     return {
      //       style: {
      //         textAlign: 'center'
      //       },
      //     };
      //   },
      //   Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge ${props.value.toLowerCase() === "new" ? 'kt-badge--success' : props.value.toLowerCase() === "assigned" ? 'kt-badge--warning' : ''} kt-badge--inline kt-badge--pill`}>{props.value.toLowerCase() === "new" ? 'New' : props.value.toLowerCase() === "assigned" ? "Assigned" : ''}</span>
      // },
      {
        Header: 'Date Uploaded',
        accessor: 'dateUploaded',
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        resizable: false,
        width: 250,
        Cell: (props) => {
          return props.value
            ? formatDateTime(explodeDate(props.value, '+'))
            : null;
        },
      },

      {
        Header: 'Action',
        accessor: 'deviceUuid',
        overflow: 'visible',
        resizable: false,
        sortable: false,
        headerStyle: { 'white-space': 'unset' },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: '20px',
              textAlign: 'center',
              whiteSpace: 'unset',
            },
          };
        },
        width: 60,
        Cell: ({ row, original }) => (
          <div class='dropdown'>
            {original.uploadFailCount > 0 && (
              <a
                href='#'
                class='btn btn-hover-brand btn-icon btn-pill'
                data-toggle='dropdown'
              >
                <i class='la la-ellipsis-v'></i>
              </a>
            )}

            {original.uploadFailCount > 0 && (
              <div class='dropdown-menu dropdown-menu-right'>
                <ProtectedComponent
                  requiredPermissions={[this.props.canRemapDevice]}
                >
                  {/* <a href={`https://mufasa.k8.isw.la/p/POS/posInventory/failed_STOCK_93b03b8f-c2cb-4953-b103-d2acd061484a.csv`} class="dropdown-item" download="Failed Uploads"> */}
                  <a
                    href={` ${
                      process.env.REACT_APP_DOCUMENT_URL
                    }${(this.path = original.documentFailPath.replace(
                      /data/,
                      ''
                    ))}`}
                    class='dropdown-item'
                    download='Failed Uploads'
                  >
                    <i class='la la-download'></i>
                    Download Failed
                    {/* {console.log(this.path)} */}
                  </a>
                </ProtectedComponent>
              </div>
            )}
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          // data={FipExportSerializer.reverseFipDetails(this.props.data)}
          // headers={FipExportSerializer.headerExport()}
          fileName={'POS Stocks'}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getStocks}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
          downloadLink={this.props.downloadStocks}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: 'none',
            boxShadow: 'none',
            whiteSpace: 'unset',
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ''}
          pageSizes={[10, 20, 25, 50, 100]}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          // minRows={this.props.data.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getStocks(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: 'flex',
              overflow: 'visible',
              color: 'black',
              fontSize: '10px',
              fontWeight: '50',
              paddingLeft: '5px',
              paddingRight: '5px',
              alignItems: 'center',
              padding: '0px, 0',
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: '#00425f',
              borderBottom: '0.1px solid #DBDEDE',
              borderWidth: '0.1px',
              padding: '10px 0',
              color: '#FFFFFF',
              fontSize: '11px',
              fontWeight: '100',
              textAlign: 'left',
              paddingLeft: '5px',
              paddingRight: '5px',
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
            },
          })}
          className='-striped -highlight'
        />
      </React.Fragment>
    );
  }
}
