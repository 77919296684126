import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';

export default class TraceSearch extends React.Component {
    state = {
        traceDataSearchParameters: {
            serviceName: "",
            startData: "",
            endDate: ""
        },
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest(this.state.traceDataSearchParameters)
    }
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Search:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({traceDataSearchParameters: {...this.state.traceDataSearchParameters, serviceName: event.target.value}})} />
                    </div>
                    <div class="col-md-2">
                        <label>Date From</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({traceDataSearchParameters:{...this.state.traceDataSearchParameters, startDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label>Date To</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({traceDataSearchParameters: {...this.state.traceDataSearchParameters, endDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.traceDataSearchParameters.startDate ? false : true}
                            minDate={new Date(this.state.traceDataSearchParameters.startDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}