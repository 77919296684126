import React from "react";
import ContentHead from "../../../../../../components/content-head";
import BaseScene from "../../../../../../components/scenes/component";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import {
  CAN_MESSAGING_WRITE_TOPIC,
  CAN_MESSAGING_READ_TOPIC,
} from "../../../../../../constants/permissions";
import { SUCCESS_STATUS, ERROR_STATUS } from "../../../../../../constants/api";
import { AccessDenied } from "../../../../../../components/access-denied";
import { messagingService } from "../../../../../../mixins/api";
import Notification from "../../../../../../components/notification";
import { formatBackEndDateTime } from "../../../../../../utils/formatter";
import MessagingHistorySearch from "../../components/messaging-history-search";
import MessagingHistoryTable from "../../components/messaging-history-table";

export default class MessagingHistoryGridScene extends BaseScene {
  metadataSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      histories: [],
      searchParameters: {},
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      useDefaultDate: true,
    };
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getMessagingHistory = this.getMessagingHistory.bind(this);
  }

  sceneName = "messaging-history";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 3000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 3000);
    }
  }

  async getMessagingHistory(pageNum, pageSize) {
    const payload = this.state.searchParameters;
    payload.fromDate =
      payload.fromDate && formatBackEndDateTime(payload.fromDate);
    payload.toDate = payload.toDate && formatBackEndDateTime(payload.toDate);
    this.setState({
      isLoading: true,
      canLoadDate: true,
    });
    const { status, response } = await messagingService.getMessagingHistory(
      pageNum,
      pageSize,
      payload
    );
    const { items, totalCount } = response;
    if (status === SUCCESS_STATUS) {
      console.log("messaging history response ", response);
      this.setState({
        histories: items,
        pages: Math.ceil(totalCount / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        dataCount: totalCount,
        tableMessage: items.length === 0 ? "No messaging history found" : "",
      });
    } else {
      this.setState({
        histories: [],
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        errorMessage: response,
        tableMessage: "No messaging history found",
      });
    }
  }

  handleSearchRequest(searchParameters) {
    this.setState({
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getMessagingHistory(1, this.state.pageSize);
    }, 200);
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />
        <ContentHead
          title={"Messaging History"}
          totalCount={56}
          filterPermissions={[CAN_MESSAGING_READ_TOPIC]}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_MESSAGING_READ_TOPIC]}
        >
          <GridLayout>
            <MessagingHistorySearch
              handleSearchRequest={this.handleSearchRequest}
            />
            <MessagingHistoryTable
              data={this.state.histories}
              getMessagingHistory={this.getMessagingHistory}
              pages={this.state.pages}
              startPage={this.state.startPage}
              noDataText={this.state.tableMessage}
              loading={this.state.isLoading}
              dataCount={this.state.dataCount}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_MESSAGING_WRITE_TOPIC}
              canCancel={CAN_MESSAGING_WRITE_TOPIC}
              canLoadData={this.state.canLoadData}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
