import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_CREATE_STATE } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import StateForm from '../../components/state-form';

export default class AddStateScene extends BaseScene {
  constructor (props) {
    super(props)
    this.state = {
      canSave: null,
      countries: [],
      stateFormData: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onSaveButtonClick () {
    
  }

  updateSetState (type, value) {
    this.setState({stateFormData: {...this.state.stateFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_CREATE_STATE]}><button type="button" disabled={!this.state.name || !this.state.abbreviation } class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
      <button type="button" disabled={!this.state.name || !this.state.abbreviation } class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"State"} withoutSearch subtitle="Add State" ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_CREATE_STATE]}>
        <GridLayout>
          <StateForm 
            onChange={this.updateSetState}
            countries={this.state.countries}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
