import React from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import BaseScene from '../../../../../../components/scenes/component'
import DomainDeviceGridScene from './scenes/device-grid'
// import ChangePasswordGridScene from './scenes/change-password'
// import AccountSettingsGridScene from './scenes/account-settings'
// import UserSettingsGridScene from './scenes/user-settings'
import { isAuthorized } from '../../../../../../mixins/authentication'
import { CAN_VIEW_AGENT_DEVICE } from '../../../../../../constants/permissions'

export default class DomainDeviceScene extends BaseScene {
  sceneName = 'role-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT_DEVICE]} path='/home/domain-management/:domainCode/devices' component={DomainDeviceGridScene} />
      {/* <Route exact path='/home/profile/change-password' component={ChangePasswordGridScene} />
      <Route exact path='/home/profile/account-settings' component={AccountSettingsGridScene} />
      <Route exact path='/home/profile/user-settings' component={UserSettingsGridScene} /> */}
    </Router>
  }
}
