import React from 'react'
import Statuses from '../../../../../../fixtures/agentBvnVerificationStatus.json'
import Select from '../../../../../../components/select'

export const AgentBVNSearch = (props) => {
    const {
        updateAgentSearchParameters, handleSearchRequest, agentSearchParameters: { status, startDate }
    } = props;
    return <>
        <form class="search-params" onSubmit={(e) => {
            e.preventDefault()
            handleSearchRequest()
        }}>
            <div class="form-group row">
                <div class="col-md-3">
                    <label>Agent Mobile Number:</label>
                    <input type="text" class="search-input" onChange={event => updateAgentSearchParameters('agentMobileNumber', event.target.value)} />
                </div>
                <div class="col-md-3">
                    <label>BVN Verification Status:</label>
                    <Select
                        options={Statuses}
                        onChange={event => updateAgentSearchParameters('verificationStatus', event.value)}
                        defaultValue={status}
                    />
                </div>
                <div class="col-md-2">
                    <label class="search-label">&nbsp;&nbsp;</label>
                    <button type="submit" class="search-button">Search</button>
                </div>
            </div>
        </form>
    </>
}