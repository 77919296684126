import React from "react";
import { isPhoneNumberValid } from "../../utils/helper";
import PhoneNumberComponent from "../phone-number-component";
import Select from "../select";

export default class NextOfKinDetailsAggregatorForm extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              class="kt-portlet__head"
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target="#collapseOne3"
              aria-controls="collapseOne3"
            >
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Next Of Kin Details</h3>
              </div>
            </div>
          )}

          <form
            id="collapseOne3"
            class={`collapse ${this.props.showContent ? "show" : ""}`}
          >
            <div class="kt-portlet__body">
              <div class="form-group row">
                {this.props.nextOfKinDetails &&
                  this.props.nextOfKinDetails.firstName && (
                    <div class="col-lg-4 mb-4">
                      <label>Full Name *:</label>
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="text"
                        class="form-control"
                        placeholder="Enter first name"
                        defaultValue={
                          this.props.nextOfKinDetails
                            ? this.props.nextOfKinDetails.firstName
                            : ""
                        }
                        onChange={(event) =>
                          this.props.onChange("firstName", event.target.value)
                        }
                      />
                      <span class="form-text text-muted">
                        Please enter first name
                      </span>
                    </div>
                  )}
                {this.props.nextOfKinDetails &&
                  this.props.nextOfKinDetails.surname && (
                    <div class="col-lg-4 mb-4">
                      <label>Last Name *:</label>
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="text"
                        class="form-control"
                        placeholder="Enter last name"
                        defaultValue={
                          this.props.nextOfKinDetails
                            ? this.props.nextOfKinDetails.surname
                            : ""
                        }
                        onChange={(event) =>
                          this.props.onChange("surname", event.target.value)
                        }
                      />
                      <span class="form-text text-muted">
                        Please enter last name
                      </span>
                    </div>
                  )}
                {(this.props.nextOfKinDetails || this.props.showPhoneInput) && (
                  <div class="col-lg-4 mb-4">
                    <label>Phone *:</label>
                    <div class="kt-input-icon">
                      {this.props.nextOfKinDetails && (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          placeholder="Enter last name"
                          defaultValue={
                            this.props.nextOfKinDetails
                              ? this.props.nextOfKinDetails.phoneNumber
                              : ""
                          }
                          onChange={(event) =>
                            this.props.onChange(
                              "phoneNumber",
                              event.target.value
                            )
                          }
                        />
                      )}
                      {!this.props.nextOfKinDetails && (
                        <PhoneNumberComponent
                        showBorderError={false}
                        phoneIsValid={
                          this.props.nextOfKinPhoneIsValid
                            ? this.props.nextOfKinPhoneIsValid
                            : true
                        }
                        onChange={(status, phoneNumber) => {
                          this.props.updateSetState(
                            "nextOfKinPhoneIsValid",
                            status || isPhoneNumberValid(phoneNumber)
                          );
                          this.props.onChange("phoneNumber", phoneNumber);
                        }}
                        defaultValue={
                          this.props.nextOfKinDetails &&
                          this.props.nextOfKinDetails.phoneNumber
                            ? this.props.nextOfKinDetails.phoneNumber
                            : ""
                        }
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                      />
                      )}
                      {this.props.applicantDetailsFormData.nextOfKin
                        .phoneNumber &&
                        !this.props.nextOfKinPhoneIsValid && (
                          <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                              <i
                                class="la la-info-circle"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          </span>
                        )}
                    </div>
                    <span class="form-text text-muted">Phone number</span>
                  </div>
                )}
            
                {/* <div class="col-lg-4 mb-4">
                                <label>Email Address *:</label>
                                <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="email" class="form-control" placeholder="Enter email address" defaultValue={this.props.nextOfKinDetails ? this.props.nextOfKinDetails.emailAddress : ''} onChange={(event) => this.props.onChange('emailAddress', event.target.value)}/>
                                <span class="form-text text-muted">Please enter email address</span>
                            </div> */}
                {this.props.nextOfKinDetails &&
                  this.props.nextOfKinDetails.relationship && (
                    <div class="col-lg-4 mb-4">
                      <label>Relationship *:</label>
                      <Select
                        options={this.props.relationships.map((detail) => {
                          return { label: detail.name, value: detail.name };
                        })}
                        onChange={(event) =>
                          this.props.onChange("relationship", event.value)
                        }
                        minHeight={37}
                        disabled={this.props.disabled}
                        defaultValue={
                          this.props.applicantDetailsFormData.nextOfKin
                            ? this.props.applicantDetailsFormData.nextOfKin
                                .relationship
                            : this.props.nextOfKinDetails.relationship
                        }
                      />
                    </div>
                  )}
                {this.props.nextOfKinDetails &&
                  this.props.nextOfKinDetails.gender && (
                    <div class="col-lg-4 mb-4">
                      <label>Gender *:</label>
                      <div class="col-9">
                        <div class="kt-radio-inline">
                          <label class="kt-radio">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="radio"
                              name="gender"
                              value="MALE"
                              defaultChecked={
                                this.props.nextOfKinDetails &&
                                this.props.nextOfKinDetails.gender &&
                                this.props.nextOfKinDetails.gender.toUpperCase() ===
                                  "MALE"
                              }
                              onChange={(event) =>
                                this.props.onChange(
                                  "gender",
                                  event.target.value
                                )
                              }
                            />{" "}
                            Male
                            <span></span>
                          </label>
                          <label class="kt-radio">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="radio"
                              name="gender"
                              value="FEMALE"
                              defaultChecked={
                                this.props.nextOfKinDetails &&
                                this.props.nextOfKinDetails.gender &&
                                this.props.nextOfKinDetails.gender.toUpperCase() ===
                                  "FEMALE"
                              }
                              onChange={(event) =>
                                this.props.onChange(
                                  "gender",
                                  event.target.value
                                )
                              }
                            />{" "}
                            Female
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <label>Address *:</label>&nbsp;&nbsp;
                  {!this.props.disabled &&
                    this.props.applicantDetailsFormData.address && (
                      <label>
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            this.props.updateSetState(
                              "shouldUpdateNextOfKinAddress",
                              !this.props.shouldUpdateNextOfKinAddress
                            );
                            this.props.updateNextOfKinAddress(
                              !this.props.shouldUpdateNextOfKinAddress
                            );
                          }}
                        />
                        <span>
                          Tick this box if your next of kin address is same as
                          applicant address
                        </span>
                      </label>
                    )}
                  <div class="kt-input-icon kt-input-icon--right">
                    <textarea
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      rows={5}
                      style={{ resize: "none" }}
                      class="form-control"
                      placeholder="Enter office address"
                      defaultValue={
                        this.props.applicantDetailsFormData &&
                        this.props.applicantDetailsFormData.nextOfKin.address
                          ? this.props.applicantDetailsFormData.nextOfKin
                              .address
                          : this.props.nextOfKinDetails &&
                            this.props.nextOfKinDetails.address
                          ? this.props.nextOfKinDetails.address
                          : ""
                      }
                      onChange={(event) =>
                        this.props.onChange("address", event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}