import React from "react";

import FileUpload from "../../pos-config/components/file-upload";
import { AccessDenied } from "../../../../../../components/access-denied";
import Loader from "../../../../../../components/loader";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component.js";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../constants/api.js";
import { CAN_REMAP_DEVICE } from "../../../../../../constants/permissions";
import { userManagement } from "../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { getDateByType } from "../../../../../../utils/helper";
import SelectComponent from "../../../../../../components/select";
import State from "../../../../../../fixtures/states.json";

export default class AllocatePosManagementScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {},
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      stockDisable: false,

      stockError: "",
      configError: "",
      redirect: false,
    };
    // this.uploadConfigFile = this.uploadConfigFile.bind(this);
    this.AllocatePos = this.AllocatePos.bind(this);
    this.GetBatchId = this.GetBatchId.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
  }

  sceneName = "allocate-pos";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async GetBatchId(id) {
    const responseObj = await userManagement.getPosRequestConfigurationByBatch(
      id
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      console.log(response);

      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "pos-allocation-file.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
    } else {
      this.setState({
        errorMessage: response,
      });
    }
  }

  async AllocatePos() {
    if (
      this.state.searchParameters.state &&
      this.state.searchParameters.quantity
    )
      this.setState({
        isLoading: true,
      });
    const responseObj = await userManagement.allocatePOS(
      this.state.searchParameters
    );
    const { status, response } = responseObj;

    if (status === SUCCESS_STATUS) {
      await this.GetBatchId(response.data.batchId);
      this.setState({
        successMessage: "Allocation successful",
        isLoading: false,
      });
      setTimeout(function () {
        window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home/pos-management/allocations-manage`;
      }, 3000);
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />

        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                  <div class="kt-portlet__body">
                    <h5>Allocate POS</h5>
                    <span>Choose a State and enter Quantity.</span>

                    <div
                      className="col-lg-8 align-content-center d-flex"
                      style={{ marginTop: "50px", marginLeft: "-18px" }}
                    >
                      <div className="col-lg-3">
                        <label className="search-label">Select State</label>
                        <SelectComponent
                          options={State.map((detail) => {
                            return { label: detail.name, value: detail.name };
                          })}
                          onChange={(event) =>
                            this.updateSearchParameters("state", event.value)
                          }
                          // defaultValue={props.searchParameters.transactionStatus}
                        />
                      </div>

                      <div className="search-component">
                        <label className="search-label">Quantity</label>
                        <input
                          type="number"
                          className="search-input"
                          onChange={(event) =>
                            this.updateSearchParameters(
                              "quantity",
                              event.target.value
                            )
                          }
                          style={{
                            height: "33px",

                            borderRadius: "5px",
                          }}
                        />
                      </div>
                      <div className="search-components">
                        <div className="col-md-2">
                          <label className="search-label">&nbsp;&nbsp;</label>
                          <button
                            onClick={this.AllocatePos}
                            type="button"
                            className="search-button"
                          >
                            Allocate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
