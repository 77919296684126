import DeviceUUID from "device-uuid";
import jwt_decode from "jwt-decode";
import { SUCCESS_STATUS } from "../constants/api";
import {
  AWAITING_APPROVAL_APPLICATION,
  AWAITING_VALIDATION_APPLICATION,
  DECLINED_APPLICATION,
} from "../constants/application-types";
import { AND, OR } from "../constants/operators";
import {
  CAN_APPROVE_APPLICATIONS,
  CAN_REJECT_APPLICATIONS,
  CAN_VALIDATE_APPLICATIONS,
} from "../constants/permissions";
import {
  ACCESS_TOKEN,
  FORGOT_PASSWORD_TOKEN,
} from "../constants/session-storage";
import UserManagement from "../services/api/resources/user-management";
import { toPermissionArray } from "../utils/helper";
const CURRENT_USER = "user";
const AUTH_TOKEN_EXPIRY = "auth_token_expiry";
export const CHECK_TOKEN_EXPIRY_DATE = "check_token_expiry_date";
export const TIME_TO_REFRESH_TOKEN_IN_MILLISECONDS = 100000;
const AUTH_TOKEN_LIFESPAN = parseInt(process.env.REACT_APP_AUTH_TOKEN_LIFESPAN);
const toEnforcePermissions = JSON.parse(
  process.env.REACT_APP_ENFORCE_PERMISSIONS
);

//this.userManagement = new UserManagement();

function currentTimestamp() {
  return currentTimestampInMilliSeconds() / 1000;
}

function currentTimestampInMilliSeconds() {
  const currentDate = new Date();
  return currentDate.valueOf();
}

export function deleteSessionInformation() {
  localStorage.removeItem(CURRENT_USER);
  localStorage.removeItem(AUTH_TOKEN_EXPIRY);
}

export function deleteOtherInformation(infoType) {
  localStorage.removeItem(infoType);
}

// 👇️ Subtract 15 minutes from current Date
// const result = subtractMinutes(15);
export function isAuthTokenExpired() {
  const authTokenExpiry = parseInt(localStorage.getItem(AUTH_TOKEN_EXPIRY));
  const expiryTime = authTokenExpiry + "000";
  const calculatedDate = new Date(Number(expiryTime)).setMinutes(
    new Date(Number(expiryTime)).getMinutes() - 10
  );
  const currentTime = parseInt(currentTimestamp()) + "000";
  if (authTokenExpiry && currentTime > calculatedDate) {
    return true;
  }

  return false;
}

export async function shouldCallRefressToken() {
  const authTokenExpiry = parseInt(localStorage.getItem(AUTH_TOKEN_EXPIRY));
  const currentTimestamp1 = currentTimestamp();
  const diff = authTokenExpiry - currentTimestamp1;

  if (diff <= TIME_TO_REFRESH_TOKEN_IN_MILLISECONDS && diff > 200) {
    return true;
  }

  return false;
}

export function retrieveSessionInformation() {
  return JSON.parse(localStorage.getItem(CURRENT_USER));
}

export function retrieveOtherInformation(infoType) {
  return localStorage.getItem(infoType);
}

export function saveSessionInformation(currentUser) {
  const parsedCurrentUser = JSON.parse(currentUser);
  if (parsedCurrentUser.access_token) {
    localStorage.setItem(CURRENT_USER, currentUser);
    localStorage.setItem(AUTH_TOKEN_EXPIRY, getTokenInformation());
  }
}

export function saveOtherInformation(saveType, userInfo) {
  localStorage.setItem(saveType, userInfo);
}

export function getDeviceUuid() {
  return DeviceUUID.DeviceUUID().get();
}

export function parseDeviceUuid() {
  return DeviceUUID.DeviceUUID().parse();
}

export function getDeviceInformation() {
  return {
    deviceUuid: "130230d4-87a4-4219-b789-a15f398e3184",
    deviceName: "My PC",
    deviceModel: "",
    deviceOs: parseDeviceUuid().os,
    channel: "",
  };
}

export function getTokenInformation() {
  const accessToken = retrieveSessionInformation().access_token;
  return jwt_decode(accessToken).exp;
}

export async function refreshUserSession() {
  const userManagement = new UserManagement();
  //const refreshToken = retrieveRefreshToken()

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { status, response } = await userManagement.refreshUserSession(
    currentUser.user.username,
    currentUser.refresh_token
  );

  if (status === SUCCESS_STATUS) {
    currentUser["access_token"] = response.data;
    saveSessionInformation(JSON.stringify(currentUser));
  } else {
    window.location = `${process.env.REACT_APP_ROUTE_BASENAME}/logout`;
  }
}

export async function testRefresh() {}

export async function clearLocalStorage() {
  deleteSessionInformation();
  deleteOtherInformation("pointerEvents");
  deleteOtherInformation("forgotPasswordToken");
  deleteOtherInformation("weeklyTransferVolume");
  deleteOtherInformation("weeklyRechargeVolume");
  deleteOtherInformation("weeklyBillsVolume");
  deleteOtherInformation("commissionAgentDetails");
}

export function validateApplicationPermission() {
  const currentUser = retrieveSessionInformation();
  const userPermissions = toPermissionArray(currentUser.user.permissions);
  let status = null;
  if (
    userPermissions.includes(CAN_APPROVE_APPLICATIONS) &&
    userPermissions.includes(CAN_VALIDATE_APPLICATIONS)
  ) {
    status = null;
  } else if (userPermissions.includes(CAN_APPROVE_APPLICATIONS)) {
    status = AWAITING_APPROVAL_APPLICATION;
  } else if (userPermissions.includes(CAN_VALIDATE_APPLICATIONS)) {
    status = AWAITING_VALIDATION_APPLICATION;
  } else if (userPermissions.includes(CAN_REJECT_APPLICATIONS)) {
    status = DECLINED_APPLICATION;
  }
  return status;
}

export function saveSessionData(sessionData) {
  saveOtherInformation("domainCode", sessionData.domainCode);
  saveOtherInformation(
    "username",
    `${sessionData.firstName} ${sessionData.lastName}`
  );
  saveOtherInformation(
    "domainTypes",
    JSON.stringify(sessionData.domainTypeIds)
  );
  const getUserRolesResponse = sessionData.permissions;
  const userPermissions = getUserRolesResponse.map((value) => value.name);
  saveOtherInformation("userPermissions", JSON.stringify(userPermissions));
}

export function isLogin() {
  const currentUser = retrieveSessionInformation();
  return Boolean(currentUser && currentUser.access_token);
}

export function isAuthorized(requiredPermissions) {
  const operator = OR;
  const currentUser = retrieveSessionInformation();
  const userPermissions = currentUser.user.permissions
    ? toPermissionArray(currentUser.user.permissions)
    : [];

  if (toEnforcePermissions) {
    if (operator === OR) {
      return Boolean(
        userPermissions.find((value) => requiredPermissions.includes(value))
      );
    } else if (operator === AND) {
      return Boolean(
        userPermissions.filter((value) =>
          requiredPermissions.includes(value)
        ) === requiredPermissions
      );
    }
  }

  return true;
}

export function getSpecificItem(currentUser, type) {
  return currentUser ? currentUser[type] : null;
}

export function getAccessToken() {
  const accessToken = getSpecificItem(
    retrieveSessionInformation(),
    ACCESS_TOKEN
  );
  const passwordToken = retrieveOtherInformation(FORGOT_PASSWORD_TOKEN);
  return accessToken || passwordToken
    ? `Bearer ${accessToken ? accessToken : passwordToken}`
    : null;
}
