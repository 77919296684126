import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { CAN_VIEW_STATIC_ACCOUNT, CAN_VIEW_TRANSACTION } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import StaticAccountGridScene from './scenes/static-account-grid/index.js';
import CatergoryScene from './scenes/category-upload/index.js';

export default class StaticAccountScene extends Component {
  sceneName = 'manage-static-account'

  componentDidMount() {
    this.props.updateScene(this.sceneName)
  }

  render() {
    const ProtectedRoute = ({ component: Component, requiredPermissions, ...rest }) => {

      return (
        <Route
          {...rest}
          render={props =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_STATIC_ACCOUNT]}
          exact
          path="/home/manage-static-account"
          component={StaticAccountGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact path="/home/manage-static-account/upload"
          component={CatergoryScene}
        />
      </Router>
    );
  }

}
