import qs from 'querystring';
import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_TRANSACTION } from '../../../../../../../constants/permissions.js';
import { settlementService, transactionHistory, transactionService } from '../../../../../../../mixins/api';
import { deleteOtherInformation, retrieveOtherInformation, saveOtherInformation } from '../../../../../../../mixins/authentication';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { formatBackEndDateTime, formatTransactionStatus, formatTransactionStatusName, phoneNumberFormatter } from '../../../../../../../utils/formatter';
import { getDateByType, getTransactionTypeInt } from '../../../../../../../utils/helper';
import TransactionDetails from '../../components/transaction-details';
import TransactionDetailsModal from '../../components/transaction-details-modal';
import TransactionHistoryTable from '../../components/transaction-history-table';
import TransactionSearch from '../../components/transaction-search';

export default class ReversalHistoryGridScene extends BaseScene {
  
  sceneName = 'reversal-history'

  transactionDetailsSectionRef = React.createRef(null)
  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      transactionDetails: [],
      searchParameters: {
        startDate: retrieveOtherInformation("parentReference") ? null : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        endDate: retrieveOtherInformation("parentReference") ? null : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
        transactionType: "",
        transactionStatus: ""
      },
      pages: 1,
      canLoadData: retrieveOtherInformation("parentReference") ? true : getTransactionTypeInt(qs.parse(window.location.search)['?transactionType']) ? true : JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      pageNum: 1,
      pageSize: 20,
      checkedTransactions: [],
      settlement: {
        parties: []
      },
      reversalDetails: [],
      tableMessage: ""
    }

    this.getAllTransactions = this.getAllTransactions.bind(this)
    // this.getTransactions = this.getTransactions.bind(this)
    this.getTransactionDetails = this.getTransactionDetails.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this)
    this.handleSearchRequest = this.handleSearchRequest.bind(this)
    this.requerySingleTransaction = this.requerySingleTransaction.bind(this)
    this.getClickedAction = this.getClickedAction.bind(this)
    this.resolveSingleTransaction = this.resolveSingleTransaction.bind(this)
    this.getSelectedTransactions = this.getSelectedTransactions.bind(this)
    this.requeryBulkTransactions = this.requeryBulkTransactions.bind(this)
    this.resolveBulkTransactions = this.resolveBulkTransactions.bind(this)
    this.selectAllTransactions = this.selectAllTransactions.bind(this)
    this.getTransactionReversalDetails = this.getTransactionReversalDetails.bind(this)
    this.redirectToCommission = this.redirectToCommission.bind(this)
  }
  
  componentDidMount() {
    const transactionType = getTransactionTypeInt(qs.parse(window.location.search)['?transactionType'])
    this.setState({transactionType})

    const parentReference = retrieveOtherInformation("parentReference")

    if(parentReference) {
      this.updateSearchParameters("parentReference", parentReference)    
    }
    if(transactionType) {
      this.updateSearchParameters("transactionType", transactionType)
    }
    

  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAllTransactions (pageNum, pageSize) {
    const {
      businessMobileNo,
      businessName,
      endDate,
      startDate,
      transactionStatus,
      transactionType,
      transRef,
      responseCode,
      parentReference,
      service,
      paymentItemCode,
      serviceOptionName
     } = this.state.searchParameters

     this.setState({canLoadData: true})
     
     const parentReference2 = retrieveOtherInformation("parentReference")

     if(parentReference2) {
      this.updateSearchParameters("parentReference", parentReference2)
     }

    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate)
    const formattedBusinessMobileNo = businessMobileNo && phoneNumberFormatter(businessMobileNo)
    this.setState({
      pageNum,
      pageSize,
      isLoading: true
    })
    const { status, response, count } = await transactionHistory.getTransactionsBySearch(pageNum, pageSize, formattedBusinessMobileNo, businessName, formattedEndDate, formattedStartDate, transactionStatus, transactionType, transRef, responseCode, parentReference ? parentReference : parentReference2, service, paymentItemCode, serviceOptionName);
    if (status === SUCCESS_STATUS) {
      this.setState({
        transactions: response ? response : [],
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count,
        isLoading: false,
      })
    } else {
      this.setState({
        transactions: [],
        transactionExportData: [],
        dataCount: 0,
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No transaction history found"
      })
    }
    deleteOtherInformation("parentReference")
  }

  async getTransactionDetails (refNo) {
    const responseObj = await transactionHistory.getTransactionsByRef(refNo)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        transactionDetails: {...this.state.transactionDetails, extraInfoList: response.extraInfoList},
      })
    }
  }

  handleSearchRequest () {
    const {
      businessMobileNo,
      businessName,
      endDate,
      startDate,
      transactionStatus,
      transactionType,
      transRef,
      responseCode,
      parentReference
     } = this.state.searchParameters
    this.getAllTransactions(this.state.pageNum, this.state.pageSize, businessMobileNo, businessName, endDate, startDate, transactionStatus, transactionType, transRef, responseCode, parentReference)
  }

  updateSearchParameters (type, value) {
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  async requerySingleTransaction (transRef) {
    this.setState({isLoading: true})
    const responseObj = await transactionHistory.requerySingleTransaction(transRef)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Transaction with reference number ${transRef} will be processed shortly, please check back.`,
        isLoading: false
      })
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false
      })
    }
    this.getAllTransactions()
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  async requeryBulkTransactions () {
    this.setState({isLoading: true})
    const responseObj = this.state.checkedTransactions.length ? await transactionHistory.requeryBulkTransactions(this.state.checkedTransactions) 
      : {status: 'ERROR', response: {description: "Check out the transaction(s) you want to bulk re-query"}}
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Transactions will be processed shortly, please check back.`,
        isLoading: false,
        checkedTransactions: []
      })
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false
      })
    }
    this.getAllTransactions(this.state.pageNum, this.state.pageSize)
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }


  async resolveSingleTransaction () {
    this.setState({isLoading: true})
    const desiredStatusName = formatTransactionStatusName(this.state.desiredStatus)
    const responseObj = await transactionHistory.resolveSingleTransaction(this.state.transRef, {
      desiredStatus: this.state.desiredStatus,
      transactionRef: this.state.transRef
    })
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `${this.state.transRef} was resolved as ${desiredStatusName} transaction`,
        desiredStatus: null,
        isLoading: false
      })
    }else{
      this.setState({
        errorMessage: response,
        desiredStatus: null,
        isLoading: false
      })
    }
    this.getAllTransactions()
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  async resolveBulkTransactions (desiredStatus) {
    this.setState({isLoading: true})
    const desiredStatusName = formatTransactionStatusName(desiredStatus)
    const responseObj = this.state.checkedTransactions.length ? await transactionHistory.resolveBulkTransactions({
      "completionDTOList": this.formatBulkResolution(desiredStatus, this.state.checkedTransactions)
    }) : {status: 'ERROR', response: {description: "Check out the transaction(s) you want to bulk resolve"}}
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Transactions were resolved as ${desiredStatusName} transaction`,
        desiredStatus: 'default',
        isLoading: false,
        checkedTransactions: []
      })
    }else{
      this.setState({
        errorMessage: response,
        desiredStatus: 'default',
        isLoading: false
      })
    }
    this.getAllTransactions(this.state.pageNum, this.state.pageSize)
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  formatBulkResolution(desiredStatus, checkedTransactions) {
    let bulkTransactions = []
    checkedTransactions.map(value => {
      let transactionDetails = {}
      transactionDetails.transactionRef = value
      transactionDetails.desiredStatus = desiredStatus
      bulkTransactions.push(transactionDetails)
    })
    return bulkTransactions
  }

  getClickedAction (transaction, actionType) {
    if(actionType == 'view') {
      this.getTransactionDetails(transaction.transactionRef)
      //this.getTransactionDetails(transaction)
      if((formatTransactionStatus(transaction.statusCode ? transaction.statusCode : '') == 'Successful' && (transaction.transactionTypeInt < 10))) {
        this.getSettlementByRef(transaction.transactionRef)
      }
      if((formatTransactionStatus(transaction.statusCode ? transaction.statusCode : '') == 'Failed' && (transaction.transactionTypeInt < 10))) {
        this.getTransactionReversalDetails(transaction.transactionRef)
      }
      this.setState({
        transactionDetails: transaction,
        settlement: {
          parties: []
        }
      })
    }
    this.setState({
      transRef: transaction.transRef,
      actionType
    })
  }

  async getSettlementByRef (refNo) {
    this.setState({
      settlement: {
        parties: []
      },
      isSettlementLoading: true
    })
    const responseObj = await settlementService.getSettlementByTransRef(refNo)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        settlement: response[0],
        isSettlementLoading: false
      })
    }else{
      this.setState({
        settlement: {
          parties: []
        },
        isSettlementLoading: false
      })
    }
  }

  async getTransactionReversalDetails (transactionReference) {
    this.setState({
      isReversalDetailLoading: true,
      reversalDetails: []
    })
    const responseObj = await transactionService.getTransactionReversal(transactionReference)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        reversalDetails: response && response.description != "successful" ? [response] : [],
        isReversalDetailLoading: false
      })
    }else{
      this.setState({
        reversalDetails: [],
        isReversalDetailLoading: false
      })
    }
  }

  isFormValid () {
    //return false
    if(this.state.actionType == 'resolve') {
      return Boolean(this.state.desiredStatus)
    }else{
      return true
    }
  }

  redirectToCommission (transaction) {
    const commissionReference = `${transaction.transactionRef}${transaction.domainCode}`
    saveOtherInformation("commissionReference", commissionReference)
  }

  getSelectedTransactions (isChecked, transRef) {
    let checkedTransactions = this.state.checkedTransactions
    const transactions = this.state.transactions.map(value => {
      if(value.transactionRef === transRef) {
        value.checked = isChecked
        const transRefExist = this.handleDuplicateTransactions(checkedTransactions, transRef )
        if(isChecked) {
          if(!transRefExist) {
            checkedTransactions.push(transRef)
          }
        }else{
          checkedTransactions = checkedTransactions.filter(function(item) {
            return item !== transRef
          })
        }
      }
      return value
    })
    this.setState({
      transactions,
      checkedTransactions
    })
  }

  selectAllTransactions(isChecked) {
    let checkedTransactions = this.state.checkedTransactions
    const transactions = this.state.transactions.map(value => {
      value.checked = value.statusCode == 'PENDING_VALUE_PROVISIONING' ? isChecked : false
      const transRefExist = this.handleDuplicateTransactions(checkedTransactions, value.transactionRef )
      if(isChecked && value.statusCode == 'PENDING_VALUE_PROVISIONING') {
        if(!transRefExist) {
          checkedTransactions.push(value.transactionRef)
        }
      }else{
        checkedTransactions = checkedTransactions.filter(function(item) {
          return item !== value.transactionRef
        })
      }
      return value
    })

    this.setState({
      transactions,
      checkedTransactions
    })
  }

  handleDuplicateTransactions (transactions, transRef) {
    if(transactions.indexOf(transRef) == -1) {
      return false
    }else{
      return true
    }
  }

  render () {
    const modalBody = <React.Fragment>
      <p>{`Do you want to resolve this transaction ${this.state.transRef} as`}</p>
      <div class="form-group row">
        <div class="col-lg-5">
            <div class="col-12">
                <div class="kt-radio-inline">
                    <label class="kt-radio">
                        <input autoComplete="off" type="radio" name="gender" value="1" checked={this.state.desiredStatus == 1} onChange={event => this.setState({desiredStatus: event.target.value})}/> Success
                        <span></span>
                    </label>
                    <label class="kt-radio">
                        <input autoComplete="off" type="radio" name="gender" value="0" checked={this.state.desiredStatus == 0} onChange={event => this.setState({desiredStatus: event.target.value})}/> Fail
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>

    return <React.Fragment>
      <ContentHead title={"Reversal History"} filterPermissions={[CAN_VIEW_TRANSACTION]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_TRANSACTION]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <TransactionSearch 
            searchParameters={this.state.searchParameters}
            agentName={this.state.transactions.agentName}
            updateSearchParameters={this.updateSearchParameters}
            handleSearchRequest={this.handleSearchRequest}
            preloadDate={retrieveOtherInformation("parentReference") ? false : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
            showRepush={true}
            requeryBulkTransactions={this.requeryBulkTransactions}
            resolveBulkTransactions={this.resolveBulkTransactions}
            desiredStatus={this.state.desiredStatus ? this.state.desiredStatus : 'default'}
          />
          <TransactionHistoryTable 
            pages={this.state.pages}
            getTransactions={this.getAllTransactions}
            data={this.state.transactions}
            startPage={this.state.startPage}
            viewMore={this.getTransactionDetails}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            page={this.state.pageNum}
            pageSize={this.state.pageSize}
            // getTransactionByTransRef={this.getTransactionByTransRef}
            searchParameters={this.state.searchParameters}
            // getTransactionByResponseCode={this.getTransactionByResponseCode}
            responseCode={this.state.searchParameters.responseCode}
            // getTransactionsByDateRange={this.getTransactionsByDateRange}
            startDate={this.state.searchParameters.startDate}
            domainCode={this.state.searchParameters.domainCode}
            // getTransactionByDomainCode={this.getTransactionByDomainCode}
            canView={CAN_VIEW_TRANSACTION}
            requerySingleTransaction={this.requerySingleTransaction}
            getClickedAction={this.getClickedAction}
            getSelectedTransactions={this.getSelectedTransactions}
            requeryBulkTransactions={this.requeryBulkTransactions}
            resolveBulkTransactions={this.resolveBulkTransactions}
            desiredStatus={this.state.desiredStatus ? this.state.desiredStatus : 'default'}
            selectAllTransactions={this.selectAllTransactions}
            canLoadData={this.state.canLoadData}
            redirectToCommission={this.redirectToCommission}
          />
          <TransactionDetailsModal
            transactionDetails={this.state.transactionDetails}
            settlementDetails={this.state.settlement}
            isSettlementLoading={this.state.isSettlementLoading}
            transactionDetailsSectionRef={this.transactionDetailsSectionRef}
            reversalDetails={this.state.reversalDetails}
            isReversalDetailLoading={this.state.isReversalDetailLoading}
          />
          <PromptModal 
            modalTitle={this.state.actionType == 'resolve' ? `Transaction Resolution` : `Transaction Details`}
            modalBody={this.state.actionType == 'resolve' ? modalBody : <TransactionDetails transactionDetails={this.state.transactionDetails} parties={this.state.settlement.parties} />}
            modalFooterButton1={this.state.actionType == 'resolve' ? `Cancel` : `Close`}
            hideModalHeader={true}
            //hideModalFooter={this.state.hideModalFooter}
            modalSize={`modal-md`}
            height={this.state.actionType == 'resolve' ? '300px' : '690px'}
            modalFooterButton2={this.state.actionType == 'resolve' ? `Submit` : ''}
            deleteModal={this.resolveSingleTransaction}
            modalFooterButton2Disabled={!this.isFormValid()}
            // isLoading={this.state.isLoading}
            // canReload={this.state.canReload}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
