import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../components/pagination";
import SpinnerComponent from "../../../../../components/spinner";
import { getPaginationRows } from "../../../../../utils/helper";

export default class MetricTable extends React.Component {
  state = {
    tableHeight: "700px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  mapMetrics = { ResponseTime: "Response Time", 
  FulfilmentTime: "Fulfilment Time",
   FailToSuccessRatio: "Fail to Success Ratio" }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "Metric Type",
        accessor: "metricType",
        width: 200,
        Cell: (props) => (
            <span>{props.value ? this.mapMetrics[props.value] : ""}</span>
        ),
      },
      {
        Header: "Lower Bound",
        accessor: "lowerBound",
        width: 300,
        style: { "white-space": "unset" },
      },
      {
        Header: "Upper Bound",
        accessor: "upperBound",
        width: 200,
        style: { "white-space": "unset" },
      },
      {
        Header: "Weight",
        accessor: "weight",
        width: 200,
        style: { "white-space": "unset" },
      },

      {
        Header: "PMC Level",
        accessor: "pmcLevel",
        resizable: false,
        style: { "white-space": "unset" },
        width: 150,
      },
      {
        Header: "PMC User",
        accessor: "pmcUser",
        resizable: false,
        style: { "white-space": "unset" },
        width: 150,
      },

      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 200,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="modal"
              data-target="#scheduler_edit"
              onClick={() =>{
                console.log({original})
                this.props.setMetricsDetails(original)
              }
              }
            >
              <i class="fa fa-pencil-square-o"></i>
            </a>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          pageSizes={getPaginationRows(this.props.dataCount)}
          dataCount={this.props.dataCount}
          defaultSorted={this.props.defaultSorted}
          headerNodeHeight={`123`}
          showTotalCount={true}
          minRows={this.props.data.length > 0 ? 10 : 10}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          // getTableProps={(state, rowInfo, column, instance) => ({
          //   style: {
          //     borderColor: '#00425f',
          //     boxSizing: 'none',
          //     border: 'none',
          //   },
          // })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "2px",
              paddingRight: "2px",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
