import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  formatTransactionStatus,
  formatDateTime,
  formatTransactionStatusColorCode,
  formatCurrency,
} from "../../../../../../utils/formatter";
import { convertToMajor } from "../../../../../../utils/converter";
import {
  getPaginationRows,
  getTransactionTypeName,
  replaceUnderscoreWithSpace,
} from "../../../../../../utils/helper";
import SpinnerComponent from "../../../../../../components/spinner";
import Pagination from "../../../../../../components/pagination";
import TableExtra from "../../../../../../components/table-extra";
import { TransactionExportSerializer } from "../../../../../../serializers";
import ProtectedComponent from "../../../../../../mixins/protected-component";

export default class TransactionHistoryTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: (
          <input
            type="checkbox"
            onClick={(event) =>
              this.props.selectAllTransactions(event.target.checked)
            }
          />
        ),
        accessor: "transactionRef",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 25,
        headerStyle: { "white-space": "unset", textAlign: "center" },
        Cell: ({ row, original }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                checked={original.checked}
                onClick={(event) =>
                  this.props.getSelectedTransactions(
                    event.target.checked,
                    row.transactionRef
                  )
                }
                disabled={formatTransactionStatus(row.statusCode) !== "Pending"}
                style={{ verticalAlign: "middle" }}
              />
            </div>
          );
        },
      },
      // {
      //     Header: "S/N",
      //     accessor: 'id',
      //     Cell: (id) => <span>{id.index + 1}</span>,
      //     sortable: false,
      //     resizable: false,
      //     width: 35,
      //     headerStyle: {'white-space': 'unset'},
      // },
      {
        Header: "Trans Ref",
        accessor: "transactionRef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: ({ row, original }) => (
          <a
            href="#"
            className="selectable"
            style={{ cursor: "pointer" }}
            data-toggle="modal"
            data-target="#transaction_details_modal"
            onClick={() => this.props.getClickedAction(original, "view")}
          >
            {original.transactionRef}
          </a>
        ),
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: (props) => (
          <span class="word-capitalize">
            {props.value ? props.value.toLowerCase() : ""}
          </span>
        ),
      },
      {
        Header: "Trans Type",
        accessor: "transactionTypeInt",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 130,
        Cell: (props) => <span>{getTransactionTypeName(props.value)}</span>,
      },
      {
        Header: "Response Code",
        accessor: "responseCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 70,
        Cell: ({ row, original }) => (
          <span>{original.responseCode ? original.responseCode : ""}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "statusCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 80,
        Cell: (props) => {
          return (
            <span
              class={`badge ${formatTransactionStatusColorCode(props.value)}`}
            >
              {formatTransactionStatus(props.value)}
            </span>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "transactionAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 130,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      // {
      //   Header: "Available Balance",
      //   accessor: 'availableBalance',
      //   resizable: false,
      //   headerStyle: {'white-space': 'unset'},
      //   style: {'white-space': 'unset'},
      //   width: 130,
      //   Cell: props => props.value ? <span>&#x20A6;{formatCurrency(convertToMajor(props.value))}</span> : ''
      // },
      // {
      //   Header: "Ledger Balance",
      //   accessor: 'ledgerBalance',
      //   resizable: false,
      //   headerStyle: {'white-space': 'unset'},
      //   style: {'white-space': 'unset'},
      //   width: 130,
      //   Cell: props => props.value ? <span>&#x20A6;{formatCurrency(convertToMajor(props.value))}</span> : ''
      // },
      {
        Header: "Batch Credit/Debit Reference",
        accessor: "parentReference",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 174,
      },
      {
        Header: "Date",
        accessor: "dateCreated",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 160,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Action",
        accessor: "agentCode",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        //width: 70,
        // Cell: ({row, original}) => <ProtectedComponent requiredPermissions={[this.props.canView]}>
        //   <button type="button" class="btn btn-secondary btn-xs link-button" data-toggle="modal" data-target="#transaction_details_modal" onClick={() => this.props.getClickedAction(original, 'view')}>
        //     View
        //   </button>
        // </ProtectedComponent>
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#transaction_details_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
              {/* <ProtectedComponent requiredPermissions={[this.props.canView]}><a hidden={formatTransactionStatus(row.statusCode) != 'Successful'} onClick={() => this.props.redirectToCommission(original)} href="admin/home/commission-history" class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                      <i class="la la-eye"></i>
                      View Commission
                    </a></ProtectedComponent> */}
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={formatTransactionStatus(row.statusCode) !== "Pending"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(row.transactionRef, "resolve")
                  }
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-unlock-alt"></i>
                  Resolve
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={formatTransactionStatus(row.statusCode) !== "Pending"}
                  onClick={() =>
                    this.props.requerySingleTransaction(row.transactionRef)
                  }
                  /*data-toggle="modal" data-target="#kt_modal_1"*/ /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-refresh"></i>
                  Re-query
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={TransactionExportSerializer.reverseTransactionHistoryDetails(
            this.props.data
          )}
          headers={TransactionExportSerializer.headerExport()}
          fileName={"transaction_history"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getTransactions}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          requeryBulkTransactions={this.props.requeryBulkTransactions}
          //resolveBulkTransactions={this.props.resolveBulkTransactions}
          //showRepush={true}
          desiredStatus={this.props.desiredStatus}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              businessMobileNo,
              businessName,
              endDate,
              startDate,
              transactionStatus,
              transactionType,
              transRef,
              responseCode,
              parentReference,
            } = this.props.searchParameters;
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getTransactions(
                state.page + 1,
                state.pageSize,
                businessMobileNo,
                businessName,
                endDate,
                startDate,
                transactionStatus,
                transactionType,
                transRef,
                responseCode,
                parentReference
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "11.5px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
