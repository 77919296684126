import React from 'react'
import BaseScene from '../../../../../../components/scenes/component';
import ContentHead from '../../../../../../components/content-head';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import qs from 'querystring';
import UserForm from '../../../../../../components/forms/user-form'
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { userManagement } from '../../../../../../mixins/api'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_VIEW_PERMISSION } from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import PermissionForm from '../../components/permission-form'

export default class ViewPermissionScreen extends BaseScene {
  requiredPermissions = [
    CAN_VIEW_PERMISSION
  ]

  constructor (props) {
    super(props)

    this.state = {
      canSave: false,
      canUpdate: true,
      pageTitle: 'View Permission Details',
      disabled: true,
      permissionFormData: [],
      permissionDetails: [],
      permissionGroups: [],
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updatePermissionFormData = this.updatePermissionFormData.bind(this)
    this.getAllPermissionGroups = this.getAllPermissionGroups.bind(this)
  } 

  componentDidMount() {
    this.permissionId = this.props.match.params.id
    this.getPermissionDetails()
    this.getAllPermissionGroups()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAllPermissionGroups () {
    this.setState({isGetPermissionGroupLoading: true})
    const responseObj = await userManagement.getAllPermissionGroups()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissionGroups: response,
        isGetPermissionGroupLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        isGetPermissionGroupLoading: false,
        dataCount: 0,
        permissionGroups: [],
        //errorMessage: response
      })
    }
  }

  async getPermissionDetails () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.getPermissionById(this.permissionId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissionFormData: response,
        permissionDetails: response,
        isLoading: false,
      })
    }else{
      this.setState({
        permissionFormData: [],
        permissionDetails: [],
        errorMessage: response,
        isLoading: false,
      })
    }
  }

  async onUpdateButtonClick () {
    this.setState({
      pageTitle: 'Update Permission Details',
      canSave: true,
      canUpdate: false,
      disabled: false,
    })
  }

  async onSaveButtonClick () {
    this.setState({
      isLoading: true,
      disabled: true
    })
    const createUserResponseObj = await userManagement.updatePermission(this.permissionId, this.state.permissionFormData)
    const createUserResponseStatus = createUserResponseObj.status
    const createUserResponse = createUserResponseObj.response.data
    
    if (createUserResponseStatus === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: createUserResponse,
        disabled: false
      })

      return
    }

    this.setState({
      isLoading: false,
      successMessage: 'Permission details updated successfully.',
      canSave: false,
      canUpdate: true,
      pageTitle: 'View Permission Details',
      disabled: true,
    })

  }

  isFormValid () {
    return this.state.permissionDetails.name !== this.state.permissionFormData.name;
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  updatePermissionFormData (type, value) {
    this.setState({permissionFormData: {...this.state.permissionFormData, [type]: value}})
  }

  render () {
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Update</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION]}><button type="button" class="btn btn-primary" /*disabled={!this.isFormValid()}*/ style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_PERMISSION]}>      
        <GridLayout>
          <PermissionForm 
            onChange={this.updatePermissionFormData}
            permissionDetails={this.state.permissionFormData}
            permissionGroups={this.state.permissionGroups}
            disabled={this.state.disabled}
            isGetPermissionGroupLoading={this.state.isGetPermissionGroupLoading}
            getPermissionGroups={this.getAllPermissionGroups}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
