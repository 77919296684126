import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import BaseScene from "../../../../../../../components/scenes/component";
// import TransactionHistoryTable from '../../components/transaction-history-table';
import { AccessDenied } from "../../../../../../../components/access-denied";
import {
  SUCCESS_STATUS
} from "../../../../../../../constants/api";
import {
  CAN_VIEW_TRANSACTION
} from "../../../../../../../constants/permissions.js";
import {
  transactionHistory
} from "../../../../../../../mixins/api";
import {
  formatBackEndDateTime
} from "../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../utils/helper";
// import TransactionDetails from '../../components/transaction-details'
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
// import TransactionSearch from '../../components/transaction-search'
import Notification from "../../../../../../../components/notification";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
// import TransactionDetailsModal from '../../components/transaction-details-modal'
import {
  retrieveOtherInformation
} from "../../../../../../../mixins/authentication";
import WalletJournalSearch from "../../components/wallet-journal-search";
import WalletJournalTable from "../../components/wallet-journal-table";
import WalletLienJournalTable from "../../components/wallet-lien-journal-table";

export default class WalletJournalGridScene extends BaseScene {
  sceneName = "wallet-journal";

  transactionDetailsSectionRef = React.createRef(null);
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      walletJournal: [],
      searchParameters: {
        walletJournalType: 1,
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("endDate")
          : null,
      },
      agentDetails: [],
      tableMessage: "",
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      pages: 1,
      pageNum: 1,
      pageSize: 20,
    };

    this.getWalletJournal = this.getWalletJournal.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.downloadWalletJournalReport = this.downloadWalletJournalReport.bind(
      this
    );
  }

  componentDidMount() {
    this.domainCode = this.props.match.params.domainCode;
    if (this.domainCode != null) {
      this.updateSearchParameters("agentCode", this.domainCode);
      const retrievedAgentDetails = JSON.parse(
        retrieveOtherInformation("agentDetails")
      );

      if (retrievedAgentDetails.agentCode) {
        this.updateSearchParameters(
          "agentCode",
          retrievedAgentDetails.agentCode
        );
        this.setState({ agentDetails: retrievedAgentDetails });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getWalletJournal(pageNum, pageSize) {
    const {
      walletJournalType,
      agentCode,
      startDate,
      endDate,
    } = this.state.searchParameters;

    this.setState({
      canLoadData: true,
    });

    const retrievedAgentDetails = JSON.parse(
      retrieveOtherInformation("agentDetails")
    );

    if (Boolean(retrievedAgentDetails) && retrievedAgentDetails.agentCode) {
      this.updateSearchParameters("agentCode", retrievedAgentDetails.agentCode);
      this.setState({ agentDetails: retrievedAgentDetails });
    }

    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate);
    this.setState({
      pageNum,
      pageSize,
      isLoading: true,
    });

    const sortDesc = true;
    const agentMobileNo = retrievedAgentDetails.agentMobileNo;

    const { status, response, count } =
      walletJournalType === 3
        ? await transactionHistory.getWalletLienJournal(
            pageNum,
            pageSize,
            walletJournalType,
            agentCode
              ? agentCode
              : retrievedAgentDetails && retrievedAgentDetails.agentCode,
            formattedStartDate,
            formattedEndDate,
            sortDesc,
            agentMobileNo
          )
        : await transactionHistory.getWalletJournal(
            pageNum,
            pageSize,
            walletJournalType,
            agentCode
              ? agentCode
              : retrievedAgentDetails && retrievedAgentDetails.agentMobileNo,
            formattedStartDate,
            formattedEndDate
          );

    //  alert(JSON.stringify(response))
    if (status === SUCCESS_STATUS) {
      this.setState({
        walletJournal: count > 0 ? response : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count,
        isLoading: false,
        tableMessage:
          count == 0 ? "No record found for statement of account" : "",
      });
    } else {
      this.setState({
        walletJournal: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No record found for statement of account",
      });
    }
  }

  handleSearchRequest() {
    const {
      walletJournalType,
      agentCode,
      startDate,
      endDate,
    } = this.state.searchParameters;

   

      
     if(walletJournalType === 3)
       {  
         
          this.setState({
            isWalletLienJournal : true
          }) 
          
       }
       else {
             this.setState({isWalletLienJournal:false})
       }

       this.getWalletJournal(this.state.pageNum, this.state.pageSize, walletJournalType, agentCode, startDate, endDate)

  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(transaction, actionType) {
    if (actionType == "view") {
      //this.getTransactionDetails(transaction)
      this.getSettlementByRef(transaction.transactionRef);
      this.setState({
        transactionDetails: transaction,
        settlement: {
          parties: [],
        },
      });
    }
    this.setState({
      transRef: transaction.transRef,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType == "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  async downloadWalletJournalReport() {
    const dataCount = this.state.dataCount;
    const pageNum = 1;

    const {
      walletJournalType,
      agentCode,
      startDate,
      endDate,
    } = this.state.searchParameters;

    this.setState({
      canLoadData: true,
      isLoading: true,
    });

    const retrievedAgentDetails = JSON.parse(
      retrieveOtherInformation("agentDetails")
    );

    if (retrievedAgentDetails.agentCode) {
      this.updateSearchParameters("agentCode", retrievedAgentDetails.agentCode);
      this.setState({ agentDetails: retrievedAgentDetails });
    }

    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate);

    const { status, response } = await transactionHistory.downloadWalletJournal(
      formattedStartDate,
      formattedEndDate,
      walletJournalType,
      dataCount,
      pageNum,
      agentCode ? agentCode : retrievedAgentDetails.agentCode
    );

    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "Statement of Account Report.xls");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ContentHead
          title={"Statement of Account"}
          subtitle={`${
            Boolean(this.state.agentDetails.businessName)
              ? this.state.agentDetails.businessName
              : ""
          } Statement of Account`}
          filterPermissions={[CAN_VIEW_TRANSACTION]}
          totalCount={56}
        />
        <Notification
          ref={this.notificationSectionRef}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <WalletJournalSearch
              searchParameters={this.state.searchParameters}
              updateSearchParameters={this.updateSearchParameters}
              handleSearchRequest={this.handleSearchRequest}
            />

            {this.state.isWalletLienJournal === true ? (
              <WalletLienJournalTable
                pages={this.state.pages}
                getWalletJournal={this.getWalletJournal}
                data={this.state.walletJournal}
                startPage={this.state.startPage}
                //viewMore={this.getTransactionDetails}
                loading={this.state.isLoading}
                noDataText={this.state.tableMessage}
                dataCount={this.state.dataCount}
                page={this.state.pageNum}
                pageSize={this.state.pageSize}
                searchParameters={this.state.searchParameters}
                canView={CAN_VIEW_TRANSACTION}
                domainCode={this.domainCode}
                agentBusinessName={this.state.agentDetails.businessName}
                canLoadData={this.state.canLoadData}
                downloadWalletJournal={null}
              />
            ) : (
              <WalletJournalTable
                pages={this.state.pages}
                getWalletJournal={this.getWalletJournal}
                data={this.state.walletJournal}
                startPage={this.state.startPage}
                //viewMore={this.getTransactionDetails}
                loading={this.state.isLoading}
                noDataText={this.state.tableMessage}
                dataCount={this.state.dataCount}
                page={this.state.pageNum}
                pageSize={this.state.pageSize}
                searchParameters={this.state.searchParameters}
                canView={CAN_VIEW_TRANSACTION}
                domainCode={this.domainCode}
                agentBusinessName={this.state.agentDetails.businessName}
                canLoadData={this.state.canLoadData}
                downloadWalletJournal={this.downloadWalletJournalReport}
              />
            )}
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
