import React from 'react'
import { formatCurrency } from '../../utils/formatter'
import { hideText } from '../../utils/helper'
import { convertToMajor } from '../../utils/converter'

export const BalanceCard = (props) => {
    const {
        title, isBalance, isPostCardAccount, showBalance, showPostCardAccount, balance, 
        showType, updateSetState, postCardAccount 
    } = props;
    return <>
        <div class="col-sm-4">
            <div class="card bg-light">
                <div class="card-body">
                    <h5 class="card-title">{title}</h5>
                    <h3 class="card-text">
                        {isBalance && <span>&#x20A6;{showBalance ? formatCurrency(convertToMajor(balance)) : hideText(convertToMajor(balance, true))}</span>}
                        {isPostCardAccount && <span>{showPostCardAccount ? postCardAccount : hideText(postCardAccount, false)}</span>}
                        <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                                {isBalance && <i onClick={() => updateSetState(showType)} class={showBalance ? `fa fa-eye-slash fa-sm` : `fa fa-eye fa-sm`}></i>}
                                {isPostCardAccount && <i onClick={() => updateSetState(showType)} class={showPostCardAccount ? `fa fa-eye-slash fa-sm` : `fa fa-eye fa-sm`}></i>}
                            </span>
                        </span>
                    </h3>
                </div>
            </div>
        </div>
    </>
}