import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_ADD_WORKFLOW_CONFIGURATION_STEP, CAN_MOVE_WORKFLOW_CONFIGURATION_STEP, CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP, CAN_VIEW_WORKFLOW_CONFIGURATION } from '../../../../../../../constants/permissions.js';
import { workFlowManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import WorkflowSearch from '../../components/workflow-search';
import WorkflowTable from '../../components/workflow-table';

export default class WorkflowConfigurationGridScene extends BaseScene {

    sceneName = 'configuration'

    state = {
        isLoading: false,
        workflows: [],
        searchParameters: [],
        canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
        workflowModules: []
    }

    updateWorkflow = this.updateWorkflow.bind(this)
    getAllWorkflow = this.getAllWorkflow.bind(this)
    handleSearchRequest = this.handleSearchRequest.bind(this)
    getClickedAction = this.getClickedAction.bind(this)
    
    componentDidMount() {
        this.getWorkflowModules()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async updateWorkflow () {
        this.setState({isLoading: true})
        const responseObj = await workFlowManagement.updateWorkflowStatus(this.state.workflowId, this.state.workflowStatus)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                workflowId: null,
                workflowStatus: null,
                successMessage: `Workflow status updated successfully`,
                isLoading: false,
            })
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false, 
                workflowId: null,
                workflowStatus: null,
            })
        }
        this.getAllWorkflow()      
    }

    async getAllWorkflow (pageNum, pageSize) {
        this.setState({isLoading: true})
        const responseObj = await workFlowManagement.queryWorkflow(pageNum, pageSize, this.state.searchParameters)
        const { status, response, count } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                workflows: response.items,
                dataCount: response.length,
                pages: Math.ceil(count/pageSize),
                pageSize: pageSize,
                page: pageNum,
                startPage: null,
                isLoading: false,
                tableMessage: count === 0 ? "No workflow configuration found" : ""
              })
        }else{
            this.setState({
                approvals: [],
                dataCount: 0,
                isLoading: false,
                pages: Math.ceil(count/pageSize),
                pageSize: pageSize,
                page: pageNum,
                errorMessage: response,
                tableMessage: "No workflow configuration found"
            })
        }
    }

    async getWorkflowModules () {
        this.setState({isLoading: true})
        const responseObj = await workFlowManagement.getWorkflowModules()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                workflowModules: this.includeDefaultValue(response),
                isLoading: false,
              })
        }else{
            this.setState({
                workflowModules: [],
                isLoading: false
            })
        }
    }

    includeDefaultValue (array) {
        let newArray = ['All'];
        array.map(detail => newArray.push(detail));
        return newArray
    }

    getClickedAction (id, status) {
        this.setState({
          workflowId: id,
          workflowStatus: status
        })
    }

    handleSearchRequest (searchParameters) {
        this.setState({
            pageIndex: 1,
            startPage: 1,
            searchParameters
        })
        setTimeout(() =>{
            this.getAllWorkflow(1, 20)
        })
    }

    render() {
        return <React.Fragment>
            <Notification 
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"WorkFlow Management"} subtitle="Configuration" />
            <ProtectedComponent 
                permissionDeniedContent={this.permissionDeniedContent} 
                requiredPermissions={[
                    CAN_VIEW_WORKFLOW_CONFIGURATION, CAN_ADD_WORKFLOW_CONFIGURATION_STEP, 
                    CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP, CAN_MOVE_WORKFLOW_CONFIGURATION_STEP
                ]}
            >
                <GridLayout>
                    <WorkflowSearch 
                        handleSearchRequest={this.handleSearchRequest}
                        workflowModules={this.state.workflowModules}
                    />
                    <WorkflowTable 
                        pages={this.state.pages}
                        data={this.state.workflows} 
                        loading={this.state.isLoading}
                        noDataText={"No workflow configuration found"}
                        getAllWorkflow={this.getAllWorkflow}
                        dataCount={this.state.dataCount}
                        pageSize={this.state.pageSize}
                        page={this.state.page}
                        canLoadData={this.state.canLoadData}
                        getClickedAction={this.getClickedAction}
                    />
                    {/* <Table 
                        data={this.state.workflows} 
                        columns={this.columns} 
                        loading={this.state.isLoading} 
                        noDataText={`No workflow configuration found`}
                        dataCount={this.state.dataCount}
                        getData={this.getAllWorkflow}
                        exportData={WorkflowConfigurationsExportSerializer.reverseWorkflowConfigurationDetails(this.state.workflows)}
                        exportHeaders={WorkflowConfigurationsExportSerializer.headerExport()}
                        exportFileName={'workflow-configurations'}
                    /> */}
                    <PromptModal
                        modalTitle={`${this.state.workflowStatus} Workflow`}
                        modalBody={`Do you want to ${this.state.workflowStatus} this workflow?`}
                        deleteModal={this.updateWorkflow}
                        modalFooterButton1={`Cancel`}
                        modalFooterButton2={`Ok`}
                        hideModalHeader={true}
                        hideModalFooter={true}
                        height={'250px'}
                    />
                </GridLayout>
            </ProtectedComponent>
        </React.Fragment>
    }
    get permissionDeniedContent() {
        return <AccessDenied />
    }
}
