import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../components/pagination';
import SpinnerComponent from '../../../../../components/spinner';
import TableExtra from '../../../../../components/table-extra';
import { formatDateTime } from '../../../../../utils/formatter';
import { getPaginationRows } from '../../../../../utils/helper';

export default class MessagingHistoryTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '950px',
        zoom: 'plus'
    }

    zoomTableHeight = this.zoomTableHeight.bind(this)

    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }

    render() {
        const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            {
                Header: "Date",
                accessor: 'created',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 170,
                Cell: props => props.value ? formatDateTime(props.value) : ''
            },{
                Header: "Recipient",
                accessor: 'to',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 170,
                Cell: ({row, original}) => <div>
                    <span class="recipient-details" title={original.recipientName}>{original.recipientName}</span>
                    <small class="recipient-details" title={original.to}>{original.to}</small>
                </div>
            },{
                Header: "Sender",
                accessor: 'to',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 170,
                Cell: ({row, original}) => <div>
                    <span class="recipient-details" title={original.userFullName}>{original.userFullName}</span>
                    <small class="recipient-details" title={original.userId}>{original.userId}</small>
                </div>
            },{
                Header: "Channel",
                accessor: 'channel',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 100,
                Cell: props => props.value
            },{
                Header: "Status",
                accessor: 'status',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 170,
                Cell: ({row, original}) => <div>  
                    <span style={{color: '#ffffff'}} class={`kt-badge ${original.status.toLowerCase() === "delivered" ? 'kt-badge--success' : original.status.toLowerCase() === "pending" ? 'kt-badge--warning' : original.status.toLowerCase() === "failed" ? 'kt-badge--danger' : original.status.toLowerCase() === "sent" ? 'kt-badge--info' : 'kt-badge--primary'} kt-badge--inline kt-badge--pill`} title={original.error}>{original.status}</span><br/>
                    <small>{original.updated ? formatDateTime(original.updated) : ''}</small>
                </div>
            },{
                Header: "Message",
                accessor: 'subject',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: ({row, original}) => <div>
                    <span><strong>Subject:</strong>&nbsp;{original.subject}</span><br/><br/>
                    <span class="message-body" title={original.body}><strong>Body:</strong>&nbsp;{original.body}</span>
                </div>
            }
        ]

        return <React.Fragment>
            <TableExtra 
                //data={ApplicationExportSerializer.reverseApplicationDetails(this.props.data)}
                //headers={ApplicationExportSerializer.headerExport()}
               //fileName={'applications'}
                zoomTableHeight={this.zoomTableHeight}
                getData={this.props.getMessagingHistory}
                page={this.props.page}
                pageSize={this.props.pageSize}
                zoom={this.state.zoom}
                disabled={this.props.loading}
            />
            <ReactTable 
                PaginationComponent={Pagination}
                loading={this.props.loading}
                LoadingComponent={MyLoader}
                style={{
                    border: "none",
                    boxShadow: "none",
                    whiteSpace: 'unset',
                    height: this.state.tableHeight,
                }}
                data={this.props.data}
                columns={columns}
                dataCount={this.props.dataCount}
                noDataText={!this.props.loading ? this.props.noDataText : ''}
                pageSizes={getPaginationRows(this.props.dataCount)}
                headerNodeHeight={`123`}
                manual
                pages={this.props.pages}
                page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
                minRows={(this.props.data).length > 0 ? 10 : 10}
                onFetchData={(state, instance) => {
                    this.setState({page: state.page})
                    if(this.props.canLoadData) {
                        this.props.getMessagingHistory(state.page + 1,state.pageSize)
                    } 
                }}
                getTableProps={(state, rowInfo, column, instance) => ({
                    style: {
                        borderColor: '#00425f',
                        boxSizing: 'none',
                        border: 'none',
                        fontSize: '11.5px',
                    },
                })}
                // getTdProps={(state, rowInfo, column, instance) => ({
                //     style: {
                //       overflow: 'visible',
                //       //backgroundColor: '#FFFFFF',
                //       lineHeight: '20px',
                //     },
                // })}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    style: {
                        backgroundColor: '#00425f',
                        borderBottom: '0.1px solid #DBDEDE',
                        borderWidth: '0.1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        color: '#FFFFFF',
                        fontSize: '12px',
                        fontWeight: '200',
                        textAlign: 'left'
                    },
                })}
                getPaginationProps={(state, rowInfo, column, instance) => ({
                    style: {
                        overflow: 'visible',
                        backgroundColor: '#ffffff',
                    },
                })}    
                className="-striped -highlight" 
            />
        </React.Fragment>
          
    }
}