import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import ServiceTypes from '../../../../../../fixtures/service_types';
import { getDateByType, getTransactionTypeInt, getTransactionTypeName } from '../../../../../../utils/helper';

export default class SettlementSearch extends React.Component {
    state = {
        searchParameters: {
            startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
            endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
            transactionType: "",
            transactionSettlementStatus: ""
        },
        statuses: [
            {value: "", "label": "All"},
            {value: "Settled", "label": "Settled"},
            {value: "Unsettled", "label": "Unsettled"}
        ]
        
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest(this.state.searchParameters)
    }

    render() {
        return <React.Fragment>
            <div class="search-params">
                <form onSubmit={this.handleSearchRequest}>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label class="search-label">Transaction Reference:</label>
                            <input type="text" placeholder="Tran Ref...." class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, transactionRef: event.target.value}})} />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Agent Id</label>
                            <input type="text" placeholder="Agent id...." class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, agentId: event.target.value}})} />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Transaction Type</label>
                            <Select 
                                options={ServiceTypes}
                                onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, transactionType: getTransactionTypeName(event.value, "name")}})}
                                defaultValue={getTransactionTypeInt(this.state.searchParameters.transactionType)}
                            />
                            {/* <select class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, transactionType: event.target.value}})} >
                                <option value={null}>All</option>
                                {ServiceTypes.map(value=><option value={value.displayName}>{value.name}</option>)}
                            </select> */}
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Status</label>
                            <Select
                                options={this.state.statuses}
                                onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, transactionSettlementStatus: event.value}})}
                                defaultValue={this.state.searchParameters.transactionSettlementStatus}
                            />
                            {/* <select class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, transactionSettlementStatus: event.target.value}})} >
                                <option value={null}>All</option>
                                <option value="Settled">Settled</option>
                                <option value="Unsettled">Unsettled</option>
                            </select> */}
                        </div> 
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label class="search-label">Date From</label>
                            <DatePickerComponent
                                onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, startDate: event}})}
                                size={`150px`}
                                format={`dd/MM/yyyy h:mm aa`}
                                isClearable={true}
                                showTimeInput={true}
                                style={"0.4rem"}
                                textStyle={"1.0rem"}
                                iconStyle={"0.2rem"}
                                dateType={"startDate"}
                                preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                            />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Date To</label>
                            <DatePickerComponent
                                onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, endDate: event}})}
                                size={`150px`}
                                format={`dd/MM/yyyy h:mm aa`}
                                isClearable={true}
                                showTimeInput={true}
                                disabled={this.state.searchParameters.startDate ? false : true}
                                minDate={new Date(this.state.searchParameters.startDate)}
                                style={"0.4rem"}
                                textStyle={"1.0rem"}
                                iconStyle={"0.2rem"}
                                dateType={"endDate"}
                                preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                            />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">&nbsp;&nbsp;</label>
                            <button type="submit" class="search-button">Search</button>
                        </div>
                    </div>

                    <div class='form-group row'>
                        
                    </div>
                </form>
                {/* <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="btn-group ml-12" role="group" aria-label="First group">
                        {this.props.showSettlementRepush && <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter agent id / days" onChange={event => this.props.updateSetState('backlogDaysToRetry', event.target.value)} />
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1" style={{cursor: 'pointer', 'backgroundColor': '#00425f', 'borderColor':'#00425f', 'color': '#ffffff'}} onClick={() => this.props.requeryAllPendingSettlement()}>Re-push</span>
                            </div>
                        </div>}
                    </div>
                </div> */}
            </div>
        </React.Fragment>
    }
}