import React from "react";
import { convertToMajor } from "../../../../../../../../utils/converter";

export default class RemapPosRequestCancelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      cancelReason: "",
    };
  }

  render() {
    // console.log("Remap Request Modal ", this.props.posRequestDetails);

    const resul = this.props.posRequestDetails
      ? this.props.posRequestDetails
      : "";

    return (
      <React.Fragment>
        <div
          class="modal"
          id="cancel_remap_request_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-md"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 style={{ color: "tomato" }} class="kt-portlet__head-title">
                  <strong> Cancel Request</strong>
                </h3>
              </div>

              <div class="kt-portlet">
                <div className="p-4">
                  <h3
                    class="modal-title"
                    style={{ fontSize: "18px", fontWeight: 700 }}
                  >
                    Request details
                  </h3>
                  <br />
                  <div class="form-group row ">
                    <div class="col-lg-6">
                      <label>
                        <strong>Next Terminal Owner</strong>
                      </label>
                      <br />
                      <span>
                        {resul ? resul.nextTerminalOwner : "loading..."}
                      </span>
                      <br />
                    </div>
                    <div class="col-lg-6">
                      <label>
                        <strong>Payment Status</strong>
                      </label>
                      <br />
                      <span>{resul ? resul.paymentStatus : "loading..."}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>
                        <strong>Initiator Username</strong>
                      </label>
                      <br />
                      <span>
                        {resul ? resul.remapInitiatorUsername : "loading..."}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <label>
                        <strong>Request type</strong>
                      </label>
                      <br />
                      <span>
                        {resul ? resul.remapRequestType : "loading..."}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>
                        <strong>Request Status</strong>
                      </label>
                      <br />
                      <span>{resul ? resul.requestStatus : "loading..."}</span>
                      <br />
                    </div>
                    <div class="col-lg-6">
                      <label>
                        <strong>Terminal Model</strong>
                      </label>
                      <br />
                      <span>{resul ? resul.terminalModel : "loading..."}</span>
                      <br />
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>
                        <strong>Unit Price</strong>
                      </label>
                      <br />
                      <span>
                        {resul ? convertToMajor(resul.unitPrice) : "loading..."}
                      </span>
                      <br />
                    </div>
                  </div>
                  {/* <div class="col-md-12"> */}
                  <textarea
                    class="form-control"
                    style={{
                      resize: "none",
                      marginTop: "15px",
                    }}
                    value={this.state.cancelReason}
                    placeholder="Enter reasons for cancellation"
                    name="txtname"
                    rows="4"
                    cols="50"
                    maxlength="200"
                    onChange={(event) =>
                      this.setState({
                        cancelReason: event.target.value,
                      })
                    }
                  />
                  {/* </div> */}
                </div>
                <div
                  style={{
                    float: "right",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",

                    margin: "10px 20px 10px 20px",
                  }}
                >
                  {/* {this.props.posRequestDetails === "awaiting_delivery" &&  */}
                  <button
                    type="button"
                    class="btn col-lg-4"
                    style={{
                      backgroundColor: "#004257",
                      color: "white",
                      height: "40px",
                      borderRadius: "15px",
                    }}
                    disabled={!this.state.cancelReason}
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#kt_modal_2"
                    onClick={async () => {
                      await this.props.cancelRemapRequestsAwaitingDelivery(
                        this.state.cancelReason
                      );

                      this.setState({
                        cancelReason: "",
                      });
                    }}
                  >
                    Cancel Remap
                  </button>
                  {/* } */}

                  <button
                    type="button"
                    // class="btn btn-default"
                    // class="btn col-lg-4"
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      border: "2px solid #00425f",
                      backgroundColor: "#ffffff",
                      color: "#00425f",
                      fontSize: "14px",
                      //   margin: "auto" ,
                      //   display: "block",
                    }}
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
