import React from 'react';
import { Link } from 'react-router-dom';
import { AccessDenied } from '../../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../../components/loader';
import Notification from '../../../../../../../../components/notification';
import Table from '../../../../../../../../components/table';
import { SUCCESS_STATUS } from '../../../../../../../../constants/api';
import {
    CAN_CREATE_AGENT_ROLE, CAN_CREATE_AGGREGATOR_ROLE, CAN_CREATE_SP_ROLE,
    CAN_VIEW_AGENT_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_SP_ROLE
} from '../../../../../../../../constants/permissions';
import { platformManagement } from '../../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../../mixins/protected-component';
import { RolesExportSerializer } from '../../../../../../../../serializers';
import DomainGridLayout from '../../../../components/domain-grid-layout';

export default class DomainRoleGridScene extends React.Component {
    columns = [
        {
            Header: "S/N",
            accessor: 'id',
            Cell: (id) => <span>{id.index + 1}</span>,
            sortable: false,
            resizable: false,
            width: 60,
            headerStyle: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: 'center',
                        whiteSpace: 'unset',
                    },
                };
            },
        },{
            Header: "Name",
            accessor: 'name',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
        },{
            Header: "Description",
            accessor: 'description',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
        },{
            Header: "Action",
            accessor: 'id',
            overflow: 'visible',
            resizable: false,
            sortable: false,
            headerStyle: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        lineHeight: '20px',
                        textAlign: 'center',
                        whiteSpace: 'unset',
                    },
                };
            },
            width: 60,
            Cell: props => <div class="dropdown">
                <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                    <i class="la la-ellipsis-v"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_SP_ROLE]}><Link to={`roles/view?id=${props.value}`} class="dropdown-item">
                        <i class="la la-eye"></i>
                        View 
                    </Link></ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[CAN_CREATE_AGENT_ROLE, CAN_CREATE_AGGREGATOR_ROLE, CAN_CREATE_SP_ROLE]}><Link to={`roles/add?roleId=${props.value}`} class="dropdown-item">
                        <i class="la la-copy"></i>
                        Create a copy
                    </Link></ProtectedComponent>
                </div>
            </div>
        }
    ]
    
    constructor(props) {
        super(props);
        this.state = {
            domainName: null,
            allRoles: [],
        }
    }

    componentDidMount () {
        this.domainCode = this.props.match.params.domainCode
        const { status, response } = platformManagement.getAgentByAgentCode(this.domainCode)
        this.loadApplication()
        this.getDomainRoles()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async loadApplication () {
        let application = undefined
        const responseObj = await platformManagement.getAgentByAgentCode(this.domainCode)
        const { status, response }  = responseObj
        this.setState({domainName: response.businessName})
    }

    async getDomainRoles () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getRoles(this.domainCode)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                allRoles: response,
                dataCount: response.length,
                isLoading: false,
            })
        }else{
            this.setState({
                allRoles: [],
                dataCount: 0,
                isLoading: false,
                errorMessage: response
            })
        }
    }

    render() {
        return <React.Fragment>
            <Loader 
                isLoading={this.state.isLoading}
            />
            <Notification 
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_SP_ROLE]} permissionDeniedContent={this.permissionDeniedContent}>
                <GridLayout>
                    <DomainGridLayout title={'Role Management'} urlLinkPermission={[CAN_CREATE_AGENT_ROLE, CAN_CREATE_AGGREGATOR_ROLE, CAN_CREATE_SP_ROLE]} url={`/home/domain-management/${this.domainCode}/roles/add`} urlName={"Add Role"}>
                        {/* <Table 
                            columns={this.columns}
                            data={this.state.allRoles} 
                            loading={this.state.isLoading} 
                            noDataText={`No roles found`}
                            dataCount={this.state.dataCount}
                            disabled={true}
                        /> */}
                        <Table 
                            columns={this.columns}
                            data={this.state.allRoles} 
                            loading={this.state.isLoading} 
                            noDataText={`No roles found`}
                            dataCount={this.state.dataCount}
                            getData={this.getDomainRoles}
                            exportData={RolesExportSerializer.reverseRoleDetails(this.state.allRoles)}
                            exportHeaders={RolesExportSerializer.headerExport()}
                            exportFileName={'roles'}
                        />
                    </DomainGridLayout>
                </GridLayout>
            </ProtectedComponent>
        </React.Fragment>
    }
    get permissionDeniedContent() {
        return <AccessDenied /> 
    }
}