export default class PermissionsExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'shortDescription', title: 'Name', dataIndex: 'shortDescription'},
            {label: 'Description', key: 'description', title: 'Description', dataIndex: 'description'}
        ]
    }

    static reversePermissionDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { shortDescription, description } = details
            let detailFormData = {
                shortDescription,
                description
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}