import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../constants/api";
import {
  CAN_ACTIVATE_SERVICE_TYPE, CAN_DEACTIVATE_SERVICE_TYPE, CAN_DELETE_SERVICE_TYPE, CAN_VIEW_SERVICE_TYPE
} from "../../../../../../../constants/permissions.js";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  getFormattedStatus, pastTenseFormatter
} from "../../../../../../../utils/formatter";
import ServiceTypesTable from "../../components/service-types-table";

export default class ServiceTypesGridScene extends BaseScene {
  sceneName = "service-types-management";

  state = {
    serviceTypes: [],
    isLoading: true,
  };

  getServiceTypes = this.getServiceTypes.bind(this);
  updateStatus = this.updateStatus.bind(this);
  getClickedAction = this.getClickedAction.bind(this);

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async updateStatus() {
    this.setState({ isLoading: true });
    let responseObj = {};
    let approvalMessage = "";
    let message = "";
    if (this.state.serviceTypeStatus === "delete") {
      responseObj = await feeConfigurationManagement.removeServiceType(
        this.state.serviceTypeId
      );
      approvalMessage = `Your request to delete ${this.state.serviceTypeName} as a service type has been sent successfully for approval.`;
      message = `${
        this.state.serviceTypeName
      } was successfully ${pastTenseFormatter(
        this.state.serviceTypeStatus
      )} as a service type.`;
    } else {
      responseObj = await feeConfigurationManagement.updateServiceTypeStatus(
        this.state.serviceTypeId,
        this.state.serviceTypeStatus
      );
      approvalMessage = `Your request to ${getFormattedStatus(
        this.state.serviceTypeStatus
      )} ${
        this.state.serviceTypeName
      } as a service type has been sent successfully for approval.`;
      message = `${
        this.state.serviceTypeName
      } was successfully ${pastTenseFormatter(
        getFormattedStatus(this.state.serviceTypeStatus)
      )} as a service type.`;
    }
    const { status, response,  } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: approvalMessage,
        });
        return;
      } else {
        this.setState({
          successMessage: message,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getServiceTypes();
    setTimeout(() => {
      this.setState({
        serviceTypeId: null,
        serviceTypeStatus: null,
        serviceTypeName: null,
      });
    }, 3000);
  }

  async getServiceTypes(pageNum, pageSize) {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceTypes(
      pageNum,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceTypes: response ? response.data : [],
        dataCount: count,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      });
    } else {
      this.setState({
        serviceTypes: [],
        exportServiceTypes: [],
        dataCount: 0,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  getClickedAction(status, id, name) {
    this.setState({
      serviceTypeId: id,
      serviceTypeStatus: status,
      serviceTypeName: name,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Service Types"}
          filterPermissions={[]}
          /*actionButtonText={"Add Service Type"} actionButtonUrl={"/home/service-types-management/add"}*/ totalCount={
            56
          }
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_SERVICE_TYPE]}
        >
          <GridLayout>
            <ServiceTypesTable
              getServiceTypes={this.getServiceTypes}
              pages={this.state.pages}
              data={this.state.serviceTypes}
              updateStatus={this.getClickedAction}
              loading={this.state.isLoading}
              noDataText={`No service types found`}
              dataCount={this.state.dataCount}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_SERVICE_TYPE}
              canDeactivate={CAN_DEACTIVATE_SERVICE_TYPE}
              canActivate={CAN_ACTIVATE_SERVICE_TYPE}
              canDelete={CAN_DELETE_SERVICE_TYPE}
            />
            <PromptModal
              modalTitle={this.state.serviceTypeStatus + ` Service Type`}
              modalBody={`Do you want to ${this.state.serviceTypeStatus} ${this.state.serviceTypeName} from service types?`}
              deleteModal={this.updateStatus}
              modalSize={`modal-md`}
              height={`230px`}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
