import React from "react";
import { convertToMajor } from "../../../../../../utils/converter";
import {
  formatCurrency
} from "../../../../../../utils/formatter";
export default class CashoutResponseModal extends React.Component {
  
  render() {
    return (
      <React.Fragment>
        <div
          class="modal"
          id="transaction_details_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Requery Response
                </h3>
              </div>
                {this.props.transactionDetails && this.props.transactionDetails.map((transactionDetail, index)=>(
              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  data-toggle="collapse"
                  data-target={`#collapseTransDetails${index}`}
                  aria-expanded="true"
                  aria-controls={`collapseTransDetails${index}`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label>
                        <strong>Transaction Ref: </strong>{transactionDetail.transactionRef}
                      </label>
                    </h3>
                  </div>
                </div>
              
                <form
                  class="kt-form kt-form--label-right"
                  id={`collapseTransDetails${index}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <label>
                            <strong>Amount</strong>
                          </label>
                          <br />
                          <span>
                            &#x20A6;
                            {formatCurrency(
                              transactionDetail.responseAmount
                                ? convertToMajor(
                                    transactionDetail
                                      .responseAmount
                                  )
                                : 0
                            )}
                          </span>
                        </div>
                        <div class="col-sm-4">
                          <label>
                            <strong>RRN</strong>
                          </label>
                          <br />
                          <span>
                            { transactionDetail.responseRRN }
                          </span>
                        </div>
                        <div class="col-sm-4">
                          <label>
                            <strong>Stan</strong>
                          </label>
                          <br />
                          <span>
                            {transactionDetail.responseStan}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <label>
                            <strong>Account Number</strong>
                          </label>
                          <br />
                          <span>
                            { transactionDetail.fromAccount }
                          </span>
                        </div>
                        <div class="col-sm-4">
                          <label>
                            <strong>Masked Pan</strong>
                          </label>
                          <br />
                          <span>
                            { transactionDetail.maskedPan }
                          </span>
                        </div>
                        <div class="col-sm-4">
                          <label>
                            <strong>Response Code</strong>
                          </label>
                          <br />
                          <span>{transactionDetail.responseCode}</span>
                        </div>
                        
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <br />
                          <span>
                            {transactionDetail.requeryStatus
                                ? "REQUERIED"
                                : "NOT REQUERIED"
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                
              </div>
              ))}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
