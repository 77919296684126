import React from "react";
// import ContentHead from "../../../../../../../../components/content-head";
import { AccessDenied } from "../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE,
} from "../../../../../../constants/permissions.js";
// import { userManagement } from "../../../../../../../../mixins/api";
import { userManagement } from "../../../../../../mixins/api";
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../mixins/protected-component";

import Loader from "../../../../../../components/loader";
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from "../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../utils/helper";
import AllocatePosAllSearch from "./components/allocate-pos-all-search";
import AllocatePosAllTable from "./components/allocate-pos-all-table";

export default class AllocateAllGridScene extends BaseScene {
  sceneName = "remap-pos-all";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      // services: [{ id: 0, name: "Any" }],
      // serviceTypes: [{ id: 0, name: "Any" }],
      remapStatuses: [
        { value: "", label: "Select..." },
        { value: "approved", label: "Approved" },
        { value: "declined", label: "Decline" },
        { value: "closed", label: "Closed" },
        { value: "cancelled", label: "Cancelled" },
        { value: "awaiting_approval", label: "Awaiting Approval" },
        { value: "awaiting_delivery", label: "Awaiting Delivery" },
      ],
      requests: [],
      posAllocationDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        pageNum: 1,
        pageSize: 20,
        batchId: "",
      },

      tableMessage: "",
      configured: true,
      showConfirm: false,
      cancelReason: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
    };

    this.getAllPosRequestConfurationRequests = this.getAllPosRequestConfurationRequests.bind(
      this
    );
    this.getPosRequestConfigurationByBatch = this.getPosRequestConfigurationByBatch.bind(
      this
    );
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    // this.getClickedAction = this.getClickedAction.bind(this);
    // this.approveRemapRequestsByRequestid = this.cancelRemapRequestsByRequestid.bind(
    //   this
    // );
    // this.cancelRemapRequestsByRequestid = this.cancelRemapRequestsByRequestid.bind(
    //   this
    // );
    this.confirmCancel = this.confirmCancel.bind(this);
    this.handleNoConfirmationModal = this.handleNoConfirmationModal.bind(this);
    this.downloadRemapData = this.downloadRemapData.bind(this);
  }

  componentDidMount() {
    this.getAllPosRequestConfurationRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllPosRequestConfurationRequests(pageNum, pageSize) {
    let searchParameters = this.state.searchParameters;

    this.setState({ canLoadData: true, isLoading: true });
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTimeFrom(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTimeTo(searchParameters.toDate, true)}.747`;

    const {
      status,
      response,
      count,
    } = await userManagement.getPosRequestConfigurationBySearch(
      searchParameters
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        requests: response ? response.data.content : [],
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        dataCount: count ? count : response.data.content.length,
        isLoading: false,
        tableMessage:
          !count || count === 0 ? "No Allocation Request found" : "",
        startPage: null,
      });
    } else {
      this.setState({
        requests: [],
        dataCount: 0,
        pages: 1,
        // pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        tableMessage: "No Allocation Request found",
        // errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async getPosRequestConfigurationByBatch(id) {
    this.setState({
      loading: true,
    });

    const responseObj = await userManagement.getPosRequestConfigurationByBatch(
      id
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      console.log(response);

      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "pos-allocation-file.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();

      // if (response.data.length > 0) {
      this.setState({
        loading: false,
      });
      // }
    } else {
      this.setState({
        errorMessage: response,
        loading: false,
      });
    }
  }

  // async cancelRemapRequestsByRequestid(cancelReason) {
  //   this.setState({
  //     configured: false,
  //   });
  //   let cancelId = localStorage.getItem('remap details');
  //   cancelId = JSON.parse(cancelId);
  //   const responseObj = await remapPosAll.cancelRemapRequestsByRequestid(
  //     cancelId,
  //     cancelReason
  //   );
  //   const { status, response, count } = responseObj;
  //   if (status === SUCCESS_STATUS) {
  //     this.setState({
  //       configured: true,
  //       // posRequestAwaitingConfigDetails: response.data,
  //       successMessage: `Remap request cancelled Successfully`,
  //     });
  //     this.getAllRemapRequests();
  //   } else {
  //     this.setState({
  //       configured: true,
  //       // posRequestAwaitingConfigDetails: [],
  //       errorMessage: response,
  //     });
  //   }
  // }

  async downloadRemapData() {
    const payload = {
      pageNum: this.state.page,
      pageSize: this.state.dataCount,
      batchId: this.state.searchParameters.batchId,
      fromDate:
        this.state.searchParameters.fromDate &&
        `${formatBackEndDateTimeFrom(
          this.state.searchParameters.fromDate,
          true
        )}.747`,
      toDate:
        this.state.searchParameters.toDate &&
        `${formatBackEndDateTimeTo(
          this.state.searchParameters.toDate,
          true
        )}.747`,
    };
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await userManagement.getPosRequestConfigurationBySearch(
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "pos-allocation-file.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }
  isFormValid() {
    if (this.state.showCommentField) {
      return Boolean(this.state.description);
    } else {
      return true;
    }
  }

  handleSearchRequest() {
    this.getAllPosRequestConfurationRequests(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  // getClickedAction(requests, actionType) {
  //   if (actionType == 'view') {
  //     this.getPosRequestConfigurationByBatch(requests.batchId);
  //     this.setState({
  //       requestDetails: requests,
  //       settlement: {
  //         parties: [],
  //       },
  //     });
  //   }
  //   //TO-DO
  //   // else if(actionType == "approve") {
  //   //   this.approveRemapRequestsByRequestid(requests.id);
  //   // } else if(actionType == "decline") {
  //   //   this.cancelRemapRequestsByRequestid(requests.id);
  //   // }
  //   // this.setState({
  //   //   requestId: requests.requestId,
  //   //   actionType,
  //   // });
  // }

  isFormValid() {
    //return false
    if (this.state.actionType == "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  confirmCancel() {
    this.setState({
      showConfirm: true,
    });
  }

  handleNoConfirmationModal() {
    this.setState({
      showConfirm: false,
    });
    // this.getAllRemapRequests();
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <center>
          <p
            style={{
              fontSize: "15px",
              lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
              margin: "10px",
            }}
          >
            Are you sure you want to cancel this POS remap Request?
          </p>
          <br />
          <br />
          <div class="col-md-12">
            <textarea
              class="form-control"
              style={{
                resize: "none",
                marginTop: "15px",
              }}
              placeholder="Enter reasons for cancellation"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              onChange={(event) =>
                this.setState({
                  cancelReason: event.target.value,
                })
              }
            />
          </div>
        </center>
        <br />
        <br />
        <br />
        <br />
        <br />
      </React.Fragment>
    );
    const modalButton1 = (
      <React.Fragment>
        <button
          disabled={!this.state.cancelReason}
          type="button"
          class="btn btn-primary col-lg-6"
          style={{ backgroundColor: "#004257" }}
          onClick={() => {
            // alert("remap cancelled successflly!!!")
            this.cancelRemapRequestsByRequestid(this.state.cancelReason);
          }}
        >
          Yes
        </button>
      </React.Fragment>
    );
    const modalButton2 = (
      <React.Fragment>
        <button
          type="button"
          class="btn btn-primary col-lg-6"
          style={{
            border: "1px solid #00425f",
            backgroundColor: "#ffffff",
            color: "#004257",
          }}
          onClick={this.handleNoConfirmationModal}
        >
          No
        </button>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.loading} />
        {this.state.configured ? (
          <React.Fragment>
            <Notification
              ref={this.notificationSectionRef}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
            <ProtectedComponent
              // requiredPermissions={[CAN_VIEW_TRANSACTION]}
              requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
              permissionDeniedContent={this.permissionDeniedContent}
            >
              <GridLayout>
                <AllocatePosAllSearch
                  searchParameters={this.state.searchParameters}
                  remapStatuses={this.state.remapStatuses}
                  agentName={this.state.requests.agentName}
                  updateSearchParameters={this.updateSearchParameters}
                  handleSearchRequest={this.handleSearchRequest}
                  preloadDate={
                    retrieveOtherInformation("parentReference")
                      ? false
                      : !JSON.parse(
                          process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA
                        )
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                {/* {this.state.showConfirm && confirmationModal} */}
                <AllocatePosAllTable
                  data={this.state.requests}
                  loading={this.state.isLoading}
                  noDataText={this.state.tableMessage}
                  dataCount={this.state.dataCount}
                  downloadRemap={this.downloadRemapData}
                  pages={this.state.pages}
                  startPage={this.state.startPage}
                  getAllPosRequestConfurationRequests={
                    this.getAllPosRequestConfurationRequests
                  }
                  page={this.state.page}
                  pageSize={this.state.pageSize}
                  canRemapDevice={CAN_REMAP_DEVICE}
                  canLoadData={this.state.canLoadData}
                  searchParameters={this.state.searchParameters}
                  responseCode={this.state.searchParameters.responseCode}
                  startDate={this.state.searchParameters.startDate}
                  canView={CAN_GET_DEVICES_BY_UUID}
                  getPosRequestConfigurationByBatch={
                    this.getPosRequestConfigurationByBatch
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                {/* <RemapPosAllModal
                  posAllocationDetails={this.state.posAllocationDetails}
                  loading={this.state.isLoading}
                  confirmConfigure={this.confirmCancel}
                /> */}
                {/* <RemapPromptModal
                  modalTitle={'CANCEL REMAP REQUEST?'}
                  modalBody={modalBody}
                  modalFooterButton1={modalButton1}
                  hideModalHeader={true}
                  modalSize={`modal-md`}
                  height={`500px`}
                  modalFooterButton2={modalButton2}
                  deleteModal={this.handleNoConfirmationModal}
                  // modalFooterButton2Disabled={!this.isFormValid()}
                  // isLoading={this.state.isLoading}
                  // canReload={this.state.canReload}
                /> */}
              </GridLayout>
            </ProtectedComponent>
          </React.Fragment>
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
