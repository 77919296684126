import Requester from '../requester'

const API_BASE_URL = process.env.REACT_APP_FEE_CONFIGURATION_API_BASE_URL

export default class FeeConfigurationManagement {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }

    createRevenueSharingParty (payload) {
        return this.apiRequester.post({
            endpoint: `revenue-sharing-parties`,
            body: payload
        })
    }

    getRevenueSharingParties (pageNumber = null, pageSize = null) {
        return this.apiRequester.get({
            endpoint: `revenue-sharing-parties`,
            args: {
                pageNumber,
                pageSize,
            }
        })
    }

    getRevenueSharingParty (revenueSharingPartyId) {
        return this.apiRequester.get({
            endpoint: `revenue-sharing-parties/${revenueSharingPartyId}`
        })
    }

    updateRevenueSharingParty (revenueSharingPartyId, payload) {
        return this.apiRequester.put({
            endpoint: `revenue-sharing-parties/${revenueSharingPartyId}`,
            body: payload
        })
    }

    updateRevenueSharingPartyStatus (revenueSharingPartyId, status) {
        return this.apiRequester.put({
            endpoint: `revenue-sharing-parties/${revenueSharingPartyId}/${status}`
        })
    }

    removeRevenueSharingParty (revenueSharingPartyId) {
        return this.apiRequester.delete({
            endpoint: `revenue-sharing-parties/${revenueSharingPartyId}`
        })
    }

    createServiceType (payload) {
        return this.apiRequester.post({
            endpoint: `service-types`,
            body: payload
        })
    }

    getServiceTypes (pageNumber = null, pageSize = null) {
        return this.apiRequester.get({
            endpoint: `service-types`,
            args: {
                pageNumber,
                pageSize,
            }
        })
    }

    getServiceType (serviceTypeId, withServices = false) {
        return this.apiRequester.get({
            endpoint: `service-types/${serviceTypeId}`,
            args: {
                withServices
            }
        })
    }
    
    updateServiceType (serviceTypeId, payload) {
        return this.apiRequester.put({
            endpoint: `service-types/${serviceTypeId}`,
            body: payload
        })
    }

    updateServiceTypeStatus (serviceTypeId, status) {
        return this.apiRequester.put({
            endpoint: `service-types/${serviceTypeId}/${status}`
        })
    }

    removeServiceType (serviceTypeId) {
        return this.apiRequester.delete({
            endpoint: `service-types/${serviceTypeId}`
        })
    }

    createRevenueSplit (serviceTypeId, payload) {
        return this.apiRequester.put({
            endpoint: `service-types/${serviceTypeId}/revenue-sharing-parties`,
            body: payload
        })
    }

    createFee (payload) {
        return this.apiRequester.post({
            endpoint: `fees`,
            body: payload
        })
    }

    getFees (pageNumber = null, pageSize = null) {
        return this.apiRequester.get({
            endpoint: `fees`,
            args: {
                pageNumber,
                pageSize,
            }
        })
    }

    getFee (feeId) {
        return this.apiRequester.get({
            endpoint: `fees/${feeId}`
        })
    }

    updateFee (feeId, payload){
        return this.apiRequester.put({
            endpoint: `fees/${feeId}`,
            body: payload
        })
    }

    updateFeeStatus (feeId, status) {
        return this.apiRequester.put({
            endpoint: `fees/${feeId}/${status}`
        })
    }

    deleteFee (feeId) {
        return this.apiRequester.delete({
            endpoint: `fees/${feeId}`
        })
    }

    createFeeRule (payload) {
        return this.apiRequester.post({
            endpoint: `fee-rules`,
            body: payload
        })
    }

    getFeeRules (pageNumber = null, pageSize = null) {
        return this.apiRequester.get({
            endpoint: `fee-rules`,
            args: {
                pageNumber,
                pageSize,
            }
        })
    }

    getFeeRule (feeRuleId) {
        return this.apiRequester.get({
            endpoint: `fee-rules/${feeRuleId}`
        })
    }

    updateFeeRule (feeRuleId, payload) {
        return this.apiRequester.put({
            endpoint: `fee-rules/${feeRuleId}`,
            body: payload
        })
    }

    updateFeeRuleStatus (feeRuleId, status) {
        return this.apiRequester.put({
            endpoint: `fee-rules/${feeRuleId}/${status}`
        })
    }

    deleteFeeRule (feeRuleId) {
        return this.apiRequester.delete({
            endpoint: `fee-rules/${feeRuleId}`
        })
    }

    addExternalFee (payload) {
        return this.apiRequester.post({
            endpoint: `paydirect-fees`,
            body: payload
        })
    }

    getExternalFees (pageNumber = null, pageSize = null, payload = {}) {
        const { 
            billerName, paydirectItemCode, serviceOptionCodeName, status
        } = payload
        return this.apiRequester.get({
            endpoint: `paydirect-fees/search`,
            args: {
                pageNumber,
                pageSize,
                ...(billerName != "" ? { billerName } : {}),
                ...(paydirectItemCode != "" ? { paydirectItemCode } : {}),
                ...(serviceOptionCodeName != "" ? { serviceOptionCodeName } : {}),
                ...(status != 'All' ? { status } : {})
            }
        })
    }

    getExternalFee (externalFeeId) {
        return this.apiRequester.get({
            endpoint: `paydirect-fees/${externalFeeId}`
        })
    }

    updateExternalFeeStatus (externalFeeId, status) {
        return this.apiRequester.put({
            endpoint: `paydirect-fees/${externalFeeId}/${status}`
        })
    }

    deleteExternalFee (externalFeeId) {
        return this.apiRequester.delete({
            endpoint: `paydirect-fees/${externalFeeId}`
        })
    }

    bulkBlacklistExternalFees (payload) {
        return this.apiRequester.delete({
            endpoint: 'paydirect-fees/bulk',
            body: payload
        })
    }

    getCategories () {
        return this.apiRequester.get({
            endpoint: `categories`
        })
    }

    getCategoryById (categoryId) {
        return this.apiRequester.get({
            endpoint: `categories/${categoryId}`,
            args: {
                withServices: true
            }
        })
    }

    getAppConfigTable () {
        // const { name, status } = payload
        return this.apiRequester.get({
            endpoint: `quickteller/dualchannel/configs`,
            // args: {
            //     pageNumber,
            //     pageSize,
            //     ...(name != "" ? { name } : {}),
            //     ...(status != 'All' ? { status } : {})
            // }
        })
    }
    getBillConfigTable () {
        // const { name, status } = payload
        return this.apiRequester.get({
            endpoint: `quickteller/services/dualchannel/configs`,
            // args: {
            //     pageNumber,
            //     pageSize,
            //     ...(name != "" ? { name } : {}),
            //     ...(status != 'All' ? { status } : {})
            // }
        })
    }
    handleAddBillConfig (payload = {}) {
        
        return this.apiRequester.post({
            endpoint: `quickteller/services/whitelist`,
            body: payload
        })
    }

    getServices (pageNumber = null, pageSize = null, payload = {}) {
        const { name, status } = payload
        return this.apiRequester.get({
            endpoint: `services/search`,
            args: {
                pageNumber,
                pageSize,
                ...(name != "" ? { name } : {}),
                ...(status != 'All' ? { status } : {})
            }
        })
    }

    getServiceById (serviceId) {
        return this.apiRequester.get({
            endpoint: `services/${serviceId}`,
            args: {
                withOptions: true
            }
        })
    }

    getServiceOptions (pageNumber = null , pageSize = null, payload = {}) {
        const { 
            name, paydirectItemCode, paymentCode, serviceName, status 
        } = payload
        return this.apiRequester.get({
            endpoint: `service-options/search`,
            args: {
                pageNumber,
                pageSize,
                ...(name != "" ? { name } : {}), 
                ...(paydirectItemCode != "" ? { paydirectItemCode } : {}), 
                ...(paymentCode != "" ? { paymentCode } : {}),
                ...(serviceName != "" ? { serviceName } : {}),
                ...(status != 'All' ? { status } : {})
            }
        })
    }

    getServiceOption (paymentCode) {
        return this.apiRequester.get({
            endpoint: `service-options/${paymentCode}`
        })
    }

    updateService (payload) {
        return this.apiRequester.put({
            endpoint: `services/${payload.id}`,
            body: payload
        })
    }

    updateServiceOption (payload) {
        return this.apiRequester.put({
            endpoint: `service-options/${payload.id}`,
            body: payload
        })
    }

    getCommissionSplitRules (pageNumber = null , pageSize = null) {
        return this.apiRequester.get({
            endpoint: `revenue-sharing-party-rules`,
            args: {
                pageNumber,
                pageSize
            }
        })
    }

    createCommissionSplitRule (payload) {
        return this.apiRequester.post({
            endpoint: `revenue-sharing-party-rules`,
            body: payload
        })
    }

    getCommissionSplitRule (partyRuleId) {
        return this.apiRequester.get({
            endpoint: `revenue-sharing-party-rules/${partyRuleId}`
        })
    }

    updateCommissionSplitRule (partyRuleId, payload) {
        return this.apiRequester.put({
            endpoint: `revenue-sharing-party-rules/${partyRuleId}`,
            body: payload
        })
    }

    updateCommissionSplitRuleStatus (partyRuleId, status) {
        return this.apiRequester.put({
            endpoint: `revenue-sharing-party-rules/${partyRuleId}/${status}`
        })
    }

    deleteCommissionSplitRule (partyRuleId) {
        return this.apiRequester.delete({
            endpoint: `revenue-sharing-party-rules/${partyRuleId}`
        })
    }

    searchPaydirectItem (serviceOptionId) {
        return this.apiRequester.get({
            endpoint: `paydirect-items/search`,
            args: {
                sericeOptionId: serviceOptionId
            }
        })
    }

    addPaydirectItem (payload) {
        return this.apiRequester.post({
            endpoint: `paydirect-items`,
            body: payload
        })
    }

    deletePaydirectItem (paydirectItemId) {
        return this.apiRequester.delete({
            endpoint: `paydirect-items/${paydirectItemId}`
        })
    }

    updatePaydirectItemStatus (status, paydirectItemId) {
        return this.apiRequester.put({
            endpoint: `paydirect-items/${paydirectItemId}/${status}`
        })
    }

    updatePaydirectItem (payload) {
        const { id } = payload
        return this.apiRequester.put({
            endpoint: `paydirect-items/${id}`,
            body: payload
        })
    }



    handleBuyPowerBalanceAddThreshold (thresholdAmount,
        thresholdStatus,
        ) {
            const payload = {
                amount: thresholdAmount,
                triggerState: thresholdStatus
              }
        return this.apiRequester.post({
            endpoint: `quickteller/services/alert-triggers}`,
            body: payload
        })
    }


    handleBuyPowerBalanceDeleteThreshold (id
        ) {
            
        return this.apiRequester.delete({
            endpoint: `quickteller/services/alert-triggers/${id}`,
            // body: payload
        })
    }


    handleBuyPowerBalanceEditThreshold (thresholdAmount,
        thresholdStatus,
        thresholdId) {
            const payload = {
                amount: thresholdAmount,
                id: thresholdId,
                triggerState: thresholdStatus
              }
        return this.apiRequester.put({
            endpoint: `quickteller/services/alert-triggers`,
            body: payload
        })
    }

    getBuyPowerBalanceThreshold () {
        return this.apiRequester.get({
            endpoint: `quickteller/services/alert-triggers`,
            // body: payload
        })
    }

    getDiscosStatusToday () {
        return this.apiRequester.get({
            endpoint: `quickteller/services/getBuypowerDiscos`,
        })
    }
}
