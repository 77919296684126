export function transactionExtraSerializer(extraData) {
    const refinedData = {};
  
    extraData.map(
        ({name, value}) => {
          refinedData[name] = value;
        },
    );
  
    return refinedData;
  }
  