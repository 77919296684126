import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import BaseScene from '../../../../../../../components/scenes/component';
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { CAN_VIEW_AGENT_HIERARCHY, CAN_VIEW_AGENT_HIERARCHY_PERMISSION, CAN_VIEW_AGENT_HIERARCHY_ROLE } from '../../../../../../../constants/permissions'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../components/access-denied';
import AgentHierarchyCard from '../../components/agent-hierarchy-card'

export default class AgentHierarchyGridScene extends BaseScene {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			successMessage: null,
			errorMessage: null,
		}
  	}

  	render () {
    	return <React.Fragment>
			<Loader 
				isLoading={this.state.isLoading}
			/>
			<Notification
				successMessage={this.state.successMessage}
				errorMessage={this.state.errorMessage}
			/>
			<ContentHead title={"Agent Hierarchy Management"} withoutSearch />
			<ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY]}>
				<div class="d-flex flex-column-fluid">
					<div class="container">
						<div class="row">
							<AgentHierarchyCard 
								title="Platform Provider"
								urlId="platform-provider"
								canViewRoles={CAN_VIEW_AGENT_HIERARCHY_ROLE}
								canViewPermissions={CAN_VIEW_AGENT_HIERARCHY_PERMISSION}
							/>
							<AgentHierarchyCard 
								title="Super Agent"
								urlId="super-agent"
								canViewRoles={CAN_VIEW_AGENT_HIERARCHY_ROLE}
								canViewPermissions={CAN_VIEW_AGENT_HIERARCHY_PERMISSION}
							/>
							<AgentHierarchyCard 
								title="Aggregator"
								urlId="aggregator"
								canViewRoles={CAN_VIEW_AGENT_HIERARCHY_ROLE}
								canViewPermissions={CAN_VIEW_AGENT_HIERARCHY_PERMISSION}
							/>
							<AgentHierarchyCard 
								title="Agent"
								urlId="agent"
								canViewRoles={CAN_VIEW_AGENT_HIERARCHY_ROLE}
								canViewPermissions={CAN_VIEW_AGENT_HIERARCHY_PERMISSION}
							/>
						</div>
					</div>
				</div>					
			</ProtectedComponent>
		</React.Fragment>
  	}

	get permissionDeniedContent() {
    	return <AccessDenied />
  	}
}
