import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LogoutModal } from "./modals/logout-modal";
import UserManagement from "../services/api/resources/user-management";
import { clearLocalStorage } from "../mixins/authentication";
import { appSession } from "../mixins/session-timer";
import { isOnline } from "../mixins/connectivity-checker";
import { retrieveSessionInformation } from "../mixins/authentication";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectUrl: null,
      domainName: null,
    };
    this.currentUser = retrieveSessionInformation();
    this.logout = this.logout.bind(this);
  }

  componentDidUpdate() {
    if (this.state.redirectTo) {
      const { redirectTo } = this.state;
      this.setState({
        redirectTo: false,
      });
    }
  }

  logout() {
    if (this.currentUser) {
      const userManagement = new UserManagement();
      const getUserRolesResponseObj = userManagement.logoutUser(
        this.currentUser.access_token
      );
    }
    clearLocalStorage();
    window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/login`;
  }

  componentDidMount() {
    this.setState({
      domainName: this.currentUser.user.domainType,
    });
    appSession();
    isOnline();
  }

  render() {
    const username = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <div>
        <div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed ">
          <button
            class="kt-header-menu-wrapper-close"
            id="kt_header_menu_mobile_close_btn"
          >
            <i class="la la-close"></i>
          </button>
          <div class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
            <div
              id="kt_header_menu"
              class="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default "
            >
              <ul class="kt-menu__nav ">
                {this.props.pathName.split("/").length >= 4 && (
                  <li
                    class="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                    data-ktmenu-submenu-toggle="click"
                    aria-haspopup="true"
                  >
                    <Link
                      onClick={() => {
                        this.props.history.goBack();
                        this.setState({
                          redirectTo: this.props.history[
                            this.props.history.length - 2
                          ],
                        });
                      }}
                      class="kt-menu__link kt-menu__toggle"
                    >
                      <i class="kt-menu__ver-arrow la la-angle-right"></i>
                      <span
                        class="kt-menu__link-text"
                        style={{ color: "#00425f" }}
                      >
                        Back
                      </span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div class="kt-header__topbar">
            <div class="kt-header__topbar-item dropdown">
              <div
                class="kt-header__topbar-wrapper"
                data-toggle="dropdown"
                data-offset="30px,0px"
              >
                <span class="kt-header__topbar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#00b8de"
                      d="M15 21c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6zm.137-17.055c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.668 2.709-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.193-10.598-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm-6.451 16c1.189-1.667 1.605-3.891 1.964-5.815.447-2.39.869-4.648 2.354-5.509 1.38-.801 2.956-.76 4.267 0 1.485.861 1.907 3.119 2.354 5.509.359 1.924.775 4.148 1.964 5.815h-12.903z"
                    />
                  </svg>
                </span>
              </div>
              <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
                <form>
                  <div
                    class="kt-head"
                    style={{ backgroundColor: "#00425f", color: "#ffffff" }}
                  >
                    <h3 class="kt-head__title">Notifications</h3>
                    <div class="kt-head__sub">
                      <span class="kt-head__desc">No New Notifications</span>
                    </div>
                  </div>
                  {/* <div class="kt-notification kt-margin-t-30 kt-margin-b-20 kt-scroll" data-scroll="true" data-height="270" data-mobile-height="220">
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-line-chart kt-font-success"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New order has been received
                      </div>
                      <div class="kt-notification__item-time">
                        2 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-box-1 kt-font-brand"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New customer is registered
                      </div>
                      <div class="kt-notification__item-time">
                        3 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-chart2 kt-font-danger"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        Application has been approved
                      </div>
                      <div class="kt-notification__item-time">
                        3 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-image-file kt-font-warning"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New file has been uploaded
                      </div>
                      <div class="kt-notification__item-time">
                        5 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-user kt-font-info"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New user feedback received
                      </div>
                      <div class="kt-notification__item-time">
                        8 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-pie-chart-2 kt-font-success"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        System reboot has been successfully completed
                      </div>
                      <div class="kt-notification__item-time">
                        12 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-favourite kt-font-focus"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New order has been placed
                      </div>
                      <div class="kt-notification__item-time">
                        15 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item kt-notification__item--read">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-safe kt-font-primary"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        Company meeting canceled
                      </div>
                      <div class="kt-notification__item-time">
                        19 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-psd kt-font-success"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New report has been received
                      </div>
                      <div class="kt-notification__item-time">
                        23 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon-download-1 kt-font-danger"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        Finance report has been generated
                      </div>
                      <div class="kt-notification__item-time">
                        25 hrs ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon-security kt-font-warning"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New customer comment recieved
                      </div>
                      <div class="kt-notification__item-time">
                        2 days ago
                      </div>
                    </div>
                  </a>
                  <a href="#" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-pie-chart kt-font-focus"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title">
                        New customer is registered
                      </div>
                      <div class="kt-notification__item-time">
                        3 days ago
                      </div>
                    </div>
                  </a>
                </div> */}
                </form>
              </div>
            </div>

            <div class="kt-header__topbar-item kt-header__topbar-item--user">
              <div
                class="kt-header__topbar-wrapper"
                data-toggle="dropdown"
                data-offset="0px,0px"
              >
                <div class="kt-header__topbar-user">
                  <span class="kt-header__topbar-username kt-hidden-mobile word-capitalize">
                    {username}
                  </span>
                  <span
                    class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
                    style={{ backgroundColor: "#00b8de" }}
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Stockholm-icons-/-General-/-User"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon
                          id="Shape"
                          points="0 0 24 0 24 24 0 24"
                        ></polygon>
                        <path
                          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                          id="Mask"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                          id="Mask-Copy"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                <div
                  class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                  style={{
                    backgroundImage: require("../assets/media/misc/bg-1.jpg"),
                  }}
                >
                  <div class="kt-user-card__avatar">
                    <img
                      class="kt-hidden"
                      alt="Pic"
                      src="./assets/media/users/300_25.jpg"
                    />
                    <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Stockholm-icons-/-General-/-User"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon
                            id="Shape"
                            points="0 0 24 0 24 24 0 24"
                          ></polygon>
                          <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            id="Mask"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                          ></path>
                          <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            id="Mask-Copy"
                            fill="#000000"
                            fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div class="kt-user-card__name" style={{ color: "#00425f" }}>
                    {this.state.domainName}
                  </div>
                </div>

                <div class="kt-notification">
                  <a
                    href="/admin/home/profile/personal-information"
                    class="kt-notification__item"
                  >
                    <div class="kt-notification__item-icon">
                      <i class="flaticon2-calendar-3 kt-font-success"></i>
                    </div>
                    <div class="kt-notification__item-details">
                      <div class="kt-notification__item-title kt-font-bold">
                        My Profile
                      </div>
                      <div class="kt-notification__item-time">
                        Change password and more
                      </div>
                    </div>
                  </a>
                  <div class="kt-notification__custom kt-space-between">
                    <Link
                      data-toggle="modal"
                      data-target="#kt_modal_1_logout"
                      style={{ background: "#00425f", color: "#ffffff" }}
                      class="btn btn-label btn-label-brand btn-sm btn-bold"
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LogoutModal
          modalTitle={`Logout of Finch?`}
          hideModalHeader={true}
          hideModalFooter={true}
          modalBody={`Are you sure you want to logout of Finch 2.0 Admin Portal? You can always log back in at any time.`}
          deleteModal={this.logout}
          modalSize={`modal-sm`}
          height={`250px`}
          modalFooterButton1={`Cancel`}
          modalFooterButton2={`Logout`}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    history: state.navigation.history,
    pathName: state.navigation.pathName,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
