import React from 'react'
import BaseScene from '../../../../../../components/scenes/component';
import ContentHead from '../../../../../../components/content-head';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import qs from 'querystring';
import UserForm from '../../../../../../components/forms/user-form'
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { userManagement } from '../../../../../../mixins/api'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_VIEW_PERMISSION_GROUP } from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import PermissionForm from '../../components/permission-form'

export default class ViewPermissionScreen extends BaseScene {
  requiredPermissions = [
    CAN_VIEW_PERMISSION_GROUP
  ]

  constructor (props) {
    super(props)

    this.state = {
      canSave: false,
      canUpdate: true,
      pageTitle: 'View Permission Group Details',
      disabled: true,
      permissionFormData: [],
      permissionDetails: [],
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updatePermissionFormData = this.updatePermissionFormData.bind(this)
  } 

  componentDidMount() {
    const permissionGroupDetails = qs.parse(window.location.search)['?id']
    this.permissionGroupId = this.splitString(permissionGroupDetails)[0]
    this.setState({
      permissionFormData: {...this.state.permissionFormData, name: this.splitString(permissionGroupDetails)[1], code: this.splitString(permissionGroupDetails)[2]}
    })
    this.getGroupPermissions()
  }

  splitString (string) {
    return string.split("_");
}

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getGroupPermissions () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.getGroupPermissions(this.permissionGroupId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissionFormData: {...this.state.permissionFormData, permissions: response},
        isLoading: false,
      })
    }else{
      this.setState({
        permissionFormData: {...this.state.permissionFormData, permissions: []},
        errorMessage: response,
        isLoading: false,
      })
    }
  }

  async onUpdateButtonClick () {
    this.setState({
      pageTitle: 'Update Permission Details',
      canSave: true,
      canUpdate: false,
      disabled: false,
    })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})

    const createUserResponseObj = await userManagement.updatePermission(this.permissionId, this.state.permissionFormData)
    const createUserResponseStatus = createUserResponseObj.status
    const createUserResponse = createUserResponseObj.response.data
    
    if (createUserResponseStatus === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: createUserResponse
      })

      return
    }

    this.setState({
      isLoading: false,
      successMessage: 'Permission group details updated successfully.',
      canSave: false,
      canUpdate: true,
      pageTitle: 'View Permission Group Details',
      disabled: true,
    })

  }

  isFormValid () {
    return this.state.permissionDetails.name !== this.state.permissionFormData.name;
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  updatePermissionFormData (type, value) {
    this.setState({permissionFormData: {...this.state.permissionFormData, [type]: value}})
  }

  render () {
    // const ctaWidgets = <React.Fragment>
    //   {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Update</button></ProtectedComponent>}
    //   {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}><button type="button" class="btn btn-primary" disabled={!this.isFormValid()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    // </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} subtitle={"Permission Group Details"} subtitle={this.state.pageTitle} /*ctaWidgets={ctaWidgets}*/ />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}>      
        <GridLayout>
          <PermissionForm 
            onChange={this.updatePermissionFormData}
            permissionDetails={this.state.permissionFormData}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
