import { 
    formatDateTime, formatCurrency, formatLongNumber 
} from '../../utils/formatter';

export default class HistoricalDataExportSerializer {
    static headerExport () {
        return [
            { label: 'Reference ID', key: 'referenceId', title: 'Reference ID', dataIndex: 'referenceId' },
            { label: 'Date created', key: 'dateCreated', title: 'Date created', dataIndex: 'dateCreated' },
            { label: 'Last modification date', key: 'dateModified', title: 'Last modification date', dataIndex: 'dateModified' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
            { label: 'Transaction type', key: 'transactionType', title: 'Transaction type', dataIndex: 'transactionType' },
            { label: 'Source', key: 'source', title: 'Source', dataIndex: 'source'},
            { label: 'Destination', key: 'destination', title: 'Destination', dataIndex: 'destination' },
            { label: 'Debited amount', key: 'debitedAmount', title: 'Debited amount', dataIndex: 'debitedAmount' },
            { label: 'Credited amount', key: 'creditedAmount', title: 'Credited amount', dataIndex: 'creditedAmount' },
            { label: 'Cash amount', key: 'cashAmount', title: 'Cash amount', dataIndex: 'cashAmount' },
            { label: 'Consumer SP name', key: 'consumerSP', title: 'Consumer SP name', dataIndex: 'consumerSP' },
            { label: 'Original ESB reference', key: 'esbRef', title: 'Original ESB reference', dataIndex: 'esbRef' },
            { label: 'External reference', key: 'externalRef', title: 'External reference', dataIndex: 'externalRef' },
            { label: 'Token type', key: 'tokenType', title: 'Token type', dataIndex: 'tokenType' },
            { label: 'Consumer name', key: 'consumer', title: 'Consumer', dataIndex: 'consumer' },
        ];
    }

    static reverseHistoricalData (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
                referenceId, dateCreated, status, transactionType, source, destination, debitedAmount, 
                creditedAmount, cashAmount, consumerSP, esbRef, externalRef, tokenType, consumer
            } = detail

            let detailFormData = {
                referenceId: referenceId ? formatLongNumber(referenceId) : '',
                dateCreated: dateCreated ? formatDateTime(dateCreated) : '',
                status,
                transactionType,
                source: source ? formatLongNumber(source) : '',
                destination: destination ? formatLongNumber(destination) : '',
                debitedAmount: debitedAmount ? `₦${formatCurrency(parseFloat(debitedAmount))}` : `₦${formatCurrency(0)}`,
                creditedAmount: creditedAmount ? `₦${formatCurrency(parseFloat(creditedAmount))}` : `₦${formatCurrency(0)}`,
                cashAmount: cashAmount ? `₦${formatCurrency(parseFloat(cashAmount))}` : `₦${formatCurrency(0)}`,
                consumerSP,
                esbRef: esbRef ? formatLongNumber(esbRef) : '',
                externalRef: externalRef ? formatLongNumber(externalRef) : '',
                tokenType,
                consumer,
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}