import React from 'react';
import { AccessDenied } from '../../../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../../../components/notification';
import BaseScene from '../../../../../../../../../components/scenes/component';
import Table from '../../../../../../../../../components/table';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { platformManagement } from '../../../../../../../../../mixins/api';
import { BusinessTypesExportSerializer } from '../../../../../../../../../serializers';

const agentClasses = JSON.parse(
  localStorage.getItem("agentClasses")
);
export default class BusinessTypeGridScene extends BaseScene {

  sceneName = 'business-types'

  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: { 'white-space': 'unset' },
    },
    {
      Header: "Name",
      accessor: 'business_type',
      resizable: false,
      headerStyle: { 'white-space': 'unset' },
      style: { 'white-space': 'unset' },
      Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
    },
    {
      Header: "Tier",
      accessor: 'agent_class_name',
      resizable: false,
      headerStyle: { 'white-space': 'unset' },
      style: { 'white-space': 'unset' },
      Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
    },
    {
      Header: "Edit",
      accessor: 'id',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: { 'white-space': 'unset' },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: '10px',
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 60,
      Cell: ({ row, original }) => <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.onEditButtonClick(original)} class="dropdown-item" style={{ cursor: 'pointer' }}>

        <i class="la la-edit la-lg"></i>
      </a>
    },
    {
      Header: "Action",
      accessor: 'id',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: { 'white-space': 'unset' },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: '10px',
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 60,
      Cell: ({ row, original }) => <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.onDeleteButtonClick(row.id, row.business_type, original.tier_id)} class="dropdown-item" style={{ cursor: 'pointer' }}>
        <i class="la la-trash la-lg error"></i>
      </a>
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
      businessTypes: [],
      businessTypeFormData: [],
    }

    this.deleteBusinessType = this.deleteBusinessType.bind(this)
    this.getBusinessTypes = this.getBusinessTypes.bind(this)
    this.onAddButtonClick = this.onAddButtonClick.bind(this)
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateBusinessType = this.updateBusinessType.bind(this)
    this.onEditButtonClick = this.onEditButtonClick.bind(this)
    this.onClearButtonClick = this.onClearButtonClick.bind(this)
  }

  componentDidMount() {
    this.getBusinessTypes()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null })
      }, 3000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null })
      }, 3000)
    }
  }

  async deleteBusinessType() {
    this.setState({ isLoading: true })
    const responseObj = await platformManagement.deleteBusinessType(this.state.businessTypeId, this.state.businessTier_id)

    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        businessTypeId: null,
        businessTier_id: null,
        businessTypeName: null,
        successMessage: "Business type deleted succesfully!",
        isLoading: false,
        canDelete: false,
        canEdit: false,
        canAdd: false,
      })
    } else {
      this.setState({
        errorMessage: 'Unable to delete business type',
        isLoading: false,
      })
    }
    this.getBusinessTypes()
  }

  async getBusinessTypes() {
    this.setState({ isLoading: true })
    const responseObj = await platformManagement.getBusinessTypes()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        businessTypes: response,
        isLoading: false,
        dataCount: response.length,
      })
    } else {
      this.setState({
        businessTypes: [],
        isLoading: false,
        dataCount: 0,
        errorMessage: response,
      })
    }
  }

  async updateBusinessType() {
    const { id, tierId, business_type_name } = this.state
    const business_tier_num = Number(tierId)

    const payload = { id, businessTypeName: business_type_name, tierId: business_tier_num }
    this.setState({ isLoading: true })

    const responseObj = await platformManagement.editBusinessType(payload)

    const status = responseObj.status
    const response = responseObj.response.data

    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: 'Unable to update business type',
      })

      return
    }

    this.setState({
      businessTypeId: null,
      businessTier_id: null,
      businessTypeName: null,
      successMessage: "Business type updated successfully",
      isLoading: false,
      canDelete: false,
      canEdit: false,
      canAdd: false,
      // redirect: true,
    })

    this.getBusinessTypes()
  }

  onEditButtonClick(row) {
    this.setState({
      id: row.id,
      business_type_name: row.business_type,
      tierId: row.tier_id,
      agentClassName: row.agent_class_name,
      canDelete: false,
      canEdit: true,
      canAdd: false,
      modalHeight: '350px',
      modalTitle: 'Edit Business Type',
      businessTypeFormData: [],
    })
  }

  async onSaveButtonClick() {
    const { business_tier, business_type_name } = this.state.businessTypeFormData
    const business_tier_num = parseInt(business_tier)

    const payload = { business_type_name: business_type_name, tierId: business_tier_num }

    this.setState({ isLoading: true })

    const responseObj = await platformManagement.addBusinessType(payload)

    const status = responseObj.status
    const response = responseObj.response.data

    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: 'Unable to create business type',
      })

      return
    }

    this.setState({
      isLoading: false,
      successMessage: "Business type created successfully",
      redirect: true,
      canDelete: false,
      canEdit: false,
      canAdd: false,
    })

    this.getBusinessTypes()
  }

  onAddButtonClick() {
    this.setState({
      canDelete: false,
      onEdit: false,
      canAdd: true,
      modalHeight: '350px',
      modalTitle: 'New Business Type',
      businessTypeFormData: [],
    })
  }

  onDeleteButtonClick(businessTypeId, businessTypeName, businessTier_id) {

    this.setState({
      businessTypeId,
      businessTypeName,
      businessTier_id,
      canDelete: true,
      canAdd: false,
      canEdit: false,
      modalHeight: '230px',
      modalTitle: 'Delete Business Type',
      businessTypeFormData: []
    })
  }

  onClearButtonClick() {
    this.setState({
      business_type_name: '',
      tierId: '',
      business_tier: '',
      canDelete: false,
      canAdd: false,
      canEdit: false,
      businessTypeFormData: [{}]
    })
  }

  render() {
    const addButton = <React.Fragment>
      <button type="button" class="btn btn-primary" style={{ backgroundColor: '#00425f', borderColor: '#00425f' }} data-toggle="modal" data-target="#kt_modal_1" onClick={this.onAddButtonClick}>Add Business Types</button>
    </React.Fragment>

    const addModalBody = <React.Fragment>
      {this.state.canAdd && <div class="form-group col-lg-12">
        <label>Name:</label>
        <input autoComplete="off" type="text" class="form-control" onChange={event => this.setState({ businessTypeFormData: { ...this.state.businessTypeFormData, business_type_name: event.target.value, } })} />
        <label>Tiers:</label>
        <select type="text" class="form-control" onChange={e => this.setState({ businessTypeFormData: { ...this.state.businessTypeFormData, business_tier: e.target.value, } })}>
          <option>Select a Tier</option>
          {agentClasses && agentClasses.map((item) => {
            const { id, name } = item;
            return <option value={id} key={id}>{name}</option>;
          })}
        </select>
      </div>}
    </React.Fragment>

    const editModalBody = <React.Fragment>
      {this.state.canEdit && <div class="form-group col-lg-12">
        <label>Name:</label>
        <input autoComplete="off" type="text" class="form-control" value={this.state.business_type_name} onChange={e => this.setState({ business_type_name: e.target.value })} />
        <label>Tiers:</label>
        <select type="text" class="form-control" value={this.state.tierId} onChange={e => this.setState({ tierId: e.target.value })}>
          <option>Select a Tier</option>
          {agentClasses && agentClasses.map((item) => {
            const { id, name } = item;
            return <option value={id} key={id}>{name}</option>;
          })}
        </select>
      </div>}
    </React.Fragment>

    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Application Management Configuration"} subtitle={"Business Types"} ctaWidgets={addButton} />
      <GridLayout>
        <Table
          columns={this.columns}
          data={this.state.businessTypes}
          deleteBusinessType={this.deleteBusinessType}
          loading={this.state.isLoading}
          noDataText={`No business types found`}
          dataCount={this.state.dataCount}
          getData={this.getBusinessTypes}
          exportData={BusinessTypesExportSerializer.reverseBusinessTypeDetails(this.state.businessTypes)}
          exportHeaders={BusinessTypesExportSerializer.headerExport()}
          exportFileName={'business_types'}
        />

        <PromptModal
          modalTitle={this.state.modalTitle}
          modalBody={this.state.canAdd ? addModalBody : this.state.canEdit ? editModalBody : `Do you want to delete ${this.state.businessTypeName} from business types?`}
          deleteModal={this.state.canDelete ? this.deleteBusinessType : this.state.canAdd ? this.onSaveButtonClick : this.updateBusinessType}
          modalSize={'modal-md'}
          height={this.state.modalHeight}
          modalFooterButton1={'Cancel'}
          modalFooterButton2={this.state.canDelete ? 'Ok' : 'Save'}
          modalFooterButton2Disabled={this.state.canAdd ? !this.state.businessTypeFormData.business_type_name || !this.state.businessTypeFormData.business_tier : this.state.canEdit ? !this.state.business_type_name || !this.state.tierId : false}
          hideModalHeader={true}
          hideModalFooter={true}
          textAlign={this.state.canDelete ? 'centre' : 'left'}
          clearModal={this.onClearButtonClick}
        />
      </GridLayout>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
