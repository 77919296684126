import React from 'react';
import { Link } from 'react-router-dom';
import { AccessDenied } from '../../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../../components/notification';
import Table from '../../../../../../../../components/table';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../../constants/api';
import {
    CAN_ACTIVATE_AGENT_USER, CAN_ACTIVATE_AGGREGATOR_USER, CAN_ACTIVATE_SP_USER, CAN_CREATE_AGENT_USER, CAN_CREATE_AGGREGATOR_USER, CAN_CREATE_SP_USER, CAN_DEACTIVATE_AGENT_USER, CAN_DEACTIVATE_AGGREGATOR_USER, CAN_DEACTIVATE_SP_USER,
    CAN_DELETE_AGENT_USER, CAN_DELETE_AGGREGATOR_USER, CAN_DELETE_SP_USER, CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_USER
} from '../../../../../../../../constants/permissions';
import { platformManagement } from '../../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../../mixins/protected-component';
import { UsersExportSerializer } from '../../../../../../../../serializers';
import { pastTenseFormatter } from '../../../../../../../../utils/formatter';
import DomainGridLayout from '../../../../components/domain-grid-layout';

export default class DomainUserGridScene extends React.Component {
    columns = [
        {
            Header: "S/N",
            accessor: 'id',
            Cell: (id) => <span>{id.index + 1}</span>,
            sortable: false,
            resizable: false,
            width: 60,
            headerStyle: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
              return {
                  style: {
                      textAlign: 'center',
                      whiteSpace: 'unset',
                  },
              };
          },
        },{
            Header: "First Name",
            accessor: 'firstName',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
            width: 150,
        },{
            Header: "Last Name",
            accessor: 'lastName',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
            width: 150,
        },{
            Header: "Phone Number",
            accessor: 'mobileNo',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
            width: 120,
        },{
            Header: "Email",
            accessor: 'email',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
        },{
            Header: "Status",
            accessor: 'status',
            resizable: false,
            width: 80,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
              return {
                  style: {
                      textAlign: 'center'
                  },
              };
            },
            Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge ${props.value === 1 ? 'kt-badge--success' : props.value === 2 ? 'kt-badge--warning' : props.value === 3 ? 'kt-badge--danger' : props.value === 4 ? 'kt-badge--info' : ''} kt-badge--inline kt-badge--pill`}>{props.value === 1 ? 'Active' : props.value === 2 ? "Inactive" : props.value === 3 ? "Retired" : props.value === 4 ? "Dormant" : ''}</span>
        },{
            Header: "Action",
            accessor: 'username',
            overflow: 'visible',
            resizable: false,
            sortable: false,
            headerStyle: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
              return {
                  style: {
                      lineHeight: '20px',
                      textAlign: 'center',
                      whiteSpace: 'unset',
                  },
              };
          },
            width: 60,
            Cell: ({row, original}) => <div class="dropdown">
            <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
            <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_USER]}><Link to={`users/view?username=${original.username}`} class="dropdown-item">
                <i class="la la-eye"></i>
                View
              </Link></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[CAN_ACTIVATE_AGENT_USER, CAN_ACTIVATE_AGGREGATOR_USER, CAN_ACTIVATE_SP_USER]}><a hidden={(original.status === 2 || original.status === 4) ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(original.username, original.firstName, original.lastName, 'activate')} /*href={`/home/user-management/${'activate'}/${row.email}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                <i class="la la-toggle-on"></i>
                Activate
              </a></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[CAN_DEACTIVATE_AGENT_USER, CAN_DEACTIVATE_AGGREGATOR_USER, CAN_DEACTIVATE_SP_USER]}><a hidden={original.status === 1 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(original.username, original.firstName, original.lastName, 'suspend')} /*href={`/home/user-management/${'suspend'}/${row.email}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                <i class="la la-ban"></i>
                Suspend
              </a></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[CAN_DELETE_AGENT_USER, CAN_DELETE_AGGREGATOR_USER, CAN_DELETE_SP_USER]}><a hidden={original.status !== 3 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(original.username, original.firstName, original.lastName, 'retire')} /*href={`/home/user-management/${'retire'}/${row.email}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                <i class="la la-lock"></i>
                Retire
              </a></ProtectedComponent>
            </div>
          </div>
        }
      ]
    constructor(props) {
        super(props);
        this.state = {
            domainName: null,
            allUsers: [],
        }
        this.updateUser = this.updateUser.bind(this)
        this.getUsers = this.getUsers.bind(this)
    }

    componentDidMount () {
        this.domainCode = this.props.match.params.domainCode
        const { status, response } = platformManagement.getAgentByAgentCode(this.domainCode)
        this.loadApplication()
        this.getUsers()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async loadApplication () {
        const responseObj = await platformManagement.getAgentByAgentCode(this.domainCode)
        const { status, response } = responseObj
        this.setState({domainName: response.businessName})
    }

    async getUsers () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getUsers(this.domainCode)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                allUsers: response,
                isLoading: false,
                dataCount: response.length,
            })
        }else{
            this.setState({
                showErrorMessage: true,
                isLoading: false,
                dataCount: 0,
                allUsers: []
            })
        }
    }

    getClickedAction (username, firstName, lastName, status) {
        this.setState({
            username: username,
            userStatus: status,
            firstName,
            lastName,
        })
    }
    
    async updateUser () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.updateUserStatus(this.state.userStatus, this.state.username, this.domainCode)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            if(response.code === ACCEPTED_REQUEST) {
                this.setState({
                    successMessage: `Your request to ${this.state.userStatus} ${this.state.lastName} ${this.state.firstName} as a user has been sent successfully for approval.`,
                    isLoading: false
                })
            }else{
                this.setState({
                    successMessage: `${this.state.lastName} ${this.state.firstName} was successfully ${pastTenseFormatter(this.state.userStatus)} as a user.`,
                    isLoading: false
                })
            }
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false
            })
        }
        this.getUsers()
        setTimeout(() => {
            this.setState({
                username: null,
                userStatus: null,
                firstName: null,
                lastName: null,
                    
            })
        }, 3000)
    }

    render() {
        return <React.Fragment>
            <Notification 
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_USER]} permissionDeniedContent={this.permissionDeniedContent}>
                <GridLayout>
                    <DomainGridLayout title={'User Management'} urlLinkPermission={[CAN_CREATE_AGENT_USER, CAN_CREATE_AGGREGATOR_USER, CAN_CREATE_SP_USER]} url={`/home/domain-management/${this.domainCode}/users/add`} urlName={'Add User'}>
                        {/* <Table 
                            columns={this.columns} 
                            data={this.state.allUsers.reverse()} 
                            loading={this.state.isLoading} 
                            noDataText={`No users found`}
                            dataCount={this.state.dataCount}
                            getData={this.getUsers}
                            disabled={true}
                        /> */}
                        <Table 
                            columns={this.columns} 
                            data={this.state.allUsers} 
                            loading={this.state.isLoading} 
                            noDataText={`No users found`}
                            dataCount={this.state.dataCount}
                            getData={this.getUsers}
                            exportData={UsersExportSerializer.reverseUserDetails(this.state.allUsers)}
                            exportHeaders={UsersExportSerializer.headerExport()}
                            exportFileName={'Users'}
                        />
                        <PromptModal
                            modalTitle={ this.state.userStatus+` User`}
                            modalBody={`Do you want to ${this.state.userStatus} ${this.state.firstName} ${this.state.lastName} as a user?`}
                            deleteModal={this.updateUser}
                            modalSize={`modal-md`}
                            height={`230px`}
                            modalFooterButton1={`Cancel`}
                            modalFooterButton2={`Ok`}
                            hideModalHeader={true}
                            hideModalFooter={true}
                        />
                    </DomainGridLayout>
                </GridLayout>
            </ProtectedComponent>
        </React.Fragment>
    }
    get permissionDeniedContent() {
        return <AccessDenied /> 
    }
}