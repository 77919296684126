import React, { createRef } from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE,
} from '../../../../../../../constants/permissions.js';
import { userManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import {
  convertToMajor,
  convertToMinor,
} from '../../../../../../../utils/converter';
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import EditStockModal from '../../components/edit-stock-modal';
import StockDetailsModal from '../../components/stock-modal';
import StockTable from '../../components/stock-table';
import { Modal } from 'react-bootstrap';

export default class StockGridScene extends BaseScene {
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];
  inputRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      id: '',
      original: {},
      show: false,
      searchParameters: {
        deviceType: 'All',
        fromDate: getDateByType('startDate'),
        toDate: getDateByType('endDate'),
        stockStatus: 'All',
        pageNum: 1,
        pageSize: 20,
      },
      tableMessage: '',
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      posDetails: {},
      newUpdateDetails: {
        file: '',
      },
      updateEditDetailsPayload: {},
    };
    this.getStocks = this.getStocks.bind(this);
    this.getStocksDetails = this.getStocksDetails.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.downloadStocks = this.downloadStocks.bind(this);
    this.updateEditDetails = this.updateEditDetails.bind(this);
    this.editPosDetails = this.editPosDetails.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  sceneName = 'all-stocks';

  handleClose() {
    this.setState({
      show: false,
    });
  }
  handleShow(original) {
    this.setState({
      show: true,
      original: original,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getStocks(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.stockStatus =
      searchParameters.stockStatus === 'All'
        ? null
        : searchParameters.stockStatus;
    searchParameters.deviceType =
      searchParameters.deviceType === 'All'
        ? null
        : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTimeFrom(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTimeTo(searchParameters.toDate, true)}.747`;

    const responseObj = await userManagement.getAllStocks(searchParameters);
    const { status, response, count } = responseObj;

    if (status === SUCCESS_STATUS) {
      this.setState({
        stocks: response.data,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: !count || count == 0 ? 'No POS stock found' : '',
      });
    } else {
      this.setState({
        stocks: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 2,
        isLoading: false,
        errorMessage: response,
        tableMessage: 'No POS stock found',
      });
    }
  }

  async getStocksDetails(id) {
    this.setState({
      stockDetails: [],
    });

    const responseObj = await userManagement.getStockById(id);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        stockDetails: response ? response : '',
      });
    } else {
      this.setState({
        stockDetails: [],
      });
    }
  }

  async downloadStocks() {
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = this.state.page;
    searchParameters.pageSize = this.state.dataCount;
    searchParameters.stockStatus =
      searchParameters.stockStatus === 'All'
        ? null
        : searchParameters.stockStatus;
    searchParameters.deviceType =
      searchParameters.deviceType === 'All'
        ? null
        : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      this.setState({
        isLoading: true,
        canLoadData: true,
      });
    const responseObj = await userManagement.downloadStocks(searchParameters);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], { type: 'contentType' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('download', 'pos_stocks.csv');
      a.setAttribute('href', window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  handleSearchRequest(searchParameters) {
    this.setState({ loading: true });
    this.setState({ pageIndex: 1 });
    this.setState({ startPage: 1 });
    this.setState({
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getStocks(1, 20);
      this.setState({ loading: false });
    });
  }

  updateEditDetails(type, val) {
    const { posDetails, id, updateEditDetailsPayload } = this.state;
    let posPrice = posDetails.price;
    let posModel = id;
    let posModelDescription = null;
    let newUpdateDetails = {
      ...updateEditDetailsPayload,
      ...this.state.newUpdateDetails,
      posModel,
    };

    if (type === 'file') {
      newUpdateDetails.file = val;
    } else if (type === 'description') {
      posModelDescription = val;
      newUpdateDetails.posModelDescription = posModelDescription;
    } else if (type === 'posPrice') {
      posPrice = +val;
      newUpdateDetails.posPrice = convertToMinor(posPrice);
    }

    if (type === 'posPrice') {
      newUpdateDetails.posPrice =convertToMinor(posPrice);
    }

    this.setState({
      newUpdateDetails,
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType == 'view') {
      this.getStocksDetails(requests.id);
      this.setState({
        id: requests.terminalModel,
        requestDetails: requests,
        settlement: {
          parties: [],
        },
      });
    }
    if (actionType === 'edit') {
      console.log(requests, 'request');
      this.setState({
        posDetails: requests,
        id: requests.terminalModel,
        updateEditDetailsPayload: {
          posModel: requests.terminalModel,
          posModelDescription: requests.terminalDescription,
          posPrice: +requests.price,
          file: requests.file,
        },
      });
    }
    this.setState({
      requestId: requests.requestId,
      id: requests.terminalModel,
      actionType,
    });
  }

  async editPosDetails() {
    this.setState({ isLoading: true });

    try {
      const responseObj = await userManagement.editPosDetails(
        this.state.newUpdateDetails
      );

      if (this.state.newUpdateDetails.file) {
        const data = new FormData();
        data.append('file', this.state.newUpdateDetails.file[0]);
        const res = await userManagement.uploadPosImage(
          data,
          this.state.newUpdateDetails.posModel
        );

        if (res.status === 'SUCCESS') {
          this.setState({
            isLoading: false,
          });
        }
      }
      if (responseObj.status === 'SUCCESS') {
        this.setState({
          isLoading: false,
        });
      }
      this.setState({
        newUpdateDetails: {},
        updateEditDetailsPayload: {},
      });
      this.getStocks(
        this.state.searchParameters.pageNum,
        this.state.searchParameters.pageSize
      );
    } catch (error) {
      console.log('an error occured');
    }
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />

        {/* <ContentHead
          title={"POS Management"}
          subtitle={"AllStocks"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          actionButtonRequiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        <ProtectedComponent
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            {/* <StockSearch
              handleSearchRequest={this.handleSearchRequest}
              searchParameters={this.state.searchParameters}
            /> */}
            <StockTable
              data={this.state.stocks}
              loading={this.state.isLoading}
              handleShow={this.handleShow}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getStocks={this.getStocks}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canRemapDevice={CAN_REMAP_DEVICE}
              canLoadData={this.state.canLoadData}
              // downloadStocks={this.downloadStocks}
              getClickedAction={this.getClickedAction}
            />

            <StockDetailsModal
              stockDetails={this.state.stockDetails}
              loading={this.state.isLoading}
              // confirmConfigure={this.confirmConfigure}
            />
            {this.state.original.terminalModel === this.state.id && (
              <Modal size='lg' show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit POS Details</Modal.Title>
                </Modal.Header>

                <EditStockModal
                  handleClose={this.handleClose}
                  posDetails={this.state.posDetails}
                  newUpdateDetails={this.state.newUpdateDetails}
                  updateEditDetails={this.updateEditDetails}
                  editPosDetails={this.editPosDetails}
                />
              </Modal>
            )}
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
