import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import ViewFipScene from './scenes/view-fip';
import StockGridScene from "./scenes/stock-grid";
import NewStockConfigScene from "./scenes/new-stock-config";
import ViewStockScene from "./scenes/view-stock";
// import AddFipScene from './scenes/add-fip'
import { isAuthorized } from "../../../../../mixins/authentication";
import {
  CAN_REMAP_DEVICE,
  CAN_GET_DEVICES_BY_UUID,
} from "../../../../../constants/permissions.js";

export default class DeviceManagementScene extends Component {
  sceneName = "pos-stocks-request";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/pos-stocks-request"
          component={StockGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_REMAP_DEVICE]}
          exact
          path="/home/pos-management/pos-stocks-request/:id/view"
          component={ViewStockScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_REMAP_DEVICE]}
          exact
          path="/home/pos-management/pos-stocks-request/add"
          component={NewStockConfigScene}
        />
      </Router>
    );
  }
}
