import React from 'react';
import { Redirect } from 'react-router-dom';
import { AccessDenied } from '../../../../../../../../components/access-denied';
import Loader from '../../../../../../../../components/loader';
import Notification from '../../../../../../../../components/notification';
import BaseScene from '../../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../../constants/api';
import { CAN_REMAP_DEVICE } from '../../../../../../../../constants/permissions.js';
import {
  liquidityService
} from "../../../../../../../../mixins/api";
import ProtectedComponent from '../../../../../../../../mixins/protected-component';
import { getDateByType } from '../../../../../../../../utils/helper';
import FileUpload from '../../../../../pos-management/upload-stocks/components/file-upload';

export default class NewCashoutConfigScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      cashouts: [],
      searchParameters: {
        deviceType: 'All',
        fromDate: getDateByType('startDate'),
        toDate: getDateByType('endDate'),
        documentStatus: 'All',
        pageNum: 1,
        pageSize: 20,
      },
      tableMessage: '',
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      cashoutDisable: false,
      configDisable: false,
      cashoutFile: [],
      configFile: [],
      cashoutError: '',
      configError: '',
      redirect: false,
    };
    this.uploadCashoutFile = this.uploadCashoutFile.bind(this);
    this.readUploadedFile = this.readUploadedFile.bind(this);
  }

  sceneName = 'upload-cashout';

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }
  readUploadedFile(event, type) {
    const document = {
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };

    if (
      document.file.type === 'application/vnd.ms-excel' ||
      document.file.type === 'text/csv'
    ) {
      this.setState({
        [`${type}File`]: document,
        [`${type}Disable`]: false,
        [`${type}Error`]: '',
      });
    } else {
      this.setState({
        [`${type}Error`]: 'Invalid file, upload a csv file',
        [`${type}Disable`]: false,
      });
    }
  }

  async uploadCashoutFile() {
    this.setState({
      isLoading: true,
      configDisable: true,
    });
    const responseObj = await liquidityService.postCashoutInjectFile(this.state.configFile);
    const { status, response } = responseObj;

    if (status === SUCCESS_STATUS) {
        this.setState({
          successMessage: `Your cashout upload processing has been sent successfully for approval`,
          isLoading: false,
          cashoutDisable: false,
        });
        setTimeout(function () {
          window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home/cashout-inject`;
        }, 3000);
   
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
        cashoutDisable: false,
      });
    }
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to='/home/cashout-inject' />;
    }

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />

        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <div class='row'>
            <div class='col-lg-6 mt-4'>
              <div class='kt-portlet'>
                <form class='kt-form kt-form--label-right'>
                  <div class='kt-portlet__body'>
                    <h5>Upload Cashout Transaction</h5>
                    <a
                      href={`${process.env.REACT_APP_POS_CONFIG_UPLOAD_TEMPLATE_URL}`}
                      target='_blank'
                      download
                    >
                      Download Template
                    </a>
                    <h5 style={{ marginTop: '15px' }}>Upload CSV File Only </h5>
                    <div style={{ marginTop: '20px' }}>
                      <FileUpload
                        disabled={this.state.configDisable}
                        fileErrorMessage={this.state.configError}
                        file={this.state.configFile}
                        readUploadedFile={this.readUploadedFile}
                        handleFileUpload={this.uploadCashoutFile}
                        type='config'
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
