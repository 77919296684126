import React from 'react'

export default class FileUpload extends React.Component {
    render() {
        return <React.Fragment>
            <div class="row" style={{marginBottom: '20px'}}>
                <div class="col-lg-10">
                    <input type="file" disabled={this.props.disabled ? this.props.disabled : ''} class="form-control" autoComplete="off" placeholder="Enter agent phone number" onChange={event => this.props.readUploadedFile(event, this.props.type)} />
                    {this.props.fileErrorMessage && <span class="error">{this.props.fileErrorMessage}</span>}
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <button disabled={!Object.keys(this.props.file).length > 0 || this.props.disabled} type="button" class="btn isw-button" onClick={this.props.handleFileUpload}>Submit</button>
                </div>
            </div>
        </React.Fragment>
    }
}