import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { formatDateTime } from '../../../../../utils/formatter'
import { getPaginationRows } from '../../../../../utils/helper'
import SpinnerComponent from '../../../../../components/spinner'
import Pagination from '../../../../../components/pagination'
import TableExtra from '../../../../../components/table-extra'
import ProtectedComponent from '../../../../../mixins/protected-component';

export default class NotificationGroupTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '430px',
        zoom: 'plus'
    }

    zoomTableHeight = this.zoomTableHeight.bind(this)

    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }

    render() {
        const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            {
                Header: "ID",
                accessor: 'id',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                // width: 100,
                Cell: props => props.value
            },{
                Header: "Query Name",
                accessor: 'name',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                // width: 100,
                Cell: props=> props.value
            },
            {
                Header: "Agent Match",
                accessor: 'match',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                // width: 100,
                Cell: props=> props.value
            },
            {
              Header: "Date",
              accessor: 'created',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              // width: 100,
              Cell: props=> props.value && formatDateTime(props.value)
          },
            {
                Header: "Action",
                accessor: 'agentCode',
                overflow: 'visible',
                resizable: false,
                sortable: false,
                headerStyle: {'white-space': 'unset'},
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      lineHeight: '10px',
                      textAlign: 'center',
                      whiteSpace: 'unset',
                      overflow: 'visible',
                    },
                  };
                },
                width: 60,
                Cell: ({row, original}) => <div class="dropdown">
                  <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                    <i class="la la-ellipsis-v"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    {/* <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`notification-group/${row.key}/view`} class="dropdown-item">
                      <i class="la la-eye"></i>
                      View
                    </Link></ProtectedComponent> */}
                    <ProtectedComponent requiredPermissions={[this.props.canCancel]}><a onClick={() => this.props.getClickedAction(row.id, row.name)} data-toggle="modal" data-target="#kt_modal_1" class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                      <i class="la la-close"></i>
                      Delete
                    </a></ProtectedComponent>
                  </div>
                </div>
            }
        ]

        return <React.Fragment>
            <TableExtra 
                //data={ApplicationExportSerializer.reverseApplicationDetails(this.props.data)}
                //headers={ApplicationExportSerializer.headerExport()}
                //fileName={'applications'}
                zoomTableHeight={this.zoomTableHeight}
                getData={this.props.getTopics}
                page={this.props.page}
                pageSize={this.props.pageSize}
                zoom={this.state.zoom}
                disabled={this.props.loading}
            />
            <ReactTable 
                PaginationComponent={Pagination}
                loading={this.props.loading}
                LoadingComponent={MyLoader}
                style={{
                    border: "none",
                    boxShadow: "none",
                    whiteSpace: 'unset',
                    height: this.state.tableHeight,
                }}
                data={this.props.data}
                columns={columns}
                dataCount={this.props.dataCount}
                noDataText={!this.props.loading ? this.props.noDataText : ''}
                pageSizes={getPaginationRows(this.props.dataCount)}
                headerNodeHeight={`123`}
                manual
                pages={this.props.pages}
                page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
                minRows={(this.props.data).length > 0 ? 10 : 10}
                onFetchData={(state, instance) => {
                    this.setState({page: state.page})
                    if(this.props.canLoadData) {
                        this.props.getTopics(state.page + 1,state.pageSize)
                    } 
                }}
                getTableProps={(state, rowInfo, column, instance) => ({
                    style: {
                        borderColor: '#00425f',
                        boxSizing: 'none',
                        border: 'none',
                        fontSize: '11.5px',
                    },
                })}
                // getTdProps={(state, rowInfo, column, instance) => ({
                //     style: {
                //       overflow: 'visible',
                //       //backgroundColor: '#FFFFFF',
                //       lineHeight: '20px',
                //     },
                // })}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    style: {
                        backgroundColor: '#00425f',
                        borderBottom: '0.1px solid #DBDEDE',
                        borderWidth: '0.1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        color: '#FFFFFF',
                        fontSize: '12px',
                        fontWeight: '500',
                        textAlign: 'left'
                    },
                })}
                getPaginationProps={(state, rowInfo, column, instance) => ({
                    style: {
                        overflow: 'visible',
                        backgroundColor: '#ffffff',
                    },
                })}    
                className="-striped -highlight" 
            />
        </React.Fragment>
          
    }
}