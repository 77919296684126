import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import DocumentTypeGridScene from './scenes/document-types-grid/index.js';
import { isAuthorized } from '../../../../../mixins/authentication.js'
import { CAN_VIEW_AGENT_CLASS, CAN_CREATE_AGENT_CLASS } from '../../../../../constants/permissions.js'
import AddDocumentTypeClassScene from './scenes/add-document-types/index.js';

export default class DocumentTypeScene extends Component {
  sceneName = 'document-type'

  componentDidMount() {
    this.props.updateScene(this.sceneName)
  }

  render() {
    const ProtectedRoute = ({ component: Component, requiredPermissions, ...rest }) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT_CLASS]} exact path='/home/document-type' component={DocumentTypeGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_AGENT_CLASS]} exact path='/home/document-type/add' component={AddDocumentTypeClassScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_AGENT_CLASS]} exact path='/home/document-type/edit' component={AddDocumentTypeClassScene} />
    </Router>
  }
}