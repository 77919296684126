import Country from "../fixtures/countries";
import DomainTypes from "../fixtures/domain-types";
import Lga from "../fixtures/lgas";
import ServiceTypes from "../fixtures/service_types";
import State from "../fixtures/states";
import UserStatuses from "../fixtures/user_statuses";

export function stripNonNumbers(str) {
  return str.replace(/\D/g, "");
}

export function stripNotAllowedCharactersFromString(str) {
  return str.replace(/[!@#$%^&*(),.?":{}|_`¬<>+=£\\/;[\]]~/g, "");
}

export function stripNumbersFromString(str) {
  return str.replace(/[0-9]/g, "");
}

export function stripSpacesFromString(str) {
  return str.replace(/ /g, "");
}

export function splitNameWithUnderscore(name) {
  return name.replace(/ /g, "_");
}

export function getSanefBankByBankCode(BankList, bankCode) {
  const bank = BankList.filter((value) => value.bankCode == bankCode);
  return bank;
}

export function getStatesByCountryId(countryId) {
  const states = State.filter((value) => value.countryId == countryId);
  return states;
}

export function getLgasByStateId(stateId) {
  const lgas = Lga.filter((value) => value.stateId == stateId);
  return lgas;
}

export function getCountryName(countryId) {
  const country = Country.filter((value) => value.id == countryId);
  return country[0] ? country[0].name : "";
}

export function getStateName(countryId, stateId) {
  const states = getStatesByCountryId(countryId);
  const state = states.filter((value) => value.id == stateId);
  return state[0] ? state[0].name : "";
}

export function getLgaName(stateId, lgaId) {
  const lgas = getLgasByStateId(stateId);
  const lga = lgas.filter((value) => value.id == lgaId);
  return lga[0] ? lga[0].name : "";
}

export function getUserStatusName(statusId) {
  const status = UserStatuses.filter((value) => value.id == statusId);
  return status[0] ? status[0].name : "";
}

export function capitalizeFirstLetter(string, useDefaultFormat = true) {
  if (string == null) {
    return null;
  }
  return useDefaultFormat
    ? `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`
    : `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function getDateByType(type = null) {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(23, 59, 59, 59);
  return type == "startDate"
    ? fromDate
    : type == "endDate"
    ? toDate
    : new Date();
}

export function addDays(date, days) {
  let newDate = new Date(date);
  return newDate.setDate(newDate.getDate() + days);
}

export function subtractDays(date, days) {
  let newDate = new Date(date);
  return newDate.setDate(newDate.getDate() - days);
}

export function reducedDataExport(count) {
  if (count > 10000) {
    return 10000;
  } else {
    return count;
  }
}

export function canRequerySettlement(transactionDate) {
  const today = new Date();
  transactionDate = new Date(transactionDate);
  return (
    transactionDate.getDate() == today.getDate() &&
    transactionDate.getMonth() == today.getMonth() &&
    transactionDate.getFullYear() == today.getFullYear()
  );
}

export function getApplicationType(agentTypeId) {
  if (agentTypeId === 4) {
    return (agentTypeId = "Agent");
  } else if (agentTypeId === 3) {
    return (agentTypeId = "Aggregator");
  } else {
    return "";
  }
}

export function explodeDate(date, type) {
  return date.split(type)[0];
}

export function explodeString(string, explodeType) {
  const stringArray = string.split(explodeType);

  let explodedString = "";

  for (let i = 0; i < stringArray.length; i++) {
    explodedString += `${stringArray[i].charAt(0).toUpperCase()}${stringArray[i]
      .substr(1)
      .toLowerCase()} `;
  }

  return explodedString;
}

export function getDomainTypeName(domainTypeId) {
  //return DomainTypes.map(value=> value.id == domainTypeId)
  const domainType = DomainTypes.filter((value) => value.id === domainTypeId);
  return domainType[0] ? domainType[0].name : "";
}

export function hideText(text, showDecimal = true) {
  if (showDecimal) {
    return "*****.**";
  } else {
    return "*******";
  }
}

export function getPaginationRows(count) {
  let rows = [10, 20, 25, 50, 100];
  return rows;
}

export function getTransactionTypeName(
  transactionTypeInt,
  fieldName = "label"
) {
  const transactionType = ServiceTypes.filter(
    (serviceType) => serviceType.value === transactionTypeInt
  );
  return transactionType[0] ? transactionType[0][fieldName] : "";
}

export function getTransactionTypeLabel(
  transactionTypeName,
  fieldName = "label"
) {
  const transactionType = ServiceTypes.filter(
    (serviceType) => serviceType.name === transactionTypeName
  );
  return transactionType[0] ? transactionType[0][fieldName] : transactionTypeName;
}

export function getTransactionTypeInt(transactionTypeName) {
  const transactionType = ServiceTypes.filter(
    (serviceType) => serviceType.label === transactionTypeName
  );
  return transactionType[0] ? transactionType[0].id : "";
}

export function absoluteValue(value) {
  return Math.abs(value);
}

export function toDecimal(value, point) {
  return parseFloat(value).toFixed(point);
}

export function replaceSpaceWithUnderScore(name) {
  return name.replace(/ /g, "_");
}

export function replaceUnderscoreWithSpace(name) {
  return name.replace(/_/g, " ");
}

export function toPermissionArray(permissions) {
  let newPermissionsArray = [];
  permissions.map((value) => {
    newPermissionsArray.push(value.name);
  });
  return newPermissionsArray;
}

export function toJsonData(time, value) {
  let object = {};
  object.time = time;
  object.value = value;
  return object;
}

export function mapJson(agents) {
  let newArray = [];
  agents.map((value) => {
    if (value && value.businessLocation) {
      let myArray = {};
      myArray["latitude"] = value.businessLocation[0].latitude;
      myArray["longitude"] = value.businessLocation[0].longitude;
      myArray["businessName"] = value.businessName;
      newArray.push(myArray);
    }
  });
  return newArray;
}

export function trimPhoneNumber(phoneNumber) {
  if (phoneNumber.length >= 14) {
    return `${phoneNumber.slice(0, 3)}${phoneNumber.slice(4)}`;
  } else {
    return phoneNumber;
  }
}

export function sentenceCapitalization(text) {
  let sentence = text.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1) + " ";
  }
  return sentence;
}

export function splitStringByUppercase(string) {
  return string.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
}

export function splitStringByUnderscore(string) {
  return string.match(/([^_]+)/g).join(" ");
}

export function isPhoneNumberValid(phoneNumber) {
  phoneNumber = stripSpacesFromString(phoneNumber.slice(3));
  return phoneNumber.length === 10 || phoneNumber.length === 11;
}

export function getCachedItem(key) {
  return localStorage.getItem(key);
}

export function saveCachedItem(key, value) {
  return localStorage.setItem(key, value);
}

export function isAgentRepush(value) {
  console.log("supplied value is ", value);
  return value.indexOf("AG") > -1 || value.indexOf("SA") > -1;
}
