import React from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import BaseScene from '../../../../../../components/scenes/component'
import DomainRoleGridScene from './scenes/role-grid'
import DomainRoleViewScene from './scenes/view-role'
import DomainRoleAddScene from './scenes/add-role'
// import ChangePasswordGridScene from './scenes/change-password'
// import AccountSettingsGridScene from './scenes/account-settings'
// import UserSettingsGridScene from './scenes/user-settings'
import { isAuthorized } from '../../../../../../mixins/authentication'
import { 
  CAN_VIEW_AGENT_ROLE, CAN_CREATE_AGENT_ROLE, CAN_VIEW_AGGREGATOR_ROLE,
  CAN_CREATE_AGGREGATOR_ROLE, CAN_VIEW_SP_ROLE, CAN_CREATE_SP_ROLE 
} from '../../../../../../constants/permissions'

export default class DomainRoleScene extends BaseScene {
  sceneName = 'role-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SP_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_AGENT_ROLE]} exact path='/home/domain-management/:domainCode/roles' component={DomainRoleGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SP_ROLE, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_AGENT_ROLE]} exact path='/home/domain-management/:domainCode/roles/view' component={DomainRoleViewScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_SP_ROLE, CAN_CREATE_AGGREGATOR_ROLE, CAN_CREATE_AGENT_ROLE]} exact path='/home/domain-management/:domainCode/roles/add' component={DomainRoleAddScene} />
      {/* <Route exact path='/home/profile/change-password' component={ChangePasswordGridScene} />
      <Route exact path='/home/profile/account-settings' component={AccountSettingsGridScene} />
      <Route exact path='/home/profile/user-settings' component={UserSettingsGridScene} /> */}
    </Router>
  }
}
