import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import AuditActions from '../../../../../../fixtures/audit_actions.json';
import AuditEntities from '../../../../../../fixtures/audit_entities.json';
import { capitalizeFirstLetter, getDateByType } from '../../../../../../utils/helper';

export default class AuditSearch extends React.Component {
    state = {
        searchParams: {
            fromDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
            toDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
            auditEntity: "",
            auditType: "",
            auditAction: ""
        }
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest(this.state.searchParams)
    }

    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="search-label">ID :</label>
                        <input type="text" placeholder="Audit Id" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, auditId: event.target.value ? event.target.value : null}})} />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">User:</label>
                        <input type="text" placeholder="Email address..." class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, username: event.target.value ? event.target.value : null}})} />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Audit Entity:</label>
                        <Select 
                            options={AuditEntities}
                            onChange={(event) => this.setState({searchParams: {...this.state.searchParams, auditEntity: event.value.toUpperCase()}})}
                            defaultValue={capitalizeFirstLetter(this.state.searchParams.auditEntity)}
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Action:</label>
                        <Select 
                            options={AuditActions}
                            onChange={(event) => this.setState({searchParams: {...this.state.searchParams, auditAction: event.value.toUpperCase()}})}
                            defaultValue={capitalizeFirstLetter(this.state.searchParams.auditAction)}
                        />
                    </div>
                </div>
                <div class="form-group row">
                    {/* <div class="col-md-3">
                        <label class="search-label">Audit Type:</label>
                        <Select 
                            options={AuditTypes}
                            onChange={(event) => this.setState({searchParams: {...this.state.searchParams, auditType: event.value.toUpperCase()}})}
                            defaultValue={this.state.searchParams.auditType}
                        />
                    </div> */}
                    <div class="col-md-3">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.setState({searchParams: {...this.state.searchParams, fromDate: event}})}
                            size={`170px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                            //selected={this.props.searchParams.fromDate}
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent
                            onChange={(event) => this.setState({searchParams: {...this.state.searchParams, toDate: event}})}
                            size={`170px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.searchParams.fromDate ? false : true}
                            minDate={new Date(this.state.searchParams.fromDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                            //selected={this.state.searchParams.toDate ? this.state.searchParams.toDate : this.props.searchParams.toDate}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}