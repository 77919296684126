import React from "react";
import "./index.css";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseScene from "../../../../../components/scenes/component";
import ConfigUploadGridScene from "../upload-config/scenes/config-upload-grid/scene";
import UploadConfigGridScene from '../upload-config/scenes/upload-config-grid';
// import RequestHeader from "./components/header";

export default class ConfigManagementScene extends BaseScene {
  sceneName = "config-manage";

  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };
  }

  componentDidMount() {
    // this.props.updateScene(this.sceneName)
    //this.domainCode = this.props.match.params.domainCode
    // this.loadApplication();
  }

  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };
  render() {
    
    return (
      <>
        <div className="col-md-12" style={{marginTop:"-80px"}}>
          <section id="open-positions">
            <div className="positions">
              <div className="position-filters">
                <label
                  className={
                    this.state.toggleState === 1
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All
                </label>
                <label
                  className={
                    this.state.toggleState === 2
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Upload Config
                </label>
              
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                <ConfigUploadGridScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 2
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <UploadConfigGridScene />
                </div>
              </div>

            
            </div>
          </section>
        </div>
      </>
     
    );
  }
}