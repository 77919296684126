import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { CAN_VIEW_AUDIT } from "../../../../../../../constants/permissions";
import { auditService } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import AuditData from "../../components/audit-data";
import AuditDetails from "../../components/audit-details";

export default class ViewAuditReportScene extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      auditDetails: [],
      disabled: true,
    };
  }

  componentDidMount() {
    this.auditId = this.props.match.params.id;
    this.getAudit();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getAudit() {
    this.setState({ isLoading: true });
    const responseObj = await auditService.getAuditById(this.auditId);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        auditDetails: response.data,
        isLoading: false,
      });
    } else {
      this.setState({
        auditDetails: [],
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ContentHead
          title={"Audit Report"}
          filterPermissions={[CAN_VIEW_AUDIT]}
          withoutSearch
          subtitle={``}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AUDIT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <Loader isLoading={this.state.isLoading} />
          <Notification
            ref={this.notificationSectionRef}
            successMessage={this.state.successMessage}
            errorMessage={this.state.errorMessage}
          />
          <GridLayout>
            <AuditDetails
              auditDetails={this.state.auditDetails}
              disabled={this.state.disabled}
            />

            {this.state.auditDetails.dataAfter && (
              <AuditData auditData={this.state.auditDetails.dataAfter} />
            )}
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
