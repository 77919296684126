export default class BusinessDetailsSerializer {
  static serialize (formData) {
    return {
      ...formData
    }
  }

  static serializeForApplication (formData) {
    return {
      ...formData
    }
  }

  static reverseSerializeFromApplication (formData) {
    return {
      ...formData
    }
  }
}
