import React from 'react'

export const FormLayout = (props) => {
  const { title, children } = props;
  return <>
    <div class="kt-login__body">
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>{title}</h3>
        </div>
        {children}
      </div>
    </div>
  </>
}