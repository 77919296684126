import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  CAN_VIEW_COMMISSION_SPLIT_RULE,
  CAN_CREATE_COMMISSION_SPLIT_RULE,
  CAN_ACTIVATE_COMMISSION_SPLIT_RULE,
  CAN_DEACTIVATE_COMMISSION_SPLIT_RULE,
  CAN_DELETE_COMMISSION_SPLIT_RULE,
} from "../../../../../../../constants/permissions.js";
import { AccessDenied } from "../../../../../../../components/access-denied";
import {
  SUCCESS_STATUS,
  ERROR_STATUS,
  ACCEPTED_REQUEST,
} from "../../../../../../../constants/api";
import RevenueSharingTable from "../../components/revenue-sharing-table";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import AccountTypes from "../../../../../../../fixtures/account_types.json";
import { splitNameWithUnderscore } from "../../../../../../../utils/helper";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import RevenueSharingForm from "../../components/revenue-sharing-form";
import {
  pastTenseFormatter,
  getFormattedStatus,
} from "../../../../../../../utils/formatter";

export default class RevenueSharingGridScene extends BaseScene {
  requiredPermissions = [CAN_VIEW_COMMISSION_SPLIT_RULE];

  notificationSectionRef = React.createRef(null);

  getRevenueSharingParties = this.getRevenueSharingParties.bind(this);
  updateStatus = this.updateStatus.bind(this);
  getClickedAction = this.getClickedAction.bind(this);
  onSaveButtonClick = this.onSaveButtonClick.bind(this);
  onAddButtonClick = this.onAddButtonClick.bind(this);
  updatePartyFormData = this.updatePartyFormData.bind(this);

  state = {
    revenueSharingParties: [],
    accountTypes: [],
    partyFormData: [],
    isLoading: true,
  };

  componentDidMount() {
    this.setState({ accountTypes: AccountTypes });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });

    const responseObj = await feeConfigurationManagement.createRevenueSharingParty(
      this.state.partyFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new revenue sharing party has been sent successfully for approval.",
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Revenue sharing party created successfully.",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    this.getRevenueSharingParties();
  }

  async updateStatus() {
    this.setState({ isLoading: true });
    let responseObj = {};
    let approvalMessage = "";
    let message = "";
    if (this.state.partyStatus === "delete") {
      responseObj = await feeConfigurationManagement.removeRevenueSharingParty(
        this.state.partyId
      );
      approvalMessage = `Your request to delete ${this.state.partyName} as a revenue sharing party has been sent successfully for approval.`;
      message = `${this.state.partyName} was successfully ${pastTenseFormatter(
        this.state.partyStatus
      )} as a revenue sharing party.`;
    } else {
      responseObj = await feeConfigurationManagement.updateRevenueSharingPartyStatus(
        this.state.partyId,
        this.state.partyStatus
      );
      approvalMessage = `Your request to ${getFormattedStatus(
        this.state.partyStatus
      )} ${
        this.state.partyName
      } as a revenue sharing party has been sent successfully for approval.`;
      message = `${this.state.partyName} was successfully ${pastTenseFormatter(
        getFormattedStatus(this.state.partyStatus)
      )} as a revenue sharing party.`;
    }
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: approvalMessage,
        });
        return;
      } else {
        this.setState({
          successMessage: message,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getRevenueSharingParties();
    setTimeout(() => {
      this.setState({
        partyId: null,
        partyStatus: null,
        partyName: null,
      });
    }, 3000);
  }

  async getRevenueSharingParties(pageNum, pageSize) {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getRevenueSharingParties(
      pageNum,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        revenueSharingParties: response ? response.data : [],
        dataCount: count,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      });
    } else {
      this.setState({
        revenueSharingParties: [],
        dataCount: 0,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  onAddButtonClick() {
    this.setState({
      canUpdateStatus: false,
      canAdd: true,
      modalHeight: "450px",
      modalTitle: "New Revenue Sharing Party",
      partyFormData: [],
    });
  }

  getClickedAction(status, partyId, partyName) {
    this.setState({
      partyId: partyId,
      partyStatus: status,
      partyName,
      canUpdateStatus: true,
      canAdd: false,
      modalHeight: "230px",
    });
  }

  isFormValid() {
    return Boolean(
      !this.state.partyFormData.name || !this.state.partyFormData.type
    );
  }

  updatePartyFormData(type, value) {
    this.setState({
      partyFormData: { ...this.state.partyFormData, [type]: value },
    });
  }

  render() {
    const addButton = (
      <React.Fragment>
        <ProtectedComponent
          requiredPermissions={[CAN_CREATE_COMMISSION_SPLIT_RULE]}
        >
          <button
            type="button"
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            data-toggle="modal"
            data-target="#kt_modal_1"
            onClick={this.onAddButtonClick}
          >
            Add Revenue Party
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    const modalForm = (
      <React.Fragment>
        <RevenueSharingForm
          accountTypes={this.state.accountTypes}
          onChange={this.updatePartyFormData}
          partyFormData={this.state.partyFormData}
          class="col-lg-12"
          disableName={false}
        />
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Revenue Sharing Parties"}
          ctaWidgets={addButton}
          filterPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]}
          totalCount={56}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]}
        >
          <GridLayout>
            <RevenueSharingTable
              getRevenueSharingParties={this.getRevenueSharingParties}
              pages={this.state.pages}
              data={this.state.revenueSharingParties}
              updateStatus={this.getClickedAction}
              loading={this.state.isLoading}
              noDataText={`No revenue sharing parties found`}
              dataCount={this.state.dataCount}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canActivate={CAN_ACTIVATE_COMMISSION_SPLIT_RULE}
              canDeactivate={CAN_DEACTIVATE_COMMISSION_SPLIT_RULE}
              canDelete={CAN_DELETE_COMMISSION_SPLIT_RULE}
              canView={CAN_VIEW_COMMISSION_SPLIT_RULE}
            />
            <PromptModal
              modalTitle={
                this.state.canAdd
                  ? this.state.modalTitle
                  : this.state.partyStatus + ` Revenue Sharing Party`
              }
              modalBody={
                this.state.canAdd
                  ? modalForm
                  : `Do you want to ${this.state.partyStatus} ${this.state.partyName} as a revenue sharing party?`
              }
              deleteModal={
                this.state.canAdd ? this.onSaveButtonClick : this.updateStatus
              }
              modalSize={`modal-md`}
              height={this.state.modalHeight}
              modalFooterButton1={"Cancel"}
              modalFooterButton2={this.state.canAdd ? "Submit" : "Ok"}
              hideModalHeader={true}
              hideModalFooter={true}
              textAlign={this.state.canAdd ? "left" : ""}
              modalFooterButton2Disabled={
                this.state.canAdd ? this.isFormValid() : false
              }
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
