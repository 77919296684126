import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RevenueSharingGridScene from './scenes/revenue-sharing-grid';
import ViewRevenueSharingScene from './scenes/view-revenue-sharing';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_COMMISSION_SPLIT_RULE } from '../../../../../constants/permissions.js'

export default class RevenueSharingScene extends Component {
  sceneName = 'revenue-sharing'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]} exact path='/home/revenue-sharing' component={RevenueSharingGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]} exact path='/home/revenue-sharing/:id/view' component={ViewRevenueSharingScene} />
    </Router>
  }
}
