export default class DeclineReasonCategoriesExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'categoryName', title: 'Name', dataIndex: 'categoryName'},
            {label: 'Status', key: 'inUse', title: 'Status', dataIndex: 'inUse'},
            {label: 'Date Created', key: 'createdOn', title: 'Date Created', dataIndex: 'createdOn'},
            {label: 'Last Modified Date', key: 'lastModifiedOn', title: 'Last Modified Date', dataIndex: 'lastModifiedOn'}
        ]
    }

    static reverseDeclineReasonCategoryDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { categoryName, inUse, createdOn, lastModifiedOn } = details
            let detailFormData = {
                categoryName,
                inUse: inUse === false ? 'Inactive' : inUse === true ? 'Active' : '',
                createdOn,
                lastModifiedOn
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}