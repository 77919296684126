import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_TRANSACTION } from '../../../../../../../constants/permissions.js';
import { transactionHistory } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { formatBackEndDateTime } from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import TransactionSummarySearch from '../../components/transaction-summary-search';
import TransactionSummaryTable from '../../components/transaction-summary-table';

export default class TransactionSummaryGridScene extends BaseScene {
  
  sceneName = 'transaction-summary'

  transactionDetailsSectionRef = React.createRef(null)
  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      transactionSummary: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
        agent: 0,
        transactionTypeInt: "",
        statusCodeInt: ""
      },
      pages: 1,
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      pageNum: 1,
      pageSize: 20,
      tableMessage: ""
    }

    this.getTransactionSummary = this.getTransactionSummary.bind(this)
    this.downloadTransactionSummary = this.downloadTransactionSummary.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this)
    this.handleSearchRequest = this.handleSearchRequest.bind(this)
    this.downloadTransactionSummaryDetails = this.downloadTransactionSummaryDetails.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getTransactionSummary (pageNum, pageSize) {
    let payload = this.state.searchParameters
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    payload.startDate = payload.startDate && formatBackEndDateTime(payload.startDate)
    this.setState({
      pageNum,
      pageSize,
      isLoading: true
    })
    const { status, response, count } = await transactionHistory.getTransactionSummary(pageNum, pageSize, payload);
    if (status === SUCCESS_STATUS) {
      this.setState({
        transactionSummary: response ? response : [],
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count ? count: response.length,
        isLoading: false,
      })
    } else {
      this.setState({
        transactionSummary: [],
        dataCount: 0,
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No transaction history found"
      })
    }
  }

  handleSearchRequest () {
    this.getTransactionSummary(this.state.pageNum, this.state.pageSize)
  }

  updateSearchParameters (type, value) {
    console.log(type,value)
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  async downloadTransactionSummary() {
    this.setState({
      isLoading: true,
      canLoadData: true
    })

    let payload = this.state.searchParameters;
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    payload.startDate = payload.startDate && formatBackEndDateTime(payload.startDate)

    const responseObj = await transactionHistory.downloadTransactionSummary(this.state.page, this.state.dataCount, payload)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([ response ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a');
      a.setAttribute('download', 'Transaction Summary Report.xls');
      a.setAttribute('href', window.URL.createObjectURL(blob));
      a.click();
      this.setState({
          isLoading: false
      })
    }else{
        this.setState({
            errorMessage: response,
            isLoading: false
        })
    }
  }

  async downloadTransactionSummaryDetails() {
    this.setState({
      isLoading: true,
      canLoadData: true
    })

    let payload = this.state.searchParameters;
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    payload.startDate = payload.startDate && formatBackEndDateTime(payload.startDate)

    const responseObj = await transactionHistory.downloadTransactionSummaryDetails(this.state.page, this.state.dataCount, payload)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([ response ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a');
      a.setAttribute('download', 'Transaction Summary Details Report.xls');
      a.setAttribute('href', window.URL.createObjectURL(blob));
      a.click();
      this.setState({
          isLoading: false
      })
    }else{
        this.setState({
            errorMessage: response,
            isLoading: false
        })
    }
  }

  render () {
    const modalBody = <React.Fragment>
      <p>{`Do you want to resolve this transaction ${this.state.transRef} as`}</p>
      <div class="form-group row">
        <div class="col-lg-5">
            <div class="col-12">
                <div class="kt-radio-inline">
                    <label class="kt-radio">
                        <input autoComplete="off" type="radio" name="gender" value="1" checked={this.state.desiredStatus == 1} onChange={event => this.setState({desiredStatus: event.target.value})}/> Success
                        <span></span>
                    </label>
                    <label class="kt-radio">
                        <input autoComplete="off" type="radio" name="gender" value="0" checked={this.state.desiredStatus == 0} onChange={event => this.setState({desiredStatus: event.target.value})}/> Fail
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>

    return <React.Fragment>
      <ContentHead title={"Transaction Summary"} filterPermissions={[CAN_VIEW_TRANSACTION]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_TRANSACTION]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <TransactionSummarySearch 
            searchParameters={this.state.searchParameters}
            updateSearchParameters={this.updateSearchParameters}
            handleSearchRequest={this.handleSearchRequest}
            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
          />
          <TransactionSummaryTable 
            pages={this.state.pages}
            getTransactionSummary={this.getTransactionSummary}
            data={this.state.transactionSummary}
            startPage={this.state.startPage}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            page={this.state.pageNum}
            pageSize={this.state.pageSize}
            searchParameters={this.state.searchParameters}
            responseCode={this.state.searchParameters.responseCode}
            canView={CAN_VIEW_TRANSACTION}
            canLoadData={this.state.canLoadData}
            downloadTransactionSummary={this.downloadTransactionSummary}
            downloadTransactionSummaryDetails={this.downloadTransactionSummaryDetails}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
