import React from 'react'
import { formatCurrency } from '../../../../../../utils/formatter'
import { convertToMajor } from '../../../../../../utils/converter'

export default class WalletBalanceSummary extends React.Component {
    render() {
        return <React.Fragment>
            {this.props.canDistribute && <div>
                <div class="form-group col-lg-6 row ml-auto">
                    <div class="col-lg-7">
                        <span><strong>Total Amount Available (In Wallet) - </strong></span>
                    </div>
                    <div class="col-lg-5">
                        <span><strong>&#8358;{formatCurrency(convertToMajor(this.props.availableWalletBalance))}</strong></span>
                    </div>
                </div>
                <div class="form-group col-lg-6 row ml-auto">
                    <div class="col-lg-7">
                        <span><strong>Total Amount To Distribute - </strong></span>
                    </div>
                    <div class="col-lg-5">
                        <span><strong>&#8358;{formatCurrency(convertToMajor(this.props.totalDistribute))}</strong></span>
                    </div>
                </div>
                <div class="form-group col-lg-6 row ml-auto">
                    <div class="col-lg-7">
                        <span><strong>New Wallet Balance - </strong></span>
                    </div>
                    <div class="col-lg-5">
                        <span><strong>&#8358;{formatCurrency(convertToMajor(this.props.walletBalance))}</strong></span>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    }
}