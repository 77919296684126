import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
//import PDFViewer from 'pdf-viewer-reactjs'
const DOC_FORMATs = ['pdf'] ;
const IMAGE_FORMATS = ['png', 'jpg', 'jpeg', 'bimp'];

export default class Thumbnail extends Component {
  state = {
    isOpen: false,
    isPdfOpen: false,
    isFileOpen: false,
  }

  fileIsAnImage () {
    const splittedFilename = (this.props.filename.split('.'))
    return IMAGE_FORMATS.includes(splittedFilename[splittedFilename.length-1])
  }

  fileIsADocument () {
    const splittedFilename = (this.props.filename.split('.'))
    return DOC_FORMATs.includes(splittedFilename[splittedFilename.length-1])
  }

  getUpdatedSrc () {
    return this.state.src
  }

  render () {
    return <React.Fragment>
      
      {this.fileIsADocument() && 
    
      <div data-toggle="modal" data-target="#kt_modal_pdf_viewer" onClick={() => this.props.getDocumentClickName(this.props.filename, this.props.src)} style={{width: 150, height: 150, padding: '5px', fontSize: '20px', textAlign: 'center', cursor: 'pointer'}}>
        <i class="fas fa-file-pdf fa-7x"></i><br/>
      </div>
      }

      {this.fileIsAnImage() && <div>
        <img {...this.props}  onClick={() => this.setState({isOpen: true})} />
        {(this.state.isOpen && this.props.showPreview) && (
          <Lightbox
            mainSrc={this.props.src}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>}
      <div style={{fontSize: '10px'}}>{this.props.filename}</div>
      {/* <a href="https://www.facebook.com/myfile.pdf" download>Click to Download</a> */}
      <a href={this.props.src} target="_blank" download="filename" style={{display: this.props.showDownloadButton ? 'block' : 'none'}}><strong>Download</strong></a>
    </React.Fragment>
  }
}