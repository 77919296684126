import React from 'react';
import FeeSplits from '../../../../../../components/fee-splits';
import { transactionExtraSerializer } from '../../../../../../serializers/transaction-extras';
import { convertToMajor } from '../../../../../../utils/converter';
import { formatCurrency, formatDateTime, formatTransactionStatus } from '../../../../../../utils/formatter';
import { getTransactionTypeName } from '../../../../../../utils/helper';
import AccountOpening from './transaction-details-account-opening';
import TransactionReversalDetails from './transaction-reversal-details';

const FeeSplit = (props) => {
    if(formatTransactionStatus(props.transactionDetails.statusCode ? props.transactionDetails.statusCode : '') === 'Successful' && (props.transactionDetails.transactionTypeInt < 10)) {  
        return <div class="form-group row">
            <FeeSplits 
                feeSplits={props.parties} 
                isLoading={props.isLoading}
            />
        </div>
    }
    else return null
}

const Reversal = (props) => {
    if(formatTransactionStatus(props.transactionDetails.statusCode ? props.transactionDetails.statusCode : '') === 'Failed' && (props.transactionDetails.transactionTypeInt < 10)) {
        return <div class="form-group row">
            <TransactionReversalDetails 
                reversalDetails={props.reversal}
                isLoading={props.isLoading}
            />
        </div>
    }
    else return null
}

export default class TransactionDetailsModal extends React.Component {
    render() {
        console.log('Transaction Modal ', this.props.transactionDetails.extraInfoList);
        const extras = this.props.transactionDetails.extraInfoList ? this.props.transactionDetails.extraInfoList : []
    
        return <React.Fragment>
            <div class="modal" id="transaction_details_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
                <div class="modal-dialog modal-lg" style={{overflowY: 'scroll', overflowX : 'auto',fontSize: '14px'}}> 
                    <div class="modal-content" style={{borderRadius: '15px'}}> 
                        <div class="modal-header"> 
                            <h3 class="modal-title" style={{textAlign: 'center'}}>Transaction Details</h3> 
                        </div> 
                        <div class="modal-body fluid" style={{height: '400px'}}>
                            <div class="kt-portlet" ref={this.props.transactionDetailsSectionRef}>
                                <form class="kt-form kt-form--label-right collapse show" id="collapseModalOne1" aria-labelledby="headingOne1">
                                    <div class="kt-portlet__body">
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Transaction Reference</strong></label><br/>
                                                <span>{this.props.transactionDetails.transactionRef}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Agent Name</strong></label><br/>
                                                <span>{this.props.transactionDetails.agentName}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Amount</strong></label><br/>
                                                <span>&#x20A6;{formatCurrency(this.props.transactionDetails.transactionAmount ? convertToMajor(this.props.transactionDetails.transactionAmount) : 0)}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Fee</strong></label><br/>
                                                <span>&#x20A6;{formatCurrency(this.props.transactionDetails.fee ? convertToMajor(this.props.transactionDetails.fee) : 0)}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Transaction Type</strong></label><br/>
                                                <span>{this.props.transactionDetails.transactionTypeInt ? getTransactionTypeName(this.props.transactionDetails.transactionTypeInt) : ''}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Date</strong></label><br/>
                                                <span>{this.props.transactionDetails.dateCreated ? formatDateTime(this.props.transactionDetails.dateCreated) : ''}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Business Name</strong></label><br/>
                                                <span>{this.props.transactionDetails.businessName}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Business Phone Number</strong></label><br/>
                                                <span>{this.props.transactionDetails.businessMobileNo}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Narration</strong></label><br/>
                                                <span>{this.props.transactionDetails.narration}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Status</strong></label><br/>
                                                <span>{formatTransactionStatus(this.props.transactionDetails.statusCode ? this.props.transactionDetails.statusCode : '')}</span>
                                            </div>
                                        </div>
                                        <AccountOpening data={this.props.transactionDetails}/>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Payment Method</strong></label><br/>
                                                <span>{this.props.transactionDetails.paymentMethod}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Payment Item Code</strong></label><br/>
                                                <span>{this.props.transactionDetails.paymentItemCode}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Service Provider Resp Code (Initial)</strong></label><br/>
                                                <span>{this.props.transactionDetails.serviceProviderResponseCode}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Service Provider Resp Code (Final)</strong></label><br/>
                                                <span>{this.props.transactionDetails.responseCode}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Service</strong></label><br/>
                                                <span>{this.props.transactionDetails.service}</span>
                                            </div>
                                            <div class="col-lg-6">
                                                <label><strong>Service Option</strong></label><br/>
                                                <span>{this.props.transactionDetails.serviceOptionName}</span>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <div class="col-lg-6">
                                                <label><strong>Wallet Provider Response Code</strong></label><br/>
                                                <span>{this.props.transactionDetails.walletProviderResponseCode}</span>
                                            </div>
                                            {Boolean(extras) && Object.entries(transactionExtraSerializer(extras)).map(([key, value]) =><div class="col-lg-6">
                                                <label><strong>{key}</strong></label><br/>
                                                <span>{value}</span><br /><br />
                                            </div>)}
                                        </div>

                                        <FeeSplit parties={this.props.settlementDetails.parties} isLoading={this.props.isSettlementLoading} transactionDetails={this.props.transactionDetails}/>
                                        <Reversal reversal={this.props.reversalDetails} isLoading={this.props.isReversalDetailLoading} transactionDetails={this.props.transactionDetails}/>
                                    </div>
                                    {/* {(formatTransactionStatus(this.props.transactionDetails.statusCode ? this.props.transactionDetails.statusCode : '') == 'Successful' && this.props.transactionDetails.transactionTypeInt != 10) ? 
                                        <a class="pull-right" href={`/home/reporting/settlement-report/view?transRef=${this.props.transactionDetails.transactionRef}`}>View Settlement Details</a>
                                    : ''}
                                    <br/> */}
                                </form>
                            </div>
                        </div>  
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" style={{height: '40px', width:'100px', padding: '0px', borderRadius: '15px', borderColor: '#00425f', backgroundColor: '#00425f', color: '#ffffff', fontSize: '14px', margin: 'auto', display: 'block'}} data-dismiss="modal">Close</button>
                        </div>
                    </div> 
                </div> 
            </div>
        </React.Fragment>
    }
}