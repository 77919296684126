import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_UPDATE_COUNTRY, CAN_VIEW_COUNTRY } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import CountryForm from '../../components/country-form';

export default class ViewCountryScene extends BaseScene {
  
  constructor (props) {
    super(props)

    this.state = {
      canSave: null,
      countries: [],
      updateButton: true,
      saveButton: false,
      disabled: true,
      pageTitle: "View Country",
      statuses: [
        {"id": 1, "name": "Active"},
        {"id": 2, "name": "Inactive"}
      ]
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

  componentDidMount() {
    this.currencyId = this.props.match.params.id
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onUpdateButtonClick () {
    this.setState({
        disabled: false,
        saveButton: true,
        updateButton: false,
        pageTitle: 'Update Country',
    })
  }

  async onSaveButtonClick () {
    
  }

  updateSetState (type, value) {
    this.setState({countryFormData: {...this.state.countryFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
        {this.state.updateButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_COUNTRY]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
        {this.state.saveButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_COUNTRY]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Country"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_COUNTRY]}>
        <GridLayout>
          <CountryForm 
            onChange={this.updateSetState}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
