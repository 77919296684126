import React from 'react'

export const DeclineDetails = (props) => {
    const { 
        toShowDeclineReason, showContent, application:{ declineReasonCategoryName, declineReason } 
    } = props;
    return <>
        {toShowDeclineReason && <div class="kt-portlet">
            <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target="#collapseOne6" aria-controls="collapseOne6">
                <div class="kt-portlet__head-label" role="button" aria-expanded="true">
                    <h3 class="kt-portlet__head-title">
                        Reason for Decline
                    </h3>
                </div>
            </div>

            <form class="kt-form kt-form--label-right" id="collapseOne6" class={`collapse ${showContent ? 'show' : ''}`} aria-labelledby="headingOne4">
                <div class="kt-portlet__body">
                    <div class="form-group row">
                        <div class="col-lg-8">
                            <label class="">Decline Reason Category</label>
                            <p>{declineReasonCategoryName}</p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-8">
                            <label class="">Decline Reason</label>
                            <p>{declineReason}</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>}
    </>
}