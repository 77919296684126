import React from 'react';
import { convertToMajor } from '../../../../../../utils/converter';
import { formatCurrency, formatDateTime, formatReversalTransactionStatus } from '../../../../../../utils/formatter';

export default class TransactionReversalDetails extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne2" aria-controls="collapseOne2">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                        <h3 class="kt-portlet__head-title">
                            Reversal Details
                        </h3>
                    </div>
                </div>
                {this.props.isLoading && <span class="loading">Loading</span>}
                {(!this.props.isLoading && this.props.reversalDetails.length > 0) && <form class="kt-form kt-form--label-right" id="collapseOne2" aria-labelledby="headingOne1">
                    <div class="kt-portlet__body">
                        <table class="table table-hover">
                            <thead class="thead-light">
                                <th>Reference Number</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Source Response Code</th>
                                <th>Retries</th>
                                <th>Date Created</th>
                                <th>Date Modified</th>
                            </thead>
                            <tbody>
                                {this.props.reversalDetails.map(value =>
                                    <tr>
                                        <td>{value.reverseTransRef}</td>
                                        <td>&#x20A6;{formatCurrency(convertToMajor(value.reverseTransAmount))}</td>
                                        <td>{formatReversalTransactionStatus(value.statusCode)}</td>
                                        <td>{value.sourceResponseCode}</td>
                                        <td>{value.retries}</td>
                                        <td>{formatDateTime(value.dateCreated)}</td>
                                        <td>{formatDateTime(value.dateModified)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </form>}
                {(!this.props.isLoading && !this.props.reversalDetails.length) && <span>No reversal Details found</span>}
            </div>
        </React.Fragment>
    }
}