import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import ContentHead from '../../../../../../../components/content-head';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_REMAP_DEVICE } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { SUCCESS_STATUS, ERROR_STATUS, ACCEPTED_REQUEST } from '../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { userManagement, platformManagement } from '../../../../../../../mixins/api'
import DeviceTable from '../../components/stock-table'
import DeviceSearch from '../../components/stock-search'
import FileUpload from '../../components/file-upload'
import { phoneNumberFormatter } from '../../../../../../../utils/formatter'
import { capitalizeFirstLetter, splitStringByUnderscore } from '../../../../../../../utils/helper'

export default class NewStockConfigScene extends BaseScene {

  requiredPermissions = [
    CAN_REMAP_DEVICE
  ]

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      stockDisable: false,
      configDisable: false,
      stockFile: [],
      configFile: [],
      stockError: "",
      configError: "",
      redirect: false
    }
    this.uploadConfigFile = this.uploadConfigFile.bind(this)
    this.uploadStockFile = this.uploadStockFile.bind(this)
    this.readUploadedFile = this.readUploadedFile.bind(this)
  }

  sceneName = "pos-stocks"

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  readUploadedFile (event, type) {
    console.log("type passed", type)
    const document = {
        file: event.target.files[0],
        filename: event.target.files[0].name,
        url: URL.createObjectURL(event.target.files[0])
    }

    if(document.file.type === "application/vnd.ms-excel" || document.file.type === "text/csv"){
        this.setState({
            [`${type}File`]: document,
            [`${type}Disable`]: false,
            [`${type}Error`]: "",
        })
    }else{
        this.setState({
            [`${type}Error`]: "Invalid file, upload a csv file",
            [`${type}Disable`]: false,
        })
    }  
  }

  async uploadStockFile () {
    this.setState({
        isLoading: true,
        stockDisable: true,
    })
    const responseObj = await userManagement.uploadStock(this.state.stockFile)
    const { status, response } = responseObj

    if (status === SUCCESS_STATUS) {
        if(response.code === ACCEPTED_REQUEST) {
            this.setState({
                successMessage: `Your stock upload processing has been sent successfully for approval`,
                isLoading: false,
                stockDisable: false,
                redirect: false
            })
        }else{
            this.setState({
                successMessage: `Your stock upload processing was successful.`,
                isLoading: false,
                redirect: true,
                stockDisable: false,
            })
        }
    }else{
        this.setState({
          errorMessage: response,
          isLoading: false,
          stockDisable: false,
      })
    }
  }

  async uploadConfigFile () {
    this.setState({
        isLoading: true,
        configDisable: true,
    })
    const responseObj = await userManagement.uploadConfig(this.state.configFile)
    const { status, response } = responseObj

    if (status === SUCCESS_STATUS) {
        if(response.code === ACCEPTED_REQUEST) {
            this.setState({
                successMessage: `Your configuration upload processing has been sent successfully for approval`,
                isLoading: false,
                configDisable: false,
                redirect: false
            })
        }else{
            this.setState({
                successMessage: `Your configuration upload processing was successful.`,
                isLoading: false,
                redirect: true,
                configDisable: false,
            })
        }
    }else{
        this.setState({
          errorMessage: response,
          isLoading: false,
          configDisable: false,
      })
    }
  }

  

  render () {
    console.log('redirect', this.state.redirect)
    if(this.state.redirect) {
      return <Redirect to="/home/pos-management/pos-stocks-request" />
    }

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"POS Management"} subtitle={'Upload POS Stocks'} filterPermissions={[CAN_REMAP_DEVICE]} totalCount={56} />
      <ProtectedComponent requiredPermissions={[CAN_REMAP_DEVICE]} permissionDeniedContent={this.permissionDeniedContent}>
        {/* <GridLayout> */}
          <div class="row">
            <div class="col-lg-6">
              <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                  <div class="kt-portlet__body">
                    <h5>Upload POS Stocks</h5>
                    <a href={`${process.env.REACT_APP_POS_STOCK_UPLOAD_TEMPLATE_URL}`} download>Download Template</a>
                    <div style={{marginTop: '50px'}}>
                      <FileUpload 
                        disabled={this.state.stockDisable}
                        fileErrorMessage={this.state.stockError}
                        file={this.state.stockFile}
                        readUploadedFile={this.readUploadedFile}
                        handleFileUpload={this.uploadStockFile}
                        type="stock"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <div class="col-lg-6">
              <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                  <div class="kt-portlet__body">
                    <h5>Upload POS Config</h5>
                    <a href={`${process.env.REACT_APP_POS_CONFIG_UPLOAD_TEMPLATE_URL}`} download>Download Template</a>
                    <div style={{marginTop: '50px'}}>
                      <FileUpload 
                        disabled={this.state.configDisable}
                        fileErrorMessage={this.state.configError}
                        file={this.state.configFile}
                        readUploadedFile={this.readUploadedFile}
                        handleFileUpload={this.uploadConfigFile}
                        type="config"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        {/* </GridLayout> */}
      </ProtectedComponent>
    </React.Fragment>
  }

    get permissionDeniedContent() {
      return <AccessDenied /> 
    }
}
