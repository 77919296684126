import React from 'react';
import ContentHead from '../../../../../../components/content-head';
import Table from '../../../../../../components/table';
import BaseScene from '../../../../../../components/scenes/component';
import { PromptModal } from '../../../../../../components/modals/prompt-modal';
import { SUCCESS_STATUS, ERROR_STATUS } from '../../../../../../constants/api';
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { GridLayout } from '../../../../../../components/layouts/grid-layout'
import { platformManagement, userManagement } from '../../../../../../mixins/api'
import { retrieveSessionInformation } from '../../../../../../mixins/authentication'
import { PermissionsExportSerializer } from '../../../../../../serializers'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_VIEW_PERMISSION } from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'
import PermissionTreeView from '../../components/permission-tree-view'

export default class PermissionsGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
    },
    {
      Header: "Name",
      accessor: 'shortDescription',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Description",
      accessor: 'description',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Action",
      accessor: 'id',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 70,
      Cell: ({row, original}) => <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION]}>
        <button type="button" class="btn btn-xs link-button">
          <Link to={`permission-management/${row.id}/view`} class="link-button">
            View
          </Link>
        </button>
      </ProtectedComponent>
      // Cell: ({row, original}) => <div class="dropdown">
      //   <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
      //     <i class="la la-ellipsis-v"></i>
      //   </a>
      //   <div class="dropdown-menu dropdown-menu-right">
      //     <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION]}><Link to={`permission-management/${row.id}/view`} class="dropdown-item">
      //       <i class="la la-eye"></i>
      //       View
      //     </Link></ProtectedComponent>
      //   </div>
      // </div>
    }
  ]

  state = {
    permissions: [],
  }
  sceneName = "permission-management"

  updateUser = this.updateUser.bind(this)
  getAllPermissions = this.getAllPermissions.bind(this)

  componentDidMount() {
    this.getAllPermissions()
  }

  componentWillUnmount() {
    const script = document.createElement("script")
    script.src = "./assets/js/demo1/treeview.js"
    script.async = false
    document.body.appendChild(script)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }
  
  async getAllPermissions () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.getAllPermissions()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissions: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        isLoading: false,
        dataCount: 0,
        permissions: [],
        errorMessage: response
      })
    }
  }

  getClickedAction (username, firstName, lastName, status) {
    this.setState({
      username: username,
      userStatus: status,
      firstName,
      lastName,
      modalIsOpen: true,
    })
  }

  async updateUser () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.updateUserStatus(this.state.userStatus, this.state.username)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        username: null,
        userStatus: null,
        lastName: null,
        firstName: null,
        successMessage: `User status updated succesfully!`,
        isLoading: false,
      })
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getUsers()
  }

  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} subtitle={"Permissions"} filterPermissions={[CAN_VIEW_PERMISSION]} totalCount={56} /*actionButtonText={"Add Permission"} actionButtonUrl={"/home/permission-management/add"} actionButtonRequiredPermissions={[CAN_VIEW_PERMISSION]}*/ />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          {/* <PermissionTreeView 
          /> */}
          <Table 
            columns={this.columns} 
            data={this.state.permissions} 
            loading={this.state.isLoading} 
            noDataText={`No permissions found`}
            dataCount={this.state.dataCount}
            getData={this.getAllPermissions}
            exportData={PermissionsExportSerializer.reversePermissionDetails(this.state.permissions)}
            exportHeaders={PermissionsExportSerializer.headerExport()}
            exportFileName={'permissions'}
          />
          <PromptModal
            modalTitle={ this.state.userStatus+` User`}
            modalBody={`Do you want to ${this.state.userStatus} ${this.state.firstName} ${this.state.lastName} as a user?`}
            deleteModal={this.updateUser}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
