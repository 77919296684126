import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_SETTLEMENT } from '../../../../../../../constants/permissions.js';
import { settlementService } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { formatBackEndDateTime, formatBackEndDateTimeFrom, formatBackEndDateTimeTo } from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import SettlementReportTable from '../../components/settlement-report-table';
import SettlementSearch from '../../components/settlement-search';

export default class SettlementReportGridScene extends BaseScene {
  
  sceneName = 'settlement-report'

  notificationSectionRef = React.createRef(null)
  
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      settlements: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("fromDate") : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("toDate") : null
      },
      pages: 1,
      totalCount: 10,
      page: 0,
      backlogDaysToRetry: null,
      transRef: null,
      tableMessage: "",
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      pageSize: 20
    }
  
    this.getSettlementReport = this.getSettlementReport.bind(this);
    this.getSettlementTotalCount = this.getSettlementTotalCount.bind(this)
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.requeryPendingSettlement = this.requeryPendingSettlement.bind(this)
    this.requeryAllPendingSettlement = this.requeryAllPendingSettlement.bind(this)
    this.getAgentId = this.getAgentId.bind(this);
    this.updateSetState = this.updateSetState.bind(this)
  }
  
  componentDidMount() {
    //this.getSettlementTotalCount()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getSettlementTotalCount (pageNum, pageSize) {
    console.log('object.getSettlementTotalCount')
    this.setState({
      canLoadData: true,
      isLoading: true
    })
    const { transactionRef, agentId, transactionType, transactionSettlementStatus, startDate, endDate } = this.state.searchParameters
    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate)
    const responseObj = await settlementService.getSettlementReportCount({ transactionRef, agentId, transactionType, transactionSettlementStatus, startDate: formattedStartDate, endDate: formattedEndDate })
    const { status, response, count } = responseObj
    console.log('response', status, response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        totalCount: response,
        tableMessage: response == 0 ? "No settlement report found" : ""
      })
      // this.getSettlementReport(pageNum, pageSize)
    }else{
      this.setState({
        totalCount: 0,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No settlement report found"
      })
    }
  }

  async getSettlementReport (pageNum, pageSize) {
    this.setState({
      canLoadData: true,
      isLoading: true
    })
    //this.getSettlementTotalCount()
    const { transactionRef, agentId, transactionType, transactionSettlementStatus, startDate, endDate } = this.state.searchParameters
    const formattedEndDate = endDate && formatBackEndDateTimeTo(endDate);
    const formattedStartDate = startDate && formatBackEndDateTimeFrom(startDate)
    const responseObj = await settlementService.getSettlementReport(pageNum, pageSize, { transactionRef, agentId, transactionType, transactionSettlementStatus, startDate: formattedStartDate, endDate: formattedEndDate })
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        settlements: response ? response : [],
        dataCount: count,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        tableMessage: response.length == 0 ? "No settlement report found" : ""
      })
    }else{
      this.setState({
        settlements: [],
        dataCount: 0,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response,
        successMessage: null,
        tableMessage: "No settlement report found"
      })
    }
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  handleSearchRequest (searchParameters) {
    this.setState({loading: true})
    //this.getSettlementTotalCount()
    this.setState({pageIndex: 1})
    this.setState({startPage: 1})
    this.setState({
      startPage: 1,
      searchParameters
    })
    setTimeout(() =>{
      // this.getSettlementTotalCount(1, 20)
      this.getSettlementReport(1, 20)
      this.setState({loading: false})
    })
    // this.getSettlementReport(1, 20)
    // this.setState({loading: false})
  }

  getAgentId (parties) {
    let agentId = ''
    parties.map(value => {
      if((value.agentName).toLowerCase() == 'agent'){
        agentId = value.agentId
      }
    })
    return agentId
  }

  async requeryPendingSettlement (transRef = this.state.transRef) {
    this.setState({isLoading: true})
    const responseObj = await settlementService.requeryPendingSettlement(transRef)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Settlement with transaction reference number ${transRef} will be processed shortly, please check back.`,
        isLoading: false,
        transRef: null,
        backlogDaysToRetry: null
      })
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false,
        transRef: null,
        backlogDaysToRetry: null
      })
    }
    this.getSettlementReport(1, 20)
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  async requeryAllPendingSettlement () {
    this.setState({isLoading: true})
    const responseObj = await settlementService.requeryAllPendingSettlement(this.state.backlogDaysToRetry)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: 'Settlement will be processed shortly, please check back.',
        errorMessage: null,
        isLoading: false,
        transRef: null,
        backlogDaysToRetry: null
      })
    }else{
      this.setState({
        errorMessage: response,
        successMessage:  null,
        isLoading: false,
        transRef: null,
        backlogDaysToRetry: null
      })
    }
    this.getSettlementReport(1, 20)
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  render () {

    const repushButton = <React.Fragment>
        <button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} data-toggle="modal" data-target="#kt_modal_1" onClick={this.onAddButtonClick}>Process Pending Commission</button>
    </React.Fragment>

    const repushModalBody = <React.Fragment>
      <div class="form-group col-lg-12">
        <label>Enter Agent id / Days:</label>
        <input autoComplete="off" type="text" class="form-control" placeholder="Enter agent id / days" 
          onChange={event => this.updateSetState('backlogDaysToRetry', event.target.value)} 
        />
      </div>
    </React.Fragment>

    return <React.Fragment>
      <ContentHead title={"Settlement Report"} filterPermissions={[CAN_VIEW_SETTLEMENT]} totalCount={56} ctaWidgets={repushButton}/>
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_SETTLEMENT]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <SettlementSearch 
            handleSearchRequest={this.handleSearchRequest}
            requeryPendingSettlement={this.requeryPendingSettlement}
            requeryAllPendingSettlement={this.requeryAllPendingSettlement}
            updateSetState={this.updateSetState}
            showSettlementRepush={true}
          />
          <SettlementReportTable 
            pages={this.state.pages}
            data={this.state.settlements}
            getSettlementReport={this.getSettlementTotalCount}
            getAgentId={this.getAgentId}
            page={this.state.page}
            loading={this.state.loading || this.state.isLoading}
            startPage={this.state.startPage}
            // loading=}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            // page={this.state.page}
            pageSize={this.state.pageSize}
            canView={CAN_VIEW_SETTLEMENT}
            requeryPendingSettlement={this.requeryPendingSettlement}
            requeryAllPendingSettlement={this.requeryAllPendingSettlement}
            updateSetState={this.updateSetState}
            canLoadData={this.state.canLoadData}
          />
          <PromptModal 
            modalTitle={`Process Pending Commission`}
            modalBody={repushModalBody}
            deleteModal={this.requeryAllPendingSettlement}
            modalSize={`modal-md`}
            height={`290px`}
            textAlign={'left'}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={'Submit'}
            modalFooterButton2Disabled={this.state.backlogDaysToRetry ? false : true}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
