import React from "react";
import PhoneNumberComponent from "../../../../../../components/phone-number-component";
import Select from "../../../../../../components/select";

export default class AgentClassDetails extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              class="kt-portlet__head"
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target="#collapseOne2"
              aria-controls="collapseOne2"
            >
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Business Details</h3>
              </div>
            </div>
          )}

          <form id="collapseOne2" class="collapse show">
            <div class="kt-portlet__body">
              <div class="form-group row">
                <div class="col-lg-4">
                  <label>Business Name *:</label>
                  <input
                    autoComplete="off"
                    disabled={this.props.disabled ? this.props.disabled : ""}
                    type="text"
                    class="form-control"
                    defaultValue={
                      this.props.businessDetails
                        ? this.props.businessDetails.businessName
                        : ""
                    }
                    placeholder="Enter business name"
                    onChange={(event) =>
                      this.props.onChange("businessName", event.target.value)
                    }
                  />
                </div>
                <div class="col-lg-4">
                  <label>Business Type *:</label>
                  <Select
                    options={this.props.businessTypes.map((detail) => {
                      return { label: detail.name, value: detail.name };
                    })}
                    onChange={(event) =>
                      this.props.onChange("businessType", event.value)
                    }
                    disabled={this.props.disabled}
                    defaultValue={
                      this.props.businessDetailsFormData
                        ? this.props.businessDetailsFormData.businessType
                        : this.props.businessDetails.businessType
                    }
                  />
                  {/* <input type="text" list="idtypes" disabled={this.props.disabled ? this.props.disabled : ''} autoComplete="off" class="form-control" defaultValue={this.props.businessDetails ? this.props.businessDetails.businessType : ''} onChange={(event) => this.props.onChange('businessType', event.target.value)} />
                                <datalist id="idtypes">
                                    {this.props.businessTypes.map(details => {
                                        return (
                                            <option value={details.name} />
                                        );
                                    })}
                                </datalist> */}
                </div>
                <div class="col-lg-4">
                  <label>Company Registration Number *:</label>
                  <div class="kt-input-icon">
                    <input
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      type="text"
                      class="form-control"
                      defaultValue={
                        this.props.businessDetails
                          ? this.props.businessDetails.companyRegistrationNumber
                          : ""
                      }
                      maxLength={8}
                      placeholder="Enter registration number"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row">
                {((this.props.businessDetails &&
                  this.props.businessDetails.phoneNumber) ||
                  this.props.showPhoneInput) && (
                  <div class="col-lg-4">
                    <label>Phone *:</label>
                    <div class="kt-input-icon">
                      <PhoneNumberComponent
                        phoneIsValid={
                          this.props.businessPhoneIsValid
                            ? this.props.businessPhoneIsValid
                            : true
                        }
                        onChange={(status, phoneNumber) => {
                          //this.props.onChange('phoneNumber', phoneNumber)
                          //this.props.updateSetState('businessPhoneIsValid', status)
                        }}
                        defaultValue={
                          this.props.businessDetails &&
                          this.props.businessDetails.phoneNumber
                            ? this.props.businessDetails.phoneNumber
                            : ""
                        }
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                      />
                      {this.props.businessDetailsFormData.phoneNumber &&
                        !this.props.businessPhoneIsValid && (
                          <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                              <i
                                class="la la-info-circle"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          </span>
                        )}
                    </div>
                  </div>
                )}
                <div class="col-lg-4">
                  <label class="">State *:</label>
                  <Select
                    options={this.props.states.map((detail) => {
                      return { label: detail.name, value: detail.id };
                    })}
                    onChange={(event) =>
                      this.props.updateState(event.value, "businessState")
                    }
                    disabled={this.props.disabled}
                    defaultValue={this.props.businessDetailsFormData.state}
                  />
                  {/* <select autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} class="form-control" defaultValue={this.props.businessDetailsFormData.state} placeholder="Lagos" onChange={(event) => this.props.updateState(event.target.value,'businessState')} data-live-search="true">
                                    <option>Select State</option>
                                    {this.props.states.map(value => {
                                        return <option value={value.id} selected={this.props.businessDetailsFormData && this.props.businessDetailsFormData.state == value.id ? 'selected' : ''}>{value.name}</option>
                                    })}
                                </select> */}
                </div>
                <div class="col-lg-4">
                  <label class="">LGA *:</label>
                  <Select
                    options={this.props.lgas.map((detail) => {
                      return { label: detail.name, value: detail.id };
                    })}
                    onChange={(event) =>
                      this.props.onChange("localGovernmentArea", event.value)
                    }
                    disabled={this.props.disabled}
                    defaultValue={
                      this.props.businessDetailsFormData.localGovernmentArea
                    }
                  />
                  {/* <select autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} class="form-control" defaultValue={this.props.businessDetailsFormData.localGovernmentArea} onChange={(event) => this.props.onChange('localGovernmentArea', event.target.value)} data-live-search="true">
                                    <option>Select LGA</option>
                                    {this.props.lgas.map(value => <option value={value.id} selected={this.props.businessDetailsFormData && this.props.businessDetailsFormData.localGovernmentArea == value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <label>Address *:</label>&nbsp;&nbsp;
                  {!this.props.disabled &&
                    this.props.applicantDetailsFormData.address && (
                      <label>
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            //this.props.updateSetState('shouldUpdateBusinessAddress', !this.props.shouldUpdateBusinessAddress)
                            this.props.updateBusinessAddress(
                              !this.props.shouldUpdateBusinessAddress
                            );
                          }}
                        />
                        <span>
                          Tick this box if your business address is same as
                          applicant address
                        </span>
                      </label>
                    )}
                  <div class="kt-input-icon kt-input-icon--right">
                    <textarea
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      rows={5}
                      style={{ resize: "none" }}
                      class="form-control"
                      placeholder="Enter office address"
                      defaultValue={
                        this.props.businessDetailsFormData &&
                        this.props.businessDetailsFormData.address
                          ? this.props.businessDetailsFormData.address
                          : this.props.businessDetails &&
                            this.props.businessDetails.address
                          ? this.props.businessDetails.address
                          : ""
                      }
                      onChange={(event) =>
                        this.props.onChange("address", event.target.value)
                      }
                    />
                  </div>
                  <span class="form-text text-muted">Office address</span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-4">
                  <label>Email Address:</label>
                  <input
                    autoComplete="off"
                    disabled={this.props.disabled ? this.props.disabled : ""}
                    type="text"
                    class="form-control"
                    defaultValue={
                      this.props.businessDetails
                        ? this.props.businessDetails.businessEmail
                        : ""
                    }
                    placeholder="Enter business name"
                    onChange={(event) =>
                      this.props.onChange("businessEmail", event.target.value)
                    }
                  />
                </div>

                {this.props.showAgentClass && (
                  <div class="col-lg-4">
                    <label>Agent Class:</label>
                    <Select
                      options={this.props.agentClasses.map((detail) => {
                        return {
                          label: detail.name,
                          value: `${detail.id}-${detail.name}`,
                        };
                      })}
                      onChange={(event) =>
                        this.props.onChange("agentClass", event.value)
                      }
                      disabled={this.props.disabled2}
                      defaultValue={
                        this.props.businessDetailsFormData.agentClassId
                          ? `${this.props.businessDetailsFormData.agentClassId}-${this.props.businessDetailsFormData.agentClass}`
                          : `${this.props.businessDetails.agentClassId}-${this.props.businessDetails.agentClass}`
                      }
                    />
                    {/* <select autoComplete="off" disabled={this.props.disabled2 ? this.props.disabled2 : ''} class="form-control" onChange={(event) => this.props.onChange('agentClass', event.target.value)} data-live-search="true">
                                    <option disabled selected>Select agent class</option>
                                    {this.props.agentClasses.map(value => <option value={`${value.id}-${value.name}`} selected={this.props.businessDetails.agentClassId == value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                  </div>
                )}
                {(this.props.businessDetails &&
                  this.props.businessDetails.agentTypeId) == 4 && (
                  <div class="col-lg-4">
                    <label>Aggregator:</label>
                    <Select
                      options={this.props.aggregators.map((detail) => {
                        return {
                          label: detail.businessName,
                          value: detail.referralCode,
                        };
                      })}
                      onChange={(event) =>
                        this.props.onChange("superAgent", event.value)
                      }
                      disabled={this.props.disabled}
                      defaultValue={
                        this.props.businessDetailsFormData.superAgent
                          ? this.props.businessDetailsFormData.superAgent
                          : this.props.businessDetails.parentId
                      }
                    />
                    {/* <select autoComplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('superAgent', event.target.value)} data-live-search="true">
                                    <option disabled selected>Select Aggregator</option>
                                    {this.props.aggregators.map(value => <option value={value.referralCode} selected={this.props.businessDetails.parentId == value.referralCode ? 'selected' : ''}>{value.businessName}</option>)}
                                </select> */}
                  </div>
                )}
              </div>

              <div class="form-group row">
                <div class="col-lg-4">
                  <label>Bank Name *:</label>
                  <Select
                    options={this.props.banks.map((detail) => {
                      return { label: detail.name, value: detail.name };
                    })}
                    onChange={(event) =>
                      this.props.onChange("bankName", event.value)
                    }
                    disabled={this.props.disabled}
                    defaultValue={
                      this.props.businessDetailsFormData
                        ? this.props.businessDetailsFormData.bankName
                        : this.props.businessDetails.bankName
                    }
                  />
                  {/* <select autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} class="form-control multipleselect" data-live-search="true" onChange={(event) => this.props.onChange('bankName', event.target.value)}>
                                    <option selected disabled>Select Bank</option>
                                    {this.props.banks.map((value, index) => <option value={value.name} selected={this.props.businessDetails && this.props.businessDetails.bankName == value.name ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                </div>
                <div class="col-lg-4">
                  <label>Account Number *:</label>
                  <div class="kt-input-icon">
                    <input
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      type="text"
                      class="form-control"
                      defaultValue={
                        this.props.businessDetails
                          ? this.props.businessDetails.accountNumber
                          : ""
                      }
                      maxLength={10}
                      placeholder="Enter account number"
                    />
                  </div>
                </div>

                <div class="col-lg-4">
                  <label>BVN *:</label>
                  <div class="kt-input-icon">
                    <input
                      autoComplete="off"
                      disabled={this.props.disabled ? this.props.disabled : ""}
                      type="text"
                      class="form-control"
                      defaultValue={
                        this.props.applicantDetails
                          ? this.props.applicantDetails.bvn
                          : ""
                      }
                      maxLength={11}
                      placeholder="Enter your BVN"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
