export const PLATFORM_PROVIDER = 'PLATFORM PROVIDER'

export const SERVICE_PROVIDER = 'SERVICE_PROVIDER'

export const SUPER_AGENT = 'SUPER AGENT'

export const AGENT = 'AGENT'

export const DOMAIN_TYPES = 'domainTypes'

export const AGENT_TYPE_ID = 4

export const AGGREGATOR_TYPE_ID = 3

export const SUPER_AGENT_TYPE_ID = 2
