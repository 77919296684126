import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isAuthorized } from '../../../../mixins/authentication'
import { CAN_MESSAGING_WRITE_TEMPLATE, CAN_MESSAGING_READ_TEMPLATE } from '../../../../constants/permissions.js'
// import ApplicationsGridScene from '../applications/scenes/applications-grid/scene';
import BaseScene from '../../../../components/scenes/component';
import TemplateGridScene from './scenes/template-grid/scene';
import AddTemplateScene from './scenes/add-template';

export default class NotificationScene extends BaseScene {
  sceneName = 'template'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TEMPLATE]} exact path='/home/notifications/template' component={TemplateGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TEMPLATE]} exact path='/home/notifications/template/:id/view' component={AddTemplateScene} />
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_WRITE_TEMPLATE]} exact path='/home/notifications/template/add' component={AddTemplateScene} />
    </Router>
  }
}
