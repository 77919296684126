import React from 'react';
import './index.css';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseScene from '../../../../../components/scenes/component';
import ViewPosRequest from './../view-pos-requests/scenes/pos-requests-grid';
import ViewPosRequestsAwaitingConfigScene from './scenes/view-pos-stocks/scenes/pos-request-awaiting-config-grid';
import ViewPosRequestsAwaitingStockUploadScene from './scenes/view-pos-requests-awaiting-stock-upload/scenes/pos-stocks-grid';
import ViewPosRequestsAwaitingConfigUploadScene from './scenes/view-pos-requests-awaiting-config-upload/scenes/pos-awaiting-config-grid';
import DeliveryNoteScene from './scenes/delivery-note/scenes/delivery-note-grid';
// import RequestHeader from "./components/header";
import {
  CAN_MANAGE_SP_DOMAIN,
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_MANAGE_AGGREGATOR_DOMAIN,
  CAN_VIEW_SP_ROLE,
  CAN_VIEW_AGGREGATOR_ROLE,
  CAN_VIEW_AGENT_ROLE,
  CAN_VIEW_SP_USER,
  CAN_VIEW_AGGREGATOR_USER,
  CAN_VIEW_AGENT_USER,
  CAN_VIEW_AGENT_DEVICE,
} from '../../../../../constants/permissions';
import { isAuthorized } from '../../../../../mixins/authentication';

export default class PosManagementScene extends BaseScene {
  sceneName = 'pos-manage';

  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };
  }

  componentDidMount() {
    // this.props.updateScene(this.sceneName)
    //this.domainCode = this.props.match.params.domainCode
    // this.loadApplication();
  }

  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };
  render() {
    return (
      <>
        <div className='col-md-12' style={{ marginTop: '-80px' }}>
          <section id='open-positions'>
            <div className='positions'>
              <div className='position-filters'>
                <label
                  className={
                    this.state.toggleState === 1
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All
                </label>
                {/* <label
                  className={
                    this.state.toggleState === 2
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Awaiting Configuration
                </label> */}
                {/* <label
                  className={
                    this.state.toggleState === 3
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(3)}
                >
                  Awaiting Stock Upload
                </label> */}
                {/* <label
                  className={
                    this.state.toggleState === 4
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(4)}
                >
                  Awaiting Configuartion Upload
                </label> */}
                <label
                  className={
                    this.state.toggleState === 5
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(5)}
                >
                  Delivery Note
                </label>
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <ViewPosRequest />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 2
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <ViewPosRequestsAwaitingConfigScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 3
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <ViewPosRequestsAwaitingStockUploadScene />
                </div>
              </div>
              <div
                className={
                  this.state.toggleState === 4
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <ViewPosRequestsAwaitingConfigUploadScene />
                </div>
              </div>
              <div
                className={
                  this.state.toggleState === 5
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <DeliveryNoteScene />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
