import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ViewAgentScene from './scenes/view-agent';
import AgentsGridScene from './scenes/agents-grid';
import ManageAgentClassScene from './scenes/manage-agent-class'
import ManageAgentLimitScene from './scenes/manage-agent-limit'
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_AGENT, CAN_MANAGE_AGENT_DOMAIN, CAN_VIEW_TRANSACTION } from '../../../../../constants/permissions.js'
import DomainManagementScene from '../../domain-management';
import WalletJournalScene from '../../reports/wallet-journal'
import CommissionHistoryScene from '../../reports/commission-history'

export default class AgentsScene extends Component {
  sceneName = 'agent-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/agent-management' component={AgentsGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/agent-management/:id/view' component={ViewAgentScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/agent-management/manage-agent-class' component={ManageAgentClassScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/agent-management/manage-agent-limit' component={ManageAgentLimitScene} />
      <ProtectedRoute requiredPermissions={[CAN_MANAGE_AGENT_DOMAIN]} path="/home/domain-management/:domainCode" component={DomainManagementScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} path="/home/account-statement/:domainCode" component={WalletJournalScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} path="/home/commission-history/:domainCode" component={CommissionHistoryScene} />
    </Router>
  }
}
