export default class RevenueSharingExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Account Type', key: 'type', title: 'Account Type', dataIndex: 'type'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Created By', key: 'createdBy', title: 'Created By', dataIndex: 'createdBy'},
            {label: 'Last Updated By', key: 'lastUpdatedBy', title: 'Last Updated By', dataIndex: 'lastUpdatedBy'},
        ]
    }

    static reverseRevenueSharingDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, type, status, createdBy, lastUpdatedBy } = details
            let detailFormData = {
                name,
                type: type === 1 ? 'Bank Account' : type === 0 ? 'Wallet' : '',
                status: status === 0 ? 'Active' : status === 1 ?  'Inactive' : '',
                createdBy: createdBy ? createdBy : '',
                lastUpdatedBy: lastUpdatedBy ? lastUpdatedBy : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}