import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { 
    formatDateTime, formatCurrency
} from '../../../../../../../../utils/formatter';
import { convertToMajor } from '../../../../../../../../utils/converter'
import { getPaginationRows } from '../../../../../../../../utils/helper'
import SpinnerComponent from '../../../../../../../../components/spinner'
import Pagination from '../../../../../../../../components/pagination'
import TableExtra from '../../../../../../../../components/table-extra'
import { WalletPoolReportExportSerializer } from '../../../../../../../../serializers'

export default class WalletPoolTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '430px',
    zoom: 'plus'
  }
    
  zoomTableHeight = this.zoomTableHeight.bind(this)
    
  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }
    
  render() {
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
      {
        Header: "Unique Reference",
        accessor: 'uniqueReference',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 210,
      },
      {
        Header: "Payment Reference",
        accessor: 'paymentReference',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset', 'word-wrap':'break-word'},
        width: 200,
      },
      {
        Header: "Date",
        accessor: 'journalEntryDate',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 160,
        Cell: props => {
            return props.value ? formatDateTime(props.value) : ''
        }
      },
      {
        Header: "Wallet Journal",
        accessor: 'walletJournalId',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 150,
      },
      {
        Header: "Wallet",
        accessor: 'walletId',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 130,
      },
      {
        Header: "Credit Amount",
        accessor: 'creditAmount',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 100,
        Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
      },
      {
        Header: "Debit Amount",
        accessor: 'debitAmount',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 100,
        Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
      },
      {
        Header: "Balance",
        accessor: 'balance',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 130,
        Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
      },
      {
        Header: "Journal Description",
        accessor: 'journalDescription',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },
      {
        Header: "Narration",
        accessor: 'narration',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 174,
      },
    ]

    return <React.Fragment> 
      <TableExtra 
        data={WalletPoolReportExportSerializer.reverseWalletPoolReportDetails(this.props.data)}
        headers={WalletPoolReportExportSerializer.headerExport()}
        fileName={'wallet_pool_report'}
        zoomTableHeight={this.zoomTableHeight}
        getData={this.props.getWalletPoolStatements}
        zoom={this.state.zoom}
        page={this.props.page}
        pageSize={this.props.pageSize}
        disabled={this.props.loading}
      />
      <ReactTable 
        PaginationComponent={Pagination} 
        loading={this.props.loading}
        LoadingComponent={MyLoader}
        noDataText={!this.props.loading ? this.props.noDataText : ''}
        style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: 'unset',
            height: this.state.tableHeight,
        }}
        data={this.props.data}
        dataCount={this.props.dataCount}
        pageSizes={getPaginationRows(this.props.dataCount)}
        columns={columns}
        manual
        pages={this.props.pages}
        page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
        onFetchData={(state, instance) => {
          this.setState({page: state.page})
            if(this.props.canLoadData) {
              this.props.getWalletPoolStatements(state.page + 1, state.pageSize)
            }
          }
        }
        headerNodeHeight={`123`}
        minRows={(this.props.data).length > 0 ? 10 : 10}
        getTableProps={(state, rowInfo, column, instance) => ({
          style: {
            borderColor: '#00425f',
            boxSizing: 'none',
            border: 'none',
          },
        })}
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            display:"flex",
            overflow: "visible",
            color: "black",
            fontSize: "11.5px",
            fontWeight: "50",
            paddingLeft:"5px",
            paddingRight:"5px",
            alignItems: "center",
            
          },
        })}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            backgroundColor: '#00425f',
            borderBottom: '0.1px solid #DBDEDE',
            borderWidth: '0.1px',
            paddingTop: '5px',
            paddingBottom: '5px',
            color: '#FFFFFF',
            fontSize: '12px',
            fontWeight: '100',
            textAlign: 'left'
          },
        })}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
            overflow: 'visible',
            backgroundColor: '#ffffff',
          },
        })}    
        className="-striped -highlight" 
      />
    </React.Fragment>
  }
}