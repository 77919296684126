import React from 'react';
// import {
//   formatTransactionStatus
// } from "../../../../../../utils/formatter";

export default class QuickStockModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      inputKey: Date.now(),
    };
  }
  render() {
    const resul = this.props.posDetails ? this.props.posDetails : '';

    const handleInputFile = () => {
      this.setState({ inputKey: Date.now() });
    };
    return (
      <React.Fragment>
        <div
          class='modal'
          id='app_config_edit_details_modal'
          tabindex='-1'
          role='dialog'
          aria-labelledby='myModalLabel'
          aria-hidden='true'
        >
          <div
            class='modal-dialog modal-lg'
            style={{ overflowY: 'scroll', overflowX: 'auto', fontSize: '14px' }}
          >
            <div class='modal-content' style={{ borderRadius: '15px' }}>
              <div class='kt-portlet'>
                <div class='kt-portlet__head' style={{ cursor: 'pointer' }}>
                  <div
                    class='kt-portlet__head-label'
                    role='button'
                    aria-expanded='true'
                  >
                    <h3 class='kt-portlet__head-title'>
                      <label style={{ cursor: 'pointer' }}>
                        <strong>Edit POS Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class='kt-form kt-form--label-right'
                  aria-labelledby='headingOne4'
                >
                  <div class='kt-portlet__body'>
                    <div>
                      <div class='form-group row'>
                        <div class='col-lg-6'>
                          <label>
                            <strong>POS model</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type='text'
                              disabled
                              class='search-input'
                              onChange={(event) =>
                                this.props.updateEditDetails(
                                  'posModel',
                                  event.target.value
                                )
                              }
                              defaultValue={resul.terminalModel}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                            />
                          </span>
                        </div>
                        <div class='col-lg-6'>
                          <label>
                            <strong>Price</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type='text'
                              class='search-input'
                              onChange={(event) =>
                                this.props.updateEditDetails(
                                  'posPrice',
                                  event.target.value
                                )
                              }
                              defaultValue={resul.price}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.code}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class='form-group row'>
                        <div class='col-lg-6'>
                          <label>
                            <strong>POS Image URL</strong>
                          </label>
                          <br />

                          <span>
                            <input
                              type='text'
                              class='search-input'
                              value={resul.terminalImage}

                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                            />
                          </span>
                        </div>
                        <div class='col-lg-6'>
                          <label>
                            <strong>Upload new POS image</strong>
                          </label>
                          <br />
                          <span>
                            {/* <input type="file" name="image" accept="image/*"> */}
                            <input
                              // for picking a new image entirely
                              key={this.state.inputKey}
                              type='file'
                              name='image'
                              accept='image/*'
                              class='search-input'
                              onChange={(event) =>
                                this.props.updateEditDetails(
                                  // this.props.UploadPosImage(
                                  'file',
                                  event.target.files
                                )
                              }
                              defaultValue={resul.file}
                            />
                          </span>
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div class='col-lg-12'>
                          <label>
                            <strong>POS Model Description(Optional)</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type='text'
                              class='search-input'
                              onChange={(event) =>
                                this.props.updateEditDetails(
                                  'description',
                                  event.target.value
                                )
                              }
                              defaultValue={resul.terminalDescription}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class='modal-footer'>
                <button
                  type='button'
                  class='btn btn-primary'
                  style={{
                    height: '40px',
                    width: '100px',
                    padding: '0px',
                    borderRadius: '15px',
                    border: '2px solid #00425f',
                    backgroundColor: '#ffffff',
                    color: '#00425f',
                    fontSize: '14px',
                    margin: 'auto',
                    display: 'block',
                  }}
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button
                  type='button'
                  class='btn btn-primary'
                  style={{
                    height: '40px',
                    width: '100px',
                    padding: '0px',
                    borderRadius: '15px',
                    // border: "2px solid #00425f",
                    backgroundColor: '#00425f',
                    color: '#ffffff',
                    fontSize: '14px',
                    margin: 'auto',
                    display: 'block',
                  }}
                  data-dismiss='modal'
                  onClick={() => (
                    // to make the picture picked erlier to disappear
                    this.props.editPosDetails(), handleInputFile()
                  )}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
