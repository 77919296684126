import React from 'react';
import { AccessDenied } from '../../../../../../components/access-denied';
import ContentHead from '../../../../../../components/content-head';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../components/notification';
import BaseScene from '../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../constants/api';
import { CAN_MESSAGING_READ_TEMPLATE, CAN_MESSAGING_WRITE_TEMPLATE } from '../../../../../../constants/permissions';
import { messagingService } from '../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { formatBackEndDateTime } from '../../../../../../utils/formatter';
import RecurringMessageTable from '../../components/recurring-message-table';

export default class RecurringMessageGridScene extends BaseScene {

  metadataSectionRef = React.createRef(null)
  
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      recurringMessages: [],
      recurringMessageSearchParameters: {},
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      useDefaultDate: true
    }
    this.handleSearchRequest = this.handleSearchRequest.bind(this);

    this.getRecurringMessages = this.getRecurringMessages.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this)
    this.deleteRecurringMessage = this.deleteRecurringMessage.bind(this)
  }

  sceneName = 'template'
  
  componentDidMount() {
    // this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, approvalStatus: validateApplicationPermission()}})
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 3000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 3000)
    }
  }

  async getRecurringMessages (pageNum, pageSize) {
    const payload = this.state.recurringMessageSearchParameters;
    payload.fromDate = payload.fromDate && formatBackEndDateTime(payload.fromDate)
    payload.toDate = payload.toDate && formatBackEndDateTime(payload.toDate)
    this.setState({
      isLoading: true,
      canLoadData: true
    })

    const { status, response } = await messagingService.getRecurringMessages(pageNum, pageSize, payload);
    const { items, totalCount } = response;

    console.log("recurring message response ", response)

    if (status === SUCCESS_STATUS) {
      this.setState({
        recurringMessages: items,
        pages: Math.ceil(totalCount/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        dataCount: totalCount,
        tableMessage: items.length == 0 ? "No recurring message found" : ""
      })
    } else {
      this.setState({
        recurringMessages: [],
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        errorMessage: response,
        tableMessage: "No recurring message found"
      })
    }
    window.scrollTo(0, this.metadataSectionRef.current)
  }

  handleSearchRequest (recurringMessageSearchParameters) {
    this.setState({
      startPage: 1,
      recurringMessageSearchParameters,
    })
    setTimeout(() => {
      this.getRecurringMessages(1, this.state.pageSize)
    }, 200)
  }

  getClickedAction (recurringMessageId) {
    this.setState({
      recurringMessageId,
      canDelete: true,
    })
  }

  async deleteRecurringMessage () {
    this.setState({
      isLoading: true
    })

    const { status, response } = await messagingService.deleteRecurringMessage(this.state.recurringMessageId);
    if(status === SUCCESS_STATUS) {
      this.setState({
       recurringMessageId: null,
        successMessage: `Recurring Message was successfully deleted`,
        isLoading: false
      })
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false
      })
    }
    this.getRecurringMessages(this.state.page, this.state.pageSize)
  }

  render () {
    return <React.Fragment>
      <Notification
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        ref={this.metadataSectionRef}
      />
      <ContentHead title={"Recurring Message"} totalCount={56} filterPermissions={[CAN_MESSAGING_READ_TEMPLATE]} actionButtonRequiredPermissions={[CAN_MESSAGING_WRITE_TEMPLATE]} actionButtonText={"Create New Recurring Message"} actionButtonUrl={"recurring-message/add"} showTooltip={true} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_MESSAGING_READ_TEMPLATE]}>
        <GridLayout>
          <RecurringMessageTable
            data={this.state.recurringMessages} 
            showApplicantName={false} 
            showBusinessName={true} 
            getRecurringMessages={this.getRecurringMessages}
            pages={this.state.pages}
            startPage={this.state.startPage}
            noDataText={this.state.tableMessage}
            loading={this.state.isLoading}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            onDeleteButtonClick={this.getClickedAction}
            canView={CAN_MESSAGING_WRITE_TEMPLATE}
            canCancel={CAN_MESSAGING_WRITE_TEMPLATE}
            canLoadData={this.state.canLoadData}
          />
          <PromptModal
            modalTitle={`Delete Recurring Message`}
            modalBody={`Do you want to delete this recurring message?`}
            deleteModal={this.deleteRecurringMessage}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
