import React from 'react'
import { trimPhoneNumber } from '../../utils/helper'
import PhoneNumberComponent from '../phone-number-component'
import Select from '../select'

export default class UserForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                {this.props.title && <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">{this.props.title}</h3>
                    </div>
                    {this.props.submitButton && <div class="kt-portlet__head-label">
                        {this.props.submitButton}
                    </div>}
                </div>}
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>First Name *:</label>
                            <input autoComplete="off" type="name" class="form-control" placeholder="First name" disabled={this.props.disabled2 ? this.props.disabled2 : ''} defaultValue={this.props.userDetails ? this.props.userDetails.firstName : ''} onChange={(event) => this.props.onChange('firstName', event.target.value)} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Last Name *:</label>
                            <input autoComplete="off" type="name" class="form-control" placeholder="Last name" disabled={this.props.disabled2 ? this.props.disabled2 : ''} defaultValue={this.props.userDetails ? this.props.userDetails.lastName : ''} onChange={(event) => this.props.onChange('lastName', event.target.value)} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Email *:</label>
                            <input autoComplete="off" style={{borderColor: (this.props.userFormData.email && !this.props.emailIsValid) ? 'red' : ''}} type="email" disabled={this.props.disabled2 ? this.props.disabled2 : ''} defaultValue={this.props.userDetails ? this.props.userDetails.email : ''} placeholder="Email Address" class="form-control" onChange={(event) => {
                                this.props.updateSetState('emailIsValid', this.props.checkEmailIsValid(event.target.value))
                                this.props.onChange('email', event.target.value)
                            }}/>
                        </div>
        
                        {this.props.showPhoneNumber && <div class="form-group col-lg-6">
                            <label>Phone Number *:</label>
                            <div class="input-group">
                                <PhoneNumberComponent 
                                    phoneIsValid={this.props.phoneIsValid ? this.props.phoneIsValid : true}
                                    onChange={(status, phoneNumber) => {
                                        this.props.onChange('mobileNo', trimPhoneNumber(phoneNumber))
                                        this.props.updateSetState('phoneIsValid', status)
                                    }}
                                    defaultValue={(this.props.userDetails && this.props.userDetails.mobileNo) ? this.props.userDetails.mobileNo : ''}
                                    disabled={this.props.disabled2 ? this.props.disabled2 : ''}
                                />
                            </div>
                        </div>}
                        <div class="form-group col-lg-6">
                            <label style={{fontWeight: 400}}>Role *:</label><br/>
                            <Select 
                                options={this.props.roles.map(detail => { return ( {label: detail.name, value: detail.name} ) })}
                                onChange={(event) => this.props.onChange('roleName', event.value)}
                                disabled={this.props.disabled}
                                defaultValue={this.props.userFormData ? this.props.userFormData.roleName : this.props.userDetails.roleName}
                                isLoading={this.props.isGetRolesLoading}
                            />
                            {/* <div class="input-group">
                                <select autoComplete="off" data-live-search="true" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} name="role_id" onChange={(event) => this.props.onChange('roleName', event.target.value)} >
                                    <option selected disabled>Select a role</option>
                                    {this.props.roles.map((value, index) => <option value={value.name} selected={this.props.userDetails.roleName == value.name ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px', cursor: 'pointer'}}>
                                    <span class={`la ${this.props.isGetRolesLoading ? 'la-spin' : ''} la-refresh`} onClick={this.props.getRoles}></span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}