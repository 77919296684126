import React from 'react'
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

export default class AgentPermissions extends React.Component {
    render() {
        return <React.Fragment>
            <div class="row">
                <div class="col-lg-10 form-group">
                    <label style={{fontWeight: 100}}>Permissions *:</label>
                    <DualListBox
                        canFilter
                        preserveSelectOrder
                        options={this.props.permissions.map(permission => {
                            return(
                                {'label': permission.description, 'value': permission.id}
                            )
                        })}
                        selected={this.props.domainTypePermissions}
                        onChange={permission => this.props.onChange('domainTypePermissions', permission)}
                        disabled={this.props.disabled ? this.props.disabled : ''}
                        showHeaderLabels
                    />
                </div>
                {/* <div class="col-lg-12 form-group pull-right">
                    <button disabled={this.props.isFormValidForUpdate} type="button" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} class="btn btn-primary pull-right">Update</button>
                </div> */}
            </div>
        </React.Fragment>
    }
}