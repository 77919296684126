import moment from "moment";
import React from "react";
import { AccessDenied } from "../../../../../../components/access-denied";
import ContentHead from "../../../../../../components/content-head";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { CAN_VIEW_USER } from "../../../../../../constants/permissions.js";
import { walletProxyService } from "../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import WalletPerformanceSearch from "../../components/wallet-performance-search";
import WalletPerformanceTable from "../../components/wallet-performance-table";

export default class WalletPerformanceGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      walletRecord: [],
      dataCount: "",
      pages: 1,
      totalCount: 10,
      page: 1,
      pageSize: 20,
      status: "JOB-001",
      successMessage: "",
      errorMessage: "",
      showMessage: false,
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
    };
    this.getWalletRate = this.getWalletRate.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
  }

  componentDidMount() {
    this.getWalletRate();
  }

  async getWalletRate(page, pageSize) {
    const currentDate = moment();
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const formattedEndDate = currentDate.format("DD/MM/YYYY");
    const formattedStartDate = "10/10/2021";
    const status = this.state.status;
    try {
      const responseObj = await walletProxyService.getTheWalletPerformance(
        page ? page : this.state.page,
        pageSize ? pageSize : this.state.pageSize,
        formattedStartDate,
        formattedEndDate,
        status
      );
      const { response } = responseObj;
      if (
        response.status === true &&
        response.remark === "Completed Successfully"
      ) {
        this.setState({
          isLoading: false,
          walletRecord: response.responseMessage,
          dataCount: response.recordCount,
          startPage: null,
          pages: Math.ceil(response.recordCount / this.state.pageSize),
          page: 1,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        showMessage: true,
        errorMessage: "Oops! Something went wrong.",
      });
      setTimeout(() => {
        this.setState({ showMessage: false });
      }, 3000);
    }
  }

  async handleSearchRequest(status) {
    const currentDate = moment();
    this.setState({
      isLoading: true,
      canLoadData: true,
      status: status,
    });
    const formattedEndDate = currentDate.format("DD/MM/YYYY");
    const formattedStartDate = "10/10/2021";
    const { page, pageSize } = this.state;

    try {
      const responseObj = await walletProxyService.getTheWalletPerformance(
        page,
        pageSize,
        formattedStartDate,
        formattedEndDate,
        status
      );
      const { response } = responseObj;
      if (
        response.status === true &&
        response.remark === "Completed Successfully"
      ) {
        this.setState({
          isLoading: false,
          walletRecord: response.responseMessage,
          dataCount: response.recordCount,
          startPage: null,
          pages: Math.ceil(response.recordCount / this.state.pageSize),
          page: 1,
        });
      } else if (
        response.status === false &&
        response.remark === "No Record Selected/Record not found"
      ) {
        this.setState({
          isLoading: false,
          errorMessage: "Record not found",
          showMessage: true,
        });
        setTimeout(() => {
          this.setState({
            showMessage: false,
          });
        }, 3000);
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "Oops! Something went wrong.",
          showMessage: true,
        });
        setTimeout(() => {
          this.setState({
            showMessage: false,
          });
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        showMessage: true,
        errorMessage: "Oops! An error occurred, try again.",
      });
      setTimeout(() => {
        this.setState({ showMessage: false });
      }, 3000);
    }
  }
  render() {
    const { showMessage, successMessage, errorMessage } = this.state;
    return (
      <React.Fragment>
        <Notification
          successMessage={showMessage && successMessage}
          errorMessage={showMessage && errorMessage}
        />
        <ContentHead title={"Wallet-Performance"} totalCount={56} />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_USER]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <WalletPerformanceSearch
            handleSearchRequest={this.handleSearchRequest}
          />
          <GridLayout>
            <WalletPerformanceTable
              data={this.state.walletRecord}
              loading={this.state.isLoading}
              noDataText={`No record found`}
              dataCount={this.state.dataCount}
              getData={this.getWalletRate}
              canLoadData={this.state.canLoadData}
              pages={this.state.pages}
              page={this.state.page}
              pageSize={this.state.pageSize}
              startPage={this.state.startPage}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
