import React from "react";
import Statuses from "../../fixtures/approval_status.json";
import Categories from "../../fixtures/category_types.json";
import Select from "../select";

const KYCUpdateSearch = (props) => {
  const {
    updateAgentSearchParameters,
    handleSearchRequest,
    agentSearchParameters: { status, category } = {},
  } = props;

  const allCategoriesOption = Categories.find(option => option.value === null);

  return (
    <>
      <form
        class="search-params"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchRequest();
        }}
      >
        <div class="form-group row">
          <div class="col-md-3">
            <label>Category:</label>
             <Select
              options={Categories}
              onChange={(event) =>
                updateAgentSearchParameters("category", event.value)
              }
              defaultValue={Categories.find(option => option.value === category) || allCategoriesOption}
            />
          </div>
          <div class="col-md-3">
            <label>Batch ID:</label>
            <input
              type="text"
              class="search-input"
              onChange={(event) =>
                updateAgentSearchParameters(
                  "batchId",
                  event.target.value)
              }
            />
          </div>
          <div class="col-md-3">
            <label>Approval Status:</label>
            <Select
              options={Statuses}
              onChange={(event) =>
                updateAgentSearchParameters("status", event.value)
              }
              defaultValue={Statuses.find(option => option.value === status)}
            />
          </div>
          <div class="form-group row">
            <div class="col-md-2">
              <label class="search-label">&nbsp;&nbsp;</label>
              <button type="submit" class="search-button">
                Search
              </button>
            </div>
          </div>
        </div>

      </form>
    </>
  );
};
export default KYCUpdateSearch
