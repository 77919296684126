import { formatDate } from '../../utils/formatter';

export default class StateExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Country', key: 'country', title: 'Country', dataIndex: 'country'}
        ]
    }

    static reverseStateDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, countryId } = details
            let detailFormData = {
                name,
                country: countryId
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}