// export const CAN_VIEW_AGENT = 'GET_AGENT'
export const CAN_VIEW_AGENTS = 'GET_ALL_AGENTS'
//export const CAN_ACTIVATE_AGENT = 'ACTIVATE_AGENT'
export const CAN_SUSPEND_AGENT = 'SUSPEND_AGENT'
//export const CAN_RETIRE_AGENT = 'RETIRE_AGENT'
//export const CAN_UPDATE_AGENT = 'UPDATE_AGENT'
//export const CAN_CREATE_AGENT_USER = 'CREATE_AGENT_USER'
export const CAN_VIEW_AGENT_USERS = 'GET_AGENT_USERS'

export const CAN_SUSPEND_SUPER_AGENT = 'SUSPEND_SUPER_AGENT'
export const CAN_UPDATE_SUPER_AGENT = 'UPDATE_SUPER_AGENT'
export const CAN_RETIRE_SUPER_AGENT = 'RETIRE_SUPER_AGENT'
export const CAN_CREATE_SUPER_AGENT_USER = 'CREATE_SUPER_AGENT_USER'
export const CAN_VIEW_SUPER_AGENTS = 'GET_ALL_SUPER_AGENTS'
export const CAN_VIEW_SUPER_AGENT = 'GET_SUPER_AGENT'
export const CAN_VIEW_SUPER_AGENT_USERS = 'GET_SUPER_AGENT_USERS'

// export const CAN_CREATE_AGENT_CLASS = 'CREATE_AGENT_CLASS'
// export const CAN_UPDATE_AGENT_CLASS = 'UPDATE_AGENT_CLASS'

export const CAN_DISTRIBUTE_WALLET = 'DISTRIBUTE_FUNDS'


export const CAN_VIEW_FEE = 'VIEW_FEE'
export const CAN_CREATE_FEE = 'CREATE_FEE'
export const CAN_ACTIVATE_FEE = 'ACTIVATE_FEE'
export const CAN_DEACTIVATE_FEE = 'DEACTIVATE_FEE'
export const CAN_UPDATE_FEE = 'UPDATE_FEE'
export const CAN_DELETE_FEE = 'DELETE_FEE'

export const CAN_VIEW_COMMISSION_SPLIT_RULE = 'VIEW_COMMISSION_SPLIT_RULE' 
export const CAN_CREATE_COMMISSION_SPLIT_RULE = 'CREATE_COMMISSION_SPLIT_RULE'
export const CAN_UPDATE_COMMISSION_SPLIT_RULE = 'UPDATE_COMMISSION_SPLIT_RULE'
export const CAN_ACTIVATE_COMMISSION_SPLIT_RULE = 'ACTIVATE_COMMISSION_SPLIT_RULE'
export const CAN_DEACTIVATE_COMMISSION_SPLIT_RULE = 'DEACTIVATE_COMMISSION_SPLIT_RULE'
export const CAN_DELETE_COMMISSION_SPLIT_RULE = 'DELETE_COMMISSION_SPLIT_RULE'

export const CAN_VIEW_FEE_RULE = 'VIEW_FEE_RULE'
export const CAN_CREATE_FEE_RULE = 'CREATE_FEE_RULE' 
export const CAN_UPDATE_FEE_RULE = 'UPDATE_FEE_RULE'
export const CAN_ACTIVATE_FEE_RULE = 'ACTIVATE_FEE_RULE' 
export const CAN_DEACTIVATE_FEE_RULE = 'DEACTIVATE_FEE_RULE'
export const CAN_DELETE_FEE_RULE = 'DELETE_FEE_RULE'

export const CAN_VIEW_SERVICE_OPTION = 'VIEW_SERVICE_OPTION' 
export const CAN_UPDATE_SERVICE_OPTION = 'UPDATE_SERVICE_OPTION'
export const CAN_ACTIVATE_SERVICE_OPTION = 'ACTIVATE_SERVICE_OPTION'
export const CAN_DEACTIVATE_SERVICE_OPTION = 'DEACTIVATE_SERVICE_OPTION'

export const CAN_VIEW_SERVICE = 'VIEW_SERVICE'
export const CAN_UPDATE_SERVICE = 'UPDATE_SERVICE'
export const CAN_ACTIVATE_SERVICE = 'ACTIVATE_SERVICE'
export const CAN_DEACTIVATE_SERVICE = 'DEACTIVATE_SERVICE'

export const CAN_VIEW_SERVICE_TYPE = 'VIEW_SERVICE_TYPE'
export const CAN_UPDATE_SERVICE_TYPE = 'UPDATE_SERVICE_TYPE'
export const CAN_ACTIVATE_SERVICE_TYPE = 'ACTIVATE_SERVICE_TYPE'
export const CAN_DEACTIVATE_SERVICE_TYPE = 'DEACTIVATE_SERVICE_TYPE'
export const CAN_DELETE_SERVICE_TYPE = 'DELETE_SERVICE_TYPE'

export const CAN_VIEW_USER_MAGT = 'VIEW_USER_MAGT'

export const CAN_VIEW_USER = 'VIEW_USER'
export const CAN_CREATE_USER = 'CREATE_USER'
export const CAN_UPDATE_USER = 'UPDATE_USER'
export const CAN_ACTIVATE_USER = 'ACTIVATE_USER'
export const CAN_DEACTIVATE_USER = 'DEACTIVATE_USER'
export const CAN_DELETE_USER = 'DELETE_USER'

export const CAN_VIEW_ROLE = 'VIEW_ROLE'
export const CAN_CREATE_ROLE = 'CREATE_ROLE'

export const CAN_PLACE_LIEN = 'PLACE_LIEN'
export const CAN_LIFT_LIEN = 'LIFT_LIEN'
export const CAN_CANCEL_LIEN = 'CANCEL_LIEN'

export const CAN_UPDATE_ROLE = 'UPDATE_ROLE'
export const CAN_ACTIVATE_ROLE = 'ACTIVATE_ROLE'
export const CAN_DEACTIVATE_ROLE = 'DEACTIVATE_ROLE'
export const CAN_DELETE_ROLE = 'DELETE_ROLE'

export const CAN_VIEW_BANK = 'CAN_VIEW_BANK'
export const CAN_UPDATE_BANK = 'CAN_UPDATE_BANK'

export const CAN_VIEW_PERMISSION_GROUP = 'VIEW_PERMISSION_GROUP'

export const CAN_VIEW_PERMISSION = 'VIEW_PERMISSION'

export const CAN_VIEW_AGENT_MAGT = 'VIEW_AGENT_MAGT'

export const CAN_VIEW_AGENT_HIERARCHY = 'VIEW_AGENT_HIERARCHY'

export const CAN_VIEW_AGENT_HIERARCHY_PERMISSION = 'VIEW_AGENT_HIERARCHY_PERMISSION'
export const CAN_UPDATE_AGENT_HIERARCHY_PERMISSION = 'UPDATE_AGENT_HIERARCHY_PERMISSION'

export const CAN_VIEW_AGENT_HIERARCHY_ROLE = 'VIEW_AGENT_HIERARCHY_ROLE'
export const CAN_CREATE_AGENT_HIERARCHY_ROLE = 'CREATE_AGENT_HIERARCHY_ROLE'
export const CAN_UPDATE_AGENT_HIERARCHY_ROLE = 'UPDATE_AGENT_HIERARCHY_ROLE'
export const CAN_ACTIVATE_AGENT_HIERARCHY_ROLE = 'ACTIVATE_AGENT_HIERARCHY_ROLE'
export const CAN_DEACTIVATE_AGENT_HIERARCHY_ROLE = 'DEACTIVATE_AGENT_HIERARCHY_ROLE'
export const CAN_DELETE_AGENT_HIERARCHY_ROLE = 'DELETE_AGENT_HIERARCHY_ROLE'

export const CAN_VIEW_AGENT_CLASS = 'VIEW_AGENT_CLASS'
export const CAN_CREATE_AGENT_CLASS = 'CREATE_AGENT_CLASS'
export const CAN_UPDATE_AGENT_CLASS = 'UPDATE_AGENT_CLASS'
export const CAN_ACTIVATE_AGENT_CLASS = 'ACTIVATE_AGENT_CLASS'
export const CAN_DEACTIVATE_AGENT_CLASS = 'DEACTIVATE_AGENT_CLASS'
export const CAN_DELETE_AGENT_CLASS = 'DELETE_AGENT_CLASS'

export const CAN_VIEW_APPLICATION = 'VIEW_APPLICATION'
export const CAN_CREATE_APPLICATION = 'CREATE_APPLICATION'
export const CAN_UPDATE_APPLICATION = 'UPDATE_APPLICATION'
export const CAN_ACTIVATE_APPLICATION = 'ACTIVATE_APPLICATION'
export const CAN_DEACTIVATE_APPLICATION = 'DEACTIVATE_APPLICATION'
export const CAN_DELETE_APPLICATION = 'DELETE_APPLICATION'
export const CAN_VIEW_APPLICATIONS = 'GET_ALL_APPLICATIONS'
export const CAN_APPROVE_APPLICATIONS = 'APPROVE_APPLICATION'
export const CAN_VALIDATE_APPLICATIONS = 'VALIDATE_APPLICATION'
export const CAN_REJECT_APPLICATIONS = 'REJECT_APPLICATION'
export const CAN_CANCEL_APPLICATION = 'CANCEL_APPLICATION'

export const CAN_VIEW_SERVICE_PROVIDER = 'VIEW_SERVICE_PROVIDER'
export const CAN_CREATE_SERVICE_PROVIDER = 'CREATE_SERVICE_PROVIDER'
export const CAN_UPDATE_SERVICE_PROVIDER = 'UPDATE_SERVICE_PROVIDER'
export const CAN_ACTIVATE_SERVICE_PROVIDER = 'ACTIVATE_SERVICE_PROVIDER'
export const CAN_DEACTIVATE_SERVICE_PROVIDER = 'DEACTIVATE_SERVICE_PROVIDER'
export const CAN_DELETE_SERVICE_PROVIDER = 'DELETE_SERVICE_PROVIDER'
export const CAN_RETIRE_SERVICE_PROVIDER = 'RETIRE_SERVICE_PROVIDER'
export const CAN_MANAGE_SP_DOMAIN = 'MANAGE_SP_DOMAIN'
export const CAN_VIEW_SP_USER = 'VIEW_SP_USER'
export const CAN_CREATE_SP_USER = 'CREATE_SP_USER'
export const CAN_UPDATE_SP_USER = 'UPDATE_SP_USER'
export const CAN_ACTIVATE_SP_USER = 'ACTIVATE_SP_USER'
export const CAN_DEACTIVATE_SP_USER = 'DEACTIVATE_SP_USER'
export const CAN_DELETE_SP_USER = 'DELETE_SP_USER'
export const CAN_RETIRE_SP_USER = 'RETIRE_SP_USER'
export const CAN_VIEW_SP_ROLE = 'VIEW_SP_ROLE'
export const CAN_CREATE_SP_ROLE = 'CREATE_SP_ROLE'
export const CAN_UPDATE_SP_ROLE = 'UPDATE_SP_ROLE'
export const CAN_ACTIVATE_SP_ROLE = 'ACTIVATE_SP_ROLE'
export const CAN_DEACTIVATE_SP_ROLE = 'DEACTIVATE_SP_ROLE'
export const CAN_DELETE_SP_ROLE = 'DELETE_SP_ROLE'
// export const CAN_CREATE_SERVICE_PROVIDERS = 'CREATE_SERVICE_PROVIDER'
// export const CAN_DELETE_SERVICE_PROVIDERS = 'DELETE_SERVICE_PROVIDER'
// export const CAN_UPDATE_SERVICE_PROVIDERS = 'UPDATE_SERVICE_PROVIDER'
export const CAN_VIEW_SERVICE_PROVIDERS = 'GET_ALL_SERVICE_PROVIDERS'

export const CAN_VIEW_AGGREGATOR = 'VIEW_AGGREGATOR'
export const CAN_CREATE_AGGREGATOR = 'CREATE_AGGREGATOR'
export const CAN_UPDATE_AGGREGATOR = 'UPDATE_AGGREGATOR'
export const CAN_ACTIVATE_AGGREGATOR = 'ACTIVATE_AGGREGATOR'
export const CAN_DEACTIVATE_AGGREGATOR = 'DEACTIVATE_AGGREGATOR'
export const CAN_RETIRE_AGGREGATOR = 'RETIRE_AGGREGATOR'
export const CAN_MANAGE_AGGREGATOR_DOMAIN = 'MANAGE_AGGREGATOR_DOMAIN'
export const CAN_VIEW_AGGREGATOR_USER = 'VIEW_AGGREGATOR_USER'
export const CAN_CREATE_AGGREGATOR_USER = 'CREATE_AGGREGATOR_USER'
export const CAN_UPDATE_AGGREGATOR_USER = 'UPDATE_AGGREGATOR_USER'
export const CAN_ACTIVATE_AGGREGATOR_USER = 'ACTIVATE_AGGREGATOR_USER'
export const CAN_DEACTIVATE_AGGREGATOR_USER = 'DEACTIVATE_AGGREGATOR_USER'
export const CAN_RETIRE_AGGREGATOR_USER = 'RETIRE_AGGREGATOR_USER'
export const CAN_DELETE_AGGREGATOR_USER = 'DELETE_AGGREGATOR_USER'
export const CAN_VIEW_AGGREGATOR_ROLE = 'VIEW_AGGREGATOR_ROLE'
export const CAN_CREATE_AGGREGATOR_ROLE = 'CREATE_AGGREGATOR_ROLE'
export const CAN_UPDATE_AGGREGATOR_ROLE = 'UPDATE_AGGREGATOR_ROLE'
export const CAN_ACTIVATE_AGGREGATOR_ROLE = 'ACTIVATE_AGGREGATOR_ROLE'
export const CAN_DEACTIVATE_AGGREGATOR_ROLE = 'DEACTIVATE_AGGREGATOR_ROLE'
export const CAN_DELETE_AGGREGATOR_ROLE = 'DELETE_AGGREGATOR_ROLE'

export const CAN_SUSPEND_FOR_KYC = 'SUSPEND_FOR_KYC '
export const CAN_VIEW_AGENT = 'VIEW_AGENT'
export const CAN_VIEW_EXEMPTED_AGENT = 'VIEW_EXEMPTED_AGENT'
export const CAN_CREATE_AGENT = 'CREATE_AGENT'
export const CAN_UPDATE_AGENT = 'UPDATE_AGENT'
export const CAN_ACTIVATE_AGENT = 'ACTIVATE_AGENT'
export const CAN_DEACTIVATE_AGENT = 'DEACTIVATE_AGENT'
export const CAN_RETIRE_AGENT = 'RETIRE_AGENT'
export const CAN_MANAGE_AGENT_DOMAIN = 'MANAGE_AGENT_DOMAIN'
export const CAN_VIEW_AGENT_USER = 'VIEW_AGENT_USER'
export const CAN_CREATE_AGENT_USER = 'CREATE_AGENT_USER'
export const CAN_UPDATE_AGENT_USER = 'UPDATE_AGENT_USER'
export const CAN_ACTIVATE_AGENT_USER = 'ACTIVATE_AGENT_USER'
export const CAN_DEACTIVATE_AGENT_USER = 'DEACTIVATE_AGENT_USER'
export const CAN_RETIRE_AGENT_USER = 'RETIRE_AGENT_USER'
export const CAN_DELETE_AGENT_USER = 'DELETE_AGENT_USER'
export const CAN_VIEW_AGENT_ROLE = 'VIEW_AGENT_ROLE'
export const CAN_CREATE_AGENT_ROLE = 'CREATE_AGENT_ROLE'
export const CAN_UPDATE_AGENT_ROLE = 'UPDATE_AGENT_ROLE'
export const CAN_ACTIVATE_AGENT_ROLE = 'ACTIVATE_AGENT_ROLE'
export const CAN_DEACTIVATE_AGENT_ROLE = 'DEACTIVATE_AGENT_ROLE'
export const CAN_DELETE_AGENT_ROLE = 'DELETE_AGENT_ROLE'
export const CAN_VIEW_AGENT_DEVICE = 'VIEW_AGENT_DEVICE'
export const CAN_UPDATE_AGENT_DEVICE = 'UPDATE_AGENT_DEVICE'
export const CAN_ACTIVATE_AGENT_DEVICE = 'ADMIN_UPDATE_DEVICE'
export const CAN_DEACTIVATE_AGENT_DEVICE = 'ADMIN_UPDATE_DEVICE'
export const CAN_DELETE_AGENT_DEVICE = 'ADMIN_RETIRE_DEVICE'

export const CAN_ACTIVATE_FIP_USER = 'ACTIVATE_USER_FIP'
export const CAN_DEACTIVATE_FIP_USER = 'DEACTIVATE_USER_FIP'
export const CAN_RETIRE_FIP_USER = 'DELETE_USER_FIP'
export const CAN_VIEW_FIP_USER = 'VIEW_USER_FIP'
export const CAN_CREATE_FIP_USER = 'CREATE_USER_FIP'
export const CAN_UPDATE_FIP_USER = 'UPDATE_USER_FIP'

export const CAN_VIEW_TRANSACTION = 'VIEW_TRANSACTIONS'
export const CAN_PERFORM_BULK = 'PERFORM_BULK'
export const CAN_VIEW_SETTLEMENT = 'VIEW_SETTLEMENT'
export const CAN_VIEW_AUDIT = 'VIEW_AUDIT'

export const CAN_ADD_WORKFLOW_CONFIGURATION_STEP = "ADD_WORKFLOW_STEP"
export const CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP = "REMOVE_WORKFLOW_STEP"
export const CAN_MOVE_WORKFLOW_CONFIGURATION_STEP = "MOVE_WORKFLOW_STEP"
export const CAN_VIEW_WORKFLOW_CONFIGURATION = "VIEW_WORKFLOW_CONFIGURATION"
export const CAN_ACTIVATE_WORKFLOW_CONFIGURATION = "ACTIVATE_WORKFLOW"
export const CAN_DEACTIVATE_WORKFLOW_CONFIGURATION = "DEACTIVATE_WORKFLOW"

export const CAN_CONFIGURE_SYSTEM = 'CONFIGURE_SYSTEM'

export const CAN_VIEW_COUNTRY = 'VIEW_COUNTRY'
export const CAN_CREATE_COUNTRY = 'CREATE_COUNTRY'
export const CAN_UPDATE_COUNTRY = 'UPDATE_COUNTRY'
export const CAN_DELETE_COUNTRY = 'DELETE_COUNTRY'

export const CAN_VIEW_REGION = 'VIEW_REGION'
export const CAN_CREATE_REGION = 'CREATE_REGION'
export const CAN_UPDATE_REGION = 'UPDATE_REGION'
export const CAN_DELETE_REGION = 'DELETE_REGION'
export const CAN_ACTIVATE_REGION = 'ACTIVATE_REGION'
export const CAN_DEACTIVATE_REGION = 'DEACTIVATE_REGION'


export const CAN_VIEW_STATE = 'VIEW_STATE'
export const CAN_CREATE_STATE = 'CREATE_STATE'
export const CAN_UPDATE_STATE = 'UPDATE_STATE'
export const CAN_DELETE_STATE = 'DELETE_STATE'

export const CAN_VIEW_LGA = 'VIEW_LGA'
export const CAN_CREATE_LGA = 'CREATE_LGA'
export const CAN_UPDATE_LGA = 'UPDATE_LGA'
export const CAN_DELETE_LGA = 'DELETE_LGA'

export const CAN_VIEW_CURRENCY = 'VIEW_CURRENCY'
export const CAN_ACTIVATE_CURRENCY = 'ACTIVATE_CURRENCY'
export const CAN_DEACTIVATE_CURRENCY = 'DEACTIVATE_CURRENCY'

export const CAN_VIEW_LANGUAGE = 'VIEW_LANGUAGE'
export const CAN_ACTIVATE_LANGUAGE = 'ACTIVATE_LANGUAGE'
export const CAN_DEACTIVATE_LANGUAGE = 'DEACTIVATE_LANGUAGE'

export const CAN_MESSAGING_READ_TOPIC = 'MESSAGING_READ_TOPIC'
export const CAN_MESSAGING_WRITE_TOPIC = 'MESSAGING_WRITE_TOPIC'
export const CAN_MESSAGING_READ_TEMPLATE = 'MESSAGING_READ_TEMPLATE'
export const CAN_MESSAGING_WRITE_TEMPLATE = 'MESSAGING_WRITE_TEMPLATE'
export const CAN_MESSAGING_SEND = 'MESSAGING_SEND'

export const CAN_AGENT_BALANCE_VIEW = 'AGENT_BALANCE_VIEW'
export const CAN_TRIAL_BALANCE_VIEW = 'TRIAL_BALANCE_VIEW'
export const CAN_WALLET_STMT_VIEW = 'WALLET_STMT_VIEW'
export const CAN_EOD_REPORT_VIEW = 'EOD_REPORT_VIEW'

export const CAN_REMAP_DEVICE = 'ADMIN_REMAP_DEVICE'
export const CAN_GET_DEVICES_BY_UUID = 'GET_DEVICES_BY_UUID'


export const CAN_ASSIGN_ROLE_PERMISSIONS = 'Can Assign Role Permissions'
export const CAN_ASSIGN_USER_ROLES = 'Can Assign User Role'

export const CAN_CREATE_DOMAINS = 'Can Create Domains'
export const CAN_CREATE_PERMISSIONS = 'Can Create Permissions'
export const CAN_CREATE_ROLES = 'Can Create Role'

export const CAN_CREATE_USERS = 'Can Create User'

export const CAN_VIEW_DOMAINS = 'Can View Domains'

export const CAN_VIEW_PERMISSIONS = 'GET_PERMISSIONS'

export const CAN_VIEW_ROLES = 'GET_ROLES'

export const CAN_VIEW_USERS = 'Can View User'

export const CAN_DELETE_DOMAINS = 'Can Delete Domain'
export const CAN_DELETE_PERMISSIONS = 'Can Delete Permissions'
export const CAN_DELETE_ROLE_PERMISSIONS = 'Can Delete Role Permissions'
export const CAN_DELETE_ROLES = 'Can Delete Role'
export const CAN_DELETE_USER_ROLES = 'Can Delete User Role'
export const CAN_DELETE_USERS = 'Can Delete User'

export const CAN_UPDATE_ROLES = 'Can Edit Role'
export const CAN_UPDATE_DOMAINS = 'Can Update Domains'
export const CAN_UPDATE_PERMISSIONS = 'Can Update Permissions'
export const CAN_UPDATE_USERS = 'Can Update User'

export const CAN_VIEW_AUDITS = 'Can View Audit'

export const CAN_VIEW_DASHBOARD = 'Can View Dashboard'