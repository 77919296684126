import Chart from "chart.js";
import React from "react";

export default class MetricChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidUpdate() {
    // this.myChart.data.labels = this.props.data.map((d) => d.time);
    // this.myChart.data.datasets[0].data = this.props.data.map((d) => d.value);
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.chartRef.current, {
      type: "line",
      options: {
        title: {
          display: false,
        },

        tooltips: {
          mode: "nearest",
          intersect: false,
          position: "nearest",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 1,
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: false,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
            },
          ],
          yAxes: [
            {
              display: false,
              gridLines: false,
              scaleLabel: {
                display: true,
                labelString: "Value",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        elements: {
          line: {
            borderJoinStyle: "round",
          },
          point: {
            radius: 4,
            borderWidth: 12,
          },
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
      },
      data: {
        // labels: this.props.data.map((d) => d.name),
        labels: ["route", "route", "route"],
        datasets: [
          {
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            label: "QT",
            data: this.props.data1,
            lineTension: 1,
            //steppedLine: "middle",
            //borderDash: [5, 5],
            fill: false,
            pointBackgroundColor: Chart.helpers
              .color("#000000")
              .alpha(0)
              .rgbString(),
            pointBorderColor: Chart.helpers
              .color("#000000")
              .alpha(0)
              .rgbString(),
            pointHoverBackgroundColor: "#d9534f",
            pointHoverBorderColor: Chart.helpers
              .color("#000000")
              .alpha(0.1)
              .rgbString(),
          },

          {
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",

            label: "NIP",
            data: this.props.data2,
            fill: false,
            lineTension: 1,
            pointBackgroundColor: Chart.helpers
              .color("#000000")
              .alpha(0)
              .rgbString(),
            pointBorderColor: Chart.helpers
              .color("#000000")
              .alpha(0)
              .rgbString(),
            pointHoverBackgroundColor: "#d9534f",
            pointHoverBorderColor: Chart.helpers
              .color("#000000")
              .alpha(0.1)
              .rgbString(),
          },
        ],
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid-half">
          <div class="kt-portlet__head kt-portlet__space-x">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                <a
                  style={{ color: "#464457" }}
                  //   href={`home/transaction-history?transactionType=${this.props.parameter}`}
                >
                  QT & NIP Chart Board
                </a>
                <br />
                <small> Route count</small>
              </h3>
            </div>
          </div>

          <div class="kt-portlet__body kt-portlet__body--fluid">
            <div class="kt-widget">
              {this.props.canReload && this.props.loading && (
                <div class="text-center">
                  <p>Something went wrong. Please try again.</p>
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    style={{ backgroundColor: "#00425f", color: "#ffffff" }}
                    onClick={this.props.reload}
                  >
                    Reload
                  </button>
                </div>
              )}

              <div class="kt-widget20__content kt-portlet__space-x"></div>
              <div class="kt-widget20__chart" style={{ height: "100px" }}>
                {/* {!this.props.loading && (
                  <div class="loading" style={{ textAlign: "center" }}>
                    Loading
                  </div>
                )} */}
                <div class="loading" style={{ textAlign: "center" }}>
                  Real-time update
                </div>
                <div class="text-center">
                  {(this.props.data1 && this.props.data2).length > 1
                    ? ""
                    : "No enough data yet to display chart"}
                </div>
                <canvas ref={this.chartRef} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
