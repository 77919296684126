import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_UPDATE_LGA, CAN_VIEW_LGA } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import LgaForm from '../../components/lga-form';

export default class ViewLgaScene extends BaseScene {  
  constructor (props) {
    super(props)

    this.state = {
      canSave: null,
      countries: [],
      states: [],
      updateButton: true,
      saveButton: false,
      disabled: true,
      pageTitle: "View Local Government",
      statuses: [
        {"id": 1, "name": "Active"},
        {"id": 2, "name": "Inactive"}
      ],
      lgaFormData: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

  componentDidMount() {
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onUpdateButtonClick () {
    this.setState({
        disabled: false,
        saveButton: true,
        updateButton: false,
        pageTitle: 'Update Local Government',
    })
  }

  async onSaveButtonClick () {
    
  }

  updateSetState (type, value) {
    this.setState({lgaFormData: {...this.state.lgaFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
        {this.state.updateButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_LGA]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Update</button></ProtectedComponent>}
        {this.state.saveButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_LGA]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
        countries={this.state.countries}
        states={this.state.states}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Local Government"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_LGA]}>
        <GridLayout>
          <LgaForm 
            onChange={this.updateSetState}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
