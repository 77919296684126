import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { convertToMajor } from '../../../../../../utils/converter';
import {
  formatCurrency,
  formatDateTime,
} from '../../../../../../utils/formatter';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class PosRequestsTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus',
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === '430px'
          ? '860px'
          : this.state.tableHeight === '860px'
          ? 'auto'
          : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus',
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className='-loading -active'>
            <div className='-loading-inner'>
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: 'Agent Username',
        accessor: 'agentUsername',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 170,
      },
      {
        Header: 'Initiated By',
        accessor: 'initiatorUsername',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 170,
      },
      {
        Header: 'POS Channel',
        accessor: 'requestDeviceChannel',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 170,
      },
      {
        Header: 'POS Unit Price',
        accessor: 'posUnitPrice',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 150,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: 'Request Type',
        accessor: 'requestType',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 155,
        // Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: 'Request ID',
        accessor: 'requestId',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 155,
        // Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: 'Terminal Model',
        accessor: 'terminalModel',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 160,
        // Cell: ({ row, original }) => (
        //   <span>{original.responseCode ? original.responseCode : ""}</span>
        // ),
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 80,
      },
      {
        Header: 'Total Price',
        accessor: 'totalPrice',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 100,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: 'Terminal Type',
        accessor: 'terminalType',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 100,
      },
      {
        Header: 'Date',
        accessor: 'dateInitiated',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 200,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : '';
        },
      },
      {
        Header: 'Date Approved/Declined',
        accessor: 'dateApprovedOrDeclined',
        resizable: false,
        headerStyle: { 'white-space': 'unset' },
        style: { 'white-space': 'unset' },
        width: 200,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : '';
        },
      },
      {
        Header: 'Action',
        accessor: 'requestId',
        overflow: 'visible',
        resizable: false,
        sortable: false,
        headerStyle: { 'white-space': 'unset' },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: '20px',
              textAlign: 'center',
              whiteSpace: 'unset',
            },
          };
        },
        width: 50,
        Cell: ({ row, original }) => (
          <div class='dropdown'>
            {original.approvalStatus === 'declined' ? (
              ''
            ) : (
              <>
                <a
                  href='#'
                  class='btn btn-hover-brand btn-icon btn-pill'
                  data-toggle='dropdown'
                >
                  <i class='la la-ellipsis-v'></i>
                </a>
                <div class='dropdown-menu dropdown-menu-right'>
                  <ProtectedComponent
                    requiredPermissions={[this.props.canView]}
                  >
                    <a
                      data-toggle='modal'
                      data-target='#pos_request_modal'
                      onClick={() =>
                        this.props.getClickedAction(original, 'view')
                      }
                      /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class='dropdown-item'
                      style={{ color: '#A7ABC3', cursor: 'pointer' }}
                    >
                      <i class='la la-eye'></i>
                      View
                    </a>
                  </ProtectedComponent>
                </div>
              </>
            )}
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          fileName={'Pos Requests'}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getPosRequests}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          desiredStatus={this.props.desiredStatus}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ''}
          style={{
            border: 'none',
            boxShadow: 'none',
            whiteSpace: 'unset',
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              agentUsername,
              approvalStatus,
              toDate,
              fromDate,
              pageNumber,
              pageSize,
            } = this.props.searchParameters;
            this.setState({ page: state.pageNumber });
            if (this.props.canLoadData) {
              this.props.getPosRequests(
                state.page + 1,
                state.pageSize,
                agentUsername,
                approvalStatus,
                toDate,
                fromDate,
                pageNumber,
                pageSize
              );
            }
          }}
          headerNodeHeight={`123`}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: 'flex',
              overflow: 'visible',
              color: 'black',
              fontSize: '11.5px',
              fontWeight: '50',
              paddingLeft: '5px',
              paddingRight: '5px',
              alignItems: 'center',
              padding: '0px, 0',
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: '#00425f',
              borderBottom: '0.1px solid #DBDEDE',
              borderWidth: '0.1px',
              padding: '10px 0',
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: '100',
              textAlign: 'left',
              paddingLeft: '5px',
              paddingRight: '5px',
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
            },
          })}
          className='-striped -highlight'
        />
      </React.Fragment>
    );
  }
}
