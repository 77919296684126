import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CountryGridScene from './scenes/country-grid'
import BaseScene from '../../../../../components/scenes/component';
import AddCountryScene from './scenes/add-country';
import ViewCountryScene from './scenes/view-country';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_COUNTRY, CAN_CREATE_COUNTRY } from '../../../../../constants/permissions.js'

export default class CountryScene extends BaseScene {
  sceneName = 'country-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_COUNTRY]} exact path='/home/system-configuration/country-management' component={CountryGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_COUNTRY]} exact path='/home/system-configuration/country-management/add' component={AddCountryScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_COUNTRY]} exact path='/home/system-configuration/country-management/:id/view' component={ViewCountryScene} />
    </Router>
  }
}
