import React from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import BaseScene from '../../../../../../components/scenes/component'
import DomainUserGridScene from './scenes/user-grid'
import DomainUserViewScene from './scenes/view-user'
import DomainUserAddScene from './scenes/add-user'
// import ChangePasswordGridScene from './scenes/change-password'
// import AccountSettingsGridScene from './scenes/account-settings'
// import UserSettingsGridScene from './scenes/user-settings'
import { isAuthorized } from '../../../../../../mixins/authentication'
import { 
  CAN_VIEW_AGENT_USER, CAN_CREATE_AGENT_USER, CAN_VIEW_AGGREGATOR_USER,
  CAN_CREATE_AGGREGATOR_USER, CAN_VIEW_SP_USER, CAN_CREATE_SP_USER 
} from '../../../../../../constants/permissions'

export default class DomainUserScene extends BaseScene {
  sceneName = 'user-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SP_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_AGENT_USER]} exact path='/home/domain-management/:domainCode/users' component={DomainUserGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SP_USER, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_AGENT_USER]} exact path='/home/domain-management/:domainCode/users/view' component={DomainUserViewScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_SP_USER, CAN_CREATE_AGGREGATOR_USER, CAN_CREATE_AGENT_USER]} exact path='/home/domain-management/:domainCode/users/add' component={DomainUserAddScene} />
      {/* <Route exact path='/home/profile/change-password' component={ChangePasswordGridScene} />
      <Route exact path='/home/profile/account-settings' component={AccountSettingsGridScene} />
      <Route exact path='/home/profile/user-settings' component={UserSettingsGridScene} /> */}
    </Router>
  }
}
