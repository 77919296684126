import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import {
  CAN_ADD_WORKFLOW_CONFIGURATION_STEP,
  CAN_MOVE_WORKFLOW_CONFIGURATION_STEP,
  CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP,
  CAN_VIEW_WORKFLOW_CONFIGURATION,
} from "../../../../../../../constants/permissions.js";
import {
  platformManagement,
  workFlowManagement,
} from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import AddWorkflowApprover from "../../components/add-workflow-approver";
import MoveWorkflowStep from "../../components/move-workflow-step";
import NewWorkflowStep from "../../components/new-workflow-step";
import WorkflowDetails from "../../components/workflow-details";

export default class ViewWorkflowConfigurationDetailsScene extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      modalTitle: "Description",
      modalBody: null,
      workflowDetails: {
        steps: [],
      },
      approvalFormData: {},
      allUsers: [],
      allRoles: [],
      isLoading: false,
      formData: {
        username: "james.doe222@gmail.com",
      },
    };

    this.updateWorkflow = this.updateWorkflow.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
    this.getRemoveApproverDetails = this.getRemoveApproverDetails.bind(this);
    this.getAddApproverDetails = this.getAddApproverDetails.bind(this);
    this.getMoveStepDetails = this.getMoveStepDetails.bind(this);
    this.getRemoveStepDetails = this.getRemoveStepDetails.bind(this);
    this.getAddStepDetails = this.getAddStepDetails.bind(this);
  }

  componentDidMount() {
    this.workflowId = this.props.match.params.id;
    this.getDomainUsers();
    this.getRoles();
    this.getWorkflowDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getWorkflowDetails() {
    this.setState({ isLoading: true });
    const responseObj = await workFlowManagement.getWorkflowDetails(
      this.workflowId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        workflowDetails: response,
        isLoading: false,
      });
    } else {
      this.setState({
        workflowDetails: {
          steps: [],
        },
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  async getDomainUsers() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getUsers(
      this.state.domainUserCode
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        allUsers: response,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        allUsers: [],
      });
    }
  }

  async getRoles() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getRoles();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        allRoles: response,
        isLoading: false,
        dataCount: response.length,
      });
    } else {
      this.setState({
        isLoading: false,
        dataCount: 0,
        allRoles: [],
        errorMessage: response,
      });
    }
  }

  async updateWorkflow() {
    this.setState({ isLoading: true });
    let saveApplicationResponseObj;
    const { stepId, username, role, moveFrom, moveTo } = this.state.formData;
    if (this.state.actionType === "addStep") {
      const payload = {
        id: stepId,
        usernameOrRole: [username ? username : role],
      };
      saveApplicationResponseObj = await workFlowManagement.createWorkflowStep(
        this.workflowId,
        payload
      );
    } else if (this.state.actionType === "removeStep") {
      saveApplicationResponseObj = await workFlowManagement.removeWorkflowStep(
        this.workflowId,
        stepId
      );
    } else if (this.state.actionType === "addApprover") {
      const payload = [username];
      saveApplicationResponseObj = await workFlowManagement.addWorkflowStepApprovers(
        this.workflowId,
        stepId,
        payload
      );
    } else if (this.state.actionType === "removeApprover") {
      const payload = [username];
      saveApplicationResponseObj = await workFlowManagement.deleteWorkflowStepApprovers(
        this.workflowId,
        stepId,
        payload
      );
    } else if (this.state.actionType === "moveStep") {
      saveApplicationResponseObj = await workFlowManagement.moveWorkflowStep(
        this.workflowId,
        moveFrom,
        moveTo
      );
    }
    const saveApplicationStatus = saveApplicationResponseObj.status;
    const saveApplicationResponse = saveApplicationResponseObj.response;
    if (saveApplicationStatus === SUCCESS_STATUS) {
      this.setState({
        successMessage: "Workflow configuration updated succesfully!",
        isLoading: false,
        username: null,
        stepId: null,
      });
    } else {
      this.setState({
        errorMessage: saveApplicationResponse,
        isLoading: false,
        username: null,
        stepId: null,
      });
    }
    this.getWorkflowDetails(this.workflowId);
  }

  getRemoveApproverDetails(stepId, username, name) {
    this.setState({
      modalTitle: `Remove Step ${stepId} Approver`,
      modalBody: `Do you want to remove ${name} as an approver in step ${stepId} ?`,
      height: `230px`,
      formData: { ...this.state.formData, stepId, username },
      stepId: stepId,
      username: username,
      actionType: "removeApprover",
      textAlign: "center",
    });
  }

  getRemoveStepDetails(stepId) {
    this.setState({
      modalTitle: `Remove Workflow Step`,
      modalBody: `Do you want to remove step ${stepId} from workflow configruation ?`,
      height: `230px`,
      stepId: stepId,
      formData: { ...this.state.formData, stepId },
      actionType: "removeStep",
      textAlign: "center",
    });
  }

  getMoveStepDetails(stepId) {
    const form = (
      <MoveWorkflowStep
        stepId={stepId}
        workflowDetails={this.state.workflowDetails}
        updateSetState={this.updateSetState}
      />
    );
    this.setState({
      modalTitle: `Move Workflow Step`,
      modalBody: form,
      height: `230px`,
      formData: { ...this.state.formData, moveFrom: stepId },
      moveFrom: stepId,
      actionType: "moveStep",
      textAlign: "center",
    });
  }

  getAddApproverDetails(stepId) {
    const form = (
      <AddWorkflowApprover
        stepId={stepId}
        users={this.state.allUsers}
        formData={this.state.formData}
        updateSetState={this.updateSetState}
      />
    );
    this.setState({
      modalTitle: `Add Step ${stepId} Approver`,
      modalBody: form,
      height: `470px`,
      actionType: "addApprover",
      formData: { ...this.state.formData, stepId },
      stepId: stepId,
      textAlign: "left",
    });
  }

  getAddStepDetails() {
    const form = (
      <NewWorkflowStep
        users={this.state.allUsers}
        formData={this.state.formData}
        roles={this.state.allRoles}
        updateSetState={this.updateSetState}
      />
    );
    this.setState({
      modalTitle: `Add Step`,
      modalBody: form,
      height: `470px`,
      actionType: "addStep",
      textAlign: "left",
    });
  }

  updateSetState(type, value) {
    this.setState({ formData: { ...this.state.formData, [type]: value } });
    //this.setState({[type]: value})
  }

  render() {
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Workflow Management"}
          withoutSearch
          subtitle={`Configuration Details`}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[
            CAN_VIEW_WORKFLOW_CONFIGURATION,
            CAN_ADD_WORKFLOW_CONFIGURATION_STEP,
            CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP,
            CAN_MOVE_WORKFLOW_CONFIGURATION_STEP,
          ]}
        >
          <GridLayout>
            <WorkflowDetails
              title={"Workflow Configuration Details"}
              workflowDetails={this.state.workflowDetails}
              getAddApproverDetails={this.getAddApproverDetails}
              getMoveStepDetails={this.getMoveStepDetails}
              getRemoveStepDetails={this.getRemoveStepDetails}
              getRemoveApproverDetails={this.getRemoveApproverDetails}
              getAddStepDetails={this.getAddStepDetails}
              canAddStep={CAN_ADD_WORKFLOW_CONFIGURATION_STEP}
              canRemoveStep={CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP}
              canMoveStep={CAN_MOVE_WORKFLOW_CONFIGURATION_STEP}
            />
            <PromptModal
              modalTitle={this.state.modalTitle}
              modalBody={this.state.modalBody}
              deleteModal={this.updateWorkflow}
              height={this.state.height}
              textAlign={this.state.textAlign}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`OK`}
              hideModalHeader={true}
              hideModalFooter={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
