import React from 'react';
import BaseScene from '../../../../../../../components/scenes/component.js';
import { AccessDenied } from '../../../../../../../components/access-denied.js';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal.js';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout.js';
import Notification from '../../../../../../../components/notification.js';
import { DocTypeExportSerializer } from '../../../../../../../serializers/index.js';
import { CAN_CREATE_AGENT_CLASS, CAN_VIEW_AGENT_CLASS, CAN_DEACTIVATE_AGENT_CLASS } from '../../../../../../../constants/permissions.js';
import ContentHead from '../../../../../../../components/content-head.js';
import { SUCCESS_STATUS } from '../../../../../../../constants/api.js';
import Table from '../../../../../../../components/table.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component.js';
import PropTypes from 'prop-types';
import { platformManagement } from '../../../../../../../mixins/api.js';


export default class DocumentTypeGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      getProps: () => ({
        style: {
          textAlign: 'center',
          whiteSpace: 'unset',
          padding: '8px'
        },
      }),
    },
    {
      Header: "Name",
      accessor: 'name',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Description",
      accessor: 'description',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Date Created",
      accessor: 'dateCreated',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Date Modified",
      accessor: 'dateLastModified',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Status",
      accessor: 'active',
      resizable: false,
      width: 80,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: props => (
        <span 
          style={{ color: '#ffffff' }} 
          className={`kt-badge kt-badge--inline ${props.value ? 'kt-badge--success' : 'kt-badge--danger'} kt-badge--pill`}
        >
          {props.value ? 'Active' : 'Inactive'}
        </span>
      ),
    },    
    {
      Header: "Action",
      accessor: 'active',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      minWidth: 100,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      getProps: () => ({
        style: {
          lineHeight: '20px',
          textAlign: 'center',
          whiteSpace: 'unset',
          padding: '8px'
        },
      }),
      Cell: ({ row }) => {
        return (
          <div className="dropdown">
            <a href="#" className="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
              <i className="la la-ellipsis-v"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[CAN_DEACTIVATE_AGENT_CLASS]}>
                <a className="dropdown-item" style={{ color: '#A7ABC3', cursor: 'pointer' }} onClick={() => this.onEditButtonClick(row.id, row.name, row._original.description, row)}>
                  <i className="la la-edit"></i> Edit
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[CAN_DEACTIVATE_AGENT_CLASS]}>
                {row._original.active ? (
                  <a
                    data-toggle="modal"
                    data-target="#kt_modal_1"
                    className="dropdown-item"
                    style={{ color: '#A7ABC3', cursor: 'pointer' }}
                    onClick={() => this.onActivateDeactivateButtonClick(row.id)}
                  >
                    <i className="la la-ban"></i> Deactivate
                  </a>
                ) : (
                  <a
                    data-toggle="modal"
                    data-target="#kt_modal_1"
                    className="dropdown-item"
                    style={{ color: '#A7ABC3', cursor: 'pointer' }}
                    onClick={() => this.onActivateDeactivateButtonClick(row.id)}
                  >
                    <i className="la la-toggle-on"></i> Activate
                  </a>
                )}
              </ProtectedComponent>
            </div>
          </div>
        );
      },
    }
    
    
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditMode: false,
      documents: [],
      getDocTypes: [],
    };

    this.getDocTypes = this.getDocTypes.bind(this);
  }

  componentDidMount() {
    this.getDocTypes()
  }

  async getDocTypes(pageNum, pageSize) {
    this.setState({ isLoading: true });
    try {
      const responseObj = await platformManagement.getDocTypes(pageNum, pageSize);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS && response) {
        const totalDocuments = response.count || 0;
        const sortedDocuments = response.sort((a, b) => {
          return new Date(b.dateCreated) - new Date(a.dateCreated);
        });

        this.setState({
          getDocTypes: sortedDocuments || [],
          documents: sortedDocuments || [],
          dataCount: totalDocuments,
          pages: Math.ceil(totalDocuments / pageSize),
          pageSize: pageSize,
          page: pageNum,
          startPage: null,
          isLoading: false,
          tableMessage: response.length == 0 ? "No agents found" : "",
        }, () => {
        });
      } else {
        this.setState({
          getDocTypes: [],
          documents: [],
          dataCount: 0,
          pages: 1,
          pageSize: pageSize,
          page: pageNum,
          isLoading: false,
          tableMessage: "No agents found",
        });
      }
    } catch (error) {
      console.error("Error fetching document types:", error);
      this.setState({
        getDocTypes: [],
        documents: [],
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  onSubmitButtonClick = (formData) => {
    this.activateDeactivateDocType(formData);
  };

  onEditButtonClick(docTypeId, docTypeName, docTypeDescription) {
    this.props.history.push({
      pathname: "/home/document-type/edit",
      state: {
        isEditMode: true,
        documentTypeFormData: { id: docTypeId, name: docTypeName, description: docTypeDescription },
      },
    });
  }

  async activateDeactivateDocType() {
    this.setState({ isLoading: true });

    const isActive = this.state.doctypeStatus === "Deactivate";
    try {
      const responseObj = await platformManagement.toggleDocType(this.state.docTypeId, isActive);
      const { status } = responseObj;

      if (status === SUCCESS_STATUS) {
        this.setState((prevState) => {
          const updatedDocTypes = prevState.getDocTypes.map((doc) => {
            if (doc.id === prevState.docTypeId) {
              return { ...doc, active: !isActive };
            }
            return doc;
          });
          return {
            getDocTypes: updatedDocTypes,
            isLoading: false,
            successMessage: `Document type ${isActive ? "deactivated" : "activated"} successfully!`,
          };
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "Failed to update document type status.",
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  onActivateDeactivateButtonClick = (docTypeId) => {
    const selectedDocType = this.state.getDocTypes.find((doc) => doc.id === docTypeId);
    this.setState({
      docTypeId,
      doctypeStatus: (selectedDocType && selectedDocType.active === true) ? "Deactivate" : "Activate",
    });
  };


  render() {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead
        title="Document Types"
        totalCount={56}
        filterPermissions={[CAN_VIEW_AGENT_CLASS]}
        actionButtonRequiredPermissions={[CAN_CREATE_AGENT_CLASS]}
        actionButtonText={"Create Document Type"}
        actionButtonUrl={this.state.isEditMode ? "/home/document-type/edit" : "/home/document-type/add"}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_CLASS]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <Table
            columns={this.columns}
            data={this.state.getDocTypes || []}
            loading={this.state.isLoading}
            noDataText={`No Document Type found`}
            dataCount={this.state.dataCount}
            exportData={DocTypeExportSerializer.reverseDocType(this.state.documents)}
            exportHeaders={DocTypeExportSerializer.headerExport()}
            exportFileName={'agent_docType'}
            pageSize={this.state.pageSize}
            page={this.state.page}
            pages={this.state.pages}
          />
          <PromptModal
            modalTitle={`${this.state.doctypeStatus} Document Type`}
            modalBody={`Do you want to ${this.state.doctypeStatus} this document type?`}
            deleteModal={() => this.activateDeactivateDocType()}
            modalSize="modal-md"
            height="230px"
            modalFooterButton1="Cancel"
            modalFooterButton2="Ok"
            hideModalHeader
            hideModalFooter
            actionButtonPermissionId={CAN_CREATE_AGENT_CLASS}
            exportSerializer={DocTypeExportSerializer}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}

DocumentTypeGridScene.propTypes = {
  original: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    dateCreated: PropTypes.string,
    dateLastModified: PropTypes.string,
    agentClassID: PropTypes.number,
    disabled: PropTypes.number,
  }),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), 
};

