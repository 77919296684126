import { formatDate } from '../../utils/formatter';

export default class CurrencyExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Symbol', key: 'symbol', title: 'Symbol', dataIndex: 'symbol'},
            {label: 'Country', key: 'country', title: 'Country', dataIndex: 'country'},
            {label: 'status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Date Created', key: 'dateCreated', title: 'Date Created', dataIndex: 'dateCreated'},
            {label: 'Date Modified', key: 'dateModified', title: 'Date Modified', dataIndex: 'dateModified'}

        ]
    }

    static reverseCurrencyDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, symbol, country, status, dateCreated, dateModified } = details
            let detailFormData = {
                name,
                symbol,
                country,
                status,
                dateCreated: dateCreated ? formatDate(dateCreated) : '',
                dateModified: dateModified ? formatDate(dateModified) : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}