import { clearLocalStorage } from './authentication'
let sessionTimeout;

export function appSession () {
    document.addEventListener("mousemove", resetSession, false);
    document.addEventListener("mousedown", resetSession, false);
    document.addEventListener("keypress", resetSession, false);
    document.addEventListener("DOMMouseScroll", resetSession, false);
    document.addEventListener("mousewheel", resetSession, false);
    document.addEventListener("touchmove", resetSession, false);
    document.addEventListener("MSPointerMove", resetSession, false);
    document.addEventListener('scroll', resetSession, false);

    startSession();

}

function startSession () {
    sessionTimeout = window.setTimeout(setSessionInactive, parseInt(process.env.REACT_APP_IDLE_TIMEOUT));
}

function resetSession (e) {
    window.clearTimeout(sessionTimeout);
    setSessionActive();
}

function setSessionInactive () {
    clearLocalStorage()
    window.location.href = '/admin/logout';
}

function setSessionActive () {
    startSession()
}
