import Requester from "../requester";
import { getDefaultDateFrom, getDefaultDateTo } from "../../../utils/formatter";

const API_BASE_URL = process.env.REACT_APP_RECONCILIATION_REPORT_API_BASE_URL;

export default class reconReport {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  generateDownloadableReport(
    transactionType,
    agentId,
    reconciliationType,
    transactionStatus,
    transRef,
    pageNum,
    pageSize,
    formattedStartDateFrom,
    formattedEndDateTo
  ) {
    const requestBody = {
      startDate: formattedStartDateFrom,
      endDate: formattedEndDateTo,
      filter: {
        transactionType: transactionType ? transactionType : "ALL",
        agentID: agentId ? agentId : "",
        reconciliationType: reconciliationType
          ? reconciliationType
          : "SETTLEMENT_WALLET",
        status: transactionStatus ? transactionStatus : "ALL",
        transactionRef: transRef ? transRef : "",
      },
      pager: {
        page: pageNum,
        pageSize: pageSize,
      },
    };
    return this.apiRequester.post({
      endpoint: `finch-reconciliation-service/v1/report/generateReport`,
      body: requestBody,
    });
  }

  downloadReportFile(fileName) {
    return this.apiRequester.get({
      endpoint: `finch-reconciliation-service/v1/report/download/report/file/${fileName}`,
    });
  }

  getReconBySearch(
    transactionType,
    agentId,
    reconciliationType,
    transactionStatus,
    transRef,
    pageNum,
    pageSize,
    formattedStartDateFrom,
    formattedEndDateTo
  ) {
    // const date = new Date();
    // const optionalDateFrom = `${date.getFullYear() - 1}-${(
    //   "0" +
    //   (date.getMonth() + 1)
    // ).slice(-2)}-${("0" + date.getDate()).slice(-2)}T${(
    //   "0" +
    //   date.getHours() * 0
    // ).slice(-2)}:${("0" + date.getMinutes() * 0).slice(-2)}:${(
    //   "0" +
    //   date.getSeconds() * 0
    // ).slice(-2)}`;
    // const optionalDateTo = `${date.getFullYear()}-${(
    //   "0" +
    //   (date.getMonth() + 1)
    // ).slice(-2)}-${("0" + date.getDate()).slice(-2)}T${(
    //   "0" +
    //   date.getHours() * 0
    // ).slice(-2)}:${("0" + date.getMinutes() * 0).slice(-2)}:${(
    //   "0" +
    //   date.getSeconds() * 0
    // ).slice(-2)}`;

    const requestBody = {
      startDate: formattedStartDateFrom
        ? formattedStartDateFrom
        : getDefaultDateFrom(),
      endDate: formattedEndDateTo ? formattedEndDateTo : getDefaultDateTo(),
      filter: {
        transactionType: transactionType ? transactionType : "ALL",
        agentID: agentId ? agentId : "",
        reconciliationType: reconciliationType
          ? reconciliationType
          : "SETTLEMENT_WALLET",
        status: transactionStatus ? transactionStatus : "ALL",
        transactionRef: transRef ? transRef : "",
      },
      pager: {
        page: pageNum ? pageNum : 0,
        pageSize: pageSize ? pageSize : 20,
      },
    };
    return this.apiRequester.post({
      endpoint: `finch-reconciliation-service/v1/report/queryReconReport`,
      // endpoint: `queryReconReport`,
      body: requestBody,
    });
  }
  getReconSchedulerBySearch(
    transactionType,
    agentId,
    reconciliationType,
    transactionStatus,
    transRef,
    pageNum,
    pageSize,
    formattedStartDateFrom,
    formattedEndDateTo
  ) {
    return this.apiRequester.get({
      endpoint: `finch-reconciliation-service/v1/report/getJobStatus`,
      // endpoint: `queryReconReport`,
      // body: requestBody,
    });
  }
  submitScheduler(jobName, jobEnable, lagHour, offset, formattedRunningDate) {
    const requestBody = {
      jobName: jobName,
      runningDate: formattedRunningDate || getDefaultDateTo(),
      offset: offset,
      lagHour: lagHour,
      jobEnabled: jobEnable,
    };
    console.log(requestBody, 'SCHEDULERRRRR');
    return this.apiRequester.post({
      endpoint: `finch-reconciliation-service/v1/report/updateJobTable`,
      // endpoint: `queryReconReport`,
      body: requestBody,
    });
  }

  requerySingleReport(uniqueReference) {
    const requestBody = {
      uniqueRefs: uniqueReference,
      // uniqueRefs: uniqueReference.length > 0 ? uniqueReference : [uniqueReference]
    };
    return this.apiRequester.post({
      endpoint: `finch-reconciliation-service/v1/report/resolveCommission`,
      body: requestBody,
    });
  }

  getDownloadableReportFiles() {
    return this.apiRequester.get({
      endpoint: `finch-reconciliation-service/v1/report/downloadable`,
      // endpoint: `generateReport`,
      // body: requestBody,
    });
  }
}
