import React from 'react'
import { AccessDenied } from '../../../../../../components/access-denied'
import ContentHead from '../../../../../../components/content-head'
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import BaseScene from '../../../../../../components/scenes/component'
import { SUCCESS_STATUS } from '../../../../../../constants/api'
import { CAN_MESSAGING_WRITE_TEMPLATE } from '../../../../../../constants/permissions'
import { messagingService } from '../../../../../../mixins/api'
import ProtectedComponent from '../../../../../../mixins/protected-component'
import { stripNumbersFromString } from '../../../../../../utils/helper'

export default class AddTemplateScene extends BaseScene {
  requiredPermissions = [
    CAN_MESSAGING_WRITE_TEMPLATE
  ]

  sceneName = 'add-template'

  canSubmit = false

  componentDidMount () {
    this.templateKey = this.props.match.params.id
    this.props.updateScene(this.sceneName)
    this.props.match.params.id && this.getTemplate()
  }

  constructor (props) {
    super(props)
    this.requiredTemplateFormFields = [
      "name", "subject", "key", "body"
      // , "file",
    ]

    this.state = {
      templateForm: {
        channels: []
      },
      canSubmit: false,
      fetchTemplate: false
    }

    this.submitTemplate = this.submitTemplate.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    // this.templateKey = this.templateKey.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const emptyRequiredTemplateFormFields = this.requiredTemplateFormFields.filter(value => {
      return Boolean(this.state.templateForm[value]) === false
    })

   


    const newState = {}

    if (emptyRequiredTemplateFormFields.length === 0 && prevState.templateForm.channels.length !== 0) {
      if(prevState.templateForm.channels.includes('EMAIL') && !prevState.templateForm.file) {
        return newState.canSubmit = false
      }
      newState.canSubmit = true
    }
    else if (emptyRequiredTemplateFormFields.length !==  0) {
      newState.canSubmit = false
    }

    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }

    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }

    this.canSubmit = newState.canSubmit
  }

  canUploadDocument () {
    this.setState({
      canUploadDocument: this.state.applicantDetailsFormData
    })
  }

  updateTemplateForm(type, value) {
    if (type === 'key') {
      this.setState({
        templateForm: {...this.state.templateForm, [type]: value.replace(/\s/g, '')}
      })
      return
    }
    this.setState({
      templateForm: {...this.state.templateForm, [type]: value}
    })
  }

  modifiedArrayUpdate = (value) => {
    const index = this.state.templateForm.channels.indexOf(value);
    if (index > -1) {
      return this.state.templateForm.channels.splice(index, 1)
    } else {
      return this.state.templateForm.channels.push(value)
    }
  }

  updateCheckboxField(value) {
    this.setState({
      ...this.state.templateForm,
      channels: this.modifiedArrayUpdate(value)
    })
  }

  async submitTemplate() {
    this.setState({
      isLoading: true
    })
    const { status, response } = await messagingService.createTemplates(this.state.templateForm)
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: `Template created successfully`
      })
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  async updateTemplate() {
    const { templateForm } = this.state;
    let payload;
    if (typeof (templateForm.file) !== 'string') {
      payload = templateForm
    } else {
      payload = {
        name: templateForm.name,
        subject: templateForm.subject,
        key: templateForm.key,
        body: templateForm.body,
        channels: templateForm.channels
      }
    }
    const { status, response } = await messagingService.updateTemplate(payload);
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: `Template created successfully`
      })
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  async getTemplate() {
    const { status, response } = await messagingService.getTemplate(this.templateKey);
    if(status === SUCCESS_STATUS) {
      const modifiedResponse = Object.assign(response, {file: response.fileName})
      this.setState({
        // templateForm: response
        fetchTemplate: true,
        templateForm: modifiedResponse
      })
    } else {
      this.setState({
        // templateForm: []
        templateForm: []
      })
    }
  
  }

  isFormValid = () => {
    const { name, subject, key, body, channels, file } = this.state.templateForm;
    if (channels.includes('EMAIL')) {
      return Boolean(name && subject && key && body && file)
    } else {
      return Boolean(name && subject && key && body && channels.length)
    }
  }


  render () {
    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_MESSAGING_WRITE_TEMPLATE]}><button disabled={!this.isFormValid()} class="btn" style={{backgroundColor: '#00425f', color: 'white'}} onClick={this.templateKey ? this.updateTemplate : this.submitTemplate}>{this.templateKey ? 'Save' : 'Submit'}</button></ProtectedComponent>
    </React.Fragment>
    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        ref={this.metadataSectionRef}
      />
      <ContentHead 
        title={this.templateKey ? "Edit Template" : "Create Template"} 
        withoutSearch subtitle={this.templateKey ? "Edit existing Template" : `Create a new notification template`}
        ctaWidgets={ctaWidgets}
      />
      <ProtectedComponent requiredPermissions={[CAN_MESSAGING_WRITE_TEMPLATE]} permissionDeniedContent={this.permissionDeniedContent}>
      <div class="kt-portlet">
                {!this.props.hideTitleHeader && <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target="#collapseOne1" aria-controls="collapseOne1">
                    <div class="kt-portlet__head-label">
                      
                    </div>
                </div>}
                <form id="collapseOne1" class="collapse show">
                    <div class="kt-portlet__body">
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Template name *:</label>
                                <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="name" class="form-control" defaultValue={this.state.templateForm ? this.state.templateForm.name : ''} placeholder="Template name" onChange={(event) => this.updateTemplateForm('name', stripNumbersFromString(event.target.value))} />
                            </div>
                            <div class="col-lg-4">
                                <label>Channel *: </label>
                                
                                <div class="col-9">
                                    <div class="kt-radio-inline">
                                        <label class="kt-checkbox" style={{paddingRight: '10px'}}>
                                            <input autoComplete="off" checked={this.state.templateForm && this.state.templateForm.channels.includes('SMS')} type="checkbox" name="channel" value="SMS" onChange={event => this.updateCheckboxField(event.target.value)}/> Sms
                                            <span></span>
                                        </label>
                                        <label class="kt-checkbox" style={{paddingRight: '10px'}}>
                                            <input autoComplete="off" checked={this.state.templateForm && this.state.templateForm.channels.includes('EMAIL')} type="checkbox" name="channel" value="EMAIL" onChange={event => this.updateCheckboxField(event.target.value)}/> Email
                                            <span></span>
                                        </label>
                                        <label class="kt-checkbox" style={{paddingRight: '10px'}}>
                                            <input autoComplete="off" checked={this.state.templateForm && this.state.templateForm.channels.includes('PUSH')} type="checkbox" name="channel" value="PUSH" onChange={event => this.updateCheckboxField(event.target.value)}/> Push Notification
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label>Subject *:</label>
                                <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="name" class="form-control" defaultValue={this.state.templateForm ? this.state.templateForm.subject : ''} placeholder="Subject" onChange={(event) => this.updateTemplateForm('subject', stripNumbersFromString(event.target.value))} />
                            </div>
                            
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Template Key *:</label>
                                <input autoComplete="off" type="name" disabled={this.state.fetchTemplate} class="form-control" defaultValue={this.state.templateForm ? this.state.templateForm.key : ''} placeholder="Key" onChange={(event) => this.updateTemplateForm('key', stripNumbersFromString(event.target.value))} />
                            </div>
                            {/* <div class="col-lg-4">
                                <label>File Name *:</label>
                                <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="name" class="form-control" defaultValue={this.state.templateForm ? this.state.templateForm.fileName : ''} placeholder="file Name" onChange={(event) => this.updateTemplateForm('fileName', event.target.value)} />
                            </div> */}
                            {(this.state.templateForm && this.state.templateForm.channels.includes('EMAIL')) && <div class="col-lg-8">
                                <label>Email Template *:</label>
                                <div class="col-lg-8">
                                  <div class="upload-btn-wrapper">
                                      <button class="upload-btn">Upload a file</button>
                                      <input autoComplete="off" disabled={this.props.canUploadDocument} type="file" name="file" onChange={event => this.updateTemplateForm('file', event.target.files[0])} />
                                      {(this.state.templateForm.file) && (
                                      <>
                                        {<span style={{ paddingLeft: '10px'}}>Filename: {this.state.templateForm.file.name ? this.state.templateForm.file.name : this.state.templateForm.file}</span>}
                                      </>
                                      )}
                                  </div>
                                </div>
                            </div>}
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label className='tool-tip'>Body *: 
                                  <span className='tool-tip-text'>Fields that can be parameterlized are businessName, agentMobileNo, businessPhoneNo, agentCode,
                                  businessTypeName, businessEmail, agentClass, agentType, status, walletStatus, contact.title, contact.firstname, contact.middlename,
                                  contact.lastname, contact.phoneNo, contact.gender, contact.placeOfBirth, contact.nationality, contact.motherMadienName, contact.educationLevelId, 
                                  contact.educationLevel, contact.contactType, walletRef, parentId,amount, companyRegNo, dateApplied, dateApplicationValidated, dateApplicationApproved,
                                  dateCreated, dateLastUpdated, applicationValidatedBy, applicationApprovedBy, createdBy, lastUpdatedBy</span>
                                </label>
                                <textarea autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} rows={5} style={{resize: 'none'}} defaultValue={this.state.templateForm ? this.state.templateForm.body : ''} class="form-control" placeholder="Body" onChange={(event) => this.updateTemplateForm('body', event.target.value)} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
 
}
