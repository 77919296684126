import React from 'react';
import BaseScene from '../../../../../components/scenes/component';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllocationAllGridScene from './allocate-all/index';
// import RemapPos from "./scenes/remap-pos/scenes/remap-pos-grid";
import AllocatePos from '../allocations-manage/allocate-pos/index';
export default class AllocationsManagementScene extends BaseScene {
  sceneName = 'allocations-manage';

  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };
  }

  componentDidMount() {
    // this.getAllRemapRequests();
    console.log('HERE I AM');
  }

  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };

  render() {
    return (
      <>
        <div className='col-md-12' style={{ marginTop: '-80px' }}>
          <section id='open-positions'>
            <div className='positions'>
              <div className='position-filters'>
                <label
                  className={
                    this.state.toggleState === 1
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All
                </label>

                <label
                  className={
                    this.state.toggleState === 2
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Allocate
                </label>
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <AllocationAllGridScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 2
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <AllocatePos />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
