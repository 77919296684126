import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LgaGridScene from './scenes/lga-grid'
import BaseScene from '../../../../../components/scenes/component';
import AddLgaScene from './scenes/add-lga';
import ViewLgaScene from './scenes/view-lga';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_LGA, CAN_CREATE_LGA } from '../../../../../constants/permissions.js'

export default class LgaScene extends BaseScene {
  sceneName = 'lga-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_LGA]} exact path='/home/system-configuration/lga-management' component={LgaGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_LGA]} exact path='/home/system-configuration/lga-management/add' component={AddLgaScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_LGA]} exact path='/home/system-configuration/lga-management/:id/view' component={ViewLgaScene} />
    </Router>
  }
}
