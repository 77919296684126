import React from "react";
import Thumbnail from "../../../../../components/thumbnail";
import { formatDate } from "../../../../../utils/formatter";
import { capitalizeFirstLetter } from "../../../../../utils/helper";

export default class ApplicationPreviewModal extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          class="modal"
          id="preview_application_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3
                  class="modal-title"
                  style={{ textAlign: "center !important" }}
                >
                  Application Details Preview
                </h3>
              </div>
              <div class="modal-body fluid" style={{ height: "440px" }}>
                <div
                  class="kt-portlet"
                  ref={this.props.applicantDetailsSectionRef}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    class="kt-portlet__head"
                    data-toggle="collapse"
                    data-target="#collapseModalOne1"
                    aria-controls="collapseModalOne1"
                  >
                    <div
                      class="kt-portlet__head-label"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapseModalOne1"
                      aria-expanded="true"
                      aria-controls="collapseModalOne1"
                    >
                      <h3 class="kt-portlet__head-title">Applicant Details</h3>
                    </div>
                  </div>
                  <form
                    class="kt-form kt-form--label-right"
                    id="collapseModalOne1"
                    class="collapse show"
                    aria-labelledby="headingOne1"
                  >
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails.firstName && (
                            <div class="col-lg-4">
                              <label>
                                <strong>First Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .firstName
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .firstName
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .middleName && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Middle Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .middleName
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .middleName
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails.surname && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Last Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails.surname
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .surname
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .mothersMaidenName && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Mother's maiden name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .mothersMaidenName
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .mothersMaidenName
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .identificationType && (
                            <div class="col-lg-4">
                              <label>
                                <strong>ID Type</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .identificationType
                                  ? this.props.getIdentificationTypeName(
                                      this.props.application.applicantDetails
                                        .identificationType
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .identificationNumber && (
                            <div class="col-lg-4">
                              <label>
                                <strong>ID Number</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.applicantDetails
                                    .identificationNumber
                                }
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .placeOfBirth && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Place of Birth</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .placeOfBirth
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .placeOfBirth
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails.dob && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Date of Birth</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails.dob
                                  ? formatDate(
                                      this.props.application.applicantDetails
                                        .dob
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails.gender && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Gender</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails.gender
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .gender
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .emailAddress && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Email</strong>
                              </label>
                              <br />
                              <span style={{ wordBreak: "break-all" }}>
                                {
                                  this.props.application.applicantDetails
                                    .emailAddress
                                }
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .phoneNumber && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Phone</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.applicantDetails
                                    .phoneNumber
                                }
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .nationality && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Country</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .nationality
                                  ? this.props.getCountryName(
                                      this.props.application.applicantDetails
                                        .nationality
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails.state && (
                            <div class="col-lg-4">
                              <label>
                                <strong>State</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails.state
                                  ? this.props.getStateName(
                                      this.props.application.applicantDetails
                                        .state
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .localGovernmentArea && (
                            <div class="col-lg-4">
                              <label>
                                <strong>LGA</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .localGovernmentArea
                                  ? this.props.getLgaName(
                                      this.props.application.applicantDetails
                                        .localGovernmentArea
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .closestLandMark && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Landmark</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .closestLandMark
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .closestLandMark
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails.address && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Address</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.applicantDetails
                                    .address
                                }
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails &&
                          this.props.application.applicantDetails
                            .howYouHeardAboutUs && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Channel</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.howYouHeardAboutUs
                                  ? capitalizeFirstLetter(
                                      this.props.application.howYouHeardAboutUs
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                  <div
                    style={{ cursor: "pointer" }}
                    class="kt-portlet__head"
                    data-toggle="collapse"
                    data-target="#collapseModalOne2"
                    aria-controls="collapseModalOne2"
                  >
                    <div
                      class="kt-portlet__head-label"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapseModalOne2"
                      aria-expanded="true"
                      aria-controls="collapseModalOne2"
                    >
                      <h3 class="kt-portlet__head-title">Business Details</h3>
                    </div>
                  </div>
                  <form
                    class="kt-form kt-form--label-right"
                    id="collapseModalOne2"
                    class="collapse"
                    aria-labelledby="headingOne1"
                  >
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails
                            .businessName && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Business Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.businessDetails
                                  .businessName
                                  ? capitalizeFirstLetter(
                                      this.props.application.businessDetails
                                        .businessName
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails
                            .businessType && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Business Type</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.businessDetails
                                    .businessType
                                }
                              </span>
                            </div>
                          )}
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails
                            .companyRegistrationNumber && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Company Registration Number</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.businessDetails
                                    .companyRegistrationNumber
                                }
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails
                            .phoneNumber && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Phone</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.businessDetails
                                    .phoneNumber
                                }
                              </span>
                            </div>
                          )}
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails.state && (
                            <div class="col-lg-4">
                              <label>
                                <strong>State</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.businessDetails.state
                                  ? this.props.getStateName(
                                      this.props.application.businessDetails
                                        .state
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails
                            .localGovernmentArea && (
                            <div class="col-lg-4">
                              <label>
                                <strong>LGA</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.businessDetails
                                  .localGovernmentArea
                                  ? this.props.getLgaName(
                                      this.props.application.businessDetails
                                        .localGovernmentArea
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails.address && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Address</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.businessDetails.address}
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails.bankName && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Bank Name</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.businessDetails
                                    .bankName
                                }
                              </span>
                            </div>
                          )}
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails
                            .accountNumber && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Account Number</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.businessDetails
                                    .accountNumber
                                }
                              </span>
                            </div>
                          )}
                        {this.props.application.businessDetails &&
                          this.props.application.businessDetails.bvn && (
                            <div class="col-lg-4">
                              <label>
                                <strong>BVN</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails.bvn}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                  <div
                    style={{ cursor: "pointer" }}
                    class="kt-portlet__head"
                    data-toggle="collapse"
                    data-target="#collapseModalOne3"
                    aria-controls="collapseModalOne3"
                  >
                    <div
                      class="kt-portlet__head-label"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapseModalOne3"
                      aria-expanded="true"
                      aria-controls="collapseModalOne3"
                    >
                      <h3 class="kt-portlet__head-title">
                        Next of Kin Details
                      </h3>
                    </div>
                  </div>
                  <form
                    class="kt-form kt-form--label-right"
                    id="collapseModalOne3"
                    class="collapse"
                    aria-labelledby="headingOne1"
                  >
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        {this.props.application.applicantDetails.nextOfKin &&
                          this.props.application.applicantDetails.nextOfKin
                            .firstName && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Full Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .nextOfKin.firstName
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .nextOfKin.firstName
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails.nextOfKin &&
                          this.props.application.applicantDetails.nextOfKin
                            .surname && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Last Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .nextOfKin.surname
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .nextOfKin.surname
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails.nextOfKin &&
                          this.props.application.applicantDetails.nextOfKin
                            .phoneNumber && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Phone</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.applicantDetails
                                    .nextOfKin.phoneNumber
                                }
                              </span>
                            </div>
                          )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails.nextOfKin &&
                          this.props.application.applicantDetails.nextOfKin
                            .relationship && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Relationship</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.applicantDetails
                                    .nextOfKin.relationship
                                }
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails.nextOfKin &&
                          this.props.application.applicantDetails.nextOfKin
                            .gender && (
                            <div class="col-lg-4">
                              <label>
                                <strong>Gender</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.application.applicantDetails
                                  .nextOfKin.gender
                                  ? capitalizeFirstLetter(
                                      this.props.application.applicantDetails
                                        .nextOfKin.gender
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                        {this.props.application.applicantDetails.nextOfKin
                          .emailAddress && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Email Address</strong>
                            </label>
                            <br />
                            <span>
                              {
                                this.props.application.applicantDetails
                                  .nextOfKin.emailAddress
                              }
                            </span>
                          </div>
                        )}
                      </div>
                      <div class="form-group row">
                        {this.props.application.applicantDetails.nextOfKin &&
                          this.props.application.applicantDetails.nextOfKin
                            .address && (
                            <div class="col-lg-12">
                              <label>
                                <strong>Address</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.application.applicantDetails
                                    .nextOfKin.address
                                }
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                  <div
                    style={{ cursor: "pointer" }}
                    class="kt-portlet__head"
                    data-toggle="collapse"
                    data-target="#collapseModalOne4"
                    aria-controls="collapseModalOne4"
                  >
                    <div
                      class="kt-portlet__head-label"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapseModalOne4"
                      aria-expanded="true"
                      aria-controls="collapseModalOne4"
                    >
                      <h3 class="kt-portlet__head-title">KYC Documents</h3>
                    </div>
                  </div>
                  <form
                    class="kt-form kt-form--label-right"
                    id="collapseModalOne4"
                    class="collapse"
                    aria-labelledby="headingOne1"
                  >
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        {this.props.uploadedDocuments.map((details) => {
                          return (
                            <div class="col-lg-4">
                              <label>{details.documentType}</label>
                              <div class="col-lg-4">
                                <Thumbnail
                                  showPreview={false}
                                  filename={details.documentName}
                                  style={{ width: 200, height: 200 }}
                                  src={`${
                                    process.env.REACT_APP_FILE_URL +
                                    details.documentName
                                  }`}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </form>
                  <div
                    style={{ cursor: "pointer" }}
                    class="kt-portlet__head"
                    data-toggle="collapse"
                    data-target="#collapseModalOne5"
                    aria-controls="collapseModalOne5"
                  >
                    <div
                      class="kt-portlet__head-label"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapseModalOne5"
                      aria-expanded="true"
                      aria-controls="collapseModalOne5"
                    >
                      <h3 class="kt-portlet__head-title">
                        Agent Class Assignment
                      </h3>
                    </div>
                  </div>
                  <form
                    class="kt-form kt-form--label-right"
                    id="collapseModalOne5"
                    class="collapse"
                    aria-labelledby="headingOne1"
                  >
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Agent Class</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.metdataFormData.agentClass ||
                            this.props.application.agentClassId
                              ? this.props.getAgentClassName(
                                  this.props.metdataFormData.agentClass
                                    ? this.props.metdataFormData.agentClass
                                    : this.props.application.agentClassId
                                )
                              : ""}
                          </span>
                        </div>
                        <div
                          class="col-lg-8"
                          hidden={
                            this.props.application.agentTypeId == 4
                              ? ""
                              : "hidden"
                          }
                        >
                          <label>
                            <strong>Aggregator</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.metdataFormData.superAgent ||
                            this.props.application.referralCode
                              ? this.props.getAggregatorName(
                                  this.props.metdataFormData.superAgent
                                    ? this.props.metdataFormData.superAgent
                                    : this.props.application.referralCode
                                )
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#F1F1F2 ",
                    backgroundColor: "#F1F1F2 ",
                    color: "#000000",
                    margin: "auto",
                    display: "block",
                    fontSize: "14px",
                  }}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                  onClick={
                    this.props.toShowValidationButton
                      ? this.props.onValidateButtonClick
                      : this.props.toShowApprovalButton
                      ? this.props.onApproveButtonClick
                      : ""
                  }
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
