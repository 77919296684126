import React from 'react'
import PhoneNumberComponent from '../../../../../../components/phone-number-component'
import Select from '../../../../../../components/select'
import { trimPhoneNumber } from '../../../../../../utils/helper'

export default class FipForm extends React.Component {
    splitString (string) {
        return string.split("-");
    }
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group row">
                            {this.props.showPhoneNumber && <div class="col-md-4">
                                <label>Phone Number *:</label>
                                <PhoneNumberComponent 
                                    phoneIsValid={this.props.phoneIsValid ? this.props.phoneIsValid : true}
                                    onChange={(status, phoneNumber) => {
                                        this.props.onChange('mobileNo', trimPhoneNumber(phoneNumber))
                                        this.props.updateSetState('phoneIsValid', status)
                                        this.props.validatePhoneNumber(trimPhoneNumber(phoneNumber)) && (status && this.props.validateUser(trimPhoneNumber(phoneNumber)))
                                    }}
                                    defaultValue={(this.props.fipFormData && this.props.fipFormData.mobileNo) ? this.props.fipFormData.mobileNo : ''}
                                    disabled={this.props.disabled2 ? this.props.disabled2 : ''}
                                />
                            </div>}
                            <div class="col-md-4">
                                <label>Email *:</label>
                                <input autoComplete="off" type="email" class="form-control" placeholder="Email" disabled={this.props.disabled2 ? this.props.disabled2 : this.props.emailFoundDisabled ? this.props.emailFoundDisabled : ''} defaultValue={(this.props.fipFormData && this.props.fipFormData.email) ? this.props.fipFormData.email : ''} onChange={(event) => this.props.onChange('email', event.target.value)} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label>First Name *:</label>
                                <input autoComplete="off" type="text" class="form-control" placeholder="First name" disabled={this.props.disabled ? this.props.disabled : this.props.userFoundDisabled ? this.props.userFoundDisabled : ''} defaultValue={(this.props.fipFormData && this.props.fipFormData.firstName) ? this.props.fipFormData.firstName : ''} onChange={(event) => this.props.onChange('firstName', event.target.value)} />
                            </div>
                            <div class="col-md-4">
                                <label>Last Name *:</label>
                                <input autoComplete="off" type="text" class="form-control" placeholder="Last name" disabled={this.props.disabled ? this.props.disabled : this.props.userFoundDisabled ? this.props.userFoundDisabled : ''} defaultValue={(this.props.fipFormData && this.props.fipFormData.lastName) ? this.props.fipFormData.lastName : ''} onChange={(event) => this.props.onChange('lastName', event.target.value)} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label>ID Type *:</label>
                                <Select 
                                    options={this.props.idTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => this.props.onChange('idType', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.fipFormData.idType}
                                />
                                {/* <select class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('idType', event.target.value)}>
                                    <option disabled selected>Select</option>
                                    {this.props.idTypes.map(value => <option value={value.id} selected={this.props.fipFormData.idType == value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                            </div>
                            <div class="col-md-4">
                                <label>ID Number *:</label>
                                <input autoComplete="off" type="text" class="form-control" placeholder="ID Number" disabled={this.props.disabled ? this.props.disabled : ''} defaultValue={(this.props.fipFormData && this.props.fipFormData.idNumber) ? this.props.fipFormData.idNumber : ''} onChange={(event) => this.props.onChange('idNumber', event.target.value)} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label>Country *:</label>
                                <Select 
                                    options={this.props.countries.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={(event) => {
                                        this.props.onChange('countryCode', event.value)
                                        this.props.getStates(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.fipFormData.countryCode}
                                />
                                {/* <select class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => {
                                    this.props.onChange('countryCode', event.target.value)
                                    this.props.getStates(event.target.value)
                                }}>
                                    <option disabled selected>Select</option>
                                    {this.props.countries.map(value => <option value={value.id} selected={this.props.fipFormData.countryCode == value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                            </div>
                            <div class="col-md-4">
                                <label>State *:</label>
                                <Select 
                                    options={this.props.states.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={(event) => { 
                                        this.props.onChange('stateCode', event.value)
                                        this.props.getLgas(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.fipFormData.stateCode}
                                    isLoading={this.props.isStateLoading}
                                />
                                {/* <div class="input-group">
                                    <select class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => { 
                                        this.props.onChange('stateCode', event.target.value)
                                        this.props.getLgas(event.target.value)
                                    }}>
                                        <option disabled selected>Select</option>
                                        {this.props.states.map(value => <option value={value.id} selected={this.props.fipFormData.stateCode == value.id ? 'selected' : ''}>{value.name}</option>)}
                                    </select>
                                    {this.props.isStateLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                        <span class="la la-spin la-refresh"></span>
                                    </div>}
                                </div> */}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label>LGA *:</label>
                                <Select 
                                    options={this.props.lgas.map(detail => { return ( {label: detail.name, value: detail.code} ) })}
                                    onChange={(event) => {
                                        this.props.onChange('lgaCode', event.value)
                                        this.props.getZones(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.fipFormData.lgaCode}
                                    isLoading={this.props.isLgaLoading}
                                />
                                {/* <div class="input-group">
                                    <select class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => {
                                        this.props.onChange('lgaCode', event.target.value)
                                        this.props.getZones(event.target.value)
                                    }}>
                                        <option disabled selected>Select</option>
                                        {this.props.lgas.map(value => <option value={value.code} selected={this.props.fipFormData.lgaCode === value.code ? 'selected' : ''}>{value.name}</option>)}
                                    </select>
                                    {this.props.isLgaLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                        <span class="la la-spin la-refresh"></span>
                                    </div>}
                                </div> */}
                            </div>
                            <div class="col-md-4">
                                <label>Region *:</label>
                                <Select 
                                    options={this.props.regions.map(detail => { return ( {label: detail.regionName, value: detail.regionCode} ) })}
                                    onChange={(event) => this.props.onChange('regionCode', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.fipFormData.regionCode}
                                />
                                {/* <select class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('regionCode', event.target.value)}>
                                    <option disabled selected>Select</option>
                                    {this.props.regions.map(value => <option value={value.regionCode} selected={this.props.fipFormData.regionCode === value.regionCode ? 'selected' : ''}>{value.regionName}</option>)}
                                </select> */}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label>Zone *:</label>
                                <Select 
                                    options={this.props.zones.map(detail => { return ( {label: detail.zoneName, value: detail.zoneCode} ) })}
                                    onChange={(event) => this.props.onChange('zoneCode', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.fipFormData.zoneCode}
                                    isLoading={this.props.isZoneLoading}
                                />
                                {/* <div class="input-group">
                                    <select class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('zoneCode', event.target.value)}>
                                        <option disabled selected>Select</option>
                                        {this.props.zones.map(value => <option value={value.zoneCode} selected={this.props.fipFormData.zoneCode == value.zoneCode ? 'selected' : ''}>{value.zoneName}</option>)}
                                    </select>
                                    {this.props.isZoneLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                        <span class="la la-spin la-refresh"></span>
                                    </div>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}