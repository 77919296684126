import React from 'react'
import { convertToMajor } from '../../../../../../utils/converter'
import { capitalizeFirstLetter } from '../../../../../../utils/helper'

export default class BatchCreditDebitSingle extends React.Component {
    render() {
        return <React.Fragment>
            {!this.props.showListOnly && <div class="form-group row">
                <div class="col-lg-6">
                    <input type="search" class="form-control" autoComplete="off" placeholder="Enter agent phone number" onChange={event => this.props.updateAgentSearchParameters("agentMobileNo", event.target.value)} />
                </div>
                <div class="col-lg-2">
                    <button disabled={!this.props.agentSearchParameters.agentMobileNo || this.props.isAgentSearchLoading} type="button" class="btn isw-button" onClick={this.props.getAgents}>Search</button>
                </div>
            </div>}

            {this.props.isAgentSearchLoading && <div class="form-group col-lg-6">
                <span class="loading">Loading</span>
            </div>}

            {(!this.props.isAgentSearchLoading && !this.props.showListOnly) && <div>
                {this.props.agents.map(value => <div class="form-group col-lg-6">
                    {this.props.showSearchInfo && <p>{this.props.getResultDisplayMessage(this.props.agentCount)}</p>}
                    <span><strong>{value.businessName}</strong></span><br/>
                    <span>{value.agentMobileNo}</span><span class="pull-right" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateState("selectedAgent", value)} style={{cursor: 'pointer'}}>Add</span><br/>
                    <span>{`${value.agentClass} Agent Class`}</span>
                </div>)}
            </div>}
            
            {this.props.bulkProcessingFormData.agents.length ? <div class="form-group col-lg-10">
                <span>{this.props.bulkProcessingFormData.bulkTransactionType && `${capitalizeFirstLetter(this.props.bulkProcessingFormData.bulkTransactionType)} List`}</span>                  
                <table class="table table-hover">
                    <thead class="thead-light">
                        <th>S/N</th>
                        <th>Agent Phone Number</th>
                        <th>Wallet Type</th>
                        <th>Amount (&#x20A6;)</th>
                        <th>Narration</th>
                        <th>Service Provider Reference</th>
                        {!this.props.showListOnly && <th></th>}
                    </thead>
                    <tbody>
                        {this.props.bulkProcessingFormData.agents.map((value, index) => <tr>
                            <td>{index + 1}</td>
                            <td>{value.agentPhoneNumber}</td>
                            <td>{this.props.getWalletTypeName(value.walletType)}</td>
                            <td>{convertToMajor(value.amount)}</td>
                            <td>{value.narration}</td>
                            <td>{value.serviceProviderReference}</td>
                            {!this.props.showListOnly && <td>
                                <span class="fa fa-trash" onClick={() => this.props.removeAgentDetails(value.id)}></span>
                            </td>}
                        </tr>)}
                    </tbody>
                </table>
                {!this.props.showListOnly && <button disabled={this.props.isFormValid()} type="button" class="btn isw-button pull-right" onClick={this.props.onSubmitButtonClick}>Submit</button>}
            </div> : '' }
        </React.Fragment>
    }
}