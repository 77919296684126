import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { 
  formatDateTime, formatBulkProcessingType 
} from '../../../../../../utils/formatter';
import { explodeDate, getPaginationRows } from '../../../../../../utils/helper';
import SpinnerComponent from '../../../../../../components/spinner'
import Pagination from '../../../../../../components/pagination'
import TableExtra from '../../../../../../components/table-extra'
import { Link } from 'react-router-dom'

export default class BatchCreditDebitTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '970px',
    zoom: 'plus'
  }

zoomTableHeight = this.zoomTableHeight.bind(this)

zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
}
  render() {
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
      {
        Header: "Type",
        accessor: 'bulkTransactionType',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 80,
        Cell: props => props.value ? formatBulkProcessingType(props.value) : ''
      },{ 
        Header: "Transaction Reference",
        accessor: 'transactionRef',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 220,
        Cell: props => <Link to="transaction-history" onClick={() => this.props.redirectToTransactionHistory(props.value)}>{props.value}</Link>
      },{
        Header: "Initiator",
        accessor: 'username',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 180,
        Cell: ({rows, original}) => original.user ? <span>{`${original.user.firstName} ${original.user.lastName}`}</span> : ''
      },{
        Header: "File",
        accessor: 'file',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 190,
        Cell: props => props.value ? <a style={{cursor: 'pointer'}} onClick={() => this.props.downloadFile(props.value.name)} download>{props.value.name}</a> : ''
      },{
        Header: "Total Trans",
        accessor: 'total',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 64,
      },{
        Header: "Successful Trans",
        accessor: 'success',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 64,
      },{
        Header: "Failed Trans",
        accessor: 'failed',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 63,
      },{
        Header: "Date Created",
        accessor: 'created',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 160,
        Cell: props => props.value ? formatDateTime(explodeDate(props.value, '.')) : ''
      },{
        Header: "Date Updated",
        accessor: 'updated',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 180,
        Cell: props => props.value ? formatDateTime(explodeDate(props.value, '.')) : ''
      }
      ,{
        Header: "Action",
        accessor: 'transactionRef',
        overflow: 'visible',
        resizable: false,
        sortable: false,
        headerStyle: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: '20px',
              textAlign: 'left',
              whiteSpace: 'unset',
            },
          };
        },
        width: 60,
        Cell: ({row, original}) => <a title="export" style={{cursor: 'pointer'}} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateExportParameter('parentReference', original.transactionRef)} class="dropdown-item">
          <i class="la la-download la-lg"></i>
        </a>
      }
    ]
    return <React.Fragment> 
      <TableExtra 
        data={this.props.data}
        zoomTableHeight={this.zoomTableHeight}
        getData={this.props.getBulkProcessingTransactions}
        zoom={this.state.zoom}
        page={this.props.page}
        pageSize={this.props.pageSize}
        disabled={this.props.loading}
      />
      <ReactTable 
        PaginationComponent={Pagination} 
        loading={this.props.loading}
        LoadingComponent={MyLoader}
        noDataText={!this.props.loading ? this.props.noDataText : ''}
        style={{
          border: "none",
          boxShadow: "none",
          whiteSpace: 'unset',
          height: this.state.tableHeight,
        }}
        data={this.props.data}
        dataCount={this.props.dataCount}
        pageSizes={getPaginationRows(this.props.dataCount)}
        columns={columns}
        manual
        pages={this.props.pages}
        page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
        onFetchData={(state, instance) => {
          this.setState({page: state.page})
          this.props.getBulkProcessingTransactions(state.page + 1,state.pageSize) 
        }}
        headerNodeHeight={`123`}
        minRows={(this.props.data).length > 0 ? 10 : 10}
        getTableProps={(state, rowInfo, column, instance) => ({
          style: {
            borderColor: '#00425f',
            boxSizing: 'none',
            border: 'none',
          },
        })}
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            display:"flex",
            overflow: "visible",
            color: "black",
            fontSize: "11.5px",
            fontWeight: "50",
            paddingLeft:"3px",
            paddingRight:"3px",
            alignItems: "center",
            
          },
        })}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            backgroundColor: "#00425f",
            borderBottom: "0.1px solid #DBDEDE",
            borderWidth: "0.1px",
            padding:"7px 0",
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: "100",
            textAlign: "left",
            paddingLeft:"3px",
            paddingRight:"3px",
          },
        })}
        className="-striped -highlight" 
      />
    </React.Fragment>
  }
}