import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LanguageGridScene from './scenes/language-grid'
import BaseScene from '../../../../../components/scenes/component';
import AddLanguageScene from './scenes/add-language';
import ViewLanguageScene from './scenes/view-language';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_LANGUAGE } from '../../../../../constants/permissions.js'

export default class LanguageScene extends BaseScene {
  sceneName = 'language-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_LANGUAGE]} exact path='/home/system-configuration/language-management' component={LanguageGridScene} />
      {/* <ProtectedRoute exact path='/home/system-configuration/language-management/add' component={AddLanguageScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_VIEW_LANGUAGE]} exact path='/home/system-configuration/language-management/:id/view' component={ViewLanguageScene} />
    </Router>
  }
}
