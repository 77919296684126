import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ManageAggregatorGridScene from './scenes/manage-aggregator-grid/index.js';
import { isAuthorized } from '../../../../../mixins/authentication.js'
import { CAN_VIEW_MANAGE_AGGREGATOR } from '../../../../../constants/permissions.js'

export default class ManageAggregatorScene extends Component {
  sceneName = 'manage-aggregator-change'

  componentDidMount() {
    this.props.updateScene(this.sceneName)
  }

  render() {
    const ProtectedRoute = ({ component: Component, requiredPermissions, ...rest }) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_MANAGE_AGGREGATOR]} exact path='/home/manage-aggregator-change' component={ManageAggregatorGridScene} />
    </Router>
  }
}