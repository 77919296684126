import React from "react";
import { DatePickerComponent } from "../../../../../../../../components/date-picker";

export default class PosRequestsAwaitingStockUploadSearch extends React.Component {
  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest();
  }


  render() {
    return (
      <React.Fragment>
        <div class="search-params">
          <form onSubmit={this.handleSearchRequest}>
          <div class="search-components">
              <div class="search-component">
                <label class="search-label">Date From</label>
                <DatePickerComponent
                  onChange={(event) =>
                    this.props.updateSearchParameters("fromDate", event)
                  }
                  size={`240px`}
                  format={`dd/MM/yyyy h:mm aa`}
                  isClearable={true}
                  showTimeInput={true}
                  textStyle={"1.0rem"}
                  iconStyle={"0.2rem"}
                  dateType={"startDate"}
                  preloadDate={this.props.preloadDate}
                />
              </div>
              <div class="search-component">
                <label class="search-label">Date To</label>
                <DatePickerComponent
                  onChange={(event) =>
                    this.props.updateSearchParameters("toDate", event)
                  }
                  size={`240px`}
                  format={`dd/MM/yyyy h:mm aa`}
                  isClearable={true}
                  showTimeInput={true}
                  disabled={
                    this.props.searchParameters.fromDate ? false : true
                  }
                  minDate={this.props.searchParameters.fromDate}
                  //   style={{marginRight:"30px"}}
                  textStyle={"1.0rem"}
                  iconStyle={"0.2rem"}
                  dateType={"endDate"}
                  preloadDate={this.props.preloadDate}
                />
              </div>
              <div class="search-component">
                <label class="search-label">Agent Mobile</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "mobilePhone",
                      event.target.value
                    )
                  }
                  // style={{ width: "240px" }}
                />
              </div>
              {/* </div> */}

              {/* <div class="form-group row"> */}
              
              <div class="col-md-2 mb-3">
                <label class="search-label">&nbsp;&nbsp;</label>
                <button type="submit" class="search-button">
                  Search
                </button>
              </div>
            </div>

            <div class="form-group row">
              
            </div>
          </form>
         
        </div>
      </React.Fragment>
    );
  }
}
