import React from 'react'
import Select from '../../../../../../components/select'
import { toDecimal } from '../../../../../../utils/helper'

export default class CommissionSplitRuleForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <p>When</p>
                        <div class="form-group row">
                            <div class="col-lg-2">
                                <label>Service Type is</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.serviceTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.updateCommissionSplitRuleFormData('serviceType', event.value)
                                        this.props.getServiceTypeById(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.commissionSplitRuleFormData.serviceType}
                                    isLoading={this.props.isGetServiceTypeLoading}
                                    minHeight={40}
                                />
                                {/* <select name="serviceType" disabled={this.props.disabled} class="form-control" onChange={event => {
                                    this.props.updateCommissionSplitRuleFormData('serviceType', event.target.value)
                                    this.props.getServiceTypeById(event.target.value)
                                }} data-live-search="true">
                                    <input type="text" />
                                    <option value={0}>Any</option>
                                    {this.props.serviceTypes.map(value => <option value={value.id} selected={this.props.commissionSplitRuleFormData.serviceType === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                            </div>
                            <div class="col-lg-2">
                                <label>AND Service is</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.services.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.updateCommissionSplitRuleFormData('service', event.value)
                                        this.props.getServiceById(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.commissionSplitRuleFormData.service}
                                    isLoading={this.props.isServiceLoading}
                                    minHeight={40}
                                />
                                {/* <select name="service" disabled={this.props.disabled} class="form-control" onChange={event => {
                                    this.props.updateCommissionSplitRuleFormData('service', event.target.value)
                                    this.props.getServiceById(event.target.value)
                                }} >
                                    <option value={0}>Any</option>
                                    {this.props.services.map(value=><option value={value.id} selected={this.props.commissionSplitRuleFormData.service === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                {this.props.isServiceLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                    <span class="la la-spin la-refresh"></span>
                                </div>} */}
                            </div>
                        </div>
                        <p>AND</p>
                        <div class="form-group row">
                            <div class="col-lg-2">
                                <label>Service Option is</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.serviceOptions.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => this.props.updateCommissionSplitRuleFormData('serviceOption', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.commissionSplitRuleFormData.serviceOption}
                                    isLoading={this.props.isServiceOptionLoading}
                                    minHeight={40}
                                />
                                {/* <select name="serviceOption" class="form-control" disabled={this.props.disabled} onChange={event => this.props.updateCommissionSplitRuleFormData('serviceOption', event.target.value)} >
                                    <option value={0}>Any</option>
                                    {this.props.serviceOptions.map(value=><option value={value.id} selected={this.props.commissionSplitRuleFormData.serviceOption === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                {this.props.isServiceOptionLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                    <span class="la la-spin la-refresh"></span>
                                </div>} */}
                            </div>
                        </div>
                        <p>Share As</p>
                        <div class="form-group col-lg-12 row">
                            <div class="col-lg-3">
                                <label>Revenue Sharing Party</label>
                            </div>
                            <div class="col-lg-2">
                                <label>Quota (100%)</label>
                            </div>
                        </div>
                        {this.props.commissionSplitRuleFormData.revenueSplits && this.props.commissionSplitRuleFormData.revenueSplits.map(value =>
                            <div>
                                <div class="form-group col-lg-12 row" style={{marginBottom: '0px'}}>
                                    <div class="col-lg-3">
                                        {this.props.getSharingPartyName(value.sharingPartyId)}
                                    </div>
                                    <div class="col-lg-2">
                                        {toDecimal(value.quota, 4)}%
                                    </div>
                                    <div class="col-lg-3">
                                        <a hidden={this.props.disabled} style={{cursor: 'pointer'}} onClick={() => this.props.removeRevenueSplit(value.id)}>Delete</a>
                                    </div>
                                </div>
                                <hr style={{width: this.props.disabled ? '270px' : '400px', float: 'left'}}/>
                            </div>
                        )}
                        {!this.props.disabled && <div class="form-group col-lg-12 row">
                            <div class="col-lg-3">
                                <Select 
                                    options={this.props.unSelectedSharingParties.map((detail, index) => {
                                        //if(detail.selected != 1) {
                                            return (
                                                {label: detail.name, value: detail.id}
                                            )
                                        //}
                                        })}
                                    //options={this.props.serviceOptions.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={(event) => this.props.updateRevenueSplitFormData('sharingPartyId', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.sharingPartyId}
                                    isLoading={this.props.isPartiesLoading}
                                    minHeight={40}
                                />
                                {/* <select autoComplete="off" class="form-control input-large" defaultValue={this.props.sharingPartyId} data-live-search="true" onChange={(event) => this.props.updateRevenueSplitFormData('sharingPartyId', event.target.value)}>
                                    <option selected>Select</option>
                                    {this.props.sharingParties.map((value, index) => {
                                    if(value.selected != 0) {
                                        return (
                                        <option value={value.id} >{value.name}</option>
                                        )
                                    }
                                    })}
                                </select> */}
                            </div>
                            <div class="col-lg-2">
                                <div class="kt-input-icon">
                                    <input type="number" defaultValue={this.props.quota} class="form-control" placeholder={this.props.totalQuota} 
                                    onChange={(event) => {
                                        this.props.updateRevenueSplitFormData('quota', toDecimal(event.target.value, 4))
                                        this.props.updateRevenueSplitFormData('isQuotaValid', this.props.validateSharingQuota(toDecimal(event.target.value, 4)))
                                    }} />
                                    <span class="kt-input-icon__icon kt-input-icon__icon--right"><span>
                                            %
                                        </span>  
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-1">
                                <button class="btn form-control" disabled={!this.props.isQuotaValid || this.props.validateSharingQuotas() || !this.props.sharingPartyId || !this.props.quota} style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.props.addRevenueSplit}>Add</button>
                            </div>
                        </div>}
                    </div>
                </form>
            </div>

        </React.Fragment>
    }
}