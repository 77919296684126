import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CAN_CREATE_FEE, CAN_VIEW_FEE } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import AddFeeConfigurationScene from './scenes/add-fee-configuration';
import FeeConfigurationGridScene from './scenes/fee-configuration-grid';
import ViewFeeConfigurationScene from './scenes/view-fee-configuration';

export default class FeeConfigurationScene extends Component {
  sceneName = 'fee-configuration'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
  
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FEE]} exact path='/home/fee-configuration' component={FeeConfigurationGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_FEE]} exact path='/home/fee-configuration/add' component={AddFeeConfigurationScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FEE]} exact path='/home/fee-configuration/:id/view' component={ViewFeeConfigurationScene} />
    </Router>
  }
}
