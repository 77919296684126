import {
  formatDateTime,
  formatCurrency,
  formatTransactionStatus,
  formatLongNumber,
} from "../../utils/formatter";
import { convertToMajor } from "../../utils/converter";
import { getTransactionTypeName } from "../../utils/helper";

export default class TransactionHistoryExportSerializer {
  static headerExport() {
    return [
      {
        label: "Agent",
        key: "domainCode",
        title: "Agent",
        dataIndex: "domainCode",
      },
      {
        label: "Agent Name",
        key: "agentName",
        title: "Agent Name",
        dataIndex: "agentName",
      },
      {
        label: "Business Name",
        key: "businessName",
        title: "Business Name",
        dataIndex: "businessName",
      },
      { label: "Email", key: "email", title: "Email", dataIndex: "email" },
      {
        label: "Business Phone Number",
        key: "businessMobileNo",
        title: "Business Phone Number",
        dataIndex: "businessMobileNo",
      },
      {
        label: "Transaction Type",
        key: "transactionType",
        title: "Transaction Type",
        dataIndex: "transactionType",
      },
      {
        label: "Ref No",
        key: "transactionRef",
        title: "Ref No",
        dataIndex: "transactionRef",
      },
      {
        label: "Amount",
        key: "transactionAmount",
        title: "Amount",
        dataIndex: "transactionAmount",
      },
      { label: "Fee", key: "fee", title: "Fee", dataIndex: "fee" },
      {
        label: "Available Balance",
        key: "availableBalance",
        title: "Available Balance",
        dataIndex: "availableBalance",
      },
      {
        label: "Ledger Balance",
        key: "ledgerBalance",
        title: "Ledger Balance",
        dataIndex: "ledgerBalance",
      },
      { label: "Pin", key: "pin", title: "Pin", dataIndex: "pin" },
      {
        label: "Customer Name",
        key: "customerName",
        title: "Customer Name",
        dataIndex: "customerName",
      },
      {
        label: "Customer Phone Number",
        key: "customerPhoneNumber",
        title: "Customer Phone Number",
        dataIndex: "customerPhoneNumber",
      },
      {
        label: "Beneficiary Account Number",
        key: "accountNumber",
        title: "Beneficiary Account Number",
        dataIndex: "accountNumber",
      },
      {
        label: "Customer Gender",
        key: "gender",
        title: "Customer Gender",
        dataIndex: "gender",
      },
      {
        label: "Bank Name",
        key: "bankName",
        title: "Bank Name",
        dataIndex: "bankName",
      },
      {
        label: "Payment Item Code",
        key: "paymentItemCode",
        title: "Payment Item Code",
        dataIndex: "paymentItemCode",
      },
      {
        label: "Payment Method",
        key: "paymentMethod",
        title: "Payment Method",
        dataIndex: "paymentMethod",
      },
      {
        label: "Final Service Provider Response Code",
        key: "responseCode",
        title: "Final Service Provider Response Code",
        dataIndex: "responseCode",
      },
      {
        label: "Service Provider Response Code",
        key: "serviceProviderResponseCode",
        title: "Service Provider Response Code",
        dataIndex: "serviceProviderResponseCode",
      },
      {
        label: "Provider Reference",
        key: "providerReference",
        title: "Provider Reference",
        dataIndex: "providerReference",
      },
      {
        label: "Service Provider Transaction Reference",
        key: "serviceProviderTranRef",
        title: "Service Provider Transaction Reference",
        dataIndex: "serviceProviderTranRef",
      },
      {
        label: "Request Reference",
        key: "requestReference",
        title: "Request Reference",
        dataIndex: "requestReference",
      },
      {
        label: "Status",
        key: "statusCode",
        title: "Status",
        dataIndex: "statusCode",
      },
      {
        label: "Date of Creation",
        key: "dateCreated",
        title: "Date of Creation",
        dataIndex: "dateCreated",
      },
      {
        label: "Final Fulfilment Date",
        key: "dateModified",
        title: "Final Fulfilment Date",
        dataIndex: "dateModified",
      },
    ];
  }

  static reverseTransactionHistoryDetails(formData) {
    let newFormData = [];
    formData.map((detail) => {
      let {
        domainCode,
        agentName,
        email,
        businessMobileNo,
        transactionTypeInt,
        transactionRef,
        transactionAmount,
        fee,
        paymentItemCode,
        statusCode,
        paymentMethod,
        dateCreated,
        businessName,
        responseCode,
        ledgerBalance,
        availableBalance,
        pin,
        dateModified,
        serviceProviderResponseCode,
        serviceProviderTranRef,
        customerFirstName,
        customerLastName,
        customerMsisdn,
        beneficiaryAccountNumber,
        customerGender,
        bankName,
        requestReference,
      } = detail;

      let detailFormData = {
        customerName: `${customerFirstName} ${customerLastName}`,
        customerPhoneNumber: customerMsisdn
          ? formatLongNumber(customerMsisdn)
          : "",
        gender: customerGender,
        accountNumber: beneficiaryAccountNumber
          ? formatLongNumber(beneficiaryAccountNumber)
          : "",
        bankName,
        domainCode,
        agentName,
        email,
        businessName,
        responseCode,
        providerReference: serviceProviderTranRef
          ? formatLongNumber(serviceProviderTranRef)
          : "",
        serviceProviderTranRef: serviceProviderTranRef
          ? formatLongNumber(serviceProviderTranRef)
          : "",
        requestReference: requestReference
          ? formatLongNumber(requestReference)
          : "",
        serviceProviderResponseCode: serviceProviderResponseCode
          ? serviceProviderResponseCode
          : "",
        pin: pin ? formatLongNumber(pin) : "",
        businessMobileNo: businessMobileNo
          ? formatLongNumber(businessMobileNo)
          : "",
        transactionRef: transactionRef ? formatLongNumber(transactionRef) : "",
        fee: fee
          ? `₦${formatCurrency(convertToMajor(fee))}`
          : `₦${formatCurrency(0)}`,
        ledgerBalance: ledgerBalance
          ? `₦${formatCurrency(convertToMajor(ledgerBalance))}`
          : "",
        availableBalance: availableBalance
          ? `₦${formatCurrency(convertToMajor(availableBalance))}`
          : "",
        paymentItemCode,
        statusCode: formatTransactionStatus(statusCode),
        paymentMethod,
        dateCreated: dateCreated ? formatDateTime(dateCreated) : "",
        dateModified: dateModified ? formatDateTime(dateModified) : "",
        transactionType: transactionTypeInt
          ? getTransactionTypeName(transactionTypeInt)
          : "",
        transactionAmount: transactionAmount
          ? `₦${formatCurrency(convertToMajor(transactionAmount))}`
          : "",
      };

      newFormData.push(detailFormData);
    });
    return newFormData;
  }
}
