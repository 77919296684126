import React from 'react';

export default class StaticAccount extends React.Component {
    render() {
        const { availableStaticAccounts } = this.props;

        return (
            <div className="kt-portlet">
                {!this.props.hideStaticTitleHeader && (
                    <div
                        className="kt-portlet__head"
                        style={{ cursor: 'pointer' }}
                        data-toggle="collapse"
                        data-target="#collapseOne9"
                        aria-controls="collapseOne9"
                    >
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">Static Accounts</h3>
                        </div>
                    </div>
                )}
                <form id="collapseOne9" className={`collapse ${this.props.showContentStatic ? 'show' : ''}`}>
                    <div className="kt-portlet__body">
                        <div className="row">
                            {availableStaticAccounts.length === 0 ? "No Account Available" :
                                availableStaticAccounts.map((account) => (
                                    <div className="col-sm-4" key={account.staticAccountId}>
                                        <div className="card bg-light">
                                            <div className="card-body">
                                                <h5 className="card-title">{account.bankName}</h5>
                                                <p className="card-text">
                                                    <strong>Account Name:</strong> {account.accountName}
                                                </p>
                                                <p className="card-text">
                                                    <strong>Account Number:</strong> {account.accountNumber}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
