import React from 'react';

export default class MapCard extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            {this.props.title}
                        </h3>
                    </div>
                </div>
                <div class="kt-portlet__body kt-portlet__body--fluid" style={{ zIndex: 1 }}>
                    {this.props.content}
                </div>
            </div>
        </React.Fragment>
    }
}