import React from "react";

export default class FileUpload extends React.Component {
  static defaultProps = {
    buttonText: "Submit",
  };

  render() {
    return (
      <React.Fragment>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-lg-10">
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/svg+xml, image/webp"
              disabled={this.props.disabled}
              className="form-control"
              autoComplete="off"
              onChange={(event) =>
                this.props.readUploadedFile(event, this.props.type)
              }
            />
            {this.props.fileErrorMessage && (
              <span className="error">{this.props.fileErrorMessage}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              disabled={!this.props.isSubmitEnabled}
              type="button"
              className="btn isw-button"
              onClick={() => {
                this.props.handleFileUpload();
              }}
            >
              {this.props.buttonText}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}