import React from "react";
import Select from "../../../../../../../components/select";
// import {
//   formatTransactionStatus
// } from "../../../../../../utils/formatter";

export default class AppConfigEditDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      categoryNameOptions: [{ value: "Utility Bills", label: "Utility Bills" }],
      routeUrlOptions: [
        { value: "qtbpikeja", label: "Ikeja Electricity" },
        { value: "qtbpjos", label: "Jos Electricity" },
        { value: "qtbpeko", label: "EKO Eelectricity" },
        { value: "qtbpabuja", label: "Abuja Electricity" },
        { value: "qtbpenugu", label: "Enugu Electricity" },
        { value: "qtbpibadan", label: "Ibadan Electricity" },
        { value: "qtbpkano", label: "Kano Electricity" },
        { value: "qtbpph", label: "Port Harcourt Electricity" },
        { value: "qtbpkaduna", label: "Kaduna Electricity" },
        { value: "qtbpbenin", label: "Benin Electricity" },
        { value: "qtbpaba", label: "Aba Electricity" },
        { value: "qtbpyola", label: "Yola Electricity" },
        { value: "ifisikedcpredirect", label: "Ikeja Electric IFIS Pre-PAID" },
        {
          value: "ifisikedcpostdirect",
          label: "Ikeja Electric IFIS Post-PAID",
        },
      ],
      statusOptions: [
        { value: "True", label: "True" },
        { value: "False", label: "False" },
      ],
      providerCodeOptions: [
        { value: "QT", label: "Quickteller" },
        { value: "BP", label: "Buy Power" },
        { value: "IKEDC", label: "Ikeja Electric" },
      ],
    };
  }
  render() {
    const resul = this.props.configurationDetails
      ? this.props.configurationDetails
      : "";

    return (
      <React.Fragment>
        <div
          class="modal"
          id="app_config_edit_details_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Bill Configuration Edit Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div class="kt-portlet__head" style={{ cursor: "pointer" }}>
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Edit Configuration Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Category ID</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "categoryId",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.categoryId}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                            />
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Category Name</strong>
                          </label>
                          <br />
                          <span>
                            <Select
                              options={this.state.categoryNameOptions}
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "categoryName",
                                  event.value
                                )
                              }
                              defaultValue={resul.categoryName}
                              // placeholder="Select..."
                              // styles={customStylesBill}
                            />
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Channel Code</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "channelCode",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.code}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.code}
                            />
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Currency Code</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "currencyCode",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.currencyCode}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.currencyCode}
                            />
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Customer ID Name</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "customerIdName",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.customerIdName}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.customerIdName}
                            />
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Description</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "description",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.description}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.description}
                            />
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Image Name</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "imageName",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.imageName}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.imageName}
                            />
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Image URL</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "imageUrl",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.imageUrl}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.imageUrl}
                            />
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Name</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "name",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.name}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.name}
                            />
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Provider Code</strong>
                          </label>
                          <br />
                          <span>
                            <Select
                              options={this.state.providerCodeOptions}
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "providerCode",
                                  event.value
                                )
                              }
                              defaultValue={resul.providerCode}
                              placeholder="Select..."
                            />
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Route URL</strong>
                          </label>
                          <br />
                          <span>
                            <Select
                              options={this.state.routeUrlOptions}
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "routeUrl",
                                  event.value
                                )
                              }
                              defaultValue={resul.routeUrl}
                              placeholder={resul.routeUrl}
                            />
                          </span>
                          <br />
                          <br />
                        </div>

                        <div class="col-lg-6">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <br />
                          <span>
                            <Select
                              options={this.state.statusOptions}
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "appStatus",
                                  event.value
                                )
                              }
                              defaultValue={
                                resul.status === true ? "true" : "false"
                              }
                              placeholder={
                                resul.status === true ? "true" : "false"
                              }
                            />
                          </span>

                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Surcharge</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "surcharge",
                                  event.target.value
                                )
                              }
                              defaultValue={resul.surcharge}
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                              // placeholder={resul.surcharge}
                            />
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>URL Name</strong>
                          </label>
                          <br />
                          <span>
                            <input
                              type="text"
                              class="search-input"
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "urlName",
                                  event.target.value
                                )
                              }
                              value={resul.urlName}
                              defaultValue={resul.urlName}
                              disabled
                              // style={{width:"250px", borderRadius:"8px", boxShadow: "0 0 5px #11111140", border:"none"}}
                            />
                          </span>
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    // border: "2px solid #00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSearchParameters("urlName", resul.urlName);
                    setTimeout(
                      () => this.props.handleEditConfigurations(),
                      1500
                    );
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
