import React from 'react';
import ContentHead from '../../../../../../../../../components/content-head';
import BaseScene from '../../../../../../../../../components/scenes/component';
import { CAN_AGENT_BALANCE_VIEW } from '../../../../../../../../../constants/permissions.js';
import { SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../../../components/access-denied'
import { transactionHistory } from '../../../../../../../../../mixins/api'
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import AgentsBalanceTable from '../../components/agents-balance-table'
import Notification from '../../../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../../../mixins/protected-component';

export default class TransactionHistoryGridScene extends BaseScene {
  
  sceneName = 'wallet-management'

  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      balances: [],
      pages: 1,
      canLoadData: true,
      pageNum: 1,
      pageSize: 20,
      tableMessage: ""
    }

    this.getAgentsBalance = this.getAgentsBalance.bind(this)
  }
  
  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getAgentsBalance (pageNum, pageSize) {
    this.setState({
      pageNum,
      pageSize,
      isLoading: true
    })
    const { status, response, count } = await transactionHistory.retrieveAgentBalances(
      pageNum,
      pageSize,
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        balances: response ? response : [],
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count,
        isLoading: false,
      })
    } else {
      this.setState({
        balances: [],
        dataCount: 0,
        pages: Math.ceil(count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No agent balance report found"
      })
    }
  }

  render () {
    return <React.Fragment>
      <ContentHead title={"Wallet Management"} subtitle={"Agents Balance Report"} filterPermissions={[CAN_AGENT_BALANCE_VIEW]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_AGENT_BALANCE_VIEW]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <AgentsBalanceTable 
            pages={this.state.pages}
            getAgentsBalance={this.getAgentsBalance}
            data={this.state.balances}
            startPage={this.state.startPage}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            page={this.state.pageNum}
            pageSize={this.state.pageSize}
            canLoadData={this.state.canLoadData}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
