import React, { Component } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import Header from "../../components/header";
import HeaderMobile from "../../components/header-mobile";
import { GridItem } from "../../components/layouts/grid-item";
import { HorizontalGrid } from "../../components/layouts/grids/horizontal";
import NavBar from "../../components/nav-bar";
import AgentClasses from "./scenes/agent-management/agent-classes";
import DocumentTypeScene from "./scenes/agent-management/document-types";
import AgentHierarchyScene from "./scenes/agent-management/agent-hierarchy";
import ExemptedAgentScene from "./scenes/agent-management/exempted-agents";
import KycUpdate from "./scenes/agent-management/kyc-update";

import AgentManagement from "./scenes/agent-management/agents";
// import RemapPosHistoryScene from './scenes/pos-management/remap-manage/scenes/remap-pos-history';
import AgentBVNManagement from "./scenes/agent-management/agents-bvn";
import AggregatorManagement from "./scenes/agent-management/aggregators";
import SuperAgentManagment from "./scenes/agent-management/super-agent";
import DistributeScene from "./scenes/agent-management/wallet-distribution";
import ApplicationScene from "./scenes/applications";
import BusinessTypeScene from "./scenes/applications/scenes/configurations/business-type";
import DeclineReasonCategoryScene from "./scenes/applications/scenes/configurations/decline-reason-category";
import IdentityTypeScene from "./scenes/applications/scenes/configurations/identity-type";
import BuypowerWalletScene from "./scenes/buy-power-wallet";
import DashboardScene from "./scenes/dashboard";
import DomainManagementScene from "./scenes/domain-management";
import AppConfigScene from "./scenes/fee-management/app-config";
import CommissionSplitRuleScene from "./scenes/fee-management/commission-split-rule";
import ExternalFeeScene from "./scenes/fee-management/external-fee";
import FeeConfigurationScene from "./scenes/fee-management/fee-configuration";
import FeeRuleScene from "./scenes/fee-management/fee-rule";
import RevenueSharingScene from "./scenes/fee-management/revenue-sharing";
import ServiceOptionScene from "./scenes/fee-management/service-options";
import ServiceTypesScene from "./scenes/fee-management/service-type-management";
import ServiceScene from "./scenes/fee-management/services";
//import ApplicationScene from './scenes/application-management/application'
import FipsScene from "./scenes/fip-management/fips";
import TraceScene from "./scenes/instrumentation/trace";
import NotificationScene from "./scenes/notification";
import MessagingHistoryScene from "./scenes/notification/messaging-history-scene";
import NotificationGroupScene from "./scenes/notification/notification-group-scene";
import RecurringMessageScene from "./scenes/notification/recurring-message-scene";
import SendBroadcastScene from "./scenes/notification/send-broadcast-scene";
import PerformanceMetricScene from "./scenes/performance-metric";
import PerformanceConfigGridScene from "./scenes/performance-metric-config/scenes/performance-config-grid";
import PermissionGroupScene from "./scenes/permission-group-management";
import PermissionScene from "./scenes/permission-management";
// import RemapPosAllScene from './scenes/pos-management/remap-pos-all';
import AllStocksScene from "./scenes/pos-management/all-stocks";
import AllocationsManageScene from "./scenes/pos-management/allocations-manage";
// import RemapPosScene from './scenes/pos-management/remap-pos';
import ConfigManageScene from "./scenes/pos-management/config-manage";
import PosConfig from "./scenes/pos-management/pos-config";
import PosManageScene from "./scenes/pos-management/pos-manage";
import DeviceManagementScene from "./scenes/pos-management/pos-remap/scene";
import PosStocks from "./scenes/pos-management/pos-stocks";
// import WalletRateScene from "./scenes/wallet-performance/scene";
// import ViewPosRequest from './scenes/pos-management/view-pos-requests';
// import StockScene from "./scenes/stock-management/stocks";

import RemapManageScene from "./scenes/pos-management/remap-manage";
import ArbitrateRequestScene from "./scenes/pos-management/arbitrate-manage";
import StockManageScene from "./scenes/pos-management/stock-manage";
import ApprovalScene from "./scenes/workflow-management/approval";

import ConfigUpload from "./scenes/pos-management/upload-config";

import UploadStocksDetailsScene from "./scenes/pos-management/upload-stocks/scenes/stock-upload-details-grid";
import StocksUploadScene from "./scenes/pos-management/upload-stocks/scenes/stock-upload-grid";
import ProfileManagementScene from "./scenes/profile-management";
import AuditReportScene from "./scenes/reports/audit-report";
import CashoutInitiatedScene from "./scenes/reports/cashout-initiated";
import CommissionHistoryScene from "./scenes/reports/commission-history";
import HistoricalTransactionsScene from "./scenes/reports/historical-transactions";
import PayWithTransferScene from "./scenes/reports/pwt-resolution";
import ReconReportScene from "./scenes/reports/recon-report";
import ReversalHistory from "./scenes/reports/reversal-history";
import SettlementReportScene from "./scenes/reports/settlement-report";
import TransactionHistoryScene from "./scenes/reports/transaction-history";
import NIPUploadGridScene from "./scenes/reports/transaction-history/scenes/Nip-upload-grid";
import TransactionSummaryScene from "./scenes/reports/transaction-summary";
import WalletJournalScene from "./scenes/reports/wallet-journal";
import RoleManagment from "./scenes/role-management";
import BankScene from "./scenes/setup/bank-management";
import CountryScene from "./scenes/setup/country-management";
import CurrencyScene from "./scenes/setup/currency-management";
import LanguageScene from "./scenes/setup/language-management";
import LgaScene from "./scenes/setup/lga-management";
import ReconSchedulerScene from "./scenes/setup/recon-scheduler";
import RegionScene from "./scenes/setup/region-management";
import SanefBankScene from "./scenes/setup/sanef-bank-management";
import StateScene from "./scenes/setup/state-managment";
import UserManagment from "./scenes/user-management";
import BatchCreditDebitScene from "./scenes/wallet-management/batch-credit-debit";
import LienManagementScene from "./scenes/wallet-management/lien-management";
import WalletManagementAgentBalanceReports from "./scenes/wallet-management/reports/scenes/agent-balance-reports";
import WalletManagementEndOfDayLedgerReports from "./scenes/wallet-management/reports/scenes/end-of-day-ledger";
import WalletManagementEndOfDayReports from "./scenes/wallet-management/reports/scenes/end-of-day-reports";
import WalletManagementTrialBalanceReports from "./scenes/wallet-management/reports/scenes/trial-balance-reports";
import WalletManagementWalletPoolStatements from "./scenes/wallet-management/reports/scenes/wallet-pool-statements";
// import ViewPosRequest from './scenes/pos-management/view-pos-requests';
// import StockScene from "./scenes/stock-management/stocks";
import QuickStockGridScene from "./scenes/pos-management/quick-stocks/";
import UploadQuickStockScene from "./scenes/pos-management/quick-stocks/scenes/quick-upload-stock-grid";
import PosStocksScene from "./scenes/pos-management/stocks";
import UploadStocksScene from "./scenes/pos-management/upload-stocks";

import BacklogManageScene from "./scenes/pos-management/backlog-upload";
import CashoutInjectScene from "./scenes/reports/cashout-inject/index";
import WalletPerformanceScene from "./scenes/wallet-performance/scene";
import WorkflowConfigurationScene from "./scenes/workflow-management/configuration";
import StaticAccountScene from "./scenes/agent-management/static-accounts";

export default class HomeScene extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "./assets/js/demo1/scripts.bundle.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    if (
      ["/home", "/home/", "/admin/home", "/admin/home/"].includes(
        window.location.pathname
      )
    ) {
      return <Redirect to="/home/dashboard" />;
    }

    return (
      <React.Fragment>
        <HeaderMobile />

        <HorizontalGrid isRoot>
          <GridItem class="kt-page" isFluid isVerticalGrid>
            <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
              <NavBar />

              <GridItem isFluid isHorizontalGrid isPage class="kt-wrapper">
                <Header />

                <GridItem isFluid isHorizontalGrid class="kt-content">
                  <Switch>
                    <Route path="/home/dashboard" component={DashboardScene} />
                    <Route
                      path="/home/buy-power-wallet"
                      component={BuypowerWalletScene}
                    />
                    <Route path="/home/app-config" component={AppConfigScene} />
                    <Route
                      path="/home/super-agent-management"
                      component={SuperAgentManagment}
                    />
                    <Route
                      path="/home/user-management"
                      component={UserManagment}
                    />
                    <Route
                      path="/home/role-management"
                      component={RoleManagment}
                    />
                    <Route
                      path="/home/transaction-history/Upload-Nip"
                      component={NIPUploadGridScene}
                    />
                    <Route
                      path="/home/domain-management/:domainCode"
                      component={DomainManagementScene}
                    />
                    {/* <Route path="/home/domain-management/:domainCode/roles" component={DomainRoleScene} />
                  <Route path="/home/domain-management/:domainCode/users" component={DomainUserScene} /> */}
                    <Route
                      path="/home/agent-management"
                      component={AgentManagement}
                    />
                    <Route
                      path="/home/exempted-agent-management"
                      component={ExemptedAgentScene}
                    />
                    <Route
                      path="/home/manage-static-account"
                      component={StaticAccountScene}
                    />
                    <Route
                      path="/home/kyc-update"
                      component={KycUpdate}
                    />
                    <Route
                      path="/home/document-type"
                      component={DocumentTypeScene}
                    />
                    <Route
                      path="/home/aggregator-management"
                      component={AggregatorManagement}
                    />
                    <Route
                      path="/home/agent-classes"
                      component={AgentClasses}
                    />
                    <Route
                      path="/home/service-types-management"
                      component={ServiceTypesScene}
                    />
                    <Route
                      path="/home/fee-configuration"
                      component={FeeConfigurationScene}
                    />
                    <Route
                      path="/home/pos-management/all-stocks"
                      component={AllStocksScene}
                    />
                    <Route
                      path="/home/transaction-history"
                      component={TransactionHistoryScene}
                    />
                    <Route
                      path="/home/cashout-initiated"
                      component={CashoutInitiatedScene}
                    />
                    <Route
                      path="/home/cashout-inject"
                      component={CashoutInjectScene}
                    />
                    <Route
                      path="/home/recon-report"
                      component={ReconReportScene}
                    />
                    <Route
                      path="/home/commission-history"
                      component={CommissionHistoryScene}
                    />
                    <Route
                      path="/home/lien-management"
                      component={LienManagementScene}
                    />

                    <Route
                      path="/home/wallet-management-agent-balance-reports"
                      component={WalletManagementAgentBalanceReports}
                    />
                    <Route
                      path="/home/wallet-management-end-of-day-reports"
                      component={WalletManagementEndOfDayReports}
                    />
                    <Route
                      path="/home/wallet-management-end-of-day-ledger-reports"
                      component={WalletManagementEndOfDayLedgerReports}
                    />
                    <Route
                      path="/home/wallet-management-trial-balance-reports"
                      component={WalletManagementTrialBalanceReports}
                    />
                    <Route
                      path="/home/wallet-management-wallet-pool-statements"
                      component={WalletManagementWalletPoolStatements}
                    />
                    <Route
                      path="/home/batch-credit-debit"
                      component={BatchCreditDebitScene}
                    />
                    <Route
                      path="/home/pwt-resolution"
                      component={PayWithTransferScene}
                    />
                    <Route
                      path="/home/wallet-perfromance"
                      component={WalletPerformanceScene}
                    />
                    <Route
                      path="/home/settlement-report"
                      component={SettlementReportScene}
                    />
                    <Route
                      path="/home/audit-report"
                      component={AuditReportScene}
                    />
                    <Route
                      path="/home/application-management/configuration/decline-reason-categories"
                      component={DeclineReasonCategoryScene}
                    />
                    <Route
                      path="/home/application-management/configuration/business-types"
                      component={BusinessTypeScene}
                    />
                    <Route
                      path="/home/application-management/configuration/identity-types"
                      component={IdentityTypeScene}
                    />
                    {/* <Route path="/home/application-management" component={ApplicationScene} /> */}
                    <Route
                      path="/home/profile"
                      component={ProfileManagementScene}
                    />
                    <Route
                      path="/home/system-configuration/recon-scheduler"
                      component={ReconSchedulerScene}
                    />
                    <Route
                      path="/home/performance-metric-config"
                      component={PerformanceConfigGridScene}
                    />

                    <Route
                      path="/home/system-configuration/bank"
                      component={BankScene}
                    />
                    <Route
                      path="/home/system-configuration/bank-management/add"
                      component={BankScene}
                    />
                    <Route
                      path="/home/system-configuration/currency-management"
                      component={CurrencyScene}
                    />
                    <Route
                      path="/home/system-configuration/language-management"
                      component={LanguageScene}
                    />
                    <Route
                      path="/home/system-configuration/country-management"
                      component={CountryScene}
                    />
                    <Route
                      path="/home/system-configuration/state-management"
                      component={StateScene}
                    />
                    <Route
                      path="/home/system-configuration/sanef-bank-management"
                      component={SanefBankScene}
                    />
                    <Route
                      path="/home/system-configuration/lga-management"
                      component={LgaScene}
                    />
                    <Route
                      path="/home/system-configuration/region-management"
                      component={RegionScene}
                    />
                    <Route
                      path="/home/revenue-sharing"
                      component={RevenueSharingScene}
                    />
                    <Route path="/home/fee-rules" component={FeeRuleScene} />
                    <Route
                      path="/home/commission-split-rules"
                      component={CommissionSplitRuleScene}
                    />
                    <Route
                      path="/home/external-fees"
                      component={ExternalFeeScene}
                    />
                    <Route
                      path="/home/application-management"
                      component={ApplicationScene}
                    />
                    <Route
                      path="/home/workflow-management/approval"
                      component={ApprovalScene}
                    />
                    <Route
                      path="/home/workflow-management/configuration"
                      component={WorkflowConfigurationScene}
                    />
                    <Route path="/home/services" component={ServiceScene} />
                    <Route
                      path="/home/service-options"
                      component={ServiceOptionScene}
                    />
                    <Route
                      path="/home/wallet/distribute"
                      component={DistributeScene}
                    />
                    <Route path="/home/fip-management" component={FipsScene} />
                    <Route
                      path="/home/agent-hierarchy"
                      component={AgentHierarchyScene}
                    />
                    <Route
                      path="/home/permission-management"
                      component={PermissionScene}
                    />
                    <Route
                      path="/home/permission-group-management"
                      component={PermissionGroupScene}
                    />
                    <Route
                      path="/home/historical-transactions"
                      component={HistoricalTransactionsScene}
                    />
                    <Route
                      path="/home/account-statement/:domainCode"
                      component={WalletJournalScene}
                    />
                    <Route
                      path="/home/account-statement"
                      component={WalletJournalScene}
                    />
                    {/* <Route path="/home/application-management/add" component={AddApplicationScene} /> */}
                    <Route
                      path="/home/transaction-summary"
                      component={TransactionSummaryScene}
                    />
                    <Route
                      path="/home/notifications/template"
                      component={NotificationScene}
                    />
                    <Route
                      path="/home/notifications/notification-group"
                      component={NotificationGroupScene}
                    />
                    <Route
                      path="/home/notifications/messaging-history"
                      component={MessagingHistoryScene}
                    />
                    <Route
                      path="/home/notifications/send-notification"
                      component={SendBroadcastScene}
                    />
                    <Route
                      path="/home/notifications/recurring-message"
                      component={RecurringMessageScene}
                    />
                    <Route
                      path="/home/instrumentation/trace"
                      component={TraceScene}
                    />
                    <Route
                      path="/home/reversal-history"
                      component={ReversalHistory}
                    />
                    <Route
                      path="/home/pos-management/devices"
                      component={DeviceManagementScene}
                    />
                    {/* <Route
                      path="/home/bank-networking/performance"
                      component={WalletRateScene}
                    /> */}
                    <Route
                      path="/home/pos-management/pos-stocks-request"
                      component={PosStocks}
                    />
                    <Route
                      path="/home/pos-management/pos-stocks"
                      component={PosStocksScene}
                    />

                    <Route
                      path="/home/pos-management/upload-stocks-details"
                      component={UploadStocksDetailsScene}
                    />
                    <Route
                      path="/home/pos-management/upload-stocks"
                      component={UploadStocksScene}
                    />
                    <Route
                      path="/home/pos-management/stocks-upload"
                      component={StocksUploadScene}
                    />
                    <Route
                      path="/home/pos-management/quick-stocks"
                      component={QuickStockGridScene}
                    />
                    <Route
                      path="/home/pos-management/upload-quick-stocks"
                      component={UploadQuickStockScene}
                    />
                    <Route
                      path="/home/pos-management/pos-config"
                      component={PosConfig}
                    />
                    <Route
                      path="/home/pos-management/config-upload"
                      component={ConfigUpload}
                    />
                    {/* <Route path="/home/pos-management/view-pos-requests" component={ViewPosRequest} /> */}
                    {/* <Route path="/home/pos-management/pos-requests-awaiting-config" component={ViewPosRequestsAwaitingConfigScene} /> */}
                    {/* <Route path="/home/pos-management/pos-requests-awaiting-stock-upload" component={ViewPosRequestsAwaitingStockUploadScene} /> */}
                    {/* <Route path="/home/pos-management/pos-awaiting-config-upload" component={ViewPosRequestsAwaitingConfigUploadScene} /> */}
                    {/* <Route path="/home/pos-management/remap-pos" component={RemapPosScene} /> */}
                    <Route
                      path="/home/pos-management/config-manage"
                      component={ConfigManageScene}
                    />
                    <Route
                      path="/home/pos-management/stock-manage"
                      component={StockManageScene}
                    />
                    <Route
                      path="/home/pos-management/backlog-manage"
                      component={BacklogManageScene}
                    />
                    <Route
                      path="/home/pos-management/pos-manage"
                      component={PosManageScene}
                    />
                    <Route
                      path="/home/pos-management/remap-manage"
                      component={RemapManageScene}
                    />
                    <Route
                      path="/home/pos-management/arbitrate-requests"
                      component={ArbitrateRequestScene}
                    />
                    <Route
                      path="/home/pos-management/allocations-manage"
                      component={AllocationsManageScene}
                    />
                    {/* <Route path="/home/pos-management/remap-pos-all" component={RemapPosAllScene} /> */}
                    {/* <Route path="/home/pos-management/remap-pos-history" component={RemapPosHistoryScene} /> */}
                    <Route
                      path="/home/agent-bvn-management"
                      component={AgentBVNManagement}
                    />
                    <Route
                      path="/home/performance-metrics"
                      component={PerformanceMetricScene}
                    />
                  </Switch>
                </GridItem>
              </GridItem>
            </Router>
          </GridItem>
        </HorizontalGrid>
      </React.Fragment>
    );
  }
}
