import React from 'react'
import "./index.css"

export default class StockSplits extends React.Component {
    render () {
        return <React.Fragment>
            <div class="kt-portlet">
                {/* <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne2" aria-controls="collapseOne2">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                        <h3 class="kt-portlet__head-title">
                            Fee Split Details
                        </h3>
                    </div>
                </div> */}
                <form style={{width:"1500px"}} class="kt-form kt-form--label-right" id="collapseOne2" aria-labelledby="headingOne1">
                    <div class="kt-portlet__body" style={{}}>
                        <table class="table table-hover" >
                            <thead class="thead-light" >
                                <th style={{width:"300px"}}>Batch ID</th>
                                <th style={{width:"300px"}}>Serial No</th>
                                <th style={{width:"300px"}}>Device Type</th>
                                <th style={{width:"300px"}}>Name</th>
                                <th style={{width:"300px"}}>Model</th>
                                <th style={{width:"300px"}}>Date Uploaded</th>
                                <th style={{width:"300px"}}>Uploaded By</th>
                            </thead>
                            <tbody>
                                {this.props.stockSplits? this.props.stockSplits.map((value, idx) =>
                                    <tr key={idx}>
                                        <td>{value.batchId ? value.batchId : "loading..."}</td>
                                        <td>{value.serialNo ? value.serialNo : "loading..."}</td>
                                        <td>{value.terminalType ? value.terminalType : "loading..."}</td>
                                        <td>{value.terminalName ? value.terminalName : "loading..."}</td>
                                        <td>{value.terminalModel ? value.terminalModel : "loading..."}</td>
                                        <td>{value.dateUploaded ? value.dateUploaded : "loading..."}</td>
                                        <td>{value.uploadedBy ? value.uploadedBy : "loading..."}</td>
                                    </tr>
                                ) : ""}
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}