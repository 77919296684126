import React from "react";
// import ContentHead from "../../../../../../../../components/content-head";
import { AccessDenied } from "../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE,
} from "../../../../../../../constants/permissions.js";
import RemapPosAllModal from "./components/remap-pos-all-modal";
import RemapPosAllSearch from "./components/remap-pos-all-search";
import RemapPosAllTable from "./components/remap-pos-all-table";
// import { userManagement } from "../../../../../../../../mixins/api";
import { RemapPromptModal } from "../../../../../../../components/modals/remap-cancel-prompt-modal";
import { remapPosAll, userManagement } from "../../../../../../../mixins/api";
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from "../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../utils/helper";
import RemapPosRequestCancelModal from "./components/remap-pos-cancel-modal.js";
import Loader from "../../../../../../../components/loader.js";

export default class RemapPosAllGridScene extends BaseScene {
  sceneName = "remap-pos-all";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      // services: [{ id: 0, name: "Any" }],
      // serviceTypes: [{ id: 0, name: "Any" }],
      remapStatuses: [
        { value: "", label: "Select..." },
        { value: "approved", label: "Approved" },
        { value: "declined", label: "Decline" },
        { value: "closed", label: "Closed" },
        { value: "cancelled", label: "Cancelled" },
        { value: "awaiting_approval", label: "Awaiting Approval" },
        { value: "awaiting_delivery", label: "Awaiting Delivery" },
      ],
      requests: [],
      posRequestDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        approvalStatus: "",
        agentUsername: "",
        requestId: "",
      },
      // pages: 1,
      pageSize: 20,
      pageNumber: 1,
      tableMessage: "",
      configured: true,
      showConfirm: false,
      cancelReason: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
    };

    this.getAllRemapRequests = this.getAllRemapRequests.bind(this);
    this.getPosRemapRequestDetails = this.getPosRemapRequestDetails.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.approveRemapRequestsByRequestid = this.cancelRemapRequestsByRequestid.bind(
      this
    );
    this.cancelRemapRequestsAwaitingDelivery = this.cancelRemapRequestsAwaitingDelivery.bind(
      this
    );

    this.confirmCancel = this.confirmCancel.bind(this);
    this.handleNoConfirmationModal = this.handleNoConfirmationModal.bind(this);
    this.downloadRemapData = this.downloadRemapData.bind(this);
  }

  componentDidMount() {
    this.getAllRemapRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllRemapRequests(pageNumber, pageSize) {
    const {
      agentUsername,
      toDate,
      fromDate,
      approvalStatus,
      requestId,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true, isLoading: true });

    const formattedEndDate =
      toDate && `${formatBackEndDateTimeTo(toDate, true)}.747`;
    const formattedStartDate =
      fromDate && `${formatBackEndDateTimeFrom(fromDate, true)}.747`;

    const {
      status,
      response,
      count,
    } = await remapPosAll.getAllRemapRequestsBySearch(
      pageNumber,
      pageSize,
      agentUsername,
      requestId,
      formattedEndDate,
      formattedStartDate,
      approvalStatus
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        requests: response ? response.data : [],
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNumber,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        tableMessage: !count || count == 0 ? "No Remap Request found" : "",
        startPage: null,
      });
    } else {
      this.setState({
        requests: [],
        dataCount: 0,
        pages: 1,
        // pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        page: pageNumber,
        isLoading: false,
        tableMessage: "No Remap Request found",
        // errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async getPosRemapRequestDetails(id) {
    this.setState({
      posRequestDetails: [],
    });
    localStorage.setItem("remap details", JSON.stringify(id));

    const responseObj = await remapPosAll.getRemapRequestsById(id);
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      // console.log(response);
      // this.setState({
      //   isLoading: false,
      // });

      // if (response.data.length > 0) {
      this.setState({
        posRequestDetails: response.data,
      });
      // }
    }
  }

  async cancelRemapRequestsByRequestid(cancelReason) {
    this.setState({
      configured: false,
    });
    let cancelId = localStorage.getItem("remap details");
    cancelId = JSON.parse(cancelId);
    const responseObj = await remapPosAll.cancelRemapRequestsByRequestid(
      cancelId,
      cancelReason
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        configured: true,
        // posRequestAwaitingConfigDetails: response.data,
        successMessage: `Remap request cancelled Successfully`,
      });
      this.getAllRemapRequests();
    } else {
      this.setState({
        configured: true,
        // posRequestAwaitingConfigDetails: [],
        errorMessage: response,
      });
    }
  }
  async cancelRemapRequestsAwaitingDelivery(cancelReason) {
    this.setState({
      // configured: false,
      loading: true,
    });
    let cancelId = localStorage.getItem("remap details");
    cancelId = JSON.parse(cancelId);

    const responseObj = await remapPosAll.cancelRemapRequests(
      cancelId,
      cancelReason
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        // configured: true,
        loading: false,
        // posRequestAwaitingConfigDetails: response.data,
        successMessage: `Remap request cancelled Successfully`,
      });
      this.getAllRemapRequests();
    } else {
      this.setState({
        // configured: true,
        loading: false,
        // posRequestAwaitingConfigDetails: [],
        errorMessage: response,
      });
    }
  }

  async downloadRemapData() {
    const payload = {
      pageNum: this.state.page,
      pageSize: this.state.dataCount,
      posRemapData: this.state.searchParameters.approvalStatus,
      fromDate:
        this.state.searchParameters.fromDate &&
        `${formatBackEndDateTimeFrom(
          this.state.searchParameters.fromDate,
          true
        )}.747`,
      toDate:
        this.state.searchParameters.toDate &&
        `${formatBackEndDateTimeTo(
          this.state.searchParameters.toDate,
          true
        )}.747`,
    };
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await userManagement.downloadRemaptData(payload);
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "All_remaps.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }
  isFormValid() {
    if (this.state.showCommentField) {
      return Boolean(this.state.description);
    } else {
      return true;
    }
  }

  handleSearchRequest() {
    this.getAllRemapRequests(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType == "view") {
      this.getPosRemapRequestDetails(requests.id);
      this.setState({
        requestDetails: requests,
        settlement: {
          parties: [],
        },
      });
    }
    if (actionType == "cancel") {
      this.getPosRemapRequestDetails(requests.id);
      this.setState({
        requestDetails: requests,
      });
    }

    //TO-DO
    // else if(actionType == "approve") {
    //   this.approveRemapRequestsByRequestid(requests.id);
    // } else if(actionType == "decline") {
    //   this.cancelRemapRequestsByRequestid(requests.id);
    // }
    this.setState({
      requestId: requests.requestId,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType == "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  confirmCancel() {
    this.setState({
      showConfirm: true,
    });
  }
  confirmCancelRequest() {
    this.setState({
      showConfirmRequest: true,
    });
  }

  handleNoConfirmationModal() {
    this.setState({
      showConfirm: false,
    });
    // this.getAllRemapRequests();
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <center>
          <p
            style={{
              fontSize: "15px",
              lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
              margin: "10px",
            }}
          >
            Are you sure you want to cancel this POS remap Request?
          </p>
          <br />
          <br />
          <div class="col-md-12">
            <textarea
              class="form-control"
              style={{
                resize: "none",
                marginTop: "15px",
              }}
              placeholder="Enter reasons for cancellation"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              onChange={(event) =>
                this.setState({
                  cancelReason: event.target.value,
                })
              }
            />
          </div>
        </center>
        <br />
        <br />
        <br />
        <br />
        <br />
      </React.Fragment>
    );
    const modalButton1 = (
      <React.Fragment>
        <button
          disabled={!this.state.cancelReason}
          type="button"
          class="btn btn-primary col-lg-6"
          style={{ backgroundColor: "#004257" }}
          onClick={() => {
            // alert("remap cancelled successflly!!!")
            this.cancelRemapRequestsByRequestid(this.state.cancelReason);
          }}
        >
          Yes
        </button>
      </React.Fragment>
    );
    const modalButton2 = (
      <React.Fragment>
        <button
          type="button"
          class="btn btn-primary col-lg-6"
          style={{
            border: "1px solid #00425f",
            backgroundColor: "#ffffff",
            color: "#004257",
          }}
          onClick={this.handleNoConfirmationModal}
        >
          No
        </button>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {/* <ContentHead
          title={"View POS Requests"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        <Loader isLoading={this.state.loading} />
        {this.state.configured ? (
          <>
            <Notification
              ref={this.notificationSectionRef}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
            <ProtectedComponent
              // requiredPermissions={[CAN_VIEW_TRANSACTION]}
              requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
              permissionDeniedContent={this.permissionDeniedContent}
            >
              <GridLayout>
                <RemapPosAllSearch
                  searchParameters={this.state.searchParameters}
                  remapStatuses={this.state.remapStatuses}
                  agentName={this.state.requests.agentName}
                  updateSearchParameters={this.updateSearchParameters}
                  handleSearchRequest={this.handleSearchRequest}
                  preloadDate={
                    retrieveOtherInformation("parentReference")
                      ? false
                      : !JSON.parse(
                          process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA
                        )
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                {/* {this.state.showConfirm && confirmationModal} */}
                <RemapPosAllTable
                  data={this.state.requests}
                  loading={this.state.isLoading}
                  noDataText={this.state.tableMessage}
                  dataCount={this.state.dataCount}
                  downloadRemap={this.downloadRemapData}
                  pages={this.state.pages}
                  startPage={this.state.startPage}
                  getAllRemapRequests={this.getAllRemapRequests}
                  page={this.state.page}
                  pageSize={this.state.pageSize}
                  canRemapDevice={CAN_REMAP_DEVICE}
                  canLoadData={this.state.canLoadData}
                  searchParameters={this.state.searchParameters}
                  responseCode={this.state.searchParameters.responseCode}
                  startDate={this.state.searchParameters.startDate}
                  canView={CAN_GET_DEVICES_BY_UUID}
                  getClickedAction={this.getClickedAction}
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                <RemapPosAllModal
                  posRequestDetails={this.state.posRequestDetails}
                  loading={this.state.isLoading}
                  confirmConfigure={this.confirmCancel}
                />
                <RemapPosRequestCancelModal
                  posRequestDetails={this.state.posRequestDetails}
                  loading={this.state.isLoading}
                  cancelRemapRequestsAwaitingDelivery={
                    this.cancelRemapRequestsAwaitingDelivery
                  }
                />
                <RemapPromptModal
                  modalTitle={"CANCEL REMAP REQUEST?"}
                  modalBody={modalBody}
                  modalFooterButton1={modalButton1}
                  hideModalHeader={true}
                  modalSize={`modal-md`}
                  height={`500px`}
                  modalFooterButton2={modalButton2}
                  deleteModal={this.handleNoConfirmationModal}
                  // modalFooterButton2Disabled={!this.isFormValid()}
                  // isLoading={this.state.isLoading}
                  // canReload={this.state.canReload}
                />
              </GridLayout>
            </ProtectedComponent>
          </>
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
