export default class AuditExportSerializer {
    static headerExport () {
        return [
            { label: 'IP', key: 'ip', title: 'IP', dataIndex: 'ip' },
            { label: 'Date', key: 'entryDate', title: 'Date', dataIndex: 'entryDate' },
            { label: 'Time', key: 'entryTime', title: 'Time', dataIndex: 'entryTime' },
            { label: 'Entity', key: 'auditEntity', title: 'Audit Entity', dataIndex: 'auditEntity' },
            { label: 'Action', key: 'auditAction', title: 'Action', dataIndex: 'auditAction' },
            { label: 'Status', key: 'auditStatus', title: 'Status', dataIndex: 'auditStatus' },
            { label: 'Application Audited', key: 'applicationAudited', title: 'Application Auidted', dataIndex: 'applicationAudited' },
            { label: 'Caller Application', key: 'callerApplication', title: 'Caller Application', dataIndex: 'callerApplication' },
            { label: 'Audit Type', key: 'auditType', title: 'Audit Type', dataIndex: 'auditType' },
        ];
    }
}