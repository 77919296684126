import React from "react";
import { AccessDenied } from "../../../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS
} from "../../../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE
} from "../../../../../../../../../constants/permissions.js";
import { posRequestsAwaitingStockUpload } from "../../../../../../../../../mixins/api";
import {
  deleteOtherInformation, retrieveOtherInformation
} from "../../../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom, formatBackEndDateTimeTo
} from "../../../../../../../../../utils/formatter";
import {
  getDateByType
} from "../../../../../../../../../utils/helper";
import PosRequestsAwaitingStockUploadDetailsModal from "../../components/pos-requests-awaiting-stock-upload-details-modal";
import PosRequestsAwaitingStockUploadSearch from "../../components/pos-requests-awaiting-stock-upload-search";
import PosRequestsAwaitingStockUploadTable from "../../components/pos-requests-awaiting-stock-upload-table";

export default class PosRequestsAwaitingStockUploadGridScene extends BaseScene {
  sceneName = "pos-requests-awaiting-stock-upload";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      posRequestAwaitingStockUploadDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        // requestType: "",
        approvalStatus: "",
        mobilePhone: "",
        posRequestsAwaitingStockUploadStatus: "All",
        deviceType: "All",
      },
      pages: 1,
      pageSize: 20,
      pageNumber: 1,
      tableMessage: "",
      configured: true,
    };

    this.getAllPosRequestsAwaitingStockUpload = this.getAllPosRequestsAwaitingStockUpload.bind(
      this
    );
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.uploadPosRequestsAwaitingStockUpload = this.uploadPosRequestsAwaitingStockUpload.bind(
      this
    );
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  componentDidMount() {
    this.getAllPosRequestsAwaitingStockUpload();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content === null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllPosRequestsAwaitingStockUpload(pageNumber, pageSize) {
    const {
      mobilePhone,
      toDate,
      fromDate,
      approvalStatus,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDate =
      toDate && `${formatBackEndDateTimeTo(toDate, true)}.747`;
    const formattedStartDate =
      fromDate && `${formatBackEndDateTimeFrom(fromDate, true)}.747`;
    console.log(formattedEndDate, "and", formattedStartDate);

    this.setState({
      pageNumber,
      pageSize,
      isLoading: true,
    });

    const {
      status,
      response,
      count,
    } = await posRequestsAwaitingStockUpload.getRequestsAwaitingStockUploadBySearch(
      pageNumber,
      pageSize,
      mobilePhone,
      formattedEndDate,
      formattedStartDate,
      approvalStatus
    );
    if (status === SUCCESS_STATUS) {
      console.log(
        response,
        " POS REQUESTS AWAITING STOCK UPLOAD RETRIEVED SUCCESSFULLY"
      );
      this.setState({
        requests: response ? response.data : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        // dataCount: count,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        tableMessage:
          !count || count === 0
            ? "No POS request Awaiting Stock Upload found"
            : "",
      });
    } else {
      this.setState({
        requests: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        isLoading: false,
        tableMessage: "No POS Request Awaiting Stock Upload found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async uploadPosRequestsAwaitingStockUpload(quantity, workflowId) {
    this.setState({
      configured: false,
    });
    const responseObj = await posRequestsAwaitingStockUpload.uploadPosRequestsAwaitingStockUpload(
      quantity,
      workflowId
    );
  
    const { status } = responseObj;
    if (status === SUCCESS_STATUS) {
     
      // this.setState({
      //   isLoading: false,
      // });

      this.setState({
        configured: true,
      });

      // if(response.data.length > 0) {
      this.setState({
        // posRequestAwaitingStockUploadDetails:
        // response.data,
        successMessage: `POS Stock Uploaded Successfully`,
      });
      this.getAllPosRequestsAwaitingStockUpload();
    }
    // }
  }

  handleSearchRequest() {
    this.getAllPosRequestsAwaitingStockUpload(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType === "view") {
      this.setState({
        posRequestAwaitingStockUploadDetails: requests,
        settlement: {
          parties: [],
        },
      });
    }
    this.setState({
      requestId: requests.requestId,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <ContentHead
          title={"View POS Requests Awaiting Stock Upload"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        {this.state.configured ? (
          <>
            <Notification
              ref={this.notificationSectionRef}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
            <ProtectedComponent
              requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
              permissionDeniedContent={this.permissionDeniedContent}
            >
              <GridLayout>
                <PosRequestsAwaitingStockUploadSearch
                  searchParameters={this.state.searchParameters}
                  agentName={this.state.requests.agentName}
                  updateSearchParameters={this.updateSearchParameters}
                  handleSearchRequest={this.handleSearchRequest}
                  preloadDate={
                    retrieveOtherInformation("parentReference")
                      ? false
                      : !JSON.parse(
                          process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA
                        )
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                <PosRequestsAwaitingStockUploadTable
                  pages={this.state.pages}
                  getAllPosRequestsAwaitingStockUpload={
                    this.getAllPosRequestsAwaitingStockUpload
                  }
                  data={this.state.requests}
                  startPage={this.state.startPage}
                  loading={this.state.isLoading}
                  noDataText={this.state.tableMessage}
                  dataCount={this.state.dataCount}
                  page={this.state.pageNum}
                  pageSize={this.state.pageSize}
                  canRemapDevice={CAN_REMAP_DEVICE}
                  searchParameters={this.state.searchParameters}
                  responseCode={this.state.searchParameters.responseCode}
                  startDate={this.state.searchParameters.startDate}
                  canView={CAN_GET_DEVICES_BY_UUID}
                  getClickedAction={this.getClickedAction}
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                  canLoadData={this.state.canLoadData}
                />
                <PosRequestsAwaitingStockUploadDetailsModal
                  posRequestAwaitingStockUploadDetails={
                    this.state.posRequestAwaitingStockUploadDetails
                  }
                  loading={this.state.isLoading}
                  uploadPosRequestsAwaitingStockUpload={
                    this.uploadPosRequestsAwaitingStockUpload
                  }
                />
              </GridLayout>
            </ProtectedComponent>
          </>
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
