import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";
import Select from '../../../../../../components/select';


export default class ApprovalSearch extends React.Component {
  state = {
    searchParameters: [],
    actionTypes: [
      { label: "All", value: "All" },
      { label: "Create", value: "CREATE" },
      { label: "Edit", value: "EDIT" },
      { label: "Delete", value: "DELETE" },
      { label: "Enable", value: "ENABLE" },
      { label: "Disable", value: "DISABLE" },
    ],
    statuses: [
      { label: "All", value: "All" },
      { label: "APPROVED & CLOSED", value: "APPROVED_CLOSED" },
      { label: "DECLINED & CLOSED", value: "DECLINED_CLOSED" },
      { label: "IN PROGRESS", value: "INPROGRESS" },
    ],
  };

  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest(this.state.searchParameters);
  }

  render() {
    return (
      <React.Fragment>
        <div class="search-params">
          <form onSubmit={this.handleSearchRequest}>
            <div class="search-components">
              <div class="search-component">
                <label class="search-label">Action Types:</label>
                <Select
                options={this.state.actionTypes}
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      actionType: event.value,
                    },
                  })
                }
                defaultValue={this.state.searchParameters.actionType}
              />
            
              </div>
              <div class="search-component">
                <label class="search-label">Status:</label>
                <Select
                options={this.state.statuses}
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      status: event.value,
                    },
                  })
                }
                defaultValue={this.state.searchParameters.status}
              />
              
              </div>
              <div class="search-component">
                <label class="search-label">Username:</label>
                <input
                  type="text"
                  placeholder="Username...."
                  class="search-input"
                  onChange={(event) =>
                    this.setState({
                      searchParameters: {
                        ...this.state.searchParameters,
                        username: event.target.value
                          ? event.target.value
                          : null,
                      },
                    })
                  }
                />
              </div>
              <div class="search-component">
                <label class="search-label">Date From</label>
                <DatePickerComponent
                  onChange={(event) =>
                    this.setState({
                      searchParameters: {
                        ...this.state.searchParameters,
                        startDate: event,
                      },
                    })
                  }
                  size={`150px`}
                  format={`dd/MM/yyyy h:mm aa`}
                  isClearable={true}
                  showTimeInput={true}
                  style={"0.4rem"}
                  textStyle={"1.0rem"}
                  iconStyle={"0.2rem"}
                  dateType={"startDate"}
                  preloadDate={false}
                />
              </div>
              <div class="search-component">
                <label class="search-label">Date To</label>
                <DatePickerComponent
                  onChange={(event) =>
                    this.setState({
                      searchParameters: {
                        ...this.state.searchParameters,
                        endDate: event,
                      },
                    })
                  }
                  size={`150px`}
                  format={`dd/MM/yyyy h:mm aa`}
                  isClearable={true}
                  showTimeInput={true}
                  disabled={
                    this.state.searchParameters.startDate ? false : true
                  }
                  minDate={new Date(this.state.searchParameters.startDate)}
                  style={"0.4rem"}
                  textStyle={"1.0rem"}
                  iconStyle={"0.2rem"}
                  dateType={"endDate"}
                  preloadDate={false}
                />
              </div>
              <div class="col-md-2 mb-3">
                <label class="search-label">&nbsp;&nbsp;</label>
                <button type="submit" class="search-button">
                  Search
                </button>
              </div>
            </div>
          </form>
          <div
            class="btn-toolbar justify-content-between"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div class="btn-group ml-12" role="group" aria-label="First group">
              {this.props.showApprovalRepush && (
                <div class="input-group">
                  <button
                    id="basic-addon1"
                    type="button"
                    class="btn btn-secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.props.closePendingApprovals()}
                  >
                    Repush
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
