import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import DeclineReasonCategoryGridScene from './scenes/decline-reason-category-grid'
import BaseScene from '../../../../../../../components/scenes/component';

export default class DeclineReasonCategoryScene extends BaseScene {
  sceneName = 'decline-reason-categories'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <Route exact path='/home/application-management/configuration/decline-reason-categories' component={DeclineReasonCategoryGridScene} />
    </Router>
  }
}
