import React from 'react';
import { AccessDenied } from '../../../../../../components/access-denied';
import ContentHead from '../../../../../../components/content-head';
import UserForm from '../../../../../../components/forms/user-form';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../components/loader';
import Notification from '../../../../../../components/notification';
import BaseScene from '../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../constants/api';
import { CAN_UPDATE_USERS } from '../../../../../../constants/permissions';
import { CAN_UPDATE_USER, CAN_VIEW_USER } from '../../../../../../constants/permissions.js';
import { platformManagement } from '../../../../../../mixins/api';
import { retrieveSessionInformation } from '../../../../../../mixins/authentication';
import ProtectedComponent from '../../../../../../mixins/protected-component';

export default class ViewUserScreen extends BaseScene {
  requiredPermissions = [
    CAN_UPDATE_USERS
  ]

  constructor (props) {
    super(props)

    this.state = {
      canSave: false,
      canUpdate: false,
      emailIsValid: true,
      phoneIsValid: true,
      roles: [],
      userDetails: [],
      pageTitle: 'View a User',
      disabled: true,
      userFormData: [],
      showPhoneNumber: false,
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
    this.updateUserFormData = this.updateUserFormData.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
    this.getDomainRoles = this.getDomainRoles.bind(this)
  } 

  componentDidMount() {
    this.username = this.props.match.params.id
    const currentUser = retrieveSessionInformation()
    this.domainCode = currentUser.user.domainCode
    this.getUserDetails()
    this.getDomainRoles()  
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getUserDetails () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getUserByEmail(this.username)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        userDetails: response,
        userFormData: response,
        showPhoneNumber: true,
        isLoading: false,
        canUpdate: true,
      })
    }else{
      this.setState({
        userDetails: [],
        userFormData: [],
        errorMessage: response,
        showPhoneNumber: true,
        isLoading: false,
      })
    }
  }

  async getDomainRoles () {
    this.setState({
      isGetRolesLoading: true
    })
    const responseObj = await platformManagement.getRoles(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        roles: response,
        isGetRolesLoading: false
      })
    }else{
      this.setState({
        roles: [],
        isGetRolesLoading: false,
        errorMessage: response
      })
    }
  }

  async onUpdateButtonClick () {
    this.setState({
      pageTitle: 'Update User Details',
      canSave: true,
      canUpdate: false,
      disabled: false,
    })
  }

  async onCancelButtonClick () {
    this.setState({
      pageTitle: 'View User Details',
      canSave: false,
      canUpdate: true,
      disabled: true,
    })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})

    const createUserResponseObj = await platformManagement.updateUserRole(this.state.userFormData)
    const createUserResponseStatus = createUserResponseObj.status
    const createUserResponse = createUserResponseObj.response
    
    if (createUserResponseStatus === SUCCESS_STATUS) {
      if(createUserResponse.code == ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: 'Your changes to this user has been sent successfully for approval.',
          canSave: false,
          canUpdate: true,
          pageTitle: 'View a User',
          disabled: true,
        })
      }else{
        this.setState({
          isLoading: false,
          successMessage: 'User role updated successfully.',
          canSave: false,
          canUpdate: true,
          pageTitle: 'View a User',
          disabled: true,
        })
      }
    }else{
      this.setState({
        isLoading: false,
        errorMessage: createUserResponse
      })
    }
  }

  isFormValid () {
    return this.state.userDetails.roleName !== this.state.userFormData.roleName;
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  updateUserFormData (type, value) {
    this.setState({userFormData: {...this.state.userFormData, [type]: value}})
  }

  render () {
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_USER]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit Role</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_USER]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_USER]}><button type="button" class="btn btn-primary" disabled={!this.isFormValid()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_USER]}>      
        <GridLayout>
          <UserForm
            updateSetState={this.updateSetState}
            onChange={this.updateUserFormData}
            disabled={this.state.disabled}
            disabled2={true}
            userDetails={this.state.userDetails}
            userFormData={this.state.userFormData}
            roles={this.state.roles}
            emailIsValid={this.state.emailIsValid}
            showPhoneNumber={this.state.showPhoneNumber}
            isGetRolesLoading={this.state.isGetRolesLoading}
            getRoles={this.getDomainRoles}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
