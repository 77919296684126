import qs from "querystring";
import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { CAN_VIEW_TRANSACTION } from "../../../../../../../constants/permissions.js";
import { reconReport } from "../../../../../../../mixins/api";
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from "../../../../../../../utils/formatter";
import {
  getDateByType,
  getTransactionTypeInt,
} from "../../../../../../../utils/helper";
import ReconDetailsModal from "../../components/recon-detail-modal";
import ReconDownloadableFilesModal from "../../components/recon-downloadable-list-modal";
import ReconHistoryTable from "../../components/recon-history-table";
import ReconSearch from "../../components/recon-search";

export default class ReconReportGridScene extends BaseScene {
  sceneName = "recon-report";

  transactionDetailsSectionRef = React.createRef(null);
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      transactionDetails: [],
      commissionAmount: "",
      downloadableFiles: [],
      isLoading: false,
      searchParameters: {
        startDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
        endDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("endDate")
          : null,

        transactionType: "",
        agentId: "",
        reconciliationType: "",
        transactionStatus: "",
        transRef: "",
      },
      pages: 1,
      pageNum: 0,
      pageSize: 20,
      canLoadData: retrieveOtherInformation("parentReference")
        ? true
        : getTransactionTypeInt(
            qs.parse(window.location.search)["?transactionType"]
          )
        ? true
        : JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      checkedReports: [],
      requeryButton: false,
      settlement: {
        parties: [],
      },
      tableMessage: "No Reconciliation report found",
    };

    this.getAllReconReports = this.getAllReconReports.bind(this);
    this.generateDownloadableReport = this.generateDownloadableReport.bind(
      this
    );
    this.getDownloadableReportFiles = this.getDownloadableReportFiles.bind(
      this
    );
    this.downloadReportFile = this.downloadReportFile.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.selectAllTransactions = this.selectAllTransactions.bind(this);
    this.getSelectedTransactions = this.getSelectedTransactions.bind(this);
    this.handleDuplicateTransactions = this.handleDuplicateTransactions.bind(
      this
    );
    this.requerySingleReport = this.requerySingleReport.bind(this);
    this.requeryBulkReport = this.requeryBulkReport.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getAllReconReports(pageNum, pageSize) {
    const {
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      endDate,
      startDate,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDateTo = endDate && formatBackEndDateTimeTo(endDate);
    const formattedStartDateFrom =
      startDate && formatBackEndDateTimeFrom(startDate);

    this.setState({
      pageNum,
      pageSize,
      isLoading: true,
    });
    const { status, response, count } = await reconReport.getReconBySearch(
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,

      pageNum,
      pageSize,
      formattedStartDateFrom,
      formattedEndDateTo
    );
    if (status === SUCCESS_STATUS) {
      if (response.length === 0) {
        this.setState({
          transactions: [],
          dataCount: 0,
          isLoading: false,
          tableMessage: "No Reconciliation report found",
          successMessage: "No Reconciliation report found",
        });
      } else {
        this.setState({
          transactions: response ? response : [],
          pages: Math.ceil(count / this.state.pageSize),
          pageSize: pageSize,
          pageNum: pageNum,
          dataCount: count,
          isLoading: false,
          commissionAmount: response[0].commissionAmount,
        });
      }
    } else {
      this.setState({
        transactions: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No Reconciliation report found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async getDownloadableReportFiles() {
    this.setState({
      isLoading: true,
      downloadableFiles: [],
    });
    const {
      status,
      response,
      count,
    } = await reconReport.getDownloadableReportFiles();
    if (status === SUCCESS_STATUS) {
      this.setState({
        downloadableFiles: response ? response : [],
        dataCount: count,
        isLoading: false,
      });
    } else {
      this.setState({
        downloadableFiles: [],
        isLoading: false,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async downloadReportFile(fileName) {
    this.setState({
      isLoading: true,
    });
    const { status, response, count } = await reconReport.downloadReportFile(
      fileName
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: "File Downloaded Successfully!",
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async generateDownloadableReport() {
    const {
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      pageNum,
      pageSize,
      endDate,
      startDate,
    } = this.state.searchParameters;

    const formattedEndDateTo = endDate && formatBackEndDateTimeTo(endDate);
    const formattedStartDateFrom =
      startDate && formatBackEndDateTimeFrom(startDate);

    this.setState({
      isLoading: true,
    });
    const { status, response } = await reconReport.generateDownloadableReport(
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      pageNum,
      pageSize,
      formattedStartDateFrom,
      formattedEndDateTo
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage:
          "Report creation has been submitted. Please check the download link after few minutes",
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  handleSearchRequest() {
    const {
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      endDate,
      startDate,
    } = this.state.searchParameters;
    this.getAllReconReports(
      this.state.pageNum,
      this.state.pageSize,
      transactionType,
      agentId,
      reconciliationType,
      transactionStatus,
      transRef,
      endDate,
      startDate
    );
  }

  updateSearchParameters(type, value) {
    if (value == 0 || value == "All" || !value) {
      this.setState({
        searchParameters: { ...this.state.searchParameters, [type]: "" },
      });
      console.log("Here");

      return;
    }

    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  selectAllTransactions(isChecked) {
    let checkedReports = this.state.checkedReports;
    const transactions = this.state.transactions.map((value) => {
      value.checked =
        value.status === "UNPAID" ||
        value.status === "Comm. Not In Settlement" ||
        value.status === "RECEIVED_AND_SETTLED" ||
        value.status == "FAILED" ||
        value.status == "SUCCESS" ||
        value.status == "NOTIFIED" ||
        value.status == "UNDEFINED" ||
        value.status == "PENDING" ||
        value.status == "RECEIVED_BUT_UNSETTLED" ||
        value.status == "NOT_RECEIVED"
          ? isChecked
          : false;
      const transRefExist = this.handleDuplicateTransactions(
        checkedReports,
        value.transactionRef
      );
      if (
        (isChecked && value.status == "UNPAID") ||
        (isChecked && value.status == "Comm. Not In Settlement") ||
        (isChecked && value.status == "RECEIVED_AND_SETTLED")
      ) {
        if (!transRefExist) {
          checkedReports.push(value.uniqueReference);
        }
      } else {
        checkedReports = checkedReports.filter(function (item) {
          return item !== value.uniqueReference;
        });
      }
      return value;
    });

    this.setState({
      transactions,
      checkedReports,
    });
  }

  getSelectedTransactions(isChecked, uniqueRef) {
    let checkedReports = this.state.checkedReports;
    const reports = this.state.transactions.map((value) => {
      if (value.uniqueReference === uniqueRef) {
        value.checked = isChecked;
        const transRefExist = this.handleDuplicateTransactions(
          checkedReports,
          uniqueRef
        );
        if (isChecked) {
          if (!transRefExist) {
            checkedReports.push(uniqueRef);
          }
        } else {
          checkedReports = checkedReports.filter(function (item) {
            return item !== uniqueRef;
          });
        }
      }
      return value;
    });
    this.setState({
      reports,
      checkedReports,
    });
    if (checkedReports.length > 0) {
      this.setState({
        requeryButton: true,
      });
    }
  }

  handleDuplicateTransactions(reports, uniqueRef) {
    if (reports.indexOf(uniqueRef) == -1) {
      return false;
    } else {
      return true;
    }
  }

  async requerySingleReport(uniqueReference) {
    this.setState({ isLoading: true });
    const responseObj = await reconReport.requerySingleReport(uniqueReference);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Your request has been processed successfully!`,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    setTimeout(() => {
      this.getAllReconReports();
      window.scrollTo(0, this.notificationSectionRef.offsetTop);
    }, 2000);
  }

  async requeryBulkReport() {
    this.setState({ isLoading: true });
    const responseObj =
      this.state.checkedReports.length > 0
        ? await reconReport.requerySingleReport(this.state.checkedReports)
        : {
            status: "ERROR",
            response: {
              description: "Check out the Report(s) you want to bulk re-query",
            },
          };
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Your bulk request has been processed successfully!`,
        isLoading: false,
        checkedReports: [],
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    setTimeout(() => {
      this.getAllReconReports();
      window.scrollTo(0, this.notificationSectionRef.offsetTop);
    }, 1500);
  }

  getClickedAction(transaction, actionType) {
    if (actionType == "view") {
      this.setState({
        transactionDetails: transaction,
      });
    }
    this.setState({
      actionType,
    });
  }

  isFormValid() {
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`Do you want to resolve this transaction ${this.state.transRef} as`}</p>
        <div class="form-group row">
          <div class="col-lg-5">
            <div class="col-12">
              <div class="kt-radio-inline">
                <label class="kt-radio">
                  <input
                    autoComplete="off"
                    type="radio"
                    name="gender"
                    value="1"
                    checked={this.state.desiredStatus == 1}
                    onChange={(event) =>
                      this.setState({ desiredStatus: event.target.value })
                    }
                  />{" "}
                  Success
                  <span></span>
                </label>
                <label class="kt-radio">
                  <input
                    autoComplete="off"
                    type="radio"
                    name="gender"
                    value="0"
                    checked={this.state.desiredStatus == 0}
                    onChange={(event) =>
                      this.setState({ desiredStatus: event.target.value })
                    }
                  />{" "}
                  Fail
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <ContentHead
          title={"Reconciliation Report"}
          filterPermissions={[CAN_VIEW_TRANSACTION]}
          totalCount={56}
          actionButtonText={"Upload File"}
          actionButtonUrl={"/home/recon-report/upload-reconReport"}
        />

        <Notification
          ref={this.notificationSectionRef}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <ReconSearch
              serviceTypes={this.state.serviceTypes}
              searchParameters={this.state.searchParameters}
              agentName={this.state.transactions.agentName}
              updateSearchParameters={this.updateSearchParameters}
              generateDownloadableReport={this.generateDownloadableReport}
              getDownloadableReportFiles={this.getDownloadableReportFiles}
              serviceOptions={this.state.serviceOptions}
              getServiceById={this.getServiceById}
              getServiceTypeById={this.getServiceTypeById}
              isGetServiceTypeLoading={this.state.isGetServiceTypeLoading}
              isServiceLoading={this.state.isServiceLoading}
              isServiceOptionLoading={this.state.isServiceOptionLoading}
              services={this.state.services}
              handleSearchRequest={this.handleSearchRequest}
              preloadDate={
                retrieveOtherInformation("parentReference")
                  ? false
                  : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
              }
              desiredStatus={
                this.state.desiredStatus ? this.state.desiredStatus : "default"
              }
              showRepush={true}
              commissionAmount={this.state.commissionAmount}
              requeryBulkTransactions={this.requeryBulkReport}
              checkedReports={this.state.checkedReports}
              requeryButton={this.state.requeryButton}
            />

            <ReconHistoryTable
              pages={this.state.pages}
              getAllReconReports={this.getAllReconReports}
              commissionAmount={this.state.commissionAmount}
              data={this.state.transactions}
              startPage={this.state.startPage}
              viewMore={this.getAllReconReports}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              page={this.state.pageNum}
              pageSize={this.state.pageSize}
              searchParameters={this.state.searchParameters}
              responseCode={this.state.searchParameters.responseCode}
              startDate={this.state.searchParameters.startDate}
              domainCode={this.state.searchParameters.domainCode}
              canView={CAN_VIEW_TRANSACTION}
              getClickedAction={this.getClickedAction}
              desiredStatus={
                this.state.desiredStatus ? this.state.desiredStatus : "default"
              }
              selectAllTransactions={this.selectAllTransactions}
              getSelectedTransactions={this.getSelectedTransactions}
              requerySingleReport={this.requerySingleReport}
              requeryBulkReport={this.requerySingleReport}
              canLoadData={this.state.canLoadData}
            />

            <ReconDetailsModal
              transactionDetails={this.state.transactionDetails}
            />
            <ReconDownloadableFilesModal
              downloadableFiles={this.state.downloadableFiles}
              downloadReportFile={this.downloadReportFile}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
