import React from 'react'
import Select from '../../../../../../components/select'
import { convertToMajor, convertToMinor } from '../../../../../../utils/converter'
import { absoluteValue, replaceSpaceWithUnderScore, toDecimal } from '../../../../../../utils/helper'

export default class FeeForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Name *:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.feeFormData.name} class="form-control" placeholder="Name" disabled={this.props.disabled} defaultValue={this.props.feeFormData.name} onChange={event => this.props.updateFeeFormData('name', replaceSpaceWithUnderScore(event.target.value))} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Description *:</label>
                            <textarea autoComplete="off" class="form-control" style={{height: '100px', resize: 'none'}} maxLength="100" name="description" disabled={this.props.disabled} defaultValue={this.props.feeFormData.description} onChange={event => this.props.updateFeeFormData('description', event.target.value)}></textarea>
                            <span class="form-text text-muted">Maximum character 100</span>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Borne By *:<br></br></label>
                            <Select 
                                options={this.props.billers.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                onChange={event => this.props.updateFeeFormData('borneBy', event.value)}
                                disabled={this.props.disabled}
                                defaultValue={this.props.feeFormData.borneBy}
                                minHeight={40}
                            />
                            {/* <select autoComplete="off" type="text" class="form-control" placeholder="Borne By" disabled={this.props.disabled} onChange={event => this.props.updateFeeFormData('borneBy', event.target.value)}>
                                <option disabled selected>Select</option>
                                {this.props.billers.map(value => <option value={value.id} selected={this.props.feeFormData.borneBy === value.id ? 'selected' : ''}>{value.name}</option>)}
                            </select> */}
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Fee Category *:</label>
                            <div class="kt-radio-inline">
                                <label class="kt-radio">
                                    <input autoComplete="off" type="checkbox" disabled={this.props.disabled} checked={this.props.feeFormData.banded} name="feecategory" onChange={event => {
                                        this.props.updateFeeFormData('banded', !this.props.feeFormData.banded)
                                        //this.props.toggleBanded(!this.props.feeFormData.banded)
                                    }} /> Banded
                                    <span></span>
                                </label>
                            </div>
                        </div>
        
                        {!this.props.feeFormData.banded && <div>
                            <div class="form-group col-lg-6">
                                <label>Flat Amount <span>(&#8358;)</span>*:<br></br></label>
                                <input autocomplete="off" disabled={this.props.disabled} value={convertToMajor(this.props.feeFormData.flatAmount)} type="number" min="1" class="form-control" placeholder="2000" onChange={event => this.props.updateFeeFormData('flatAmount', event.target.value === undefined ? 0 : absoluteValue(convertToMinor(event.target.value)))} />
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Percentage Amount <span>(%)</span> *:<br></br></label>
                                <input autocomplete="off" disabled={this.props.disabled} value={this.props.feeFormData.percentageAmount} type="number" min="1" class="form-control" placeholder="20" onChange={event => this.props.updateFeeFormData('percentageAmount', event.target.value === "" ? 0 : absoluteValue(toDecimal(event.target.value, 2)))} />
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Minimum Cap <span>(&#8358;)</span> *:<br></br></label>
                                <input autoComplete="off" disabled={this.props.disabled} value={convertToMajor(this.props.feeFormData.minimumCap)} type="number" min="1" class="form-control" placeholder="10" onChange={event => {
                                    this.props.updateFeeFormData('minimumCap', event.target.value == undefined ? 0 : absoluteValue(convertToMinor(event.target.value)))
                                    this.props.updateSetState('isMaximumCapValid', !this.props.validateCapField(convertToMinor(event.target.value === undefined ? 0 : event.target.value), this.props.feeFormData.maximumCap))
                                }} />
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Maximum Cap <span>(&#8358;)</span> *:<br></br></label>
                                <input autocomplete="off" disabled={this.props.disabled} value={convertToMajor(this.props.feeFormData.maximumCap)} type="number" min="1" class="form-control" placeholder="40" onChange={event => {
                                    this.props.updateFeeFormData('maximumCap', event.target.value === undefined ? 0 : absoluteValue(convertToMinor(event.target.value)))
                                    this.props.updateSetState('isMaximumCapValid', !this.props.validateCapField(this.props.feeFormData.minimumCap, convertToMinor(event.target.value === undefined ? 0 : event.target.value)))
                                }} />
                                {this.props.isMaximumCapValid && <span style={{color: 'red'}}>Maximum cap must be greater than minimum cap</span>}
                            </div>
                        </div>}
        

                        {this.props.feeFormData.banded && <div>
                            <div class="form-group col-lg-12">
                                <label>Bands</label>
                                <table class="table table-striped table-hover table-bordered" id="sample_editable_1">
                                    <thead>
                                        <tr>
                                            <th>Minimum Band <span style={{fontSize: '10px'}}>(&#8358;)</span></th>
                                            <th>Maximum Band <span style={{fontSize: '10px'}}>(&#8358;)</span></th>
                                            <th>Flat Amount <span style={{fontSize: '10px'}}>(&#8358;)</span></th>
                                            <th>Percentage Amount <span style={{fontSize: '10px'}}>(%)</span></th>
                                            <th>Minimum Cap <span style={{fontSize: '10px'}}>(&#8358;)</span></th>
                                            <th>Maximum Cap <span style={{fontSize: '10px'}}>(&#8358;)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.feeFormData.feeBands && this.props.feeFormData.feeBands.map((bound, index) => {
                                            return (
                                                <tr>
                                                    <td>{toDecimal(convertToMajor(bound.minimumBand), 2)}</td>
                                                    <td>{bound.maximumBand === 0 ? 'Above' : toDecimal(convertToMajor(bound.maximumBand), 2)}</td>
                                                    <td>{toDecimal(convertToMajor(bound.flatAmount), 2)}</td>
                                                    <td>{toDecimal(bound.percentageAmount ? bound.percentageAmount : 0, 2)}</td>
                                                    <td>{toDecimal(convertToMajor(bound.minimumCap), 2)}</td>
                                                    <td>{toDecimal(convertToMajor(bound.maximumCap), 2)}</td>
                                                    {!this.props.disabled && <td>{((this.props.feeFormData.feeBands.length - 1) === index) && <a onClick={() => this.props.removeBoundClick(bound.id)} style={{cursor: 'pointer'}}>Delete</a>}</td>}
                                                </tr>
                                            )
                                        })}
                                        {this.props.showBandsInput &&
                                            <tr>
                                                <td>
                                                    <input type="number" disabled value={toDecimal(convertToMajor(this.props.feeBandsFormData.boundedMinBound), 2)} class="form-control" onChange={event => {
                                                     this.props.updateFeeBandsFormData('boundedMinBound', convertToMinor(event.target.value))
                                                     this.props.updateSetState('isBoundedMaxBoundValid', !this.props.validateMaxField(convertToMinor(event.target.value), this.props.feeBandsFormData.boundedMaxBound))   
                                                    }}/>
                                                </td>
                                                <td>
                                                    <input type="text" list="idtypes" defaultValue={this.props.feeBandsFormData.boundedMaxBound} class="form-control" onChange={event => {
                                                        this.props.updateFeeBandsFormData('boundedMaxBound', event.target.value === 'Above' ? 0 : convertToMinor(event.target.value))
                                                        this.props.updateSetState('isBoundedMaxBoundValid', !this.props.validateMaxField(this.props.feeBandsFormData.boundedMinBound, event.target.value === 'Above' ? 'Above' : convertToMinor(event.target.value)))
                                                    }} />
                                                    <datalist id="idtypes">
                                                        <option value="Above" />
                                                    </datalist>
                                                    {this.props.isBoundedMaxBoundValid && <span style={{color: 'red', fontSize: '10px'}}>Must be greater than minimum bound</span>}
                                                </td>
                                                <td><input type="number" defaultValue={this.props.feeBandsFormData.boundedFlatAmount} class="form-control" onChange={event => this.props.updateFeeBandsFormData('boundedFlatAmount', convertToMinor(event.target.value === undefined ? 0 : event.target.value))}/></td>
                                                <td><input type="number" defaultValue={this.props.feeBandsFormData.boundedPercentageAmount} class="form-control" onChange={event => this.props.updateFeeBandsFormData('boundedPercentageAmount', (event.target.value === undefined ? 0 : toDecimal(event.target.value, 2)))}/></td>
                                                <td>
                                                    <input type="number" defaultValue={this.props.feeBandsFormData.boundedMinCap} class="form-control" onChange={event => {
                                                        this.props.updateFeeBandsFormData('boundedMinCap', convertToMinor(event.target.value == undefined ? 0 : event.target.value))
                                                        this.props.updateSetState('isBoundedMaxCapValid', !this.props.validateCapField(convertToMinor(event.target.value === undefined ? 0 : event.target.value), this.props.feeBandsFormData.boundedMaxCap))
                                                    }}/>
                                                </td>
                                                <td>
                                                    <input type="number" defaultValue={this.props.feeBandsFormData.boundedMaxCap} class="form-control" onChange={event => { 
                                                        this.props.updateFeeBandsFormData('boundedMaxCap', convertToMinor(event.target.value === undefined ? 0 : event.target.value))
                                                        this.props.updateSetState('isBoundedMaxCapValid', !this.props.validateCapField(this.props.feeBandsFormData.boundedMinCap, convertToMinor(event.target.value === undefined ? 0 : event.target.value)))
                                                    }}/>
                                                    {this.props.isBoundedMaxCapValid && <span style={{color: 'red', fontSize: '10px'}}>Must be greater than minimum cap</span>}
                                                </td>
                                                <td><a hidden={!this.props.validateBoundedData()} onClick={this.props.addBoundClick} style={{cursor: 'pointer', top: '500px'}}><span style={{top: '500px'}}>Save</span></a></td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}