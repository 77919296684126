import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_CREATE_REGION } from '../../../../../../../constants/permissions.js';
import Countries from '../../../../../../../fixtures/countries.json';
import { platformManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { getLgasByStateId, getStatesByCountryId } from '../../../../../../../utils/helper';
import RegionMappingForm from '../../components/region-mapping-form';

export default class RegionMappingScene extends BaseScene {
    constructor (props) {
        super(props)

        this.state = {
            canSave: null,
            countries: [],
            regionMappingFormData: {
                lga: []
            },
            states: [],
            regions: [],
            lgas: []
        }

        this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
        this.updateRegionMappingFormData = this.updateRegionMappingFormData.bind(this)
        this.getStates = this.getStates.bind(this)
        this.getLgas = this.getLgas.bind(this)
        this.checkAllLgas = this.checkAllLgas.bind(this)
        this.uncheckAllLgas = this.uncheckAllLgas.bind(this)
        this.updateLgaStatus = this.updateLgaStatus.bind(this)
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    }

    componentDidMount() {
        this.getCountries()
        this.getRegions()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
            setTimeout(() => {
                this.setState({errorMessage: null})
            }, 5000)
        }
        if (this.state.successMessage) {
            setTimeout(() => {
                this.setState({successMessage: null})
            }, 5000)
        }
    }

    async onSaveButtonClick () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.mapRegionToLga(this.state.regionMappingFormData)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                successMessage: 'Region mapped to Lga successfully',
                isLoading: false,
            })
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false
            })
        }
    }

    async getCountries () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getCountries()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                countries: response,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                countries: Countries,
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getRegions () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getRegions()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                regions: response.content,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                regions: [],
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getStates (countryId) {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getStatesByCountryId(countryId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                states: response,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                states: getStatesByCountryId(countryId),
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getLgas (stateId) {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getLgasByStateId(stateId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                lgas: response,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                lgas: getLgasByStateId(stateId),
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    checkAllLgas () {
        const selectedLgas = []
        const checkedLgas = this.state.lgas.map(value => {
            value.checked = true
            selectedLgas.push(value.id)
            return value
        })
        this.setState({
            lgas: checkedLgas,
            regionMappingFormData: {...this.state.regionMappingFormData, lga: selectedLgas}
        })
    }

    uncheckAllLgas () {
        let selectedLgas = this.state.regionMappingFormData.lga
        const uncheckedLgas = this.state.lgas.map(value => {
            value.checked = false
            selectedLgas =  selectedLgas.filter(item => item !== value.id)
            return value
        })
        this.setState({
            lgas: uncheckedLgas,
            regionMappingFormData: {...this.state.regionMappingFormData, lga: selectedLgas}
        })
    }

    updateLgaStatus (lgaId) {
        let selectedLgas = this.state.regionMappingFormData.lga
        const lgas = this.state.lgas.map(value => {
            if(value.id === lgaId) {
                value.checked = !value.checked
                if(value.checked) {
                    selectedLgas.push(value.id)
                }else{
                    selectedLgas =  selectedLgas.filter(item => item !== value.id)
                }
            }
            return value
        })
        
        this.setState({
            lgas: lgas,
            regionMappingFormData: {...this.state.regionMappingFormData, lga: selectedLgas}
        })
    }

    mapLgaToRegion (lgaDetails) {
        const lga = {
            id: lgaDetails.id,
            name: lgaDetails.name,
            code: lgaDetails.code
        }

        // this.setState({
        //     regionMappingFormData: {...this.state.regionMappingFormData, [type]: value}
        // })
    }

    unmapLgaToRegion () {

    }

    isFormValid () {
        return Boolean(!this.state.regionMappingFormData.regionId || !this.state.regionMappingFormData.stateId || !this.state.regionMappingFormData.countryId)
    }

    updateRegionMappingFormData (type, value) {
        this.setState({regionMappingFormData: {...this.state.regionMappingFormData, [type]: value}})
    }

    render () {

    // const ctaWidgets = <React.Fragment>
    //     <ProtectedComponent requiredPermissions={[CAN_CREATE_REGION]}><button type="button" disabled={this.isFormValid()} class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
    // </React.Fragment>

    return <React.Fragment>
        <Loader 
            isLoading={this.state.isLoading}
        />
        <Notification 
            successMessage={this.state.successMessage}
            errorMessage={this.state.errorMessage}
        />
        <ContentHead title={"Region"} withoutSearch subtitle="Region To LGA Mapping" /*ctaWidgets={ctaWidgets}*/ />
        <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_CREATE_REGION]}>
            <GridLayout>
                <RegionMappingForm 
                    onChange={this.updateRegionMappingFormData}
                    regions={this.state.regions}
                    countries={this.state.countries}
                    states={this.state.states}
                    lgas={this.state.lgas}
                    getLgas={this.getLgas}
                    getStates={this.getStates}
                    checkAllLgas={this.checkAllLgas}
                    uncheckAllLgas={this.uncheckAllLgas}
                    updateLgaStatus={this.updateLgaStatus}
                    onSaveButtonClick={this.onSaveButtonClick}
                    regionMappingFormData={this.state.regionMappingFormData}
                    disabled={false}
                    hideCheck={false}
                />
            </GridLayout>
        </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
