import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import { formatDate } from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";

export default class KycUpdateTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
            ? "auto"
            : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getSerialNumber(index) {
    return this.props.pageSize * (this.props.page - 1) + index;
  }

  render() {
    const agentData = this.props.data && this.props.data;
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "S/N",
        accessor: "index",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 40,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Kyc Category",
        accessor: "kycType",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value.toUpperCase()}</div>
        ),
        width: 150,
      },
      {
        Header: "Requester Name",
        accessor: "initiator",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
        width: 120,
      },
      {
        Header: "Batch ID",
        accessor: "batchId",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
        width: 200,
      },
      {
        Header: "Date of Request",
        accessor: "createdDate",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Approver's Name",
        accessor: "approver",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value &&
              props.value.charAt(0).toUpperCase() +
              props.value.slice(1).toLowerCase()}
          </div>
        ),
        width: 150,
      },
      {
        Header: "Date of Approval",
        accessor: "approvalDate",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Total Count",
        accessor: "numberOfRecords",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData && agentData[props.index].numberOfRecords}
          </div>
        ),
        resizable: false,
        // width: 150,
      },
      {
        Header: "Number of Successes",
        accessor: "successful",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData && agentData[props.index].successful}
          </div>
        ),
        resizable: false,
        // width: 150,
      },
      {
        Header: "Number of Failed",
        accessor: "failed",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData && agentData[props.index].failed}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Status",
        accessor: "status",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData && agentData[props.index].status}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Action",
        accessor: "agentId",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { whiteSpace: "unset" },
        Cell: ({ original }) => (
          <div
            className="dropdown"
            style={{
              textAlign: "center",
              opacity: original.status === "COMPLETED" ? 1 : 0.6,
            }}
          >
            <a
              href={original.status === "COMPLETED" ? "#" : undefined}
              className="btn btn-hover-brand btn-icon btn-pill"
              data-toggle={original.status === "COMPLETED" ? "dropdown" : undefined}
              onClick={(e) => {
                if (original.status !== "COMPLETED") {
                  e.preventDefault();
                }
              }}
              style={{
                cursor: original.status === "COMPLETED" ? "pointer" : "not-allowed",
                color: original.status === "COMPLETED" ? "#A7ABC3" : "transparent",
                backgroundColor: original.status === "COMPLETED" ? undefined : "transparent",
              }}
            >
              <i className="la la-ellipsis-v"></i>
            </a>
            {original.status === "COMPLETED" && (
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  onClick={() => {
                    const fileUrl = original && original.processedFileUrl;
                    if (fileUrl) {
                      const link = document.createElement("a");
                      link.href = fileUrl;
                      link.download = fileUrl.split("/").pop();
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } else {
                      console.error("File URL is missing");
                    }
                  }}
                  className="dropdown-item"
                  style={{
                    color: "#A7ABC3",
                    cursor: "pointer",
                  }}
                >
                  <i className="la la-lock"></i>
                  Download output file
                </a>
              </div>
            )}
          </div>
        ),
        width: 60,
      }
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={AgentDetailsExportSerializer.reverseAgentDetails(agentData)}
          headers={AgentDetailsExportSerializer.headerExport()}
          fileName={"kyc update"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAgents}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          downloadLink={this.props.downloadAgentData}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
            justifyContent: "center",
            width: "100%",
          }}
          data={agentData && agentData}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={
            agentData && agentData.length > 0 ? 10 : 10
          }
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAgents(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
              justifyContent: "center",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              justifyContent: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
