import React from "react";
import { AccessDenied } from "../../../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS
} from "../../../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE
} from "../../../../../../../../../constants/permissions.js";
import {
  formatBackEndDateTimeFrom, formatBackEndDateTimeTo
} from "../../../../../../../../../utils/formatter";
import {
  getDateByType
} from "../../../../../../../../../utils/helper";
import RemapPosHistorySearch from "../../components/remap-pos-history-search";
import RemapPosHistoryTable from "../../components/remap-pos-history-table";
// import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import {
  remapPosHistory
} from "../../../../../../../../../mixins/api";
import {
  deleteOtherInformation, retrieveOtherInformation
} from "../../../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../../../mixins/protected-component";

export default class RemapPosHistoryGridScene extends BaseScene {
  sceneName = "remap-pos-history";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      posRequestDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        approvalStatus: "",
        serialNo: "",
      },
      pages: 1,
      pageSize: 20,
      pageNumber: 1,
      tableMessage: "",
      configured: true,
    };

    this.getAllRemapHistory = this.getAllRemapHistory.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  componentDidMount() {
    this.getAllRemapHistory()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllRemapHistory(pageNumber, pageSize) {
    const {
      serialNo,
      toDate,
      fromDate,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDate =
      toDate && `${formatBackEndDateTimeTo(toDate, true)}.747`;
    const formattedStartDate =
      fromDate && `${formatBackEndDateTimeFrom(fromDate, true)}.747`;
    console.log(formattedEndDate, "and", formattedStartDate);

    this.setState({
      pageNumber,
      pageSize,
      isLoading: true,
    });

    const { status, response, count } = await remapPosHistory.getAllRemapHistoryBySearch(
      pageNumber,
      pageSize,
      serialNo,
      formattedEndDate,
      formattedStartDate,
    );
    if (status === SUCCESS_STATUS) {
    
      this.setState({
        requests: response ? response.data : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        tableMessage: !count || count === 0 ? "No Remap History found" : "",
      });
    } else {
      this.setState({
        requests: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        isLoading: false,
        tableMessage: "No Remap History found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }


  handleSearchRequest() {
    const {
      mobilePhone,
      toDate,
      fromDate,
      approvalStatus,
    } = this.state.searchParameters;
    this.getAllRemapHistory(
      this.state.pageNumber,
      this.state.pageSize,
      mobilePhone,
      toDate,
      fromDate,
      approvalStatus
    );
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType === "view") {
      this.getPosRequestDetails(requests.id);
      this.setState({
        requestDetails: requests,
        settlement: {
          parties: [],
        },
      });
    }
    this.setState({
      requestId: requests.requestId,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    

    return (
      <React.Fragment>
        {/* <ContentHead
          title={"Remap POS History"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        {this.state.configured ? (
          <>
            <Notification
              ref={this.notificationSectionRef}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
            <ProtectedComponent
              requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
              permissionDeniedContent={this.permissionDeniedContent}
            >
              <GridLayout>
                <RemapPosHistorySearch
                  searchParameters={this.state.searchParameters}
                  agentName={this.state.requests.agentName}
                  updateSearchParameters={this.updateSearchParameters}
                  handleSearchRequest={this.handleSearchRequest}
                  preloadDate={
                    retrieveOtherInformation("parentReference")
                      ? false
                      : !JSON.parse(
                          process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA
                        )
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                <RemapPosHistoryTable
                  pages={this.state.pages}
                  getPosRequests={this.getAllPosRequests}
                  data={this.state.requests}
                  startPage={this.state.startPage}
                  viewMore={this.getPosRequestDetails}
                  loading={this.state.isLoading}
                  noDataText={this.state.tableMessage}
                  dataCount={this.state.dataCount}
                  page={this.state.pageNum}
                  pageSize={this.state.pageSize}
                  canRemapDevice={CAN_REMAP_DEVICE}
                  searchParameters={this.state.searchParameters}
                  responseCode={this.state.searchParameters.responseCode}
                  startDate={this.state.searchParameters.startDate}
                  canView={CAN_GET_DEVICES_BY_UUID}
                  getClickedAction={this.getClickedAction}
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                  canLoadData={this.state.canLoadData}
                />
               
              </GridLayout>
            </ProtectedComponent>
          </>
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
