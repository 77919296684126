import React from 'react';
import Chart from 'chart.js';

export default class DoughnutChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }
    
    componentDidUpdate() {
        this.myChart.data.labels = this.props.data.map(d => d.label);
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
        this.myChart.update();
    }
    
    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: 'doughnut',
            options: {
                cutoutPercentage: 50,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'top',
                },
                title: {
                    display: false,
                    text: 'Agent Activity'
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    intersect: false,
                    mode: 'nearest',
                    xPadding: 10,
                    yPadding: 10,
                    caretPadding: 10
                }
            },
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: [{
                    data: this.props.data.map(d => d.value),
                    backgroundColor: ['#0275d8', '#0abb87', '#f0ad4e', '#d9534f', '#5bc0de']
                }]
            }
        });
    }
    render() {
        return <canvas ref={this.chartRef} />;
    }
}