import React from 'react'
import { Redirect } from 'react-router-dom'
import BaseScene from '../../../../../../components/scenes/component';
import ContentHead from '../../../../../../components/content-head';
import { GridLayout } from '../../../../../../components/layouts/grid-layout';
import { SUCCESS_STATUS, ERROR_STATUS, ACCEPTED_REQUEST } from '../../../../../../constants/api';
import { CAN_CREATE_USERS } from '../../../../../../constants/permissions';
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import UserForm from '../../../../../../components/forms/user-form'
import { checkEmailIsValid } from '../../../../../../utils/validator'
import { platformManagement } from '../../../../../../mixins/api'
import { retrieveSessionInformation } from '../../../../../../mixins/authentication'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_CREATE_USER } from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';

export default class AddUserScreen extends BaseScene {
  requiredPermissions = [
    CAN_CREATE_USERS
  ]
  
  notificationSectionRef = React.createRef(null)
  
  constructor (props) {
    super(props)

    this.state = {
      roles: [],
      emailIsValid: false,
      phoneIsValid: false,
      userFormData: [],
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
    this.updateUserFormData = this.updateUserFormData.bind(this)
    this.getRoles = this.getRoles.bind(this)
  }

  componentDidMount() {
    const currentUser = retrieveSessionInformation()
    this.domainCode = currentUser.user.domainCode
    this.updateUserFormData('domainCode', this.domainCode)
    this.getRoles()
  } 

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getRoles () {
    this.setState({
      isGetRolesLoading: true
    })
    const responseObj = await platformManagement.getRoles()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        roles: response,
        isGetRolesLoading: false
      })
    }else{
      this.setState({
        roles: [],
        isGetRolesLoading: false,
        errorMessage: response
      })
    }
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.createUser(this.state.userFormData)
    const { status, response } = responseObj
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
    if (status === SUCCESS_STATUS) {
      if(response.code == ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: 'Your request to add a new user has been sent successfully for approval.',
          redirect: true,
        })
      }else{
        this.setState({
          isLoading: false,
          successMessage: 'User created successfully.',
          redirect: true,
        })
      }
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  updateUserFormData (type, value) {
    this.setState({userFormData: {...this.state.userFormData, [type]: value}})
  }

  updateSetState (type, value) {
    this.setState({[type]: value})
  }

  isFormValid () {
    return Boolean(!this.state.userFormData.firstName || !this.state.userFormData.lastName 
    || !this.state.userFormData.roleName || !this.state.userFormData.email 
    || !this.state.userFormData.mobileNo || !this.state.emailIsValid)
  }

  render () {

    if(this.state.redirect) {
      return <Redirect to="/home/user-management" />
    }

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_CREATE_USER]}><button type="button" disabled={this.isFormValid()} class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
        ref={this.notificationSectionRef}
      />
      <ContentHead title={"User Management"} withoutSearch subtitle="Create a User" ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_CREATE_USER]}>
        <GridLayout>
          <UserForm
            onChange={this.updateUserFormData}
            updateSetState={this.updateSetState}
            roles={this.state.roles}
            checkEmailIsValid={checkEmailIsValid}
            emailIsValid={this.state.emailIsValid}
            phoneIsValid={this.state.phoneIsValid}
            userFormData={this.state.userFormData}
            showPhoneNumber={true}
            isGetRolesLoading={this.state.isGetRolesLoading}
            getRoles={this.getRoles}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
