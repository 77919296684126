import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export default class PhoneNumberComponent extends React.Component {

    formatPhoneNumber(dialCode, phoneNumber) {
        if(phoneNumber.startsWith(dialCode)) {
            return phoneNumber
          }else{
            return `${dialCode}${phoneNumber}`
          }
    }

    render() {
        return <React.Fragment>
            <IntlTelInput
                maxLength={2}
                disabled={this.props.disabled}
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                preferredCountries={['ng']}
                onlyCountries={['ke','ng','ug']}
                style={{width: '100%', border: this.props.phoneIsValid ? '' : '2px solid', borderColor: this.props.phoneIsValid ? '' : 'red', borderRadius: this.props.phoneIsValid ? '' : '7px'}}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                    this.props.onChange && this.props.onChange(status,this.formatPhoneNumber(countryData.dialCode, value))
                }}
                defaultValue={this.props.defaultValue}
            />
        </React.Fragment>
    }
}