import React from 'react';
import { formatDate } from '../../../../../../utils/formatter';
import AuditModal from './audit-modal';

export default class AuditDetails extends React.Component {

    state = {
        auditDetails: []
    }

    getAuditDetails = (auditDetails) => {
        this.setState({auditDetails})
    }

    render () {
        return <React.Fragment>
            <div class="kt-portlet">
                <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne1" aria-controls="collapseOne1">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                        <h3 class="kt-portlet__head-title">
                            Audit Details
                        </h3>
                    </div>
                </div>
                

                <form class="kt-form kt-form--label-right" id="collapseOne1" class="collapse show" aria-labelledby="headingOne1">
                
                <div class="kt-scroll" data-scroll="true">
                        <div class="kt-timeline">
                            {this.props.auditDetails.map(detail => {
                                return (
                                    <div class="kt-timeline__item kt-timeline__item--accent">
                                        <div class="kt-timeline__item-section">
                                            <div class="kt-timeline__item-section-border">
                                                <div class="kt-timeline__item-section-icon">
                                                    <i class="flaticon2-download-symbol kt-font-success"></i>
                                                </div>
                                            </div>
                                            <span class="kt-timeline__item-datetime">{formatDate(detail.entryDate)} {detail.entryTime && `@${detail.entryTime}`}</span>
                                        </div>
                                        <span class="kt-timeline__item-text timeline-body">
                                            {detail.auditAction} action performed on {detail.auditEntity} from {detail.auditType}
                                        </span>                             
                                        <div class="kt-timeline__item-info">
                                            <strong>{detail.username}</strong><br/>
                                            {detail.roleName}<br/>
                                            {detail.ip && `IP: ${detail.ip}`}<br/><br/>
                                            <p><button type="button" class="button-primary link-button" data-toggle="modal" data-target="#audit_details_modal" onClick={() => this.getAuditDetails(detail)}>Details</button></p>
                                        </div>  
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* <AuditTable 
                        //pages={this.state.pages}
                        //getAuditReport={this.getAuditReport}
                        data={this.props.auditDetails} 
                        // startPage={this.state.startPage}
                        // loading={this.state.isLoading}
                        // noDataText={this.state.tableMessage}
                        dataCount={this.props.auditDetails.length}
                        // pageSize={this.state.pageSize}
                        // page={this.state.page}
                        // canLoadData={this.state.canLoadData}
                    /> */}
                    {/* <div class="kt-portlet__body"> */}
                            {/* {this.props.auditDetails.map(audit => 
                                <div>
                                    <h3>{audit.auditType}</h3><br/>
                                    <div class="form-group row">
                                        {Object.entries(audit).map(([key, value]) => {
                                            if(typeof value !== 'object') {
                                                return ( 
                                                    <div class="form-group col-lg-3">
                                                        <label>{splitStringByUppercase(capitalizeFirstLetter(key, false))}</label>
                                                        <input defaultValue={key == 'entryDate' ? formatDate(value) : value} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <hr/>
                                </div>
                            )} */}
                        {/* </div> */}
                    {/* </div> */}
                </form>
                <AuditModal data={this.state.auditDetails} />
            </div>
        </React.Fragment>
    }
}