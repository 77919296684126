import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import SpinnerComponent from '../../../../../../components/spinner'
import Pagination from '../../../../../../components/pagination'
import TableExtra from '../../../../../../components/table-extra'
import { SuperAgentExportSerializer } from '../../../../../../serializers'
import { getPaginationRows } from '../../../../../../utils/helper'
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class SuperAgentTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus'
  }

  zoomTableHeight = this.zoomTableHeight.bind(this)
  getSerialNumber = this.getSerialNumber.bind(this)

  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }

  getSerialNumber (index) {
    return (this.props.pageSize * (this.props.page - 1)) + index
  }

  render() {
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
      {
        Header: "S/N",
        accessor: 'id',
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: {'white-space': 'unset'},
      },{
        Header: "Super Agent ID",
        accessor: 'agentCode',
        resizable: false,
        width: 150,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },{
        Header: "Business Name",
        accessor: 'businessName',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        Cell: ({ row }) => <div class="ellipse ellipse-width word-capitalize">{row.businessName.toLowerCase()}</div>
      },{
        Header: "Business Email",
        accessor: 'businessEmail',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        Cell: ({ row }) => <div class="ellipse ellipse-width">{row.businessEmail}</div>
      },{
        Header: "Phone Number",
        accessor: 'businessPhoneNo',
        resizable: false,
        width: 150,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },{
        Header: "Registration Number",
        accessor: 'companyRegNo',
        resizable: false,
        width: 150,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },{
        Header: "Status",
        accessor: 'statusId',
        resizable: false,
        width: 80,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: 'center'
            },
          };
        },
        Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge kt-badge--inline ${props.value === 1 ? 'kt-badge--info' : props.value === 2 ? 'kt-badge--success' : props.value === 3 ? 'kt-badge--warning' : props.value === 4 ? 'kt-badge--danger' : props.value === 5 ? 'kt-badge--info' : '' } kt-badge--pill`}>{props.value === 1 ? 'New' : props.value === 2 ? 'Active' : props.value === 3 ? 'Inactive' : props.value === 4 ? 'Retired' : props.value === 5 ? 'Dormant' : ''}</span>,
      },{
        Header: "Action",
        accessor: 'serviceProviderId',
        overflow: 'visible',
        resizable: false,
        sortable: false,
        headerStyle: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "7px",
              color: "#ffffff",
              textAlign: "left",
              whiteSpace: "unset",
            },
          };
        },
        width: 60,
        Cell: ({row, original}) => <div class="dropdown">
          <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
            <i class="la la-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`super-agent-management/${row.serviceProviderId}/view`} class="dropdown-item">
              <i class="la la-eye"></i>
              View
            </Link></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.canManageDomain]}><Link hidden={original.statusId !== 4 ? '' : 'hidden'} to={`domain-management/${row.serviceProviderId}`} class="dropdown-item">
              <i class="la la-tasks"></i>
              Manage Domain
            </Link></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.canActivate]}><a hidden={original.statusId === 3 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(row.serviceProviderId,row.businessName, 'activate')} /*href={`/home/service-provider-management/${'activate'}/${row.serviceProviderId}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
              <i class="la la-toggle-on"></i>
              Activate
            </a></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.canDeactivate]}><a hidden={original.statusId === 2 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(row.serviceProviderId, row.businessName, 'suspend')} /*href={`/home/service-provider-management/${'suspend'}/${row.serviceProviderId}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
              <i class="la la-ban"></i>
              Suspend
            </a></ProtectedComponent>
            <ProtectedComponent requiredPermissions={[this.props.CanRetire]}><a hidden={original.statusId !== 4 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(row.serviceProviderId, row.businessName, 'retire')} /*href={`/home/service-provider-management/${'retire'}/${row.serviceProviderId}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
              <i class="la la-lock"></i>
              Retire
            </a></ProtectedComponent>
          </div>
        </div>
      }
    ]
    return <React.Fragment>
      <TableExtra 
        data={SuperAgentExportSerializer.reverseSuperAgentDetails(this.props.data)}
        headers={SuperAgentExportSerializer.headerExport()}
        fileName={'super_agents'}
        zoomTableHeight={this.zoomTableHeight}
        getData={this.props.getSuperAgents}
        zoom={this.state.zoom}
        page={this.props.page}
        pageSize={this.props.pageSize}
        downloadLink={this.props.downloadSuperAgentData}
        disabled={this.props.loading}
      />
      <ReactTable 
        PaginationComponent={Pagination}
        loading={this.props.loading}
        LoadingComponent={MyLoader}
        style={{
          border: "none",
          boxShadow: "none",
          whiteSpace: 'unset',
          height: this.state.tableHeight,
        }}
        data={this.props.data}
        columns={columns}
        dataCount={this.props.dataCount}
        noDataText={!this.props.loading ? this.props.noDataText : ''}
        pageSizes={getPaginationRows(this.props.dataCount)}
        headerNodeHeight={`123`}
        manual
        pages={this.props.pages}
        page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
        minRows={(this.props.data).length > 0 ? 10 : 10}
        onFetchData={(state, instance) => {
          this.setState({page: state.page})
          if(this.props.canLoadData) {
            this.props.getSuperAgents(state.page + 1, state.pageSize) 
          } 
        }}
        getTableProps={(state, rowInfo, column, instance) => ({
          style: {
            borderColor: '#00425f',
            boxSizing: 'none',
            border: 'none',
          },
        })}
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            display:"flex",
            overflow: "visible",
            color: "black",
            fontSize: "11.5px",
            fontWeight: "70",
            paddingLeft:"5px",
            paddingRight:"5px",
            alignItems: "center",
          },
        })}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"5px",
              paddingRight:"5px",
          },
        })}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
            overflow: 'visible',
            backgroundColor: '#ffffff',
          },
        })}    
        className="-striped -highlight" 
      />
    </React.Fragment>
  }
}