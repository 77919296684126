import { formatBackEndDateTime } from '../../../utils/formatter'
import { getDateByType, isAgentRepush, subtractDays } from '../../../utils/helper'
import Requester from '../requester'

const API_BASE_URL = process.env.REACT_APP_SETTLEMENT_SERVICE_API_BASE_URL

export default class SettlementService {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }

    getSettlementReport (fromPage = null, pageSize = null, payload) {
        let { transactionRef, agentId, transactionType, transactionSettlementStatus, startDate, endDate } = payload
        transactionType = transactionType === 'All' ? null : transactionType
        transactionSettlementStatus = transactionSettlementStatus === 'All' ? null : transactionSettlementStatus
        return this.apiRequester.get({
            endpoint: `commissions/getReport`,
            args: {
                fromPage: fromPage - 1,
                pageSize,
                ...(transactionRef != null ? { transactionRef } : {}),
                ...(agentId != null ? { agentId } : {}),
                ...(transactionType != null ? { transactionType } : {}),
                ...(transactionSettlementStatus != null ? { transactionSettlementStatus } : {}),
                ...(startDate != null ? { startDate: startDate, endDate: endDate } : {})
            }
        })
    } 

    getSettlementReportCount (payload) {
        let { transactionRef, agentId, transactionType, transactionSettlementStatus, startDate, endDate } = payload
        transactionType = transactionType === 'All' ? null : transactionType
        transactionSettlementStatus = transactionSettlementStatus === 'All' ? null : transactionSettlementStatus
        return this.apiRequester.get({
            endpoint: `commissions/getReport`,
            args: {
                ...(transactionRef != null ? { transactionRef } : {}),
                ...(agentId != null ? { agentId } : {}),
                ...(transactionType != null ? { transactionType } : {}),
                ...(transactionSettlementStatus != null ? { transactionSettlementStatus } : {}),
                ...(startDate != null ? { startDate: startDate, endDate: endDate } : {})
            }
        })
    }

    getSettlementByTransRef (transactionRef) {
        return this.apiRequester.get({
            endpoint: `commissions/getReport`,
            args: {
                transactionRef,
            }
        })
    } 

    getCommissionBalance (agentPhoneNo) {
        return this.apiRequester.get({
            endpoint: `commissions/unsettled`,
            args: {
                agentPhoneNo
            }
        })
    }

    requeryPendingSettlement (transactionRef) {
        return this.apiRequester.get({
            endpoint: `commissions/republishPendingCommissions`,
            args: {
                transactionRef,
                startDate: formatBackEndDateTime(subtractDays(getDateByType("startDate"), 1)),
                endDate: formatBackEndDateTime(subtractDays(getDateByType("endDate"), 1)),
                //skipNonPublished: false
            }
        })
    }

    requeryAllPendingSettlement (daysToRepublish) {
        console.log('days is ', daysToRepublish)
        return this.apiRequester.get({
            endpoint: `commissions/republishPendingCommissions`,
            args: {
                ...(!isAgentRepush(daysToRepublish) ? { daysToRepublish } : {}),
                ...(isAgentRepush(daysToRepublish) ? { agentId: daysToRepublish } : {}),
                isUnsettled: isAgentRepush(daysToRepublish) ? false : true,
                startDate: formatBackEndDateTime(subtractDays(getDateByType("startDate"), 1)),
                endDate: formatBackEndDateTime(subtractDays(getDateByType("endDate"), 1))
            }
        })
    }

}
