import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_MESSAGING_READ_TOPIC, CAN_MESSAGING_WRITE_TOPIC } from '../../../../../constants/permissions.js'
import NotificationGroupGridScene from '../scenes/notification-group-grid/scene';
import AddNotificationGroupScene from '../scenes/add-notification-group';
import BaseScene from '../../../../../components/scenes/component';

export default class NotificationGroupScene extends BaseScene {
  sceneName = 'notification-group'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TOPIC]} exact path='/home/notifications/notification-group' component={NotificationGroupGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TOPIC]} exact path='/home/notifications/notification-group/:id/view' component={AddNotificationGroupScene} />
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_WRITE_TOPIC]} exact path='/home/notifications/notification-group/add' component={AddNotificationGroupScene} />
    </Router>
  }
}
