import React from "react";
import Select from "../../../../../../components/select";

export default function LienSearch(props) {
  const handleSearchRequest = (e) => {
    e.preventDefault();
    props.handleSearchRequest();
  };

  const lienStatuses = [
    { value: "PENDING_APPROVAL", label: "PENDING APPROVAL" },
    { value: "DECLINED", label: "DECLINED" },
    { value: "AWAITING_POSTING", label: "AWAITING POSTING" },
    { value: "COMPLETED_SUCCESSFULLY", label: "COMPLETED SUCCESSFULLY" },
    { value: "POSTED_BUT_FAILED", label: "POSTED BUT FAILED" },
  ];

  return (
    <React.Fragment>
      <div class="search-params">
        <form onSubmit={handleSearchRequest}>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="search-label">Phone Number</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  props.updateSearchParameters(
                    "accountCode",
                    event.target.value
                  )
                }
              />
            </div>
            <div class="col-md-3">
              <label class="search-label">Status</label>
              <Select
                options={lienStatuses}
                onChange={(event) =>
                  props.updateSearchParameters("lienStatus", event.value)
                }
                defaultValue={props.searchParameters.transactionStatus}
              />
            </div>
            <div class="col-md-2">
              <label class="search-label">&nbsp;&nbsp;</label>
              <button type="submit" class="search-button">
                Search
              </button>
            </div>
          </div>
        </form>
        <button
          id="basic-addon1"
          type="button"
          class="btn btn-secondary"
          // Cell: ({ props, original, column }) => (
          style={{
            marginLeft: "10px",
            backgroundColor: "#00425f",
            borderColor: "#00425f",
            color: "#ffffff",
            marginBottom: 5,
          }}
          onClick={() => {
            props.requeryBulkLien();
          }}
        >
          Re-query
        </button>
      </div>
    </React.Fragment>
  );
}
