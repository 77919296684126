import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import Table from '../../../../../../../components/table';
import { CAN_VIEW_CURRENCY } from '../../../../../../../constants/permissions.js';
import Currencies from '../../../../../../../fixtures/currencies';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { CurrencyExportSerializer } from '../../../../../../../serializers';
import { formatDate } from '../../../../../../../utils/formatter';

const columns = [
  {
    Header: "S/N",
    accessor: 'id',
    Cell: (id) => <span>{id.index + 1}</span>,
    sortable: false,
    resizable: false,
    width: 60,
    headerStyle: {'white-space': 'unset'},
  },{
    Header: "Name",
    accessor: 'name',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
  },{
    Header: "Symbol",
    accessor: 'symbol',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
  },{
    Header: "Country",
    accessor: 'country',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
  },{
    Header: "Status",
    accessor: 'status',
    resizable: false,
    width: 80,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          textAlign: 'center'
        },
      };
    },
    Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge kt-badge--inline ${props.value === 1 ? 'kt-badge--success' : props.value === 2 ? 'kt-badge--warning' : '' } kt-badge--pill`}>{props.value === 1 ? 'Active' : props.value === 2 ? 'Inactive' : ''}</span>,
  },{
    Header: "Date Created",
    accessor: 'dateCreated',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
    width: 100,
    Cell: props => {
      return props.value ? formatDate(props.value) : ''
    }
  },{
    Header: "Last Modified",
    accessor: 'dateModified',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
    width: 100,
    Cell: props => {
      return props.value ? formatDate(props.value) : ''
    }
  }
  // ,{
  //   Header: "Action",
  //   accessor: 'id',
  //   overflow: 'visible',
  //   resizable: false,
  //   sortable: false,
  //   headerStyle: {'white-space': 'unset'},
  //   getProps: (state, rowInfo, column) => {
  //     return {
  //       style: {
  //         lineHeight: '20px',
  //         textAlign: 'center',
  //         whiteSpace: 'unset',
  //       },
  //     };
  //   },
  //   width: 100,
  //   Cell: ({row, original}) => <div class="dropdown">
  //     <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
  //       <i class="la la-ellipsis-v"></i>
  //     </a>
  //     <div class="dropdown-menu dropdown-menu-right">
  //       <ProtectedComponent requiredPermissions={[CAN_VIEW_CURRENCY]}><Link to={`system-configuration/currency-management/${row.id}/view`} class="dropdown-item">
  //         <i class="la la-eye"></i>
  //         View
  //       </Link></ProtectedComponent>
  //       {/* <a href={`/home/system-configuration/currency-management/${row.id}/delete`} class="dropdown-item">
  //         <i class="la la-trash"></i>
  //         Delete
  //       </a> */}
  //     </div>
  //   </div>
  // }
]

export default class CurrencyGridScene extends BaseScene {
  
  sceneName = 'currency-management'
  state={
    currencies: []
  }

  getCurrencies = this.getCurrencies.bind(this)
  
  componentDidMount() {
    this.getCurrencies()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  getCurrencies () {
    this.setState({isLoading: true})
    setTimeout(() => {
      this.setState({
        currencies: Currencies,
        isLoading: false
      })
    }, 5000)
  }

  render () {
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Currencies"} filterPermissions={[]} /*actionButtonText={"Add Currency"} actionButtonUrl={"/home/system-configuration/currency-management/add"}*/ totalCount={56} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_CURRENCY]}>
        <GridLayout>
          <Table 
            columns={columns} 
            data={this.state.currencies}
            loading={this.state.isLoading}
            noDataText={`No currencies found`}
            dataCount={this.state.currencies.length}
            getData={this.getCurrencies}
            disabled={true}
            exportData={CurrencyExportSerializer.reverseCurrencyDetails(this.state.currencies.reverse())}
            exportHeaders={CurrencyExportSerializer.headerExport()}
            exportFileName={'Currency'}
          /> 
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
