import React from "react";
import { CAN_DISTRIBUTE_WALLET } from "../../../../../../constants/permissions";
import ProtectedComponent from "../../../../../../mixins/protected-component";

export default class CardComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet kt-portlet--height-fluid">
          {/* {this.props.canReload && <div class="kt-widget14 text-center">
                    <div class="kt-widget14__stat">
                        <div class="kt-widget14__header">
                        </div>
                        <p>Something went wrong. Please try again.</p>
                        <button type="button" class="btn btn-secondary btn-sm" style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.props.reload}>Reload</button>
                    </div> 
                </div>} */}
          <div class="kt-widget14">
            <div class="kt-widget14__header">
              {this.props.title && (
                <h3 class="kt-widget14__title">
                  {this.props.title}
                  {this.props.showReloader && (
                    <i
                      onClick={this.props.getAllBalances}
                      class={`fa fa-refresh ${
                        this.props.reloadWalletBalance ? `fa-spin` : ""
                      } la-md pull-right`}
                      arial-hidden="true"
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </h3>
              )}
              {this.props.description && (
                <span class="kt-widget14__desc">{this.props.description}</span>
              )}
            </div>
            {this.props.canReload && (
              <div class="text-center">
                <p>Something went wrong. Please try again.</p>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  style={{ backgroundColor: "#00425f", color: "#ffffff" }}
                  onClick={this.props.reload}
                >
                  Reload
                </button>
              </div>
            )}
            {!this.props.canReload && (
              <div>
                {this.props.value && (
                  <div>
                    {this.props.currency && <h2>&#x20A6;{this.props.value}</h2>}
                    {!this.props.loading && !this.props.currency && (
                      <h2>{this.props.value}</h2>
                    )}
                  </div>
                )}
                {this.props.showLastBalanceTime && (
                  <small>
                    {this.props.lastBalanceTime
                      ? `@ ${this.props.lastBalanceTime}`
                      : ""}
                  </small>
                )}
                {/* <br /> */}
                {this.props.showLastBalanceTime && this.props.canDistribute && (
                  <ProtectedComponent
                    requiredPermissions={[CAN_DISTRIBUTE_WALLET]}
                  >
                    <a
                      href="/admin/home/wallet/distribute"
                      class="btn btn-label-brand btn-sm btn-bold"
                      style={{ backgroundColor: "#00425f", color: "white" }}
                    >
                      Distribute Wallet
                    </a>
                  </ProtectedComponent>
                )}
                {this.props.loading && (
                  <div
                    class="loading"
                    style={{ textAlign: "center", fontSize: "15px" }}
                  >
                    Loading
                  </div>
                )}
                {this.props.chart && !this.props.loading && (
                  <div class="" style={{ marginLeft: "-25px" }}>
                    {this.props.chart}
                  </div>
                )}
                {this.props.transactionsVolume && (
                  <div class="">
                    <div class="kt-widget14__chart">
                      {!this.props.loading && (
                        <div
                          style={{ fontSize: "1.2rem", marginBottom: "10px" }}
                        >{`Total Average Transaction = ${this.props.count}`}</div>
                      )}
                      {/* <canvas id="" style={{ height: "50px", width: "100px" }}></canvas> */}
                    </div>
                    {!this.props.loading && (
                      <div class="kt-widget14__legends">
                        {this.props.transactionsVolume.map((transaction) => (
                          <div class="kt-widget14__legend">
                            <span class="kt-widget14__bullet kt-bg-success"></span>
                            <span class="kt-widget14__stats">{`${Math.round(
                              (this.props.count === 0
                                ? 0
                                : transaction.value / this.props.count) * 100
                            )}% ${transaction.time}`}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {this.props.buypowerDiscos && !this.props.loadingDiscos && (
                  <div class="">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1.2rem",
                          textDecoration: "underline",
                        }}
                      >
                        Discos
                      </span>
                      <span
                        style={{
                          fontSize: "1.2rem",
                          textDecoration: "underline",
                        }}
                      >
                        Status
                      </span>
                    </div>
                    {this.props.buypowerDiscos.map((disco, idx) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "5px 0",
                        }}
                        key={idx}
                      >
                        <span style={{ fontSize: "1.1rem" }}>{disco.name}</span>
                        <span
                          style={{
                            height: "12px",
                            width: "20px",
                            backgroundColor:
                              disco.value === true ? "green" : "red",
                            borderRadius: "5px",
                          }}
                        ></span>
                      </div>
                    ))}

                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
