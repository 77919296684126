import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { WorkflowApprovalExportSerializer } from '../../../../../../serializers';
import { formatDateTime, formatWorkflowApprovalStatus } from '../../../../../../utils/formatter';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class ApprovalTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '500px',
        zoom: 'plus'
      }
    
    zoomTableHeight = this.zoomTableHeight.bind(this)
    
    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }

    
    render() { 
        const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            // {
            //     Header: "ID",
            //     accessor: "applicationId",
            //     resizable: false,
            //     headerStyle: { 'white-space': 'unset' },
            //     style: { 'white-space': 'unset' },
            // },
            {
                Header: "Module",
                accessor: "runingWorkflow",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                Cell: props => { return props.value.name }
            }, {
                Header: "Initiator",
                accessor: "initiator",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                Cell: props => <span class="word-capitalize">{props.value.name.toLowerCase()}</span>
            },
             {
                Header: "Action Type",
                accessor: "actionType",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' }
            },
            {
                Header: "Status",
                accessor: "status",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                Cell: props => <span>{formatWorkflowApprovalStatus(props.value)}</span>
            }, 
            {
                Header: "Date&Time",
                accessor: "created",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                width: 170,
                Cell: props => {
                    return props.value ? formatDateTime(props.value) : ''
                }
            },
             {
        
                Header: "Action",
                accessor: "actionType",
                resizable: false,
                headerStyle: { 'white-space': 'unset' },
                style: { 'white-space': 'unset' },
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            lineHeight: '20px',
                            textAlign: 'center',
                            whiteSpace: 'unset',
                        },
                    };
                },
                width: 70,
                // Cell: ({row, original}) => <button type="button" class="btn btn-secondary btn-xs link-button">
                //     <Link to={`approval/${original.id}/view`} class="link-button">
                //         View
                //     </Link>
                // </button>
                Cell: ({ row, original }) => <div class="dropdown">
                    <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                        <i class="la la-ellipsis-v"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a hidden={original.status != "INPROGRESS"} onClick={() => this.props.getClickedAction(original,`recall`)} data-toggle="modal" data-target="#kt_modal_1" class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                            <i class="la la-close"></i>
                                Recall
                        </a>
                        <Link to={`approval/${original.id}/view`} class="dropdown-item">
                            <i class="la la-eye"></i>
                            View
                        </Link>
                    </div>
                </div>
            }
        ]        

        return <React.Fragment>
        <TableExtra 
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getApprovals}
          zoom={this.state.zoom}
          data={WorkflowApprovalExportSerializer.reverseWorkflowApprovalDetails(this.props.data)}
          headers={WorkflowApprovalExportSerializer.headerExport()}
          fileName={'Workflow Approval'}
          //disabled={true}
          disabled={this.props.loading}
          page={this.props.page}
          pageSize={this.props.pageSize}
          closePendingApprovals={this.props.closePendingApprovals}
          showApprovalRepush={true}
        />
        <ReactTable 
            PaginationComponent={Pagination} 
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ''}
            style={{
                border: "none",
                boxShadow: "none",
                whiteSpace: 'unset',
                height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
            onFetchData={(state, instance) => {
                this.setState({page: state.page})
                if(this.props.canLoadData) {
                    this.props.getApprovals(state.page + 1,state.pageSize)
                }
            }}
            headerNodeHeight={`123`}
            minRows={(this.props.data).length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
                style: {
                    borderColor: '#00425f',
                    boxSizing: 'none',
                    border: 'none',
                },
            })}
            getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                    display:"flex",
                    overflow: "visible",
                    color: "black",
                    fontSize: "11.5px",
                    fontWeight: "50",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                    alignItems: "center",
                    
                  },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                    backgroundColor: "#00425f",
                    borderBottom: "0.1px solid #DBDEDE",
                    borderWidth: "0.1px",
                    padding:"10px 0",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "100",
                    textAlign: "left",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                  },
            })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
                style: {
                    overflow: 'visible',
                    backgroundColor: '#ffffff',
                },
            })}    
            className="-striped -highlight" 
        />
        </React.Fragment>
    }
}