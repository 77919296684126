import { convertToMajor } from "../../utils/converter";
import {
  formatCurrency,
  formatDateTime,
  formatLongNumber,
  formatTransactionStatus,
} from "../../utils/formatter";
import { getTransactionTypeName } from "../../utils/helper";

export default class CashoutInjectExportSerializer {
  static headerExport() {
    return [
      {
        label: "Batch ID",
        key: "batchId",
        title: "Batch ID",
        dataIndex: "batchId",
      },
      {
        label: "Uploaded By",
        key: "uploadedBy",
        title: "Uploaded By",
        dataIndex: "uploadedBy",
      },
      {
        label: "Document Name",
        key: "documentName",
        title: "Document Name",
        dataIndex: "documentName",
      },
      {
        label: "Date Uploaded",
        key: "dateCreated",
        title: "Date Uploaded",
        dataIndex: "dateCreated",
      },
      {
        label: "Date Processed",
        key: "dateModified",
        title: "Date Processed",
        dataIndex: "dateModified",
      },
      {
        label: "Status",
        key: "processed",
        title: "Status",
        dataIndex: "processed",
      },
    ];
  }

  static cashoutDetails(formData) {
    let newFormData = [];
    formData.totalCount > 0 && formData.items.map((detail) => {
      let {
        agentName,
        customerMsisdn,
        transactionAmount,
        serviceProviderResponseCode,
        paymentItemCode,
        transactionRef,
        statusCode,
        fee,
        serviceProviderTranRef,
        paymentMethod,
        customerGender,
        customerFirstName,
        customerLastName,
        businessName,
        ledgerBalance,
        availableBalance,
        pin,
        businessMobileNo,
        domainCode,
        dateCreated,
        dateModified,
        responseCode,
        requestReference,
        transactionTypeInt,
      } = detail;

      let detailFormData = {
        customerName: `${customerFirstName} ${customerLastName}`,
        customerPhoneNumber: customerMsisdn
          ? formatLongNumber(customerMsisdn)
          : "",
        gender: customerGender,
        domainCode,
        agentName,
        businessName,
        responseCode,
        providerReference: serviceProviderTranRef
          ? formatLongNumber(serviceProviderTranRef)
          : "",
        serviceProviderTranRef: serviceProviderTranRef
          ? formatLongNumber(serviceProviderTranRef)
          : "",
        requestReference: requestReference
          ? formatLongNumber(requestReference)
          : "",
        serviceProviderResponseCode: serviceProviderResponseCode
          ? serviceProviderResponseCode
          : "",
        pin: pin ? formatLongNumber(pin) : "",
        businessMobileNo: businessMobileNo
          ? formatLongNumber(businessMobileNo)
          : "",
        transactionRef: transactionRef ? formatLongNumber(transactionRef) : "",
        fee: fee
          ? `₦${formatCurrency(convertToMajor(fee))}`
          : `₦${formatCurrency(0)}`,
        ledgerBalance: ledgerBalance
          ? `₦${formatCurrency(convertToMajor(ledgerBalance))}`
          : "",
        availableBalance: availableBalance
          ? `₦${formatCurrency(convertToMajor(availableBalance))}`
          : "",
        paymentItemCode,
        statusCode: formatTransactionStatus(statusCode),
        paymentMethod,
        dateCreated: dateCreated ? formatDateTime(dateCreated) : "",
        dateModified: dateModified ? formatDateTime(dateModified) : "",
        transactionType: transactionTypeInt
          ? getTransactionTypeName(transactionTypeInt)
          : "",
        transactionAmount: transactionAmount
          ? `₦${formatCurrency(convertToMajor(transactionAmount))}`
          : "",
      };

      newFormData.push(detailFormData);
    });
    return newFormData;
  }
}
