import React from 'react'
import { checkEmailIsValid } from '../../utils/validator'
import PhoneNumberComponent from '../phone-number-component'
import Select from '../select'


export const SuperAgentForm = (props) => {
    const { 
        emailIsValid, disabled, updateSuperAgentFormData, updateSetState, showPhoneNumber, phoneIsValid, 
        businessTypes, superAgentFormData:{ businessEmail, businessName, businessPhoneNo, businessTypeId, companyRegNo } 
    } = props;
    return <>
        <div class="kt-portlet">
            <form class="kt-form kt-form--label-right">
                <div class="kt-portlet__body">
                    <div class="form-group col-lg-6">
                        <label>Business Email *:<br></br></label>
                        <input autoComplete="off" disabled={disabled} type="email" style={{borderColor: emailIsValid ? '' : 'red'}} defaultValue={businessEmail} class="form-control" placeholder="Business Email" onChange={(event) => { 
                            updateSuperAgentFormData('businessEmail', event.target.value)
                            updateSetState('emailIsvalid', checkEmailIsValid(event.target.value))
                        }}/>
                        <span class="form-text text-muted">Please enter a valid business email</span>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Business Name *:<br></br></label>
                        <input autoComplete="off" disabled={disabled} type="text" class="form-control" placeholder="Business Name" defaultValue={businessName} onChange={event => updateSuperAgentFormData('businessName', event.target.value)} />
                        <span class="form-text text-muted">Please enter a valid business name</span>
                    </div>

                    {showPhoneNumber && <div class="form-group col-lg-6">
                        <label>Business Phone Number *:<br></br></label>
                        <PhoneNumberComponent 
                            phoneIsValid={phoneIsValid}
                            onChange={(status, phoneNumber) => {
                                updateSuperAgentFormData('businessPhoneNo', phoneNumber)
                                updateSetState('phoneIsValid', status)
                            }}
                            defaultValue={businessPhoneNo}
                            disabled={disabled}
                        />
                        <span class="form-text text-muted">Please enter a valid business phone number</span>
                    </div>}

                    <div class="form-group col-lg-6">
                        <label>Business Type *:</label>
                        <Select 
                            options={businessTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                            onChange={event => updateSuperAgentFormData('businessTypeId', event.value)}
                            disabled={disabled}
                            defaultValue={businessTypeId}
                        />
                        <span class="form-text text-muted">Please select appropriate business type</span>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Business Registration Number *:</label>
                        <input autoComplete="off" disabled={disabled} type="text" maxLength={8} class="form-control" placeholder="Business Registration Number" defaultValue={companyRegNo} onChange={event => updateSuperAgentFormData('companyRegNo', event.target.value)}/>
                        <span class="form-text text-muted">Please enter valid business registration number</span>
                    </div>
                </div>
            </form>
        </div>
    </>
}
