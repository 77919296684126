import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { convertToMajor } from "../../../../../../../utils/converter";
import {
  formatCurrency,
  formatDateTime,
} from "../../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../../utils/helper";
import Pagination from "../../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../../components/spinner";
import TableExtra from "../../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../../mixins/protected-component";

export default class AllocatePosAllTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "520px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "Batch ID",
        accessor: "batchId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 230,
      },
      {
        Header: "Configuration Type",
        accessor: "configurationType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
      },

      {
        Header: "Date Configured",
        accessor: "dateConfigured",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Created By",
        accessor: "configuredBy",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 230,
        // Cell: (props) => <span>{props.value}</span>,
      },

      {
        Header: "Date Uploaded",
        accessor: "dateUploaded",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 155,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },

      {
        Header: "Quantity",
        accessor: "quantity",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 155,
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          fileName={"Allocation request"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getPosRequests}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          desiredStatus={this.props.desiredStatus}
          disabled={this.props.loading}
          // downloadLink={this.props.downloadRemap}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              batchId,
              toDate,
              fromDate,
              pageNumber,
              pageSize,
            } = this.props.searchParameters;
            this.setState({ page: state.pageNumber });
            if (this.props.canLoadData) {
              this.props.getAllPosRequestConfurationRequests(
                state.page + 1,
                state.pageSize,
                batchId,
                toDate,
                fromDate,
                pageNumber,
                pageSize
              );
            }
          }}
          headerNodeHeight={`123`}
          // minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              // backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 5px",
              color: "black",
              fontSize: "10.5px",
              fontWeight: "100",
              textAlign: "left",
              overflow: "visible",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "7px",
              paddingBottom: "7px",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
