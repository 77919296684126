import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import { HistoricalDataExportSerializer } from "../../../../../../serializers";
import {
  formatCurrency,
  formatDateTime,
} from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";

export default class HistoricalTransactionsTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "",
        accessor: "serialNumber",
        Cell: (props) => <span>{props.value}</span>,
        sortable: false,
        resizable: false,
        width: 35,
        headerStyle: { "white-space": "unset" },
      },
      {
        Header: "Reference ID",
        accessor: "referenceId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 110,
      },
      {
        Header: "Last modificcation date",
        accessor: "dateModified",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 80,
      },
      {
        Header: "Transaction type",
        accessor: "transactionType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Source",
        accessor: "source",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 110,
      },
      {
        Header: "Destination",
        accessor: "destination",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 110,
      },
      {
        Header: "Debited amount",
        accessor: "debitedAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) =>
          props.value ? (
            <span>&#x20A6;{formatCurrency(parseFloat(props.value))}</span>
          ) : (
            ""
          ),
      },
      {
        Header: "Credited amount",
        accessor: "creditedAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) =>
          props.value ? (
            <span>&#x20A6;{formatCurrency(parseFloat(props.value))}</span>
          ) : (
            ""
          ),
      },
      {
        Header: "Cash amount",
        accessor: "cashAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) =>
          props.value ? (
            <span>&#x20A6;{formatCurrency(parseFloat(props.value))}</span>
          ) : (
            ""
          ),
      },
      {
        Header: "Consumer SP name",
        accessor: "consumerSP",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Original ESB reference",
        accessor: "esbRef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
      },
      {
        Header: "External reference",
        accessor: "externalRef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Token type",
        accessor: "tokenType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Consumer name",
        accessor: "consumer",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={HistoricalDataExportSerializer.reverseHistoricalData(
            this.props.data
          )}
          headers={HistoricalDataExportSerializer.headerExport()}
          fileName={"historical-transactions"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getHistoricalTransactions}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          //disabledPaginationWrapper={true}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              agentPhone,
              gmppRef,
              transType,
              endDate,
              startDate,
            } = this.props.searchParameters;
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getHistoricalTransactions(
                state.page + 1,
                state.pageSize,
                agentPhone,
                gmppRef,
                transType,
                endDate,
                startDate
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              padding: "0px, 0",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
