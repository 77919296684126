import React from "react";
import { DatePickerComponent } from "../../../../../components/date-picker";
import Select from "../../../../../components/select";
import ApplicationStatuses from "../../../../../fixtures/application_statuses.json";
import { phoneNumberFormatter } from "../../../../../utils/formatter";
import { getDateByType } from "../../../../../utils/helper";

export default class ApplicationSearch extends React.Component {
  state = {
    applicationSearchParameters: {
      fromDate: getDateByType("startDate"),
      toDate: getDateByType("endDate"),
      // approvalStatus: "0",
      agentTypeId: "All",
    },
    applicationCategories: [
      { label: "All", value: "All" },
      { label: "Agent", value: 4 },
      { label: "Aggregator", value: 3 },
    ],
  };

  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest(this.state.applicationSearchParameters);
  }

  render() {
    return (
      <React.Fragment>
        <form class="search-params" onSubmit={this.handleSearchRequest}>
          <div class="form-group row">
            {/* <div class="col-md-3">
                        <label class="search-label">Applicant Id:</label>
                        <input type="text" class="search-input" onChange={event => this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, applicationId: event.target.value ? applicationIdFormatter(event.target.value) : null}})}/> 
                    </div> */}
            <div class="col-md-3">
              <label class="search-label">Applicant First Name:</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      applicantName: event.target.value
                        ? event.target.value
                        : null,
                    },
                  })
                }
              />
            </div>
            <div class="col-md-3">
              <label class="search-label">Business Name:</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      businessName: event.target.value
                        ? event.target.value
                        : null,
                    },
                  })
                }
              />
            </div>
            <div class="col-md-3">
              <label class="search-label">Category:</label>
              <Select
                options={this.state.applicationCategories}
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      agentTypeId: event.value,
                    },
                  })
                }
                defaultValue={
                  this.state.applicationSearchParameters.agentTypeId
                }
              />
              {/* <select class="search-input" onChange={event => this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, approvalStatus: event.target.value ? event.target.value : null}})}> 
                            <option value={``}>All</option>
                            {ApplicationStatuses.map(value => <option value={value.id} selected={value.id == this.state.applicationSearchParameters.approvalStatus ? 'selected' : ''}>{value.name}</option>)}
                        </select> */}
            </div>
            <div class="col-md-3">
              <label class="search-label">Status:</label>
              <Select
                options={ApplicationStatuses}
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      approvalStatus: event.value,
                    },
                  })
                }
                defaultValue={
                  this.state.applicationSearchParameters.approvalStatus
                }
              />
              {/* <select class="search-input" onChange={event => this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, approvalStatus: event.target.value ? event.target.value : null}})}> 
                            <option value={``}>All</option>
                            {ApplicationStatuses.map(value => <option value={value.id} selected={value.id == this.state.applicationSearchParameters.approvalStatus ? 'selected' : ''}>{value.name}</option>)}
                        </select> */}
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="search-label">Applicant Email Address:</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      emailAddress: event.target.value
                        ? event.target.value
                        : null,
                    },
                  })
                }
              />
            </div>
            <div class="col-md-3">
              <label class="search-label">Phone Number:</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      phoneNumber: event.target.value
                        ? phoneNumberFormatter(event.target.value)
                        : null,
                    },
                  })
                }
              />
            </div>
            {/* <div class="col-md-3">
                        <label>Creation Type:</label>
                        <select class="form-control" onChange={event => this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, creationType: event.target.value ? event.target.value : null}})}> 
                            <option value={``}>All</option>
                            {CreationTypes.map(value => <option value={value.id} selected={value.id == this.state.applicationSearchParameters.creationType ? 'selected' : ''}>{value.name}</option>)}
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Created By:</label>
                        <input type="text" class="form-control" onChange={event => this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, createdBy: event.target.value ? event.target.value : null}})}/> 
                    </div> */}
            <div class="col-md-2">
              <label class="search-label">Date From</label>
              <DatePickerComponent
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      fromDate: event,
                    },
                  })
                }
                size={`130px`}
                format={`dd/MM/yyyy h:mm aa`}
                isClearable={true}
                showTimeInput={true}
                style={"0.4rem"}
                textStyle={"1.0rem"}
                iconStyle={"0.2rem"}
                dateType={"startDate"}
                preloadDate={true}
              />
            </div>
            <div class="col-md-2">
              <label class="search-label">Date To</label>
              <DatePickerComponent
                onChange={(event) =>
                  this.setState({
                    applicationSearchParameters: {
                      ...this.state.applicationSearchParameters,
                      toDate: event,
                    },
                  })
                }
                size={`130px`}
                format={`dd/MM/yyyy h:mm aa`}
                isClearable={true}
                showTimeInput={true}
                disabled={
                  this.state.applicationSearchParameters.fromDate ? false : true
                }
                minDate={
                  new Date(this.state.applicationSearchParameters.fromDate)
                }
                style={"0.4rem"}
                textStyle={"1.0rem"}
                iconStyle={"0.2rem"}
                dateType={"endDate"}
                preloadDate={true}
              />
            </div>
            <div class="col-md-2">
              <label class="search-label">&nbsp;&nbsp;</label>
              <button type="submit" class="search-button">
                Search
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
