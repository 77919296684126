import { formatLongNumber } from '../../utils/formatter';

export default class UsersExportSerializer {
    static headerExport () {
        return [
            {label: 'First Name', key: 'firstName', title: 'First Name', dataIndex: 'firstName'},
            {label: 'Last Name', key: 'lastName', title: 'Last Name', dataIndex: 'lastName'},
            {label: 'Phone Number', key: 'mobileNo', title: 'Phone Number', dataIndex: 'mobileNo'},
            {label: 'Email', key: 'email', title: 'Email', dataIndex: 'email'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'}

        ]
    }

    static reverseUserDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { firstName, lastName, mobileNo, email, status } = details
            let detailFormData = {
                firstName,
                lastName,
                mobileNo: mobileNo ? formatLongNumber(mobileNo) : '',
                email,
                status: status === 1 ? 'Active' : status === 2 ?  'Inactive' : status === 3 ? 'Retired' : status === 4 ? 'Dormant' : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}