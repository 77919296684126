import { connect } from 'react-redux'
import LogoutScene from './scene'

function mapStateToProps (state) {
  return {

  }
}

function mapDispatchToProps (dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutScene)