import React from "react";
import { GridItem } from "./layouts/grid-item";

export default class LandingBanner extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <GridItem
        class="kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
        style={{ backgroundColor: "#00425f" }}
      >
        <GridItem>
          <a href="/admin" class="kt-login__logo">
            <img src="./assets/media/logos/logo-light.png" width="150" />
          </a>
        </GridItem>

        <GridItem class="kt-grid__item--fluid kt-grid kt-grid--ver">
          <GridItem class="kt-grid__item--middle">
            <h3 class="kt-login__title">Financial Inclusion Hub</h3>
          </GridItem>
        </GridItem>

        <GridItem>
          <div class="kt-login__info">
            <div class="kt-login__copyright">
              &copy; 2019 - {currentYear} Financial Inclusion Hub
            </div>
            <div class="kt-login__menu">
              <a href="https://paypoint.quickteller.com/" class="kt-link">
                Privacy
              </a>
              <a href="https://help.interswitchgroup.com/" class="kt-link">
                Contact
              </a>
            </div>
          </div>
        </GridItem>
      </GridItem>
    );
  }
}
