import React from 'react';
import ContentHead from '../../../../../../../../../components/content-head';
import BaseScene from '../../../../../../../../../components/scenes/component';
import { CAN_EOD_REPORT_VIEW } from '../../../../../../../../../constants/permissions.js';
import { SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../../../components/access-denied'
import { 
  formatBackEndDateTime, formatCurrency
} from '../../../../../../../../../utils/formatter';
import { convertToMajor } from '../../../../../../../../../utils/converter'
import { getDateByType } from '../../../../../../../../../utils/helper';
import { transactionHistory } from '../../../../../../../../../mixins/api'
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import EndOfDaySearch from '../../components/end-of-day-search'
import Notification from '../../../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../../../mixins/protected-component';
import Table from '../../../../../../../../../components/table';
import { EndOfDayReportExportSerializer } from '../../../../../../../../../serializers'

export default class EndOfDayReportsGridScene extends BaseScene {
  columns = [
    {
      Header: 'Date Created',
      accessor: 'dateCreated',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: 'Business Name',
      accessor: 'accountName',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Business Phone",
      accessor: 'accountNumber',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 150,
    },
    {
      Header: "Agent ID",
      accessor: 'agentID',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 130,
    },
    {
      Header: "Ledger Balance",
      accessor: 'ledgerBalance',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 140,
      Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
    {
      Header: "Commission Balance",
      accessor: 'commBalance',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 140,
      Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
    {
      Header: "Lien Balance",
      accessor: 'lienBalance',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 140,
      Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
  ]
  
  sceneName = 'wallet-management-end-of-day-reports-grid'

  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      balances: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
      },
      canLoadData: false,
      tableMessage: ""
    }

    this.getEndOfDay = this.getEndOfDay.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this)
  }
  
  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getEndOfDay () {
    const {
      endDate,
      startDate,
      agentCode,
     } = this.state.searchParameters

    this.setState({
      canLoadData: true,
      isLoading: true,
    });
     
    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate);

    const { status, response, count } = (
      await transactionHistory.retrieveEodReport(
        formattedStartDate, formattedEndDate, agentCode
      )
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        balances: response ? response : [],
        dataCount: count,
        isLoading: false,
      })
    } else {
      this.setState({
        balances: [],
        dataCount: 0,
        isLoading: false,
        tableMessage: "No end of day report found"
      })
    }
  }

  updateSearchParameters (type, value) {
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  render () {
    return <React.Fragment>
      <ContentHead title={"Wallet Management"} subtitle={"End of Day Report"} filterPermissions={[CAN_EOD_REPORT_VIEW]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_EOD_REPORT_VIEW]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <EndOfDaySearch 
            searchParameters={this.state.searchParameters}
            updateSearchParameters={this.updateSearchParameters}
            handleSearchRequest={this.getEndOfDay}
            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
          />
           <Table 
            columns={this.columns} 
            data={this.state.balances} 
            loading={this.state.isLoading} 
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            getData={this.getEndOfDay}
            exportData={EndOfDayReportExportSerializer.reverseEndOfDayReportDetails(this.state.balances)}
            exportHeaders={EndOfDayReportExportSerializer.headerExport()}
            exportFileName={'end_of_day_report'}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
