import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import BaseScene from '../../../../components/scenes/component'
import PermissionGroupGridScene from './scenes/permission-group-grid'
// import AddPermissionScene from './scenes/add-permission'
import ViewPermissionGroupScene from './scenes/view-permission-group'
import { isAuthorized } from '../../../../mixins/authentication'
import { CAN_VIEW_PERMISSION_GROUP } from '../../../../constants/permissions.js'

export default class PermissionGroupManagementScene extends BaseScene {
  sceneName = 'permission-group-management'

  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]} exact path='/home/permission-group-management' component={PermissionGroupGridScene} />
      {/* <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]} exact path='/home/permission-management/add' component={AddPermissionScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]} exact path='/home/permission-group-management/view' component={ViewPermissionGroupScene} />
    </Router>
  }
}
