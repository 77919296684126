import React from "react";
// import {
//   formatTransactionStatus
// } from "../../../../../../utils/formatter";



export default class AppConfigDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {
    const resul = this.props.configurationDetails ? this.props.configurationDetails : "";
   
    return (
      <React.Fragment>
        <div
          class="modal"
          id="app_config_details_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Application Configuration Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Configuration Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Category ID</strong>
                          </label>
                          <br />
                          <span>
                            {resul.categoryId}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                        <label>
                            <strong>Category Name</strong>
                          </label>
                          <br />
                          <span>
                            {resul.categoryName}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Channel Code</strong>
                          </label>
                          <br />
                          <span>
                            {resul.code}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Currency Code</strong>
                          </label>
                          <br />
                          <span>
                            {resul.currencyCode}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Customer ID Name</strong>
                          </label>
                          <br />
                          <span>
                            {resul.customerIdName}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Description</strong>
                          </label>
                          <br />
                          <span>
                            {resul.description}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Image Name</strong>
                          </label>
                          <br />
                          <span>
                            {resul.imageName}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Image URL</strong>
                          </label>
                          <br />
                          <span>
                            {resul.imageUrl}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Name</strong>
                          </label>
                          <br />
                          <span>
                            {resul.name}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Provider Code</strong>
                          </label>
                          <br />
                          <span>
                            {resul.providerCode}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Route URL</strong>
                          </label>
                          <br />
                          <span>
                            {resul.routeUrl}
                          </span>
                          <br />
                          <br />
                        </div>
                        
                        <div class="col-lg-6">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul.status === true ? "True" : "False"}
                          </span>
                          
                          <br />
                          <br />
                        </div>
                       
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Surcharge</strong>
                          </label>
                          <br />
                          <span>
                            {resul.surcharge}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                        <label>
                            <strong>URL Name</strong>
                          </label>
                          <br />
                          <span>
                            {resul.urlName}
                          </span>
                        </div>
                      </div>

                      <hr />
                      
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
