import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { CAN_CREATE_FEE_RULE } from "../../../../../../../constants/permissions.js";
import {
  feeConfigurationManagement,
  platformManagement,
} from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import FeeRuleForm from "../../components/fee-rule-form";

export default class AddFeeRuleScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      canSave: null,
      feeRuleFormData: {
        serviceType: "0",
        service: "0",
        serviceOption: "0",
        agentCategory: "0",
      },
      services: [{ id: 0, name: "Any" }],
      serviceTypes: [{ id: 0, name: "Any" }],
      serviceOptions: [{ id: 0, name: "Any" }],
      agentTypes: [
        { id: 0, name: "Any" },
        { id: 1, name: "Agent" },
        { id: 2, name: "Aggregator" },
      ],
      agentCategories: [{ id: 0, name: "Any" }],
      agentCategoryTypes: [{ id: 0, name: "Any" }],
      fees: [],
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.updateFeeRuleFormData = this.updateFeeRuleFormData.bind(this);
    this.getServiceTypeById = this.getServiceTypeById.bind(this);
    this.getServiceById = this.getServiceById.bind(this);
    this.getAgentCategories = this.getAgentCategories.bind(this);
    this.filterAgentCategories = this.filterAgentCategories.bind(this);
  }

  componentDidMount() {
    this.getServiceTypes();
    this.getFees();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getServiceTypes() {
    this.setState({
      //isLoading: true,
      isGetServiceTypeLoading: true,
    });
    const responseObj = await feeConfigurationManagement.getServiceTypes();
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreServiceTypes(count);
    } else {
      this.setState({
        serviceTypes: [],
        isLoading: false,
        isGetServiceTypeLoading: false,
      });
    }
  }

  async getServiceTypeById(serviceTypeId, withServices = true) {
    this.setState({ isServiceLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceType(
      serviceTypeId,
      withServices
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        services: response.services
          ? this.updateBackendResponseWithAny(response.services)
          : [],
        isServiceLoading: false,
      });
    } else {
      this.setState({
        services: [],
        isServiceLoading: false,
      });
    }
  }

  async getServiceById(serviceId, withOptions = true) {
    this.setState({ isServiceOptionLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceById(
      serviceId,
      withOptions
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceOptions: response.serviceOptions
          ? this.updateBackendResponseWithAny(response.serviceOptions)
          : [],
        isServiceOptionLoading: false,
      });
    } else {
      this.setState({
        serviceOptions: [],
        isServiceOptionLoading: false,
      });
    }
  }

  async getAgentCategories(agentType) {
    this.setState({ isAgentCategoryLoading: true });
    const responseObj = await platformManagement.getAgentClasses();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentCategories: response
          ? this.updateBackendResponseWithAny(
              this.filterAgentCategories(response, agentType)
            )
          : [],
        isAgentCategoryLoading: false,
      });
    } else {
      this.setState({
        agentCategories: [],
        isAgentCategoryLoading: false,
      });
    }
  }

  filterAgentCategories(agentCategories, agentType) {
    return agentCategories.filter(
      (value) => value.agentTypeId == agentType && value.disabled != 1
    );
  }

  async getFees() {
    this.setState({ isFeeLoading: true });
    const responseObj = await feeConfigurationManagement.getFees();
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreFees(count);
    } else {
      this.setState({
        fees: [],
        isFeeLoading: false,
      });
    }
  }

  async getMoreFees(pageSize) {
    this.setState({ isFeeLoading: true });
    const responseObj = await feeConfigurationManagement.getFees(
      null,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        fees: response.data,
        isFeeLoading: false,
      });
    } else {
      this.setState({
        fees: [],
        isFeeLoading: false,
      });
    }
  }

  async getMoreServiceTypes(pageSize) {
    this.setState({
      isLoading: true,
      isGetServiceTypeLoading: true,
    });
    const responseObj = await feeConfigurationManagement.getServiceTypes(
      null,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceTypes: response.data
          ? this.updateBackendResponseWithAny(response.data)
          : [],
        serviceTypesCount: count,
        isLoading: false,
        isGetServiceTypeLoading: false,
      });
    } else {
      this.setState({
        serviceTypes: [],
        serviceTypesCount: 0,
        isLoading: false,
        isGetServiceTypeLoading: false,
      });
    }
  }

  updateBackendResponseWithAny(response) {
    let anyObj = { id: 0, name: "Any" };
    let obj = [];
    obj.push(anyObj);
    response.map((detail) => obj.push(detail));
    return obj;
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.createFeeRule(
      this.state.feeRuleFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new fee rule has been sent successfully for approval.",
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Fee rule created successfully.",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  isFormValid() {
    return this.state.isLoading || !this.state.feeRuleFormData.feeToApply;
  }

  updateFeeRuleFormData(type, value) {
    this.setState({
      feeRuleFormData: { ...this.state.feeRuleFormData, [type]: value },
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/fee-rules" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_FEE_RULE]}>
          <button
            type="button"
            disabled={this.isFormValid()}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Fee Rules"}
          withoutSearch
          subtitle="Add Fee Rule"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_CREATE_FEE_RULE]}
        >
          <GridLayout>
            <FeeRuleForm
              serviceTypes={this.state.serviceTypes}
              services={this.state.services}
              serviceOptions={this.state.serviceOptions}
              agentCategories={this.state.agentCategories}
              agentTypes={this.state.agentTypes}
              fees={this.state.fees}
              updateFeeRuleFormData={this.updateFeeRuleFormData}
              getServiceTypeById={this.getServiceTypeById}
              getServiceById={this.getServiceById}
              getAgentCategories={this.getAgentCategories}
              feeRuleFormData={this.state.feeRuleFormData}
              disabled={false}
              isGetServiceTypeLoading={this.state.isGetServiceTypeLoading}
              isServiceLoading={this.state.isServiceLoading}
              isServiceOptionLoading={this.state.isServiceOptionLoading}
              isAgentCategoryLoading={this.state.isAgentCategoryLoading}
              isFeeLoading={this.state.isFeeLoading}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
