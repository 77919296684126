import React from 'react'
import { DatePickerComponent } from '../../../../../../../../components/date-picker';
import Select from '../../../../../../../../components/select'

export default class WalletPoolSearch extends React.Component {
    
    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest()
    }

    render() {
        return <React.Fragment>
            <div class="search-params">
                <form onSubmit={this.handleSearchRequest}>
                    <div class='form-group row'>
                        <div class="col-md-3">
                            <label>Account Name:</label>
                            <Select 
                                options={this.props.accountNames.map(detail => { return ( {label: detail.accountName, value: detail.accountCode} ) })}
                                onChange={event => this.props.updateSearchParameters('ledgerAccountCode', event.value)}
                                defaultValue={this.props.searchParameters.ledgerAccountCode}
                                isLoading={this.props.isAccountNamesLoading}
                            />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Date From</label>
                            <DatePickerComponent
                                onChange={(event) => this.props.updateSearchParameters('startDate', event)}
                                size={`240px`}
                                format={`dd/MM/yyyy h:mm aa`}
                                isClearable={true}
                                showTimeInput={true}
                                style={"0.4rem"}
                                textStyle={"1.0rem"}
                                iconStyle={"0.2rem"}
                                dateType={"startDate"}
                                preloadDate={this.props.preloadDate}
                            />
                        </div>
                        <div class="col-md-3">
                            <label class="search-label">Date To</label>
                            <DatePickerComponent 
                                onChange={(event) => this.props.updateSearchParameters('endDate', event)}
                                size={`240px`}
                                format={`dd/MM/yyyy h:mm aa`}
                                isClearable={true}
                                showTimeInput={true}
                                disabled={this.props.searchParameters.startDate ? false : true}
                                minDate={this.props.searchParameters.startDate}
                                style={"0.4rem"}
                                textStyle={"1.0rem"}
                                iconStyle={"0.2rem"}
                                dateType={"endDate"}
                                preloadDate={this.props.preloadDate}
                            />
                        </div>
                        <div class="col-md-2">
                            <label class="search-label">&nbsp;&nbsp;</label>
                            <button type="submit" class="search-button">Search</button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </React.Fragment>
    }
}