import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";

export default class NIPBankTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }
  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    return (
      <React.Fragment>
        <TableExtra
          data={this.props.exportData}
          headers={this.props.exportHeaders}
          fileName={this.props.exportFileName}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getBankList}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={this.props.columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={[10, 20, 25, 50, 100]}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={this.props.data.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getBankList(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "20px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
