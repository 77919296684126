import React from 'react'

export default class BankForm extends React.Component {
    
    constructor (props) {
        super(props)
      }
    
    render () {
        let enabledForSanef = this.props.enabledForSanef
        let enabledForCardLinking = this.props.enabledForCardLinking
        let requireBvn = this.props.requireBvn

        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Bank Name *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.bankName} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('bankName', event.target.value)}/>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Sanef Bank Code*:</label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.sanefBankCode} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('sanefBankCode', event.target.value)}/>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>CBN Bank Code*:</label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.cbnCode} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('cbnCode', event.target.value)}/>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Is Enabled (Acct. Opening) *:</label>
                            <select autoComplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} data-live-search="true" onChange={(event) => this.props.onChange('enabledForSanef', event.target.value)} value={enabledForSanef}>
                                {[{"enabledForSanef":"true", "categoryName":"Active"}, {"enabledForSanef":"false", "categoryName":"Inactive"}].map((value, index) => <option value={value.enabledForSanef} key={value.enabledForSanef}>{value.categoryName}</option>)}
                            </select>
                        </div>
                        
                        <div class="form-group col-lg-6">
                            <label>Is Enabled (Debit Card Linking) *:</label>
                            <select autoComplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} data-live-search="true" onChange={(event) => this.props.onChange('enabledForCardLinking', event.target.value)} value={enabledForCardLinking}>
                                {[{"enabledForCardLinking":"true", "categoryName":"Active"}, {"enabledForCardLinking":"false", "categoryName":"Inactive"}].map((value, index) => <option value={value.enabledForCardLinking} key={value.enabledForCardLinking} >{value.categoryName}</option>)}
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Is BVN Required? *:</label>
                            <select autoComplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} data-live-search="true" onChange={(event) => this.props.onChange('requireBvn', event.target.value)} value={requireBvn}>
                                {[{"requireBvn":"true", "categoryName":"Yes"}, {"requireBvn":"false", "categoryName":"No"}].map((value, index) => <option value={value.requireBvn} key={value.requireBvn} >{value.categoryName}</option>)}
                            </select>
                        </div>
                        
                    </div>
                </form>
            </div>
        </React.Fragment>
        
    }
}