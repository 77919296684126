import React from 'react'

export default class NavTab extends React.Component {
    render() {
        return <React.Fragment>
            <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-bold" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#kt_tabs_2_1" role="tab">Agent Details</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#kt_tabs_2_2" role="tab">Business Details</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#kt_tabs_2_3" role="tab">Next of Kin Details</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#kt_tabs_2_4" role="tab">KYC Documents</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#kt_tabs_2_5" role="tab">Other Details</a>
                </li>
            </ul>
        </React.Fragment>
    }
}