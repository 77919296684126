import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_REMAP_DEVICE } from '../../../../../../../constants/permissions.js';
import { userManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
// import DeviceTable from '../../components/stock-table'
// import DeviceSearch from '../../components/stock-search'
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import UploadStockDetailsModal from '../../components/upload-stock-detail-modal';
import UploadStockSearch from '../../components/upload-stock-search';
import UploadStockTable from '../../components/upload-stock-table';

export default class ConfigUploadGridScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {
        deviceType: 'All',
        fromDate: getDateByType('startDate'),
        toDate: getDateByType('endDate'),
        documentStatus: 'All',
        pageNum: 1,
        pageSize: 20,
        batchId: '',
      },
      searchParameters2: {
        deviceType: 'All',
        fromDate: getDateByType('startDate'),
        toDate: getDateByType('endDate'),
        documentStatus: 'All',
        pageNum: 1,
        pageSize: 20,
        batchId: '',
      },
      tableMessage: '',
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      // stockDisable: false,
      // configDisable: false,
      // stockFile: [],
      // configFile: [],
      // stockError: "",
      // configError: "",
      // redirect: false,
      stockDetails: '',
    };
    // this.uploadConfigFile = this.uploadConfigFile.bind(this);
    // this.uploadStockFile = this.uploadStockFile.bind(this);
    // this.readUploadedFile = this.readUploadedFile.bind(this);
    this.getConfig = this.getConfig.bind(this);
    // this.getMModal = this.getMModal.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getStocksModal = this.getStocksModal.bind(this);
    // this.downloadStocks = this.downloadStocks.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  sceneName = 'config-upload';

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  // ==============GRID TABLE FUNCTIONS=============================
  async getConfig(pageNum, pageSize) {
    console.log('pagination details ', pageNum, pageSize);
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.documentStatus =
      searchParameters.documentStatus === 'All'
        ? null
        : searchParameters.documentStatus;
    searchParameters.documentStatus =
      searchParameters.documentStatus === 'All'
        ? null
        : searchParameters.documentStatus;

    searchParameters.documentType = 'CONFIG';
    // searchParameters.deviceType =
    //   searchParameters.deviceType === "All"
    //     ? null
    //     : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTimeFrom(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTimeTo(searchParameters.toDate, true)}.747`;
    console.log('from the main scene ', searchParameters);
    const responseObj = await userManagement.getStocksUploadrequest(
      searchParameters
    );
    const { status, response, count } = responseObj;
    console.log('response is ', response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        stocks: response.data,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: !count || count == 0 ? 'No POS Config found' : '',
      });
    } else {
      this.setState({
        stocks: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 2,
        isLoading: false,
        errorMessage: response,
        tableMessage: 'No POS Config found',
      });
    }
  }

  async getStocksModal(pageNum, pageSize, batchId) {
    this.setState({
      // isLoading: true,
      // canLoadData: true,
    });
    let searchParameters = this.state.searchParameters2;
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.batchId = batchId ? batchId : null;
    searchParameters.stockStatus =
      searchParameters.stockStatus === 'All'
        ? null
        : searchParameters.stockStatus;
    searchParameters.deviceType =
      searchParameters.deviceType === 'All'
        ? null
        : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTimeFrom(searchParameters.fromDate * 0, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTimeTo(searchParameters.toDate, true)}.747`;
    console.log('from the main scene ', searchParameters);
    const responseObj = await userManagement.getStocks(searchParameters);
    const { status, response, count } = responseObj;
    console.log('response is ', response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        stocks: response.data,
        // dataCount: count ? count : response.data.length,
        // isLoading: false,
        // pages: Math.ceil(count / pageSize),
        // pageSize: pageSize,
        // page: pageNum,
        // startPage: null,
        // tableMessage: !count || count == 0 ? "No POS stock found" : "",
        // searchParameters: null,
        // successMessage2: ``,
      });
    } else {
      this.setState({
        stocks: [],
        // pages: 1,
        // pageSize: pageSize,
        // page: pageNum,
        // dataCount: 2,
        // isLoading: false,
        // errorMessage2: response,
        errorMessage2: response,
      });
    }
  }

  handleSearchRequest(searchParameters) {
    this.setState({ loading: true });
    this.setState({ pageIndex: 1 });
    this.setState({ startPage: 1 });
    this.setState({
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getConfig(1, 20);
      this.setState({ loading: false });
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType == 'view') {
      this.getStocksModal(1, 20, requests.batchId);
      // this.getPosRequestDetails(requests.id);
      // this.setState({
      //   requestDetails: requests,
      // });
    }
    // this.setState({
    //   requestId: requests.requestId,
    //   actionType,
    // });
  }

  render() {
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        {/* <ContentHead
          title={"POS Management"}
          subtitle={"POS Config Upload"}
          filterPermissions={[CAN_REMAP_DEVICE]}
          actionButtonText={'Upload POS Config'}
          actionButtonUrl={'/home/pos-management/upload-config'}
          actionButtonRequiredPermissions={[CAN_REMAP_DEVICE]}
          totalCount={56}
        /> */}
        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <UploadStockSearch
              handleSearchRequest={this.handleSearchRequest}
              searchParameters={this.state.searchParameters}
            />
            <UploadStockTable
              data={this.state.stocks}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getStocks={this.getConfig}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canRemapDevice={CAN_REMAP_DEVICE}
              canLoadData={this.state.canLoadData}
              downloadStocks={this.downloadStocks}
              getClickedAction={this.getClickedAction}
              viewMore={this.getPosRequestDetails}
              // searchParameters={this.state.searchParameters}
              // responseCode={this.state.searchParameters.responseCode}
              // startDate={this.state.searchParameters.startDate}
              // canView={CAN_GET_DEVICES_BY_UUID}
            />
            <UploadStockDetailsModal
              data={this.state.stocks}
              errorMessage2={this.state.errorMessage2}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getStocks={this.getStocksModal}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canRemapDevice={CAN_REMAP_DEVICE}
              canLoadData={this.state.canLoadData}
              // downloadStocks={this.downloadStocks}
              // getClickedAction={this.getClickedAction}
              viewMore={this.getPosRequestDetails}
              searchParameters={this.state.searchParameters}
              // posRequestDetails={this.state.posRequestDetails}
              // loading={this.state.isLoading}
              // confirmConfigure={this.confirmConfigure}
              // cancelPosRequest={this.cancelPosRequestByStockId}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
