import React from 'react';
import { Redirect } from 'react-router-dom';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../constants/api';
import Handlers from '../../../../../../../fixtures/handlers.json';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';

export default class AddServiceTypeScene extends BaseScene {
  
  notificationSectionRef = React.createRef(null)
  
  constructor (props) {
    super(props)

    this.state = {
      handlers: [],
      sharingParties: [],
      revenueSplits: [],
      totalQuota: 100.00,
      externalServiceType: 0,
      transactionInterval: 1,
      externalServiceTypes: [
        {"id": 1, "name": "Recharge"},
        {"id": 2, "name": "Transfer"},
        {"id": 3, "name": "Bill"},
        {"id": 4, "name": "Cash_in"}
      ]
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.addRevenueSplit = this.addRevenueSplit.bind(this)
    this.getSharingPartiesLeft = this.getSharingPartiesLeft.bind(this)
  }

  componentDidMount() {
    this.setState({
      handlers: Handlers,
      isTransactionIntervalNegative: !this.validateNegativeNumber(this.state.transactionInterval),
    })
    this.getSharingParties()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getSharingParties () {
    const responseObj = await feeConfigurationManagement.getRevenueSharingParties()
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        sharingParties: response.data,
      })
    }else{
      this.setState({
        sharingParties: []
      })
    }
  }

  toOneDecimal (value) {
    return parseFloat(value).toFixed(1);
  }

  toTwoDecimal (value) {
    return parseFloat(value).toFixed(2);
  }

  getSharingPartyName(partyId) {
    const sharingParty = this.state.sharingParties.filter(value => value.id == partyId)
    return sharingParty[0].name;
  }

  checkforNegativity () {
    return (this.isMaximumAmountNegative || this.isMinimumAmountNegative || this.isTransactionIntervalNegative)
  }

  async addRevenueSplit (e) {
    e.preventDefault()
    const revenueSplit = {
      "id": Date.now(),
      "sharingPartyId": this.state.sharingPartyId,
      "quota": parseFloat(this.state.quota)
    }

    //this.getSharingPartiesLeft(this.state.sharingPartyId);

    const sharingParties = (this.state.sharingParties).filter(function(value) { 
      if(value.id == revenueSplit.sharingPartyId) {
        value.selected = 0
      }  
      return value
    });

    this.setState({
        revenueSplits: [
            ...this.state.revenueSplits,
            revenueSplit,
        ],
        totalQuota: this.toOneDecimal(parseFloat(this.state.totalQuota) - parseFloat(this.state.quota)),
        isQuotaValid: Boolean((parseFloat(this.state.totalQuota)-parseFloat(this.state.quota)) >= parseFloat(this.state.quota)),
        sharingParties,
        sharingPartyId: null,
    })
    
  }

  async removeRevenueSplit(id) {
    const revenueSplits = (this.state.revenueSplits).filter(function(value) { 
        return value.id != id;  
    });

    const removedRevenueSplit = (this.state.revenueSplits).filter(function(value) { 
      return value.id == id;  
    });

    const sharingParties = (this.state.sharingParties).filter(function(value) { 
      if(value.id == removedRevenueSplit[0].sharingPartyId) {
        value.selected = 1
      }  
      return value
    });

    this.setState({
        revenueSplits,
        totalQuota: this.toOneDecimal(parseFloat(this.state.totalQuota) + parseFloat(removedRevenueSplit[0].quota)),
        isQuotaValid: Boolean((parseFloat(this.state.totalQuota) + parseFloat(removedRevenueSplit[0].quota)) >= parseFloat(this.state.quota)),
        sharingParties,
        //quota: this.state.totalQuota + parseInt(removedRevenueSplit[0].quota),
    })
  }

  splitPartyName (name) {
    return name.replace(/ /g, "_")
  }

  getSharingPartiesLeft (sharingPartyId) {
    const sharingParties = (this.state.sharingParties).map(function(value) { 
      return value.id == sharingPartyId ? value.status = 0 : '';  
    });

    this.setState({sharingParties})

  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})

    const responseObj = await feeConfigurationManagement.createServiceType(this.state)
    const { status, response } = responseObj
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
    if (status === SUCCESS_STATUS) {
      if(response.code == ACCEPTED_REQUEST){
        this.setState({
          isLoading: false,
          successMessage: 'Your request to add a new service type has been sent successfully for approval.',
        })
        return
      }else{
        this.setState({
          isLoading: false,
          successMessage: 'Service type created successfully.',
          redirect: true,
        })
      }
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response,
      })
    }
    return
    
  }

  validateNegativeNumber (value) {
    return Boolean (parseFloat(value) > 0)
  }

  validateSharingQuotas () {
    let totalQuota = 0.0;
    this.state.revenueSplits.map(value => {
      totalQuota = parseFloat(value.quota) + totalQuota
    })
    return Boolean (parseFloat(totalQuota) >= 100.00)
  }

  validateSharingQuota (quota) {
    return Boolean (parseFloat(this.state.totalQuota) >= parseFloat(quota) && parseFloat(quota) > 0)
  }

  isRevenueSplitsValid () {
    if(this.state.revenueSplits.length > 0) {
      return this.validateSharingQuotas()
    }else{
      return true
    }
  }


  isformValid () {
    return this.state.isMaximumAmountValid || !this.state.name || !this.state.displayName || !this.state.minimumAmount || !this.state.maximumAmount || !this.state.transactionInterval || !this.state.handler || this.state.isTransactionIntervalNegative
      || this.state.isMaximumAmountNegative || this.state.isMinimumAmountNegative
  }

  validateMaxField (minValue, maxValue) {
    return Boolean (parseInt(maxValue) > parseInt(minValue))
  }

  checkMinField (minValue) {
    return Boolean (minValue)
  }

  render () {
    
    if(this.state.redirect) {
      return <Redirect to="/home/service-types-management" />
    }
    const ctaWidgets = <React.Fragment>
      <button type="button" disabled={this.isformValid()} class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        ref={this.notificationSectionRef}
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Service Types"} withoutSearch subtitle="Add Service Type" ctaWidgets={ctaWidgets} />
      <GridLayout>
        <div class="kt-portlet">

          <form class="kt-form kt-form--label-right">
            <div class="kt-portlet__body">
              <div class="form-group col-lg-6">
                <label>Name *:<br></br></label>
                <input autoComplete="off" value={this.state.name} type="text" class="form-control" placeholder="Name" onChange={event => this.setState({
                  name: this.splitPartyName(event.target.value)
                })} />
              </div>

              <div class="form-group col-lg-6">
                <label>Display Name *:<br></br></label>
                <input autoComplete="off" value={this.state.displayName} type="text" class="form-control" placeholder="Display Name" onChange={event => this.setState({
                  displayName: this.splitPartyName(event.target.value)
                })} />
              </div>

              <div class="form-group col-lg-6">
                <label>Minimum Amount <span>(&#8358;)</span> *:</label>
                <input autoComplete="off" step=".01" type="number" min="1" class="form-control" placeholder="2000" onChange={event => this.setState({
                  minimumAmount: (this.toTwoDecimal(event.target.value)) * 100,
                  isMinimumAmountNegative: !this.validateNegativeNumber(this.toTwoDecimal(event.target.value)),
                  isMaximumAmountValid: !this.validateMaxField((this.toTwoDecimal(event.target.value)) * 100, this.state.maximumAmount)
                })} />
                {this.state.isMinimumAmountNegative && <span style={{color: 'red'}}>Minimum amount must be greater than 0</span>}
              </div>

              <div class="form-group col-lg-6">
                <label>Maximum Amount <span>(&#8358;)</span> *:</label>
                <input autoComplete="off" step=".01" type="number" min="1" class="form-control" placeholder="40000" onChange={event => this.setState({
                  maximumAmount: (this.toTwoDecimal(event.target.value)) * 100,
                  isMaximumAmountNegative: !this.validateNegativeNumber(this.toTwoDecimal(event.target.value)),
                  isMaximumAmountValid: !this.validateMaxField(this.state.minimumAmount, this.toTwoDecimal((event.target.value)) * 100) 
                })} />
                {this.state.isMaximumAmountNegative && <span style={{color: 'red'}}>Maximum amount must be greater than 0</span>}<br/>
                {this.state.isMaximumAmountValid && <span style={{color: 'red'}}>Maximum amount must be greater than minimum amount</span>}
              </div>

              {/* <div class="form-group col-lg-6">
                <label>Transaction Succession Interval *:<br></br></label>
                <input autoComplete="off" type="number" min="1" class="form-control" placeholder="2" onChange={event => this.setState({
                  transactionInterval: event.target.value,
                  isTransactionIntervalNegative: !this.validateNegativeNumber(event.target.value),
                })} />
                {this.state.isTransactionIntervalNegative && <span style={{color: 'red'}}>Transaction interval amount must be greater than 0</span>}
              </div> */}

              <div class="form-group col-lg-6">
                <label>Handler *:</label>
                <select autoComplete="off" class="form-control input-large" data-live-search="true" onChange={(event) => this.setState({handler: event.target.value})}>
						      <option selected disabled>Select</option>
                  {this.state.handlers.map((value, index) => <option value={value.id} key={value.id}>{value.name}</option>)}
                </select>
              </div>

              {this.state.handler == "0" && <div class="form-group col-lg-6">
                <label>External Service Type *:</label>
                <select autoComplete="off" class="form-control input-large" data-live-search="true" onChange={(event) => this.setState({externalServiceType: event.target.value})}>
						      <option value={0}>None</option>
                  {this.state.externalServiceTypes.map((value, index) => <option value={value.id} key={value.id}>{value.name}</option>)}
                </select>
              </div>}

              {/* <div class="form-group col-lg-12 row">
                    <div class="col-lg-3">
                        <label>Revenue Sharing Party</label>
                    </div>
                    <div class="col-lg-2">
                        <label>Quota (100%)</label>
                      </div>
                </div>
              {this.state.revenueSplits.map(value =>
                    <div key={value.id}>
                     <div class="form-group col-lg-12 row" style={{marginBottom: '0px'}}>
                        <div class="col-lg-3">
                            {this.getSharingPartyName(value.sharingPartyId)}
                        </div>
                        <div class="col-lg-2">
                            {this.toOneDecimal(value.quota)}%
                        </div>
                        <div class="col-lg-3">
                            <a style={{cursor: 'pointer'}} onClick={this.removeRevenueSplit.bind(this,value.id)}>Delete</a>
                        </div>
                    </div>
                    <hr style={{width: '460px', float: 'left'}}/>
                    </div>
                )}
              <div class="form-group col-lg-12 row">
                    <div class="col-lg-3">
                        <select autoComplete="off" class="form-control input-large" defaultValue={this.state.sharingPartyId} data-live-search="true" onChange={(event) => this.setState({sharingPartyId: event.target.value})}>
                            <option selected>Select</option>
                            {this.state.sharingParties.map((value, index) => {
                              if(value.selected != 0) {
                                return (
                                  <option value={value.id} key={value.id}>{value.name}</option>
                                )
                              }
                            })}
                        </select>
                    </div>
                    <div class="col-lg-2">
                      <div class="kt-input-icon">
                        <input type="number" defaultValue={this.state.quota} class="form-control" placeholder={parseFloat(this.state.totalQuota)} 
                          onChange={(event) => this.setState({
                            quota: this.toOneDecimal(event.target.value),
                            isQuotaValid: this.validateSharingQuota(this.toOneDecimal(event.target.value))
                          })} 
                        /><span class="kt-input-icon__icon kt-input-icon__icon--right"><span>
                            %
                        </span>
                        
                      </span>
                      </div>
                    </div>
                    <div class="col-lg-1">
                        <button class="btn form-control" disabled={!this.state.isQuotaValid || this.validateSharingQuotas() || !this.state.sharingPartyId || !this.state.quota} style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.addRevenueSplit}>Add</button>
                    </div>
                </div> */}
            </div>
          </form>
        </div>
      </GridLayout>
    </React.Fragment>
  }
}
