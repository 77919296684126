import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import { formatDate } from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";

export default class AgentTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getSerialNumber(index) {
    return this.props.pageSize * (this.props.page - 1) + index;
  }

  render() {
    const agentData = this.props.data && this.props.data;
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value.toLowerCase()}</div>
        ),
        width: 250,
      },
      {
        Header: "Agent Code",
        accessor: "agentCode",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
        width: 200,
      },
      {
        Header: "Date Exempted",
        accessor: "skipDateCreated",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Modified Date",
        accessor: "skipModifiedDate",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Exemption Status",
        accessor: "skipCacTinCheck",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData[props.index].skipCacTinCheck ? (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                True
              </span>
            ) : (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                False
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "Agent Class",
        accessor: "agentClass",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? props.value.toLowerCase() : null}
          </div>
        ),
        width: 120,
      },
      {
        Header: "Action",
        accessor: "agentId",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: ({ row, original }) => (
          <div class="dropdown" style={{ textAlign: "center" }}>
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canRetire]}>
                <a
                  onClick={() =>
                    this.props.getClickedAction(
                      original.agentId,
                      original.businessName
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-lock"></i>
                  Remove
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
        width: 60,
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={AgentDetailsExportSerializer.reverseAgentDetails(agentData)}
          headers={AgentDetailsExportSerializer.headerExport()}
          fileName={"agents"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAgents}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          downloadLink={this.props.downloadAgentData}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
            justifyContent:'center',

          }}
          data={agentData}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={agentData.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAgents(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
              justifyContent: "center", 

            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              justifyContent: "center", 
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
