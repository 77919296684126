import React from 'react';
import ContentHead from '../../../../../../components/content-head';
import Table from '../../../../../../components/table';
import BaseScene from '../../../../../../components/scenes/component';
import { PromptModal } from '../../../../../../components/modals/prompt-modal';
import { SUCCESS_STATUS, ERROR_STATUS, ACCEPTED_REQUEST } from '../../../../../../constants/api';
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { GridLayout } from '../../../../../../components/layouts/grid-layout'
import { platformManagement } from '../../../../../../mixins/api'
import { retrieveSessionInformation } from '../../../../../../mixins/authentication'
import { UsersExportSerializer } from '../../../../../../serializers'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_CREATE_USER, CAN_VIEW_USER, CAN_ACTIVATE_USER, CAN_DEACTIVATE_USER , CAN_DELETE_USER} from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'
import { pastTenseFormatter } from '../../../../../../utils/formatter'

export default class UsersGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
    },
    {
      Header: "First Name",
      accessor: 'firstName',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 150,
      Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
    },
    {
      Header: "Last Name",
      accessor: 'lastName',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 150,
      Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
    },
    {
      Header: "Phone Number",
      accessor: 'mobileNo',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 120,
    },
    {
      Header: "Email",
      accessor: 'email',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Status",
      accessor: 'status',
      resizable: false,
      width: 80,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center'
          },
        };
      },
      Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge ${props.value === 1 ? 'kt-badge--success' : props.value === 2 ? 'kt-badge--warning' : props.value === 3 ? 'kt-badge--danger' : props.value === 4 ? 'kt-badge--info' : ''} kt-badge--inline kt-badge--pill`}>{props.value === 1 ? 'Active' : props.value === 2 ? "Inactive" : props.value === 3 ? "Retired" : props.value === 4 ? "Dormant" : ''}</span>
    },
    {
      Header: "Action",
      accessor: 'email',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: '10px',
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 60,
      Cell: ({row, original}) => <div class="dropdown">
        <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
          <i class="la la-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <ProtectedComponent requiredPermissions={[CAN_VIEW_USER]}><Link to={`user-management/${row.email}/view`} class="dropdown-item">
            <i class="la la-eye"></i>
            View
          </Link></ProtectedComponent>
          <ProtectedComponent requiredPermissions={[CAN_ACTIVATE_USER]}><a hidden={(original.status === 2 || original.status === 4) ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(row.email, row.firstName, row.lastName, 'activate')} /*href={`/home/user-management/${'activate'}/${row.email}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
            <i class="la la-toggle-on"></i>
            Activate
          </a></ProtectedComponent>
          <ProtectedComponent requiredPermissions={[CAN_DEACTIVATE_USER]}><a hidden={original.status === 1 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(row.email, row.firstName, row.lastName, 'suspend')} /*href={`/home/user-management/${'suspend'}/${row.email}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
            <i class="la la-ban"></i>
            Suspend
          </a></ProtectedComponent>
          <ProtectedComponent requiredPermissions={[CAN_DELETE_USER]}><a hidden={original.status !== 3 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(row.email, row.firstName, row.lastName, 'retire')} /*href={`/home/user-management/${'retire'}/${row.email}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
            <i class="la la-lock"></i>
            Retire
          </a></ProtectedComponent>
        </div>
      </div>
    }
  ]

  state = {
    users: [],
  }
  sceneName = "user-management"
  updateUser = this.updateUser.bind(this)
  getUsers = this.getUsers.bind(this)

  componentDidMount () {
    const currentUser = retrieveSessionInformation()
    this.domainCode = currentUser.user.domainCode
    this.getUsers()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }
  
  async getUsers () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getUsers(this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        users: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        isLoading: false,
        dataCount: 0,
        users: [],
        errorMessage: response
      })
    }
  }

  getClickedAction (username, firstName, lastName, status) {
    this.setState({
      username: username,
      userStatus: status,
      firstName,
      lastName,
      modalIsOpen: true,
    })
  }

  async updateUser () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.updateUserStatus(this.state.userStatus, this.state.username, this.domainCode)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      if(response.code == ACCEPTED_REQUEST) {
        this.setState({
          username: null,
          userStatus: null,
          lastName: null,
          firstName: null,
          successMessage: `Your request to ${this.state.userStatus} ${this.state.lastName} ${this.state.firstName} as a user has been sent successfully for approval.`,
          isLoading: false,
        })
      }else{
        this.setState({
          username: null,
          userStatus: null,
          lastName: null,
          firstName: null,
          successMessage: `${this.state.lastName} ${this.state.firstName} was successfully ${pastTenseFormatter(this.state.userStatus)} as a user.`,
          isLoading: false,
        })
      }
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getUsers()
  }

  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} subtitle={"All Users"} getRolesUsers={this.getDomainUsers} filterPermissions={[CAN_VIEW_USER]} getDomains={this.getDomains} totalCount={56} actionButtonText={"Add User"} actionButtonUrl={"/home/user-management/add"} actionButtonRequiredPermissions={[CAN_CREATE_USER]} />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_USER]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <Table 
            columns={this.columns} 
            data={this.state.users} 
            loading={this.state.isLoading} 
            noDataText={`No users found`}
            dataCount={this.state.dataCount}
            getData={this.getUsers}
            exportData={UsersExportSerializer.reverseUserDetails(this.state.users)}
            exportHeaders={UsersExportSerializer.headerExport()}
            exportFileName={'Users'}
          />
          <PromptModal
            modalTitle={ this.state.userStatus+` User`}
            modalBody={`Do you want to ${this.state.userStatus} ${this.state.firstName} ${this.state.lastName} as a user?`}
            deleteModal={this.updateUser}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
