import React from 'react'
import Select from '../../../../../../components/select'

export default class FeeRuleForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <p>When</p>
                        <div class="form-group row">
                            <div class="col-lg-2">
                                <label>Service Type is</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.serviceTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.updateFeeRuleFormData('serviceType', event.value)
                                        this.props.getServiceTypeById(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.feeRuleFormData.serviceType}
                                    isLoading={this.props.isGetServiceTypeLoading}
                                    minHeight={40}
                                />
                                {/* <select name="serviceType" class="form-control" disabled={this.props.disabled} onChange={event => {
                                    this.props.updateFeeRuleFormData('serviceType', event.target.value)
                                    this.props.getServiceTypeById(event.target.value)
                                }} data-live-search="true">
                                    <option value={0}>Any</option>
                                    {this.props.serviceTypes.map(value => <option value={value.id} selected={this.props.feeRuleFormData.serviceType === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                                {!this.props.feeRuleFormData.serviceType && <span style={{color: 'red'}}>Invalid service type</span>}
                            </div>
                            <div class="col-lg-2">
                                <label>AND Service is</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.services.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.updateFeeRuleFormData('service', event.value)
                                        this.props.getServiceById(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.feeRuleFormData.service}
                                    isLoading={this.props.isServiceLoading}
                                    minHeight={40}
                                />
                                {/* <select name="service" class="form-control" disabled={this.props.disabled} onChange={event => {
                                    this.props.updateFeeRuleFormData('service', event.target.value)
                                    this.props.getServiceById(event.target.value)
                                }} >
                                    <option value={0}>Any</option>
                                    {this.props.services.map(value => <option value={value.id} selected={this.props.feeRuleFormData.service === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                {this.props.isServiceLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                    <span class="la la-spin la-refresh"></span>
                                </div>} */}
                            </div>
                        </div>
                        <p>AND</p>
                        <div class="form-group row">
                            <div class="col-lg-2">
                                <label>Service Option is</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.serviceOptions.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => this.props.updateFeeRuleFormData('serviceOption', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.feeRuleFormData.serviceOption}
                                    isLoading={this.props.isServiceOptionLoading}
                                    minHeight={40}
                                />
                                {/* <select name="serviceOption" class="form-control" disabled={this.props.disabled} onChange={event => this.props.updateFeeRuleFormData('serviceOption', event.target.value)}>
                                    <option value={0}>Any</option>
                                    {this.props.serviceOptions.map(value => <option value={value.id} selected={this.props.feeRuleFormData.serviceOption === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                {this.props.isServiceOptionLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                    <span class="la la-spin la-refresh"></span>
                                </div>} */}
                            </div>
                            <div class="col-lg-3">
                                <label>AND Agent Category Type is</label>
                            </div>
                            <div class="col-lg-3">
                                <Select 
                                    options={this.props.agentTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.getAgentCategories(event.value)
                                        this.props.updateFeeRuleFormData('agentCategoryType', event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.feeRuleFormData.agentCategoryType}
                                    minHeight={40}
                                />
                                {/* <select name="agentcategory" class="form-control" disabled={this.props.disabled} onChange={event => {
                                    this.props.getAgentCategories(event.target.value)
                                    this.props.updateFeeRuleFormData('agentCategoryType', event.target.value)
                                }}>
                                    <option value={0}>Any</option>
                                    {this.props.agentTypes.map(value => <option value={value.id}>{value.name}</option>)}
                                </select> */}
                            </div>
                        </div>
                        <p>AND</p>
                        <div class="form-group row">
                            <div class="col-lg-3">
                                <label>Agent Category is</label>
                            </div>
                            <div class="col-lg-3">
                                <Select 
                                    options={this.props.agentCategories.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => this.props.updateFeeRuleFormData('agentCategory', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.feeRuleFormData.agentCategory}
                                    minHeight={40}
                                    isLoading={this.props.isAgentCategoryLoading}
                                />
                                {/* <select name="agentcategory" class="form-control" disabled={this.props.disabled} onChange={event => this.props.updateFeeRuleFormData('agentCategory', event.target.value)}>
                                    <option value={0}>Any</option>
                                    {this.props.agentCategories.map(value => <option value={value.id} selected={this.props.feeRuleFormData.agentCategory === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                {this.props.isAgentCategoryLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                    <span class="la la-spin la-refresh"></span>
                                </div>} */}
                            </div>
                        </div>

                        <p>Apply</p>
                        <div class="form-group row">
                            <div class="col-lg-1">
                                <label>Fee</label>
                            </div>
                            <div class="col-lg-4">
                                <Select 
                                    options={this.props.fees.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => this.props.updateFeeRuleFormData('feeToApply', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.feeRuleFormData.feeToApply}
                                    minHeight={40}
                                    isLoading={this.props.isFeeLoading}
                                />
                                {/* <select name="feeToApply" class="form-control" disabled={this.props.disabled} onChange={event => this.props.updateFeeRuleFormData('feeToApply', event.target.value)}>
                                    <option selected disabled>Select</option>
                                    {this.props.fees.map(value => <option value={value.id} selected={this.props.feeRuleFormData.feeToApply === value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}