import React from 'react';
import ContentHead from '../../../../../../../../../components/content-head';
import BaseScene from '../../../../../../../../../components/scenes/component';
import { CAN_EOD_REPORT_VIEW } from '../../../../../../../../../constants/permissions.js';
import { SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../../../components/access-denied'
import { formatBackEndDateTime, formatCurrency } from '../../../../../../../../../utils/formatter';
import { convertToMajor } from '../../../../../../../../../utils/converter'
import { getDateByType } from '../../../../../../../../../utils/helper';
import { transactionHistory } from '../../../../../../../../../mixins/api'
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import EndOfDayLedgerSearch from '../../components/end-of-day-ledger-search'
import Notification from '../../../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../../../mixins/protected-component';
import Table from '../../../../../../../../../components/table';
import { EndOfDayReportExportSerializer } from '../../../../../../../../../serializers'

export default class EndOfDayLedgerReportsGridScene extends BaseScene {
  columns = [
    {
      Header: 'Date Created',
      accessor: 'dateCreated',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: 'Account Name',
      accessor: 'accountName',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Account Number",
      accessor: 'accountNumber',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 150,
    },
    {
      Header: "Agent ID",
      accessor: 'agentID',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 130,
    },
    {
      Header: "Ledger Balance",
      accessor: 'ledgerBalance',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 140,
      Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
    {
      Header: "Commission Balance",
      accessor: 'commBalance',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 140,
      Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
    {
      Header: "Lien Balance",
      accessor: 'lienBalance',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      width: 140,
      Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
  ]
  
  sceneName = 'wallet-management-end-of-day-reports-grid'

  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);

    this.state = {
      balances: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
      },
      canLoadData: false,
      tableMessage: "",
      isAccountNamesLoading: false,
      accountNames: [],
    }
    this.getEndOfDayLedger = this.getEndOfDayLedger.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this)
  }
  
  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  componentDidMount() {
    this.getAccountNames()
  }

  async getEndOfDayLedger () {
    const {
      endDate,
      startDate,
      agentCode,
     } = this.state.searchParameters

    this.setState({
      canLoadData: true,
      isLoading: true,
    });
     
    const formattedEndDate = endDate && formatBackEndDateTime(endDate);
    const formattedStartDate = startDate && formatBackEndDateTime(startDate);

    const { status, response, count } = (
      await transactionHistory.retrieveEodLedgerReport(
        formattedStartDate, formattedEndDate, agentCode
      )
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        balances: response ? response : [],
        dataCount: count,
        isLoading: false,
      })
    } else {
      this.setState({
        balances: [],
        dataCount: 0,
        isLoading: false,
        tableMessage: "No end of day ledger found"
      })
    }
  }

  updateSearchParameters (type, value) {
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  async getAccountNames () {
    this.setState({ isAccountNamesLoading: true })
    const { status, response } = (
      await transactionHistory.retrieveTrialBalanceReport()
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        accountNames: response ? response.ledgerBalances : [],
        isAccountNamesLoading: false
      })
    } else {
      this.setState({
        accountNames:[],
        isAccountNamesLoading: false
      })
    }
  }

  render () {
    return <React.Fragment>
      <ContentHead title={"Wallet Management"} subtitle={"End of Day Ledger Report"} filterPermissions={[CAN_EOD_REPORT_VIEW]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_EOD_REPORT_VIEW]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <EndOfDayLedgerSearch 
            searchParameters={this.state.searchParameters}
            updateSearchParameters={this.updateSearchParameters}
            handleSearchRequest={this.getEndOfDayLedger}
            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
            accountNames={this.state.accountNames}
            isAccountNamesLoading={this.state.isAccountNamesLoading}
          />
           <Table 
            columns={this.columns} 
            data={this.state.balances} 
            loading={this.state.isLoading} 
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            getData={this.getEndOfDayLedger}
            exportData={EndOfDayReportExportSerializer.reverseEndOfDayReportDetails(this.state.balances)}
            exportHeaders={EndOfDayReportExportSerializer.headerExport()}
            exportFileName={'end_of_day_ledger-report'}
            
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
