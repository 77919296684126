import React from 'react'
import { DatePickerComponent } from '../../../../../components/date-picker'
import { getDateByType } from '../../../../../utils/helper'

export default class NotificationGroupSearch extends React.Component {
    state = {
        topicsSearchParameters: {
            fromDate: !process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA ? getDateByType("startDate") : null,
            toDate: !process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA ? getDateByType("endDate") : null,
            // approvalStatus: "All",
            // agentTypeId: "All"
        },
        applicationCategories: [
            {label: "All", value: "All"},
            {label: "Agent", value: 4},
            {label: "Aggregator", value: 3}
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        console.log('>>>SP', this.state.topicsSearchParameters)
        this.props.handleSearchRequest(this.state.topicsSearchParameters)
    }

    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="search-label">Query Name:</label>
                        <input type="text" class="search-input" onChange={event => this.setState({topicsSearchParameters: {...this.state.topicsSearchParameters, queryName: event.target.value ? event.target.value : null}})}/> 
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.setState({topicsSearchParameters:{...this.state.topicsSearchParameters, fromDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent
                            onChange={(event) => this.setState({topicsSearchParameters: {...this.state.topicsSearchParameters, toDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.topicsSearchParameters.fromDate ? false : true}
                            minDate={new Date(this.state.topicsSearchParameters.fromDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                    
                </div>
            </form>
        </React.Fragment>
    }
}