import axios from 'axios';
import {
  DELETE_METHOD,
  GET_METHOD,
  POST_METHOD,
  PUT_METHOD,
} from '../../constants/api';
import {
  getAccessToken,
  isAuthTokenExpired,
  refreshUserSession,
} from '../../mixins/authentication';
//import { UserManagement } from '../../mixins/api'
import { debounce } from 'lodash';
import { getCachedItem, saveCachedItem } from '../../utils/helper';
const AUTH_TOKEN_EXPIRY = 'auth_token_expiry';
export default class Requester {
  constructor(props) {
    const { apiBaseUrl } = props;
    this.apiBaseUrl = apiBaseUrl;
  }

  _getFullUrl(endpoint) {
    return `${this.apiBaseUrl}/${endpoint}`;
  }

  _handleError(error) {
    if (error.response && error.response.status === 401) {
      this._handle401Error();
    }
    return error.response ? error.response.data : {};
  }

  _handle401Error() {
    const authToken = getAccessToken();
    if (authToken) {
      // clearLocalStorage();
      // window.location.href = '/admin/logout';
    }
    return;
  }

  _handleResponse(response) {
    return response.data.responseData || response.data.content || response.data;
  }

  _handleResponseCount(response) {
    return response.data.count || response.data.totalElements;
  }

  async _makeHttpRequest(params) {
    const authToken = getAccessToken();
    let { url, method, headers, args, body } = params; //console.log(JSON.stringify(headers))
    const callRefreshToken = await isAuthTokenExpired();
    if (authToken && callRefreshToken) {
      debounce(async () => await refreshUserSession(), 2000);
    }

    if (headers && headers['NoAuthorization'] && !headers['download']) {
      headers = {
        ...headers,
      };
    } else if (headers && headers['NoAuthorization'] && headers['download']) {
      headers['content-type'] = 'application/vnd.ms-excel';
    } else if (headers && headers['download']) {
      headers['content-type'] = 'application/vnd.ms-excel';
      headers['Authorization'] = `${authToken}`;
    } else if (headers && headers['content-type']) {
      headers['Authorization'] = `${authToken}`;
    } else if (headers) {
      headers['content-type'] = 'application/json';
      headers['Authorization'] = `${authToken}`;
    } else {
      headers = {
        'content-type': 'application/json',
        Authorization: `${authToken}`,
      };
    }

    return axios({
      url,
      method,
      headers,
      responseType: headers['download'] ? 'arraybuffer' : '',
      params: args,
      data: body,
      mode: 'no-cors',
      proxy: {
        host: '172.16.10.20',
        port: 8080,
      },
    });
  }

  async post(params) {
    const { endpoint, headers, body, args } = params;
    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: POST_METHOD,
        headers: headers,
        body: body,
        args: args,
      });
      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        count: this._handleResponseCount(response),
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
      };
    }
  }

  async get(params) {
    const { endpoint, headers, args, cache, cacheKey } = params;

    if (cache) {
      const cachedData = getCachedItem(cacheKey);
      if (cachedData) {
        return JSON.parse(cachedData);
      }
    }

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: GET_METHOD,
        headers: headers,
        args: args,
        body: null,
      });

      const result = {
        status: 'SUCCESS',
        response: this._handleResponse(response),
        count: this._handleResponseCount(response),
      };

      if (cache) {
        saveCachedItem(cacheKey, JSON.stringify(result));
      }

      return result;
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
      };
    }
  }

  async put(params) {
    const { endpoint, headers, body, args } = params;

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: PUT_METHOD,
        headers: headers,
        args: args,
        body: body,
      });
      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
      };
    }
  }

  async delete(params) {
    const { endpoint, headers, body, args } = params;

    try {
      const response = await this._makeHttpRequest({
        url: this._getFullUrl(endpoint),
        method: DELETE_METHOD,
        headers: headers,
        args: args,
        body: body,
      });
      return {
        status: 'SUCCESS',
        response: this._handleResponse(response),
      };
    } catch (error) {
      return {
        status: 'ERROR',
        response: this._handleError(error),
      };
    }
  }
}
