import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridItem } from "../../../../../../../components/layouts/grid-item";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { CAN_CREATE_FEE } from "../../../../../../../constants/permissions.js";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import ExternalFeeForm from "../../components/external-fee-form";

export default class AddExternalFeeScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      canSave: null,
      categories: [],
      services: [],
      serviceOptions: [],
      externalFeeData: {
        useServiceId: false,
      },
      canWhitelist: false,
      serviceTypes: [],
    };
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onUpdateExternalFeeData = this.onUpdateExternalFeeData.bind(this);
    this.getServiceOptionsByServiceId = this.getServiceOptionsByServiceId.bind(
      this
    );
    this.getServiceTypeServices = this.getServiceTypeServices.bind(this);
  }

  componentDidMount() {
    this.getServiceTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getServiceTypes() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceTypes(1, 20);
    const { status, response, count } = responseObj;
    if (count > 20) {
      this.getMoreServiceTypes(1, count);
    }
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceTypes: response ? response.data : [],
        isLoading: false,
      });
    } else {
      this.setState({
        serviceTypes: [],
        isLoading: false,
      });
    }
  }

  async getMoreServiceTypes(pageNum, pageSize) {
    const responseObj = await feeConfigurationManagement.getServiceTypes(
      pageNum,
      pageSize
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceTypes: response ? response.data : this.state.serviceTypes,
      });
    } else {
      this.setState({
        serviceTypes: this.state.serviceTypes,
      });
    }
  }

  async getServiceTypeServices(serviceTypeId) {
    this.setState({
      services: [],
      serviceOptions: [],
      isServiceLoading: true,
      isServiceEmpty: false,
      canWhitelist: false,
    });
    const withServices = true;
    const responseObj = await feeConfigurationManagement.getServiceType(
      serviceTypeId,
      withServices
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        services: response.services ? response.services : [],
        isServiceLoading: false,
        isServiceEmpty: response.services ? false : true,
      });
    } else {
      this.setState({
        services: [],
        isServiceLoading: false,
        isServiceEmpty: false,
      });
    }
  }

  async getServiceOptionsByServiceId(serviceId) {
    this.setState({
      isServiceOptionLoading: true,
      isServiceOptionEmpty: false,
      canWhitelist: false,
    });
    const responseObj = await feeConfigurationManagement.getServiceById(
      serviceId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceOptions: response.serviceOptions ? response.serviceOptions : [],
        isServiceOptionLoading: false,
        isServiceOptionEmpty: response.serviceOptions ? false : true,
        canWhitelist: response.serviceOptions ? true : false,
      });
    } else {
      this.setState({
        serviceOptions: [],
        isServiceOptionLoading: false,
        isServiceOptionEmpty: false,
        canWhitelist: false,
      });
    }
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.addExternalFee(
      this.state.externalFeeData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new external fee has been sent successfully for approval.",
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "External fee created successfully.",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  isformValid() {
    return (
      !this.state.externalFeeData.serviceOptionCode &&
      !this.state.externalFeeData.useServiceId
    );
  }

  onUpdateExternalFeeData(type, value) {
    this.setState({
      externalFeeData: { ...this.state.externalFeeData, [type]: value },
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/external-fees" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_FEE]}>
          <button
            type="button"
            disabled={this.isformValid()}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Whitelist For External Fee
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"External Fees"}
          withoutSearch
          subtitle="Add External Fee"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_CREATE_FEE]}
        >
          <GridItem isFluid class="kt-content">
            <ExternalFeeForm
              categories={this.state.categories}
              serviceTypes={this.state.serviceTypes}
              serviceOptions={this.state.serviceOptions}
              services={this.state.services}
              onChange={this.onUpdateExternalFeeData}
              externalFeeData={this.state.externalFeeData}
              getCategoryServices={this.getCategoryServices}
              getServiceTypeServices={this.getServiceTypeServices}
              getServiceOptions={this.getServiceOptionsByServiceId}
              isServiceLoading={this.state.isServiceLoading}
              isServiceOptionLoading={this.state.isServiceOptionLoading}
              isServiceEmpty={this.state.isServiceEmpty}
              isServiceOptionEmpty={this.state.isServiceOptionEmpty}
              canWhitelist={this.state.canWhitelist}
            />
          </GridItem>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
