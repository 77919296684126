import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import ViewFipScene from './scenes/view-fip';
import UploadConfigGridScene from './scenes/upload-config-grid';
import ConfigUploadGridScene from './scenes/config-upload-grid';
// import NewStockConfigScene from './scenes/new-stock-config';
// import ViewStockScene from './scenes/view-stock';
// import AddFipScene from './scenes/add-fip'
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_REMAP_DEVICE, CAN_GET_DEVICES_BY_UUID } from '../../../../../constants/permissions.js'

export default class UploadConfigScene extends Component {
  sceneName = 'upload-config'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_GET_DEVICES_BY_UUID]} exact path='/home/pos-management/upload-config' component={UploadConfigGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_GET_DEVICES_BY_UUID]} exact path='/home/pos-management/config-upload' component={ConfigUploadGridScene} />
      {/* <ProtectedRoute requiredPermissions={[CAN_REMAP_DEVICE]} exact path='/home/pos-management/pos-stocks/:id/view' component={ViewStockScene} />
      <ProtectedRoute requiredPermissions={[CAN_REMAP_DEVICE]} exact path='/home/pos-management/pos-stocks/add' component={NewStockConfigScene} /> */}
    </Router>
  }
}
