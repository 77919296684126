import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_FIP_USER } from '../../../../../../../constants/permissions.js';
import { instrumentationService } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import TraceChart from '../../components/trace-chart';
import TraceDetails from '../../components/trace-details';

export default class ViewTraceScene extends BaseScene {
  
    requiredPermissions = [
        CAN_VIEW_FIP_USER
    ]

    constructor(props) {
        super(props);
        this.state = {
            traceData: [],
            showModal: false,
        }
    }

    sceneName = "instrumentation"
    traceDetailsSectionRef = React.createRef(null)

    componentDidMount () {
        this.traceId = this.props.match.params.id
        this.getTraceDetails(this.traceId);
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
            setTimeout(() => {
            this.setState({errorMessage: null})
            }, 5000)
        }
        if (this.state.successMessage) {
            setTimeout(() => {
            this.setState({successMessage: null})
            }, 5000)
        }
    }

    async getTraceDetails (traceId) {
        this.setState({isLoading: true})
        const responseObj = await instrumentationService.getTraceById(traceId)
        const { status, response } = responseObj
        console.log("trace by id ", response)
        if (status === SUCCESS_STATUS) {
            this.setState({
                traceDetails: response,
                isLoading: false,
            })
        }else{
            this.setState({
                traceDetails: [],
                isLoading: false,
            })
        }
    }

    handleOpenModal = () => {
        this.setState({showModal: true})
    }
      
    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    render () {

        // const ctaWidgets = <React.Fragment>
        //     {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_FIP_USER]}><button type="button" class="btn btn-secondary" style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
        //     {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_FIP_USER]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
        //     {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_FIP_USER]}><button type="button" class="btn btn-secondary" style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.onSubmitButtonClick}>Save Changes</button></ProtectedComponent>}
        // </React.Fragment>
        const ctaWidgets = <React.Fragment>
            <button data-toggle="modal" data-target="#trace_details_modal" class="btn btn-secondary" type="button" style={{backgroundColor: '#00425f', color: '#ffffff'}} >View Details</button>
        </React.Fragment>

        return <React.Fragment>
            <Loader
            isLoading={this.state.isLoading}
            />
            <Notification
            successMessage={this.state.successMessage}
            errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"Instrumentation"} subtitle={'Trace Details'} filterPermissions={[CAN_VIEW_FIP_USER]} totalCount={56} ctaWidgets={ctaWidgets} />
            <ProtectedComponent requiredPermissions={[CAN_VIEW_FIP_USER]} permissionDeniedContent={this.permissionDeniedContent}>
                <GridLayout>
                    {this.state.traceDetails && <TraceChart traceData={this.state.traceDetails} />}
                    <TraceDetails
                        traceDetailsSectionRef={this.traceDetailsSectionRef}
                        spans={this.state.traceDetails && this.state.traceDetails.spans}
                        //spans={TraceDetails.spans}
                    />
                </GridLayout>
            </ProtectedComponent>
    </React.Fragment>
  }

    get permissionDeniedContent() {
      return <AccessDenied /> 
    }
}
