import { formatLongNumber } from '../../utils/formatter';

export default class WorkflowConfigurationsExportSerializer {
    static headerExport () {
        return [
            {label: 'Module', key: 'name', title: 'Module', dataIndex: 'name'},
            {label: 'Action Type', key: 'actionType', title: 'Action Type', dataIndex: 'actionType'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'}

        ]
    }

    static reverseWorkflowConfigurationDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, actionType, active } = details
            let detailFormData = {
                name,
                actionType,
                status: active == false ? 'Inactive' : active == true ?  'Active' : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}