import React from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import BaseScene from '../../../../components/scenes/component'
import AddRoleScene from './scenes/add-role'
import RolesGridScene from './scenes/roles-grid'
import ViewRoleScene from './scenes/view-role'
import { isAuthorized } from '../../../../mixins/authentication'
import { CAN_VIEW_ROLE, CAN_CREATE_ROLE } from '../../../../constants/permissions.js'

export default class RoleManagementScene extends BaseScene {
  sceneName = 'role-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_ROLE]} exact path='/home/role-management' component={RolesGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_ROLE]} exact path='/home/role-management/add' component={AddRoleScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_ROLE]} exact path='/home/role-management/:id/view' component={ViewRoleScene} />
    </Router>
  }
}
