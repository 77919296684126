import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { TransactionExportSerializer } from '../../../../../../serializers';
import { formatCurrency, formatDate, formatTransactionStatus, formatTransactionStatusColorCode } from '../../../../../../utils/formatter';
import { getPaginationRows, getTransactionTypeLabel } from '../../../../../../utils/helper';

export default class TransactionSummaryTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '950px',
        zoom: 'plus'
      }
    
    zoomTableHeight = this.zoomTableHeight.bind(this)
    
    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }
    
    render() {
          const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        let columns = [
            {
                Header: "Transaction Date",
                accessor: 'transactionDate',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: props => <span>{props.value ? formatDate(props.value) : ''}</span>
            },
            {
                Header: "Transaction Type",
                accessor: 'transactionType',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: (props) => <span>{getTransactionTypeLabel(props.value)}</span>
            },
            {
                Header: "Count",
                accessor: 'recordCount',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
            },
            {
              Header: "Value",
              accessor: 'totalAmount',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(parseFloat(props.value)) : formatCurrency(0)}</span>
            },
            {
                Header: "Status",
                accessor: 'statusCode',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: props => {
                    return <span class={`badge ${formatTransactionStatusColorCode(props.value)}`}>{formatTransactionStatus(props.value)}</span>
                }
            },
        ]

        if(this.props.data[0] && this.props.data[0].agentName != null){
          
            columns = [
              {
                  Header: "Agent Name",
                  accessor: 'agentName',
                  resizable: false,
                  headerStyle: {'white-space': 'unset'},
                  style: {'white-space': 'unset'}
              },
              {
                  Header: "Transaction Date",
                  accessor: 'transactionDate',
                  resizable: false,
                  headerStyle: {'white-space': 'unset'},
                  style: {'white-space': 'unset'},
              },
              {
                  Header: "Transaction Type",
                  accessor: 'transactionType',
                  resizable: false,
                  headerStyle: {'white-space': 'unset'},
                  style: {'white-space': 'unset'},
              },
              {
                  Header: "Count",
                  accessor: 'recordCount',
                  resizable: false,
                  headerStyle: {'white-space': 'unset'},
                  style: {'white-space': 'unset'},
              },
              {
                Header: "Value",
                accessor: 'totalAmount',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: props => <span>&#x20A6;{props.value ? formatCurrency(parseFloat(props.value)) : formatCurrency(0)}</span>
              },
              {
                  Header: "Status",
                  accessor: 'statusCode',
                  resizable: false,
                  headerStyle: {'white-space': 'unset'},
                  style: {'white-space': 'unset'},
                  Cell: props => {
                      return <span class={`badge ${formatTransactionStatusColorCode(props.value)}`}>{formatTransactionStatus(props.value)}</span>
                  }
              },
          ]

        }
        return <React.Fragment> 
        <TableExtra 
            data={TransactionExportSerializer.reverseTransactionHistoryDetails(this.props.data)}
            headers={TransactionExportSerializer.headerExport()}
            fileName={'Transaction Summary Report'}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getTransactionSummary}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            downloadLink1={this.props.downloadTransactionSummary}
            downloadLink2={this.props.downloadTransactionSummaryDetails}
            hasMultipleDownloadLinks={true}
            disabled={this.props.loading}
        />
        <ReactTable 
            PaginationComponent={Pagination} 
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ''}
            style={{
                border: "none",
                boxShadow: "none",
                whiteSpace: 'unset',
                height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
            onFetchData={(state, instance) => {
                this.setState({page: state.page})
                if(this.props.canLoadData) {
                  this.props.getTransactionSummary(state.page + 1, state.pageSize)
                }
              }}
            headerNodeHeight={`123`}
            minRows={(this.props.data).length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
                style: {
                  borderColor: '#00425f',
                  boxSizing: 'none',
                  border: 'none',
                },
              })}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                  display:"flex",
                  overflow: "visible",
                  color: "black",
                  fontSize: "10px",
                  fontWeight: "50",
                  paddingLeft:"5px",
                  paddingRight:"5px",
                  alignItems: "center",
                  padding:  "0px, 0",
                  
                },
              })}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                  backgroundColor: "#00425f",
                  borderBottom: "0.1px solid #DBDEDE",
                  borderWidth: "0.1px",
                  padding:"10px 0",
                  color: "#FFFFFF",
                  fontSize: "11px",
                  fontWeight: "100",
                  textAlign: "left",
                  paddingLeft:"5px",
                  paddingRight:"5px",
                },
              })}
              getPaginationProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  backgroundColor: '#ffffff',
                },
              })}    
              className="-striped -highlight" 
        />
    </React.Fragment>
    }
}