import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { WalletJournalExportSerializer } from '../../../../../../serializers';
import { convertToMajor } from '../../../../../../utils/converter';
import { formatCurrency, formatDateTime, formatTransactionStatus, formatTransactionStatusColorCode } from '../../../../../../utils/formatter';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class WalletJournalTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '430px',
        zoom: 'plus'
      }
    
    zoomTableHeight = this.zoomTableHeight.bind(this)
    
    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }
    
    render() {
          const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            {
                Header: "Trans Ref",
                accessor: 'uniqueReference',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 150,
                Cell: ({ row, original }) => (
                  <span
                    className="selectable wordbreak"
                    style={{ wordBreak: "break-all" }}
                  >
                    {original.uniqueReference}
                  </span>
                ),
            },
            {
                Header: "Credit Amount",
                accessor: 'creditAmount',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 130,
                Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
            },
            {
              Header: "Debit Amount",
              accessor: 'debitAmount',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 130,
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
            },
            {
              Header: "Available Balance",
              accessor: 'balance',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 130,
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
            },
            {
              Header: "Ledger Balance",
              accessor: 'remainingBalance',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 130,
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
            },
            {
              Header: "Status",
              accessor: 'status',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 80,
              Cell: props => {
                  return props.value ? <span class={`badge ${formatTransactionStatusColorCode(props.value)}`}>{formatTransactionStatus(props.value)}</span> : ''
              }
            },
            {
              Header: "Narration",
              accessor: 'narration',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 200,
            },
            {
                Header: "Transaction Date",
                accessor: 'journalEntryDate',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 170,
                Cell: props => {
                    return props.value ? formatDateTime(props.value) : ''
                }
            },
            {
              Header: "Value Date",
              accessor: 'lastUpdatedOn',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 170,
              Cell: props => {
                  return props.value ? formatDateTime(props.value) : ''
              }
          }
        ]

        return <React.Fragment> 
          <TableExtra 
            data={WalletJournalExportSerializer.reverseWalletJournalDetails(this.props.data)}
            headers={WalletJournalExportSerializer.headerExport()}
            fileName={`${this.props.agentBusinessName} statement of account`}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getWalletJournal}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            downloadWalletJournal={this.props.downloadWalletJournal}
            disabled={this.props.loading}
        />
        <ReactTable 
            PaginationComponent={Pagination} 
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ''}
            style={{
                border: "none",
                boxShadow: "none",
                whiteSpace: 'unset',
                height: this.state.tableHeight,
                display:'block'
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
            onFetchData={(state, instance) => {
                const {
                    walletJournalType,
                    agentCode,
                    startDate,
                    endDate
                } = this.props.searchParameters
                this.setState({page: state.page})
                if(this.props.canLoadData) {
                  this.props.getWalletJournal(state.page + 1, state.pageSize, walletJournalType, agentCode, startDate, endDate)
                }
              }}
            headerNodeHeight={`123`}
            minRows={(this.props.data).length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
                style: {
                  borderColor: '#00425f',
                  boxSizing: 'none',
                  border: 'none',
                },
              })}
              getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  //backgroundColor: '#FFFFFF',
                  lineHeight: '20px',
                },
              })}
              getTheadThProps={(state, rowInfo, column, instance) => ({
                style: {
                  backgroundColor: '#00425f',
                  borderBottom: '0.1px solid #DBDEDE',
                  borderWidth: '0.1px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  color: '#FFFFFF',
                  fontSize: '13px',
                  fontWeight: '500',
                  textAlign: 'left'
                },
              })}
              getPaginationProps={(state, rowInfo, column, instance) => ({
                style: {
                  overflow: 'visible',
                  backgroundColor: '#ffffff',
                },
              })}    
              className="-striped -highlight" 
        />
    </React.Fragment>
    }
}