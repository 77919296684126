import React from 'react'

export default class WalletDistributionForm extends React.Component {
    render() {
        return <React.Fragment>
            {!this.props.canDistribute && <div class="form-group col-lg-12 row">
                <div class="col-lg-4">
                    <label>Agent:</label>
                    <select class="form-control" onChange={event => this.props.updateWalletDistributionFormData('agentDetails', event.target.value !== 'Select Agent' ? JSON.parse(event.target.value) : null)}>
                        <option value={null} selected={this.props.walletDistributionFormData.agentDetails == null ? 'selected' : ''}>Select Agent</option>
                        {this.props.agents.map((value, index) => {
                            if(value.selected != 0) {
                                return (
                                    <option value={JSON.stringify(value)} key={value.id}>{value.businessName}</option>
                                )
                            }
                        })}
                    </select>
                </div>
                <div class="col-lg-4">
                    <label>Amount (&#8358;):</label>
                    <input type="number" class="form-control" defaultValue={this.props.walletDistributionFormData.amount} placeholder={(this.props.walletBalance/100).toFixed(2)} onChange={event => this.props.updateWalletDistributionFormData('amount', event.target.value)} />
                </div>
                <div class="col-lg-2">
                    <button disabled={!this.props.walletDistributionFormData.agentDetails || !this.props.walletDistributionFormData.amount || !this.props.isFormValid} class="btn form-control" style={{backgroundColor: '#00425f', color: '#ffffff', marginTop: '25px'}} onClick={this.props.updatedistributeFormData}>Add</button>
                </div>
                <div class="col-lg-2">
                    <div class={`c100 p${Math.round(this.props.walletBalancePercentage)} small`}>
                        <span>{`${(this.props.walletBalancePercentage).toFixed(1)}%`}</span>
                        <div class="slice">
                            <div class="bar"></div>
                            <div class="fill"></div>
                        </div>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    }
}