import { formatDateTime, formatLongNumber } from '../../utils/formatter';
import { capitalizeFirstLetter, getApplicationType, getCountryName, getLgaName, getStateName } from '../../utils/helper';

export default class ApplicationExportSerializer {
    static headerExport () {
        return [
            { label: 'Application ID', key: 'applicationId', title: 'Application ID', dataIndex: 'applicationId' },
            { label: 'Category', key: 'agentTypeId', title: 'Category', dataIndex: 'agentTypeId'},
            { label: 'Business Name', key: 'businessName', title: 'Business Name', dataIndex: 'businessName' },
            { label: 'First Name', key: 'firstName', title: 'Firstname', dataIndex: 'firstName' },
            { label: 'Middle Name', key: 'middleName', title: 'Middle Name', dataIndex: 'middleName'},
            { label: 'Surname', key: 'surname', title: 'Surname', dataIndex: 'surname' },
            { label: 'Applicant Phone Number', key: 'phoneNumber', title: 'Applicant Phone Number', dataIndex: 'phoneNumber' },
            { label: 'Email Address', key: 'emailAddress', title: 'Email Address', dataIndex: 'emailAddress' },
            { label: 'Gender', key: 'gender', title: 'Gender', dataIndex: 'gender' },
            { label: 'Application Type', key: 'applicationType', title: 'Application Type', dataIndex: 'applicationType'},
            { label: 'Status', key: 'approvalStatus', title: 'Status', dataIndex: 'approvalStatus'},
            { label: 'Country', key: 'country', title: 'Country', dataIndex: 'country' },
            { label: 'State', key: 'state', title: 'State', dataIndex: 'state' },
            { label: 'LGA', key: 'lga', title: 'LGA', dataIndex: 'lga' },
            { label: 'Date Created', key: 'dateCreated', title: 'Date Created', dataIndex: 'dateCreated' },
            { label: 'Created By', key: 'createdBy', title: 'Created By', dataIndex: 'createdBy' },
            { label: 'Last Modified Date', key: 'dateLastModified', title: 'Last Modified Date', dataIndex: 'dateLastModified' },
        ];
    }

    static reverseApplicationDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
                applicationId, businessName, firstName, surname, phoneNumber, agentTypeId, middleName, emailAddress,
                applicationType, approvalStatus, dateCreated, dateLastModified, gender, nationality, state, localGovernmentArea,
                createdBy
            } = detail

            let detailFormData = {
                applicationId: applicationId ? `IFIS/${applicationId}` : '',
                agentTypeId: agentTypeId ? getApplicationType(agentTypeId) : '',
                businessName,
                firstName: firstName ? capitalizeFirstLetter(firstName) : '',
                middleName: middleName ? capitalizeFirstLetter(middleName) : '',
                surname: surname ? capitalizeFirstLetter(surname) : '',
                phoneNumber: phoneNumber ? formatLongNumber(phoneNumber) : '',
                emailAddress,
                gender: gender ? capitalizeFirstLetter(gender) : '',
                applicationType: applicationType ? capitalizeFirstLetter(applicationType) : '',
                approvalStatus,
                country: nationality ? getCountryName(nationality) : '',
                state: state ? getStateName(nationality, state) : '',
                lga: localGovernmentArea ? getLgaName(state, localGovernmentArea) : '',
                createdBy,
                dateCreated: dateCreated ? formatDateTime(dateCreated) : '',
                dateLastModified: dateLastModified ? formatDateTime(dateLastModified) : ''
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}