import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";
import ServiceTypes from "../../../../../../fixtures/service_types";
import TransactionStatuses from "../../../../../../fixtures/transaction_statuses";
import Select from "../../../../../../components/select";

export default class TransactionSearch extends React.Component {
  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest();
  }

  get canResolveOrRequeryTransactions() {
    return Boolean(!this.props.checkedTransactions.length);
  }

  resolutionValues = [
    { value: "1", name: "Successful" },
    { value: "0", name: "Failed" },
  ];

  render() {
    return (
      <React.Fragment>
        <div class="search-params">
          <form onSubmit={this.handleSearchRequest}>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="search-label">Transaction Reference:</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "transRef",
                      event.target.value
                    )
                  }
                />
              </div>
              <div class="col-md-3">
                <label class="search-label">Business Name</label>
                <input
                  type="text"
                  placeholder="Business Name...."
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "businessName",
                      event.target.value
                    )
                  }
                />
              </div>
              <div class="col-md-3">
                <label class="search-label">Business Number</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "businessMobileNo",
                      event.target.value
                    )
                  }
                />
              </div>
              <div class="col-md-3">
                <label class="search-label">
                  Batch Credit/Debit Reference:
                </label>
                <input
                  type="text"
                  class="search-input"
                  defaultValue={this.props.searchParameters.parentReference}
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "parentReference",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-3">
                <label class="search-label">Transaction Type</label>
                <Select
                  options={ServiceTypes}
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "transactionType",
                      event.value
                    )
                  }
                  defaultValue={this.props.searchParameters.transactionType}
                />
                {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('transactionType', event.target.value)} >
                                <option selected value={null}>All</option>
                                {ServiceTypes.map(value=><option value={value.id} selected={this.props.searchParameters.transactionType == value.id ? 'selected' : ''}>{value.name}</option>)}
                            </select> */}
              </div>
              <div class="col-md-3">
                <label class="search-label">Status</label>
                <Select
                  options={TransactionStatuses}
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "transactionStatus",
                      event.value
                    )
                  }
                  defaultValue={this.props.searchParameters.transactionStatus}
                />
                {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('transactionStatus', event.target.value)} >
                                <option selected value={null}>All</option>
                                {TransactionStatuses.map(value=><option value={value.id}>{value.name}</option>)}
                            </select> */}
              </div>
              <div class="col-md-3">
                <label class="search-label">Service</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "service",
                      event.target.value
                    )
                  }
                />
              </div>
              <div class="col-md-3">
                <label class="search-label">Payment Item Code:</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "paymentItemCode",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="search-label">Service Option Name:</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.props.updateSearchParameters(
                      "serviceOptionName",
                      event.target.value
                    )
                  }
                />
              </div>
              <div class="col-md-3">
                <label class="search-label">Date From</label>
                <DatePickerComponent
                  onChange={(event) =>
                    this.props.updateSearchParameters("startDate", event)
                  }
                  size={`240px`}
                  format={`dd/MM/yyyy h:mm aa`}
                  isClearable={true}
                  showTimeInput={true}
                  style={"0.4rem"}
                  textStyle={"1.0rem"}
                  iconStyle={"0.2rem"}
                  dateType={"startDate"}
                  preloadDate={this.props.preloadDate}
                />
              </div>
              <div class="col-md-3">
                <label class="search-label">Date To</label>
                <DatePickerComponent
                  onChange={(event) =>
                    this.props.updateSearchParameters("endDate", event)
                  }
                  size={`240px`}
                  format={`dd/MM/yyyy h:mm aa`}
                  isClearable={true}
                  showTimeInput={true}
                  disabled={
                    this.props.searchParameters.startDate ? false : true
                  }
                  minDate={this.props.searchParameters.startDate}
                  style={"0.4rem"}
                  textStyle={"1.0rem"}
                  iconStyle={"0.2rem"}
                  dateType={"endDate"}
                  preloadDate={this.props.preloadDate}
                />
              </div>
              <div class="col-md-2">
                <label class="search-label">&nbsp;&nbsp;</label>
                <button type="submit" class="search-button">
                  Search
                </button>
              </div>
            </div>
          </form>
          <div
            class="btn-toolbar justify-content-between"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div class="btn-group ml-12" role="group" aria-label="First group">
              {this.props.showRepush && (
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      Re-push as
                    </span>
                  </div>
                  <select
                    class="form-control"
                    onChange={(event) =>
                      this.props.resolveBulkTransactions(event.target.value)
                    }
                    value={this.props.desiredStatus}
                  >
                    <option
                      value={"default"}
                      disabled
                      selected={
                        this.props.desiredStatus === "default" ? "selected" : ""
                      }
                    >
                      Select
                    </option>
                    {this.resolutionValues.map((item) => (
                      <option
                        value={item.value}
                        selected={
                          this.props.desiredStatus === item.value
                            ? "selected"
                            : ""
                        }
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <button
                    id="basic-addon1"
                    type="button"
                    class="btn btn-secondary"
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#00425f",
                      borderColor: "#00425f",
                      color: "#ffffff",
                    }}
                    onClick={() => this.props.requeryBulkTransactions()}
                  >
                    Re-query
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
