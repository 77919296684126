import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_UPDATE_STATE, CAN_VIEW_STATE } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import StateForm from '../../components/state-form';

export default class ViewStateScene extends BaseScene {
  constructor (props) {
    super(props)
    this.state = {
      canSave: null,
      countries: [],
      updateButton: true,
      saveButton: false,
      disabled: true,
      pageTitle: "View State",
      statuses: [
        {"id": 1, "name": "Active"},
        {"id": 2, "name": "Inactive"}
      ],
      stateFormData: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

 
  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onUpdateButtonClick () {
    this.setState({
        disabled: false,
        saveButton: true,
        updateButton: false,
        pageTitle: 'Update State',
    })
  }

  async onSaveButtonClick () {
    
  }

  updateSetState (type, value) {
    this.setState({stateFormData: {...this.state.stateFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
        {this.state.updateButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_STATE]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
        {this.state.saveButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_STATE]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"State"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_STATE]}>
        <GridLayout>
          <StateForm 
            onChange={this.updateSetState}
            countries={this.state.countries}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
