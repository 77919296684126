import React from 'react'
import { formatDate } from '../../../../../../utils/formatter'
import { capitalizeFirstLetter, splitStringByUppercase } from '../../../../../../utils/helper'

export default class AuditDetailsModal extends React.Component {
    render() {
        return <React.Fragment>
            <div class="modal" id="audit_details_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
                <div class="modal-dialog modal-lg" style={{overflowY: 'scroll', overflowX : 'auto',fontSize: '14px'}}> 
                    <div class="modal-content" style={{borderRadius: '15px'}}> 
                        <div class="modal-header"> 
                            <h3 class="modal-title" style={{textAlign: 'center'}}>Details</h3> 
                        </div> 
                        <div class="modal-body fluid" style={{height: '400px'}}>
                            <div class="kt-portlet" ref={this.props.transactionDetailsSectionRef}>
                                <form class="kt-form kt-form--label-right" id="collapseModalOne1" class="collapse show" aria-labelledby="headingOne1">
                                    <h5>Audit Information</h5>
                                    <div class="form-group row">
                                        {Object.entries(this.props.data).map(([key, value]) => {
                                            if(typeof value !== 'object') {
                                                return ( 
                                                    <div class="form-group col-lg-3">
                                                        <label>{splitStringByUppercase(capitalizeFirstLetter(key, false))}</label>
                                                        <input defaultValue={key == 'entryDate' ? formatDate(value) : value} disabled autoComplete="off" type="text" class="form-control" placeholder="" />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </form>
                            </div>
                            {this.props.data.dataAfter && <div class="kt-portlet">
                                <form class="kt-form kt-form--label-right" id="collapseModalOne1" class="collapse show" aria-labelledby="headingOne1">
                                    <h5>Audit Data</h5>
                                    <table class="table">
                                        <tr class="thead-light">
                                            <th>Key</th>
                                            <th scope="col">Value</th>
                                        </tr>
                                        {this.props.data.dataAfter && Object.entries(this.props.data.dataAfter).map(([key, value]) => {
                                            if(typeof value !== 'object') {
                                                return ( 
                                                    <tr>
                                                        <th scope="row">{key}</th>
                                                        <td>{value.toString()}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </table>
                                </form>
                            </div>}
                        </div>  
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" class="btn btn-primary" style={{height: '40px', width:'100px', padding: '0px', borderRadius: '15px', borderColor: '#00425f', backgroundColor: '#00425f', color: '#ffffff', fontSize: '14px', margin: 'auto', display: 'block'}} data-dismiss="modal">Close</button>
                        </div>
                    </div> 
                </div> 
            </div>
        </React.Fragment>
    }
}