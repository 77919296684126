import React from "react";

export default function BankSearch(props) {
  const handleSearchRequest = (e) => {
    e.preventDefault();
    props.handleSearchRequest();
  };

  return (
    <React.Fragment>
      <div class="search-params">
        <form onSubmit={handleSearchRequest}>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="search-label">Bank Name</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  props.updateSearchParameters(
                    "bankName",
                    event.target.value.trim()
                  )
                }
              />
            </div>

            <div class="col-md-2">
              <label class="search-label">&nbsp;&nbsp;</label>
              <button type="submit" class="search-button">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
