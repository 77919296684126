import qs from "querystring";
import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../components/access-denied";
import ContentHead from "../../../../../../components/content-head";
import RoleForm from "../../../../../../components/forms/role-form";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../components/loader";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../constants/api";
import { CAN_CREATE_ROLES } from "../../../../../../constants/permissions";
import { CAN_CREATE_ROLE } from "../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../mixins/api";
import { retrieveSessionInformation } from "../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../mixins/protected-component";

export default class AddRoleScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  requiredPermissions = [CAN_CREATE_ROLES];

  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      roleFormData: [],
      roleDetails: [],
    };
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.updateRoleFormData = this.updateRoleFormData.bind(this);
  }

  componentDidMount() {
    this.roleId = qs.parse(window.location.search)["?roleId"];
    const currentUser = retrieveSessionInformation();
    this.domainCode = currentUser.user.domainCode;
    this.updateRoleFormData("domainCode", this.domainCode);
    this.getPermissions();
    if (this.roleId) {
      this.getRoles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getRoles() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getRoles(this.domainCode);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      response.map((details) => {
        if (this.roleId === details.id) {
          this.getRolePermissions(details.name);
        }
      });
    } else {
      this.setState({
        roleFormData: [],
        isLoading: false,
      });
    }
  }

  async getRolePermissions(roleName) {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getRolePermissions(
      this.domainCode,
      roleName
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        roleFormData: {
          ...this.state.roleFormData,
          permissions: this.getPermissionId(response),
          name: roleName,
        },
        isLoading: false,
      });
    } else {
      this.setState({
        roleFormData: [],
        isLoading: false,
      });
    }
  }

  async getPermissions() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getPermissions();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissions: response,
        isLoading: false,
      });
    } else {
      this.setState({
        permissions: [],
        isLoading: false,
      });
    }
  }

  getPermissionId(permissions) {
    let permissionArray = [];
    permissions.map((details) => {
      permissionArray.push(details.id);
    });
    return permissionArray;
  }

  toJSON(array) {
    let newArray = [];
    array.map((value) => {
      let myArray = {};
      myArray["id"] = value;
      newArray.push(myArray);
    });

    return newArray;
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const createRoleResponseObj = await platformManagement.createRole({
      name: this.state.roleFormData.name,
      domainCode: this.state.roleFormData.domainCode,
      description: this.state.roleFormData.description,
      permissions: this.toJSON(this.state.roleFormData.permissions),
    });
    const createRoleResponseStatus = createRoleResponseObj.status;
    const createRoleResponse = createRoleResponseObj.response;
    if (createRoleResponseStatus === SUCCESS_STATUS) {
      if (createRoleResponse.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new role has been sent successfully for approval.",
        });
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Role created successfully",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: createRoleResponse,
      });
    }
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
  }

  isFormValid() {
    return Boolean(
      !this.state.roleFormData.name || !this.state.roleFormData.permissions
    );
  }

  updateRoleFormData(type, value) {
    this.setState({
      roleFormData: { ...this.state.roleFormData, [type]: value },
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/role-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_ROLE]}>
          <button
            type="button"
            disabled={this.isFormValid()}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"User Management"}
          withoutSearch
          subtitle="Create a Role"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_CREATE_ROLE]}
        >
          <GridLayout>
            <RoleForm
              allPermissions={this.state.permissions}
              onChange={this.updateRoleFormData}
              roleFormData={this.state.roleFormData}
              roleDetails={this.state.roleDetails}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
