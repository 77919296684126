import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import {
  formatDate,
  formatWalletStatus,
  formatWalletStatusClassName,
} from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";

export default class AgentTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getSerialNumber(index) {
    return this.props.pageSize * (this.props.page - 1) + index;
  }

  render() {
    const agentData = this.props.data && this.props.data
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Agent ID",
        accessor: "agentCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Phone Number",
        accessor: "agentMobileNo",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <span class="word-capitalize ellipse" style={{ width: "150px" }}>
            {props.value.toLowerCase()}
          </span>
        ),
      },
      {
        Header: "Agent Class",
        accessor: "agentClass",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        Cell: (props) => (
          <span class="word-capitalize">
            {props.value ? props.value.toLowerCase() : null}
          </span>
        ),
      },
      {
        Header: "Date Created",
        accessor: "dateApplied",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 100,
        Cell: (props) => {
          return props.value ? formatDate(props.value) : null;
        },
      },
      {
        Header: "Last Modified",
        accessor: "dateLastUpdated",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 100,
        Cell: (props) => {
          return props.value ? formatDate(props.value) : null;
        },
      },
      {
        Header: "Profile Config",
        accessor: "walletStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <span
            style={{ color: "#ffffff" }}
            class={`kt-badge ${formatWalletStatusClassName(
              props.value
            )} word-capitalize kt-badge--inline kt-badge--pill`}
          >
            {props.value ? formatWalletStatus(props.value) : null}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "index", //'statusId',
        resizable: false,
        width: 90,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
       
        Cell: (props) => {
          return (agentData.bvnVerificationStatus &&
            agentData[props.index].bvnVerificationStatus ==
              "SUSPENDED") ||
            agentData[props.index].bvnVerificationStatus ==
              "PENDING_VERIFICATION_SUSPENDED" ? (
            <span
              style={{ color: "#ffffff" }}
              class={`kt-badge kt-badge--warning kt-badge--inline kt-badge--pill`}
            >
              {"Suspended"}
            </span>
          ) : (
            <span
              style={{ color: "#ffffff" }}
              class={`kt-badge ${
                agentData[props.index].statusId === 1 ||
                agentData[props.index].statusId === 0
                  ? "kt-badge--primary"
                  : agentData[props.index].statusId === 2
                  ? "kt-badge--success"
                  : agentData[props.index].statusId === 3
                  ? "kt-badge--warning"
                  : agentData[props.index].statusId === 4
                  ? "kt-badge--danger"
                  : agentData[props.index].statusId === 5
                  ? "kt-badge--info"
                  : ""
              } kt-badge--inline kt-badge--pill`}
            >
              {agentData[props.index].statusId === 0
                ? "New"
                : agentData[props.index].statusId === 1
                ? "New"
                : agentData[props.index].statusId === 2
                ? "Active"
                : agentData[props.index].statusId === 3
                ? "Suspended"
                : agentData[props.index].statusId === 4
                ? "Retired"
                : agentData[props.index].statusId === 5
                ? "Dormant"
                : ""}
            </span>
          );
        },
      },
      {
        Header: "Action",
        accessor: "agentCode",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 60,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <Link
                  to={`agent-management/${row.agentCode}/view`}
                  class="dropdown-item"
                >
                  <i class="la la-eye"></i>
                  View
                </Link>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  to={`agent-management/manage-agent-class?agentId=${row.agentCode}&agentPhoneNumber=${row.agentMobileNo}`}
                  class="dropdown-item"
                >
                  <i class="la la-magnet"></i>
                  Manage Agent Class
                </Link>
              </ProtectedComponent>
              {original.walletVersion === 2 && (
                <ProtectedComponent requiredPermissions={[this.props.canView]}>
                  <Link
                    hidden={original.statusId !== 4 ? "" : "hidden"}
                    to={`agent-management/manage-agent-limit?agentId=${row.agentCode}&agentPhoneNumber=${row.agentMobileNo}`}
                    class="dropdown-item"
                  >
                    <i class="la la-magnet"></i>
                    Manage Agent Limit
                  </Link>
                </ProtectedComponent>
              )}
              <ProtectedComponent
                requiredPermissions={[this.props.canManageDomain]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  to={`domain-management/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-tasks"></i>
                  Manage Domain
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canViewTransaction]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() =>
                    this.props.redirectToCommissionHistory(original)
                  }
                  to={`commission-history/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-book"></i>
                  View Commission History
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canViewTransaction]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() => this.props.redirectToWalletJournal(original)}
                  to={`account-statement/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-book"></i>
                  View Statement of Account
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canActivate]}
              >
                <a
                  hidden={original.statusId === 3 ? "" : "hidden"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      "activate"
                    )
                  }
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-toggle-on"></i>
                  Activate
                </a>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canDeactivate]}
              >
                <a
                  hidden={original.statusId === 2 ? "" : "hidden"}
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      `suspend`
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-ban"></i>
                  Suspend
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canRetire]}>
                <a
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      `retire`
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  /*href={`/home/agent-management/${'retire'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-lock"></i>
                  Retire
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={AgentDetailsExportSerializer.reverseAgentDetails(
            agentData
          )}
          headers={AgentDetailsExportSerializer.headerExport()}
          fileName={"agents"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAgents}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          downloadLink={this.props.downloadAgentData}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={agentData}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={agentData.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAgents(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
