export default class IdentityTypesExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'identity_type', title: 'Name', dataIndex: 'identity_type'}
        ]
    }

    static reverseIdentityTypeDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { identity_type } = details
            let detailFormData = {
                identity_type
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}