import React from "react";
import PdfModalComponent from "../pdf-viewer";
import Thumbnail from "../thumbnail";
import { Col } from "react-bootstrap";
import { platformManagement } from "../../mixins/api";
import { SUCCESS_STATUS } from "../../constants/api";

const documents = [
  {
    id: 1,
    name: "Utility Bill-BD",
  },
  {
    id: 2,
    name: "Certificate of Incorporation-BD",
  },
  {
    id: 3,
    name: "Character Attestation Form-BD",
  },
  {
    id: 4,
    name: "Proof of Identity-PD",
  },
  {
    id: 5,
    name: "Passport Photo-PD",
  },
];
export default class KycDocuments extends React.Component {
  state = {
    error: false,
    documentClickUrl: null,
    NewuploadDocumentName: "",
    getDoctypeName: [],
    selectedDocumentName: "",
    documentClickFilename: null,
    selectedFiles: [],
    documents: [],
    isLoading: false,
    errorMessage: "",
  };

  getDocumentClickName = this.getDocumentClickName.bind(this);
  getSpecificUploadedDocument = this.getSpecificUploadedDocument.bind(this);
  getPersonalUploadedDocument = this.getPersonalUploadedDocument.bind(this);
  getUtilityUploadedDocument = this.getUtilityUploadedDocument.bind(this);
  getDoctypeName = this.getDoctypeName.bind(this);

  async componentDidMount() {
    await this.getDoctypeName();
  }

  async getDoctypeName() {
    this.setState({ isLoading: true });

    try {
      const responseObj = await platformManagement.getDocTypes();
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS && response) {
        this.setState(
          {
            getDoctypeName: response || [],
            isLoading: false,
          },
          () => {}
        );
      } else {
        this.setState({
          getDoctypeName: [],
          isLoading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching document types:", error);
      this.setState({
        getDoctypeName: [],
        documents: [],
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  handleSelectChange = (event) => {
    this.setState({ selectedDocumentName: event.target.value });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    const THREE_MB = 3 * 1024 * 1024;

    if (file.size > THREE_MB) {
      this.setState({
        error: true,
      });
    } else {
      const files = Array.from(event.target.files);
      this.setState({
        error: false,
      });

      const documentName =
        process.env.REACT_APP_SHOW_UPLOAD_DETAILS &&
        process.env.REACT_APP_SHOW_UPLOAD_DETAILS === "true"
          ? this.state.selectedDocumentName
          : this.state.NewuploadDocumentName;

      this.props.readNationalIdUrl(
        files.name,
        files[0].type,
        event,
        documentName
      );
    }
  };

  getDocumentClickName(filename, src) {
    this.setState({
      documentClickUrl: src,
      documentClickFilename: filename,
    });
  }

  getSpecificUploadedDocument(documentType, key = "documentName") {
    let document = this.props.uploadedDocuments.filter(
      (details) => details.documentType == documentType
    );
    return document[0] ? document[0][key] : "";
  }

  getPersonalUploadedDocument(type) {
    let file = [];
    this.props.personalDocuments.map((details) => {
      this.props.uploadedDocuments.filter((document) => {
        if (document.documentType == details.name) {
          file = document;
        }
      });
    });
    return file[type] ? file[type] : "";
  }

  getUtilityUploadedDocument(type) {
    let file = [];
    this.props.utilityBills.map((details) => {
      this.props.uploadedDocuments.filter((document) => {
        if (document.documentType == details.name) {
          file = document;
        }
      });
    });
    return file[type] ? file[type] : "";
  }

  convertUploadedDocument(documentLink) {
    let convertedDocument = documentLink.replace(
      "/data",
      process.env.REACT_APP_FILE_URL
    );
    return convertedDocument;
  }

  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              class="kt-portlet__head"
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target="#collapseOne4"
              aria-controls="collapseOne4"
            >
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">KYC Documents</h3>
              </div>
            </div>
          )}

          {this.props.showUploadForm && (
            <form
              id="collapseOne4"
              class={`collapse ${this.props.showContent ? "show" : ""}`}
            >
              <div class="kt-portlet__body">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Passport Photo *:</label>
                    <div class="col-lg-8">
                      <div>
                        <Thumbnail
                          filename={
                            this.props.kycDocuments.passportPhoto
                              ? this.props.kycDocuments.passportPhoto.filename
                              : this.getSpecificUploadedDocument(
                                  "Passport Photo",
                                  "documentName"
                                )
                          }
                          style={{ width: 150, height: 100 }}
                          src={
                            this.props.kycDocuments.passportPhoto
                              ? this.props.kycDocuments.passportPhoto.url
                              : `${
                                  process.env.REACT_APP_FILE_URL
                                }${this.getSpecificUploadedDocument(
                                  "Passport Photo",
                                  "documentName"
                                )}`
                          }
                        />
                        <br />
                        <br />
                      </div>
                      {!this.props.passportPhotoProgress && (
                        <div class="upload-btn-wrapper">
                          <button class="upload-btn">Upload a file</button>
                          <input
                            autoComplete="off"
                            disabled={this.props.canUploadDocument}
                            type="file"
                            onChange={(event) =>
                              this.props.readNationalIdUrl(
                                "passportPhoto",
                                "Passport Photo",
                                event
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.passportPhotoProgress && (
                      <div class="col-lg-8">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {this.props.documentErrors.includes("Passport Photo") &&
                      !this.props.passportPhotoProgress && (
                        <div class="col-lg-12">
                          <span style={{ color: "red" }}>
                            Unable to upload{" "}
                          </span>
                          <i
                            class="fa fa-upload fa-lg uploadIcon"
                            aria-hidden="true"
                            onClick={() =>
                              this.props.uploadApplicationDocument(
                                this.props.applicationId,
                                this.props.kycDocuments.passportPhoto.name,
                                this.props.kycDocuments.passportPhoto.file,
                                "passportPhoto"
                              )
                            }
                          ></i>
                        </div>
                      )}
                  </div>
                  <div class="col-lg-4">
                    <label>Certificate of Incorporation *:</label>
                    <div class="col-lg-8">
                      <div>
                        <Thumbnail
                          filename={
                            this.props.kycDocuments.incorporationCertificate
                              ? this.props.kycDocuments.incorporationCertificate
                                  .filename
                              : this.getSpecificUploadedDocument(
                                  "Incorporation Certificate",
                                  "documentName"
                                )
                          }
                          style={{ width: 150, height: 100 }}
                          src={
                            this.props.kycDocuments.incorporationCertificate
                              ? this.props.kycDocuments.incorporationCertificate
                                  .url
                              : `${
                                  process.env.REACT_APP_FILE_URL
                                }${this.getSpecificUploadedDocument(
                                  "Incorporation Certificate",
                                  "documentName"
                                )}`
                          }
                        />
                        <br />
                        <br />
                      </div>
                      {/* } */}
                      {!this.props.incorporationCertificateProgress && (
                        <div class="upload-btn-wrapper">
                          <button class="upload-btn">Upload a file</button>
                          <input
                            autoComplete="off"
                            disabled={this.props.canUploadDocument}
                            type="file"
                            onChange={(event) =>
                              this.props.readNationalIdUrl(
                                "incorporationCertificate",
                                "Incorporation Certificate",
                                event
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.incorporationCertificateProgress && (
                      <div class="col-lg-8">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {this.props.documentErrors.includes(
                      "Incorporation Certificate"
                    ) &&
                      !this.props.incorporationCertificateProgress && (
                        <div class="col-lg-12">
                          <span style={{ color: "red" }}>
                            Unable to upload{" "}
                          </span>
                          <i
                            class="fa fa-upload fa-lg uploadIcon"
                            aria-hidden="true"
                            onClick={() =>
                              this.props.uploadApplicationDocument(
                                this.props.applicationId,
                                this.props.kycDocuments.incorporationCertificate
                                  .name,
                                this.props.kycDocuments.incorporationCertificate
                                  .file,
                                "incorporationCertificate"
                              )
                            }
                          ></i>
                        </div>
                      )}
                  </div>
                  <div class="col-lg-4">
                    <label>Character Attestation Form *:</label>
                    <div class="col-lg-8">
                      <div>
                        <Thumbnail
                          filename={
                            this.props.kycDocuments.characterAttestation
                              ? this.props.kycDocuments.characterAttestation
                                  .filename
                              : this.getSpecificUploadedDocument(
                                  "Character Attestation",
                                  "documentName"
                                )
                          }
                          style={{ width: 150, height: 100 }}
                          src={
                            this.props.kycDocuments.characterAttestation
                              ? this.props.kycDocuments.characterAttestation.url
                              : `${
                                  process.env.REACT_APP_FILE_URL
                                }${this.getSpecificUploadedDocument(
                                  "Character Attestation",
                                  "documentName"
                                )}`
                          }
                        />
                        <br />
                        <br />
                      </div>
                      {!this.props.characterAttestationProgress && (
                        <div class="upload-btn-wrapper">
                          <button class="upload-btn">Upload a file</button>
                          <input
                            autoComplete="off"
                            disabled={this.props.canUploadDocument}
                            type="file"
                            onChange={(event) =>
                              this.props.readNationalIdUrl(
                                "characterAttestation",
                                "Character Attestation",
                                event
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.characterAttestationProgress && (
                      <div class="col-lg-8">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {this.props.documentErrors.includes(
                      "Character Attestation"
                    ) &&
                      !this.props.characterAttestationProgress && (
                        <div class="col-lg-12">
                          <span style={{ color: "red" }}>
                            Unable to upload{" "}
                          </span>
                          <i
                            class="fa fa-upload fa-lg uploadIcon"
                            aria-hidden="true"
                            onClick={() =>
                              this.props.uploadApplicationDocument(
                                this.props.applicationId,
                                this.props.kycDocuments.characterAttestation
                                  .name,
                                this.props.kycDocuments.characterAttestation
                                  .file,
                                "characterAttestation"
                              )
                            }
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Personal Document *:</label>
                    <div class="col-lg-8">
                      <select
                        autoComplete="off"
                        disabled={this.props.canUploadDocument}
                        class="form-control"
                        onChange={(event) =>
                          this.props.updateSetState(
                            "personalDocumentType",
                            event.target.value
                          )
                        }
                      >
                        <option selected disabled>
                          Select type
                        </option>
                        {this.props.personalDocuments.map((details) => {
                          return (
                            <option
                              value={details.name}
                              selected={
                                this.getPersonalUploadedDocument(
                                  "documentType"
                                ) == details.name
                                  ? "selected"
                                  : ""
                              }
                            >
                              {details.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <br />
                    <div class="col-lg-8">
                      <div>
                        <Thumbnail
                          filename={
                            this.props.kycDocuments.personalDocument
                              ? this.props.kycDocuments.personalDocument
                                  .filename
                              : this.getPersonalUploadedDocument("documentName")
                          }
                          style={{ width: 150, height: 100 }}
                          src={
                            this.props.kycDocuments.personalDocument
                              ? this.props.kycDocuments.personalDocument.url
                              : `${
                                  process.env.REACT_APP_FILE_URL
                                }${this.getPersonalUploadedDocument(
                                  "documentName"
                                )}`
                          }
                        />
                        <br />
                        <br />
                      </div>
                      {!this.props.personalDocumentProgress && (
                        <div class="upload-btn-wrapper">
                          <button class="upload-btn">Upload a file</button>
                          <input
                            autoComplete="off"
                            disabled={
                              (this.props.personalDocumentType
                                ? false
                                : true) && !this.props.canUploadDocument
                            }
                            type="file"
                            onChange={(event) =>
                              this.props.readNationalIdUrl(
                                "personalDocument",
                                this.props.personalDocumentType,
                                event
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.personalDocumentProgress && (
                      <div class="col-lg-8">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {this.props.documentErrors.includes(
                      this.props.personalDocumentType
                    ) &&
                      !this.props.personalDocumentProgress && (
                        <div class="col-lg-12">
                          <span style={{ color: "red" }}>
                            Unable to upload{" "}
                          </span>
                          <i
                            class="fa fa-upload fa-lg uploadIcon"
                            aria-hidden="true"
                            onClick={() =>
                              this.props.uploadApplicationDocument(
                                this.props.applicationId,
                                this.props.kycDocuments.personalDocument.name,
                                this.props.kycDocuments.personalDocument.file,
                                "personalDocument"
                              )
                            }
                          ></i>
                        </div>
                      )}
                  </div>
                  <div class="col-lg-4">
                    <label>Utility Bill *:</label>
                    <div class="col-lg-8">
                      <select
                        autoComplete="off"
                        disabled={this.props.canUploadDocument}
                        class="form-control"
                        onChange={(event) =>
                          this.props.updateSetState(
                            "utilityBillType",
                            event.target.value
                          )
                        }
                      >
                        <option selected disabled>
                          Select type
                        </option>
                        {this.props.utilityBills.map((details) => {
                          return (
                            <option
                              value={details.name}
                              selected={
                                this.getUtilityUploadedDocument(
                                  "documentType"
                                ) == details.name
                                  ? "selected"
                                  : ""
                              }
                            >
                              {details.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <br />
                    <div class="col-lg-8">
                      <div>
                        <Thumbnail
                          filename={
                            this.props.kycDocuments.utilityBill
                              ? this.props.kycDocuments.utilityBill.filename
                              : this.getUtilityUploadedDocument("documentName")
                          }
                          style={{ width: 150, height: 100 }}
                          src={
                            this.props.kycDocuments.utilityBill
                              ? this.props.kycDocuments.utilityBill.url
                              : `${
                                  process.env.REACT_APP_FILE_URL
                                }${this.getUtilityUploadedDocument(
                                  "documentName"
                                )}`
                          }
                        />
                        <br />
                        <br />
                      </div>
                      {!this.props.utilityBillProgress && (
                        <div class="upload-btn-wrapper">
                          <button class="upload-btn">Upload a file</button>
                          <input
                            autoComplete="off"
                            disabled={
                              (this.props.utilityBillType ? false : true) &&
                              !this.props.canUploadDocument
                            }
                            type="file"
                            onChange={(event) =>
                              this.props.readNationalIdUrl(
                                "utilityBill",
                                this.props.utilityBillType,
                                event
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.utilityBillProgress && (
                      <div class="col-lg-8">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {this.props.documentErrors.includes(
                      this.props.utilityBillType
                    ) &&
                      !this.props.utilityBillProgress && (
                        <div class="col-lg-12">
                          <span style={{ color: "red" }}>
                            Unable to upload{" "}
                          </span>
                          <i
                            class="fa fa-upload fa-lg uploadIcon"
                            aria-hidden="true"
                            onClick={() =>
                              this.props.uploadApplicationDocument(
                                this.props.applicationId,
                                this.props.kycDocuments.utilityBill.name,
                                this.props.kycDocuments.utilityBill.file,
                                "utilityBill"
                              )
                            }
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
                <div class="form-group row">
                  {this.props.uploadedOtherDocuments.map((details) => (
                    <div class="col-lg-3">
                      <label>{details.name}:</label>
                      <div>
                        <Thumbnail
                          filename={details.filename}
                          style={{ width: 150, height: 100 }}
                          src={details.url}
                        />
                        <br />
                        <br />
                      </div>
                    </div>
                  ))}
                  <div class="col-lg-4">
                    <label>Other Document:</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        defaultValue={
                          this.props.kycDocuments.otherDocument &&
                          this.props.kycDocuments.otherDocument.name
                            ? this.props.kycDocuments.otherDocument.name
                            : ""
                        }
                        autoComplete="off"
                        placeholder="Document Name"
                        disabled={this.props.canUploadDocument}
                        class="form-control"
                        onChange={(event) =>
                          this.props.updateSetState(
                            "otherDocumentName",
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <br />
                    <div class="col-lg-8">
                      <div>
                        <Thumbnail
                          filename={
                            this.props.kycDocuments.otherDocument &&
                            this.props.kycDocuments.otherDocument.filename
                              ? this.props.kycDocuments.otherDocument.filename
                              : ""
                          }
                          style={{ width: 150, height: 100 }}
                          src={
                            this.props.kycDocuments.otherDocument &&
                            this.props.kycDocuments.otherDocument.url
                              ? this.props.kycDocuments.otherDocument.url
                              : ""
                          }
                        />
                        <br />
                        <br />
                      </div>
                      {!this.props.otherDocumentProgress && (
                        <div class="upload-btn-wrapper">
                          <button class="upload-btn">Upload a file</button>
                          <input
                            autoComplete="off"
                            disabled={
                              (this.props.otherDocumentName ? false : true) &&
                              !this.props.canUploadDocument
                            }
                            type="file"
                            onChange={(event) =>
                              this.props.readNationalIdUrl(
                                "otherDocument",
                                this.props.otherDocumentName,
                                event
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.otherDocumentProgress && (
                      <div class="col-lg-8">
                        <div class="progress">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {this.props.documentErrors.includes(
                      this.props.otherDocumentName
                    ) &&
                      !this.props.otherDocumentProgress && (
                        <div class="col-lg-12">
                          <span style={{ color: "red" }}>
                            Unable to upload{" "}
                          </span>
                          <i
                            class="fa fa-upload fa-lg uploadIcon"
                            aria-hidden="true"
                            onClick={() =>
                              this.props.uploadApplicationDocument(
                                this.props.applicationId,
                                this.props.kycDocuments.otherDocument.name,
                                this.props.kycDocuments.otherDocument.file,
                                "otherDocument",
                                this.props.kycDocuments.otherDocument.filename,
                                this.props.kycDocuments.otherDocument.url
                              )
                            }
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </form>
          )}
          {this.props.showUploadedDocuments && (
            <form
              id="collapseOne4"
              class={`collapse ${this.props.showContent ? "show" : ""}`}
            >
              <div class="kt-portlet__body">
                <div class="form-group row">
                  {this.props.uploadedDocuments.map((details) => {
                    return (
                      <div class="col-lg-3">
                        <label>{details.documentType}</label>
                        <div class="col-lg-8">
                          <div>
                            <Thumbnail
                              showDownloadButton={true}
                              showPreview={true}
                              filename={
                                this.getSpecificUploadedDocument(
                                  details.documentType,
                                  "documentName"
                                )
                                  ? this.getSpecificUploadedDocument(
                                      details.documentType,
                                      "documentName"
                                    )
                                  : details.documentName
                              }
                              getDocumentClickName={this.getDocumentClickName}
                              style={{ width: 150, height: 100 }}
                              src={this.convertUploadedDocument(
                                details.documentLink
                              )}
                            />
                            <br />
                            <br />
                          </div>
                          {!this.props.disabled && (
                            <div class="upload-btn-wrapper">
                              <button class="upload-btn">Upload a file</button>
                              <input
                                autoComplete="off"
                                disabled={this.props.canUploadDocument}
                                type="file"
                                onChange={(event) =>
                                  this.props.readNationalIdUrl(
                                    details.documentId,
                                    details.documentType,
                                    event
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                        {this.props.documentUploadProgress[
                          details.documentType
                        ] && (
                          <div class="col-lg-8">
                            <div class="progress">
                              <div
                                class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        )}
                        {this.props.documentErrors.includes(
                          details.documentType
                        ) &&
                          !this.props.documentUploadProgress[
                            details.documentType
                          ] && (
                            <div class="col-lg-12">
                              <span style={{ color: "red" }}>
                                Unable to upload{" "}
                              </span>
                              <i
                                class="fa fa-upload fa-lg uploadIcon"
                                aria-hidden="true"
                                onClick={() =>
                                  this.props.uploadDocument(
                                    details.documentId,
                                    this.props.kycDocuments[
                                      details.documentType
                                    ]["file"],
                                    details.documentType
                                  )
                                }
                              ></i>
                            </div>
                          )}
                        <br />
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>

              {!this.props.disabled && (
                <div class="kt-portlet__body col-lg-3">
                  <h5>Upload Document</h5>

                  {process.env.REACT_APP_SHOW_UPLOAD_DETAILS &&
                  process.env.REACT_APP_SHOW_UPLOAD_DETAILS === "true" ? (
                    <select
                      autoComplete="off"
                      disabled={this.props.loading}
                      className="form-control mb-3"
                      onChange={(e) =>
                        this.setState({
                          selectedDocumentName: e.target.value,
                        })
                      }
                    >
                      <option selected disabled>
                        Select document name
                      </option>
                      {Array.isArray(this.state.getDoctypeName) &&
                        this.state.getDoctypeName
                          .filter((item) => item.active === true)
                          .map((item) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <select
                      autoComplete="off"
                      disabled={this.props.loading}
                      className="form-control mb-3"
                      onChange={(e) =>
                        this.setState({
                          NewuploadDocumentName: e.target.value,
                        })
                      }
                    >
                      <option selected disabled>
                        Select document name
                      </option>
                      {documents.map((details) => (
                        <option key={details.name} value={details.name}>
                          {details.name}
                        </option>
                      ))}
                    </select>
                  )}
                  {console.log(
                    this.state.selectedDocumentName,
                    "this.state.selectedDocumentName"
                  )}
                  <input
                    type="file"
                    disabled={
                      process.env.REACT_APP_SHOW_UPLOAD_DETAILS === "true"
                        ? this.props.loading || !this.state.selectedDocumentName
                        : this.props.loading ||
                          !this.state.NewuploadDocumentName
                    }
                    className="form-control"
                    name="documents[]"
                    multiple
                    onChange={this.handleFileChange}
                  />
                  {this.props.loading && (
                    <div class="mt-1">
                      <div class="progress">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                          role="progressbar"
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  )}
                  {this.state.error && (
                    <Col lg={12}>
                      <span style={{ color: "#EE312A" }}>
                        File size is above 3MB
                      </span>
                    </Col>
                  )}

                  <span
                    style={{
                      color: "tomato",
                    }}
                  >
                    {this.props.message}
                  </span>
                </div>
              )}
            </form>
          )}
          <PdfModalComponent
            modalTitle={`PDF Viewer`}
            src={this.state.documentClickUrl}
            filename={this.state.documentClickFilename}
            modalSize={`modal-lg`}
            pageNumber={1}
            numPages={1}
          />
        </div>
      </React.Fragment>
    );
  }
}
