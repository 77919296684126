import React from "react";
import { isPhoneNumberValid } from "../../utils/helper";
import {
  checkAccountNumberIsValid,
  checkBvnIsValid,
  checkCompanyRegistrationNumberIsValid,
  checkEmailIsValid,
} from "../../utils/validator";
import PhoneNumberComponent from "../phone-number-component";
import Select from "../select";

export default class BusinessDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exemptAgent: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              class="kt-portlet__head"
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target="#collapseOne2"
              aria-controls="collapseOne2"
            >
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Business Details</h3>
              </div>
            </div>
          )}

          <form
            id="collapseOne2"
            class={`collapse ${this.props.showContent ? "show" : ""}`}
          >
            <div class="kt-portlet__body">
              <div class="form-group row">
                {this.props.businessDetails &&
                  this.props.businessDetails.businessName && (
                    <div class="col-lg-4 mb-4">
                      <label>Business Name *:</label>
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="text"
                        class="form-control"
                        defaultValue={
                          this.props.businessDetails
                            ? this.props.businessDetails.businessName
                            : ""
                        }
                        placeholder="Enter business name"
                        onChange={(event) =>
                          this.props.onChange(
                            "businessName",
                            event.target.value
                          )
                        }
                      />
                    </div>
                  )}
                {this.props.businessDetails &&
                  this.props.businessDetails.businessType && (
                    <div class="col-lg-4 mb-4">
                      <label>Business Type *:</label>
                      <input
                        type="text"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        autoComplete="off"
                        class="form-control"
                        defaultValue={
                          this.props.businessDetails
                            ? this.props.businessDetails.businessType
                            : ""
                        }
                        onChange={(event) =>
                          this.props.onChange(
                            "businessType",
                            event.target.value
                          )
                        }
                      />
                    </div>
                  )}
                {this.props.businessDetails &&
                  this.props.businessDetails.companyRegistrationNumber && (
                    <div class="col-lg-4 mb-4">
                      <label>Company Registration Number *:</label>
                      <div class="kt-input-icon">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.businessDetails
                              ? this.props.businessDetails
                                  .companyRegistrationNumber
                              : ""
                          }
                          maxLength={8}
                          placeholder="Enter registration number"
                          onChange={(event) => {
                            this.props.onChange(
                              "companyRegistrationNumber",
                              event.target.value
                            );
                            this.props.updateSetState(
                              "companyRegistrationNumberIsValid",
                              checkCompanyRegistrationNumberIsValid(
                                event.target.value
                              )
                            );
                          }}
                        />
                        {this.props.businessDetailsFormData
                          .companyRegistrationNumber &&
                          !this.props.companyRegistrationNumberIsValid && (
                            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                <i
                                  class="la la-info-circle"
                                  style={{ color: "red" }}
                                ></i>
                              </span>
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                {/* </div> */}

                {/* <div class="form-group row"> */}
                {((this.props.businessDetails &&
                  this.props.businessDetails.phoneNumber) ||
                  this.props.showPhoneInput) && (
                  <div class="col-lg-4 mb-4">
                    <label>Phone *:</label>
                    <div class="kt-input-icon">
                      <PhoneNumberComponent
                        phoneIsValid={
                          this.props.businessPhoneIsValid
                            ? this.props.businessPhoneIsValid
                            : true
                        }
                        onChange={(status, phoneNumber) => {
                          this.props.onChange("phoneNumber", phoneNumber);
                          this.props.updateSetState(
                            "businessPhoneIsValid",
                            status || isPhoneNumberValid(phoneNumber)
                          );
                        }}
                        defaultValue={
                          this.props.businessDetails &&
                          this.props.businessDetails.phoneNumber
                            ? this.props.businessDetails.phoneNumber
                            : ""
                        }
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                      />
                      {this.props.businessDetailsFormData.phoneNumber &&
                        !this.props.businessPhoneIsValid && (
                          <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                              <i
                                class="la la-info-circle"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          </span>
                        )}
                    </div>
                  </div>
                )}

                {this.props.businessDetails &&
                  this.props.businessDetails.state && (
                    <div class="col-lg-4 mb-4">
                      <label class="">State *:</label>
                      {this.props.disabled ? (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.businessDetails
                              ? this.props.getStateName(
                                  this.props.businessDetails.state
                                )
                              : this.props.businessDetails.state
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange("state", event.target.value)
                          }
                        />
                      ) : (
                        <Select
                          options={this.props.states.map((detail) => {
                            return { label: detail.name, value: detail.id };
                          })}
                          onChange={(event) =>
                            this.props.updateState(event.value, "businessState")
                          }
                          minHeight={37}
                          disabled={this.props.disabled}
                          defaultValue={
                            this.props.businessDetailsFormData &&
                            this.props.businessDetailsFormData.state
                          }
                        />
                      )}
                    </div>
                  )}

                {this.props.businessDetails &&
                  this.props.businessDetails.localGovernmentArea && (
                    <div class="col-lg-4 mb-4">
                      <label class="">LGA *:</label>
                      {this.props.disabled ? (
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.businessDetails
                              ? this.props.getLgaName(
                                  this.props.businessDetails.localGovernmentArea
                                )
                              : this.props.businessDetails.localGovernmentArea
                          }
                          placeholder=""
                          onChange={(event) =>
                            this.props.onChange(
                              "localGovernmentArea",
                              event.target.value
                            )
                          }
                        />
                      ) : (
                        <Select
                          options={this.props.lgas.map((detail) => {
                            return { label: detail.name, value: detail.id };
                          })}
                          onChange={(event) =>
                            this.props.onChange(
                              "localGovernmentArea",
                              event.value
                            )
                          }
                          minHeight={37}
                          disabled={this.props.disabled}
                          defaultValue={
                            this.props.businessDetailsFormData &&
                            this.props.businessDetailsFormData
                              .localGovernmentArea
                          }
                        />
                      )}
                    </div>
                  )}

                {process.env.REACT_APP_SHOW_AGENT_EXEMPTION === 'true' &&
                  this.props.businessDetails && (
                    <div class="form-group row">
                      <div
                        class="col-lg-12 d-flex justify-content-center align-items-center"
                        style={{ paddingTop: "25px", paddingLeft: "30px" }}
                      >
                        <div class="form-check kt-radio-inline">
                          <input
                            class="kt-radio"
                            type="checkbox"
                            id="exemptAgent"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            checked={this.props.exemptAgent}
                            onChange={this.props.handleChange}
                          />
                          <label>Exempt agent from business validation</label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              <div class="form-group row">
                {this.props.businessDetails &&
                  this.props.businessDetails.address && (
                    <div class="col-lg-12">
                      <label>Address *:</label>&nbsp;&nbsp;
                      {!this.props.disabled &&
                        this.props.applicantDetailsFormData.address && (
                          <label>
                            <input
                              type="checkbox"
                              onChange={(event) => {
                                this.props.updateSetState(
                                  "shouldUpdateBusinessAddress",
                                  !this.props.shouldUpdateBusinessAddress
                                );
                                this.props.updateBusinessAddress(
                                  !this.props.shouldUpdateBusinessAddress
                                );
                              }}
                            />
                            <span>
                              Tick this box if your business address is same as
                              applicant address
                            </span>
                          </label>
                        )}
                      <div class="kt-input-icon kt-input-icon--right">
                        <textarea
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          rows={5}
                          style={{ resize: "none" }}
                          class="form-control"
                          placeholder="Enter office address"
                          defaultValue={
                            this.props.businessDetailsFormData &&
                            this.props.businessDetailsFormData.address
                              ? this.props.businessDetailsFormData.address
                              : this.props.businessDetails &&
                                this.props.businessDetails.address
                              ? this.props.businessDetails.address
                              : ""
                          }
                          onChange={(event) =>
                            this.props.onChange("address", event.target.value)
                          }
                        />
                      </div>
                      <span class="form-text text-muted">Office address</span>
                    </div>
                  )}
              </div>
              <div class="form-group row">
                {this.props.businessDetails &&
                  this.props.businessDetails.businessEmail && (
                    <div class="col-lg-4 mb-4">
                      <label>Email Address:</label>
                      <div class="kt-input-icon">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="email"
                          class="form-control"
                          defaultValue={
                            this.props.businessDetails
                              ? this.props.businessDetails.businessEmail
                              : ""
                          }
                          placeholder="Enter business email address"
                          onChange={(event) => {
                            this.props.updateSetState(
                              "businessEmailIsValid",
                              checkEmailIsValid(event.target.value)
                            );
                            this.props.onChange(
                              "businessEmail",
                              event.target.value
                            );
                          }}
                        />
                        {this.props.businessDetailsFormData.businessEmail &&
                          !this.props.businessEmailIsValid && (
                            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                <i
                                  class="la la-info-circle"
                                  style={{ color: "red" }}
                                ></i>
                              </span>
                            </span>
                          )}
                      </div>
                    </div>
                  )}

                {this.props.showAgentClass && (
                  <div class="col-lg-4 mb-4">
                    <label>Agent Class:</label>
                    <Select
                      options={this.props.agentClasses.map((detail) => {
                        return { label: detail.name, value: detail.id };
                      })}
                      onChange={(event) =>
                        this.props.onChange("agentClass", event.value)
                      }
                      disabled={
                        this.props.disabledAgentClass
                          ? this.props.disabledAgentClass
                          : this.props.disabled
                      }
                      defaultValue={
                        this.props.businessDetailsFormData.agentClass
                          ? this.props.businessDetailsFormData.agentClass
                          : this.props.businessDetails.agentClassId
                      }
                    />
                  </div>
                )}
                {(this.props.businessDetails &&
                  this.props.businessDetails.agentTypeId) === 4 && (
                  <div class="col-lg-4 mb-4">
                    <label>Aggregator:</label>
                    <Select
                      options={this.props.aggregators.map((detail) => {
                        return {
                          label: detail.businessName,
                          value: detail.referralCode,
                        };
                      })}
                      onChange={(event) =>
                        this.props.onChange("superAgent", event.value)
                      }
                      disabled={this.props.disabled}
                      defaultValue={
                        this.props.businessDetailsFormData.superAgent
                          ? this.props.businessDetailsFormData.superAgent
                          : this.props.businessDetails.parentId
                      }
                    />
                  </div>
                )}
                {this.props.businessDetails &&
                  this.props.businessDetails.bankName && (
                    <div class="col-lg-4 mb-4">
                      <label>Bank Name *:</label>
                      <Select
                        options={this.props.banks.map((detail) => {
                          return { label: detail.name, value: detail.name };
                        })}
                        onChange={(event) =>
                          this.props.onChange("bankName", event.value)
                        }
                        minHeight={37}
                        disabled={this.props.disabled}
                        defaultValue={
                          this.props.businessDetailsFormData
                            ? this.props.businessDetailsFormData.bankName
                            : this.props.businessDetails.bankName
                        }
                      />
                    </div>
                  )}
                {/* </div> */}

                {/* <div class="form-group row"> */}
                {this.props.businessDetails &&
                  this.props.businessDetails.accountNumber && (
                    <div class="col-lg-4 mb-4">
                      <label>Account Number *:</label>
                      <div class="kt-input-icon">
                        <input
                          autoComplete="off"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.businessDetails
                              ? this.props.businessDetails.accountNumber
                              : ""
                          }
                          maxLength={10}
                          placeholder="Enter account number"
                          onChange={(event) => {
                            this.props.updateSetState(
                              "accountNumberIsValid",
                              checkAccountNumberIsValid(event.target.value)
                            );
                            this.props.onChange(
                              "accountNumber",
                              event.target.value
                            );
                          }}
                        />
                        {this.props.businessDetailsFormData.accountNumber &&
                          !this.props.accountNumberIsValid && (
                            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                <i
                                  class="la la-info-circle"
                                  style={{ color: "red" }}
                                ></i>
                              </span>
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                {this.props.businessDetails && this.props.businessDetails.bvn && (
                  <div class="col-lg-4 mb-4">
                    <label>BVN *:</label>
                    <div class="kt-input-icon">
                      <input
                        autoComplete="off"
                        disabled={
                          this.props.disabled ? this.props.disabled : ""
                        }
                        type="text"
                        class="form-control"
                        defaultValue={
                          this.props.applicantDetails
                            ? this.props.applicantDetails.bvn
                            : ""
                        }
                        maxLength={11}
                        placeholder="Enter your BVN"
                        onChange={(event) => {
                          this.props.updateSetState(
                            "bvnIsValid",
                            checkBvnIsValid(event.target.value)
                          );
                          this.props.onChange2("bvn", event.target.value);
                        }}
                      />
                      {this.props.applicantDetailsFormData.bvn &&
                        !this.props.bvnIsValid && (
                          <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                              <i
                                class="la la-info-circle"
                                style={{ color: "red" }}
                              ></i>
                            </span>
                          </span>
                        )}
                    </div>
                  </div>
                )}
                {this.props.businessDetails &&
                  this.props.businessDetails.bvnVerificationStatus && (
                    <div class="col-lg-4 mb-4">
                      <label>BVN Status*:</label>
                      <div class="kt-input-icon">
                        <input
                          autoComplete="off"
                          disabled
                          type="text"
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails
                              ? this.props.applicantDetails
                                  .bvnVerificationStatus
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
