import React from "react";
import FeeSplits from "../../../../../../components/fee-splits";
import { transactionExtraSerializer } from "../../../../../../serializers/transaction-extras";
import { convertToMajor } from "../../../../../../utils/converter";
import {
  formatCurrency,
  formatDateTime,
  formatTransactionStatus,
  maskDeviceUuid
} from "../../../../../../utils/formatter";
import { getTransactionTypeName } from "../../../../../../utils/helper";
import AccountOpening from "./transaction-details-account-opening";
import TransactionReversalDetails from "./transaction-reversal-details";

const FeeSplit = (props) => {
  if (
    formatTransactionStatus(
      props.transactionDetails.statusCode
        ? props.transactionDetails.statusCode
        : ""
    ) == "Successful" &&
    props.transactionDetails.transactionTypeInt < 10
  ) {
    return (
      <div class="form-group row">
        <FeeSplits feeSplits={props.parties} isLoading={props.isLoading} />
      </div>
    );
  } else return null;
};

const Reversal = (props) => {
  if (
    formatTransactionStatus(
      props.transactionDetails.statusCode
        ? props.transactionDetails.statusCode
        : ""
    ) == "Failed" &&
    props.transactionDetails.transactionTypeInt < 10
  ) {
    return (
      <div class="form-group row">
        <TransactionReversalDetails
          reversalDetails={props.reversal}
          isLoading={props.isLoading}
        />
      </div>
    );
  } else return null;
};

export default class TransactionDetailsModal extends React.Component {
  render() {
    const extras = this.props.transactionDetails.extraInfoList
      ? this.props.transactionDetails.extraInfoList
      : [];

    return (
      <React.Fragment>
        <div
          class="modal"
          id="transaction_details_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Transaction Details
                </h3>
              </div>
              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  data-toggle="collapse"
                  data-target={`#collapseTransDetails`}
                  aria-expanded="true"
                  aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label>
                        <strong>Transaction Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>

                <form
                  class="kt-form kt-form--label-right"
                  id={`collapseTransDetails`}
                  class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Transaction Reference</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.transactionDetails.transactionRef}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Agent Name</strong>
                          </label>
                          <br />
                          <span>{this.props.transactionDetails.agentName}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Amount</strong>
                          </label>
                          <br />
                          <span>
                            &#x20A6;
                            {formatCurrency(
                              this.props.transactionDetails.transactionAmount
                                ? convertToMajor(
                                    this.props.transactionDetails
                                      .transactionAmount
                                  )
                                : 0
                            )}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Fee</strong>
                          </label>
                          <br />
                          <span>
                            &#x20A6;
                            {formatCurrency(
                              this.props.transactionDetails.fee
                                ? convertToMajor(
                                    this.props.transactionDetails.fee
                                  )
                                : 0
                            )}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Transaction Type</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.transactionDetails.transactionTypeInt
                              ? getTransactionTypeName(
                                  this.props.transactionDetails
                                    .transactionTypeInt
                                )
                              : ""}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Date</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.transactionDetails.dateCreated
                              ? formatDateTime(
                                  this.props.transactionDetails.dateCreated
                                )
                              : ""}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Narration</strong>
                          </label>
                          <br />
                          <span>{this.props.transactionDetails.narration}</span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <br />
                          <span>
                            {formatTransactionStatus(
                              this.props.transactionDetails.statusCode
                                ? this.props.transactionDetails.statusCode
                                : ""
                            )}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        {(this.props.transactionDetails.customerEmail ||
                          this.props.transactionDetails.borneBy) && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Borne By</strong>
                            </label>
                            <br />
                            <span>{this.props.transactionDetails.borneBy}</span>
                          </div>
                        )}
                        {this.props.transactionDetails.pin && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Token</strong>
                            </label>
                            <br />
                            <span>{this.props.transactionDetails.pin}</span>
                          </div>
                        )}
                      </div>

                      {(this.props.transactionDetails.dateCreated ||
                        this.props.transactionDetails.dateModified) && (
                        <div class="form-group row">
                          {this.props.transactionDetails.dateCreated && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Date Created</strong>
                              </label>
                              <br />
                              <span>
                                {formatDateTime(
                                  this.props.transactionDetails.dateCreated
                                )}
                              </span>
                            </div>
                          )}
                          {this.props.transactionDetails.dateModified && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Date Modified</strong>
                              </label>
                              <br />
                              <span>
                                {formatDateTime(
                                  this.props.transactionDetails.dateModified
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.props.transactionDetails.paymentMethod ||
                        this.props.transactionDetails.paymentItemCode) && (
                        <div class="form-group row">
                          {this.props.transactionDetails.paymentMethod && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Payment Method</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.transactionDetails.paymentMethod}
                              </span>
                            </div>
                          )}
                          {this.props.transactionDetails.paymentItemCode && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Payment Item Code</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.transactionDetails.paymentItemCode}
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {this.props.transactionDetails.collectionsAccount && (
                        <>
                          <div class="form-group row">
                            <div class="col-lg-6">
                              <label>
                                <strong>Collections Account</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.transactionDetails
                                    .collectionsAccount
                                }
                              </span>
                            </div>
                            <div class="col-lg-6">
                              <label>
                                <strong>Masked Pan</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.transactionDetails.maskedPan}
                              </span>
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="col-lg-6">
                              <label>
                                <strong>RRN</strong>
                              </label>
                              <br />
                              <span>{this.props.transactionDetails.rrn}</span>
                            </div>

                            <div class="col-lg-6">
                              <label>
                                <strong>Posting Date</strong>
                              </label>
                              <br />
                              <span>
                                {formatDateTime(
                                  this.props.transactionDetails.postingDate
                                )}
                              </span>
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="col-lg-6">
                              <label>
                                <strong>Fulfillment Date</strong>
                              </label>
                              <br />
                              <span>
                                {formatDateTime(
                                  this.props.transactionDetails.fulfillmentDate
                                )}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      {(this.props.transactionDetails.service ||
                        this.props.transactionDetails.serviceOptionName) && (
                        <div class="form-group row">
                          {this.props.transactionDetails.service && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Service</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.transactionDetails.service}
                              </span>
                            </div>
                          )}
                          {this.props.transactionDetails.serviceOptionName && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Service Option</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.transactionDetails
                                    .serviceOptionName
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.props.transactionDetails
                        .serviceProviderResponseCode ||
                        this.props.transactionDetails.responseCode) && (
                        <div class="form-group row">
                          {this.props.transactionDetails
                            .serviceProviderResponseCode && (
                            <div class="col-lg-6">
                              <label>
                                <strong>
                                  Service Provider Resp Code (Initial)
                                </strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.transactionDetails
                                    .serviceProviderResponseCode
                                }
                              </span>
                            </div>
                          )}
                          {this.props.transactionDetails.responseCode && (
                            <div class="col-lg-6">
                              <label>
                                <strong>
                                  Service Provider Resp Code (Final)
                                </strong>
                              </label>
                              <br />
                              <span>
                                {this.props.transactionDetails.responseCode}
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.props.transactionDetails
                        .walletProviderResponseCode ||
                        this.props.transactionDetails.customerType) && (
                        <div class="form-group row">
                          {this.props.transactionDetails
                            .walletProviderResponseCode && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Wallet Provider Response Code</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.transactionDetails
                                    .walletProviderResponseCode
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  data-toggle="collapse"
                  data-target={`#collapseCustomerDetails`}
                  aria-expanded="true"
                  aria-controls={`collapseCustomerDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      {(this.props.transactionDetails.customerFirstName ||
                        this.props.transactionDetails.customerMsisdn ||
                        this.props.transactionDetails.customerEmail) && (
                        <>
                          <label>
                            <strong>Customer Details</strong>
                          </label>
                        </>
                      )}
                    </h3>
                  </div>
                </div>

                <form
                  class="kt-form kt-form--label-right"
                  id={`collapseCustomerDetails`}
                  class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    {(this.props.transactionDetails.customerFirstName ||
                      this.props.transactionDetails.customerAddress) && (
                      <div class="form-group row">
                        {this.props.transactionDetails.customerFirstName && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer Name</strong>
                            </label>
                            <br />
                            <span>{`${
                              this.props.transactionDetails.customerFirstName
                            } ${
                              this.props.transactionDetails.customerLastName
                                ? this.props.transactionDetails.customerLastName
                                : ""
                            }`}</span>
                          </div>
                        )}
                        {this.props.transactionDetails.customerAddress && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer Address</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.customerAddress}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.props.transactionDetails.customerMsisdn ||
                      this.props.transactionDetails.customerId) && (
                      <div class="form-group row">
                        {this.props.transactionDetails.customerMsisdn && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer Number</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.customerMsisdn}
                            </span>
                          </div>
                        )}
                        {this.props.transactionDetails.customerId && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer ID</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.customerId}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.props.transactionDetails.customerEmail ||
                      this.props.transactionDetails.borneBy) && (
                      <div class="form-group row">
                        {this.props.transactionDetails.customerEmail && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer Email</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.customerEmail}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.props.transactionDetails.customerGender ||
                      this.props.transactionDetails.deviceUuid) && (
                      <div class="form-group row">
                        {this.props.transactionDetails.customerGender && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer Gender</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.customerGender}
                            </span>
                          </div>
                        )}
                        {this.props.transactionDetails.deviceUuid && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer DeviceId</strong>
                            </label>
                            <br />
                            <span>
                              {maskDeviceUuid(
                                this.props.transactionDetails.deviceUuid
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </form>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  data-toggle="collapse"
                  data-target={`#collapseBusinessDetails`}
                  aria-expanded="true"
                  aria-controls={`collapseBusinessDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      {(this.props.transactionDetails.businessName ||
                        this.props.transactionDetails.businessMobileNo) && (
                        <>
                          <label>
                            <strong>Business Details</strong>
                          </label>
                          <br />
                        </>
                      )}
                    </h3>
                  </div>
                </div>

                <form
                  class="kt-form kt-form--label-right"
                  id={`collapseBusinessDetails`}
                  class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    {(this.props.transactionDetails.businessName ||
                      this.props.transactionDetails.businessMobileNo) && (
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Business Name</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.transactionDetails.businessName}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Business Phone Number</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.transactionDetails.businessMobileNo}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>

              {(this.props.transactionDetails.bankName ||
                this.props.transactionDetails.beneficiaryFirstName) && (
                <div class="kt-portlet">
                  <div
                    class="kt-portlet__head"
                    style={{ cursor: "pointer" }}
                    data-toggle="collapse"
                    data-target={`#collapseTransferDetails`}
                    aria-expanded="true"
                    aria-controls={`collapseTransferDetails`}
                  >
                    <div
                      class="kt-portlet__head-label"
                      role="button"
                      aria-expanded="true"
                    >
                      <h3 class="kt-portlet__head-title">
                        <label>
                          <strong>Transfer Details</strong>
                        </label>
                        <br />
                      </h3>
                    </div>
                  </div>

                  <form
                    class="kt-form kt-form--label-right"
                    id={`collapseTransferDetails`}
                    class={`collapse 'show'}`}
                    aria-labelledby="headingOne4"
                  >
                    <div class="kt-portlet__body">
                      {(this.props.transactionDetails.bankName ||
                        this.props.transactionDetails
                          .beneficiaryAccountNumber) && (
                        <div class="form-group row">
                          {this.props.transactionDetails.bankName && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Bank Name</strong>
                              </label>
                              <br />
                              <span>
                                {this.props.transactionDetails.bankName}
                              </span>
                            </div>
                          )}
                          {this.props.transactionDetails
                            .beneficiaryAccountNumber && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Beneficiary Account Number</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.transactionDetails
                                    .beneficiaryAccountNumber
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.props.transactionDetails.beneficiaryFirstName ||
                        this.props.transactionDetails
                          .beneficiaryMobileNumber) && (
                        <div class="form-group row">
                          {this.props.transactionDetails
                            .beneficiaryFirstName && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Beneficiary Name</strong>
                              </label>
                              <br />
                              <span>{`${
                                this.props.transactionDetails
                                  .beneficiaryFirstName
                              } ${
                                this.props.transactionDetails
                                  .beneficiaryOtherName
                                  ? this.props.transactionDetails
                                      .beneficiaryOtherName
                                  : ""
                              } ${
                                this.props.transactionDetails
                                  .beneficiaryLastName
                                  ? this.props.transactionDetails
                                      .beneficiaryLastName
                                  : ""
                              }`}</span>
                            </div>
                          )}
                          {this.props.transactionDetails
                            .beneficiaryMobileNumber && (
                            <div class="col-lg-6">
                              <label>
                                <strong>Beneficiary Mobile Number</strong>
                              </label>
                              <br />
                              <span>
                                {
                                  this.props.transactionDetails
                                    .beneficiaryMobileNumber
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              )}

              <AccountOpening data={this.props.transactionDetails} />

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  data-toggle="collapse"
                  data-target={`#collapseResponse`}
                  aria-expanded="true"
                  aria-controls={`collapseResponse`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      {(this.props.transactionDetails.paymentMethod ||
                        this.props.transactionDetails
                          .serviceProviderResponseCode ||
                        this.props.transactionDetails.service ||
                        this.props.transactionDetails
                          .walletProviderResponseCode ||
                        this.props.transactionDetails.serviceProvider ||
                        this.props.transactionDetails
                          .walletProviderTranRef) && (
                        <>
                          <label>
                            <strong>Response</strong>
                          </label>
                          <br />
                        </>
                      )}
                    </h3>
                  </div>
                </div>

                <form
                  class="kt-form kt-form--label-right"
                  id={`collapseResponse`}
                  class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    {(this.props.transactionDetails
                      .walletProviderResponseCode ||
                      this.props.transactionDetails.customerType) && (
                      <div class="form-group row">
                        {this.props.transactionDetails.customerType && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Customer Type</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.customerType}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.props.transactionDetails.serviceProvider ||
                      this.props.transactionDetails.serviceProviderTranRef) && (
                      <div class="form-group row">
                        {this.props.transactionDetails.serviceProvider && (
                          <div class="col-lg-6">
                            <label>
                              <strong>Service Provider</strong>
                            </label>
                            <br />
                            <span>
                              {this.props.transactionDetails.serviceProvider}
                            </span>
                          </div>
                        )}
                        {this.props.transactionDetails
                          .serviceProviderTranRef && (
                          <div class="col-lg-6">
                            <label>
                              <strong>
                                Service Provider Transaction Reference
                              </strong>
                            </label>
                            <br />
                            <span>
                              {
                                this.props.transactionDetails
                                  .serviceProviderTranRef
                              }
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.props.transactionDetails.walletProviderTranRef ||
                      this.props.transactionDetails.requestReference) && (
                      <div class="form-group row">
                        {this.props.transactionDetails
                          .walletProviderTranRef && (
                          <div class="col-lg-6">
                            <label>
                              <strong>
                                Wallet Provider Transaction Reference
                              </strong>
                            </label>
                            <br />
                            <span>
                              {
                                this.props.transactionDetails
                                  .walletProviderTranRef
                              }
                            </span>
                          </div>
                        )}
                        <div class="col-lg-6">
                          <label>
                            <strong>Request Reference</strong>
                          </label>
                          <br />
                          <span>
                            {this.props.transactionDetails.requestReference}
                          </span>
                        </div>
                      </div>
                    )}
                    <hr />

                    <div class="form-group row">
                      {Boolean(extras) &&
                        Object.entries(transactionExtraSerializer(extras)).map(
                          ([key, value]) => (
                            <div class="col-lg-6">
                              <label>
                                <strong>{key}</strong>
                              </label>
                              <br />
                              <span>{value}</span>
                              <br />
                              <br />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </form>
              </div>

              <FeeSplit
                parties={this.props.settlementDetails.parties}
                isLoading={this.props.isSettlementLoading}
                transactionDetails={this.props.transactionDetails}
              />
              <Reversal
                reversal={this.props.reversalDetails}
                isLoading={this.props.isReversalDetailLoading}
                transactionDetails={this.props.transactionDetails}
              />
              {/* {(formatTransactionStatus(this.props.transactionDetails.statusCode ? this.props.transactionDetails.statusCode : '') == 'Successful' && this.props.transactionDetails.transactionTypeInt != 10) ? 
                                <a class="pull-right" href={`/home/reporting/settlement-report/view?transRef=${this.props.transactionDetails.transactionRef}`}>View Settlement Details</a>
                            : ''}           
                            <br/> */}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
