import React from 'react';
import ContentHead from '../../../../../../components/content-head';
import BaseScene from '../../../../../../components/scenes/component';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { GridLayout } from '../../../../../../components/layouts/grid-layout'
import { 
  CAN_MESSAGING_WRITE_TOPIC, CAN_MESSAGING_READ_TOPIC
} from '../../../../../../constants/permissions'
import { SUCCESS_STATUS } from '../../../../../../constants/api';
import { AccessDenied } from '../../../../../../components/access-denied';
import { validateApplicationPermission } from '../../../../../../mixins/authentication'
import { messagingService, onboarding } from '../../../../../../mixins/api'
import { PromptModal } from '../../../../../../components/modals/prompt-modal'
import Notification from '../../../../../../components/notification'
import { formatBackEndDateTime } from '../../../../../../utils/formatter'
import NotificationGroupSearch from '../../components/notification-group-search';
import NotificationGroupTable from '../../components/notification-group-table';

export default class NotificationGroupGridScene extends BaseScene {

  metadataSectionRef = React.createRef(null)
  
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      topics: [],
      topicsSearchParameters: {},
      applicationSearchParameters: {
        // fromDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        // toDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      useDefaultDate: true
    }
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getApplications = this.getApplications.bind(this);
    this.getTopics = this.getTopics.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this)
    this.updateApplicationStatus = this.updateApplicationStatus.bind(this)
    this.deleteNotification = this.deleteNotification.bind(this)
  }

  sceneName = 'notification-group'
  
  componentDidMount() {
    this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, approvalStatus: validateApplicationPermission()}})
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 3000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 3000)
    }
  }

  async getApplications (pageNum, pageSize) {
    const payload = this.state.applicationSearchParameters
    payload.fromDate = payload.fromDate && formatBackEndDateTime(payload.fromDate)
    payload.toDate = payload.toDate && formatBackEndDateTime(payload.toDate)
    this.setState({
      isLoading: true,
      canLoadData: true
    })
    const responseObj = await onboarding.searchApplications(pageNum, pageSize, this.state.applicationSearchParameters)
    const { status, response } = responseObj
    const { content, count} = response
    if (status === SUCCESS_STATUS) {
      this.setState({
        applications: content,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        dataCount: response.count,
        tableMessage: content.length == 0 ? "No applications found" : ""
      })
    }else{
      this.setState({
        applications: [],
        applicationExportData: [],
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        errorMessage: response,
        tableMessage: "No applications found"
      })
    }
    window.scrollTo(0, this.metadataSectionRef.current)
  }

  async getTopics(pageNum, pageSize) {
    const payload = this.state.topicsSearchParameters;
    payload.fromDate = payload.fromDate && formatBackEndDateTime(payload.fromDate)
    payload.toDate = payload.toDate && formatBackEndDateTime(payload.toDate)
    this.setState({
      isLoading: true,
      canLoadDate: true
    })
    const { status, response } = await messagingService.getAllTopicsQuery(pageNum, pageSize, this.state.topicsSearchParameters);
    const { items, totalCount } = response;
    if(status === SUCCESS_STATUS) {
      this.setState({
        topics: items,
        pages: Math.ceil(totalCount/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        dataCount: totalCount,
        tableMessage: items.length == 0 ? "No notification group found" : ""
      })
    } else {
      this.setState({
        topics: [],
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        errorMessage: response,
        tableMessage: "No notification group found"
      })
    }
  }

  handleSearchRequest (topicsSearchParameters) {
    this.setState({
      startPage: 1,
      topicsSearchParameters,
    })
    setTimeout(() => {
      this.getTopics(1, this.state.pageSize)
    }, 200)
  }

  getClickedAction (topicId, topicName) {
    this.setState({
      topicId,
      topicName,
    })
  }

  async deleteNotification () {
    this.setState({
      isLoading: true
    })
    const { status, response } = await messagingService.deleteTopics(this.state.topicId)
    if(status === SUCCESS_STATUS) {
      this.setState({
        topicId: null,
        topicName: null,
        successMessage: `Notification group was successfully deleted`,
        isLoading: false
      })
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false
      })
    }
    this.getTopics(this.state.page, this.state.pageSize)
  }

  async updateApplicationStatus () {
    this.setState({isLoading: true})
    const responseObj =  await onboarding.updateApplicationStatus(this.state.applicationId, this.state.applicationStatus)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        applicationId: null,
        applicationName: null,
        applicationStatus: null,
        successMessage: `Application was successfully cancelled!`,
        isLoading: false,
      })
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getApplications()
  }

  render () {
    return <React.Fragment>
      <Notification
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        ref={this.metadataSectionRef}
      />
      <ContentHead title={"Notifications Group"} totalCount={56} filterPermissions={[CAN_MESSAGING_READ_TOPIC]} actionButtonRequiredPermissions={[CAN_MESSAGING_WRITE_TOPIC]} actionButtonText={"New Notification Group"} actionButtonUrl={"notification-group/add"} showTooltip={true} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_MESSAGING_READ_TOPIC]}>
        <GridLayout>
          <NotificationGroupSearch
            handleSearchRequest={this.handleSearchRequest}
          />
          <NotificationGroupTable
            data={this.state.topics} 
            showApplicantName={false} 
            showBusinessName={true} 
            getTopics={this.getTopics}
            pages={this.state.pages}
            startPage={this.state.startPage}
            noDataText={this.state.tableMessage}
            loading={this.state.isLoading}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getClickedAction={this.getClickedAction}
            canView={CAN_MESSAGING_WRITE_TOPIC}
            canCancel={CAN_MESSAGING_WRITE_TOPIC}
            canLoadData={this.state.canLoadData}
          />
          <PromptModal
            modalTitle={ this.state.topicId }
            modalBody={`Do you want to delete ${this.state.topicName} Notification group?`}
            deleteModal={this.deleteNotification}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
