import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import Banks from '../../../../../../fixtures/banks';
import ServiceTypes from '../../../../../../fixtures/service_types';
import TransactionStatuses from '../../../../../../fixtures/transaction_statuses';

export default class TransactionSummarySearch extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
      useBanks: null
    }
  }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest()
    }

    serviceTypeUseBankCheck (serviceTypeId) {
        const serviceType = ServiceTypes.filter(value => value.id == serviceTypeId)
        this.setState({'useBanks':serviceType[0].useBanks})
    }
    
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class='form-group row'>
                    <div class="col-md-3">
                        <label class="search-label">Transaction Type</label>
                        <Select 
                            options={ServiceTypes}
                            onChange={(event) => this.props.updateSearchParameters('transactionTypeInt', event.value)}
                            defaultValue={this.props.searchParameters.transactionTypeInt}
                        />
                        {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('transactionTypeInt', event.target.value)} >
                            <option selected value={null}>All</option>
                            {ServiceTypes.map(value=><option value={value.id} selected={this.props.searchParameters.transactionType == value.id ? 'selected' : ''}>{value.name}</option>)}
                        </select> */}
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Status</label>
                        <Select 
                            options={TransactionStatuses}
                            onChange={(event) => this.props.updateSearchParameters('statusCodeInt', event.value)}
                            defaultValue={this.props.searchParameters.statusCodeInt}
                        />
                        {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('statusCodeInt', event.target.value)} >
                            <option selected value={null}>All</option>
                            {TransactionStatuses.map(value=><option value={value.id}>{value.name}</option>)}
                        </select> */}
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.props.updateSearchParameters('startDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={this.props.preloadDate}
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent 
                            onChange={(event) => this.props.updateSearchParameters('endDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.props.searchParameters.startDate ? false : true}
                            minDate={this.props.searchParameters.startDate}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={this.props.preloadDate}
                        />
                    </div>
                    
                    {(this.state.useBanks) && <div class="col-md-3">
                     <label class="search-label">Bank</label>
                        <select class="search-input" onChange={(event) => this.props.updateSearchParameters('bankCode', event.target.value)} >
                            <option selected value={null}>All</option>
                            {Banks.map(value=><option value={value.cbnCode} selected={this.props.searchParameters.bankCode == value.cbnCode ? 'selected' : ''}>{value.name}</option>)}
                        </select>
                    </div>}

                    <div class="col-md-3">
                        <label class="search-label">&nbsp;</label>
                        {(!this.props.disabled) && <label>
                            <input type="checkbox" onChange={event => {
                                this.props.updateSearchParameters('agent', !this.props.searchParameters.agent)
                            }} />
                            <span>Tick to group by agents</span>
                        </label>}
                    </div>


                    

                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}