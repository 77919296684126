import React from 'react'
import BaseScene from '../../../../../../../components/scenes/component';
import ContentHead from '../../../../../../../components/content-head';
import { ERROR_STATUS } from '../../../../../../../constants/api';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { AccessDenied } from '../../../../../../../components/access-denied';
import { CAN_VIEW_BANK, CAN_UPDATE_BANK } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import BankForm from '../../components/bank-form';
import { nipbankService } from '../../../../../../../mixins/api';
import { Redirect } from 'react-router-dom'

export default class AddBankScene extends BaseScene {
  constructor (props) {
    super(props)
    this.state = {
      canSave: null,
      saveButton: true,
      disabled: true,
      pageTitle: "Bank List",
      statuses: [
        {"id": 1, "name": "Active"},
        {"id": 2, "name": "Inactive"}
      ],
      bankDetails: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

  componentDidMount() {
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  validateForm = (param) => {
    let formResponse = false;
    if(!param.bankName){
      this.handleValidationErrorResponse("Bank name is required");
    }
    else if(!param.nipBankCode){
      this.handleValidationErrorResponse("NIP Bank code is required");
    }
    else if(!param.cbnBankCode){
      this.handleValidationErrorResponse("CBN Bank Code is required");
    }
    else if(!param.bankCategory){
      this.handleValidationErrorResponse("bank category is required");
    }
    else{formResponse = true}
    return formResponse
  }
  

  handleValidationErrorResponse = (description) => {
    //console.log("Error: ",description)
   // alert(description)
    this.setState({
      isLoading: false,
      errorMessage: description
    })
  }

  async onSaveButtonClick () {
    this.setState({
      isLoading: true,
    });

    const param = {...this.state.bankDetails}
    
    if(this.validateForm(param)){
      
      const { status, response, code } = await nipbankService.addNIPBank(
        {
          "bankName":param.bankName, 
          "cbnBankCode":param.cbnBankCode,
          "nipBankCode":param.nipBankCode,
          "alias":param.alias,
          "bankCategory": param.bankCategory

        }
      )

      if (status === ERROR_STATUS) {
        this.setState({
          errorMessage: response.description,
          isLoading: false
        })
      }else{
        this.setState({
          isLoading: false,
          successMessage: 'Bank added successfully',
          redirect: true,
          bankDetails:{
                bankName:"",
                bankCategory:"",
                cbnBankCode:"",
                nipBankCode:"",
                alias:""
          }
        })
      //  alert('Bank added successfully')
        
      }
    }
    
  }

  updateSetState (type, value) {
    this.setState({
      bankDetails: {...this.state.bankDetails, [type]: value}})
  }

  render () {

    // if(this.state.redirect) {
    //   return <Redirect to="/home/system-configuration/nip-bank-management" />
    // }
   
   const ctaWidgets = <React.Fragment>
        {this.state.saveButton && <ProtectedComponent requiredPermissions={[CAN_UPDATE_BANK]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>



    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Bank Details"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_BANK]}>
        <GridLayout>
          <BankForm 
            onChange={this.updateSetState}
            {...this.state.bankDetails}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
