import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CAN_GET_DEVICES_BY_UUID } from "../../../../../constants/permissions.js";
import { isAuthorized } from "../../../../../mixins/authentication";
import AllStockGridScene from "./scenes/stock-grid";

export default class StocksScene extends Component {
  sceneName = "all-stocks";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/all-stocks"
          component={AllStockGridScene}
        />
      </Router>
    );
  }
}
