import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
	FormInput,
	FormTextArea
} from '../../../../../../components/form-controls/form-input';
import { walletProxyService } from '../../../../../../mixins/api';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../constants/api';
import DebitLienHistoryTable from './debit-lien-history-table';
import { formatCurrency } from '../../../../../../utils/formatter';

const REGEX_FOR_PHONE_NUMBER_VALIDATION = /^[0]\d{10}$/;
const PHONE_NUMBER_VALIDATION_RESULTS = {};

  
export default function CancelLienForm(props) {
    const { 
		hasFormBeenSubmitted, innerRef, title, submitButton, onSubmit,
    } = props;

	const [isLoading, setIsLoading] = useState(false);

	const [lienData, setLienData] = useState({ items: [], totalCount: 0, });
	const [balanceData, setBalanceData] = useState({ items: [], totalCount: 0, });
		
		  

	function phoneNumberValidation(value, setIsLoading) {

		if((balanceData && balanceData.summary && balanceData.summary.accountNumber && value !== 0+balanceData.summary.accountNumber.slice(-10))
		|| !balanceData.summary){
			if (!REGEX_FOR_PHONE_NUMBER_VALIDATION.test(value)) {
				return 'Phone number is not valid';
			}

			setIsLoading(true);
			
			return walletProxyService.lienMiniStatement({
				"channel": "web",
				"pageNumber": 1,
				"accountNumber": `234${value.slice(-10)}`,
				"recordNumberToFetch": 10
				})
				.then(({response, status}) => {
					setIsLoading(false);
					if (status === ERROR_STATUS) {
						const errorMsg = 'Agent with phone number does not exist.';
						PHONE_NUMBER_VALIDATION_RESULTS[value] = errorMsg;
						setBalanceData([]);
						setLienData([]);
						return errorMsg;
					}
					if (status === SUCCESS_STATUS) {
						setBalanceData(response.responseMessage);
						setLienData(response.responseMessage.statementDetails);
					}
				})
				.catch(() => setIsLoading(false));
		}

}
		
	function amountValidation(value) {
		if (balanceData.summary.lienBalance/100 < value) {
			return 'Amount to cancel should not be greater than the lien balance';
		}
	}

	const validationSchema = Yup.object().shape({
		amount: Yup.number('Field must be a number')
			.min(10, 'Value is too small')
			.required('Field is required'),
		narration: Yup.string()
			.min(3, 'Field is too short')
			.required('Field is required'),
	});

    return <React.Fragment>
		<div class="kt-portlet">
			{title && <div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">{title}</h3>
				</div>
				{submitButton && <div class="kt-portlet__head-label">
					{submitButton}
				</div>}
			</div>}
			<Formik
				initialValues={{}}
				innerRef={innerRef}
				isInitialValid={true}
				onSubmit={(values, actions) => {
					onSubmit({...values, accountName: balanceData.summary.accountName,
						availableBalance: balanceData.summary.availableBalance});
				}}
				validationSchema={validationSchema}
			>
				<Form class="kt-form kt-form--label-right" id={'cancel-lien-form'}>
					<div class="kt-portlet__body"><div class="form-group row">
						<div class="col-md-6">
							<Field
								component={(props) => <FormInput
									{...props}
									isLoading={isLoading}
									showErrorIfAvailable={true}
									subMessage={balanceData && balanceData.summary && balanceData.summary.accountName}
								/>}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Agent Phone Number"
								name="customerMsisdn"
								class="search-input"
								validate={(value) => phoneNumberValidation(value, setIsLoading)}
								validateOnBlur={true}
								validateOnChange={true}
							/>
						</div>
						
						{balanceData && balanceData.summary && <>
						<div class="col-md-6">
							Lien Balance:<br/>
							<input value={`₦${formatCurrency(balanceData.summary.lienBalance/100)}`} disabled />
						</div>
						<div class="col-md-6">
							<Field
								component={FormInput}
								label="Amount to Cancel"
								name="amount"
								validate={(value) => amountValidation(value)}
							/>
						</div>
						<div class="col-md-6">
							Ledger Balance: <br/> <input disabled value={`₦${formatCurrency(balanceData.summary.ledgerBalance/100)}`}/>
						</div>
						<div class="col-md-6">
							<Field
								component={FormTextArea}
								numberOfLines="3"
								label="Reason for canceling Lien"
								name="narration"
							/>
						</div>
						<div class="col-md-6">
							Available Balance: <br/> <input disabled value={`₦${formatCurrency(balanceData.summary.availableBalance/100)}`}/>
						</div>
						</>}
						</div>
					</div>
				</Form>
			</Formik>

			{lienData.length > 0 && <DebitLienHistoryTable
              canLoadData={true}
              data={lienData}
              dataCount={lienData.length}
              loading={isLoading}
              page={1}
              pages={1}
              pageSize={10}
              searchParameters={{}}
            />}

		</div>
    </React.Fragment>
}
