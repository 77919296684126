import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import BaseScene from "../../../../../components/scenes/component";
import { CAN_VIEW_TRANSACTION } from "../../../../../constants/permissions";
import { isAuthorized } from "../../../../../mixins/authentication";
import AddLienScene from "./scenes/add-lien";
import CancelLienScene from "./scenes/cancel-lien/scene";
import DebitLienScene from "./scenes/debit-lien/scene";
import LienGridScene from "./scenes/lien-grid";

export default class LienManagementScene extends BaseScene {
  sceneName = "lien-management";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };

    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/lien-management"
          component={LienGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/lien-management/add"
          component={AddLienScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/lien-management/debit"
          component={DebitLienScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/lien-management/cancel"
          component={CancelLienScene}
        />
      </Router>
    );
  }
}
