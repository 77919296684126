import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_CREATE_COUNTRY } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import CountryForm from '../../components/country-form';

export default class AddCountryScene extends BaseScene {
  constructor (props) {
    super(props)

    this.state = {
      canSave: null,
      countryFormData: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onSaveButtonClick () {
    
  }

  updateSetState (type, value) {
    this.setState({countryFormData: {...this.state.countryFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_CREATE_COUNTRY]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.uploadNIPBank}>NIP Bank Upload</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Country"} withoutSearch subtitle="Add Country" ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_CREATE_COUNTRY]}>
        <GridLayout>
          <CountryForm 
            onChange={this.updateSetState}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
