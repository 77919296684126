import React from "react";
import Select from "../../../../../components/select";

export default class BuyPowerBalanceAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      thresholdStatus: [
        { value: "SAFE", label: "Safe" },
        { value: "CRITICAL", label: "Critical" },
        { value: "WARNING", label: "Warning" },
      ],
    };
  }
  
  render() {

    return (
      <React.Fragment>
        <div
          class="modal"
          id="buy_power_balance_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px", width: "450px", margin: "0 auto" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Add New wallet threshold
                </h3>
              </div>

              <div class="kt-portlet">
                <div class="kt-portlet__head" style={{ cursor: "pointer" }}>
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Threshold Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-12">
                          <label>
                            <strong>Threshold Amount</strong>
                          </label>
                          <br />
                          <input
                            type="number"
                            class="search-input"
                            onChange={(event) =>
                              this.props.updateSearchParameters(
                                "thresholdAmount",
                                event.target.value
                              )
                            }
                          />
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-12">
                          <label>
                            <strong>Threshold Type</strong>
                          </label>
                          <br />
                          <span>
                            <Select
                              options={this.state.thresholdStatus}
                              onChange={(event) =>
                                this.props.updateSearchParameters(
                                  "thresholdStatus",
                                  event.value
                                )
                              }
                            />
                          </span>
                          <br />
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>
                </form>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      border: "2px solid #00425f",
                      backgroundColor: "#ffffff",
                      color: "#00425f",
                      fontSize: "14px",
                      margin: "auto",
                      display: "block",
                    }}
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      backgroundColor: "#00425f",
                      color: "#ffffff",
                      fontSize: "14px",
                      margin: "auto",
                      display: "block",
                    }}
                    data-dismiss="modal"
                    onClick={() => this.props.handleBuyPowerAddThreshold()}
                    disabled={!(this.props.searchParameters.thresholdAmount && this.props.searchParameters.thresholdStatus)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
