import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '../../../components/forms/form';
import { FormGroup } from '../../../components/forms/form-group';
import { FormLayout } from '../../../components/forms/form-layout';
import { GridItem } from '../../../components/layouts/grid-item';
import Notification from '../../../components/notification';
import { FORGOTPASSWORD } from '../../../constants/routes';
import { disabledStyle } from '../../../constants/styles';
import { phoneNumberFormatter } from '../../../utils/formatter';
import { checkEmailIsValid } from '../../../utils/validator';

export const LoginForm = (props) => {
  const { 
    loginSuccessful, isLoading, emailIsValid, hidePassword, isFormValid, attemptLogin, errorMessage,
    updateLoginFormData, updateSetState, loginFormData: { username, password }
  } = props;
  return <>
    <GridItem isFluid class="kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
      <FormLayout title={"Sign In"}>
        {loginSuccessful === false && 
          <Notification 
            errorMessage={errorMessage}
          />
        }
        <Form class="kt-form" action="" novalidate="novalidate">
          <FormGroup>
            <input disabled={isLoading} style={isLoading ? disabledStyle : {}} defaultValue={username} id="username" class="form-control" type="text" placeholder="Email Address" name="username" onChange={(event) => {
              updateLoginFormData('username', phoneNumberFormatter(event.target.value))
              updateSetState('emailIsValid', checkEmailIsValid(event.target.value))
              updateSetState('loginSuccessful', null)
            }} autoComplete="off" />
            {(username && !emailIsValid) && <span style={{color: 'red'}}>Invalid Email Address</span>}
          </FormGroup>
          <FormGroup>
            <div class="kt-input-icon">
              <input disabled={isLoading} style={isLoading ? disabledStyle : {}} defaultValue={password} id="password" class="form-control" type={hidePassword ? `text` : `password`} placeholder="Password" name="password" onChange={(event) => {
                updateLoginFormData('password', event.target.value)
                updateSetState('loginSuccessful', null)
              }} autoComplete="off" />
              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                <span>
                  <i onClick={() => updateSetState('hidePassword', !hidePassword)} class={hidePassword ? `fa fa-eye-slash` : `fa fa-eye`}></i>
                </span>
              </span>
            </div>
          </FormGroup>
          <div class="kt-login__actions">
            <div class="external-link"><Link to={FORGOTPASSWORD}>Forgot Password?</Link></div>
          </div>
        <div class="kt-login__actions">
            <button id="kt_login_signin_submit" disabled={isLoading || isFormValid() || !emailIsValid} class="form-control btn btn-interswitch btn-elevate kt-login__btn-primary login-button" onClick={(e) => {
              e.preventDefault()
              attemptLogin()
            }}>{!isLoading ? `Sign In` : <span class="loading">Loading</span>}</button>
          </div>
        </Form>
      </FormLayout>
    </GridItem>
  </>
}