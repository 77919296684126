import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_UPDATE_SERVICE_TYPE, CAN_VIEW_SERVICE_TYPE } from '../../../../../../../constants/permissions.js';
import Handlers from '../../../../../../../fixtures/handlers.json';
import TouchPoints from '../../../../../../../fixtures/touchpoints.json';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { convertToMajor } from '../../../../../../../utils/converter';
import ServiceTypesForm from '../../components/service-types-form';

export default class ViewServiceTypeScene extends BaseScene {
  
  notificationSectionRef = React.createRef(null)

  constructor (props) {
    super(props)
    this.state = {
      isMaximumAmountValid: false,
      isMinimumAmountNegative: false,
      isMaximumAmountNegative: false,
      isTransactionIntervalNegative: false,
      canSave: null,
      touchpoints: TouchPoints,
      canUpdate: false,
      canSave: false,
      disabled: true,
      pageTitle: "View Service Type",
      handlers: [],
      serviceTypeFormData: {
        transactionInterval: 1,
        revenueSplits: [],
      },
      sharingParties: [],
      totalQuota: 0.0,
      //quota: 0,
      number: 2,
      externalServiceTypes: [
        {"id": 0, "name": "None"},
        {"id": 1, "name": "Recharge"},
        {"id": 2, "name": "Transfer"},
        {"id": 3, "name": "Bill"},
        {"id": 4, "name": "Cash_in"}
      ]
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.addRevenueSplit = this.addRevenueSplit.bind(this)
    this.getSharingPartiesLeft = this.getSharingPartiesLeft.bind(this)
    this.updateState = this.updateState.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
  }

  componentDidMount() {
    this.serviceTypeId = this.props.match.params.id
    this.setState({handlers: Handlers})
    this.getSharingParties()
    this.getServiceType()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getSharingParties () {
    const responseObj = await feeConfigurationManagement.getRevenueSharingParties()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        sharingParties: response.data,
      })
    }else{
      this.setState({
        sharingParties: []
      })
    }
  }

  async getServiceType () {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getServiceType(this.serviceTypeId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      let res = response
      res.settlementType = res.settlementType ? res.settlementType : 0
      res.settlementInterval  = !res.settlementInterval || res.settlementInterval === 0 ? 1 : res.settlementInterval
      this.setState({
        serviceTypeFormData: this.convertValueToNaira(res),
        serviceType: res,
        revenueSplits: res.revenueSplits,
        canUpdate: true,
        isLoading: false
      })
    }else{
      this.setState({
        serviceType: [],
        isLoading: false,
        errorMessage: response
      })
    }
  }

  convertValueToNaira (response) {
    response.minimumAmountNaira = convertToMajor(response.minimumAmount);
    response.maximumAmountNaira = convertToMajor(response.maximumAmount);
    return response
  }

  getSharingPartyName(partyId) {
    const sharingParty = this.state.sharingParties.filter(value => value.id === partyId)
    return sharingParty[0] ? sharingParty[0].name : 'Invalid Party';
  }

  getSelectedSharingParties () {
    const sharingPartiesIds = this.state.serviceTypeFormData.revenueSplits.map(value => value.sharingPartyId)
    return sharingPartiesIds
  }  

  async addRevenueSplit (e) {
    e.preventDefault()
    const revenueSplit = {
        "id": Date.now(),
        "sharingPartyId": this.state.sharingPartyId,
        "quota": parseFloat(this.state.quota)
    }

    //this.getSharingPartiesLeft(this.state.sharingPartyId);

    const sharingParties = (this.state.sharingParties).filter(function(value) { 
      if(value.id === revenueSplit.sharingPartyId) {
        value.selected = 0
      }  
      return value
    });

    this.setState({
        serviceTypeFormData: {...this.state.serviceTypeFormData,
          revenueSplits: [
            ...this.state.serviceTypeFormData.revenueSplits ? this.state.serviceTypeFormData.revenueSplits : '',
            revenueSplit,
          ],
        },
        totalQuota: this.toOneDecimal(parseFloat(this.state.totalQuota) - parseFloat(this.state.quota)),
        isQuotaValid: Boolean((parseFloat(this.state.totalQuota)-parseFloat(this.state.quota)) >= parseFloat(this.state.quota)),
        //quota: this.state.totalQuota - this.state.quota,
        sharingParties,
        sharingPartyId: null,
        //sharingPartyId: parseInt(this.state.sharingPartyId) + 1,

    })
    
  }

  splitPartyName (name) {
    return name.replace(/ /g, "_")
  }

  async removeRevenueSplit(id) {
    const revenueSplits = (this.state.serviceTypeFormData.revenueSplits).filter(function(value) { 
        return value.id !== id;  
    });

    const removedRevenueSplit = (this.state.serviceTypeFormData.revenueSplits).filter(function(value) { 
      return value.id === id;  
    });

    const sharingParties = (this.state.sharingParties).filter(function(value) { 
      if(value.id === removedRevenueSplit[0].sharingPartyId) {
        value.selected = 1
      }  
      return value
    });
    this.setState({
        serviceTypeFormData: {...this.state.serviceTypeFormData, revenueSplits},
        isQuotaValid: Boolean((parseFloat(this.state.totalQuota) + parseFloat(removedRevenueSplit[0].quota)) >= parseFloat(this.state.quota)),
        totalQuota: this.toOneDecimal(parseFloat(this.state.totalQuota) + parseFloat(removedRevenueSplit[0].quota)),
        //isQuotaValid: Boolean((this.state.totalQuota + parseInt(removedRevenueSplit[0].quota)) >= this.state.quota),
        sharingParties,
        //quota: this.state.totalQuota + parseInt(removedRevenueSplit[0].quota),
    })
  }

  isRevenueSplitsValid () {
    if(this.state.serviceTypeFormData.revenueSplits && this.state.serviceTypeFormData.revenueSplits.length > 0) {
      return this.validateSharingQuotas()
    }else{
      return true
    }
  }

  getSharingPartiesLeft (sharingPartyId) {
    const sharingParties = (this.state.sharingParties).map(function(value) { 
      return value.id === sharingPartyId ? value.status = 0 : '';  
    });

    this.setState({sharingParties})

  }

  async onUpdateButtonClick () {
    let sharingParties = this.state.sharingParties
    let ids = null
    let totalQuota = 100.00;
    if(this.state.serviceTypeFormData.revenueSplits) {
      ids = this.getSelectedSharingParties();
      sharingParties = this.state.sharingParties.filter(value => {
        if(ids.includes(value.id)) {
          value.selected = 0
        }
        return value
      })
      this.state.serviceTypeFormData.revenueSplits.map(value => {
        totalQuota = parseFloat(totalQuota) - parseFloat(value.quota)
      })
    }
    this.setState({
        disabled: false,
        canSave: true,
        canUpdate: false,
        pageTitle: 'Update Service Type',
        sharingParties,
        totalQuota,
    })
  }

  async onCancelButtonClick () {
    this.setState({
      disabled: true,
      canSave: false,
      canUpdate: true,
      pageTitle: 'View Service Type',
  })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})

    const responseObj = await feeConfigurationManagement.updateServiceType(this.serviceTypeId, this.state.serviceTypeFormData)
    const  { status, response } = responseObj
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
    if (status === SUCCESS_STATUS) {
      if(response.code === ACCEPTED_REQUEST){
        this.setState({
          isLoading: false,
          successMessage: 'Your changes to this service type has been sent successfully for approval.',
          canUpdate: true,
          canSave: false,
          disabled: true,
          pageTitle: "View Service Type",
        })
        return
      }else{
        this.setState({
          isLoading: false,
          canUpdate: true,
          canSave: false,
          disabled: true,
          pageTitle: "View Service Type",
          successMessage: 'Service type updated successfully.',
        })
      }
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response,
      })
    }
    
    return
  }

  validateSharingQuotas () {
    let totalQuota = 0.0;
    {this.state.serviceTypeFormData.revenueSplits && this.state.serviceTypeFormData.revenueSplits.map(value => {
        totalQuota = parseFloat(value.quota) + parseFloat(totalQuota)
      })
      return Boolean (parseFloat(totalQuota) >= 100)
    }
   
  }

  validateSharingQuota (quota) {
    return Boolean (parseFloat(this.state.totalQuota) >= parseFloat(quota) && parseFloat(quota) > 0)
  }

  isFormValid () {
    return this.state.isTransactionIntervalNegative || this.state.isMinimumAmountNegative || this.state.isMaximumAmountNegative
      || this.state.isMaximumAmountValid || this.state.serviceTypeFormData.settlementInterval < 0
  }

  checkMinField (minValue) {
    return Boolean (minValue)
  }

  updateSetState (type, value) {
    this.setState({serviceTypeFormData:{...this.state.serviceTypeFormData, [type]: value}})
  }

  updateState (type, value) {
    this.setState({[type]: value})
  }

  render () {
    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_SERVICE_TYPE]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_SERVICE_TYPE]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_SERVICE_TYPE]}><button type="button" disabled={this.isFormValid()}class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        ref={this.notificationSectionRef}
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Service Types"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_SERVICE_TYPE]}>
        <GridLayout>
          <ServiceTypesForm 
            serviceTypeFormData={this.state.serviceTypeFormData}
            updateSetState={this.updateSetState}
            updateState={this.updateState}
            handlers={this.state.handlers}
            externalServiceTypes={this.state.externalServiceTypes}
            disabled={this.state.disabled}
            isMaximumAmountNegative={this.state.isMaximumAmountNegative}
            isMaximumAmountValid={this.state.isMaximumAmountValid}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
