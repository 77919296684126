import React from 'react';
import { Link } from 'react-router-dom';


export const  MenuItem = (props) => {
  const { icon, text, children, to, onClick } = props;
  const content = <React.Fragment>
    <span class="kt-menu__link-icon">
      {icon}
    </span>  
    <span class="kt-menu__link-text">{text}</span>
    {children && <i class="kt-menu__ver-arrow la la-angle-right"></i>}
  </React.Fragment>

  return <li class={`kt-menu__item ${props.class} ${children && 'kt-menu__item--submenu'}`} aria-haspopup="true">
    {children ? <Link className="kt-menu__link kt-menu__toggle">
      {content}
    </Link> : <Link className="kt-menu__link" to={to} onClick={onClick}>
      {content}
    </Link>}

    <div class="kt-menu__submenu">
      <span class="kt-menu__arrow"></span>
      <ul class="kt-menu__subnav">
        {children}
      </ul>
    </div>
  </li>
}