import React from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

export default class RoleForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                {this.props.title && <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">{this.props.title}</h3>
                    </div>
                    {this.props.submitButton && <div class="kt-portlet__head-label">
                        {this.props.submitButton}
                    </div>}
                </div>}
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Name *:</label>
                            <input autoComplete="off" disabled={this.props.disabled2 ? this.props.disabled2 : ''} defaultValue={this.props.roleFormData ? this.props.roleFormData.name : ''} type="text" class="form-control" placeholder="Name" onChange={event => this.props.onChange('name', event.target.value)} />
                            <span class="form-text text-muted">Please enter a name</span>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Description:</label>
                            <textarea autoComplete="off" disabled={this.props.disabled2 ? this.props.disabled2 : ''} defaultValue={this.props.roleFormData ? this.props.roleFormData.description : ''} class="form-control" style={{height: '100px', resize: 'none'}} maxLength="100" name="description" onChange={event => this.props.onChange('description', event.target.value)}></textarea>
                            <span class="form-text text-muted">Maximum character 100</span>
                        </div>
                        <div class="col-lg-10">
                            <label style={{fontWeight: 400}}>Permissions *:</label>
                            <DualListBox
                                canFilter
                                //preserveSelectOrder
                                options={this.props.allPermissions.map((permission) => {
                                    return(
                                        {'label': permission.description, 'value': permission.id}
                                    )
                                })}
                                selected={this.props.roleFormData ? this.props.roleFormData.permissions : []}
                                onChange={permission => this.props.onChange('permissions', permission)}
                                disabled={this.props.disabled ? this.props.disabled : ''}
                                showHeaderLabels
                            />
                        </div> 
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}