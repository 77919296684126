import React, { useEffect, useState } from 'react';

export const useNetworkState = () => {
  const [isOnline, setIsOnline] = useState('');
  const [connectionType, setConnectionType] = useState(
    navigator.connection ? navigator.connection.effectiveType : 'unknown'
  );
  const [connectionSpeed, setConnectionSpeed] = useState(
    navigator.connection ? navigator.connection.downlink : 0
  );

  useEffect(() => {
    let initialOfflineEventHandled = false;

    function handleOnline() {
      if (initialOfflineEventHandled) {
        setIsOnline('online');
      }
    }

    function handleOffline() {
      setIsOnline('offline');
      initialOfflineEventHandled = true;
    }

    function handleConnectionChange() {
      setConnectionType(
        navigator.connection ? navigator.connection.effectiveType : 'unknown'
      );
      setConnectionSpeed(
        navigator.connection ? navigator.connection.downlink : 0
      );
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    if (navigator.connection) {
      navigator.connection.addEventListener('change', handleConnectionChange);
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);

      if (navigator.connection) {
        navigator.connection.removeEventListener(
          'change',
          handleConnectionChange
        );
      }
    };
  }, []);

  return { isOnline, connectionType, connectionSpeed };
};

export const Banner = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={`alert ${type} fixed-top w-100 text-center ${
        isVisible ? 'd-block' : 'd-none'
      }`}
      style={{ zIndex: 1000 }}
    >
      {message}
    </div>
  );
};
