import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";
import ServiceTypes from "../../../../../../fixtures/service_types";

export default class CashoutSearch extends React.Component {
  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest();
  }

  get canResolveOrRequeryTransactions() {
    return Boolean(!this.props.checkedTransactions.length);
  }

  getCatalogSeerviceTypeValue(value) {
    let serviceType = ServiceTypes.filter(function (item) {
      return item.value === value;
    });
    return serviceType[0].catalogValue;
  }

  resolutionValues = [
    { value: "1", name: "Successful" },
    { value: "0", name: "Failed" },
  ];

  render() {
    return (
      <React.Fragment>
        <div>
          <div class="search-params">
            <form onSubmit={this.handleSearchRequest}>
              <div class="form-group row">
                <div class="col-md-3">
                  <label class="search-label">Transaction Reference:</label>
                  <input
                    type="text"
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "transRef",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Business Number</label>
                  <input
                    type="text"
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "businessMobileNo",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Date From</label>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.updateSearchParameters("startDate", event)
                    }
                    size={`170px`}
                    format={`dd/MM/yyyy h:mm aa`}
                    isClearable={true}
                    showTimeInput={true}
                    textStyle={"1.0rem"}
                    iconStyle={"0.2rem"}
                    dateType={"startDate"}
                    preloadDate={this.props.preloadDate}
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Date To</label>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.updateSearchParameters("endDate", event)
                    }
                    size={`170px`}
                    format={`dd/MM/yyyy h:mm aa`}
                    isClearable={true}
                    showTimeInput={true}
                    disabled={
                      this.props.searchParameters.startDate ? false : true
                    }
                    minDate={this.props.searchParameters.startDate}
                    textStyle={"1.0rem"}
                    iconStyle={"0.2rem"}
                    dateType={"endDate"}
                    preloadDate={this.props.preloadDate}
                  />
                </div>
                <div class="col-md-4">
                  <label class="search-label">&nbsp;&nbsp;</label>
                  <button type="submit" class="search-button">
                    Search
                  </button>
                {this.props.showRepush && (
                    <button
                      id="basic-addon1"
                      type="button"
                      class="search-button"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#00425f",
                        borderColor: "#00425f",
                        color: "#ffffff",
                      }}
                      onClick={() => this.props.requeryBulkTransactions()}
                    >
                      Re-query
                    </button>
                )}
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}
