import React from "react";
import { AccessDenied } from "../../../../../../components/access-denied";
import ContentHead from "../../../../../../components/content-head";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import { CAN_VIEW_CURRENCY } from "../../../../../../constants/permissions";
import { transactionService } from "../../../../../../mixins/api";
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import ManualRouting from "../../components/manual-routing";
import PerformanceConfigSearch from "../../components/metric-search";
import MetricTable from "../../components/metric-table";
import PerformanceConfigEditModal from "../../components/performance-metric-config-edit-modal";

export default class MetricGridScene extends BaseScene {
  sceneName = "performance-config";

  constructor(props) {
    super(props);
    this.state = {
      metricData: [],
      metricDetails: {},
      filteredMetrics: [],
      isLoading: false,
      searchParameters: "ResponseTime",
      payload: {
        jobName: "",
        jobEnable: "",
        lagHour: "",
        offset: "",
      },
    };

    // getCurrencies = this.getCurrencies.bind(this);
    this.getAllMetrics = this.getAllMetrics.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.editMetrics = this.editMetrics.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.setMetricsDetails = this.setMetricsDetails.bind(this);
  }

  componentDidMount() {
    this.getAllMetrics();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getAllMetrics() {
    this.setState({
      canLoadData: true,
      isLoading: true
    });

    const {
      status,
      response,
    } = await transactionService.getMetricConfigData();

    if (status === SUCCESS_STATUS) {
      if (response.length === 0) {
        this.setState({
          metricData: [],
          filteredMetrics: [],
          dataCount: 0,
          isLoading: false,
          tableMessage: "No metrics data found",
          successMessage: "No metrics data found",
        });
      } else {
        const metricData = [...response];
        let filteredMetrics = metricData;
        if (this.state.searchParameters !== "all") {
          filteredMetrics = metricData.filter((item) => item.metricType === this.state.searchParameters);
        }

        this.setState({
          metricData,
          filteredMetrics,
          dataCount: filteredMetrics.length,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        metricData: [],
        dataCount: 0,
        isLoading: false,
        tableMessage: "No metrics data found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async editMetrics() {

    this.setState({
      isLoading: true,
    });
    const res = await transactionService.editConfigData(this.state.metricDetails);

    if (res.status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "Metrics saved successfully!!!",
      });
      this.getAllMetrics();
    } else {
      this.setState({
        isLoading: false,
        errorMessage: res.response,
      });
    }
  }

  updateSearchParameters(value) {
    let filteredMetrics = [];
    if (value !== "all") {
      filteredMetrics = this.state.metricData.filter((item) => item.metricType === value);
    }
    else {
      filteredMetrics = this.state.metricData;
    }

    this.setState({
      filteredMetrics,
      dataCount: filteredMetrics.length,
      searchParameters: value
    });
  }

  updateFields(name, value) {
    if (value.length === 0 || !value) {
      this.setState({
        metricDetails: { ...this.state.metricDetails, [name]: "" },
      });
      return;
    }

    this.setState({
      metricDetails: { ...this.state.metricDetails, [name]: value },
    });
  }

  setMetricsDetails(metricDetails) {
    this.setState({
      metricDetails
    })
  }

  async getMetricData() {
    const res = await transactionService.getMetricConfigData();
    if (res.description === "successful") {
      console.log(res.responseData, "errrr");
    }
  }
  render() {
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Performance Metrics Config Table"}
          filterPermissions={[]}
          /*actionButtonText={"Add Currency"} actionButtonUrl={"/home/system-configuration/currency-management/add"}*/ totalCount={
            56
          }
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_CURRENCY]}
        >
          <GridLayout>
            <ManualRouting />
            <PerformanceConfigSearch
              loading={this.state.isLoading}
              updateSearchParameters={this.updateSearchParameters}
            />
            <MetricTable
              data={this.state.filteredMetrics}
              setMetricsDetails={this.setMetricsDetails}
              loading={this.state.isLoading}
              noDataText={`No metrics data found`}
              dataCount={this.state.filteredMetrics.length}
              getData={this.getAllMetrics}
              disabled={true}
            />
            <PerformanceConfigEditModal
              metricDetails={this.state.metricDetails}
              data={this.state.filteredMetrics}
              updateFields={this.updateFields}
              searchParameters={this.state.searchParameters}
              editMetrics={this.editMetrics}
              preloadDate={
                retrieveOtherInformation("parentReference")
                  ? false
                  : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
              }
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
