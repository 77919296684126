import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import ContentHead from "../../../../../../../components/content-head.js";
import { AgentSearch } from "../../../../../../../components/forms/agent-search.js";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout.js";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal.js";
import Notification from "../../../../../../../components/notification.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../constants/api.js";
import { AGGREGATOR_TYPE_ID } from "../../../../../../../constants/domain-types.js";
import {
  CAN_ACTIVATE_AGGREGATOR, CAN_DEACTIVATE_AGGREGATOR, CAN_MANAGE_AGGREGATOR_DOMAIN, CAN_RETIRE_AGGREGATOR, CAN_VIEW_AGGREGATOR, CAN_VIEW_TRANSACTION
} from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import { saveOtherInformation } from "../../../../../../../mixins/authentication.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import {
  formatBackEndDateTime, pastTenseFormatter
} from "../../../../../../../utils/formatter.js";
import AggregatorTable from "../../components/aggregators-table.js";

export default class AggregaatorsGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      aggregators: [],
      aggregatorSearchParameters: {
        agentType: AGGREGATOR_TYPE_ID,
      },
      pages: 1,
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      tableMessage: "",
    };

    this.getAggregators = this.getAggregators.bind(this);
    this.updateAggregator = this.updateAggregator.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.redirectToWalletJournal = this.redirectToWalletJournal.bind(this);
    this.redirectToCommissionHistory = this.redirectToCommissionHistory.bind(
      this
    );
    this.downloadAggregatorData = this.downloadAggregatorData.bind(this);
    this.updateAggregatorSearchParameters = this.updateAggregatorSearchParameters.bind(
      this
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getAggregators(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const payload = this.state.aggregatorSearchParameters;
    payload.startDate =
      payload.startDate && formatBackEndDateTime(payload.startDate);
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    const responseObj = await platformManagement.searchAgents(
      pageNum,
      pageSize,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        aggregators: response ? response : [],
        dataCount: count,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: response.length === 0 ? "No aggregators found" : "",
      });
    } else {
      this.setState({
        aggregators: [],
        dataCount: 0,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No aggregators found",
      });
    }
  }

  getClickedAction(aggregatorId, businessName, status) {
    this.setState({
      aggregatorId: aggregatorId,
      aggregatorStatus: status,
      aggregatorBusinessName: businessName,
      showCommentField:
        status === "suspend" || status === "retire" ? true : false,
    });
  }

  async updateAggregator() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.updateAgentStatus(
      this.state.aggregatorStatus,
      this.state.aggregatorId,
      this.state.description
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          aggregatorId: null,
          aggregatorStatus: null,
          aggregatorBusinessName: null,
          isLoading: false,
          description: null,
          successMessage: `Your request to ${this.state.aggregatorStatus} ${this.state.aggregatorBusinessName} as an aggregator has been sent successfully for approval.`,
        });
        return;
      } else {
        this.setState({
          aggregatorId: null,
          aggregatorStatus: null,
          aggregatorBusinessName: null,
          description: null,
          successMessage: `${
            this.state.aggregatorBusinessName
          } was successfully ${pastTenseFormatter(
            this.state.aggregatorStatus
          )} as an aggregator.`,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getAggregators();
  }

  isFormValid() {
    if (this.state.showCommentField) {
      return Boolean(this.state.description);
    } else {
      return true;
    }
  }

  handleSearchRequest(aggregatorSearchParameters) {
    this.setState({ loading: true });
    this.setState({
      startPage: 1,
    });
    setTimeout(() => {
      this.getAggregators(1, 20);
      this.setState({ loading: false });
    });
  }

  async downloadAggregatorData() {
    //this.parentId = qs.parse(window.location.search)['?parentId']
    const payload = this.state.aggregatorSearchParameters;
    payload.startDate =
      payload.startDate && formatBackEndDateTime(payload.startDate);
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    //payload.parentId = this.parentId ? this.parentId : null
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await platformManagement.downloadAgentData(
      this.state.page,
      this.state.dataCount,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "aggregators.xls");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  redirectToWalletJournal(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("agentDetails", JSON.stringify(agent));
  }

  redirectToCommissionHistory(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("commissionAgentDetails", JSON.stringify(agent));
  }

  setAggregatorName(businessName) {
    saveOtherInformation("aggregatorBusinessName", businessName);
  }

  updateAggregatorSearchParameters(type, value) {
    this.setState({
      aggregatorSearchParameters: {
        ...this.state.aggregatorSearchParameters,
        [type]: value,
      },
    });
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`Do you want to ${this.state.aggregatorStatus} ${this.state.aggregatorBusinessName} as an aggregator?`}</p>
        {this.state.showCommentField && (
          <textarea
            placeholder={`Add more comment`}
            autoComplete="off"
            class="form-control"
            style={{ height: "200px", resize: "none" }}
            maxLength="100"
            name="description"
            onChange={(event) =>
              this.setState({ description: event.target.value })
            }
          ></textarea>
        )}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Aggregators"}
          filterPermissions={[CAN_VIEW_AGGREGATOR]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AGGREGATOR]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <AgentSearch
              handleSearchRequest={this.handleSearchRequest}
              agentType={AGGREGATOR_TYPE_ID}
              preloadDate={false}
              agentSearchParameters={this.state.aggregatorSearchParameters}
              updateAgentSearchParameters={
                this.updateAggregatorSearchParameters
              }
            />
            <AggregatorTable
              data={this.state.aggregators}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getAggregators={this.getAggregators}
              getClickedAction={this.getClickedAction}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_AGGREGATOR}
              canDeactivate={CAN_DEACTIVATE_AGGREGATOR}
              canActivate={CAN_ACTIVATE_AGGREGATOR}
              canRetire={CAN_RETIRE_AGGREGATOR}
              canManageDomain={CAN_MANAGE_AGGREGATOR_DOMAIN}
              canViewTransaction={CAN_VIEW_TRANSACTION}
              canLoadData={this.state.canLoadData}
              redirectToWalletJournal={this.redirectToWalletJournal}
              redirectToCommissionHistory={this.redirectToCommissionHistory}
              setAggregatorName={this.setAggregatorName}
              downloadAggregatorData={this.downloadAggregatorData}
            />
            <PromptModal
              modalTitle={this.state.aggregatorStatus + ` Aggregator`}
              modalBody={modalBody}
              deleteModal={this.updateAggregator}
              modalSize={`modal-md`}
              height={this.state.showCommentField ? "430px" : "230px"}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
              textAlign={"left"}
              modalFooterButton2Disabled={!this.isFormValid()}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}