export default class FeeRuleExportSerializer {
    static headerExport () {
        return [
            {label: 'Condition', key: 'condition', title: 'Condition', dataIndex: 'condition'},
            {label: 'Fee', key: 'feeToApplyName', title: 'Fee', dataIndex: 'feeToApplyName'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Created By', key: 'createdBy', title: 'Created By', dataIndex: 'createdBy'},
            {label: 'Last Updated By', key: 'lastUpdatedBy', title: 'Last Updated By', dataIndex: 'lastUpdatedBy'},
        ]
    }

    static reverseFeeRuleDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { serviceTypeName, service, serviceName, serviceOption, serviceOptionName, feeToApplyName, status, createdBy, lastUpdatedBy } = details
            let detailFormData = {
                condition: `When service type is ${serviceTypeName ? serviceTypeName : 'Default'} AND service is ${service === 0 ? 'Any' : serviceName} AND service option is ${serviceOption === 0 ? 'Any' : serviceOptionName} `,
                feeToApplyName,
                status: status === 0 ? 'Active' : status === 1 ?  'Inactive' : '',
                createdBy: createdBy ? createdBy : '',
                lastUpdatedBy: lastUpdatedBy ? lastUpdatedBy : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}