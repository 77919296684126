import React from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import BaseScene from '../../../../components/scenes/component'
import PersonalInformationGridScene from './scenes/personal-information'

export default class ProfileManagementScene extends BaseScene {
  sceneName = 'profile-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <Route exact path='/home/profile/personal-information' component={PersonalInformationGridScene} />
    </Router>
  }
}
