import React from 'react';
import './index.css';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseScene from '../../../../../components/scenes/component';
import CashoutInjectGridScene from './scenes/cashout-grid/scene';
import NewCashoutConfigScene from './upload-cashout/scenes/upload-cashout-grid/scene.js';


export default class CashoutInjectScene extends BaseScene {
  sceneName = 'cashout-inject';

  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };
  }

  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };
  render() {
    return (
      <>
        <div className='col-md-12' style={{ marginTop: '-30px' }}>
          <section id='open-positions'>
            <div className='positions'>
              <div className='position-filters'>
                <label
                  className={
                    this.state.toggleState === 1
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All
                </label>
                <label
                  className={
                    this.state.toggleState === 2
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Upload Cashout
                </label>
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <CashoutInjectGridScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 2
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <NewCashoutConfigScene />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
