import React from 'react';
import BaseScene from '../../../../../../../components/scenes/component.js';
import { AccessDenied } from '../../../../../../../components/access-denied.js';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout.js';
import Notification from '../../../../../../../components/notification.js';
import { DocTypeExportSerializer } from '../../../../../../../serializers/index.js';
import { CAN_CREATE_AGENT_CLASS, CAN_VIEW_AGENT_CLASS, CAN_DEACTIVATE_AGENT_CLASS, CAN_VIEW_MANAGE_AGGREGATOR } from '../../../../../../../constants/permissions.js';
import ContentHead from '../../../../../../../components/content-head.js';
import { SUCCESS_STATUS } from '../../../../../../../constants/api.js';
import Table from '../../../../../../../components/table.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component.js';
import PropTypes from 'prop-types';
import { platformManagement } from '../../../../../../../mixins/api.js';
import { ManageAggregatorSearch } from '../../components/manage-aggregator-search.js';
import { AGGREGATOR_TYPE_ID } from '../../../../../../../constants/domain-types.js';
import { formatDate, formatDatePayload } from '../../../../../../../utils/formatter.js';


export default class ManageAggregatorGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      getProps: () => ({
        style: {
          textAlign: 'center',
          whiteSpace: 'unset',
          padding: '8px'
        },
      }),
    },
    {
      Header: "Agent Code",
      accessor: 'agentCode',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Initiator",
      accessor: 'migratedBy',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Business Name",
      accessor: 'agentBusinessName',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Previous Aggregator Code",
      accessor: 'previousAggregatorCode',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Previous Aggregator Name",
      accessor: 'previousAggregatorBusinessName',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "New Aggregator Code",
      accessor: 'newAggregatorCode',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "New Aggregator Name",
      accessor: 'newAggregatorBusinessName',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', padding: '8px' },
      Cell: ({ value }) => value,
    },
    {
      Header: "Date Created",
      accessor: 'dateCreated',
      resizable: false,
      minWidth: 200,
      headerStyle: { whiteSpace: 'unset', padding: '8px' },
      style: { whiteSpace: 'unset', textAlign: 'center', padding: '8px' },
      Cell: props => {
        return props.value ? formatDate(props.value) : ''
      }
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      searchParameters: {},
      documents: [],
      dataCount: 0,
      pages: 1,
      pageSize: 10,
      page: 1,
      errorMessage: null,
    };

    this.fetchAgentAggregator = this.fetchAgentAggregator.bind(this);
  }

  componentDidMount() {
    this.fetchAgentAggregator()
  }

  handleSearchRequest = () => {
    this.fetchAgentAggregator(1, this.state.pageSize, this.state.searchParameters);
  };

  updateSearchParameters = (key, value) => {
    this.setState(prevState => ({
      searchParameters: { ...prevState.searchParameters, [key]: value },
    }));
  };

  async fetchAgentAggregator(pageNum = 1, pageSize = 10, payload = {}) {
    this.setState({ isLoading: true });

    try {
      const formattedPayload = {
        ...payload,
        businessName: payload.agentBusinessName || "",
        startDate: payload.startDate ? formatDatePayload(payload.startDate) : null,
        endDate: payload.endDate ? formatDatePayload(payload.endDate) : null,
      };
      const responseObj = await platformManagement.searchAgentAggregator(pageNum, pageSize, formattedPayload);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS && response) {
        this.setState({
          documents: response || [],
          dataCount: response.count || 0,
          pages: Math.ceil(response.count / pageSize),
          pageSize,
          page: pageNum,
          isLoading: false,
        });
      } else {
        this.setState({
          documents: [],
          dataCount: 0,
          pages: 1,
          isLoading: false,
          errorMessage: "No agents found",
        });
      }
    } catch (error) {
      console.error("Error fetching agent aggregators:", error);
      this.setState({
        documents: [],
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  render() {
    return (
      <>
        <Notification successMessage={this.state.successMessage} errorMessage={this.state.errorMessage} />
        <ContentHead title="Change Aggregator" filterPermissions={[CAN_VIEW_AGENT_CLASS]} actionButtonRequiredPermissions={[CAN_CREATE_AGENT_CLASS]} />

        <ManageAggregatorSearch
          updateAgentSearchParameters={this.updateSearchParameters}
          agentSearchParameters={this.state.searchParameters}
          handleSearchRequest={this.handleSearchRequest}
          agentType={AGGREGATOR_TYPE_ID}
        />

        <ProtectedComponent requiredPermissions={[CAN_VIEW_MANAGE_AGGREGATOR]} permissionDeniedContent={<AccessDenied />}>
          <GridLayout>
            <Table
              columns={this.columns}
              data={this.state.documents}
              loading={this.state.isLoading}
              noDataText="No Document found"
              dataCount={this.state.dataCount}
              pageSize={this.state.pageSize}
              page={this.state.page}
              pages={this.state.pages}
            />
          </GridLayout>
        </ProtectedComponent>
      </>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}

