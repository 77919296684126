import React from 'react'
import { Link } from 'react-router-dom'

export default class NewApplicationButton extends React.Component {
    render() {
        return <React.Fragment>
            <div class="dropdown">
                <a href="javascript:;" class="btn btn" style={{'backgroundColor': '#00425f', 'borderColor':'#00425f', 'color': '#ffffff', 'borderRadius': '3px'}} data-toggle="dropdown">
                    New Application
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <ul class="kt-nav">
                        <li class="kt-nav__item">
                            <Link class="kt-nav__link" to={`application-management/agent/add`}>
                                <i class="kt-nav__link-icon flaticon2-file-1"></i>
                                <span class="kt-nav__link-text">Agent</span>
                            </Link>
                        </li>
                        <li class="kt-nav__item">
                            <Link class="kt-nav__link" to={`application-management/aggregator/add`}>
                                <i class="kt-nav__link-icon flaticon2-file-1"></i>
                                <span class="kt-nav__link-text">Aggregator</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    }
}