import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";
import Select from "../../../../../../components/select";
import ServiceTypes from "../../../../../../fixtures/service_types";
import TransactionStatuses from "../../../../../../fixtures/transaction_statuses";

export default class TransactionSearch extends React.Component {
  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest();
  }

  get canResolveOrRequeryTransactions() {
    return Boolean(!this.props.checkedTransactions.length);
  }

  getCatalogSeerviceTypeValue(value) {
    let serviceType = ServiceTypes.filter(function (item) {
      return item.value === value;
    });
    return serviceType[0].catalogValue;
  }

  resolutionValues = [
    { value: "1", name: "Successful" },
    { value: "0", name: "Failed" },
  ];

  serviceProviders = [
    { value: "", name: "All" },
    { value: "QT", name: "QuickTeller" },
    { value: "BP", name: "BuyPower" },
  ];

  render() {
    return (
      <React.Fragment>
        <div>
          <div class="search-params">
            <form onSubmit={this.handleSearchRequest}>
              <div class="row" style={{marginBottom:"15px"}}>
                <div class="col-md-3">
                  <label class="search-label">Transaction Reference:</label>
                  <input
                    type="text"
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "transRef",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Business Name</label>
                  <input
                    type="text"
                    placeholder="Business Name...."
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "businessName",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Business Number</label>
                  <input
                    type="text"
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "businessMobileNo",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">
                    Batch Credit/Debit Reference:
                  </label>
                  <input
                    type="text"
                    class="search-input"
                    defaultValue={this.props.searchParameters.parentReference}
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "parentReference",
                        event.target.value
                      )
                    }
                  />
                </div>
              </div>

              <div class="row" style={{marginBottom:"15px"}}>
                <div class="col-md-3">
                  <label class="search-label">Status</label>
                  <Select
                    options={TransactionStatuses}
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "transactionStatus",
                        event.value
                      )
                    }
                    defaultValue={this.props.searchParameters.transactionStatus}
                  />
                </div>

                <div class="col-md-3">
                  <label class="search-label">Transaction Type</label>
                  <Select
                    options={ServiceTypes}
                    onChange={(event) => {
                      this.props.updateSearchParameters(
                        "transactionType",
                        event.value
                      );
                      this.props.getServiceTypeById(
                        this.getCatalogSeerviceTypeValue(event.value)
                      );
                    }}
                    defaultValue={this.props.searchParameters.transactionType}
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Service</label>
                  <Select
                    options={this.props.services.map((detail) => {
                      return { label: detail.name, value: detail.id };
                    })}
                    onChange={(event) => {
                      this.props.updateSearchParameters("service", event.label);
                      this.props.getServiceById(event.value);
                    }}
                    disabled={this.props.disabled}
                    isLoading={this.props.isServiceLoading}
                    minHeight={40}
                  />
                </div>

                <div class="col-md-3">
                  <label class="search-label">Service Option Name:</label>
                  <Select
                    options={this.props.serviceOptions.map((detail) => {
                      return { label: detail.name, value: detail.paymentCode };
                    })}
                    onChange={(event) => {
                      this.props.updateSearchParameters(
                        "paymentItemCode",
                        event.value
                      );
                    }}
                    disabled={this.props.disabled}
                    isLoading={this.props.isServiceOptionLoading}
                    minHeight={40}
                  />
                </div>
                </div>
                
              <div class="row" style={{marginBottom:"15px"}}>
                <div class="col-md-3">
                  <label class="search-label">Service Provider</label>
                  <Select
                    options={this.serviceProviders.map((detail) => {
                      return { label: detail.name, value: detail.value };
                    })}
                    onChange={(event) =>
                      this.props.updateSearchParameters("serviceProvider", event.value)
                    }
                    defaultValue={this.props.searchParameters.serviceProvider}
                  />
                </div>

                <div class="col-md-3">
                  <label class="search-label">Date From</label>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.updateSearchParameters("startDate", event)
                    }
                    size={`170px`}
                    format={`dd/MM/yyyy h:mm aa`}
                    isClearable={true}
                    showTimeInput={true}
                    style={"0.4rem"}
                    textStyle={"1.0rem"}
                    iconStyle={"0.2rem"}
                    dateType={"startDate"}
                    preloadDate={this.props.preloadDate}
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Date To</label>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.updateSearchParameters("endDate", event)
                    }
                    size={`170px`}
                    format={`dd/MM/yyyy h:mm aa`}
                    isClearable={true}
                    showTimeInput={true}
                    disabled={
                      this.props.searchParameters.startDate ? false : true
                    }
                    minDate={this.props.searchParameters.startDate}
                    style={"0.4rem"}
                    textStyle={"1.0rem"}
                    iconStyle={"0.2rem"}
                    dateType={"endDate"}
                    preloadDate={this.props.preloadDate}
                  />
                </div>
                <div class="col-md-2">
                  <label class="search-label">&nbsp;&nbsp;</label>
                  <button type="submit" class="search-button">
                    Search
                  </button>
                </div>
              </div>
            </form>
            <div
              class="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div
                class="btn-group ml-12"
                role="group"
                aria-label="First group"
              >
                {this.props.showRepush && (
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Re-push as
                      </span>
                    </div>
                    <select
                      class="form-control"
                      onChange={(event) =>
                        this.props.resolveBulkTransactions(event.target.value)
                      }
                      value={this.props.desiredStatus}
                    >
                      <option
                        value={"default"}
                        disabled
                        selected={
                          this.props.desiredStatus == "default"
                            ? "selected"
                            : ""
                        }
                      >
                        Select
                      </option>
                      {this.resolutionValues.map((item) => (
                        <option
                          value={item.value}
                          selected={
                            this.props.desiredStatus == item.value
                              ? "selected"
                              : ""
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <button
                      id="basic-addon1"
                      type="button"
                      class="btn btn-secondary"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#00425f",
                        borderColor: "#00425f",
                        color: "#ffffff",
                      }}
                      onClick={() => this.props.requeryBulkTransactions()}
                    >
                      Re-query
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}
