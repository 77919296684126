import React from 'react';

export const AccessDenied = () => {
    return <React.Fragment>
            <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="form-group row" style={{paddingTop: '80px', paddingLeft: '100px'}}>
                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6">
                        <i class="fa fa-ban fa-10x" aria-hidden="true" style={{color: 'red'}}></i>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-6 col-xs-6">
                        <h3>Access is denied due to invalid credentials</h3>
                        <span>You do not have permission  to view this resource using the credentials that you supplied.</span>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}