import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import { LienExportSerializer } from "../../../../../../serializers";
import {
  formatCurrency,
  formatLienStateColorCode,
  formatTransactionStatus
} from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";

export default class LienManagementTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "Request ID",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 250,
        Cell: ({ row, original }) => original.requestID,
      },
      {
        Header: "Agent Phone Number",
        accessor: "accountCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Trans Type",
        accessor: "transType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 105,
      },
      {
        Header: "Amount",
        accessor: "transAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 70,
        Cell: (props) => <span>₦{formatCurrency(props.value / 100)}</span>,
      },
      {
        Header: "State",
        accessor: "approvalStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 140,
        Cell: (props) => {
          return (
            <span class={`badge ${formatLienStateColorCode(props.value)}`}>
              {formatTransactionStatus(props.value)}
            </span>
          );
        },
      },
      {
        Header: "Narration",
        accessor: "remark",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
        Cell: (props) => (
          <span class="word-capitalize">
            {props.value ? props.value.toLowerCase() : ""}
          </span>
        ),
      },
      {
        Header: "Date Initiated",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 160,
        Cell: ({ original, row }) => {
          return original.dateCreated;
        },
      },
      {
        Header: "Action",overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        width: 70,
        Cell: ({ original }) => (
          <>
            {(original.approvalStatus === "POSTED_BUT_FAILED" ||
              original.approvalStatus === "AWAITING_POSTING") && (
                <div class="dropdown">
                  <a
                    href="#"
                    class="btn btn-hover-brand btn-icon btn-pill"
                    data-toggle="dropdown"
                  >
                    <i class="la la-ellipsis-v"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                <a
                onClick={() =>
                  this.props.requeryLien(
                    original.requestID
                  )
                }
                class="dropdown-item"
                style={{ color: "#A7ABC3", cursor: "pointer" }}
              >
                <i class="la la-refresh"></i>
                Re-query
              </a>
              </div>
              </div>
            )}
          </>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={LienExportSerializer.lienHistoryDetails(this.props.data)}
          headers={LienExportSerializer.headerExport()}
          getData={this.props.fetchData}
          fileName={"lien_history"}
          zoomTableHeight={this.zoomTableHeight}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              businessMobileNo,
              businessName,
              endDate,
              startDate,
              transactionStatus,
              transactionType,
              transRef,
              responseCode,
              parentReference,
            } = this.props.searchParameters;
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.fetchData(
                state.page + 1,
                state.pageSize,
                businessMobileNo,
                businessName,
                endDate,
                startDate,
                transactionStatus,
                transactionType,
                transRef,
                responseCode,
                parentReference
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows="10"
          getTableProps={() => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={() => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
            },
          })}
          getTheadThProps={() => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              // padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={() => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
