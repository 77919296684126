import qs from "querystring";
import React from "react";
import { Redirect } from "react-router-dom";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { CAN_PLACE_LIEN } from "../../../../../../../constants/permissions.js";
import {
  platformManagement,
  walletProxyService,
} from "../../../../../../../mixins/api";
import { retrieveSessionInformation } from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import AddLienForm from "../../components/add-lien-form";

export default class AddLienScene extends BaseScene {
  notificationSectionRef = React.createRef(null);
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      formattedConfirmationData: {},
      permissions: [],
      roleDetails: [],
    };

    this.proceedLien = this.proceedLien.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
  }

  componentDidMount() {
    this.roleId = qs.parse(window.location.search)["?roleId"];
    const currentUser = retrieveSessionInformation();
    this.username = currentUser.user.username;
    this.getPermissions();
    if (this.roleId) {
      this.getRoles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
        window.location.href = "admin/home/lien-management";
      }, 5000);
    }
  }

  async getRoles() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getRoles(this.domainCode);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      response.map((details) => {
        if (this.roleId == details.id) {
          this.getRolePermissions(details.name);
        }
      });
    } else {
      this.setState({
        formData: [],
        isLoading: false,
      });
    }
  }

  async getRolePermissions(roleName) {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getRolePermissions(
      this.domainCode,
      roleName
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        formData: {
          ...this.state.formData,
          permissions: this.getPermissionId(response),
          name: roleName,
        },
        isLoading: false,
      });
    } else {
      this.setState({
        formData: [],
        isLoading: false,
      });
    }
  }

  async getPermissions() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getPermissions();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissions: response,
        isLoading: false,
      });
    } else {
      this.setState({
        permissions: [],
        isLoading: false,
      });
    }
  }

  getPermissionId(permissions) {
    let permissionArray = [];
    permissions.map((details) => {
      permissionArray.push(details.id);
    });
    return permissionArray;
  }

  toJSON(array) {
    let newArray = [];
    array.map((value) => {
      let myArray = {};
      myArray["id"] = value;
      newArray.push(myArray);
    });

    return newArray;
  }

  async proceedLien(values) {
    this.setState({
      isLoading: true,
    });
    const formDataRequired = {
      accountCode: values.customerMsisdn,
      amount: values.amount * 100,
      reasonDesc: values.narration,
      approvedBy: this.username,
      accountName: values.userData.businessName,
    };

    const {
      response,
      status,
    } = await walletProxyService.proceedLienTransaction(formDataRequired);

    this.setState({
      isLoading: false,
      errorMessage: status === ERROR_STATUS ? response : null,
      successMessage:
        status === SUCCESS_STATUS
          ? "Lien successfully initiated, pending approval"
          : null,
    });
  }

  isFormValid() {
    return (
      Boolean(this.state.formData.amount) &&
      Boolean(this.state.formData.customerMsisdn) &&
      Boolean(this.state.formData.narration)
    );
  }

  render() {
    const { hasFormBeenSubmitted } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home/lien-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_PLACE_LIEN]}>
          <button
            disabled={hasFormBeenSubmitted}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={() => {
              this.setState({ hasFormBeenSubmitted: true });
              this.form.submitForm();
            }}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Lien Management"}
          withoutSearch
          subtitle="Add a Lien"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_PLACE_LIEN]}
        >
          <GridLayout>
            <AddLienForm
              allPermissions={this.state.permissions}
              formData={this.state.formData}
              hasFormBeenSubmitted={this.state.hasFormBeenSubmitted}
              innerRef={(form) => (this.form = form)}
              onChange={this.updateFormData}
              onSubmit={(values) => this.proceedLien(values)}
              roleDetails={this.state.roleDetails}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  updateFormData(key, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [key]: value,
      },
    }));
  }
}
