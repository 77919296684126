import PropTypes from "prop-types";
import React from "react";

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
    dataCount: PropTypes.number,
    pageRows: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizes: PropTypes.any,
    showTotalCount: PropTypes.any,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
        //activePageSize: nextProps.pageSize
      });
    }

    this.changePage(nextProps.page + 1);
    this.makePageInfo(nextProps.page, nextProps.pageSize, nextProps.data, nextProps.dataCount, nextProps.showTotalCount)
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    }else if(total === undefined || isNaN(total) || total === 0) {
      return [1]
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  changePageSize (pageSize) {
    this.setState({activePageSize: pageSize})
    this.props.onPageSizeChange(pageSize);
  }

  makePageInfo(page, pageSize, pageRows, sortedData, showTotalCount) {
    let totalRecords = sortedData ? sortedData : 0;
    let recordsCountFrom = pageRows.length > 0 ? page * pageSize + 1 : 0;
    let recordsCountTo = pageRows.length > 0 ? recordsCountFrom + pageRows.length - 1 : 0;
    let recordsInfoText = showTotalCount ? `${totalRecords} entries` : `Showing ${recordsCountFrom}-${recordsCountTo} ${this.props.disabledPaginationWrapper ? '' : `of ${totalRecords} entries`}`;
    this.setState({recordsInfoText})
  }

  render() {
    const { PageButtonComponent = defaultButton, pageSizes } = this.props;
    const { visiblePages, recordsInfoText, activePageSize } = this.state;
    const activePage = this.props.page + 1;
    
    return (
      <div class="row Table">
        <div class="col-md-3">
          <div className="pull-left Table__pageDetails">
            {recordsInfoText}
          </div>
        </div>
        <div class="col-md-3">
          <div className="pull-left Table__pageDetails" style={{marginTop: '-5px'}}>
            <span class="select-wrap -pageSizeOptions">
              <select class="form-control" aria-label="rows per page" value={activePageSize ?  activePageSize : 20} onChange={event => this.changePageSize(event.target.value)}>
                {pageSizes.map(pageSize => <option value={pageSize}>{pageSize}</option>)}
              </select>
            </span>
          </div>
          <div className="pull-left Table__pageDetails" style={{paddingLeft: '35px'}}>
            <span class="select-wrap -pageSizeOptions">
              rows per page
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div className="col-md-12 Table__pagination">
            <div className="Table__prevPageWrapper">
              <PageButtonComponent
                className="Table__pageButton"
                onClick={() => {
                  if (activePage === 1) return;
                  this.changePage(activePage - 1);
                }}
                disabled={activePage === 1}
              >
                {this.props.previousText}
              </PageButtonComponent>
            </div>
            {!this.props.disabledPaginationWrapper && <div className="Table__visiblePagesWrapper">
              {visiblePages.map((page, index, array) => {
                return (
                  <PageButtonComponent
                    key={page}
                    className={
                      activePage === page
                        ? "Table__pageButton Table__pageButton--active"
                        : "Table__pageButton"
                    }
                    onClick={this.changePage.bind(null, page)}
                  >
                    {array[index - 1] + 2 < page ? `...${page}` : page}
                  </PageButtonComponent>
                );
              })}
            </div>}
            <div className="Table__nextPageWrapper">
              <PageButtonComponent
                className="Table__pageButton"
                onClick={() => {
                  if (activePage === this.props.pages) return;
                  this.changePage(activePage + 1);
                }}
                disabled={this.props.disabledPaginationWrapper ? false : this.props.pages === undefined || isNaN(this.props.pages) || this.props.pages === 0 ? 'true' : activePage === this.props.pages}
              >
                {this.props.nextText}
              </PageButtonComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
