import React from "react";
import "./index.css";

export default class StockSplits extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="kt-portlet">
          {/* <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne2" aria-controls="collapseOne2">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                        <h3 class="kt-portlet__head-title">
                            Fee Split Details
                        </h3>
                    </div>
                </div> */}
          <form
            style={{ width: "1500px" }}
            class="kt-form kt-form--label-right"
            id="collapseOne2"
            aria-labelledby="headingOne1"
          >
            <div class="kt-portlet__body" style={{}}>
              <table class="table table-hover">
                <thead class="thead-light">
                  <th style={{ width: "300px" }}>Batch ID</th>
                  <th style={{ width: "300px" }}>Serial No</th>
                  <th style={{ width: "300px" }}>Device Type</th>
                  <th style={{ width: "300px" }}>Name</th>
                  <th style={{ width: "300px" }}>Model</th>
                  <th style={{ width: "300px" }}>Date Uploaded</th>
                  <th style={{ width: "300px" }}>Uploaded By</th>
                </thead>
                <tbody>
                  {/* {this.props.stockSplits.map(value =>
                                    <tr>
                                        <td>{value.agentName}</td>
                                        <td>{value.serialNo}</td>
                                        <td>{(value.terminalType}</td>
                                        <td>{value.terminalName}</td>
                                        <td>{value.terminalModel}</td>
                                        <td>{value.formatDateTime(explodeDate(dateUploaded, "+"))}</td>
                                        <td>{value.uploadedBy}</td>
                                    </tr>
                                )} */}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
