import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import BaseScene from "../../../../components/scenes/component";
import { isAuthorized } from "../../../../mixins/authentication";
import WalletPerformanceGridScene from "./scenes/wallet-performance-grid";

export default class WalletPerformanceScene extends BaseScene {
  sceneName = "wallet-performance";

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[]}
          exact
          path="/home/wallet-perfromance"
          component={WalletPerformanceGridScene}
        />
      </Router>
    );
  }
}
