import React from 'react'
import Select from '../../../../../../../components/select'
import SettlementTypes from '../../../../../../../fixtures/settlement_types.json'

export default class ServiceForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Name:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceFormData ? this.props.serviceFormData.name : ''} class="form-control" placeholder="Name" onChange={event => this.props.onChange('name', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Display Name:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceFormData ? this.props.serviceFormData.displayName : ''} class="form-control" placeholder="Display Name" onChange={event => this.props.onChange('displayName', event.target.value)} disabled/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Settlement Interval:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.serviceFormData ? this.props.serviceFormData.settlementInterval : ''} class="form-control" placeholder="Settlement Interval" onChange={event => this.props.onChange('settlementInterval', event.target.value)} disabled={this.props.disabled ? this.props.disabled : ''}/>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Settlement Type:<br></br></label>
                            <Select 
                                options={SettlementTypes.map(detail => { return ( {label: detail.name, value: detail.value} ) })}
                                onChange={event => this.props.onChange('settlementType', event.value)} 
                                disabled={this.props.disabled}
                                defaultValue={this.props.serviceFormData.settlementType}
                            />
                            {/* <select autoComplete="off" type="text" value={this.props.serviceFormData ? this.props.serviceFormData.settlementType : 0} class="form-control" placeholder="Settlement Type" onChange={event => this.props.onChange('settlementType', event.target.value)} disabled={this.props.disabled ? this.props.disabled : ''}>
                                <option selected disabled>Select</option>
                                {SettlementTypes.map(detail => <option value={detail.value}>{detail.name}</option>)}
                            </select> */}
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}