import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import Loader from '../../../../../../../components/loader';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_SERVICE_OPTION } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { formatDateTime } from '../../../../../../../utils/formatter';

export default class ManagePaydirectItemCodeScene extends React.Component {
    
    notificationSectionRef = React.createRef(null)

    constructor (props) {
        super(props);
        this.state = {
            paydirectItems: [],
            paydirectFormData: [],
            disabled: false,
            editPaydirectFormData: []
        }
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    }

    componentDidMount() {
        this.serviceOptionId = this.props.match.params.id
        this.setState({paydirectFormData: {...this.state.paydirectFormData, serviceOptionId: this.serviceOptionId}})
        this.getPaydirectItems()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async getPaydirectItems () {
        this.setState({isLoading: true})
        const responseObj = await feeConfigurationManagement.searchPaydirectItem(this.serviceOptionId)
        const { status, response } = responseObj
        console.log(response)
        if (status === SUCCESS_STATUS) {
          this.setState({
            paydirectItems: response,
            canUpdate: true,
            isLoading: false
          })
        }else{
          this.setState({
            paydirectItems: [],
            isLoading: false,
            errorMessage: response
          })
        }
      }

      async onSaveButtonClick () {
        this.setState({
            isLoading: true,
            disabled: true
        })
        const responseObj = await feeConfigurationManagement.addPaydirectItem(this.state.paydirectFormData)
        const { status, response } = responseObj
        window.scrollTo(0, this.notificationSectionRef.offsetTop)
        if (status === SUCCESS_STATUS) {
          if(response.code == ACCEPTED_REQUEST){
            this.setState({
              isLoading: false,
              successMessage: 'Your changes to add a new paydirect item code has been sent successfully for approval.',
              disabled: false,
            })
            return
          }else{
            this.setState({
              isLoading: false,
              disabled: false,
              successMessage: 'Paydirect item code added successfully.',
            })
          }
        }else{
          this.setState({
            isLoading: false,
            disabled: false,
            errorMessage: response,
          })
        }
        this.getPaydirectItems()
        return
      }

      async onUpdateButtonClick () {
        this.setState({
            isLoading: true,
            disabled: true
        })
        const responseObj = await feeConfigurationManagement.updatePaydirectItem(this.state.editPaydirectFormData)
        const { status, response } = responseObj
        window.scrollTo(0, this.notificationSectionRef.offsetTop)
        if (status === SUCCESS_STATUS) {
          if(response.code == ACCEPTED_REQUEST){
            this.setState({
              isLoading: false,
              successMessage: 'Your changes to update a paydirect item code has been sent successfully for approval.',
              disabled: false,
              paydirectItemDetails: [],
              editPaydirectFormData: [],
              paydirectItemStatus: null,

            })
            return
          }else{
            this.setState({
              isLoading: false,
              disabled: false,
              successMessage: 'Paydirect item code updated successfully.',
              paydirectItemDetails: [],
              editPaydirectFormData: [],
              paydirectItemStatus: null,
            })
          }
        }else{
          this.setState({
            isLoading: false,
            disabled: false,
            errorMessage: response,
            paydirectItemDetails: [],
            editPaydirectFormData: [],
            paydirectItemStatus: null,
          })
        }
        this.getPaydirectItems()
        return
      }

      async updateStatus () {
        this.setState({isLoading: true})
        let responseObj;
        if(this.state.paydirectItemStatus == 'delete') {
          responseObj = await feeConfigurationManagement.deletePaydirectItem(this.state.paydirectItemId)
        }else{
          responseObj = await feeConfigurationManagement.updatePaydirectItemStatus(this.state.paydirectItemStatus, this.state.paydirectItemId)
        }
        const { status, response, count } = responseObj
          if (status === SUCCESS_STATUS) {
            this.setState({
              isLoading: false,
              successMessage: `Paydirect item code ${this.state.paydirectItemStatus}d successfully!`
            })
          }else{
            this.setState({
              isLoading: false,
              errorMessage: response
            })
          }  
          this.getPaydirectItems()
          setTimeout(() => {
          this.setState({
            paydirectItemId: null,
            paydirectItemStatus: null
          })
        }, 3000)
      }

      getClickedAction (status, id) {
        this.setState({
          paydirectItemId: id,
          paydirectItemStatus: status
        })
      }

      getEditDetails (status, details) {
        this.setState({
          paydirectItemDetails: [],
          editPaydirectFormData: [],
          paydirectItemStatus: null
        })
        setTimeout(() => {
          this.setState({
            paydirectItemDetails: details,
            editPaydirectFormData: details,
            paydirectItemStatus: status
          })
        }, 5)
      }

      isFormValid () {
        return Boolean(!this.state.paydirectFormData.customerType || !this.state.paydirectFormData.paydirectItemCode) 
      }

    render() {
      const editModalBody = <React.Fragment>
            <form class="kt-form kt-form--label-right">
               <div class="kt-portlet__body">
                <div class="form-group col-lg-12">
                    <label>Payment Item Code *:</label>
                    <input autoComplete="off" type="name" class="form-control" defaultValue={this.state.paydirectItemDetails ? this.state.paydirectItemDetails.paydirectItemCode : ''} onChange={(event) => this.setState({editPaydirectFormData: {...this.state.editPaydirectFormData, paydirectItemCode: event.target.value}})} />
                </div>
                <div class="form-group col-lg-12">
                    <label>Customer Type *:</label>
                    <input autoComplete="off" type="name" class="form-control" defaultValue={this.state.paydirectItemDetails ? this.state.paydirectItemDetails.customerType : ''} onChange={(event) => this.setState({editPaydirectFormData: {...this.state.editPaydirectFormData, customerType: event.target.value}})} />
                </div>
            </div>
          </form>
        </React.Fragment>

        return <React.Fragment>
            <Loader
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
        ref={this.notificationSectionRef}
      />
      <ContentHead title={"Service Options"} withoutSearch subtitle={'Manage Paydirect Item Code'} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_SERVICE_OPTION]}>
        <div class="kt-portlet">
            <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne1" aria-controls="collapseOne1">
                <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                    <h3 class="kt-portlet__head-title">
                        
                    </h3>
                </div>
            </div>
            <form class="kt-form kt-form--label-right" id="collapseOne1" class="collapse show" aria-labelledby="headingOne1">
                <div class="kt-portlet__body">
                <div class="form-group row">
                            <div class="col-lg-4">
                                <label class="search-label">Paydirect Item Code *:</label>
                                <input autoComplete="off" type="name" disabled={this.state.disabled} class="search-input" onChange={(event) => this.setState({paydirectFormData: {...this.state.paydirectFormData, paydirectItemCode: event.target.value}})} />
                            </div>
                            <div class="col-lg-4">
                                <label class="search-label">Customer Type *: </label>
                                <input autoComplete="off" type="name" disabled={this.state.disabled} class="search-input" onChange={(event) => this.setState({paydirectFormData: {...this.state.paydirectFormData, customerType: event.target.value}})} />
                            </div>
                            <div class="col-lg-4">
                                <label class="search-label">&nbsp;&nbsp;&nbsp;</label>
                                <button type="button" disabled={this.isFormValid()} class="search-button" onClick={this.onSaveButtonClick}>Add</button>
                            </div>
                        </div>
                </div>
            </form>

            <form class="kt-form kt-form--label-right" id="collapseOne1" class="collapse show" aria-labelledby="headingOne1">
                <div class="kt-portlet__body">
                        <table class="table table-hover">
                            <thead class="thead-light">
                                <th>S/N</th>
                                <th>Paydirect Item Code</th>
                                <th>Customer Type</th>
                                <th>Status</th>
                                <th>Date Created</th>
                                <th>Date Modified</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {this.state.paydirectItems.map((value, key) =>
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td>{value.paydirectItemCode}</td>
                                        <td>{value.customerType}</td>
                                        <td>{value.status === 0 ? 'Active' : value.status === 1 ? 'Inactive' : ''}</td>
                                        <td>{value.createdOn ? formatDateTime(value.createdOn) : ''}</td>
                                        <td>{value.lastUpdatedOn ? formatDateTime(value.lastUpdatedOn) : ''}</td>
                                        <td>
                                          <div class="dropdown">
                                            <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                                              <i class="la la-ellipsis-v"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getEditDetails(`edit`, value)} style={{color: '#A7ABC3', cursor: 'pointer'}}>
                                                <i class="la la-edit"></i>
                                                Edit 
                                              </a>
                                              <a hidden={value.status === 0} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(`enable`, value.id)} style={{color: '#A7ABC3', cursor: 'pointer'}}>
                                                <i class="la la-toggle-on"></i>
                                                Activate 
                                              </a>
                                              <a hidden={value.status === 1} class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(`disable`, value.id)} style={{color: '#A7ABC3', cursor: 'pointer'}}>
                                                <i class="la la-ban"></i>
                                                Deactivate 
                                              </a>
                                              <a class="dropdown-item" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.getClickedAction(`delete`, value.id)} style={{color: '#A7ABC3', cursor: 'pointer'}}>
                                                <i class="la la-trash"></i>
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* <div class="form-group col-lg-6">
                        <label>Trans Ref</label>
                        <input value={this.props.settlement.transactionRef} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Date & Time</label>
                        <input value={this.props.settlement.dateTimeProcessed ? formatDateTime(this.props.settlement.dateTimeProcessed) : ''} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Settlement Date</label>
                        <input value={this.props.settlement.settlementDate ? formatDateTime(this.props.settlement.settlementDate) : ''} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Service Type</label>
                        <input value={this.props.settlement.transactionType} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Transaction Amount (&#x20A6;)</label>
                        <input value={(this.props.settlement.transactionAmount ? this.props.settlement.transactionAmount / 100 : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Transaction Surcharge (&#x20A6;)</label>
                        <input value={(this.props.settlement.transactionSurcharge ? this.props.settlement.transactionSurcharge / 100 : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                    </div> */}
            </form>
        </div>
        <PromptModal 
            modalTitle={`${this.state.paydirectItemStatus} Paydirect Item Code`}
            modalBody={this.state.paydirectItemStatus === 'edit' ? editModalBody : `Do you want to ${this.state.paydirectItemStatus} this paydirect item code?`}
            deleteModal={this.state.paydirectItemStatus === 'edit' ? this.onUpdateButtonClick : this.updateStatus}
            modalSize={`modal-md`}
            textAlign={this.state.paydirectItemStatus === 'edit' ? 'left' : 'center'}
            height={this.state.paydirectItemStatus === 'edit' ? '400px' : '230px'}
            modalFooterButton1={'Cancel'}
            modalFooterButton2={this.state.paydirectItemStatus === 'edit' ? 'Save' : 'Ok'}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </ProtectedComponent>
    </React.Fragment>
    }
}
