import React from "react";
import ContentHead from "../../../../../../components/content-head";
import BaseScene from "../../../../../../components/scenes/component";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { GridLayout } from "../../../../../../components/layouts/grid-layout";
import {
  CAN_MESSAGING_WRITE_TEMPLATE,
  CAN_MESSAGING_READ_TEMPLATE,
} from "../../../../../../constants/permissions";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import { AccessDenied } from "../../../../../../components/access-denied";
import { messagingService } from "../../../../../../mixins/api";
import { PromptModal } from "../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../components/notification";
import { formatBackEndDateTime } from "../../../../../../utils/formatter";
import TemplateSearch from "../../components/template-search";
import TemplateTable from "../../components/template-table";

export default class TemplateGridScene extends BaseScene {
  metadataSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      templates: [],
      templateSearchParameters: {},
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      useDefaultDate: true,
    };
    this.handleSearchRequest = this.handleSearchRequest.bind(this);

    this.getTemplates = this.getTemplates.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
  }

  sceneName = "template";

  componentDidMount() {
    // this.setState({applicationSearchParameters: {...this.state.applicationSearchParameters, approvalStatus: validateApplicationPermission()}})
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 3000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 3000);
    }
  }

  async getTemplates(pageNum, pageSize) {
    const payload = this.state.templateSearchParameters;
    payload.fromDate =
      payload.fromDate && formatBackEndDateTime(payload.fromDate);
    payload.toDate = payload.toDate && formatBackEndDateTime(payload.toDate);
    this.setState({
      isLoading: true,
      canLoadData: true,
    });

    const { status, response } = await messagingService.getAllTemplatesQuery(
      pageNum,
      pageSize,
      payload
    );
    const { items, totalCount } = response;

    if (status === SUCCESS_STATUS) {
      this.setState({
        templates: items,
        pages: Math.ceil(totalCount / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        dataCount: totalCount,
        tableMessage: items.length === 0 ? "No template found" : "",
      });
    } else {
      this.setState({
        templates: [],
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        errorMessage: response,
        tableMessage: "No notification group found",
      });
    }
    window.scrollTo(0, this.metadataSectionRef.current);
  }

  handleSearchRequest(templateSearchParameters) {
    this.setState({
      startPage: 1,
      templateSearchParameters,
    });
    setTimeout(() => {
      this.getTemplates(1, this.state.pageSize);
    }, 200);
  }

  getClickedAction(templateKey, templateName) {
    this.setState({
      templateKey,
      templateName,
    });
  }

  async deleteTemplate() {
    this.setState({
      isLoading: true,
    });

    const { status, response } = await messagingService.deleteTemplate(
      this.state.templateKey
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        templateKey: null,
        templateName: null,
        successMessage: `Template was successfully deleted`,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getTemplates(this.state.page, this.state.pageSize);
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />
        <ContentHead
          title={"Notifications Template"}
          totalCount={56}
          filterPermissions={[CAN_MESSAGING_READ_TEMPLATE]}
          actionButtonRequiredPermissions={[CAN_MESSAGING_WRITE_TEMPLATE]}
          actionButtonText={"Create New Template"}
          actionButtonUrl={"template/add"}
          showTooltip={true}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_MESSAGING_READ_TEMPLATE]}
        >
          <GridLayout>
            <TemplateSearch handleSearchRequest={this.handleSearchRequest} />
            <TemplateTable
              data={this.state.templates}
              showApplicantName={false}
              showBusinessName={true}
              getTemplates={this.getTemplates}
              pages={this.state.pages}
              startPage={this.state.startPage}
              noDataText={this.state.tableMessage}
              loading={this.state.isLoading}
              dataCount={this.state.dataCount}
              page={this.state.page}
              pageSize={this.state.pageSize}
              getClickedAction={this.getClickedAction}
              canView={CAN_MESSAGING_WRITE_TEMPLATE}
              canCancel={CAN_MESSAGING_WRITE_TEMPLATE}
              canLoadData={this.state.canLoadData}
            />
            <PromptModal
              modalTitle={this.state.templateName + ` template`}
              modalBody={`Do you want to delete ${this.state.templateName} template?`}
              deleteModal={this.deleteTemplate}
              modalSize={`modal-md`}
              height={`230px`}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
