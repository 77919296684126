import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import BaseScene from '../../../../../components/scenes/component';
import { CAN_VIEW_TRANSACTION } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import PayWithTransferGridScene from './scenes/pay-with-transfer-grid';

export default class PayWithTransferScene extends BaseScene {
  sceneName = 'pwt-resolution'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} exact path='/home/pwt-resolution' component={PayWithTransferGridScene} />
    </Router>
  }
}
