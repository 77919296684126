import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_UPDATE_REGION, CAN_VIEW_REGION } from '../../../../../../../constants/permissions.js';
import Countries from '../../../../../../../fixtures/countries.json';
import { platformManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { getLgasByStateId, getStatesByCountryId } from '../../../../../../../utils/helper';
import RegionMappingForm from '../../components/region-mapping-form';

export default class ViewRegionMappingScene extends BaseScene {

    notificationSectionRef = React.createRef(null)

    constructor (props) {
        super(props)

        this.state = {
            canSave: null,
            countries: [],
            regionMappingFormData: {
                lga: [],
                removedLga: [],
                countryId: 1,
                stateId: 1,
                requestType: 0,
            },
            states: [],
            regions: [],
            lgas: []
        }

        this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
        this.updateRegionMappingFormData = this.updateRegionMappingFormData.bind(this)
        this.getStates = this.getStates.bind(this)
        this.getLgas = this.getLgas.bind(this)
        this.checkAllLgas = this.checkAllLgas.bind(this)
        this.uncheckAllLgas = this.uncheckAllLgas.bind(this)
        this.updateLgaStatus = this.updateLgaStatus.bind(this)
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
        this.getLgas = this.getLgas.bind(this)
    }

    componentDidMount() {
        this.regionId = this.props.match.params.id
        this.getRegion()
        this.getMappedRegionLgas()
        this.getRegions()
        this.getCountries()
        // this.getLgas()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
            setTimeout(() => {
                this.setState({errorMessage: null})
            }, 5000)
        }
        if (this.state.successMessage) {
            setTimeout(() => {
                this.setState({successMessage: null})
            }, 5000)
        }
    }

    async onSaveButtonClick () {
        this.setState({isLoading: true})
        let responseObj = await platformManagement.mapRegionToLga(this.state.regionMappingFormData)
        if(this.state.regionMappingFormData.removedLga.length > 0) {
            responseObj = await platformManagement.mapRegionToLga({
                countryId: this.state.regionMappingFormData.stateId,
                stateId: this.state.regionMappingFormData.stateId,
                regionId: this.state.regionMappingFormData.regionId,
                requestType: 1,
                lga: this.state.regionMappingFormData.removedLga
            })    
        }
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                successMessage: 'Region mapping updated successfully',
                isLoading: false,
            })
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false,
            })
        }
        window.scrollTo(0, this.notificationSectionRef.offsetTop)
    }

    async getMappedRegionLgas () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getMappedRegionLgas(this.regionId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                regionMappingFormData: {...this.state.regionMappingFormData, lga: this.getLgaIds(response)},
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                regionMappingFormData: {...this.state.regionMappingFormData, lga: []},
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getRegion () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getRegion(this.regionId)
        const { status, response } = responseObj
        const { regionId, regionName } = response
        if (status === SUCCESS_STATUS) {
            this.getStates(this.state.regionMappingFormData.countryId)  
            this.getLgas(this.state.regionMappingFormData.stateId)
            this.setState({
                isLoading: false,
                regionMappingFormData: {...this.state.regionMappingFormData, 
                    regionId,
                    regionName
                }
            })
        }else{
          this.setState({
            regionMappingFormData: [],
            isLoading: false,
            errorMessage: response
          })
        }
    }

    async getCountries () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getCountries()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                countries: response,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                countries: Countries,
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getRegions () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getRegions()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                regions: response.content,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                regions: [],
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getStates (countryId) {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getStatesByCountryId(countryId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                states: response,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                states: getStatesByCountryId(countryId),
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    async getLgas (stateId) {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getLgasByStateId(stateId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                lgas: response,
                isLoading: false,
                dataCount: response.length
            })
        }else{
            this.setState({
                lgas: getLgasByStateId(stateId),
                isLoading: false,
                dataCount: 0,
            })
        }
    }

    getLgaIds (lgas) {
        let lgaIds = []
        lgas.map(value => {
            lgaIds.push(value.id)
        })
        return  lgaIds
    }

    checkAllLgas () {
        const selectedLgas = []
        const checkedLgas = this.state.lgas.map(value => {
            value.checked = true
            selectedLgas.push(value.id)
            return value
        })
        this.setState({
            lgas: checkedLgas,
            regionMappingFormData: {...this.state.regionMappingFormData, lga: selectedLgas}
        })
    }

    uncheckAllLgas () {
        let selectedLgas = this.state.regionMappingFormData.lga
        const uncheckedLgas = this.state.lgas.map(value => {
            value.checked = false
            selectedLgas =  selectedLgas.filter(item => item !== value.id)
            return value
        })
        this.setState({
            lgas: uncheckedLgas,
            regionMappingFormData: {...this.state.regionMappingFormData, lga: selectedLgas}
        })
    }

    updateLgaStatus (lgaId) {
        let selectedLgas = this.state.regionMappingFormData.lga
        let removedLgas = this.state.regionMappingFormData.removedLga
        const lgas = this.state.lgas.map(value => {
            if(value.id === lgaId) {
                value.checked = !value.checked
                if(value.checked) {
                    selectedLgas.push(value.id)
                }else{
                    removedLgas.push(value.id)
                    selectedLgas =  selectedLgas.filter(item => item !== value.id)
                }
            }
            return value
        })
        this.setState({
            lgas: lgas,
            regionMappingFormData: {...this.state.regionMappingFormData, lga: selectedLgas, removedLga: removedLgas}
        })
    }

    mapLgaToRegion (lgaDetails) {
        const lga = {
            id: lgaDetails.id,
            name: lgaDetails.name,
            code: lgaDetails.code
        }

        // this.setState({
        //     regionMappingFormData: {...this.state.regionMappingFormData, [type]: value}
        // })
    }

    unmapLgaToRegion () {

    }

    isFormValid () {
        return Boolean(!this.state.regionMappingFormData.regionId || !this.state.regionMappingFormData.stateId || !this.state.regionMappingFormData.countryId)
    }

    updateRegionMappingFormData (type, value) {
        this.setState({regionMappingFormData: {...this.state.regionMappingFormData, [type]: value}})
    }

    render () {

    const ctaWidgets = <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_UPDATE_REGION]}><button type="button" disabled={this.isFormValid()} class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
        <Loader 
            isLoading={this.state.isLoading}
        />
        <Notification 
            ref={this.notificationSectionRef}
            successMessage={this.state.successMessage}
            errorMessage={this.state.errorMessage}
        />
        <ContentHead title={"Region"} withoutSearch subtitle="View Mapping" /*ctaWidgets={ctaWidgets}*/ />
        <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_REGION]}>
            <GridLayout>
                <RegionMappingForm 
                    onChange={this.updateRegionMappingFormData}
                    regions={this.state.regions}
                    countries={this.state.countries}
                    states={this.state.states}
                    lgas={this.state.lgas}
                    getLgas={this.getLgas}
                    getStates={this.getStates}
                    checkAllLgas={this.checkAllLgas}
                    uncheckAllLgas={this.uncheckAllLgas}
                    updateLgaStatus={this.updateLgaStatus}
                    onSaveButtonClick={this.onSaveButtonClick}
                    regionMappingFormData={this.state.regionMappingFormData}
                    disabled={true}
                    hideCheck={true}
                />
            </GridLayout>
        </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}