import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  CAN_VIEW_AGENT,
  CAN_ACTIVATE_AGENT,
  CAN_DEACTIVATE_AGENT,
  CAN_RETIRE_AGENT,
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_VIEW_TRANSACTION,
} from "../../../../../../../constants/permissions.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { AccessDenied } from "../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import { AGENT_TYPE_ID } from "../../../../../../../constants/domain-types";
import { platformManagement } from "../../../../../../../mixins/api";
import AgentBVNTable from "../../components/agent-bvn-table";
import { AgentBVNSearch } from "../../components/agent-bvn-search";
import { retrieveOtherInformation } from "../../../../../../../mixins/authentication";
import qs from "querystring";

export default class AgentBVNGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      agentSearchParameters: {
        agentType: AGENT_TYPE_ID,
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
    };

    this.getAgentBVNDetails = this.getAgentBVNDetails.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.downloadAgentBVNData = this.downloadAgentBVNData.bind(this);
    this.updateAgentSearchParameters = this.updateAgentSearchParameters.bind(
      this
    );
  }

  sceneName = "agent-bvn-management";

  componentDidMount() {
    this.parentId = qs.parse(window.location.search)["?parentId"];
    this.setState({
      parentBusinessName: this.parentId
        ? `${retrieveOtherInformation("aggregatorBusinessName")} Agents`
        : "",
    });

    this.props.updateScene(this.sceneName);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  updateAgentSearchParameters(type, value) {
    this.setState({
      agentSearchParameters: {
        ...this.state.agentSearchParameters,
        [type]: value,
      },
    });
  }

  async getAgentBVNDetails(pageNum, pageSize) {
    this.parentId = qs.parse(window.location.search)["?parentId"];
    const payload = this.state.agentSearchParameters;
    payload.parentId = this.parentId ? this.parentId : null;
    payload.agentMobileNumber = payload.agentMobileNumber
      ? `234${payload.agentMobileNumber.slice(-10)}`
      : "";

    this.setState({
      isLoading: true,
      canLoadData: true,
    });

    const responseObj = await platformManagement.searchAgentBVNDetails(
      pageNum,
      pageSize,
      payload
    );
    const { status, response, count } = responseObj;

    if (status === SUCCESS_STATUS) {
      this.setState({
        agents: response ? response : "",
        dataCount: count,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: response.length === 0 ? "No agents found" : "",
      });
    } else {
      this.setState({
        agents: [],
        agentExportData: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No agents found",
      });
    }
  }

  async downloadAgentBVNData() {
    this.parentId = qs.parse(window.location.search)["?parentId"];
    const payload = this.state.agentSearchParameters;
    // payload.startDate = payload.startDate && formatBackEndDateTime(payload.startDate)
    // payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate)
    payload.parentId = this.parentId ? this.parentId : null;
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await platformManagement.downloadAgentBVNData(
      this.state.page,
      this.state.dataCount,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "BvnVerificationReport.xls");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  isFormValid() {
    if (this.state.showCommentField) {
      return Boolean(this.state.description);
    } else {
      return true;
    }
  }

  handleSearchRequest() {
    this.setState({ loading: true });
    this.setState({
      startPage: 1,
    });
    setTimeout(() => {
      this.getAgentBVNDetails(1, 20);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Agent's BVN"}
          subtitle={this.state.parentBusinessName}
          filterPermissions={[CAN_VIEW_AGENT]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AGENT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <AgentBVNSearch
              handleSearchRequest={this.handleSearchRequest}
              agentType={AGENT_TYPE_ID}
              preloadDate={false}
              agentSearchParameters={this.state.agentSearchParameters}
              updateAgentSearchParameters={this.updateAgentSearchParameters}
            />
            <AgentBVNTable
              data={this.state.agents}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getAgentBVNDetails={this.getAgentBVNDetails}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_AGENT}
              canActivate={CAN_ACTIVATE_AGENT}
              canDeactivate={CAN_DEACTIVATE_AGENT}
              canRetire={CAN_RETIRE_AGENT}
              canManageDomain={CAN_MANAGE_AGENT_DOMAIN}
              canViewTransaction={CAN_VIEW_TRANSACTION}
              canLoadData={this.state.canLoadData}
              downloadAgentBVNData={this.downloadAgentBVNData}
            />
            {/* <PromptModal
            modalTitle={ this.state.agentStatus+` Agent`}
            modalBody={modalBody}
            deleteModal={this.updateAgent}
            modalSize={`modal-md`}
            height={this.state.showCommentField ? '430px' : '230px'}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
            textAlign={'left'}
            modalFooterButton2Disabled={!this.isFormValid()}
          /> */}
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
