import React from "react";
import { Link } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import {
  CAN_UPDATE_BANK,
  CAN_VIEW_BANK,
} from "../../../../../../../constants/permissions.js";
import { nipbankService } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { NIPBankExportSerializer } from "../../../../../../../serializers";
import BankSearch from "../../components/bank-search";
import NIPBankTable from "../../components/Nip-bank-table";

export default class BankGridScene extends BaseScene {
  sceneName = "nip-bank-management";

  constructor(props) {
    super(props);
    this.state = {
      bankList: [],
      searchParameters: { bankName: "" },
      pageNum: 1,
      pageSize: 20,
    };
  }

  getBankList = this.getBankList.bind(this);
  getClickedAction = this.getClickedAction.bind(this);
  onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
  updateSearchParameters = this.updateSearchParameters.bind(this);
  handleSearchRequest = this.handleSearchRequest.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  componentDidMount() {
    this.getBankList(this.state.pageNum, this.state.pageSize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getBankList(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });

    const payload = {
      ...this.state.searchParameters,
      pageNo: pageNum,
      pageSize: pageSize,
    };

    const responseObj = await nipbankService.getNIPBankList(payload);

    const { status, response } = responseObj;
    let tempCount = 500;
    if (status === SUCCESS_STATUS) {
      this.setState({
        bankList: response,
        dataCount: tempCount,
        pages: Math.ceil(tempCount / pageSize), //change tempCount to count when backend returns it
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        tableMessage: "",
      });
    } else {
      this.setState({
        bankList: [],
        dataCount: 0,
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        errorMessage: response,
        tableMessage: "No record found",
      });
    }
  }

  async onUpdateButtonClick() {
    this.setState({ isLoading: true });

    const payload = {
      sanefBankCode: this.state.sanefBankCode,
      bankName: this.state.bankName,
      enabledForSanef: this.state.enabledForSanef,
      enabledForCardLinking: this.state.enabledForCardLinking,
      requireBvn: this.state.requireBvn,
    };

    const responseObj = await nipbankService.modifyBankDetails(
      this.state.sanefBankCode,
      payload
    );

    const status = responseObj.status;
    const response = responseObj.response;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: `${this.state.bankName} successfully ${this.state.categoryName}d`,
        redirect: true,
        updateButton: true,
        saveButton: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }

    this.getBankList();
  }

  getClickedAction(
    sanefBankCode,
    bankName,
    enabledForSanef,
    enabledForCardLinking,
    requireBvn,
    categoryName
  ) {
    this.setState({
      sanefBankCode,
      bankName,
      enabledForSanef,
      enabledForCardLinking,
      requireBvn,
      categoryName,
      modalIsOpen: true,
      canDelete: false,
      modalTitle: `${bankName}`,
      updateModalBody: `Do you want to ${categoryName} ${bankName}?`,
      modalHeight: "250px",
      canUpdate: false,
      canAdd: false,
    });
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  handleSearchRequest() {
    this.getBankList(1, 20);
  }

  getSerialNumber(index) {
    return this.state.pageSize * (this.state.page - 1) + index;
  }

  render() {
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
      },
      {
        Header: "Bank Name",
        accessor: "bankName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "CBN Code",
        accessor: "cbnBankCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "NIP Bank Code",
        accessor: "nipBankCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Bank Category",
        accessor: "bankCategory",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Alias",
        accessor: "alias",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 100,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              {/* <ProtectedComponent requiredPermissions={[CAN_UPDATE_BANK]}> */}
              <Link
                to={`bank-management/${original.bankName /*nipBankCode*/}/view`}
                class="dropdown-item"
              >
                <i class="la la-edit"></i>
                Edit Bank
              </Link>
              {/* </ProtectedComponent> */}
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"NIP Banks"}
          filterPermissions={[CAN_UPDATE_BANK]}
          actionButtonText={"Add NIP Bank"}
          actionButtonUrl={"/home/system-configuration/bank-management/add"}
          totalCount={56}
          actionButtonRequiredPermissions={[CAN_UPDATE_BANK]}
        />

        <BankSearch
          updateSearchParameters={this.updateSearchParameters}
          handleSearchRequest={this.handleSearchRequest}
        />

        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_BANK]}
        >
          <GridLayout>
            <NIPBankTable
              data={this.state.bankList}
              columns={columns}
              loading={this.state.isLoading}
              noDataText={`No bank found`}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getBankList={this.getBankList}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canLoadData={this.state.canLoadData}
              exportData={this.state.bankList}
              exportHeaders={NIPBankExportSerializer.headerExport()}
              exportFileName={"NIP Banks"}
            />
          </GridLayout>
        </ProtectedComponent>

        <PromptModal
          modalTitle={this.state.modalTitle}
          modalBody={this.state.updateModalBody}
          deleteModal={this.onUpdateButtonClick}
          modalSize={`modal-md`}
          height={this.state.modalHeight}
          textAlign={"center"}
          modalFooterButton1={`Cancel`}
          modalFooterButton2={"Yes"}
          modalFooterButton2Disabled={
            this.state.canDelete ? false : !this.state.bankName
          }
          hideModalHeader={true}
          hideModalFooter={true}
        />
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
