import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BaseScene from '../../../../../components/scenes/component';
import SuperAgentGridScene from './scene/super-agent-grid'
import AddSuperAgentScene from './scene/add-super-agent';
import ViewSuperAgentScene from './scene/view-super-agent';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_SERVICE_PROVIDER, CAN_CREATE_SERVICE_PROVIDER, CAN_MANAGE_SP_DOMAIN } from '../../../../../constants/permissions.js'
import DomainManagementScene from '../../domain-management';

export default class SuperAgentScene extends BaseScene {
  sceneName = 'super-agent-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_PROVIDER]} exact path="/home/super-agent-management" component={SuperAgentGridScene} />
      {/* <ProtectedRoute requiredPermissions={[CAN_CREATE_SERVICE_PROVIDER]} exact path="/home/super-agent-management/add" component={AddSuperAgentScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_PROVIDER]} exact path="/home/super-agent-management/:id/view" component={ViewSuperAgentScene} />
      <ProtectedRoute requiredPermissions={[CAN_MANAGE_SP_DOMAIN]} path="/home/domain-management/:domainCode" component={DomainManagementScene} />
    </Router>
  }
}
