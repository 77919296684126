import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BusinessTypeGridScene from './scenes/business-type-grid'
import BaseScene from '../../../../../../../components/scenes/component';

export default class BusinessTypeScene extends BaseScene {
  sceneName = 'business-types'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <Route exact path='/home/application-management/configuration/business-types' component={BusinessTypeGridScene} />
    </Router>
  }
}
