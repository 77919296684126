import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AgentHierarchyGridScene from './scenes/agent-hierarchy-grid';
import AgentHierarchyPermissionScene from './scenes/agent-hierarchy-permission'
import AgentHierarchyRoleGridScene from './scenes/agent-hierarchy-roles-grid'
import AgentHierarchyAddRoleScene from './scenes/agent-hierarchy-add-role'
import AgentHierarchyViewRoleScene from './scenes/agent-hierarchy-view-role'
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_AGENT_HIERARCHY, CAN_VIEW_AGENT_HIERARCHY_PERMISSION, CAN_VIEW_AGENT_HIERARCHY_ROLE, CAN_CREATE_AGENT_HIERARCHY_ROLE } from '../../../../../constants/permissions.js'

export default class AgentHierarchyScene extends Component {
  sceneName = 'agent-hierarchy'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY]} exact path='/home/agent-hierarchy' component={AgentHierarchyGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_PERMISSION]} exact path='/home/agent-hierarchy/manage-permissions/:id' component={AgentHierarchyPermissionScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_ROLE]} exact path='/home/agent-hierarchy/manage-roles/:id' component={AgentHierarchyRoleGridScene} />
      {/* <ProtectedRoute requiredPermissions={[CAN_CREATE_AGENT_HIERARCHY_ROLE]} exact path="/home/agent-hierarchy/manage-roles/add" component={AgentHierarchyAddRoleScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_ROLE]} exact path="/home/agent-hierarchy/manage-roles/:domainType/:id" component={AgentHierarchyViewRoleScene} />
    </Router>
  }
}
