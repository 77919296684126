import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";
import Select from "../../../../../../components/select";
import moment from 'moment';

export default class ReconSchedulerEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      jobEnable: "",
      value: "Yes",
      jobEnableOptions: [
        { value: "True", label: "True" },
        { value: "False", label: "False" },
      ],
    };
  }

  render() {
    const resul = this.props.schedulerDetails || "";
    // console.log(this.props.schedulerDetails, "SCHEDDDDDDD");
    const placeholder = JSON.stringify(resul.jobEnabled);
    const setJobName = () => {
      this.props.updateSearchParameters(
        "jobName",
        resul.jobName
      )

      setTimeout(() => {this.props.handleScheduler()},[2000])
  }
    return (
      <React.Fragment>
        <div
          class="modal"
          id="scheduler_edit"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Edit Reconciliation Scheduler
                </h3>
              </div>

              <div class="kt-portlet">
                <div class="kt-portlet__head" style={{ cursor: "pointer" }}>
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Scheduler Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Job Name</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="text"
                            class="search-input"
                            value={resul.jobName}
                            onChange={(event) =>
                              this.props.updateSearchParameters(
                                "agentId",
                                event.target.value
                              )
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Job Date</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <DatePickerComponent
                            onChange={(event) =>
                              this.props.updateSearchParameters(
                                "runningDate",
                                event
                              )
                            }
                            size={`170px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            // minDate={addDays(new Date(), 1)}
                            maxDate={moment().add(3, 'months').format('YYYY-MM-DDT00:00:00')}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            // dateType={"startDate"}
                            preloadDate={this.props.preloadDate}
                            placeholder="Select Date..."
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Offset (Hour)</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            class="search-input"
                            // placeholder="Enter Offset(hour)"
                            placeholder={`Enter Offset hour(${resul.offset})`}
                            onChange={(event) =>
                              this.props.updateSearchParameters(
                                "offset",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Lag (hour)</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            class="search-input"
                            // placeholder="Enter Lag(hour)"
                            placeholder={`Enter Lag hour(${resul.lagHour})`}
                            onChange={(event) =>
                              this.props.updateSearchParameters(
                                "lagHour",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Job Enabled</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <Select
                            options={this.state.jobEnableOptions}
                            // placeholder={placeholder}
                            placeholder="Select..."
                            // onChange={(event) =>
                            //   this.setState({ jobEnable: event.value })
                            // }
                            onChange={(event) => {
                              this.props.updateSearchParameters(
                                "jobEnable",
                                event.value
                              );
                            }}
                          />
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer" style={{width: "250px", marginLeft: "auto", marginRight: "20%"}}>
                
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                  disabled={!(this.props.searchParameters.lagHour && this.props.searchParameters.offset && this.props.searchParameters.jobEnable)}
                  onClick={() => {
                    setJobName();
                  }}
                 
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
