import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  CAN_VIEW_TRANSACTION,
} from "../../../../../../../constants/permissions.js";
import {
  liquidityService
} from "../../../../../../../mixins/api";
import {
  retrieveOtherInformation, saveOtherInformation,
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
  phoneNumberFormatter,
} from "../../../../../../../utils/formatter";
import {
  getDateByType,
} from "../../../../../../../utils/helper";
import CashoutInitiatedTable from "../../components/cashout-initiated-table";
import CashoutResponseModal from "../../components/cashout-response-modal";
import CashoutSearch from "../../components/cashout-search";

export default class CashoutGridScene extends BaseScene {
  sceneName = "cashout-initiated";

  transactionDetailsSectionRef = React.createRef(null);
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      transactionDetails: [],
      searchParameters: {
        startDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
        endDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("endDate")
          : null
      },
      pages: 1,
      pageNumber: 1,
      pageSize: 20,
      checkedTransactions: [],
      tableMessage: "",
    };

    this.getInitiatedCashout = this.getInitiatedCashout.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.requerySingleTransaction = this.requerySingleTransaction.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.requeryBulkTransactions = this.requeryBulkTransactions.bind(this);
    this.selectAllTransactions = this.selectAllTransactions.bind(this);
    this.redirectToCommission = this.redirectToCommission.bind(this);
    this.getSelectedTransactions = this.getSelectedTransactions.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getInitiatedCashout(pageNumber, pageSize) {
    const {
      businessMobileNo,
      endDate,
      startDate,
      transRef,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDate = endDate && formatBackEndDateTimeTo(endDate);
    const formattedStartDate = startDate && formatBackEndDateTimeFrom(startDate);
    const formattedBusinessMobileNo =
      businessMobileNo && phoneNumberFormatter(businessMobileNo);
    this.setState({
      pageNumber,
      pageSize,
      isLoading: true,
    });
    const {
      status,
      response,
    } = await liquidityService.getInitiatedCashout({
      pageNumber,
      pageSize,
      formattedBusinessMobileNo,
      formattedEndDate,
      formattedStartDate,
      transRef}
    );
    if (status === SUCCESS_STATUS) {
      console.log({response})
      this.setState({
        transactions: response ? response.items : [],
        pages: Math.ceil(response.totalCount / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        dataCount: response.totalCount,
        isLoading: false,
      });
    } else {
      this.setState({
        transactions: [],
        transactionExportData: [],
        dataCount: 0,
        pages: Math.ceil(response.totalCount / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        isLoading: false,
        tableMessage: "No transaction history found",
      });
    }
  }

  getSelectedTransactions(isChecked, transRef) {
    let checkedTransactions = this.state.checkedTransactions;
    const transactions = this.state.transactions.map((value) => {
      if (value.transactionRef === transRef) {
        value.checked = isChecked;
        const transRefExist = this.handleDuplicateTransactions(
          checkedTransactions,
          transRef
        );
        if (isChecked) {
          if (!transRefExist) {
            checkedTransactions.push(transRef);
          }
        } else {
          checkedTransactions = checkedTransactions.filter(function (item) {
            return item !== transRef;
          });
        }
      }
      return value;
    });
    this.setState({
      transactions,
      checkedTransactions,
    });
  }

  handleSearchRequest() {
    const {
      businessMobileNo,
      businessName,
      endDate,
      startDate,
      transactionStatus,
      transactionType,
      transRef,
      responseCode,
      parentReference,
    } = this.state.searchParameters;
    this.getInitiatedCashout(
      this.state.pageNumber,
      this.state.pageSize,
      businessMobileNo,
      businessName,
      endDate,
      startDate,
      transactionStatus,
      transactionType,
      transRef,
      responseCode,
      parentReference
    );
  }

  updateSearchParameters (type, value) {
    if(value == 0 || value=='All' || !value)
    {
      this.setState({searchParameters: {...this.state.searchParameters, [type]: ''}})
      console.log("Here")
      return;
    }
    
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  async requerySingleTransaction() {
    let transRef = this.state.transRef;
    this.setState({ isLoading: true });
    const responseObj = await liquidityService.requeryBulkTransactions(
      [transRef]
    );

    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        transRef,
        actionType:"response",
        transactionDetails:response
      });
      let requeryButtonHidden = document.getElementById('requery');
      requeryButtonHidden.click();
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getInitiatedCashout(this.state.pageNumber, this.state.pageSize);
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
  }

  async requeryBulkTransactions() {
    this.setState({ isLoading: true });
    const responseObj = this.state.checkedTransactions.length
      ? await liquidityService.requeryBulkTransactions(
          this.state.checkedTransactions
        )
      : {
          status: "ERROR",
          response: {
            description:
              "Check out the transaction(s) you want to bulk re-query",
          },
        };
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        checkedTransactions: [],
        actionType:"response",
        transactionDetails:response
      });

      let requeryButtonHidden = document.getElementById('requery');
      requeryButtonHidden.click();
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getInitiatedCashout(this.state.pageNumber, this.state.pageSize);
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
  }

  getClickedAction(transaction, actionType) {
    this.setState({
      transRef: transaction,
      actionType,
    });
  }

  isFormValid() {
      return true;
  }

  redirectToCommission(transaction) {
    const commissionReference = `${transaction.transactionRef}${transaction.domainCode}`;
    saveOtherInformation("commissionReference", commissionReference);
  }

  selectAllTransactions(isChecked) {
    let checkedTransactions = this.state.checkedTransactions;
    const transactions = this.state.transactions.map((value) => {
      value.checked =
        value.statusCode == "INITIATED" ? isChecked : false;
      const transRefExist = this.handleDuplicateTransactions(
        checkedTransactions,
        value.transactionRef
      );
      if (isChecked && value.statusCode == "CLOSED_AND_SUCCESSFUL") {
        if (!transRefExist) {
          checkedTransactions.push(value.transactionRef);
        }
      } else {
        checkedTransactions = checkedTransactions.filter(function (item) {
          return item !== value.transactionRef;
        });
      }
      return value;
    });

    this.setState({
      transactions,
      checkedTransactions,
    });
  }

  handleDuplicateTransactions(transactions, transRef) {
    if (transactions.indexOf(transRef) === -1) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`You are about to requery the selected cashout transaction \nClick SUBMIT to continue or CANCEL if otherwise`}</p>
        <div class="form-group row">
          <div class="col-lg-5">
          </div>
        </div>
      </React.Fragment>
    );
    
    return (
      <React.Fragment>
        <ContentHead
          title={"Cashout Initiated"}
          filterPermissions={[CAN_VIEW_TRANSACTION]}
          totalCount={56}
        />
        <Notification
          ref={this.notificationSectionRef}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <CashoutSearch
              searchParameters={this.state.searchParameters}
              agentName={this.state.transactions.agentName}
              updateSearchParameters={this.updateSearchParameters}
              handleSearchRequest={this.handleSearchRequest}
              preloadDate={
                retrieveOtherInformation("parentReference")
                  ? false
                  : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
              }
              showRepush={this.state.transactions.length>0}
              requeryBulkTransactions={this.requeryBulkTransactions}
            />
            <CashoutInitiatedTable
              pages={this.state.pages}
              getTransactions={this.getInitiatedCashout}
              data={this.state.transactions}
              startPage={this.state.startPage}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              page={this.state.pageNumber}
              pageSize={this.state.pageSize}
              searchParameters={this.state.searchParameters}
              responseCode={this.state.searchParameters.responseCode}
              startDate={this.state.searchParameters.startDate}
              domainCode={this.state.searchParameters.domainCode}
              canView={CAN_VIEW_TRANSACTION}
              getSelectedTransactions={this.getSelectedTransactions}
              requerySingleTransaction={this.requerySingleTransaction}
              getClickedAction={this.getClickedAction}
              requeryBulkTransactions={this.requeryBulkTransactions}
              desiredStatus={
                this.state.desiredStatus ? this.state.desiredStatus : "default"
              }
              selectAllTransactions={this.selectAllTransactions}
              canLoadData={this.state.canLoadData}
              redirectToCommission={this.redirectToCommission}
            />
            <CashoutResponseModal
              transactionDetails={this.state.transactionDetails}
            />
            <PromptModal
              modalTitle={
                this.state.actionType === "resolve"
                  ? `Transaction Requery`
                  : `Requery Response`
              }
              modalBody={
                this.state.actionType === "resolve" ? (
                  modalBody
                ) : ""
              }
              modalFooterButton1={
                this.state.actionType === "resolve" ? `Cancel` : `Close`
              }
              hideModalHeader={true}
              modalSize={`modal-md`}
              height={this.state.actionType === "resolve" ? "300px" : "690px"}
              modalFooterButton2={
                this.state.actionType === "resolve" ? `Submit` : ""
              }
              deleteModal={this.requerySingleTransaction}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}