import { formatDate } from '../../utils/formatter';
import { getStateName } from '../../utils/helper'

export default class LgaExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Code', key: 'code', title: 'Code', dataIndex: 'code'},
            {label: 'State', key: 'state', title: 'State', dataIndex: 'state'},
        ]
    }

    static reverseLgaDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, code, stateId } = details
            let detailFormData = {
                name,
                code,
                state: stateId ? getStateName(1, stateId) : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}