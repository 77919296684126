import React from "react";
import { getTransactionTypeLabel } from "../../../../../../utils/helper";


export default class ReconDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {
    const resul = this.props.transactionDetails ? this.props.transactionDetails : "";
   
    return (
      <React.Fragment>
        <div
          class="modal"
          id="recon_details_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Reconciliation report Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Report Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                    {resul.commissionAmount? 
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Agent ID</strong>
                          </label>
                          <br />
                          <span>
                            {resul.agentID}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                        <label>
                            <strong>Commission Amout</strong>
                          </label>
                          <br />
                          <span>
                            {resul.commissionAmount}
                          </span>
                        </div>
                      </div>
                      : ""}

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Reconciliation type</strong>
                          </label>
                          <br />
                          <span>
                            {resul.reconciliationType}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Source</strong>
                          </label>
                          <br />
                          <span>
                            {resul.serviceSource}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul.status}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Status Code</strong>
                          </label>
                          <br />
                          <span>
                            {resul.statusCode}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Transaction Amount</strong>
                          </label>
                          <br />
                          <span>
                            #{resul.transactionAmount}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Transsaction Ref</strong>
                          </label>
                          <br />
                          <span>
                            {resul.transactionRef}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Transaction Type</strong>
                          </label>
                          <br />
                          <span>
                            {getTransactionTypeLabel(resul.transactionType)}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Unique Reference</strong>
                          </label>
                          <br />
                          <span>
                            {resul.uniqueReference}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Wallet Version</strong>
                          </label>
                          <br />
                          <span>
                            {resul.walletVersion}
                          </span>
                          <br />
                          <br />
                        </div>
                        
                        <div class="col-lg-6">
                          <label>
                            <strong>Narration</strong>
                          </label>
                          <br />
                          <span>
                            {resul.narration}
                          </span>
                          
                          <br />
                          <br />
                        </div>
                       
                      </div>
                      <div class="form-group row">
                      {resul.serviceSource? 
                        <div class="col-lg-6">
                          <label>
                            <strong>Service Source</strong>
                          </label>
                          <br />
                          <span>
                            {resul.serviceSource}
                          </span>
                          <br />
                          <br />
                        </div>
                        : ""}
                        {resul.payDirectStatus? 
                        <div class="col-lg-6">
                        <label>
                            <strong>Pay Direct Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul.payDirectStatus}
                          </span>
                        </div>
                      : ""}
                      </div>

                      <hr />
                      
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
