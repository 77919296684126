import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  formatDateTime,
  formatCurrency,
} from "../../../../../../../../utils/formatter";
import { convertToMajor } from "../../../../../../../../utils/converter";
import {
  getPaginationRows
} from "../../../../../../../../utils/helper";
import SpinnerComponent from "../../../../../../../../components/spinner";
import Pagination from "../../../../../../../../components/pagination";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";
import TableExtra from "../../../../../../../../components/table-extra";



export default class DeliveryNoteTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "Agent Name",
        accessor: "agentName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
      },
      {
        Header: "Agent Mobile",
        accessor: "agentMobile",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
      },
      {
        Header: "Agent Address",
        accessor: "agentAddress",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150
      },
      {
        Header: "Agent State",
        accessor: "agentState",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150
      },
      {
        Header: " POS Request ID",
        accessor: "requestId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150
      },
      {
        Header: "Terminal Model",
        accessor: "terminalModel",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150
      },
      {
        Header: "Terminal Type",
        accessor: "terminalType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        // Cell: (props) => (
        //   <span>
        //     &#x20A6;
        //     {props.value
        //       ? formatCurrency(convertToMajor(props.value))
        //       : formatCurrency(0)}
        //   </span>
        // ),
      },
      {
        Header: "Activated By",
        accessor: "activatedBy",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Activation Date",
        accessor: "terminalActivationDate",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Delivery Date",
        accessor: "dateDelivered",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      // {
      //   Header: "Action",
      //   overflow: "visible",
      //   resizable: false,
      //   sortable: false,
      //   headerStyle: { "white-space": "unset" },
      //   width: 70,
      //   Cell: ({ row, original }) => (
      //     <div class="dropdown">
      //       <a
      //         href="#"
      //         class="btn btn-hover-brand btn-icon btn-pill"
      //         data-toggle="dropdown"
      //       >
      //         <i class="la la-ellipsis-v"></i>
      //       </a>
      //       <div class="dropdown-menu dropdown-menu-right">
      //         <ProtectedComponent requiredPermissions={[this.props.canView]}>
      //           <a
      //             data-toggle="modal"
      //             data-target="#recon_details_modal"
      //             onClick={() => this.props.getClickedAction(original, "view")}
      //             /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
      //             style={{ color: "#A7ABC3", cursor: "pointer" }}
      //           >
      //             <i class="la la-eye"></i>
      //             View
      //           </a>
      //         </ProtectedComponent>
      //       </div>
      //     </div>
      //   ),
      // },
    ];

    return (
      <React.Fragment>
       <TableExtra
          fileName={"Delivery Notes"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAllDeliveryNotes}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
          downloadLink={this.props.downloadDeliveryNotes}
        />
          <ReactTable
            PaginationComponent={Pagination}
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ""}
            style={{
              border: "none",
              boxShadow: "none",
              whiteSpace: "unset",
              height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={
              this.props.startPage ? this.props.startPage - 1 : this.state.page
            }
            onFetchData={(state, instance) => {
              const {
                agentMobile,
                // requestId,
                endDate,
                startDate,
              } = this.props.searchParameters;
              this.setState({ page: state.page });
              if (this.props.canLoadData) {
                this.props.getAllDeliveryNotes(
                  state.page + 1,
                  state.pageSize,
                  agentMobile,
                  // requestId,
                  endDate,
                  startDate
                );
              }
            }}
            headerNodeHeight={`123`}
            minRows={this.props.data.length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
              style: {
                borderColor: "#00425f",
                boxSizing: "none",
                border: "none",
              },
            })}
            getTdProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                lineHeight: "20px",
              },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
              style: {
                backgroundColor: "#00425f",
                borderBottom: "0.1px solid #DBDEDE",
                borderWidth: "0.1px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#FFFFFF",
                fontSize: "13px",
                fontWeight: "500",
                textAlign: "left",
              },
            })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
            className="-striped -highlight"
          />
       
      </React.Fragment>
    );
  }
}
