import React from 'react'

export default class LgaForm extends React.Component {
    render () {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                        <label>Name *:<br></br></label>
                        <input autoComplete="off" type="text" class="form-control" placeholder="Nigeria" onChange={event => this.props.onChange('name', event.target.value)}/>
                        </div>

                        <div class="form-group col-lg-6">
                        <label>Country*:</label>
                        <select autoComplete="off" class="form-control" data-live-search="true" onChange={(event) => this.props.onChange('country', event.target.value)}>
                            {this.props.countries.map((value, index) => <option value={value.id} >{value.name}</option>)}
                        </select>
                        </div>

                        <div class="form-group col-lg-6">
                        <label>State*:</label>
                        <select autoComplete="off" class="form-control" data-live-search="true" onChange={(event) => this.props.onChange('state', event.target.value)}>
                            {this.props.states.map((value, index) => <option value={value.id} >{value.name}</option>)}
                        </select>
                        </div>

                        <div class="form-group col-lg-6">
                        <label>Description *:</label>
                        <textarea autoComplete="off" class="form-control" style={{height: '100px', resize: 'none'}} maxLength="100" name="description" 
                            onChange={event => this.props.onChange('description', event.target.value)}
                        >
                        </textarea>
                        <span class="form-text text-muted">Maximum character 100</span>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}