import Requester from '../requester';
const API_BASE_URL = process.env.REACT_APP_PLATFORM_MANAGEMENT_API_BASE_URL;

export default class PlatformManagement {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
      new Requester({
        apiBaseUrl: API_BASE_URL,
      })
      : new Requester({
        apiBaseUrl: API_BASE_URL,
      });
  }

  getUserInformation() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/users/me`,
    });
  }

  getDomains(domainTypeId = null, pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agents`,
      args: {
        agentType: domainTypeId,
        pageNum,
        pageSize,
      },
    });
  }

  getPermissions(domainId = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/users/permissions`,
      args: {
        domainCode: domainId,
      },
    });
  }

  getRoles(domainId = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/users/roles`,
      args: {
        domainCode: domainId,
      },
    });
  }

  createRole(payload) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-platform-service/users/roles/create',
      body: payload,
    });
  }

  removeRolePermission(payload) {
    return this.apiRequester.put({
      endpoint: 'v2/finch-platform-service/users/roles/permission/delete',
      body: payload,
    });
  }

  getRolePermissions(domainCode, roleName) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/users/role/permissions`,
      args: {
        domainCode: domainCode,
        roleName: roleName,
      },
    });
  }

  createServiceProvider(payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/service-providers`,
      body: payload,
    });
  }

  addBusinessType(payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/businesstypes`,
      body: payload,
    });
  }

  getBusinessTypes() {
    const newEndpoint = process.env.REACT_APP_SHOW_BUSINESS_TIRE === 'true' ? `v5/finch-platform-service/businesstypes` : `v2/finch-platform-service/businesstypes`
    return this.apiRequester.get({
      endpoint: newEndpoint,
    });
  }


  getBusinessTypeByTier(tier_id) {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/businesstypes/${tier_id}`,
    });
  }

  deleteBusinessType(id, tier_id) {
    const newEndpoint = process.env.REACT_APP_SHOW_BUSINESS_TIRE === 'true' ? `v5/finch-platform-service/businesstypes/delete/${id}/${tier_id}` : `v2/finch-platform-service/businesstype/delete/${id}`
    return this.apiRequester.delete({
      endpoint: newEndpoint,
    });
  }

  editBusinessType(payload) {
    return this.apiRequester.put({
      endpoint: `v5/finch-platform-service/businesstypes/${payload.id}?tierId=${payload.tierId}&businessTypeName=${payload.businessTypeName}`
    });
  }

  getIdentityTypes() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/identitytype`,
    });
  }

  createIdentityType(payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/identitytype`,
      body: payload,
    });
  }

  deleteIdentityType(id) {
    return this.apiRequester.delete({
      endpoint: `v2/finch-platform-service/identitytype/delete/${id}`,
    });
  }

  createUser(payload) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-platform-service/users',
      body: payload,
    });
  }

  getUsers(domainId = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/users`,
      args: {
        domainCode: domainId,
      },
    });
  }

  getUserByEmail(email, domainCode = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/users/retrieve`,
      args: {
        email: email,
        domainCode,
      },
    });
  }

  updateUserRole(payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/users/roles/edit`,
      body: payload,
    });
  }

  getAgentClasses() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agent-classes`,
    });
  }

  getAgentClass(agentClassId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agent-classes/${agentClassId}`,
    });
  }

  createAgentClass(payload) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-platform-service/agent-classes',
      body: payload,
    });
  }

  updateAgentClass(agentClassId, payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agent-classes/${agentClassId}`,
      body: payload,
    });
  }

  deleteAgentClass(agentClassId) {
    return this.apiRequester.delete({
      endpoint: `v2/finch-platform-service/agent-classes/${agentClassId}`,
    });
  }

  updateAgentClassStatus(status, agentClassId) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agent-classes/status/${agentClassId}/${status}`,
    });
  }

  getSuperAgentList() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/super-agents/list`,
    });
  }

  getAgentByBusinessPhoneNo(agentMobileNo) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agents/phoneNo/${agentMobileNo}`,
      headers: {
        NoAuthorization: 'true',
      },
    });
  }

  getAgentByAgentCode(agentCode) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agents/agentCode/${agentCode}`,
    });
  }

  getAgentByStatus(agentStatus) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agents/status/${agentStatus}`,
    });
  }

  updateAgentStatus(status, agentId, comment = null) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agents/${status}/${agentId}`,
      body: {
        comment,
      },
    });
  }

  getCountries() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/countries`,
      headers: {
        'no-authorization': 'null',
      },
    });
  }

  getStatesByCountryId(countryId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/states/country/${countryId}`,
      headers: {
        'no-authorization': 'null',
      },
    });
  }

  getStates() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/states`,
      headers: {
        'no-authorization': 'null',
      },
    });
  }

  getLgasByStateId(stateId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/lgas/state/${stateId}`,
      headers: {
        'no-authorization': 'null',
      },
    });
  }

  getLgas() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/lgas`,
      args: {
        pageSize: 300,
      },
      headers: {
        'no-authorization': 'null',
      },
    });
  }

  getServiceProviders() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/service-providers`,
    });
  }

  exemptUsers(agentId, payload) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/kyc-management/skip-verification/${agentId}?skipCacTin=${payload}`,
    })
  }

  exemptUserFromList(agentId) {
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/kyc-management/skip-verification/${agentId}?skipCacTin=${false}`,
    })
  }

  updateServiceProvider(payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/service-providers`,
      body: payload,
    });
  }

  updateUserStatus(status, username, domainCode = null) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/users/${status}`,
      args: {
        username,
        domainCode,
      },
    });
  }

  getSuperAgents(pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/super-agents`,
      args: {
        pageNum,
        pageSize,
      },
    });
  }

  getUploadedDocuments(agentCode) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/documents/agent/${agentCode}`,
    });
  }

  createRegion(payload) {
    return this.apiRequester.post({
      endpoint: 'v2/finch-platform-service/regions',
      body: payload,
    });
  }

  getRegions(pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/regions`,
      args: {
        pageNum,
        pageSize,
      },
    });
  }

  getRegion(regionId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/regions/${regionId}`,
    });
  }

  updateRegion(payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/regions`,
      body: payload,
    });
  }

  updateRegionStatus(status, regionId) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/regions/enable_disable_region`,
      args: {
        status,
        regionId,
      },
    });
  }

  deleteRegion(regionId) {
    return this.apiRequester.delete({
      endpoint: `v2/finch-platform-service/regions/${regionId}`,
    });
  }

  mapRegionToLga(payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/regions/map_lgas_region`,
      body: payload,
    });
  }

  getMappedRegionLgas(regionId) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/regions/lgas_via_region`,
      args: {
        regionId,
      },
    });
  }

  getLgaZones(lgaCode) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/zones/zones_via_lga`,
      args: {
        lgaCode,
      },
    });
  }

  updateAgentDetails(agentCode, agentDetails) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agents/${agentCode}`,
      body: agentDetails,
    });
  }

  documentUpload(documentId, documentType, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/document/upload-temp`,
      headers: {
        documentId: documentId,
        documentType: documentType,
        'content-type': 'multipart/form-data',
        accept: '*/*',
      },
      body: formData,
    });
  }

  documentExemptionUpload(file) {
    const formData = new FormData();
    formData.append('file', file);
    console.log(file, 'platform upload');
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/kyc-management/skip-cac-verification/bulk`,
      body: formData,
    });
  }


  documentUploadNew(agentCode, locationId, documentType, file, documentName) {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/document/upload-new`,
      headers: {
        agentCode,
        locationId,
        documentType,
        documentName,
        'content-type': 'multipart/form-data',
        accept: '*/*',
      },
      body: formData,
    });
  }

  upgradeAgentClass(classUpgradeRequest) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agents/upgrade`,
      body: classUpgradeRequest,
    });
  }

  searchAgents(pageNum = null, pageSize = null, payload) {
    const {
      agentType,
      agentCode,
      agentMobileNo,
      businessName,
      status,
      startDate,
      endDate,
      parentId,
      walletVersion,
      bvn,
    } = payload;
    return this.apiRequester.get({
      endpoint: 'v2/finch-platform-service/agents/search',
      args: {
        pageNum,
        pageSize,
        ...(parentId !== null ? { parentId } : {}),
        ...(agentType !== null ? { agentType } : {}),
        ...(agentCode !== null ? { agentCode } : {}),
        ...(agentMobileNo !== null ? { agentMobileNo } : {}),
        ...(businessName !== null ? { businessName } : {}),
        ...(status !== 'All' ? { status } : {}),
        ...(walletVersion != null ? { walletVersion } : {}),
        ...(startDate !== null
          ? { startDate: startDate, endDate: endDate }
          : {}),
        ...(bvn !== null ? { bvn } : {}),
      },
    });
  }

  searchExcemptedAgents(pageNum = null, pageSize = null, payload) {
    const {
      agentType,
      agentCode,
      agentMobileNo,
      businessName,
      status,
      startDate,
      endDate,
      parentId,
      walletVersion,
      bvn,
    } = payload;
    return this.apiRequester.get({
      endpoint: 'v2/finch-platform-service/kyc-management/search',
      args: {
        pageNum,
        pageSize,
        ...(parentId !== null ? { parentId } : {}),
        ...(agentType !== null ? { agentType } : {}),
        ...(agentCode !== null ? { agentCode } : {}),
        ...(agentMobileNo !== null ? { agentMobileNo } : {}),
        ...(businessName !== null ? { businessName } : {}),
        ...(status !== 'All' ? { status } : {}),
        ...(walletVersion != null ? { walletVersion } : {}),
        ...(startDate !== null
          ? { startDate: startDate, endDate: endDate }
          : {}),
        ...(bvn !== null ? { bvn } : {}),
      },
    });
  }

  skipVerification(payload) {
    const { id } = payload;
    return this.apiRequester.post({
      endpoint: `v2/finch-platform-service/kyc-management/skip-verification/${id}`
    })
  }

  downloadAgentData(pageNum = null, pageSize = null, payload) {
    const {
      agentType,
      agentCode,
      agentMobileNo,
      businessName,
      status,
      startDate,
      endDate,
      parentId,
      walletVersion,
    } = payload;
    return this.apiRequester.get({
      endpoint: 'v2/finch-platform-service/agents/search/download',
      args: {
        pageNum,
        pageSize,
        ...(parentId !== null ? { parentId } : {}),
        ...(agentType !== null ? { agentType } : {}),
        ...(agentCode !== null ? { agentCode } : {}),
        ...(agentMobileNo !== null ? { agentMobileNo } : {}),
        ...(businessName !== null ? { businessName } : {}),
        ...(status !== 'All' ? { status } : {}),
        ...(walletVersion !== null ? { walletVersion } : {}),
        ...(startDate !== null
          ? { startDate: startDate, endDate: endDate }
          : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  downloadExemptedAgentData(pageNum = null, pageSize = null, payload) {
    const {
      agentType,
      agentCode,
      agentMobileNo,
      businessName,
      status,
      startDate,
      endDate,
      parentId,
      walletVersion,
    } = payload;
    return this.apiRequester.get({
      endpoint: 'v2/finch-platform-service/kyc-management/search/download',
      args: {
        pageNum,
        pageSize,
        ...(parentId !== null ? { parentId } : {}),
        ...(agentType !== null ? { agentType } : {}),
        ...(agentCode !== null ? { agentCode } : {}),
        ...(agentMobileNo !== null ? { agentMobileNo } : {}),
        ...(businessName !== null ? { businessName } : {}),
        ...(status !== 'All' ? { status } : {}),
        ...(walletVersion !== null ? { walletVersion } : {}),
        ...(startDate !== null
          ? { startDate: startDate, endDate: endDate }
          : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  getQueryBuilderRulesConfig() {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agents/jsonRuleConfig`,
    });
  }

  completeConfiguration(agentCode) {
    return this.apiRequester.put({
      endpoint: 'v2/finch-platform-service/wallet/retry-wallet-creation',
      args: {
        agentCode,
      },
    });
  }

  retrieveAgentLimits(businessPhoneNumber) {
    return this.apiRequester.get({
      endpoint: `v2/finch-platform-service/agent-classes/${businessPhoneNumber}/limit`,
    });
  }

  updateAgentLimits(businessPhoneNumber, payload) {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agent-classes/${businessPhoneNumber}/limit`,
      body: {
        accountNumber: businessPhoneNumber,
        ...payload,
      },
    });
  }

  updateAgentClassLimits() {
    return this.apiRequester.put({
      endpoint: `v2/finch-platform-service/agent-classes/limit`,
    });
  }

  searchAgentBVNDetails(pageNum = null, pageSize = null, payload) {
    const { agentMobileNumber, verificationStatus } = payload;

    return this.apiRequester.get({
      endpoint: 'v2/finch-platform-service/bvn-records/verification/report',
      args: {
        pageNum,
        pageSize,
        verificationStatus,
        agentMobileNumber,
      },
      headers: { Authorization: 'Authorization' },
    });
  }

  downloadAgentBVNData(pageNum = null, pageSize = null, payload) {
    const {
      agentType,
      agentCode,
      agentMobileNo,
      businessName,
      agentMobileNumber,
      verificationStatus,
      parentId,
    } = payload;
    return this.apiRequester.get({
      endpoint: 'v2/finch-platform-service/bvn-records/verification/report/download',
      args: {
        pageNum,
        pageSize,
        ...(parentId !== null ? { parentId } : {}),
        ...(agentType !== null ? { agentType } : {}),
        ...(agentCode !== null ? { agentCode } : {}),
        ...(agentMobileNo !== null ? { agentMobileNo } : {}),
        ...(businessName !== null ? { businessName } : {}),
        ...(verificationStatus !== null ? { verificationStatus } : {}),
        ...(agentMobileNumber !== null ? { agentMobileNumber } : {}),
      },
      headers: {
        download: true,
        Authorization: 'Authorization',
      },
    });
  }
  getDocTypes(pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `v5/finch-platform-service/doc-type`,
      args: {
        pageNum,
        pageSize,
      },
      headers: {
        Authorization: 'Authorization',
      },
    });
  }
  createDocType(payload) {
    return this.apiRequester.post({
      endpoint: `v5/finch-platform-service/doc-type`,
      body: payload,
    })
  }

  editDocType(id, payload) {
    return this.apiRequester.put({
      endpoint: `v5/finch-platform-service/doc-type/${id}`,
      body: payload,
    });
  }

  toggleDocType(id, status) {
    return this.apiRequester.put({
      endpoint: `v5/finch-platform-service/doc-type/toggle/${id}?status=${status}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

}
