import React from "react";
import { Modal } from "react-bootstrap";
import { AccessDenied } from "../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS
} from "../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE
} from "../../../../../../../constants/permissions.js";
import {
  posRequests,
  posRequestsAwaitingConfig
} from "../../../../../../../mixins/api";
import {
  deleteOtherInformation, retrieveOtherInformation
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom, formatBackEndDateTimeTo
} from "../../../../../../../utils/formatter";
import {
  getDateByType
} from "../../../../../../../utils/helper";
import PosRequestDetailsModal from "../../components/pos-request-detail-modal";
import PosRequestsSearch from "../../components/pos-requests-search";
import PosRequestsTable from "../../components/pos-requests-table";
import "./style.css";

export default class PosRequestsGridScene extends BaseScene {
  sceneName = "view-pos-requests";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      posRequestDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        approvalStatus: "",
        agentUsername: "",
      },
      // pages: 1,
      pageSize: 20,
      pageNumber: 1,
      tableMessage: "",
      configured: true,
      showConfirm: false,
      cancelReason: "",
    };

    this.getAllPosRequests = this.getAllPosRequests.bind(this);
    this.getPosRequestDetails = this.getPosRequestDetails.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.cancelPosRequestByStockId = this.cancelPosRequestByStockId.bind(this);
    this.confirmConfigure = this.confirmConfigure.bind(this);
    this.handleNoConfirmationModal = this.handleNoConfirmationModal.bind(this);
  }

  componentDidMount() {
    this.getAllPosRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 8000);
    }
  }

  handleBackendResponse(response) {
    if (response.content === null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllPosRequests(pageNumber, pageSize) {
    const {
      agentUsername,
      toDate,
      fromDate,
      approvalStatus,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDate =
      toDate && `${formatBackEndDateTimeTo(toDate, true)}.747`;
    const formattedStartDate =
      fromDate && `${formatBackEndDateTimeFrom(fromDate, true)}.747`;
    console.log(formattedEndDate, "and", formattedStartDate);

    this.setState({
      pageNumber,
      pageSize,
      isLoading: true,
    });

    const { status, response, count } = await posRequests.getRequestsBySearch(
      pageNumber,
      pageSize,
      agentUsername,
      formattedEndDate,
      formattedStartDate,
      approvalStatus
    );
    if (status === SUCCESS_STATUS) {
      console.log(" POS REQUESTS RETRIEVED SUCCESSFULLY");
      this.setState({
        requests: response ? response.data : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        tableMessage: !count || count == 0 ? "No POS stock found" : "",
      });
    } else {
      this.setState({
        requests: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        isLoading: false,
        tableMessage: "No POS Request found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async getPosRequestDetails(id) {
    // this.setState({
    //   isLoading: true,
    // });

    this.setState({
      posRequestDetails: [],
    });

    const responseObj = await posRequests.getPosRequestsByRequestid(id);
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      console.log("GETTNG REQUEST BY ID TO TOGGLE MODAL");
      console.log(response);
      this.setState({
        posRequestDetails: response ? response.data : "",
      });
    } else {
      this.setState({
        posRequestDetails: [],
      });
    }
  }

  async cancelPosRequestByStockId(posRequestStockId, cancelReason) {
    console.log(posRequestStockId, "STOCKID LEVEL 1");

    this.setState({
      configured: false,
      showConfirm: false,
    });
    const responseObj = await posRequestsAwaitingConfig.cancelPosRequestByStockId(
      posRequestStockId, cancelReason
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      console.log(response.data, "POS request cancelled Successfully");

      this.setState({
        configured: true,
        posRequestAwaitingConfigDetails: response.data,
        successMessage: `POS request cancelled Successfully`,
      });
      this.getAllPosRequests();
      localStorage.removeItem(
        "cancelId"
      )
    } else {
      this.setState({
        configured: true,
        posRequestAwaitingConfigDetails: [],
        errorMessage: response.message,
      });
    }
  }

  handleSearchRequest() {
    
    this.getAllPosRequests(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType == "view") {
      this.getPosRequestDetails(requests.id);
      this.setState({
        requestDetails: requests,
        settlement: {
          parties: [],
        },
      });
    }
    this.setState({
      requestId: requests.requestId,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType == "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }
  confirmConfigure() {
    console.log("PROMPT MODAL");
    this.setState({
      showConfirm: true,
    });
  }

  handleNoConfirmationModal() {
    this.setState({
      showConfirm: false,
    });
    // this.getAllPosRequests();
  }

  render() {
    //  const confirmationResponseMsgModal=  <Modal  style={{borderRadius: '10px', transition: 'all .2s ease-in-out'}} animation="true" keyboard="true" size="sm" show={this.state.showConfirmationResponseMsgModal} centered>

    //                                           <Modal.Body style={{margin: '10px', padding: '15px'}}>

    //                                               <center>
    //                                                   <Text style={{fontSize: process.env.REACT_APP_BIG_FONT_SIZE, lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT, margin: '10px'}}>{this.state.confirmationResponseMsg}</Text>
    //                                               </center>
    //                                           </Modal.Body>
    //                                       </Modal>

    const confirmationModal = (
      <Modal
        animation="true"
        style={{ borderRadius: "10px", transition: "all .2s ease-in-out" }}
        size="sm"
        show={this.state.showConfirm}
        centered
      >
        <Modal.Body
          style={{ margin: "10px", height: "300px", padding: "15px" }}
        >
          <h4
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              margin: "0px",
              padding: "0px",
            }}
          >
            cancel Request?
          </h4>

          <center>
            <p
              style={{
                fontSize: "15px",
                lineHeight: process.env.REACT_APP_MID_BIG_LINE_HEIGHT,
                margin: "10px",
              }}
            >
              Are you sure you want to cancel this POS Request?
            </p>
              <br />
              <br />
              <div class="col-md-12">
                <textarea
                  class="form-control"
                  style={{
                    resize: "none",
                    marginTop: "15px",
                  }}
                  placeholder="Enter reasons for cancellation"
                  name="txtname"
                  rows="4"
                  cols="50"
                  maxlength="200"
                  onChange={(event) =>
                    this.setState({
                      cancelReason: event.target.value,
                    })
                  }
                />
              </div>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <button
          disabled={!this.state.cancelReason}
            type="button"
            class="btn btn-primary col-lg-6"
            style={{ backgroundColor: "#004257" }}
            onClick={() => {
              this.cancelPosRequestByStockId(cancelId, this.state.cancelReason);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn-primary col-lg-6"
            style={{
              border: "1px solid #00425f",
              backgroundColor: "#ffffff",
              color: "#004257",
            }}
            onClick={this.handleNoConfirmationModal}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    );
    

    let cancelId = localStorage.getItem("cancelId");
    cancelId = JSON.parse(cancelId);

    return (
      <React.Fragment>
        {/* <ContentHead
          title={"View POS Requests"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        {this.state.configured ? (
          <>
            <Notification
              ref={this.notificationSectionRef}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
            <ProtectedComponent
              // requiredPermissions={[CAN_VIEW_TRANSACTION]}
              requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
              permissionDeniedContent={this.permissionDeniedContent}
            >
              <GridLayout>
                <PosRequestsSearch
                  searchParameters={this.state.searchParameters}
                  agentName={this.state.requests.agentName}
                  updateSearchParameters={this.updateSearchParameters}
                  handleSearchRequest={this.handleSearchRequest}
                  preloadDate={
                    retrieveOtherInformation("parentReference")
                      ? false
                      : !JSON.parse(
                          process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA
                        )
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                {this.state.showConfirm && confirmationModal}
                <PosRequestsTable
                  pages={this.state.pages}
                  getPosRequests={this.getAllPosRequests}
                  data={this.state.requests}
                  startPage={this.state.startPage}
                  viewMore={this.getPosRequestDetails}
                  loading={this.state.isLoading}
                  noDataText={this.state.tableMessage}
                  dataCount={this.state.dataCount}
                  page={this.state.pageNum}
                  pageSize={this.state.pageSize}
                  canRemapDevice={CAN_REMAP_DEVICE}
                  searchParameters={this.state.searchParameters}
                  responseCode={this.state.searchParameters.responseCode}
                  startDate={this.state.searchParameters.startDate}
                  canView={CAN_GET_DEVICES_BY_UUID}
                  getClickedAction={this.getClickedAction}
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                  canLoadData={this.state.canLoadData}
                />
                <PosRequestDetailsModal
                  posRequestDetails={this.state.posRequestDetails}
                  loading={this.state.isLoading}
                  confirmConfigure={this.confirmConfigure}
                  // cancelPosRequest={this.cancelPosRequestByStockId}
                />
                
              </GridLayout>
            </ProtectedComponent>
          </>
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
