import React from "react";



export default class PosRequestsSAwaitingConfigDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {
    console.log(
      "Pos Requests Awaiting Config Modal ",
      this.props.posRequestAwaitingConfigDetails
    );
    const {
      appName,
      appVersion,
      bankAccountNo,
      bankAccountType,
      bankCode,
      batchId,
      businessOccupationCode,
      configuredBy,
      contactName,
      contactTitle,
      dateConfigured,
      dateUploaded,
      deviceName,
      deviceSN,
      deviceType,
      email,
      emailAlerts,
      id,
      lga,
      mastercardAcquirerIdNo,
      merchantAccountName,
      merchantCategoryCode,
      merchantId,
      merchantName,
      mobilePhone,
      networkType,
      physicalAddr,
      posRequestStockId,
      postCode,
      ptsp,
      // slipFooter,
      // slipHeader,
      stateCode,
      status,
      terminalId,
      terminalModelCode,
      terminalOwnerCode,
      url,
      verveAcquirerIdNo,
      visaAcquirerIdNo,
   } = this.props.posRequestAwaitingConfigDetails;

    return (
      <React.Fragment>
        <div
          class="modal"
          id="pos_awaiting_config_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            // class="modal-dialog"
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  POS Request Awaiting Configuration Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Request Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>App Name:</strong>
                          </label>
                          <br />
                          <span>
                            {appName ? appName : ""}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>App Version</strong>
                          </label>
                          <br />
                          <span>{appVersion ? appVersion : ""}</span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Bank Account Number</strong>
                          </label>
                          <br />
                          <span>
                            {bankAccountNo ? bankAccountNo : ""}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Account Type</strong>
                          </label>
                          <br />
                          <span>
                            {bankAccountType ? bankAccountType : ""}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Bank Code</strong>
                          </label>
                          <br />
                          <span>
                            {bankCode ? bankCode : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Batch ID</strong>
                          </label>
                          <br />
                          {batchId ? batchId : ""}
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Occupation Code</strong>
                          </label>
                          <br />
                          {businessOccupationCode
                            ? businessOccupationCode
                            : ""}
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Configured By</strong>
                          </label>
                          <br />
                          <span>
                            {configuredBy ? configuredBy : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Contact Title</strong>
                          </label>
                          <br />
                          <span>
                            {contactTitle ? contactTitle : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Contact Name</strong>
                          </label>
                          <br />
                          <span>
                            {contactName ? contactName : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Date Configured</strong>
                          </label>
                          <br />
                          <span>
                            {dateConfigured ? dateConfigured : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Date Uploaded</strong>
                          </label>
                          <br />
                          <span>
                            {dateUploaded ? dateUploaded : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Device Name</strong>
                          </label>
                          <br />
                          <span>
                            {deviceName ? deviceName : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Device S/N</strong>
                          </label>
                          <br />
                          <span>
                            {deviceSN ? deviceSN : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Device Type</strong>
                          </label>
                          <br />
                          <span>
                            {deviceType ? deviceType : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Email</strong>
                          </label>
                          <br />
                          <span>
                            {email ? email : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Email Alerts</strong>
                          </label>
                          <br />
                          <span>
                            {emailAlerts ? emailAlerts : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        
                        <div class="col-lg-6">
                          <label>
                            <strong>L.G.A</strong>
                          </label>
                          <br />
                          <span>
                            {lga ? lga : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Mastercard Acquirer ID No.</strong>
                          </label>
                          <br />
                          <span>
                            {mastercardAcquirerIdNo ? mastercardAcquirerIdNo : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Merchant Account Name</strong>
                          </label>
                          <br />
                          <span>
                            {merchantAccountName ? merchantAccountName : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Merchant Category Code</strong>
                          </label>
                          <br />
                          <span>
                            {merchantCategoryCode ? merchantCategoryCode : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Merchant ID</strong>
                          </label>
                          <br />
                          <span>
                            {merchantId ? merchantId : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Merchant Name</strong>
                          </label>
                          <br />
                          <span>
                            {merchantName ? merchantName : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Mobile Number</strong>
                          </label>
                          <br />
                          <span>
                            {mobilePhone ? mobilePhone : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Network type</strong>
                          </label>
                          <br />
                          <span>
                            {networkType ? networkType : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Address</strong>
                          </label>
                          <br />
                          <span>
                            {physicalAddr ? physicalAddr : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Request Stock ID</strong>
                          </label>
                          <br />
                          <span>
                            {posRequestStockId ? posRequestStockId : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Post Code</strong>
                          </label>
                          <br />
                          <span>
                            {postCode ? postCode : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>PTSP</strong>
                          </label>
                          <br />
                          <span>
                            {ptsp ? ptsp : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>State Code</strong>
                          </label>
                          <br />
                          <span>
                            {stateCode ? stateCode : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <br />
                          <span>
                            {status ? status : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal ID</strong>
                          </label>
                          <br />
                          <span>
                            {terminalId ? terminalId : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Model Code</strong>
                          </label>
                          <br />
                          <span>
                            {terminalModelCode ? terminalModelCode : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Owner Code</strong>
                          </label>
                          <br />
                          <span>
                            {terminalOwnerCode ? terminalOwnerCode : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Url</strong>
                          </label>
                          <br />
                          <span>
                            {url ? url : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Verve Acquirer ID No.</strong>
                          </label>
                          <br />
                          <span>
                            {verveAcquirerIdNo ? verveAcquirerIdNo : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Visa Acquirer ID No.</strong>
                          </label>
                          <br />
                          <span>
                            {visaAcquirerIdNo ? visaAcquirerIdNo : ""}
                          </span>
                          <br />
                          <br />
                        </div>

                       
                      </div>
                    </div>
                  </div>
                </form>

               
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  // class="btn btn-default"
                  class="btn col-lg-4"
                  // class="btn btn-primary col-lg-6"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "inline-block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              {/* </div> */}
              {/* <div class="modal-footer"> */}
              {status === "awaiting_configuration" ?
                <button
                  type="button"
                  // class="btn btn-default"
                  class="btn col-lg-4"
                  // class="btn btn-primary col-lg-6"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "inline-block",
                  }}
                  data-dismiss="modal"
                  onClick={() => {this.props.configure(id)}}
                >
                  Configure
                </button>
                : "" }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
