import React from "react";
import BaseScene from "../../../../../components/scenes/component.js";
import DeviceGridScene from "../../platform-management/pos-management2/pos-remap/scenes/device-grid/scene.js";
import RemapDeviceScene from "../../platform-management/pos-management2/pos-remap/scenes/remap-device/scene.js";
// import UploadQuickStockGridScene from "../quick-stocks/scenes/quick-upload-stock-grid/scene";
import QuickStockGridScene from "../../pos-management/quick-stocks/scenes/quick-grid";
import UploadQuickStockGridScene from "../../pos-management/quick-stocks/scenes/quick-upload-stock-grid";
export default class DeviceManagementScene extends BaseScene {
  sceneName = "pos-remap";

  // componentDidMount() {
  //   this.props.updateScene(this.sceneName);
  // }
  // render() {
  //   const ProtectedRoute = ({
  //     component: Component,
  //     requiredPermissions,
  //     ...rest
  //   }) => {
  //     return (
  //       <Route
  //         {...rest}
  //         render={(props) =>
  //           isAuthorized(requiredPermissions) ? (
  //             <Component {...props} />
  //           ) : (
  //             (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
  //           )
  //         }
  //       />
  //     );
  //   };
  //   return (
  //     <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
  //       <ProtectedRoute
  //         requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
  //         exact
  //         path="/home/pos-management/devices"
  //         component={DeviceGridScene}
  //       />
  //       <ProtectedRoute
  //         requiredPermissions={[CAN_REMAP_DEVICE]}
  //         exact
  //         path="/home/pos-management/devices/:id/remap"
  //         component={RemapDeviceScene}
  //       />
  //       <ProtectedRoute
  //         requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
  //         exact
  //         path="/home/pos-management/quick-stocks"
  //         component={QuickStockGridScene}
  //       />
  //       <ProtectedRoute
  //         requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
  //         exact
  //         path="/home/pos-management/quick-upload-stocks"
  //         component={UploadQuickStockGridScene}
  //       />
  //     </Router>
  //   );
  // }
  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };
  }
  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };
  render() {
    return (
      <>
        <div className="col-md-12" style={{ marginTop: "-80px" }}>
          <section id="open-positions">
            <div className="positions">
              <div className="position-filters">
                <label
                  className={
                    this.state.toggleState === 1
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All Devices
                </label>
                <label
                  className={
                    this.state.toggleState === 2
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Pos Config
                </label>
                {/* <label
                  className={
                    this.state.toggleState === 3
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(3)}
                >
                  Device Remap
                </label> */}
                <label
                  className={
                    this.state.toggleState === 4
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(4)}
                >
                  Upload Pos Config
                </label>
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <DeviceGridScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 2
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <QuickStockGridScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 3
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <RemapDeviceScene />
                </div>
              </div>
              <div
                className={
                  this.state.toggleState === 4
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <UploadQuickStockGridScene />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
