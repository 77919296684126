import React from "react";

export const LogoutModal = (props) => {
  const {
    modalTitle,
    modalBody,
    deleteModal,
    modalSize,
    hideModalHeader,
    hideModalFooter,
    height,
    modalFooterButton1,
    modalFooterButton2,
  } = props;
  return (
    <React.Fragment>
      <div
        class="modal"
        id="kt_modal_1_logout"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class={`modal-dialog ${modalSize}`} role="document">
          <div
            class="modal-content"
            style={{ borderRadius: "15px", top: "120px" }}
          >
            <div
              class="modal-body fluid"
              style={{
                textAlign: "center",
                fontSize: "15px",
                height: height ? height : "100px",
                overflow: "hidden"
              }}
            >
              {hideModalHeader && (
                <h4 style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                  {modalTitle}
                </h4>
              )}
              {modalBody}
              {hideModalFooter && (
                <div
                  class="modal-footer row"
                  style={{
                    paddingTop: "35px",
                    borderTop: "0 none",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      borderColor: "#F1F1F2 ",
                      backgroundColor: "#F1F1F2 ",
                      color: "#000000",
                      marginRight: "10px",
                      fontSize: "14px",
                      margin: "auto",
                      display: "block",
                    }}
                    class="btn btn-secondary btn-bold btn-sm btn-icon-h"
                    id="kt_subheader_group_actions_fetch"
                    data-dismiss="modal"
                  >
                    {modalFooterButton1}
                  </button>
                  <button
                    style={{
                      height: "40px",
                      width: "100px",
                      padding: "0px",
                      borderRadius: "15px",
                      borderColor: "#00425f",
                      backgroundColor: "#00425f",
                      color: "#ffffff",
                      fontSize: "14px",
                      margin: "auto",
                      display: "block",
                    }}
                    class="btn btn-primary btn-bold btn-sm btn-icon-h"
                    id="kt_subheader_group_actions_fetch"
                    data-dismiss="modal"
                    onClick={deleteModal}
                  >
                    {modalFooterButton2}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
