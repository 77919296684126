import React from 'react'

export default class WorkflowInfo extends React.Component {
    render () {
        return <React.Fragment>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-2">Name:</label>
                <div class="col-lg-10">
                    <span>{this.props.workflowDetails.name}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-2">Action Type:</label>
                <div class="col-lg-10">
                    <span>{this.props.workflowDetails.actionType}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-lg-2">Status:</label>
                <div class="col-lg-10">
                    <span>{this.props.workflowDetails.active == true ? 'Active' : this.props.workflowDetails.active == false ? 'Inactive' : ''}</span>
                </div>
            </div>
        </React.Fragment>
    }
}