import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import BaseScene from '../../../../components/scenes/component'
import PermissionsGridScene from './scenes/permissions-grid'
import AddPermissionScene from './scenes/add-permission'
import ViewPermissionScene from './scenes/view-permission'
import { isAuthorized } from '../../../../mixins/authentication'
import { CAN_VIEW_PERMISSION } from '../../../../constants/permissions.js'

export default class PermissionManagementScene extends BaseScene {
  sceneName = 'permission-management'

  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION]} exact path='/home/permission-management' component={PermissionsGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION]} exact path='/home/permission-management/add' component={AddPermissionScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_PERMISSION]} exact path='/home/permission-management/:id/view' component={ViewPermissionScene} />
    </Router>
  }
}
