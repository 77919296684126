import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ServiceTypesGridScene from './scenes/service-types-grid';
import BaseScene from '../../../../../components/scenes/component';
import AddServiceTypeScene from './scenes/add-service-type';
import ViewServiceTypeScene from './scenes/view-service-type';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_SERVICE_TYPE } from '../../../../../constants/permissions.js'

export default class ServiceTypesScene extends BaseScene {
  sceneName = 'service-types-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_TYPE]} exact path='/home/service-types-management' component={ServiceTypesGridScene} />
      {/* <ProtectedRoute requiredPermissions={[CAN_CREATE_SERVICE_TYPE]} exact path='/home/service-types-management/add' component={AddServiceTypeScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_TYPE]} exact path='/home/service-types-management/:id/view' component={ViewServiceTypeScene} />
    </Router>
  }
}
