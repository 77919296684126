import qs from "querystring";
import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../../components/access-denied";
import RoleForm from "../../../../../../../../components/forms/role-form";
import { GridLayout } from "../../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../../components/loader";
import Notification from "../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../../constants/api";
import {
  CAN_CREATE_AGENT_ROLE,
  CAN_CREATE_AGGREGATOR_ROLE,
  CAN_CREATE_SP_ROLE,
} from "../../../../../../../../constants/permissions";
import { platformManagement } from "../../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";

export default class DomainRoleAddScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      roleFormData: [],
      roleDetails: [],
    };
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.updateRoleFormData = this.updateRoleFormData.bind(this);
  }

  componentDidMount() {
    this.roleId = qs.parse(window.location.search)["?roleId"];
    this.domainCode = this.props.match.params.domainCode;
    this.updateRoleFormData("domainCode", this.domainCode);
    this.getPermissions();
    if (this.roleId) {
      this.getRoles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getRoles() {
    const responseObj = await platformManagement.getRoles(this.domainCode);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      response.map((details) => {
        if (this.roleId == details.id) {
          this.getRolePermissions(details.name);
        }
      });
    } else {
      this.setState({
        roleFormData: [],
      });
    }
  }

  async getRolePermissions(roleName) {
    const responseObj = await platformManagement.getRolePermissions(
      this.domainCode,
      roleName
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        roleFormData: {
          ...this.state.roleFormData,
          permissions: this.getPermissionId(response),
          name: roleName,
        },
      });
    } else {
      this.setState({
        roleFormData: [],
      });
    }
  }

  async getPermissions() {
    const responseObj = await platformManagement.getPermissions(
      this.domainCode
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissions: response,
      });
    } else {
      this.setState({
        permissions: [],
      });
    }
  }

  getPermissionId(permissions) {
    let permissionArray = [];
    permissions.map((details) => {
      permissionArray.push(details.id);
    });
    return permissionArray;
  }

  toJSON(array) {
    let newArray = [];
    array.map((value) => {
      let myArray = {};
      myArray["id"] = value;
      newArray.push(myArray);
    });

    return newArray;
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const createRoleResponseObj = await platformManagement.createRole({
      name: this.state.roleFormData.name,
      domainCode: this.state.roleFormData.domainCode,
      description: this.state.roleFormData.description,
      permissions: this.toJSON(this.state.roleFormData.permissions),
    });

    const createRoleResponseStatus = createRoleResponseObj.status;
    const createRoleResponse = createRoleResponseObj.response;
    if (createRoleResponseStatus === SUCCESS_STATUS) {
      if (createRoleResponse.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new role has been sent successfully for approval.",
        });
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Role created successfully",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: createRoleResponse,
      });
    }
  }

  isFormValid() {
    return Boolean(
      !this.state.roleFormData.name || !this.state.roleFormData.permissions
    );
  }

  updateRoleFormData(type, value) {
    this.setState({
      roleFormData: { ...this.state.roleFormData, [type]: value },
    });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to={`/home/domain-management/${this.domainCode}/roles`} />
      );
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent
          requiredPermissions={[
            CAN_CREATE_AGENT_ROLE,
            CAN_CREATE_AGGREGATOR_ROLE,
            CAN_CREATE_SP_ROLE,
          ]}
        >
          <button
            type="button"
            disabled={this.isFormValid()}
            class="btn btn-primary btn-sm"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ProtectedComponent
          requiredPermissions={[
            CAN_CREATE_AGENT_ROLE,
            CAN_CREATE_AGGREGATOR_ROLE,
            CAN_CREATE_SP_ROLE,
          ]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <RoleForm
              allPermissions={this.state.permissions}
              onChange={this.updateRoleFormData}
              roleFormData={this.state.roleFormData}
              roleDetails={this.state.roleDetails}
              title={`Role Management`}
              submitButton={ctaWidgets}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
