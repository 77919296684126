import React from "react";
import FeeSplits from "../../../../../../components/fee-splits";
import {
  formatTransactionStatus
} from "../../../../../../utils/formatter";

const FeeSplit = (props) => {
  if (
    formatTransactionStatus(
      props.transactionDetails.statusCode
        ? props.transactionDetails.statusCode
        : ""
    ) == "Successful" &&
    props.transactionDetails.transactionTypeInt < 10
  ) {
    return (
      <div class="form-group row">
        <FeeSplits feeSplits={props.parties} isLoading={props.isLoading} />
      </div>
    );
  } else return null;
};

export default class StockDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {
    
    const resul = this.props.stockDetails ? this.props.stockDetails : "";

    return (
      <React.Fragment>
        <div
          class="modal"
          id="stock_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Upload Stock Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Stock Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Batch ID</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.batchId : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Date Uploaded</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.dateUploaded : ""}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>I.D</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.id : ""}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Serial No.</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.serialNo : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Stock Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.stockStatus : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Model</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.terminalModel : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Name</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.terminalName : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Type</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.terminalType : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Uploaded By</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.uploadedBy : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
