import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_REMAP_DEVICE } from '../../../../../../../constants/permissions.js';
import { userManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
// import DeviceTable from '../../components/stock-table'
// import DeviceSearch from '../../components/stock-search'
import {
  formatBackEndDateTime,
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import UploadStockSearch from '../../components/upload-stock-search';
import UploadStockTable from '../../components/upload-stock-table';

export default class StocksUploadDetailsGridScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {
        deviceType: 'All',
        fromDate: getDateByType('startDate'),
        toDate: getDateByType('endDate'),
        documentStatus: 'All',
        pageNum: 1,
        pageSize: 20,
      },
      tableMessage: '',
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      stockDisable: false,
      configDisable: false,
      stockFile: [],
      configFile: [],
      stockError: '',
      configError: '',
      redirect: false,
    };
    // this.uploadConfigFile = this.uploadConfigFile.bind(this);
    // this.uploadStockFile = this.uploadStockFile.bind(this);
    // this.readUploadedFile = this.readUploadedFile.bind(this);
    this.getStocks = this.getStocks.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.downloadStocks = this.downloadStocks.bind(this);
  }

  sceneName = 'stocks-upload-details';

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }
  // readUploadedFile(event, type) {
  //   console.log("type passed", type);
  //   const document = {
  //     file: event.target.files[0],
  //     filename: event.target.files[0].name,
  //     url: URL.createObjectURL(event.target.files[0]),
  //   };

  //   if (
  //     document.file.type === "application/vnd.ms-excel" ||
  //     document.file.type === "text/csv"
  //   ) {
  //     this.setState({
  //       [`${type}File`]: document,
  //       [`${type}Disable`]: false,
  //       [`${type}Error`]: "",
  //     });
  //   } else {
  //     this.setState({
  //       [`${type}Error`]: "Invalid file, upload a csv file",
  //       [`${type}Disable`]: false,
  //     });
  //   }
  // }

  // async uploadStockFile() {
  //   this.setState({
  //     isLoading: true,
  //     stockDisable: true,
  //   });
  //   const responseObj = await userManagement.uploadStock(this.state.stockFile);
  //   const { status, response } = responseObj;

  //   if (status === SUCCESS_STATUS) {
  //     if (response.code === ACCEPTED_REQUEST) {
  //       this.setState({
  //         successMessage: `Your stock upload processing has been sent successfully for approval`,
  //         isLoading: false,
  //         stockDisable: false,
  //         redirect: false,
  //       });
  //     } else {
  //       this.setState({
  //         successMessage: `Your stock upload processing was successful.`,
  //         isLoading: false,
  //         redirect: true,
  //         stockDisable: false,
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       errorMessage: response,
  //       isLoading: false,
  //       stockDisable: false,
  //     });
  //   }
  // }

  // async uploadConfigFile() {
  //   this.setState({
  //     isLoading: true,
  //     configDisable: true,
  //   });
  //   const responseObj = await userManagement.uploadConfig(
  //     this.state.configFile
  //   );
  //   const { status, response } = responseObj;

  //   if (status === SUCCESS_STATUS) {
  //     if (response.code === ACCEPTED_REQUEST) {
  //       this.setState({
  //         successMessage: `Your configuration upload processing has been sent successfully for approval`,
  //         isLoading: false,
  //         configDisable: false,
  //         redirect: false,
  //       });
  //     } else {
  //       this.setState({
  //         successMessage: `Your configuration upload processing was successful.`,
  //         isLoading: false,
  //         redirect: true,
  //         configDisable: false,
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       errorMessage: response,
  //       isLoading: false,
  //       configDisable: false,
  //     });
  //   }
  // }

  // ==============GRID TABLE FUNCTIONS=============================
  async getStocks(pageNum, pageSize) {
    console.log('pagination details ', pageNum, pageSize);
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.documentStatus =
      searchParameters.documentStatus === 'All'
        ? null
        : searchParameters.documentStatus;
    searchParameters.documentStatus =
      searchParameters.documentStatus === 'All'
        ? null
        : searchParameters.documentStatus;

    searchParameters.documentType = 'STOCK';
    // searchParameters.deviceType =
    //   searchParameters.deviceType === "All"
    //     ? null
    //     : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTimeFrom(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTimeTo(searchParameters.toDate, true)}.747`;
    console.log('from the main scene ', searchParameters);
    const responseObj = await userManagement.getStocksUploadrequest(
      searchParameters
    );
    const { status, response, count } = responseObj;
    console.log('response is ', response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        stocks: response.data,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: !count || count == 0 ? 'No POS stock found' : '',
      });
    } else {
      this.setState({
        stocks: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 2,
        isLoading: false,
        errorMessage: response,
        tableMessage: 'No POS stock found',
      });
    }
  }

  async downloadStocks() {
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = this.state.page;
    searchParameters.pageSize = this.state.dataCount;
    searchParameters.stockStatus =
      searchParameters.stockStatus === 'All'
        ? null
        : searchParameters.stockStatus;
    searchParameters.deviceType =
      searchParameters.deviceType === 'All'
        ? null
        : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTime(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTime(searchParameters.toDate, true)}.747`;
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await userManagement.downloadStocks(searchParameters);
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], { type: 'contentType' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('download', 'pos_stocks.csv');
      a.setAttribute('href', window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  handleSearchRequest(searchParameters) {
    this.setState({ loading: true });
    this.setState({ pageIndex: 1 });
    this.setState({ startPage: 1 });
    this.setState({
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getStocks(1, 20);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        {/* <ContentHead
          title={"POS Management"}
          subtitle={"POS Stocks Upload"}
          filterPermissions={[CAN_REMAP_DEVICE]}
          totalCount={56}
        /> */}
        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <UploadStockSearch
              handleSearchRequest={this.handleSearchRequest}
              searchParameters={this.state.searchParameters}
            />
            <UploadStockTable
              data={this.state.stocks}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getStocks={this.getStocks}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canRemapDevice={CAN_REMAP_DEVICE}
              canLoadData={this.state.canLoadData}
              downloadStocks={this.downloadStocks}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
