import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { AgentBVNDetailsExportSerializer } from '../../../../../../serializers';
import { getPaginationRows } from '../../../../../../utils/helper';

export default class AgentBVNTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus'
  }

  zoomTableHeight = this.zoomTableHeight.bind(this)
  getSerialNumber = this.getSerialNumber.bind(this)

  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }

  getSerialNumber (index) {
    return (this.props.pageSize * (this.props.page - 1)) + index
  }

  render() {
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
      {
        Header: "S/N",
        accessor: 'id',
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: 'center',
              whiteSpace: 'unset',
            },
          };
        },
      },
      {
        Header: "Agent Code",
        accessor: 'agentCode',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 120,
      },
      {
        Header: "Business Name",
        accessor: 'businessName',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width:200,
        Cell: props => <span class="word-capitalize ellipse" style={{width: '150px'}}>{props.value !== undefined && props.value !== ""?    props.value.toLowerCase()  : ''}</span>
      },
      {
        Header: "First Name",
        accessor: 'firstname',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width:200,
        Cell: props => <span class="word-capitalize ellipse" style={{width: '150px'}}>{props.value !== undefined && props.value !== ""?    props.value.toLowerCase()  : ''}</span>
      },
      {
        Header: "Last Name",
        accessor: 'lastname',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width:200,
        Cell: props => <span class="word-capitalize ellipse" style={{width: '150px'}}>{props.value !== undefined && props.value !== ""?    props.value.toLowerCase()  : ''}</span>
      },
      {
        Header: "Phone Number",
        accessor: 'phoneNumber',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width:200,
      },
      {
        Header: "Email",
        accessor: 'email',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 300,
      },
      {
        Header: "State",
        accessor: 'state',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 150,
      },
      {
        Header: "Status",
        accessor: 'bvnVerificationStatus',
        resizable: false,
        width: 200,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: 'center'
            },
          };
        },
        Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge ${props.value === 'NOT_VERIFIED' || props.value === 'VERIFIED_PARTIALLY' || props.value === 'PENDING_VERIFICATION' ? 'kt-badge--primary' : props.value === 'VERIFIED' ? 'kt-badge--success' : props.value === 'VERIFICATION_FAILED' ? 'kt-badge--warning' : props.value === 'SUSPENDED' ? 'kt-badge--danger':''} kt-badge--inline kt-badge--pill`}>{props.value}</span>
      }
    ]

    return <React.Fragment>
      <TableExtra 
        data={AgentBVNDetailsExportSerializer.reverseAgentDetails(this.props.data)}
        headers={AgentBVNDetailsExportSerializer.headerExport()}
        fileName={'agentBvn'}
        zoomTableHeight={this.zoomTableHeight}
        getData={this.props.getAgentBVNDetails}
        zoom={this.state.zoom}
        page={this.props.page}
        pageSize={this.props.pageSize}
        downloadLink={this.props.downloadAgentBVNData}
        disabled={this.props.loading}
      />
      <ReactTable 
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: 'unset',
            height: this.state.tableHeight,
            display:'block'
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ''}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
          minRows={(this.props.data).length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({page: state.page})
            if(this.props.canLoadData) {
              this.props.getAgentBVNDetails(state.page + 1,state.pageSize) 
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display:"flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft:"5px",
              paddingRight:"5px",
              alignItems: "center",
              
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"5px",
              paddingRight:"5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
            },
          })}    
          className="-striped -highlight" 
      />
    </React.Fragment>     
  }
}