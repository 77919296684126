import React from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseScene from "../../../../../components/scenes/component";
import RemapPosAllGridScene from "./scenes/remap-pos-all";
import RemapPosHistory from "./scenes/remap-pos-history/scenes/remap-pos-history-grid";
// import RemapPos from "./scenes/remap-pos/scenes/remap-pos-grid";

export default class RemapManagementScene extends BaseScene {
  sceneName = "remap-manage";

  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };

  }

  componentDidMount() {
    // this.getAllRemapRequests();
    console.log("HERE I AM")
  }


  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };
  
  render() {
    
    return (
      <>
        <div className="col-md-12" style={{marginTop:"-80px"}}>
          <section id="open-positions">
            <div className="positions">
              <div className="position-filters">
                <label
                  className={
                    this.state.toggleState === 1
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All
                </label>
                {/* <label
                  className={
                    this.state.toggleState === 2
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Remap POS
                </label> */}
                <label
                  className={
                    this.state.toggleState === 3
                      ? "tabb-label active-tabs"
                      : "tabb-label"
                  }
                  onClick={() => this.toggleTab(3)}
                >
                  Remap History
                </label>
                
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <RemapPosAllGridScene />
                </div>
              </div>

              {/* <div
                className={
                  this.state.toggleState === 2
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <RemapPos />
                </div>
              </div> */}

              <div
                className={
                  this.state.toggleState === 3
                    ? "position-group  active-content"
                    : "position-group"
                }
              >
                <div className="position-post">
                  <RemapPosHistory />
                </div>
              </div>
              
            </div>
          </section>
        </div>
      </>
     
    );
  }
}
