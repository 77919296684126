import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import ServiceTypes from '../../../../../../fixtures/service_types';
import { phoneNumberFormatter } from '../../../../../../utils/formatter';

export default class CommissionHistorySearch extends React.Component {
    
    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest()
    }

    events = [
        {label: "All", value: "All"},
        {label: "Debit", value: "Debit"},
        {label: "Credit", value: "Credit"}
    ]

    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class='form-group row'>
                    {/* <div class="col-md-3">
                        <label class="search-label">Transaction Reference</label>
                        <input type="text" defaultValue={this.props.searchParameters.transactionRef} class="search-input" onChange={(event) => this.props.updateSearchParameters('transactionRef', event.target.value)} />
                    </div> */}
                    <div class="col-md-3">
                        <label class="search-label">Agent Phone Number</label>
                        <input type="text" class="search-input" onChange={(event) => this.props.updateSearchParameters('agentPhoneNumber', phoneNumberFormatter(event.target.value))} />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Transaction Type</label>
                        <Select 
                            options={ServiceTypes}
                            onChange={(event) => this.props.updateSearchParameters('transactionTypeInt', event.value)}
                            defaultValue={this.props.searchParameters.transactionTypeInt}
                        />
                        {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('transactionTypeInt', event.target.value)} >
                            <option selected value={null}>All</option>
                            {ServiceTypes.map(value=><option value={value.id} selected={this.props.searchParameters.transactionType == value.id ? 'selected' : ''}>{value.name}</option>)}
                        </select> */}
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Event</label>
                        <Select 
                            options={this.events}
                            onChange={(event) => this.props.updateSearchParameters('event', event.value)}
                            defaultValue={this.props.searchParameters.event}
                        />
                        {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('event', event.target.value)} >
                            <option selected value={null}>All</option>
                            <option value="Debit">Debit</option>
                            <option value="Credit">Credit</option>
                        </select> */}
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Parent Reference</label>
                        <input type="text" class="search-input" onChange={(event) => this.props.updateSearchParameters('parentRef', event.target.value)} />
                    </div>
                </div>
                <div class='form-group row'>
                    {/* <div class="col-md-3">
                        <label class="search-label">Parent Reference</label>
                        <input type="text" class="search-input" onChange={(event) => this.props.updateSearchParameters('parentRef', event.target.value)} />
                    </div> */}
                    <div class="col-md-3">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.props.updateSearchParameters('start', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={this.props.preloadDate}
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent 
                            onChange={(event) => this.props.updateSearchParameters('end', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.props.searchParameters.start ? false : true}
                            minDate={this.props.searchParameters.start}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={this.props.preloadDate}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}