import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_ACTIVATE_FEE, CAN_CREATE_FEE, CAN_DEACTIVATE_FEE, CAN_DELETE_FEE, CAN_VIEW_FEE } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { getFormattedStatus, pastTenseFormatter } from '../../../../../../../utils/formatter';
import FeeConfigurationTable from '../../components/fee-configuration-table';

export default class FeeConfigurationGridScene extends BaseScene {
  requiredPermissions = [
    CAN_VIEW_FEE,
    CAN_CREATE_FEE
  ]

  getFees = this.getFees.bind(this);
  updateStatus = this.updateStatus.bind(this);
  getClickedAction = this.getClickedAction.bind(this);

  state = {
    fees: [],
    isLoading: false,
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async updateStatus () {
    this.setState({isLoading: true})
    let responseObj = {}
    let approvalMessage = ""
    let message = ""
    if(this.state.feeStatus === 'delete') {
      responseObj = await feeConfigurationManagement.deleteFee(this.state.feeId)
      approvalMessage = `Your request to delete ${this.state.feeName} as a fee has been sent successfully for approval.`
      message = `${this.state.feeName} was successfully ${pastTenseFormatter(this.state.feeStatus)} as a fee.`
    }else{
      responseObj = await feeConfigurationManagement.updateFeeStatus(this.state.feeId, this.state.feeStatus)
      approvalMessage = `Your request to ${getFormattedStatus(this.state.feeStatus)} ${this.state.feeName} as a fee has been sent successfully for approval.`
      message = `${this.state.feeName} was successfully ${pastTenseFormatter(getFormattedStatus(this.state.feeStatus))} as a fee.`
    }
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      if(response.code === ACCEPTED_REQUEST){
      this.setState({
        isLoading: false,
        successMessage: approvalMessage
      })
      return
    }else{
      this.setState({
        successMessage: message,
        isLoading: false,
      })
    }
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getFees()
    setTimeout(() => {
      this.setState({
        feeId: null,
        feeStatus: null
      })
    }, 3000) 
  }

  async getFees (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getFees(pageNum, pageSize)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        fees: response ? response.data : [],
        dataCount: count,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      })
    }else{
      this.setState({
        fees: [],
        dataCount: 0,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response
      })
    }
  }

  getClickedAction (status, id, name) {
    this.setState({
      feeId: id,
      feeStatus: status,
      feeName: name
    })
  }

  render () {
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Fees"} filterPermissions={[CAN_VIEW_FEE]} actionButtonRequiredPermissions={[CAN_CREATE_FEE]} actionButtonText={"Add Fee"} actionButtonUrl={"/home/fee-configuration/add"} totalCount={56} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_FEE]}> 
        <GridLayout>
          <FeeConfigurationTable
            getFees={this.getFees}
            pages={this.state.pages} 
            data={this.state.fees} 
            updateStatus={this.getClickedAction}
            loading={this.state.isLoading} 
            noDataText={`No fee found`}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canActivate={CAN_ACTIVATE_FEE}
            canDeactivate={CAN_DEACTIVATE_FEE}
            canDelete={CAN_DELETE_FEE}
            canView={CAN_VIEW_FEE}
          />
          <PromptModal
            modalTitle={ this.state.feeStatus+` Fee`}
            modalBody={`Do you want to ${this.state.feeStatus} ${this.state.feeName} from fees?`}
            deleteModal={this.updateStatus}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
