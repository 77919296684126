import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import Table from "../../../../../../../components/table";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { CAN_VIEW_CURRENCY } from "../../../../../../../constants/permissions.js";
import { reconReport } from "../../../../../../../mixins/api";
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeTo,
  formatDate,
} from "../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../utils/helper";
import ReconSchedulerEditModal from "../../components/recon-scheduler-edit-modal";

export default class ReconSchedulerGridScene extends BaseScene {
  sceneName = "recon-scheduler";

  constructor(props) {
    super(props);
    this.state = {
      schedulers: [],
      schedulerDetails: [],
      searchParameters: {
        jobName: "",
        jobEnable: "",
        lagHour: "",
        offset: "",
        runningDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
      },
    };

    // getCurrencies = this.getCurrencies.bind(this);
    this.getAllReconSchedulers = this.getAllReconSchedulers.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleScheduler = this.handleScheduler.bind(this);
  }

  componentDidMount() {
    this.getAllReconSchedulers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getAllReconSchedulers() {
    this.setState({ canLoadData: true });

    const {
      status,
      response,
      count,
    } = await reconReport.getReconSchedulerBySearch();
    if (status === SUCCESS_STATUS) {
      if (response.length === 0) {
        this.setState({
          schedulers: [],
          dataCount: 0,
          isLoading: false,
          tableMessage: "No Reconciliation Scheduler found",
          successMessage: "No Reconciliation Scheduler found",
        });
      } else {
        this.setState({
          schedulers: response ? response : [],
          dataCount: count,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        schedulers: [],
        dataCount: 0,
        isLoading: false,
        tableMessage: "No Reconciliation Scheduler found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async handleScheduler() {
    const {
      jobName,
      jobEnable,
      lagHour,
      offset,
      runningDate,
    } = this.state.searchParameters;

    const formattedRunningDate =
      runningDate && formatBackEndDateTimeTo(runningDate);

    this.setState({
      isLoading: true,
    });
    const { status, response } = await reconReport.submitScheduler(
      jobName,
      jobEnable,
      lagHour,
      offset,
      formattedRunningDate
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "Your Scheduler was successfully created!!!",
      });
      window.location.reload();
      this.getAllReconSchedulers();
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  updateSearchParameters(type, value) {
    if (value === 0 || value === "All" || !value) {
      this.setState({
        searchParameters: { ...this.state.searchParameters, [type]: "" },
      });
      console.log("Here");
      return;
    }

    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  // getCurrencies() {
  //   this.setState({ isLoading: true });
  //   setTimeout(() => {
  //     this.setState({
  //       currencies: Currencies,
  //       isLoading: false,
  //     });
  //   }, 5000);
  // }

  render() {
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
      },
      {
        Header: "Job Name",
        accessor: "jobName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Lag Hour",
        accessor: "lagHour",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Offset",
        accessor: "offset",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },

      {
        Header: "Running Date",
        accessor: "runningDate",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: (props) => {
          return props.value ? formatDate(props.value) : "";
        },
      },

      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 100,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="modal"
              data-target="#scheduler_edit"
              onClick={() =>
                this.setState({
                  schedulerDetails: original,
                })
              }
            >
              <i class="fa fa-pencil-square-o"></i>
            </a>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Reconciliation Scheduler Setup"}
          filterPermissions={[]}
          /*actionButtonText={"Add Currency"} actionButtonUrl={"/home/system-configuration/currency-management/add"}*/ totalCount={
            56
          }
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_CURRENCY]}
        >
          <GridLayout>
            <Table
              columns={columns}
              data={this.state.schedulers}
              loading={this.state.isLoading}
              noDataText={`No Reconciliation Scheduler found`}
              dataCount={this.state.schedulers.length}
              getData={this.getAllReconSchedulers}
              disabled={true}
              // exportData={CurrencyExportSerializer.reverseCurrencyDetails(
              //   this.state.currencies.reverse()
              // )}
              // exportHeaders={CurrencyExportSerializer.headerExport()}
              // exportFileName={"Currency"}
            />
            <ReconSchedulerEditModal
              schedulerDetails={this.state.schedulerDetails}
              updateSearchParameters={this.updateSearchParameters}
              searchParameters={this.state.searchParameters}
              handleScheduler={this.handleScheduler}
              preloadDate={
                retrieveOtherInformation("parentReference")
                  ? false
                  : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
              }
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
