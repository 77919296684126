import React from "react";
import ContentHead from "../../../../../components/content-head";

import { Link } from "react-router-dom";
import {
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_MANAGE_AGGREGATOR_DOMAIN,
  CAN_MANAGE_SP_DOMAIN, CAN_VIEW_AGENT_DEVICE, CAN_VIEW_AGENT_ROLE,
  CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_ROLE,
  CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_ROLE,
  CAN_VIEW_SP_USER
} from "../../../../../constants/permissions";
import ProtectedComponent from "../../../../../mixins/protected-component";
import { formatDomainTypeUrl } from "../../../../../utils/formatter";

export default class DomainHeader extends React.Component {
  state = {
    redirect: false,
  };

  exitDomainManagement = this.exitDomainManagement.bind(this);

  componentDidMount() {
    //localStorage.setItem('pointerEvents', 'none')
  }

  exitDomainManagement() {
    //localStorage.removeItem('pointerEvents')
    this.setState({ redirect: true });
    //return <Redirect to={`/home/agent-management`} />
  }

  render() {
    if (this.state.redirect) {
      //return <Redirect to="/home/agent-management" />
      window.location.href = `${
        process.env.REACT_APP_ROUTE_BASENAME
      }/home/${formatDomainTypeUrl(this.props.domainTypeId)}`;
    }
    const headerTitle = (
      <React.Fragment>
        <div>
          <i
            class="fa fa-address-card fa-3x"
            style={{ paddingRight: "10px" }}
          ></i>
          <div
            style={{
              float: "right",
              width: "600px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <h3>
              {this.props.domainName ? this.props.domainName.toUpperCase() : ""}
            </h3>
            <small>
              {" "}
              <i class="fa fa-phone"></i> {this.props.domainPhoneNo}{" "}
              {this.props.domainAddress && (
                <span>
                  | <i class="fa fa-map-marker-alt"></i>{" "}
                  {this.props.domainAddress}
                </span>
              )}
            </small>
          </div>
        </div>
      </React.Fragment>
    );
    const ctaWidgetsForSubmittedApplication = (
      <React.Fragment>
        <ul class="domain-header-nav">
          <ProtectedComponent
            requiredPermissions={[
              CAN_MANAGE_SP_DOMAIN,
              CAN_MANAGE_AGGREGATOR_DOMAIN,
              CAN_MANAGE_AGENT_DOMAIN,
            ]}
          >
            <li class="domain-header-link">
              <Link
                class="kt-nav__link-text"
                to={`/home/domain-management/${this.props.domainCode}`}
              >
                About
              </Link>
            </li>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermissions={[
              CAN_VIEW_SP_USER,
              CAN_VIEW_AGGREGATOR_USER,
              CAN_VIEW_AGENT_USER,
            ]}
          >
            <li class="domain-header-link">
              <Link
                class="kt-nav__link-text"
                to={`/home/domain-management/${this.props.domainCode}/users`}
              >
                Users
              </Link>
            </li>
          </ProtectedComponent>
          <ProtectedComponent
            requiredPermissions={[
              CAN_VIEW_SP_ROLE,
              CAN_VIEW_AGGREGATOR_ROLE,
              CAN_VIEW_AGENT_ROLE,
            ]}
          >
            <li class="domain-header-link">
              <Link
                class="kt-nav__link-text"
                to={`/home/domain-management/${this.props.domainCode}/roles`}
              >
                Roles
              </Link>
            </li>
          </ProtectedComponent>
          <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_DEVICE]}>
            <li class="domain-header-link">
              <Link
                class="kt-nav__link-text"
                to={`/home/domain-management/${this.props.domainCode}/devices`}
              >
                Devices
              </Link>
            </li>
          </ProtectedComponent>
          {/* <li class="domain-header-link-dropdown">
                    <div class="dropdown">
                        <a href="javascript:;" class="btn btn" data-toggle="dropdown">
                            <svg height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
                            </svg>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <ul class="kt-nav">
                                <ProtectedComponent requiredPermissions={[CAN_VIEW_AGENT_DEVICE]}><li class="kt-nav__item">
                                    <a class="kt-nav__link">
                                        <i class="kt-nav__link-icon flaticon-imac"></i>
                                        <Link class="kt-nav__link-text" to={`/home/domain-management/${this.props.domainCode}/devices`}>Devices</Link>
                                    </a>
                                </li></ProtectedComponent>
                                <li class="kt-nav__item">
                                    <a class="kt-nav__link">
                                        <i class="kt-nav__link-icon flaticon-logout"></i>
                                        <Link class="kt-nav__link-text" onClick={this.exitDomainManagement}>Exit</Link>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li> */}
        </ul>
      </React.Fragment>
    );
    return (
      <ContentHead
        title={headerTitle}
        /*title={`Welcome to ${this.props.domainName ? this.props.domainName.toUpperCase() : ''}`}*/ ctaWidgets={
          ctaWidgetsForSubmittedApplication
        }
      />
    );
  }
}
