import React from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useMemo } from 'react';
import { FormInput } from '../../../../../../components/form-controls/form-input';
import Select from '../../../../../../components/select';


const VALIDATION_SCHEMA = Yup.object().shape({
	code: Yup.number()
		.min(0, 'Value must be at least "0"')
		.required('Field is required'),
	// accruedDailyDeposit: Yup.number()
	// 	.typeError('Field must be a number')
	// 	.min(0, 'Value is too small')
	// 	.required('Field is required'),
	dailyDepositLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	// dailyTransCount: Yup.number()
	// 	.typeError('Field must be a number')
	// 	.min(0, 'Value is too small')
	// 	.required('Field is required'),
	dailyTransCountLimit: Yup.number()
		.integer()
		.typeError('Field must be an integer')
		.min(0, 'Value is too small')
		.required('Field is required'),
	dailyWithdrawalLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	ledgerBalanceLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	name: Yup.string()
		.min(4, 'Field is too short')
		.required('Field is required'),
	singleDepositLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	singleWithdrawalLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	walletSchemeRank: Yup.number()
		.integer()
		.typeError('Field must be an integer')
		.min(0, 'Value is too small')
		.required('Field is required'),
});


export default function AgentClassForm(props) {
	const { 
		agentClassFormData = {}, disabled, hasFormBeenSubmitted, innerRef,
		onSubmit, submitButton, title,
	} = props;

	const initialFormValues = useMemo(() => ({
		code: agentClassFormData.code,
		dailyDepositLimit: agentClassFormData.dailyDepositLimit,
		dailyTransCountLimit: agentClassFormData.dailyTransCountLimit,
		dailyWithdrawalLimit: agentClassFormData.dailyWithdrawalLimit,
		description : agentClassFormData.description,
		disabled: agentClassFormData.disabled,
		ledgerBalanceLimit: agentClassFormData.ledgerBalanceLimit,
		name: agentClassFormData.name,
		singleDepositLimit: agentClassFormData.singleDepositLimit,
		singleWithdrawalLimit: agentClassFormData.singleWithdrawalLimit,
		walletSchemeRank: agentClassFormData.walletSchemeRank,
	}), [agentClassFormData]);

	console.log({initialFormValues});

	return <React.Fragment>
		<div class="kt-portlet">
			<Formik
				enableReinitialize={true}
				initialValues={initialFormValues}
				isInitialValid={true}
				innerRef={innerRef}
				onSubmit={(values, actions) => {
					onSubmit({
						...values,
						walletScheme: values.name,
					});
				}}
				validationSchema={VALIDATION_SCHEMA}
			>
				<Form class="kt-form kt-form--label-right" id={'agent-class-form'}>
					<div class="kt-portlet__body">
						{/* <label>Code *:</label>
						<input
							type="name"
							class="form-control"
							defaultValue={props.agentClassFormData ? props.agentClassFormData.code : ''}
							placeholder="Code"
							onChange={event => props.onChange('code', event.target.value)}
							disabled
							autoComplete="off"
						/> */}
						<Field
							component={
								(props) => (
									<FormInput
										{...props}
										defaultValue={initialFormValues.name}
										disabled={disabled}
									/>
								)
							}
							hasFormBeenSubmitted={hasFormBeenSubmitted}
							label="Name"
							name="name"
						/>

						<div className="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											defaultValue={initialFormValues.walletSchemeRank}
											disabled={disabled}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Wallet Scheme Rank"
								name="walletSchemeRank"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											defaultValue={initialFormValues.code}
											disabled={disabled}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Code"
								name="code"
							/>
						</div>
						{/* <label>Name: </label>
						<input
							type="name"
							class="form-control"
							defaultValue={props.agentClassFormData ? props.agentClassFormData.name : ''}
							placeholder="Name"
							onChange={event => {
								const value = event.target.value;
								props.onChange(['walletScheme', 'name'], value);
							}}
							disabled={props.disabled ? props.disabled : ''}
							autoComplete="off" 
						/> */}

						{/* <div class="form-group row">
							<div class="col-lg-6">
								<label>Description</label>
								<textarea class="form-control" rows={10} cols={20} style={{'resize': 'none'}} defaultValue={props.agentClassFormData ? props.agentClassFormData.description : ''} placeholder="Agent class description" onChange={event => props.onChange('description', event.target.value)}
									disabled={props.disabled ? props.disabled : ''}
									autoComplete="off" 
								/>
							</div>
						</div> */}

						{props.showBlock && <div class="form-group row" style={{padding: '8px'}}>
							<div class="col-lg-6">
								<label>Status: </label>
								<Select 
									options={props.agentStatuses.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
									onChange={(event) => props.onChange('disabled', event.value)}
									disabled={disabled}
									defaultValue={props.agentClassFormData ? props.agentClassFormData.disabled : props.agentClassFormData.disabled}
								/>
							</div>
						</div>}

						{props.showBlock && <div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyTransCount}
											disabled={true}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Transactions Count"
								name="dailyTransCount"
							/>
							
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.accruedDailyDeposit}
											disabled={true}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Accrued Daily Deposit"
								name="accruedDailyDeposit"
							/>
						</div>}

						<div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyDepositLimit}
											disabled={disabled}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Deposit Limit"
								name="dailyDepositLimit"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyWithdrawalLimit}
											disabled={disabled}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Withdrawal Limit"
								name="dailyWithdrawalLimit"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyTransCountLimit}
											disabled={disabled}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Transactions Count Limit"
								name="dailyTransCountLimit"
							/>
						</div>

						<div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.singleDepositLimit}
											disabled={disabled}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Single Deposit Limit"
								name="singleDepositLimit"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.singleWithdrawalLimit}
											disabled={disabled}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Single Withdrawal Limit"
								name="singleWithdrawalLimit"
							/>
						</div>

						<div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.ledgerBalanceLimit}
											disabled={disabled}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Ledger Balance Limit"
								name="ledgerBalanceLimit"
							/>
						</div>
					</div>
				</Form>
			</Formik>
		</div>
	</React.Fragment>
}