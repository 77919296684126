import React from 'react'

export default class ProfileView extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <button class="kt-app__aside-close" id="kt_profile_aside_close">
                        <i class="la la-close"></i>
                    </button>
                    {this.props.children}
                </div>
            </div>
        </React.Fragment>
    }
}