import {
  formatDateTime,
  formatLongNumber
} from "../../utils/formatter";

export default class RemapHistoryExportSerializer {
  static headerExport() {
    return [
      {
        label: "ID",
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        label: "Current Owner Name",
        key: "currentOwnerName",
        title: "Current Owner Name",
        dataIndex: "currentOwnerName",
      },
      {
        label: "Current Owner Number",
        key: "currentTerminalOwner",
        title: "Current Owner Number",
        dataIndex: "currentTerminalOwner",
      },
      {
        label: "Next Terminal Owner",
        key: "nextOwnerName",
        title: "Next Terminal Owner",
        dataIndex: "nextOwnerName",
      },
      {
        label: "Next Terminal Number",
        key: "nextTerminalOwner",
        title: "Next Terminal Number",
        dataIndex: "nextTerminalOwner",
      },
      {
        label: "Serial No",
        key: "serialNo",
        title: "Serial No",
        dataIndex: "serialNo",
      },
      {
        label: "Initiator Username",
        key: "remapInitiatorUsername",
        title: "Initiator Username",
        dataIndex: "remapInitiatorUsername",
      },
      {
        label: "Date",
        key: "dateInitiated",
        title: "Date",
        dataIndex: "dateInitiated",
      },
      {
        label: "isCancelled",
        key: "isCancelled",
        title: "isCancelled",
        dataIndex: "isCancelled",
      },
   
     
    ];
  }

  static reverseRemapHistoryDetails(formData) {
    let newFormData = [];
    formData.map((detail) => {
      let {
        id,
        currentOwnerName,
        currentTerminalOwner,
        nextOwnerName,
        nextTerminalOwner,
        serialNo,
        remapInitiatorUsername,
        dateInitiated,
      } = detail;

      let detailFormData = {
        id,
        currentOwnerName,
        currentTerminalOwner: currentTerminalOwner
        ? formatLongNumber(currentTerminalOwner)
        : "",
        nextOwnerName,
        nextTerminalOwner: nextTerminalOwner
        ? formatLongNumber(nextTerminalOwner)
        : "",
        serialNo,
        remapInitiatorUsername,
        dateInitiated: dateInitiated ? formatDateTime(dateInitiated) : "",
      };

      newFormData.push(detailFormData);
    });
    return newFormData;
  }
}
