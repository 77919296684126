import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

const TraceChart = (props) => {
  console.log("trace props ", props.traceData)
    const [traceSpansChart, setTraceSpansChart] = useState([])
    const getTraceTimeLine = () => {
        const result = [
          [
            { type: 'string', id: 'Service' },
            { type: 'string', id: 'Url' },
            { type: 'date', id: 'Start' },
            { type: 'date', id: 'End' },
          ],
        ]
        props.traceData.spans.map(details => {
          const startDate = new Date((details.start.replace(/\s/, 'T')))
          const endDate = new Date((details.end.replace(/\s/, 'T')))
          const array = [];
          array.push(details.service)
          array.push(details.url)
          array.push(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds(), startDate.getMilliseconds()))
          array.push(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds()))
          result.push(array)
        })
        console.log("all trace spans ", result)
        setTraceSpansChart(result)
    }

    useEffect(() => {
        props.traceData && getTraceTimeLine()
    }, [])

    return (
        <Chart
            width={'100%'}
            height={props.traceData.spans.length ? `${props.traceData.spans.length * 100}px` : '200px'}
            chartType="Timeline"
            loader={<div>Loading Chart</div>}
            data={traceSpansChart}
            // chartEvents={[
            //   {
            //     eventName: "ready",
            //     callback: ({ chartWrapper, google }) => {
            //       const chart = chartWrapper.getChart();
            //       google.visualization.events.addListener(chart, "onmouseover", e => {
            //         const { row, column } = e;
            //         console.warn("MOUSE OVER ", { row, column });
            //       });
            //       google.visualization.events.addListener(chart, "onmouseout", e => {
            //         const { row, column } = e;
            //         console.warn("MOUSE OUT ", { row, column });
            //       });
            //     }
            //   }
            // ]}
            options={{
              timeline: {
                groupByRowLabel: false,
              },
            }}
            rootProps={{ 'data-testid': '4' }}
          />
    )
}

export default TraceChart