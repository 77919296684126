import { formatLongNumber } from '../../utils/formatter';
import { formatTransactionStatus, formatDateTime, formatWorkflowApprovalStatus } from '../../utils/formatter';

export default class WorkflowApprovalExportSerializer {
    static headerExport () {
        return [
            {label: 'ID', key: 'applicationId', title: 'ID', dataIndex: 'applicationId'},
            {label: 'Module', key: 'runingWorkflow', title: 'Module', dataIndex: 'runingWorkflow'},
            {label: 'Initiator', key: 'initiator', title: 'Initiator', dataIndex: 'initiator'},
            {label: 'Action Type', key: 'actionType', title: 'Action Type', dataIndex: 'actionType'},
            {label: "Status", key: 'status', title: 'Status', dataIndex: 'status'},
            {label: "Date&Time", key: 'created', title: 'Date&Time', dataIndex: 'created'}

        ]
    }

    static reverseWorkflowApprovalDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { applicationId, runingWorkflow, initiator, actionType, status, created } = details
            let detailFormData = {
                applicationId,
                runingWorkflow: runingWorkflow ? runingWorkflow.name : '',
                initiator,
                status: status ? formatWorkflowApprovalStatus(status) : '',
                created: created ? formatDateTime(created) : '',
                actionType
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}