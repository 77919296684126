import React from 'react'

export default class PermissionForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Name *:</label>
                            <input autoComplete="off" type="name" class="form-control" placeholder="name" disabled value={this.props.permissionDetails ? this.props.permissionDetails.name : ''} onChange={(event) => this.props.onChange('name', event.target.value)} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Code *:</label>
                            <input autoComplete="off" type="name" class="form-control" placeholder="name" disabled value={this.props.permissionDetails ? this.props.permissionDetails.code : ''} onChange={(event) => this.props.onChange('code', event.target.value)} />
                        </div>
                        {/* <div class="form-group col-lg-6">
                            <label>Description *:</label>
                            <textarea autoComplete="off" type="name" class="form-control" placeholder="Last name" disabled={this.props.disabled2 ? this.props.disabled2 : ''} defaultValue={this.props.permissionDetails ? this.props.permissionDetails.description : ''} onChange={(event) => this.props.onChange('description', event.target.value)}></textarea>
                        </div> */}
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}