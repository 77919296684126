import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import LanguageForm from '../../components/language-form';

export default class AddLanguageScene extends BaseScene {
  
  
  constructor (props) {
    super(props)

    this.state = {
      canSave: null,
      languageFormData: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateSetState = this.updateSetState.bind(this)
  }

  componentDidMount() {
  
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onSaveButtonClick () {
    
  }

  updateSetState (type, value) {
    this.setState({languageFormData: {...this.state.languageFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
      <button type="button" disabled={!this.state.name || !this.state.abbreviation } class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Language"} withoutSearch subtitle="Add Language" ctaWidgets={ctaWidgets} />
      <GridLayout>
        <LanguageForm 
          onChange={this.updateStatus}
        />
      </GridLayout>
    </React.Fragment>
  }
}
