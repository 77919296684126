import React from "react";
import { AccessDenied } from "../../../../../../components/access-denied";
import ContentHead from "../../../../../../components/content-head";
import { GridItem } from "../../../../../../components/layouts/grid-item";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { transactionService } from "../../../../../../mixins/api";
import MetricChart from "../../components/metric-chart";
import RoutingCard from "../../components/routing-card";
import TransactionsCard from "../../components/transactions-card";
export default class MetricGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      metricData: [],
      isLoading: true,
      errorMessage: "",
      successMessage: "",
    };
  }

  componentDidMount() {
    this.getAllMetricData();
  }

  async getAllMetricData() {
    try {
      const { status, response } = await transactionService.getMetricData();
      if (status === "SUCCESS") {
        this.setState({
          metricData: response,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: true,
          errorMessage:
            "Oops! An error occured while fetching performance metric data.",
        });
      }
    } catch (err) {
      console.log("Error:" + err.message);
      this.setState({
        isLoading: true,
        errorMessage:
          "Oops! An error occured while fetching performance metric data.",
      });
    }
  }
  render() {
    const {
      responseMetrics,
      successMetric,
      scoreMetric,
    } = this.state.metricData;
    const nipSuccessFailMetric = successMetric
      ? successMetric.failureSuccessPairs.find(
          (item) => item.transactionType === "NIP"
        )
      : null;
    const qtSuccessFailMetric = successMetric
      ? successMetric.failureSuccessPairs.find(
          (item) => item.transactionType === "TRANSFER"
        )
      : null;
    const nipRouteScore = scoreMetric
      ? scoreMetric.scores.find((item) => item.transactionType === "NIP")
      : null;
    const qtRouteScore = scoreMetric
      ? scoreMetric.scores.find((item) => item.transactionType === "TRANSFER")
      : null;

    const nipData = responseMetrics
      ? responseMetrics
          .filter((item) => item.serviceName === "NIP")
          .map((item) => item.responseTime)
      : null;
    const qtData = responseMetrics
      ? responseMetrics
          .filter((item) => item.serviceName === "TRANSFER")
          .map((item) => item.responseTime)
      : null;

    return (
      <React.Fragment>
        <GridItem isFluid isHorizontalGrid>
          <ContentHead title={"Performance Metrics"} />
          <Notification
            errorMessage={this.state.errorMessage}
            successMessage={this.state.successMessage}
          />
          <div class="row" style={{ userSelect: "none" }}>
            <div class="col-md-8">
              <div class="kt-portlet kt-portlet--height-fluid">
                {this.state.isLoading === true ? (
                  <>
                    <div class="kt-widget14">
                      <div class="kt-widget14__header">
                        <h3 class="kt-widget14__title">QT & NIP Chart Board</h3>
                        <span class="kt-widget14__desc">Route count</span>
                      </div>
                      <small class="loading">Loading chart</small>
                    </div>
                  </>
                ) : (
                  <MetricChart
                    isLoading={this.state.isLoading}
                    data1={qtData}
                    data2={nipData}
                  />
                )}
              </div>
            </div>

            <div class="col-md-4">
              <RoutingCard
                isLoading={this.state.isLoading}
                nipRouteScore={nipRouteScore}
                qtRouteScore={qtRouteScore}
                theRoute={scoreMetric}
              />
            </div>
          </div>
          <div class="row" style={{ marginTop: 30, userSelect: "none" }}>
            <div class="col-md-6" style={{ margin: "auto" }}>
              <TransactionsCard
                isLoading={this.state.isLoading}
                nipSuccessFailMetric={nipSuccessFailMetric}
                qtSuccessFailMetric={qtSuccessFailMetric}
              />
            </div>
          </div>
        </GridItem>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
