import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";

export default class QuickStockTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "950px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }
  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "Device Name",
        accessor: "deviceName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Status",
        accessor: "status",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Channel",
        accessor: "channel",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: " New Serial Number",
        accessor: "merchantId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Device OS",
        accessor: "deviceOs",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Terminal ID",
        accessor: "terminalId",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 200,
      },
      {
        Header: "Download Settings",
        accessor: "downloadSettingsEnabled",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 150,
        Cell: (props) => {
          return (
            <span
              style={{ color: "#ffffff" }}
              class={`kt-badge kt-badge--inline ${
                props.row.downloadSettingsEnabled
                  ? "kt-badge--success"
                  : "kt-badge--danger"
              } kt-badge--pill`}
            >
              {props.row.downloadSettingsEnabled ? "Enabled" : "Disabled"}
            </span>
          );
        },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              // marginTop: "0.2px",
              // marginBottom: "1px",
            },
          };
        },
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          // data={FipExportSerializer.reverseFipDetails(this.props.data)}
          // headers={FipExportSerializer.headerExport()}
          fileName={"Update Stocks"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getStocks}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
          downloadLink={this.props.downloadStocks}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={[10, 20, 25, 50, 100]}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          // minRows={this.props.data.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getQuickStocks(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              padding: "0px, 0",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
