import React from 'react'
import Select from '../../../../../../../components/select'

export default class ServiceSearch extends React.Component {
    state = {
        searchParams: {
            status: "All"
        },
        statuses: [
            { value: "All", label: "All" },
            { value: 0, label: "Active" },
            { value: 1, label: "Inactive" }
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest(this.state.searchParams)
    }

    
    render() {

        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="search-label">Name :</label>
                        <input type="text" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, name: event.target.value}})} />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Status :</label>
                        <Select 
                            options={this.state.statuses}
                            onChange={(event) => this.setState({searchParams: {...this.state.searchParams, status: event.value}})}
                            defaultValue={this.state.searchParams.status}
                        />
                        {/* <select type="text" class="search-input" onChange={(event) => this.setState({searchParams: {...this.state.searchParams, status: event.target.value}})} >
                            {this.state.statuses.map(value => <option value={value.id}>{value.name}</option>)}
                        </select> */}
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}