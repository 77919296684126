import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  formatDateTime,
} from "../../../../../../utils/formatter";
import { explodeDate } from "../../../../../../utils/helper";
import SpinnerComponent from "../../../../../../components/spinner";
import Pagination from "../../../../../../components/pagination";
import TableExtra from "../../../../../../components/table-extra";
import { FipExportSerializer } from "../../../../../../serializers";
import ProtectedComponent from "../../../../../../mixins/protected-component";

export default class StockTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "950px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }
  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "Batch ID",
        accessor: "batchId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Serial No",
        accessor: "serialNo",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Device Type",
        accessor: "terminalType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Name",
        accessor: "terminalName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Model",
        accessor: "terminalModel",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Date Uploaded",
        accessor: "dateUploaded",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 170,
        Cell: (props) => {
          return props.value
            ? formatDateTime(explodeDate(props.value, "+"))
            : null;
        },
      },
      {
        Header: "Uploaded By",
        accessor: "uploadedBy",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Action",
        accessor: "deviceUuid",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 60,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent
                requiredPermissions={[this.props.canRemapDevice]}
              >
                <a
                  data-toggle="modal"
                  data-target="#stock_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={FipExportSerializer.reverseFipDetails(this.props.data)}
          fileName={"POS Stocks"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getStocks}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
          downloadLink={this.props.downloadStocks}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={[10, 20, 25, 50, 100]}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={this.props.data.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getStocks(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display:"flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft:"5px",
              paddingRight:"5px",
              alignItems: "center",
              padding:  "0px, 0",
              
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"5px",
              paddingRight:"5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
