import { formatDate } from '../../utils/formatter';

export default class CountryExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Alphabetic Code', key: 'alphabeticCode2', title: 'Alphabetic Code', dataIndex: 'alphabeticCode2'},
            {label: 'Dial Code', key: 'dialCode', title: 'Dial Code', dataIndex: 'dialCode'},
        ]
    }

    static reverseCountryDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, alphabeticCode2, dialCode } = details
            let detailFormData = {
                name,
                alphabeticCode2,
                dialCode
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}