import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BankGridScene from './scenes/bank-grid'
import BaseScene from '../../../../../components/scenes/component';
import ViewBanksScene from './scenes/view-banks';
import AddBankScene from './scenes/add-bank';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_BANK, CAN_UPDATE_BANK } from '../../../../../constants/permissions.js'

export default class BankScene extends BaseScene {
  sceneName = 'sanef-bank-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_BANK]} exact path='/home/system-configuration/sanef-bank-management' component={BankGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_BANK]} exact path='/home/system-configuration/sanef-bank-management/:id/view' component={ViewBanksScene} />
      <ProtectedRoute requiredPermissions={[CAN_UPDATE_BANK]} exact path='/home/system-configuration/sanef-bank-management/add' component={AddBankScene} />
    </Router>
  }
}
