import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_UPDATE_REGION, CAN_VIEW_REGION } from '../../../../../../../constants/permissions.js';
import Countries from '../../../../../../../fixtures/countries.json';
import { platformManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { getStatesByCountryId } from '../../../../../../../utils/helper';
import RegionForm from '../../components/region-form';

export default class ViewRegionScene extends BaseScene {
  constructor (props) {
    super(props)

    this.state = {
      canSave: null,
      countries: [],
      states: [],
      regionFormData: [],
      canUpdate: false,
      canSave: false,
      disabled: true,
      pageTitle: "View Region",
      statuses: [
        {"id": 1, "name": "Active"},
        {"id": 2, "name": "Inactive"}
      ]
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.updateRegionFormData = this.updateRegionFormData.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
  }

  componentDidMount() {
    this.regionId = this.props.match.params.id
    this.getRegion()
    this.getCountries()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onCancelButtonClick () {
    this.setState({
        disabled: true,
        canSave: false,
        canUpdate: true,
        pageTitle: 'View Region',
    })
  }

  async onUpdateButtonClick () {
    this.setState({
        disabled: false,
        canSave: true,
        canUpdate: false,
        pageTitle: 'Update Region',
    })
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.updateRegion(this.state.regionFormData)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.getRegion()
      this.setState({
        successMessage: 'Region updated successfully',
        disabled: true,
        canSave: false,
        canUpdate: true,
        pageTitle: 'View Region',
        redirect: true
      })
    }else{
      this.setState({
        errorMessage: response
      })
    }
  }

  async getRegion () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getRegion(this.regionId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        regionFormData: response,
        isLoading: false,
        canUpdate: true
      })
    }else{
      this.setState({
        regionFormData: [],
        isLoading: false,
        errorMessage: response
      })
    }
  }

  async getCountries () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getCountries()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        countries: response,
        isLoading: false,
        dataCount: response.length
      })
    }else{
      this.setState({
        countries: Countries,
        isLoading: false,
        dataCount: 0,
      })
    }
  }

  async getStates (countryId) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getStatesByCountryId(countryId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        states: response,
        isLoading: false,
        dataCount: response.length
      })
    }else{
      this.setState({
        states: getStatesByCountryId(countryId),
        isLoading: false,
        dataCount: 0,
      })
    }
  }

  isFormValid () {
    return Boolean(!this.state.regionFormData.regioName || !this.state.regionFormData.regionCode)
  }

  updateRegionFormData (type, value) {
    this.setState({regionFormData: {...this.state.regionFormData, [type]: value}})
  }

  render () {

    const ctaWidgets = <React.Fragment>
        {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_REGION]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
        {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_REGION]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
        {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_REGION]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Region"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_REGION]}>
        <GridLayout>
          <RegionForm 
            onChange={this.updateRegionFormData}
            countries={this.state.countries}
            states={this.state.states}
            getStates={this.getStates}
            disabled={this.state.disabled}
            regionFormData={this.state.regionFormData}
            showStatus={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
