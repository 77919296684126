import React from 'react'


const AccountOpening = (props) => {
    if(props.data.transactionTypeInt === 13){
        return <AccountOpeningData data={props.data} />
    }else if(props.data.transactionTypeInt === 15){
        return <CardLinkingData data={props.data} />
    }
    else return null;
}

const AccountOpeningData = (props) => {
    return (
    <div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Agent Name</strong></label><br/>
                <span>{props.data.agentName}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Agent Phone No</strong></label><br/>
                <span>{props.data.businessMobileNo}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Agent ID</strong></label><br/>
                <span>{props.data.agentIdentifier}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Agent State</strong></label><br/>
                <span>{props.data.state}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Customer Name</strong></label><br/>
                <span>{props.data.customerFirstName} {props.data.customerLastName}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Customer Phone No</strong></label><br/>
                <span>{props.data.customerMsisdn}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Account No.</strong></label><br/>
                <span>{props.data.beneficiaryAccountNumber}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Gender</strong></label><br/>
                <span>{props.data.customerGender}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Bank Name</strong></label><br/>
                <span>{props.data.bankName}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Date & Time</strong></label><br/>
                <span>{props.data.dateCreated}</span>
            </div>
        </div>
    </div>
    )
}

const CardLinkingData = (props) => {
    return (
    <div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Card Serial Number</strong></label><br/>
                <span>{props.data.pin}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Agent Phone No</strong></label><br/>
                <span>{props.data.businessMobileNo}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Agent ID</strong></label><br/>
                <span>{props.data.agentIdentifier}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Agent State</strong></label><br/>
                <span>{props.data.state}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Latitude</strong></label><br/>
                <span>{props.data.latitude}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Longitude</strong></label><br/>
                <span>{props.data.longitude}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Bank Name</strong></label><br/>
                <span>{props.data.bankName}</span>
            </div>
            <div class="col-lg-6">
                <label><strong>Account No.</strong></label><br/>
                <span>{props.data.beneficiaryAccountNumber}</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label><strong>Date & Time</strong></label><br/>
                <span>{props.data.dateCreated}</span>
            </div>
        </div>
    </div>
    )
}

export default AccountOpening;