import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../components/pagination';
import SpinnerComponent from '../../../../../components/spinner';
import TableExtra from '../../../../../components/table-extra';
import ProtectedComponent from '../../../../../mixins/protected-component';
import { ApplicationExportSerializer } from '../../../../../serializers';
import { formatDateTime } from '../../../../../utils/formatter';
import { explodeDate, getApplicationType, getPaginationRows } from '../../../../../utils/helper';

export default class ApplicationTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '430px',
        zoom: 'plus'
    }

    zoomTableHeight = this.zoomTableHeight.bind(this)

    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }

    render() {
        const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            {
                Header: "ID",
                accessor: 'applicationId',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 100,
                Cell: props => `IFIS/${props.value}`
            },{
                Header: "Category",
                accessor: 'agentTypeId',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 100,
                Cell: props=> getApplicationType(props.value)
            },{
                Header: "Business/Applicant Name",
                accessor: 'business_name',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                show: this.props.showBusinessName,
                Cell: ({row, original}) => <span class="word-capitalize">{original.businessName ? original.businessName
                    : `${original.firstName ? original.firstName : ''} ${original.surname ? original.surname : ''}` }
                </span>
            },{
                Header: "Email",
                accessor: 'emailAddress',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 120,
            },{
                Header: "Phone Number",
                accessor: 'phoneNumber',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 120,
                Cell: ({row, original}) => {
                    return original.phoneNumber ? original.phoneNumber : '';
                }
            },{
                Header: "Date Created",
                accessor: 'dateCreated',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 100,
                Cell: ({ row, original }) => {
                    return original.dateCreated ? formatDateTime(explodeDate(original.dateCreated)) : ''
                }
            },{
                Header: "Last Modified",
                accessor: 'dateLastModified',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 100,
                Cell: ({ row, original }) => {
                    return original.dateLastModified ? formatDateTime(explodeDate(original.dateLastModified)) : ''
                }
            },{
                Header: "Status",
                accessor: 'approvalStatus',
                resizable: false,
                width: 150,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            textAlign: 'center'
                        },
                    };
                },
                Cell: props => <span style={{textAlign: 'left', width: '135px', borderRadius: '4px', padding: '5px', paddingTop: '15px', paddingBottom: '15px', fontSize: '11px', backgroundColor: props.value == 'CANCELLED' ? '#D4D5CF' : props.value == 'REJECTED' ? '#FB1203' : props.value == 'AWAITING_VALIDATION' ? '#1DB1F6' : props.value == 'AWAITING_APPROVAL' ? '#CFFB03' : props.value == 'APPROVED' ? '#145E01' : '#F6C342', color: 'black', fontWeight: 'bold'}} class="kt-badge kt-badge--inline kt-badge--pill">{props.value === "CANCELLED" ? 'Cancelled' : props.value === "AWAITING_APPROVAL" ? "Awaiting Approval" : props.value === "AWAITING_VALIDATION" ? "Awaiting Validation" : props.value === "REJECTED" ? "Rejected" : props.value === "APPROVED" ? 'Approved' : 'Draft'}</span>
            },{
                Header: "Creation Type",
                accessor: 'approvalStatus',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: props => <span>Self Onboarding</span>,
                show: false
            },{
                Header: "Created By",
                accessor: 'approvalStatus',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                Cell: props => <span>James Doe</span>,
                show: false
            },{
                Header: "Action",
                accessor: 'agentCode',
                overflow: 'visible',
                resizable: false,
                sortable: false,
                headerStyle: {'white-space': 'unset'},
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      lineHeight: '20px',
                      textAlign: 'center',
                      whiteSpace: 'unset',
                      overflow: 'visible',
                    },
                  };
                },
                width: 60,
                Cell: ({row, original}) => <div class="dropdown">
                  <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                    <i class="la la-ellipsis-v"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    {original.agentClassId == 6 ? 
                    <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`application-management/${row.applicationId}/view-aggregator`} class="dropdown-item">
                      <i class="la la-eye"></i>
                      View
                    </Link></ProtectedComponent> :
                    <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`application-management/${row.applicationId}/view`} class="dropdown-item">
                    <i class="la la-eye"></i>
                    View
                  </Link></ProtectedComponent>}
                    <ProtectedComponent requiredPermissions={[this.props.canCancel]}><a hidden={original.approvalStatus === 'CANCELLED' || original.approvalStatus === 'APPROVED'} onClick={() => this.props.getClickedAction(row.applicationId, original.businessName ? original.businessName : `${original.firstName ? original.firstName : ''} ${original.surname ? original.surname : ''}`, `cancel`)} data-toggle="modal" data-target="#kt_modal_1" /*href={`/home/agent-management/${'retire'}/${row.agentCode}`}*/ class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                      <i class="la la-close"></i>
                      Cancel
                    </a></ProtectedComponent>
                  </div>
                </div>
            }
            
            // {
            //     Header: "Action",
            //     accessor: 'agentCode',
            //     overflow: 'visible',
            //     resizable: false,
            //     sortable: false,
            //     headerStyle: {'white-space': 'unset'},
            //     getProps: (state, rowInfo, column) => {
            //       return {
            //           style: {
            //               lineHeight: '20px',
            //               textAlign: 'center',
            //               whiteSpace: 'unset',
            //           },
            //       };
            //   },
            //     width: 60,
            //     Cell: ({row, original}) => {
            //       return <a href={`/home/application-management/applications/${row.applicationId}/view`} style={{background: '#00425F', borderColor: '#00425F', padding: '5px', margin: '5px', fontSize: '11px'}} class="btn btn-info"> View
            //     </a>
            //     }
            // }
        ]

        return <React.Fragment>
            <TableExtra 
                data={ApplicationExportSerializer.reverseApplicationDetails(this.props.data)}
                headers={ApplicationExportSerializer.headerExport()}
                fileName={'applications'}
                zoomTableHeight={this.zoomTableHeight}
                getData={this.props.getApplications}
                page={this.props.page}
                pageSize={this.props.pageSize}
                zoom={this.state.zoom}
                disabled={this.props.loading}
            />
            <ReactTable 
                PaginationComponent={Pagination}
                loading={this.props.loading}
                LoadingComponent={MyLoader}
                style={{
                    border: "none",
                    boxShadow: "none",
                    whiteSpace: 'unset',
                    height: this.state.tableHeight,
                }}
                data={this.props.data}
                columns={columns}
                dataCount={this.props.dataCount}
                noDataText={!this.props.loading ? this.props.noDataText : ''}
                pageSizes={getPaginationRows(this.props.dataCount)}
                headerNodeHeight={`123`}
                manual
                pages={this.props.pages}
                page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
                minRows={(this.props.data).length > 0 ? 10 : 10}
                onFetchData={(state, instance) => {
                    this.setState({page: state.page})
                    if(this.props.canLoadData) {
                        this.props.getApplications(state.page + 1,state.pageSize)
                    } 
                }}
                getTableProps={(state, rowInfo, column, instance) => ({
                    style: {
                        borderColor: '#00425f',
                        boxSizing: 'none',
                        border: 'none',
                    },
                })}
                // getTdProps={(state, rowInfo, column, instance) => ({
                //     style: {
                //       overflow: 'visible',
                //       //backgroundColor: '#FFFFFF',
                //       lineHeight: '20px',
                //     },
                // })}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                    style: {
                        backgroundColor: '#00425f',
                        borderBottom: '0.1px solid #DBDEDE',
                        borderWidth: '0.1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        color: '#FFFFFF',
                        fontSize: '13px',
                        fontWeight: '500',
                        textAlign: 'left'
                    },
                })}
                getPaginationProps={(state, rowInfo, column, instance) => ({
                    style: {
                        overflow: 'visible',
                        backgroundColor: '#ffffff',
                    },
                })}    
                className="-striped -highlight" 
            />
        </React.Fragment>
          
    }
}