import React from 'react'
import Select from '../../.../../../../../../components/select'

export default class AddWorkflowApprover extends React.Component {
    render() {
        return <React.Fragment>
            <span>Select Approver to add to step {this.props.stepId}</span>
            <Select 
                options={this.props.users.map(detail => { return ( {label: `${detail.firstName} ${detail.lastName}`, value: detail.email} ) })}
                onChange={event => this.props.updateSetState('username', event.value)}
                //defaultValue={this.props.formData.username}
                minHeight={40}
            />
            {/* <select class="form-control" onChange={event => this.props.updateSetState('username', event.target.value)}>
                <option selected disabled>Select</option>
                {this.props.users.map(value => <option value={value.email}>{`${value.firstName} ${value.lastName}`}</option>)}
            </select> */}
        </React.Fragment>
    }
}