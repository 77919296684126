import React from 'react'
import ApprovalInformation from './approval-information'
import ApprovalInitiator from './approval-initiator'
import ApprovalDetails from './approval-details'
import ApprovalDate from './approval-dates'
import ApprovalHistory from './approval-history'

export default class ApprovalView extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne1" aria-controls="collapseOne1">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                        <h3 class="kt-portlet__head-title">
                            {this.props.title}
                        </h3>
                    </div>
                </div>
                <form class="kt-form kt-form--label-right" id="collapseOne1" class="collapse show" aria-labelledby="headingOne1">
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-lg-5">
                                <ApprovalInformation 
                                    approvalDetails={this.props.approvalDetails}
                                />
                            </div>
                            <div class="col-lg-7">
                                <ApprovalInitiator 
                                    initiator={this.props.approvalDetails.initiator ? this.props.approvalDetails.initiator : []}
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <ApprovalDetails
                                    details={this.props.approvalDetails.data ? this.props.approvalDetails.data : []}
                                    downloadFile={this.props.downloadFile}
                                />
                                <ApprovalDate 
                                    approvalDetails={this.props.approvalDetails}
                                />
                            </div>
                            <div class="col-lg-6">
                                <ApprovalHistory 
                                    approvalDetails={this.props.approvalDetails}
                                />  
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}