import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import Notification from '../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_PERFORM_BULK } from '../../../../../../../constants/permissions.js';
import { liquidityService } from '../../../../../../../mixins/api'
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import BatchCreditDebitTable from '../../components/batch-credit-debit-table'
import { saveOtherInformation } from '../../../../../../../mixins/authentication'

export default class BatchCreditDebitGridScene extends BaseScene {
  
    sceneName = 'bulk-processing'
  
    notificationSectionRef = React.createRef(null)

    constructor (props) {
        super(props)
        this.state = {
            bulkProcessingTransactions: [],
            exportParams: {
                statusCode: 'All'
            }
        }
        this.getBulkProcessingTransactions = this.getBulkProcessingTransactions.bind(this)
        this.getFile = this.getFile.bind(this)
        this.redirectToTransactionHistory = this.redirectToTransactionHistory.bind(this)
        this.exportFile = this.exportFile.bind(this)
        this.updateExportParameter = this.updateExportParameter.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async getBulkProcessingTransactions(pageNum, pageSize) {
        this.setState({isLoading: true})
        const responseObj = await liquidityService.getBulkProcessingTransactions(pageNum, pageSize)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                bulkProcessingTransactions: response.items,
                dataCount: response.totalCount,
                pages: Math.ceil(response.totalCount/pageSize),
                pageSize: pageSize,
                page: pageNum,
                isLoading: false
            })
        }else{
            this.setState({
                bulkProcessingTransactions: [],
                dataCount: 0,
                pages: Math.ceil(0/pageSize),
                pageSize: pageSize,
                page: pageNum,
                errorMessage: response,
                isLoading: false
            })
        }
    }

    async getFile(fileName) {
        this.setState({isLoading: true})
        const responseObj = await liquidityService.getUploadedFile(fileName)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                isLoading: false
            })
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false
            })
        }
    }

    redirectToTransactionHistory(batchRef) {
        saveOtherInformation("parentReference", batchRef)
    }

    async exportFile (transactionRef) {
        this.setState({isLoading: true})
        const responseObj = await liquidityService.exportBatchCreditDebit(this.state.exportParams)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            const blob = new Blob([ response ], { type: 'text/csv;charset=utf-8;' });
            const a = document.createElement('a');
            a.setAttribute('download', 'batch_credit_debit.csv');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
            this.setState({
                isLoading: false
            })
        }else{
            this.setState({
                errorMessage: response,
                isLoading: false
            })
        }
    }

    updateExportParameter(type, value) {
        this.setState({exportParams: {...this.state.exportParams, [type]: value}})
    }

    async downloadFile (fileName) {
        this.setState({isLoading: true})
        const responseObj = await liquidityService.downloadBatchCreditDebit(fileName)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            const blob = new Blob([ response ], { type: 'text/csv;charset=utf-8;' });
            const a = document.createElement('a');
            a.setAttribute('download', fileName);
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
            this.setState({
                isLoading: false
            })
        }else{
          window.scrollTo(0, this.notificationSectionRef.offsetTop)
          this.setState({
              errorMessage: response,
              isLoading: false
          })
        }
    }

    render() {
        const modalBody = <React.Fragment>
            <div class="form-group row">
                <div class="col-md-10">
                    <label class="search-label">Status :</label>
                    <select class="search-input" onChange={(event) => this.setState({exportParams: {...this.state.exportParams, statusCode: event.target.value}})} >
                        <option value="All">All</option>
                        <option value="CLOSED_AND_SUCCESSFUL">Successful</option>
                        <option value="CLOSED_BUT_FAILED">Failed</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-10">
                    <label class="search-label">Page Number :</label>
                    <input type="text" class="search-input" onChange={(event) => this.setState({exportParams: {...this.state.exportParams, pageNumber: event.target.value}})} />
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-10">
                    <label class="search-label">Page Size :</label>
                    <input type="text" class="search-input" onChange={(event) => this.setState({exportParams: {...this.state.exportParams, pageSize: event.target.value}})} />
                </div>
            </div>
        </React.Fragment>

        return <React.Fragment>
            <ContentHead title={"Batch Credit/Debit"} subtitle={"All Batch Credit/Debit"} filterPermissions={[CAN_PERFORM_BULK]} actionButtonText={"Create Batch"} actionButtonUrl={"batch-credit-debit/new"} actionButtonRequiredPermissions={[CAN_PERFORM_BULK]}/>
            <Notification 
                ref={this.notificationSectionRef}
                errorMessage={this.state.errorMessage}
                successMessage={this.state.successMessage}
            />
            <ProtectedComponent requiredPermissions={[CAN_PERFORM_BULK]} permissionDeniedContent={this.permissionDeniedContent}>
                <GridLayout>
                 <BatchCreditDebitTable 
                    data={this.state.bulkProcessingTransactions}
                    getBulkProcessingTransactions={this.getBulkProcessingTransactions}
                    getFile={this.getFile}
                    pages={this.state.pages}
                    startPage={this.state.startPage}
                    loading={this.state.isLoading}
                    noDataText={`No bulk processing transactions found`}
                    dataCount={this.state.dataCount}
                    pageSize={this.state.pageSize}
                    page={this.state.page}
                    redirectToTransactionHistory={this.redirectToTransactionHistory}
                    exportFile={this.exportFile}
                    updateExportParameter={this.updateExportParameter}
                    downloadFile={this.downloadFile}
                 />
                 <PromptModal
                    modalTitle={"File Export Options"}
                    modalBody={modalBody}
                    deleteModal={this.exportFile}
                    modalSize={`modal-md`}
                    height={'430px'}
                    modalFooterButton1={`Cancel`}
                    modalFooterButton2={`Download`}
                    hideModalHeader={true}
                    hideModalFooter={true}
                    textAlign={'left'}
                />
                </GridLayout>
            </ProtectedComponent>
        </React.Fragment>
    }
}