export const DELETE_METHOD = 'DELETE'
export const GET_METHOD = 'GET'
export const POST_METHOD = 'POST'
export const PUT_METHOD = 'PUT'
export const ERROR_STATUS = 'ERROR'
export const SUCCESS_STATUS = 'SUCCESS'
export const PASSWORD_EXPIRY = '20201'
export const GLOBAL_ERROR_MESSAGE = 'An error occurred, please try again later'
export const LOGIN_SUCCESS = '200'
export const ACCEPTED_REQUEST = '20200'
export const MULTIPLE_PROFILE = '20206'
