import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  CAN_UPDATE_FEE,
  CAN_VIEW_FEE,
} from "../../../../../../../constants/permissions.js";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import FeeForm from "../../components/fee-form";

export default class ViewFeeConfigurationScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      disableboundedMinBound: false,
      showBandsInput: false,
      feeTypes: [
        { id: "flat", name: "Flat" },
        { id: "percentage", name: "Percentage" },
      ],
      billers: [
        { id: "1", name: "Customer" },
        { id: "0", name: "Biller" },
      ],
      canUpdate: false,
      canSave: false,
      disabled: true,
      pageTitle: "View Fee",
      isBoundedMaxBoundValid: true,
      feeFormData: {
        feeBands: [],
        banded: false,
        flatAmount: 0,
        percentageAmount: 0,
        minimumCap: 0,
        maximumCap: 0,
      },
      feeBandsFormData: {
        boundedMinBound: 1,
        boundedMaxBound: "",
        boundedFlatAmount: 0,
        boundedPercentageAmount: 0,
        boundedMinCap: 0,
        boundedMaxCap: 0,
      },
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.addBoundClick = this.addBoundClick.bind(this);
    this.updateFeeFormData = this.updateFeeFormData.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
    this.updateFeeBandsFormData = this.updateFeeBandsFormData.bind(this);
    this.validateBoundedData = this.validateBoundedData.bind(this);
    this.removeBoundClick = this.removeBoundClick.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  componentDidMount() {
    this.feeId = this.props.match.params.id;
    this.getFee();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getFee() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getFee(this.feeId);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        feeFormData: this.getUniqueFeeBands(response),
        showBandsInput: response.feeBands ? false : true,
        feeBandsFormData: {
          ...this.state.feeBandsFormData,
          boundedMinBound: response.feeBands ? "" : 1,
        },
        canUpdate: true,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  getUniqueFeeBands(response) {
    let increment = 1;
    if (response.feeBands) {
      const feeBands = response.feeBands.filter(function (value) {
        value.id = increment;
        increment++;
        return value;
      });
      response.feeBands = feeBands;
    }
    return response;
  }

  async onUpdateButtonClick() {
    this.setState({
      disabled: false,
      canSave: true,
      canUpdate: false,
      pageTitle: "Update Fee",
    });
  }

  async onCancelButtonClick() {
    this.setState({
      disabled: true,
      canSave: false,
      canUpdate: true,
      pageTitle: "View Fee",
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.updateFee(
      this.feeId,
      this.state.feeFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your changes to this fee has been sent successfully for approval.",
          canUpdate: true,
          canSave: false,
          disabled: true,
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Fee updated successfully.",
          canUpdate: true,
          canSave: false,
          disabled: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  addBoundClick() {
    const newBound = {
      id: Date.now(),
      minimumBand: this.state.feeBandsFormData.boundedMinBound,
      maximumBand: this.state.feeBandsFormData.boundedMaxBound,
      flatAmount: this.state.feeBandsFormData.boundedFlatAmount,
      percentageAmount: this.state.feeBandsFormData.boundedPercentageAmount,
      minimumCap: this.state.feeBandsFormData.boundedMinCap,
      maximumCap: this.state.feeBandsFormData.boundedMaxCap,
    };
    this.setState({
      feeFormData: {
        ...this.state.feeFormData,
        feeBands: [
          ...(this.state.feeFormData.feeBands
            ? this.state.feeFormData.feeBands
            : ""),
          newBound,
        ],
      },
      feeBandsFormData: {
        ...this.state.feeBandsFormData,
        boundedMinBound: this.state.feeBandsFormData.boundedMaxBound + 1,
      },
      disableboundedMinBound: true,
      isBoundedMaxBoundValid: !this.validateMaxField(
        this.state.feeBandsFormData.boundedMaxBound + 1,
        this.state.feeBandsFormData.boundedMaxBound
      ),
      showBandsInput:
        this.state.feeBandsFormData.boundedMaxBound === 0 ? false : true,
    });
  }

  removeBoundClick(id) {
    const response = this.state.feeFormData.feeBands.filter(function (value) {
      return value.id !== id;
    });

    const removedBands = this.state.feeFormData.feeBands.filter(function (
      value
    ) {
      return value.id === id;
    });

    const previousBand = removedBands[0];

    this.setState({
      feeFormData: { ...this.state.feeFormData, feeBands: response },
      showBandsInput: true,
      feeBandsFormData: {
        ...this.state.feeBandsFormData,
        boundedMinBound: previousBand.minimumBand,
      },
      isBoundedMaxBoundValid: !this.validateMaxField(
        previousBand.minimumBand,
        this.state.feeBandsFormData.boundedMaxBound
      ),
    });
  }

  validateMaxField(minValue, maxValue) {
    if (maxValue === "Above") {
      return true;
    } else {
      return Boolean(maxValue > minValue);
    }
  }

  validateCapField(minValue, maxValue) {
    if (minValue === 0 && maxValue === 0) {
      return Boolean(maxValue >= minValue);
    }
    return Boolean(maxValue > minValue);
  }

  checkMinField(minValue) {
    return Boolean(minValue);
  }

  validateBoundedData() {
    return (
      (this.state.feeBandsFormData.boundedFlatAmount >= 0 ||
        this.state.feeBandsFormData.boundedPercentageAmount >= 0) &&
      !this.state.isBoundedMaxBoundValid &&
      !this.state.isBoundedMaxCapValid &&
      this.state.feeBandsFormData.boundedMinBound >= 0 &&
      this.state.feeBandsFormData.boundedMaxBound >= 0
    );
  }

  isFormValid() {
    return (
      !this.validateIfBanded() ||
      !this.state.feeFormData.name ||
      !this.state.feeFormData.description
    );
  }

  validateIfBanded() {
    if (this.state.feeFormData.banded) {
      return Boolean(
        (this.state.feeFormData.feeBands
          ? this.state.feeFormData.feeBands.length > 0
          : false) && !this.state.showBandsInput
      );
    } else {
      return (
        Boolean(
          this.state.feeFormData.flatAmount >= 0 ||
            this.state.feeFormData.percentageAmount >= 0
        ) && !this.state.isMaximumCapValid
      );
    }
  }

  updateFeeFormData(type, value) {
    this.setState({
      feeFormData: { ...this.state.feeFormData, [type]: value },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  updateFeeBandsFormData(type, value) {
    this.setState({
      feeBandsFormData: { ...this.state.feeBandsFormData, [type]: value },
    });
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE]}>
            <button
              type="button"
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onUpdateButtonClick}
            >
              Edit
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE]}>
            <button
              type="button"
              class="btn btn-secondary btn-bold btn-sm btn-icon-h"
              id="kt_subheader_group_actions_fetch"
              onClick={this.onCancelButtonClick}
            >
              Cancel
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE]}>
            <button
              type="button"
              class="btn btn-primary"
              disabled={this.isFormValid()}
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onSaveButtonClick}
            >
              Save Changes
            </button>
          </ProtectedComponent>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />

        <ContentHead
          title={"Fees"}
          withoutSearch
          subtitle={this.state.pageTitle}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_FEE]}
        >
          <GridLayout>
            <FeeForm
              feeBandsFormData={this.state.feeBandsFormData}
              feeFormData={this.state.feeFormData}
              billers={this.state.billers}
              feeTypes={this.state.feeTypes}
              updateFeeFormData={this.updateFeeFormData}
              updateFeeBandsFormData={this.updateFeeBandsFormData}
              updateSetState={this.updateSetState}
              showBandsInput={this.state.showBandsInput}
              validateBoundedData={this.validateBoundedData}
              validateCapField={this.validateCapField}
              isMaximumCapValid={this.state.isMaximumCapValid}
              validateMaxField={this.validateMaxField}
              isBoundedMaxCapValid={this.state.isBoundedMaxCapValid}
              addBoundClick={this.addBoundClick}
              isBoundedMaxBoundValid={this.state.isBoundedMaxBoundValid}
              removeBoundClick={this.removeBoundClick}
              toggleBanded={this.toggleBanded}
              disabled={this.state.disabled}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
