import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import { CashoutInjectExportSerializer } from "../../../../../../serializers";
import { formatDateTime } from "../../../../../../utils/formatter";
import { explodeDate, getPaginationRows } from "../../../../../../utils/helper";

export default class CashoutInjectedTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  handleDownload = (path) => {
    window.open(process.env.REACT_APP_DOCUMENT_URL + path, '_blank');
  };

  handleDownloadProcessed = (path) => {
    window.open(process.env.REACT_APP_DOCUMENT_URL + path, '_blank');
  };

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "Batch ID",
        accessor: "batchId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <span class="word-capitalize ellipse" style={{ width: "150px" }}>
            {props.value ? props.value : ""}{" "}
          </span>
        ),
      },
      {
        Header: "Document Name",
        accessor: "documentName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <span class="word-capitalize ellipse" style={{ width: "150px" }}>
            {props.value ? props.value : ""}
          </span>
        ),
      },
      {
        Header: "Uploaded By",
        accessor: "uploadedBy",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <span class="word-capitalize ellipse" style={{ width: "150px" }}>
            {props.value ? props.value : ""}
          </span>
        ),
      },
      {
        Header: "Date Uploaded",
        accessor: "dateCreated",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 170,
        Cell: (props) => {
          return props.value
            ? formatDateTime(explodeDate(props.value, "+"))
            : "";
        },
      },
      {
        Header: "Date Processed",
        accessor: "dateModified",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 170,
        Cell: (props) => {
          return props.value
            ? formatDateTime(explodeDate(props.value, "+"))
            : "";
        },
      },
      {
        Header: "Approval Status",
        accessor: "approvalStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 80,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => {
          const value = props.value ? props.value : "";
          const capitalizedText =
            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

          return (
            <span
              style={{ width: "150px", color:'white' }}
              class={`kt-badge ${
                props.value === 'APPROVED'
                  ? "kt-badge--success"
                  : props.value === 'INPROGRESS'
                  ? "kt-badge--warning"
                  : "kt-badge--danger"
              } kt-badge--inline kt-badge--pill`}
            >
              {capitalizedText}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "processed",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 80,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <span
            style={{ color: "#ffffff" }}
            class={`kt-badge ${
              props.value === true
                ? "kt-badge--success"
                : props.value === false
                ? "kt-badge--warning"
                : ""
            } kt-badge--inline kt-badge--pill`}
          >
            {props.value === true
              ? "Completed"
              : props.value === false
              ? "Pending"
              : ""}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        width: 70,
        Cell: (props) => (
          <div className="dropdown" style={{ paddingLeft: "20px" }}>
            <a
              href="#"
              className="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i className="la la-ellipsis-v"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                data-toggle="modal"
                data-target="#recon_details_modal"
                onClick={() => this.handleDownload(props.original.absolutePath)}
                className="dropdown-item"
                style={{ color: "#A7ABC3", cursor: "pointer" }}
              >
                <i className="la la-download"></i>
                Uploaded File
              </a>
              <a
                data-toggle="modal"
                data-target="#recon_details_modal"
                onClick={() => this.handleDownloadProcessed(props.original.processedFilePath)}
                className="dropdown-item"
                style={{ color: "#A7ABC3", cursor: "pointer" }}
              >
                <i className="la la-download"></i>
                Processed File
              </a>
            </div>
          </div>
        ),
      }
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={CashoutInjectExportSerializer.cashoutDetails(this.props.data)}
          headers={CashoutInjectExportSerializer.headerExport()}
          fileName={"cashout_inject"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getInjectCashout}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          requeryBulkTransactions={this.props.requeryBulkTransactions}
          desiredStatus={this.props.desiredStatus}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getInjectCashout(state.page + 1, state.pageSize);
            }
          }}
          // onFetchData={(state, instance) => {
          //   const {
          //     batchId,
          //     uploadedBy,
          //     documentName,
          //     dateCreated,
          //     dateModified,
          //     processed,

          //   } = this.props.searchParameters;
          //   this.setState({ page: state.page });
          //   if (this.props.canLoadData) {
          //     this.props.getTransactions(
          //       state.page + 1,
          //       state.pageSize,
          //       batchId,
          //       uploadedBy,
          //       documentName,
          //       dateCreated,
          //       dateModified,
          //       processed,
          //     );
          //   }
          // }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
