import React from 'react';
import { Redirect } from 'react-router-dom';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_CREATE_REGION } from '../../../../../../../constants/permissions.js';
import Countries from '../../../../../../../fixtures/countries.json';
import { platformManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { getStatesByCountryId } from '../../../../../../../utils/helper';
import RegionForm from '../../components/region-form';

export default class AddRegionScene extends BaseScene {
  
  notificationSectionRef = React.createRef(null)
  
  constructor (props) {
    super(props)

    this.state = {
      canSave: null,
      countries: [],
      regionFormData: [],
      states: []
    }

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.updateRegionFormData = this.updateRegionFormData.bind(this)
    this.getStates = this.getStates.bind(this)
  }

  componentDidMount() {
    this.getCountries()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.createRegion(this.state.regionFormData)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: 'Region Created successfully',
        redirect: true
      })
    }else{
      this.setState({
        errorMessage: response
      })
    }
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  async getCountries () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getCountries()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        countries: response,
        isLoading: false,
        dataCount: response.length
      })
    }else{
      this.setState({
        countries: Countries,
        isLoading: false,
        dataCount: 0,
      })
    }
  }

  async getStates (countryId) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getStatesByCountryId(countryId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        states: response,
        isLoading: false,
        dataCount: response.length
      })
    }else{
      this.setState({
        states: getStatesByCountryId(countryId),
        isLoading: false,
        dataCount: 0,
      })
    }
  }

  isFormValid () {
    return Boolean(!this.state.regionFormData.regionName || !this.state.regionFormData.regionCode)
  }

  updateRegionFormData (type, value) {
    this.setState({regionFormData: {...this.state.regionFormData, [type]: value}})
  }

  render () {
    if(this.state.redirect) {
      return <Redirect to="/home/system-configuration/region-management" />
    }

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_CREATE_REGION]}><button type="button" disabled={this.isFormValid()} class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        ref={this.notificationSectionRef}
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Region"} withoutSearch subtitle="Add Region" ctaWidgets={ctaWidgets} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_CREATE_REGION]}>
        <GridLayout>
          <RegionForm 
            onChange={this.updateRegionFormData}
            countries={this.state.countries}
            states={this.state.states}
            getStates={this.getStates}
            regionFormData={this.state.regionFormData}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
