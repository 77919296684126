import React from 'react'
import { FormLayout } from '../../../components/forms/form-layout'
import { Link } from 'react-router-dom';
import { LOGIN } from '../../../constants/routes';

export const ChangePasswordSuccess = () => {
    return <>
        <FormLayout>
            <div class="fade-in" style={{textAlign: 'center'}}>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                <h2>Password Updated!</h2><br/>
                <p>
                    Your password has been changed successfully.<br/>
                    Use your new password to <Link to={LOGIN}>log in</Link>
                </p>
            </div>
        </FormLayout>
    </>
}