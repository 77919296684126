import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_REMAP_DEVICE } from '../../../../../../../constants/permissions.js';
import { userManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';

export default class ViewStockScene extends BaseScene {

  requiredPermissions = [
    CAN_REMAP_DEVICE
  ]

  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      canUpdate: false,
      stockDetails: [],
      isLoading: false,
      disabled: true,
      updatedModelPrice: ""
    }
    this.getStock = this.getStock.bind(this)
    this.updateModelPrice = this.updateModelPrice.bind(this)
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
  }

  sceneName = "pos-stocks"

  componentDidMount() {
    this.stockId = this.props.match.params.id
    console.log("stock id is ", this.stockId);
    this.getStock();
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getStock() {
    this.setState({
      isLoading: true,
      canLoadData: true
    })
    const responseObj = await userManagement.getStock(this.stockId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.getPosModelPrice(response.terminalModel)
      this.setState({
        stockDetails: response,
        isLoading: false,
        canUpdate: true
      })
    }else{
      this.setState({
        stockDetails: [],
        isLoading: false,
        canUpdate: false,
      })
    }
  }

  async getPosModelPrice(model) {
    this.setState({
      isLoading: true,
      canLoadData: true
    })
    const responseObj = await userManagement.getPosModelPrice(model)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      console.log("model price response ", response)
      //const updatedStock =
      this.setState({
        stockDetails: {...this.state.stockDetails, price: response.data.price},
        isLoading: false,
        canUpdate: true
      })
    }else{
      this.setState({
        stockDetails: this.state.stockDetails,
        isLoading: false,
        canUpdate: false,
      })
    }
  }

async updateModelPrice() {
  this.setState({isLoading: true})
  const payload = {
    price: this.state.updatedModelPrice
  }
  const responseObj = await userManagement.updatePosModelPrice(this.state.stockDetails.price, this.state.stockDetails.terminalModel)
  const { status, response, count } = responseObj
  if (status === SUCCESS_STATUS) {
      this.setState({
          successMessage: "Model price updated successfully",
          errorMessage: null,
          isLoading: false,
          canSave: false,
          canUpdate: true,
      })
  }else{
      this.setState({
          successMessage: null,
          errorMessage: response,
          isLoading: false,
          canSave: false,
          canUpdate: true,
      })
  }
  this.getStock();
}

async onUpdateButtonClick () {
  this.setState({
    pageTitle: 'Update User Details',
    canSave: true,
    canUpdate: false,
    disabled: false,
  })
}

async onCancelButtonClick () {
  this.setState({
    pageTitle: 'View User Details',
    canSave: false,
    canUpdate: true,
    disabled: true,
  })
}

  render () {

    const ctaWidgets = <React.Fragment>
      {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_REMAP_DEVICE]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_REMAP_DEVICE]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
      {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_REMAP_DEVICE]}><button type="button" class="btn btn-primary"style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.updateModelPrice}>Save Changes</button></ProtectedComponent>}
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"POS Management"} subtitle={'Stock Details'} filterPermissions={[CAN_REMAP_DEVICE]} totalCount={56} ctaWidgets={ctaWidgets} />
      <ProtectedComponent requiredPermissions={[CAN_REMAP_DEVICE]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <div class="kt-portlet">
            <form class="kt-form kt-form--label-right">
              <div class="kt-portlet__body">
                <div class="form-group col-lg-6">
                  <label>Batch ID *:</label>
                  <input autoComplete="off" type="name" class="form-control" disabled={true} defaultValue={this.state.stockDetails.batchId} />
                </div>
                <div class="form-group col-lg-6">
                  <label>Serial Number *:</label>
                  <input autoComplete="off" type="name" class="form-control" disabled={true} defaultValue={this.state.stockDetails.serialNo} />
                </div>
                <div class="form-group col-lg-6">
                  <label>Type *:</label>
                  <input autoComplete="off" type="name" class="form-control" disabled={true} defaultValue={this.state.stockDetails.terminalType} />
                </div>
                <div class="form-group col-lg-6">
                  <label>Name *:</label>
                  <input autoComplete="off" type="name" class="form-control" disabled={true} defaultValue={this.state.stockDetails.terminalName} />
                </div>
                <div class="form-group col-lg-6">
                  <label>Model *:</label>
                  <input autoComplete="off" type="name" class="form-control" disabled={true} defaultValue={this.state.stockDetails.terminalModel} />
                </div>
                <div class="form-group col-lg-6">
                  <label>Price *:</label>
                  <input autoComplete="off" type="name" class="form-control" 
                    onChange={event => this.setState({stockDetails: {...this.state.stockDetails, price: event.target.value}})} 
                    disabled={this.state.disabled} defaultValue={this.state.stockDetails.price} 
                  />
                </div>
                <div class="form-group col-lg-6">
                  <label>Status *:</label>
                  <input autoComplete="off" type="name" class="form-control" disabled={true} defaultValue={this.state.stockDetails.stockStatus} />
                </div>
              </div>
            </form>
          </div>
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

    get permissionDeniedContent() {
      return <AccessDenied /> 
    }
}
