import React from 'react'
import { formatTransactionStatus, formatCurrency } from '../../../../../../utils/formatter';
import { convertToMajor } from '../../../../../../utils/converter'
import { getTransactionTypeName } from '../../../../../../utils/helper';
import FeeSplits from '../../../../../../components/fee-splits'

export default class TransactionDetails extends React.Component {
    render() {
        return <React.Fragment>
            <div style={{textAlign: 'left'}}>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong>Transaction Reference</strong></label><br/>
                        <span>{this.props.transactionDetails.transactionRef}</span>
                    </div>
                    <div class="col-lg-6">
                        <label><strong>Agent Name</strong></label><br/>
                        <span>{this.props.transactionDetails.agentName}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong>Amount</strong></label><br/>
                        <span>&#x20A6;{formatCurrency(this.props.transactionDetails.transactionAmount ? convertToMajor(this.props.transactionDetails.transactionAmount) : 0)}</span>
                    </div>
                    <div class="col-lg-6">
                        <label><strong>Fee</strong></label><br/>
                        <span>&#x20A6;{formatCurrency(this.props.transactionDetails.fee ? convertToMajor(this.props.transactionDetails.fee) : 0)}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong>Transaction Type</strong></label><br/>
                        <span>{this.props.transactionDetails.transactionTypeInt ? getTransactionTypeName(this.props.transactionDetails.transactionTypeInt) : ''}</span>
                    </div>
                    <div class="col-lg-6">
                        <label><strong>Business Name</strong></label><br/>
                        <span>{this.props.transactionDetails.businessName}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong>Narration</strong></label><br/>
                        <span>{this.props.transactionDetails.narration}</span>
                    </div>
                    <div class="col-lg-6">
                        <label><strong>Status</strong></label><br/>
                        <span>{formatTransactionStatus(this.props.transactionDetails.statusCode ? this.props.transactionDetails.statusCode : '')}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label><strong>Payment Method</strong></label><br/>
                        <span>{this.props.transactionDetails.paymentMethod}</span>
                    </div>
                    <div class="col-lg-6">
                        <label><strong>Payment Item Code</strong></label><br/>
                        <span>{this.props.transactionDetails.paymentItemCode}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-4">
                        <label><strong>Response Code</strong></label><br/>
                        <span>{this.props.transactionDetails.responseCode}</span>
                    </div>
                    <div class="col-lg-4">
                        <label><strong>Service Provider Response Code</strong></label><br/>
                        <span>{this.props.transactionDetails.serviceProviderResponseCode}</span>
                    </div>
                    <div class="col-lg-4">
                        <label><strong>Wallet Provider Response Code</strong></label><br/>
                        <span>{this.props.transactionDetails.walletProviderResponseCode}</span>
                    </div>
                </div>
                {(formatTransactionStatus(this.props.transactionDetails.statusCode ? this.props.transactionDetails.statusCode : '') == 'Successful' && this.props.transactionDetails.transactionTypeInt != 10) ? 
                    <a class="pull-right" href={`/home/reporting/settlement-report/view?transRef=${this.props.transactionDetails.transactionRef}`}>View Settlment Details</a>
                : ''}
                <br/>
            </div>
        </React.Fragment>
    }
}