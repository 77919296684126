import React from 'react'

export default class SpinnerComponent extends React.Component {
    state = {
        laoding: true
    }
    render() {
        return <React.Fragment>
            <div class="spinner" style={this.props.style} />
        </React.Fragment>
    }
}
