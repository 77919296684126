import React from "react";

export default class PdfModalComponent extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    disablePrevPageButton: false,
    disableNextPageButton: false,
  };

  componentDidMount() {
    this.savePdf(
      "https://mufasa-qa.interswitchng.com/p/finch/onboarding/2523_Incorporation_Certificate.pdf"
    );
  }

  savePdf(pdfUrl) {
    // Send Request
    var xhr = new XMLHttpRequest();
    xhr.open("POST", pdfUrl, true);
    xhr.responseType = "arraybuffer";
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function (e) {
      // Check status
      if (this.status === 200) {
        // convert response to Blob, then create URL from blob.
        var blob = new Blob([this.response], { type: "application/pdf" }),
          fileURL = URL.createObjectURL(blob);

        // open new URL
        window.open(fileURL, "_blank");
      } else {
      }
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  goToPrevPage = () => {
    if (this.state.pageNumber > 1) {
      this.setState((state) => ({
        pageNumber: state.pageNumber - 1,
        disablePrevPageButton: false,
      }));
    } else {
      this.setState((state) => ({
        pageNumber: state.pageNumber,
        disablePrevPageButton: true,
      }));
    }
  };

  goToNextPage = () => {
    if (this.state.pageNumber < this.state.numPages) {
      this.setState((state) => ({
        pageNumber: state.pageNumber + 1,
        disableNextPageButton: false,
      }));
    } else {
      this.setState((state) => ({
        pageNumber: state.pageNumber,
        disableNextPageButton: true,
      }));
    }
  };

  render() {
    const { modalSize, pageNumber, numPages } = this.props;
    return (
      <React.Fragment>
        <div
          class="modal"
          id="kt_modal_pdf_viewer"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class={`modal-dialog ${modalSize}`} role="document">
            <div class="modal-content" style={{ height: "700px" }}>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {this.props.filename}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="modal-body fluid"
                style={{
                  height: this.props.height ? this.props.height : "450px",
                }}
              >
                <div>
                  <iframe
                    id="inlineFrameExample"
                    title="Inline Frame Example"
                    width="900px"
                    height="500px"
                    src={this.props.src}
                  ></iframe>
                </div>
              </div>
              <hr />
              <div style={{ paddingBottom: "10px" }}>
                <button
                  style={{
                    borderColor: "#00425f",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    marginRight: "20px",
                  }}
                  class="btn btn-default btn-bold btn-sm btn-icon-h"
                  id="kt_subheader_group_actions_fetch"
                  onClick={(this.goToPrevPage, (e) => e.preventDefault())}
                >
                  Prev
                </button>
                <button
                  style={{
                    borderColor: "#00425f",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }}
                  class="btn btn-default btn-bold btn-sm btn-icon-h"
                  id="kt_subheader_group_actions_fetch"
                  onClick={(this.goToNextPage, (e) => e.preventDefault())}
                >
                  Next
                </button>
                <span class="pull-right" style={{ paddingRight: "20px" }}>
                  Page {pageNumber} / {numPages}
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
