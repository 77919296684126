import { formatDateTime, formatLongNumber } from '../../utils/formatter';

export default class ServiceOptionExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Display Name', key: 'displayName', title: 'Display Name', dataIndex: 'displayName'},
            {label: 'Service', key: 'serviceName', title: 'Service', dataIndex: 'serviceName'},
            {label: 'Service Type', key: 'serviceType', title: 'Service Type', dataIndex: 'serviceType'},
            {label: 'Settlement Interval', key: 'settlementInterval', title: 'Settlement Interval', dataIndex: 'settlementInterval'},
            {label: 'Payment Code', key: 'paymentCode', title: 'Payment Code', dataIndex: 'paymentCode'},
            {label: 'Paydirect Item Code', key: 'paydirectItemCode', title: 'Paydirect Item Code', dataIndex: 'paydirectItemCode'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Date Created', key: 'createdOn', title: 'Date Created', dataIndex: 'createdOn'},
            {label: 'Date Last Updated', key: 'lastUpdatedOn', title: 'Date Last Updated', dataIndex: 'lastUpdatedOn'},
        ]
    }

    static reverseServiceOptionDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, displayName, serviceName, serviceType, settlementInterval, paymentCode, 
                paydirectItemCode, status, createdOn, lastUpdatedOn 
            } = details
            let detailFormData = {
                name,
                displayName,
                serviceName,
                serviceType,
                settlementInterval,
                paymentCode: paymentCode ? formatLongNumber(paymentCode) : '',
                paydirectItemCode: paydirectItemCode ? formatLongNumber(paydirectItemCode) : '',
                status: status === 0 ? 'Active' : status === 1 ?  'Inactive' : '',
                createdOn: createdOn ? formatDateTime(createdOn) : '',
                lastUpdatedOn: lastUpdatedOn ? formatDateTime(lastUpdatedOn) : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}