import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BaseScene from '../../../../../components/scenes/component';
import WorkflowConfigurationGridScene from './scenes/configuration-grid'
import ViewWorkflowConfigurationDetailsScene from './scenes/view-configuration'
import { 
  CAN_VIEW_WORKFLOW_CONFIGURATION, CAN_ADD_WORKFLOW_CONFIGURATION_STEP, 
  CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP, CAN_MOVE_WORKFLOW_CONFIGURATION_STEP 
} from '../../../../../constants/permissions.js'
import { isAuthorized } from '../../../../../mixins/authentication'

export default class WorkflowConfigurationScene extends BaseScene {
  sceneName = 'configuration'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <Route requiredPermissions={[CAN_VIEW_WORKFLOW_CONFIGURATION, CAN_ADD_WORKFLOW_CONFIGURATION_STEP, CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP, CAN_MOVE_WORKFLOW_CONFIGURATION_STEP]} exact path="/home/workflow-management/configuration" component={WorkflowConfigurationGridScene} />
      <Route requiredPermissions={[CAN_VIEW_WORKFLOW_CONFIGURATION, CAN_ADD_WORKFLOW_CONFIGURATION_STEP, CAN_REMOVE_WORKFLOW_CONFIGURATION_STEP, CAN_MOVE_WORKFLOW_CONFIGURATION_STEP]} exact path="/home/workflow-management/configuration/:id/view" component={ViewWorkflowConfigurationDetailsScene} />
    </Router>
  }
}
