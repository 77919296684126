import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import {
  formatDate,
  formatWalletStatus,
  formatWalletStatusClassName,
} from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";
import { Modal } from "react-bootstrap";
import { platformManagement } from "../../../../../../mixins/api";
import { SUCCESS_STATUS } from "../../../../../../constants/api";

export default class AgentTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
    showAggregatorForm: false,
    aggregatorCode: "",
    agentID: "",
    isLoading: false,
    successMessage: "",
    errorMessage: "",
    aggregatorInfo: {},
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);
  fetchAgentAggregatorDetails = this.fetchAgentAggregatorDetails.bind(this);


  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
            ? "auto"
            : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getSerialNumber(index) {
    const pageSize = Number(this.props.pageSize) || 10;
    const currentPage = Number(this.props.page) || 1;
    const safeIndex = Number(index) + 1 || 1;

    return pageSize * (currentPage - 1) + safeIndex;
  }

  handleInputChange = (field, value) => {
    this.setState({ [field]: value });
  };

  migrateAgentAggregator = async () => {
    this.setState({ isLoading: true });

    const { aggregatorCode, aggregatorInfo } = this.state;
    const payload = {
      agentDomainCodes: [aggregatorInfo.agentCode],
      aggregatorDomainCode: aggregatorCode,
    };

    try {
      const responseObj = await platformManagement.updateAgentAggregator(payload);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS) {
        this.setState({
          successMessage: response.description || "Agent migrated successfully!",
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            showAggregatorForm: false,
            successMessage: "",
            errorMessage: "",
            aggregatorCode: "",
            newAggregatorName: "",
          });
        }, 2000);

        if (this.props.onUpdateData) {
          this.props.onUpdateData();
        }

        setTimeout(() => this.setState({ redirect: true }), 5000);
      } else {
        this.setState({
          errorMessage: response.description || "Migration failed. Try again.",
          isLoading: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 1000);
      }
    } catch (error) {
      console.error("Migration Error:", error);
      this.setState({
        errorMessage: "An unexpected error occurred. Please try again.",
        isLoading: false,
      });
    }
  };

  async fetchAgentAggregatorDetails(agentCode) {
    this.setState({ isLoading: true });

    try {
      const responseObj = await platformManagement.getAgentAggregator(agentCode);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS && response) {
        this.setState({
          aggregatorInfo: response.data,
          isLoading: false,
        });
      } else {
        this.setState({
          aggregatorInfo: {},
          errorMessage: "No agents found",
          isLoading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching agent aggregators:", error);
      this.setState({
        aggregatorInfo: {},
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  render() {
    const agentData = this.props.data && this.props.data;
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Agent ID",
        accessor: "agentCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Phone Number",
        accessor: "agentMobileNo",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <span class="word-capitalize ellipse" style={{ width: "150px" }}>
            {props.value.toLowerCase()}
          </span>
        ),
      },
      {
        Header: "Agent Class",
        accessor: "agentClass",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        Cell: (props) => (
          <span class="word-capitalize">
            {props.value ? props.value.toLowerCase() : null}
          </span>
        ),
      },
      {
        Header: "Date Created",
        accessor: "dateApplied",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 100,
        Cell: (props) => {
          return props.value ? formatDate(props.value) : null;
        },
      },
      {
        Header: "Last Modified",
        accessor: "dateLastUpdated",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 100,
        Cell: (props) => {
          return props.value ? formatDate(props.value) : null;
        },
      },
      {
        Header: "Profile Config",
        accessor: "walletStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <span
            style={{ color: "#ffffff" }}
            class={`kt-badge ${formatWalletStatusClassName(
              props.value
            )} word-capitalize kt-badge--inline kt-badge--pill`}
          >
            {props.value ? formatWalletStatus(props.value) : null}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "index",
        resizable: false,
        width: 120,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },

        Cell: (props) => {
          return (agentData.bvnVerificationStatus &&
            agentData[props.index].bvnVerificationStatus == "SUSPENDED") ||
            agentData[props.index].bvnVerificationStatus ==
            "PENDING_VERIFICATION_SUSPENDED" ? (
            <span
              style={{ color: "#ffffff" }}
              class={`kt-badge kt-badge--warning kt-badge--inline kt-badge--pill`}
            >
              {"Suspended"}
            </span>
          ) : (
            <span
              style={{ color: "#ffffff", whiteSpace: "nowrap" }}
              class={`kt-badge ${agentData[props.index].statusId === 1 ||
                agentData[props.index].statusId === 0
                ? "kt-badge--primary"
                : agentData[props.index].statusId === 2
                  ? "kt-badge--success"
                  : agentData[props.index].statusId === 3
                    ? "kt-badge--warning"
                    : agentData[props.index].statusId === 4
                      ? "kt-badge--danger"
                      : agentData[props.index].statusId === 5
                        ? "kt-badge--info"
                        : agentData[props.index].statusId === 7
                          ? "kt-badge--warning"
                          : ""
                } kt-badge--inline kt-badge--pill`}
            >
              {process.env.REACT_APP_SHOW_KYC_UPDATE === "true"
                ? agentData[props.index].statusId === 0
                  ? "New"
                  : agentData[props.index].statusId === 1
                    ? "New"
                    : agentData[props.index].statusId === 2
                      ? "Active"
                      : agentData[props.index].statusId === 3
                        ? "Suspended"
                        : agentData[props.index].statusId === 4
                          ? "Retired"
                          : agentData[props.index].statusId === 5
                            ? "Dormant"
                            : agentData[props.index].statusId === 7
                              ? "KYC Suspended"
                              : ""
                : agentData[props.index].statusId === 0
                  ? "New"
                  : agentData[props.index].statusId === 1
                    ? "New"
                    : agentData[props.index].statusId === 2
                      ? "Active"
                      : agentData[props.index].statusId === 3
                        ? "Suspended"
                        : agentData[props.index].statusId === 4
                          ? "Retired"
                          : agentData[props.index].statusId === 5
                            ? "Dormant"
                            : ""}
            </span>
          );
        },
      },
      {
        Header: "Action",
        accessor: "agentCode",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 60,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div
              style={{
                maxHeight: "200px",
                overflowY: "scroll",
              }}
              class="dropdown-menu dropdown-menu-right"
            >
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <Link
                  to={`agent-management/${row.agentCode}/view/${row.id}`}
                  class="dropdown-item"
                >
                  <i class="la la-eye"></i>
                  View
                </Link>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  to={`agent-management/manage-agent-class?agentId=${row.agentCode}&agentPhoneNumber=${row.agentMobileNo}`}
                  class="dropdown-item"
                >
                  <i class="la la-magnet"></i>
                  Manage Agent Class
                </Link>
              </ProtectedComponent>
              {original.walletVersion === 2 && (
                <ProtectedComponent requiredPermissions={[this.props.canView]}>
                  <Link
                    hidden={original.statusId !== 4 ? "" : "hidden"}
                    to={`agent-management/manage-agent-limit?agentId=${row.agentCode}&agentPhoneNumber=${row.agentMobileNo}`}
                    class="dropdown-item"
                  >
                    <i class="la la-magnet"></i>
                    Manage Agent Limit
                  </Link>
                </ProtectedComponent>
              )}
              <ProtectedComponent
                requiredPermissions={[this.props.canManageDomain]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  to={`domain-management/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-tasks"></i>
                  Manage Domain
                </Link>
              </ProtectedComponent>
              {process.env.REACT_APP_SHOW_MANAGE_AGGREGATOR === "true" &&
                <ProtectedComponent
                  requiredPermissions={[this.props.canManageDomain]}
                >
                  <Link
                    hidden={original.statusId !== 4 ? "" : "hidden"}
                    className="dropdown-item"
                    onClick={() => {
                      this.fetchAgentAggregatorDetails(row.agentCode);
                      this.setState({ showAggregatorForm: true });
                    }}
                  >
                    <i className="la la-tasks"></i>
                    Manage Aggregator
                  </Link>
                </ProtectedComponent>
              }
              <ProtectedComponent
                requiredPermissions={[this.props.canViewTransaction]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() =>
                    this.props.redirectToCommissionHistory(original)
                  }
                  to={`commission-history/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-book"></i>
                  View Commission History
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canViewTransaction]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() => this.props.redirectToWalletJournal(original)}
                  to={`account-statement/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-book"></i>
                  View Statement of Account
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canActivate]}
              >
                <a
                  hidden={original.statusId === 3 ? "" : "hidden"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      "activate"
                    )
                  }
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-toggle-on"></i>
                  Activate
                </a>
              </ProtectedComponent>
              {process.env.REACT_APP_SHOW_KYC_UPDATE === "true" &&
                original.statusId !== 7 &&
                original.statusId !== 4 &&
                original.statusId !== 3 && (
                  <ProtectedComponent
                    requiredPermissions={[this.props.canSuspendKyc]}
                  >
                    <a
                      onClick={() =>
                        this.props.getClickedAction(
                          row.agentCode,
                          row.businessName,
                          `suspend`,
                          true
                        )
                      }
                      data-toggle="modal"
                      data-target="#kt_modal_1"
                      class="dropdown-item"
                      style={{ color: "#A7ABC3", cursor: "pointer" }}
                    >
                      <i class="la la-ban"></i>
                      Suspended for KYC
                    </a>
                  </ProtectedComponent>
                )}

              <ProtectedComponent
                requiredPermissions={[this.props.canActivate]}
              >
                <a
                  hidden={original.statusId === 3 ? "" : "hidden"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      "activate"
                    )
                  }
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-toggle-on"></i>
                  Deactivate
                </a>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canActivate]}
              >
                <a
                  hidden={original.statusId === 7 ? "" : "hidden"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      "activate"
                    )
                  }
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-toggle-on"></i>
                  Activate
                </a>
              </ProtectedComponent>
              {(original.statusId === 2 || original.statusId === 7) && (
                <ProtectedComponent
                  requiredPermissions={[this.props.canDeactivate]}
                >
                  <a
                    onClick={() =>
                      this.props.getClickedAction(
                        row.agentCode,
                        row.businessName,
                        `suspend`
                      )
                    }
                    data-toggle="modal"
                    data-target="#kt_modal_1"
                    /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item"
                    style={{ color: "#A7ABC3", cursor: "pointer" }}
                  >
                    <i class="la la-ban"></i>
                    Suspend
                  </a>
                </ProtectedComponent>
              )}
              <ProtectedComponent requiredPermissions={[this.props.canRetire]}>
                <a
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      `retire`
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  /*href={`/home/agent-management/${'retire'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-lock"></i>
                  Retire
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];
    const { aggregatorInfo, aggregatorCode, agentID, isLoading } = this.state;

    return (
      <>
        <React.Fragment>
          <TableExtra
            data={AgentDetailsExportSerializer.reverseAgentDetails(agentData)}
            headers={AgentDetailsExportSerializer.headerExport()}
            fileName={"agents"}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getAgents}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            downloadLink={this.props.downloadAgentData}
            disabled={this.props.loading}
          />
          <ReactTable
            PaginationComponent={Pagination}
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            style={{
              border: "none",
              boxShadow: "none",
              whiteSpace: "unset",
              height: this.state.tableHeight,
            }}
            data={agentData}
            columns={columns}
            dataCount={this.props.dataCount}
            noDataText={!this.props.loading ? this.props.noDataText : ""}
            pageSizes={getPaginationRows(this.props.dataCount)}
            headerNodeHeight={`123`}
            manual
            pages={this.props.pages}
            page={
              this.props.startPage ? this.props.startPage - 1 : this.state.page
            }
            minRows={agentData.length > 0 ? 10 : 10}
            onFetchData={(state, instance) => {
              this.setState({ page: state.page });
              if (this.props.canLoadData) {
                this.props.getAgents(state.page + 1, state.pageSize);
              }
            }}
            getTableProps={(state, rowInfo, column, instance) => ({
              style: {
                borderColor: "#00425f",
                boxSizing: "none",
                border: "none",
              },
            })}
            getTdProps={(state, rowInfo, column, instance) => ({
              style: {
                display: "flex",
                overflow: "visible",
                color: "black",
                fontSize: "11.5px",
                fontWeight: "50",
                paddingLeft: "5px",
                paddingRight: "5px",
                alignItems: "center",
              },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
              style: {
                backgroundColor: "#00425f",
                borderBottom: "0.1px solid #DBDEDE",
                borderWidth: "0.1px",
                padding: "10px 0",
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: "100",
                textAlign: "left",
                paddingLeft: "5px",
                paddingRight: "5px",
              },
            })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
            className="-striped -highlight"
          />
        </React.Fragment>

        <Modal
          animation={true}
          style={{
            borderRadius: "10px",
            transition: "all .2s ease-in-out",
            maxHeight: "85vh",
            overflow: "hidden",
          }}
          size="lg"
          show={this.state.showAggregatorForm}
          centered
        >
          <Modal.Body style={{ margin: "20px", padding: "20px" }}>
            <button
              onClick={() => this.setState({ showAggregatorForm: false })}
              style={{
                position: "absolute",
                top: "10px",
                right: "15px",
                background: "transparent",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              ✖
            </button>
            {this.state.successMessage && (
              <div className="alert alert-success text-center">
                {this.state.successMessage}
              </div>
            )}

            {this.state.errorMessage && (
              <div className="alert alert-danger text-center">
                {this.state.errorMessage}
              </div>
            )}
            <h4
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                marginBottom: "20px",
              }}
            >
              Change Aggregator
            </h4>

            <div className="col-md-12">
              <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                {[
                  { label: "Aggregator ID", value: aggregatorInfo.previousAggregatorCode, editable: false },
                  { label: "Aggregator Name", value: aggregatorInfo.previousAggregatorBusinessName, editable: false },
                  { label: "Agent ID", value: aggregatorInfo.agentCode, editable: false },
                  { label: "Agent Name", value: aggregatorInfo.agentBusinessName, editable: false },
                  { label: "New Aggregator ID", stateKey: "aggregatorCode", editable: true },
                  { label: "New Aggregator Name", stateKey: "newAggregatorName", editable: true }
                ].map((field, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label
                      className="search-label"
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      {field.label}
                    </label>
                    <input
                      style={{
                        flex: "1",
                        height: "45px",
                        padding: "10px",
                        fontSize: "14px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: field.editable ? "#fff" : "#e9ecef",
                      }}
                      value={field.editable ? this.state[field.stateKey] : field.value || ""}
                      onChange={
                        field.editable
                          ? (e) => this.handleInputChange(field.stateKey, e.target.value)
                          : undefined
                      }
                      readOnly={!field.editable}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary col-lg-6"
              style={{
                width: "100px",
                border: "1px solid #00425f",
                backgroundColor: "#004257",
                color: "#ffffff",
              }}
              onClick={this.migrateAgentAggregator}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "OK"}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
