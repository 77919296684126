import React from 'react';
import ContentHead from '../../../../../../components/content-head';
import Table from '../../../../../../components/table';
import BaseScene from '../../../../../../components/scenes/component';
import { PromptModal } from '../../../../../../components/modals/prompt-modal';
import { SUCCESS_STATUS, ERROR_STATUS } from '../../../../../../constants/api';
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { GridLayout } from '../../../../../../components/layouts/grid-layout'
import { platformManagement, userManagement } from '../../../../../../mixins/api'
import { PermissionGroupsExportSerializer } from '../../../../../../serializers'
import { AccessDenied } from '../../../../../../components/access-denied';
import { CAN_VIEW_PERMISSION_GROUP } from '../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class PermissionGroupGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
    },
    {
      Header: "Name",
      accessor: 'name',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Code",
      accessor: 'code',
      resizable: false,
      width: 150,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    }
    // {
    //   Header: "Action",
    //   accessor: 'permission',
    //   overflow: 'visible',
    //   resizable: false,
    //   sortable: false,
    //   headerStyle: {'white-space': 'unset'},
    //   getProps: (state, rowInfo, column) => {
    //     return {
    //       style: {
    //         lineHeight: '20px',
    //         textAlign: 'center',
    //         whiteSpace: 'unset',
    //       },
    //     };
    //   },
    //   width: 60,
    //   Cell: ({row, original}) => <div class="dropdown">
    //     <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
    //       <i class="la la-ellipsis-v"></i>
    //     </a>
    //     <div class="dropdown-menu dropdown-menu-right">
    //       <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}><Link to={`permission-group-management/view?id=${row.id}_${original.name}_${original.code}`} class="dropdown-item">
    //         <i class="la la-eye"></i>
    //         View
    //       </Link></ProtectedComponent>
    //     </div>
    //   </div>
    // }
  ]

  state = {
    permissionGroups: [],
  }
  sceneName = "permission-group-management"

  getAllPermissionGroups = this.getAllPermissionGroups.bind(this)

  componentDidMount () {
    this.getAllPermissionGroups()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }
  
  async getAllPermissionGroups () {
    this.setState({isLoading: true})
    const responseObj = await userManagement.getAllPermissionGroups()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        permissionGroups: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        isLoading: false,
        dataCount: 0,
        permissionGroups: [],
        errorMessage: response
      })
    }
  }

  getClickedAction (username, firstName, lastName, status) {
    this.setState({
      username: username,
      userStatus: status,
      firstName,
      lastName,
      modalIsOpen: true,
    })
  }

  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} subtitle={"Permission Groups"} filterPermissions={[CAN_VIEW_PERMISSION_GROUP]} totalCount={56} /*actionButtonText={"Add Permission"} actionButtonUrl={"/home/permission-management/add"} actionButtonRequiredPermissions={[CAN_VIEW_PERMISSION_GROUP]}*/ />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_PERMISSION_GROUP]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <Table 
            columns={this.columns} 
            data={this.state.permissionGroups} 
            loading={this.state.isLoading} 
            noDataText={`No permission group found`}
            dataCount={this.state.dataCount}
            getData={this.getAllPermissionGroups}
            exportData={PermissionGroupsExportSerializer.reversePermissionGroupDetails(this.state.permissionGroups)}
            exportHeaders={PermissionGroupsExportSerializer.headerExport()}
            exportFileName={'permission-groups'}
          />
          <PromptModal
            modalTitle={ this.state.userStatus+` User`}
            modalBody={`Do you want to ${this.state.userStatus} ${this.state.firstName} ${this.state.lastName} as a user?`}
            deleteModal={this.updateUser}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
