import qs from "querystring";
import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import ContentHead from "../../../../../../../components/content-head.js";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout.js";
import Notification from "../../../../../../../components/notification.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS
} from "../../../../../../../constants/api.js";
import { AGENT_TYPE_ID } from "../../../../../../../constants/domain-types.js";
import {
  CAN_ACTIVATE_AGENT,
  CAN_DEACTIVATE_AGENT,
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_RETIRE_AGENT,
  CAN_VIEW_AGENT,
  CAN_VIEW_TRANSACTION
} from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import {
  retrieveOtherInformation,
  saveOtherInformation
} from "../../../../../../../mixins/authentication.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import KycUpdateTable from "../../components/kyc-update-table.js";
import KYCUpdateSearch from "../../../../../../../components/forms/kyc-update-search.js";

export default class kycUpdateGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      kycBulkData: [],
      agentSearchParameters: {
        agentType: AGENT_TYPE_ID,
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      agentId: null,
      agentBusinessName: null,
      agentStatus: null,
      isLoading: false,
      errorMessage: null,
      successMessage: null,
    };
    this.updateAgentSearchParameters = this.updateAgentSearchParameters.bind(this);
    this.searchKycBulkData = this.searchKycBulkData.bind(this);
  }

  sceneName = "kyc-update";

  getClickedAction(agentId, businessName, status) {
    this.setState({
      agentId: agentId,
      agentStatus: status,
      agentBusinessName: businessName,
    });
  }

  updateAgentSearchParameters(type, value) {
    this.setState((prevState) => ({
      agentSearchParameters: {
        ...prevState.agentSearchParameters,
        [type]: value,
      },
    }));
  }

  async searchKycBulkData(pageNum, pageSize) {
    const { agentSearchParameters } = this.state;
    const payload = {
      batchId: agentSearchParameters && agentSearchParameters.batchId,
      category: agentSearchParameters && agentSearchParameters.category,
      approvalStatus: agentSearchParameters && agentSearchParameters.status,
    };
    this.setState({ isLoading: true, tableMessage: "" });

    try {
      const responseObj = await platformManagement.searchBulkUpdate(pageNum,
        pageSize, payload);
      const { status, response, count } = responseObj;
      if (status === SUCCESS_STATUS) {
        this.setState({
          kycBulkData: Array.isArray(response) ? response : [],
          dataCount: count,
          isLoading: false,
          pages: Math.ceil(count / pageSize),
          pageSize: pageSize,
          page: pageNum,
          startPage: null,
          tableMessage: response.length === 0 ? "No matching data found" : "",
        });
      } else {
        this.setState({
          kycBulkData: [],
          pages: 1,
          pageSize: pageSize,
          page: pageNum,
          dataCount: 0,
          isLoading: false,
          tableMessage: "Failed to fetch data. Please try again.",
        });
      }
    } catch (error) {
      this.setState({
        tableMessage: "An error occurred. Please try again.",
      });
    } finally {
      this.setState({ isLoading: false });
    }

  }
  handleSearchRequest = () => {
    this.searchKycBulkData(this.state.pageNum, this.state.pageSize);
  };

  getActiveBulkData(agents) {
    return agents.filter(function (value) {
      return value.statusId == 2;
    });
  }

  redirectToWalletJournal(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("agentDetails", JSON.stringify(agent));
  }

  redirectToCommissionHistory(agentDetails) {
    const agent = {
      agentCode: agentDetails.agentCode,
      businessName: agentDetails.businessName,
      agentMobileNo: agentDetails.agentMobileNo,
    };
    saveOtherInformation("commissionAgentDetails", JSON.stringify(agent));
  }

  render() {
    const modalBody = (
      <React.Fragment>

      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Kyc Update"}
          subtitle={this.state.parentBusinessName}
          filterPermissions={[CAN_VIEW_AGENT]}
          totalCount={56}
          actionButtonText={"Update New Kyc"}
          actionButtonUrl={"/home/kyc-update/upload"}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AGENT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <KYCUpdateSearch
              handleSearchRequest={this.handleSearchRequest}
              agentType={AGENT_TYPE_ID}
              preloadDate={false}
              agentSearchParameters={this.state.agentSearchParameters}
              updateAgentSearchParameters={this.updateAgentSearchParameters}
            />
            <KycUpdateTable
              data={this.state.kycBulkData}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getAgents={this.searchKycBulkData}
              getClickedAction={this.getClickedAction}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_AGENT}
              canActivate={CAN_ACTIVATE_AGENT}
              canDeactivate={CAN_DEACTIVATE_AGENT}
              canRetire={CAN_RETIRE_AGENT}
              canManageDomain={CAN_MANAGE_AGENT_DOMAIN}
              canViewTransaction={CAN_VIEW_TRANSACTION}
              redirectToWalletJournal={this.redirectToWalletJournal}
              redirectToCommissionHistory={this.redirectToCommissionHistory}
              canLoadData={this.state.canLoadData}
              downloadAgentData={this.downloadAgentData}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
