import React from "react";
import ContentHead from "../../../../../../../components/content-head.js";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import {
  SUCCESS_STATUS,
} from "../../../../../../../constants/api.js";
import Loader from "../../../../../../../components/loader.js";
import Notification from "../../../../../../../components/notification.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import { CAN_CREATE_AGENT_CLASS } from "../../../../../../../constants/permissions.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import DocumentTypeForm from "../../components/document-types-upload.js";

export default class AddDocumentTypeClassScene extends BaseScene {
  sceneName = "document-type-add";

  canSubmit = false;

  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      formData: {
        name: "",
        description: "",
      },
      documents: [],
      errors: {},
    };

    this.createDocType = this.createDocType.bind(this);
    this.editDocType = this.editDocType.bind(this);
    this.updateFormData = this.updateFormData.bind(this);

  }

  validateForm() {
    const { name } = this.state.formData;
    const errors = {};

    if (!name.trim()) {
      errors.name = "Field is required";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  onSubmitButtonClick = () => {
    const isValid = this.validateForm();

    if (!isValid) {
      return;
    }

    const { formData } = this.state;
    const currentPath = this.props.history.location.pathname;

    if (currentPath === "/home/document-type/add") {
      this.createDocType(formData);
    } else if (currentPath === "/home/document-type/edit") {
      this.editDocType(formData);
    } else {
      console.log("Invalid path");
    }
  };

  handleEditForm = (name, description) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        name,
        description,
      },
    }))
  }

  async createDocType() {

    this.setState({ isLoading: true });

    const payload = {
      name: this.state.formData.name,
      description: this.state.formData.description,
    };
    try {
      const responseObj = await platformManagement.createDocType(payload);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS) {
        this.setState((prevState) => ({
          documents: Array.isArray(prevState.documents)
            ? [...prevState.documents, response]
            : [response],
          isLoading: false,
          dataCount: prevState.dataCount + 1,
          successMessage: "Document type created successfully!",
        }));

        setTimeout(() => {
          this.props.history.push('/home/document-type');
        }, 2000);
      } else if (response.code === "40000") {
        this.setState({
          documents: [],
          isLoading: false,
          errorMessage: response.description,
        })
      } else {
        this.setState({
          documents: [],
          isLoading: false,
          errorMessage: "Failed to create document type.",
        });
      }
    } catch (error) {
      this.setState({
        documents: [],
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  async editDocType() {
    this.setState({ isLoading: true, isEditMode: true });
    const { state } = this.props.history.location;

    const payload = {
      name: this.state.formData.name,
      description: this.state.formData.description,
    };
    try {
      const responseObj = await platformManagement.editDocType(state.documentTypeFormData.id, payload);
      const { status } = responseObj;

      if (status === SUCCESS_STATUS) {
        this.setState({
          docTypeId: null,
          docTypeName: null,
          successMessage: "Doctype edited succesfully!",
          isLoading: false,
        });

        setTimeout(() => {
          this.props.history.push('/home/document-type');
        }, 2000);
      } else {
        this.setState({ errorMessage: "Failed to create document type.", isLoading: false });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        errorMessage: error.message || "An error occurred",
      });
    }
  }

  updateFormData = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };

  render() {
    const { errors } = this.state;

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_AGENT_CLASS]}>
          <button
            type="button"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            className="btn btn-primary"
            onClick={this.onSubmitButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Document Types"}
          withoutSearch
          subtitle={this.props.history.location.pathname === `/home/document-type/edit` ? "Edit Document Types" : "Create Document Types"}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_CREATE_AGENT_CLASS]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <DocumentTypeForm
              innerRef={form => this.form = form}
              onSubmit={this.onSubmitButtonClick}
              onChange={this.updateFormData}
              documentTypeFormData={this.state.formData}
              errors={errors}
              handleEditForm={this.handleEditForm}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
