import React, { useEffect, useState } from "react";
import { addArticle } from "./services/redux/actions/temp";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import HomeScene from "./scenes/home";
import LandingScene from "./scenes/landing";
import LoginScene from "./scenes/login";
import LogoutScene from "./scenes/logout";
import NotFoundScene from "./scenes/not-found";
import ForgotPasswordScene from "./scenes/forgot-password";
import ForgotPasswordResponseScene from "./scenes/forgot-password-response";
import ResetPasswordScene from "./scenes/reset-password";
import ChangePasswordScene from "./scenes/change-password";
import ResetPasswordMobileScene from "./scenes/reset-password-mobile";
import { isLogin } from "./mixins/authentication";
import { retrieveOtherInformation } from "./mixins/authentication";
import {
  RESET_EMAIL,
  FORGOT_PASSWORD_TOKEN,
} from "./constants/session-storage";

import "./App.css";
import { Banner, useNetworkState } from "./utils/hooks/useNetworkState";
import Notification from "./components/notification";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to="/home" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const ProtectedRoute = ({ component: Component, sessionType, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        retrieveOtherInformation(sessionType) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isOnline, connectionType, connectionSpeed } = useNetworkState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const checkNetworkStatus = () => {
    if (isOnline === "online") {
      setSuccessMessage(`You are back online`);

      setTimeout(() => {
        window.location.reload(false);
      }, 6000);
    } else if (isOnline === "offline") {
      setErrorMessage(`You are offline, check your connection`);
    } else {
      return "";
    }
  };
  useEffect(() => {
    checkNetworkStatus();
  }, [isOnline]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <>
            {errorMessage && (
              <Banner message={errorMessage} type={"alert-danger"} />
            )}
            {successMessage && (
              <Banner message={successMessage} type={"alert-success"} />
            )}
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

class App extends React.Component {
  render() {
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <Switch>
          <PublicRoute
            restricted={true}
            exact
            path="/"
            component={LandingScene}
          />
          <PublicRoute
            restricted={true}
            exact
            path={`/${process.env.REACT_APP_ROUTE_BASENAME}`}
            component={LandingScene}
          />
          <PrivateRoute path="/home" component={HomeScene} />
          <PublicRoute
            restricted={true}
            exact
            path="/login"
            component={LoginScene}
          />
          <PublicRoute
            restricted={false}
            exact
            path="/logout"
            component={LogoutScene}
          />
          <PublicRoute
            restricted={false}
            exact
            path="/forgot-password"
            component={ForgotPasswordScene}
          />
          <ProtectedRoute
            sessionType={RESET_EMAIL}
            exact
            path="/forgot-password-response"
            component={ForgotPasswordResponseScene}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/reset-password/:passwordResetUid"
            component={ResetPasswordScene}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/reset-password-phone/:passwordResetUid"
            component={ResetPasswordMobileScene}
          />
          <ProtectedRoute
            sessionType={FORGOT_PASSWORD_TOKEN}
            exact
            path="/change-password"
            component={ChangePasswordScene}
          />
          <Route component={NotFoundScene} />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    articles: state.temp.articles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addArticle: (article) => dispatch(addArticle(article)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
// testing
