import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SUCCESS_STATUS } from '../constants/api';
import { userManagement } from '../mixins/api';
import {
  clearLocalStorage,
  isAuthTokenExpired,
  saveSessionInformation,
} from '../mixins/authentication';
import ProtectedComponent from '../mixins/protected-component';
import { DatePickerComponent } from './date-picker';

export default class ContentHead extends Component {
  constructor() {
    super();
    if (isAuthTokenExpired()) {
      this.refreshUserToken();
    }
  }

  async refreshUserToken() {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const getUserResponseObj = await userManagement.refreshUserSession(
      currentUser.user.username,
      currentUser.refresh_token
    );
    const getUserResponse = getUserResponseObj.response;
    const getUserStatus = getUserResponseObj.status;

    console.log(getUserStatus, 'status content');
    if (getUserStatus === SUCCESS_STATUS) {
      currentUser['access_token'] = getUserResponse.data;
      saveSessionInformation(JSON.stringify(currentUser));
    } else {
      clearLocalStorage();
      window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/logout`;
    }
  }

  get actionButtonComponent() {
    if (this.props.downloadButtonUrl) {
      return (
        <a
          type='button'
          class='btn'
          style={{ backgroundColor: '#00425f', color: 'white' }}
          href={this.props.downloadButtonUrl}
          download
        >
          {this.props.downloadButtonText}
        </a>
      );
    }
    if (this.props.actionButtonUrlRedirect) {
      return (
        <Link
          to={this.props.actionButtonUrlRedirect}
          onClick={this.props.onActionButtonClick}
          class='btn btn-label-brand btn-bold'
          style={{ backgroundColor: '#00425f', color: 'white' }}
        >
          <i class='la la-plus' />
          {this.props.actionButtonText}
        </Link>
      );
    }
    return (
      <Link
        to={this.props.actionButtonUrl}
        onClick={this.props.onActionButtonClick}
        class='btn btn-label-brand btn-bold'
        style={{ backgroundColor: '#00425f', color: 'white' }}
        data-toggle={this.props.showTooltip ? 'kt-tooltip' : ''}
        data-skin='default'
        title={this.props.tooltipTittle}
      >
        <i class='la la-plus' />
        {this.props.actionButtonText}
      </Link>
    );
  }

  render() {
    const activeServiceType = this.props.activeServiceType;

    return (
      <div class='kt-subheader kt-grid__item' id='kt_subheader'>
        <div class='kt-subheader__main'>
          <h3 class='kt-subheader__title'>{this.props.title}</h3>

          {this.props.subtitle && (
            <React.Fragment>
              <span class='kt-subheader__separator kt-subheader__separator--v'></span>
              <div class='kt-subheader__subtitle'>{this.props.subtitle}</div>
            </React.Fragment>
          )}

          {this.props.domainTypes && (
            <div
              class='kt-form__control'
              style={{ width: '170px', marginLeft: '10px' }}
            >
              <select
                class='form-control kt-selectpicker'
                onChange={(event) => this.props.getDomains(event.target.value)}
              >
                {this.props.domainTypes
                  ? this.props.domainTypes.map(function (domainType) {
                    return (
                      <option value={domainType.id}>{domainType.name}</option>
                    );
                  })
                  : ''}
              </select>
            </div>
          )}

          {this.props.domains && (
            <div
              class='kt-form__control'
              style={{ width: '170px', marginLeft: '10px' }}
            >
              <select
                class='form-control kt-selectpicker'
                onChange={(event) =>
                  this.props.getRolesUsers(event.target.value)
                }
              >
                <option>Select</option>
                {this.props.domains.map(function (domain) {
                  return (
                    <option value={domain.agentCode}>
                      {domain.businessName}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {this.props.serviceTypes && (
            <div
              class='kt-form__control'
              style={{ width: '170px', marginLeft: '10px' }}
            >
              <select
                class='form-control kt-selectpicker'
                onChange={(event) =>
                  this.props.filterByServiceType(event.target.value)
                }
              >
                <option selected value={`all`}>
                  All
                </option>
                {this.props.serviceTypes.map(function (serviceType) {
                  return (
                    <option
                      value={serviceType.id}
                      selected={
                        activeServiceType == serviceType.id ? 'selected' : ''
                      }
                    >
                      {serviceType.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {this.props.withCustomSearchAgentStatus &&
            (this.props.filterPermissions ? (
              <ProtectedComponent
                requiredPermissions={this.props.filterPermissions}
              >
                <div
                  class='kt-form__control'
                  style={{ width: '150px', marginLeft: '10px' }}
                >
                  <select
                    class='form-control kt-selectpicker'
                    id='kt-agent-status'
                    onChange={(event) => this.props.filter(event.target.value)}
                  >
                    <option value='all'>All Statuses</option>
                    {this.props.allStatuses
                      ? this.props.allStatuses.map(function (status) {
                        return (
                          <option value={status.id}>{status.name}</option>
                        );
                      })
                      : ''}
                  </select>
                </div>
              </ProtectedComponent>
            ) : (
              ''
            ))}

          {this.props.withCustomSearch &&
            (this.props.filterPermissions ? (
              <ProtectedComponent
                requiredPermissions={this.props.filterPermissions}
              >
                <div
                  class='kt-form__control'
                  style={{ width: '150px', marginLeft: '10px' }}
                >
                  <input
                    type='text'
                    class='form-control'
                    onChange={(event) =>
                      this.props.filterApplications(event.target.value)
                    }
                  />
                </div>
              </ProtectedComponent>
            ) : (
              ''
            ))}

          {this.props.withCustomSearchAgentDate &&
            (this.props.filterPermissions ? (
              <ProtectedComponent
                requiredPermissions={this.props.filterPermissions}
              >
                <div style={{ marginLeft: '10px' }}>
                  <DatePickerComponent
                    onChange={(event) => this.setState({ startDate: event })}
                    size={`150px`}
                    format={`dd/MM/yyyy`}
                    isClearable={true}
                  />
                </div>
                <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                  <strong>-</strong>
                </div>
                <div style={{ marginLeft: '0px' }}>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.filterByDate(this.state.startDate, event)
                    }
                    size={`150px`}
                    format={`dd/MM/yyyy`}
                    isClearable={true}
                  />
                </div>
              </ProtectedComponent>
            ) : (
              ''
            ))}

          <div
            class='kt-subheader__group kt-hidden'
            id='kt_subheader_group_actions'
          >
            <div class='kt-subheader__desc'>
              <span id='kt_subheader_group_selected_rows'></span> Selected:
            </div>
            <div class='btn-toolbar kt-margin-l-20'>
              <div
                class='dropdown'
                id='kt_subheader_group_actions_status_change'
              >
                <button
                  type='button'
                  class='btn btn-label-brand btn-bold btn-sm dropdown-toggle'
                  data-toggle='dropdown'
                >
                  Update Status
                </button>
                <div class='dropdown-menu'>
                  <ul class='kt-nav'>
                    <li class='kt-nav__section kt-nav__section--first'>
                      <span class='kt-nav__section-text'>
                        Change status to:
                      </span>
                    </li>
                    {this.props.allStatuses
                      ? this.props.allStatuses.map(function (status) {
                        const className =
                          'kt-badge kt-badge--unified-' +
                          status.class +
                          ' kt-badge--inline kt-badge--bold';
                        return (
                          <li class='kt-nav__item'>
                            <a
                              href='#'
                              class='kt-nav__link'
                              data-toggle='status-change'
                              data-status={status.id}
                            >
                              <span class='kt-nav__link-text'>
                                <span class={className}>{status.name}</span>
                              </span>
                            </a>
                          </li>
                        );
                      })
                      : ''}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='kt-subheader__toolbar kt-section__content kt-section__content--border'>
          {this.props.actionButtonText &&
            (this.props.actionButtonRequiredPermissions ? (
              <ProtectedComponent
                requiredPermissions={this.props.actionButtonRequiredPermissions}
              >
                {this.actionButtonComponent}
              </ProtectedComponent>
            ) : (
              this.actionButtonComponent
            ))}
          {this.props.downloadButtonUrl && (
            <ProtectedComponent
              requiredPermissions={this.props.actionButtonRequiredPermissions}
            >
              {this.actionButtonComponent}
            </ProtectedComponent>
          )}
          {this.props.ctaWidgets}
          {this.props.applicationButton &&
            (this.props.filterPermissions ? (
              <ProtectedComponent
                requiredPermissions={this.props.actionButtonRequiredPermissions}
              >
                {this.props.applicationButton}
              </ProtectedComponent>
            ) : (
              ''
            ))}
          {this.props.exportButton &&
            (this.props.filterPermissions ? (
              <ProtectedComponent
                requiredPermissions={this.props.filterPermissions}
              >
                {this.props.exportButton}
              </ProtectedComponent>
            ) : (
              ''
            ))}
        </div>

        {this.props.showUploadButton && (
          <div className='kt-subheader__toolbar kt-section__content kt-section__content--border'>
            <button
              className='btn btn-label-brand btn-bold'
              style={{ backgroundColor: '#00425f', color: 'white' }}
              onClick={this.props.onUploadButtonClick}
            >
              Upload File
            </button>
          </div>
        )}
      </div>
    );
  }
}
