import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getPaginationRows } from "../utils/helper";
import Pagination from "./pagination";
import SpinnerComponent from "./spinner";
import TableExtra from "./table-extra";

export default class Table extends React.Component {
  state = {
    tableHeight: "700px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    return (
      <React.Fragment>
        <TableExtra
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getData}
          zoom={this.state.zoom}
          data={this.props.exportData}
          headers={this.props.exportHeaders}
          fileName={this.props.exportFileName}
          disabled={this.props.loading}
          downloadLink1={this.props.downloadLink1}
          //disabled={this.props.disabled ? this.props.disabled : false}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={this.props.columns}
          pageSizes={getPaginationRows(this.props.dataCount)}
          dataCount={this.props.dataCount}
          defaultSorted={this.props.defaultSorted}
          headerNodeHeight={`123`}
          showTotalCount={true}
          minRows={this.props.data.length > 0 ? 10 : 10}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          // getTableProps={(state, rowInfo, column, instance) => ({
          //   style: {
          //     borderColor: '#00425f',
          //     boxSizing: 'none',
          //     border: 'none',
          //   },
          // })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "2px",
              paddingRight: "2px",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
