import qs from "querystring";
import React from "react";
import { Redirect } from "react-router-dom";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { CAN_CANCEL_LIEN } from "../../../../../../../constants/permissions.js";
import { walletProxyService } from "../../../../../../../mixins/api";
import { retrieveSessionInformation } from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import CancelLienForm from "../../components/cancel-lien-form";

export default class CancelLienScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      formattedConfirmationData: {},
      permissions: [],
      roleDetails: [],
    };

    this.proceedLien = this.proceedLien.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
  }

  componentDidMount() {
    this.roleId = qs.parse(window.location.search)["?roleId"];
    const currentUser = retrieveSessionInformation();
    this.username = currentUser.user.username;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
        window.location.href = "admin/home/lien-management";
      }, 10000);
    }
  }

  async proceedLien(values) {
    this.setState({
      isLoading: true,
    });
    const formDataRequired = {
      accountCode: values.customerMsisdn,
      accountName: values.accountName,
      amount: values.amount * 100,
      reasonDesc: values.narration,
      approvedBy: this.username,
      availableBalance: values.availableBalance,
      channel: "web",
    };

    const { response, status } = await walletProxyService.cancelLienTransaction(
      formDataRequired
    );

    this.setState({
      isLoading: false,
      errorMessage: status === ERROR_STATUS ? response : null,
      successMessage:
        status === SUCCESS_STATUS
          ? "Cancel Lien successfully initiated. Pending approval"
          : null,
    });
  }

  isFormValid() {
    return (
      Boolean(this.state.formData.amount) &&
      Boolean(this.state.formData.customerMsisdn) &&
      Boolean(this.state.formData.narration)
    );
  }

  render() {
    const { hasFormBeenSubmitted } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home/lien-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CANCEL_LIEN]}>
          <button
            disabled={hasFormBeenSubmitted}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={() => {
              this.setState({ hasFormBeenSubmitted: true });
              this.form.submitForm();
            }}
          >
            Submit
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Lien Management"}
          withoutSearch
          subtitle="Cancel a Lien"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_CANCEL_LIEN]}
        >
          <GridLayout>
            <CancelLienForm
              allPermissions={this.state.permissions}
              formData={this.state.formData}
              hasFormBeenSubmitted={this.state.hasFormBeenSubmitted}
              innerRef={(form) => (this.form = form)}
              onChange={this.updateFormData}
              onSubmit={(values) => this.proceedLien(values)}
              roleDetails={this.state.roleDetails}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  updateFormData(key, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [key]: value,
      },
    }));
  }
}
