import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
// import ViewFipScene from './scenes/view-fip';
import UploadQuickStockGridScene from "../quick-stocks/scenes/quick-upload-stock-grid";
import StocksUploadGridScene from "./scenes/stock-upload-grid";
import UploadStocksGridScene from "./scenes/upload-stock-grid";
// import UploadQuickStockGridScene from "./"
// import NewStockConfigScene from './scenes/new-stock-config';
// import ViewStockScene from './scenes/view-stock';
// import AddFipScene from './scenes/add-fip'
import { CAN_GET_DEVICES_BY_UUID } from "../../../../../constants/permissions.js";
import { isAuthorized } from "../../../../../mixins/authentication";
import StockGridScene from "../../pos-management/all-stocks/components/pos-stock-grid/scene";
import QuickStockGridScene from "../../pos-management/quick-stocks/scenes/quick-grid";
// import BringStockGridScene from "../bring-stocks/scenes/bring-stock-grid/scene";

export default class UploadStocksScene extends Component {
  sceneName = "upload-stocks";

  // componentDidMount() {
  //   this.props.updateScene(this.sceneName);
  // }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/upload-stocks"
          component={UploadStocksGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/upload-quick-stocks"
          component={UploadQuickStockGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/stocks-upload"
          component={StocksUploadGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="home/pos-management/allpos-stocks"
          component={StockGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="home/pos-management/quick-upload"
          component={QuickStockGridScene}
        />
        {/* <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="home/pos-management/bring-stocks"
          component={BringStockGridScene}
        /> */}
        {/* <ProtectedRoute requiredPermissions={[CAN_REMAP_DEVICE]} exact path='/home/pos-management/pos-stocks/:id/view' component={ViewStockScene} />  */}
        {/* <ProtectedRoute requiredPermissions={[CAN_REMAP_DEVICE]} exact path='/home/pos-management/pos-stocks/add' component={NewStockConfigScene} />  */}
      </Router>
    );
  }
}
