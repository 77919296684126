import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import Pagination from "../../../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../../../components/spinner";
import TableExtra from "../../../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";
import { getPaginationRows } from "../../../../../../../../utils/helper";

export default class PosRequestsAwaitingConfigTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "Title",
        accessor: "contactTitle",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 250,
        // Cell: (props) => (
        //   <span class="word-capitalize">
        //     {props.value ? props.value.toLowerCase() : ""}
        //   </span>
        // ),
      },

      {
        Header: "Mobile Phone",
        accessor: "mobilePhone",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 220,
        // Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: "ID",
        accessor: "merchantId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        // Cell: ({ row, original }) => (
        //   <span>{original.responseCode ? original.responseCode : ""}</span>
        // ),
      },

      {
        Header: "Status",
        accessor: "status",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 250,
        // Cell: (props) => (
        //   <span>
        //     &#x20A6;
        //     {props.value
        //       ? formatCurrency(convertToMajor(props.value))
        //       : formatCurrency(0)}
        //   </span>
        // ),
      },

      {
        Header: "Address",
        accessor: "physicalAddr",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        // Cell: (props) => {
        //   return props.value ? formatDateTime(props.value) : "";
        // },
      },
      {
        Header: "Email",
        accessor: "email",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 280,
      },
      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 50,
        // Cell: ({row, original}) => <ProtectedComponent requiredPermissions={[this.props.canView]}>
        //   <button type="button" class="btn btn-secondary btn-xs link-button" data-toggle="modal" data-target="#transaction_details_modal" onClick={() => this.props.getClickedAction(original, 'view')}>
        //     View
        //   </button>
        // </ProtectedComponent>
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#pos_awaiting_config_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          fileName={"Pos Requests Awaiting Configuration"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAllPosRequestsAwaitingConfig}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          disabled={this.props.loading}
          downloadLink={this.props.downloadStocks}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              agentUsername,
              approvalStatus,
              toDate,
              fromDate,
              pageNumber,
              pageSize,
            } = this.props.searchParameters;
            this.setState({ page: state.pageNumber });
            if (this.props.canLoadData) {
              this.props.getAllPosRequestsAwaitingConfig(
                state.page + 1,
                state.pageSize,
                agentUsername,
                approvalStatus,
                toDate,
                fromDate,
                pageNumber,
                pageSize
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "20px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
