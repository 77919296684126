import qs from 'querystring';
import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import { GridItem } from '../../../../../../../components/layouts/grid-item';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_UPDATE_FEE } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';

export default class ViewExternalFeeScene extends BaseScene {
    
    notificationSectionRef = React.createRef(null)

    constructor (props) {
        super(props)
        this.state = {
            canSave: null,
            categories: [],
            services: [],
            serviceOptions: [],
            externalFeeData: [],
        }
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    }

    componentDidMount() {
        const externalFeeId = qs.parse(window.location.search)['?externalFeeId']
        this.getExternalFee(externalFeeId)
        this.getCategories()
        this.getServices()
        this.getServiceOptions()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async getExternalFee (externalFeeId) {
        this.setState({isLoading: true})
        const responseObj = await feeConfigurationManagement.getExternalFee(externalFeeId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                externalFeeData: response,
                isLoading: false
            })
        }else{
            this.setState({
                externalFeeData: [],
                isLoading: false,
                errorMessage: response
            })
        }
    }

    async getCategories () {
        const responseObj = await feeConfigurationManagement.getCategories()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                categories: response,
            })
        }else{
            this.setState({
                categories: []
            })
        }
    }

    async  getServices () {
        const responseObj = await feeConfigurationManagement.getServices()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                services: response,
            })
        }else{
            this.setState({
                services: []
            })
        }
    }

    async getServiceOptions () {
        const responseObj = await feeConfigurationManagement.getServiceOptions()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                serviceOptions: response,
            })
        }else{
            this.setState({
                serviceOptions: []
            })
        }
    }

    async onSaveButtonClick () {
        this.setState({isLoading: true})

        const createRevenueSharingPartyObj = await this.feeConfigurationManagement.addExternalFee(this.state.externalFeeData.serviceOption)

        const createRevenueSharingPartyResponseStatus = createRevenueSharingPartyObj.status
        const createRevenueSharingPartyResponse = createRevenueSharingPartyObj.response

        window.scrollTo(0, this.notificationSectionRef.offsetTop)
    
        if (createRevenueSharingPartyResponseStatus === SUCCESS_STATUS) {
            this.setState({
                isLoading: false,
                successMessage: 'External fee created successfully.',
            })
        }else{
            this.setState({
                isLoading: false,
                errorMessage: createRevenueSharingPartyResponse,
            })
        }
        return
    }

    isformValid () {
        return false
        //return !this.state.feeRuleFormData.serviceType
        //return !this.isRevenueSplitsValid() || !this.state.feeRuleFormData.serviceType || !this.state.feeRuleFormData.service || !this.state.feeRuleFormData.serviceOption || !this.state.feeRuleFormData.agentCategory || !this.state.feeRuleFormData.feeToApply || !this.state.feeRuleFormData.sharingPartySource
    }

    render () {

        const ctaWidgets = <React.Fragment>
            <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE]}><button type="button" disabled={this.isformValid()} class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
        </React.Fragment>

        return <React.Fragment>
            <Loader
                isLoading={this.state.isLoading}
            />
            <Notification 
                ref={this.notificationSectionRef}
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"External Fees"} withoutSearch subtitle="Add External Fee" ctaWidgets={ctaWidgets} />
            <GridItem isFluid class="kt-content">
                <div class="kt-portlet">
                    <form class="kt-form kt-form--label-right">
                        <div class="kt-portlet__body">
                            <div class="form-group col-lg-6">
                                <label>Category *:</label>
                                <select name="category" class="form-control" onChange={event => this.setState({
                                    externalFeeData: {...this.state.externalFeeData, category: event.target.value}
                                })} >
                                    <option selected disabled>Select</option>
                                    {this.state.categories.map(value => <option value={value.id}>{value.name}</option>)}
                                </select>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Service *:</label>
                                <select name="service" class="form-control" onChange={event => this.setState({
                                    externalFeeData: {...this.state.externalFeeData, service: event.target.value}
                                })} >
                                    <option selected disabled>Select</option>
                                    {this.state.services.map(value => <option value={value.id}>{value.name}</option>)}
                                </select>
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Service Option *:</label>
                                <select name="serviceOption" class="form-control" onChange={event => this.setState({
                                    externalFeeData: {...this.state.externalFeeData, serviceOption: event.target.value}
                                })} >
                                    <option selected disabled>Select</option>
                                    {this.state.serviceOptions.map(value => <option value={value.id}>{value.name}</option>)}
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </GridItem>
        </React.Fragment>
    }
}
