import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ViewTraceScene from './scenes/view-trace';
import TraceGridScene from './scenes/trace-grid';
import ArchiveGridScene from './scenes/archive';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_FIP_USER, CAN_CREATE_FIP_USER } from '../../../../../constants/permissions.js'

export default class TraceScene extends Component {
  sceneName = 'instrumentation'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FIP_USER]} exact path='/home/instrumentation/trace' component={TraceGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FIP_USER]} exact path='/home/instrumentation/trace/:id/view' component={ViewTraceScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FIP_USER]} exact path='/home/instrumentation/trace/archive' component={ArchiveGridScene} />
    </Router>
  }
}
