import React from 'react';
import { Link } from 'react-router-dom';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_ACTIVATE_REGION, CAN_CREATE_REGION, CAN_DEACTIVATE_REGION, CAN_DELETE_REGION, CAN_UPDATE_REGION, CAN_VIEW_REGION } from '../../../../../../../constants/permissions.js';
import { platformManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { pastTenseFormatter } from '../../../../../../../utils/formatter';
import RegionTable from '../../components/region-table';

export default class RegionGridScene extends BaseScene {
  
  sceneName = 'region-management'

  state={
    regions: []
  }

  getRegions = this.getRegions.bind(this)
  getClickedAction = this.getClickedAction.bind(this)
  updateRegionStatus = this.updateRegionStatus.bind(this)
  
  componentDidMount() {
    //this.getRegions()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }
  async getRegions (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getRegions(pageNum, pageSize, this.state.searchParams)
    const { status, response} = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        regions: response.content,
        dataCount: response.count,
        pages: Math.ceil(response.count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
      })
    }else{
      this.setState({
        regions: [],
        dataCount: 0,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response
      })
    }
  }

  getClickedAction (name, id, status) {
    this.setState({
      regionId: id,
      regionName: name,
      regionStatus: status
    })
  }

  async updateRegionStatus () {
    this.setState({isLoading: true})
    if(this.state.regionStatus === 'delete') {
      const responseObj = await platformManagement.deleteRegion(this.state.regionId)
      const { status, response } = responseObj
      if (status === SUCCESS_STATUS) {
        this.setState({
          regionName: null,
          regionStatus: null,
          regionId: null,
          successMessage: `${this.state.regionName} was successfully ${pastTenseFormatter(this.state.regionStatus)} as a region.`,
          isLoading: false,
        })
      }else{
        this.setState({
          errorMessage: response,
          isLoading: false, 
        })
      }
    }else{
      const responseObj = await platformManagement.updateRegionStatus(this.state.regionStatus === 'deactivate' ? 1 : 0, this.state.regionId)
      const { status, response } = responseObj
      if (status === SUCCESS_STATUS) {
        this.setState({
          regionName: null,
          regionStatus: null,
          regionId: null,
          successMessage: `Region status updated succesfully!`,
          isLoading: false,
        })
      }else{
        this.setState({
          errorMessage: response,
          isLoading: false, 
        })
      }
    }
    this.getRegions()
  }

  render () {
    const ctaWidgets = <React.Fragment>
       <ProtectedComponent requiredPermissions={[CAN_CREATE_REGION]}><Link type="button" to="region-management/add" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}}>Add Region</Link></ProtectedComponent>
       <ProtectedComponent requiredPermissions={[CAN_CREATE_REGION]}><Link type="button" to="region-management/map" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}}>Map to LGA</Link></ProtectedComponent>
    </React.Fragment>
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Regions"} filterPermissions={[]} totalCount={56} ctaWidgets={ctaWidgets}/>
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_REGION]}>
        <GridLayout>
          <RegionTable 
            pages={this.state.pages}
            getRegions={this.getRegions}
            data={this.state.regions} 
            startPage={this.state.startPage}
            loading={this.state.isLoading}
            noDataText={`No region found`}
            dataCount={this.state.dataCount}
            pageSize={this.state.pageSize}
            page={this.state.page}
            getClickedAction={this.getClickedAction}
            canActivate={CAN_ACTIVATE_REGION}
            canDeactivate={CAN_DEACTIVATE_REGION}
            canDelete={CAN_DELETE_REGION}
            canUpdate={CAN_UPDATE_REGION}
            canView={CAN_VIEW_REGION}
          />
          <PromptModal
            modalTitle={ this.state.regionStatus+` Region`}
            modalBody={`Do you want to ${this.state.regionStatus} ${this.state.regionName} as a region?`}
            deleteModal={this.updateRegionStatus}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
