import Requester from '../requester';

const API_BASE_URL = process.env.REACT_APP_INSTRUMENTATION_SERVICE_API_BASE_URL;

export default class InstrumentationService {
  constructor (props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }

  getTraceData(payload) {
    const { pageNumber, pageSize, serviceName, startDate, endDate } = payload
    return this.apiRequester.post({
      endpoint: 'logs/trace/query',
      body: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        // filter: {
        //   ...(serviceName != "" ? { service: serviceName} : {})
        // },
        ...(serviceName != "" ? { search: serviceName} : {}),
        range: {
          ...(startDate != "" ? { start: startDate, end: endDate} : {})
        }
      }
    })
  }

  getTraceById(id) {
    return this.apiRequester.get({
      endpoint: `logs/trace/${id}`
    })
  }

  getArchiveData(payload) {
    const { pageNumber, pageSize, serviceName, startDate, endDate } = payload
    return this.apiRequester.post({
      endpoint: 'logs/archive/query',
      body: {
        pageNo: pageNumber,
        pageSize: pageSize,
        // filter: {
        //   ...(serviceName != "" ? { service: serviceName} : {})
        // },
        ...(serviceName != "" ? { service: serviceName} : {}),
        range: {
          ...(startDate != "" ? { start: startDate, end: endDate} : {})
        }
      }
    })
  }
  
}