import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { CAN_VIEW_AGENT, CAN_VIEW_TRANSACTION } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import AgentsGridScene from './scenes/agents-grid';
import ReconUploadGridScene from './scenes/exemption-upload-grid/index.js';

export default class ExemptedAgentsScene extends Component {
  sceneName = 'exempted-agent-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/exempted-agent-management' component={AgentsGridScene} />
      <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact path="/home/exempted-agent-management/upload"
          component={ReconUploadGridScene}
        />
    </Router>
  }
}
