import { convertToMajor } from '../../utils/converter';
import {
    formatCurrency, formatLongNumber
} from '../../utils/formatter';

export default class EndOfDayReportExportSerializer {
    static headerExport () {
        return [
            { label: 'Date Created', key: 'dateCreated', title: 'Date Created', dataIndex: 'dateCreated' },
            { label: 'Business Name', key: 'accountName', title: 'Business Name', dataIndex: 'accountName' },
            { label: 'Business Phone', key: 'accountNumber', title: 'Business Phone', dataIndex: 'accountNumber' },
            { label: 'Agent ID', key: 'agentID', title: 'Agent ID', dataIndex: 'agentID' },
            { label: 'Ledger Balance', key: 'ledgerBalance', title: 'Ledger Balance', dataIndex: 'ledgerBalance' },
            { label: 'Commission Balance', key: 'commBalance', title: 'Commission Balance', dataIndex: 'commBalance' },
            { label: 'Lien Balance', key: 'lienBalance', title: 'Lien Balance', dataIndex: 'lienBalance' }
        ];
    }

    static reverseEndOfDayReportDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
              dateCreated, accountName, accountNumber, agentID,
              ledgerBalance, commBalance, lienBalance
            } = detail

            let detailFormData = {
                agentID: agentID ? formatLongNumber(agentID) : '',
                accountNumber: accountNumber ? formatLongNumber(accountNumber) : '',
                ledgerBalance: ledgerBalance ? `₦${formatCurrency(convertToMajor(ledgerBalance))}` : `₦${formatCurrency(0)}`,
                commBalance: commBalance ? `₦${formatCurrency(convertToMajor(commBalance))}` : `₦${formatCurrency(0)}`,
                lienBalance: lienBalance ? `₦${formatCurrency(convertToMajor(lienBalance))}` : `₦${formatCurrency(0)}`,
                accountName,
                dateCreated: dateCreated ? dateCreated : ''
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}