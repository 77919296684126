import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AuditReportGridScene from './scenes/audit-report-grid';
import BaseScene from '../../../../../components/scenes/component';
import ViewAuditReportScene from './scenes/view-audit-report';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_AUDIT } from '../../../../../constants/permissions.js'

export default class AuditReportScene extends BaseScene {
  sceneName = 'audit-report'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AUDIT]} exact path='/home/audit-report' component={AuditReportGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AUDIT]} exact path="/home/audit-report/:id/view" component={ViewAuditReportScene} />
    </Router>
  }
}
