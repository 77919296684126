import React from "react";
import Select from "react-select";


// const styles = {
//     control: (base) => ({
//       ...base,
//       minHeight: 32,
//     }),
//     dropdownIndicator: (base) => ({
//       ...base,
//       paddingTop: 0,
//       paddingBottom: 0,
//     }),
//     clearIndicator: (base) => ({
//       ...base,
//       paddingTop: 0,
//       paddingBottom: 0,
//     }),
// };

export default class SelectComponent extends React.Component {
  styles = {
    control: (base) => ({
      ...base,
      minHeight: this.props.minHeight ? this.props.minHeight : 32,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };
  state = {
    selectedOption: { label: null, value: null },
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  render() {
    const {
      selectedOption: { value },
    } = this.state;

    return (
      <Select
        styles={this.styles}
        //value={this.props.defaultValue ? this.props.defaultValue : selectedOption}
        value={this.props.options.filter((option) =>
          value
            ? option.value === value
            : option.value === this.props.defaultValue
        )}
        isDisabled={this.props.disabled}
        onChange={(event) => {
          this.props.onChange(event);
          this.handleChange(event);
        }}
        options={this.props.options}
        placeholder={this.props.placeholder}
        isLoading={this.props.isLoading}
      />
    );
  }
}
