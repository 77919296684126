import React from 'react';
import { ReactBingmaps } from 'react-bingmaps';

export default class MapComponent extends React.Component {
    render() {
        return <React.Fragment>
            <ReactBingmaps 
                bingmapKey = "ApadSxvR_qR3YajjAK3j2AdhjlFEfX3nDsutiV8hMuDl_dtx2dBbRbgbKuGZin75 "
                center = {[ 9.072264, 7.491302]}
                zoom={6}
                disableStreetside={true}
                infoboxesWithPushPins = {
                    this.props.agentLocation && this.props.agentLocation.map(agentDetails => {
                        return(
                            {
                                "location": [agentDetails.latitude, agentDetails.longitude],
                                "addHandler":"mouseover",
                                "infoboxOption": { title: agentDetails.businessName },
                                "pushPinOption":{ color: '#00425f' },
                            }
                        );
                    })
                }
            > 
            </ReactBingmaps>
        </React.Fragment>
    }
}