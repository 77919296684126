import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_MESSAGING_READ_TOPIC, CAN_MESSAGING_WRITE_TOPIC } from '../../../../../constants/permissions.js'
import RecurringMessageGridScene from '../scenes/recurring-message-grid/scene';
import AddRecurringMessageScene from '../scenes/add-recurring-message';
import BaseScene from '../../../../../components/scenes/component';

export default class RecurringMessageScene extends BaseScene {
  sceneName = 'recurring-message'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TOPIC]} exact path='/home/notifications/recurring-message' component={RecurringMessageGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_READ_TOPIC]} exact path='/home/notifications/recurring-message/:id/view' component={AddRecurringMessageScene} />
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_WRITE_TOPIC]} exact path='/home/notifications/recurring-message/add' component={AddRecurringMessageScene} />
    </Router>
  }
}
