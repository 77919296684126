import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import BaseScene from '../../../../components/scenes/component';
import { CAN_CREATE_APPLICATION, CAN_VIEW_APPLICATION } from '../../../../constants/permissions.js';
import { isAuthorized } from '../../../../mixins/authentication';
import AddApplicationScene from './scenes/add-application';
import ApplicationsGridScene from './scenes/applications-grid';
import ViewApplicationScene from './scenes/view-application';
import ViewApplicationAggregatorScene from './scenes/view-application-aggregator/scene';

export default class ApplicationsScene extends BaseScene {
  sceneName = 'application-management'
  
  componentDidMount () {
    console.log('>>>>', this.props)

    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_APPLICATION]} exact path='/home/application-management' component={ApplicationsGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_APPLICATION]} exact path='/home/application-management/:id/view' component={ViewApplicationScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_APPLICATION]} exact path='/home/application-management/:id/view-aggregator' component={ViewApplicationAggregatorScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_APPLICATION]} exact path='/home/application-management/:id/add' component={AddApplicationScene} />
    </Router>
  }
}
