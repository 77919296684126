import { formatLongNumber, formatDateTime } from '../../utils/formatter';
import { getCountryName, getStateName, getLgaName, capitalizeFirstLetter, getApplicationType, sentenceCapitalization  } from '../../utils/helper';

export default class SuperAgentExportSerializer {
    static headerExport () {
        return [
            { label: 'Code', key: 'agentCode', title: 'Code', dataIndex: 'agentCode'},
            { label: 'Business Name', key: 'businessName', title: 'Business Name', dataIndex: 'businessName'},
            { label: 'Business Email', key: 'businessEmail', title: 'Business Email', dataIndex: 'businessEmail'},
            { label: 'Business Phone Number', key: 'businessPhoneNo', title: 'Business Phone Number', dataIndex: 'businessPhoneNo'},
            { label: 'Agent Phone Number', key: 'agentMobileNo', title: 'Agent Phone Number', dataIndex: 'agentMobileNo'},
            { label: 'Agent Class', key: 'agentClass', title: 'Agent Class', dataIndex: 'agentClass'},
            { label: 'Registration Number', key: 'companyRegNo', title: 'Registration Number', dataIndex: 'companyRegNo'},
            { label: 'Date Last Updated', key: 'dateLastUpdated', title: 'Date Last Updated', dataIndex: 'dateLastUpdated'},
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'}

        ]
    }

    static reverseSuperAgentDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { agentCode, businessName, businessEmail, businessPhoneNo, agentMobileNo, 
                companyRegNo, dateLastUpdated, status, agentClass
            } = details
            let detailFormData = {
                agentCode: agentCode ? agentCode : '',
                businessEmail: businessEmail ? businessEmail : '',
                businessPhoneNo: businessPhoneNo ? formatLongNumber(businessPhoneNo) : '',
                agentMobileNo: agentMobileNo ? formatLongNumber(agentMobileNo) : '',
                businessName: businessName ? businessName : '',
                companyRegNo: companyRegNo ? formatLongNumber(companyRegNo) : '',
                dateLastUpdated: dateLastUpdated ? dateLastUpdated : '',
                status: status ? status : '',
                agentClass: agentClass ? agentClass : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}