import { Builder } from '@vojtechportes/react-query-builder'
import React from 'react'
import { AccessDenied } from '../../../../../../components/access-denied'
import ContentHead from '../../../../../../components/content-head'
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import BaseScene from '../../../../../../components/scenes/component'
import { SUCCESS_STATUS } from '../../../../../../constants/api'
import { CAN_MESSAGING_WRITE_TOPIC } from '../../../../../../constants/permissions'
import { messagingService, platformManagement } from '../../../../../../mixins/api'
import ProtectedComponent from '../../../../../../mixins/protected-component'
import { stripNumbersFromString } from '../../../../../../utils/helper'

const fields = [
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'age', label: 'Age' },
  { name: 'address', label: 'Address' },
  { name: 'phone', label: 'Phone' },
  { name: 'email', label: 'Email' },
  { name: 'twitter', label: 'Twitter' },
  { name: 'isDev', label: 'Is a Developer?', defaultValue: false }
];

const queryFields = [
  {
    field: "agent_class",
    label: "Agent Class",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      { value: "3", label: "Classic" },
      { value: "14", label: "Gold" },
      { value: "17", label: "Premium" },
      { value: "10007", label: "micro" }
    ]
  },
  {
    field: "status",
    label: "Status",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Enable",
        value: "1"
      },
      {
        label: "Disabled",
        value: "2"
      }
    ]
  },
  {
    field: "business_type_id",
    label: "Business Type",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      { value: "1", label: "Partnership" },
      { value: "2", label: "Sole Proprietorship" },
      { value: "3", label: "Limited Partnership" },
      { value: "4", label: "Corporation" }
    ]
  },
  {
    field: "bank_name",
    label: "Bank",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Union Bank",
        value: "123"
      }
    ]
  },
  {
    field: "location.state",
    label: "State",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Lagos",
        value: "123"
      },
      {
        label: "Abuja",
        value: "123"
      }
    ]
  },
  {
    field: "location.lga",
    label: "Lga",
    type: "LIST",
    operators: ["EQUAL", "NOT_EQUAL"],
    value: [
      {
        label: "Surulere",
        value: "123"
      }
    ]
  },
  {
    field: "date_created",
    label: "Date Created",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  },
  {
    field: "date_applied",
    label: "Date Applied",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  },
  {
    field: "date_application_validated",
    label: "Date Validated",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  },
  {
    field: "date_application_approved",
    label: "Date Approved",
    type: "DATE",
    operators: [
      "EQUAL",
      "NOT_EQUAL",
      "BETWEEN",
      "NOT_BETWEEN",
      "LARGER",
      "SMALLER"
    ]
  }
];

export default class AddNotificationGroupScene extends BaseScene {
  requiredPermissions = [
    CAN_MESSAGING_WRITE_TOPIC
  ]

  sceneName = 'add-notification-group'
  metadataSectionRef = React.createRef(null)

  canSubmit = false

  componentDidMount () {
    this.props.updateScene(this.sceneName)
    this.getQueryBuilderField()
  }

  constructor (props) {
    super(props)
    this.requiredTopicsFormFields = [
      "name", "query",
    ]

    this.state = {
      topicsForm: {},
      initialData: [],
      canSubmit: false,
      queryFields: []
    }

    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.submitTemplate = this.submitTemplate.bind(this);
  }



  componentDidUpdate (prevProps, prevState) {
    

    const emptyRequiredTopicsFormFields = this.requiredTopicsFormFields.filter(value => {
      return Boolean(this.state.topicsForm[value]) === false
    })

    const emptyTopicsFormFields = this.requiredTopicsFormFields.filter(value => {
      return Boolean(this.state.topicsForm[value]) === false
    })

    const newState = {}

    if (emptyRequiredTopicsFormFields.length === 0) {
      newState.canSubmit = true
    }
    else if (emptyRequiredTopicsFormFields.length !==  0) {
      newState.canSubmit = false
    }

    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }

    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }

    this.canSubmit = newState.canSubmit
  }

  async getQueryBuilderField () {
    const { status, response } = await platformManagement.getQueryBuilderRulesConfig();
    if (status === SUCCESS_STATUS) {
      this.setState({
        queryFields: response
      })
    } else {
      this.setState({
        queryFields: []
      })
    }
  }


  updateTemplateForm(type, value) {
    this.setState({
      topicsForm: {...this.state.topicsForm, [type]: value}
    })
  }

  async submitTemplate() {
    this.setState({
      isLoading: true
    })
    const { status, response } = await messagingService.createTopics(this.state.topicsForm);
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: `Notification group created successfully`
      })
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  handleQueryChange(data) {
    // const topicsQuery = JSON.stringify(data, null, 4)
    this.updateTemplateForm('query', data)
  }

  render () {

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_MESSAGING_WRITE_TOPIC]}><button disabled={!this.canSubmit} class="btn" style={{backgroundColor: '#00425f', color: 'white'}} onClick={this.submitTemplate}>Submit</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        ref={this.metadataSectionRef}
      />
      <ContentHead 
        title={"Create Notification Group"} 
        withoutSearch subtitle={`Create a new notification group`}
        ctaWidgets={ctaWidgets}
      />
      <ProtectedComponent requiredPermissions={[CAN_MESSAGING_WRITE_TOPIC]} permissionDeniedContent={this.permissionDeniedContent}>
        <div class="kt-portlet">
          <div class="kt-portlet__body">
            <div class="col-lg-4">
              <label>Notification Group Name *:</label>
              <input
                autoComplete="off"
                disabled={this.props.disabled ? this.props.disabled : ''}
                type="name"
                class="form-control"
                defaultValue={this.props.applicantDetails ? this.props.applicantDetails.firstName : ''}
                placeholder="Subject"
                onChange={(event) => this.updateTemplateForm('name', stripNumbersFromString(event.target.value))} 
              />
            </div>
            {console.log('>>>>', this.canSubmit)}
            <div class="with-bootstrap">
              <Builder 
                fields={this.state.queryFields}
                data={this.state.initialData}
                onChange={(data) => {
                  this.handleQueryChange(data)
                  // console.log(data)
                }}
              />
              {/* <QueryBuilder fields={fields} onQueryChange={this.logQuery.bind(this)} /> */}
            </div>
          </div>
        </div>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
 
}
