import React from 'react';
import { AccessDenied } from '../../../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../../../components/notification';
import BaseScene from '../../../../../../../../../components/scenes/component';
import Table from '../../../../../../../../../components/table';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { platformManagement } from '../../../../../../../../../mixins/api';
import { IdentityTypesExportSerializer } from '../../../../../../../../../serializers';

export default class IdentityTypeGridScene extends BaseScene {
  
  sceneName = 'identity-types'

  columns = [
    {
      Header: "S/N",
      accessor: 'id',
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: {'white-space': 'unset'},
    },
    {
      Header: "Name",
      accessor: 'identity_type',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
      Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
    },
    {
      Header: "Action",
      accessor: 'id',
      overflow: 'visible',
      resizable: false,
      sortable: false,
      headerStyle: {'white-space': 'unset'},
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: '20px',
            textAlign: 'center',
            whiteSpace: 'unset',
          },
        };
      },
      width: 60,
      Cell: ({row, original}) => <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.onDeleteButtonClick(row.id, row.identity_type)} class="dropdown-item"  style={{cursor: 'pointer'}}>
        <i class="la la-trash la-lg error"></i>
      </a>
      // Cell: ({row, original}) => <div class="dropdown">
      //   <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
      //     <i class="la la-ellipsis-v"></i>
      //   </a>
      //   <div class="dropdown-menu dropdown-menu-right">
      //     <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.onDeleteButtonClick(row.id, row.identity_type)} class="dropdown-item"  style={{color: '#A7ABC3', cursor: 'pointer'}}>
      //       <i class="la la-trash"></i>
      //       Delete
      //     </a>
      //   </div>
      // </div>
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
      identityTypes: [],
      identityTypeFormData: [],
    }
  
    this.deleteIdentityType = this.deleteIdentityType.bind(this)
    this.getIdentityTypes = this.getIdentityTypes.bind(this)
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
    this.onAddButtonClick = this.onAddButtonClick.bind(this)
  }
  
  componentDidMount() {
    this.getIdentityTypes()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 3000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 3000)
    }
  }

  async getIdentityTypes () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.getIdentityTypes()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        identityTypes: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        identityTypes: [],
        isLoading: false,
        dataCount: 0,
        errorMessage: response,
      })
    }
  }

  async onSaveButtonClick () {
    this.setState({isLoading: true})

    const responseObj = await platformManagement.createIdentityType(this.state.identityTypeFormData)

    const status = responseObj.status
    const response = responseObj.response.data
    if (status === ERROR_STATUS) {
      this.setState({
        errorMessage: response,
        isLoading: false
      })

      return
    }

    this.setState({
      isLoading: false,
      successMessage: "Identity type created successfully",
      redirect: true,
    })
    this.getIdentityTypes()
  }

  async deleteIdentityType () {
    this.setState({isLoading: true})
    const responseObj = await platformManagement.deleteIdentityType(this.state.identityTypeId)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        identityTypeId: null,
        identityTypeName: null,
        successMessage: "Identity type deleted succesfully!",
        isLoading: false,
      })
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getIdentityTypes()
  }

  onAddButtonClick () {
    this.setState({
      canDelete: false,
      canAdd: true,
      modalHeight: '290px',
      modalTitle: 'New Identity Type',
      identityTypeFormData: [],
    })
  }

  onDeleteButtonClick (identityTypeId, identityTypeName) {
    this.setState({
      identityTypeId,
      identityTypeName,
      canDelete: true,
      canAdd: false,
      modalHeight: '230px',
      modalTitle: 'Delete Identity Type',
      identityTypeFormData: []
    })
  }

  render () {

    const addButton = <React.Fragment>
        <button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} data-toggle="modal" data-target="#kt_modal_1" onClick={this.onAddButtonClick}>Add Identity Type</button>
    </React.Fragment>

    const addModalBody = <React.Fragment>
      {this.state.canAdd && <div class="form-group col-lg-12">
        <label>Name:</label>
        <input autoComplete="off" type="text" class="form-control" onChange={event => this.setState({identityTypeFormData: {...this.state.identityTypeFormData, identity_type: event.target.value}})} />
      </div>}
    </React.Fragment>
   
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Application Management Configuration"} subtitle={"Identity Types"} ctaWidgets={addButton} />
      <GridLayout>
        <Table 
          columns={this.columns} 
          data={this.state.identityTypes} 
          loading={this.state.isLoading}
          noDataText={`No identity types found, please <a>try again</a>`}
          dataCount={this.state.dataCount}
          getData={this.getIdentityTypes}
          exportData={IdentityTypesExportSerializer.reverseIdentityTypeDetails(this.state.identityTypes)}
          exportHeaders={IdentityTypesExportSerializer.headerExport()}
          exportFileName={'identity_types'}
        />
        <PromptModal
          modalTitle={this.state.modalTitle}
          modalBody={this.state.canAdd ? addModalBody : `Do you want to delete ${this.state.identityTypeName} from identity types?`}
          deleteModal={this.state.canDelete ? this.deleteIdentityType : this.onSaveButtonClick}
          modalSize={`modal-md`}
          height={this.state.modalHeight}
          modalFooterButton1={'Cancel'}
          modalFooterButton2={this.state.canDelete ? 'Ok' : 'Save'}
          hideModalHeader={true}
          hideModalFooter={true}
          textAlign={this.state.canAdd ? 'left' : ''}
          modalFooterButton2Disabled={this.state.canAdd ? !this.state.identityTypeFormData.identity_type : false}
        />
        </GridLayout>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }

}
