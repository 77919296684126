import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_VIEW_FIP_USER, CAN_UPDATE_FIP_USER } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { SUCCESS_STATUS, ERROR_STATUS } from '../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { phoneNumberFormatter } from '../../../../../../../utils/formatter'
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { AGENT_TYPE_ID } from '../../../../../../../constants/domain-types'
import { platformManagement, userManagement } from '../../../../../../../mixins/api'
import Countries from '../../../../../../../fixtures/countries.json'
import States from '../../../../../../../fixtures/states.json'
import IDTYPES from '../../../../../../../fixtures/id-types.json';
import FipForm from '../../components/fip-form'
import qs from 'querystring';
import { getStatesByCountryId, getLgasByStateId } from '../../../../../../../utils/helper';

export default class ViewFipScene extends BaseScene {
  
    requiredPermissions = [
        CAN_VIEW_FIP_USER
    ]

    constructor(props) {
        super(props);
        this.state = {
            fipFormData: [],
            fipDetails: [],
            aggregators: [],
            states: [],
            countries: [],
            lgas: [],
            regions: [],
            zones: [],
            disabled: true,
            canUpdate: false,
            canSave: false,
            showPhoneNumber: false,
            isStateLoading: false,
            isLgaLoading: false,
            isZoneLoading: false,
            validatePhoneNumber: false,
        }
        this.updateFipFormData = this.updateFipFormData.bind(this)
        this.getStates = this.getStates.bind(this)
        this.updateSetState = this.updateSetState.bind(this)
        this.getLgas = this.getLgas.bind(this)
        this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
        this.getZones = this.getZones.bind(this)
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
        this.isPhoneNumberLengthValid = this.isPhoneNumberLengthValid.bind(this)
    }

    sceneName = "fip-management"

    componentDidMount () {
        this.username = this.props.match.params.id
        this.getAggregatorList()
        this.getFip()
        this.getRegions()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
            setTimeout(() => {
            this.setState({errorMessage: null})
            }, 5000)
        }
        if (this.state.successMessage) {
            setTimeout(() => {
            this.setState({successMessage: null})
            }, 5000)
        }
    }

    async getAggregatorList () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getSuperAgentList()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                aggregators: response,
                isLoading: false,
            })
        }else{
            this.setState({
                aggregators: [],
                isLoading: false,
            })
        }
    }

    async getFip () {
        this.setState({isLoading: true})
        const payload = {
            mobileNo: this.username
        }
        const responseObj = await userManagement.getFipUsers(payload)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            const { domainCode, countryCode, stateCode, lgaCode } = response[0]
            this.getStates(countryCode)
            this.getLgas(stateCode)
            this.getZones(lgaCode)
            this.setState({
                fipFormData: response[0],
                oldPhoneNumber: response[0].mobileNo,
                showPhoneNumber: true,
                isLoading: false,
                canUpdate: true
            })
        }else{
            this.setState({
                fipFormData: [],
                showPhoneNumber: true,
                isLoading: false,
                errorMessage: response
            })
        }
    }

    onUpdateButtonClick () {
        this.setState({
            disabled: false,
            canUpdate: false,
            canSave: true,
            validatePhoneNumber: true,
        })
    }

    onCancelButtonClick () {
        this.setState({
            disabled: true,
            canUpdate: true,
            canSave: false,
            validatePhoneNumber: false
        })
    }

    async getStates (countryId) {
        this.setState({
            states: getStatesByCountryId(countryId),
            isStateLoading: false,
        })
    }

    async getLgas (stateId) {
        this.setState({
            lgas: getLgasByStateId(stateId),
            isLgaLoading: false,
        })
    }

    async getRegions () {
        this.setState({isLoading: true})
        const responseObj = await platformManagement.getRegions()
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                regions: response.content,
                isLoading: false,
            })
        }else{
            this.setState({
                regions: [],
                isLoading: false,
            })
        }
    }

    async getZones (lgaCode) {
        this.setState({
            isZoneLoading: true,
            zones: []
        })
        const responseObj = await platformManagement.getLgaZones(lgaCode)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                zones: response,
                isZoneLoading: false,
            })
        }else{
            this.setState({
                zones: [],
                isZoneLoading: false,
            })
        }
    }

    async onSubmitButtonClick () {
        this.setState({isLoading: true})
        const formData = this.state.fipFormData
        formData.mobileNo = this.state.oldPhoneNumber
        const responseObj = await userManagement.updateFip(this.username, formData)
        const status = responseObj.status
        const response = responseObj.response
        
        if (status === SUCCESS_STATUS) {
            this.getFip();
            this.setState({
                isLoading: false,
                successMessage: "FIP details updated successfully",
                redirect: true,
                canUpdate: false,
                disabled: true,
                canUpdate: true,
                canSave: false,
                validatePhoneNumber: false,
            })
        }else{
            this.setState({
                isLoading: false,
                errorMessage: response,
                validatePhoneNumber: false
            })
        }
    }

    updateFipFormData (type, value) {
        this.setState({fipFormData: {...this.state.fipFormData, [type]: value}})
    }

    updateSetState (type, value) {
        this.setState({[type]: value})
    }

    isPhoneNumberLengthValid (phoneNumber) {
        if(phoneNumber.startsWith('0') && phoneNumber.length == 11) {
            return true
        }else if(phoneNumber.startsWith('234') && phoneNumber.length == 13) {
           return true
        }else{
          return false
        }
    }

    render () {

        const ctaWidgets = <React.Fragment>
            {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_FIP_USER]}><button type="button" class="btn btn-secondary" style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.onUpdateButtonClick}>Edit</button></ProtectedComponent>}
            {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_FIP_USER]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
            {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_FIP_USER]}><button type="button" class="btn btn-secondary" style={{backgroundColor: '#00425f', color: '#ffffff'}} onClick={this.onSubmitButtonClick}>Save Changes</button></ProtectedComponent>}
        </React.Fragment>

        return <React.Fragment>
            <Loader
            isLoading={this.state.isLoading}
            />
            <Notification
            successMessage={this.state.successMessage}
            errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"FIP Management"} subtitle={'FIP Details'} filterPermissions={[CAN_VIEW_FIP_USER]} totalCount={56} ctaWidgets={ctaWidgets} />
            <ProtectedComponent requiredPermissions={[CAN_VIEW_FIP_USER]} permissionDeniedContent={this.permissionDeniedContent}>
                <GridLayout>
                    <FipForm 
                        aggregators={this.state.aggregators}
                        idTypes={IDTYPES}
                        countries={Countries}
                        states={this.state.states}
                        fipFormData={this.state.fipFormData}
                        onChange={this.updateFipFormData}
                        updateSetState={this.updateSetState}
                        getStates={this.getStates}
                        disabled={this.state.disabled}
                        disabled2={true}
                        lgas={this.state.lgas}
                        getLgas={this.getLgas}
                        regions={this.state.regions}
                        showPhoneNumber={this.state.showPhoneNumber}
                        zones={this.state.zones}
                        getZones={this.getZones}
                        showField={true}
                        isStateLoading={this.state.isStateLoading}
                        isLgaLoading={this.state.isLgaLoading}
                        isZoneLoading={this.state.isZoneLoading}
                        validatePhoneNumber={this.isPhoneNumberLengthValid}
                    />
                </GridLayout>
            </ProtectedComponent>
    </React.Fragment>
  }

    get permissionDeniedContent() {
      return <AccessDenied /> 
    }
}
