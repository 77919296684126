import React from 'react'
import ProtectedComponent from '../../.../../../../../../mixins/protected-component';

export default class WorkflowApprovers extends React.Component {
    render () {
        return <React.Fragment>
            <div class="kt-timeline__item-text">
               <h5>Approvers</h5>
                {this.props.approvers.map(value => {
                    return (
                        <div class="kt-widget-5">
                            <div class="kt-widget-5__item kt-widget-5__item--info">
                                <div class="kt-widget-5__item-info">
                                    <span class="kt-widget-5__item-title">
                                        {value.name}
                                    </span>
                                    <ProtectedComponent requiredPermissions={[this.props.canAddStep]}><i style={{paddingLeft: '10px', cursor: 'pointer'}} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getRemoveApproverDetails(this.props.stepId, value.username, value.name)} class="fa fa-trash"></i></ProtectedComponent>
                                    <div class="kt-widget-5__item-datetime">
                                        {value.email}
                                    </div>
                                    <div class="kt-widget-5__item-datetime">
                                        {value.phone}
                                    </div>
                                </div>                                                                        
                            </div>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    }
}