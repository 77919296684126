import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";
import Select from "../../../../../../components/select";

export default class TransactionSearch extends React.Component {
  state = {
    transactionTypes: [
      { value: "ALL", label: "ALL" },
      { value: "TRANSFER", label: "TRANSFER" },
      { value: "CASH_OUT", label: "CASH OUT" },
      { value: "TRANSFER_CASH_IN", label: "CASH IN" },
      { value: "RECHARGE", label: "RECHARGE" },
      { value: "BILLS", label: "BILLS" },
      { value: "NIP", label: "NIP" },
    ],
    reconciliationTypes: [
      { value: "SETTLEMENT_WALLET", label: "SETTLEMENT WALLET" },
      { value: "TRANSERVICE_SETTLEMENT", label: "TRANSERVICE_SETTLEMENT" },
      { value: "CASHOUT_SETTLEMENT", label: "CASHOUT_SETTLEMENT" },
      { value: "BUYPOWER_SETTLEMENT", label: "BUYPOWER_SETTLEMENT" },
      { value: "ELECTRIC_BILL_RECON", label: "ELECTRIC_BILL_RECON" },
    ],
    status: [
      { value: "ALL", label: "ALL" },
      { value: "PAID", label: "PAID" },
      { value: "UNPAID", label: "UNPAID" },
    ],
    status2: [
      { value: "ALL", label: "ALL" },
      { value: "Comm. In Settlement", label: "Comm. In Settlement" },
      { value: "Comm. Not In Settlement", label: "Comm. Not In Settlement" },
    ],
    status3: [
      { value: "ALL", label: "ALL" },
      { value: "RECEIVED_BUT_UNSETTLED", label: "RECEIVED_BUT_UNSETTLED" },
      { value: "RECEIVED_AND_SETTLED", label: "RECEIVED_AND_SETTLED" },
      { value: "NOT_RECEIVED", label: "NOT_RECEIVED" },
    ],
    status4: [
      { value: "SUCCESS", label: "SUCCESS" },
      { value: "FAILED", label: "FAILED" },
      { value: "NOTIFIED", label: "NOTIFIED" },
      { value: "ALL", label: "ALL" },
    ],
  };
  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest();
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div class="search-params">
            <form onSubmit={this.handleSearchRequest}>
              <div class="form-group row">
                <div class="col-md-3">
                  <label class="search-label">Transaction Reference:</label>
                  <input
                    type="text"
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "transRef",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Agent ID:</label>
                  <input
                    type="text"
                    class="search-input"
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "agentId",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div class="col-md-2">
                  <label class="search-label">Reconciliation Type</label>
                  <Select
                    options={this.state.reconciliationTypes}
                    onChange={(event) => {
                      this.props.updateSearchParameters(
                        "reconciliationType",
                        event.value
                      );
                      console.log(event.value, "val");
                    }}
                  />
                </div>
                <div class="col-md-2">
                  <label class="search-label">Transaction Type</label>
                  <Select
                    options={this.state.transactionTypes}
                    onChange={(event) => {
                      this.props.updateSearchParameters(
                        "transactionType",
                        event.value
                      );
                    }}
                  />
                </div>
                <div class="col-md-2">
                  <label class="search-label">Status</label>
                  <Select
                    options={
                      this.props.searchParameters.reconciliationType ===
                      "TRANSERVICE_SETTLEMENT"
                        ? this.state.status2
                        : this.props.searchParameters.reconciliationType ===
                          "CASHOUT_SETTLEMENT"
                        ? this.state.status3
                        : this.props.searchParameters.reconciliationType ===
                          "BUYPOWER_SETTLEMENT"
                        ? this.state.status3
                        : this.props.searchParameters.reconciliationType ===
                          "ELECTRIC_BILL_RECON"
                        ? this.state.status4
                        : this.state.status
                    }
                    onChange={(event) =>
                      this.props.updateSearchParameters(
                        "transactionStatus",
                        event.value
                      )
                    }
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-3">
                  <label class="search-label">Date From</label>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.updateSearchParameters("startDate", event)
                    }
                    size={`170px`}
                    format={`dd/MM/yyyy h:mm aa`}
                    isClearable={true}
                    showTimeInput={true}
                    textStyle={"1.0rem"}
                    iconStyle={"0.2rem"}
                    dateType={"startDate"}
                    preloadDate={this.props.preloadDate}
                  />
                </div>
                <div class="col-md-3">
                  <label class="search-label">Date To</label>
                  <DatePickerComponent
                    onChange={(event) =>
                      this.props.updateSearchParameters("endDate", event)
                    }
                    size={`170px`}
                    format={`dd/MM/yyyy h:mm aa`}
                    isClearable={true}
                    showTimeInput={true}
                    disabled={
                      this.props.searchParameters.startDate ? false : true
                    }
                    minDate={this.props.searchParameters.startDate}
                    textStyle={"1.0rem"}
                    iconStyle={"0.2rem"}
                    dateType={"endDate"}
                    preloadDate={this.props.preloadDate}
                  />
                </div>
                <div class="col-md-1">
                  <label class="search-label">&nbsp;&nbsp;</label>
                  <button type="submit" class="search-button">
                    Search
                  </button>
                </div>
                <div class="col-md-3">
                  <label class="search-label">&nbsp;&nbsp;</label>
                  <button
                    type="button"
                    class="search-button"
                    onClick={() => this.props.generateDownloadableReport()}
                  >
                    Generate Downloadable Report
                  </button>
                </div>
                <div class="col-md-2">
                  <label class="search-label">&nbsp;&nbsp;</label>
                  <button
                    type="button"
                    class="search-button"
                    data-toggle="modal"
                    data-target="#downloadable_list_modal"
                    onClick={() => this.props.getDownloadableReportFiles()}
                  >
                    View Generated Files
                  </button>
                </div>
              </div>
            </form>
            <div
              class="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div
                class="btn-group ml-12"
                role="group"
                aria-label="First group"
              >
                {this.props.showRepush && (
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Re-push as
                      </span>
                    </div>
                    <button
                      id="basic-addon1"
                      type="button"
                      class="btn btn-secondary"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#00425f",
                        borderColor: "#00425f",
                        color: "#ffffff",
                      }}
                      onClick={() => this.props.requeryBulkTransactions()}
                      // disabled={this.props.requeryButton === true}
                    >
                      Re-query
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
