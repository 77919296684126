import React from 'react'
import WorkflowInfo from './workflow-info'
import WorkflowSteps from './workflow-steps'

export default class WorkflowDetails extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            {this.props.title}
                        </h3>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <ul class="nav nav-pills nav-fill" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#kt_tabs_5_1">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#kt_tabs_5_2">Steps</a>
                        </li>
                    </ul>                    
                    <div class="tab-content">
                        <div class="tab-pane active" id="kt_tabs_5_1" role="tabpanel">
                            <WorkflowInfo 
                                workflowDetails={this.props.workflowDetails}
                            />
                        </div>
                        <div class="tab-pane" id="kt_tabs_5_2" role="tabpanel">
                            <WorkflowSteps 
                                workflowDetails={this.props.workflowDetails}
                                getAddApproverDetails={this.props.getAddApproverDetails}
                                getMoveStepDetails={this.props.getMoveStepDetails}
                                getRemoveStepDetails={this.props.getRemoveStepDetails}
                                getRemoveApproverDetails={this.props.getRemoveApproverDetails}
                                getAddStepDetails={this.props.getAddStepDetails}
                                canAddStep = {this.props.canAddStep}
                                canRemoveStep = {this.props.canRemoveStep}
                                canMoveStep = {this.props.canMoveStep}
                            />
                        </div>
                    </div>      
                </div>
            </div>
        </React.Fragment>
    }
}