import React from 'react'

export default class BatchCreditDebitOptions extends React.Component {
    render() {
        return <React.Fragment>
            <div class="form-group col-lg-6">
                <span>* Upload a file for bulk processing</span><br/>
            </div>
            <div class="form-group col-lg-6">
                <label>Transaction Type *: </label>
                <div class="kt-radio-inline">
                    <label class="kt-radio">
                        <input autoComplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} type="radio" name="transaction-type" value="REFUND" onChange={event => this.props.updateBulkProcessingFormData('bulkTransactionType', event.target.value)} /> Refund
                        <span></span>
                    </label>
                    <label class="kt-radio">
                        <input autoComplete="off" class="form-control" disabled={this.props.disabled ? this.props.disabled : ''} type="radio" name="transaction-type" value="RETRIEVAL" onChange={event => this.props.updateBulkProcessingFormData('bulkTransactionType', event.target.value)} /> Retrieval
                        <span></span>
                    </label>
                </div><br/>
            </div>
            <div class="form-group col-lg-6">
                <label>Bulk Processing Method *: </label>
                <div class="kt-radio-inline">
                    <label class="kt-radio">
                        <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="radio" name="processing-method" value="SINGLE" onChange={event => this.props.updateBulkProcessingFormData('bulkTransactionMethod', event.target.value)} /> UI
                        <span></span>
                    </label>
                    <label class="kt-radio">
                        <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="radio" name="processing-method" value="BULK" onChange={event => this.props.updateBulkProcessingFormData('bulkTransactionMethod', event.target.value)} /> File
                        <span></span>
                    </label>
                </div><br/>
            </div>
        </React.Fragment>
    }
}