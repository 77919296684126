import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { SuperAgentForm } from "../../../../../../../components/forms/super-agent-form";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { CAN_VIEW_SERVICE_PROVIDER } from "../../../../../../../constants/permissions";
import BusinessTypes from "../../../../../../../fixtures/business_types.json";
import { platformManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";

export default class ViewSuperAgentScene extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      emailIsValid: true,
      phoneIsValid: true,
      websiteIsValid: true,
      createdBy: 0,
      businessTypes: BusinessTypes,
      pageTitle: "View Super Agent",
      disabled: true,
      canUpdate: false,
      canSave: false,
      isLoading: false,
      superAgentFormData: [],
      showPhoneNumber: false,
    };
    this.requiredSuperAgentFields = [
      "businessEmail",
      "businessName",
      "businessPhoneNo",
      "companyRegNo",
      "businessTypeId",
    ];
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.updateSuperAgentFormData = this.updateSuperAgentFormData.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.superAgentId = this.props.match.params.id;
    this.getSuperAgent();

    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getSuperAgent() {
    const responseObj = await platformManagement.getAgentByAgentCode(
      this.superAgentId
    );
    const { status, response } = responseObj;

    if (status === SUCCESS_STATUS) {
      this.setState({
        superAgentFormData: response,
        isLoading: false,
        showPhoneNumber: true,
        canUpdate: true,
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
        showPhoneNumber: true,
      });
    }
  }

  async onCancelButtonClick() {
    this.setState({
      disabled: true,
      canSave: false,
      canUpdate: true,
      pageTitle: "View Super Agent",
    });
  }

  async onUpdateButtonClick() {
    this.setState({
      disabled: false,
      canSave: true,
      canUpdate: false,
      pageTitle: "Update Super Agent",
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.updateAgentDetails(
      this.superAgentId,
      this.state.superAgentFormData
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "Super agent updated successfully.",
        canSave: false,
        canUpdate: true,
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  isFormValid() {
    const emptyRequiredSuperAgentDetailsFields = this.requiredSuperAgentFields.filter(
      (value) => {
        return Boolean(this.state.superAgentFormData[value]) === false;
      }
    );

    let status;

    if (emptyRequiredSuperAgentDetailsFields.length === 0) {
      status = true;
    } else {
      status = false;
    }
    return Boolean(
      !status ||
        !this.state.emailIsValid ||
        !this.state.phoneIsValid ||
        !this.state.websiteIsValid
    );
  }

  updateSuperAgentFormData(type, value) {
    this.setState({
      superAgentFormData: { ...this.state.superAgentFormData, [type]: value },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <button
            type="button"
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onUpdateButtonClick}
          >
            Edit
          </button>
        )}
        {this.state.canSave && (
          <button
            type="button"
            class="btn btn-secondary btn-bold btn-sm btn-icon-h"
            id="kt_subheader_group_actions_fetch"
            onClick={this.onCancelButtonClick}
          >
            Cancel
          </button>
        )}
        {this.state.canSave && (
          <button
            type="button"
            disabled={this.isFormValid()}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save Changes
          </button>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Super Agents"}
          withoutSearch
          subtitle={this.state.pageTitle}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_SERVICE_PROVIDER]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <SuperAgentForm
              updateSetState={this.updateSetState}
              updateSuperAgentFormData={this.updateSuperAgentFormData}
              businessTypes={this.state.businessTypes}
              superAgentFormData={this.state.superAgentFormData}
              disabled={this.state.disabled}
              emailIsValid={this.state.emailIsValid}
              websiteIsValid={this.state.websiteIsValid}
              phoneIsValid={this.state.phoneIsValid}
              showPhoneNumber={this.state.showPhoneNumber}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
