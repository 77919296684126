import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SettlementReportGridScene from './scenes/settlement-report-grid';
import BaseScene from '../../../../../components/scenes/component';
import ViewSettlementReportScene from './scenes/view-settlement-report';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_SETTLEMENT } from '../../../../../constants/permissions.js'

export default class SettlementReportScene extends BaseScene {
  sceneName = 'settlement-report'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SETTLEMENT]} exact path='/home/settlement-report' component={SettlementReportGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SETTLEMENT]} exact path="/home/settlement-report/:id/view" component={ViewSettlementReportScene} />
    </Router>
  }
}
