import React from "react";

export const GridItem = (props) => {
  const {
    isFluid,
    isVerticalGrid,
    isHorizontalGrid,
    children,
    page,
    style,
    id,
  } = props;
  return (
    <div
      class={`kt-grid__item ${isFluid ? "kt-grid__item--fluid" : ""} ${
        isVerticalGrid ? "kt-grid kt-grid--ver" : ""
      } ${isHorizontalGrid ? "kt-grid kt-grid--hor" : ""} ${
        page ? "kt-page" : ""
      } ${props.class ? props.class : ""}`}
      style={{ ...style }}
      id={id}
    >
      {children}
    </div>
  );
};
