import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import ViewFipScene from './scenes/view-fip';
import ConfigGridScene from './scenes/config-grid';
import NewConfigScene from './scenes/new-config';
// import ViewStockScene from './scenes/view-stock';
// import AddFipScene from './scenes/add-fip'
import { CAN_GET_DEVICES_BY_UUID, CAN_REMAP_DEVICE } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';

export default class PosConfigurationScene extends Component {
  sceneName = 'pos-config'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_GET_DEVICES_BY_UUID]} exact path='/home/pos-management/pos-config' component={ConfigGridScene} />
      {/* <ProtectedRoute requiredPermissions={[CAN_REMAP_DEVICE]} exact path='/home/pos-management/pos-config/:id/view' component={ViewStockScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_REMAP_DEVICE]} exact path='/home/pos-management/pos-config/add' component={NewConfigScene} />
    </Router>
  }
}
