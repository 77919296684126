import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_MESSAGING_SEND } from '../../../../../constants/permissions.js'
import BaseScene from '../../../../../components/scenes/component';
import SendBroadcastGroupScene from '../scenes/send-broadcast-group';

export default class SendBroadcastScene extends BaseScene {
  sceneName = 'send-broadcast'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      {/* <ProtectedRoute requiredPermissions={[CAN_VIEW_APPLICATION]} exact path='/home/notifications/send-notification' component={NotificationGroupGridScene} /> */}
      <ProtectedRoute requiredPermissions={[CAN_MESSAGING_SEND]} exact path='/home/notifications/send-notification' component={SendBroadcastGroupScene} />
    </Router>
  }
}
