import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import SpinnerComponent from '../../../../../../components/spinner'
import Pagination from '../../../../../../components/pagination'
import TableExtra from '../../../../../../components/table-extra'
import { ExternalFeeExportSerializer } from '../../../../../../serializers'
import { getPaginationRows } from '../../../../../../utils/helper'
import ProtectedComponent from '../../../../../../mixins/protected-component';

export default class ExternalFeeTable extends React.Component {

  state = {
    page: 0,
    tableHeight: '980px',
    zoom: 'plus'
  }

  zoomTableHeight = this.zoomTableHeight.bind(this)
  getSerialNumber = this.getSerialNumber.bind(this)

  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }

  getSerialNumber (index) {
    return (this.props.pageSize * (this.props.page - 1)) + index
  }

    render() {
      const MyLoader = props => { return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div> 
          </div>
        )
      )};
      const columns = [
        {
          Header: <input type="checkbox" onClick={event => this.props.selectAllExternalFees(event.target.checked)} />,
          accessor: 'id',
          Cell: (id) => <span>{id.index + 1}</span>,
          sortable: false,
          resizable: false,
          width: 25,
          headerStyle: {'white-space': 'unset', textAlign: 'center'},
          Cell: ({row, original}) => {
              return (
                  <div style={{textAlign: 'center'}}>
                      <input type="checkbox" checked={original.checked} onClick={event => this.props.getSelectedExternalFees(event.target.checked, row.id)} style={{verticalAlign: 'middle'}}/>
                  </div>
              )
          }
        },
        // {
        //     Header: "S/N",
        //     accessor: 'id',
        //     Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        //     sortable: false,
        //     resizable: false,
        //     width: 60,
        //     headerStyle: {'white-space': 'unset'},
        // },
        {
          Header: "Biller Name",
            accessor: 'billerName',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
        },{
            Header: "Service Option Code",
            accessor: 'serviceOptionCode',
            resizable: false,
            headerStyle: {'white-space': 'unset'},
            style: {'white-space': 'unset'},
        },{
          Header: "Service Option Name",
          accessor: 'serviceOptionCodeName',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
        },{
          Header: "Paydirect Item Code",
          accessor: 'paydirectItemCode',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
        },
        {
          Header: "Status",
          accessor: 'status',
          resizable: false,
          width: 80,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
          getProps: (state, rowInfo, column) => {
            return {
                style: {
                    textAlign: 'center'
                },
            };
          },
          Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge kt-badge--inline ${props.value === 0 ? 'kt-badge--success' : props.value === 1 ? 'kt-badge--warning' : '' } kt-badge--pill`}>{props.value === 0 ? 'Active' : props.value === 1 ? 'Inactive' : ''}</span>,
        },
        {
            Header: "Action",
            accessor: 'id',
            overflow: 'visible',
            resizable: false,
            sortable: false,
            headerStyle: {'white-space': 'unset'},
            getProps: (state, rowInfo, column) => {
              return {
                  style: {
                      textAlign: 'center',
                      whiteSpace: 'unset',
                  },
              };
          },
            width: 100,
            Cell: ({row, original}) => <ProtectedComponent requiredPermissions={[this.props.canDelete]}><button type="button" data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateStatus(`delete`, row.id)} style={{color: '#a7abc3', cursor: 'pointer'}} class="btn btn-secondary btn-xs link-button-danger">
              Blacklist
            </button></ProtectedComponent>
          //   Cell: ({row, original}) => <div class="dropdown">
          //   <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
          //     <i class="la la-ellipsis-v"></i>
          //   </a>
          //   <div class="dropdown-menu dropdown-menu-right">
          //     <ProtectedComponent requiredPermissions={[this.props.canDelete]}><a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateStatus(`delete`, row.id)} style={{color: '#a7abc3', cursor: 'pointer'}} class="dropdown-item">
          //       <i class="la la-trash"></i>
          //       Blacklist
          //     </a></ProtectedComponent>
          //   </div>
          // </div>
        }
      ]
        return <React.Fragment>
        <TableExtra 
            data={ExternalFeeExportSerializer.reverseExternalFeeDetails(this.props.data)}
            headers={ExternalFeeExportSerializer.headerExport()}
            fileName={'external_fees'}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getExternalFees}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            disabled={this.props.loading}
          />
        <ReactTable 
        PaginationComponent={Pagination}
        loading={this.props.loading}
        LoadingComponent={MyLoader}
        noDataText={!this.props.loading ? this.props.noDataText : ''}
        style={{
          border: "none",
          boxShadow: "none",
          whiteSpace: 'unset',
          height: this.state.tableHeight,
        }}
        data={this.props.data}
        dataCount={this.props.dataCount}
        pageSizes={getPaginationRows(this.props.dataCount)}
        columns={columns}
        manual
        pages={this.props.pages}
        onFetchData={(state, instance) => {
            this.props.getExternalFees(state.page + 1,state.pageSize)
        }}
        headerNodeHeight={`123`}
        minRows={(this.props.data).length > 0 ? 10 : 10}
        getTableProps={(state, rowInfo, column, instance) => ({
          style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
          },
        })}
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            display:"flex",
            overflow: "visible",
            color: "black",
            fontSize: "10px",
            fontWeight: "50",
            paddingLeft:"5px",
            paddingRight:"5px",
            alignItems: "center",
            
          },
        })}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            backgroundColor: "#00425f",
            borderBottom: "0.1px solid #DBDEDE",
            borderWidth: "0.1px",
            padding:"10px 0",
            color: "#FFFFFF",
            fontSize: "11px",
            fontWeight: "100",
            textAlign: "left",
            paddingLeft:"5px",
            paddingRight:"5px",
          },
        })}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
          },
        })}    
        className="-striped -highlight"
        />
      </React.Fragment>
    }
}