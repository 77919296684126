import React from 'react'
import { splitNameWithUnderscore } from '../../../../../utils/helper'

export default class PermissionForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                    <div class="form-group col-lg-6">
                            <label>Permission Group *:</label>
                            <div class="input-group">
                                <select autoComplete="off" class="form-control" placeholder="Name" disabled={this.props.disabled} onChange={(event) => this.props.onChange('permissionGroupId', event.target.value)}>
                                    <option selected disabled>Select</option>
                                    {this.props.permissionGroups.map(value => <option value={value.id} selected={this.props.permissionDetails && this.props.permissionDetails.permissionGroupId == value.id ? 'selected' : ''}>{value.name}</option>)}
                                </select>
                                <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px', cursor: 'pointer'}}>
                                    <span class={`la ${this.props.isGetPermissionGroupLoading ? 'la-spin' : ''} la-refresh`} onClick={this.props.getPermissionGroups}></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Name *:</label>
                            <input autoComplete="off" type="name" class="form-control" placeholder="Name" disabled={this.props.disabled} defaultValue={this.props.permissionDetails ? this.props.permissionDetails.name : ''} onChange={(event) => this.props.onChange('name', (splitNameWithUnderscore(event.target.value)).toUpperCase())} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Description *:</label>
                            <textarea autoComplete="off" type="name" class="form-control" placeholder="Description" disabled={this.props.disabled} defaultValue={this.props.permissionDetails ? this.props.permissionDetails.description : ''} onChange={(event) => this.props.onChange('description', event.target.value)}></textarea>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Short Description *:</label>
                            <textarea autoComplete="off" type="name" class="form-control" placeholder="Description" disabled={this.props.disabled} defaultValue={this.props.permissionDetails ? this.props.permissionDetails.shortDescription : ''} onChange={(event) => this.props.onChange('shortDescription', event.target.value)}></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}