import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import { getDateByType } from '../../../../../../utils/helper';

export default class StockSearch extends React.Component {
    state = {
        stockSearchParameters: {
            deviceType: "All",
            stockStatus: "All", 
            fromDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
            toDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null
        },
        deviceTypes: [
            {value: "All", label: "All"},
            {value: 'android', label: 'Android POS'},
            {value: 'terminal', label: 'Terminal POS'}
        ],
        stockStatuses: [
            {value: "All",label: "All"},
            {value: 'new', label: 'New'},
            {value: 'assigned', label: 'Assigned'}
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        console.log(this.state.stockSearchParameters)
        this.props.handleSearchRequest(this.state.stockSearchParameters)
    }
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
            <div class="search-components">
                    <div class="col-md-4">
                        <label>Batch ID:</label>
                        <input class="search-input" type="text" name="batchId" onChange={event => this.setState({stockSearchParameters: {...this.state.stockSearchParameters, batchId: event.target.value}})} />
                    </div>
                    <div class="col-md-4">
                        <label>Device Model:</label>
                        <input class="search-input" type="text" name="deviceModel" onChange={event => this.setState({stockSearchParameters: {...this.state.stockSearchParameters, deviceModel: event.target.value}})} />
                    </div>
                    <div class="col-md-2">
                        <label>Device Type:</label>
                        <Select 
                            options={this.state.deviceTypes}
                            onChange={event => this.setState({stockSearchParameters: {...this.state.stockSearchParameters, deviceType: event.value}})}
                            defaultValue={this.state.stockSearchParameters.deviceType}
                        />
                    </div>
                    <div class="col-md-2">
                        <label>Status:</label>
                        <Select 
                            options={this.state.stockStatuses}
                            onChange={event => this.setState({stockSearchParameters: {...this.state.stockSearchParameters, stockStatus: event.value}})}
                            defaultValue={this.state.stockSearchParameters.stockStatus}
                        />
                    </div>
                </div>
                <div class="search-components">
                <div class="search-component">
                        <label>Date From</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({stockSearchParameters:{...this.state.stockSearchParameters, fromDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="search-component">
                        <label>Date To</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({stockSearchParameters: {...this.state.stockSearchParameters, toDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.stockSearchParameters.fromDate ? false : true}
                            minDate={new Date(this.state.stockSearchParameters.fromDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button" disabled={!this.state.stockSearchParameters.fromDate || !this.state.stockSearchParameters.toDate}>Search</button>
                    </div>
                    {/* <div class="col-md-3">
                        <label>Status:</label>
                        <Select 
                            options={this.state.statuses}
                            onChange={event => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, active: event.value}})}
                            defaultValue={this.state.deviceSearchParameters.status}
                        />
                    </div> */}
                </div>
            </form>
        </React.Fragment>
    }
}