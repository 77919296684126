import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import Table from "../../../../../../../components/table";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import {
  CAN_CREATE_COUNTRY,
  CAN_VIEW_COUNTRY,
} from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { CountryExportSerializer } from "../../../../../../../serializers";

const columns = [
  {
    Header: "S/N",
    accessor: "id",
    Cell: (id) => <span>{id.index + 1}</span>,
    sortable: false,
    resizable: false,
    width: 60,
    headerStyle: { "white-space": "unset" },
  },
  {
    Header: "Name",
    accessor: "name",
    resizable: false,
    headerStyle: { "white-space": "unset" },
    style: { "white-space": "unset" },
  },
  {
    Header: "Alphabetic Code",
    accessor: "alphabeticCode2",
    resizable: false,
    headerStyle: { "white-space": "unset" },
    style: { "white-space": "unset" },
  },
  {
    Header: "Dial Code",
    accessor: "dialCode",
    resizable: false,
    headerStyle: { "white-space": "unset" },
    style: { "white-space": "unset" },
  },
  // ,{
  //   Header: "Action",
  //   accessor: 'id',
  //   overflow: 'visible',
  //   resizable: false,
  //   sortable: false,
  //   headerStyle: {'white-space': 'unset'},
  //   getProps: (state, rowInfo, column) => {
  //     return {
  //       style: {
  //         lineHeight: '20px',
  //         textAlign: 'center',
  //         whiteSpace: 'unset',
  //       },
  //     };
  //   },
  //   width: 100,
  //   Cell: ({row, original}) => <div class="dropdown">
  //     <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
  //       <i class="la la-ellipsis-v"></i>
  //     </a>
  //     <div class="dropdown-menu dropdown-menu-right">
  //       <ProtectedComponent requiredPermissions={[CAN_VIEW_COUNTRY]}><Link to={`system-configuration/country-management/${row.id}/view`} class="dropdown-item">
  //         <i class="la la-eye"></i>
  //         View
  //       </Link></ProtectedComponent>
  //       {/* <a href={`/home/system-configuration/country-management/${row.id}/delete`} class="dropdown-item">
  //         <i class="la la-trash"></i>
  //         Delete
  //       </a> */}
  //     </div>
  //   </div>
  // }
];

export default class CountryGridScene extends BaseScene {
  sceneName = "country-management";

  state = {
    countries: [],
  };

  getCountries = this.getCountries.bind(this);

  componentDidMount() {
    this.getCountries();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getCountries() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getCountries();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        countries: response,
        isLoading: false,
        dataCount: response.length,
      });
    } else {
      this.setState({
        countries: [],
        isLoading: false,
        dataCount: 0,
        errorMessage: response,
      });
    }
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {/* <ProtectedComponent requiredPermissions={[CAN_CREATE_COUNTRY]}><button type="button" class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.uploadNipBank}>Upload NIP Banks</button></ProtectedComponent> */}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Countries"}
          filterPermissions={[CAN_VIEW_COUNTRY]}
          actionButtonText={"Add Country"}
          actionButtonUrl={"/home/system-configuration/country-management/add"}
          totalCount={56}
          actionButtonRequiredPermissions={[CAN_CREATE_COUNTRY]}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_COUNTRY]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <Table
              columns={columns}
              data={this.state.countries}
              loading={this.state.isLoading}
              noDataText={`No countries found`}
              dataCount={this.state.dataCount}
              getData={this.getCountries}
              disabled={true}
              exportData={CountryExportSerializer.reverseCountryDetails(
                this.state.countries.reverse()
              )}
              exportHeaders={CountryExportSerializer.headerExport()}
              exportFileName={"Countries"}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
