import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../../../../../../../constants/api";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import { getDeviceInformation } from "../../../../../../../mixins/authentication";
import { AGENT_TYPE_ID } from "../../../../../../../constants/domain-types";
import {
  TERMINAL_ID,
  PAYMENT_ITEM_CODE,
} from "../../../../../../../constants/transaction-types";
import {
  liquidityService,
  platformManagement,
  transactionService,
} from "../../../../../../../mixins/api";
import {
  CAN_VIEW_AGENT_HIERARCHY_ROLE,
  CAN_UPDATE_AGENT_HIERARCHY_ROLE,
} from "../../../../../../../constants/permissions";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { AccessDenied } from "../../../../../../../components/access-denied";
import { retrieveSessionInformation } from "../../../../../../../mixins/authentication";
import {
  convertToMajor,
  convertToMinor,
} from "../../../../../../../utils/converter";
import {
  explodeString,
  toPermissionArray,
} from "../../../../../../../utils/helper";
import AgentHierarchyCard from "../../components/agent-hierarchy-card";
import AgentPermissions from "../../components/agent-permissions";
import RoleForm from "../../../../../../../components/forms/role-form";
import { Redirect } from "react-router-dom";
import { userManagement } from "../../../../../../../mixins/api";

export default class AgentHierarchyViewRoleScene extends BaseScene {
  metadataSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      successMessage: null,
      errorMessage: null,
      permissions: [],
      roleFormData: [],
      roleDetails: [],
      canSave: false,
      canUpdate: false,
      disabled: true,
    };
    this.updateRoleFormData = this.updateRoleFormData.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
  }

  componentDidMount() {
    this.domainType = this.props.match.params.domainType;
    this.roleId = this.props.match.params.id;
    this.getDomainTypeRoles();
  }

  async getDomainTypeRoles() {
    this.setState({ isLoading: true });
    const responseObj = await userManagement.getDomainTypeRoles(
      this.getDomainTypeId(this.domainType)
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      response.map((details) => {
        if (this.roleId == details.id) {
          this.getDomainTypePermissions(details.roleName);
        }
      });
    } else {
      this.setState({
        roles: [],
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  async getDomainTypePermissions(roleName) {
    this.setState({ isLoading: true });
    const responseObj = await userManagement.getDomainTypePermissions(
      this.getDomainTypeId(this.domainType)
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getDomainTypeRolePermissions(roleName, response);
    } else {
      this.setState({
        permissions: [],
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  async getDomainTypeRolePermissions(roleName, domainTypePermissions) {
    this.setState({ isLoading: true });
    const responseObj = await userManagement.getDomainTypeRolePermissions(
      this.roleId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        roleFormData: {
          ...this.state.roleFormData,
          name: roleName,
          permissions: this.getPermissionName(response),
          defaultSelectedPermissions: this.getPermissionName(response),
        },
        permissions: domainTypePermissions,
        isLoading: false,
        canUpdate: true,
      });
    } else {
      this.setState({
        roleFormData: {
          ...this.state.roleFormData,
          permissions: [],
          defaultSelectedPermissions: [],
        },
        permissions: [],
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  getPermissionName(permissions) {
    let permissionArray = [];
    permissions.map((details) => {
      permissionArray.push(details.id);
    });

    return permissionArray;
  }

  splitString(string) {
    return string.split("-");
  }

  getDomainTypeId(domainType) {
    const domainTypeName = `${this.splitString(domainType)[0]}${
      this.splitString(domainType)[1] ? this.splitString(domainType)[1] : ""
    }`;
    const domainTypes = {
      platformprovider: 1,
      superagent: 2,
      aggregator: 3,
      agent: 4,
    };
    return domainTypes[domainTypeName];
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const { permissions, defaultSelectedPermissions } = this.state.roleFormData;

    const addedPermissions = permissions.filter(function (v) {
      return !defaultSelectedPermissions.includes(v);
    });

    const removedPermissions = defaultSelectedPermissions.filter(function (v) {
      return !permissions.includes(v);
    });

    if (addedPermissions && addedPermissions.length > 0) {
      const createRoleResponseObj = await userManagement.createDomainTypeRolePermissions(
        this.roleId,
        { permissions: addedPermissions }
      );

      const createRoleResponseStatus = createRoleResponseObj.status;
      const createRoleResponse = createRoleResponseObj.response.data;

      if (createRoleResponseStatus === ERROR_STATUS) {
        this.setState({
          errorMessage: createRoleResponse,
          isLoading: false,
        });
      }
    }

    if (removedPermissions && removedPermissions.length > 0) {
      const removeRoleResponseObj = await userManagement.removeDomainTypeRolePermissions(
        this.roleId,
        { permissions: removedPermissions }
      );

      const removeRoleResponseStatus = removeRoleResponseObj.status;
      const removeRoleResponse = removeRoleResponseObj.response.data;
      if (removeRoleResponseStatus === ERROR_STATUS) {
        this.setState({
          errorMessage: removeRoleResponse,
          isLoading: false,
        });
      }
    }

    this.setState({
      isLoading: false,
      successMessage: "Role updated successfully",
      canSave: false,
      canUpdate: true,
      pageTitle: "View a Role",
      disabled: true,
    });

    window.scrollTo(0, this.metadataSectionRef.current);
  }

  updateRoleFormData(type, value) {
    this.setState({
      roleFormData: { ...this.state.roleFormData, [type]: value },
    });
  }

  isFormValid() {
    const { permissions, defaultSelectedPermissions } = this.state.roleFormData;
    if (permissions && defaultSelectedPermissions) {
      const addedPermissions = permissions.filter(function (v) {
        return !defaultSelectedPermissions.includes(v);
      });

      const removedPermissions = defaultSelectedPermissions.filter(function (
        v
      ) {
        return !permissions.includes(v);
      });

      return Boolean(!addedPermissions || !removedPermissions);
    }
    return true;
  }

  async onUpdateButtonClick() {
    this.setState({
      canSave: true,
      canUpdate: false,
      disabled: false,
    });
  }

  async onCancelButtonClick() {
    this.setState({
      canSave: false,
      canUpdate: true,
      disabled: true,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/role-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <ProtectedComponent
            requiredPermissions={[CAN_UPDATE_AGENT_HIERARCHY_ROLE]}
          >
            <button
              type="button"
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onUpdateButtonClick}
            >
              Edit Role Permission
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent
            requiredPermissions={[CAN_UPDATE_AGENT_HIERARCHY_ROLE]}
          >
            <button
              type="button"
              class="btn btn-secondary btn-bold btn-sm btn-icon-h"
              id="kt_subheader_group_actions_fetch"
              onClick={this.onCancelButtonClick}
            >
              Cancel
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent
            requiredPermissions={[CAN_UPDATE_AGENT_HIERARCHY_ROLE]}
          >
            <button
              type="button"
              disabled={this.isFormValid()}
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onSaveButtonClick}
            >
              Save
            </button>
          </ProtectedComponent>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
          ref={this.metadataSectionRef}
        />
        <ContentHead
          title={"Agent Hierarchy Management"}
          subtitle={`${
            this.domainType ? explodeString(this.domainType, "-") : ""
          } Role Details`}
          withoutSearch
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_ROLE]}
        >
          <GridLayout>
            <RoleForm
              allPermissions={this.state.permissions}
              onChange={this.updateRoleFormData}
              roleFormData={this.state.roleFormData}
              roleDetails={this.state.roleDetails}
              disabled={this.state.disabled}
              disabled2={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
