import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ServiceOptionGridScene from './scenes/service-options-grid';
import ManagePaydirectItemCodeScene from './scenes/manage-paydirect-item-code';
import ViewServiceOptionScene from './scenes/view-service-option';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_SERVICE_OPTION } from '../../../../../constants/permissions.js'

export default class ServiceOptionScene extends Component {
  sceneName = 'service-options'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_OPTION]} exact path='/home/service-options' component={ServiceOptionGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_OPTION]} exact path='/home/service-options/:id/view' component={ViewServiceOptionScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE_OPTION]} exact path='/home/service-options/:id/manage-paydirect-item-code' component={ManagePaydirectItemCodeScene} />
    </Router>
  }
}
