import React, { Component } from 'react';
import { GridItem } from '../../components/layouts/grid-item';
import { VerticalGrid } from '../../components/layouts/grids/vertical';
import { HorizontalGrid } from '../../components/layouts/grids/horizontal';
import { SUCCESS_STATUS } from '../../constants/api';
import LandingBanner from '../../components/landing-banner';
import { retrieveOtherInformation } from '../../mixins/authentication'
import { FORGOT_PASSWORD_TOKEN } from '../../constants/session-storage'
import { userManagement } from '../../mixins/api'
import { ChangePasswordSuccess } from './components/change-password-success'
import { ChangePasswordForm } from './components/change-password-form'
export default class ChangePasswordScene extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isPasswordMatch: false,
        isPasswordValid: false,
        errorMessage: null,
        successMessage: null,
        changePasswordFormData: {
          accessToken: retrieveOtherInformation(FORGOT_PASSWORD_TOKEN)
        }
      }
      this.updateChangePasswordFormData = this.updateChangePasswordFormData.bind(this)
      this.updateSetState = this.updateSetState.bind(this)
      this.changePassword = this.changePassword.bind(this)
    }
  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }
  async changePassword () {
    this.setState({isLoading: true})
    const getUserResponseObj = await userManagement.changeUserPassword(this.state.changePasswordFormData)
    const getUserResponse = getUserResponseObj.response
    const getUserStatus = getUserResponseObj.status
    if (getUserStatus === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: 'Password Changed successfully',
        redirect: true,
      })
    }else {
      this.setState({
        isLoading: false,
        errorMessage: getUserResponse,
      })
    }
  }
  updateChangePasswordFormData (type, value) {
    this.setState({changePasswordFormData: {...this.state.changePasswordFormData, [type]: value}})
  }
  updateSetState (type, value) {
    this.setState({[type]: value})
  }
  render() {
    return <React.Fragment>
      <VerticalGrid isRoot>
        <HorizontalGrid isRoot class="kt-login kt-login--v1">
          <GridItem isFluid class="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <LandingBanner />
            <GridItem isFluid class="kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
              {!this.state.redirect && <ChangePasswordForm 
                isLoading={this.state.isLoading}
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
                updateSetState={this.updateSetState}
                updateChangePasswordFormData={this.updateChangePasswordFormData}
                changePassword={this.changePassword}
                isPasswordValid={this.state.isPasswordValid}
                isPasswordMatch={this.state.isPasswordMatch}
                isPasswordNew={this.state.isPasswordNew}
                changePasswordFormData={this.state.changePasswordFormData}
              />}
              {this.state.redirect && <ChangePasswordSuccess />}
            </GridItem>
          </GridItem>
        </HorizontalGrid>
      </VerticalGrid>
    </React.Fragment>
  }
}