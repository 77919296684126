import { formatDate } from '../../utils/formatter';

export default class SanefBankExportSerializer {
    static headerExport () {
        return [
            {label: 'Bank Name', key: 'bankName', title: 'Bank Name', dataIndex: 'bankName'},
            {label: 'Status', key: 'enabledForSanef', title: 'Status', dataIndex: 'enabledForSanef'}
        ]
    }

    static reverseSanefBankDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { bankName, enabledForSanef } = details
            let detailFormData = {
                bankName,
                enabledForSanef
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}