import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { convertToMajor } from "../../../../../../utils/converter";
import {
  formatCurrency,
  formatDateTime,
} from "../../../../../../utils/formatter";
import {
  getPaginationRows,
  getTransactionTypeLabel,
} from "../../../../../../utils/helper";

export default class ReconHistoryTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "950px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: (
          <input
            type="checkbox"
            onClick={(event) =>
              this.props.selectAllTransactions(event.target.checked)
            }
          />
        ),
        accessor: "transactionRef",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 25,
        headerStyle: { "white-space": "unset", textAlign: "center" },
        Cell: ({ row, original }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                checked={original.checked}
                onClick={(event) =>
                  this.props.getSelectedTransactions(
                    event.target.checked,
                    original.uniqueReference
                  )
                }
                disabled={
                  row.status === "Comm. In Settlement" ||
                  row.status === "RECEIVED_AND_SETTLED"
                }
                style={{ verticalAlign: "middle" }}
              />
            </div>
          );
        },
      },
      {
        Header: "Trans Ref",
        accessor: "transactionRef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 220,
      },
      {
        Header: "Transaction Type",
        accessor: "transactionType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
        Cell: (props) => {
          return props.value ? getTransactionTypeLabel(props.value) : "";
        },
      },
      {
        Header: "Reconciliation Type",
        accessor: "reconciliationType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 240,
      },
      {
        Header: "Status",
        accessor: "status",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Amount",
        accessor: "transactionAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Wallet Version",
        accessor: "walletVersion",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Date",
        accessor: "dateCreated",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Action",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        width: 70,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#recon_details_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={
                    row.status === "Comm. In Settlement" ||
                    row.status === "RECEIVED_AND_SETTLED"
                  }
                  onClick={() => {
                    this.props.requerySingleReport(
                      row._original.uniqueReference
                    );
                  }}
                  /*data-toggle="modal" data-target="#kt_modal_1"*/ /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-refresh"></i>
                  Re-query
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];
    const columns2 = [
      {
        Header: (
          <input
            type="checkbox"
            onClick={(event) =>
              this.props.selectAllTransactions(event.target.checked)
            }
          />
        ),
        accessor: "transactionRef",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 25,
        headerStyle: { "white-space": "unset", textAlign: "center" },
        Cell: ({ row, original }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                checked={original.checked}
                onClick={(event) => {
                  this.props.getSelectedTransactions(
                    event.target.checked,
                    original.uniqueReference
                  );
                }}
                disabled={row.status === "PAID"}
                style={{ verticalAlign: "middle" }}
              />
            </div>
          );
        },
      },
      {
        Header: "Agent ID",
        accessor: "agentID",
        sortable: false,
        resizable: false,
        width: 120,
        headerStyle: { "white-space": "unset" },
      },
      {
        Header: "Trans Ref",
        accessor: "transactionRef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Commission",
        accessor: "commissionAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Transaction Type",
        accessor: "transactionType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: (props) => {
          return props.value ? getTransactionTypeLabel(props.value) : "";
        },
      },
      {
        Header: "Reconciliation Type",
        accessor: "reconciliationType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 180,
      },
      {
        Header: "Status",
        accessor: "status",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Amount",
        accessor: "transactionAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Wallet Version",
        accessor: "walletVersion",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Date",
        accessor: "dateCreated",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Action",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        width: 70,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#recon_details_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={row.status === "PAID"}
                  onClick={() => {
                    this.props.requerySingleReport([
                      row._original.uniqueReference,
                    ]);
                  }}
                  /*data-toggle="modal" data-target="#kt_modal_1"*/ /*href={`/home/agent-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-refresh"></i>
                  Re-query
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        {this.props.commissionAmount === "" ||
        this.props.commissionAmount === 0 ? (
          <ReactTable
            PaginationComponent={Pagination}
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ""}
            style={{
              border: "none",
              boxShadow: "none",
              whiteSpace: "unset",
              height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns}
            manual
            pages={this.props.pages}
            page={
              this.props.startPage ? this.props.startPage - 1 : this.state.page
            }
            onFetchData={(state, instance) => {
              const {
                transactionType,
                agentId,
                reconciliationType,
                transactionStatus,
                transRef,
                endDate,
                startDate,
              } = this.props.searchParameters;
              this.setState({ page: state.page });
              if (this.props.canLoadData) {
                this.props.getAllReconReports(
                  state.page + 1,
                  state.pageSize,
                  transactionType,
                  agentId,
                  reconciliationType,
                  transactionStatus,
                  transRef,
                  endDate,
                  startDate
                );
              }
            }}
            headerNodeHeight={`123`}
            minRows={this.props.data.length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
              style: {
                borderColor: "#00425f",
                boxSizing: "none",
                border: "none",
              },
            })}
            getTdProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                lineHeight: "20px",
              },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
              style: {
                backgroundColor: "#00425f",
                borderBottom: "0.1px solid #DBDEDE",
                borderWidth: "0.1px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#FFFFFF",
                fontSize: "13px",
                fontWeight: "500",
                textAlign: "left",
              },
            })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
            className="-striped -highlight"
          />
        ) : (
          <ReactTable
            PaginationComponent={Pagination}
            loading={this.props.loading}
            LoadingComponent={MyLoader}
            noDataText={!this.props.loading ? this.props.noDataText : ""}
            style={{
              border: "none",
              boxShadow: "none",
              whiteSpace: "unset",
              height: this.state.tableHeight,
            }}
            data={this.props.data}
            dataCount={this.props.dataCount}
            pageSizes={getPaginationRows(this.props.dataCount)}
            columns={columns2}
            manual
            pages={this.props.pages}
            page={
              this.props.startPage ? this.props.startPage - 1 : this.state.page
            }
            onFetchData={(state, instance) => {
              const {
                transactionType,
                agentId,
                reconciliationType,
                transactionStatus,
                transRef,
                endDate,
                startDate,
              } = this.props.searchParameters;
              this.setState({ page: state.page });
              if (this.props.canLoadData) {
                this.props.getAllReconReports(
                  state.page + 1,
                  state.pageSize,
                  transactionType,
                  agentId,
                  reconciliationType,
                  transactionStatus,
                  transRef,
                  endDate,
                  startDate
                );
              }
            }}
            headerNodeHeight={`123`}
            minRows={this.props.data.length > 0 ? 10 : 10}
            getTableProps={(state, rowInfo, column, instance) => ({
              style: {
                borderColor: "#00425f",
                boxSizing: "none",
                border: "none",
              },
            })}
            getTdProps={(state, rowInfo, column, instance) => ({
              style: {
                display: "flex",
                overflow: "visible",
                color: "black",
                fontSize: "11.5px",
                fontWeight: "50",
                paddingLeft: "5px",
                paddingRight: "5px",
                alignItems: "center",
                padding: "0px, 0",
              },
            })}
            getTheadThProps={(state, rowInfo, column, instance) => ({
              style: {
                backgroundColor: "#00425f",
                borderBottom: "0.1px solid #DBDEDE",
                borderWidth: "0.1px",
                padding: "10px 0",
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: "100",
                textAlign: "left",
                paddingLeft: "5px",
                paddingRight: "5px",
              },
            })}
            getPaginationProps={(state, rowInfo, column, instance) => ({
              style: {
                overflow: "visible",
                backgroundColor: "#ffffff",
              },
            })}
            className="-striped -highlight"
          />
        )}
      </React.Fragment>
    );
  }
}
