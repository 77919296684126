import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { RegionExportSerializer } from '../../../../../../serializers';
import { formatDateTime } from '../../../../../../utils/formatter';

export default class RegionTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '700px',
    zoom: 'plus'
  }

zoomTableHeight = this.zoomTableHeight.bind(this)

zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
}
  render() {
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
        {
          Header: "S/N",
          accessor: 'id',
          Cell: (id) => <span>{id.index + 1}</span>,
          sortable: false,
          resizable: false,
          width: 60,
          headerStyle: {'white-space': 'unset'},
        },{
          Header: "Region Name",
          accessor: 'regionName',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
        },{
          Header: "Region Code",
          accessor: 'regionCode',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
        },{
          Header: "Status",
          accessor: 'status',
          resizable: false,
          width: 80,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                textAlign: 'center'
              },
            };
          },
          Cell: props => <span style={{color: props.value === 0 ? '#5cb85c' : props.value === 1 ? '#d9534f' : '', fontWeight: 'bold' }}>{props.value === 0 ? 'Active' : props.value === 1 ? 'Inactive' : ''}</span>
          //Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge kt-badge--inline ${props.value === 1 ? 'kt-badge--success' : props.value === 2 ? 'kt-badge--warning' : '' } kt-badge--pill`}>{props.value === 1 ? 'Active' : props.value === 2 ? 'Inactive' : ''}</span>,
        },{
          Header: "Date Created",
          accessor: 'dateCreated',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
          //width: 100,
          Cell: props => {
            return props.value ? formatDateTime(props.value) : ''
          }
        },{
          Header: "Last Modified",
          accessor: 'dateLastUpdated',
          resizable: false,
          headerStyle: {'white-space': 'unset'},
          style: {'white-space': 'unset'},
          //width: 100,
          Cell: props => {
            return props.value ? formatDateTime(props.value) : ''
          }
        },{
          Header: "Action",
          accessor: 'id',
          overflow: 'visible',
          resizable: false,
          sortable: false,
          headerStyle: {'white-space': 'unset'},
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                lineHeight: '20px',
                textAlign: 'center',
                whiteSpace: 'unset',
              },
            };
          },
          width: 100,
          Cell: ({row, original}) => <div class="dropdown">
            <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canUpdate]}><Link to={`region-management/${original.regionId}/view`} class="dropdown-item">
                <i class="la la-edit"></i>
                Edit Region
              </Link></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`region-management/map/${original.regionId}/view`} class="dropdown-item">
                <i class="la la-eye"></i>
                View Mapping
              </Link></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canActivate]}><a hidden={original.status === 1  ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(original.regionName, original.regionId, 'activate')} class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                <i class="la la-toggle-on"></i>
                Activate
              </a></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canDeactivate]}><a hidden={original.status === 0 ? '' : 'hidden'} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(original.regionName, original.regionId, 'deactivate')} class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                <i class="la la-ban"></i>
                Deactivate
              </a></ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canDelete]}><a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getClickedAction(original.regionName, original.regionId, 'delete')} class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                <i class="la la-trash"></i>
                Delete
              </a></ProtectedComponent>
            </div>
          </div>
        }
    ]

    return <React.Fragment> 
    <TableExtra 
      zoomTableHeight={this.zoomTableHeight}
      getData={this.props.getRegions}
      zoom={this.state.zoom}
      page={this.props.page}
      pageSize={this.props.pageSize}
      data={RegionExportSerializer.reverseRegionDetails(this.props.data)}
      headers={RegionExportSerializer.headerExport()}
      fFileName={'Regions'}
      disabled={this.props.loading}
    />
    <ReactTable 
      PaginationComponent={Pagination} 
      loading={this.props.loading}
      LoadingComponent={MyLoader}
      noDataText={!this.props.loading ? this.props.noDataText : ''}
      style={{
        border: "none",
        boxShadow: "none",
        whiteSpace: 'unset',
        height: this.state.tableHeight,
      }}
      data={this.props.data}
      dataCount={this.props.dataCount}
      pageSizes={[10,20,25,50,100]}
      columns={columns}
      pages={this.props.pages}
      page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
      onFetchData={(state, instance) => {
        this.setState({page: state.page})
        this.props.getRegions(state.page + 1,state.pageSize) 
      }}
      headerNodeHeight={`123`}
      minRows={(this.props.data).length > 0 ? 10 : 10}
      getTableProps={(state, rowInfo, column, instance) => ({
        style: {
          borderColor: '#00425f',
          boxSizing: 'none',
          border: 'none',
        },
      })}
      getTdProps={(state, rowInfo, column, instance) => ({
        style: {
          overflow: 'visible',
          lineHeight: '11.5px',
          display:'flex',
          alignItems: 'center',
        },
      })}
      getTheadThProps={(state, rowInfo, column, instance) => ({
        style: {
          backgroundColor: '#00425f',
          borderBottom: '0.1px solid #DBDEDE',
          borderWidth: '0.1px',
          padding:"0px,0px",
          paddingTop: '10px',
          paddingBottom: '10px',
          color: '#FFFFFF',
          fontSize: '12px',
          fontWeight: '300',
          textAlign: 'left'
        },
      })}
      className="-striped -highlight" 
    />
  </React.Fragment>
  }
}