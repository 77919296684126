import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';
import HistoricalServiceTypes from '../../../../../../fixtures/historical-service_types';

export default class HistoricalTransactionsSearch extends React.Component {
    
    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest()
    }

    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label class="search-label">Agent phone:</label>
                        <input type="text" class="search-input" onChange={(event) => this.props.updateSearchParameters('agentPhone', event.target.value)} />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">GMPP Ref</label>
                        <input type="text" class="search-input" onChange={(event) => this.props.updateSearchParameters('gmppRef', event.target.value)} />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Transaction Type</label>
                        <Select 
                            options={HistoricalServiceTypes}
                            onChange={(event) => this.props.updateSearchParameters('transType', event.value)}
                            defaultValue={this.props.searchParameters.transType}
                        />
                        {/* <select class="search-input" onChange={(event) => this.props.updateSearchParameters('transType', event.target.value)} >
                            <option selected value={null} disabled>All</option>
                            {HistoricalServiceTypes.map(value=><option value={value.key} selected={this.props.searchParameters.transactionType === value.key ? 'selected' : ''}>{value.name}</option>)}
                        </select> */}
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.props.updateSearchParameters('startDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent 
                            onChange={(event) => this.props.updateSearchParameters('endDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.props.searchParameters.startDate ? false : true}
                            minDate={this.props.searchParameters.startDate}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateTYpe={"endDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}