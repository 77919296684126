import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ViewFipScene from './scenes/view-fip';
import FipsGridScene from './scenes/fips-grid';
import AddFipScene from './scenes/add-fip'
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_FIP_USER, CAN_CREATE_FIP_USER } from '../../../../../constants/permissions.js'

export default class FipsScene extends Component {
  sceneName = 'fip-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FIP_USER]} exact path='/home/fip-management' component={FipsGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_FIP_USER]} exact path="/home/fip-management/add" component={AddFipScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FIP_USER]} exact path='/home/fip-management/:id/view' component={ViewFipScene} />
    </Router>
  }
}
