import React from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

export default class TableExtra extends React.Component {
  get canResolveOrRequeryTransactions() {
    return Boolean(!this.props.checkedTransactions.length);
  }

  resolutionValues = [
    { value: "1", name: "Successful" },
    { value: "0", name: "Failed" },
  ];

  render() {
    return (
      <React.Fragment>
        <div
          class="btn-toolbar justify-content-between"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <div
            class="btn-group ml-12"
            role="group"
            aria-label="First group"
          ></div>
          <div class="btn-group ml-12" role="group" aria-label="First group">
            <button
              type="button"
              title="Reload Grid"
              class="btn btn-secondary"
              disabled={this.props.disabled}
              onClick={() =>
                this.props.getData(this.props.page, this.props.pageSize)
              }
            >
              <i class="la la-refresh"></i>
            </button>
            {!this.props.downloadLink &&
              !this.props.hasMultipleDownloadLinks &&
              this.props.data && (
                <button
                  type="button"
                  title="Download Excel"
                  class="btn btn-secondary"
                  disabled={this.props.disabled ? this.props.disabled : false}
                  onClick={this.props.downloadWalletJournal}
                >
                  {!this.props.disabled &&
                    typeof this.props.downloadWalletJournal !== "function" && (
                      <CSVLink
                        data={this.props.data}
                        headers={this.props.headers}
                        filename={`${this.props.fileName}.csv`}
                        classsName="kt-nav__link"
                      >
                        <i class="la la-download"></i>
                      </CSVLink>
                    )}
                  {!this.props.disabled &&
                    typeof this.props.downloadWalletJournal === "function" && (
                      <i class="la la-download"></i>
                    )}
                </button>
              )}
            {this.props.downloadLink && !this.props.hasMultipleDownloadLinks && (
              // &&
              // this.props.data.length > 0
              <button
                type="button"
                title="Download"
                class="btn btn-secondary"
                // disabled={this.props.disabled}
                onClick={this.props.downloadLink}
                disabled={this.props.disabled ? this.props.disabled : false}
              >
                <i class="la la-download"></i>
              </button>
            )}
            {this.props.hasMultipleDownloadLinks && !this.props.downloadLink && (
              <div class="dropdown">
                <button
                  type="button"
                  title="Download"
                  class="btn btn-secondary"
                  disabled={this.props.disabled}
                  data-toggle="dropdown"
                >
                  <i class="la la-download"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul class="kt-nav">
                    <li class="kt-nav__item">
                      <Link
                        class="kt-nav__link"
                        onClick={this.props.downloadLink1}
                      >
                        <span class="kt-nav__link-text">Summary Report</span>
                      </Link>
                    </li>
                    <li class="kt-nav__item" onClick={this.props.downloadLink2}>
                      <Link class="kt-nav__link">
                        <span class="kt-nav__link-text">Summary Details</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <button
              type="button"
              title="Print"
              class="btn btn-secondary"
              disabled={this.props.disabled}
              onClick={() => window.print()}
            >
              <i class="la la-print"></i>
            </button>
            {/* <button type="button" title="download" class="btn btn-secondary">
              <i class="la la-download"></i>
            </button> */}
            <button
              type="button"
              title="Expand"
              class="btn btn-secondary"
              disabled={this.props.disabled}
              onClick={() => this.props.zoomTableHeight()}
            >
              <i class="la la-expand"></i>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
