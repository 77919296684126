import React from "react";
import { Link } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import Table from "../../../../../../../components/table";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import {
  CAN_UPDATE_BANK,
  CAN_VIEW_BANK,
} from "../../../../../../../constants/permissions.js";
import { accountOpeningService } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { SanefBankExportSerializer } from "../../../../../../../serializers";

export default class BankGridScene extends BaseScene {
  sceneName = "sanef-bank-management";

  state = {
    bankList: [],
  };

  getBankList = this.getBankList.bind(this);
  getClickedAction = this.getClickedAction.bind(this);
  onUpdateButtonClick = this.onUpdateButtonClick.bind(this);

  componentDidMount() {
    this.getBankList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getBankList(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });

    const responseObj = await accountOpeningService.getSanefBankList();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        bankList: response,
        dataCount: response.length,
        pages: response.length,
        pageSize: response.length,
        page: pageNum,
        startPage: null,
        isLoading: false,
        tableMessage: "",
      });
    } else {
      this.setState({
        bankList: [],
        dataCount: 0,
        isLoading: false,
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        errorMessage: response,
        tableMessage: "No record found",
      });
    }
  }

  async onUpdateButtonClick() {
    this.setState({ isLoading: true });

    const payload = {
      sanefBankCode: this.state.sanefBankCode,
      bankName: this.state.bankName,
      enabledForSanef: this.state.enabledForSanef,
      enabledForCardLinking: this.state.enabledForCardLinking,
      requireBvn: this.state.requireBvn,
    };

    const responseObj = await accountOpeningService.modifyBankDetails(
      this.state.sanefBankCode,
      payload
    );

    const status = responseObj.status;
    const response = responseObj.response;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: `${this.state.bankName} successfully ${this.state.categoryName}d`,
        redirect: true,
        updateButton: true,
        saveButton: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }

    this.getBankList();
  }

  getClickedAction(
    sanefBankCode,
    bankName,
    enabledForSanef,
    enabledForCardLinking,
    requireBvn,
    categoryName
  ) {
    this.setState({
      sanefBankCode,
      bankName,
      enabledForSanef,
      enabledForCardLinking,
      requireBvn,
      categoryName,
      modalIsOpen: true,
      canDelete: false,
      modalTitle: `${bankName}`,
      updateModalBody: `Do you want to ${categoryName} ${bankName}?`,
      modalHeight: "250px",
      canUpdate: false,
      canAdd: false,
    });
  }

  render() {
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
      },
      {
        Header: "Bank Name",
        accessor: "bankName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "CBN Code",
        accessor: "cbnCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Sanef Bank Code",
        accessor: "sanefBankCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
      },
      {
        Header: "Acct. Opening",
        accessor: "enabledForSanef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (props.value === true ? "Active" : "Inactive"),
      },
      {
        Header: "Debit Card",
        accessor: "enabledForCardLinking",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (props.value === true ? "Active" : "Inactive"),
      },
      {
        Header: "Require BVN",
        accessor: "requireBvn",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (props.value === true ? "Yes" : "No"),
      },
      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 100,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[CAN_UPDATE_BANK]}>
                <Link
                  to={`sanef-bank-management/${original.sanefBankCode}/view`}
                  class="dropdown-item"
                >
                  <i class="la la-edit"></i>
                  Edit Bank
                </Link>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Sanef Banks"}
          filterPermissions={[CAN_UPDATE_BANK]}
          actionButtonText={"Add Sanef Bank"}
          actionButtonUrl={
            "/home/system-configuration/sanef-bank-management/add"
          }
          totalCount={56}
          actionButtonRequiredPermissions={[CAN_UPDATE_BANK]}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_BANK]}
        >
          <GridLayout>
            <Table
              columns={columns}
              data={this.state.bankList}
              loading={this.state.isLoading}
              NoDataText={`No bank found`}
              dataCount={this.state.dataCount}
              getData={this.getBankList}
              exportData={SanefBankExportSerializer.reverseSanefBankDetails(
                this.state.bankList
              )}
              exportHeaders={SanefBankExportSerializer.headerExport()}
              exportFileName={"SANEF Banks"}
            />
          </GridLayout>
        </ProtectedComponent>

        <PromptModal
          modalTitle={this.state.modalTitle}
          modalBody={this.state.updateModalBody}
          deleteModal={this.onUpdateButtonClick}
          modalSize={`modal-md`}
          height={this.state.modalHeight}
          textAlign={"center"}
          modalFooterButton1={`Cancel`}
          modalFooterButton2={"Yes"}
          modalFooterButton2Disabled={
            this.state.canDelete ? false : !this.state.bankName
          }
          hideModalHeader={true}
          hideModalFooter={true}
        />
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
