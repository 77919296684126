import React from 'react'

export default class MoveWorkflowStep extends React.Component {
    render() {
        return <React.Fragment>
            <span>Move workflow step from {this.props.stepId} to </span>
            <select onChange={event => this.props.updateSetState('moveTo', event.target.value)}>
                {this.props.workflowDetails.steps.map(step => <option hidden={step.id === this.props.stepId ? true : false}value={step.id}>{step.id}</option>)}
            </select>
        </React.Fragment>
    }
}