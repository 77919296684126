import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_CREATE_FEE, CAN_DELETE_FEE, CAN_VIEW_FEE } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import ExternalFeeSearch from '../../components/external-fee-search';
import ExternalFeeTable from '../../components/external-fee-table';

export default class ExternalFeeGridScene extends BaseScene {

  notificationSectionRef = React.createRef(null)
  
  state = {
    externalFees: [],
    isLoading: true,
    searchParams: [],
    checkedExternalFees: [],
  }

  getExternalFees = this.getExternalFees.bind(this);
  updateStatus = this.updateStatus.bind(this);
  getClickedAction = this.getClickedAction.bind(this);
  handleSearchRequest = this.handleSearchRequest.bind(this);
  getSelectedExternalFees = this.getSelectedExternalFees.bind(this)
  selectAllExternalFees = this.selectAllExternalFees.bind(this)
  bulkBlacklistExternalFees = this.bulkBlacklistExternalFees.bind(this)

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async updateStatus () {
    this.setState({isLoading: true})
    if(this.state.externalFeeStatus === 'delete') {
      const responseObj = await feeConfigurationManagement.deleteExternalFee(this.state.externalFeeId)
      const { status, response } = responseObj
      if (status === SUCCESS_STATUS) {
        if(response.code === ACCEPTED_REQUEST){
          this.setState({
            isLoading: false,
            successMessage: 'Your request to blacklist this external fee has been sent successfully for approval.',
          })
          return
        }else{
          this.setState({
            successMessage: "External fee blacklisted successfully!",
            isLoading: false,
          })
        }
      }else{
        this.setState({
          errorMessage: response,
          isLoading: false, 
        })
      }
      this.getExternalFees()
      setTimeout(() => {
        this.setState({
          externalFeeId: null,
          externalFeeStatus: null
        })
      }, 3000)
    }
  }

  async getExternalFees (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getExternalFees(pageNum, pageSize, this.state.searchParams)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        externalFees: response ? response : [],
        dataCount: count,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      })
    }else{
      this.setState({
        externalFees: [],
        dataCount: 0,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response
      })
    }
  }

  getClickedAction (status, id) {
    this.setState({
      externalFeeId: id,
      externalFeeStatus: status
    })
  }

  handleSearchRequest (searchParams) {
    this.setState({
      searchParams,
      startPage: 1
    })
    setTimeout(() => {
      this.getExternalFees(1, 20)
    }, 500)
  }

  getSelectedExternalFees (isChecked, externalFeeId) {
    let checkedExternalFees = this.state.checkedExternalFees
    const externalFees = this.state.externalFees.map(value => {
      if(value.id === externalFeeId) {
        value.checked = isChecked
        const externalFeeExist = this.handleDuplicateExternalFees(checkedExternalFees, externalFeeId )
        if(isChecked) {
          if(!externalFeeExist) {
            checkedExternalFees.push(externalFeeId)
          }
        }else{
          checkedExternalFees = checkedExternalFees.filter(function(item) {
            return item !== externalFeeId
          })
        }
      }
      return value
    })
    this.setState({
      externalFees,
      checkedExternalFees
    })
  }

  selectAllExternalFees(isChecked) {
    let checkedExternalFees = this.state.checkedExternalFees
    const externalFees = this.state.externalFees.map(value => {
      value.checked = isChecked 
      const externalFeeExist = this.handleDuplicateExternalFees(checkedExternalFees, value.id )
      if(isChecked) {
        if(!externalFeeExist) {
          checkedExternalFees.push(value.id)
        }
      }else{
        checkedExternalFees = checkedExternalFees.filter(function(item) {
          return item !== value.id
        })
      }
      return value
    })

    this.setState({
      externalFees,
      checkedExternalFees
    })
  }

  handleDuplicateExternalFees (externalFees, externalFeeId) {
    if(externalFees.indexOf(externalFeeId) === -1) {
      return false
    }else{
      return true
    }
  }

  async bulkBlacklistExternalFees () {
    this.setState({isLoading: true})
    const responseObj = this.state.checkedExternalFees.length ? await feeConfigurationManagement.bulkBlacklistExternalFees(this.state.checkedExternalFees) 
      : {status: 'ERROR', response: {description: "Check out the external fee(s) you want to bulk blacklist"}}
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      if(response.code === ACCEPTED_REQUEST){
        this.setState({
          isLoading: false,
          successMessage: 'Your request to bulk blacklist external fees has been sent successfully for approval.',
          checkedExternalFees: []
        })
        return
      }else{
        this.setState({
          successMessage: "External fees blacklisted successfully!",
          isLoading: false,
          checkedExternalFees: []
        })
      }
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false
      })
    }
    this.getExternalFees(this.state.pageNum, this.state.pageSize)
    window.scrollTo(0, this.notificationSectionRef.offsetTop)
  }

  render () {
    
    return <React.Fragment>
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
        ref={this.notificationSectionRef}
      />
      <ContentHead title={"External Fees"} filterPermissions={[CAN_VIEW_FEE]} actionButtonRequiredPermissions={[CAN_CREATE_FEE]} actionButtonText={"Add External Fee"} actionButtonUrl={"/home/external-fees/add"} totalCount={56} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_FEE]}>
        <GridLayout>
          <ExternalFeeSearch 
            handleSearchRequest={this.handleSearchRequest}
            searchParams={this.state.searchParams}
            checkedExternalFees={this.state.checkedExternalFees}
            bulkBlacklistExternalFees={this.bulkBlacklistExternalFees}
          />
          <ExternalFeeTable 
            data={this.state.externalFees}
            getExternalFees={this.getExternalFees}
            pages={this.state.pages} 
            updateStatus={this.getClickedAction}
            loading={this.state.isLoading} 
            noDataText={`No external fees found`}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canDelete={CAN_DELETE_FEE}
            getSelectedExternalFees={this.getSelectedExternalFees}
            selectAllExternalFees={this.selectAllExternalFees}
          />
          <PromptModal 
            modalTitle={`Blacklist External Fee`}
            modalBody={`Do you want to blacklist this external fee?`}
            deleteModal={this.updateStatus}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
