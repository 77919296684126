import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CashoutGridScene from './scenes/cashout-grid'
import BaseScene from "../../../../../components/scenes/component";
import { isAuthorized } from "../../../../../mixins/authentication";
import {
  CAN_VIEW_TRANSACTION
} from "../../../../../constants/permissions.js";

export default class CashoutInitiatedScene extends BaseScene {
  sceneName = "cashout-initiated";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/cashout-initiated"
          component={CashoutGridScene}
        />
      </Router>
    );
  }
}
