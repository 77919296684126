import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import { formatDate } from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { platformManagement } from "../../../../../../mixins/api.js";

export default class AgentTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
    showModal: false,
    selectedAgent: null,
    checkboxState: {
      nin:
        this.props.data && this.props.data[0]
          ? this.props.data[0].bvnNinMatch || false
          : false,
      tin:
        this.props.data && this.props.data[0]
          ? this.props.data[0].tinNinMatch || false
          : false,
      bvn:
        this.props.data && this.props.data[0]
          ? this.props.data[0].livelinessCheck || false
          : false,
    },
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  // getSerialNumber(index) {
  //   return this.props.pageSize * (this.props.page - 1) + index;
  // }
  getSerialNumber(index) {
    const pageSize = Number(this.props.pageSize) || 10;
    const currentPage = Number(this.props.page) || 1;
    const safeIndex = Number(index) + 1 || 1;

    return pageSize * (currentPage - 1) + safeIndex;
  }
  toggleModal = (agent = null) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        selectedAgent: agent,
        checkboxState: agent
          ? {
              nin: agent.bvnNinMatch || false,
              tin: agent.tinNinMatch || false,
              bvn: agent.livelinessCheck || false,
            }
          : { nin: false, tin: false, bvn: false },
      }),
      () => {
        if (!this.state.showModal && this.props.getAgents) {
          this.props.getAgents(this.state.page + 1, this.props.pageSize || 10);
        }
      }
    );
  };

  handleCheckboxChange = async (kycType) => {
    const { selectedAgent, checkboxState } = this.state;

    const adjustedKycType = kycType === "bvn" ? "LIVELINESS" : kycType;
    const updatedFlag = !checkboxState[kycType];

    this.setState((prevState) => ({
      checkboxState: {
        ...prevState.checkboxState,
        [kycType]: updatedFlag,
      },
    }));

    try {
      await platformManagement.reinitiateKyc(
        selectedAgent.agentId,
        adjustedKycType,
        updatedFlag
      );
    } catch (error) {
      console.error("Error updating KYC:", error);
    }
  };

  render() {
    const agentData = this.props.data && this.props.data;

    const checkboxState = this.state.checkboxState || {
      nin: false,
      tin: false,
      bvn: false,
    };

    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columnsUpdate = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value.toLowerCase()}</div>
        ),
        width: 250,
      },

      {
        Header: "BVN",
        accessor: "livelinessCheck",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData[props.index].livelinessCheck ? (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                True
              </span>
            ) : (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                False
              </span>
            )}
          </div>
        ),
        width: 150,
      },
      {
        Header: "NIN",
        accessor: "bvnNinMatch",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {/* {`${props.value}`} */}
            {agentData[props.index].bvnNinMatch ? (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                True
              </span>
            ) : (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                False
              </span>
            )}
          </div>
        ),
        width: 150,
      },
      {
        Header: "TIN",
        accessor: "tinNinMatch",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData[props.index].tinNinMatch ? (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                True
              </span>
            ) : (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                False
              </span>
            )}
          </div>
        ),
        width: 150,
      },
      {
        Header: "Date Exempted",
        accessor: "skipDateCreated",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Modified Date",
        accessor: "skipModifiedDate",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Exemption Status",
        accessor: "skipCacTinCheck",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData[props.index].skipCacTinCheck ? (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                True
              </span>
            ) : (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                False
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "agentId",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: ({ row, original }) => (
          <div className="dropdown" style={{ textAlign: "center" }}>
            <a
              href="#"
              className="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i className="la la-ellipsis-v"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              {original.skipCacTinCheck && (
                <ProtectedComponent
                  requiredPermissions={[this.props.canRetire]}
                >
                  <a
                    onClick={() =>
                      this.props.getClickedAction(
                        original.agentId,
                        original.businessName
                      )
                    }
                    data-toggle="modal"
                    data-target="#kt_modal_1"
                    className="dropdown-item"
                    style={{ color: "#A7ABC3", cursor: "pointer" }}
                  >
                    <i className="la la-lock"></i>
                    Remove
                  </a>
                </ProtectedComponent>
              )}
              {process.env.REACT_APP_SHOW_AGENT_EXEMPTION_UPDATE === "true" && (
                <ProtectedComponent
                  requiredPermissions={[this.props.canRetire]}
                >
                  <a
                    onClick={() => this.toggleModal(original)}
                    className="dropdown-item"
                    style={{ color: "#A7ABC3", cursor: "pointer" }}
                  >
                    <i className="la la-lock"></i>
                    View
                  </a>
                </ProtectedComponent>
              )}
            </div>
          </div>
        ),
        width: 60,
      },
    ];

    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 60,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value.toLowerCase()}</div>
        ),
        width: 250,
      },
      {
        Header: "Agent Code",
        accessor: "agentCode",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
        width: 200,
      },
      {
        Header: "Date Exempted",
        accessor: "skipDateCreated",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Modified Date",
        accessor: "skipModifiedDate",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Exemption Status",
        accessor: "skipCacTinCheck",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {agentData[props.index].skipCacTinCheck ? (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                True
              </span>
            ) : (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill"
                style={{ color: "#ffffff" }}
              >
                False
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "Agent Class",
        accessor: "agentClass",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? props.value.toLowerCase() : null}
          </div>
        ),
        width: 120,
      },
      {
        Header: "Action",
        accessor: "agentId",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: ({ row, original }) => (
          <div class="dropdown" style={{ textAlign: "center" }}>
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canRetire]}>
                <a
                  onClick={() =>
                    this.props.getClickedAction(
                      original.agentId,
                      original.businessName
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-lock"></i>
                  Remove
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
        width: 60,
      },
    ];

    const AgentDetailsModal = ({
      show,
      agent,
      onClose,
      checkboxState,
      handleCheckboxChange,
    }) => {
      return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>KYC Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>
                    <strong>NIN:</strong>
                  </label>
                  <Form.Check
                    type="checkbox"
                    checked={checkboxState.nin || false}
                    onChange={() => handleCheckboxChange("nin")}
                  />
                  <label>
                    <strong>Reinitiate NIN</strong>
                  </label>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>
                    <strong>TIN:</strong>
                  </label>
                  <Form.Check
                    type="checkbox"
                    checked={checkboxState.tin || false}
                    onChange={() => handleCheckboxChange("tin")}
                  />
                  <label>
                    <strong>Reinitiate TIN</strong>
                  </label>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>
                    <strong>BVN:</strong>
                  </label>
                  <Form.Check
                    type="checkbox"
                    checked={checkboxState.bvn || false}
                    onChange={() => handleCheckboxChange("bvn")}
                  />
                  <label>
                    <strong>Reinitiate BVN</strong>
                  </label>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };

    return (
      <React.Fragment>
        {/* <TableExtra
          data={AgentDetailsExportSerializer.reverseAgentDetails(agentData)}
          headers={AgentDetailsExportSerializer.headerExport()}
          fileName={"agents"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAgents}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          downloadLink={this.props.downloadAgentData}
          disabled={this.props.loading}
        /> */}
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
            justifyContent: "center",
          }}
          data={agentData}
          columns={
            process.env.REACT_APP_SHOW_AGENT_EXEMPTION_UPDATE === "true"
              ? columnsUpdate
              : columns
          }
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={agentData.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAgents(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
              justifyContent: "center",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              justifyContent: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
        <AgentDetailsModal
          show={this.state.showModal}
          agent={this.state.selectedAgent}
          onClose={() => this.toggleModal()}
          checkboxState={this.state.checkboxState}
          handleCheckboxChange={this.handleCheckboxChange}
        />
      </React.Fragment>
    );
  }
}
