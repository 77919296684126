import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import BaseScene from '../../../../../../../components/scenes/component';
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { CAN_DISTRIBUTE_WALLET } from '../../../../../../../constants/permissions'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../components/access-denied';
import RoleForm from '../../../../../../../components/forms/role-form'
import { Redirect } from 'react-router-dom'

export default class AgentHierarchyAddRoleScene extends BaseScene {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			successMessage: null,
            errorMessage: null,
            permissions: [],
            roleFormData: [],
            roleDetails: []
        }
        this.updateRoleFormData = this.updateRoleFormData.bind(this)
    }
      
    updateRoleFormData (type, value) {
        this.setState({roleFormData: {...this.state.roleFormData, [type]: value}})
    }

    isFormValid () {
        return Boolean(!this.state.roleFormData.name || !this.state.roleFormData.permissions) 
    }

  	render () {
        if(this.state.redirect) {
            return <Redirect to="/home/role-management" />
        }
      
        const ctaWidgets = <React.Fragment>
            <ProtectedComponent requiredPermissions={[CAN_DISTRIBUTE_WALLET]}><button type="button" disabled={this.isFormValid()} class="btn btn-primary"  style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save</button></ProtectedComponent>
        </React.Fragment>
        
        return <React.Fragment>
            <Loader 
                isLoading={this.state.isLoading}
            />
            <Notification
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"Agent Hierarchy Management"} withoutSearch ctaWidgets={ctaWidgets} />
            <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_DISTRIBUTE_WALLET]}>
                <GridLayout>
                    <RoleForm 
                        allPermissions={this.state.permissions}
                        onChange={this.updateRoleFormData}
                        roleFormData={this.state.roleFormData}
                        roleDetails={this.state.roleDetails}
                    />
                </GridLayout>				
            </ProtectedComponent>
        </React.Fragment>
    }

    get permissionDeniedContent() {
        return <AccessDenied />
    }
}
