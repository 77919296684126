import { stripNonNumbers, trimPhoneNumber } from '../utils/helper'

export default class AgentDetailsSerializer {
    static serializeApplicantDetails (applicantDetailsFormData, businessDetailsFormData) {

      const { bankName, accountNumber, agentClass, agentClassId, superAgent, parentId } = businessDetailsFormData

      const { 
        bvn, phoneNumber, phone, dob, emailAddress, email, firstName, gender, identificationType, surname, lastName, middleName, mothersMaidenName, 
        country, nationality, placeOfBirth, address, state, lga, localGovernmentArea, closestLandmark, identificationNumber, nextOfKin, howYouHeardAboutUs
      } = applicantDetailsFormData

      let newFormData = {
        "agentBankAccount": {
          bankName,
          accountNumber,
          bvn
        }, 
        howYouHeardAboutUs,
        agentClassId: agentClassId,
        agentMobileNo: phone ? trimPhoneNumber(stripNonNumbers(phone)) : trimPhoneNumber(stripNonNumbers(phoneNumber)),
        "businessContact": {
          dob, 
          firstname: firstName,
          gender,
          emailAddress: email ? email : emailAddress,
          phoneNo: phone ? trimPhoneNumber(stripNonNumbers(phone)) : trimPhoneNumber(stripNonNumbers(phoneNumber)),
          //identityId: identificationType,
          lastname: lastName ? lastName : surname,
          middlename: middleName,
          motherMadienName: mothersMaidenName,
          nationality: country ? country : nationality, 
          placeOfBirth,
          "residentialAddress": {
            addressLine1: address,
            stateId: state,
            lgaId: lga ? lga : localGovernmentArea,
            countryId: country ? country : nationality,
            landmark: closestLandmark
          }
        }, 
        identificationNumber,
        identificationType,
        "nextOfKins": {
          firstname: nextOfKin.firstName,
          gender: nextOfKin.gender,
          lastname: nextOfKin.surname,
          phoneNo: trimPhoneNumber(stripNonNumbers(nextOfKin.phoneNumber)),
          residentialAddress: {
            addressLine1: nextOfKin.address
          }
        }, 
        parentId: superAgent ? superAgent: parentId,
        businessEmail: email ? email : emailAddress
      }
      
      // const { businessLocation, nextOfKins } = agentFormData

      // let newFormData  = {
      //   ...agentFormData,
      //   businessLocation: businessLocation[0],
      //   nextOfKins: nextOfKins[0]
      // }
  
      return newFormData
    }

    static serializeBusinessDetails (applicantDetailsFormData, businessDetailsFormData) {
      
      const { country, nationality, closestLandmark } = applicantDetailsFormData

      const { address, state, localGovernmentArea, businessName, phoneNumber, businessType, companyRegistrationNumber, businessEmail } = businessDetailsFormData

      let newFormData = {
        "businessLocation": {
          addressLine1: address,
          countryId: country ? country : nationality,
          stateId: state,
          lgaId: localGovernmentArea
        },
        businessName,
        businessPhoneNo: trimPhoneNumber(stripNonNumbers(phoneNumber)),
        businessTypeName: businessType,
        businessEmail: businessEmail,
        companyRegNo: companyRegistrationNumber
      }
      
      // const { businessLocation, nextOfKins } = agentFormData

      // let newFormData  = {
      //   ...agentFormData,
      //   businessLocation: businessLocation[0],
      //   nextOfKins: nextOfKins[0]
      // }
  
      return newFormData
    }
  
    
  }
  