import React from "react";
import ReactTable from "react-table";
import { Modal, Button } from "react-bootstrap";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import { formatDate } from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";
import { platformManagement } from "../../../../../../mixins/api";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import Notification from "../../../../../../components/notification";
import FileUpload from "./file-upload";

export default class StaticAccountTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
    data: [],
    isModalOpen: false,
    selectedProvider: this.props.selectedProvider || { bankName: "", providerCode: "" },
    isLoading: false,
    tableMessage: "",
    staticAccountProvider: [],
    configFile: null,
    stockDisable: false,
    configDisable: false,
    isSaveEnabled: false,
    isSubmitEnabled: false,
    successMessage: "",
    errorMessage: "",
    iconUploaded: false,
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  constructor(props) {
    super(props);
    this.toggleStaticAccountProvider = this.toggleStaticAccountProvider.bind(this);
    this.editStaticAccountProvider = this.editStaticAccountProvider.bind(this);
    this.handleIconUpload = this.handleIconUpload.bind(this);
    this.readUploadedFile = this.readUploadedFile.bind(this);
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }

    if (prevProps.selectedProvider !== this.props.selectedProvider && this.props.isModalOpen) {
      this.setState({ selectedProvider: this.props.selectedProvider || {} });
    }

    if (this.state.errorMessage && prevState.errorMessage !== this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }

    if (this.state.successMessage && prevState.successMessage !== this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
            ? "auto"
            : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getSerialNumber(index) {
    return this.props.pageSize * (this.props.page - 1) + index;
  }

  toggleStaticAccountProvider = async (id, isActive) => {
    try {
      const responseObj = await platformManagement.toggleStaticAccountProvider(id, !isActive);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS) {
        this.setState({
          successMessage: response.description,
        });
        this.setState((prevState) => {
          const updatedData = prevState.data.map((item) =>
            item.id === id ? { ...item, enabled: !item.enabled } : item
          );
          if (this.props.onUpdateData) {
            this.props.onUpdateData(updatedData);
          }
          return { data: updatedData };
        });
      } else {
        this.setState({
          errorMessage: response.description,
          isLoading: false,
        });
        console.error("API call failed. Status:", status);
      }
    } catch (error) {
      console.error("Error toggling static account provider:", error);
    }
  };

  handleModalOpen = (provider) => {
    this.setState({
      isModalOpen: true,
      selectedProvider: provider,
      isSubmitEnabled: false,
      isSaveEnabled: false,
    });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  editStaticAccountProvider = async () => {
    this.setState({ isLoading: true });

    const { selectedProvider } = this.state;
    const payload = {
      bankName: selectedProvider.bankName,
      providerCode: selectedProvider.providerCode,
    };
    try {
      const responseObj = await platformManagement.updateStaticAccountProvider(this.state.selectedProvider.id, payload);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS) {
        if (this.props.onUpdateData) {
          this.props.onUpdateData();
        }
        const data = Array.isArray(response.data) ? response.data : [];
        this.setState({
          successMessage: response.description,
          staticAccountProvider: data,
          isLoading: false,
          isModalOpen: false,
        });
        setTimeout(() => this.setState({ redirect: true }), 2000);
      } else {
        this.setState({
          errorMessage: response.description,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: "An unexpected error occurred. Please try again.",
        isLoading: false,
        tableMessage: "An error occurred. Please try again.",
      });
    }
  };

  readUploadedFile = (event, type) => {
    const uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      return;
    }
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml", "image/webp"];

    if (!allowedTypes.includes(uploadedFile.type)) {
      this.setState({
        configFile: null,
        isSubmitEnabled: false,
        [`${type}Error`]: "Invalid file type. Upload a PNG, JPG, SVG, or WEBP image.",
      });
      return;
    }

    this.setState(
      {
        configFile: { file: uploadedFile, url: URL.createObjectURL(uploadedFile) },
        [`${type}File`]: { file: uploadedFile, url: URL.createObjectURL(uploadedFile) },
        [`${type}Disable`]: false,
        [`${type}Error`]: "",
        isSubmitEnabled: true,
        isSaveEnabled: false,
      },
      () => {
      }
    );
  };

  async handleIconUpload() {
    const { configFile, selectedProvider } = this.state;

    if (!configFile.file) {
      this.setState({ errorMessage: "Please select a file." });
      return;
    }
    this.setState({ isLoading: true, configDisable: true, stockDisable: true });

    try {
      const responseObj = await platformManagement.createStaticProviderUpload(
        selectedProvider.id,
        configFile.file
      );
      const { status, response } = responseObj;

      if (status === "SUCCESS") {
        this.setState({
          successMessage: response.description,
          errorMessage: "",
          isLoading: false,
          configDisable: false,
          stockDisable: false,
          isSaveEnabled: true,
        });
        setTimeout(() => {
          this.setState({ successMessage: "" });
        }, 3000);
      } else {
        this.setState({
          errorMessage: response.description || "Icon upload failed.",
          successMessage: "",
          isLoading: false,
          configDisable: false,
          stockDisable: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      }
    } catch (error) {
      this.setState({
        errorMessage: "An unexpected error occurred. Please try again.",
        isLoading: false,
        configDisable: false,
        stockDisable: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000);
    }
  }

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      selectedProvider: { ...prevState.selectedProvider, [field]: value },
      isSaveEnabled: true,
    }));
  };

  render() {
    const agentData = this.props.data && this.props.data;
    const { selectedProvider, isSaveEnabled, iconUploaded, isSubmitEnabled } = this.state;

    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };

    const columns = [
      {
        Header: "S/N",
        accessor: "index",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 40,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Bank Name",
        accessor: "bankName",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
        width: 150,
      },
      {
        Header: "ProviderCode",
        accessor: "providerCode",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.value}</div>
        ),
        width: 150,
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        width: 200,
      },
      {
        Header: "Date Modified",
        accessor: "dateModified",
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.value ? formatDate(props.value) : null}
          </div>
        ),
        resizable: false,
        width: 150,
      },
      {
        Header: "Toggle",
        accessor: "enabled",
        resizable: false,
        headerStyle: { whiteSpace: "unset", textAlign: "center" },
        Cell: (props) => {
          const isEnabled = props.original.enabled;
          const rowData = props.original;
          const rowId = props.original.id;

          const handleToggleClick = () => {
            this.toggleStaticAccountProvider(rowId, isEnabled);
          };

          return (
            <div style={{ textAlign: "center" }}>
              <div
                onClick={handleToggleClick}
                style={{
                  width: "30px",
                  height: "15px",
                  backgroundColor: isEnabled ? "green" : "red",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isEnabled ? "flex-end" : "flex-start",
                  padding: "2px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </div>
          );
        },
        width: 150,
      },
      {
        Header: "CTA",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { whiteSpace: "unset" },
        Cell: ({ original }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <a
                href="#"
                className="btn btn-hover-brand btn-icon btn-pill"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleModalOpen(original);
                }}
              >
                <i className="la la-ellipsis-v"></i>
              </a>
            </div>
          );
        },
        width: 100,
      }
    ];

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <TableExtra
          data={AgentDetailsExportSerializer.reverseAgentDetails(agentData)}
          fileName={"static provider"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAgents}
          zoom={false}
          page={false}
          pageSize={this.props.pageSize}
          downloadLink={true}
          hasMultipleDownloadLinks={true}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
            justifyContent: "center",
            width: "100%",
          }}
          data={agentData && agentData}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={
            agentData && agentData.length > 0 ? 10 : 10
          }
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAgents(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
              justifyContent: "center",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              justifyContent: "center",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
        <div>
          <Modal show={this.state.isModalOpen} onHide={this.handleModalClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Account Provider</Modal.Title>
            </Modal.Header>

            <Modal.Body className="pb-3">
              {this.state.errorMessage && (
                <div className="alert alert-danger">{this.state.errorMessage}</div>
              )}

              {this.state.successMessage && (
                <div className="alert alert-success">{this.state.successMessage}</div>
              )}
              <div className="mb-3 flex items-center">
                <label className="text-sm font-bold min-w-[150px]">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedProvider.bankName || ""}
                  onChange={(e) => this.handleChange("bankName", e.target.value)}
                />
              </div>

              <div className="mb-3 flex items-center">
                <label className="text-sm font-bold min-w-[150px]">
                  Provider Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedProvider.providerCode || ""}
                  onChange={(e) => this.handleChange("providerCode", e.target.value)}
                />
              </div>

              <div className="mb-3 flex items-center">
                <label className="text-sm font-bold min-w-[150px]">Icon</label>
                <FileUpload
                  buttonText="Upload"
                  readUploadedFile={this.readUploadedFile}
                  handleFileUpload={this.handleIconUpload}
                  type="config"
                  isSubmitEnabled={this.state.isSubmitEnabled}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button
                disabled={!isSaveEnabled}
                type="button"
                className="btn isw-button"
                style={{ width: "80px" }}
                onClick={() => this.editStaticAccountProvider(selectedProvider)}
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
