import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;

export default class remapPosAll {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getAllRemapRequests(
    pageNum = null,
    pageSize = null,
    transactionTypeInt = null,
    statusCodeInt = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-remap-request`,
      args: {
        pageNum,
        pageSize,
        transactionTypeInt,
        statusCodeInt,
      },
    });
  }

  getAllRemapRequestsBySearch(
    pageNumber = null,
    pageSize = null,
    agentUsername = null,
    requestId = null,
    toDate = null,
    fromDate = null,
    approvalStatus = null
  ) {
    return this.apiRequester.get({
      endpoint: `pos-remap-request/admin/search`,
      args: {
        agentUsername,
        requestId,
        toDate,
        fromDate,
        pageNumber,
        pageSize,
        approvalStatus,
      },
    });
  }

  getRemapRequestsById(id) {
    return this.apiRequester.get({
      endpoint: `pos-remap-request/${id}`,
    });
  }
  cancelRemapRequestsByRequestid(posRemapRequestId, cancelReason) {
    return this.apiRequester.delete({
      endpoint: `pos-remap-request/${posRemapRequestId}`,
      body: {
        posRemapRequestId: posRemapRequestId,
        reasonForCancellation: cancelReason,
      },
    });
  }
  cancelRemapRequests(posRemapRequestId, cancelReason) {
    return this.apiRequester.put({
      endpoint: `pos-remap-request`,
      body: {
        posRemapRequestId: posRemapRequestId,
        reasonForCancellation: cancelReason,
      },
    });
  }
  initiateRemapPos(payload) {
    return this.apiRequester.post({
      endpoint: `pos-remap-request`,
      body: payload,
    });
  }
}
