import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
    SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
    CAN_VIEW_TRANSACTION,
} from "../../../../../../../constants/permissions.js";
import {
    liquidityService
} from "../../../../../../../mixins/api";
import {
    retrieveOtherInformation, saveOtherInformation,
} from "../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
    getDateByType,
} from "../../../../../../../utils/helper";
import CashoutInjectedTable from "../../components/cashout-inject-table";


export default class CashoutInjectGridScene extends BaseScene {
  sceneName = "cashout-inject";

  transactionDetailsSectionRef = React.createRef(null);
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      cashout: [],
      transactions: [],
      transactionDetails: [],
      searchParameters: {
        startDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
        endDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("endDate")
          : null
      },
      pages: 1,
      pageNumber: 1,
      pageSize: 20,
      checkedTransactions: [],
      tableMessage: "",
    };

    this.getInjectCashout = this.getInjectCashout.bind(this);
    this.selectAllTransactions = this.selectAllTransactions.bind(this);
    this.redirectToCommission = this.redirectToCommission.bind(this);
  }

    componentDidMount() {
    this.getInjectCashout()
  }

  async getInjectCashout() {
    this.setState({isLoading: true});
    
    try {
        const responseObj = await liquidityService.getUploadedCashoutInjectFile();
        
        const { status, response, count } = responseObj;
        let sortedResponse = [];
        
        if (Array.isArray(response)) {
            sortedResponse = response.sort(
                (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
            );
        }
        
        if (status === SUCCESS_STATUS) {
            this.setState({
                cashout: sortedResponse,
                pages: Math.ceil(response.totalCount / this.state.pageSize),
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                dataCount: response.totalCount,
                isLoading: false,
                tableMessage: !count || count === 0 ? "No cashout transaction found" : ""
            });
        } else {
            this.setState({
                cashout: [],
                errorMessage: response,
                isLoading: false,
                transactions: [],
                tableMessage: "No cashout transaction found",
            });
        }
    } catch (error) {
        console.error('Error fetching cashout:', error);
        this.setState({
            cashout: [],
            errorMessage: "An error occurred while fetching cashout transactions",
            isLoading: false,
            transactions: [],
            tableMessage: "No cashout transaction found",
        });
    }
}


 handleBackendResponse (response) {
  if(response.content == null && response.count == 0) {
    return []
  }else{
    return response ? response : []
  } 
}


  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }


  updateSearchParameters (type, value) {
    if(value == 0 || value=='All' || !value)
    {
      this.setState({searchParameters: {...this.state.searchParameters, [type]: ''}})
      console.log("Here")
      return;
    }
    
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  isFormValid() {
      return true;
  }

  redirectToCommission(transaction) {
    const commissionReference = `${transaction.transactionRef}${transaction.domainCode}`;
    saveOtherInformation("commissionReference", commissionReference);
  }

  selectAllTransactions(isChecked) {
    let checkedTransactions = this.state.checkedTransactions;
    const transactions = this.state.transactions.map((value) => {
      value.checked =
        value.statusCode == "PENDING_VALUE_PROVISIONING" ? isChecked : false;
      const transRefExist = this.handleDuplicateTransactions(
        checkedTransactions,
        value.transactionRef
      );
      if (isChecked && value.statusCode == "PENDING_VALUE_PROVISIONING") {
        if (!transRefExist) {
          checkedTransactions.push(value.transactionRef);
        }
      } else {
        checkedTransactions = checkedTransactions.filter(function (item) {
          return item !== value.transactionRef;
        });
      }
      return value;
    });

    this.setState({
      transactions,
      checkedTransactions,
    });
  }

  handleDuplicateTransactions(transactions, transRef) {
    if (transactions.indexOf(transRef) === -1) {
      return false;
    } else {
      return true;
    }
}


  render() {
    const modalBody = (
      <React.Fragment>
        <p>{`You are about to requery the selected cashout transaction \nClick SUBMIT to continue or CANCEL if otherwise`}</p>
        <div class="form-group row">
          <div class="col-lg-5">
          </div>
        </div>
      </React.Fragment>
    );
    
    return (
      <React.Fragment>
        <ContentHead
          title={"Cashout Injected"}
          filterPermissions={[CAN_VIEW_TRANSACTION]}
          totalCount={56}
        />
        <Notification
          ref={this.notificationSectionRef}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
    
            <CashoutInjectedTable
              data={this.state.cashout}
              pages={this.state.pages}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
            />
            <PromptModal
              modalTitle={
                this.state.actionType === "resolve"
                  ? `Transaction Requery`
                  : `Requery Response`
              }
              modalBody={
                this.state.actionType === "resolve" ? (
                  modalBody
                ) : ""
              }
              modalFooterButton1={
                this.state.actionType === "resolve" ? `Cancel` : `Close`
              }
              hideModalHeader={true}
              modalSize={`modal-md`}
              height={this.state.actionType === "resolve" ? "300px" : "690px"}
              modalFooterButton2={
                this.state.actionType === "resolve" ? `Submit` : ""
              }
              deleteModal={this.requerySingleTransaction}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}