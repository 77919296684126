import React, { Fragment, useCallback, useEffect, useState } from "react";
import Notification from "../../../../../../../components/notification";
import {
  ERROR_STATUS,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { CAN_VIEW_TRANSACTION } from "../../../../../../../constants/permissions";
import { walletProxyService } from "../../../../../../../mixins/api";
import ContentHeadLien from "../../components/content-head-lien";
import { default as LienManagementTable } from "../../components/lien-management-table";
import LienSearch from "../../components/lien-search";

export default function LienGrid({ updateScene }) {
  const sceneName = "lien-management";

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [lienData, setLienData] = useState({
    data: [],
    pages: 1,
    recordsCount: 0,
  });

  const [searchParameters, setSearchParameters] = useState({
    accountCode: "",
    lienStatus: "",
  });

  const handleSearchRequest = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);

    const {
      response,
      status,
    } = await walletProxyService.fetchLienBySearchParams(
      pageNumber,
      pageSize,
      searchParameters
    );

    if (status === ERROR_STATUS) {
      setIsLoading(false);
      setLienData({});
      setErrorMessage(response.remark ? response.remark : response);
      return;
    }
    setLienData({
      data: response.responseMessage,
      pages: Math.ceil(response.totalRecord / pageSize),
      recordsCount: response.totalRecord,
    });

    setIsLoading(false);
  }, [pageNumber, pageSize, searchParameters]);

  const fetchLienData = useCallback(
    async (pageNumber, pageSize) => {
      setIsLoading(true);
      setErrorMessage(null);

      setPageNumber(pageNumber);
      setPageSize(pageSize);

      const {
        response,
        status,
      } = await walletProxyService.fetchLienBySearchParams(
        pageNumber,
        pageSize,
        searchParameters
      );

      if (status === ERROR_STATUS) {
        setIsLoading(false);
        setErrorMessage(response);
        return;
      }

      setLienData({
        data: response.responseMessage,
        pages: Math.ceil(response.totalRecord / pageSize),
        recordsCount: response.totalRecord,
      });
      setIsLoading(false);
    },
    [searchParameters]
  );

  const fetchLienDataNoCache = async () => {
    setErrorMessage(null);
    const {
      response,
      status,
    } = await walletProxyService.fetchLienBySearchParams(
      pageNumber,
      pageSize,
      searchParameters
    );

    if (status === ERROR_STATUS) {
      setIsLoading(false);
      setErrorMessage(response);
      return;
    }

    setLienData({
      data: response.responseMessage,
      pages: Math.ceil(response.totalRecord / pageSize),
      recordsCount: response.totalRecord,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    updateScene(sceneName);
  }, [updateScene]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
      setSuccessMessage(null);
    }, 5000);
  }, [errorMessage, successMessage]);

  const updateSearchParameters = (type, value) => {
    setSearchParameters({ ...searchParameters, [type]: value });
  };

  const requeryLien = async (requestID) => {
    setIsLoading(true);
    try {
      const responseObj = await walletProxyService.requeryLienSingleTransaction(
        requestID
      );
      if (responseObj.status === SUCCESS_STATUS) {
        const res = JSON.parse(responseObj.response.responseMessage[0]);
        fetchLienDataNoCache();
        setSuccessMessage(res.remark);
      } else {
        setIsLoading(false);
        setErrorMessage(responseObj.response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function requeryBulkLien() {
    setIsLoading(true);
    try {
      const responseObj = await walletProxyService.requeryLienBulkTransaction();
      if (responseObj.status === SUCCESS_STATUS) {
        fetchLienDataNoCache();
        setSuccessMessage(responseObj.response.responseMessage);
      } else {
        setIsLoading(false);
        setErrorMessage(responseObj.response.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      {/* TODO: PERMISSION */}
      <ContentHeadLien
        title={"Lien Management"}
        filterPermissions={[CAN_VIEW_TRANSACTION]}
        addActionButtonUrl={"/home/lien-management/add"}
        debitActionButtonUrl={"/home/lien-management/debit"}
        cancelActionButtonUrl={"/home/lien-management/cancel"}
        actionButtonRequiredPermissions={[CAN_VIEW_TRANSACTION]}
      />
      <Notification
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
      <LienSearch
        searchParameters={searchParameters}
        updateSearchParameters={updateSearchParameters}
        handleSearchRequest={handleSearchRequest}
        // requeryLienAll={requeryLienAll}
        requeryBulkLien={requeryBulkLien}
      />
      <LienManagementTable
        canLoadData={true}
        data={lienData && lienData.data}
        dataCount={lienData && lienData.recordsCount}
        fetchData={fetchLienData}
        requeryLien={requeryLien}
        // requeryBulkLien={requeryBulkLien}
        loading={isLoading}
        page={pageNumber}
        pages={lienData.pages}
        pageSize={pageSize}
        searchParameters={{}}
      />
    </Fragment>
  );
}
