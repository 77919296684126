import React from 'react'

export default class PermissionTreeView extends React.Component {
    render() {
        return <React.Fragment>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <ul id="tree1">
                            <li><a href="#">DASHBOARD</a>
                                <ul>
                                    <li>View_Dashboard</li>
                                </ul>
                            </li>
                            <li><a href="#">USER MANAGEMENT</a>
                                <ul>
                                    <li>View User Magt</li>
                                    <li>Users
                                        <ul>
                                            <li>View User</li>
                                            <li>Create User</li>
                                            <li>Update User</li>
                                            <li>Activate User</li>
                                            <li>Deactivate User</li>
                                            <li>Delete User</li>
                                        </ul>
                                    </li>
                                    <li>Roles and Permissions
                                        <ul>
                                            <li>Create Role</li>
                                            <li>Update Role</li>
                                            <li>Activate Role</li>
                                            <li>Deactivate Role</li>
                                            <li>Delete Role</li>
                                        </ul>
                                    </li>
                                    <li>Permission Group
                                        <ul>
                                            <li>View Permission Group</li>
                                        </ul>
                                    </li>
                                    <li>Permission
                                        <ul>
                                            <li>View Permission</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">AGENT MANAGEMENT</a>
                                <ul>
                                    <li>View Agent Magt</li>
                                    <li>Domain Types
                                        <li>View Agent Hierarchy</li>
                                        <ul>
                                            <li>Domain Type Permissions
                                                <ul>
                                                    <li>View Agent Hierarchy Permission</li>
                                                    <li>Update Agent Hierarchy Permission</li>
                                                </ul>
                                            </li>
                                            <li>Domain Type Roles
                                                <ul>
                                                    <li>View Agent Hierarchy Role</li>
                                                    <li>Create Agent Hierarchy Role</li>
                                                    <li>Update Agent Hierarchy Role</li>
                                                    <li>Activate Agent Hierarchy Role</li>
                                                    <li>Deactivate Agent Hierarchy Role</li>
                                                    <li>Delete Agent Hierarchy Role</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Agent Classes
                                        <ul>
                                            <li>View Agent Class</li>
                                            <li>Create Agent Class</li>
                                            <li>Update Agent Class</li>
                                            <li>Activate Agent Class</li>
                                            <li>Deactivate Agent Class</li>
                                            <li>Delete Agent Class</li>
                                        </ul>
                                    </li>
                                    <li>Application Management
                                        <ul>
                                            <li>View Application</li>
                                            <li>Create Application</li>
                                            <li>Update Application</li>
                                            <li>Cancel Application</li>
                                        </ul>
                                    </li>
                                    <li>Service Provider
                                        <ul>
                                            <li>View Service Provider</li>
                                            <li>Create Service Provider</li>
                                            <li>Update Service Provider</li>
                                            <li>Activate Service Provider</li>
                                            <li>Deactivate Service Provider</li>
                                            <li>Retire Service Provider</li>
                                            <li>Manage SP Domain</li>
                                            <li>View SP User</li>
                                            <li>Create SP User</li>
                                            <li>Update SP User</li>
                                            <li>Activate SP User</li>
                                            <li>Deactivate SP User</li>
                                            <li>Retire SP User</li>
                                            <li>View SP Role</li>
                                            <li>Crate SP Role</li>
                                            <li>Update SP Role</li>
                                        </ul>
                                    </li>
                                    <li>Aggregator
                                        <ul>
                                            <li>View Aggregator</li>
                                            <li>Create Aggregator</li>
                                            <li>Update Aggregator</li>
                                            <li>Activate Aggregator</li>
                                            <li>Deactivate Aggregator</li>
                                            <li>Retire Aggregator</li>
                                            <li>Manage Aggregator Domain</li>
                                            <li>View Aggregator User</li>
                                            <li>Create Aggregator User</li>
                                            <li>Update Aggregator User</li>
                                            <li>Activate Aggregator User</li>
                                            <li>Deactivate Aggregator User</li>
                                            <li>Retire Aggregator User</li>
                                            <li>View Aggregator Role</li>
                                            <li>Crate Aggregator Role</li>
                                            <li>Update Aggregator Role</li>
                                        </ul>
                                    </li>
                                    <li>Agent
                                        <ul>
                                            <li>View Agent</li>
                                            <li>Create Agent</li>
                                            <li>Update Agent</li>
                                            <li>Activate Agent</li>
                                            <li>Deactivate Agent</li>
                                            <li>Retire Agent</li>
                                            <li>Manage Agent Domain</li>
                                            <li>View Agent User</li>
                                            <li>Create Agent User</li>
                                            <li>Update Agent User</li>
                                            <li>Activate Agent User</li>
                                            <li>Deactivate Agent User</li>
                                            <li>Retire Agent User</li>
                                            <li>View Agent Role</li>
                                            <li>Crate Agent Role</li>
                                            <li>Update Agent Role</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            {/* <li><a href="#">Liquidity MANAGEMENT</a>
                                <ul>
                                    <li>Distribute Fund</li>
                                    <li>Agent Liquidity</li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICE MANAGEMENT</a>
                                <ul>
                                    <li>Service Type</li>
                                    <li>Services</li>
                                    <li>Service Options</li>
                                    <li>Fee Management</li>
                                    <li>Fee Rule COnfiguration</li>
                                    <li>Revenue Sharing Configuration</li>
                                </ul>
                            </li>
                            <li><a href="#">APPROVAL CONFIGURATION</a>
                                <ul>
                                    <li>Approval configuration</li>
                                    <li>Approvals</li>
                                </ul>
                            </li>
                            <li><a href="#">System Configuration</a>
                                <ul>
                                    <li>Currency</li>
                                    <li>Languages</li>
                                    <li>Country</li>
                                    <li>State</li>
                                    <li>LGA</li>
                                    <li>Region</li>
                                </ul>
                            </li>
                            <li><a href="#">REPORT</a>
                                <ul>
                                    <li>Transaction Report</li>
                                    <li>Settlement Report</li>
                                    <li>Audit Trail Report</li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div class="container">
                <div class="row">
                    <div class="col-md-4 col-md-offset-4">
                        <ul id="treeview">
                            <li data-icon-cls="fa fa-tachometer" data-expanded="true">DASHBOARD
                                <ul>
                                    <li>VIEW_DASHBOARD</li>
                                </ul>
                            </li>
                            <li data-icon-cls="fa fa-user">USER MANAGEMENT
                                <ul id="treeview">
                                    <li data-icon-cls="fa fa-user">USER MANAGEMENT</li>
                                    <li data-icon-cls="fa fa-user">USER MANAGEMENT</li>
                                    <li data-icon-cls="fa fa-user">USER MANAGEMENT</li>
                                </ul>
                            </li>
                            <li data-icon-cls="fa fa-user">Contacts
                                <ul>
                                    <li>Alexander Stein</li>
                                    <li>John Doe</li>
                                    <li>Paul Smith</li>
                                    <li>Steward Lynn</li>
                                </ul>
                            </li>
                            <li data-icon-cls="fa fa-folder">Folders
                                <ul>
                                    <li>Backup</li>
                                    <li>Deleted</li>
                                    <li>Projects</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    }
}