import React from "react";

export default class FileUpload extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-lg-10">
            <input
              type="file"
              disabled={this.props.disabled}
              className="form-control"
              autoComplete="off"
              onChange={(event) =>
                this.props.readUploadedFile(event, this.props.type)
              }
            />
            {this.props.fileErrorMessage && (
              <span className="error">{this.props.fileErrorMessage}</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              disabled={
                !Object.keys(this.props.file).length || this.props.disabled
              }
              type="button"
              className="btn isw-button"
              onClick={this.props.handleFileUpload}
            >
              Submit
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
