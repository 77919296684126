import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridItem } from "../../../../../../../components/layouts/grid-item";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { CAN_CREATE_COMMISSION_SPLIT_RULE } from "../../../../../../../constants/permissions.js";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import { toDecimal } from "../../../../../../../utils/helper";
import CommissionSplitRuleForm from "../../components/commission-split-rule-form";

export default class AddCommissionSplitRuleScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      canSave: null,
      sharingParties: [],
      unSelectedSharingParties: [],
      commissionSplitRuleFormData: {
        revenueSplits: [],
        serviceType: "0",
        serviceOption: "0",
        service: "0",
      },
      totalQuota: 100.0,
      serviceTypes: [{ id: 0, name: "Any" }],
      services: [{ id: 0, name: "Any" }],
      serviceOptions: [{ id: 0, name: "Any" }],
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.addRevenueSplit = this.addRevenueSplit.bind(this);
    this.updateCommissionSplitRuleFormData = this.updateCommissionSplitRuleFormData.bind(
      this
    );
    this.updateRevenueSplitFormData = this.updateRevenueSplitFormData.bind(
      this
    );
    this.getServiceTypeById = this.getServiceTypeById.bind(this);
    this.getServiceById = this.getServiceById.bind(this);
    this.validateSharingQuota = this.validateSharingQuota.bind(this);
    this.validateSharingQuotas = this.validateSharingQuotas.bind(this);
    this.getSharingPartyName = this.getSharingPartyName.bind(this);
    this.removeRevenueSplit = this.removeRevenueSplit.bind(this);
  }

  componentDidMount() {
    this.getRevenueParties();
    this.getServiceTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getRevenueParties() {
    this.setState({ isPartiesLoading: true });
    const responseObj = await feeConfigurationManagement.getRevenueSharingParties();
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreRevenueParties(count);
      this.setState({
        sharingParties: response.data,
        unSelectedSharingParties: response.data,
        isPartiesLoading: false,
      });
    } else {
      this.setState({
        sharingParties: [],
        unSelectedSharingParties: [],
        isPartiesLoading: false,
      });
    }
  }

  async getMoreRevenueParties(pageSize) {
    this.setState({ isPartiesLoading: true });
    const responseObj = await feeConfigurationManagement.getRevenueSharingParties(
      null,
      pageSize
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        sharingParties: response.data,
        unSelectedSharingParties: response.data,
        isPartiesLoading: false,
      });
    } else {
      this.setState({
        sharingParties: [],
        unSelectedSharingParties: [],
        isPartiesLoading: false,
      });
    }
  }

  async getServiceTypes() {
    this.setState({ isGetServiceTypeLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceTypes();
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreServiceTypes(count);
    } else {
      this.setState({
        serviceTypes: [],
        isGetServiceTypeLoading: false,
      });
    }
  }

  async getMoreServiceTypes(pageSize) {
    this.setState({ isGetServiceTypeLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceTypes(
      null,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceTypes: response.data
          ? this.updateBackendResponseWithAny(response.data)
          : [],
        serviceTypesCount: count,
        isGetServiceTypeLoading: false,
      });
    } else {
      this.setState({
        serviceTypes: [],
        serviceTypesCount: 0,
        isLoading: false,
      });
    }
  }

  async getServiceTypeById(serviceTypeId, withServices = true) {
    this.setState({ isServiceLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceType(
      serviceTypeId,
      withServices
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        services: response.services
          ? this.updateBackendResponseWithAny(response.services)
          : [],
        isServiceLoading: false,
      });
    } else {
      this.setState({
        services: [],
        isServiceLoading: false,
      });
    }
  }

  async getServiceById(serviceId, withOptions = true) {
    this.setState({ isServiceOptionLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceById(
      serviceId,
      withOptions
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceOptions: response.serviceOptions
          ? this.updateBackendResponseWithAny(response.serviceOptions)
          : [],
        isServiceOptionLoading: false,
      });
    } else {
      this.setState({
        serviceOptions: [],
        isServiceOptionLoading: false,
      });
    }
  }

  getSharingPartyName(partyId) {
    const sharingParty = this.state.sharingParties.filter(
      (value) => value.id === partyId
    );
    return sharingParty[0].name;
  }

  async addRevenueSplit(e) {
    e.preventDefault();
    const revenueSplits = {
      id: Date.now(),
      sharingPartyId: this.state.sharingPartyId,
      quota: parseFloat(this.state.quota),
    };

    const unSelectedSharingParties = this.state.unSelectedSharingParties.filter(
      function (value) {
        if (value.id === revenueSplits.sharingPartyId) {
          value.selected = 1;
        } else {
          value.selected = 0;
        }
        return value;
      }
    );

    const sharingParties = this.state.sharingParties.filter(function (value) {
      if (value.id === revenueSplits.sharingPartyId) {
        value.selected = 1;
      }
      return value;
    });

    this.setState({
      commissionSplitRuleFormData: {
        ...this.state.commissionSplitRuleFormData,
        revenueSplits: [
          ...this.state.commissionSplitRuleFormData.revenueSplits,
          revenueSplits,
        ],
      },
      totalQuota: toDecimal(
        parseFloat(this.state.totalQuota) - parseFloat(this.state.quota),
        4
      ),
      isQuotaValid: Boolean(
        parseFloat(this.state.totalQuota) - parseFloat(this.state.quota) >=
          parseFloat(this.state.quota)
      ),
      unSelectedSharingParties: this.agentsFiltering(unSelectedSharingParties),
      sharingPartyId: null,
      sharingParties,
    });
  }

  async removeRevenueSplit(id) {
    const revenueSplits = this.state.commissionSplitRuleFormData.revenueSplits.filter(
      function (value) {
        return value.id !== id;
      }
    );

    const removedRevenueSplit = this.state.commissionSplitRuleFormData.revenueSplits.filter(
      function (value) {
        return value.id === id;
      }
    );

    const sharingParties = this.state.sharingParties.filter(function (value) {
      if (value.id === removedRevenueSplit[0].sharingPartyId) {
        value.selected = 0;
      }
      return value;
    });

    this.setState({
      commissionSplitRuleFormData: {
        ...this.state.commissionSplitRuleFormData,
        revenueSplits: revenueSplits,
      },
      totalQuota: toDecimal(
        parseFloat(this.state.totalQuota) +
          parseFloat(removedRevenueSplit[0].quota),
        4
      ),
      isQuotaValid: Boolean(
        parseFloat(this.state.totalQuota) +
          parseFloat(removedRevenueSplit[0].quota) >=
          parseFloat(this.state.quota)
      ),
      unSelectedSharingParties: this.agentsFiltering(sharingParties),
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.createCommissionSplitRule(
      this.state.commissionSplitRuleFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your request to add a new commission split rule has been sent successfully for approval.",
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Commission split rule created successfully.",
          redirect: true,
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  validateSharingQuotas() {
    let totalQuota = 0.0;
    this.state.commissionSplitRuleFormData.revenueSplits.map((value) => {
      totalQuota = parseFloat(value.quota) + totalQuota;
    });
    return Boolean(totalQuota >= 100.0);
  }

  validateSharingQuota(quota) {
    return Boolean(
      parseFloat(this.state.totalQuota) >= parseFloat(quota) &&
        parseFloat(quota) > 0
    );
  }

  validateMaxField(minValue, maxValue) {
    return Boolean(parseInt(maxValue) > parseInt(minValue));
  }

  checkMinField(minValue) {
    return Boolean(minValue);
  }

  isRevenueSplitsValid() {
    return this.validateSharingQuotas();
  }

  isformValid() {
    //return !this.state.feeRuleFormData.serviceType
    return (
      !this.isRevenueSplitsValid() ||
      !this.state.commissionSplitRuleFormData.serviceType ||
      !this.state.commissionSplitRuleFormData.service ||
      !this.state.commissionSplitRuleFormData.serviceOption
    );
  }

  updateCommissionSplitRuleFormData(type, value) {
    this.setState({
      commissionSplitRuleFormData: {
        ...this.state.commissionSplitRuleFormData,
        [type]: value,
      },
    });
  }

  updateRevenueSplitFormData(type, value) {
    this.setState({ [type]: value });
  }

  updateBackendResponseWithAny(response) {
    let anyObj = { id: 0, name: "Any" };
    let obj = [];
    obj.push(anyObj);
    response.map((detail) => obj.push(detail));
    return obj;
  }

  agentsFiltering(sharingParties) {
    return sharingParties.filter((details) => details.selected != 1);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/commission-split-rules" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent
          requiredPermissions={[CAN_CREATE_COMMISSION_SPLIT_RULE]}
        >
          <button
            type="button"
            disabled={this.isformValid()}
            class="btn btn-primary"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Commission Split Rules"}
          withoutSearch
          subtitle="New Commission Split Rule"
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_CREATE_COMMISSION_SPLIT_RULE]}
        >
          <GridItem isFluid class="kt-content">
            <CommissionSplitRuleForm
              serviceTypes={this.state.serviceTypes}
              services={this.state.services}
              serviceOptions={this.state.serviceOptions}
              commissionSplitRuleFormData={
                this.state.commissionSplitRuleFormData
              }
              sharingParties={this.state.sharingParties}
              updateRevenueSplitFormData={this.updateRevenueSplitFormData}
              validateSharingQuota={this.validateSharingQuota}
              validateSharingQuotas={this.validateSharingQuotas}
              isQuotaValid={this.state.isQuotaValid}
              sharingPartyId={this.state.sharingPartyId}
              quota={this.state.quota}
              totalQuota={this.state.totalQuota}
              addRevenueSplit={this.addRevenueSplit}
              removeRevenueSplit={this.removeRevenueSplit}
              getSharingPartyName={this.getSharingPartyName}
              updateCommissionSplitRuleFormData={
                this.updateCommissionSplitRuleFormData
              }
              getServiceById={this.getServiceById}
              getServiceTypeById={this.getServiceTypeById}
              disabled={false}
              isServiceLoading={this.state.isServiceLoading}
              isServiceOptionLoading={this.state.isServiceOptionLoading}
              isGetServiceTypeLoading={this.state.isGetServiceTypeLoading}
              unSelectedSharingParties={this.state.unSelectedSharingParties}
              isPartiesLoading={this.state.isPartiesLoading}
            />
          </GridItem>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
