import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridItem } from '../../../../../../../components/layouts/grid-item';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { ACCEPTED_REQUEST, SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_UPDATE_COMMISSION_SPLIT_RULE, CAN_VIEW_COMMISSION_SPLIT_RULE } from '../../../../../../../constants/permissions.js';
import { feeConfigurationManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { toDecimal } from '../../../../../../../utils/helper';
import CommissionSplitRuleForm from '../../components/commission-split-rule-form';

export default class ViewCommissionSplitRuleScene extends BaseScene {
  
    notificationSectionRef = React.createRef(null)

    constructor (props) {
        super(props)
        this.state = {
            canSave: null,
            pageTitle: "View Commission Split Rule",
            canSave: false,
            canUpdate: false,
            disabled: true,
            sharingParties: [],
            unSelectedSharingParties: [],
            commissionSplitRuleFormData: {
                revenueSplits: [],
            },
            totalQuota: 0.0,
            serviceTypes: [
                {id: 0, name: "Any"}
            ],
            services: [
                {id: 0, name: "Any"}
            ],
            serviceOptions: [
                {id: 0, name: "Any"}
            ]
        }

        this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
        this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
        this.addRevenueSplit = this.addRevenueSplit.bind(this)
        this.updateCommissionSplitRuleFormData = this.updateCommissionSplitRuleFormData.bind(this)
        this.updateRevenueSplitFormData = this.updateRevenueSplitFormData.bind(this)
        this.getServiceTypeById = this.getServiceTypeById.bind(this)
        this.getServiceById = this.getServiceById.bind(this)
        this.validateSharingQuota = this.validateSharingQuota.bind(this)
        this.validateSharingQuotas = this.validateSharingQuotas.bind(this)
        this.getSharingPartyName = this.getSharingPartyName.bind(this)
        this.removeRevenueSplit =this.removeRevenueSplit.bind(this)
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
    }

    componentDidMount() {
        this.commissionSplitRuleId = this.props.match.params.id
        this.getRevenueSharingParties()
        this.getCommissionSplitRule()
        this.getServiceTypes()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async getRevenueSharingParties () {
        const responseObj = await feeConfigurationManagement.getRevenueSharingParties()
        const { status, response, count } = responseObj
        if (status === SUCCESS_STATUS) {
            this.getMoreRevenueParties(count);
            this.setState({
                sharingParties: response.data,
                unSelectedSharingParties: response.data
            })
        }else{
            this.setState({
                sharingParties: [],
                unSelectedSharingParties: []
            })
        }
    }

    async getMoreRevenueParties (pageSize) {
        this.setState({isLoading: true})
        const responseObj = await feeConfigurationManagement.getRevenueSharingParties(null, pageSize)
        const { status, response, count } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                sharingParties: response.data,
                unSelectedSharingParties: response.data,
                isLoading: false
            })
        }else{
            this.setState({
                sharingParties: [],
                unSelectedSharingParties: [],
                isLoading: false
            })
        }
    }

    async getCommissionSplitRule () {
        this.setState({isLoading: true})
        const responseObj = await feeConfigurationManagement.getCommissionSplitRule(this.commissionSplitRuleId)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.getServiceTypeById(response.serviceType)
            this.getServiceById(response.service)
            this.setState({
                commissionSplitRuleFormData: response,
                canUpdate: true,
                isLoading: false
            })
        }else{
            this.setState({
                commissionSplitRuleFormData: [],
                isLoading: false,
                errorMessage: response
            })
        }
    }
    async getServiceTypes () {
        const responseObj = await feeConfigurationManagement.getServiceTypes()
        const { status, response, count } = responseObj
        if (status === SUCCESS_STATUS) {
            this.getMoreServiceTypes(count)
        }else{
            this.setState({
                serviceTypes: this.state.serviceTypes
            })
        }
    }
    
    async getMoreServiceTypes (pageSize) {
        const responseObj = await feeConfigurationManagement.getServiceTypes(null, pageSize)
        const { status, response, count } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                serviceTypes: response.data ? this.updateBackendResponseWithAny(response.data) : this.state.serviceTypes,
                serviceTypesCount: count,
            })
        }else{
            this.setState({
                serviceTypes: this.state.serviceTypes,
                serviceTypesCount: 0
            })
        }
    }

    async onUpdateButtonClick () {
        let ids = null;
        let sharingParties = this.state.sharingParties;
        let totalQuota = 100.0;
        if(this.state.commissionSplitRuleFormData.revenueSplits){
            ids = this.getSelectedSharingParties();
            sharingParties = this.state.sharingParties.filter(value => {
                if(ids.includes(value.id)) {
                    value.selected = 0
                    //return value
                }
                return value
            })
            this.state.commissionSplitRuleFormData.revenueSplits.map(value => {
                totalQuota = totalQuota - parseFloat(value.quota)
            })
        }
        this.setState({
            sharingParties,
            disabled: false,
            canSave: true,
            canUpdate: false,
            pageTitle: 'Update Commission Split Rule',
            totalQuota,
        })
    }

    async onCancelButtonClick () {
        this.setState({
            disabled: true,
            canSave: false,
            canUpdate: true,
            pageTitle: 'View Commission Split Rule',
        })
    }

    getSharingPartyName(partyId) {
        const sharingParty = this.state.sharingParties.filter(value => value.id == partyId)
        return sharingParty[0] ? sharingParty[0].name : 'Agent';
    }

    getSelectedSharingParties () {
        //emails = data.map(d => d.email);
        const sharingPartiesIds = this.state.commissionSplitRuleFormData.revenueSplits.map(value => value.sharingPartyId)
        return sharingPartiesIds
    }  

    async addRevenueSplit (e) {
        e.preventDefault()
        const revenueSplit = {
            "id": Date.now(),
            "sharingPartyId": this.state.sharingPartyId,
            "quota": parseFloat(this.state.quota)
        }

        const unSelectedSharingParties = (this.state.unSelectedSharingParties).filter(function(value) { 
            if(value.id == revenueSplit.sharingPartyId) {
              value.selected = 1
            }else{
                value.selected = 0
            }  
            return value
        });

        const sharingParties = (this.state.sharingParties).filter(function(value) { 
            if(value.id == revenueSplit.sharingPartyId) {
              value.selected = 1
            }  
            return value
        });

        this.setState({
            commissionSplitRuleFormData: {...this.state.commissionSplitRuleFormData,
                revenueSplits: [
                    ...this.state.commissionSplitRuleFormData.revenueSplits ? this.state.commissionSplitRuleFormData.revenueSplits : '',
                    revenueSplit,
                ],
            },
            sharingParties,
            sharingPartyId: null,
            totalQuota: toDecimal(parseFloat(this.state.totalQuota) - parseFloat(this.state.quota), 4),
            isQuotaValid: Boolean((parseFloat(this.state.totalQuota)-parseFloat(this.state.quota)) >= parseFloat(this.state.quota)),
            unSelectedSharingParties: this.agentsFiltering(unSelectedSharingParties),
        })
    }

    async removeRevenueSplit(id) {
        const revenueSplits = (this.state.commissionSplitRuleFormData.revenueSplits).filter(function(value) { 
            return value.id != id;  
        });

        const removedRevenueSplit = (this.state.commissionSplitRuleFormData.revenueSplits).filter(function(value) { 
          return value.id == id;  
        });

        const sharingParties = (this.state.sharingParties).filter(function(value) { 
            if(value.id == removedRevenueSplit[0].sharingPartyId) {
              value.selected = 0
            }  
            return value
        });
    
        this.setState({
            commissionSplitRuleFormData: {...this.state.commissionSplitRuleFormData, revenueSplits},
            isQuotaValid: Boolean((parseFloat(this.state.totalQuota) + parseFloat(removedRevenueSplit[0].quota)) >= parseFloat(this.state.quota)),
            totalQuota: toDecimal(parseFloat(this.state.totalQuota) + parseFloat(removedRevenueSplit[0].quota), 4),
            unSelectedSharingParties: this.agentsFiltering(sharingParties)
        })
    }

    async onSaveButtonClick () {
       this.setState({isLoading: true})
        const responseObj = await feeConfigurationManagement.updateCommissionSplitRule(this.commissionSplitRuleId, this.state.commissionSplitRuleFormData)
        const { status, response } = responseObj
        window.scrollTo(0, this.notificationSectionRef.offsetTop)

        if (status === SUCCESS_STATUS) {
            this.getCommissionSplitRule()
            if(response.code == ACCEPTED_REQUEST){
                this.setState({
                    isLoading: false,
                    successMessage: 'Your changes to this commission split rule has been sent successfully for approval.',
                    pageTitle: "View Fee Rule",
                    canSave: false,
                    canUpdate: true,
                    disabled: true,
                })
                return
            }else{
                this.setState({
                    isLoading: false,
                    pageTitle: "View Fee Rule",
                    canSave: false,
                    canUpdate: true,
                    disabled: true,
                    successMessage: 'Commission split rule updated successfully.',
                })
            }
        }else{
            this.setState({
                isLoading: false,
                errorMessage: response,
            })
        }
        return       
    }

    validateSharingQuotas () {
        let totalQuota = 0.0;
        this.state.commissionSplitRuleFormData.revenueSplits && this.state.commissionSplitRuleFormData.revenueSplits.map(value => {
          totalQuota = parseFloat(value.quota) + totalQuota
        })
        return Boolean (totalQuota >= 100)
    }
    
    validateSharingQuota (quota) {
        return Boolean (parseFloat(this.state.totalQuota) >= parseFloat(quota) && parseFloat(quota) >= 0)
    }

    validateMaxField (minValue, maxValue) {
        return Boolean (parseInt(maxValue) > parseInt(minValue))
    }
    
    checkMinField (minValue) {
        return Boolean (minValue)
    }

    isRevenueSplitsValid () {
        return this.validateSharingQuotas()
    }

    isFormValid () {
        return !this.isRevenueSplitsValid() || !this.state.commissionSplitRuleFormData;
        //return !this.state.feeRuleFormData.serviceType || !this.state.feeRuleFormData.service || !this.state.feeRuleFormData.serviceOption || !this.state.feeRuleFormData.agentCategory || !this.state.feeRuleFormData.feeToApply || !this.state.feeRuleFormData.sharingPartySource
    }

    async getServiceTypeById (serviceTypeId, withServices = true) {
        this.setState({isServiceLoading: true})
        const responseObj = await feeConfigurationManagement.getServiceType(serviceTypeId, withServices) 
        const { status, response } = responseObj
        if(status === SUCCESS_STATUS) {
            this.setState({
                services: response.services ? this.updateBackendResponseWithAny(response.services) : this.state.services,
                isServiceLoading: false
            })
        }else{
            this.setState({
                services: this.state.services,
                isServiceLoading: false
            })
        }
    }

    async getServiceById (serviceId, withOptions = true) {
        this.setState({isServiceOptionLoading: true})
        const responseObj = await feeConfigurationManagement.getServiceById(serviceId, withOptions)
        const { status, response } = responseObj
        if(status === SUCCESS_STATUS) {
            this.setState({
                serviceOptions: response.serviceOptions ? this.updateBackendResponseWithAny(response.serviceOptions) : this.state.serviceOptions,
                isServiceOptionLoading: false
            })
        }else{
            this.setState({
                serviceOptions: this.state.serviceOptions,
                isServiceOptionLoading: false
            })
        }
    }

    updateCommissionSplitRuleFormData (type, value) {
        this.setState({commissionSplitRuleFormData: {...this.state.commissionSplitRuleFormData, [type]: value}})
    }

    updateRevenueSplitFormData (type, value) {
        this.setState({[type]: value})
    }

    updateBackendResponseWithAny (response) {
        let anyObj = {id: 0, name: "Any"}
        let obj = []
        obj.push(anyObj)
        response.map(detail => obj.push(detail))
        return obj
    }

    agentsFiltering (sharingParties) {
        return sharingParties.filter(details => details.selected != 1)
    }
    
    render () {
        const ctaWidgets = <React.Fragment>
            {!this.state.canSave && <button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit</button>}
            {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_COMMISSION_SPLIT_RULE]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
            {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_COMMISSION_SPLIT_RULE]}><button type="button" class="btn btn-primary" disabled={this.isFormValid()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
        </React.Fragment>

        return <React.Fragment>
            <Loader 
                isLoading={this.state.isLoading}
            />
            <Notification 
                ref={this.notificationSectionRef}
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"Fee Rules"} withoutSearch subtitle={this.state.pageTitle} ctaWidgets={ctaWidgets} />
            <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]}>
                <GridItem isFluid class="kt-content">
                    <CommissionSplitRuleForm 
                        serviceTypes={this.state.serviceTypes}
                        services={this.state.services}
                        serviceOptions={this.state.serviceOptions}
                        commissionSplitRuleFormData={this.state.commissionSplitRuleFormData}
                        sharingParties={this.state.sharingParties}
                        updateRevenueSplitFormData={this.updateRevenueSplitFormData}
                        validateSharingQuota={this.validateSharingQuota}
                        validateSharingQuotas={this.validateSharingQuotas}
                        isQuotaValid={this.state.isQuotaValid}
                        sharingPartyId={this.state.sharingPartyId}
                        quota={this.state.quota}
                        totalQuota={this.state.totalQuota}
                        addRevenueSplit={this.addRevenueSplit}
                        removeRevenueSplit={this.removeRevenueSplit}
                        getSharingPartyName={this.getSharingPartyName}
                        updateCommissionSplitRuleFormData={this.updateCommissionSplitRuleFormData}
                        getServiceById={this.getServiceById}
                        getServiceTypeById={this.getServiceTypeById}
                        disabled={this.state.disabled}
                        isServiceLoading={this.state.isServiceLoading}
                        isServiceOptionLoading={this.state.isServiceOptionLoading}
                        unSelectedSharingParties={this.state.unSelectedSharingParties}
                    />
                </GridItem>
            </ProtectedComponent>
        </React.Fragment>
    }
    get permissionDeniedContent() {
        return <AccessDenied />
    }
}
