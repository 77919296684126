import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';

export default class WalletJournalSearch extends React.Component {
    
    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest()
    }

    transactionTypes = [
        {label: "Transaction", value: 1},
        {label: "Commission", value: 2},
        {label:  "Lien" ,     value:3},
        {label:  "credit card" ,    value:4}

    ]

    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class='form-group row'>
                    <div class="col-md-3">
                        <label>Agent ID:</label>
                        <input
                            defaultValue={this.props.searchParameters.agentCode}
                            type="text"
                            class="search-input"
                            onChange={event => this.props.updateSearchParameters('agentCode', event.target.value)}
                        /> 
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Type</label>
                        {/* <Select 
                            options={this.transactionTypes}
                            onChange={(event) => this.props.updateSearchParameters('walletJournalType', event.value)}
                            defaultValue={this.props.searchParameters.walletJournalType}
                        /> */}
                        <select class="search-input" onChange={(event) => this.props.updateSearchParameters('walletJournalType', event.target.value)}>
                            <option value="1">Transaction</option>
                            <option value="2">Commission</option>
                            <option value="3">Lien</option>
                            {/* <option value="4">Credit Card</option> */}
                            

                            

                        </select>
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.props.updateSearchParameters('startDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent 
                            onChange={(event) => this.props.updateSearchParameters('endDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.props.searchParameters.startDate ? false : true}
                            minDate={this.props.searchParameters.startDate}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}