import Requester from '../requester'

const API_BASE_URL = process.env.REACT_APP_AUDIT_SERVICE_API_BASE_URL

export default class AuditService {
    constructor(props) {
        const currentUser = JSON.parse(localStorage.getItem("user"))
        this.currentUserEmail = currentUser ? currentUser.email : null
        this.currentUser = currentUser ? currentUser.access_token : null
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }

    getAuditReport (pageStart = null, pageSize = null, payload) {
        payload.auditAction = payload.auditAction === "" ? null : payload.auditAction
        payload.auditEntity = payload.auditEntity === "" ? null : payload.auditEntity
        return this.apiRequester.get({
            endpoint: `audit/admin`,
            headers: {
                pageStart: pageStart - 1,
                pageSize,
                queryData:(JSON.stringify(payload))
            },
        })
    } 

    // getAuditById (id) {
    //     return this.apiRequester.get({
    //         endpoint: `audit/getById`,
    //         headers: {
    //             id
    //         }
    //     })
    // }

    getAuditById (auditId) {
        return this.apiRequester.get({
            endpoint: `audit/by-audit-id`,
            headers: {
                auditId
            }
        })
    }
}
