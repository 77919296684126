import React from "react";
import { AccessDenied } from "../../../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE,
} from "../../../../../../../../../constants/permissions.js";
import { posRequestsAwaitingConfigUpload } from "../../../../../../../../../mixins/api";
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom,
  formatBackEndDateTimeTo,
} from "../../../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../../../utils/helper";
import DeliveryNoteSearch from "../../components/delivery-note-search";
import DeliveryNoteTable from "../../components/delivery-note-table";

export default class DeliveryNoteGridScene extends BaseScene {
  sceneName = "delivery-note";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      posRequestAwaitingConfigUploadDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        agentMobile: "",
        requestId: "",
      },
      pages: 1,
      pageNum: 1,
      pageSize: 20,
      pageNumber: 1,
      tableMessage: "",
    };

    this.getAllDeliveryNotes = this.getAllDeliveryNotes.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.downloadDeliveryNotes = this.downloadDeliveryNotes.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  componentDidMount() {
    this.getAllDeliveryNotes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content === null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllDeliveryNotes(pageNumber, pageSize) {
    const {
      agentMobile,
      toDate,
      fromDate,
      // requestId,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDate =
      toDate && `${formatBackEndDateTimeTo(toDate, true)}.747`;
    const formattedStartDate =
      fromDate && `${formatBackEndDateTimeFrom(fromDate, true)}.747`;

    this.setState({
      pageNumber,
      pageSize,
      isLoading: true,
    });

    const {
      status,
      response,
      count,
    } = await posRequestsAwaitingConfigUpload.getAllDeliveryNotesBySearch(
      pageNumber,
      pageSize,
      agentMobile,
      formattedEndDate,
      formattedStartDate
      // requestId
    );
    if (status === SUCCESS_STATUS) {
      console.log(
        response,
        " POS REQUESTS AWAITING CONFIG. UPLOAD RETRIEVED SUCCESSFULLY"
      );

      this.setState({
        requests: response.data ? response.data : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        // dataCount: count,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        tableMessage: !count || count === 0 ? "No Delivery Notes found!" : "",
      });
    } else {
      //alert("shdf")
      this.setState({
        requests: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        isLoading: false,
        tableMessage: "No Delivery Notes found!",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async downloadDeliveryNotes() {
    const payload = {
      pageNum: this.state.page,
      pageSize: this.state.dataCount,
      // posRequestsStatus: this.state.searchParameters.approvalStatus,
      fromDate:
        this.state.searchParameters.fromDate &&
        `${formatBackEndDateTimeFrom(
          this.state.searchParameters.fromDate,
          true
        )}.747`,
      toDate:
        this.state.searchParameters.toDate &&
        `${formatBackEndDateTimeTo(
          this.state.searchParameters.toDate,
          true
        )}.747`,
    };
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await posRequestsAwaitingConfigUpload.downloadDeliveryNotes(
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], { type: "contentType" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "delivery_notes.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        successMessage: "Download Succcessfully",
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response.description,
        isLoading: false,
      });
    }
  }

  handleSearchRequest() {
    this.getAllDeliveryNotes(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(stockId, flowId) {
    this.uploadPosRequestsAwaitingConfigUpload(stockId, flowId);
  }

  isFormValid() {
    //return false
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <ContentHead
          title={"View POS Requests Awaiting Config Upload"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        <>
          <Notification
            ref={this.notificationSectionRef}
            errorMessage={this.state.errorMessage}
            successMessage={this.state.successMessage}
          />
          <ProtectedComponent
            requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
            permissionDeniedContent={this.permissionDeniedContent}
          >
            <GridLayout>
              <DeliveryNoteSearch
                searchParameters={this.state.searchParameters}
                updateSearchParameters={this.updateSearchParameters}
                handleSearchRequest={this.handleSearchRequest}
                preloadDate={
                  retrieveOtherInformation("parentReference")
                    ? false
                    : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
                }
                desiredStatus={
                  this.state.desiredStatus
                    ? this.state.desiredStatus
                    : "default"
                }
              />
              <DeliveryNoteTable
                pages={this.state.pages}
                getAllDeliveryNotes={this.getAllDeliveryNotes}
                data={this.state.requests || ""}
                startPage={this.state.startPage}
                loading={this.state.isLoading}
                noDataText={this.state.tableMessage}
                dataCount={this.state.dataCount}
                page={this.state.pageNum}
                pageSize={this.state.pageSize}
                canRemapDevice={CAN_REMAP_DEVICE}
                downloadDeliveryNotes={this.downloadDeliveryNotes}
                searchParameters={this.state.searchParameters}
                responseCode={this.state.searchParameters.responseCode}
                startDate={this.state.searchParameters.startDate}
                canView={CAN_GET_DEVICES_BY_UUID}
                getClickedAction={this.getClickedAction}
                desiredStatus={
                  this.state.desiredStatus
                    ? this.state.desiredStatus
                    : "default"
                }
                canLoadData={this.state.canLoadData}
              />
            </GridLayout>
          </ProtectedComponent>
        </>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
