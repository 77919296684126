import React from "react";
import { AccessDenied } from "../../../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../../components/scenes/component";
import Table from "../../../../../../../../../components/table";
import {
  SUCCESS_STATUS
} from "../../../../../../../../../constants/api";
import { onboarding } from "../../../../../../../../../mixins/api";
import { DeclineReasonCategoryExportSerializer } from "../../../../../../../../../serializers";
import { formatDateTime } from "../../../../../../../../../utils/formatter";

export default class DeclineReasonCategoryGridScene extends BaseScene {
  columns = [
    {
      Header: "S/N",
      accessor: "id",
      Cell: (id) => <span>{id.index + 1}</span>,
      sortable: false,
      resizable: false,
      width: 60,
      headerStyle: { "white-space": "unset" },
    },
    {
      Header: "Name",
      accessor: "categoryName",
      resizable: false,
      headerStyle: { "white-space": "unset" },
      style: { "white-space": "unset" },
      Cell: (props) => (
        <span class="word-capitalize">{props.value.toLowerCase()}</span>
      ),
    },
    {
      Header: "Status",
      accessor: "inUse",
      resizable: false,
      headerStyle: { "white-space": "unset" },
      style: { "white-space": "unset" },
      width: 100,
      Cell: (props) => (
        <span
          style={{ color: "#ffffff" }}
          class={`kt-badge ${
            props.value === true ? "kt-badge--success" : "kt-badge--danger"
          } kt-badge--inline kt-badge--pill`}
        >
          {props.value === true ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      Header: "Date Created",
      accessor: "createdOn",
      headerStyle: { "white-space": "unset" },
      style: { "white-space": "unset" },
      resizable: false,
      width: 180,
      Cell: (props) => {
        return props.value ? formatDateTime(props.value) : "";
      },
    },
    {
      Header: "Last Modified",
      accessor: "lastModifiedOn",
      headerStyle: { "white-space": "unset" },
      style: { "white-space": "unset" },
      resizable: false,
      width: 180,
      Cell: (props) => {
        return props.value ? formatDateTime(props.value) : "";
      },
    },
    {
      Header: "Action",
      accessor: "id",
      overflow: "visible",
      resizable: false,
      sortable: false,
      headerStyle: { "white-space": "unset" },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            lineHeight: "20px",
            textAlign: "center",
            whiteSpace: "unset",
          },
        };
      },
      width: 60,
      Cell: ({ row, original }) => (
        <div class="dropdown">
          <a
            href="#"
            class="btn btn-hover-brand btn-icon btn-pill"
            data-toggle="dropdown"
          >
            <i class="la la-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a
              class="dropdown-item"
              onClick={() => this.onEditButtonClick(original)}
              data-toggle="modal"
              data-target="#kt_modal_1"
              style={{ color: "#A7ABC3", cursor: "pointer" }}
            >
              <i class="la la-eye"></i>
              View
            </a>
            <a
              class="dropdown-item"
              onClick={() => this.getDeclineReasonCategoryDetails(original)}
              data-toggle="modal"
              data-target="#kt_modal_1"
              style={{ color: "#A7ABC3", cursor: "pointer" }}
            >
              <i class="la la-trash"></i>
              Delete
            </a>
          </div>
        </div>
      ),
    },
  ];

  sceneName = "decline-reason-categories";

  state = {
    declineReasonCategories: [],
    declineReasonCategoryFormData: [],
    statuses: [
      { id: true, name: "Yes" },
      { id: false, name: "No" },
    ],
  };

  onSaveButtonClick = this.onSaveButtonClick.bind(this);
  onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
  onAddButtonClick = this.onAddButtonClick.bind(this);
  onEditButtonClick = this.onEditButtonClick.bind(this);
  getDeclineReasonCategories = this.getDeclineReasonCategories.bind(this);
  deleteDeclineReasonCategory = this.deleteDeclineReasonCategory.bind(this);
  getDeclineReasonCategoryDetails = this.getDeclineReasonCategoryDetails.bind(
    this
  );

  componentDidMount() {
    this.getDeclineReasonCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 3000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 3000);
    }
  }

  async getDeclineReasonCategories() {
    this.setState({ isLoading: true });
    const responseObj = await onboarding.getDeclineReasonCategories();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        declineReasonCategories: response,
        isLoading: false,
        dataCount: response.length,
      });
    } else {
      this.setState({
        declineReasonCategories: [],
        isLoading: false,
        dataCount: 0,
        errorMessage: response,
      });
    }
  }

  async getDeclineReasonCategory(id) {
    const responseObj = await onboarding.getDeclineReasonCategory(id);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        declineReasonCategoryFormData: response,
      });
    } else {
      this.setState({
        declineReasonCategoryFormData: [],
      });
    }
  }

  async deleteDeclineReasonCategory() {
    this.setState({ isLoading: true });
    const responseObj = await onboarding.deleteDeclineReasonCategory(
      this.state.deleteDeclineReasonCategoryFormData.id
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        canDelete: false,
        successMessage: `${this.state.deleteDeclineReasonCategoryFormData.categoryName} deleted successfully.`,
        deleteDeclineReasonCategoryFormData: [],
        modalTitle: "",
        deleteModalBody: "",
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getDeclineReasonCategories();
  }

  getDeclineReasonCategoryDetails(declineReasonCategoryDetails) {
    this.setState({
      deleteDeclineReasonCategoryFormData: declineReasonCategoryDetails,
      canDelete: true,
      modalTitle: "Delete Decline Reason Category",
      deleteModalBody: `Do you want to delete ${declineReasonCategoryDetails.categoryName} from decline reason categories?`,
      modalHeight: "250px",
      canUpdate: false,
      canAdd: false,
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });

    const responseObj = await onboarding.addDeclineReasonCategory(
      this.state.declineReasonCategoryFormData
    );

    const status = responseObj.status;
    const response = responseObj.response;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "Decline reason category created successfully",
        redirect: true,
        declineReasonCategoryFormData: [],
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getDeclineReasonCategories();
    setTimeout(() => {
      this.setState({
        declineReasonCategoryFormData: [],
      });
    }, 10);
  }

  async onUpdateButtonClick() {
    this.setState({ isLoading: true });

    const details = {
      categoryName: "",
    };

    const responseObj = await onboarding.updateDeclineReasonCategory(
      this.state.declineReasonCategoryFormData
    );

    const status = responseObj.status;
    const response = responseObj.response;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "Decline reason category updated successfully",
        redirect: true,
        updateButton: true,
        saveButton: false,
        declineReasonCategoryFormData: details,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }

    this.getDeclineReasonCategories();
    setTimeout(() => {
      this.setState({
        declineReasonCategoryFormData: details,
      });
    }, 10);
  }

  onAddButtonClick() {
    const details = {
      categoryName: "",
    };
    this.setState({
      canUpdate: false,
      canDelete: false,
      modalHeight: "290px",
      modalTitle: "New Decline Reason Category",
      declineReasonCategoryFormData: details,
    });
    setTimeout(() => {
      this.setState({
        canAdd: true,
        declineReasonCategoryFormData: details,
      });
    }, 10);
  }

  onEditButtonClick(details) {
    this.setState({
      canUpdate: true,
      canAdd: false,
      canDelete: false,
      modalHeight: "370px",
      modalTitle: "Decline Reason Category Details",
      declineReasonCategoryFormData: [],
    });
    setTimeout(() => {
      this.setState({
        declineReasonCategoryFormData: details,
      });
    }, 5);
  }

  render() {
    const addButton = (
      <React.Fragment>
        <button
          type="button"
          class="btn btn-primary"
          style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
          data-toggle="modal"
          data-target="#kt_modal_1"
          onClick={this.onAddButtonClick}
        >
          Add Decline Reason Category
        </button>
      </React.Fragment>
    );

    const addModalBody = (
      <React.Fragment>
        {this.state.canAdd && (
          <div class="form-group col-lg-12">
            <label>Name:</label>
            <input
              autoComplete="off"
              type="text"
              class="form-control"
              value={
                this.state.declineReasonCategoryFormData
                  ? this.state.declineReasonCategoryFormData.categoryName
                  : ""
              }
              placeholder="Incomplete Documentation"
              onChange={(event) =>
                this.setState({
                  declineReasonCategoryFormData: {
                    ...this.state.declineReasonCategoryFormData,
                    categoryName: event.target.value,
                  },
                })
              }
            />
          </div>
        )}
      </React.Fragment>
    );

    const updateModalBody = (
      <React.Fragment>
        {this.state.declineReasonCategoryFormData && (
          <div class="form-group col-lg-12">
            <label>Name:</label>
            <input
              autoComplete="off"
              type="text"
              class="form-control"
              value={
                this.state.declineReasonCategoryFormData
                  ? this.state.declineReasonCategoryFormData.categoryName
                  : ""
              }
              placeholder="Incomplete Documentation"
              onChange={(event) =>
                this.setState({
                  declineReasonCategoryFormData: {
                    ...this.state.declineReasonCategoryFormData,
                    categoryName: event.target.value,
                  },
                })
              }
            />
          </div>
        )}
        {this.state.declineReasonCategoryFormData && (
          <div class="form-group col-lg-12">
            <label>In Use:</label>
            <select
              disabled={this.state.disabled}
              value={
                this.state.declineReasonCategoryFormData
                  ? this.state.declineReasonCategoryFormData.inUse
                  : ""
              }
              autoComplete="off"
              class="form-control"
              data-live-search="true"
              onChange={(event) =>
                this.setState({
                  declineReasonCategoryFormData: {
                    ...this.state.declineReasonCategoryFormData,
                    inUse: event.target.value,
                  },
                })
              }
            >
              {this.state.statuses.map((value, index) => (
                <option
                  value={value.id}
                  selected={
                    this.state.declineReasonCategoryFormData.inUse === value.id
                      ? "selected"
                      : ""
                  }
                >
                  {value.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Application Management Configuration"}
          subtitle={"Decline Reason Categories"}
          ctaWidgets={addButton}
        />
        <GridLayout>
          <Table
            columns={this.columns}
            data={this.state.declineReasonCategories}
            loading={this.state.isLoading}
            noDataText={`No decline reason categories found`}
            dataCount={this.state.dataCount}
            getData={this.getDeclineReasonCategories}
            exportData={DeclineReasonCategoryExportSerializer.reverseDeclineReasonCategoryDetails(
              this.state.declineReasonCategories
            )}
            exportHeaders={DeclineReasonCategoryExportSerializer.headerExport()}
            exportFileName={"decline_reason_categories"}
          />
          <PromptModal
            modalTitle={this.state.modalTitle}
            modalBody={
              this.state.canDelete
                ? this.state.deleteModalBody
                : this.state.canUpdate
                ? updateModalBody
                : addModalBody
            }
            deleteModal={
              this.state.canDelete
                ? this.deleteDeclineReasonCategory
                : this.state.canUpdate
                ? this.onUpdateButtonClick
                : this.onSaveButtonClick
            }
            modalSize={`modal-md`}
            height={this.state.modalHeight}
            textAlign={this.state.canDelete ? "center" : "left"}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={
              this.state.canDelete
                ? "Delete"
                : this.state.canUpdate
                ? "Update"
                : "Save"
            }
            modalFooterButton2Disabled={
              this.state.canDelete
                ? false
                : !this.state.declineReasonCategoryFormData.categoryName
            }
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
