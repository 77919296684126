import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ServiceGridScene from './scenes/service-grid';
import ViewServiceScene from './scenes/view-service';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_SERVICE } from '../../../../../constants/permissions.js'

export default class ServiceScene extends Component {
  sceneName = 'services'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE]} exact path='/home/services' component={ServiceGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_SERVICE]} exact path='/home/services/:id/view' component={ViewServiceScene} />
    </Router>
  }
}
