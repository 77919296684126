import { Component } from 'react';

export default class BaseScene extends Component {
  requiredPermissions = []
  
  constructor (props) {
    super(props);
    if (localStorage.getItem("user") === null && window.location.pathname !== `${process.env.REACT_APP_ROUTE_BASENAME}/login`) {
      window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/login`
    }
    
    if(this.props.updateCurrentPathName){
      this.props.updateCurrentPathName(window.location.pathname)
    }
  }
}
