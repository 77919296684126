import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import WalletJournalGridScene from './scene/wallet-journal-grid';
import BaseScene from '../../../../../components/scenes/component';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_TRANSACTION, CAN_PERFORM_BULK } from '../../../../../constants/permissions.js'

export default class WalletJournalScene extends BaseScene {
  sceneName = 'wallet-journal'
  
  componentDidMount () {
    //this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} exact path='/home/account-statement/:domainCode' component={WalletJournalGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} exact path='/home/account-statement' component={WalletJournalGridScene} />
    </Router>
  }
}
