export const BILLS = 1;

export const TRANSFER = 2;

export const RECHARGE = 3;

export const FUND = 4;

export const CASH_OUT = 5;

export const W2W = 6;

export const ACCOUNT_OPENING = 13;

export const TERMINAL_ID = "9FIS001"

export const PAYMENT_ITEM_CODE = "IFISW2W"
