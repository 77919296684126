import React from 'react'

export default class BatchCreditDebitFile extends React.Component {
    render() {
        return <React.Fragment>
            <div class="col-lg-8">
                <input type="file" disabled={this.props.disabled ? this.props.disabled : ''} class="form-control" autoComplete="off" placeholder="Enter agent phone number" onChange={event => this.props.readUploadedFile(event)} />
                {this.props.fileErrorMessage && <span class="error">{this.props.fileErrorMessage}</span>}
            </div>
            <div class="col-lg-2">
                <button disabled={!Object.keys(this.props.bulkProcessingFileUpload).length > 0 || !this.props.bulkProcessingFormData.bulkTransactionType || this.props.disabled} type="button" class="btn isw-button" onClick={this.props.uploadBulkProcessingDocument}>Submit</button>
            </div>
        </React.Fragment>
    }
}