import React from 'react';
import JSONPretty from 'react-json-pretty';

const TraceDetails = (props) => {
    return (
        <div>
            <div class="modal" id="trace_details_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> 
                <div class="modal-dialog modal-lg" style={{fontSize: '15px'}}> 
                    <div class="modal-content" style={{borderRadius: '15px'}}> 
                        <div class="modal-header"> 
                            <h3 class="modal-title" style={{textAlign: 'center'}}>Trace Details</h3> 
                        </div> 
                        <div class="modal-body fluid">
                        {props.spans && props.spans.map((span, index) => <div class="kt-portlet">
            <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                <div class="kt-portlet__head-label" role="button" aria-expanded="true">
                    <h3 class="kt-portlet__head-title">
                        {span.url}
                    </h3>
                </div>
            </div>

            <form class="kt-form kt-form--label-right" id={`collapse${index}`} class={`collapse ${props.showContent ? 'show' : ''}`} aria-labelledby="headingOne4">
            <div class="kt-portlet__body">
                                            <div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Duration</label><br/>
                                                        <span>{`${span.duration}ms`}</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Code</label><br/>
                                                        <span>{span.code}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginTop: '40px', marginBottom: '40px'}}>
                                                <h6>REQUEST</h6>
                                                <div style={{border: 'solid 1px grey', background: '#F4F4F4', borderRadius: '0.4%'}}>
                                                    <JSONPretty id="json-pretty" data={span.request}></JSONPretty>
                                                </div>
                                            </div>
                                            <div style={{marginTop: '40px', marginBottom: '10px'}}>
                                                <h6>RESPONSE</h6>
                                                <div style={{border: 'solid 1px grey', background: '#F4F4F4', borderRadius: '0.4%'}}>
                                                    <JSONPretty id="json-pretty" data={span.response}></JSONPretty>
                                                </div>
                                            </div>
                                        </div>
            </form>
        </div>)}
                            {/* <div class="kt-portlet" ref={props.traceDetailsSectionRef}>
                                {props.spans && props.spans.map(span => <div class="kt-portlet">
                                    <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target={`collapseOne`} aria-controls={`collapseOne`}>
                                        <div class="kt-portlet__head-label">
                                            <h3 class="kt-portlet__head-title">
                                                {span.url}
                                            </h3>
                                        </div>
                                    </div>
                                    <form id={`collapseOne`} class="collapse">
                                        <div class="kt-portlet__body">
                                            <div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Duration</label><br/>
                                                        <span>{`${span.duration}ms`}</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Code</label><br/>
                                                        <span>{span.code}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginTop: '40px', marginBottom: '40px'}}>
                                                <h6>REQUEST</h6>
                                                <div style={{border: 'solid 1px grey', background: '#F4F4F4', borderRadius: '0.4%'}}>
                                                    <JSONPretty id="json-pretty" data={span.request}></JSONPretty>
                                                </div>
                                            </div>
                                            <div style={{marginTop: '40px', marginBottom: '10px'}}>
                                                <h6>RESPONSE</h6>
                                                <div style={{border: 'solid 1px grey', background: '#F4F4F4', borderRadius: '0.4%'}}>
                                                    <JSONPretty id="json-pretty" data={span.response}></JSONPretty>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>)}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default TraceDetails