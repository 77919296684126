import { formatLongNumber } from '../../utils/formatter';

export default class ExternalFeeExportSerializer {
    static headerExport () {
        return [
            {label: 'Biller Name', key: 'billerName', title: 'Biller Name', dataIndex: 'billerName'},
            {label: 'Service Option Code', key: 'serviceOptionCode', title: 'Service Option Code', dataIndex: 'serviceOptionCode'},
            {label: 'Service Option Name', key: 'serviceOptionCodeName', title: 'Service Option Name', dataIndex: 'serviceOptionCodeName'},
            {label: 'Paydirect Item Code', key: 'paydirectItemCode', title: 'Paydirect Item Code', dataIndex: 'paydirectItemCode'},
            {label: 'Created By', key: 'createdBy', title: 'Created By', dataIndex: 'createdBy'},
            {label: 'Last Updated By', key: 'lastUpdatedBy', title: 'Last Updated By', dataIndex: 'lastUpdatedBy'},
        ]
    }

    static reverseExternalFeeDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { billerName, serviceOptionCode, serviceOptionCodeName, paydirectItemCode, createdBy, lastUpdatedBy } = details
            let detailFormData = {
                billerName,
                serviceOptionCode: serviceOptionCode ? formatLongNumber(serviceOptionCode) : '',
                serviceOptionCodeName,
                paydirectItemCode: paydirectItemCode ? formatLongNumber(paydirectItemCode) : '',
                createdBy: createdBy ? createdBy : '',
                lastUpdatedBy: lastUpdatedBy ? lastUpdatedBy : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}