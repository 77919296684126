import React from "react";
import Statuses from "../../fixtures/statuses.json";
import Wallet_Versions from "../../fixtures/wallet_versions.json";
import { phoneNumberFormatter } from "../../utils/formatter";
import { DatePickerComponent } from "../date-picker";
import Select from "../select";

export const AgentSearch = (props) => {
  const {
    updateAgentSearchParameters,
    preloadDate,
    handleSearchRequest,
    agentSearchParameters: { status, startDate },
    showBVN = true,
    showWalletVersion = true,
  } = props;
  return (
    <>
      <form
        class="search-params"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchRequest();
        }}
      >
        <div class="form-group row">
          <div class="col-md-3">
            <label>Agent ID:</label>
            <input
              type="text"
              class="search-input"
              onChange={(event) =>
                updateAgentSearchParameters("agentCode", event.target.value)
              }
            />
          </div>
          <div class="col-md-3">
            <label>Phone Number:</label>
            <input
              type="text"
              class="search-input"
              onChange={(event) =>
                updateAgentSearchParameters(
                  "agentMobileNo",
                  phoneNumberFormatter(event.target.value)
                )
              }
            />
          </div>
          <div class="col-md-3">
            <label>Business Name:</label>
            <input
              type="text"
              class="search-input"
              onChange={(event) =>
                updateAgentSearchParameters("businessName", event.target.value)
              }
            />
          </div>
        {showWalletVersion && (  <div class="col-md-3">
            <label>Status:</label>
            <Select
              options={Statuses}
              onChange={(event) =>
                updateAgentSearchParameters("status", event.value)
              }
              defaultValue={status}
            />
          </div>)}
        </div>
        <div class="form-group row">
          {showBVN && (
            <div class="col-md-3">
              <label>BVN:</label>
              <input
                type="text"
                class="search-input"
                onChange={(event) =>
                  updateAgentSearchParameters("bvn", event.target.value)
                }
              />
            </div>
          )}

          {showWalletVersion && (
            <div class="col-md-3">
              <label>Wallet Version:</label>
              <Select
                options={Wallet_Versions}
                onChange={(event) =>
                  updateAgentSearchParameters("walletVersion", event.value)
                }
                // defaultValue={status}
              />
            </div>
          )}
          <div class="col-md-2">
            <label class="search-label">Date From</label>
            <DatePickerComponent
              onChange={(event) =>
                updateAgentSearchParameters("startDate", event)
              }
              size={`90px`}
              format={`dd/MM/yyyy h:mm aa`}
              isClearable={true}
              showTimeInput={true}
              style={".1rem"}
              textStyle={".9rem"}
              iconStyle={".1rem"}
              dateType={"startDate"}
              preloadDate={preloadDate}
            />
          </div>
          <div class="col-md-2">
            <label class="search-label">Date To</label>
            <DatePickerComponent
              onChange={(event) =>
                updateAgentSearchParameters("endDate", event)
              }
              size={`90px`}
              format={`dd/MM/yyyy h:mm aa`}
              isClearable={true}
              showTimeInput={true}
              disabled={startDate ? false : true}
              minDate={new Date(startDate)}
              style={".1rem"}
              textStyle={".3rem"}
              iconStyle={".1rem"}
              dateType={"endDate"}
              preloadDate={preloadDate}
            />
            {/* fueuejiethe>gefyp   pwuyegdd */}
          </div>
          <div class="col-md-2">
            <label class="search-label">&nbsp;&nbsp;</label>
            <button type="submit" class="search-button">
              Search
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
