import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { CAN_VIEW_AGENT, CAN_VIEW_TRANSACTION } from '../../../../../constants/permissions.js';
import { isAuthorized } from '../../../../../mixins/authentication';
import KycUpdateScene from './scenes/kyc-update-grid/index.js';
import CatergoryScene from './scenes/category-upload/index.js';

export default class ExemptedAgentsScene extends Component {
  sceneName = 'kyc-update'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
            : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_AGENT]} exact path='/home/kyc-update' component={KycUpdateScene} />
      <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact path="/home/kyc-update/upload"
          component={CatergoryScene}
        />
    </Router>
  }
}
