import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import SpinnerComponent from "../../../../../components/spinner";
import ProtectedComponent from "../../../../../mixins/protected-component";
import { convertToMajor } from "../../../../../utils/converter";
import {
  formatCurrency
} from "../../../../../utils/formatter";
import {
  getPaginationRows
} from "../../../../../utils/helper";

export default class BuyPowerBalanceTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "250px",
    tableWidth: "65%",
    zoom: "plus",
  };


  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {

    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
     
      {
        Header: "Threshold Amount",
        accessor: "amount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 340,
        Cell: (props) => (
          <span>
            &#x20A6;{" "}
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Threshold State",
        accessor: "triggerState",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 350,
        // Cell: (props) => (
        //   <span>
        //     {this.getCombinedServiceAndServiceOption(
        //       props.original.service,
        //       props.original.serviceOptionName
        //     )}
        //   </span>
        // ),
      },
      
      // {
      //   Header: "Status",
      //   accessor: "statusCode",
      //   resizable: false,
      //   headerStyle: { "white-space": "unset" },
      //   style: { "white-space": "unset" },
      //   width: 100,
      //   Cell: (props) => {
      //     return (
      //       <span
      //         class={`badge ${formatTransactionStatusColorCode(props.value)}`}
      //       >
      //         {formatTransactionStatus(props.value)}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: "Action",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        width: 97,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#buy_power_balance_edit_modal"
                  onClick={() => this.props.getClickedAction(original, "edit")}
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  Edit
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  onClick={() => this.props.getClickedAction(original, "delete")}
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-ban"></i>
                  Delete
                </a>
              </ProtectedComponent>
             
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <ReactTable
          PaginationComponent="false"
          // PaginationComponent={Pagination}
          // loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            boxShadow: "0 0 10px #33333340",
            whiteSpace: "unset",
            height: this.state.tableHeight,
            width: this.state.tableWidth,
            margin: "10px auto",
            border: "1px solid #00425fa6",
            borderRadius: "0 0 10px 10px",
            textAlign: "center"
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          // pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getBuyPowerBalanceThreshold(
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows="3"
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display:"flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              // paddingLeft:"5px",
              paddingRight:"5px",
              alignItems: "center",
              textAlign: "center",
              paddingLeft: '40px'
              
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 5px",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: '20px'
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
