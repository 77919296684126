import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReconSchedulerGridScene from './scenes/recon-scheduler-grid';
import BaseScene from '../../../../../components/scenes/component';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_CURRENCY } from '../../../../../constants/permissions.js'

export default class ReconSchedulerScene extends BaseScene {
  sceneName = 'recon-scheduler'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_CURRENCY]} exact path='/home/system-configuration/recon-scheduler' component={ReconSchedulerGridScene} />
      {/* <ProtectedRoute exact path='/home/system-configuration/currency-management/add' component={AddCurrencyScene} /> */}
      {/* <ProtectedRoute requiredPermissions={[CAN_VIEW_CURRENCY]} exact path='/home/system-configuration/currency-management/:id/view' component={ViewCurrencyScene} /> */}
    </Router>
  }
}
