import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { CAN_VIEW_SETTLEMENT } from "../../../../../../../constants/permissions";
import { settlementService } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import FeeSplits from "../../components/fee-splits";
import TransactionDetails from "../../components/transaction-details";

export default class ViewSettlementReportScene extends BaseScene {
  constructor(props) {
    super(props);

    this.state = {
      transaction: [],
      transactions: [],
      disabled: true,
      settlement: {
        parties: [],
      },
    };
  }

  componentDidMount() {
    this.transRef = this.props.match.params.id;
    this.getSettlementByRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getSettlementByRef() {
    const responseObj = await settlementService.getSettlementByTransRef(
      this.transRef
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        settlement: response[0],
      });
    } else {
      this.setState({
        settlement: {
          parties: [],
        },
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ContentHead
          title={"Settlement Report"}
          filterPermissions={[CAN_VIEW_SETTLEMENT]}
          withoutSearch
          subtitle={``}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_SETTLEMENT]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <TransactionDetails
              settlement={this.state.settlement}
              disabled={this.state.disabled}
            />
            <FeeSplits feeSplits={this.state.settlement.parties} />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
