import React from "react";
import { AccessDenied } from "../../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE,
} from "../../../../../../../../constants/permissions.js";
import { userManagement } from "../../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";
import { formatBackEndDateTime } from "../../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../../utils/helper";
import DeviceSearch from "../../../../../device-management/components/device-search";
import DeviceTable from "../../../../../device-management/components/device-table";

export default class DeviceGridScene extends BaseScene {
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      searchParameters: {
        channel: "All",
        deviceUuid: null,
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate"),
        active: "All",
        username: null,
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
    };
    this.getAllDevices = this.getAllDevices.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
  }

  sceneName = "pos-remap";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllDevices(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.active =
      searchParameters.active === "All"
        ? null
        : searchParameters.active === "true";
    searchParameters.channel =
      searchParameters.channel === "All" ? null : searchParameters.channel;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      formatBackEndDateTime(searchParameters.fromDate, true);
    searchParameters.toDate =
      searchParameters.toDate &&
      formatBackEndDateTime(searchParameters.toDate, true);
    console.log("from the main scene ", searchParameters);
    const responseObj = await userManagement.getAllDevices(searchParameters);
    const { status, response, count } = responseObj;
    console.log("response is ", response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        devices: this.handleBackendResponse(response),
        dataCount: count,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: !count || count == 0 ? "No devices found" : "",
      });
    } else {
      this.setState({
        devices: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 2,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No devices found",
      });
    }
  }

  handleSearchRequest(searchParameters) {
    this.setState({ loading: true });
    this.setState({ pageIndex: 1 });
    this.setState({ startPage: 1 });
    this.setState({
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getAllDevices(1, 20);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        {/* <ContentHead title={"POS Management"} subtitle={'All Devices'} filterPermissions={[CAN_GET_DEVICES_BY_UUID]} totalCount={56} /> */}
        <ProtectedComponent
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <DeviceSearch
              handleSearchRequest={this.handleSearchRequest}
              searchParameters={this.state.searchParameters}
            />
            <DeviceTable
              data={this.state.devices}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getAllDevices={this.getAllDevices}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canRemapDevice={CAN_REMAP_DEVICE}
              canLoadData={this.state.canLoadData}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
