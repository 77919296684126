import React from 'react'
import Banks from "../../../../../../fixtures/NIPbanks.json";

export default class BankForm extends React.Component {
    
    constructor (props) {
        super(props)
      }
    
    render () {
        
        let enabledForSanef = this.props.enabledForSanef
        let enabledForCardLinking = this.props.enabledForCardLinking
        let requireBvn = this.props.requireBvn

        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                    <div class="form-group col-lg-6">
                            <label>Bank Name *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.bankName} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('bankName', event.target.value)}/>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>NIP Bank Code*:</label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.nipBankCode} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('nipBankCode', event.target.value)}/>
                        </div>

                        <div class="form-group col-lg-6">
                            <label>CBN Bank Code*:</label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.cbnBankCode} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('cbnBankCode', event.target.value)}/>
                        </div>


                        <div class="form-group col-lg-6">
                            <label>Alias*:</label>
                            <input autoComplete="off" type="text" class="form-control" value={this.props.alias} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('alias', event.target.value)}/>
                        </div>
                      
                      
                        <div class="form-group col-lg-6">
                            <label>Bank Category*:</label>
                            <select autoComplete="off" class="form-control" disabed={this.props.disabled ? this.props.disabled : ''} data-live-search="true" onChange={(event) => this.props.onChange('bankCategory', event.target.value)} value={this.props.bankCategory}>
                                 <option  key='0'>Select category</option>
                                {Banks.map((value, index) => <option value={value.category} key={value.id}>{value.category}</option>)}
                            </select>
                        </div>
                        
                       
                        
                    </div>
                </form>
            </div>
        </React.Fragment>
        
    }
}