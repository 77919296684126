import React from "react";
import { withRouter } from "react-router-dom";
import { formatBackEndDate } from "../../utils/formatter";
import { isPhoneNumberValid, stripNumbersFromString } from "../../utils/helper";
import { DatePickerComponent } from "../date-picker";
import PhoneNumberComponent from "../phone-number-component";
import { platformManagement } from "../../mixins/api";
import { SUCCESS_STATUS } from "../../constants/api";

class KycChecklist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showBvnInfo: false,
      showNinInfo: false,
      showTinInfo: false,
      showCacInfo: false,
      showFormInfo: false,
    };

    this.fetchAllKycRecords = this.fetchAllKycRecords.bind(this);
  }
  toggleFormInfo = () => {
    this.setState((prevState) => ({
      showFormInfo: !prevState.showFormInfo,
    }));
  };

  toggleBvnInfo = () => {
    this.setState((prevState) => ({
      showBvnInfo: !prevState.showBvnInfo,
    }));
  };

  toggleNinInfo = () => {
    this.setState((prevState) => ({
      showNinInfo: !prevState.showNinInfo,
    }));
  };

  toggleTinInfo = () => {
    this.setState((prevState) => ({
      showTinInfo: !prevState.showTinInfo,
    }));
  };

  toggleCacInfo = () => {
    this.setState((prevState) => ({
      showCacInfo: !prevState.showCacInfo,
    }));
  };

  async fetchAllKycRecords(agentId) {
    const kycTypes = ["BVN", "NIN", "TIN", "CAC"];
    try {
      this.setState({ isLoading: true });

      const requests = kycTypes.map((kycType) =>
        platformManagement
          .getKycRecord(agentId, kycType)
          .then((responseObj) => ({
            kycType,
            status: responseObj.status,
            data: responseObj.response.data || [],
          }))
      );

      const results = await Promise.all(requests);

      const kycData = results.reduce((acc, result) => {
        acc[result.kycType] = result.data;
        return acc;
      }, {});

      this.setState({
        getKycRecord: kycData,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        getKycRecord: {},
        isLoading: false,
      });
    }
  }

  componentDidMount() {
    const { agentId } = this.props.match.params;
    if (agentId) {
      this.fetchAllKycRecords(agentId);
    }
  }

  render() {
    const getKycRecord = this.state.getKycRecord || {};

    let bvnData = {};
    if (Array.isArray(getKycRecord.BVN) && getKycRecord.BVN.length === 0) {
      bvnData = {};
    } else if (getKycRecord.BVN && typeof getKycRecord.BVN === "object") {
      bvnData = getKycRecord.BVN;
    }

    let cacData = {};
    if (Array.isArray(getKycRecord.CAC) && getKycRecord.CAC.length === 0) {
      cacData = {};
    } else if (getKycRecord.CAC && typeof getKycRecord.CAC === "object") {
      cacData = getKycRecord.CAC;
    }

    let ninData = {};
    if (Array.isArray(getKycRecord.NIN) && getKycRecord.NIN.length === 0) {
      ninData = {};
    } else if (getKycRecord.NIN && typeof getKycRecord.NIN === "object") {
      ninData = getKycRecord.NIN;
    }

    let tinData = {};
    if (Array.isArray(getKycRecord.TIN) && getKycRecord.TIN.length === 0) {
      tinData = {};
    } else if (getKycRecord.TIN && typeof getKycRecord.TIN === "object") {
      tinData = getKycRecord.TIN;
    }

    return (
      <React.Fragment>
        <div className="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              className="kt-portlet__head"
              style={{ cursor: "pointer" }}
              onClick={this.toggleFormInfo}
            >
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">KYC Details</h3>
              </div>
            </div>
          )}

          {this.state.showFormInfo && (
            <form id="collapseOne1" class="collapse show">
              <div class="kt-portlet__body">
                <div
                  className="kt-portlet__head-label"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={this.toggleBvnInfo}
                >
                  <h5
                    className="kt-portlet__head-title"
                    style={{
                      marginRight: "20px",
                      textDecoration: this.state.isHoveredBvn
                        ? "underline"
                        : "none",
                    }}
                    onMouseEnter={() => this.setState({ isHoveredBvn: true })}
                    onMouseLeave={() => this.setState({ isHoveredBvn: false })}
                  >
                    BVN Information
                  </h5>
                  <span
                    style={{
                      transition: "transform 0.3s ease",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className={`ml-2 ${this.state.showBvnInfo
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                        }`}
                    ></i>
                  </span>
                </div>
                {this.state.showBvnInfo && (
                  <form id="collapseOne1" class="collapse show">
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        <div class="col-lg-4 mb-4">
                          <label>BV Number:</label>
                          <div class="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              class="form-control"
                              defaultValue={bvnData.bvnNumber || ""}
                            />
                          </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                          <label>BVN Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              className="form-control"
                              defaultValue={bvnData.bvnVerificationStatus || ""}
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>First Name :</label>
                          <input
                            autoComplete="off"
                            disabled={true}
                            type="name"
                            class="form-control"
                            defaultValue={bvnData.bvnFirstName || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Middle Name : </label>
                          <input
                            autoComplete="off"
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={bvnData.bvnMiddleName || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Last Name :</label>
                          <input
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={bvnData.bvnFirstName || ""}
                          />
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Date of Birth:</label>
                          <div className="input-group">
                            <DatePickerComponent
                              selected={
                                bvnData.bvnDateOfBirth && !isNaN(new Date(bvnData.bvnDateOfBirth).getTime())
                                  ? new Date(bvnData.bvnDateOfBirth)
                                  : null
                              }
                              size="270px"
                              format="dd/MM/yyyy"
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Phone *:</label>
                          <div class="kt-input-icon">
                            <PhoneNumberComponent
                              phoneIsValid={this.props.phoneIsValid || true}
                              onChange={(status, phoneNumber) => {
                                this.props.onChange("phone", phoneNumber);
                                this.props.updateSetState(
                                  "phoneIsValid",
                                  status || isPhoneNumberValid(phoneNumber)
                                );
                              }}
                              defaultValue={bvnData.bvnPhoneNumber || ""}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Gender:</label>
                          <div class="col-9">
                            <div class="kt-radio-inline">
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={true}
                                  type="radio"
                                  name="gender"
                                  value="MALE"
                                  defaultChecked={
                                    bvnData.bvnGender &&
                                    bvnData.bvnGender.toUpperCase() === "MALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Male
                                <span></span>
                              </label>
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={true}
                                  type="radio"
                                  name="gender"
                                  value="FEMALE"
                                  defaultChecked={
                                    bvnData.bvnGender &&
                                    bvnData.bvnGender.toUpperCase() === "FEMALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Female
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                <div
                  className="kt-portlet__head-label"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={this.toggleNinInfo}
                >
                  <h5 className="kt-portlet__head-title" style={{
                    marginRight: "20px",
                    textDecoration: this.state.isHoveredNin
                      ? "underline"
                      : "none",
                  }}
                    onMouseEnter={() => this.setState({ isHoveredNin: true })}
                    onMouseLeave={() => this.setState({ isHoveredNin: false })} >NIN Information</h5>
                  <span
                    style={{
                      transition: "transform 0.3s ease",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className={`ml-2 ${this.state.showNinInfo
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                        }`}
                    ></i>
                  </span>
                </div>
                {this.state.showNinInfo && (
                  <form id="collapseOne1" class="collapse show">
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        <div class="col-lg-4 mb-4">
                          <label>NI Number:</label>
                          <div class="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              class="form-control"
                              defaultValue={ninData.ninNumber || ""}
                            />
                          </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                          <label>NIN Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              className="form-control"
                              defaultValue={ninData.ninVerificationStatus || ""}
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>First Name:</label>
                          <input
                            autoComplete="off"
                            disabled={true}
                            type="name"
                            class="form-control"
                            defaultValue={ninData.ninFirstName || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Middle Name: </label>
                          <input
                            autoComplete="off"
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={ninData.ninMiddleName || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Last Name:</label>
                          <input
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={ninData.ninLastName || ""}
                          />
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Date of Birth:</label>
                          <div className="input-group">
                            <DatePickerComponent
                              selected={
                                ninData.ninDateOfBirth && !isNaN(new Date(ninData.ninDateOfBirth).getTime())
                                  ? new Date(ninData.ninDateOfBirth)
                                  : null
                              }
                              size="270px"
                              format="dd/MM/yyyy"
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Gender:</label>
                          <div class="col-9">
                            <div class="kt-radio-inline">
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={true}
                                  type="radio"
                                  name="gender"
                                  value="MALE"
                                  defaultChecked={
                                    ninData.gender &&
                                    ninData.gender.toUpperCase() === "MALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Male
                                <span></span>
                              </label>
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={true}
                                  type="radio"
                                  name="gender"
                                  value="FEMALE"
                                  defaultChecked={
                                    ninData.ninGender &&
                                    ninData.ninGender.toUpperCase() === "FEMALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Female
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                <div
                  className="kt-portlet__head-label"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={this.toggleTinInfo}
                >
                  <h5 className="kt-portlet__head-title" style={{
                    marginRight: "20px",
                    textDecoration: this.state.isHoveredTin
                      ? "underline"
                      : "none",
                  }}
                    onMouseEnter={() => this.setState({ isHoveredTin: true })}
                    onMouseLeave={() => this.setState({ isHoveredTin: false })}>TIN Information</h5>
                  <span
                    style={{
                      transition: "transform 0.3s ease",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className={`ml-2 ${this.state.showTinInfo
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                        }`}
                    ></i>
                  </span>
                </div>
                {this.state.showTinInfo && (
                  <form id="collapseOne1" class="collapse show">
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        <div class="col-lg-4 mb-4">
                          <label>TI Number:</label>
                          <div class="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              class="form-control"
                              defaultValue={tinData.tinNumber || ""}
                            />
                          </div>
                        </div>

                        <div className="col-lg-4 mb-4">
                          <label>TIN Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              className="form-control"
                              defaultValue={tinData.tinVerificationStatus || ""}
                            />
                          </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Taxpayer Name:</label>
                          <input
                            autoComplete="off"
                            disabled={true}
                            type="name"
                            class="form-control"
                            defaultValue={tinData.taxPayerName || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>RC Number: </label>
                          <input
                            autoComplete="off"
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={tinData.cacRegNo || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Entity Type:</label>
                          <input
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={tinData.entityType || ""}
                          />
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Phone Number:</label>
                          <div class="kt-input-icon">
                            <PhoneNumberComponent
                              phoneIsValid={this.props.phoneIsValid || true}
                              defaultValue={tinData.tinPhoneNumber || ""}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Email:</label>
                          <div class="kt-input-icon">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="email"
                              class="form-control"
                              defaultValue={tinData.tinEmail || ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                <div
                  className="kt-portlet__head-label"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={this.toggleCacInfo}
                >
                  <h5 className="kt-portlet__head-title"
                    style={{
                      marginRight: "20px",
                      textDecoration: this.state.isHoveredCac
                        ? "underline"
                        : "none",
                    }}
                    onMouseEnter={() => this.setState({ isHoveredCac: true })}
                    onMouseLeave={() => this.setState({ isHoveredCac: false })}
                  >CAC Information</h5>
                  <span
                    style={{
                      transition: "transform 0.3s ease",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className={`ml-2 ${this.state.showCacInfo
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                        }`}
                    ></i>
                  </span>
                </div>

                {this.state.showCacInfo && (
                  <form id="collapseOne1" class="collapse show">
                    <div class="kt-portlet__body">
                      <div class="form-group row">
                        <div class="col-lg-4 mb-4">
                          <label>RC Number: </label>
                          <input
                            autoComplete="off"
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={cacData.rcNumber || ""}
                          />
                        </div>

                        <div className="col-lg-4 mb-4">
                          <label>CAC Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={true}
                              type="text"
                              className="form-control"
                              defaultValue={cacData.cacVerificationStatus || ""}
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange(
                                  "bvnVerificationStatus",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                          <label>Company/Business name:</label>
                          <input
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={cacData.companyName || ""}
                          />
                        </div>
                        <div class="col-lg-4 mb-4">
                          <label>Business Type:</label>
                          <input
                            type="name"
                            disabled={true}
                            class="form-control"
                            defaultValue={cacData.classification || ""}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </form>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(KycChecklist);
