import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../components/access-denied";
import ApplicantDetailsForm from "../../../../../../components/application-form/applicant-details";
import BusinessDetailsForm from "../../../../../../components/application-form/business-details";
import KycDocuments from "../../../../../../components/application-form/kyc-documents";
import NextOfKinDetailsForm from "../../../../../../components/application-form/next-of-kin-details";
import ContentHead from "../../../../../../components/content-head";
import { GridItem } from "../../../../../../components/layouts/grid-item";
import Loader from "../../../../../../components/loader";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../constants/api";
import { CAN_CREATE_APPLICATION } from "../../../../../../constants/permissions";
import ApplicationTypes from "../../../../../../fixtures/application_types.json";
import Banks from "../../../../../../fixtures/banks.json";
import BusinessTypes from "../../../../../../fixtures/business_types.json";
import Countries from "../../../../../../fixtures/countries.json";
import IDTYPES from "../../../../../../fixtures/id-types.json";
import LGAs from "../../../../../../fixtures/lgas.json";
import PersonalDocuments from "../../../../../../fixtures/personal_documents.json";
import Relationships from "../../../../../../fixtures/relationships.json";
import States from "../../../../../../fixtures/states.json";
import UtilityBills from "../../../../../../fixtures/utility_bills.json";
import { onboarding } from "../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import {
  ApplicantDetailsSerializer,
  BusinessDetailsSerializer,
} from "../../../../../../serializers";
import {
  getLgasByStateId,
  getStatesByCountryId,
  stripSpacesFromString,
  trimPhoneNumber,
} from "../../../../../../utils/helper";
import {
  checkAccountNumberIsValid,
  checkBvnIsValid,
  checkCompanyRegistrationNumberIsValid,
  checkEmailIsValid,
} from "../../../../../../utils/validator";

export default class AddApplicationScene extends BaseScene {
  requiredPermissions = [CAN_CREATE_APPLICATION];

  sceneName = "add-application";
  metadataSectionRef = React.createRef(null);
  kycDocumentSectionRef = React.createRef(null);
  applicationId = null;

  canSave = false;
  canSubmit = false;

  componentDidMount() {
    this.applicationType = this.props.match.params.id;
    this.validateApplicationType();
    this.canUploadDocument();
    this.props.updateScene(this.sceneName);
  }

  constructor(props) {
    super(props);

    this.requiredApplicantDetailsFields = ["firstName", "lastName", "phone"];

    this.allApplicantDetailsFields = [
      ...this.requiredApplicantDetailsFields,
      "gender",
      "mothersMaidenName",
      "closestLandmark",
      "placeOfBirth",
      "middleName",
      "identificationNumber",
    ];

    this.allNextOfKinFields = ["firstName", "surname", "phoneNumber", "gender"];

    this.allBusinessDetailsFields = [
      "businessName",
      "businessType",
      "companyRegistrationNumber",
      "state",
      "localGovernmentArea",
      "address",
      "bankName",
      "accountNumber",
    ];

    this.allValidationFields = [
      "bvnIsValid",
      "emailIsValid",
      "passwordIsValid",
      "phoneIsValid",
      "nextOfKinPhoneIsValid",
      "accountNumberIsValid",
    ];

    this.state = {
      canUploadDocument: false,
      documentErrors: [],
      identificationTypes: IDTYPES,
      businessTypes: BusinessTypes,
      application: null,
      countries: [],
      states: [],
      lgas: [],
      businessLgas: [],
      applicantLgas: [],
      applicantDetailsFormData: {
        nextOfKin: {},
      },
      businessDetailsFormData: {},
      kycDocuments: {},

      bvnIsValid: false,
      emailIsValid: false,
      formSubmitted: false,
      passwordIsValid: false,
      phoneIsValid: true,
      businessPhoneIsValid: true,
      nextOfKinPhoneIsValid: true,
      accountNumberIsValid: false,

      canSave: false,
      canSubmit: false,
      shoouldUpdateBusinessAddress: false,
      updateNextOfKinAddress: false,
      uploadedDocuments: [],
      personalDocumentType: null,
      utilityBillType: null,
      uploadedOtherDocuments: [],
      otherDocumentName: "",
      applicantDetails: [],
    };

    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.saveApplication = this.saveApplication.bind(this);
    this.readNationalIdUrl = this.readNationalIdUrl.bind(this);
    //this.submitApplication = this.submitApplication.bind(this);
    this.updateApplicantDetailsForm = this.updateApplicantDetailsForm.bind(
      this
    );
    this.updateBusinessDetailsForm = this.updateBusinessDetailsForm.bind(this);
    this.updateNextOfKinDetailsForm = this.updateNextOfKinDetailsForm.bind(
      this
    );
    this.updateSetState = this.updateSetState.bind(this);
    this.updateBusinessAddress = this.updateBusinessAddress.bind(this);
    this.updateNextOfKinAddress = this.updateNextOfKinAddress.bind(this);
    this.uploadApplicationDocument = this.uploadApplicationDocument.bind(this);
    this.updateCountry = this.updateCountry.bind(this);
    this.updateState = this.updateState.bind(this);
    this.getIdentificationTypeName = this.getIdentificationTypeName.bind(this);
    this.getCountryName = this.getCountryName.bind(this);
    this.getStateName = this.getStateName.bind(this);
    this.getLgaName = this.getLgaName.bind(this);
    this.getBusinessTypeName = this.getBusinessTypeName.bind(this);
    this.getAgentClasssName = this.getAgentClassName.bind(this);
    this.getAggregatorName = this.getAggregatorName.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }

    if (this.state.uploadErrorMessage) {
      setTimeout(() => {
        this.setState({ uploadErrorMessage: null });
      }, 5000);
    }

    const emptyRequiredApplicantDetailsFields = this.requiredApplicantDetailsFields.filter(
      (value) => {
        return Boolean(this.state.applicantDetailsFormData[value]) === false;
      }
    );

    const emptyApplicantDetailsFields = this.allApplicantDetailsFields.filter(
      (value) => {
        return Boolean(this.state.applicantDetailsFormData[value]) === false;
      }
    );

    const emptyNextOfKinFields = this.allNextOfKinFields.filter((value) => {
      return (
        Boolean(this.state.applicantDetailsFormData.nextOfKin[value]) === false
      );
    });

    const emptyBusinessDetailsFields = this.allBusinessDetailsFields.filter(
      (value) => {
        return Boolean(this.state.businessDetailsFormData[value]) === false;
      }
    );

    const allFieldsEmpty =
      emptyApplicantDetailsFields.length +
      emptyNextOfKinFields.length +
      emptyBusinessDetailsFields.length;

    const newState = {
      canSave: this.canSave,
      canSubmit: this.canSubmit,
    };

    if (emptyRequiredApplicantDetailsFields.length === 0) {
      newState.canSave = true;
    } else if (emptyRequiredApplicantDetailsFields.length !== 0) {
      newState.canSave = false;
    }

    //&& this.state.documentErrors.length === 0 && !this.canSubmit

    if (
      allFieldsEmpty === 0 &&
      Object.keys(this.state.kycDocuments).length >= 4 &&
      this.state.documentErrors.length === 0 &&
      !this.canSubmit
    ) {
      newState.canSubmit = true;
    }
    // if (Object.keys(this.state.kycDocuments).length >= 4) {
    //   newState.canSubmit = true
    // }
    else if (allFieldsEmpty !== 0) {
      newState.canSubmit = false;
    }

    this.canSave = newState.canSave;
    this.canSubmit = newState.canSubmit;
  }

  getIdentificationTypeName(id) {
    const details = this.state.identificationTypes.filter(
      (value) => value.id == id
    );
    return details[0] ? details[0].name : id;
  }

  getCountryName(id) {
    const details = this.state.countries.filter(
      (value) => value.id == parseInt(id)
    );
    return details[0] ? details[0].name : "";
  }

  getStateName(id) {
    const details = States.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getLgaName(id) {
    const details = LGAs.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getBusinessTypeName(id) {
    const details = this.state.businessTypes.filter((value) => value.id == id);
    return details[0] ? details[0].business_type : id;
  }

  getAgentClassName(id) {
    const details = this.state.agentClasses.filter((value) => value.id == id);
    return details[0] ? details[0].name : "";
  }

  getAggregatorName(id) {
    const details = this.state.aggregators.filter(
      (value) => value.referralCode == id
    );
    return details[0] ? details[0].businessName : id;
  }

  validateApplicationType() {
    const applicationType = ApplicationTypes.filter(
      (value) => value.name == this.applicationType
    );
    if (!applicationType.length) {
      this.setState({ redirect: true });
    } else {
      this.setState({
        application: {
          ...this.state.application,
          agentTypeId: applicationType[0].id,
        },
      });
    }
    return;
  }

  readNationalIdUrl(type, documentType, event) {
    const document = {
      name: documentType,
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      kycDocuments: {
        ...this.state.kycDocuments,
        [type]: document,
      },
    });

    //this.applicationId = '456354'

    if (!this.applicationId) {
      this.onSubmitButtonClick(true);
      const documentType = `${type}Progress`;
      this.setState({
        [documentType]: true,
      });
      setTimeout(() => {
        this.uploadApplicationDocument(
          this.applicationId,
          document.name,
          document.file,
          type,
          document.filename,
          document.url
        );
      }, 50000);
    } else {
      this.uploadApplicationDocument(
        this.applicationId,
        document.name,
        document.file,
        type,
        document.filename,
        document.url
      );
    }
  }

  formatApplicationPhoneNumbers(application) {
    if (application.applicantDetails.phoneNumber) {
      application.applicantDetails.phoneNumber = application.applicantDetails
        .phoneNumber
        ? trimPhoneNumber(
            stripSpacesFromString(application.applicantDetails.phoneNumber)
          )
        : "";
    }
    if (
      application.applicantDetails.nextOfKin &&
      application.applicantDetails.nextOfKin.phoneNumber
    ) {
      application.applicantDetails.nextOfKin.phoneNumber = application
        .applicantDetails.nextOfKin.phoneNumber
        ? trimPhoneNumber(
            stripSpacesFromString(
              application.applicantDetails.nextOfKin.phoneNumber
            )
          )
        : "";
    }
    if (application.businessDetails.phoneNumber) {
      application.businessDetails.phoneNumber = application.businessDetails
        .phoneNumber
        ? trimPhoneNumber(
            stripSpacesFromString(application.businessDetails.phoneNumber)
          )
        : "";
    }
    return application;
  }

  async saveApplication(
    application,
    showErrorResponse = false,
    showSuccessResponse = false
  ) {
    this.setState({ isLoading: true });
    application = this.formatApplicationPhoneNumbers(application);
    const responseObj = await onboarding.saveApplication(
      application,
      this.state.application.applicationId ? false : true
    );
    const { status, response } = responseObj;
    if (response.applicationId) {
      application["applicationId"] = response.applicationId;
      this.applicationId = response.applicationId;
    }

    this.setState({
      application,
    });

    if (status === SUCCESS_STATUS) {
      //application['applicationId'] = saveApplicationResponse.applicationId
      this.setState({
        isLoading: false,
        //redirect: true,
        successMessage: showSuccessResponse
          ? "Application saved successfully."
          : null,
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: showErrorResponse ? response : null,
      });
    }

    window.scrollTo(0, this.metadataSectionRef.current);
  }

  async submitApplication(draft = false) {
    this.setState({ isLoading: true });
    const application = this.applicationSerializer();
    const responseObj = await onboarding.submitApplication(application, draft);
    const { status, responseData } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        redirect: true,
        successMessage: "Application submitted successfully.",
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: responseData,
      });
    }

    window.scrollTo(0, this.metadataSectionRef.current);
    setTimeout(() => {
      // this.setState({
      //   formSubmitted: true
      // })
    }, 2000);
  }

  async uploadApplicationDocument(
    applicationId,
    name,
    file,
    type,
    filename,
    url
  ) {
    const documentType = `${type}Progress`;
    this.setState({
      [documentType]: true,
    });

    if (!applicationId) {
      this.onSubmitButtonClick();
    }

    applicationId = applicationId
      ? applicationId
      : this.state.application.applicationId;

    const responseObj = await onboarding.documentUpload(
      applicationId,
      name,
      file
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.state.documentErrors.pop(name);
      const document = {
        name: name,
        file: file,
        filename: filename,
        url: url,
      };
      if (type === "otherDocument") {
        let allUploadedDocuments = this.state.kycDocuments;

        allUploadedDocuments.otherDocument = {};

        let otherDocumentsArray = this.state.uploadedDocuments;

        otherDocumentsArray.push(document);

        this.setState({
          uploadedOtherDocuments: otherDocumentsArray,
          kycDocuments: allUploadedDocuments,
        });
      }
    } else {
      this.state.documentErrors.indexOf(name) === -1 &&
        this.setState({
          documentErrors: [...this.state.documentErrors, name],
        });
      this.setState({
        uploadErrorMessage: response,
      });
    }
    setTimeout(() => {
      this.setState({
        [documentType]: false,
      });
    }, 500);
    window.scrollTo(this.kycDocumentSectionRef.current);
  }

  applicationSerializer() {
    let application = {};
    if (!this.state.application) {
      application = {
        howYouHeardAboutUs: this.state.applicantDetailsFormData
          .howYouHeardAboutUs,
        applicantDetails: {
          ...ApplicantDetailsSerializer.serialize(
            this.state.applicantDetailsFormData
          ),
        },
        businessDetails: BusinessDetailsSerializer.serialize(
          this.state.businessDetailsFormData
        ),
      };
      // this.saveApplication(application)
    } else {
      // update application by concatenating forms
      application = {
        ...this.state.application,
        howYouHeardAboutUs: this.state.applicantDetailsFormData
          .howYouHeardAboutUs,
        applicantDetails: ApplicantDetailsSerializer.serializeForApplication(
          this.state.applicantDetailsFormData
        ),
        businessDetails: BusinessDetailsSerializer.serialize(
          this.state.businessDetailsFormData
        ),
      };
      // this.saveApplication(application)
    }

    this.setState({ application });

    return application;
  }

  async onSubmitButtonClick(
    showErrorResponse = false,
    showSuccessResponse = false
  ) {
    const application = this.applicationSerializer();
    application.applicationId
      ? this.submitApplication(true)
      : this.saveApplication(application, showErrorResponse);
  }

  updateCountry(countryId) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        country: countryId,
      },
    });
    this.setState({
      states: getStatesByCountryId(countryId),
      canUploadDocument: false,
      showStates: true,
    });
  }

  updateState(stateId, type) {
    this.setState({ lgas: [] });
    if (type === "applicantState") {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          state: stateId,
        },
        applicantLgas: getLgasByStateId(stateId),
      });
    } else {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          state: stateId,
        },
        businessLgas: getLgasByStateId(stateId),
      });
    }
  }

  canUploadDocument() {
    this.setState({
      canUploadDocument: this.state.applicantDetailsFormData,
    });
  }

  updateBusinessAddress(shouldUpdate) {
    if (shouldUpdate) {
      this.updateState(
        this.state.applicantDetailsFormData.state,
        "businessLgas"
      );
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          address: this.state.applicantDetailsFormData.address,
          state: this.state.applicantDetailsFormData.state,
          localGovernmentArea: this.state.applicantDetailsFormData.lga,
        },
      });
    } else {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          address: null,
          state: null,
          localGovernmentArea: null,
        },
      });
    }
  }

  updateNextOfKinAddress(shouldUpdate) {
    if (shouldUpdate) {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            address: this.state.applicantDetailsFormData.address,
          },
        },
      });
    } else {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            address: "",
          },
        },
      });
    }
  }

  updateApplicantDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        [type]: value,
      },
    });
  }

  updateBusinessDetailsForm(type, value) {
    this.setState({
      businessDetailsFormData: {
        ...this.state.businessDetailsFormData,
        [type]: value,
      },
    });
  }

  updateNextOfKinDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        nextOfKin: {
          ...this.state.applicantDetailsFormData.nextOfKin,
          [type]: value,
        },
      },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/application-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_APPLICATION]}>
          <button
            type="button"
            disabled={!this.canSave}
            class="btn btn-secondary"
            style={{
              backgroundColor: this.canSave ? "#00b8de" : "",
              color: this.canSave ? "white" : "grey",
            }}
            onClick={this.onSubmitButtonClick}
          >
            Save
          </button>
        </ProtectedComponent>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_APPLICATION]}>
          <button
            disabled={!this.canSubmit}
            class="btn"
            style={{ backgroundColor: "#00425f", color: "white" }}
            onClick={this.submitApplication.bind(this, false)}
          >
            Submit
          </button>
        </ProtectedComponent>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />
        <ContentHead
          title={"Application Management"}
          withoutSearch
          subtitle={`Create a new ${this.applicationType} application`}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_CREATE_APPLICATION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridItem>
            <ApplicantDetailsForm
              showPhoneInput={true}
              identificationTypes={this.state.identificationTypes}
              onChange={this.updateApplicantDetailsForm}
              countries={Countries}
              states={this.state.states}
              lgas={this.state.applicantLgas}
              updateCountry={this.updateCountry}
              updateState={this.updateState}
              checkEmailIsValid={checkEmailIsValid}
              updateSetState={this.updateSetState}
              emailIsValid={this.state.emailIsValid}
              phoneIsValid={this.state.phoneIsValid}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              showContent={true}
              showStates={this.state.showStates}
              kycCheckList={this.state.application}
              applicantDetails={this.state.applicantDetails}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              getCountryName={this.getCountryName}
              getIdentificationTypeName={this.getIdentificationTypeName}
            />
            <BusinessDetailsForm
              showPhoneInput={true}
              states={this.state.states}
              lgas={this.state.businessLgas}
              updateState={this.updateState}
              updateSetState={this.updateSetState}
              businessTypes={this.state.businessTypes}
              checkCompanyRegistrationNumberIsValid={
                checkCompanyRegistrationNumberIsValid
              }
              companyRegistrationNumberIsValid={
                this.state.companyRegistrationNumberIsValid
              }
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              businessDetailsFormData={this.state.businessDetailsFormData}
              banks={Banks}
              checkBvnIsValid={checkBvnIsValid}
              bvnIsValid={this.state.bvnIsValid}
              shouldUpdateBusinessAddress={
                this.state.shouldUpdateBusinessAddress
              }
              updateBusinessAddress={this.updateBusinessAddress}
              businessPhoneIsValid={this.state.businessPhoneIsValid}
              checkAccountNumberIsValid={checkAccountNumberIsValid}
              accountNumberIsValid={this.state.accountNumberIsValid}
              onChange2={this.updateApplicantDetailsForm}
              onChange={this.updateBusinessDetailsForm}
              showContent={true}
              businessEmailIsValid={this.state.businessEmailIsValid}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              getCountryName={this.getCountryName}
              getIdentificationTypeName={this.getIdentificationTypeName}
            />
            <NextOfKinDetailsForm
              showPhoneInput={true}
              relationships={Relationships}
              nextOfKinPhoneIsValid={this.state.nextOfKinPhoneIsValid}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              shouldUpdateNextOfKinAddress={
                this.state.shouldUpdateNextOfKinAddress
              }
              updateNextOfKinAddress={this.updateNextOfKinAddress}
              updateSetState={this.updateSetState}
              onChange={this.updateNextOfKinDetailsForm}
              showContent={true}
            />
            <KycDocuments
              showUploadForm={true}
              showUploadedDocuments={false}
              personalDocuments={PersonalDocuments}
              utilityBills={UtilityBills}
              utilityBillType={this.state.utilityBillType}
              personalDocumentType={this.state.personalDocumentType}
              kycDocuments={this.state.kycDocuments}
              documentErrors={this.state.documentErrors}
              applicationId={
                this.state.application
                  ? this.state.application.applicationId
                  : null
              }
              passportPhotoProgress={this.state.passportPhotoProgress}
              characterAttestationProgress={
                this.state.characterAttestationProgress
              }
              incorporationCertificateProgress={
                this.state.incorporationCertificateProgress
              }
              personalDocumentProgress={this.state.personalDocumentProgress}
              utilityBillProgress={this.state.utilityBillProgress}
              otherDocumentProgress={this.state.otherDocumentProgress}
              canUploadDocument={!this.canSave}
              //canUploadDocument={false}
              updateSetState={this.updateSetState}
              readNationalIdUrl={this.readNationalIdUrl}
              uploadApplicationDocument={this.uploadApplicationDocument}
              onSubmitButtonClick={this.onSubmitButtonClick}
              showContent={true}
              uploadedDocuments={this.state.uploadedDocuments}
              uploadErrorMessage={this.state.uploadErrorMessage}
              uploadSuccessMessage={this.state.uploadSuccessMessage}
              ref={this.kycDocumentSectionRef}
              otherDocumentName={this.state.otherDocumentName}
              uploadedOtherDocuments={this.state.uploadedOtherDocuments}
            />
          </GridItem>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
