import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CAN_GET_DEVICES_BY_UUID } from "../../../../../constants/permissions.js";
import { isAuthorized } from "../../../../../mixins/authentication";
import QuickStockGridScene from "./scenes/quick-grid";
import UploadQuickStockGridScene from "./scenes/quick-upload-stock-grid";

export default class QuickStocksScene extends Component {
  sceneName = "quick-stocks";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/quick-stocks"
          component={QuickStockGridScene}
        />
        <ProtectedRoute
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          exact
          path="/home/pos-management/quick-upload-stocks"
          component={UploadQuickStockGridScene}
        />
      </Router>
    );
  }
}
