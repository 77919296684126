import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import BaseScene from "../../../../../components/scenes/component";
import { CAN_VIEW_TRANSACTION } from "../../../../../constants/permissions.js";
import { isAuthorized } from "../../../../../mixins/authentication";
import NIPUploadGridScene from "./scenes/Nip-upload-grid";
import TransactionHistoryGridScene from "./scenes/transaction-history-grid";

export default class TransactionHistoryScene extends BaseScene {
  sceneName = "transaction-history";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/transaction-history"
          component={TransactionHistoryGridScene}
        />

        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          exact
          path="/home/transaction-history/Upload-Nip"
          component={NIPUploadGridScene}
        />
      </Router>
    );
  }
}
