import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_VIEW_TRANSACTION } from '../../../../../../../constants/permissions.js';
import { liquidityService } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { formatBackEndDate2 } from '../../../../../../../utils/formatter';
import { getDateByType } from '../../../../../../../utils/helper';
import PayWithTransferSearch from '../../components/pay-with-transfer-search';
import PayWithTransferTable from '../../components/pay-with-transfer-table';

export default class PayWithTransferGridScene extends BaseScene {
  
  sceneName = 'pay-with-transfer'

  transactionDetailsSectionRef = React.createRef(null)
  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      pwtResponse: [],
      searchParameters: {
        startDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
        endDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null,
        channelTransactionReference: "",
        accountNumber: "",
        forcePush: false,
      },
      pages: 1,
      canLoadData: JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      pageNum: 1,
      pageSize: 1,
      tableMessage: ""
    }

    this.getPayWithTransfer = this.getPayWithTransfer.bind(this)
    this.updateSearchParameters = this.updateSearchParameters.bind(this)
    this.handleSearchRequest = this.handleSearchRequest.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getPayWithTransfer (pageNum, pageSize) {
    const payload = JSON.parse(JSON.stringify(this.state.searchParameters));
    payload.endDate = payload.endDate && formatBackEndDate2(payload.endDate)+'T23:59:59';
    payload.startDate = payload.startDate && formatBackEndDate2(payload.startDate)+'T00:00:00';
    this.setState({
      pageNum,
      pageSize,
      isLoading: true
    })
    const { status, response } = await liquidityService.getPayWithTransfer(pageNum, pageSize, payload);

    let successMessage = "";
    if (payload.forcePush === true) {
      successMessage = "Awesome! Transaction has been repushed successfully.";
    } 

    if (status === SUCCESS_STATUS) {
      this.setState({
        pwtResponse: response ? response.content : [],
        pages: Math.ceil(response.count/this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: response.count ? response.count: 0,
        isLoading: false,
        successMessage: successMessage
      })
    } else {
      this.setState({
        pwtResponse: [],
        dataCount: 0,
        pages: 1,
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No record found"
      })
    }
  }

  handleSearchRequest () {
    this.getPayWithTransfer(this.state.pageNum, this.state.pageSize)
  }

  updateSearchParameters (type, value) {
    this.setState({searchParameters: {...this.state.searchParameters, [type]: value}})
  }

  render () {

    return <React.Fragment>
      <ContentHead title={"Pay with Transfer Report"} filterPermissions={[CAN_VIEW_TRANSACTION]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_TRANSACTION]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <PayWithTransferSearch 
            searchParameters={this.state.searchParameters}
            updateSearchParameters={this.updateSearchParameters}
            handleSearchRequest={this.handleSearchRequest}
            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
            count={this.state.dataCount || 0}
          />
          <PayWithTransferTable 
            pages={this.state.pages}
            getPayWithTransfer={this.getPayWithTransfer}
            data={this.state.pwtResponse}
            startPage={this.state.startPage}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            page={this.state.pageNum}
            pageSize={this.state.pageSize}
            searchParameters={this.state.searchParameters}
            responseCode={this.state.searchParameters.responseCode}
            canView={CAN_VIEW_TRANSACTION}
            canLoadData={this.state.canLoadData}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
