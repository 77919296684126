import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { formatDateTime } from "../../../../../utils/formatter";
import {
  getApplicationType,
  explodeDate,
  getPaginationRows,
} from "../../../../../utils/helper";
import SpinnerComponent from "../../../../../components/spinner";
import Pagination from "../../../../../components/pagination";
import { ApplicationExportSerializer } from "../../../../../serializers";
import TableExtra from "../../../../../components/table-extra";
import ProtectedComponent from "../../../../../mixins/protected-component";
import { Link } from "react-router-dom";

export default class RecurringMessageTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "Date Created",
        accessor: "created",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
        Cell: (props) => (props.value ? formatDateTime(props.value) : ""),
      },
      {
        Header: "Last Schedule Date",
        accessor: "lastExecution",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
        Cell: (props) => (props.value ? formatDateTime(props.value) : ""),
      },
      {
        Header: "Next Schedule Date",
        accessor: "nextexecution",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
        Cell: (props) => (props.value ? formatDateTime(props.value) : ""),
      },
      {
        Header: "Schedule Details",
        accessor: "to",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: ({ row, original }) => (
          <div>
            <span class="recipient-details" title={original.description}>
              {original.description}
            </span>
            <br />
            <small class="recipient-details" title={original.cron}>
              {original.cron}
            </small>
          </div>
        ),
      },
      {
        Header: "Initiator",
        accessor: "to",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: ({ row, original }) => (
          <div>
            <span class="recipient-details" title={original.userFullName}>
              {original.userFullName}
            </span>
            <br />
            <small class="recipient-details" title={original.userId}>
              {original.userId}
            </small>
          </div>
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
      },
      {
        Header: "Action",
        accessor: "id",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 60,
        Cell: ({ row, original }) => (
          <a
            data-toggle="modal"
            data-target="#kt_modal_1"
            onClick={() => this.props.onDeleteButtonClick(row.id)}
            class="dropdown-item"
            style={{ cursor: "pointer" }}
          >
            <i class="la la-trash la-lg error"></i>
          </a>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          //data={ApplicationExportSerializer.reverseApplicationDetails(this.props.data)}
          //headers={ApplicationExportSerializer.headerExport()}
          //fileName={'applications'}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getRecurringMessages}
          page={this.props.page}
          pageSize={this.props.pageSize}
          zoom={this.state.zoom}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={this.props.data.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getRecurringMessages(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
              fontSize: "11.5px",
            },
          })}
          // getTdProps={(state, rowInfo, column, instance) => ({
          //     style: {
          //       overflow: 'visible',
          //       //backgroundColor: '#FFFFFF',
          //       lineHeight: '20px',
          //     },
          // })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "5px",
              paddingBottom: "5px",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "300",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
