export default class AgentClassesGroupsExportSerializer {
    static headerExport () {
        return [
            {label: 'Agent Class ID', key: 'agentClassId', title: 'Agent Class ID', dataIndex: 'agentClassId'},
            {label: 'Agent Type', key: 'agentTypeId', title: 'Agent Type', dataIndex: 'agentTypeId'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'},
        ]
    }

    static reverseAgentClassDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { id, name, agentTypeId, disabled } = details
            let detailFormData = {
                agentClassId: `${name.toLowerCase()}${id}`,
                agentTypeId: agentTypeId === 1 ? 'Agent' : agentTypeId === 2 ? 'Super Agent' : '',
                status: disabled === 0 ? 'Active' : disabled === 1 ? 'Inactive' : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}