import React from 'react'
import Select from '../../../../../../components/select'
import { splitNameWithUnderscore } from '../../../../../../utils/helper'

export default class RevenueSharingForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class={`form-group ${this.props.class ? this.props.class : 'col-lg-6'}`}>
                            <label>Name *:<br></br></label>
                            <input autoComplete="off" type="text" value={this.props.partyFormData ? this.props.partyFormData.name : ''} class="form-control" placeholder="Name" onChange={event => this.props.onChange('name', splitNameWithUnderscore(event.target.value))} disabled={this.props.disableName}/>
                        </div>

                        <div class={`form-group ${this.props.class ? this.props.class : 'col-lg-6'}`}>
                            <label>Account Type *:</label>
                            <Select 
                                options={this.props.accountTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                onChange={event => this.props.onChange('type', event.value)} 
                                disabled={this.props.disabled}
                                defaultValue={this.props.partyFormData.type}
                            />
                            {/* <select name="accountType" value={this.props.partyFormData ? this.props.partyFormData.type : ''} class="form-control" onChange={event => this.props.onChange('type', event.target.value)} disabled={this.props.disabled} >
                                <option selected disabled>Select</option>
                                {this.props.accountTypes.map(value => <option key={value.id} value={value.id} selected={this.props.partyFormData.type === value.id ? 'selected' : ''}>{value.name}</option>)}
                            </select> */}
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}