export default class ApplicantDetailsSerializer {
  static serialize (formData) {
    const { firstName, middleName, lastName, email, phone, 
      username, password, confirmPassword, state, lga, country,
      howYouHeardAboutUs, mothersMaidenName, gender, placeOfBirth, 
      identificationNumber, nextOfKin, closestLandmark, address, identificationType,
      dob, bvn } = formData

    let newFormData = {
      firstName: firstName,
      middleName: middleName,
      surname: lastName,
      emailAddress: email,
      address: address,
      phoneNumber: phone,
      username: username,
      uniquePwd: password,
      confirmPwd: confirmPassword,
      state: state,
      localGovernmentArea: lga,
      nationality: country,
      closestLandMark: closestLandmark,
      howYouHeardAboutUs: howYouHeardAboutUs,
      mothersMaidenName: mothersMaidenName,
      gender: gender,
      placeOfBirth: placeOfBirth,
      identificationNumber: identificationNumber,
      identificationType: identificationType,
      dob: dob,
      bvn: bvn,
    }

    if (Object.values(nextOfKin).length) {
      newFormData.nextOfKin = nextOfKin
    }

    return newFormData
  }

  static serializeForApplication (formData) {
    const { firstName, middleName, lastName, email, phone, 
      username, password, confirmPassword, state, lga, country,
      howYouHeardAboutUs, mothersMaidenName, gender, placeOfBirth, 
      identificationNumber, nextOfKin, closestLandmark, address,
      bvn, dob, identificationType } = formData

    let newFormData = {
      firstName: firstName,
      middleName: middleName,
      surname: lastName,
      emailAddress: email,
      address: address,
      phoneNumber: phone,
      username: username,
      uniquePwd: password,
      confirmPwd: confirmPassword,
      state: state,
      localGovernmentArea: lga,
      nationality: country,
      closestLandMark: closestLandmark,
      howYouHeardAboutUs: howYouHeardAboutUs,
      mothersMaidenName: mothersMaidenName,
      gender: gender,
      placeOfBirth: placeOfBirth,
      identificationNumber: identificationNumber,
      bvn: bvn,
      identificationType: identificationType,
      dob: dob,
    }

    if (Object.values(nextOfKin).length) {
      newFormData.nextOfKin = nextOfKin
    }

    return newFormData
  }

  static reverseSerializeFromApplication (formData) {
    let { firstName, middleName, surname, emailAddress, phoneNumber, 
      username, uniquePwd, confirmPwd, state, localGovernmentArea, nationality,
      howYouHeardAboutUs, mothersMaidenName, gender, placeOfBirth, 
      identificationNumber, nextOfKin, closestLandMark, address, bvn, 
      dob, identificationType } = formData

    let newFormData = {
      firstName: firstName,
      middleName: middleName,
      lastName: surname,
      email: emailAddress,
      address: address,
      phone: phoneNumber,
      username: username,
      password: uniquePwd,
      confirmPassword: confirmPwd,
      state: state,
      lga: localGovernmentArea,
      country: nationality,
      closestLandmark: closestLandMark,
      howYouHeardAboutUs: howYouHeardAboutUs,
      mothersMaidenName: mothersMaidenName,
      gender: gender,
      placeOfBirth: placeOfBirth,
      identificationNumber: identificationNumber,
      identificationType: identificationType,
      bvn: bvn,
      dob: dob,
      nextOfKin: nextOfKin || {}
    }

    return newFormData
  }
}
