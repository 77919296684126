import React from 'react'

export default class RegionForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        {/* <div class="form-group col-lg-6">
                            <label>Country *:</label>
                            <select autoComplete="off" class="form-control" data-live-search="true" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => {
                                this.props.onChange('countryId', event.target.value)
                                this.props.getStates(event.target.value)
                            }}>
                                <option selected disabled>Select</option>
                                {this.props.countries.map((value, index) => <option value={value.id} >{value.name}</option>)}
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>State *:</label>
                            <select autoComplete="off" class="form-control" data-live-search="true" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('stateId', event.target.value)}>
                                <option selected disabled>Select</option>
                                {this.props.states.map((value, index) => <option value={value.id} >{value.name}</option>)}
                            </select>
                        </div> */}
                        <div class="form-group col-lg-6">
                            <label>Name *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" defaultValue={this.props.regionFormData.regionName} placeholder="Lagos Mainland" disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('regionName', event.target.value)} />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Code *:<br></br></label>
                            <input autoComplete="off" type="text" class="form-control" defaultValue={this.props.regionFormData.regionCode} placeholder="LM" maxLength={2} disabled={this.props.disabled ? this.props.disabled : ''} onChange={event => this.props.onChange('regionCode', event.target.value)} />
                        </div>
                        {/* {this.props.showStatus && <div class="form-group col-lg-6">
                            <label>Status *:</label>
                            <select autoComplete="off" class="form-control" data-live-search="true" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('status', event.target.value)}>
                                <option value={0} selected={this.props.regionFormData.status === 0 ? 'selected' : ''}>Active</option>
                                <option value={1} selected={this.props.regionFormData.status === 1 ? 'selected' : ''}>Inactive</option>
                            </select>
                        </div>} */}
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}