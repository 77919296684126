import React from 'react'
import { phoneNumberFormatter, formatDate, formatBackEndDateTime } from '../../../../../utils/formatter'
import { getDateByType } from '../../../../../utils/helper'
import { DatePickerComponent } from '../../../../../components/date-picker';
import Select from '../../../../../components/select'

export default class DeviceSearch extends React.Component {
    state = {
        deviceSearchParameters: {
            channel: "All",
            deviceUuid: null,
            terminalId: null,
            serialId: null,
            active: "All", 
            username: null,
            fromDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("startDate") : null,
            toDate: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA) ? getDateByType("endDate") : null
        },
        channels: [
            {value: "All", label: "All"},
            {value: 'POS', label: 'POS'},
            {value: 'MOBILE', label: 'Mobile'},
            {value: 'WEB', label: 'Web'}
        ],
        statuses: [
            {value: "All",label: "All"},
            {value: 'true', label: 'Active'},
            {value: 'false', label: 'Inactive'}
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        console.log(this.state.deviceSearchParameters)
        this.props.handleSearchRequest(this.state.deviceSearchParameters)
    }
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Agent Phone number:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, username: event.target.value}})} />
                    </div>
                    <div class="col-md-2">
                        <label>Channel:</label>
                        <Select 
                            options={this.state.channels}
                            onChange={event => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, channel: event.value}})}
                            defaultValue={this.state.deviceSearchParameters.status}
                        />
                    </div>
                    <div class="col-md-2">
                        <label>Terminal ID:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, terminalId: event.target.value}})} />
                    </div>
                    <div class="col-md-2">
                        <label>Serial ID:</label>
                        <input class="search-input" type="text" name="search" onChange={event => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, serialId: event.target.value}})} />
                    </div>
                    <div class="col-md-2">
                        <label>Date From</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({deviceSearchParameters:{...this.state.deviceSearchParameters, fromDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label>Date To</label><br />
                        <DatePickerComponent
                            onChange={(event) => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, toDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.deviceSearchParameters.fromDate ? false : true}
                            minDate={new Date(this.state.deviceSearchParameters.fromDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={!JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        {/* <button type="submit" class="search-button" disabled={!this.state.deviceSearchParameters.fromDate || !this.state.deviceSearchParameters.toDate}>Search</button> */}
                        <button type="submit" class="search-button">Search</button>
                    </div>
                    {/* <div class="col-md-3">
                        <label>Status:</label>
                        <Select 
                            options={this.state.statuses}
                            onChange={event => this.setState({deviceSearchParameters: {...this.state.deviceSearchParameters, active: event.value}})}
                            defaultValue={this.state.deviceSearchParameters.status}
                        />
                    </div> */}
                </div>
            </form>
        </React.Fragment>
    }
}