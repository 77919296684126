import { convertToMajor } from '../../utils/converter';
import { formatCurrency, formatDateTime, formatLongNumber } from '../../utils/formatter';

export default class WalletPoolReportExportSerializer {
    static headerExport () {
        return [
            { label: 'Unique Reference', key: 'uniqueReference', title: 'Unique Reference', dataIndex: 'uniqueReference' },
            { label: 'Payment Reference', key: 'paymentReference', title: 'Payment Reference', dataIndex: 'paymentReference' },
            { label: 'Date', key: 'journalEntryDate', title: 'Date', dataIndex: 'journalEntryDate' },
            { label: 'Wallet Journal', key: 'walletJournalId', title: 'Wallet Journal', dataIndex: 'walletJournalId' },
            { label: 'Wallet', key: 'walletId', title: 'Wallet', dataIndex: 'walletId' },
            { label: 'Credit Amount', key: 'creditAmount', title: 'Credit Amount', dataIndex: 'creditAmount' },
            { label: 'Debit Amount', key: 'debitAmount', title: 'Debit Amount', dataIndex: 'debitAmount' },
            { label: 'Balance', key: 'balance', title: 'Balance', dataIndex: 'balance' },
            { label: 'Journal Description', key: 'journalDescription', title: 'Journal Description', dataIndex: 'journalDescription' },
            { label: 'Narration', key: 'narration', title: 'Narration', dataIndex: 'narration' }
        ];
    }

    static reverseWalletPoolReportDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
               uniqueReference, paymentReference, journalEntryDate, walletJournalId,
               walletId, creditAmount, debitAmount, balance, journalDescription, narration
            } = detail

            let detailFormData = {
                uniqueReference: uniqueReference ? formatLongNumber(uniqueReference) : '',
                paymentReference: paymentReference ? formatLongNumber(paymentReference) : '',
                creditAmount: creditAmount ? `₦${formatCurrency(convertToMajor(creditAmount))}` : `₦${formatCurrency(0)}`,
                debitAmount: debitAmount ? `₦${formatCurrency(convertToMajor(debitAmount))}` : `₦${formatCurrency(0)}`,
                balance: balance ? `₦${formatCurrency(convertToMajor(balance))}` : `₦${formatCurrency(0)}`,
                journalDescription,
                narration,
                walletJournalId: walletJournalId ? formatLongNumber(walletJournalId) : '',
                walletId: walletId ? formatLongNumber(walletId) : '',
                journalEntryDate: journalEntryDate ? formatDateTime(journalEntryDate) : ''
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}