import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CommissionSplitRuleGridScene from './scenes/commission-split-rule-grid';
import AddCommissionSplitRuleScene from './scenes/add-commission-split-rule';
import ViewCommissionSplitRuleScene from './scenes/view-commission-split-rule';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_COMMISSION_SPLIT_RULE, CAN_CREATE_COMMISSION_SPLIT_RULE } from '../../../../../constants/permissions.js'

export default class CommissionSplitRuleScene extends Component {
  sceneName = 'commission-split-rules'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]} exact path='/home/commission-split-rules' component={CommissionSplitRuleGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_COMMISSION_SPLIT_RULE]} exact path='/home/commission-split-rules/add' component={AddCommissionSplitRuleScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]} exact path='/home/commission-split-rules/:id/view' component={ViewCommissionSplitRuleScene} />
    </Router>
  }
}
