import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import SpinnerComponent from '../../../../../../../components/spinner'
import Pagination from '../../../../../../../components/pagination'
import { formatDate, formatDateTime } from '../../../../../../../utils/formatter'
import { getPaginationRows } from '../../../../../../../utils/helper'
import TableExtra from '../../../../../../../components/table-extra'
import { ServiceExportSerializer } from '../../../../../../../serializers'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class ServiceTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus'
}

  zoomTableHeight = this.zoomTableHeight.bind(this)
  getSerialNumber = this.getSerialNumber.bind(this)

  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '950px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }

  getSerialNumber (index) {
    return (this.props.pageSize * (this.props.page - 1)) + index
  }
    render() {
      const MyLoader = props => { return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div> 
          </div>
        )
      )};
        const columns = [
            {
                Header: "S/N",
                accessor: 'id',
                Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
                sortable: false,
                resizable: false,
                width: 60,
                headerStyle: {'white-space': 'unset'},
              //   getProps: (state, rowInfo, column) => {
              //     return {
              //         style: {
              //             textAlign: 'center',
              //             whiteSpace: 'unset',
              //         },
              //     };
              // },
            },{
                Header: "Name",
                accessor: 'name',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
            },{
              Header: "Display Name",
              accessor: 'displayName',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
            },{
                Header: "Settlement Interval",
              accessor: 'settlementInterval',
              resizable: false,
              width: 80,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              Cell: (props) =>  !props.value || props.value == 0 ? 1 : props.value
            },{
              Header: "Settlement Type",
              accessor: 'settlementType',
              resizable: false,
              width: 80,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              Cell: (props) => {
                return <span title={props.value == 1 ? "N settlement date" : "T+N settlement date"}>{props.value == 1 ? "N" : "T+N"}</span>
              }
            },
            {
                Header: "Status",
                accessor: 'status',
                resizable: false,
                width: 80,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                getProps: (state, rowInfo, column) => {
                  return {
                      style: {
                          textAlign: 'center'
                      },
                  };
              },
                Cell: props => <span style={{color: '#ffffff'}} class={`kt-badge kt-badge--inline ${props.value === 0 ? 'kt-badge--success' : props.value === 1 ? 'kt-badge--warning' : '' } kt-badge--pill`}>{props.value === 0 ? 'Active' : props.value === 1 ? 'Inactive' : ''}</span>,
            },{
                Header: "Date Created",
                accessor: 'createdOn',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 160,
                Cell: ({ row, original }) => {
                    return original.createdOn ? formatDateTime(original.createdOn) : ''                }
            },{
                Header: "Last Modified",
                accessor: 'lastUpdatedOn',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 160,
                Cell: ({ row, original }) => {
                    return original.lastUpdatedOn ? formatDateTime(original.lastUpdatedOn) : ''
                }
            },
            {
                Header: "Action",
                accessor: 'id',
                overflow: 'visible',
                resizable: false,
                sortable: false,
                headerStyle: {'white-space': 'unset'},
                getProps: (state, rowInfo, column) => {
                  return {
                      style: {
                          textAlign: 'center',
                          whiteSpace: 'unset',
                      },
                  };
              },
                width: 70,
                Cell: ({row, original}) => <ProtectedComponent requiredPermissions={[this.props.canView]}>
                  <button type="button" class="btn btn-xs link-button">
                    <Link to={`services/${original.id}/view`} class="link-button">
                      View
                    </Link>
                  </button>
                </ProtectedComponent>
                // Cell: ({row, original}) => <div class="dropdown">
                // <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                //   <i class="la la-ellipsis-v"></i>
                // </a>
                // <div class="dropdown-menu dropdown-menu-right">
                //   <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`services/${original.id}/view`} class="dropdown-item">
                //     <i class="la la-eye"></i>
                //     View
                //   </Link></ProtectedComponent>
                  /* <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateStatus(`enable`, row.id, row.name)} style={{color: '#a7abc3', cursor: 'pointer'}} hidden={original.status === 1 ? '' : 'hidden'} class="dropdown-item">
                    <i class="la la-toggle-on"></i>
                    Activate
                  </a>
                  <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateStatus(`disable`, row.id, row.name)} style={{color: '#a7abc3', cursor: 'pointer'}} hidden={original.status === 0 ? '' : 'hidden'} class="dropdown-item">
                    <i class="la la-ban"></i>
                    Deactivate
                  </a>
                  <a data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.updateStatus(`delete`, row.id, row.name)} style={{color: '#a7abc3', cursor: 'pointer'}} class="dropdown-item">
                    <i class="la la-trash"></i>
                    Delete
                  </a> */
              //   </div>
              // </div>
            }
          ]

        return <React.Fragment>
        <TableExtra 
            data={ServiceExportSerializer.reverseServiceDetails(this.props.data)}
            headers={ServiceExportSerializer.headerExport()}
            fileName={'services'}
            zoomTableHeight={this.zoomTableHeight}
            getData={this.props.getServices}
            zoom={this.state.zoom}
            page={this.props.page}
            pageSize={this.props.pageSize}
            disabled={this.props.loading}
          />
        <ReactTable 
        PaginationComponent={Pagination} 
        loading={this.props.loading}
        LoadingComponent={MyLoader}
        noDataText={!this.props.loading ? this.props.noDataText : ''}
        style={{
          border: "none",
          boxShadow: "none",
          whiteSpace: 'unset',
          height: this.state.tableHeight,
        }}
        data={this.props.data}
        dataCount={this.props.dataCount}
        pageSizes={getPaginationRows(this.props.dataCount)}
        columns={columns}
        manual
        pages={this.props.pages}
        onFetchData={(state, instance) => {
            this.props.getServices(state.page + 1,state.pageSize)
        }}
        headerNodeHeight={`123`}
        minRows={(this.props.data).length > 0 ? 10 : 10}
        getTableProps={(state, rowInfo, column, instance) => ({
          style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
          },
        })}
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            display:"flex",
            overflow: "visible",
            color: "black",
            fontSize: "11.5px",
            fontWeight: "50",
            paddingLeft:"5px",
            paddingRight:"5px",
            alignItems: "center",
            
          },
        })}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            backgroundColor: "#00425f",
            borderBottom: "0.1px solid #DBDEDE",
            borderWidth: "0.1px",
            padding:"10px 0",
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: "100",
            textAlign: "left",
            paddingLeft:"5px",
            paddingRight:"5px",
          },
        })}
        getPaginationProps={(state, rowInfo, column, instance) => ({
          style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
          },
        })}    
        className="-striped -highlight" 
        />
      </React.Fragment>
    }
}