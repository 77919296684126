import React from 'react';
import SpinnerComponent from '../spinner';


export function FormInput({
  className = "form-group col-lg-6", defaultValue, disabled, field, form,
  hasFormBeenSubmitted, isLoading, label, placeholder, showErrorIfAvailable,
  subMessage,
}) {
  const fieldHasBeenTouched = form.touched[field.name] === true;
  const fieldError = form.errors[field.name];

  const errorToDisplay = (
    hasFormBeenSubmitted || fieldHasBeenTouched || showErrorIfAvailable
  ) && !isLoading ? fieldError : null;

  const subMessageToShow = errorToDisplay || isLoading ?
    null : subMessage;

  return (
    <div class={className}>
      <label className={errorToDisplay ? 'form-error-label' : ''}>
        {label} *:
      </label>
      <input
        autoComplete="off"
        className={`form-control ${errorToDisplay ? 'form-error-input' : ''}`}
        disabled={disabled}
        defaultValue={defaultValue}
        type="text"
        class="form-control"
        placeholder={placeholder}
        {...field}
      />
      {errorToDisplay && <span className="form-text text-muted form-error-label">
        {errorToDisplay}
      </span>}
      {isLoading && <SpinnerComponent
        style={{
          bottom: '5px',
          height: '10px',
          left: 'auto',
          position: 'absolute',
          right: '10px',
          top: 'auto',
          width: '10px',
        }}
      />}
      {subMessageToShow && <span
        className="form-text text-muted"
        style={{textAlign: 'right', position: 'absolute', bottom: -18, right: 8}}
      >
        {subMessageToShow}
      </span>}
    </div>
  );
}

export function FormTextArea({
  defaultValue, disabled, field, form, hasFormBeenSubmitted, label,
  placeholder, numberOfLines=10,
}) {
  const fieldHasBeenTouched = form.touched[field.name] === true;
  const fieldError = form.errors[field.name];

  const errorToDisplay = (
    hasFormBeenSubmitted || fieldHasBeenTouched
  ) ? fieldError : null;

  return (
    <div class="form-group col-lg-6">
      <label className={errorToDisplay ? 'form-error-label' : ''}>
        {label} *:
      </label>
      <textarea
        rows={numberOfLines}
        autoComplete="off"
        className={`form-control ${errorToDisplay ? 'form-error-input' : ''}`}
        disabled={disabled}
        defaultValue={defaultValue}
        type="text"
        class="form-control"
        placeholder={placeholder}
        {...field}
      />
      {errorToDisplay && <span className="form-text text-muted form-error-label">
        {errorToDisplay}
      </span>}
    </div>
  );
}
