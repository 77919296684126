import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS
} from "../../../../../../../constants/api";
import { CAN_CREATE_FIP_USER } from "../../../../../../../constants/permissions.js";
import Countries from "../../../../../../../fixtures/countries.json";
import IDTYPES from "../../../../../../../fixtures/id-types.json";
import {
  platformManagement,
  userManagement
} from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  getLgasByStateId, getStatesByCountryId
} from "../../../../../../../utils/helper";
import FipForm from "../../components/fip-form";

export default class AddFipScene extends BaseScene {
  requiredPermissions = [CAN_CREATE_FIP_USER];

  canSubmit = false;

  constructor(props) {
    super(props);
    this.requiredFipDetailsFields = [
      "firstName",
      "lastName",
      "idType",
      "idNumber",
      "email",
      "mobileNo",
      "countryCode",
      "stateCode",
      "lgaCode",
      "regionCode",
      "zoneCode",
    ];
    this.state = {
      fipFormData: [],
      aggregators: [],
      states: [],
      lgas: [],
      regions: [],
      zones: [],
      isStateLoading: false,
      isLgaLoading: false,
      isZoneLoading: false,
    };
    this.updateFipFormData = this.updateFipFormData.bind(this);
    this.getStates = this.getStates.bind(this);
    this.updateSetState = this.updateSetState.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.getLgas = this.getLgas.bind(this);
    this.getZones = this.getZones.bind(this);
    this.validateFipByMobileNo = this.validateFipByMobileNo.bind(this);
    this.isPhoneNumberLengthValid = this.isPhoneNumberLengthValid.bind(this);
  }

  sceneName = "fip-management";

  componentDidMount() {
    this.getAggregatorList();
    this.getRegions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }

    const emptyRequiredFipDetailsFields = this.requiredFipDetailsFields.filter(
      (value) => {
        return Boolean(this.state.fipFormData[value]) === false;
      }
    );

    const newState = {
      canSubmit: this.canSubmit,
    };

    if (emptyRequiredFipDetailsFields.length === 1) {
      newState.canSubmit = true;
    }

    this.canSubmit = newState.canSubmit;
  }

  async getAggregatorList() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getSuperAgentList();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        aggregators: response,
        isLoading: false,
      });
    } else {
      this.setState({
        aggregators: [],
        isLoading: false,
      });
    }
  }

  async validateFipByMobileNo(mobileNo) {
    this.setState({
      isLoading: true,
      disabled: true,
      disabled2: true,
    });
    const responseObj = await userManagement.getUserByMobileNo(mobileNo);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      const { email, firstName, lastName } = response.data;
      this.setState({
        fipFormData: {
          ...this.state.fipFormData,
          email,
          mobileNo,
          firstName,
          lastName,
        },
        isLoading: false,
        disabled: false,
        disabled2: false,
        userFoundDisabled: true,
        emailFoundDisabled: email ? true : false,
      });
    } else {
      this.setState({
        fipFormData: {
          ...this.state.fipFormData,
          email: null,
          firstName: null,
          lastName: null,
        },
        isLoading: false,
        disabled: false,
        disabled2: false,
        userFoundDisabled: false,
        emailFoundDisabled: false,
      });
    }
  }

  getStates(countryId) {
    this.setState({ isStateLoading: true });
    this.setState({
      states: getStatesByCountryId(countryId),
      isStateLoading: false,
    });
  }

  async getLgas(stateId) {
    this.setState({ isLgaLoading: true });
    this.setState({
      lgas: getLgasByStateId(stateId),
      isLgaLoading: false,
    });
  }

  async getRegions() {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getRegions();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        regions: response.content,
        isLoading: false,
      });
    } else {
      this.setState({
        regions: [],
        isLoading: false,
      });
    }
  }

  async getZones(lgaCode) {
    this.setState({
      isZoneLoading: true,
      zones: [],
    });
    const responseObj = await platformManagement.getLgaZones(lgaCode);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        zones: response,
        isZoneLoading: false,
      });
    } else {
      this.setState({
        zones: [],
        isZoneLoading: false,
      });
    }
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await userManagement.createFip(this.state.fipFormData);
    const status = responseObj.status;
    const response = responseObj.response;

    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: "FIP created successfully",
        redirect: true,
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  updateFipFormData(type, value) {
    this.setState({
      fipFormData: { ...this.state.fipFormData, [type]: value },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  isPhoneNumberLengthValid(phoneNumber) {
    if (phoneNumber.startsWith("0") && phoneNumber.length === 11) {
      return true;
    } else if (phoneNumber.startsWith("234") && phoneNumber.length === 13) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home/fip-management" />;
    }

    const ctaWidgets = (
      <React.Fragment>
        <button
          type="button"
          disabled={!this.canSubmit}
          class="btn btn-secondary"
          style={{ backgroundColor: "#00425f", color: "#ffffff" }}
          onClick={this.onSaveButtonClick}
        >
          Save
        </button>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"FIP Management"}
          subtitle={"Create New FIP"}
          filterPermissions={[CAN_CREATE_FIP_USER]}
          totalCount={56}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_CREATE_FIP_USER]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <FipForm
              aggregators={this.state.aggregators}
              idTypes={IDTYPES}
              countries={Countries}
              states={this.state.states}
              lgas={this.state.lgas}
              fipFormData={this.state.fipFormData}
              onChange={this.updateFipFormData}
              updateSetState={this.updateSetState}
              getStates={this.getStates}
              getLgas={this.getLgas}
              regions={this.state.regions}
              zones={this.state.zones}
              getZones={this.getZones}
              showPhoneNumber={true}
              isStateLoading={this.state.isStateLoading}
              isLgaLoading={this.state.isLgaLoading}
              isZoneLoading={this.state.isZoneLoading}
              validateUser={this.validateFipByMobileNo}
              disabled={this.state.disabled}
              disabled2={this.state.disabled2}
              userFoundDisabled={this.state.userFoundDisabled}
              validatePhoneNumber={this.isPhoneNumberLengthValid}
              emailFoundDisabled={this.state.emailFoundDisabled}
              //isPhoneNumberLengthValid={this.isPhoneNumberLengthValid}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
