import React from 'react'
import WorkflowApprovers from './workflow-approvers'
import WorkflowStepDetails from './workflow-steps-details'
import ProtectedComponent from '../../.../../../../../../mixins/protected-component';

export default class WorkflowSteps extends React.Component {
    render() {
        return <React.Fragment>
            <ProtectedComponent requiredPermissions={[this.props.canAddStep]}><button type="button" class="btn btn-primary btn-sm" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} data-toggle="modal" data-target="#kt_modal_1" onClick={() => this.props.getAddStepDetails()}>New Step</button></ProtectedComponent>
            <div class="kt-scroll" data-scroll="true" style={{height: "auto"}} /*data-mobile-height="350"*/>
                <div class="kt-timeline">
                    <div class="kt-timeline__item kt-timeline__item--success" style={{marginBottom: "50px"}}>
                        {this.props.workflowDetails.steps.map(step => {
                            return (
                                <div>
                                    <WorkflowStepDetails 
                                        stepId={step.id}
                                        getAddApproverDetails={this.props.getAddApproverDetails}
                                        getMoveStepDetails={this.props.getMoveStepDetails}
                                        getRemoveStepDetails={this.props.getRemoveStepDetails}
                                        canAddStep={this.props.canAddStep}
                                        canMoveStep={this.props.canMoveStep}
                                        canRemoveStep={this.props.canRemoveStep}
                                    />
                                    <WorkflowApprovers 
                                        stepId={step.id}
                                        approvers={step.approvers}
                                        getRemoveApproverDetails={this.props.getRemoveApproverDetails}
                                        canAddStep={this.props.canAddStep}
                                    />

                                </div>
                            )
                        })}
                        
                    </div>  
                </div>
            </div>
        </React.Fragment>
    }
}