import React from "react";
import "react-table/react-table.css";


import Notification from "../../../../../../components/notification";
import StockSplits from "./stock-splits";

export default class UploadStockDetailsModal extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
    successMessage2: "",
    errorMessage2: "",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }
  render() {
   
   

   

    return (
      <React.Fragment>
        <div
          class="modal"
          id="upload_stock_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            style={{
              overflowY: "scroll",
              overflowX: "auto",
              fontSize: "14px",
              maxWidth: "900px",
              borderRadius:"15px"
            }}
          >
            <div class="modal-content" style={{ borderRadius: "15px", height: "750px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Uploaded Stock Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Stock Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body" style={{ overflowX: "scroll", overflowY: "scroll", height:"540px" }}>
                    <React.Fragment>
                      <Notification
                        successMessage={this.props.successMessage2}
                        errorMessage={this.props.errorMessage2}
                      />
                      <StockSplits stockSplits={this.props.data} />
                      {console.log("MODALLLLLLLL", this.props.data)}
                    </React.Fragment>
                  
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
