import React from 'react'
import { formatDateTime } from '../../../../../../utils/formatter'

export default class ApprovalDate extends React.Component {
    render() {
        return <React.Fragment>
            <h5>Dates</h5>
            <div class="form-group row">
                <label for="staticEmail" class="col-sm-2">Created:</label>
                <div class="col-sm-10">
                    <span>{this.props.approvalDetails.created ? formatDateTime(this.props.approvalDetails.created) : ''}</span>
                </div>
            </div>
            <div class="form-group row">
                <label for="staticEmail" class="col-sm-2">Updated:</label>
                <div class="col-sm-10">
                    <span>{this.props.approvalDetails.created ? formatDateTime(this.props.approvalDetails.created) : ''}</span>
                </div>
            </div>
        </React.Fragment>
    }
}