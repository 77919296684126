import { formatDateTime, formatLongNumber } from '../../utils/formatter';

export default class ServiceExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Display Name', key: 'displayName', title: 'Display Name', dataIndex: 'displayName'},
            {label: 'Settlement Interval', key: 'settlementInterval', title: 'Settlement Interval', dataIndex: 'settlementInterval'},
            {label: 'Status', key: 'status', title: 'Status', dataIndex: 'status'},
            {label: 'Date Created', key: 'createdOn', title: 'Date Created', dataIndex: 'createdOn'},
            {label: 'Date Last Updated', key: 'lastUpdatedOn', title: 'Date Last Updated', dataIndex: 'lastUpdatedOn'},
        ]
    }

    static reverseServiceDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, displayName, settlementInterval, status, createdOn, lastUpdatedOn } = details
            let detailFormData = {
                name,
                displayName,
                settlementInterval,
                status: status === 0 ? 'Active' : status === 1 ?  'Inactive' : '',
                createdOn: createdOn ? formatDateTime(createdOn) : '',
                lastUpdatedOn: lastUpdatedOn ? formatDateTime(lastUpdatedOn) : ''
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}