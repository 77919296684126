import { UPDATE_CURRENT_PATHNAME, UPDATE_SCENE } from '../../../constants/action-types/navigation';

const initialState = {
  scene: 'dashboard',
  pathName: '',
  history: []
};

export default function navigationReducer(state = initialState, action) {
  switch(action.type) {
    case UPDATE_CURRENT_PATHNAME: 
      return {
        ...state,
        pathName: action.pathName,
        history: [...state.history, action.pathName]
      }

    case UPDATE_SCENE:
      return {
        ...state,
        scene: action.scene
      }

    default: 
      return state;
  }
};
