import { connect } from 'react-redux'
import ForgotPasswordScene from './scene';

function mapStateToProps(state) {
  return {
    // articles: state.session.articles
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScene);
