import React from "react";
import { AccessDenied } from "../../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../../components/layouts/grid-layout";

import Loader from "../../../../../../../../components/loader";
import Notification from "../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../../constants/api";
import { CAN_REMAP_DEVICE } from "../../../../../../../../constants/permissions.js";
import {
  platformManagement,
  userManagement,
} from "../../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";
import { phoneNumberFormatter } from "../../../../../../../../utils/formatter";

export default class RemapDeviceScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      deviceDetails: [],
      agentMobileNo: null,
      isAgentSearchLoading: false,
      agents: [],
      isLoading: false,
      showSearchInfo: false,
    };
    this.getDeviceById = this.getDeviceById.bind(this);
    this.getAgents = this.getAgents.bind(this);
  }

  sceneName = "pos-remap";

  // componentDidMount() {
  //   this.deviceId = this.props.match.params.id;
  //   console.log("Device id is ", this.deviceId);
  //   this.getDeviceById();
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getDeviceById() {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await userManagement.getDeviceById(this.deviceId);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        deviceDetails: response.data,
        isLoading: false,
      });
    } else {
      this.setState({
        deviceDetails: [],
        isLoading: false,
      });
    }
  }

  async getAgents() {
    this.setState({
      isAgentSearchLoading: true,
      showSearchInfo: false,
    });
    const payload = {
      agentType: 4,
      agentMobileNo: phoneNumberFormatter(this.state.agentMobileNo),
    };
    const responseObj = await platformManagement.searchAgents(1, 20, payload);
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agents: this.getActiveAgents(response),
        agentCount: this.getActiveAgents(response).length,
        isAgentSearchLoading: false,
      });
    } else {
      this.setState({
        agents: [],
        agentCount: 0,
        isAgentSearchLoading: false,
        showSearchInfo: true,
      });
    }
  }

  async remapDevice(agentMobileNo) {
    this.setState({ isLoading: true });
    const payload = {
      currentAgentUsername: this.state.deviceDetails.username,
      deviceId: this.state.deviceDetails.id,
      newAgentUsername: agentMobileNo,
    };
    const responseObj = await userManagement.remapDevice(payload);
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: "Device remapped successfully",
        errorMessage: null,
        isLoading: false,
      });
    } else {
      this.setState({
        successMessage: null,
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  getActiveAgents(agents) {
    return agents.filter(function (value) {
      return value.statusId == 2;
    });
  }

  render() {
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        {/* <ContentHead
          title={"POS Management"}
          subtitle={"Remap Device"}
          filterPermissions={[CAN_REMAP_DEVICE]}
          totalCount={56}
        /> */}
        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <div class="kt-portlet">
              <form class="kt-form kt-form--label-right">
                <div class="kt-portlet__body">
                  <div class="row">
                    <div class="col-lg-6">
                      <h5>Device Information</h5>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Device Name</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.device_name}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Channel</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.channel}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Device Uuid</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.device_uuid}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Device OS</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.device_os}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Device Model</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.device_model}</span>
                        </div>
                      </div>
                      <h5>Agent Information</h5>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Business Name</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.businessName}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Business Phone Number</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.businessMobile}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Agent Name</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>
                            {this.state.deviceDetails.firstName}{" "}
                            {this.state.deviceDetails.lastName}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-4">
                          <label>
                            <strong>Email</strong>
                          </label>
                        </div>
                        <div class="col-lg-5">
                          <span>{this.state.deviceDetails.email}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <h5>Agent To Map</h5>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <input
                            type="search"
                            class="form-control"
                            autoComplete="off"
                            placeholder="Enter agent phone number"
                            onChange={(event) =>
                              this.setState({
                                agentMobileNo: event.target.value,
                              })
                            }
                          />
                        </div>
                        <div class="col-lg-2">
                          <button
                            disabled={
                              !this.state.agentMobileNo ||
                              this.state.isAgentSearchLoading
                            }
                            type="button"
                            class="btn isw-button"
                            onClick={this.getAgents}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      {this.state.isAgentSearchLoading && (
                        <div class="form-group col-lg-6">
                          <span class="loading">Loading</span>
                        </div>
                      )}
                      {this.state.showSearchInfo &&
                        this.state.agents.length === 0 && <p>No agent found</p>}
                      {!this.state.isAgentSearchLoading && (
                        <div>
                          {this.state.agents.map((value) => (
                            <div class="form-group col-lg-6">
                              {/* {this.state.showSearchInfo && <p>{this.state.getResultDisplayMessage(this.state.agentCount)}</p>} */}
                              <span>
                                <strong>{value.businessName}</strong>
                              </span>
                              <br />
                              <span>{value.agentMobileNo}</span>
                              <button
                                disabled={this.state.isLoading}
                                type="button"
                                class="isw-button pull-right"
                                onClick={() =>
                                  this.remapDevice(value.agentMobileNo)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Remap Device
                              </button>
                              <br />
                              <span>{`${value.agentClass} Agent Class`}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
