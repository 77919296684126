import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../constants/api";
import {
  CAN_UPDATE_AGENT_CLASS, CAN_VIEW_AGENT_CLASS
} from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  convertToMajor,
  convertToMinor
} from "../../../../../../../utils/converter";
import AgentClassForm from "../../components/agent-class-form";

export default class ViewAgentClassScene extends BaseScene {
  sceneName = "agent-class-view";

  canSubmit = true;

  constructor(props) {
    super(props);
    this.state = {
      agentClassDetails: [],
      agentClassFormData: [],
      disabled: true,
      canSave: false,
      canUpdate: false,
      agentTypes: [
        { id: 1, name: "Agent" },
        { id: 2, name: "Super Agent" },
      ],
      agentStatuses: [
        { id: 0, name: "Active" },
        { id: 1, name: "Inactive" },
      ],
      pageTitle: "View Agent Class",
    };

    this.requiredAgentClassFormDetailsFields = ["name", "code"];

    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.onUpdateAgentClassFormData = this.onUpdateAgentClassFormData.bind(
      this
    );
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
  }

  componentDidMount() {
    this.agentClassId = this.props.match.params.id;
    this.getAgentClassDetails(this.agentClassId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }

    const emptyRequiredAgentClassFormDetailsFields = this.requiredAgentClassFormDetailsFields.filter(
      (value) => {
        return Boolean(this.state.agentClassFormData[value]) === false;
      }
    );

    let canSubmit = false;

    if (emptyRequiredAgentClassFormDetailsFields.length === 0) {
      canSubmit = true;
    }

    this.canSubmit = canSubmit;
  }

  async getAgentClassDetails(agentClassId) {
    this.setState({ isLoading: true });
    const responseObj = await platformManagement.getAgentClass(agentClassId);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentClassDetails: response,
        agentClassFormData: {
          ...response,
          accruedDailyDeposit: !response.accruedDailyDeposit
            ? ""
            : convertToMajor(response.accruedDailyDeposit),
          dailyDepositLimit: !response.dailyDepositLimit
            ? ""
            : convertToMajor(response.dailyDepositLimit),
          ledgerBalanceLimit: !response.ledgerBalanceLimit
            ? ""
            : convertToMajor(response.ledgerBalanceLimit),
          dailyTransCountLimit: response.dailyTransCountLimit || null,
          dailyWithdrawalLimit: !response.dailyWithdrawalLimit
            ? ""
            : convertToMajor(response.dailyWithdrawalLimit),
          singleDepositLimit: !response.singleDepositLimit
            ? ""
            : convertToMajor(response.singleDepositLimit),
          singleWithdrawalLimit: !response.singleWithdrawalLimit
            ? ""
            : convertToMajor(response.singleWithdrawalLimit),
        },
        isLoading: false,
        canUpdate: true,
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  async onSubmitButtonClick(formData) {
    this.setState({ isLoading: true });

    const newFormData = {
      ...formData,
      dailyDepositLimit: convertToMinor(formData.dailyDepositLimit),
      dailyWithdrawalLimit: convertToMinor(formData.dailyWithdrawalLimit),
      singleDepositLimit: convertToMinor(formData.singleDepositLimit),
      singleWithdrawalLimit: convertToMinor(formData.singleWithdrawalLimit),
      ledgerBalanceLimit: convertToMinor(formData.ledgerBalanceLimit),
      agentTypeId: 1,
      disabled: 0,
    };

    const responseObj = await platformManagement.updateAgentClass(
      this.agentClassId,
      newFormData
    );
    const { status, response } = responseObj;

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your changes to this agent class has been sent successfully for approval.",
        });
      } else {
        this.setState({
          isLoading: false,
          successMessage: "Agent class updated successfully",
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  onUpdateAgentClassFormData(type, value) {
    this.setState({
      agentClassFormData: { ...this.state.agentClassFormData, [type]: value },
    });
  }

  async onUpdateButtonClick() {
    this.setState({
      canSave: true,
      canUpdate: false,
      disabled: false,
      pageTitle: "Edit Agent Class",
    });
  }

  async onCancelButtonClick() {
    this.setState({
      canSave: false,
      canUpdate: true,
      disabled: true,
      pageTitle: "View Agent Class",
    });
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_CLASS]}>
            <button
              type="button"
              class="btn btn-info"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onUpdateButtonClick}
            >
              Edit
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_CLASS]}>
            <button
              type="button"
              class="btn btn-secondary btn-bold btn-sm btn-icon-h"
              id="kt_subheader_group_actions_fetch"
              onClick={this.onCancelButtonClick}
            >
              Cancel
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_CLASS]}>
            <button
              type="button"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              class="btn btn-primary"
              onClick={this.form.submitForm}
            >
              Save Changes
            </button>
          </ProtectedComponent>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Agent Classes"}
          withoutSearch
          subtitle={this.state.pageTitle}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_AGENT_CLASS]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <AgentClassForm
              innerRef={(form) => (this.form = form)}
              onSubmit={this.onSubmitButtonClick}
              // onChange={this.onUpdateAgentClassFormData}
              disabled={this.state.disabled}
              // agentClassDetails={this.state.agentClassDetails}
              agentClassFormData={this.state.agentClassFormData}
              showBlock={true}
              agentStatuses={this.state.agentStatuses}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
