import React from 'react';
import { formatDate } from '../../utils/formatter';

export const OtherDetails = (props) => {
    const {
        hideTitleHeader, disabled, showContent,
        bvnVerificationStatus,
        applicantDetails: {
            createdBy, dateApplied, dateCreated, applicationValidatedBy, validatedBy, dateApplicationValidated,
            dateValidated, applicationApprovedBy, approvedBy, dateApplicationApproved, dateApproved, lastModifiedBy,
            dateLastUpdated, comment, statusId,
        },
        agentData,
    } = props;
    var commentMessage = ""
    commentMessage = comment && comment;
    var bvnStatusMessage = bvnVerificationStatus !== undefined ?
        bvnVerificationStatus === "SUSPENDED" ||
            bvnVerificationStatus === "PENDING_VERIFICATION_SUSPENDED" ?
            "invalid BVN" : ''
        : '';
    var commentWithBvnStatus = ""
    if (commentMessage != "") {
        commentWithBvnStatus += commentMessage

        if (bvnStatusMessage != "")
            commentWithBvnStatus += " and " + bvnStatusMessage;

    }
    else if (bvnStatusMessage != "")
        commentWithBvnStatus += bvnStatusMessage;



    return <>
        <div class="kt-portlet">
            {!hideTitleHeader && <div class="kt-portlet__head" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseOne7" aria-controls="collapseOne7">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Other Details
                    </h3>
                </div>
            </div>}

            <form id="collapseOne7" class={`collapse ${showContent ? 'show' : ''}`}>
                <div class="kt-portlet__body">
                    {createdBy && <div>
                        <h6>Created By</h6>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Name:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={createdBy} class="form-control" />
                            </div>
                            <div class="col-lg-4">
                                <label>Date:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={formatDate(dateApplied ? dateApplied : dateCreated)} class="form-control" />
                            </div>
                        </div>
                    </div>}
                    {(applicationValidatedBy || validatedBy) && <div>
                        <h6>Validated By</h6>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Name:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={applicationValidatedBy || validatedBy} class="form-control" />
                            </div>
                            <div class="col-lg-4">
                                <label>Date:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={formatDate(dateApplicationValidated || dateValidated)} class="form-control" />
                            </div>
                        </div>
                    </div>}
                    {(applicationApprovedBy || approvedBy) && <div>
                        <h6>Approved By</h6>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Name:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={applicationApprovedBy || approvedBy} class="form-control" />
                            </div>
                            <div class="col-lg-4">
                                <label>Date:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={formatDate(dateApplicationApproved || dateApproved)} class="form-control" />
                            </div>
                        </div>
                    </div>}
                    {lastModifiedBy && <div>
                        <h6>Last Modified By</h6>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <label>Name:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={createdBy} class="form-control" />
                            </div>
                            <div class="col-lg-4">
                                <label>Date:</label>
                                <input autoComplete="off" type="text" disabled={disabled} defaultValue={formatDate(dateLastUpdated)} class="form-control" />
                            </div>
                        </div>
                    </div>}
                    {commentWithBvnStatus != 'undefined' && <div>
                        <h6>{`Reason for Agent ${statusId == 3 ? 'Suspension' : statusId == 4 ? 'Retirement' : ''}`}</h6>
                        <div class="form-group row">
                            <div class="col-lg-8">
                                <span>{commentWithBvnStatus}</span>
                            </div>
                        </div>
                    </div>
                    }
                    {(applicationValidatedBy || validatedBy) && (
                        <>
                            <h6>Onboarded Route</h6>
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        disabled={disabled}
                                        defaultValue={
                                            props.applicantDetails && props.applicantDetails.onboardingRoute
                                                ? props.applicantDetails.onboardingRoute
                                                : props.agentData && props.agentData.onboardedRoute
                                                    ? props.agentData.onboardedRoute
                                                    : ""
                                        }
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </div>
    </>
}