import { connect } from "react-redux";
import LandingScene from "./scene";

function mapStateToProps() {
  return {
    // xyz: state.item.xyz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // xyz: param => dispatch(action(param))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingScene)
