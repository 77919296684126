import React from 'react';
import ContentHead from '../../../../../../components/content-head';
import Loader from '../../../../../../components/loader';
import Notification from '../../../../../../components/notification';
import BaseScene from '../../../../../../components/scenes/component';
import { SUCCESS_STATUS } from '../../../../../../constants/api';
import { platformManagement, userManagement } from '../../../../../../mixins/api';
import ChangePassword from '../../components/change-password';
import PersonalInformation from '../../components/personal-information';
import ProfileView from '../../components/profile-view';

export default class PersonalInformationGridScene extends BaseScene {
  constructor(props) {
    super(props);
    this.state = {
      userInformation: [],
      businessInformation: [],
    }
    this.changePassword = this.changePassword.bind(this)
  }

  componentDidMount () {
    this.getUserInfo()
    this.getDomainInfo()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getUserInfo () {
    const responseObj = await platformManagement.getUserInformation()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        userInformation: response
      })
    }else{
      this.setState({
        userInformation: [],
      })
    }
  }

  async getDomainInfo () {
    const responseObj = await platformManagement.getDomains()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        businessInformation: response[0]
      })
    }else{
      this.setState({
        businessInformation: []
      })
    }
  }

  async changePassword (changePasswordFormData) {
    this.setState({isLoading: true})
    const responseObj = await userManagement.changeUserPassword(changePasswordFormData)
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: 'Password Changed successfully',
      })
    }else {
      this.setState({
        isLoading: false,
        errorMessage: response
      })
    }
  }

  render () {

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification 
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Profile"} subtitle={"Personal Information"} totalCount={56} />
      <ProfileView>
        <div class="col-md-6">
          <PersonalInformation 
            userInformation={this.state.userInformation}
            businessInformation={this.state.businessInformation}
          />
        </div>
        <div class="col-md-6">
          <ChangePassword 
            changePassword={this.changePassword}
          />
        </div>
      </ProfileView>
    </React.Fragment>
  }
}
