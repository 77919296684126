import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { SettlementExportSerializer } from '../../../../../../serializers';
import { convertToMajor } from '../../../../../../utils/converter';
import { formatCurrency, formatDateTime, formatSettlementStatusColorCode } from '../../../../../../utils/formatter';
import { canRequerySettlement, getPaginationRows, getTransactionTypeLabel } from '../../../../../../utils/helper';

export default class SetlementReportTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus'
  }

zoomTableHeight = this.zoomTableHeight.bind(this)

zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
}
    render() {
      const MyLoader = props => { return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div> 
          </div>
        )
      )};
        const columns = [
            {
              Header: "Agent ID",
              accessor: 'parties',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 100,
              Cell: props => this.props.getAgentId(props.value)
            },{
              Header: "Trans Ref",
              accessor: 'transactionRef',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 170,
            },{
              Header: "Transaction Date",
              accessor: 'dateTimeProcessed',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 200,
              Cell: props => {
                return props.value ? formatDateTime(props.value) : ''
              }
            },{
              Header: "Transaction Type",
              accessor: 'transactionType',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              Cell: props => {
                return props.value ? getTransactionTypeLabel(props.value) : ''
              }
            },{
              Header: "Trans Amount",
              accessor: 'transactionAmount',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
            },{
              Header: "Fee Amount",
              accessor: 'transactionSurcharge',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
            },
            {
              Header: "Status",
              accessor: 'transactionSettlementStatus',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 80,
              Cell: props => {
                return props.value ? <span class={`badge ${formatSettlementStatusColorCode(props.value)}`}>{props.value}</span> : ''
              }
            },{
              Header: "Settlement Date",
              accessor: 'settlementDate',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              width: 200,
              Cell: props => {
                return props.value ? formatDateTime(props.value) : ''
              }
            },
            {
              Header: "Action",
              accessor: 'refNo',
              overflow: 'visible',
              resizable: false,
              sortable: false,
              headerStyle: {'white-space': 'unset'},
              getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        lineHeight: '20px',
                        textAlign: 'center',
                        whiteSpace: 'unset',
                    },
                };
            },
              width: 60,
              Cell: ({row, original}) => <div class="dropdown">
                <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                  <i class="la la-ellipsis-v"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`settlement-report/${row.transactionRef}/view`} class="dropdown-item">
                    <i class="la la-eye"></i>
                    View
                  </Link></ProtectedComponent>
                  <ProtectedComponent requiredPermissions={[this.props.canView]}><a hidden={canRequerySettlement(original.dateTimeProcessed) || original.transactionSettlementStatus.toLowerCase() == 'settled'} /*hidden={original.transactionSettlementStatus.toLowerCase() == 'settled'}*/ onClick={() => this.props.requeryPendingSettlement(row.transactionRef)} class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                    <i class="la la-refresh"></i>
                    Re-query
                  </a></ProtectedComponent>
                </div>
              </div>
            }
          ]

        return <React.Fragment> 
        <TableExtra 
          data={SettlementExportSerializer.reverseSettlmentDetails(this.props.data)}
          headers={SettlementExportSerializer.headerExport()}
          fileName={'settlements'}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getSettlementReport}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          // showSettlementRepush={true}
          // requeryAllPendingSettlement={this.props.requeryAllPendingSettlement}
          // updateSetState={this.props.updateSetState}
          disabled={this.props.loading}
        /> 
        <ReactTable 
          PaginationComponent={Pagination} 
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ''}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: 'unset',
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
          onFetchData={(state, instance) => {
            this.setState({page: state.page})
            if(this.props.canLoadData){
              this.props.getSettlementReport(state.page + 1, state.pageSize)
            } 
          }}
          headerNodeHeight={`123`}
          minRows={(this.props.data).length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display:"flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft:"5px",
              paddingRight:"5px",
              alignItems: "center",
              padding:  "0px, 0",
              
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"5px",
              paddingRight:"5px",
            },
          })}
          className="-striped -highlight" 
        />
      </React.Fragment>
    }
}