import React from 'react';
import { AccessDenied } from '../../../../../../../components/access-denied';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import Loader from '../../../../../../../components/loader';
import Notification from '../../../../../../../components/notification';
import BaseScene from '../../../../../../../components/scenes/component';
import { ERROR_STATUS, SUCCESS_STATUS } from '../../../../../../../constants/api';
import { CAN_UPDATE_AGENT_HIERARCHY_PERMISSION, CAN_VIEW_AGENT_HIERARCHY_PERMISSION } from '../../../../../../../constants/permissions';
import { userManagement } from '../../../../../../../mixins/api';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { explodeString } from '../../../../../../../utils/helper';
import AgentPermissions from '../../components/agent-permissions';

export default class AgentHierarchyPermissionScene extends BaseScene {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			successMessage: null,
      errorMessage: null,
      permissions: [],
      domainTypePermissions: [],
      defaultSelectedPermissions: [],
      disabled: true,
      canSave: false,
      canUpdate: false,
    }
      this.updateDomainTypePermissions = this.updateDomainTypePermissions.bind(this)
      this.onSaveButtonClick = this.onSaveButtonClick.bind(this)
      this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
      this.onCancelButtonClick = this.onCancelButtonClick.bind(this)
    }
      
    componentDidMount () {
        this.domainType = this.props.match.params.id
        this.getAllPermissions()
        //this.getDomainTypePermissions()
    }

    async getAllPermissions() {
      this.setState({isLoading: true})
      const responseObj = await userManagement.getAllPermissions()
      const { status, response } = responseObj
      if (status === SUCCESS_STATUS) {
        this.getDomainTypePermissions();
        this.setState({
          permissions: response,
        })
      }else{
        this.setState({
          permissions: [],
          errorMessage: response,
          isLoading: false,
        })
      }
    }

    async getDomainTypePermissions () {
      this.setState({isLoading: true})
      const responseObj = await userManagement.getDomainTypePermissions(this.getDomainTypeId(this.domainType))
      const { status, response } = responseObj
      if (status === SUCCESS_STATUS) {
        this.setState({
          domainTypePermissions: this.getPermissionName(response),
          defaultSelectedPermissions: this.getPermissionName(response),
          isLoading: false,
          canUpdate: true
        })
      }else{
        this.setState({
          domainTypePermissions: [],
          errorMessage: response,
          isLoading: false,
        })
      }
    }

    splitString (string) {
        return string.split("-");
    }

    getDomainTypeId (domainType) {
        const domainTypeName = `${this.splitString(domainType)[0]}${this.splitString(domainType)[1] ? this.splitString(domainType)[1] : ''}`
        const domainTypes = {"platformprovider": 1, "superagent": 2, "aggregator": 3, "agent": 4}
        return domainTypes[domainTypeName]
    }

    getPermissionName (permissions) {
        let permissionArray = [];
        permissions.map(details => permissionArray.push(details.id))
    
        return permissionArray;
    }

    updateDomainTypePermissions (type, value) {
        this.setState({[type]: value})
    }

    isFormValidForUpdate () {
        const { domainTypePermissions, defaultSelectedPermissions } = this.state
        if(domainTypePermissions && defaultSelectedPermissions) {
          const addedPermissions = domainTypePermissions.filter(function(v) {
            return !defaultSelectedPermissions.includes(v)
          })
      
          const removedPermissions = defaultSelectedPermissions.filter(function(v) {
            return !domainTypePermissions.includes(v)
          })
      
          return Boolean(!addedPermissions || !removedPermissions)
        }
        return true
    }

    async onUpdateButtonClick () {
        this.setState({
          canUpdate: false,
          canSave: true,
          pageTitle: 'Update Permissions',
          disabled: false,
        })
      }
      async onCancelButtonClick () {
        this.setState({
          canUpdate: true,
          canSave: false,
          pageTitle: 'View Permissions',
          disabled: true,
        })
      }
    
      async onSaveButtonClick () {
        this.setState({isLoading: true})
        const { domainTypePermissions, defaultSelectedPermissions } = this.state
        
        const addedPermissions = domainTypePermissions.filter(function(v) {
          return !defaultSelectedPermissions.includes(v)
        })
      
        const removedPermissions = defaultSelectedPermissions.filter(function(v) {
          return !domainTypePermissions.includes(v)
        })

        if(addedPermissions && addedPermissions.length > 0) {
          const createRoleResponseObj = await userManagement.addDomainPermission(this.getDomainTypeId(this.domainType), {permissions: this.toJSON(addedPermissions)})
    
          const createRoleResponseStatus = createRoleResponseObj.status
          const createRoleResponse = createRoleResponseObj.response.data
    
          if (createRoleResponseStatus === ERROR_STATUS) {
            this.setState({
              errorMessage: createRoleResponse,
              isLoading: false
            })
          }else{
            this.setState({
              isLoading: false,
              successMessage: 'Permission(s) updated successfully',
              canSave: false,
              canUpdate: true,
              disabled: true,
            })
          }
        }
        
        if(removedPermissions && removedPermissions.length > 0) {
          const removeRoleResponseObj = await userManagement.deleteDomainPermission(this.getDomainTypeId(this.domainType), {permissions: this.toJSON(removedPermissions)})
    
          const removeRoleResponseStatus = removeRoleResponseObj.status
          const removeRoleResponse = removeRoleResponseObj.response.data
          if (removeRoleResponseStatus === ERROR_STATUS) {
            this.setState({
              errorMessage: removeRoleResponse,
              isLoading: false
            })
          }else{
            this.setState({
              isLoading: false,
              successMessage: 'Permission(s) updated successfully',
              canSave: false,
              canUpdate: true,
              disabled: true,
            })
          }
        }
    
        //window.scrollTo(0, this.metadataSectionRef.current)
    
      }

    toJSON(array) {
        let newArray = [];
        array.map(value => 
          newArray.push(value)
        )
    
        return newArray;
    }

    

  	render () {
        const ctaWidgets = <React.Fragment>
            {this.state.canUpdate && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_HIERARCHY_PERMISSION]}><button type="button" class="btn btn-primary" style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onUpdateButtonClick}>Edit Permissions</button></ProtectedComponent>}
            {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_HIERARCHY_PERMISSION]}><button type="button" class="btn btn-secondary btn-bold btn-sm btn-icon-h" id="kt_subheader_group_actions_fetch" onClick={this.onCancelButtonClick}>Cancel</button></ProtectedComponent>}
            {this.state.canSave && <ProtectedComponent requiredPermissions={[CAN_UPDATE_AGENT_HIERARCHY_PERMISSION]}><button type="button" class="btn btn-primary" disabled={this.isFormValidForUpdate()} style={{backgroundColor: '#00425f', borderColor: '#00425f'}} onClick={this.onSaveButtonClick}>Save Changes</button></ProtectedComponent>}
        </React.Fragment>
        return <React.Fragment>
            <Loader 
                isLoading={this.state.isLoading}
            />
            <Notification
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
            />
            <ContentHead title={"Agent Hierarchy Management"} subtitle={`${this.domainType ? explodeString(this.domainType, '-') : ''} Permissions`} withoutSearch ctaWidgets={ctaWidgets} />
            <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_AGENT_HIERARCHY_PERMISSION]}>
                <GridLayout>
                    <AgentPermissions 
                        permissions={this.state.permissions}
                        domainTypePermissions={this.state.domainTypePermissions}
                        onChange={this.updateDomainTypePermissions}
                        disabled={this.state.disabled}
                    />
                </GridLayout>				
            </ProtectedComponent>
        </React.Fragment>
    }

    get permissionDeniedContent() {
        return <AccessDenied />
    }
}
