import React from 'react';
import ContentHead from '../../../../../../components/content-head';
import Table from '../../../../../../components/table';
import BaseScene from '../../../../../../components/scenes/component';
import { 
  CAN_CREATE_ROLE, CAN_DELETE_ROLE, CAN_UPDATE_ROLE, CAN_VIEW_ROLE, CAN_ACTIVATE_ROLE, CAN_DEACTIVATE_ROLE } from '../../../../../../constants/permissions';
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { SUCCESS_STATUS, ERROR_STATUS } from '../../../../../../constants/api';
import { AccessDenied } from '../../../../../../components/access-denied'
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import { GridLayout } from '../../../../../../components/layouts/grid-layout'
import { platformManagement } from '../../../../../../mixins/api'
import { retrieveSessionInformation } from '../../../../../../mixins/authentication'
import { RolesExportSerializer } from '../../../../../../serializers'
import { Link } from 'react-router-dom'

const columns = [
  {
    Header: "S/N",
    accessor: 'id',
    Cell: (id) => <span>{id.index + 1}</span>,
    sortable: false,
    resizable: false,
    width: 60,
    headerStyle: {'white-space': 'unset'},
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          textAlign: 'center',
          whiteSpace: 'unset',
        },
      };
    },
  },
  {
    Header: "Name",
    accessor: 'name',
    resizable: false,
    headerStyle: {'white-space': 'unset'},
    style: {'white-space': 'unset'},
    Cell: props => <span class="word-capitalize">{props.value.toLowerCase()}</span>
  },
  // {
  //   Header: "Description",
  //   accessor: 'description',
  //   resizable: false,
  //   headerStyle: {'white-space': 'unset'},
  //   style: {'white-space': 'unset'},
  // },
  {
    Header: "Action",
    accessor: 'id',
    overflow: 'visible',
    resizable: false,
    sortable: false,
    headerStyle: {'white-space': 'unset'},
    getProps: (state, rowInfo, column) => {
      return {
        style: {
          lineHeight: '20px',
          textAlign: 'center',
          whiteSpace: 'unset',
        },
      };
    },
    width: 60,
    Cell: props => <div class="dropdown">
      <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
        <i class="la la-ellipsis-v"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <ProtectedComponent requiredPermissions={[CAN_VIEW_ROLE]}><Link to={`role-management/${props.value}/view`} class="dropdown-item">
          <i class="la la-eye"></i>
          View 
        </Link></ProtectedComponent>
        <ProtectedComponent requiredPermissions={[CAN_CREATE_ROLE]}><Link to={`role-management/add?roleId=${props.value}`} class="dropdown-item">
          <i class="la la-copy"></i>
          Create a copy
        </Link></ProtectedComponent>
      </div>
    </div>
  }
]

export default class RolesGridScene extends BaseScene {
  state = {
    roles: [],
  }

  getRoles = this.getRoles.bind(this)

  componentDidMount () {
    const currentUser = retrieveSessionInformation()
    this.domainCode = currentUser.user.domainCode
    this.getRoles()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getRoles () {
    this.setState({isLoading: true})
    const responseObj  = await platformManagement.getRoles()
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        roles: response,
        isLoading: false,
        dataCount: response.length,
      })
    }else{
      this.setState({
        isLoading: false,
        dataCount: 0,
        roles: [],
        errorMessage: response
      })
    }
  }

  render () {

    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"User Management"} subtitle={"All Roles"} filterPermissions={[CAN_VIEW_ROLE]} totalCount={56} actionButtonText={"Add Role"} actionButtonUrl={"role-management/add"} actionButtonRequiredPermissions={[CAN_CREATE_ROLE]} />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_ROLE]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <Table 
            columns={columns}
            data={this.state.roles} 
            loading={this.state.isLoading} 
            noDataText={`No roles found`}
            dataCount={this.state.dataCount}
            getData={this.getRoles}
            exportData={RolesExportSerializer.reverseRoleDetails(this.state.roles)}
            exportHeaders={RolesExportSerializer.headerExport()}
            exportFileName={'roles'}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
