import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BaseScene from '../../../../../components/scenes/component';
import ApprovalGridScene from './scenes/approval-grid'
import ViewApprovalDetailsScene from './scenes/view-approval'

export default class ApprovalScene extends BaseScene {
  sceneName = 'approval'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <Route exact path="/home/workflow-management/approval" component={ApprovalGridScene} />
      <Route exact path="/home/workflow-management/approval/:id/view" component={ViewApprovalDetailsScene} />
    </Router>
  }
}
