import React from 'react'
import Select from '../../../../../../components/select'

export default class ExternalFeeForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                    <form class="kt-form kt-form--label-right">
                        <div class="kt-portlet__body">
                            <div class="form-group col-lg-6">
                                <label>Service Type:</label>
                                <Select 
                                    options={this.props.serviceTypes.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.onChange('serviceTypeId', event.value) 
                                        this.props.getServiceTypeServices(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.externalFeeData.serviceTypeId}
                                    minHeight={40}
                                />
                                {/* <select name="category" class="form-control" onChange={event => {
                                    this.props.onChange('serviceTypeId', event.target.value) 
                                    this.props.getServiceTypeServices(event.target.value)
                                }}>
                                    <option selected disabled>Select</option>
                                    {this.props.serviceTypes.map(value => <option value={value.id}>{value.name}</option>)}
                                </select> */}
                            </div>
                            <div class="form-group col-lg-6">
                                <label>Service:</label>
                                <Select 
                                    options={this.props.services.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                    onChange={event => {
                                        this.props.onChange('serviceId', event.value)
                                        this.props.getServiceOptions(event.value)
                                    }}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.externalFeeData.serviceId}
                                    isLoading={this.props.isServiceLoading}
                                    minHeight={40}
                                />
                                {/* <div class="input-group">
                                    <select name="service" class="form-control" onChange={event => {
                                        this.props.onChange('serviceId', event.target.value)
                                        this.props.getServiceOptions(event.target.value)
                                    } }>
                                        <option selected disabled>Select</option>
                                        {this.props.services.map(value => <option value={value.id}>{value.name}</option>)}
                                    </select>
                                    {this.props.isServiceLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                        <span class="la la-spin la-refresh"></span>
                                    </div>}
                                </div> */}
                                {this.props.isServiceEmpty && <span class="error">No service found for selected service type</span>}
                            </div>
                            {this.props.canWhitelist && <div class="form-group col-lg-6">
                                <input type="checkbox" onClick={event => {
                                    this.props.onChange('useServiceId', event.target.checked)
                                }} /> Whitelist All service options
                            </div>}
                            {!this.props.externalFeeData.useServiceId && <div class="form-group col-lg-6">
                                <label>Service Option *:</label>
                                <Select 
                                    options={this.props.serviceOptions.map(detail => { return ( {label: detail.name, value: detail.paymentCode} ) })}
                                    onChange={event => this.props.onChange('serviceOptionCode', event.value)}
                                    disabled={this.props.disabled}
                                    defaultValue={this.props.externalFeeData.serviceOptionCode}
                                    isLoading={this.props.isServiceOptionLoading}
                                    minHeight={40}
                                />
                                {/* <div class="input-group">
                                    <select name="serviceOption" class="form-control" onChange={event => this.props.onChange('serviceOptionCode', event.target.value)} >
                                        <option selected disabled>Select</option>
                                        {this.props.serviceOptions.map(value => <option value={value.paymentCode}>{value.name}</option>)}
                                    </select>
                                    {this.props.isServiceOptionLoading && <div class="input-group-addon" style={{marginTop: '10px', marginLeft: '10px'}}>
                                        <span class="la la-spin la-refresh"></span>
                                    </div>}
                                </div> */}
                                {this.props.isServiceOptionEmpty && <span class="error">No service option found for selected service</span>}
                            </div>}
                        </div>
                    </form>
                </div>
        </React.Fragment>
    }
}