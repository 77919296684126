import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RegionGridScene from './scenes/region-grid'
import BaseScene from '../../../../../components/scenes/component';
import AddRegionScene from './scenes/add-region';
import ViewRegionScene from './scenes/view-region';
import RegionMappingScene from './scenes/region-mapping/scene';
import ViewRegionMappingScene from './scenes/view-region-mapping/scene';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_REGION, CAN_CREATE_REGION, CAN_UPDATE_REGION } from '../../../../../constants/permissions.js'

export default class RegionScene extends BaseScene {
  sceneName = 'region-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_REGION]} exact path='/home/system-configuration/region-management' component={RegionGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_REGION]} exact path='/home/system-configuration/region-management/add' component={AddRegionScene} />
      <ProtectedRoute requiredPermissions={[CAN_UPDATE_REGION]} exact path='/home/system-configuration/region-management/:id/view' component={ViewRegionScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_REGION]} exact path='/home/system-configuration/region-management/map' component={RegionMappingScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_REGION]} exact path='/home/system-configuration/region-management/map/:id/view' component={ViewRegionMappingScene} />
    </Router>
  }
}
