import React from 'react';
import { Redirect } from 'react-router-dom'
import ContentHead from '../../../../../../../components/content-head';
import Notification from '../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import BaseScene from '../../../../../../../components/scenes/component';
import { CAN_PERFORM_BULK } from '../../../../../../../constants/permissions.js';
import { platformManagement, liquidityService } from '../../../../../../../mixins/api'
import { SUCCESS_STATUS, ACCEPTED_REQUEST } from '../../../../../../../constants/api';
import { phoneNumberFormatter, formatBulkProcessingType } from '../../../../../../../utils/formatter'
import { convertToMinor } from '../../../../../../../utils/converter'
import { capitalizeFirstLetter } from '../../../../../../../utils/helper'
import { AGENT_TYPE_ID } from '../../../../../../../constants/domain-types'
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import BatchCreditDebitForm from '../../components/batch-credit-debit-form'
import Loader from '../../../../../../../components/loader'
import BatchCreditDebitSingle from '../../components/batch-credit-debit-single'
import BatchCreditDebitFile from '../../components/batch-credit-debit-file'
import BatchCreditDebitOptions from '../../components/batch-credit-debit-options'

export default class NewBatchCreditDebitScene extends BaseScene {
  
    sceneName = 'bulk-processing'
  
    notificationSectionRef = React.createRef(null)

    constructor (props) {
        super(props)
        this.state = {
            agentSearchParameters: {
                agentType: AGENT_TYPE_ID
            }, 
            agents: [],
            agentCount: 0,
            selectedAgent: [],
            bulkProcessingFormData: {
                agents: []
            },
            showSearchInfo: false,
            bulkProcessingFileUpload: [],
        }
        this.getAgents = this.getAgents.bind(this)
        this.updateSelectedAgent = this.updateSelectedAgent.bind(this)
        this.addAgentDetails = this.addAgentDetails.bind(this)
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
        this.uploadBulkProcessingDocument = this.uploadBulkProcessingDocument.bind(this)
        this.updateAgentSearchParameters = this.updateAgentSearchParameters.bind(this)
        this.isFormValid = this.isFormValid.bind(this)
        this.removeAgentDetails = this.removeAgentDetails.bind(this)
        this.updateState = this.updateState.bind(this)
        this.readUploadedFile = this.readUploadedFile.bind(this)
        this.updateBulkProcessingFormData = this.updateBulkProcessingFormData.bind(this)
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.errorMessage) {
          setTimeout(() => {
            this.setState({errorMessage: null})
          }, 5000)
        }
        if (this.state.successMessage) {
          setTimeout(() => {
            this.setState({successMessage: null})
          }, 5000)
        }
    }

    async getAgents() {
        this.setState({isAgentSearchLoading: true})
        const responseObj = await platformManagement.searchAgents(1, 20, this.state.agentSearchParameters)
        const { status, response } = responseObj
        if (status === SUCCESS_STATUS) {
            this.setState({
                agents: this.getActiveAgents(response),
                agentCount: this.getActiveAgents(response).length,
                showSearchInfo: true,
                isAgentSearchLoading: false
            })
        }else{
            this.setState({
                agents: [],
                agentCount: 0,
                showSearchInfo: true,
                isAgentSearchLoading: false
            })
        }
    }

    getActiveAgents (agents) {
        return agents.filter(function(value) { 
            return value.statusId === 2;  
        });
    }

    async addAgentDetails (e) {
        e.preventDefault()
        const serializedSelectedAgentDetails = {
            "id": Date.now(),
            "walletType": this.state.selectedAgent.walletType,
            "amount": convertToMinor(this.state.selectedAgent.amount),
            "agentPhoneNumber": this.state.selectedAgent.agentMobileNo,
            "narration": this.state.selectedAgent.narration,
            "serviceProviderReference": this.state.selectedAgent.serviceProviderReference
        }

        this.setState({
            bulkProcessingFormData: {...this.state.bulkProcessingFormData,
                agents: [...this.state.bulkProcessingFormData.agents, serializedSelectedAgentDetails]
            }, 
            selectedAgent: []
        })    
    }

    updateSelectedAgent (type, value) {
        this.setState({selectedAgent: {...this.state.selectedAgent, [type]: value}})
    }

    async onSubmitButtonClick () {
        this.setState({
            isLoading: true,
            showListOnly: true,
            agents: [],
            agentSearchParameters: {
                agentType: AGENT_TYPE_ID
            },
            disabled: true
        })
        const processingType = `${(this.state.bulkProcessingFormData.bulkTransactionType).toLowerCase()}List`
        const formData = {
            "bulkTransactionType": this.state.bulkProcessingFormData.bulkTransactionType,
            [processingType]: this.state.bulkProcessingFormData.agents 
        }

        const responseObj = await liquidityService.bulkProcessing(formData)
        const { status, response } = responseObj

        if (status === SUCCESS_STATUS) {
            if(response.code === ACCEPTED_REQUEST) {
                this.setState({
                    successMessage: `Your bulk ${(this.state.bulkProcessingFormData.bulkTransactionType == 'RETRIEVAL' ? 'DEBIT' : this.state.bulkProcessingFormData.bulkTransactionType).toLowerCase()} processing has been sent successfully for approval`,
                    isLoading: false,
                    showListOnly: true,
                    disabled: false,
                    redirect: false
                })
            }else{
                this.setState({
                    successMessage: `Your bulk ${(this.state.bulkProcessingFormData.bulkTransactionType == 'RETRIEVAL' ? 'DEBIT' : this.state.bulkProcessingFormData.bulkTransactionType).toLowerCase()} processing was successful. Transaction Reference - ${response.transactionRef}`,
                    isLoading: false,
                    showListOnly: true,
                    disabled: false,
                    redirect: true
                })
            }
        }else{
            this.setState({
              errorMessage: response,
              isLoading: false,
              showListOnly: false,
              disabled: false
          })
        }
    }

    isFormValid () {
        return !this.state.bulkProcessingFormData.bulkTransactionType
    }

    isBulkProcessFormValid () {
        return !this.state.selectedAgent.walletType || !this.state.selectedAgent.amount || !this.state.selectedAgent.narration || !this.state.selectedAgent.serviceProviderReference
    }

    getResultDisplayMessage (count) {
        if(count > 1) {
            return `${count} results found`
        }else if(count === 1) {
            return `${count} result found`
        }else{
            return 'No result found'
        }
    }

    removeAgentDetails (id) {
        const agents = (this.state.bulkProcessingFormData.agents).filter(function(value) { 
            return value.id !== id;  
        });
        this.setState({
            bulkProcessingFormData: {...this.state.bulkProcessingFormData, agents}
        })
    }

    async uploadBulkProcessingDocument () {
        this.setState({
            isLoading: true,
            disabled: true,
        })
        const responseObj = await liquidityService.bulkProcessingDocumentUpload(this.state.bulkProcessingFormData.bulkTransactionType, this.state.bulkProcessingFileUpload)
        const { status, response } = responseObj

        if (status === SUCCESS_STATUS) {
            if(response.code === ACCEPTED_REQUEST) {
                this.setState({
                    successMessage: `Your bulk ${(this.state.bulkProcessingFormData.bulkTransactionType === 'RETRIEVAL' ? 'DEBIT' : this.state.bulkProcessingFormData.bulkTransactionType).toLowerCase()} processing has been sent successfully for approval`,
                    isLoading: false,
                    showListOnly: true,
                    disabled: false,
                    redirect: false
                })
            }else{
                this.setState({
                    successMessage: `Your bulk ${(this.state.bulkProcessingFormData.bulkTransactionType === 'RETRIEVAL' ? 'DEBIT' : this.state.bulkProcessingFormData.bulkTransactionType).toLowerCase()} processing was successful. Transaction Reference - ${response.transactionRef}`,
                    isLoading: false,
                    showListOnly: true,
                    disabled: false,
                    redirect: true
                })
            }
        }else{
            this.setState({
              errorMessage: response,
              isLoading: false,
              disabled: false
          })
        }
    }

    readUploadedFile (event) {
        const document = {
            file: event.target.files[0],
            filename: event.target.files[0].name,
            url: URL.createObjectURL(event.target.files[0])
        }

        
        if(document.file.type === "application/vnd.ms-excel" || document.file.type === "text/csv"){
            this.setState({
                bulkProcessingFileUpload: document
            })
        }else{
            this.setState({
                fileErrorMessage: "Invalid file, upload a csv file"
            })
        }
        
    }

    getWalletTypeName (walletType) {
        let walletTypeName = ""
        if(walletType.toLowerCase() === "tran") {
            walletTypeName =  "Transaction Wallet"
        }else if(walletType.toLowerCase() === "com") {
            walletTypeName = "Commission Wallet"
        }
        return walletTypeName
    }

    updateAgentSearchParameters (type, value) {
        this.setState({agentSearchParameters: {...this.state.agentSearchParameters, [type]: phoneNumberFormatter(value)}})
    }

    updateState (type, value) {
        this.setState({[type]: value})
    }

    updateBulkProcessingFormData (type, value) {
        this.setState({bulkProcessingFormData: {...this.state.bulkProcessingFormData, [type]: value}})
    }

    render() {
        if(this.state.redirect) {
            return <Redirect to="/home/batch-credit-debit" />
        }
        return <React.Fragment>
            <ContentHead title={"Batch Credit/Debit"} subtitle={"Create Batch Credit/Debit"} filterPermissions={[CAN_PERFORM_BULK]}actionButtonRequiredPermissions={[CAN_PERFORM_BULK]} downloadButtonUrl={process.env.REACT_APP_BULK_UPLOAD_TEMPLATE_URL} downloadButtonText={`Download Template`} />
            <Loader
                isLoading={this.state.isLoading}
            />
            <Notification 
                ref={this.notificationSectionRef}
                errorMessage={this.state.errorMessage}
                successMessage={this.state.successMessage}
            />
            <ProtectedComponent requiredPermissions={[CAN_PERFORM_BULK]} permissionDeniedContent={this.permissionDeniedContent}>
                <GridLayout>
                 <div class="kt-portlet">
                    <form class="kt-form kt-form--label-right">
                            <div class="kt-portlet__body">
                                <BatchCreditDebitOptions 
                                    disabled={this.state.disabled}
                                    updateBulkProcessingFormData={this.updateBulkProcessingFormData}
                                />
                                {(this.state.bulkProcessingFormData.bulkTransactionMethod && this.state.bulkProcessingFormData.bulkTransactionMethod == 'SINGLE') &&<div>    
                                    <BatchCreditDebitSingle
                                        showListOnly={this.state.showListOnly}
                                        agentSearchParameters={this.state.agentSearchParameters}
                                        isAgentSearchLoading={this.state.isAgentSearchLoading}
                                        getAgents={this.getAgents}
                                        agents={this.state.agents}
                                        getResultDisplayMessage={this.getResultDisplayMessage}
                                        showSearchInfo={this.state.showSearchInfo}
                                        agentCount={this.state.agentCount}
                                        bulkProcessingFormData={this.state.bulkProcessingFormData}
                                        removeAgentDetails={this.removeAgentDetails}
                                        isFormValid={this.isFormValid}
                                        onSubmitButtonClick={this.onSubmitButtonClick}
                                        updateAgentSearchParameters={this.updateAgentSearchParameters}
                                        getWalletTypeName={this.getWalletTypeName}
                                        updateState={this.updateState}
                                    />
                                </div>}
                                {(this.state.bulkProcessingFormData.bulkTransactionMethod && this.state.bulkProcessingFormData.bulkTransactionMethod == 'BULK') &&<div class="form-group row">
                                    <BatchCreditDebitFile 
                                        disabled={this.state.disabled}
                                        fileErrorMessage={this.state.fileErrorMessage}
                                        bulkProcessingFileUpload={this.state.bulkProcessingFileUpload}
                                        readUploadedFile={this.readUploadedFile}
                                        uploadBulkProcessingDocument={this.uploadBulkProcessingDocument}
                                        bulkProcessingFormData={this.state.bulkProcessingFormData}
                                    />
                                </div>}
                            </div>
                        </form>
                    </div>
                    <PromptModal 
                        modalTitle={this.state.bulkProcessingFormData.bulkTransactionType && `Batch ${capitalizeFirstLetter(formatBulkProcessingType(this.state.bulkProcessingFormData.bulkTransactionType))} Details`}
                        modalBody={<BatchCreditDebitForm selectedAgent={this.state.selectedAgent} updateSelectedAgent={this.updateSelectedAgent} />}
                        modalFooterButton1={`Cancel`}
                        hideModalHeader={true}
                        modalSize={`modal-md`}
                        height={'550px'}
                        modalFooterButton2={`Submit`}
                        deleteModal={this.addAgentDetails}
                        modalFooterButton2Disabled={this.isBulkProcessFormValid()}
                        textAlign={`left`}
                    />    
                </GridLayout>
            </ProtectedComponent>
        </React.Fragment>
    }
}