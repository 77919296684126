import React from "react";
import { AccessDenied } from "../../../../../../components/access-denied";
import AgentClassAssignment from "../../../../../../components/application-form/agent-class-assignment";
import ApplicantDetailsForm from "../../../../../../components/application-form/applicant-details";
import BusinessDetailsForm from "../../../../../../components/application-form/business-details";
import { DeclineDetails } from "../../../../../../components/application-form/decline-details";
import KycDocuments from "../../../../../../components/application-form/kyc-documents";
import NextOfKinDetailsForm from "../../../../../../components/application-form/next-of-kin-details";
import { OtherDetails } from "../../../../../../components/application-form/other-details";
import ContentHead from "../../../../../../components/content-head";
import { GridItem } from "../../../../../../components/layouts/grid-item";
import Loader from "../../../../../../components/loader";
import { PromptModal } from "../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../components/notification";
import BaseScene from "../../../../../../components/scenes/component";
import { ERROR_STATUS, SUCCESS_STATUS } from "../../../../../../constants/api";
import {
  CAN_APPROVE_APPLICATIONS,
  CAN_REJECT_APPLICATIONS,
  CAN_VALIDATE_APPLICATIONS,
  CAN_VIEW_APPLICATION,
} from "../../../../../../constants/permissions";
import AgentTypes from "../../../../../../fixtures/agent_types.json";
import Banks from "../../../../../../fixtures/banks.json";
import BusinessTypes from "../../../../../../fixtures/business_types.json";
import Countries from "../../../../../../fixtures/countries.json";
import IDTYPES from "../../../../../../fixtures/id-types.json";
import LGAs from "../../../../../../fixtures/lgas.json";
import PersonalDocuments from "../../../../../../fixtures/personal_documents.json";
import Relationships from "../../../../../../fixtures/relationships.json";
import States from "../../../../../../fixtures/states.json";
import UtilityBills from "../../../../../../fixtures/utility_bills.json";
import { onboarding, platformManagement } from "../../../../../../mixins/api";
import { retrieveSessionInformation } from "../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import {
  ApplicantDetailsSerializer,
  BusinessDetailsSerializer,
} from "../../../../../../serializers";
import {
  getLgasByStateId,
  getStatesByCountryId,
  stripSpacesFromString,
  toPermissionArray,
} from "../../../../../../utils/helper";
import {
  checkAccountNumberIsValid,
  checkBvnIsValid,
  checkCompanyRegistrationNumberIsValid,
  checkEmailIsValid,
} from "../../../../../../utils/validator";
import ApplicationPreviewModal from "../../components/application-preview-modal";

export default class ViewApplicationScene extends BaseScene {
  requiredPermissions = [
    CAN_APPROVE_APPLICATIONS,
    CAN_VALIDATE_APPLICATIONS,
    CAN_REJECT_APPLICATIONS,
  ];

  canSave = null;
  canSubmit = null;

  applicantDetailsSectionRef = React.createRef(null);
  metadataSectionRef = React.createRef(null);
  kycDocumentSectionRef = React.createRef(null);

  sceneName = "view-application";

  currentUser = retrieveSessionInformation();
  userPermissions = toPermissionArray(this.currentUser.user.permissions);

  constructor(props) {
    super(props);

    this.requiredApplicantDetailsFields = ["firstName", "lastName", "phone"];

    this.allApplicantDetailsFields = [
      ...this.requiredApplicantDetailsFields,
      "gender",
      "mothersMaidenName",
      "closestLandmark",
      "placeOfBirth",
      "middleName",
      "identificationNumber",
    ];

    this.allNextOfKinFields = ["firstName", "surname", "phoneNumber", "gender"];

    this.allBusinessDetailsFields = [
      "businessName",
      "businessType",
      "companyRegistrationNumber",
      "state",
      "localGovernmentArea",
      "address",
      "bankName",
      "accountNumber",
    ];

    this.allValidationFields = [
      "bvnIsValid",
      "emailIsValid",
      "passwordIsValid",
      "phoneIsValid",
      "nextOfKinPhoneIsValid",
      "accountNumberIsValid",
    ];

    this.allMetadataFields = ["agentClass"];

    this.state = {
      readOnly: true,
      canUpdate: false,
      canSave: false,
      disable: true,
      aggregators: [],
      identificationTypes: IDTYPES,
      businessTypes: BusinessTypes,
      uploadedDocuments: [],
      application: {
        applicantDetails: {
          nextOfKin: {},
        },
        businessDetails: {},
      },
      applicantDetailsFormData: {
        nextOfKin: {},
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        phone: null,
        username: null,
        howYouHeardAboutUs: null,
        password: null,
        confirmPassword: null,
      },
      businessDetailsFormData: {},
      kycDocuments: {},
      metdataFormData: {},

      bvnIsValid: true,
      emailIsValid: true,
      formSubmitted: false,
      passwordIsValid: false,
      phoneIsValid: true,
      nextOfKinPhoneIsValid: true,
      accountNumberIsValid: true,
      metadataIsValid: false,
      companyRegistrationNumberIsValid: true,

      isLoading: true,
      canApprove: false,
      canSubmit: false,
      canValidate: false,
      agentClasses: [],
      declineReasonCategories: [],
      countries: Countries,
      lgas: LGAs,
      businessLgas: [],
      applicantLgas: [],
      states: States,
      documentErrors: [],
      showPhoneInput: false,
      documentUploadProgress: [],
      personalDocumentType: null,
      utilityBillType: null,
      uploadedOtherDocuments: [],
      otherDocumentName: "",
    };

    this.loadApplication = this.loadApplication.bind(this);
    this.onApproveButtonClick = this.onApproveButtonClick.bind(this);
    this.onModalDeclineButtonClick = this.onModalDeclineButtonClick.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.onValidateButtonClick = this.onValidateButtonClick.bind(this);
    this.readNationalIdUrl = this.readNationalIdUrl.bind(this);
    this.saveApplication = this.saveApplication.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.onCheckAgentClass = this.onCheckAgentClass.bind(this);
    this.getDocumentClickName = this.getDocumentClickName.bind(this);
    this.updateAgentAssignment = this.updateAgentAssignment.bind(this);
    this.updateApplicantDetailsForm = this.updateApplicantDetailsForm.bind(
      this
    );
    this.updateBusinessDetailsForm = this.updateBusinessDetailsForm.bind(this);
    this.updateNextOfKinDetailsForm = this.updateNextOfKinDetailsForm.bind(
      this
    );
    this.updateSetState = this.updateSetState.bind(this);
    this.submitApplication = this.submitApplication.bind(this);
    this.uploadApplicationDocument = this.uploadApplicationDocument.bind(this);
    this.getIdentificationTypeName = this.getIdentificationTypeName.bind(this);
    this.getCountryName = this.getCountryName.bind(this);
    this.getStateName = this.getStateName.bind(this);
    this.getLgaName = this.getLgaName.bind(this);
    this.getBusinessTypeName = this.getBusinessTypeName.bind(this);
    this.getAgentClassName = this.getAgentClassName.bind(this);
    this.getAggregatorName = this.getAggregatorName.bind(this);
    this.updateBusinessAddress = this.updateBusinessAddress.bind(this);
    this.updateNextOfKinAddress = this.updateNextOfKinAddress.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.getAgentClasses = this.getAgentClasses.bind(this);
    this.getAggregatorList = this.getAggregatorList.bind(this);
    this.updateCountry = this.updateCountry.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.applicationId = this.props.match.params.id;
    this.loadApplication();
    this.getAggregatorList();
    this.getAgentClasses();
    this.getDeclineReasonCategories();
    this.props.updateScene(this.sceneName);
  }

  async getAggregatorList() {
    this.setState({ isAggregatorListLoading: true });
    const responseObj = await platformManagement.getSuperAgentList();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        aggregators: response ? response : [],
      });
    } else {
      this.setState({
        aggregators: [],
      });
    }
    this.setState({ isAggregatorListLoading: false });
  }

  async getAgentClasses() {
    this.setState({ isAgentClassLoading: true });
    const responseObj = await platformManagement.getAgentClasses();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentClasses: response ? this.getActiveAgentClasses(response) : [],
      });
    } else {
      this.setState({
        agentClasses: [],
      });
    }
    this.setState({ isAgentClassLoading: false });
  }

  async getDeclineReasonCategories() {
    const responseObj = await onboarding.getDeclineReasonCategoriesByStatus(
      true
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        declineReasonCategories: response ? response : [],
      });
    } else {
      this.setState({
        declineReasonCategories: [],
      });
    }
  }

  getActiveAgentClasses(response) {
    return response.filter((details) => details.disabled === 0);
  }

  async onUpdateButtonClick() {
    this.setState({
      canUpdate: false,
      canSave: true,
      disabled: false,
      readOnly: null,
      showUploadForm: true,
      showUploadedDocuments: false,
      canUploadDocument: true,
    });
  }

  getIdentificationTypeName(id) {
    const details = this.state.identificationTypes.filter(
      (value) => value.id == id
    );
    return details[0] ? details[0].name : id;
  }

  getCountryName(id) {
    const details = this.state.countries.filter(
      (value) => value.id == parseInt(id)
    );
    return details[0] ? details[0].name : "";
  }

  getStateName(id) {
    const details = States.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getLgaName(id) {
    const details = LGAs.filter((value) => value.id == parseInt(id));
    return details[0] ? details[0].name : id;
  }

  getBusinessTypeName(id) {
    const details = this.state.businessTypes.filter((value) => value.id == id);
    return details[0] ? details[0].business_type : id;
  }

  getAgentClassName(id) {
    const details = this.state.agentClasses.filter((value) => value.id == id);
    return details[0] ? details[0].name : "";
  }

  getAggregatorName(id) {
    const details = this.state.aggregators.filter(
      (value) => value.referralCode == id
    );
    return details[0] ? details[0].businessName : id;
  }

  async loadApplication() {
    let application = undefined;

    const responseObj = await onboarding.getApplicationById(this.applicationId);
    const { status, response } = responseObj;
    application = this.patchApplication(response);
    application.applicantDetails.lastName =
      application.applicantDetails.surname;
    application.applicantDetails.closestLandmark =
      application.applicantDetails.closestLandMark;

    // rough
    const nationalIdDocument = application.documentsList
      ? application.documentsList.filter((value, index) => {
          return value.documentType === "NATIONAL_ID";
        })[0] || {
          documentType: "NATIONAL_ID",
        }
      : {
          documentType: "NATIONAL_ID",
        };

    if (
      application.applicationType === "SUBMITTED" ||
      application.applicationType === "DRAFT"
    ) {
      this.setState({
        isLoading: false,
        application: application,
        showPhoneInput: true,
        uploadedDocuments: application.documentsList
          ? application.documentsList
          : [],
        showUploadForm: application.documentsList.length ? false : true,
        showUploadedDocuments: application.documentsList.length ? true : false,
        readOnly: true,
        applicantDetailsFormData: {
          ...ApplicantDetailsSerializer.reverseSerializeFromApplication(
            application.applicantDetails
          ),
          howYouHeardAboutUs: application.howYouHeardAboutUs,
          agentTypeId: application.agentTypeId,
        },
        businessDetailsFormData: BusinessDetailsSerializer.reverseSerializeFromApplication(
          application.businessDetails || {}
        ),
        states: getStatesByCountryId(application.applicantDetails.nationality),
        applicantLgas: getLgasByStateId(application.applicantDetails.state),
        businessLgas: getLgasByStateId(application.businessDetails.state),
      });
    } else if (application.applicationType === undefined) {
      this.setState({
        isLoading: false,
        errorLoading: true,
        errorMessage: response,
      });
      window.scrollTo(0, this.metadataSectionRef.current);
    } else {
      this.setState({
        isLoading: false,
        application: application,
        readOnly: true,
        applicantDetailsFormData: {
          ...ApplicantDetailsSerializer.reverseSerializeFromApplication(
            application.applicantDetails
          ),
          howYouHeardAboutUs: application.howYouHeardAboutUs,
        },
        businessDetailsFormData: BusinessDetailsSerializer.reverseSerializeFromApplication(
          application.businessDetails || {}
        ),
        states: getStatesByCountryId(application.applicantDetails.nationality),
        applicantLgas: getLgasByStateId(application.applicantDetails.state),
        businessLgas: getLgasByStateId(application.businessDetails.state),
      });
    }
  }

  patchApplication(application) {
    if (application.applicantDetails === undefined) {
      application.applicantDetails = {};
    }

    if (application.applicantDetails.nextOfKin === undefined) {
      application.applicantDetails.nextOfKin = {};
    }

    if (application.businessDetails === undefined) {
      application.businessDetails = {};
    }

    return application;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }

    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }

    if (this.state.uploadErrorMessage) {
      setTimeout(() => {
        this.setState({ uploadErrorMessage: null });
      }, 5000);
    }

    const emptyRequiredApplicantDetailsFields = this.requiredApplicantDetailsFields.filter(
      (value) => {
        return Boolean(this.state.applicantDetailsFormData[value]) === false;
      }
    );

    const emptyApplicantDetailsFields = this.allApplicantDetailsFields.filter(
      (value) => {
        return Boolean(this.state.applicantDetailsFormData[value]) === false;
      }
    );

    const emptyNextOfKinFields = this.allNextOfKinFields.filter((value) => {
      return (
        Boolean(this.state.applicantDetailsFormData.nextOfKin[value]) === false
      );
    });

    const emptyBusinessDetailsFields = this.allBusinessDetailsFields.filter(
      (value) => {
        return Boolean(this.state.businessDetailsFormData[value]) === false;
      }
    );

    const allFieldsEmpty =
      emptyApplicantDetailsFields.length +
      emptyNextOfKinFields.length +
      emptyBusinessDetailsFields.length;

    const newState = {
      canSave: this.canSave,
      canSubmit: this.canSubmit,
    };

    if (emptyRequiredApplicantDetailsFields.length === 0 && !this.canSave) {
      newState.canSave = true;
    } else if (emptyRequiredApplicantDetailsFields.length !== 0) {
      newState.canSave = false;
    }

    if (allFieldsEmpty === 0) {
      newState.canSubmit = true;
    } else if (allFieldsEmpty !== 0) {
      newState.canSubmit = false;
    }

    this.canSave = newState.canSave;
    this.canSubmit = newState.canSubmit;
  }

  readNationalIdUrl(type, documentType, event) {
    const document = {
      name: documentType,
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      kycDocuments: {
        ...this.state.kycDocuments,
        [type]: document,
      },
    });

    if (!this.applicationId) {
      this.onSubmitButtonClick();
      const documentType = `${type}Progress`;
      this.setState({
        [documentType]: true,
      });
      setTimeout(() => {
        this.uploadApplicationDocument(
          this.applicationId,
          document.name,
          document.file,
          type
        );
      }, 50000);
    } else {
      this.uploadApplicationDocument(
        this.applicationId,
        document.name,
        document.file,
        type
      );
    }

    // if(!this.state.application.applicationId) {
    //   this.onSubmitButtonClick()
    // }

    // this.uploadApplicationDocument(this.state.application.applicationId, document.name, document.file, type)
  }

  async uploadApplicationDocument(applicationId, name, file, type) {
    const documentType = `${type}Progress`;
    this.setState({
      [documentType]: true,
    });

    if (!applicationId) {
      this.onSubmitButtonClick();
    }

    applicationId = applicationId
      ? applicationId
      : this.state.application.applicationId;

    const responseObj = await onboarding.documentUpload(
      applicationId,
      name,
      file
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.state.documentErrors.pop(name);
    } else {
      this.state.documentErrors.indexOf(name) === -1 &&
        this.setState({
          documentErrors: [...this.state.documentErrors, name],
        });
      this.setState({
        uploadErrorMessage: response,
      });
    }
    setTimeout(() => {
      this.setState({
        [documentType]: false,
      });
    }, 500);
    window.scrollTo(this.kycDocumentSectionRef.current);
  }

  async saveApplication(application) {
    this.setState({ isLoading: true });
    const responseObj = await onboarding.saveApplication(application, true);
    const { status, response } = responseObj;
    application["applicationId"] = response.applicationId;

    this.setState({
      application,
    });

    if (status === SUCCESS_STATUS) {
      //application['applicationId'] = saveApplicationResponse.applicationId
      this.setState({
        isLoading: false,
        redirect: true,
        successMessage: "Application saved successfully.",
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }

    window.scrollTo(0, this.metadataSectionRef.current);
  }

  async submitApplication(application, draft = null) {
    this.setState({ isLoading: true });
    const responseObj = await onboarding.submitApplication(application, draft);
    const { status, responseData } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        redirect: true,
        successMessage: "Application submitted successfully.",
      });
    } else {
      this.setState({
        isLoading: false,
        errorMessage: responseData,
      });
    }

    window.scrollTo(0, this.metadataSectionRef.current);
    this.loadApplication();
    setTimeout(() => {
      // this.setState({
      //   formSubmitted: true
      // })
    }, 2000);
  }

  async onSubmitButtonClick() {
    const application = this.applicationSerializer();
    // application.applicantDetails.phoneNumber = application.applicantDetails.phoneNumber ? stripSpacesFromString(application.applicantDetails.phoneNumber) : ''
    // application.applicantDetails.nextOfKin.phoneNumber = application.applicantDetails.nextOfKin.phoneNumber ? stripSpacesFromString(application.applicantDetails.nextOfKin.phoneNumber) : ''
    // application.businessDetails.phoneNumber = application.businessDetails.phoneNumber ? stripSpacesFromString(application.businessDetails.phoneNumber) : ''

    if (
      application.applicantDetails &&
      application.applicantDetails.phoneNumber
    ) {
      application.applicantDetails.phoneNumber = application.applicantDetails
        .phoneNumber
        ? stripSpacesFromString(application.applicantDetails.phoneNumber)
        : "";
    }
    if (
      application.applicantDetails.nextOfKin &&
      application.applicantDetails.nextOfKin.phoneNumber
    ) {
      application.applicantDetails.nextOfKin.phoneNumber = application
        .applicantDetails.nextOfKin
        ? stripSpacesFromString(
            application.applicantDetails.nextOfKin.phoneNumber
          )
        : "";
    }
    if (
      application.businessDetails &&
      application.businessDetails.phoneNumber
    ) {
      application.businessDetails.phoneNumber = application.businessDetails
        .phoneNumber
        ? stripSpacesFromString(application.businessDetails.phoneNumber)
        : "";
    }

    this.submitApplication(application);
  }

  async onSaveButtonClick() {
    const application = this.applicationSerializer();
    if (
      application.applicantDetails &&
      application.applicantDetails.phoneNumber
    ) {
      application.applicantDetails.phoneNumber = application.applicantDetails
        .phoneNumber
        ? stripSpacesFromString(application.applicantDetails.phoneNumber)
        : "";
    }
    if (
      application.applicantDetails.nextOfKin &&
      application.applicantDetails.nextOfKin.phoneNumber
    ) {
      application.applicantDetails.nextOfKin.phoneNumber = application
        .applicantDetails.nextOfKin
        ? stripSpacesFromString(
            application.applicantDetails.nextOfKin.phoneNumber
          )
        : "";
    }
    if (
      application.businessDetails &&
      application.businessDetails.phoneNumber
    ) {
      application.businessDetails.phoneNumber = application.businessDetails
        .phoneNumber
        ? stripSpacesFromString(application.businessDetails.phoneNumber)
        : "";
    }
    this.submitApplication(application, true);
  }

  applicationSerializer() {
    let application = {};
    if (!this.state.application) {
      application = {
        howYouHeardAboutUs: this.state.applicantDetailsFormData
          .howYouHeardAboutUs,
        applicantDetails: {
          ...ApplicantDetailsSerializer.serialize(
            this.state.applicantDetailsFormData
          ),
        },
        businessDetails: BusinessDetailsSerializer.serialize(
          this.state.businessDetailsFormData
        ),
      };
      // this.saveApplication(application)
    } else {
      // update application by concatenating forms
      application = {
        ...this.state.application,
        howYouHeardAboutUs: this.state.applicantDetailsFormData
          .howYouHeardAboutUs,
        applicantDetails: ApplicantDetailsSerializer.serializeForApplication(
          this.state.applicantDetailsFormData
        ),
        businessDetails: BusinessDetailsSerializer.serialize(
          this.state.businessDetailsFormData
        ),
      };
      // this.saveApplication(application)
    }

    return application;
  }

  get currentUserCanApprove() {
    //const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]')
    return this.userPermissions.includes(CAN_APPROVE_APPLICATIONS);
  }

  get currentUserCanValidate() {
    //const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]')
    return this.userPermissions.includes(CAN_VALIDATE_APPLICATIONS);
  }

  get currentUserCanReject() {
    //const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]')
    return this.userPermissions.includes(CAN_REJECT_APPLICATIONS);
  }

  async onApproveButtonClick() {
    this.setState({ isLoading: true });

    // const responseObj = await onboarding.approveApplication(
    //   this.state.metdataFormData.agentClass
    //     ? this.state.metdataFormData.agentClass
    //     : this.state.application.agentClassId,
    //   this.state.application,
    //   this.state.metdataFormData.superAgent
    //     ? this.state.metdataFormData.superAgent
    //     : this.state.application.referralCode
    // );

    const responseObj = await onboarding.approveApplication(
      this.state.application,

      this.state.metdataFormData.superAgent
        ? this.state.metdataFormData.superAgent
        : this.state.application.referralCode
    );

    const { status, response } = responseObj;
    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
      return;
    }
    this.setState({
      isLoading: false,
      successMessage: "Application has been approved!",
      application: {
        ...this.state.application,
        approvalStatus: "APPROVED",
      },
    });
    window.scrollTo(0, this.metadataSectionRef.current);
  }

  onDeclineButtonClick() {}

  async onModalDeclineButtonClick() {
    this.setState({ isLoading: true });

    const responseObj = await onboarding.declineApplication(
      this.state.declineReason,
      this.state.application,
      this.state.declineReasonCategory
    );
    const { status, response } = responseObj;
    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });

      return;
    }

    this.setState({
      isLoading: false,
      application: {
        ...this.state.application,
        approvalStatus: "REJECTED",
      },
      successMessage: "Application has been declined!",
      canUpdate: true,
    });
  }

  async onCheckAgentClass() {
    if (!this.canApprove && this.state.application.agentClassId) {
      return;
    } else if (!this.canApprove) {
      window.scrollTo(0, this.metadataSectionRef.current.offsetTop);
      return;
    }
  }

  updateCountry(countryId) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        country: countryId,
      },
    });
    this.setState({
      states: getStatesByCountryId(countryId),
      canUploadDocument: false,
    });
  }

  updateState(stateId, type) {
    if (type === "applicantState") {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          state: stateId,
        },
        applicantLgas: getLgasByStateId(stateId),
      });
    } else {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          state: stateId,
        },
        businessLgas: getLgasByStateId(stateId),
      });
    }
    // this.setState({
    //   lgas: LGAs
    // })
  }

  canUploadDocument() {
    this.setState({
      canUploadDocument: this.state.applicantDetailsFormData,
    });
  }

  updateBusinessAddress(shouldUpdate) {
    if (shouldUpdate) {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          address: this.state.applicantDetailsFormData.address,
          state: this.state.applicantDetailsFormData.state,
          localGovernmentArea: this.state.applicantDetailsFormData.lga,
        },
      });
    } else {
      this.setState({
        businessDetailsFormData: {
          ...this.state.businessDetailsFormData,
          address: "",
        },
      });
    }
  }

  updateNextOfKinAddress(shouldUpdate) {
    if (shouldUpdate) {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            address: this.state.applicantDetailsFormData.address,
          },
        },
      });
    } else {
      this.setState({
        applicantDetailsFormData: {
          ...this.state.applicantDetailsFormData,
          nextOfKin: {
            ...this.state.applicantDetailsFormData.nextOfKin,
            address: "",
          },
        },
      });
    }
  }

  updateAgentAssignment(type, value) {
    this.setState({
      metdataFormData: { ...this.state.metdataFormData, [type]: value },
    });
  }

  updateApplicantDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        [type]: value,
      },
    });
  }

  updateBusinessDetailsForm(type, value) {
    this.setState({
      businessDetailsFormData: {
        ...this.state.businessDetailsFormData,
        [type]: value,
      },
    });
  }

  updateNextOfKinDetailsForm(type, value) {
    this.setState({
      applicantDetailsFormData: {
        ...this.state.applicantDetailsFormData,
        nextOfKin: {
          ...this.state.applicantDetailsFormData.nextOfKin,
          [type]: value,
        },
      },
    });
  }

  updateSetState(type, value) {
    this.setState({ [type]: value });
  }

  async onValidateButtonClick() {
    this.setState({ isLoading: true });

    const responseObj = await onboarding.validateApplication(
      this.state.metdataFormData.agentClass
        ? this.state.metdataFormData.agentClass
        : this.state.application.agentClassId,
      this.state.application,
      this.state.metdataFormData.superAgent
        ? this.state.metdataFormData.superAgent
        : this.state.application.referralCode
    );
    const { status, response } = responseObj;

    if (
      !JSON.parse(process.env.REACT_APP_MOCK_MODE) &&
      status === ERROR_STATUS
    ) {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });

      return;
    }

    if (status === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });

      return;
    }

    this.setState({
      isLoading: false,
      successMessage: "Application has been validated!",
      application: {
        ...this.state.application,
        approvalStatus: "AWAITING_APPROVAL",
      },
    });
    window.scrollTo(0, this.metadataSectionRef.current);
  }

  get toShowApprovalButton() {
    return (
      this.currentUserCanApprove &&
      this.state.application.approvalStatus === "AWAITING_APPROVAL"
    );
  }

  get toShowUpdateButton() {
    return this.state.application.agentTypeId === 3;
  }

  get toShowDeclineButton() {
    return this.currentUserCanReject;
  }

  get toShowValidationButton() {
    return (
      this.state.application.approvalStatus === "AWAITING_VALIDATION" &&
      this.currentUserCanValidate
    );
  }

  get toShowDeclineReason() {
    return this.state.application.approvalStatus === "REJECTED";
  }

  get toShowSuperAgentPicker() {
    return false;
  }

  getDocumentClickName(filename, src) {
    this.setState({
      documentClickUrl: src,
      documentClickFilename: filename,
    });
  }

  render() {
    if (this.state.formSubmitted) {
      window.location.href = "/home/applications";
    }

    const declineModalBody = (
      <React.Fragment>
        <form class="kt-form kt-form--label-right">
          <div class="kt-portlet__body">
            <div class="form-group row">
              <div class="col-lg-12">
                <label>Reason for decline</label>
                <select
                  autoComplete="off"
                  class="form-control"
                  onChange={(event) =>
                    this.setState({ declineReasonCategory: event.target.value })
                  }
                >
                  <option selected disabled>
                    Select
                  </option>
                  {this.state.declineReasonCategories.map((value) => (
                    <option value={value.id}>{value.categoryName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-12">
                <label>Comment</label>
                <textarea
                  autoComplete="off"
                  type="name"
                  style={{ height: 230, resize: "none" }}
                  class="form-control"
                  placeholder="More details"
                  onChange={(event) =>
                    this.setState({ declineReason: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );

    const ctaWidgetsForDraftApplication = (
      <React.Fragment>
        {this.toShowUpdateButton && !this.state.canSave && (
          <button
            type="button"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            class="btn btn-primary"
            onClick={this.onUpdateButtonClick}
          >
            Edit
          </button>
        )}
        {this.state.canSave && (
          <button
            disabled={!this.canSave}
            type="button"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            class="btn btn-primary"
            onClick={this.onSaveButtonClick}
          >
            Save
          </button>
        )}
        {this.state.canSave && (
          <button
            disabled={!this.canSubmit}
            type="button"
            style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
            class="btn btn-primary"
            onClick={this.onSubmitButtonClick}
          >
            Submit
          </button>
        )}
      </React.Fragment>
    );

    let ctaWidgetsForSubmittedApplication = null;
    if (true) {
      ctaWidgetsForSubmittedApplication = (
        <React.Fragment>
          {this.toShowValidationButton && (
            <ProtectedComponent
              requiredPermissions={[CAN_VALIDATE_APPLICATIONS]}
            >
              <button
                type="button"
                class="btn btn-primary"
                style={{ backgroundColor: "#00b8de", borderColor: "#00b8de" }}
                data-toggle="modal"
                data-target={
                  this.state.application.agentClassId
                    ? "#preview_application_modal"
                    : this.state.metdataFormData.agentClass
                    ? "#preview_application_modal"
                    : ""
                }
                onClick={this.onCheckAgentClass}
              >
                Validate
              </button>
            </ProtectedComponent>
          )}
          {this.toShowApprovalButton && (
            <ProtectedComponent
              requiredPermissions={[CAN_APPROVE_APPLICATIONS]}
            >
              <button
                type="button"
                class="btn btn-primary"
                style={{ backgroundColor: "#00b8de", borderColor: "#00b8de" }}
                data-toggle="modal"
                data-target="#preview_application_modal"
              >
                Approve
              </button>
            </ProtectedComponent>
          )}
          {(this.toShowValidationButton || this.toShowApprovalButton) && (
            <ProtectedComponent
              requiredPermissions={[
                CAN_VALIDATE_APPLICATIONS,
                CAN_APPROVE_APPLICATIONS,
              ]}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-toggle="modal"
                data-target="#kt_modal_1"
              >
                Decline
              </button>
            </ProtectedComponent>
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />

        <ContentHead
          title={"Application Management"}
          subtitle={"Application Details"}
          withoutSearch
          ctaWidgets={
            this.state.application.applicationType === "SUBMITTED"
              ? ctaWidgetsForSubmittedApplication
              : ctaWidgetsForDraftApplication
          }
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_APPLICATION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridItem>
            <ApplicantDetailsForm
              showPhoneInput={this.state.showPhoneInput}
              kycCheckList={this.state.application}
              applicantDetails={this.state.application.applicantDetails}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              identificationTypes={this.state.identificationTypes}
              countries={Countries}
              states={this.state.states}
              lgas={this.state.applicantLgas}
              updateSetState={this.updateSetState}
              updateCountry={this.updateCountry}
              getCountryName={this.getCountryName}
              getIdentificationTypeName={this.getIdentificationTypeName}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              updateState={this.updateState}
              disabled={this.state.readOnly}
              disabledApplicantPhone={true}
              onChange={this.updateApplicantDetailsForm}
              phoneIsValid={this.state.phoneIsValid}
              emailIsValid={!this.props.emailIsValid}
              checkEmailIsValid={checkEmailIsValid}
              showAgentType={true}
            />
            <BusinessDetailsForm
              showPhoneInput={this.state.showPhoneInput}
              businessTypes={this.state.businessTypes}
              businessDetailsFormData={this.state.businessDetailsFormData}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              states={this.state.states}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              lgas={this.state.businessLgas}
              banks={Banks}
              applicantDetails={this.state.application.applicantDetails}
              businessDetails={this.state.application.businessDetails}
              updateSetState={this.updateSetState}
              updateCountry={this.updateCountry}
              updateState={this.updateState}
              disabled={this.state.readOnly}
              onChange2={this.updateApplicantDetailsForm}
              onChange={this.updateBusinessDetailsForm}
              businessPhoneIsValid={this.state.phoneIsValid}
              accountNumberIsValid={this.state.accountNumberIsValid}
              bvnIsValid={this.state.bvnIsValid}
              companyRegistrationNumberIsValid={
                this.state.companyRegistrationNumberIsValid
              }
              checkCompanyRegistrationNumberIsValid={
                checkCompanyRegistrationNumberIsValid
              }
              checkBvnIsValid={checkBvnIsValid}
              checkAccountNumberIsValid={checkAccountNumberIsValid}
              shouldUpdateBusinessAddress={
                this.state.shouldUpdateBusinessAddress
              }
              updateBusinessAddress={this.updateBusinessAddress}
              businessEmailIsValid={this.state.businessEmailIsValid}
            />
            <NextOfKinDetailsForm
              showPhoneInput={this.state.showPhoneInput}
              applicantDetailsFormData={this.state.applicantDetailsFormData}
              relationships={Relationships}
              nextOfKinDetails={
                this.state.application.applicantDetails.nextOfKin
              }
              updateSetState={this.updateSetState}
              disabled={this.state.readOnly}
              onChange={this.updateNextOfKinDetailsForm}
              nextOfKinPhoneIsValid={this.state.phoneIsValid}
              checkEmailIsValid={checkEmailIsValid}
              shouldUpdateNextOfKinAddress={
                this.state.shouldUpdateNextOfKinAddress
              }
              updateNextOfKinAddress={this.updateNextOfKinAddress}
            />
            <KycDocuments
              showUploadForm={this.state.showUploadForm}
              showUploadedDocuments={this.state.showUploadedDocuments}
              kycDocuments={this.state.kycDocuments}
              documentErrors={this.state.documentErrors}
              utilityBills={UtilityBills}
              personalDocuments={PersonalDocuments}
              uploadedDocuments={this.state.uploadedDocuments}
              documentUploadProgress={this.state.documentUploadProgress}
              disabled={true}
              readNationalIdUrl={this.readNationalIdUrl}
              passportPhotoProgress={this.state.passportPhotoProgress}
              characterAttestationProgress={
                this.state.characterAttestationProgress
              }
              incorporationCertificateProgress={
                this.state.incorporationCertificateProgress
              }
              personalDocumentProgress={this.state.personalDocumentProgress}
              utilityBillProgress={this.state.utilityBillProgress}
              uploadErrorMessage={this.state.uploadErrorMessage}
              uploadSuccessMessage={this.state.uploadSuccessMessage}
              ref={this.kycDocumentSectionRef}
              updateSetState={this.updateSetState}
              personalDocumentType={this.state.personalDocumentType}
              utilityBillType={this.state.utilityBillType}
              canUploadDocument={!this.state.canSave}
              otherDocumentName={this.state.otherDocumentName}
              uploadedOtherDocuments={this.state.uploadedOtherDocuments}
            />
            <AgentClassAssignment
              toShowValidationButton={this.toShowValidationButton}
              toShowApprovalButton={this.toShowApprovalButton}
              agentAssignmentRef={this.metadataSectionRef}
              agentClasses={this.state.agentClasses}
              agentTypes={AgentTypes}
              agentTypeId={this.state.application.agentTypeId}
              approvalStatus={this.state.application.approvalStatus}
              superAgents={this.state.aggregators}
              referralCode={this.state.application.referralCode}
              agentClassId={this.state.application.agentClassId}
              onChange={this.updateAgentAssignment}
              isAgentClassLoading={this.state.isAgentClassLoading}
              isAggregatorListLoading={this.state.isAggregatorListLoading}
              getAgentClasses={this.getAgentClasses}
              getAggregatorList={this.getAggregatorList}
            />
            <DeclineDetails
              toShowDeclineReason={this.toShowDeclineReason}
              application={this.state.application}
            />
            <OtherDetails
              disabled={true}
              applicantDetails={this.state.application}
            />
            {/* <PdfModalComponent 
            modalTitle={`PDF Viewer`}
            src={this.state.documentClickUrl}
            filename={this.state.documentClickFilename}
            modalSize={`modal-lg`}
          /> */}
            <PromptModal
              modalTitle={`Decline Application`}
              modalBody={declineModalBody}
              deleteModal={this.onModalDeclineButtonClick}
              modalSize={`modal-md`}
              height={`570px`}
              textAlign={`left`}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Decline`}
              modalFooterButton2Disabled={
                !(this.state.declineReasonCategory && this.state.declineReason)
              }
              hideModalHeader={true}
              hideModalFooter={true}
            />
            <ApplicationPreviewModal
              applicantDetailsSectionRef={this.applicantDetailsSectionRef}
              application={this.state.application}
              getIdentificationTypeName={this.getIdentificationTypeName}
              getCountryName={this.getCountryName}
              getStateName={this.getStateName}
              getLgaName={this.getLgaName}
              uploadedDocuments={this.state.uploadedDocuments}
              getAgentClassName={this.getAgentClassName}
              getAggregatorName={this.getAggregatorName}
              metdataFormData={this.state.metdataFormData}
              toShowValidationButton={this.toShowValidationButton}
              onValidateButtonClick={this.onValidateButtonClick}
              toShowApprovalButton={this.toShowApprovalButton}
              onApproveButtonClick={this.onApproveButtonClick}
            />
          </GridItem>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
