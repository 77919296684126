    import React from 'react'
import { formatCurrency } from '../../../../../../utils/formatter'
import { convertToMajor } from '../../../../../../utils/converter'

export default class WalletDistributionTable extends React.Component {
    render() {
        return <React.Fragment>
            {this.props.distributeFormData.length ? <div class="form-group col-lg-12 row" style={{marginTop: '40px'}}>
                <div class="col-lg-4">
                    <label>Agent Name</label>
                </div>
                <div class="col-lg-3">
                    <label>Phone</label>
                </div>
                <div class="col-lg-3">
                    <label>Amount(&#8358;)</label>
                </div>
            </div>: ''}
            {this.props.distributeFormData.map(value =>
                <div key={value.agentCode}>
                    <div class="form-group col-lg-12 row" style={{marginBottom: '0px'}}>
                        <div class="col-lg-4">
                            <span>{value.agentName}</span>
                        </div>
                        <div class="col-lg-3">
                            <span>{value.beneficiaryPhone}</span>
                        </div>
                        <div class="col-lg-3">
                            <span hidden={this.props.state[value.agentCode]}>{formatCurrency(convertToMajor(value.amount))}</span>
                            {/* <i class="fas fa-redo fa-spin"></i> */}
                            <input type="text" class="form-control" defaultValue={convertToMajor(value.amount)} hidden={!this.props.state[value.agentCode]} onChange={event => this.props.updateWalletDistributionFormData('updatedAmount', event.target.value)}/>
                        </div>
                        {!this.props.canDistribute && <div class="col-lg-2">
                            <a style={{cursor: 'pointer', marginRight: '20px'}} hidden={this.props.state[value.agentCode]} onClick={() => this.props.editAgentAmount(value.agentCode)}>Update</a>
                            <a style={{cursor: 'pointer', marginRight: '20px'}} hidden={!this.props.state[value.agentCode]} onClick={() => this.props.updateAgentAmount(value.agentCode, value.amount)}>Save</a>
                            <a style={{cursor: 'pointer'}} onClick={() => this.props.removeDistribute(value.agentCode, value.amount)}>Delete</a>
                        </div>}
                        {this.props.canDistribute && <div class="col-lg-1">
                            <span>{value.status}</span>
                        </div>}
                    </div>
                    <hr style={{width: this.props.canDistribute ? '660px' : '860px', float: 'left'}}/>
                </div>
            )} 
        </React.Fragment>
    }
}