import React from "react";
import { formatBackEndDate } from "../../utils/formatter";
import { isPhoneNumberValid, stripNumbersFromString } from "../../utils/helper";
import { DatePickerComponent } from "../date-picker";
import PhoneNumberComponent from "../phone-number-component";
import { checkEmailIsValid } from "../../utils/validator";

export default class KycChecklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBvnInfo: false,
      showNinInfo: false,
      showTinInfo: false,
      showCacInfo: false,
    };
  }

  toggleBvnInfo = () => {
    this.setState((prevState) => ({
      showBvnInfo: !prevState.showBvnInfo,
    }));
  };

  toggleNinInfo = () => {
    this.setState((prevState) => ({
      showNinInfo: !prevState.showNinInfo,
    }));
  };

  toggleTinInfo = () => {
    this.setState((prevState) => ({
      showTinInfo: !prevState.showTinInfo,
    }));
  };

  toggleCacInfo = () => {
    this.setState((prevState) => ({
      showCacInfo: !prevState.showCacInfo,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="kt-portlet">
          {!this.props.hideTitleHeader && (
            <div
              className="kt-portlet__head"
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target="#collapseOne11"
              aria-controls="collapseOne11"
            >
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Kyc Details</h3>
              </div>
            </div>
          )}

          <div id="collapseOne11" class="collapse show">
            <div
              className="kt-portlet__head-label"
              style={{ cursor: "pointer", marginLeft: "20px" }}
              onClick={this.toggleBvnInfo}
            >
              <h5 className="kt-portlet__head-title">BVN Information</h5>
            </div>

            {this.state.showBvnInfo && (
              <form id="collapseOne1" class="collapse show">
                <div class="kt-portlet__body">
                  <div class="form-group row">
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div class="col-lg-4 mb-4">
                          <label>BV Number:</label>
                          <div class="input-group">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="text"
                              class="form-control"
                              defaultValue={
                                this.props.applicantDetails.bvn || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange("bvn", event.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div className="col-lg-4 mb-4">
                          <label>BVN Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={this.props.disabled || ""}
                              type="text"
                              className="form-control"
                              defaultValue={
                                this.props.applicantDetails
                                  .bvnVerificationStatus || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange(
                                  "bvnVerificationStatus",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.firstName && (
                        <div class="col-lg-4 mb-4">
                          <label>First Name:</label>
                          <input
                            autoComplete="off"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            type="name"
                            class="form-control"
                            defaultValue={
                              this.props.applicantDetails.firstName || ""
                            }
                            placeholder="First name"
                            onChange={(event) =>
                              this.props.onChange(
                                "firstName",
                                stripNumbersFromString(event.target.value)
                              )
                            }
                          />
                        </div>
                      )}

                    {this.props.applicantDetails && (
                      <div class="col-lg-4 mb-4">
                        <label>Middle Name: </label>
                        <input
                          autoComplete="off"
                          type="name"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          class="form-control"
                          defaultValue={""}
                          placeholder="Middle name"
                          onChange={(event) =>
                            this.props.onChange(
                              "middleName",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.lastName && (
                        <div class="col-lg-4 mb-4">
                          <label>Last Name:</label>
                          <input
                            type="name"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            class="form-control"
                            defaultValue={
                              this.props.applicantDetails.lastName || ""
                            }
                            placeholder="Last name"
                            onChange={(event) =>
                              this.props.onChange(
                                "lastName",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div class="col-lg-4 mb-4">
                          <label>Date of Birth:</label>
                          <div class="input-group">
                            <DatePickerComponent
                              selected={
                                this.props.applicantDetailsFormData &&
                                this.props.applicantDetailsFormData.dob
                                  ? new Date(
                                      this.props.applicantDetailsFormData.dob
                                    )
                                  : this.props.applicantDetails &&
                                    this.props.applicantDetails.dob
                                  ? new Date(this.props.applicantDetails.dob)
                                  : ""
                              }
                              onChange={(event) =>
                                this.props.onChange(
                                  "dob",
                                  formatBackEndDate(event)
                                )
                              }
                              size={`270px`}
                              format={`dd/MM/yyyy`}
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                            />
                          </div>
                        </div>
                      )}

                    {((this.props.applicantDetails &&
                      this.props.applicantDetails.phoneNumber) ||
                      this.props.showPhoneInput) && (
                      <div class="col-lg-4 mb-4">
                        <label>Phone Number:</label>
                        <div class="kt-input-icon">
                          <PhoneNumberComponent
                            phoneIsValid={this.props.phoneIsValid || true}
                            onChange={(status, phoneNumber) => {
                              this.props.onChange("phone", phoneNumber);
                              this.props.updateSetState(
                                "phoneIsValid",
                                status || isPhoneNumberValid(phoneNumber)
                              );
                            }}
                            defaultValue={
                              (this.props.applicantDetails &&
                                this.props.applicantDetails.phoneNumber) ||
                              ""
                            }
                            disabled={this.props.disabled || ""}
                          />
                        </div>
                      </div>
                    )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.gender && (
                        <div class="col-lg-4 mb-4">
                          <label>Gender:</label>
                          <div class="col-9">
                            <div class="kt-radio-inline">
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={this.props.disabled || ""}
                                  type="radio"
                                  name="gender"
                                  value="MALE"
                                  defaultChecked={
                                    this.props.applicantDetails &&
                                    this.props.applicantDetails.gender &&
                                    this.props.applicantDetails.gender.toUpperCase() ===
                                      "MALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Male
                                <span></span>
                              </label>
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={this.props.disabled || ""}
                                  type="radio"
                                  name="gender"
                                  value="FEMALE"
                                  defaultChecked={
                                    this.props.applicantDetails &&
                                    this.props.applicantDetails.gender &&
                                    this.props.applicantDetails.gender.toUpperCase() ===
                                      "FEMALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Female
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </form>
            )}

            <div
              className="kt-portlet__head-label"
              style={{ cursor: "pointer", marginLeft: "20px" }}
              onClick={this.toggleNinInfo}
            >
              <h5 className="kt-portlet__head-title">NIN Information</h5>
            </div>

            {this.state.showNinInfo && (
              <form id="collapseOne1" class="collapse show">
                <div class="kt-portlet__body">
                  <div class="form-group row">
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div class="col-lg-4 mb-4">
                          <label>NI Number:</label>
                          <div class="input-group">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="text"
                              class="form-control"
                              defaultValue={
                                this.props.applicantDetails.bvn || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange("bvn", event.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div className="col-lg-4 mb-4">
                          <label>NIN Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={this.props.disabled || ""}
                              type="text"
                              className="form-control"
                              defaultValue={
                                this.props.applicantDetails
                                  .bvnVerificationStatus || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange(
                                  "bvnVerificationStatus",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.firstName && (
                        <div class="col-lg-4 mb-4">
                          <label>First Name:</label>
                          <input
                            autoComplete="off"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            type="name"
                            class="form-control"
                            defaultValue={
                              this.props.applicantDetails.firstName || ""
                            }
                            placeholder="First name"
                            onChange={(event) =>
                              this.props.onChange(
                                "firstName",
                                stripNumbersFromString(event.target.value)
                              )
                            }
                          />
                        </div>
                      )}
                    {this.props.applicantDetails && (
                      <div class="col-lg-4 mb-4">
                        <label>Middle Name: </label>
                        <input
                          autoComplete="off"
                          type="name"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          class="form-control"
                          defaultValue={
                            this.props.applicantDetails.middleName || ""
                          }
                          placeholder="Middle name"
                          onChange={(event) =>
                            this.props.onChange(
                              "middleName",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    )}
                    
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.lastName && (
                        <div class="col-lg-4 mb-4">
                          <label>Last Name:</label>
                          <input
                            type="name"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            class="form-control"
                            defaultValue={
                              this.props.applicantDetails.lastName || ""
                            }
                            placeholder="Last name"
                            onChange={(event) =>
                              this.props.onChange(
                                "lastName",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div class="col-lg-4 mb-4">
                          <label>Date of Birth:</label>
                          <div class="input-group">
                            <DatePickerComponent
                              selected={
                                this.props.applicantDetailsFormData &&
                                this.props.applicantDetailsFormData.dob
                                  ? new Date(
                                      this.props.applicantDetailsFormData.dob
                                    )
                                  : this.props.applicantDetails &&
                                    this.props.applicantDetails.dob
                                  ? new Date(this.props.applicantDetails.dob)
                                  : ""
                              }
                              onChange={(event) =>
                                this.props.onChange(
                                  "dob",
                                  formatBackEndDate(event)
                                )
                              }
                              size={`270px`}
                              format={`dd/MM/yyyy`}
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                            />
                          </div>
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.gender && (
                        <div class="col-lg-4 mb-4">
                          <label>Gender:</label>
                          <div class="col-9">
                            <div class="kt-radio-inline">
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={this.props.disabled || ""}
                                  type="radio"
                                  name="gender"
                                  value="MALE"
                                  defaultChecked={
                                    this.props.applicantDetails &&
                                    this.props.applicantDetails.gender &&
                                    this.props.applicantDetails.gender.toUpperCase() ===
                                      "MALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Male
                                <span></span>
                              </label>
                              <label class="kt-radio">
                                <input
                                  autoComplete="off"
                                  disabled={this.props.disabled || ""}
                                  type="radio"
                                  name="gender"
                                  value="FEMALE"
                                  defaultChecked={
                                    this.props.applicantDetails &&
                                    this.props.applicantDetails.gender &&
                                    this.props.applicantDetails.gender.toUpperCase() ===
                                      "FEMALE"
                                  }
                                  onChange={(event) =>
                                    this.props.onChange(
                                      "gender",
                                      event.target.value
                                    )
                                  }
                                />{" "}
                                Female
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </form>
            )}

            <div
              className="kt-portlet__head-label"
              style={{ cursor: "pointer", marginLeft: "20px" }}
              onClick={this.toggleTinInfo}
            >
              <h5 className="kt-portlet__head-title">TIN Information</h5>
            </div>
            {this.state.showTinInfo && (
              <form id="collapseOne1" class="collapse show">
                <div class="kt-portlet__body">
                  <div class="form-group row">
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div class="col-lg-4 mb-4">
                          <label>TI Number:</label>
                          <div class="input-group">
                            <input
                              autoComplete="off"
                              disabled={
                                this.props.disabled ? this.props.disabled : ""
                              }
                              type="text"
                              class="form-control"
                              defaultValue={
                                this.props.applicantDetails.bvn || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange("bvn", event.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div className="col-lg-4 mb-4">
                          <label>TIN Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={this.props.disabled || ""}
                              type="text"
                              className="form-control"
                              defaultValue={
                                this.props.applicantDetails
                                  .bvnVerificationStatus || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange(
                                  "bvnVerificationStatus",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.firstName && (
                        <div class="col-lg-4 mb-4">
                          <label>Taxpayer Name:</label>
                          <input
                            autoComplete="off"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            type="name"
                            class="form-control"
                            defaultValue={
                              this.props.applicantDetails.firstName || ""
                            }
                            placeholder="First name"
                            onChange={(event) =>
                              this.props.onChange(
                                "firstName",
                                stripNumbersFromString(event.target.value)
                              )
                            }
                          />
                        </div>
                      )}
                    {this.props.applicantDetails &&
                      this.props.applicantDetails.middleName && (
                        <div class="col-lg-4 mb-4">
                          <label>RC Number: </label>
                          <input
                            autoComplete="off"
                            type="name"
                            disabled={
                              this.props.disabled ? this.props.disabled : ""
                            }
                            class="form-control"
                            defaultValue={
                              this.props.applicantDetails.middleName || ""
                            }
                            placeholder="RC Number"
                            onChange={(event) =>
                              this.props.onChange(
                                "middleName",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      )}
                    {this.props.applicantDetails && (
                      <div class="col-lg-4 mb-4">
                        <label>Entity Type:</label>
                        <input
                          type="name"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          class="form-control"
                          defaultValue={""}
                          placeholder="Entity Type"
                          onChange={(event) =>
                            this.props.onChange("lastName", event.target.value)
                          }
                        />
                      </div>
                    )}

                    {((this.props.applicantDetails &&
                      this.props.applicantDetails.phoneNumber) ||
                      this.props.showPhoneInput) && (
                      <div class="col-lg-4 mb-4">
                        <label>Phone Number:</label>
                        <div class="kt-input-icon">
                          <PhoneNumberComponent
                            phoneIsValid={this.props.phoneIsValid || true}
                            onChange={(status, phoneNumber) => {
                              this.props.onChange("phone", phoneNumber);
                              this.props.updateSetState(
                                "phoneIsValid",
                                status || isPhoneNumberValid(phoneNumber)
                              );
                            }}
                            defaultValue={
                              (this.props.applicantDetails &&
                                this.props.applicantDetails.phoneNumber) ||
                              ""
                            }
                            disabled={this.props.disabled || ""}
                          />
                        </div>
                      </div>
                    )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.emailAddress && (
                        <div class="col-lg-4 mb-4">
                          <label>Email:</label>
                          <div class="kt-input-icon">
                            <input
                              autoComplete="off"
                              disabled={this.props.disabled || ""}
                              type="email"
                              class="form-control"
                              defaultValue={
                                this.props.applicantDetails
                                  ? this.props.applicantDetails.emailAddress
                                  : ""
                              }
                              placeholder="Enter email address"
                              onChange={(event) => {
                                this.props.updateSetState(
                                  "emailIsValid",
                                  checkEmailIsValid(event.target.value)
                                );
                                this.props.onChange(
                                  "email",
                                  event.target.value
                                );
                              }}
                            />
                            {this.props.applicantDetailsFormData.email &&
                              !this.props.emailIsValid && (
                                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                  <span>
                                    <i
                                      class="la la-info-circle"
                                      style={{ color: "red" }}
                                    ></i>
                                  </span>
                                </span>
                              )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </form>
            )}

            <div
              className="kt-portlet__head-label"
              style={{ cursor: "pointer", marginLeft: "20px" }}
              onClick={this.toggleCacInfo}
            >
              <h5 className="kt-portlet__head-title">CAC Information</h5>
            </div>

            {this.state.showCacInfo && (
              <form id="collapseOne1" class="collapse show">
                <div class="kt-portlet__body">
                  <div class="form-group row">
                    {this.props.applicantDetails && (
                      <div class="col-lg-4 mb-4">
                        <label>RC Number: </label>
                        <input
                          autoComplete="off"
                          type="name"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          class="form-control"
                          defaultValue={""}
                          placeholder="RC Number"
                          onChange={(event) =>
                            this.props.onChange(
                              "middleName",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    )}

                    {this.props.applicantDetails &&
                      this.props.applicantDetails.dob && (
                        <div className="col-lg-4 mb-4">
                          <label>CAC Status:</label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              disabled={this.props.disabled || ""}
                              type="text"
                              className="form-control"
                              defaultValue={
                                this.props.applicantDetails
                                  .bvnVerificationStatus || ""
                              }
                              placeholder=""
                              onChange={(event) =>
                                this.props.onChange(
                                  "bvnVerificationStatus",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      )}

                    {this.props.applicantDetails && (
                      <div class="col-lg-4 mb-4">
                        <label>Company/Business name:</label>
                        <input
                          type="name"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          class="form-control"
                          defaultValue={""}
                          placeholder="Company/Business name"
                          onChange={(event) =>
                            this.props.onChange("lastName", event.target.value)
                          }
                        />
                      </div>
                    )}
                    {this.props.applicantDetails && (
                      <div class="col-lg-4 mb-4">
                        <label>Business Type:</label>
                        <input
                          type="name"
                          disabled={
                            this.props.disabled ? this.props.disabled : ""
                          }
                          class="form-control"
                          defaultValue={""}
                          placeholder="Business Type"
                          onChange={(event) =>
                            this.props.onChange("lastName", event.target.value)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
