import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseScene from "../../../../components/scenes/component";
import {
  CAN_MANAGE_AGENT_DOMAIN,
  CAN_MANAGE_AGGREGATOR_DOMAIN, CAN_MANAGE_SP_DOMAIN, CAN_VIEW_AGENT_DEVICE, CAN_VIEW_AGENT_ROLE, CAN_VIEW_AGENT_USER, CAN_VIEW_AGGREGATOR_ROLE, CAN_VIEW_AGGREGATOR_USER, CAN_VIEW_SP_ROLE, CAN_VIEW_SP_USER
} from "../../../../constants/permissions";
import { isAuthorized, saveOtherInformation } from "../../../../mixins/authentication";
import PlatformManagement from "../../../../services/api/resources/platform-management";
import DomainHeader from "./components/header";
import DomainDeviceScene from "./scenes/device-management";
import DomainInformationGridScene from "./scenes/domain-information";
import DomainRoleScene from "./scenes/role-management";
import DomainUserScene from "./scenes/user-management";
// import ChangePasswordGridScene from './scenes/change-password'
// import AccountSettingsGridScene from './scenes/account-settings'
// import UserSettingsGridScene from './scenes/user-settings'

export default class DomainManagementScene extends BaseScene {
  sceneName = "domain-management";

  domainCode = this.props.match.params.domainCode;

  state = {
    domainName: null,
  };

  platformManagement = new PlatformManagement();

  componentDidMount() {
    //this.props.updateScene(this.sceneName)
    //this.domainCode = this.props.match.params.domainCode
    this.loadApplication();
  }

  async loadApplication() {
    const {
      
      response,
    } = await this.platformManagement.getAgentByAgentCode(this.domainCode);
    this.setState({
      domainTypeId: response.agentTypeId,
      domainName: response.businessName,
      domainPhoneNo: response.businessPhoneNo,
      domainAddress: response.businessLocation
        ? `${
            response.businessLocation[0].addressLine1
              ? `${response.businessLocation[0].addressLine1}, `
              : ""
          }${
            response.businessLocation[0].lga
              ? `${response.businessLocation[0].lga}, `
              : ""
          }${
            response.businessLocation[0].state
              ? `${response.businessLocation[0].state}, `
              : ""
          }${
            response.businessLocation[0].country
              ? `${response.businessLocation[0].country}`
              : ""
          }`
        : "",
    });
    saveOtherInformation(JSON.stringify("domainInfo", response));
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <DomainHeader
          domainTypeId={this.state.domainTypeId}
          domainCode={this.domainCode}
          domainName={this.state.domainName}
          domainPhoneNo={this.state.domainPhoneNo}
          domainAddress={this.state.domainAddress}
        />
        <Switch>
          <ProtectedRoute
            requiredPermissions={[
              CAN_MANAGE_SP_DOMAIN,
              CAN_MANAGE_AGGREGATOR_DOMAIN,
              CAN_MANAGE_AGENT_DOMAIN,
            ]}
            exact
            path="/home/domain-management/:domainCode"
            component={DomainInformationGridScene}
          />
          <ProtectedRoute
            requiredPermissions={[
              CAN_VIEW_SP_ROLE,
              CAN_VIEW_AGGREGATOR_ROLE,
              CAN_VIEW_AGENT_ROLE,
            ]}
            path="/home/domain-management/:domainCode/roles"
            component={DomainRoleScene}
          />
          <ProtectedRoute
            requiredPermissions={[
              CAN_VIEW_SP_USER,
              CAN_VIEW_AGGREGATOR_USER,
              CAN_VIEW_AGENT_USER,
            ]}
            path="/home/domain-management/:domainCode/users"
            component={DomainUserScene}
          />
          <ProtectedRoute
            requiredPermissions={[CAN_VIEW_AGENT_DEVICE]}
            path="/home/domain-management/:domainCode/devices"
            component={DomainDeviceScene}
          />
          {/* <Route exact path='/home/profile/change-password' component={ChangePasswordGridScene} />
        <Route exact path='/home/profile/account-settings' component={AccountSettingsGridScene} />
        <Route exact path='/home/profile/user-settings' component={UserSettingsGridScene} /> */}
        </Switch>
      </Router>
    );
  }
}
