import React from 'react';
import { Link } from 'react-router-dom'
import ContentHead from '../../../../../../../components/content-head';
import BaseScene from '../../../../../../../components/scenes/component';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal'
import { CAN_VIEW_FIP_USER, CAN_CREATE_FIP_USER, CAN_ACTIVATE_FIP_USER, CAN_DEACTIVATE_FIP_USER, CAN_RETIRE_FIP_USER } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { SUCCESS_STATUS, ERROR_STATUS, ACCEPTED_REQUEST } from '../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../components/access-denied';
import { phoneNumberFormatter, formatDate, pastTenseFormatter, formatBackEndDateTime } from '../../../../../../../utils/formatter'
import { getDateByType } from '../../../../../../../utils/helper'
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { AGENT_TYPE_ID } from '../../../../../../../constants/domain-types'
import { instrumentationService } from '../../../../../../../mixins/api'
import TraceTable from '../../components/trace-table'
import FIPS from '../../../../../../../fixtures/fips.json'
import TraceSearch from '../../components/trace-search'
import Table from '../../../../../../../components/table';
import { FipExportSerializer } from '../../../../../../../serializers'

export default class TraceGridScene extends BaseScene {

  requiredPermissions = [
    CAN_VIEW_FIP_USER
  ]

  constructor(props) {
    super(props);
    this.state = {
      traceData: [],
      searchParameters: {
        startDate: null,
        endDate: null,
        firstName: "",
        lastName: "",
        mobileNo: "",
        status: ""
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
    }
    this.getTraceData = this.getTraceData.bind(this)
    this.getClickedAction = this.getClickedAction.bind(this)
    this.handleSearchRequest = this.handleSearchRequest.bind(this)
  }

  sceneName = "instrumentation"

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  getClickedAction (username, firstName, lastName, status, domainCode) {
    this.setState({
      fipUsername: username,
      fipStatus: status,
      fipDomainCode: domainCode,
      fipFirstName: firstName,
      fipLastName: lastName
    })
  }

  async getTraceData(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true
    })
    let searchParameters = this.state.searchParameters
    const { startDate, endDate } = searchParameters
    searchParameters.startDate = startDate && formatBackEndDateTime(startDate)
    searchParameters.endDate = endDate && formatBackEndDateTime(endDate)
    searchParameters.pageNum = pageNum - 1
    searchParameters.pageSize = pageSize
    const responseObj = await instrumentationService.getTraceData(searchParameters)
    const { status, response, count } = responseObj
    console.log("trace data", response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        traceData: response.items,
        dataCount: response.totalCount,
        isLoading: false,
        pages: Math.ceil(response.totalCount/pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: !response.totalCount || response.totalCount == 0  ? "No trace data found" : ""
      })
    }else{
      this.setState({
        traceData: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 0,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No trace data found"
      })
    }
  }

  handleSearchRequest (searchParameters) {
    this.setState({loading: true})
    this.setState({pageIndex: 1})
    this.setState({startPage: 1})
    this.setState({
      startPage: 1,
      searchParameters
    })
    setTimeout(() =>{
      this.getTraceData(1, 20)
      this.setState({loading: false})
    })
  }

  render () {

    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Instrumentation"} subtitle={'Traces'} filterPermissions={[CAN_VIEW_FIP_USER]} totalCount={56} />
      <ProtectedComponent requiredPermissions={[CAN_VIEW_FIP_USER]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <TraceSearch 
            handleSearchRequest={this.handleSearchRequest}
          />
          <TraceTable 
            data={this.state.traceData}
            loading={this.state.isLoading} 
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            pages={this.state.pages}
            startPage={this.state.startPage}
            getTraceData={this.getTraceData}
            getClickedAction={this.getClickedAction}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canActivate={CAN_ACTIVATE_FIP_USER}
            canDeactivate={CAN_DEACTIVATE_FIP_USER}
            canRetire={CAN_RETIRE_FIP_USER}
            canView={CAN_VIEW_FIP_USER}
            canLoadData={this.state.canLoadData}
          />
          <PromptModal
            modalTitle={ this.state.fipStatus+` FIP`}
            modalBody={`Do you want to ${this.state.fipStatus} ${this.state.fipFirstName} ${this.state.fipLastName} as an FIP?`}
            deleteModal={this.updateFip}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

    get permissionDeniedContent() {
      return <AccessDenied /> 
    }
}
