import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import UserManagement from '../../services/api/resources/user-management';
import { clearLocalStorage } from '../../mixins/authentication'

export default class LogoutScene extends Component {
  logUserOut() {
    const currentUser = JSON.parse(localStorage.getItem("user"))
    clearLocalStorage();
    if(currentUser) {
      const userManagement = new UserManagement();
      const getUserRolesResponseObj = userManagement.logoutUser(currentUser.access_token)
    }
  }

  render() {
    this.logUserOut()
    return <Redirect to="/login" />
  }
}