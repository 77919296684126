import Requester from '../requester'

const API_BASE_URL = process.env.REACT_APP_NIP_BANK_SERVICE_API_BASE_URL

export default class nipbankService {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }
    
    modifyBankDetails (bankCode, payload) {
        return this.apiRequester.put({
            endpoint: `bank/${bankCode}`,
            body: payload
        })
    }

    addNIPBank (payload) {
        return this.apiRequester.post({
            endpoint: `create/bank`,
            body: payload
        })
    }

    getNIPBankList (payload) {
        return this.apiRequester.post({
            endpoint: `search`,
            body:payload
        })
    } 

    getNIPBankByBankCode (bankCode) { 
        return this.apiRequester.get({
            endpoint: `bankDetails/${bankCode}`
        })
    }
}
