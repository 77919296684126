import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import Pagination from "../../../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../../../components/spinner";
import ProtectedComponent from "../../../../../../../../mixins/protected-component";
import { getPaginationRows } from "../../../../../../../../utils/helper";

export default class PosRequestsAwaitingConfigUploadTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
  processValuesWithUnderscore = this.processValuesWithUnderscore.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  capitalizeFirstLetter(word) {
    return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
  }

  processValuesWithUnderscore(value) {
    const words = value.split("_");

    return (
      this.capitalizeFirstLetter(words[0]) +
      " " +
      this.capitalizeFirstLetter(words[1])
    );
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "S/N",
        accessor: "index",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 35,
        headerStyle: { "white-space": "unset" },
      },

      {
        Header: "Workflow Id",
        accessor: "workflowId",
        sortable: false,
        resizable: false,
        width: 200,
        headerStyle: { "white-space": "unset" },
      },
      {
        Header: "POS Request Stock Id",
        accessor: "posRequestStockId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Terminal Type",
        accessor: "terminalType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Terminal Model",
        accessor: "terminalModel",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "Request type",
        accessor: "requestType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        // Cell: (props) => {
        //   return (
        //     <span
        //       class={`badge ${formatTransactionStatusColorCode(props.value)}`}
        //     >
        //       {formatTransactionStatus(props.value)}
        //     </span>
        //   );
        // },
      },
      {
        Header: "Quantity Requested",
        accessor: "quantityRequested",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        // Cell: (props) => (
        //   <span>
        //     &#x20A6;
        //     {props.value
        //       ? formatCurrency(convertToMajor(props.value))
        //       : formatCurrency(0)}
        //   </span>
        // ),
      },
      {
        Header: "Approval Status",
        accessor: "approvalStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: (props) => (
          <div
            className={`${props.value.toLowerCase()}-status`}
            style={{ textAlign: "left" }}
          >
            {props.value.indexOf("_") !== -1
              ? this.processValuesWithUnderscore(props.value)
              : this.capitalizeFirstLetter(props.value)}
          </div>
        ),
      },
      {
        Header: "Configuration Status",
        accessor: "configurationStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 250,
        Cell: (props) => (
          <span
            className={`${props.value.toLowerCase()}-status`}
            style={{ textAlign: "left" }}
          >
            {props.value.indexOf("_") !== -1
              ? this.processValuesWithUnderscore(props.value)
              : this.capitalizeFirstLetter(props.value)}
          </span>
        ),
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => (
          <span
            className={`${props.value.toLowerCase()}-status`}
            style={{ textAlign: "left" }}
          >
            {props.value.indexOf("_") !== -1
              ? this.processValuesWithUnderscore(props.value)
              : this.capitalizeFirstLetter(props.value)}
          </span>
        ),
      },
      {
        Header: "Date Initiated",
        accessor: "dateInitiated",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        // Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: "Action",
        accessor: "requestId",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "20px",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 50,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  // data-toggle="modal"
                  // data-target="#transaction_details_modal"
                  onClick={() =>
                    this.props.getClickedAction(
                      this.props.data[0].posRequestStockId
                        ? this.props.data[0].posRequestStockId
                        : "",
                      this.props.data[0].workflowId
                        ? this.props.data[0].workflowId
                        : ""
                    )
                  }
                  // onClick={() => this.props.getClickedAction(original)}
                  /*href={`/home/agent-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  Configure
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              agentUsername,
              approvalStatus,
              toDate,
              fromDate,
              pageNumber,
              pageSize,
            } = this.props.searchParameters;
            this.setState({ page: state.pageNumber });
            if (this.props.canLoadData) {
              this.props.getAllPosRequestsAwaitingConfigUpload(
                state.page + 1,
                state.pageSize,
                agentUsername,
                approvalStatus,
                toDate,
                fromDate,
                pageNumber,
                pageSize
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "20px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
