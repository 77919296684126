import React from "react";
import { DatePickerComponent } from "../../../../../../components/date-picker";

export const ManageAggregatorSearch = ({ updateAgentSearchParameters, handleSearchRequest, agentSearchParameters = {} }) => {
  return (
    <form
      className="search-params"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearchRequest();
      }}
    >
      <div className="form-group row d-flex align-items-end gap-2">
        <div className="col-md-3">
          <label>Agent ID:</label>
          <input
            type="text"
            className="search-input"
            value={agentSearchParameters.agentCode || ""}
            onChange={(event) => updateAgentSearchParameters("agentCode", event.target.value)}
          />
        </div>
        <div className="col-md-3">
          <label>Business Name:</label>
          <input
            type="text"
            className="search-input"
            value={agentSearchParameters.agentBusinessName || ""}
            onChange={(event) => updateAgentSearchParameters("agentBusinessName", event.target.value)}
          />
        </div>
        <div className="col-md-3">
          <label>Initiator:</label>
          <input
            type="text"
            className="search-input"
            value={agentSearchParameters.migratedBy || ""}
            onChange={(event) => updateAgentSearchParameters("migratedBy", event.target.value)}
          />
        </div>
        <div className="col-md-3">
          <label>Start Date:</label>
          <DatePickerComponent
            selected={agentSearchParameters.startDate || null}
            onChange={(date) => updateAgentSearchParameters("startDate", date)}
            isClearable={true}
            placeholderText="Select Start Date"
          />
        </div>
        <div className="col-md-3">
          <label>End Date:</label>
          <DatePickerComponent
            selected={agentSearchParameters.endDate || null}
            onChange={(date) => updateAgentSearchParameters("endDate", date)}
            isClearable={true}
            placeholderText="Select End Date"
          />
        </div>
        <div className="col-md-3">
          <button type="submit" className="search-button mt-auto">Search</button>
        </div>
      </div>
    </form>
  );
};
