import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import { WalletJournalExportSerializer } from "../../../../../../serializers";
import { convertToMajor } from "../../../../../../utils/converter";
import {
  formatCurrency,
  formatDateTimeV2,
} from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";

export default class WalletLienJournalTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: (props) => <div style={{ textAlign: "left" }}>S/N</div>,
        Cell: (props) => (
          <div style={{ textAlign: "left" }}>{props.index + 1}</div>
        ),
        accessor: "index",
        headerClassName: "table-header",
        className: "table-cell",
        style: { "white-space": "unset" },
        width: 40,
      },
      {
        Header: "Wallet Journal ID",
        accessor: "walletJournalId",
        resizable: true,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 140,
      },
      {
        Header: "Wallet ID",
        accessor: "walletId",
        resizable: true,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 140,
      },
      {
        Header: "Credit Amount",
        accessor: "creditAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 130,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Debit Amount",
        accessor: "debitAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 130,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Remaining Balance",
        accessor: "remainingBalance",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Balance",
        accessor: "balance",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 130,
        Cell: (props) => <span>{props.value}</span>,
      },

      {
        Header: "Journal Entry Date",
        accessor: "journalEntryDate",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 170,
        Cell: (props) => {
          return props.value ? formatDateTimeV2(props.value) : "";
        },
      },
      {
        Header: "Narration",
        accessor: "narration",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 250,
        Cell: (props) => {
          return props.value ? props.value : ""; //<span class={`badge ${formatTransactionStatusColorCode(props.value)}`}>{formatTransactionStatus(props.value)}</span> : ''
        },
      },
      {
        Header: "Journal Description",
        accessor: "journalDescription",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 350,
        Cell: (props) => {
          return props.value ? props.value : "";
        },
      },
      //     {
      //       Header: "Unique Reference",
      //       accessor: 'uniqueReference',
      //       resizable: false,
      //       headerStyle: {'white-space': 'unset'},
      //       style: {'white-space': 'unset'},
      //       width: 170,
      //       Cell: props => {
      //           return props.value ? props.value : ''
      //       }
      //   },
      //   {
      //     Header: "Payment Reference",
      //     accessor: 'paymentReference',
      //     resizable: false,
      //     headerStyle: {'white-space': 'unset'},
      //     style: {'white-space': 'unset'},
      //     width: 170,
      //     Cell: props => {
      //         return props.value ? props.value : ''
      //     }
      // }
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={this.props.data.length > 0 ? this.props.data : false}
          headers={WalletJournalExportSerializer.headerExport()}
          fileName={`${this.props.agentBusinessName} statement of account`}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getWalletJournal}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          downloadWalletJournal={this.props.downloadWalletJournal}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
            display: "block" /*flex*/,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              agentCode,
              startDate,
              endDate,
            } = this.props.searchParameters;
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getWalletJournal(
                state.page + 1,
                state.pageSize,
                2,
                agentCode,
                startDate,
                endDate
              );
            }
          }}
          headerNodeHeight={`1234`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "20px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
