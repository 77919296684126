import Requester from '../requester'
//import {getApprovals,getApprovalById} from '../../../utils/mock'

const API_BASE_URL = process.env.REACT_APP_WORKFLOW_SERVICE_API_BASE_URL

export default class WorkFlowManagement {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }

    getAllApprovals (fromPage = null, pageSize = null, payload) {
        const { actionType, status, username, startDate, endDate } = payload
        return this.apiRequester.get({
            endpoint: `approval/getApprovals`,
            args: {
                fromPage: fromPage - 1,
                pageSize,
                ...(username != null ? { username } : {}),
                ...(status != null ? { status } : {}),
                ...(actionType != null ? { actionType } : {}),
                ...(startDate != null ? { startDate: startDate, endDate: endDate } : {})
            }
        })
    }  

    getApprovalCount (payload) {
        const { actionType, status, username, startDate, endDate } = payload
        return this.apiRequester.get({
            endpoint: `approval/getCount`,
            args: {
                ...(username != null ? { username } : {}),
                ...(status != null ? { status } : {}),
                ...(actionType != null ? { actionType } : {}),
                ...(startDate != null ? { startDate: startDate, endDate: endDate } : {})
            }
        })
    }  

    getApprovalById (id) {
        return this.apiRequester.get({
            endpoint: `approval/approvalById`,
            args: {
                id
            }
        })
    }

    getAllWorkflow () {
        return this.apiRequester.get({
            endpoint: `workflow`,
        })
    }

    updateWorkflowStatus (id, status) {
        return this.apiRequester.get({
            endpoint: `workflow/${id}/${status}`
        })
    }

    getWorkflowDetails (id) {
        return this.apiRequester.get({
            endpoint: `workflow/${id}`
        })
    }

    addWorkflowStepApprovers (workflowId, stepId, user) {
        return this.apiRequester.put({
            endpoint: `workflow/${workflowId}/steps/${stepId}/approvers`,
            body: user
        })
    }

    deleteWorkflowStepApprovers (workflowId, stepId, user) {
        return this.apiRequester.delete({
            endpoint: `workflow/${workflowId}/steps/${stepId}/approvers`,
            body: user
        })
    }

    removeWorkflowStep (workflowId, stepId) {
        return this.apiRequester.delete({
            endpoint: `workflow/${workflowId}/steps/${stepId}`
        })
    }

    moveWorkflowStep (workflowId, moveFrom, moveTo) {
        return this.apiRequester.put({
            endpoint: `workflow/${workflowId}/steps/move`,
            args: {
                from: moveFrom,
                to: moveTo
            }
        })
    }

    createWorkflowStep (workflowId, payload) {
        return this.apiRequester.post({
            endpoint: `workflow/${workflowId}/steps`,
            body: payload
        })
    }

    approveWorkflow (approvalId, payload) {
        return this.apiRequester.post({
            endpoint: `approval/${approvalId}/execute`,
            body: payload
        })
    }

    getApprovalByStatus (status = null) {
        return this.apiRequester.get({
            endpoint: `approval/approvalByStatus`,
            args: {
                ...(status != null ? { status } : {}),
            }
        })
    }

    getApprovalByDate (fromDate, toDate) {
        return this.apiRequester.get({
            endpoint: `approval/approvalByDate`,
            args: {
                fromDate,
                toDate
            }
        })
    }

    getApprovalByActionType (actionType) {
        return this.apiRequester.get({
            endpoint: `approval/approvalByActionType`,
            args: {
                actionType
            }
        })
    }

    closePendingApprovals () {
        return this.apiRequester.post({
            endpoint: `approval/close-pending`
        })
    }

    recallApproval (id, message) {
        return this.apiRequester.post({
            endpoint: `approval/${id}/recall`,
            body: {
                message
            }
        })
    }

    queryWorkflow (fromPage = null, pageSize = null, payload) {
        const { actionType, active, name, end, start } = payload
        return this.apiRequester.post({
            endpoint: `workflow/query`,
            // args: {
            //     fromPage: fromPage - 1,
            //     pageSize,
            //     ...(status != null ? { status } : {}),
            //     ...(actionType != null ? { actionType } : {}),
            // }
            body: {
                pageNumber: fromPage,
                pageSize,
                filter: {
                    ...(name != 'All' ? { name } : {}),
                    ...(active != 'All' ? { active } : {}),
                    ...(actionType != 'All' ? { actionType } : {}),
                },
                range: {
                    ...(start != null ? { start: start, end: end } : {})
                }
            }
        })
    }  

    getWorkflowModules () {
        return this.apiRequester.get({
            endpoint: `workflow/modules`,
        })
    }
}
