import { convertToMajor } from '../../utils/converter';
import { formatCurrency, formatDateTime, formatLongNumber } from '../../utils/formatter';

export default class WalletJournalExportSerializer {
    static headerExport () {
        return [
            { label: 'Transaction Reference', key: 'uniqueReference', title: 'Transaction Reference', dataIndex: 'uniqueReference'},
            { label: 'RRN', key: 'rrn', title: 'RRN', dataIndex: 'rrn'},
            { label: 'Credit Amount', key: 'creditAmount', title: 'Credit Amount', dataIndex: 'creditAmount' },
            { label: 'Debit Amount', key: 'debitAmount', title: 'Debit Amount', dataIndex: 'debitAmount' },
            { label: 'Available Balance', key: 'balance', title: 'Available Balance', dataIndex: 'balance' },
            { label: 'Ledger Balance', key: 'remainingBalance', title: 'Ledger Balance', dataIndex: 'remainingBalance' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
            { label: 'Description', key: 'journalDescription', title: 'Description', dataIndex: 'journalDescription' },
            { label: 'Date', key: 'journalEntryDate', title: 'Date', dataIndex: 'journalEntryDate' }
        ];
    }

    static reverseWalletJournalDetails (formData) {
        let newFormData = [];
        formData.count>0 && formData.map(detail => {
            let { 
                uniqueReference, creditAmount, debitAmount, balance, remainingBalance,
                status, journalDescription, journalEntryDate, rrn
            } = detail

            let detailFormData = {
                status,
                journalDescription,
                rrn,
                uniqueReference: uniqueReference ? formatLongNumber(uniqueReference) : '',
                creditAmount: creditAmount ? `₦${formatCurrency(convertToMajor(creditAmount))}` : `₦${formatCurrency(0)}`,
                debitAmount: debitAmount ? `₦${formatCurrency(convertToMajor(debitAmount))}` : `₦${formatCurrency(0)}`,
                balance: balance ? `₦${formatCurrency(convertToMajor(balance))}` : `₦${formatCurrency(0)}`,
                remainingBalance: remainingBalance ? `₦${formatCurrency(convertToMajor(remainingBalance))}` : `₦${formatCurrency(0)}`,
                journalEntryDate: journalEntryDate ? formatDateTime(journalEntryDate) : '',
            }

            newFormData.push(detailFormData)
        })
        
        return newFormData;
    }
}