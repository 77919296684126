import React from 'react';
import ContentHead from '../../../../../../../../../components/content-head';
import BaseScene from '../../../../../../../../../components/scenes/component';
import { CAN_TRIAL_BALANCE_VIEW } from '../../../../../../../../../constants/permissions.js';
import { SUCCESS_STATUS } from '../../../../../../../../../constants/api';
import { AccessDenied } from '../../../../../../../../../components/access-denied'
import { formatCurrency } from '../../../../../../../../../utils/formatter';
import { transactionHistory } from '../../../../../../../../../mixins/api'
import { GridLayout } from '../../../../../../../../../components/layouts/grid-layout';
import Notification from '../../../../../../../../../components/notification'
import ProtectedComponent from '../../../../../../../../../mixins/protected-component';
import { convertToMajor } from '../../../../../../../../../utils/converter';
import Table from '../../../../../../../../../components/table';
import { TrialBalanceReportExportSerializer } from '../../../../../../../../../serializers'

export default class TrialBalanceReportsGridScene extends BaseScene {
  columns = [
    {
      Header: "Account Code",
      accessor: 'accountCode',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
      Header: "Account Name",
      accessor: 'accountName',
      resizable: false,
      headerStyle: {'white-space': 'unset'},
      style: {'white-space': 'unset'},
    },
    {
        Header: "Debit",
        accessor: 'dr',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 150,
        Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>
    },
    {
        Header: "Credit",
        accessor: 'cr',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 150,
        Cell: props => <span>&#x20A6;{props.value ? formatCurrency(convertToMajor(props.value)) : formatCurrency(0)}</span>,
    },
]
  
  sceneName = 'wallet-management-trial-balance-reports-grid'

  transactionDetailsSectionRef = React.createRef(null)
  notificationSectionRef = React.createRef(null)

  constructor(props) {
    super(props);
    this.state = {
      summary: {},
      trialBalances: [],
      canLoadData: true,
      tableMessage: "",
    }
    this.getTrialBalances = this.getTrialBalances.bind(this)
  }
  
  componentDidMount() {
    this.getTrialBalances();
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async getTrialBalances () {
    this.setState({
      isLoading: true
    })
    const { status, response } = (
      await transactionHistory.retrieveTrialBalanceReport()
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        summary: response ? response.summary: {},
        trialBalances: response ? response.ledgerBalances : [],
        dataCount: response.summary.accountCount,
        isLoading: false,
      })
    } else {
      this.setState({
        trialBalances: [],
        dataCount: 0,
        isLoading: false,
        tableMessage: "No trial balance record found"
      })
    }
  }

  render () {
    return <React.Fragment>
      <ContentHead title={"Wallet Management"} subtitle={"Trial Balance Report"} filterPermissions={[CAN_TRIAL_BALANCE_VIEW]} totalCount={56} />
      <Notification 
        ref={this.notificationSectionRef}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
      />
      <ProtectedComponent requiredPermissions={[CAN_TRIAL_BALANCE_VIEW]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <Table 
            columns={this.columns} 
            data={this.state.trialBalances} 
            loading={this.state.isLoading} 
            noDataText={this.state.tableMessage}
            dataCount={this.state.dataCount}
            getData={this.getTrialBalances}
            exportData={TrialBalanceReportExportSerializer.reverseTrialBalanceReportDetails(this.state.trialBalances)}
            exportHeaders={TrialBalanceReportExportSerializer.headerExport()}
            exportFileName={'trial_balance_report'}
          />
          {!this.state.isLoading && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <div style={{display: 'grid'}}>
              <h3 style={{color: '#00425F', fontSize: '25px'}}><strong>Summary</strong></h3>
              <h4>Total Debit: &#x20A6;{this.state.summary.totalDr ? formatCurrency(convertToMajor(this.state.summary.totalDr)) : '0.00'}</h4>
              <h4>Total Credit: &#x20A6;{this.state.summary.totalCr ? formatCurrency(convertToMajor(this.state.summary.totalCr)) : '0.00'}</h4>
              <h4>Balance: &#x20A6;{this.state.summary.balance ? formatCurrency(convertToMajor(this.state.summary.balance)) : '0.00'}</h4>
              <h4>Account Count: {this.state.summary.accountCount}</h4>
            </div>
          </div>}
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }

  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
