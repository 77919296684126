import React from 'react'
import { DatePickerComponent } from '../../../../../components/date-picker'
import Select from '../../../../../components/select'
import { getDateByType } from '../../../../../utils/helper'

export default class MessagingHistorySearch extends React.Component {
    state = {
        searchParameters: {
            fromDate: !process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA ? getDateByType("startDate") : null,
            toDate: !process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA ? getDateByType("endDate") : null,
            status: "All",
            channel: "All"
        },
        channels: [
            { label: "All", value: "All" },
            { label: "Email", value: "EMAIL" },
            { label: "Push", value: "PUSH" },
            { label: "SMS", value: "SMS" }
        ],
        statuses: [
            { label: "All", value: "All" },
            { label: "Sent", value: "SENT" },
            { label: "Pending", value: "PENDING" },
            { label: "Delivered", value: "DELIVERED" },
            { label: "Failed", value: "FAILED" }
        ]
    }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        console.log('>>>SP', this.state.searchParameters)
        this.props.handleSearchRequest(this.state.searchParameters)
    }

    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="search-label">Message Body/Subject:</label>
                        <input type="text" class="search-input" placeholder="Enter Message Body/Subject" onChange={event => this.setState({searchParameters: {...this.state.searchParameters, search: event.target.value ? event.target.value : null}})}/> 
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Sender:</label>
                        <input type="text" class="search-input" placeholder="Enter Sender Email" onChange={event => this.setState({searchParameters: {...this.state.searchParameters, username: event.target.value ? event.target.value : null}})}/> 
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Recipient:</label>
                        <input type="text" class="search-input" placeholder="Enter Recipient Email/Phone Number" onChange={event => this.setState({searchParameters: {...this.state.searchParameters, to: event.target.value ? event.target.value : null}})}/> 
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Channel</label>
                        <Select 
                            options={this.state.channels}
                            onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, channel: event ? event.value : null}})}
                            defaultValue={this.state.searchParameters.channel}
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label class="search-label">Status</label>
                        <Select 
                            options={this.state.statuses}
                            //onChange={(event) => console.log("selected status is ", event.value)}
                            onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, status: event ? event.value : null}})}
                            defaultValue={this.state.searchParameters.status}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.setState({searchParameters:{...this.state.searchParameters, fromDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent
                            onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, toDate: event }})}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.state.searchParameters.fromDate ? false : true}
                            minDate={new Date(this.state.searchParameters.fromDate)}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={false}
                        />
                    </div>
                    <div class="col-md-2">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                    
                </div>
            </form>
        </React.Fragment>
    }
}