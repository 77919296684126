import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import BaseScene from '../../../../../../../components/scenes/component';
import { PromptModal } from '../../../../../../../components/modals/prompt-modal';
import { AccessDenied } from '../../../../../../../components/access-denied';
import FeeRuleTable from '../../components/fee-rule-table';
import { SUCCESS_STATUS, ERROR_STATUS, ACCEPTED_REQUEST } from '../../../../../../../constants/api';
import { feeConfigurationManagement } from '../../../../../../../mixins/api'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { GridLayout } from '../../../../../../../components/layouts/grid-layout'
import { CAN_VIEW_FEE_RULE, CAN_ACTIVATE_FEE_RULE, CAN_DEACTIVATE_FEE_RULE, CAN_DELETE_FEE_RULE, CAN_CREATE_FEE_RULE } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { pastTenseFormatter, getFormattedStatus } from '../../../../../../../utils/formatter'

export default class FeeRuleGridScene extends BaseScene {
  constructor(props) {
    super(props)
    this.state = {
      feeRules: [],
      fees: [],
      serviceTypes: [],
      isLoading: true,
    }
    this.getFeeRules = this.getFeeRules.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async updateStatus () {
    this.setState({isLoading: true})
    let responseObj = {}
    let approvalMessage = ""
    let message = ""
    if(this.state.feeRuleStatus == 'delete') {
      responseObj = await feeConfigurationManagement.deleteFeeRule(this.state.feeRuleId)
      approvalMessage = "Your request to delete a fee rule has been sent successfully for approval."
      message = "The fee rule was deleted successfully."
    }else{
      responseObj = await feeConfigurationManagement.updateFeeRuleStatus(this.state.feeRuleId, this.state.feeRuleStatus)
      approvalMessage = `Your request to ${getFormattedStatus(this.state.feeRuleStatus)} a fee rule has been sent successfully for approval.`
      message = `The fee rule was successfully ${pastTenseFormatter(getFormattedStatus(this.state.feeRuleStatus))} as a fee rule.`
    }
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      if(response.code == ACCEPTED_REQUEST){
        this.setState({
          isLoading: false,
          successMessage: approvalMessage,
        })
        return
      }else{
        this.setState({
          successMessage: message,
          isLoading: false,
        })
      }
    }else{
      this.setState({
        errorMessage: response,
        isLoading: false, 
      })
    }
    this.getFeeRules()
    setTimeout(() => {
      this.setState({
        feeRuleId: null,
        feeRuleStatus: null
      })
    }, 3000)
  }

  async getFeeRules (pageNum, pageSize) {
    this.setState({isLoading: true})
    const responseObj = await feeConfigurationManagement.getFeeRules(pageNum, pageSize)
    const { status, response, count } = responseObj
    if (status === SUCCESS_STATUS) {
      this.setState({
        feeRules: response ? response.data : [],
        dataCount: count,
        pages: Math.ceil(count/pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      })
    }else{
      this.setState({
        feeRules: [],
        dataCount: 0,
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
        errorMessage: response
      })
    }
  }

  getClickedAction (status, id) {
    this.setState({
      feeRuleId: id,
      feeRuleStatus: status
    })
  }
  
  render () {
    return <React.Fragment>
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Fee Rules"} filterPermissions={[CAN_VIEW_FEE_RULE]} actionButtonRequiredPermissions={[CAN_CREATE_FEE_RULE]} actionButtonText={"Add Fee Rule"} actionButtonUrl={"/home/fee-rules/add"} totalCount={56} />
      <ProtectedComponent permissionDeniedContent={this.permissionDeniedContent} requiredPermissions={[CAN_VIEW_FEE_RULE]}>
        <GridLayout>
          <FeeRuleTable 
            data={this.state.feeRules}
            getFeeRules={this.getFeeRules}
            pages={this.state.pages} 
            updateStatus={this.getClickedAction}
            loading={this.state.isLoading} 
            noDataText={`No fee rules found`}
            dataCount={this.state.dataCount}
            page={this.state.page}
            pageSize={this.state.pageSize}
            canView={CAN_VIEW_FEE_RULE}
            canActivate={CAN_ACTIVATE_FEE_RULE}
            canDeactivate={CAN_DEACTIVATE_FEE_RULE}
            canDelete={CAN_DELETE_FEE_RULE}
          />
          <PromptModal
            modalTitle={ this.state.feeRuleStatus+` Fee Rule`}
            modalBody={`Do you want to `+this.state.feeRuleStatus+` this fee rule?`}
            deleteModal={this.updateStatus}
            modalSize={`modal-md`}
            height={`230px`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied />
  }
}
