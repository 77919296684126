import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../../../components/pagination';
import SpinnerComponent from '../../../../../../components/spinner';
import TableExtra from '../../../../../../components/table-extra';
import { formatDateTime } from '../../../../../../utils/formatter';

export default class TraceTable extends React.Component {
  state = {
    page: 0,
    tableHeight: '950px',
    zoom: 'plus'
  }

  zoomTableHeight = this.zoomTableHeight.bind(this)

  zoomTableHeight () {
    this.setState({
      tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
      zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
    })
  }
  render() {
    console.log("from trace table ", this.props.data)
    const MyLoader = props => { return (
      props.loading && (
        <div className="-loading -active">
          <div className="-loading-inner">
            <SpinnerComponent isLoading={props.loading} />
          </div> 
        </div>
      )
    )};
    const columns = [
      {
        Header: "Date",
        accessor: 'start',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 80,
        Cell: props => <span>{formatDateTime(props.value)}</span>
      },
      {
        Header: "Trace ID",
        accessor: 'traceId',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 80,
      },
      {
        Header: "Service",
        accessor: 'service',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 80,
      },
      {
        Header: "URL",
        accessor: 'url',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
      },
      {
        Header: "Response Code",
        accessor: 'code',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        style: {'white-space': 'unset'},
        width: 60,
      },
      {
        Header: "Duration",
        accessor: 'duration',
        resizable: false,
        headerStyle: {'white-space': 'unset'},
        width: 60,
        style: {'white-space': 'unset'},
      },
      {
        Header: "Action",
        accessor: 'taceId',
        overflow: 'visible',
        resizable: false,
        sortable: false,
        headerStyle: {'white-space': 'unset'},
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: '10px',
              textAlign: 'center',
              whiteSpace: 'unset',
            },
          };
        },
        width: 60,
        Cell: ({row, original}) =><Link to={`/home/instrumentation/trace/${original.traceId}/view`} type="button" class="search-button" style={{ width: '120%', textAlign: 'center', textDecoration: 'none', border: 'none', borderRadius: '5px', color: 'white' }}>
          View
        </Link>
      }
    ]

    return <React.Fragment>
      <TableExtra 
        //data={FipExportSerializer.reverseFipDetails(this.props.data)}
        //headers={FipExportSerializer.headerExport()}
        //fileName={'FIPs'}
        zoomTableHeight={this.zoomTableHeight}
        getData={this.props.getTraceData}
        zoom={this.state.zoom}
        page={this.props.page}
        pageSize={this.props.pageSize}
        disabled={this.props.loading}
      />
      <ReactTable 
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: 'unset',
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ''}
          pageSizes={[10,20,25,50,100]}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
          minRows={(this.props.data).length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({page: state.page})
            if(this.props.canLoadData) {
              this.props.getTraceData(state.page + 1, state.pageSize)
            } 
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: '#00425f',
              boxSizing: 'none',
              border: 'none',
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              // backgroundColor: "#00425f",
              display: 'flex',
              alignItems: 'center',
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"3px 0",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"3px",
              paddingRight:"3px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: '#00425f',
              borderBottom: '0.1px solid #DBDEDE',
              borderWidth: '0.1px',
              paddingTop: '3px',
              paddingBottom: '3px',
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: '300',
              textAlign: 'left'
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: 'visible',
              backgroundColor: '#ffffff',
            },
          })}    
          className="-striped -highlight" 
      />
    </React.Fragment>     
  }
}