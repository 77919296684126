import { getDeviceInformation } from "../../../mixins/authentication.js";
import Requester from "../requester";

const API_BASE_URL = process.env.REACT_APP_TRANSACTION_API_BASE_URL;

export default class transactionService {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });
  }

  getBuyPowerBalance() {
    return this.apiRequester.get({
      endpoint: `v3/finch-transaction/payments/buy-power/balance`,
    });
  }

  getWalletBalance() {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/wallet/balance`,
    });
  }

  getWalletBalanceByPhoneNumber(phoneNumber) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/wallet/balances/${phoneNumber}`,
      args: {
        type: "ALL",
      },
    });
  }

  getTransactionReversal(transactionReference) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/system/reversals/${transactionReference}`,
    });
  }

  getTransactionReversalHistory() {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/system/reversals`,
    });
  }

  getWalletDetails(externalReference) {
    return this.apiRequester.get({
      endpoint: `v2/finch-transaction/wallet/${externalReference}`,
    });
  }

  uploadNipBank() {
    return this.apiRequester.get({
      endpoint: `v3/finch-transaction/payments/v2/institution/upload`,
      headers: {
        deviceUuid: getDeviceInformation().deviceUuid,
      },
    });
  }

  getMetricConfigData() {
    return this.apiRequester.get({
      endpoint: `v1/finch-transaction/union/dashboard`,
    });
  }

  getActiveRoute() {
    return this.apiRequester.get({
      endpoint: `v1/finch-transaction/union/switch`,
    });
  }

  saveManualRouting(payload) {
    return this.apiRequester.put({
      endpoint: `v1/finch-transaction/union`,
      body: payload
    });
  }

  editConfigData(payload) {
    return this.apiRequester.put({
      endpoint: `v1/finch-transaction/union/metrics`,
      body: payload
    });
  }

  arbitrateSingleTransaction(transRef) {
    return this.apiRequester.post({
      endpoint: `v2/finch-transaction/transactions/${transRef}/resolve`,
    });
  }

  republishTransactionDirect(payload) {
    const formData = new FormData();
    formData.append("file", payload);
    return this.apiRequester.post({
      endpoint: `v2/finch-transaction/system/upload/republishTransactionDirect`,
      body: formData,
      headers: {
        NoAuthorization: true,
      },
    });
  }

  getMetricData() {
    return this.apiRequester.get({
      endpoint: `v1/finch-transaction/union/allmetrics`,
    });
  }
}
