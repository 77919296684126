import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  CAN_UPDATE_SERVICE_OPTION,
  CAN_VIEW_SERVICE_OPTION,
} from "../../../../../../../constants/permissions.js";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import ServiceOptionForm from "../../components/service-option-form";

export default class ViewServiceOptionScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "View Service Option",
      canSave: false,
      canUpdate: false,
      disabled: true,
      accountTypes: [],
      serviceOptionFormData: {},
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.onUpdateServiceOptionFormData = this.onUpdateServiceOptionFormData.bind(
      this
    );
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  componentDidMount() {
    this.serviceOptionId = this.props.match.params.id;
    this.getServiceOptionDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getServiceOptionDetails() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceOption(
      this.serviceOptionId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      let res = response;
      res.settlementType = res.settlementType ? res.settlementType : 0;
      res.settlementInterval =
        !res.settlementInterval || res.settlementInterval === 0
          ? 1
          : res.settlementInterval;
      this.setState({
        serviceOptionFormData: res,
        canUpdate: true,
        isLoading: false,
      });
    } else {
      this.setState({
        serviceOptionFormData: [],
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  async onUpdateButtonClick() {
    this.setState({
      disabled: false,
      canSave: true,
      canUpdate: false,
      pageTitle: "Update Service Option",
    });
  }

  async onCancelButtonClick() {
    this.setState({
      disabled: true,
      canSave: false,
      canUpdate: true,
      pageTitle: "View Service Option",
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.updateServiceOption(
      this.state.serviceOptionFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your changes to this service option has been sent successfully for approval.",
          pageTitle: "View Service Option Details",
          canSave: false,
          canUpdate: true,
          disabled: true,
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          pageTitle: "View Service Option Details",
          canSave: false,
          canUpdate: true,
          disabled: true,
          successMessage: "Service Option updated successfully.",
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  onUpdateServiceOptionFormData(type, value) {
    this.setState({
      serviceOptionFormData: {
        ...this.state.serviceOptionFormData,
        [type]: value,
      },
    });
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_SERVICE_OPTION]}>
            <button
              type="button"
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onUpdateButtonClick}
            >
              Edit
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_SERVICE_OPTION]}>
            <button
              type="button"
              class="btn btn-secondary btn-bold btn-sm btn-icon-h"
              id="kt_subheader_group_actions_fetch"
              onClick={this.onCancelButtonClick}
            >
              Cancel
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_SERVICE_OPTION]}>
            <button
              type="button"
              disabled={!this.state.serviceOptionFormData}
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onSaveButtonClick}
            >
              Save Changes
            </button>
          </ProtectedComponent>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Service Options"}
          withoutSearch
          subtitle={this.state.pageTitle}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_SERVICE_OPTION]}
        >
          <GridLayout>
            <ServiceOptionForm
              onChange={this.onUpdateServiceOptionFormData}
              serviceOptionFormData={this.state.serviceOptionFormData}
              disabled={this.state.disabled}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
