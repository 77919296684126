import { formatLongNumber } from '../../utils/formatter';


export default class AgentDetailsExportSerializer {
    static headerExport() {
        return [
            { label: 'Agent Code', key: 'agentCode', title: 'Agent Code', dataIndex: 'agentCode' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
            { label: 'Agent Class', key: 'agentClass', title: 'Agent Class', dataIndex: 'agentClass' },
            { label: 'Super Agent', key: 'aggregator', title: 'Super Agent', dataIndex: 'aggregator' },
            { label: 'Business Name', key: 'businessName', title: 'Business Name', dataIndex: 'businessName' },
            { label: 'Business Phone Number', key: 'businessPhoneNo', title: 'Business Phone Number', dataIndex: 'businessPhoneNo' },
            { label: 'Business Email', key: 'businessEmail', title: 'Business Email', dataIndex: 'businessEmail' },
            { label: 'Company Registration Number', key: 'companyRegNo', title: 'Company Registration Number', dataIndex: 'companyRegNo' },
            { label: 'Phone Number', key: 'agentMobileNo', title: 'Phone Number', dataIndex: 'agentMobileNo' },
            { label: 'Identification Number', key: 'identificationNumber', title: 'Identification Number', dataIndex: 'identificationNumber' },
            { label: 'BVN', key: 'agentBankAccountBvn', title: 'BVN', dataIndex: 'agentBankAccountBvn' },
            { label: 'Account Name', key: 'agentBankAccountName', title: 'Account Name', dataIndex: 'agentBankAccountName' },
            { label: 'Account Number', key: 'agentBankAccountNumber', title: 'Account Number', dataIndex: 'agentBankAccountNumber' },
            { label: 'Bank Name', key: 'agentBankName', title: 'Bank Name', dataIndex: 'agentBankName' },
            { label: 'Service Provider Id', key: 'serviceProviderId', title: 'Service Provider Id', dataIndex: 'serviceProviderId' },
            { label: 'Date Created', key: 'dateApplied', title: 'Date Created', dataIndex: 'dateApplied' },
            { label: 'Validated By', key: 'applicationValidatedBy', title: 'Validated By', dataIndex: 'applicationValidatedBy' },
            { label: 'Validated Date', key: 'dateApplicationValidated', title: 'Validated Date', dataIndex: 'dateApplicationValidated' },
            { label: 'Approved Date', key: 'dateApplicationApproved', title: 'Approved Date', dataIndex: 'dateApplicationApproved' },
            { label: 'Last Modified Date', key: 'dateLastUpdated', title: 'Last Modified Date', dataIndex: 'dateLastUpdated' },
        ];
    }

    static headerExcelExport() {
        return [
            { title: 'First Name', dataIndex: 'details.firstName' },
            { title: 'Last Name', dataIndex: 'details.lastName' },
            { title: 'Job', dataIndex: 'job' },
        ]
    }

    static reverseAgentDetails(formData) {
        let newFormData = [];
        (formData || []).map(detail => {
            let {
                agentBankAccount, agentCode, agentClass, businessName, businessPhoneNo, businessEmail, companyRegNo,
                agentMobileNo, serviceProviderId, dateApplied, applicationValidatedBy, dateApplicationValidated, dateApplicationApproved,
                dateLastUpdated, status, identificationNumber
            } = detail;

            let detailFormData = {
                agentCode: agentCode,
                status,
                agentClass: agentClass,
                businessName: businessName,
                businessPhoneNo: businessPhoneNo ? formatLongNumber(businessPhoneNo) : '',
                businessEmail: businessEmail,
                companyRegNo: companyRegNo ? formatLongNumber(companyRegNo) : '',
                agentMobileNo: agentMobileNo ? formatLongNumber(agentMobileNo) : '',
                serviceProviderId: serviceProviderId,
                dateApplied: dateApplied,
                applicationValidatedBy: applicationValidatedBy,
                dateApplicationValidated: dateApplicationValidated,
                dateApplicationApproved: dateApplicationApproved,
                dateLastUpdated: dateLastUpdated,
                agentBankAccountBvn: agentBankAccount ? formatLongNumber(agentBankAccount.bvn) : '',
                identificationNumber: identificationNumber ? formatLongNumber(identificationNumber) : '',
                agentBankAccountNumber: agentBankAccount ? formatLongNumber(agentBankAccount.accountNo) : '',
                agentBankAccountName: agentBankAccount ? agentBankAccount.accountName : '',
                agentBankName: agentBankAccount ? agentBankAccount.bankName : '',
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}