import React from "react";

export default class PosRequestsAwaitingStockUploadDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {
    console.log(
      "Pos Requests Awaiting Stock Upload Modal ",
      this.props.posRequestDetails
    );
    const {
      agentUsername,
      approvalStatus,
      dateApproved,
      dateInitiated,
      id,
      paymentStatus,
      quantityAwaitingStockUpload,
      quantityRequested,
      requestId,
      requestType,
      terminalModel,
      terminalType,
      workflowId,
    } = this.props.posRequestAwaitingStockUploadDetails;

    return (
      <React.Fragment>
        <div
          class="modal"
          id="awaiting_stock_upload_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  POS Request Awaiting Stock Upload Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Stock Upload Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Agent Username:</strong>
                          </label>
                          <br />
                          <span>
                            {agentUsername ? agentUsername : ""}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Approval Status</strong>
                          </label>
                          <br />
                          <span>{approvalStatus ? approvalStatus : ""}</span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Date Approved</strong>
                          </label>
                          <br />
                          <span>
                            {dateApproved ? dateApproved : ""}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Date Initiated</strong>
                          </label>
                          <br />
                          <span>
                            {dateInitiated ? dateInitiated : ""}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>ID</strong>
                          </label>
                          <br />
                          <span>
                            {id ? id : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Payment Status</strong>
                          </label>
                          <br />
                          {paymentStatus ? paymentStatus : ""}
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Quantity Awaiting Stock Upload</strong>
                          </label>
                          <br />
                          {quantityAwaitingStockUpload
                            ? quantityAwaitingStockUpload
                            : ""}
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Quantity Requested</strong>
                          </label>
                          <br />
                          <span>
                            {quantityRequested ? quantityRequested : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Request ID</strong>
                          </label>
                          <br />
                          <span>
                            {requestId ? requestId : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Request Type</strong>
                          </label>
                          <br />
                          <span>
                            {requestType ? requestType : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Model</strong>
                          </label>
                          <br />
                          <span>
                            {terminalModel ? terminalModel : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal type</strong>
                          </label>
                          <br />
                          <span>
                            {terminalType ? terminalType : ""}
                          </span>
                          <br />
                          <br />
                        </div>
                       
                        
                      </div>
                    </div>
                  </div>
                </form>

                
              </div>
              <div class="col-md-12" style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
                <label class="search-label">Enter Quantity of Stock to be Uploaded To Agent</label>
                <input
                  type="text"
                  class="search-input"
                  onChange={(event) =>
                    this.setState({
                      quantity:
                      event.target.value}
                    )
                  }
                  style={{ width: "240px" }}
                />
              </div>
              <hr />

              <div class="modal-footer">
                <button
                  type="button"
                  // class="btn btn-default"
                  class="btn col-lg-4"
                  // class="btn btn-primary col-lg-6"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "inline-block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  // class="btn btn-default"
                  class="btn col-lg-4"
                  // class="btn btn-primary col-lg-6"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    borderColor: "#00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "inline-block",
                  }}
                  data-dismiss="modal"
                  onClick={() => {this.props.uploadPosRequestsAwaitingStockUpload(this.state.quantity, workflowId)}}
                  disabled={!this.state.quantity}
                >
                  Upload Stock For Agent
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
