import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import BaseScene from "../../../../../../../components/scenes/component";
// import TransactionHistoryTable from '../../components/transaction-history-table';
import { AccessDenied } from "../../../../../../../components/access-denied";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { CAN_VIEW_TRANSACTION } from "../../../../../../../constants/permissions.js";
import { transactionHistory } from "../../../../../../../mixins/api";
import { formatBackEndDateTime } from "../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../utils/helper";
// import TransactionDetails from '../../components/transaction-details'
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
// import TransactionSearch from '../../components/transaction-search'
import Notification from "../../../../../../../components/notification";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
// import TransactionDetailsModal from '../../components/transaction-details-modal'
import {
  deleteOtherInformation,
  retrieveOtherInformation,
} from "../../../../../../../mixins/authentication";
import CommissionHistorySearch from "../../components/commission-history-search";
import CommissionHistoryTable from "../../components/commissions-history-table";

export default class CommissionHistoryGridScene extends BaseScene {
  sceneName = "commission-history";

  transactionDetailsSectionRef = React.createRef(null);
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      searchParameters: {
        start: retrieveOtherInformation("commissionReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("startDate")
          : null,
        end: retrieveOtherInformation("commissionReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("endDate")
          : null,
        transactionTypeInt: "",
        event: "All",
      },
      canLoadData: retrieveOtherInformation("commissionReference")
        ? true
        : JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      //canLoadData: true,
      tableMessage: "",
      agentDetails: [],
      pages: 1,
      pageNum: 1,
      pageSize: 20,
    };

    this.getCommissions = this.getCommissions.bind(this);
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
  }

  componentDidMount() {
    this.domainCode = this.props.match.params.domainCode;
    this.updateSearchParameters("agentId", this.domainCode);

    const retrievedAgentDetails = JSON.parse(
      retrieveOtherInformation("commissionAgentDetails")
    );

    if (retrievedAgentDetails && retrievedAgentDetails.agentCode) {
      this.updateSearchParameters("agentId", retrievedAgentDetails.agentCode);
      this.setState({ agentDetails: retrievedAgentDetails });
    }

    const commissionReference = retrieveOtherInformation("commissionReference");

    if (commissionReference) {
      this.setState({ canLoadData: true });
      this.updateSearchParameters("transactionRef", commissionReference);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getCommissions(pageNum, pageSize) {
    const commissionReference = retrieveOtherInformation("commissionReference");

    if (commissionReference) {
      this.setState({ canLoadData: true });
      this.updateSearchParameters("transactionRef", commissionReference);
    }

    const {
      agentId,
      transactionTypeInt,
      start,
      end,
      event,
      agentPhoneNumber,
      parentRef,
      transactionRef,
    } = this.state.searchParameters;

    const retrievedAgentDetails = JSON.parse(
      retrieveOtherInformation("commissionAgentDetails")
    );

    if (retrievedAgentDetails && retrievedAgentDetails.agentCode) {
      this.updateSearchParameters("agentId", retrievedAgentDetails.agentCode);
      this.setState({ agentDetails: retrievedAgentDetails });
    }

    const formattedEndDate = end && formatBackEndDateTime(end);
    const formattedStartDate = start && formatBackEndDateTime(start);
    //const formattedBusinessMobileNo = businessMobileNo && phoneNumberFormatter(businessMobileNo)
    this.setState({
      pageNum,
      pageSize,
      isLoading: true,
      canLoadData: true,
    });
    const { status, response, count } = await transactionHistory.getCommissions(
      pageNum,
      pageSize,
      transactionTypeInt,
      formattedStartDate,
      formattedEndDate,
      event,
      agentPhoneNumber,
      parentRef,
      commissionReference ? commissionReference : transactionRef,
      agentId
        ? agentId
        : retrievedAgentDetails
        ? retrievedAgentDetails.agentCode
        : ""
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        commissions: response ? response : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        dataCount: count,
        isLoading: false,
      });
    } else {
      this.setState({
        commissions: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNum: pageNum,
        isLoading: false,
        tableMessage: "No commission history found",
      });
    }
    deleteOtherInformation("commissionReference");
  }

  handleSearchRequest() {
    const {
      agentId,
      start,
      end,
      event,
      transactionTypeInt,
      agentPhoneNumber,
      parentRef,
      transactionRef,
    } = this.state.searchParameters;
    this.getCommissions(
      this.state.pageNum,
      this.state.pageSize,
      transactionTypeInt,
      start,
      end,
      event,
      agentPhoneNumber,
      parentRef,
      transactionRef,
      agentId
    );
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(transaction, actionType) {
    if (actionType === "view") {
      //this.getTransactionDetails(transaction)
      this.getSettlementByRef(transaction.transactionRef);
      this.setState({
        transactionDetails: transaction,
        settlement: {
          parties: [],
        },
      });
    }
    this.setState({
      transRef: transaction.transRef,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    return (
      <React.Fragment>
        <ContentHead
          title={"Commission History"}
          subtitle={`${this.state.agentDetails.businessName} Commission History`}
          filterPermissions={[CAN_VIEW_TRANSACTION]}
          totalCount={56}
        />
        <Notification
          ref={this.notificationSectionRef}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_VIEW_TRANSACTION]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <CommissionHistorySearch
              searchParameters={this.state.searchParameters}
              updateSearchParameters={this.updateSearchParameters}
              handleSearchRequest={this.handleSearchRequest}
              preloadDate={
                retrieveOtherInformation("commissionReference")
                  ? false
                  : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
              }
            />
            <CommissionHistoryTable
              pages={this.state.pages}
              getCommissions={this.getCommissions}
              data={this.state.commissions}
              startPage={this.state.startPage}
              //viewMore={this.getTransactionDetails}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              page={this.state.pageNum}
              pageSize={this.state.pageSize}
              searchParameters={this.state.searchParameters}
              canView={CAN_VIEW_TRANSACTION}
              canLoadData={this.state.canLoadData}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
