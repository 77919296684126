import React from "react";

export default function TransactionsCard(props) {
  return (
    <>
      <div class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-widget14">
          <div class="kt-widget14__header">
            <h3 class="kt-widget14__title">Transaction Performance Board</h3>
            <span class="kt-widget14__desc">Transaction count</span>
          </div>
          {props.isLoading !== true ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h3>{props.qtSuccessFailMetric.transactionType && "QT"}</h3>

                  <div class="">
                    <p
                      class="text-center"
                      style={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      {props.qtSuccessFailMetric.successCount}
                    </p>
                    <p
                      class="text-center"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {props.qtSuccessFailMetric.failCount}
                    </p>
                  </div>
                </div>
                <div>
                  <h3>{props.nipSuccessFailMetric.transactionType}</h3>
                  <div class="">
                    <p
                      class="text-center"
                      style={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      {props.nipSuccessFailMetric.successCount}
                    </p>

                    <p
                      class="text-center"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {props.nipSuccessFailMetric.failCount}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <small class="loading">Loading transaction-count</small>
          )}
        </div>
      </div>
    </>
  );
}
