import React from "react";
import { AccessDenied } from "../../../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../../../components/notification";
import BaseScene from "../../../../../../../../../components/scenes/component";
import {
  SUCCESS_STATUS
} from "../../../../../../../../../constants/api";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE
} from "../../../../../../../../../constants/permissions.js";
import { posRequestsAwaitingConfig, userManagement } from "../../../../../../../../../mixins/api";
import {
  deleteOtherInformation, retrieveOtherInformation
} from "../../../../../../../../../mixins/authentication";
import ProtectedComponent from "../../../../../../../../../mixins/protected-component";
import {
  formatBackEndDateTimeFrom, formatBackEndDateTimeTo
} from "../../../../../../../../../utils/formatter";
import { getDateByType } from "../../../../../../../../../utils/helper";
import PosRequestsAwaitingConfigDetailsModal from "../../components/pos-requests-awaiting-config-details-modal";
import PosRequestsAwaitingConfigSearch from "../../components/pos-requests-awaiting-config-search";
import PosRequestsAwaitingConfigTable from "../../components/pos-requests-awaiting-config-table";

export default class PosRequestAwaitingConfigGridScene extends BaseScene {
  sceneName = "pos-requests-awaiting-config";
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);

    this.state = {
      requests: [],
      posRequestAwaitingConfigDetails: [],
      searchParameters: {
        fromDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("fromDate")
          : null,
        toDate: retrieveOtherInformation("parentReference")
          ? null
          : !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA)
          ? getDateByType("toDate")
          : null,
        approvalStatus: "",
        mobilePhone: "",
        posRequestsAwaitingConfigStatus: "",
        deviceType: "All",
      },
      pages: 1,
      pageSize: 20,
      pageNumber: 1,
      tableMessage: "",
      configured: true,
    };

    this.getAllPosRequestsAwaitingConfig = this.getAllPosRequestsAwaitingConfig.bind(
      this
    );
    this.updatePosRequestAwaitingConfigById = this.updatePosRequestAwaitingConfigById.bind(
      this
    );
    this.updatePosRequestAwaitingConfigByBatchId = this.updatePosRequestAwaitingConfigByBatchId.bind(
      this
    );
    this.updateSearchParameters = this.updateSearchParameters.bind(this);
    this.downloadPosAwaitingConfig = this.downloadPosAwaitingConfig.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
  }

  componentDidMount() {
    this.getAllPosRequestsAwaitingConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content === null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getAllPosRequestsAwaitingConfig(pageNumber, pageSize) {
    const {
      mobilePhone,
      toDate,
      fromDate,
      approvalStatus,
    } = this.state.searchParameters;
    this.setState({ canLoadData: true });

    const formattedEndDate =
      toDate && `${formatBackEndDateTimeTo(toDate, true)}.747`;
    const formattedStartDate =
      fromDate && `${formatBackEndDateTimeFrom(fromDate, true)}.747`;

    this.setState({
      pageNumber,
      pageSize,
      isLoading: true,
    });

    const {
      status,
      response,
      count,
    } = await posRequestsAwaitingConfig.getRequestsAwaitingConfigBySearch(
      pageNumber,
      pageSize,
      mobilePhone,
      formattedEndDate,
      formattedStartDate,
      approvalStatus
    );
    if (status === SUCCESS_STATUS) {
      this.setState({
        requests: response ? response.data : [],
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        // dataCount: count,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        tableMessage:
          !count || count === 0
            ? "No POS request Awaiting Configuration found"
            : "",
      });
    } else {
      this.setState({
        requests: [],
        dataCount: 0,
        pages: Math.ceil(count / this.state.pageSize),
        pageSize: pageSize,
        pageNumber: pageNumber,
        isLoading: false,
        tableMessage: "No POS Request Awaiting Configuration found",
        errorMessage: response,
      });
    }
    deleteOtherInformation("parentReference");
  }

  async updatePosRequestAwaitingConfigById(id) {
    this.setState({
      configured: false,
    });
    const responseObj = await posRequestsAwaitingConfig.updatePosRequestAwaitingConfigById(
      id
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      // this.setState({
      //   isLoading: false,
      // });
      this.setState({
        configured: true,
      });

      this.setState({
        successMessage: `POS request configured Successfully`,
      });
      // this.getAllPosRequestsAwaitingConfig()
    } else {
      this.setState({
        configured: true,
        // posRequestAwaitingConfigDetails:[],
        errorMessage: response.description,
      });
    }
  }
  async updatePosRequestAwaitingConfigByBatchId(batchId) {
    this.setState({
      configured: false,
    });
    const responseObj = await posRequestsAwaitingConfig.updatePosRequestAwaitingConfigByBatchId(
      batchId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        configured: true,
      });

      this.setState({
        posRequestAwaitingConfigDetails: response.data,
        successMessage: `POS requests configured Successfully`,
      });
      this.getAllPosRequestsAwaitingConfig();
    } else {
      this.setState({
        configured: true,
        posRequestAwaitingConfigDetails: [],
        errorMessage: response.description,
      });
      this.getAllPosRequestsAwaitingConfig();
    }
  }

  async downloadPosAwaitingConfig() {
    const payload = {
      pageNum: this.state.page,
      pageSize: this.state.dataCount,
      posRequestsStatus: this.state.searchParameters.approvalStatus,
      fromDate:
        this.state.searchParameters.fromDate &&
        `${formatBackEndDateTimeFrom(
          this.state.searchParameters.fromDate,
          true
        )}.747`,
      toDate:
        this.state.searchParameters.toDate &&
        `${formatBackEndDateTimeTo(
          this.state.searchParameters.toDate,
          true
        )}.747`,
    };
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await userManagement.downloadPosAwaitingConfig(payload);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], { type: "contentType" });
      const a = document.createElement("a");
      a.setAttribute("download", "pos_requests_awaiting_config.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        successMessage: "Download Succcessfully",
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response.description,
        isLoading: false,
      });
    }
  }

  handleSearchRequest() {
    this.getAllPosRequestsAwaitingConfig(1, 20);
  }

  updateSearchParameters(type, value) {
    this.setState({
      searchParameters: { ...this.state.searchParameters, [type]: value },
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType === "view") {
      this.setState({
        posRequestAwaitingConfigDetails: requests,
      });
    }
    this.setState({
      requestId: requests.id,
      actionType,
    });
  }

  isFormValid() {
    //return false
    if (this.state.actionType === "resolve") {
      return Boolean(this.state.desiredStatus);
    } else {
      return true;
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <ContentHead
          title={"View POS Requests Awaiting Configuration"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        /> */}
        {this.state.configured ? (
          <>
            <Notification
              // ref={this.notificationSectionRef}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
            <ProtectedComponent
              // requiredPermissions={[CAN_VIEW_TRANSACTION]}
              requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
              permissionDeniedContent={this.permissionDeniedContent}
            >
              <GridLayout>
                <PosRequestsAwaitingConfigSearch
                  searchParameters={this.state.searchParameters}
                  agentName={this.state.requests.agentName}
                  updateSearchParameters={this.updateSearchParameters}
                  updatePosRequestAwaitingConfigByBatchId={
                    this.updatePosRequestAwaitingConfigByBatchId
                  }
                  handleSearchRequest={this.handleSearchRequest}
                  preloadDate={
                    retrieveOtherInformation("parentReference")
                      ? false
                      : !JSON.parse(
                          process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA
                        )
                  }
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                />
                <PosRequestsAwaitingConfigTable
                  pages={this.state.pages}
                  getAllPosRequestsAwaitingConfig={
                    this.getAllPosRequestsAwaitingConfig
                  }
                  data={this.state.requests}
                  startPage={this.state.startPage}
                  viewMore={this.updatePosRequestAwaitingConfigById}
                  loading={this.state.isLoading}
                  noDataText={this.state.tableMessage}
                  dataCount={this.state.dataCount}
                  page={this.state.pageNum}
                  pageSize={this.state.pageSize}
                  canRemapDevice={CAN_REMAP_DEVICE}
                  downloadStocks={this.downloadPosAwaitingConfig}
                  searchParameters={this.state.searchParameters}
                  responseCode={this.state.searchParameters.responseCode}
                  startDate={this.state.searchParameters.startDate}
                  canView={CAN_GET_DEVICES_BY_UUID}
                  getClickedAction={this.getClickedAction}
                  desiredStatus={
                    this.state.desiredStatus
                      ? this.state.desiredStatus
                      : "default"
                  }
                  canLoadData={this.state.canLoadData}
                />
                <PosRequestsAwaitingConfigDetailsModal
                  posRequestAwaitingConfigDetails={
                    this.state.posRequestAwaitingConfigDetails
                  }
                  configure={this.updatePosRequestAwaitingConfigById}
                  loading={this.state.isLoading}
                />
              </GridLayout>
            </ProtectedComponent>
          </>
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
