import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import { workFlowManagement } from "../../../../../../../mixins/api";
import { formatBackEndDateTime } from "../../../../../../../utils/formatter";
import ApprovalSearch from "../../components/approval-search";
import ApprovalTable from "../../components/approval-table";

export default class ApprovalGridScene extends BaseScene {
  metadataSectionRef = React.createRef(null);
  sceneName = "approval";
  state = {
    approvals: [],
    pages: 1,
    isLoading: true,
    totalCount: 0,
    searchParameters: {
      actionType: null,
      status: null,
      startDate: null,
      endDate: null,
    },
    tableMessage: "",
    canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
  };

  handleSearchRequest = this.handleSearchRequest.bind(this);
  getApprovals = this.getApprovals.bind(this);
  closePendingApprovals = this.closePendingApprovals.bind(this);
  getClickedAction = this.getClickedAction.bind(this);
  recallApproval = this.recallApproval.bind(this);

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getApprovals(pageNum, pageSize) {
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const payload = this.state.searchParameters;
    payload.startDate =
      payload.startDate && formatBackEndDateTime(payload.startDate);
    payload.endDate = payload.endDate && formatBackEndDateTime(payload.endDate);
    const responseObj = await workFlowManagement.getAllApprovals(
      pageNum,
      pageSize,
      payload
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        approvals: response,
        dataCount: response.length,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        isLoading: false,
        tableMessage: count === 0 ? "No workflow approval found" : "",
      });
    } else {
      this.setState({
        approvals: [],
        dataCount: 0,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        errorMessage: response,
        tableMessage: "No workflow approval found",
      });
    }
  }

  async closePendingApprovals() {
    this.setState({ isLoading: true });
    const responseObj = await workFlowManagement.closePendingApprovals();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: response.description,
      });
    } else {
      this.setState({
        errorMessage: response,
      });
    }
    this.getApprovals(1, 20);
  }

  handleSearchRequest(searchParameters) {
    this.setState({
      pageIndex: 1,
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getApprovals(1, 20);
    });
  }

  getClickedAction(approval) {
    this.setState({
      recallId: approval.id,
    });
  }

  async recallApproval() {
    this.setState({ isLoading: true });
    const responseObj = await workFlowManagement.recallApproval(
      this.state.recallId,
      this.state.recallReason
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        recallId: null,
        recallReason: null,
        successMessage: `Approval was successfully recalled!`,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    window.scrollTo(0, this.metadataSectionRef.current);
    this.getApprovals(1, 20);
  }

  render() {
    const recallModalBody = (
      <React.Fragment>
        <form class="kt-form kt-form--label-right">
          <div class="kt-portlet__body">
            <div class="form-group row">
              <div class="col-lg-12">
                <label>Comment</label>
                <textarea
                  autocomplete="off"
                  type="name"
                  style={{ height: 230, resize: "none" }}
                  class="form-control"
                  placeholder="Type details about approval recall here..."
                  onChange={(event) =>
                    this.setState({ recallReason: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Notification
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          ref={this.metadataSectionRef}
        />
        <ContentHead title={"WorkFlow Management"} subtitle="Approvals" />
        <GridLayout>
          <ApprovalSearch
            handleSearchRequest={this.handleSearchRequest}
            showApprovalRepush={true}
            closePendingApprovals={this.closePendingApprovals}
          />
          <ApprovalTable
            pages={this.state.pages}
            data={this.state.approvals}
            loading={this.state.isLoading}
            noDataText={this.state.tableMessage}
            getApprovals={this.getApprovals}
            dataCount={this.state.dataCount}
            pageSize={this.state.pageSize}
            page={this.state.page}
            closePendingApprovals={this.closePendingApprovals}
            canLoadData={this.state.canLoadData}
            getClickedAction={this.getClickedAction}
          />
          <PromptModal
            modalTitle="Recall Approval"
            modalBody={`Do you want to recall this approval`}
            deleteModal={this.recallApproval}
            modalSize={`modal-md`}
            height={`470px`}
            textAlign={`left`}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Recall`}
            modalFooterButton2Disabled={!this.state.recallReason}
            hideModalHeader={true}
            hideModalFooter={true}
            modalBody={recallModalBody}
          />
        </GridLayout>
      </React.Fragment>
    );
  }
}
