import React from "react";

export default class PerformanceConfigEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,

      jobEnable: "",
      value: "Yes",
      jobEnableOptions: [
        { value: "True", label: "True" },
        { value: "False", label: "False" },
      ],
    };
  }

  render() {
    const { lowerBound, upperBound, weight } = this.props.metricDetails;

    return (
      <React.Fragment>
        <div
          class="modal"
          id="scheduler_edit"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Performance Metric Config
                </h3>
              </div>

              <div class="kt-portlet">
                <div class="kt-portlet__head" style={{ cursor: "pointer" }}>
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Lower Bound</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            class="search-input"
                            value={this.props.metricDetails.lowerBound}
                            onChange={(event) =>
                              this.props.updateFields(
                                "lowerBound",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Upper Bound</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            class="search-input"
                            value={this.props.metricDetails.upperBound}
                            onChange={(event) =>
                              this.props.updateFields(
                                "upperBound",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Weight</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            class="search-input"
                            value={this.props.metricDetails.weight}
                            onChange={(event) =>
                              this.props.updateFields(
                                "weight",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>PMC Level</strong>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="text"
                            class="search-input"
                            disabled="true"
                            value={this.props.metricDetails.pmcLevel}
                            onChange={(event) =>
                              this.props.updateFields(
                                "pmcLevel",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                </form>
              </div>

              <div
                class="modal-footer"
                style={{
                  width: "250px",
                  marginLeft: "auto",
                  marginRight: "20%",
                }}
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#00425f",
                    color: "#ffffff",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                  disabled={!(lowerBound || upperBound || weight)}
                  onClick={() => {
                    this.props.editMetrics();
                  }}
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
