import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import StateGridScene from './scenes/state-grid'
import BaseScene from '../../../../../components/scenes/component';
import AddStateScene from './scenes/add-state';
import ViewStateScene from './scenes/view-state';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_STATE, CAN_CREATE_STATE } from '../../../../../constants/permissions.js'

export default class StateScene extends BaseScene {
  sceneName = 'state-management'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_STATE]} exact path='/home/system-configuration/state-management' component={StateGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_STATE]} exact path='/home/system-configuration/state-management/add' component={AddStateScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_STATE]} exact path='/home/system-configuration/state-management/:id/view' component={ViewStateScene} />
    </Router>
  }
}
