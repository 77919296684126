import { connect } from 'react-redux'
import PermissionGroupGridScene from './scene';
import { updateCurrentPathName, updateScene } from '../../../../../../services/redux/actions/navigation'

function mapStateToProps (state) {
  return {
    scene: state.navigation.scene
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateScene: scene => dispatch(updateScene(scene)),
    updateCurrentPathName: pathName => dispatch(updateCurrentPathName(pathName))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupGridScene)
