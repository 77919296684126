import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import Select from '../../../../../../components/select';

export default class WorkflowSearch extends React.Component {
  state = {
    searchParameters: {
      actionType: 'All',
      active: 'All',
      name: 'All',
    },
    actionTypes: [
      { label: 'All', value: 'All' },
      { label: 'Create', value: 'CREATE' },
      { label: 'Edit', value: 'EDIT' },
      { label: 'Delete', value: 'DELETE' },
      { label: 'Enable', value: 'ENABLE' },
      { label: 'Disable', value: 'DISABLE' },
    ],
    statuses: [
      { label: 'All', value: 'All' },
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ],
  };

  handleSearchRequest = this.handleSearchRequest.bind(this);

  handleSearchRequest(e) {
    e.preventDefault();
    this.props.handleSearchRequest(this.state.searchParameters);
  }

  render() {
    return (
      <React.Fragment>
        <form class='search-params' onSubmit={this.handleSearchRequest}>
          <div class='form-group row'>
            <div class='col-md-2'>
              <label class='search-label'>Action Types:</label>
              <Select
                options={this.state.actionTypes}
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      actionType: event.value,
                    },
                  })
                }
                defaultValue={this.state.searchParameters.actionType}
              />
              {/* <input type="text" placeholder="Update..." class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, actionType: event.target.value ? (event.target.value).toUpperCase() : null}})} /> */}
            </div>
            <div class='col-md-2'>
              <label class='search-label'>Status:</label>
              <Select
                options={this.state.statuses}
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      active: event.value,
                    },
                  })
                }
                defaultValue={this.state.searchParameters.active}
              />
              {/* <input type="text" placeholder="In Progress..." class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, status: event.target.value ? (event.target.value).toUpperCase() : null}})} /> */}
            </div>
            <div class='col-md-2'>
              <label class='search-label'>Module:</label>
              <Select
                //options={this.props.workflowModules}
                options={this.props.workflowModules.map((detail) => {
                  return { label: detail, value: detail };
                })}
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      name: event.value,
                    },
                  })
                }
                defaultValue={this.state.searchParameters.name}
              />
              {/* <input type="text" class="search-input" onChange={(event) => this.setState({searchParameters: {...this.state.searchParameters, name: event.target.value}})} /> */}
            </div>
            <div class='col-md-2'>
              <label class='search-label'>Date From</label>
              <DatePickerComponent
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      start: event,
                    },
                  })
                }
                size={`150px`}
                format={`dd/MM/yyyy h:mm aa`}
                isClearable={true}
                showTimeInput={true}
                style={'0.4rem'}
                textStyle={'1.0rem'}
                iconStyle={'0.2rem'}
                dateType={'startDate'}
                preloadDate={false}
              />
            </div>
            <div class='col-md-2'>
              <label class='search-label'>Date To</label>
              <DatePickerComponent
                onChange={(event) =>
                  this.setState({
                    searchParameters: {
                      ...this.state.searchParameters,
                      end: event,
                    },
                  })
                }
                size={`150px`}
                format={`dd/MM/yyyy h:mm aa`}
                isClearable={true}
                showTimeInput={true}
                disabled={this.state.searchParameters.start ? false : true}
                minDate={new Date(this.state.searchParameters.start)}
                style={'0.4rem'}
                textStyle={'1.0rem'}
                iconStyle={'0.2rem'}
                dateType={'endDate'}
                preloadDate={false}
              />
            </div>
            <div class='col-md-2'>
              <label class='search-label'>&nbsp;&nbsp;</label>
              <button type='submit' class='search-button'>
                Search
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
