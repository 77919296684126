import { convertToMajor } from '../../utils/converter';
import {
    formatCurrency, formatLongNumber
} from '../../utils/formatter';

export default class TrialBalanceReportExportSerializer {
    static headerExport () {
        return [
            { label: 'Account Code', key: 'accountCode', title: 'Account Code', dataIndex: 'accountCode' },
            { label: 'Account Name', key: 'accountName', title: 'Account Name', dataIndex: 'accountName' },
            { label: 'Debit', key: 'dr', title: 'Debit', dataIndex: 'dr' },
            { label: 'Credit', key: 'cr', title: 'Credit', dataIndex: 'cr' }
        ];
    }

    static reverseTrialBalanceReportDetails (formData) {
        let newFormData = [];
        formData.map(detail => {
            let { 
               accountCode, accountName, dr, cr
            } = detail

            let detailFormData = {
                accountCode: accountCode ? formatLongNumber(accountCode) : '',
                accountName,
                cr: cr ? `₦${formatCurrency(convertToMajor(cr))}` : `₦${formatCurrency(0)}`,
                dr: dr ? `₦${formatCurrency(convertToMajor(dr))}` : `₦${formatCurrency(0)}`
            }

            newFormData.push(detailFormData)
        })
        return newFormData;
    }
}