import React from "react";
import "react-table/react-table.css";
import Notification from "../../../../../../components/notification";
import StockSplits from "./stock-splits";

export default class UploadStockDetailsModal extends React.Component {
  state = {
    page: 0,
    tableHeight: "430px",
    zoom: "plus",
    successMessage2: "",
    errorMessage2: "",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }
  render() {
    return (
      <React.Fragment>
        <div
          class="modal"
          id="upload_config_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog"
            style={{
              overflowY: "scroll",
              overflowX: "auto",
              fontSize: "14px",
              maxWidth: "900px",
              borderRadius: "15px",
              // height: "750px"
            }}
          >
            <div
              class="modal-content"
              style={{ borderRadius: "15px", height: "750px" }}
            >
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  Uploaded Config Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Config Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div
                    class="kt-portlet__body"
                    style={{
                      overflowX: "scroll",
                      overflowY: "scroll",
                      height: "540px",
                    }}
                  >
                    <React.Fragment>
                      <Notification
                        successMessage={this.props.successMessage2}
                        errorMessage={this.props.errorMessage2}
                      />
                      <StockSplits stockSplits={this.props.data} />
                      {/* {console.log("DATAAAAAAAAA", this.props.data)} */}
                    </React.Fragment>
                    {/* <React.Fragment>
        
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              batchId,
              toDate,
              fromDate,
              pageNumber,
              pageSize,
            } = this.props.searchParameters2;
            this.setState({ page: state.pageNumber });
            if (this.props.canLoadData) {
              this.props.getStocksModal(
                state.page + 1,
                state.pageSize,
                toDate,
                fromDate,
                pageNumber,
                pageSize,
                batchId
              );
            }
          }}
          headerNodeHeight={`123`}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              //backgroundColor: '#FFFFFF',
              lineHeight: "20px",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "#FFFFFF",
              fontSize: "13px",
              fontWeight: "500",
              textAlign: "left",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment> */}
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
