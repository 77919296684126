import { formatLongNumber } from "../../utils/formatter";

export default class AgentBVNDetailsExportSerializer {
  static headerExport() {
    return [
      {
        label: "Agent Code",
        key: "agentCode",
        title: "Agent Code",
        dataIndex: "agentCode",
      },
      {
        label: "agentName",
        key: "agentName",
        title: "Agent Name",
        dataIndex: "agentName",
      },
      { label: "Email", key: "email", title: "Email", dataIndex: "email" },
      {
        label: "phoneNumber",
        key: "phoneNumber",
        title: "Phone Number",
        dataIndex: "phoneNumber",
      },
      { label: "State", key: "state", title: "State", dataIndex: "state" },
      {
        label: "BVN Verification Status",
        key: "bvnVerificationStatus",
        title: "BVN Verification Status",
        dataIndex: "bvnVerificationStatus",
      },
    ];
  }

  static headerExcelExport() {
    return [
      { title: "First Name", dataIndex: "details.firstName" },
      { title: "Last Name", dataIndex: "details.lastName" },
      { title: "Job", dataIndex: "job" },
    ];
  }

  static reverseAgentDetails(formData) {
    let newFormData = [];
    formData.map((detail) => {
      let {
        agentCode,
        agentName,
        email,
        phoneNumber,
        state,
        bvnVerificationStatus,
      } = detail;

      let detailFormData = {
        agentCode: agentCode,
        agentName: agentName,
        phoneNumber: phoneNumber ? formatLongNumber(phoneNumber) : "",
        email: email,
        state: state,
        bvnVerificationStatus: bvnVerificationStatus,
      };

      newFormData.push(detailFormData);
    });
    return newFormData;
  }
}
