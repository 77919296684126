import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST, SUCCESS_STATUS
} from "../../../../../../../constants/api";
import {
  CAN_ACTIVATE_COMMISSION_SPLIT_RULE, CAN_CREATE_COMMISSION_SPLIT_RULE, CAN_DEACTIVATE_COMMISSION_SPLIT_RULE,
  CAN_DELETE_COMMISSION_SPLIT_RULE, CAN_VIEW_COMMISSION_SPLIT_RULE
} from "../../../../../../../constants/permissions.js";
import { feeConfigurationManagement } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  getFormattedStatus, pastTenseFormatter
} from "../../../../../../../utils/formatter";
import CommissionSplitRuleTable from "../../components/commission-split-rule-table";

export default class CommissionSplitRuleGridScene extends BaseScene {
  state = {
    commissionSplitRules: [],
    serviceTypes: [],
    isLoading: true,
  };

  getCommissionSplitRules = this.getCommissionSplitRules.bind(this);
  updateStatus = this.updateStatus.bind(this);
  getClickedAction = this.getClickedAction.bind(this);

  componentDidMount() {}

  async updateStatus() {
    this.setState({ isLoading: true });
    let responseObj = {};
    let approvalMessage = "";
    let message = "";
    if (this.state.commissionSplitRuleStatus === "delete") {
      responseObj = await feeConfigurationManagement.deleteCommissionSplitRule(
        this.state.commissionSplitRuleId
      );
      approvalMessage =
        "Your request to delete a commission split rule has been sent successfully for approval.";
      message = "Commission split rule was successfully deleted.";
    } else {
      responseObj = await feeConfigurationManagement.updateCommissionSplitRuleStatus(
        this.state.commissionSplitRuleId,
        this.state.commissionSplitRuleStatus
      );
      approvalMessage = `Your request to ${getFormattedStatus(
        this.state.commissionSplitRuleStatus
      )} a commission split has been sent successfully for approval.`;
      message = `The commission split rule was successfully ${pastTenseFormatter(
        getFormattedStatus(this.state.commissionSplitRuleStatus)
      )}.`;
    }
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: approvalMessage,
        });
        return;
      } else {
        this.setState({
          successMessage: message,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getCommissionSplitRules();
    setTimeout(() => {
      this.setState({
        commissionSplitRuleId: null,
        commissionSplitRuleStatus: null,
      });
    }, 3000);
  }

  async getCommissionSplitRules(pageNum, pageSize) {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getCommissionSplitRules(
      pageNum,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        commissionSplitRules: response ? response.data : [],
        dataCount: count,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        isLoading: false,
      });
    } else {
      this.setState({
        commissionSplitRules: [],
        dataCount: 0,
        isLoading: false,
        pages: 1,
        page: pageNum,
        pageSize: pageSize,
        errorMessage: response,
      });
    }
  }

  getClickedAction(status, id) {
    this.setState({
      commissionSplitRuleId: id,
      commissionSplitRuleStatus: status,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Commission Split Rules"}
          filterPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]}
          actionButtonRequiredPermissions={[CAN_CREATE_COMMISSION_SPLIT_RULE]}
          actionButtonText={"Add Commission Split Rule"}
          actionButtonUrl={"/home/commission-split-rules/add"}
          totalCount={56}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_COMMISSION_SPLIT_RULE]}
        >
          <GridLayout>
            <CommissionSplitRuleTable
              data={this.state.commissionSplitRules}
              getCommissionSplitRules={this.getCommissionSplitRules}
              pages={this.state.pages}
              updateStatus={this.getClickedAction}
              loading={this.state.isLoading}
              noDataText={`No fee rules found`}
              dataCount={this.state.dataCount}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canView={CAN_VIEW_COMMISSION_SPLIT_RULE}
              canActivate={CAN_ACTIVATE_COMMISSION_SPLIT_RULE}
              canDeactivate={CAN_DEACTIVATE_COMMISSION_SPLIT_RULE}
              canDelete={CAN_DELETE_COMMISSION_SPLIT_RULE}
            />
            <PromptModal
              modalTitle={
                this.state.commissionSplitRuleStatus + ` Commission Split Rule`
              }
              modalBody={
                `Do you want to ` +
                this.state.commissionSplitRuleStatus +
                ` this commission split rule?`
              }
              deleteModal={this.updateStatus}
              modalSize={`modal-md`}
              height={`230px`}
              modalFooterButton1={`Cancel`}
              modalFooterButton2={`Ok`}
              hideModalHeader={true}
              hideModalFooter={true}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
