import React from 'react'
import Select from '../../../../../../components/select'

export default class LgaSearch extends React.Component {
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label class="search-label">State :</label>
                        <Select 
                            options={this.props.states.map(details => { return ( {label: details.name, value: details.id} )})}
                            onChange={event => this.props.getLgas(event.value)}
                            defaultValue={this.props.searchParameters.state}
                        />
                        {/* <select class="search-input" onChange={event => this.props.getLgas(event.target.value)}>
                            <option value="all">All</option>
                            {this.props.states.map(value => <option value={value.id}>{value.name}</option>)}
                        </select> */}
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}