import React from 'react';
import { DatePickerComponent } from '../../../../../../components/date-picker';
import ServiceTypes from '../../../../../../fixtures/service_types';

export default class PayWithTransferSearch extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
      useBanks: null
    }
  }

    handleSearchRequest = this.handleSearchRequest.bind(this)

    handleSearchRequest (e) {
        e.preventDefault()
        this.props.handleSearchRequest()
    }

    serviceTypeUseBankCheck (serviceTypeId) {
        const serviceType = ServiceTypes.filter(value => value.id == serviceTypeId)
        this.setState({'useBanks':serviceType[0].useBanks})
    }
    
    render() {
        return <React.Fragment>
            <form class="search-params" onSubmit={this.handleSearchRequest}>
                <div class='form-group row'>
                    <div class="col-md-3">
                        <label class="search-label">Session ID:</label>
                        <input
                            type="text"
                            class="search-input"
                            onChange={(event) =>
                            this.props.updateSearchParameters(
                                "channelTransactionReference",
                                event.target.value
                            )}
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Date From</label>
                        <DatePickerComponent
                            onChange={(event) => this.props.updateSearchParameters('startDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"startDate"}
                            preloadDate={this.props.preloadDate}
                        />
                    </div>
                    <div class="col-md-3">
                        <label class="search-label">Date To</label>
                        <DatePickerComponent 
                            onChange={(event) => this.props.updateSearchParameters('endDate', event)}
                            size={`150px`}
                            format={`dd/MM/yyyy h:mm aa`}
                            isClearable={true}
                            showTimeInput={true}
                            disabled={this.props.searchParameters.startDate ? false : true}
                            minDate={this.props.searchParameters.startDate}
                            style={"0.4rem"}
                            textStyle={"1.0rem"}
                            iconStyle={"0.2rem"}
                            dateType={"endDate"}
                            preloadDate={this.props.preloadDate}
                        />
                    </div>

                    <div class="col-md-3">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Search</button>
                    </div>
                    { this.props.count > 0 &&
                    <><div class="col-md-3">
                        <label class="search-label">&nbsp;</label>
                        <label style={{verticalAlign:"middle"}}>
                            <input type="checkbox" onChange={event => {
                                this.props.updateSearchParameters('forcePush', !this.props.searchParameters.forcePush)
                            }} />&nbsp;
                            <span>Inject</span>
                        </label>
                    </div>
                    { this.props.searchParameters.forcePush && <div class="col-md-3">
                        <label class="search-label">&nbsp;&nbsp;</label>
                        <button type="submit" class="search-button">Repush</button>
                        </div>}</>
                    }
                </div>
            </form>
        </React.Fragment>
    }
}