import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from '../../components/forms/form';
import { FormGroup } from '../../components/forms/form-group';
import { FormLayout } from '../../components/forms/form-layout';
import LandingBanner from '../../components/landing-banner';
import { GridItem } from '../../components/layouts/grid-item';
import { HorizontalGrid } from '../../components/layouts/grids/horizontal';
import { VerticalGrid } from '../../components/layouts/grids/vertical';
import Notification from '../../components/notification';
import { ERROR_STATUS } from '../../constants/api';
import { disabledStyle } from '../../constants/styles';
import { userManagement } from '../../mixins/api';
import { checkisPasswordMatch, checkOtpIsValid, checkPasswordLength } from '../../utils/validator';

export default class ResetPasswordMobileScene extends Component {

    constructor(props) {
    super(props)

    this.state = {
      isPasswordMatch: false,
      isPasswordValid: false,
      errorMessage: null,
      successMessage: null,
      resetPasswordFormData: {}
    }
  }

  componentDidMount() {
    const { passwordResetUid } = this.props.match.params;
    this.setState({resetPasswordFormData: {...this.state.resetPasswordFormData, uuid: passwordResetUid}})
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }
  }

  async requestPasswordReset () {
    this.setState({ isLoading: true })

    const getUserResponseObj = await userManagement.resetPassword(this.state.resetPasswordFormData)

    const getUserResponse = getUserResponseObj.response
    const getUserStatus = getUserResponseObj.status

    if (getUserStatus === ERROR_STATUS) {
      this.setState({
        isLoading: false,
        errorMessage: getUserResponse
      })
      return
    }

    this.setState({
      isLoading: false,
      redirect: true,
    })
  }

  render() {

    if(this.state.redirect) {
      return <Redirect to="/login" />
    }

    return <React.Fragment>
      
      <VerticalGrid isRoot>
        <HorizontalGrid isRoot class="kt-login kt-login--v1">
          <GridItem isFluid class="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <LandingBanner />
            <GridItem isFluid class="kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
              <FormLayout title={"Reset Password"}>
                <div style={{textAlign: 'center'}}>
                  <span>
                    Please enter otp and choose a new password to finish signing in.
                  </span>
                </div>
                <Notification 
                  errorMessage={this.state.errorMessage}
                  successMessage={this.state.successMessage}
                />
                <Form class="kt-form" action="" novalidate="novalidate">
                  <FormGroup>
                    <input disabled={this.state.isLoading} style={this.state.isLoading ? disabledStyle : {}} ref={input => this.passwordInput = input} maxLength={6} id="username" class="form-control" type="text" placeholder="OTP" name="password" onChange={(event) => this.setState({ 
                      resetPasswordFormData: {...this.state.resetPasswordFormData, otp: event.target.value},
                      isOtpValid: checkOtpIsValid(event.target.value)
                    })} autoComplete="off" />
                    {(this.state.resetPasswordFormData.otp && !this.state.isOtpValid) && <span class="error">Invalid Otp</span>}
                  </FormGroup>  
                  <FormGroup>
                    <input disabled={this.state.isLoading} style={this.state.isLoading ? disabledStyle : {}} ref={input => this.passwordInput = input} id="password" class="form-control" type="password" placeholder="Password" name="password" onChange={(event) => this.setState({ 
                      resetPasswordFormData: {...this.state.resetPasswordFormData, password: event.target.value},
                      isPasswordValid: checkPasswordLength(event.target.value)
                    })} autoComplete="off" />
                    {(this.state.resetPasswordFormData.password && !this.state.isPasswordValid) && <span class="error">Choose a strong password</span>}
                  </FormGroup>
                  <FormGroup>
                    <input disabled={this.state.isLoading} style={this.state.isLoading ? disabledStyle : {}} ref={input => this.passwordInput = input} id="password2" class="form-control" type="password" placeholder="Confirm Password" name="confirmPassword" onChange={(event) => this.setState({ 
                      isPasswordMatch: checkisPasswordMatch(this.state.resetPasswordFormData.password, event.target.value),
                      resetPasswordFormData: {...this.state.resetPasswordFormData, confirmPassword: event.target.value}
                    })} autoComplete="off" />
                    {(this.state.resetPasswordFormData.confirmPassword && !this.state.isPasswordMatch) && <span class="error">Password does not match</span>}
                  </FormGroup>
                  <div class="kt-login__actions">
                    <button id="kt_login_signin_submit" disabled={this.state.isLoading || !this.state.resetPasswordFormData|| !this.state.isPasswordMatch || !this.state.isPasswordValid || !this.state.isOtpValid} class="form-control btn btn-interswitch btn-elevate kt-login__btn-primary login-button" onClick={(e) => {
                      e.preventDefault()
                      this.requestPasswordReset()
                    }}>{!this.state.isLoading ? `Reset Password` : <span class="loading">Loading</span>}</button>
                  </div>
                </Form>
              </FormLayout>
            </GridItem>
          </GridItem>
        </HorizontalGrid>
      </VerticalGrid>
    </React.Fragment>
  }
}