import AccountOpeningService from "../services/api/resources/account-opening-service";
import AuditService from "../services/api/resources/audit-service";
import FeeConfigurationManagement from "../services/api/resources/fee-configuration-management";
import HistoricalData from "../services/api/resources/historical-data";
import InstrumentationService from "../services/api/resources/instrumentation-service";
import LiquidityService from "../services/api/resources/liquidity-service";
import MessagingService from "../services/api/resources/messaging-service";
import Onboarding from "../services/api/resources/onboarding";
import PlatformManagement from "../services/api/resources/platform-management";
import PosRequests from "../services/api/resources/pos-requests";
import PosRequestsAwaitingConfig from "../services/api/resources/pos-requests-awaiting-config";
import PosRequestsAwaitingConfigUpload from "../services/api/resources/pos-requests-awaiting-config-upload";
import PosRequestsAwaitingStockUpload from "../services/api/resources/pos-requests-awaiting-stock-upload";
import ReconReport from "../services/api/resources/recon-report";
import RemapPosAll from "../services/api/resources/remap-pos-all";
import RemapPosHistory from "../services/api/resources/remap-pos-history";
import SettlementService from "../services/api/resources/settlement-service";
import TransactionHistory from "../services/api/resources/transaction-history";
import TransactionService from "../services/api/resources/transaction-service";
import UserManagement from "../services/api/resources/user-management";
import WalletProxyService from "../services/api/resources/wallet-proxy-service";
import WorkFlowManagement from "../services/api/resources/workflow-management";
import NipbankService from "../services/api/resources/NIPBankService"

export const userManagement = new UserManagement();
export const platformManagement = new PlatformManagement();
export const transactionHistory = new TransactionHistory();
export const reconReport = new ReconReport();
export const transactionService = new TransactionService();
export const settlementService = new SettlementService();
export const liquidityService = new LiquidityService();
export const onboarding = new Onboarding();
export const feeConfigurationManagement = new FeeConfigurationManagement();
export const auditService = new AuditService();
export const workFlowManagement = new WorkFlowManagement();
export const historicalData = new HistoricalData();
export const accountOpeningService = new AccountOpeningService();
export const messagingService = new MessagingService();
export const instrumentationService = new InstrumentationService();
export const posRequests = new PosRequests();
export const posRequestsAwaitingConfig = new PosRequestsAwaitingConfig();
export const posRequestsAwaitingStockUpload = new PosRequestsAwaitingStockUpload();
export const posRequestsAwaitingConfigUpload = new PosRequestsAwaitingConfigUpload();
export const remapPosAll = new RemapPosAll();
export const remapPosHistory = new RemapPosHistory();
export const walletProxyService = new WalletProxyService();
export const nipbankService = new NipbankService();
