import { getDeviceInformation } from '../../../mixins/authentication';
import { getDefaultDateFrom, getDefaultDateTo } from '../../../utils/formatter';
import Requester from '../requester';

const API_BASE_URL = process.env.REACT_APP_LIQUIDITY_SERVICE_API_BASE_URL

export default class liquidityService {
    constructor(props) {
        this.apiRequester = props ? props.apiRequester || new Requester({
        apiBaseUrl: API_BASE_URL
        }) : new Requester({
        apiBaseUrl: API_BASE_URL
        })
    }

    distributeWallet (payload, deviceUuid) {
        return this.apiRequester.post({
            endpoint: `wallet/funds/distribute`,
            body: payload,
            headers:{
                deviceUuid
            }
        })
    } 

    bulkProcessing (payload) {
        const { deviceUuid } = getDeviceInformation()
        return this.apiRequester.post({
            endpoint: `wallet/funds/bulkprocessing`,
            body: payload,
            headers: {
                deviceUuid
            }
        })
    }

    bulkProcessingDocumentUpload (bulkTransactionType, payload) {
        const { file, filename } = payload
        const formData = new FormData()
        formData.append('bulkTransactionType', bulkTransactionType)
        formData.append('file', file)
        formData.append('filename', filename)
        const { deviceUuid } = getDeviceInformation()
        return this.apiRequester.post({
          endpoint: 'wallet/funds/bulkprocessing/upload',
          body: formData,
            headers: {
                deviceUuid
            }
        })
    }

    getBulkProcessingTransactions (pageNumber, pageSize) {
        return this.apiRequester.post({
            endpoint: 'wallet/funds/bulkprocessing/proccesses',
            body: {
                pageNumber,
                pageSize,
                // "range": {
                //   "end": "2020-10-21T09:50:29.824Z",
                //   "start": "2020-10-21T09:50:29.824Z"
                // },
                "sortField": "created",
                "sortOrder": "DESC"
            }
        })
    }

    getInitiatedCashout (payload) {

        const { transRef, formattedBusinessMobileNo, pageNumber, pageSize, formattedEndDate, formattedStartDate } = payload;
        let filter = {};
        if(transRef)filter['transactionRef']=transRef;
        if(formattedBusinessMobileNo)filter['customerMsisdn']=formattedBusinessMobileNo;
        return this.apiRequester.post({
            endpoint: 'cash-out/v2/query/initiated',
            body: {
                "filter": filter, 
                ...(pageNumber !== "" ? { pageNumber } : {}),
                ...(pageSize !== "" ? { pageSize } : {}),
                "range": {
                  "end":formattedEndDate.length>0?formattedEndDate:getDefaultDateTo() ,
                  "start": formattedStartDate.length>0?formattedStartDate:getDefaultDateFrom()
                }
            }
        })
    }

    getPayWithTransfer(pageNum = 1, pageSize = 1, payload) {
        const {
          endDate,
          startDate,
          channelTransactionReference,
          accountNumber,
          forcePush,
        } = payload;
    
        return this.apiRequester.get({
            endpoint: "payments/fund_transfer",
            headers: {
              forcePush: forcePush,
            },
            args: {
              pageNum,
              pageSize,
              ...(channelTransactionReference != ""
              ? { channelTransactionReference: channelTransactionReference }
              : {}),
              ...(accountNumber != ""
                  ? { accountNumber: accountNumber }
                  : {}),
              ...(startDate != null
                ? { startDate: startDate, endDate: endDate }
                : {}),
            },
          });
      }
    
    requeryBulkTransactions (payload) {
        return this.apiRequester.post({
          endpoint: `cash-out/v2/requery`,
          body: payload
        })
    }
    
    getUploadedFile (fileName) {
        return this.apiRequester.get({
            endpoint: `wallet/funds/bulkprocessing/download/${fileName}`
        })
    }

    exportBatchCreditDebit (payload) {
        const { parentReference, statusCode, pageNumber, pageSize } = payload
        return this.apiRequester.post({
            endpoint: `wallet/funds/bulkprocessing/report/export`,
            body: {
                "filter": {
                    parentReference,
                    ...(statusCode !== 'All' ? { statusCode } : {})
                }, 
                ...(pageNumber !== "" ? { pageNumber } : {}),
                ...(pageSize !== "" ? { pageSize } : {})
            }
        })
    }

    downloadBatchCreditDebit (fileName) {
        return this.apiRequester.get({
            endpoint: `wallet/funds/bulkprocessing/download/${fileName}`
        })
    }

    initiateLienTransaction(deviceUuid, amount, customerMsisdn, reason) {
        return this.apiRequester.post({
            endpoint: 'wallet/lien',
            headers: {
                deviceUuid,
            },
            body: {
                amount,
                customerMsisdn,
                reason,
                transactionType: 'LIEN',
            }
        });
    }

    proceedLienTransaction(deviceUuid, responseData) {
        return this.apiRequester.post({
            endpoint: 'wallet/lien',
            headers: {
                deviceUuid,
            },
            body: responseData
        });
    }

    cancelLienTransaction(deviceUuid, responseData) {
        return this.apiRequester.delete({
            endpoint: 'wallet/lien',
            headers: {
                deviceUuid,
            },
            body: responseData
        });
    }

    liftLienTransaction(deviceUuid, responseData) {
        return this.apiRequester.put({
            endpoint: 'wallet/lien',
            headers: {
                deviceUuid,
            },
            body: responseData
        });
    }

    fetchLien(deviceUuid, pageNumber, pageSize) {
        return this.apiRequester.post({
            endpoint: 'wallet/lien/all',
            headers: {
                deviceUuid,
            },
            body: {
                sortOrder: 'DESC',
                sortField: 'transactionDate',
                pageNumber,
                pageSize,
            }
        });
    }

    getUploadedCashoutInjectFile() {
        return this.apiRequester.get({
            endpoint: `cash-out/kimono/bulk-inject`,
        })
    }

    postCashoutInjectFile (payload) {
        const { file, filename } = payload
        const formData = new FormData()
        formData.append('file', file)
        formData.append('filename', filename)
        return this.apiRequester.post({
          endpoint: 'cash-out/kimono/bulk-inject',
          body: formData,
        });
    }

}
