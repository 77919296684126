import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { CAN_REMAP_DEVICE } from "../../../../../../../constants/permissions.js";
import { transactionHistory } from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
// import DeviceTable from '../../components/stock-table'
// import DeviceSearch from '../../components/stock-search'
import { getDateByType } from "../../../../../../../utils/helper";
import FileUpload from "../../components/file-upload";

export default class NIPUploadGridScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {
        deviceType: "All",
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate"),
        documentStatus: "All",
        pageNum: 1,
        pageSize: 20,
      },
      errorMessage: "",
      successMessage: "",
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      stockDisable: false,
      configDisable: false,
      stockFile: [],
      configFile: { filename: "", file: "", url: "" },
      stockError: "",
      configError: "",
      redirect: false,
    };
    // this.uploadConfigFile = this.uploadConfigFile.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.readUploadedFile = this.readUploadedFile.bind(this);
  }

  sceneName = "upload-config";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }
  readUploadedFile(event, type) {
    console.log("type passed", type);
    const document = {
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      config: {
        filename: document.filename,
        file: document.file,
        url: document.url,
      },
    });
    console.log(
      document.file,
      "FILE",
      document.filename,
      "FILENAME",
      document.url,
      "URL"
    );

    if (
      document.file.type === "application/vnd.ms-excel" ||
      document.file.type === "text/csv"
    ) {
      this.setState({
        [`${type}File`]: document,
        [`${type}Disable`]: false,
        [`${type}Error`]: "",
      });
    } else {
      this.setState({
        [`${type}Error`]: "Invalid file, upload a csv file",
        [`${type}Disable`]: false,
      });
    }
  }

  async handleFileUpload() {
    this.setState({
      isLoading: true,
      configDisable: true,
      stockDisable: true,
    });

    const { file, filename } = this.state.configFile;
    const uploadFile = {
      file: file,
      filename: filename,
    };
    const responseObj = await transactionHistory.uploadNIPStock(uploadFile);

    const { status, response } = responseObj;

    if (status === "SUCCESS") {
      this.setState({
        successMessage: response.description,
        isLoading: false,
        stockDisable: false,
      });

      setTimeout(() => {
        this.setState({ redirect: true });
      }, 2000);
    } else {
      this.setState({
        errorMessage: response.description,
        isLoading: false,
        stockDisable: false,
      });
    }
  }

  render() {
    console.log("redirect", this.state.redirect);
    if (this.state.redirect) {
      return <Redirect to="/home/transaction-history" />;
    }

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Transaction History"}
          subtitle={"Upload Nip Settlement File"}
          filterPermissions={[CAN_REMAP_DEVICE]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <div class="row">
            <div class="col-lg-6">
              <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                  <div class="kt-portlet__body">
                    <h5>Upload Nip Settlement File</h5>
                    <a
                      href={`${process.env.REACT_APP_NIP_RESOLUTION_FILE_UPLOAD_TEMPLATE_URL}`}
                      target="_blank"
                      download
                    >
                      {/* Nip_Resolution_File */}
                      Download Template
                    </a>
                    <div style={{ marginTop: "50px" }}>
                      <FileUpload
                        disabled={this.state.configDisable}
                        fileErrorMessage={this.state.configError}
                        file={this.state.configFile}
                        readUploadedFile={this.readUploadedFile}
                        handleFileUpload={this.handleFileUpload}
                        type="config"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
