import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import { AuditExportSerializer } from "../../../../../../serializers";
import { formatDateTime } from "../../../../../../utils/formatter";
import { explodeDate, getPaginationRows } from "../../../../../../utils/helper";

export default class AuditReportTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "500px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }
  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "ID",
        accessor: "auditId",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 300,
      },
      {
        Header: "IP address",
        accessor: "ip",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 90,
      },
      {
        Header: "Date",
        accessor: "entryDate",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 160,
        Cell: ({ original, row }) => {
          return original.entryDate
            ? formatDateTime(explodeDate(original.entryDate, "+"))
            : "";
        },
      },
      {
        Header: "Audit Entity",
        accessor: "auditEntity",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
      },
      {
        Header: "Action",
        accessor: "auditAction",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
      },
      {
        Header: "User",
        accessor: "username",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <div class="ellipse ellipse-width-audit">{props.value}</div>
        ),
        width: 220,
      },
      {
        Header: "Audit Type",
        accessor: "auditType",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Action",
        accessor: "refNo",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        //width: 60,
        Cell: ({ row, original }) => (
          <button type="button" class="btn btn-xs link-button">
            <Link
              to={`audit-report/${original.auditId}/view`}
              class="link-button"
            >
              View
            </Link>
          </button>
        ),
        // Cell: ({row, original}) => <div class="dropdown">
        //   <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
        //     <i class="la la-ellipsis-v"></i>
        //   </a>
        //   <div class="dropdown-menu dropdown-menu-right">
        //     <Link to={`audit-report/${original.auditId}/view`} class="dropdown-item">
        //       <i class="la la-eye"></i>
        //       View
        //     </Link>
        //   </div>
        // </div>
      },
    ];
    return (
      <React.Fragment>
        <TableExtra
          data={this.props.data}
          headers={AuditExportSerializer.headerExport()}
          fileName={"audit"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAuditReport}
          zoom={this.state.zoom}
          //disabled={true}
          disabled={this.props.loading}
          page={this.props.page}
          pageSize={this.props.pageSize}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAuditReport(state.page + 1, state.pageSize);
            }
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 5 ? 10 : 5}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              padding: "0px, 0",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
