import React from 'react'
import ProtectedComponent from '../../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class AgentHierarchyCard extends React.Component {
    render() {
        return <React.Fragment>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <div class="card card-custom gutter-b card-stretch">
                    <div class="card-body pt-4">
                        <div class="d-flex justify-content-end">
                            <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left" style={{marginTop: '-40px'}}>
                                <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ki ki-bold-more-hor"></i>
                                </a>
                                <div class="dropdown dropdown-menu-md dropdown-menu-right">
                                    <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                                        <i class="la la-ellipsis-h"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ProtectedComponent requiredPermissions={[this.props.canViewPermissions]}><Link to={`agent-hierarchy/manage-permissions/${this.props.urlId}`} class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                                            <i class="la la-toggle-on"></i>
                                            Manage Permissions
                                        </Link></ProtectedComponent>
                                        <ProtectedComponent requiredPermissions={[this.props.canViewRoles]}><Link to={`agent-hierarchy/manage-roles/${this.props.urlId}`} class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                                            <i class="la la-toggle-on"></i>
                                            Manage Roles
                                        </Link></ProtectedComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingTop: '20px'}}>
                            <h3 class="align-items-center text-center">{this.props.title}</h3>
                        </div>
                        <p class="mb-7">&nbsp;</p>
                        <div class="mb-7">
                        </div>
                        <p class="btn btn-block btn-sm btn-light-warning font-weight-bolder text-uppercase py-4">&nbsp;</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}