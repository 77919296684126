import React from 'react';
import ContentHead from '../../../../../../../components/content-head';
import { GridLayout } from '../../../../../../../components/layouts/grid-layout';
import BaseScene from '../../../../../../../components/scenes/component';
import { SUCCESS_STATUS, ACCEPTED_REQUEST } from '../../../../../../../constants/api'
import AgentClassForm from '../../components/agent-class-form'
import Loader from '../../../../../../../components/loader'
import Notification from '../../../../../../../components/notification'
import { platformManagement } from '../../../../../../../mixins/api'
import { CAN_CREATE_AGENT_CLASS } from '../../../../../../../constants/permissions.js';
import ProtectedComponent from '../../../../../../../mixins/protected-component';
import { AccessDenied } from '../../../../../../../components/access-denied'
import { convertToMinor } from '../../../../../../../utils/converter';

export default class AddAgentClassScene extends BaseScene {
  sceneName = 'agent-class-add'

  canSubmit = false

  constructor (props) {
    super(props)

    this.state = {
      agentClassFormData: {
        code: '005',
        agentTypeId: 1
      },
    }

    this.requiredAgentClassFormDetailsFields = [
      "name", "description"
    ]

    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
    this.updateAgentClassFormData = this.updateAgentClassFormData.bind(this)

  }

  componentDidMount() {

  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }

    const emptyRequiredAgentClassFormDetailsFields = this.requiredAgentClassFormDetailsFields.filter(value => {
      return Boolean(this.state.agentClassFormData[value]) === false
    })

    let canSubmit = false

    if(emptyRequiredAgentClassFormDetailsFields.length == 0) {
      canSubmit = true
    }

    this.canSubmit = canSubmit
  }

  async onSubmitButtonClick (formData) {
    console.log('SUBMIT CALLED');

    this.setState({isLoading: true});
    // const responseObj = await platformManagement.createAgentClass(this.state.agentClassFormData)
    const responseObj = await platformManagement.createAgentClass({
      ...this.state.agentClassFormData,
      ...formData,
      dailyTransCountLimit: parseInt(formData.dailyTransCountLimit),
      dailyDepositLimit: convertToMinor(formData.dailyDepositLimit),
      dailyWithdrawalLimit: convertToMinor(formData.dailyWithdrawalLimit),
      singleDepositLimit: convertToMinor(formData.singleDepositLimit),
      singleWithdrawalLimit: convertToMinor(formData.singleWithdrawalLimit),
      ledgerBalanceLimit: convertToMinor(formData.ledgerBalanceLimit),
      walletSchemeRank: parseInt(formData.walletSchemeRank),
    });
    const { status, response } = responseObj
    if (status === SUCCESS_STATUS) {
      if(response.code == ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage: 'Your request to add a new agent class has been sent successfully for approval.',
        })
      }else{
        this.setState({
          isLoading: false,
          successMessage: 'Agent class created successfully',
          redirect: true,
        })
      }
    }else{
      this.setState({
        isLoading: false,
        errorMessage: response,
      })
    }
  }

  updateAgentClassFormData (type, value) {
    if (typeof type === 'object') {
      const newData = {
        ...this.state.agentClassFormData,
      };

      for (let key of type) {
        newData[key] = value;
      }

      this.setState({
        agentClassFormData: newData,
      });

      return;
    }

    this.setState({
      agentClassFormData: {
        ...this.state.agentClassFormData,
        [type]: value,
      },
    });
  }

    
  render() {
    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_CREATE_AGENT_CLASS]}>
        <button
          type="button"
          style={{backgroundColor: '#00425f', borderColor: '#00425f'}}
          class="btn btn-primary"
          onClick={() => this.form.submitForm()}
        >
          Save
        </button>
      </ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        successMessage={this.state.successMessage}
        errorMessage={this.state.errorMessage}
      />
      <ContentHead title={"Agent Classes"} withoutSearch subtitle="Create an Agent Class" ctaWidgets={ctaWidgets}/>
      <ProtectedComponent requiredPermissions={[CAN_CREATE_AGENT_CLASS]} permissionDeniedContent={this.permissionDeniedContent}>
        <GridLayout>
          <AgentClassForm
            innerRef={form => this.form = form}
            // onChange={this.updateAgentClassFormData}
            onSubmit={this.onSubmitButtonClick}
          />
        </GridLayout>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
}
