import React from 'react'

export default class WalletDistributionLayout extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
              <div class="kt-portlet__body">
                  {this.props.children}
              </div>
            </div>
        </React.Fragment>
    }
}