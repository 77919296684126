export default class PermissionGroupsExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Code', key: 'code', title: 'Code', dataIndex: 'code'}
        ]
    }

    static reversePermissionGroupDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, code } = details
            let detailFormData = {
                name,
                code
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}