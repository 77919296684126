import { convertToMajor } from '../../utils/converter';
import {
    formatCurrency
} from '../../utils/formatter';

export default class FeeExportSerializer {
    static headerExport () {
        return [
            { label: 'Name', key: 'name', title: 'Name', dataIndex: 'name' },
            { label: 'Description', key: 'description', title: 'Description', dataIndex: 'description' },
            { label: 'Borne By', key: 'borneBy', title: 'Borne By', dataIndex: 'borneBy' },
            { label: 'Banded', key: 'banded', title: 'Banded', dataIndex: 'banded' },
            { label: 'Flat Amount', key: 'flatAmount', title: 'Flat Amount', dataIndex: 'flatAmount' },
            { label: 'Percentage Amount', key: 'percentageAmount', title: 'Percentage Amount', dataIndex: 'percentage'},
            { label: 'Minimum Cap', key: 'minimumCap', title: 'Minimum Cap', dataIndex: 'minimumCap' },
            { label: 'Maximum Cap', key: 'maximumCap', title: 'Maximum Cap', dataIndex: 'maximumCap' },
            { label: 'Status', key: 'status', title: 'Status', dataIndex: 'status' },
            { label: 'Created By', key: 'createdBy', title: 'Created By', dataIndex: 'createdBy' },
            { label: 'Last Updated By', key: 'lastUpdatedBy', title: 'Last Updated By', dataIndex: 'lastUpdatedBy' },
        ]
    }

    static reverseFeeDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { 
                name, description, borneBy, banded, status, createdBy, lastUpdatedBy,
                flatAmount, percentageAmount, minimumCap, maximumCap
            } = details
            let detailFormData = {
                name,
                description,
                borneBy: borneBy === 1 ? 'Customer' : borneBy === 0 ? 'Biller' : '',
                banded: banded === true ? 'Yes' : banded === false ? 'No' : '',
                status: status === 0 ? 'Active' : status === 1 ?  'Inactive' : '',
                createdBy: createdBy ? createdBy : '',
                lastUpdatedBy: lastUpdatedBy ? lastUpdatedBy : '',
                minimumCap: minimumCap ? `₦${formatCurrency(convertToMajor(minimumCap))}` : `₦${formatCurrency(0)}`,
                maximumCap: maximumCap ? `₦${formatCurrency(convertToMajor(maximumCap))}` : `₦${formatCurrency(0)}`,
                flatAmount: flatAmount ? `₦${formatCurrency(convertToMajor(flatAmount))}` : `₦${formatCurrency(0)}`,
                percentageAmount: percentageAmount ? `${percentageAmount}%` : `0%`,
                
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}