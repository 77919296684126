import React from 'react'
import Cron from 'react-cron-generator'
import 'react-cron-generator/dist/cron-builder.css'
import { Redirect } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { AccessDenied } from '../../../../../../components/access-denied'
import ContentHead from '../../../../../../components/content-head'
import Loader from '../../../../../../components/loader'
import Notification from '../../../../../../components/notification'
import BaseScene from '../../../../../../components/scenes/component'
import { SUCCESS_STATUS } from '../../../../../../constants/api'
import { CAN_MESSAGING_SEND } from '../../../../../../constants/permissions'
import { messagingService } from '../../../../../../mixins/api'
import ProtectedComponent from '../../../../../../mixins/protected-component'
import { formatTemplateDataParameters } from '../../../../../../utils/formatter'

export default class AddRecurringMessageScene extends BaseScene {
  requiredPermissions = [
    CAN_MESSAGING_SEND
  ]

  sceneName = 'recurring-message'
  metadataSectionRef = React.createRef(null)

  canSubmit = false

  componentDidMount () {
    require('./cron.css');
    this.props.updateScene(this.sceneName)
    this.getTopics()
    this.getTemplate()
  }

  constructor (props) {
    super(props)
    this.requiredTopicsFormFields = [
      "topics", "template",
    ]

    this.state = {
      topics: [],
      template: [],
      messagingForm: {},
      templateKeyArray: [],
      dataForm: {},
      cron: ''
    }

    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.submitRecurringMessage = this.submitRecurringMessage.bind(this);
  }


  componentDidUpdate (prevProps, prevState) {
    const emptyRequiredTopicsFormFields = this.requiredTopicsFormFields.filter(value => {
      return Boolean(this.state.messagingForm[value]) === false
    })

   

    const newState = {}
    if (emptyRequiredTopicsFormFields.length === 1) {
      newState.canSubmit = true
    }
    else if (emptyRequiredTopicsFormFields.length !==  0) {
      newState.canSubmit = false
    }

    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({errorMessage: null})
      }, 5000)
    }

    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({successMessage: null})
      }, 5000)
    }

    this.canSubmit = newState.canSubmit
  }


  async getTemplate () {
    const { status, response } = await messagingService.getAllTemplates();

    const transformTemplate = (response) => {
      const newTemplateResponse = response.map(({ name: key, description: label, name: value, ...rest}) => ({ label, value, ...rest}));
      return newTemplateResponse
    }

    if(status === SUCCESS_STATUS) {
      this.setState({
        template: transformTemplate(response)
      })
    } else {
      this.setState({
        template: []
      })
    }
  }

  async getTopics () {
    const { status, response } = await messagingService.getTopics();

    const transformTopics = (response) => {
      const newTopicsResponse = response.map(({ name: key, description: label, name: value, ...rest}) => ({ label, value, ...rest}));
      return newTopicsResponse
    }

    if(status === SUCCESS_STATUS) {
      this.setState({
        topics: transformTopics(response)
      })
    } else {
      this.setState({
        topics: []
      })
    }
  }

  updateMessagingForm(type, value) {
    if (type === 'template') {
      this.getTemplateParameters(value.key);
      this.setState({
        messagingForm: {...this.state.messagingForm, [type]: value.key},
        channels: value.channels
      })
      return
    }
    this.setState({
      messagingForm: {...this.state.messagingForm, [type]: value.id},
      agentMatch: value.match
    })
  }

  updateMessagingDataForm(type, value) {
    this.setState({
      dataForm: {...this.state.dataForm, [type]: value}
    })
  }

  async submitRecurringMessage() {
    this.setState({
      isLoading: true
    })
    const { dataForm, messagingForm, cron, name } = this.state
    const data = {
      request: {
        data: dataForm,
        templateKey: messagingForm.template,
        to: [messagingForm.topics],
        topic: true,
      },
      cron: cron,
      name: name
    }
    console.log(data)
    const { status } = await messagingService.createRecurringMessage(data);
    if (status === SUCCESS_STATUS) {
      this.setState({
        isLoading: false,
        successMessage: `Recurring message created successfully`,
        redirect: true
      })
    } else {
      this.setState({
        isLoading: false,
        errorMessage: `Failed to create recurring message`,
        redirect: false
      })
    }
  }

  async searchTopics(value) {
    const transformTopics = (response) => {
      const newTopicsResponse = response.map(({ name: key, description: label, name: value, ...rest}) => ({ label, value, ...rest}));
      return newTopicsResponse
    }
    const payload = {
      queryName: value
    }
    const { status, response } = await messagingService.getAllTopicsQuery(1, 20, payload);
    const { items } = response;

    if (status === SUCCESS_STATUS) {
      this.setState({
        topics: transformTopics(items)
      })
    } else {
      this.setState({
        topics: []
      })
    }
  }

  async searchTemplate(value) {
    const transformTopics = (response) => {
      const newTopicsResponse = response.map(({ name: key, description: label, name: value, ...rest}) => ({ label, value, ...rest}));
      return newTopicsResponse
    }
    const payload = {
      queryName: value
    }
    const { status, response } = await messagingService.getAllTemplatesQuery(1, 20, payload);
    const { items } = response;
    if (status === SUCCESS_STATUS) {

      this.setState({
        template: transformTopics(items)
      })
    } else {
      this.setState({
        template: []
      })
    }
  }

  async getTemplateParameters(key) {
    this.setState({
      isLoading: true
    })
    const { status, response} = await messagingService.getTemplateParameters(key);
    if(status === SUCCESS_STATUS) {
      this.setState({
        templateKeyArray: response,
        isLoading: false,
      })
    } else {
      this.setState({
        isLoading: false,
        templateKeyArray: [],
        errorMessage: 'Failed to get template parameters'
      })
    }
  }

  handleQueryChange(data) {
    this.updateTemplateForm('query', data)
  }

  isFormValid () {
    return Boolean(!this.state.name || !this.state.messagingForm.template || !this.state.messagingForm.topics || !this.state.cron || this.state.isLoading) 
  }

  render () {

    if(this.state.redirect) {
      return <Redirect to="/home/notifications/recurring-message" />
  }

    const ctaWidgets = <React.Fragment>
      <ProtectedComponent requiredPermissions={[CAN_MESSAGING_SEND]}><button disabled={this.isFormValid()} class="btn" style={{backgroundColor: '#00425f', color: 'white'}} onClick={this.submitRecurringMessage}>Submit</button></ProtectedComponent>
    </React.Fragment>

    return <React.Fragment>
      <Loader 
        isLoading={this.state.isLoading}
      />
      <Notification
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        ref={this.metadataSectionRef}
      />
      <ContentHead 
        title={"Recurring Message"} 
        withoutSearch subtitle={`Send a new recurring message`}
        ctaWidgets={ctaWidgets}
      />
      <ProtectedComponent requiredPermissions={[CAN_MESSAGING_SEND]} permissionDeniedContent={this.permissionDeniedContent}>
        <div class="kt-portlet">
          <div class="kt-portlet__body">
            <div class="form-group row">
              <div class="col-lg-6">
                <label>Name *:</label>
                <input autoComplete="off" type="name" class="form-control" onChange={(event) => this.setState({'name': event.target.value})} />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-3">
                <label>Notification Group Name *:</label>
                <AsyncSelect 
                  cacheOptions 
                  defaultOptions={this.state.topics && this.state.topics}
                  loadOptions={this.searchTopics.bind(this)}
                  options={this.state.topics && this.state.topics}
                  onChange={event => this.updateMessagingForm('topics', event)}
                />
              </div>
              <div class="col-lg-3">
                <label>Messaging Template Name *:</label>
                <AsyncSelect 
                  cacheOptions 
                  defaultOptions={this.state.template && this.state.template}
                  loadOptions={this.searchTemplate.bind(this)}
                  options={this.state.template && this.state.template}
                  onChange={event => this.updateMessagingForm('template', event)}
                />
              </div>
              {(this.state.agentMatch >= 0) && <div class="col-lg-3">
                <label>Agent Match *:</label>
                <p style={{ padding: '10px'}}><b>{this.state.agentMatch}</b></p>
              </div>}

              {(this.state.channels) && <div class="col-lg-3">
                <label>Channels *:</label>
                <p style={{ padding: '10px'}}><b>{(this.state.channels).toString()}</b></p>
              </div>}
            </div>

            <div class="form-group row">
              {
                this.state.templateKeyArray.length !== 0 && this.state.templateKeyArray.map((key, idx) => (
                  <div class="col-lg-4" style={{paddingBottom: '20px'}}key={idx}>
                    <label>{formatTemplateDataParameters(key)}</label>
                    <input autoComplete="off" disabled={this.props.disabled ? this.props.disabled : ''} type="name" class="form-control" placeholder={key} onChange={(event) => this.updateMessagingDataForm(key, event.target.value)} />
                  </div>
                )) 
              }
             </div>

            <div class="form-group row">
              <div class="col-lg-12">
                <Cron
                  onChange={(e)=> {this.setState({'cron': e})}}
                  //onChange={(e) => console.log(e)}
                  value={this.state.value}
                  showResultText={true}
                  showResultCron={true}
                />
              </div>
            </div>
          </div>
        </div>
      </ProtectedComponent>
    </React.Fragment>
  }
  get permissionDeniedContent() {
    return <AccessDenied /> 
  }
 
}
