import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "../../../../../../components/pagination";
import SpinnerComponent from "../../../../../../components/spinner";
import TableExtra from "../../../../../../components/table-extra";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { TransactionExportSerializer } from "../../../../../../serializers";
import { convertToMajor } from "../../../../../../utils/converter";
import {
  formatCurrency,
  formatDateTime,
  formatTransactionStatus,
  formatTransactionStatusColorCode,
} from "../../../../../../utils/formatter";
import {
  getPaginationRows,
  getTransactionTypeName,
} from "../../../../../../utils/helper";

export default class TransactionHistoryTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "950px",
    zoom: "plus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);

  zoomTableHeight() {
    this.setState({
      tableHeight:
        this.state.tableHeight === "430px"
          ? "860px"
          : this.state.tableHeight === "860px"
          ? "auto"
          : "430px",
      zoom: this.state.tableHeight === "860px" ? "minus" : "plus",
    });
  }

  getCombinedServiceAndServiceOption(service_name, service_option_name) {
    if (service_name === service_option_name) {
      return service_name;
    }
    if (!service_option_name) {
      return service_name;
    }

    return `${service_name} (${service_option_name})`;
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: (
          <input
            type="checkbox"
            onClick={(event) =>
              this.props.selectAllTransactions(event.target.checked)
            }
          />
        ),
        accessor: "transactionRef",
        Cell: (id) => <span>{id.index + 1}</span>,
        sortable: false,
        resizable: false,
        width: 25,
        headerStyle: { "white-space": "unset", textAlign: "center" },
        Cell: ({ row, original }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                checked={original.checked}
                onClick={(event) =>
                  this.props.getSelectedTransactions(
                    event.target.checked,
                    row.transactionRef
                  )
                }
                disabled={formatTransactionStatus(row.statusCode) !== "Pending"}
                style={{ verticalAlign: "middle" }}
              />
            </div>
          );
        },
      },
      {
        Header: "Trans Ref",
        accessor: "transactionRef",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
        Cell: ({ row, original }) => (
          <a
            className="selectable"
            style={{ cursor: "pointer", wordBreak: "break-all" }}
            data-toggle="modal"
            data-target="#transaction_details_modal"
            onClick={() => this.props.getClickedAction(original, "view")}
          >
            {original.transactionRef}
          </a>
        ),
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => (
          <span class="word-capitalize">
            {props.value ? props.value.toLowerCase() : ""}
          </span>
        ),
      },
      {
        Header: "Trans Type",
        accessor: "transactionTypeInt",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        Cell: (props) => <span>{getTransactionTypeName(props.value)}</span>,
      },
      {
        Header: "Service & Service Option",
        accessor: "serviceOptionName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 200,
        Cell: (props) => (
          <span>
            {this.getCombinedServiceAndServiceOption(
              props.original.service,
              props.original.serviceOptionName
            )}
          </span>
        ),
      },
      {
        Header: "Init SP Resp Code",
        accessor: "serviceProviderResponseCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
        Cell: ({ row, original }) => (
          <span>
            {original.serviceProviderResponseCode
              ? original.serviceProviderResponseCode
              : ""}
          </span>
        ),
      },
      {
        Header: "Final SP Resp Code",
        accessor: "responseCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
        Cell: ({ row, original }) => (
          <span>{original.responseCode ? original.responseCode : ""}</span>
        ),
      },
      {
        Header: "Sevice Provider",
        accessor: "serviceProvider",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 70,
      },
      {
        Header: "Status",
        accessor: "statusCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
        Cell: (props) => {
          return (
            <span
              class={`badge ${formatTransactionStatusColorCode(props.value)}`}
            >
              {formatTransactionStatus(props.value)}
            </span>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "transactionAmount",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
        Cell: (props) => (
          <span>
            &#x20A6;
            {props.value
              ? formatCurrency(convertToMajor(props.value))
              : formatCurrency(0)}
          </span>
        ),
      },
      {
        Header: "Date",
        accessor: "dateCreated",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
        Cell: (props) => {
          return props.value ? formatDateTime(props.value) : "";
        },
      },
      {
        Header: "Batch Credit/Debit Reference",
        accessor: "parentReference",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 150,
      },
      {
        Header: "Action",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        width: 70,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  data-toggle="modal"
                  data-target="#transaction_details_modal"
                  onClick={() => this.props.getClickedAction(original, "view")}
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-eye"></i>
                  View
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={formatTransactionStatus(row.statusCode) != "Pending"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(row.transactionRef, "resolve")
                  }
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-unlock-alt"></i>
                  Resolve
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={formatTransactionStatus(row.statusCode) != "Pending"}
                  onClick={() =>
                    this.props.requerySingleTransaction(row.transactionRef)
                  }
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-refresh"></i>
                  Re-query
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <a
                  hidden={
                    formatTransactionStatus(row.statusCode) !== "Pending" &&
                    formatTransactionStatus(row.statusCode) !== "Failed"
                  }
                  onClick={() =>
                    this.props.arbitrateSingleTransaction(row.transactionRef)
                  }
                  class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-wrench"></i>
                  Arbitrate
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={TransactionExportSerializer.reverseTransactionHistoryDetails(
            this.props.data
          )}
          headers={TransactionExportSerializer.headerExport()}
          fileName={"transaction_history"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getTransactions}
          zoom={this.state.zoom}
          page={this.props.page}
          pageSize={this.props.pageSize}
          requeryBulkTransactions={this.props.requeryBulkTransactions}
          desiredStatus={this.props.desiredStatus}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          dataCount={this.props.dataCount}
          pageSizes={getPaginationRows(this.props.dataCount)}
          columns={columns}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          onFetchData={(state, instance) => {
            const {
              businessMobileNo,
              businessName,
              endDate,
              startDate,
              transactionStatus,
              transactionType,
              transRef,
              responseCode,
              parentReference,
            } = this.props.searchParameters;
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getTransactions(
                state.page + 1,
                state.pageSize,
                businessMobileNo,
                businessName,
                endDate,
                startDate,
                transactionStatus,
                transactionType,
                transRef,
                responseCode,
                parentReference
              );
            }
          }}
          headerNodeHeight={`123`}
          minRows={this.props.data.length > 0 ? 10 : 10}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display: "flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft: "5px",
              paddingRight: "5px",
              alignItems: "center",
              padding: "0px, 0",
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding: "10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
