import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  formatDate,
  formatWalletStatus,
  formatWalletStatusClassName,
} from "../../../../../../utils/formatter";
import { getPaginationRows } from "../../../../../../utils/helper";
import SpinnerComponent from "../../../../../../components/spinner";
import Pagination from "../../../../../../components/pagination";
import TableExtra from "../../../../../../components/table-extra";
import { AgentDetailsExportSerializer } from "../../../../../../serializers";
import ProtectedComponent from "../../../../../../mixins/protected-component";
import { Link } from "react-router-dom";

export default class AggregatorTable extends React.Component {
  state = {
    page: 0,
    tableHeight: "950px",
    zoom: "minus",
  };

  zoomTableHeight = this.zoomTableHeight.bind(this);
  getSerialNumber = this.getSerialNumber.bind(this);

  zoomTableHeight() {
    this.setState({});
  }

  getSerialNumber(index) {
    return this.props.pageSize * (this.props.page - 1) + index;
  }

  render() {
    const MyLoader = (props) => {
      return (
        props.loading && (
          <div className="-loading -active">
            <div className="-loading-inner">
              <SpinnerComponent isLoading={props.loading} />
            </div>
          </div>
        )
      );
    };
    const columns = [
      {
        Header: "S/N",
        accessor: "id",
        Cell: (id) => <span>{this.getSerialNumber(id.index + 1)}</span>,
        sortable: false,
        resizable: false,
        width: 35,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "left",
              whiteSpace: "unset",
            },
          };
        },
      },
      {
        Header: "Aggregator ID",
        accessor: "agentCode",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 100,
      },
      {
        Header: "Phone Number",
        accessor: "agentMobileNo",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        Cell: (props) => (
          <span class="word-capitalize ellipse" style={{ width: "150px" }}>
            {props.value.toLowerCase()}
          </span>
        ),
      },
      {
        Header: "Agent Class",
        accessor: "agentClass",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        Cell: (props) => (
          <span class="word-capitalize">
            {props.value ? props.value.toLowerCase() : ""}
          </span>
        ),
      },
      {
        Header: "Date Created",
        accessor: "dateApplied",
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        resizable: false,
        width: 100,
        Cell: (props) => {
          return props.value ? formatDate(props.value) : null;
        },
      },
      {
        Header: "Last Modified",
        accessor: "dateLastUpdated",
        resizable: false,
        width: 100,
        style: { "white-space": "unset" },
        headerStyle: { "white-space": "unset" },
        Cell: (props) => {
          return props.value ? formatDate(props.value) : null;
        },
      },
      {
        Header: "Profile Config",
        accessor: "walletStatus",
        resizable: false,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        width: 120,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <span
            style={{ color: "#ffffff" }}
            class={`kt-badge ${formatWalletStatusClassName(
              props.value
            )} word-capitalize kt-badge--inline kt-badge--pill`}
          >
            {props.value ? formatWalletStatus(props.value) : null}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "statusId",
        resizable: false,
        width: 80,
        headerStyle: { "white-space": "unset" },
        style: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "center",
              // marginTop: "0.2px",
              // marginBottom: "1px",
            },
          };
        },
        Cell: (props) => (
          <span
            style={{ color: "#ffffff" }}
            class={`kt-badge ${
              props.value === 1 || props.value === 0
                ? "kt-badge--primary"
                : props.value === 2
                ? "kt-badge--success"
                : props.value === 3
                ? "kt-badge--warning"
                : props.value === 4
                ? "kt-badge--danger"
                : props.value === 5
                ? "kt-badge--info"
                : ""
            } kt-badge--inline kt-badge--pill`}
          >
            {props.value === 0
              ? "New"
              : props.value === 1
              ? "New"
              : props.value === 2
              ? "Active"
              : props.value === 3
              ? "Inactive"
              : props.value === 4
              ? "Retired"
              : props.value === 5
              ? "Dormant"
              : ""}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "agentCode",
        overflow: "visible",
        resizable: false,
        sortable: false,
        headerStyle: { "white-space": "unset" },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              lineHeight: "7px",
              color: "#ffffff",
              textAlign: "center",
              whiteSpace: "unset",
            },
          };
        },
        width: 60,
        Cell: ({ row, original }) => (
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-hover-brand btn-icon btn-pill"
              data-toggle="dropdown"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <Link
                  to={`aggregator-management/${row.agentCode}/view`}
                  class="dropdown-item"
                >
                  <i class="la la-eye"></i>
                  View
                </Link>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canView]}>
                <Link
                  to={`agent-management?parentId=${row.agentCode}`}
                  onClick={() =>
                    this.props.setAggregatorName(original.businessName)
                  }
                  class="dropdown-item"
                >
                  <i class="la la-users"></i>
                  View Agents
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canManageDomain]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  to={`domain-management/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-tasks"></i>
                  Manage Domain
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canViewTransaction]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() =>
                    this.props.redirectToCommissionHistory(original)
                  }
                  to={`commission-history/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-book"></i>
                  View Commission History
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canViewTransaction]}
              >
                <Link
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() => this.props.redirectToWalletJournal(original)}
                  to={`account-statement/${row.agentCode}`}
                  class="dropdown-item"
                >
                  <i class="la la-book"></i>
                  View Statement of Account
                </Link>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canActivate]}
              >
                <a
                  hidden={original.statusId === 3 ? "" : "hidden"}
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      "activate"
                    )
                  }
                  /*href={`/home/aggregator-management/${'activate'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-toggle-on"></i>
                  Activate
                </a>
              </ProtectedComponent>
              <ProtectedComponent
                requiredPermissions={[this.props.canDeactivate]}
              >
                <a
                  hidden={original.statusId === 2 ? "" : "hidden"}
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      `suspend`
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  /*href={`/home/aggregator-management/${'suspend'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-ban"></i>
                  Suspend
                </a>
              </ProtectedComponent>
              <ProtectedComponent requiredPermissions={[this.props.canRetire]}>
                <a
                  hidden={original.statusId !== 4 ? "" : "hidden"}
                  onClick={() =>
                    this.props.getClickedAction(
                      row.agentCode,
                      row.businessName,
                      `retire`
                    )
                  }
                  data-toggle="modal"
                  data-target="#kt_modal_1"
                  /*href={`/home/aggregator-management/${'retire'}/${row.agentCode}`}*/ class="dropdown-item"
                  style={{ color: "#A7ABC3", cursor: "pointer" }}
                >
                  <i class="la la-lock"></i>
                  Retire
                </a>
              </ProtectedComponent>
            </div>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <TableExtra
          data={AgentDetailsExportSerializer.reverseAgentDetails(
            this.props.data
          )}
          headers={AgentDetailsExportSerializer.headerExport()}
          fileName={"aggregators"}
          zoomTableHeight={this.zoomTableHeight}
          getData={this.props.getAggregators}
          zoom={this.state.zoom}
          pageSize={this.props.pageSize}
          page={this.props.page}
          downloadLink={this.props.downloadAggregatorData}
          disabled={this.props.loading}
        />
        <ReactTable
          PaginationComponent={Pagination}
          loading={this.props.loading}
          LoadingComponent={MyLoader}
          style={{
            border: "none",
            boxShadow: "none",
            whiteSpace: "unset",
            height: this.state.tableHeight,
          }}
          data={this.props.data}
          columns={columns}
          dataCount={this.props.dataCount}
          noDataText={!this.props.loading ? this.props.noDataText : ""}
          pageSizes={getPaginationRows(this.props.dataCount)}
          headerNodeHeight={`123`}
          manual
          pages={this.props.pages}
          page={
            this.props.startPage ? this.props.startPage - 1 : this.state.page
          }
          minRows={this.props.data.length > 0 ? 10 : 10}
          onFetchData={(state, instance) => {
            this.setState({ page: state.page });
            if (this.props.canLoadData) {
              this.props.getAggregators(state.page + 1, state.pageSize);
            }
          }}
          getTableProps={(state, rowInfo, column, instance) => ({
            style: {
              borderColor: "#00425f",
              boxSizing: "none",
              border: "none",
            },
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            style: {
              display:"flex",
              overflow: "visible",
              color: "black",
              fontSize: "11.5px",
              fontWeight: "50",
              paddingLeft:"5px",
              paddingRight:"5px",
              alignItems: "center",
              padding:  "0px, 0",
              
            },
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            style: {
              backgroundColor: "#00425f",
              borderBottom: "0.1px solid #DBDEDE",
              borderWidth: "0.1px",
              padding:"10px 0",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "100",
              textAlign: "left",
              paddingLeft:"5px",
              paddingRight:"5px",
            },
          })}
          getPaginationProps={(state, rowInfo, column, instance) => ({
            style: {
              overflow: "visible",
              backgroundColor: "#ffffff",
            },
          })}
          className="-striped -highlight"
        />
      </React.Fragment>
    );
  }
}
