export default class BusinessTypesExportSerializer {
    static headerExport() {
        return [
            { label: 'Name', key: 'business_type', title: 'Name', dataIndex: 'business_type' }
        ]
    }

    static reverseBusinessTypeDetails(formData) {
        let newFormData = [];
        formData && formData.map(details => {
            let { business_type } = details
            let detailFormData = {
                business_type
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}