import Requester from "../requester";
const API_BASE_URL = process.env.REACT_APP_WALLET_PROXY_SERVICE_API_BASE_URL;

export default class WalletProxyService {
  constructor(props) {
    this.apiRequester = props
      ? props.apiRequester || new Requester({ apiBaseUrl: API_BASE_URL })
      : new Requester({ apiBaseUrl: API_BASE_URL });
  }

  proceedLienTransaction(payload) {
    return this.apiRequester.post({
      endpoint: "lien/initiate/placelien",
      body: payload,
    });
  }
  requeryLienSingleTransaction(requestID) {
    return this.apiRequester.get({
      endpoint: `lien/report/repushAwaitingPosting?requestId=${requestID}`,
    });
  }

  requeryLienBulkTransaction() {
    return this.apiRequester.get({
      endpoint: "lien/report/repushAwaitingPosting",
    });
  }

  cancelLienTransaction(payload) {
    return this.apiRequester.post({
      endpoint: "lien/initiate/canclelien",
      body: payload,
    });
  }

  liftLienTransaction(payload) {
    return this.apiRequester.post({
      endpoint: "lien/initiate/liftlien",
      body: payload,
    });
  }

  getTheWalletPerformance(
    page,
    pageSize,
    formattedStartDate,
    formattedEndDate,
    status
  ) {
    return this.apiRequester.get({
      endpoint: `DatabaseMaintenance/getDatabaseScheduledJobResult?taskID=${status}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&pageNumber=${page}&rowsOfPage=${pageSize}`,
    });
  }
  fetchLien(pageNumber, pageSize) {
    return this.apiRequester.get({
      endpoint: `lien/report/getAllLienApprovalRequest?pageNumber=${pageNumber}&rowsOfPage=${pageSize}`,
    });
  }

  fetchLienBySearchParams(pageNumber, pageSize, searchParameters) {
    return this.apiRequester.get({
      endpoint: `lien/report/getLienRequestByStatus?pageNumber=${pageNumber}&rowsOfPage=${pageSize}&approvalStatus=${searchParameters.lienStatus}&accountCode=${searchParameters.accountCode}`,
      headers: {
        NoAuthorization: "true",
      },
    });
  }

  lienStatement(body) {
    return this.apiRequester.post({
      endpoint: "lien/report/getLienStatement",
      body: body,
      headers: {
        NoAuthorization: "true",
      },
    });
  }

  lienMiniStatement(body) {
    return this.apiRequester.post({
      endpoint: "lien/report/getLienMiniStatement",
      body: body,
      headers: {
        NoAuthorization: "true",
      },
    });
  }
}
