import React from "react";
import FeeSplits from "../../../../../../components/fee-splits";
import {
  formatTransactionStatus
} from "../../../../../../utils/formatter";

const FeeSplit = (props) => {
  if (
    formatTransactionStatus(
      props.transactionDetails.statusCode
        ? props.transactionDetails.statusCode
        : ""
    ) == "Successful" &&
    props.transactionDetails.transactionTypeInt < 10
  ) {
    return (
      <div class="form-group row">
        <FeeSplits feeSplits={props.parties} isLoading={props.isLoading} />
      </div>
    );
  } else return null;
};

export default class PosRequestDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }
  render() {
    const {
      agentUsername,
      id,
      paymentStatus,
      posRequestStockId,
      posUnitPrice,
      quantity,
      requestId,
      terminalModel,
      terminalType,
      totalPrice,
    } = this.props.posRequestDetails ? this.props.posRequestDetails : "";
    const resul = this.props.posRequestDetails[0] ? this.props.posRequestDetails[0] : "";

    return (
      <React.Fragment>
        <div
          class="modal"
          id="pos_request_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg"
            style={{ overflowY: "scroll", overflowX: "auto", fontSize: "14px" }}
          >
            <div class="modal-content" style={{ borderRadius: "15px" }}>
              <div class="modal-header">
                <h3 class="modal-title" style={{ textAlign: "center" }}>
                  POS Request Details
                </h3>
              </div>

              <div class="kt-portlet">
                <div
                  class="kt-portlet__head"
                  style={{ cursor: "pointer" }}
                  // data-toggle="collapse"
                  // data-target={`#collapseTransDetails`}
                  // aria-expanded="true"
                  // aria-controls={`collapseTransDetails`}
                >
                  <div
                    class="kt-portlet__head-label"
                    role="button"
                    aria-expanded="true"
                  >
                    <h3 class="kt-portlet__head-title">
                      <label style={{ cursor: "pointer" }}>
                        <strong>Request Details</strong>
                      </label>
                    </h3>
                  </div>
                </div>
                <form
                  class="kt-form kt-form--label-right"
                  // id={`collapseTransDetails`}
                  // class={`collapse 'show'}`}
                  aria-labelledby="headingOne4"
                >
                  <div class="kt-portlet__body">
                    <div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Model</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.terminalModel : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Terminal Type</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.terminalType : "loading..."}
                          </span>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>Payment Status</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.paymentStatus : "loading..."}
                          </span>
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Workflow type</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.workflowType : "loading..."}
                          </span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>
                            <strong>POS Unit Price</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.posUnitPrice : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                        <div class="col-lg-6">
                          <label>
                            <strong>Request Type</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.requestType : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div>
                      </div>
                      <div class="form-group row">
                        {/* <div class="col-lg-6">
                          <label>
                            <strong>Request Type</strong>
                          </label>
                          <br />
                          <span>
                            {resul ? resul.requestType : "loading..."}
                          </span>
                          <br />
                          <br />
                        </div> */}
                      </div>

                      <hr />
                      <div class="form-group row">
                        <ol>
                          <br />
                          <br />
                          {this.props.posRequestDetails
                            ? this.props.posRequestDetails.map(
                                (detail, index) => (
                                  <>
                                    <li key={index}>
                                      <div class="form-group row">
                                        <div class="col-lg-6">
                                          <label>
                                            <strong>Request ID:</strong>
                                          </label>
                                          <br />
                                          <span>
                                            {detail.posRequestStockRequestId}
                                          </span>
                                        </div>
                                        <div class="col-lg-6">
                                          <label>
                                            <strong>Workflow Status:</strong>
                                          </label>
                                          <br />
                                          <span>{detail.workflowStatus}</span>
                                        </div>

                                        <div class="col-lg-6">
                                          <br />
                                          <br />
                                          <label>
                                            <strong>
                                              Configuration Status:
                                            </strong>
                                          </label>
                                          <br />
                                          <span>
                                            {detail.configurationStatus}
                                          </span>
                                        </div>

                                        <div class="col-lg-6">
                                          <br />
                                          <br />
                                          <label>
                                            <strong>Delivery Status</strong>
                                          </label>
                                          <br />
                                          <span>
                                            {
                                              detail.deliveryStatus
                                              }
                                            {/* {resul
                                              ? resul.deliveryStatus
                                              : "loading..."} */}
                                          </span>
                                        </div>
                                        {localStorage.setItem("cancelId", JSON.stringify(detail.posRequestStockId))}


                                        {detail.cancelled === true ? (
                                          <button
                                            type="button"
                                            // class="btn btn-default"
                                            // class="btn col-lg-6"
                                            class="btn btn-primary col-lg-4 mt-3"
                                            style={{backgroundColor:"#004257"}}
                                            // style={{
                                            //   height: "40px",
                                            //   width: "70px",
                                            //   padding: "0px",
                                            //   borderRadius: "15px",
                                            //   borderColor: "#00425f",
                                            //   backgroundColor: "#00425f",
                                            //   color: "#ffffff",
                                            //   fontSize: "14px",
                                            //   margin: "auto",
                                            //   display: "inline-block",
                                            // }}
                                            data-dismiss="modal"
                                            onClick={() => {
                                              this.props.confirmConfigure();
                                            }}
                                            // onClick={() => {
                                            //   this.props.cancelPosRequest(
                                            //     detail.posRequestStockId
                                            //   );
                                            // }}
                                          >
                                            Cancel Request
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                        <div />
                                      </div>
                                      <br />
                                    </li>
                                  </>
                                )
                              )
                            : "Loading..."}
                        </ol>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    height: "40px",
                    width: "100px",
                    padding: "0px",
                    borderRadius: "15px",
                    border: "2px solid #00425f",
                    backgroundColor: "#ffffff",
                    color: "#00425f",
                    fontSize: "14px",
                    margin: "auto",
                    display: "block",
                  }}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
