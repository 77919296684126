import Requester from '../requester';

const API_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL;
const API_BASE_URL_LOGIN_ONLY =
  process.env.REACT_APP_USER_MANAGEMENT_API_BASE_URL_LOGIN;

export default class UserManagement {
  constructor(props) {
    const currentUser = JSON.parse(localStorage.getItem('user'));

    this.currentUserEmail = currentUser ? currentUser.email : null;
    this.currentUser = currentUser ? currentUser.access_token : null;
    this.refresh_token = currentUser ? currentUser.refresh_token : null;

    this.apiRequester = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL,
        });

    this.apiRequester2 = props
      ? props.apiRequester ||
        new Requester({
          apiBaseUrl: API_BASE_URL_LOGIN_ONLY,
        })
      : new Requester({
          apiBaseUrl: API_BASE_URL_LOGIN_ONLY,
        });
  }

  loginUserOld(data) {
    return this.apiRequester.post({
      endpoint: 'auth/users/oauth/authenticate-user',
      body: data,
    });
  }

  downloadRemapData(payload) {
    const { pageNum, pageSize, fromDate, toDate, posRequestsStatus } = payload;
    return this.apiRequester.get({
      endpoint: `api/v1/finch/user-mgmt/pos-remap-request/admin/download`,
      args: {
        pageNumber: pageNum,
        pageSize: pageSize,
        // ...(batchId != "" ? { batchId } : {}),
        // ...(deviceModel != "" ? { deviceModel } : {}),
        // ...(deviceType != "All" ? { deviceType } : {}),
        // ...(posRequestsStatus != 'awaiting_configuration' ? { posRequestsStatus } : {}),
        status: posRequestsStatus,
        // status: 'awaiting_configuration',
        ...(fromDate != null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  loginUser(data) {
    return this.apiRequester2.post({
      endpoint: 'auth/users/oauth/admin/authenticate-user',
      body: data,
    });
  }

  logoutUser(data) {
    return this.apiRequester.delete({
      endpoint: 'auth/users/logout',
      headers: {
        accessToken: data,
      },
    });
  }

  forgotPassword(payload) {
    return this.apiRequester.post({
      endpoint: `auth/users/recover-password`,
      body: payload,
    });
  }

  resetPassword(payload) {
    return this.apiRequester.post({
      endpoint: `auth/users/reset-password`,
      body: payload,
    });
  }

  refreshUserSession(username, refresh_token) {
    return this.apiRequester.get({
      endpoint: `auth/users/refreshUserToken`,
      headers: {
        refreshToken: refresh_token,
        username: username,
      },
      auth: false,
    });
  }

  changeUserPassword(payload) {
    return this.apiRequester.post({
      endpoint: `auth/users/change-password`,
      headers: {
        NoAuthorization: 'true',
        authorization: `Bearer ${
          payload.accessToken ? payload.accessToken : this.currentUser
        }`,
      },
      body: payload,
    });
  }

  getDevicesByDomainCode(domainCode) {
    return this.apiRequester.get({
      endpoint: `device/byDomainCode`,
      headers: {
        domainCode,
        Authorization: `Bearer ${this.currentUser}`,
      },
    });
  }

  updateDeviceStatus(status, deviceId) {
    return this.apiRequester.put({
      endpoint: `device/admin`,
      headers: {
        deviceId,
        active:
          status === 'activate' ? true : status === 'deactivate' ? false : '',
        Authorization: `Bearer ${this.currentUser}`,
        'Content-Type': 'application/json',
      },
      body: [],
    });
  }

  deleteDevice(deviceId) {
    return this.apiRequester.delete({
      endpoint: `device/admin`,
      headers: {
        deviceId,
        Authorization: `Bearer ${this.currentUser}`,
        'Content-Type': 'application/json',
      },
      body: [],
    });
  }

  createFip(payload) {
    return this.apiRequester.post({
      endpoint: `users/onboard-fip-user`,
      body: payload,
    });
  }

  getFips(pageNum = null, pageSize = null) {
    return this.apiRequester.get({
      endpoint: `users/getAllFipUsers`,
      args: {
        pageNum,
        pageSize,
      },
    });
  }

  updateFipStatus(status, username) {
    return this.apiRequester.put({
      endpoint: `users/${status}-fip-user`,
      args: {
        username,
      },
    });
  }

  getFipByUsername(username) {
    return this.apiRequester.get({
      endpoint: 'users/getFipUser',
      args: {
        username,
      },
    });
  }

  getFipByDomainCode(domainCode) {
    return this.apiRequester.get({
      endpoint: 'fips',
      args: {
        domainCode,
      },
    });
  }

  updateFip(username, payload) {
    return this.apiRequester.put({
      endpoint: `users/update-fip-user`,
      body: payload,
    });
  }

  getFipUsers(payload) {
    return this.apiRequester.post({
      endpoint: `users/getFipUserBySearchParam`,
      body: payload,
    });
  }

  getAllPermissions() {
    return this.apiRequester.get({
      endpoint: `permissions`,
    });
  }

  getPermissionById(permissionId) {
    return this.apiRequester.get({
      endpoint: `permissions/${permissionId}`,
    });
  }

  updatePermission(permissionId, payload) {
    return this.apiRequester.put({
      endpoint: `permissions`,
      body: payload,
    });
  }

  createPermission(permissionGroupId, payload) {
    return this.apiRequester.post({
      endpoint: `permissions/${permissionGroupId}`,
      body: payload,
    });
  }

  getDomainTypePermissions(domainType) {
    return this.apiRequester.get({
      endpoint: `domain-types/${domainType}/permissions`,
    });
  }

  addDomainPermission(domainType, payload) {
    return this.apiRequester.post({
      endpoint: `domain-types/${domainType}/permissions`,
      body: payload,
    });
  }

  deleteDomainPermission(domainType, payload) {
    return this.apiRequester.delete({
      endpoint: `domain-types/${domainType}/permissions`,
      body: payload,
    });
  }

  getAllPermissionGroups() {
    return this.apiRequester.get({
      endpoint: `permissions/group`,
    });
  }

  getGroupPermissions(groupId) {
    return this.apiRequester.get({
      endpoint: `permissions/group/${groupId}`,
    });
  }

  getDomainTypeRoles(domainTypeId) {
    return this.apiRequester.get({
      endpoint: `domain-types/roles/${domainTypeId}`,
    });
  }

  getDomainTypeRolePermissions(roleId) {
    return this.apiRequester.get({
      endpoint: `domain-types/roles/${roleId}/permissions`,
    });
  }

  createDomainTypeRolePermissions(roleId, payload) {
    return this.apiRequester.post({
      endpoint: `domain-types/roles/${roleId}/permissions`,
      body: payload,
    });
  }

  removeDomainTypeRolePermissions(roleId, payload) {
    return this.apiRequester.delete({
      endpoint: `domain-types/roles/${roleId}/permissions`,
      body: payload,
    });
  }

  getUserByMobileNo(mobileNo) {
    return this.apiRequester.post({
      endpoint: `users/getUserByMobileNo`,
      args: {
        mobileNo,
      },
    });
  }

  getAllDevices(payload) {
    return this.apiRequester.post({
      endpoint: `device/admin/by-search-param`,
      body: payload,
    });
  }

  getDeviceById(deviceId) {
    return this.apiRequester.get({
      endpoint: `device/admin/${deviceId}`,
    });
  }
  getPosRemapById(uuid) {
    return this.apiRequester.get({
      endpoint: `device/by-uuid`,
      // endpoint: `device/${uuid}`,
      headers: { deviceUuid: uuid },
    });
  }

  remapDevice(payload) {
    return this.apiRequester.post({
      endpoint: `device/admin/remap-pos`,
      body: payload,
    });
  }
  getAllStocks() {
    return this.apiRequester.get({
      endpoint: `pos-stocks`,
    });
  }
  getAllQuickStocks() {
    return this.apiRequester.get({
      endpoint: `device`,
    });
  }

  getStocks(payload) {
    const {
      pageNum,
      pageSize,
      fromDate,
      toDate,
      stockStatus,
      batchId,
      deviceModel,
      deviceType,
    } = payload;
    return this.apiRequester.get({
      endpoint: `pos-stocks/search`,
      args: {
        pageNumber: pageNum,
        pageSize,
        ...(batchId !== '' ? { batchId } : {}),
        ...(deviceModel !== '' ? { deviceModel } : {}),
        ...(deviceType !== 'All' ? { deviceType } : {}),
        ...(stockStatus !== 'All' ? { stockStatus } : {}),
        ...(fromDate !== null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
    });
  }

  getStocksUploadrequest(payload) {
    const {
      pageNum,
      pageSize,
      fromDate,
      toDate,
      batchId,
      documentType,
      documentStatus,
    } = payload;
    return this.apiRequester.get({
      endpoint: `pos-stocks/upload-requests/search`,
      args: {
        pageNumber: pageNum,
        pageSize,
        ...(batchId !== '' ? { batchId } : {}),
        ...(documentType !== '' ? { documentType } : {}),
        // ...(deviceModel != "" ? { deviceModel } : {}),
        // ...(deviceType != "All" ? { deviceType } : {}),
        ...(documentStatus !== 'All' ? { documentStatus } : {}),
        ...(fromDate !== null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
    });
  }

  getStockById(id) {
    return this.apiRequester.get({
      endpoint: `pos-stocks/${id}`,
    });
  }
  getAllStockTable() {
    return this.apiRequester.get({
      endpoint: `pos-stocks`,
    });
  }

  editPosDetails(payload) {
    return this.apiRequester.put({
      endpoint: `pos-stocks/pos-model`,
      body: payload,
    });
  }
  uploadPosImage(payload, model) {
    return this.apiRequester2.put({
      endpoint: `pos-stocks/${model}/upload`,
      body: payload,
      headers: { 'content-type': 'multipart/form-data' },
    });
  }

  getPosModelPrice(model) {
    return this.apiRequester.get({
      endpoint: `pos-stocks/price/${model}`,
    });
  }

  updatePosModelPrice(price, model) {
    return this.apiRequester.put({
      endpoint: `pos-stocks/price/${price}/${model}`,
    });
  }

  downloadStocks(payload) {
    const {
      pageNum,
      pageSize,
      fromDate,
      toDate,
      stockStatus,
      batchId,
      deviceModel,
      deviceType,
    } = payload;
    return this.apiRequester.get({
      endpoint: `pos-stocks/download`,
      args: {
        pageNumber: pageNum,
        pageSize,
        ...(batchId !== '' ? { batchId } : {}),
        ...(deviceModel !== '' ? { deviceModel } : {}),
        ...(deviceType !== 'All' ? { deviceType } : {}),
        ...(stockStatus !== 'All' ? { stockStatus } : {}),
        ...(fromDate !== null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
      headers: {
        download: true,
      },
    });
  }
  downloadPosAwaitingConfig(payload) {
    const { pageNum, pageSize, fromDate, toDate, posRequestsStatus } = payload;
    return this.apiRequester.get({
      endpoint: `pos-request-configuration/download`,
      args: {
        pageNumber: pageNum,
        pageSize: pageSize,
        // ...(batchId != "" ? { batchId } : {}),
        // ...(deviceModel != "" ? { deviceModel } : {}),
        // ...(deviceType != "All" ? { deviceType } : {}),
        // ...(posRequestsStatus != 'awaiting_configuration' ? { posRequestsStatus } : {}),
        status: posRequestsStatus,
        // status: 'awaiting_configuration',
        ...(fromDate !== null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  downloadRemaptData(payload) {
    const {
      pageNum,
      pageSize,
      approvalStatus,
      agentUsername,
      requestId,
      fromDate,
      toDate,
    } = payload;
    return this.apiRequester.get({
      endpoint: 'pos-remap-request/admin/download',
      args: {
        pageNymber: pageNum,
        pageSize,
        ...(approvalStatus !== null ? { approvalStatus } : {}),
        ...(agentUsername !== null ? { agentUsername } : {}),
        ...(requestId !== null ? { requestId } : {}),
        ...(fromDate !== null ? { fromDate } : {}),
        ...(fromDate !== null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
      headers: {
        download: true,
      },
    });
  }

  uploadStock(payload) {
    const { file, filename } = payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);
    return this.apiRequester.post({
      endpoint: `pos-stocks/upload`,
      body: formData,
    });
  }
  uploadBacklogStock(payload) {
    const { file, filename } = payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);
    return this.apiRequester.post({
      endpoint: `pos-request-configuration/offline_upload`,
      body: formData,
    });
  }
  uploadQuickStock(payload) {
    const { file, filename } = payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);
    return this.apiRequester.post({
      endpoint: `device/update-pos-settings/upload-file`,
      body: formData,
    });
  }

  uploadConfig(payload) {
    const { file, filename } = payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);
    return this.apiRequester.post({
      endpoint: `pos-stocks/upload/config`,
      body: formData,
    });
  }

  allocatePOS(payload) {
    return this.apiRequester.post({
      endpoint: `pos-request-configuration`,
      body: payload,
      // headers: {
      //   download: true,
      // },
    });
  }

  arbitratePosRequest(payload) {
    const { type, agentNumber, requestId } = payload;

    return this.apiRequester2.get({
      endpoint: `pos/device/arbitrate/${requestId}`,
      args: {
        agentNumber: agentNumber
      },
      headers: {
         type: type,
       },
    });
  }

  getPosRequestConfigurationBySearch(payload) {
    const { pageNum, pageSize, fromDate, toDate, batchId } = payload;

    return this.apiRequester.get({
      endpoint: `pos-request-configuration/search`,
      args: {
        pageNumber: pageNum,
        pageSize,
        ...(batchId !== '' ? { batchId } : {}),

        ...(fromDate !== null ? { fromDate: fromDate, toDate: toDate } : {}),
      },
    });
  }
  getPosRequestConfigurationByBatch(batchId) {
    return this.apiRequester.get({
      endpoint: `pos-request-configuration/download/${batchId}`,
      headers: {
        download: true,
      },
    });
  }
}
