import React from "react";
import { AccessDenied } from "../../../../../../../components/access-denied";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  ACCEPTED_REQUEST,
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import {
  CAN_UPDATE_FEE_RULE,
  CAN_VIEW_FEE_RULE,
} from "../../../../../../../constants/permissions.js";
import {
  feeConfigurationManagement,
  platformManagement,
} from "../../../../../../../mixins/api";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import FeeRuleForm from "../../components/fee-rule-form";

export default class ViewFeeRuleScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = {
      canSave: null,
      pageTitle: "View Fee Rule",
      canSave: false,
      canUpdate: false,
      disabled: true,
      feeRuleFormData: {},
      fees: [],
      serviceTypes: [{ id: 0, name: "Any" }],
      services: [{ id: 0, name: "Any" }],
      serviceOptions: [{ id: 0, name: "Any" }],
      agentTypes: [
        { id: 0, name: "Any" },
        { id: 1, name: "Agent" },
        { id: 2, name: "Super Agent" },
      ],
      agentCategories: [],
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this);
    this.updateFeeRuleFormData = this.updateFeeRuleFormData.bind(this);
    this.getServiceTypeById = this.getServiceTypeById.bind(this);
    this.getServiceById = this.getServiceById.bind(this);
    this.getAgentCategories = this.getAgentCategories.bind(this);
    this.filterAgentCategories = this.filterAgentCategories.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  componentDidMount() {
    this.feeRuleId = this.props.match.params.id;
    this.getAgentCategories();
    this.getServiceTypes();
    this.getFees();
    this.getFeeRule();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getFeeRule() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.getFeeRule(
      this.feeRuleId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      response.agentCategoryType = this.getAgentTypeId(response.agentCategory);
      this.getServiceTypeById(response.serviceType);
      this.getServiceById(response.service);
      this.setState({
        feeRuleFormData: response,
        canUpdate: true,
        isLoading: false,
      });
    } else {
      this.setState({
        feeRuleFormData: [],
        isLoading: false,
        errorMessage: response,
      });
    }
  }

  async getServiceTypes() {
    this.setState({ isGetServiceTypeLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceTypes();
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreServiceTypes(count);
    } else {
      this.setState({
        serviceTypes: this.state.serviceTypes,
        isGetServiceTypeLoading: false,
      });
    }
  }

  async getFees() {
    this.setState({ isFeeLoading: true });
    const responseObj = await feeConfigurationManagement.getFees();
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.getMoreFees(count);
    } else {
      this.setState({
        fees: [],
        isFeeLoading: false,
      });
    }
  }

  async getMoreFees(pageSize) {
    this.setState({ isFeeLoading: true });
    const responseObj = await feeConfigurationManagement.getFees(
      null,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        fees: response.data,
        isFeeLoading: false,
      });
    } else {
      this.setState({
        fees: [],
        isFeeLoading: false,
      });
    }
  }

  async getMoreServiceTypes(pageSize) {
    this.setState({ isGetServiceTypeLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceTypes(
      null,
      pageSize
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceTypes: response.data
          ? this.updateBackendResponseWithAny(response.data)
          : [],
        serviceTypesCount: count,
        isGetServiceTypeLoading: false,
      });
    } else {
      this.setState({
        serviceTypes: this.state.serviceTypes,
        serviceTypesCount: 0,
        isGetServiceTypeLoading: false,
      });
    }
  }

  async getServiceTypeById(serviceTypeId, withServices = true) {
    this.setState({ isServiceLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceType(
      serviceTypeId,
      withServices
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        services: response.services
          ? this.updateBackendResponseWithAny(response.services)
          : [],
        isServiceLoading: false,
      });
    } else {
      this.setState({
        services: this.state.services,
        isServiceLoading: false,
      });
    }
  }

  async getServiceById(serviceId, withOptions = true) {
    this.setState({ isServiceOptionLoading: true });
    const responseObj = await feeConfigurationManagement.getServiceById(
      serviceId,
      withOptions
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        serviceOptions: response.serviceOptions
          ? this.updateBackendResponseWithAny(response.serviceOptions)
          : [],
        isServiceOptionLoading: false,
      });
    } else {
      this.setState({
        serviceOptions: this.state.serviceOptions,
        isServiceOptionLoading: false,
      });
    }
  }

  async getAgentCategories(agentType = null) {
    this.setState({ isAgentCategoryLoading: true });
    const responseObj = await platformManagement.getAgentClasses();
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        agentCategories: response
          ? this.updateBackendResponseWithAny(
              this.filterAgentCategories(response, agentType)
            )
          : [],
        isAgentCategoryLoading: false,
      });
    } else {
      this.setState({
        agentCategories: [],
        isAgentCategoryLoading: false,
      });
    }
  }

  filterAgentCategories(agentCategories, agentType) {
    if (agentType) {
      return agentCategories.filter(
        (value) => value.agentTypeId === agentType && value.disabled !== 1
      );
    }
    return agentCategories;
  }

  async onUpdateButtonClick() {
    this.setState({
      disabled: false,
      canSave: true,
      canUpdate: false,
      pageTitle: "Update Fee Rule",
    });
  }

  async onCancelButtonClick() {
    this.setState({
      disabled: true,
      canSave: false,
      canUpdate: true,
      pageTitle: "View Fee Rule",
    });
  }

  async onSaveButtonClick() {
    this.setState({ isLoading: true });
    const responseObj = await feeConfigurationManagement.updateFeeRule(
      this.feeRuleId,
      this.state.feeRuleFormData
    );
    const { status, response } = responseObj;
    window.scrollTo(0, this.notificationSectionRef.offsetTop);

    if (status === SUCCESS_STATUS) {
      if (response.code === ACCEPTED_REQUEST) {
        this.setState({
          isLoading: false,
          successMessage:
            "Your changes to this fee rule has been sent successfully for approval.",
          pageTitle: "View Fee Rule",
          canSave: false,
          canUpdate: true,
          disabled: true,
        });
        return;
      } else {
        this.setState({
          isLoading: false,
          pageTitle: "View Fee Rule",
          canSave: false,
          canUpdate: true,
          disabled: true,
          successMessage: "Fee rule updated successfully.",
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errorMessage: response,
      });
    }
    return;
  }

  updateFeeRuleFormData(type, value) {
    this.setState({
      feeRuleFormData: { ...this.state.feeRuleFormData, [type]: value },
    });
  }

  updateBackendResponseWithAny(response) {
    let anyObj = { id: 0, name: "Any" };
    let obj = [];
    obj.push(anyObj);
    response.map((detail) => obj.push(detail));
    return obj;
  }

  getAgentTypeId(agentCategory) {
    let result = this.state.agentCategories.filter(
      (detail) => agentCategory === detail.id
    );
    return result && result[0] ? result[0].agentTypeId : 0;
  }

  render() {
    const ctaWidgets = (
      <React.Fragment>
        {this.state.canUpdate && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE_RULE]}>
            <button
              type="button"
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onUpdateButtonClick}
            >
              Edit
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE_RULE]}>
            <button
              type="button"
              class="btn btn-secondary btn-bold btn-sm btn-icon-h"
              id="kt_subheader_group_actions_fetch"
              onClick={this.onCancelButtonClick}
            >
              Cancel
            </button>
          </ProtectedComponent>
        )}
        {this.state.canSave && (
          <ProtectedComponent requiredPermissions={[CAN_UPDATE_FEE_RULE]}>
            <button
              type="button"
              class="btn btn-primary"
              style={{ backgroundColor: "#00425f", borderColor: "#00425f" }}
              onClick={this.onSaveButtonClick}
            >
              Save Changes
            </button>
          </ProtectedComponent>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Fee Rules"}
          withoutSearch
          subtitle={this.state.pageTitle}
          ctaWidgets={ctaWidgets}
        />
        <ProtectedComponent
          permissionDeniedContent={this.permissionDeniedContent}
          requiredPermissions={[CAN_VIEW_FEE_RULE]}
        >
          <GridLayout>
            <FeeRuleForm
              serviceTypes={this.state.serviceTypes}
              services={this.state.services}
              serviceOptions={this.state.serviceOptions}
              agentCategories={this.state.agentCategories}
              agentTypes={this.state.agentTypes}
              fees={this.state.fees}
              updateFeeRuleFormData={this.updateFeeRuleFormData}
              getServiceTypeById={this.getServiceTypeById}
              getServiceById={this.getServiceById}
              getAgentCategories={this.getAgentCategories}
              feeRuleFormData={this.state.feeRuleFormData}
              disabled={this.state.disabled}
              isGetServiceTypeLoading={this.state.isGetServiceTypeLoading}
              isServiceLoading={this.state.isServiceLoading}
              isServiceOptionLoading={this.state.isServiceOptionLoading}
              isAgentCategoryLoading={this.state.isAgentCategoryLoading}
              isFeeLoading={this.state.isFeeLoading}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }
  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
