import React from "react";
import Select from "../../../../../components/select";

export default function WalletPerformanceSearch(props) {
  const [status, setStatus] = React.useState("");

  React.useEffect(() => {
    setStatus("JOB-001");
  }, []);

  const handleSearchRequest = (e) => {
    e.preventDefault();
    props.handleSearchRequest(status);
  };

  const WalletStatuses = [
    {
      value: "JOB-001",
      label: "Maintenance on Daily Accrue Value Reset Process",
    },
    { value: "JOB-002", label: "Maintenance on Ledger Account EOD" },
    { value: "JOB-003", label: "Maintenance on Customer Account EOD" },
    { value: "JOB-004", label: "Maintenance on tbl_request_payload_dump" },
    { value: "JOB-005", label: "Maintenance on tbl_non_trans_request_history" },
    { value: "JOB-006", label: "Maintenance on  [tbl_errorLog]" },
    { value: "JOB-007", label: "Maintenance on Expired User Session Token" },
    {
      value: "JOB-008",
      label: "Maintenance on Customer Balance Integrity Validation",
    },
    { value: "JOB-009", label: "Maintenance on  tbl_app_errorLog" },
    {
      value: "JOB-010",
      label: "Maintenance on tbl_unique_ledger_post_validator",
    },
    { value: "JOB-011", label: "Maintenance on tbl_trans_request_history" },
  ];

  return (
    <React.Fragment>
      <div class="search-params">
        <form onSubmit={(e) => handleSearchRequest(e)}>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="search-label">Task Name</label>
              <Select
                options={WalletStatuses}
                onChange={(event) => setStatus(event.value)}
                defaultValue={status}
              />
            </div>
            <div class="col-md-2">
              <label class="search-label">&nbsp;&nbsp;</label>
              <button type="submit" class="search-button">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
