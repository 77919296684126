import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import StockGridScene from './scenes/stock-grid';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_GET_DEVICES_BY_UUID } from '../../../../../constants/permissions.js'

export default class PosStocksScene extends Component {
  sceneName = 'pos-stocks'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_GET_DEVICES_BY_UUID]} exact path='/home/pos-management/pos-stocks' component={StockGridScene} />
    </Router>
  }
}
