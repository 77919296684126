import React from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { FormInput } from '../../../../../../components/form-controls/form-input';
import PhoneNumberComponent from '../../../../../../components/phone-number-component';
import Select from '../../../../../../components/select';

const VALIDATION_SCHEMA = Yup.object().shape({
	// accruedDailyDeposit: Yup.number()
	// 	.typeError('Field must be a number')
	// 	.min(0, 'Value is too small')
	// 	.required('Field is required'),
	dailyDepositLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	// dailyTransCount: Yup.number()
	// 	.typeError('Field must be a number')
	// 	.min(0, 'Value is too small')
	// 	.required('Field is required'),
	dailyTransCountLimit: Yup.number()
        .integer()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	dailyWithdrawalLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	ledgerBalanceLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	singleDepositLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
	singleWithdrawalLimit: Yup.number()
		.typeError('Field must be a number')
		.min(0, 'Value is too small')
		.required('Field is required'),
});

export default function AgentLimitDetails({
    agentLimitFormData, disabled2, hasFormBeenSubmitted, innerRef, onSubmit,
    ...props
}) {
    const initialFormValues = {
        ...agentLimitFormData,
    };

    return <React.Fragment>
        <div className="kt-portlet" id="collapseOne1">
            {!props.hideTitleHeader && <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target="#collapseOne1" aria-controls="collapseOne1">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Business Details
                    </h3>
                </div>
            </div>}
            <form class="kt-form kt-form--label-right" id={'agent-class-form'}>
                <div class="kt-portlet__body">
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label>Business Name *:</label>
                            <input autoComplete="off" disabled={props.disabled ? props.disabled : ''} type="text" class="form-control" defaultValue={props.businessDetails ? props.businessDetails.businessName : ''} placeholder="Enter business name" onChange={(event) => props.onChange('businessName', event.target.value)} />
                        </div>
                        <div class="col-lg-4">
                            <label>Business Type *:</label>
                            <Select 
                                options={props.businessTypes.map(detail => { return ( {label: detail.name, value: detail.name} ) })}
                                onChange={(event) => props.onChange('businessType', event.value)}
                                disabled={props.disabled}
                                defaultValue={props.businessDetailsFormData ? props.businessDetailsFormData.businessType : props.businessDetails.businessType}
                            />
                            {/* <input type="text" list="idtypes" disabled={props.disabled ? props.disabled : ''} autoComplete="off" class="form-control" defaultValue={props.businessDetails ? props.businessDetails.businessType : ''} onChange={(event) => props.onChange('businessType', event.target.value)} />
                            <datalist id="idtypes">
                                {props.businessTypes.map(details => {
                                    return (
                                        <option value={details.name} />
                                    );
                                })}
                            </datalist> */}
                        </div>
                        <div class="col-lg-4">
                            <label>Company Registration Number *:</label>
                            <div class="kt-input-icon">
                                <input autoComplete="off" disabled={props.disabled ? props.disabled : ''} type="text" class="form-control" defaultValue={props.businessDetails ? props.businessDetails.companyRegistrationNumber : ''} maxLength={8} placeholder="Enter registration number" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        {((props.businessDetails && props.businessDetails.phoneNumber) || props.showPhoneInput) && <div class="col-lg-4">
                            <label>Phone *:</label>
                            <div class="kt-input-icon">
                                <PhoneNumberComponent 
                                    phoneIsValid={props.businessPhoneIsValid ? props.businessPhoneIsValid : true}
                                    defaultValue={(props.businessDetails && props.businessDetails.phoneNumber) ? props.businessDetails.phoneNumber :  ''}
                                    disabled={props.disabled ? props.disabled : ''}
                                />
                                {props.businessDetailsFormData.phoneNumber && !props.businessPhoneIsValid && <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span>
                                        <i class="la la-info-circle" style={{color: 'red'}}></i>
                                    </span>
                                </span>}
                            </div>
                        </div>}
                        <div class="col-lg-4">
                            <label class="">State *:</label>
                            <Select 
                                options={props.states.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                onChange={(event) => props.updateState(event.value,'businessState')}
                                disabled={props.disabled}
                                defaultValue={props.businessDetailsFormData.state}
                            />
                        </div>
                        <div class="col-lg-4">
                            <label class="">LGA *:</label>
                            <Select 
                                options={props.lgas.map(detail => { return ( {label: detail.name, value: detail.id} ) })}
                                onChange={(event) => props.onChange('localGovernmentArea', event.value)}
                                disabled={props.disabled}
                                defaultValue={props.businessDetailsFormData.localGovernmentArea}
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Address *:</label>&nbsp;&nbsp;
                            {(!props.disabled && props.applicantDetailsFormData.address) && <label>
                                <input type="checkbox" onChange={event => {
                                    //props.updateSetState('shouldUpdateBusinessAddress', !props.shouldUpdateBusinessAddress)
                                    props.updateBusinessAddress(!props.shouldUpdateBusinessAddress)
                                }} />
                                <span>Tick this box if your business address is same as applicant address</span>
                            </label>}
                            <div class="kt-input-icon kt-input-icon--right">
                                <textarea autoComplete="off" disabled={props.disabled ? props.disabled : ''} rows={5} style={{resize: 'none'}} class="form-control" placeholder="Enter office address" defaultValue={(props.businessDetailsFormData && props.businessDetailsFormData.address) ? props.businessDetailsFormData.address : (props.businessDetails && props.businessDetails.address) ? props.businessDetails.address : ''} onChange={(event) => props.onChange('address', event.target.value)} />
                            </div>
                            <span class="form-text text-muted">Office address</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-4">
                            <label>Email Address:</label>
                            <input autoComplete="off" disabled={props.disabled ? props.disabled : ''} type="text" class="form-control" defaultValue={props.businessDetails ? props.businessDetails.businessEmail : ''} placeholder="Enter business name" onChange={(event) => props.onChange('businessEmail', event.target.value)} />
                        </div>
                    
                        {props.showAgentClass && <div class="col-lg-4">
                            <label>Agent Class:</label>
                            <Select 
                                options={props.agentClasses.map(detail => { return ( {label: detail.name, value: `${detail.id}-${detail.name}`} ) })}
                                onChange={(event) => props.onChange('agentClass', event.value)}
                                disabled={props.disabled}
                                defaultValue={props.businessDetailsFormData.agentClassId ? `${props.businessDetailsFormData.agentClassId}-${props.businessDetailsFormData.agentClass}` : `${props.businessDetails.agentClassId}-${props.businessDetails.agentClass}`}
                            />
                            {/* <select autoComplete="off" disabled={props.disabled2 ? props.disabled2 : ''} class="form-control" onChange={(event) => props.onChange('agentClass', event.target.value)} data-live-search="true">
                                <option disabled selected>Select agent class</option>
                                {props.agentClasses.map(value => <option value={`${value.id}-${value.name}`} selected={props.businessDetails.agentClassId == value.id ? 'selected' : ''}>{value.name}</option>)}
                            </select> */}
                        </div>}
                        {(props.businessDetails && props.businessDetails.agentTypeId) == 4 && <div class="col-lg-4">
                            <label>Aggregator:</label>
                            <Select 
                                options={props.aggregators.map(detail => { return ( {label: detail.businessName, value: detail.referralCode} ) })}
                                onChange={(event) => props.onChange('superAgent', event.value)}
                                disabled={props.disabled}
                                defaultValue={props.businessDetailsFormData.superAgent ? props.businessDetailsFormData.superAgent : props.businessDetails.parentId}
                            />
                            {/* <select autoComplete="off" class="form-control" disabled={props.disabled ? props.disabled : ''} onChange={(event) => props.onChange('superAgent', event.target.value)} data-live-search="true">
                                <option disabled selected>Select Aggregator</option>
                                {props.aggregators.map(value => <option value={value.referralCode} selected={props.businessDetails.parentId == value.referralCode ? 'selected' : ''}>{value.businessName}</option>)}
                            </select> */}
                        </div>}
                    </div>
                    <div class="form-group row">
                <div class="col-lg-4">
                    <label>Bank Name *:</label>
                    <Select 
                        options={props.banks.map(detail => { return ( {label: detail.name, value: detail.name} ) })}
                        onChange={(event) => props.onChange('bankName', event.value)}
                        disabled={props.disabled}
                        defaultValue={props.businessDetailsFormData ? props.businessDetailsFormData.bankName : props.businessDetails.bankName}
                    />
                    {/* <select autoComplete="off" disabled={props.disabled ? props.disabled : ''} class="form-control multipleselect" data-live-search="true" onChange={(event) => props.onChange('bankName', event.target.value)}>
                        <option selected disabled>Select Bank</option>
                        {props.banks.map((value, index) => <option value={value.name} selected={props.businessDetails && props.businessDetails.bankName == value.name ? 'selected' : ''}>{value.name}</option>)}
                    </select> */}
                </div>
                <div class="col-lg-4">
                    <label>Account Number *:</label>
                    <div class="kt-input-icon">
                        <input autoComplete="off" disabled={props.disabled ? props.disabled : ''} type="text" class="form-control" defaultValue={props.businessDetails ? props.businessDetails.accountNumber : ''} maxLength={10} placeholder="Enter account number" />
                    </div>
                </div>

                <div class="col-lg-4">
                    <label>BVN *:</label>
                    <div class="kt-input-icon">
                        <input autoComplete="off" disabled={props.disabled ? props.disabled : ''} type="text" class="form-control" defaultValue={props.applicantDetails ? props.applicantDetails.bvn : ''} maxLength={11} placeholder="Enter your BVN" />
                    </div>
                </div>
            </div>
                </div>
            </form>
        </div>
        <div class="kt-portlet" id="collapseOne2">
            {!props.hideTitleHeader && <div class="kt-portlet__head" style={{cursor: 'pointer'}} data-toggle="collapse" data-target="#collapseOne2" aria-controls="collapseOne2">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Transaction Limit
                    </h3>
                    &nbsp;&nbsp;
                    <span 
                        style={{color: '#ffffff'}} 
                        class={
                                    `kt-badge ${agentLimitFormData.specialLimitRequest
                                    ? 'kt-badge--success' : 'kt-badge--primary'
                                } kt-badge--inline kt-badge--pill`
                            }>
                                {
                                    agentLimitFormData.specialLimitRequest
                                    ? 'SPECIAL LIMITS' :  'GLOBAL LIMITS'
                                }
                    </span>
                </div>
            </div>}
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                // isInitialValid={true}
                innerRef={innerRef}
                onSubmit={(values, actions) => {
                    onSubmit({
                        ...values,
                    });
                }}
                validationSchema={VALIDATION_SCHEMA}
            >
                <Form class="kt-form kt-form--label-right" id={'agent-class-form'}>
                    <div class="kt-portlet__body">
                        <div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyTransCount}
											disabled={true}
											placeholder={'0'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Transactions Count"
								name="dailyTransCount"
							/>
							
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.accruedDailyDeposit}
											disabled={true}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Accrued Daily Deposit"
								name="accruedDailyDeposit"
							/>
						</div>

						<div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyDepositLimit}
											disabled={disabled2}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Deposit Limit"
								name="dailyDepositLimit"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyWithdrawalLimit}
											disabled={disabled2}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Withdrawal Limit"
								name="dailyWithdrawalLimit"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.dailyTransCountLimit}
											disabled={disabled2}
											placeholder={'0'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Daily Transactions Count Limit"
								name="dailyTransCountLimit"
							/>
						</div>

						<div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.singleDepositLimit}
											disabled={disabled2}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Single Deposit Limit"
								name="singleDepositLimit"
							/>

							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.singleWithdrawalLimit}
											disabled={disabled2}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Single Withdrawal Limit"
								name="singleWithdrawalLimit"
							/>
						</div>

						<div class="row" style={{padding: '8px'}}>
							<Field
								component={
									(props) => (
										<FormInput
											{...props}
											className="form-group col-lg-4"
											defaultValue={initialFormValues.ledgerBalanceLimit}
											disabled={disabled2}
											placeholder={'0.00'}
										/>
									)
								}
								hasFormBeenSubmitted={hasFormBeenSubmitted}
								label="Ledger Balance Limit"
								name="ledgerBalanceLimit"
							/>
						</div>
                    </div>
                </Form>
            </Formik>
        </div>
    </React.Fragment>;
}
