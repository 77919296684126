import React from "react";
import ContentHead from "../../../../../../../components/content-head";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Loader from "../../../../../../../components/loader";
import { PromptModal } from "../../../../../../../components/modals/prompt-modal";
import Notification from "../../../../../../../components/notification";
import BaseScene from "../../../../../../../components/scenes/component";
import { SUCCESS_STATUS } from "../../../../../../../constants/api";
import {
  liquidityService,
  workFlowManagement,
} from "../../../../../../../mixins/api";
import { retrieveSessionInformation } from "../../../../../../../mixins/authentication";
import ApprovalView from "../../components/approval-view";

export default class ViewApprovalDetailsScene extends BaseScene {
  notificationSectionRef = React.createRef(null);

  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      approvalDetails: [],
      approvalFormData: {},
      history: [],
      modulename: "",
    };
    this.currentUser = retrieveSessionInformation();

    this.approveWorkflow = this.approveWorkflow.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    //this.onApproveRejectButtonClick = this.onApproveRejectButtonClick.bind(this)
  }

  componentDidMount() {
    this.approvalId = this.props.match.params.id;
    this.getApprovalDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  async getApprovalDetails() {
    this.setState({ isLoading: true });
    const responseObj = await workFlowManagement.getApprovalById(
      this.approvalId
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        approvalDetails: response[0],
        history: response[0].history,
        canApproveReject: this.canApproveReject(
          response[0].step,
          response[0].runingWorkflow.steps
        ),
        isLoading: false,
        modulename: response[0].runingWorkflow.name,
      });
    } else {
      this.setState({
        approvalDetails: [],
        history: [],
        canApproveReject: false,
        isLoading: false,
        errorMessage: false,
      });
    }
  }

  canApproveReject(currentStep, allSteps) {
    let canApproveReject = false;
    allSteps.map((step) => {
      if (step.id === currentStep) {
        step.approvers.map((approver) => {
          if (approver.email === this.currentUser.user.username) {
            canApproveReject = true;
          }
        });
      }
    });
    return canApproveReject;
  }

  onApproveRejectButtonClick(approved) {
    this.setState({
      approvalFormData: {
        ...this.state.approvalFormData,
        approved: approved,
      },
      approvalEvent: approved === "true" ? "approved" : "rejected",
    });
  }

  async approveWorkflow() {
    this.setState({ isLoading: true });
    const responseObj = await workFlowManagement.approveWorkflow(
      this.approvalId,
      this.state.approvalFormData
    );
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      this.setState({
        successMessage: `Workflow was ${this.state.approvalEvent} succesfully!`,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
    this.getApprovalDetails(this.approvalId);
  }

  get toShowApproveRejectButton() {
    return (
      this.state.approvalDetails.status === "DECLINED" ||
      this.state.approvalDetails.status == "APPROVED_CLOSED" ||
      !this.state.canApproveReject
    );
  }

  isFormValid() {
    return Boolean(this.state.approvalFormData.message);
  }

  async downloadFile(fileName) {
    this.setState({ isLoading: true });
    const responseObj = await liquidityService.downloadBatchCreditDebit(
      fileName
    );
    const { status, response, count } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      window.scrollTo(0, this.notificationSectionRef.offsetTop);
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  render() {
    const { modulename } = this.state;
    const approvalForm = (
      <React.Fragment>
        <p>{`Do you want to ${
          this.state.approvalEvent === "approved" ? "approve" : "reject"
        } this workflow?`}</p>
        <textarea
          class="form-control"
          style={{ height: 220, resize: "none" }}
          onChange={(event) =>
            this.setState({
              approvalFormData: {
                ...this.state.approvalFormData,
                message: event.target.value,
              },
            })
          }
        ></textarea>
      </React.Fragment>
    );

    const ctaWidgetsForWorkFlowApproval = (
      <React.Fragment>
        {modulename === "Cashout-Inject" && (
          <button
            type="button"
            style={{
              backgroundColor: "rgb(0, 66, 95)",
              borderColor: "rgb(0, 66, 95)",
            }}
            className="btn btn-primary"
            onClick={this.downloadInjectDocument}
          >
            Download Document
          </button>
        )}

        {!this.toShowApproveRejectButton && (
          <button
            type="button"
            style={{ backgroundColor: "#00b8de", borderColor: "#00b8de" }}
            data-toggle="modal"
            data-target="#kt_modal_1"
            class="btn btn-primary"
            onClick={this.onApproveRejectButtonClick.bind(this, "true")}
          >
            Approve
          </button>
        )}
        {!this.toShowApproveRejectButton && (
          <button
            type="button"
            class="btn btn-danger"
            data-toggle="modal"
            data-target="#kt_modal_1"
            onClick={this.onApproveRejectButtonClick.bind(this, "false")}
          >
            Reject
          </button>
        )}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          ref={this.notificationSectionRef}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Workflow Management"}
          withoutSearch
          subtitle={`Approval Details`}
          ctaWidgets={ctaWidgetsForWorkFlowApproval}
        />
        <GridLayout>
          <ApprovalView
            approvalDetails={this.state.approvalDetails}
            title={`Workflow Approval Details`}
            downloadFile={this.downloadFile}
          />
          <PromptModal
            modalTitle={`Workflow Approval`}
            modalBody={approvalForm}
            height={`470px`}
            deleteModal={this.approveWorkflow}
            modalFooterButton1={`Cancel`}
            modalFooterButton2={`Ok`}
            hideModalHeader={true}
            hideModalFooter={true}
            textAlign={"left"}
            modalFooterButton2Disabled={!this.isFormValid()}
          />
        </GridLayout>
      </React.Fragment>
    );
  }
}
