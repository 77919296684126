import React from 'react'
import { OR, AND } from '../constants/operators';
import { retrieveSessionInformation } from '../mixins/authentication'
import { toPermissionArray } from '../utils/helper'

export default class ProtectedComponent extends React.Component {
  toEnforcePermissions = JSON.parse(process.env.REACT_APP_ENFORCE_PERMISSIONS)

  userHasPermission(requiredPermissions, operator) {
    const currentUser = retrieveSessionInformation()
    const userPermissions = currentUser.user.permissions ? toPermissionArray(currentUser.user.permissions) : []
    
    if (operator === OR) {
      return Boolean(userPermissions.find(value => requiredPermissions.includes(value)))
      //return true
    }

    else if (operator === AND) {
      return Boolean(userPermissions.filter(value => requiredPermissions.includes(value)) === requiredPermissions)
      //return true 
    }
  }

  render() {
    const operator = this.props.operator || OR;
    const { requiredPermissions } = this.props;

    if (!this.userHasPermission(requiredPermissions, operator) && this.toEnforcePermissions) {
      return this.props.permissionDeniedContent || <React.Fragment />
    }

    return this.props.children
  }
}
