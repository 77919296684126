import { connect } from 'react-redux'
import AddDocumentTypeClassScene from './scene';
import { updateCurrentPathName, updateScene } from '../../../../../../../services/redux/actions/navigation'

function mapStateToProps (state) {
  return {
    scene: state.navigation.scene,
    pathName: state.navigation.pathName
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateScene: scene => dispatch(updateScene(scene)),
    updateCurrentPathName: pathName => dispatch(updateCurrentPathName(pathName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentTypeClassScene);
