import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import BatchCreditDebitGridScene from './scenes/batch-credit-debit-grid'
import NewBatchCreditDebitScene from './scenes/new-batch-credit-debit'
import BaseScene from '../../../../../components/scenes/component';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_TRANSACTION, CAN_PERFORM_BULK } from '../../../../../constants/permissions.js'
import TransactionHistoryScene from '../../reports/transaction-history'

export default class BulkProcessingScene extends BaseScene {
  sceneName = 'batch-credit-debit'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {
    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }
    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_PERFORM_BULK]} exact path='/home/batch-credit-debit' component={BatchCreditDebitGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_PERFORM_BULK]} exact path='/home/batch-credit-debit/new' component={NewBatchCreditDebitScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_TRANSACTION]} exact path='/home/transaction-history' component={TransactionHistoryScene}  />
    </Router>
  }
}
