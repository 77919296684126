export default class RolesExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'}
        ]
    }

    static reverseRoleDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name } = details
            let detailFormData = {
                name
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}