import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import BaseScene from "../../../../../components/scenes/component";
import { CAN_VIEW_CURRENCY } from "../../../../../constants/permissions.js";
import { isAuthorized } from "../../../../../mixins/authentication";
import CurrencyGridScene from "./scenes/currency-grid";
import ViewCurrencyScene from "./scenes/view-currency";

export default class CurrencyScene extends BaseScene {
  sceneName = "currency-management";

  componentDidMount() {
    this.props.updateScene(this.sceneName);
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      requiredPermissions,
      ...rest
    }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            isAuthorized(requiredPermissions) ? (
              <Component {...props} />
            ) : (
              (window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`)
            )
          }
        />
      );
    };
    return (
      <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_CURRENCY]}
          exact
          path="/home/system-configuration/currency-management"
          component={CurrencyGridScene}
        />
        {/* <ProtectedRoute exact path='/home/system-configuration/currency-management/add' component={AddCurrencyScene} /> */}
        <ProtectedRoute
          requiredPermissions={[CAN_VIEW_CURRENCY]}
          exact
          path="/home/system-configuration/currency-management/:id/view"
          component={ViewCurrencyScene}
        />
      </Router>
    );
  }
}
