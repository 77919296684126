import React from 'react';
import './index.css';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BaseScene from '../../../../../components/scenes/component';
import AllStockGridScene from '../all-stocks/scenes/stock-grid';
import StocksUploadScene from '../upload-stocks/scenes/stock-upload-grid';
import UploadStocksGridScene from '../upload-stocks/scenes/upload-stock-grid';
// import RequestHeader from "./components/header";

export default class StockManagementScene extends BaseScene {
  sceneName = 'stock-manage';

  constructor(props) {
    super(props);

    this.state = {
      toggleState: 1,
    };
  }

  componentDidMount() {
    // this.props.updateScene(this.sceneName)
    //this.domainCode = this.props.match.params.domainCode
    // this.loadApplication();
  }

  toggleTab = (index) => {
    this.setState({
      toggleState: index,
    });
  };
  render() {
    return (
      <>
        <div className='col-md-12' style={{ marginTop: '-80px' }}>
          <section id='open-positions'>
            <div className='positions'>
              <div className='position-filters'>
                <label
                  className={
                    this.state.toggleState === 1
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(1)}
                >
                  All
                </label>
                <label
                  className={
                    this.state.toggleState === 2
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(2)}
                >
                  Upload Stock
                </label>
                <label
                  className={
                    this.state.toggleState === 3
                      ? 'tabb-label active-tabs'
                      : 'tabb-label'
                  }
                  onClick={() => this.toggleTab(3)}
                >
                  POS Stock
                </label>
              </div>

              <div
                className={
                  this.state.toggleState === 1
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <StocksUploadScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 2
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <UploadStocksGridScene />
                </div>
              </div>

              <div
                className={
                  this.state.toggleState === 3
                    ? 'position-group  active-content'
                    : 'position-group'
                }
              >
                <div className='position-post'>
                  <AllStockGridScene />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
