import { formatDate } from '../../utils/formatter';

export default class LanguageExportSerializer {
    static headerExport () {
        return [
            {label: 'Name', key: 'name', title: 'Name', dataIndex: 'name'},
            {label: 'Abbrevition', key: 'abbreviation', title: 'Abbreviation', dataIndex: 'abbreviation'},
            {label: 'status', key: 'status', title: 'Status', dataIndex: 'status'},
        ]
    }

    static reverseLanguageDetails (formData) {
        let newFormData = [];
        formData.map(details => {
            let { name, status, abbreviation } = details
            let detailFormData = {
                name,
                status,
                abbreviation
            }
            newFormData.push(detailFormData)
        })
        return newFormData
    }
}