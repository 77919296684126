import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import ContentHead from "../../../../../../../components/content-head.js";
import Loader from "../../../../../../../components/loader.js";
import Notification from "../../../../../../../components/notification.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import { CAN_REMAP_DEVICE } from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import { getDateByType } from "../../../../../../../utils/helper.js";
import FileUpload from "../../components/file-upload.js";

export default class ReconUploadGridScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {
        deviceType: "All",
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate"),
        documentStatus: "All",
        pageNum: 1,
        pageSize: 20,
      },
      errorMessage: "",
      successMessage: "",
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
      isLoading: false,
      stockDisable: false,
      configDisable: false,
      stockFile: [],
      configFile: { filename: "", file: "", url: "" },
      stockError: "",
      configError: "",
      redirect: false,
    };
    // this.uploadConfigFile = this.uploadConfigFile.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.readUploadedFile = this.readUploadedFile.bind(this);
  }

  sceneName = "upload-config";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content === null && response.count === 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }
  readUploadedFile(event, type) {
    console.log("type passed", type);
    const document = {
      file: event.target.files[0],
      filename: event.target.files[0].name,
      url: URL.createObjectURL(event.target.files[0]),
    };
    this.setState({
      config: {
        filename: document.filename,
        file: document.file,
        url: document.url,
      },
    });
    console.log(
      document.file,
      "FILE",
      document.filename,
      "FILENAME",
      document.url,
      "URL"
    );

    if (
      document.file.type === "application/vnd.ms-excel" ||
      document.file.type === "text/csv"
    ) {
      this.setState({
        [`${type}File`]: document,
        [`${type}Disable`]: false,
        [`${type}Error`]: "",
      });
    } else {
      this.setState({
        [`${type}Error`]: "Invalid file, upload a csv file",
        [`${type}Disable`]: false,
      });
    }
  }

  async handleFileUpload() {
    this.setState({
      isLoading: true,
      configDisable: true,
      stockDisable: true,
    });

    const { file, filename } = this.state.configFile;

    const responseObj = await platformManagement.documentExemptionUpload(file);

    const { status, response } = responseObj;


    if (status === "SUCCESS") {
      this.setState({
        successMessage: response.description,
        isLoading: false,
        stockDisable: false,
      });

      setTimeout(() => {
        this.setState({ redirect: true });
      }, 2000);
    } else {
      this.setState({
        errorMessage: response.description,
        isLoading: false,
        stockDisable: false,
      });
    }
  }

  render() {
    console.log("redirect", this.state.redirect);
    if (this.state.redirect) {
      return <Redirect to="/home/exempted-agent-management" />;
    }

    return (
      <React.Fragment>
        <Loader isLoading={this.state.isLoading} />
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"Exemption Report"}
          subtitle={"Upload File"}
          filterPermissions={[CAN_REMAP_DEVICE]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <div class="row">
            <div class="col-lg-6">
              <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                  <div class="kt-portlet__body">
                    <h5>Upload Exemption Report File</h5>
                    <a
                      href={`${process.env.REACT_APP_EXEMPTED_AGENTS_FILE_UPLOAD_TEMPLATE_URL}`}
                      target="_blank"
                      download
                    >
                        {/* Special_Agent_File_Template */}
                        Download Template
                    </a>
                    <div style={{ marginTop: "50px" }}>
                      <FileUpload
                        disabled={this.state.configDisable}
                        fileErrorMessage={this.state.configError}
                        file={this.state.configFile}
                        readUploadedFile={this.readUploadedFile}
                        handleFileUpload={this.handleFileUpload}
                        type="config"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
