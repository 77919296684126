import React from "react";
import BaseScene from "../../../../../../../components/scenes/component";
import {
  CAN_GET_DEVICES_BY_UUID,
  CAN_REMAP_DEVICE,
} from "../../../../../../../constants/permissions.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component";
import {
  SUCCESS_STATUS,
} from "../../../../../../../constants/api";
import { AccessDenied } from "../../../../../../../components/access-denied";
import { GridLayout } from "../../../../../../../components/layouts/grid-layout";
import Notification from "../../../../../../../components/notification";
import { userManagement } from "../../../../../../../mixins/api";
import { getDateByType } from "../../../../../../../utils/helper";
import {
  formatBackEndDateTime,
  formatBackEndDateTimeTo,
  formatBackEndDateTimeFrom,
} from "../../../../../../../utils/formatter";
import StockTable from "../../components/stock-table";
import StockSearch from "../../components/stock-search.js";
import StockDetailsModal from "../../components/stock-modal";
import ContentHead from "../../../../../../../components/content-head";

export default class StockGridScene extends BaseScene {
  requiredPermissions = [CAN_GET_DEVICES_BY_UUID];

  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      searchParameters: {
        deviceType: "All",
        fromDate: getDateByType("startDate"),
        toDate: getDateByType("endDate"),
        stockStatus: "All",
        pageNum: 1,
        pageSize: 20,
      },
      tableMessage: "",
      canLoadData: !JSON.parse(process.env.REACT_APP_ENFORCE_LOAD_GRID_DATA),
    };
    this.getStocks = this.getStocks.bind(this);
    this.getStocksDetails = this.getStocksDetails.bind(this);
    this.getClickedAction = this.getClickedAction.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.downloadStocks = this.downloadStocks.bind(this);
  }

  sceneName = "pos-stocks";

  componentDidUpdate(prevProps, prevState) {
    if (this.state.errorMessage) {
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 5000);
    }
    if (this.state.successMessage) {
      setTimeout(() => {
        this.setState({ successMessage: null });
      }, 5000);
    }
  }

  handleBackendResponse(response) {
    if (response.content == null && response.count == 0) {
      return [];
    } else {
      return response ? response : [];
    }
  }

  async getStocks(pageNum, pageSize) {
    console.log("pagination details ", pageNum, pageSize);
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = pageNum;
    searchParameters.pageSize = pageSize;
    searchParameters.stockStatus =
      searchParameters.stockStatus === "All"
        ? null
        : searchParameters.stockStatus;
    searchParameters.deviceType =
      searchParameters.deviceType === "All"
        ? null
        : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTimeFrom(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTimeTo(searchParameters.toDate, true)}.747`;
    console.log("from the main scene ", searchParameters);
    const responseObj = await userManagement.getStocks(searchParameters);
    const { status, response, count } = responseObj;
    console.log("response is ", response);
    if (status === SUCCESS_STATUS) {
      this.setState({
        stocks: response.data,
        dataCount: count ? count : response.data.length,
        isLoading: false,
        pages: Math.ceil(count / pageSize),
        pageSize: pageSize,
        page: pageNum,
        startPage: null,
        tableMessage: !count || count == 0 ? "No POS stock found" : "",
      });
    } else {
      this.setState({
        stocks: [],
        pages: 1,
        pageSize: pageSize,
        page: pageNum,
        dataCount: 2,
        isLoading: false,
        errorMessage: response,
        tableMessage: "No POS stock found",
      });
    }
  }

  async getStocksDetails(id) {
    this.setState({
      stockDetails: [],
    });

    const responseObj = await userManagement.getStockById(id);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      console.log("GETTNG REQUEST BY ID TO TOGGLE MODAL");
      console.log(response);
      this.setState({
        stockDetails: response ? response : "",
      });
    } else {
      this.setState({
        stockDetails: [],
      });
    }
  }

  async downloadStocks() {
    let searchParameters = this.state.searchParameters;
    searchParameters.pageNum = this.state.page;
    searchParameters.pageSize = this.state.dataCount;
    searchParameters.stockStatus =
      searchParameters.stockStatus === "All"
        ? null
        : searchParameters.stockStatus;
    searchParameters.deviceType =
      searchParameters.deviceType === "All"
        ? null
        : searchParameters.deviceType;
    searchParameters.fromDate =
      searchParameters.fromDate &&
      `${formatBackEndDateTime(searchParameters.fromDate, true)}.747`;
    searchParameters.toDate =
      searchParameters.toDate &&
      `${formatBackEndDateTime(searchParameters.toDate, true)}.747`;
    this.setState({
      isLoading: true,
      canLoadData: true,
    });
    const responseObj = await userManagement.downloadStocks(searchParameters);
    const { status, response } = responseObj;
    if (status === SUCCESS_STATUS) {
      const blob = new Blob([response], { type: "contentType" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", "pos_stocks.csv");
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage: response,
        isLoading: false,
      });
    }
  }

  handleSearchRequest(searchParameters) {
    this.setState({ loading: true });
    this.setState({ pageIndex: 1 });
    this.setState({ startPage: 1 });
    this.setState({
      startPage: 1,
      searchParameters,
    });
    setTimeout(() => {
      this.getStocks(1, 20);
      this.setState({ loading: false });
    });
  }

  getClickedAction(requests, actionType) {
    if (actionType == "view") {
      this.getStocksDetails(requests.id);
      this.setState({
        requestDetails: requests,
        settlement: {
          parties: [],
        },
      });
    }
    this.setState({
      requestId: requests.requestId,
      actionType,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Notification
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
        <ContentHead
          title={"POS Management"}
          subtitle={"Stocks"}
          filterPermissions={[CAN_GET_DEVICES_BY_UUID]}
          actionButtonRequiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          totalCount={56}
        />
        <ProtectedComponent
          requiredPermissions={[CAN_GET_DEVICES_BY_UUID]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <GridLayout>
            <StockSearch
              handleSearchRequest={this.handleSearchRequest}
              searchParameters={this.state.searchParameters}
            />
            <StockTable
              data={this.state.stocks}
              loading={this.state.isLoading}
              noDataText={this.state.tableMessage}
              dataCount={this.state.dataCount}
              pages={this.state.pages}
              startPage={this.state.startPage}
              getStocks={this.getStocks}
              page={this.state.page}
              pageSize={this.state.pageSize}
              canRemapDevice={CAN_REMAP_DEVICE}
              canLoadData={this.state.canLoadData}
              downloadStocks={this.downloadStocks}
              getClickedAction={this.getClickedAction}
            />

            <StockDetailsModal
              stockDetails={this.state.stockDetails}
              loading={this.state.isLoading}
              // confirmConfigure={this.confirmConfigure}
            />
          </GridLayout>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}
