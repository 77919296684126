import Requester from '../requester';

const API_BASE_URL = process.env.REACT_APP_MESSAGING_SERVICE_API_BASE_URL;

export default class MessagingService {
  constructor (props) {
    this.apiRequester = props ? props.apiRequester || new Requester({
      apiBaseUrl: API_BASE_URL
    }) : new Requester({
      apiBaseUrl: API_BASE_URL
    })
  }
  getAllTemplates() {
    return this.apiRequester.get({
      endpoint: 'templates'
    })
  }
  
  getAllTemplatesQuery(pageNumber = null, pageSize = null, payload) {
    let { toDate, fromDate, queryName } = payload;

    return this.apiRequester.post({
      endpoint: 'templates/query',
      body: {
        pageNumber,
        pageSize,
        filter: {
          ...(queryName != null ? { name: queryName } : {})
        },
        range: {
          ...(fromDate != null ? { start: fromDate, end: toDate } : {})
        },
        sortField: 'created',
        sortOrder: 'DESC'
      }
    })
  }

  createTemplates(data) {
    const bodyData = new FormData() 
    bodyData.append('name', data.name)
    bodyData.append('subject', data.subject)
    bodyData.append('key', data.key)
    data.fileName && bodyData.append('fileName', data.fileName)
    bodyData.append('body', data.body)
    data.file && bodyData.append('file', data.file)
    bodyData.append('channels', data.channels)

    return this.apiRequester.post({
      endpoint: 'templates',
      body: bodyData,
      headers: {
        'content-type': 'multipart/form-data',
        'accept': '*/*',
      }
    })
  }

  updateTemplate(data) {
    const bodyData = new FormData() 
    bodyData.append('name', data.name)
    bodyData.append('subject', data.subject)
    bodyData.append('key', data.key)
    bodyData.append('body', data.body)
    data.file && bodyData.append('file', data.file)
    bodyData.append('channels', data.channels)

    return this.apiRequester.post({
      endpoint: 'templates/update',
      body: bodyData,
      headers: {
        'content-type': 'multipart/form-data',
        'accept': '*/*',
      }
    })
  }

  deleteTemplate(templateKey) {
    return this.apiRequester.delete({
      endpoint: `templates/${templateKey}`
    })
  }

  deleteTopics(topicId) {
    return this.apiRequester.delete({
      endpoint: `topics/${topicId}`
    })
  }

  getTemplate(key) {
    return this.apiRequester.get({
      endpoint: `templates/${key}`
    })
  }

  getTopics() {
    return this.apiRequester.get({
      endpoint: 'topics'
    })
  }

  createTopics(data) {
    return this.apiRequester.post({
      endpoint: 'topics',
      body: data
    })
  }

  getAllTopicsQuery(pageNumber = null, pageSize = null, payload) {
    const { toDate, fromDate, queryName } = payload;
  
    return this.apiRequester.post({
      endpoint: 'topics/query',
      body: {
        pageNumber,
        pageSize,
        filter: {
          ...(queryName != null ? { name: queryName } : {})
        },
        range: {
          ...(fromDate != null ? { start: fromDate, end: toDate } : {})
        },
        sortField: 'created',
        sortOrder: 'DESC'
      }
    })
  }

  getTemplateParameters(key) {
    return this.apiRequester.get({
      endpoint: `templates/${key}/params`
    })
  }

  sendMessage(data) {
    return this.apiRequester.post({
      endpoint: `messaging/send`,
      body: data
    })
  }

  getMessagingHistory(pageNumber = null, pageSize = null, payload) {
    const { toDate, fromDate, search, to, channel, status, username } = payload;
  
    return this.apiRequester.post({
      endpoint: 'messaging/history',
      body: {
        pageNumber,
        pageSize,
        filter: {
          ...(search != null ? { search } : {}),
          ...(to != null ? { to } : {}),
          ...(channel != 'All' ? { channel } : {}),
          ...(status != 'All' ? { status } : {}),
          ...(username != null ? { username } : {})
        },
        range: {
          ...(fromDate != null ? { start: fromDate, end: toDate } : {})
        },
        sortField: 'created',
        sortOrder: 'DESC'
      }
    })
  }

  getRecurringMessages(pageNumber = null, pageSize = null, payload) {
    const { toDate, fromDate, search, to, channel, status, username } = payload;
  
    return this.apiRequester.post({
      endpoint: 'messaging/schedule/query',
      body: {
        pageNumber,
        pageSize
      }
    })
  }

  createRecurringMessage(data) {
    return this.apiRequester.post({
      endpoint: `messaging/schedule`,
      body: data
    })
  }

  deleteRecurringMessage(id) {
    return this.apiRequester.delete({
      endpoint: `messaging/schedule/${id}`
    })
  }

}