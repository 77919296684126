import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ExternalFeeGridScene from './scenes/external-fee-grid';
import AddExternalFeeScene from './scenes/add-external-fee';
import ViewExternalFeeScene from './scenes/view-external-fee';
// import UpdateFeeRuleStatusScene from './scenes/update-fee-rule-status';
import { isAuthorized } from '../../../../../mixins/authentication'
import { CAN_VIEW_FEE, CAN_CREATE_FEE } from '../../../../../constants/permissions.js'

export default class ExternalFeeScene extends Component {
  sceneName = 'external-fees'
  
  componentDidMount () {
    this.props.updateScene(this.sceneName)
  }

  render () {

    const ProtectedRoute = ({component: Component, requiredPermissions, ...rest}) => {
      return (
        <Route {...rest} render={props => (
          isAuthorized(requiredPermissions) ?
            <Component {...props} />
          : window.location.href = `${process.env.REACT_APP_ROUTE_BASENAME}/home`
        )} />
      )
    }

    return <Router basename={process.env.REACT_APP_ROUTE_BASENAME}>
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FEE]} exact path='/home/external-fees' component={ExternalFeeGridScene} />
      <ProtectedRoute requiredPermissions={[CAN_CREATE_FEE]} exact path='/home/external-fees/add' component={AddExternalFeeScene} />
      <ProtectedRoute requiredPermissions={[CAN_VIEW_FEE]} exact path='/home/external-fees/view' component={ViewExternalFeeScene} />
      {/* <Route exact path="/home/external-service/:status/:id" component={UpdateFeeRuleStatusScene} /> */}
    </Router>
  }
}
