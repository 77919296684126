import React from "react";
import { Redirect } from "react-router-dom";
import { AccessDenied } from "../../../../../../../components/access-denied.js";
import Loader from "../../../../../../../components/loader.js";
import Notification from "../../../../../../../components/notification.js";
import BaseScene from "../../../../../../../components/scenes/component.js";
import { CAN_REMAP_DEVICE } from "../../../../../../../constants/permissions.js";
import { platformManagement } from "../../../../../../../mixins/api.js";
import ProtectedComponent from "../../../../../../../mixins/protected-component.js";
import { SUCCESS_STATUS } from "../../../../../../../constants/api.js";
import { ContentHeadButton } from "../../../../../../../components/content-head-button.js";

export default class CategoryUpdateScene extends BaseScene {
  requiredPermissions = [CAN_REMAP_DEVICE];

  constructor(props) {
    super(props);
    this.state = {
      providerCode: "",
      bankName: "",
      description: "",
      isLoading: false,
      successMessage: "",
      errorMessage: "",
      isSaveButtonEnabled: false,
      redirect: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddProvider = this.handleAddProvider.bind(this);
  }

  sceneName = "upload-config";

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      this.validateForm
    );
  }

  validateForm() {
    const { providerCode, bankName } = this.state;
    const isSaveButtonEnabled = providerCode.trim() !== "" && bankName.trim() !== "";
    this.setState({ isSaveButtonEnabled });
  }

  async handleAddProvider() {
    const { providerCode, bankName, description } = this.state;

    const payload = {
      providerCode,
      bankName,
      description,
    };

    this.setState({ isLoading: true });

    try {
      const responseObj = await platformManagement.createStaticAccountProvider(payload);
      const { status, response } = responseObj;

      if (status === SUCCESS_STATUS) {
        this.setState({
          successMessage: "Static provider created successfully!",
          errorMessage: "",
          providerCode: "",
          bankName: "",
          description: "",
          isSaveButtonEnabled: false,
        });
        setTimeout(() => {
          this.setState({ redirect: true });
        }, 2000);
      } else {
        this.setState({ errorMessage: response.description });
      }
    } catch (error) {
      this.setState({ errorMessage: "An error occurred. Please try again." });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { providerCode, bankName, description, isLoading, successMessage, errorMessage, isSaveButtonEnabled } = this.state;
    if (this.state.redirect) {
      return <Redirect to="/home/manage-static-account" />;
    }

    return (
      <React.Fragment>
        <Loader isLoading={isLoading} />
        <Notification successMessage={successMessage} errorMessage={errorMessage} />

        <ContentHeadButton
          text="Add Provider"
          onSubmit={this.handleAddProvider}
          isSaveButtonEnabled={isSaveButtonEnabled}
        />

        <ProtectedComponent
          requiredPermissions={[CAN_REMAP_DEVICE]}
          permissionDeniedContent={this.permissionDeniedContent}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="kt-portlet">
                <form className="kt-form kt-form--label-right">
                  <div className="kt-portlet__body">
                    <div className="mt-2">
                      <label
                        htmlFor="documentType"
                        style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "25px" }}
                      >
                        Account Details
                      </label>

                      <div className="mb-6 flex items-center">
                        <label
                          className="text-sm font-medium"
                          style={{ minWidth: "150px" }}
                        >
                          Provider Code <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="providerCode"
                          value={providerCode}
                          onChange={this.handleInputChange}
                          className="flex-1 p-2 bg-gray-100 border border-gray-100 rounded-lg focus:outline-none"
                          style={{ backgroundColor: "#f2f2f2", borderColor: "#f2f2f2" }}
                        />
                      </div>

                      <div className="mb-6 flex items-center mt-2">
                        <label
                          className="text-sm font-medium"
                          style={{ minWidth: "150px" }}
                        >
                          Bank Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="bankName"
                          value={bankName}
                          onChange={this.handleInputChange}
                          className="flex-1 p-2 bg-gray-100 border border-gray-100 rounded-lg focus:outline-none"
                          style={{ backgroundColor: "#f2f2f2", borderColor: "#f2f2f2" }}
                        />
                      </div>

                      <div className="mb-6 flex items-center mt-2">
                        <label
                          className="text-sm font-medium"
                          style={{ minWidth: "150px" }}
                        >
                          Description
                        </label>
                        <input
                          type="text"
                          name="description"
                          value={description}
                          onChange={this.handleInputChange}
                          className="flex-1 p-2 bg-gray-100 border border-gray-100 rounded-lg focus:outline-none"
                          style={{ backgroundColor: "#f2f2f2", borderColor: "#f2f2f2" }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProtectedComponent>
      </React.Fragment>
    );
  }

  get permissionDeniedContent() {
    return <AccessDenied />;
  }
}