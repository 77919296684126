import React from 'react'

export default class RegionMappingForm extends React.Component {
    render() {
        return <React.Fragment>
            <div class="kt-portlet">
                <form class="kt-form kt-form--label-right">
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group col-lg-6">
                                    <label>Region *:</label>
                                    <select autoComplete="off" disabled={this.props.disabled} class="form-control" data-live-search="true" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => this.props.onChange('regionId', event.target.value)}>
                                        <option selected disabled>Select</option>
                                        {this.props.regions.map((value, index) => <option value={value.regionId}  selected={this.props.regionMappingFormData.regionId === value.regionId ? 'selected' : ''}>{value.regionName}</option>)}
                                    </select>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Country *:</label>
                                    <select autoComplete="off" disabled={this.props.disabled} class="form-control" data-live-search="true" disabled={this.props.disabled ? this.props.disabled : ''} onChange={(event) => {
                                        this.props.onChange('countryId', event.target.value)
                                        this.props.getStates(event.target.value)
                                    }}>
                                        <option selected disabled>Select</option>
                                        {this.props.countries.map((value, index) => <option value={value.id} selected={this.props.regionMappingFormData.countryId === value.id ? 'selected' : ''}>{value.name}</option>)}
                                    </select>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>State *:</label>
                                    <select autoComplete="off" /*disabled={this.props.disabled}*/ class="form-control" data-live-search="true" /*disabled={this.props.disabled ? this.props.disabled : ''}*/ onChange={(event) => {
                                        this.props.onChange('stateId', event.target.value)
                                        this.props.getLgas(event.target.value)
                                    }}>
                                        <option selected disabled>Select</option>
                                        {this.props.states.map((value, index) => <option value={value.id} selected={this.props.regionMappingFormData.stateId === value.id ? 'selected' : ''}>{value.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            {this.props.lgas.length ? <div class="col-md-8">
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                        <th>S/N</th>
                                        <th>LGA Name</th>
                                        <th>LGA Code</th>
                                        <th>LGA Status</th>
                                    </thead>
                                    <tbody>
                                        {this.props.lgas.map((value, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{value.name}</td>
                                                <td>{value.code}</td>
                                                <td><input type="checkbox" checked={this.props.regionMappingFormData.lga.includes(value.id)} onClick={() => this.props.updateLgaStatus(value.id)}/></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div class="pull-right">
                                    {!this.props.hideCheck && <button type="button" class="btn btn-default btn-wrapper" onClick={this.props.checkAllLgas}>Check All</button>}
                                    {!this.props.hideCheck && <button type="button" class="btn btn-default btn-wrapper" onClick={this.props.uncheckAllLgas}> Uncheck All</button>}
                                    <button type="button" class="btn btn-default btn-wrapper" onClick={this.props.onSaveButtonClick}>{this.props.hideCheck ? 'Update' : 'Save'}</button>
                                </div>
                            </div> : ''}
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}