import { connect } from 'react-redux';
import HomeScene from './scene';
import { updateCurrentPathName } from '../../services/redux/actions/navigation'

function mapStateToProps(state) {
  return {
    pathName: state.navigation.pathName
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCurrentPathName: pathName => dispatch(updateCurrentPathName(pathName))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScene)
