import React from 'react'
import { formatDateTime } from '../../../../../../utils/formatter'
import { getTransactionTypeLabel } from '../../../../../../utils/helper'

export default class TransactionDetails extends React.Component {
    render () {
        return <React.Fragment>
            <div class="kt-portlet">
                <div style={{cursor: 'pointer'}} class="kt-portlet__head" data-toggle="collapse" data-target="#collapseOne1" aria-controls="collapseOne1">
                    <div class="kt-portlet__head-label" data-toggle="collapse" role="button" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                        <h3 class="kt-portlet__head-title">
                            Agent Transaction Details
                        </h3>
                    </div>
                </div>

                <form class="kt-form kt-form--label-right" id="collapseOne1" class="collapse show" aria-labelledby="headingOne1">
                    <div class="kt-portlet__body">
                        <div class="form-group col-lg-6">
                            <label>Trans Ref</label>
                            <input value={this.props.settlement.transactionRef} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Date & Time</label>
                            <input value={this.props.settlement.dateTimeProcessed ? formatDateTime(this.props.settlement.dateTimeProcessed) : ''} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Settlement Date</label>
                            <input value={this.props.settlement.settlementDate ? formatDateTime(this.props.settlement.settlementDate) : ''} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Transaction Type</label>
                            <input value={getTransactionTypeLabel(this.props.settlement.transactionType)} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Transaction Amount (&#x20A6;)</label>
                            <input value={(this.props.settlement.transactionAmount ? this.props.settlement.transactionAmount / 100 : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Transaction Surcharge (&#x20A6;)</label>
                            <input value={(this.props.settlement.transactionSurcharge ? this.props.settlement.transactionSurcharge / 100 : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} disabled={this.props.disabled} autoComplete="off" type="text" class="form-control" placeholder="" />
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    }
}