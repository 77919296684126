import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { formatDateTime } from '../../../../../utils/formatter'
import { getPaginationRows } from '../../../../../utils/helper'
import SpinnerComponent from '../../../../../components/spinner'
import Pagination from '../../../../../components/pagination'
import TableExtra from '../../../../../components/table-extra'
import ProtectedComponent from '../../../../../mixins/protected-component';
import { Link } from 'react-router-dom'

export default class TemplateTable extends React.Component {
    state = {
        page: 0,
        tableHeight: '950px',
        zoom: 'plus'
    }

    zoomTableHeight = this.zoomTableHeight.bind(this)

    zoomTableHeight () {
        this.setState({
          tableHeight: this.state.tableHeight === '430px' ? '860px' : this.state.tableHeight === '860px' ? 'auto' : '430px',
          zoom: this.state.tableHeight === '860px' ? 'minus' : 'plus'
        })
    }

    render() {
        const MyLoader = props => { return (
            props.loading && (
              <div className="-loading -active">
                <div className="-loading-inner">
                  <SpinnerComponent isLoading={props.loading} />
                </div> 
              </div>
            )
        )};
        const columns = [
            {
                Header: "Template Key",
                accessor: 'key',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 200,
                Cell: props => `${props.value}`
            },{
                Header: "Template Name",
                accessor: 'name',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 200,
                // Cell: props=> getApplicationType(props.value)
            },{
                Header: "Template Subject",
                accessor: 'subject',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset'},
                width: 250,
            },{
                Header: "Template Body",
                accessor: 'body',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset', 'text-overflow': 'ellipsis'},
                width: 450,
                Cell: props => <div style={{ textOverflow: 'ellipsis', width: '250px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{`${props.value.slice(0, 55)}`}</div>
            },{
                Header: "Channels",
                accessor: 'channels',
                resizable: false,
                headerStyle: {'white-space': 'unset'},
                style: {'white-space': 'unset', 'text-overflow': 'ellipsis'},
                width: 220,
                Cell: props => <div style={{ textOverflow: 'ellipsis' }}>{`${props.value.toString()}`}</div>

            }, {
              Header: "Date",
              accessor: 'created',
              resizable: false,
              headerStyle: {'white-space': 'unset'},
              style: {'white-space': 'unset'},
              // width: 100,
              Cell: props=> props.value && formatDateTime(props.value)
          },
            {
                Header: "Action",
                accessor: 'agentCode',
                overflow: 'visible',
                resizable: false,
                sortable: false,
                headerStyle: {'white-space': 'unset'},
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      lineHeight: '20px',
                      textAlign: 'center',
                      whiteSpace: 'unset',
                      overflow: 'visible',
                    },
                  };
                },
                width: 60,
                Cell: ({row, original}) => <div class="dropdown">
                  <a href="#" class="btn btn-hover-brand btn-icon btn-pill" data-toggle="dropdown">
                    <i class="la la-ellipsis-v"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ProtectedComponent requiredPermissions={[this.props.canView]}><Link to={`template/${row.key}/view`} class="dropdown-item">
                      <i class="la la-eye"></i>
                      View
                    </Link></ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[this.props.canCancel]}><a onClick={() => this.props.getClickedAction(row.key, row.name)} data-toggle="modal" data-target="#kt_modal_1" class="dropdown-item" style={{color: '#A7ABC3', cursor: 'pointer'}}>
                      <i class="la la-close"></i>
                      Delete
                    </a></ProtectedComponent>
                  </div>
                </div>
            }
            
        ]

        return <React.Fragment>
            <TableExtra 
                zoomTableHeight={this.zoomTableHeight}
                getData={this.props.getTemplates}
                page={this.props.page}
                pageSize={this.props.pageSize}
                zoom={this.state.zoom}
                disabled={this.props.loading}
            />
            <ReactTable 
                PaginationComponent={Pagination}
                loading={this.props.loading}
                LoadingComponent={MyLoader}
                style={{
                    border: "none",
                    boxShadow: "none",
                    whiteSpace: 'unset',
                    height: this.state.tableHeight,
                }}
                data={this.props.data}
                columns={columns}
                dataCount={this.props.dataCount}
                noDataText={!this.props.loading ? this.props.noDataText : ''}
                pageSizes={getPaginationRows(this.props.dataCount)}
                headerNodeHeight={`123`}
                manual
                pages={this.props.pages}
                page={this.props.startPage ? this.props.startPage - 1 : this.state.page}
                minRows={(this.props.data).length > 0 ? 10 : 10}
                onFetchData={(state, instance) => {
                    this.setState({page: state.page})
                    if(this.props.canLoadData) {
                        this.props.getTemplates(state.page + 1,state.pageSize)
                    } 
                }}
                getTableProps={(state, rowInfo, column, instance) => ({
                  style: {
                    display:"flex",
                    overflow: "visible",
                    color: "black",
                    fontSize: "11.5px",
                    fontWeight: "50",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                    alignItems: "center",
                    
                  },
                })}
                getTheadThProps={(state, rowInfo, column, instance) => ({
                  style: {
                    backgroundColor: "#00425f",
                    borderBottom: "0.1px solid #DBDEDE",
                    borderWidth: "0.1px",
                    padding:"10px 0",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "100",
                    textAlign: "left",
                    paddingLeft:"5px",
                    paddingRight:"5px",
                  },
                })}
                getPaginationProps={(state, rowInfo, column, instance) => ({
                    style: {
                        overflow: 'visible',
                        backgroundColor: '#ffffff',
                    },
                })}    
                className="-striped -highlight" 
            />
        </React.Fragment>
          
    }
}